import { Injectable } from '@angular/core';
import { LegacyBackendSignalRService } from '@datagalaxy/data-access';
import {
    ModuleAccessRightUpdatedEvent,
    SourceAccessRightUpdatedEvent,
} from './module-signalr.types';

@Injectable({ providedIn: 'root' })
export class ModuleSignalrService {
    public readonly updatedModule$ = this.signalR.fromEvent(
        'ModuleAccessRightUpdatedEvent',
        (data) => data as ModuleAccessRightUpdatedEvent,
    );

    public readonly updatedSource$ = this.signalR.fromEvent(
        'SourceAccessRightUpdatedEvent',
        (data) => data as SourceAccessRightUpdatedEvent,
    );

    constructor(private signalR: LegacyBackendSignalRService) {}
}
