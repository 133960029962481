import { CollectionsHelper } from '@datagalaxy/core-util';
import { Injectable } from '@angular/core';
import { BaseStateService } from '@datagalaxy/utils';
import { TechnologyDto } from '@datagalaxy/webclient/technology/domain';

export interface TechnologyStoreState {
    technologies: TechnologyDto[];
    loading: boolean;
}

@Injectable({ providedIn: 'root' })
export class TechnologyStore extends BaseStateService<TechnologyStoreState> {
    public get technologies() {
        return this.state.technologies;
    }

    constructor() {
        super({ technologies: [], loading: false });
    }

    public dispatchLoading(loading: boolean) {
        this.setState({ loading });
    }

    public dispatchCreate(technology: TechnologyDto) {
        this.setState({
            technologies: CollectionsHelper.alphaSort(
                [...this.state.technologies, technology],
                'TechnologyCode',
            ),
        });
    }

    public dispatchUpdate(technology: TechnologyDto) {
        this.setState({
            technologies: [
                ...CollectionsHelper.replaceOne(
                    this.state.technologies,
                    (t) => t.TechnologyCode === technology.TechnologyCode,
                    { ...technology },
                ),
            ],
        });
    }

    public dispatchDelete(technologyCode: string) {
        this.setState({
            technologies: [
                ...this.state.technologies.filter(
                    (t) => t.TechnologyCode !== technologyCode,
                ),
            ],
        });
    }

    public dispatchLoaded(technologies: TechnologyDto[]) {
        this.setState({ technologies });
    }

    public selectLoading() {
        return this.select((state) => state.loading);
    }

    public selectTechnologies() {
        return this.select((state) => state.technologies);
    }
}
