import {
    BurgerMenuHoverManager,
    IBurgerMenuHoverManagerOptions,
} from './BurgerMenuHoverManager';
import { DomUtil, THTMLElement } from '@datagalaxy/core-util';
import { IContextMenuActionProvider, IListOption } from '../../IListOption';
import { EventName } from '../../Constants';

/** GUI sub-component providing context-menu handling for a hovered item */
export class BurgerMenuManager<
    TItem,
    TContext,
    TOptionContext = TItem,
> extends BurgerMenuHoverManager<TItem, TContext> {
    static debug = false;

    private icon: Element;
    private get menu() {
        return this.hostElement.querySelector('.burger-menu') as HTMLElement;
    }
    private isModal: boolean;

    /** must be bound to the dxy-burger-menu component's *options* property */
    public get burgerMenuOptions() {
        return this._burgerMenuOptions;
    }
    private _burgerMenuOptions: IListOption[];

    /** must be bound to the dxy-burger-menu component's *data* property */
    public get optionData() {
        return this._optionData;
    }
    private _optionData: TItem;

    private hostElement: HTMLElement;
    private getMenuOffset?: (icon: Element) => { left: number; top: number };
    private contextWhileMenuVisible: { item: TItem; context: TContext };

    /** The host component must have the following in its html template:
     * `<dxy-burger-menu [options]="burgerMenuOptions"></dxy-burger-menu>`
     * where *burgerMenuOptions* is either this burgerMenuOptions property, or an empty *IListOption[]* to be passed to this constructor */
    constructor(
        hostElement: THTMLElement,
        private actionProvider: IContextMenuActionProvider<
            TItem,
            TContext,
            TOptionContext
        >,
        private opt?: IBurgerMenuManagerOptions<TItem, TContext>,
    ) {
        super(
            {
                show: (icon, item, context) => this.show(icon, item, context),
                hide: () => this.hide(),
                isAvailableFor: (item, state) =>
                    this.actionProvider.isAvailableFor(item, state),
            },
            opt?.showHideBurgerIcon ?? (() => {}),
            opt?.getContext,
            opt?.onItemInOut,
            opt as IBurgerMenuHoverManagerOptions,
        );

        if (BurgerMenuManager.debug) {
            this.debug = true;
        }

        this.log('ctor', !!opt?.burgerMenuOptions, !!opt?.ngZone);
        this._burgerMenuOptions = opt?.burgerMenuOptions ?? [];

        this.hostElement = DomUtil.getElement(hostElement);
        this.getMenuOffset =
            opt?.getMenuOffset ??
            ((icon) => this.defaultGetMenuOffset(icon as HTMLElement));

        this.isModal = opt?.isModalContext ?? false;

        // bind the action provider
        actionProvider.onExecute = () => this.hide();
    }

    /** hide the menu */
    public hide() {
        this.log('hide');
        this.menu?.classList.add('hidden');
        this.onBurgerMenuShowHide(false, this.menu);
        this.icon = undefined;
        this.onMenuShowingOrHidding(false);
    }

    /** recompute the menu position */
    public updateMenuPosition() {
        if (!this.icon || !this.menu) {
            return;
        }
        const offset = this.getMenuOffset(this.icon);
        if (!offset) {
            return;
        }
        const bodyElement = document.body;
        const bodyRect = bodyElement.getBoundingClientRect();
        const iconRect = this.icon.getBoundingClientRect();
        this.menu.style.right = `${bodyRect.right - iconRect.right}px`;
        this.menu.style.top = `${offset.top}px`;
    }

    /** do not call - used internally */
    public onBurgerMenuShowHide(show: boolean, menu: Element) {
        super.onBurgerMenuShowHide(show, menu);
    }

    /** build the menu list content and show the menu,
     * returns true if the menu has been shown */
    private show(icon: Element, item: TItem, context: TContext) {
        this.log('show', icon, item, context);

        // get the icon position
        const offset = this.getMenuOffset(icon);
        if (!offset) {
            this._burgerMenuOptions = null;
            this.log('show-no-offset');
            return false;
        }

        // get the available options
        const options = this.actionProvider.getOptions(item, context);
        this._optionData = item;

        // no options: no menu
        if (!options?.length) {
            this._burgerMenuOptions = null;
            this.log('show-no-options');
            return false;
        }

        this.log('show-options', options);

        // unhide the menu and position it
        const menu = this.menu;
        menu.classList.remove('hidden');

        const bodyElement = document.body;
        const bodyRect = bodyElement.getBoundingClientRect();
        const iconRect = icon.getBoundingClientRect();

        /* We set burger menu visibility to hidden until we calculate its final position.
         * This avoids glitch effect when repositioning */
        menu.style.visibility = 'hidden';

        const px = (px: number) => `${px}px`;
        const modalTop = this.isModal
            ? document
                  .getElementsByClassName('modal-dialog')[0]
                  .getBoundingClientRect().top
            : 0;
        menu.style.top = px(iconRect.bottom - modalTop);
        menu.style.right = px(
            this.isModal ? 30 : bodyRect.right - iconRect.right,
        );

        this.icon = icon;

        this._burgerMenuOptions = options;
        this.onMenuShowingOrHidding(true, item, context);

        this.log('show-setTimeout', this.opt?.showMenuDelayMs);

        this.setTimeout(() => {
            this.log('show-setTimeout-start', Zone.current);
            // signal to render the menu's dynamic content
            menu.dispatchEvent(new CustomEvent(EventName.BurgerMenuShowing));

            // signal to bind the show/hide events
            this.onBurgerMenuShowHide(true, menu);

            /* If dropdown is out of screen => move it up */
            const menuRect = menu.getBoundingClientRect();
            if (menuRect.bottom > bodyRect.bottom - 20) {
                menu.style.top = px(iconRect.top - menuRect.height);
            }

            menu.style.visibility = 'visible';

            this.log('show-setTimeout-end');
        }, this.opt?.showMenuDelayMs ?? 200);

        this.log('show-end');
        return true;
    }

    private defaultGetMenuOffset(icon: HTMLElement) {
        const offset = DomUtil.getGlobalOffset(icon);
        if (!offset) {
            return;
        }
        offset.top += icon.getBoundingClientRect().height;
        return offset;
    }

    private onMenuShowingOrHidding(
        showing: boolean,
        item?: TItem,
        context?: TContext,
    ) {
        const fn = this.opt?.onMenuShowingOrHidding;
        if (fn) {
            const c = this.contextWhileMenuVisible;
            if (showing) {
                if (c) {
                    fn(false, c.item, c.context);
                }
                fn(true, item, context);
            } else if (c) {
                fn(false, c.item, c.context);
            }
        }
        this.contextWhileMenuVisible = showing ? { item, context } : null;
    }
}

export interface IBurgerMenuManagerOptions<TItem, TContext>
    extends IBurgerMenuHoverManagerOptions {
    showHideBurgerIcon?: (
        show: boolean,
        item: TItem,
        context?: TContext,
    ) => void;
    getMenuOffset?: (icon: Element) => { left: number; top: number };
    getContext?: (item: TItem) => TContext | Promise<TContext>;
    onItemInOut?: (isIn: boolean, item: TItem) => void;
    burgerMenuOptions?: IListOption[];
    onMenuShowingOrHidding?: (
        showing: boolean,
        item?: TItem,
        context?: TContext,
    ) => void;
    showMenuDelayMs?: number;
}
