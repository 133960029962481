import { CollectionsHelper, CoreUtil } from '@datagalaxy/core-util';

/** ## Role
 * Helper to manage the selection of a value given a selection of objects.
 * Behaviour:
 * - When selection contains one object, the current value is the object's value
 * - When selection contains multiple objects, and their distinct values are multiple, the current value is the default value
 */
export class ValueSelectionHelper<TObj, TVal> {
    public onValueSelected: (v: TVal) => Promise<void>;
    public get currentValue() {
        return this._currentValue;
    }
    public get availableValues() {
        return this._availableValues;
    }
    /** true when more than one distinct extracted values from selection */
    public get isMulti() {
        return this.selectionValues.length > 1;
    }

    private selection: TObj[];
    private selectionValues: TVal[];
    private _currentValue: TVal;
    private firstObj: TObj;
    private _availableValues: TVal[];

    constructor(private opt: ISelectionManagerOptions<TObj, TVal>) {
        this.init(opt.selection, opt.availableValues);
    }

    /** this call is not needed if *selection* and *availableValues* were passed to the constructor */
    public init(selection: TObj[], availableValues: TVal[]) {
        this.selection = selection ?? [];
        this._availableValues = availableValues ?? [];
        this.firstObj = this.selection[0];
        this.getCurrentValue();
        this.onValueSelected = async (v) =>
            await this.onValueSelectedInternal(v);
        //this.log('init', this)
    }

    public isCurrent(value: TVal, andIsMultiIs?: boolean) {
        const isCurrentValue = this.currentValue === value;
        if (andIsMultiIs == undefined) {
            return isCurrentValue;
        }
        return isCurrentValue && this.isMulti == !!andIsMultiIs;
    }

    public setCurrent(value: TVal) {
        this._currentValue = value;
    }

    private getSelectionValues() {
        this.selectionValues = CollectionsHelper.distinctValues(
            this.selection,
            this.opt.getValue,
        );
    }
    private getCurrentValue() {
        this.getSelectionValues();
        const isMulti = this.isMulti;
        const firstValue = this.opt.getValue(this.firstObj);
        const v = isMulti ? undefined : firstValue;
        if (this.opt.getDefaultValue) {
            this._currentValue = this.opt.getDefaultValue(
                v,
                isMulti,
                firstValue,
                this.selectionValues,
            );
        } else {
            this._currentValue = v ?? this.opt.defaultValue;
        }
        //if(this.opt.debug) { this.log('getCurrentValue', this._currentValue, { isMulti, firstValue, v }) }
    }
    private async onValueSelectedInternal(selectedValue: TVal) {
        if (
            false ===
            (await this.opt.withSelectedValue(
                selectedValue,
                this.firstObj,
                this.selection,
            ))
        ) {
            this.log('onValueSelected-abort');
            return;
        }

        this.getSelectionValues();
        if (this.isMulti) {
            this.log('onValueSelected-isMulti');
            return;
        }

        this._currentValue = this.opt.adaptSelectedValue
            ? this.opt.adaptSelectedValue(selectedValue)
            : selectedValue;
        this.log('onValueSelected-result', this._currentValue);
    }

    private log(...args: unknown[]) {
        if (!this.opt.debug || CoreUtil.isProduction) {
            return;
        }
        console.log(this.constructor.name, this.opt.logId ?? '', ...args);
    }
}

interface ISelectionManagerOptions<TObj, TVal> {
    /** can also be passed at init */
    selection?: TObj[];
    /** can also be passed at init */
    availableValues?: TVal[];
    getValue(e: TObj): TVal;
    defaultValue?: TVal;
    getDefaultValue?: (
        defaultValue: TVal,
        isMulti: boolean,
        firstValue: TVal,
        selectionValues: TVal[],
    ) => TVal;
    adaptSelectedValue?: (value: TVal) => TVal;
    /** if exactly *false* is returned, then the selected value does not become the current value */
    withSelectedValue(
        selectedValue: TVal,
        firstObj: TObj,
        selection: TObj[],
    ): void | boolean | Promise<void | boolean>;
    debug?: boolean;
    logId?: string;
}
