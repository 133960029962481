import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ComponentRef,
    ElementRef,
    EventEmitter,
    HostBinding,
    inject,
    Input,
    Output,
    Type,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import { AttributeFieldInfo } from '../attribute.types';
import { DxyAttributeEntityTypeInputComponent } from '../inputs/text/dxy-attribute-entity-type-input/dxy-attribute-entity-type-input.component';
import { DxyAttributeDataTypeSizeInputComponent } from '../inputs/dxy-attribute-data-type-size-input/dxy-attribute-data-type-size-input.component';
import { DxyAttributeDateInputComponent } from '../inputs/dxy-attribute-date-input/dxy-attribute-date-input.component';
import { DxyAttributeBooleanInputComponent } from '../inputs/dxy-attribute-boolean-input/dxy-attribute-boolean-input.component';
import { DxyAttributeLogicalPathInputComponent } from '../inputs/dxy-attribute-logical-path-input/dxy-attribute-logical-path-input.component';
import { DxyAttributeTimeSeriesInputComponent } from '../inputs/dxy-attribute-time-series-input/dxy-attribute-time-series-input.component';
import { DxyAttributeBaseInput } from '../inputs/DxyAttributeBaseInput';
import { DxyAttributeLinkShortcutInputComponent } from '../inputs/entityselector/dxy-attribute-link-shortcut-input/dxy-attribute-link-shortcut-input.component';
import { DxyAttributeLogicalParentInputComponent } from '../inputs/entityselector/dxy-attribute-logical-parent-input/dxy-attribute-logical-parent-input.component';
import { DxyAttributeMultiselectInputComponent } from '../inputs/dxy-attribute-multi-select-input/dxy-attribute-multi-select-input.component';
import { DxyAttributeReferenceListInputComponent } from '../inputs/select/dxy-attribute-reference-list-input/dxy-attribute-reference-list-input.component';
import { DxyAttributeValueListInputComponent } from '../inputs/select/dxy-attribute-value-list-input/dxy-attribute-value-list-input.component';
import { DxyAttributeFormattedTextInputComponent } from '../inputs/text/dxy-attribute-formatted-text-input/dxy-attribute-formatted-text-input.component';
import { DxyAttributeHyperlinkInputComponent } from '../inputs/text/dxy-attribute-hyperlink-input/dxy-attribute-hyperlink-input.component';
import { DxyAttributeMultilineTextInputComponent } from '../inputs/text/dxy-attribute-multiline-text-input/dxy-attribute-multiline-text-input.component';
import { DxyAttributeNumberInputComponent } from '../inputs/text/dxy-attribute-number-input/dxy-attribute-number-input.component';
import { DxyAttributeTextInputComponent } from '../inputs/text/dxy-attribute-text-input/dxy-attribute-text-input.component';
import { DxyAttributeTechnologyInputComponent } from '../inputs/select/dxy-attribute-technology-input/dxy-attribute-technology-input.component';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { AttributeValueTranslationService } from '@datagalaxy/webclient/multilingual/feature';
import { DxyDataTestIdDirective } from '@datagalaxy/ui/testing';
import {
    AttributeMetaInfo,
    AttributeMetaType,
} from '@datagalaxy/webclient/attribute/domain';
import { FeatureFlagService } from '@datagalaxy/webclient/feature-flag';
import {
    AttributeFieldComponent,
    DescriptionFieldComponent,
    RichTextAttributeFieldComponent,
    SummaryFieldComponent,
    TextAttributeFieldComponent,
} from '@datagalaxy/webclient/attribute/fields';
import { CoreUtil } from '@datagalaxy/core-util';

type AttributeFieldType =
    | DxyAttributeBaseInput<unknown>
    | AttributeFieldComponent;

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'dxy-entity-attribute-input',
    template: '<ng-container #container></ng-container>',
    standalone: true,
})
export class DxyEntityAttributeInputComponent
    extends DxyBaseComponent
    implements AfterViewInit
{
    private changeDetector = inject(ChangeDetectorRef);
    private attributeValueTranslationService = inject(
        AttributeValueTranslationService,
    );
    private featureFlagService = inject(FeatureFlagService);

    //#region static
    private static getLegacyAttributeInputComponentType(
        ami: AttributeMetaInfo,
    ): Type<DxyAttributeBaseInput<unknown>> {
        switch (ami?.AttributeType) {
            case AttributeMetaType.SystemEntityType:
                return DxyAttributeEntityTypeInputComponent;
            case AttributeMetaType.Text:
            case AttributeMetaType.EntityLogicalPathString:
                return DxyAttributeTextInputComponent;
            case AttributeMetaType.MultiLineText:
                return DxyAttributeMultilineTextInputComponent;
            case AttributeMetaType.FormattedText:
                return DxyAttributeFormattedTextInputComponent;
            case AttributeMetaType.Number:
                return DxyAttributeNumberInputComponent;
            case AttributeMetaType.Date:
                return DxyAttributeDateInputComponent;
            case AttributeMetaType.DateTime:
                return DxyAttributeDateInputComponent;
            case AttributeMetaType.Boolean:
                return DxyAttributeBooleanInputComponent;
            case AttributeMetaType.ValueList:
                return DxyAttributeValueListInputComponent;
            case AttributeMetaType.Technology:
                return DxyAttributeTechnologyInputComponent;
            case AttributeMetaType.HtmlLink:
                return DxyAttributeHyperlinkInputComponent;

            case AttributeMetaType.Reference:
                return DxyAttributeReferenceListInputComponent;
            case AttributeMetaType.DataTypeAndSize:
                return DxyAttributeDataTypeSizeInputComponent;

            case AttributeMetaType.ClientTag:
            case AttributeMetaType.ReferenceList:
            case AttributeMetaType.ManagedTag:
            case AttributeMetaType.MultiValueList:
            case AttributeMetaType.StewardUserReference:
            case AttributeMetaType.UserReference:
            case AttributeMetaType.PersonReference:
            case AttributeMetaType.Hierarchy:
                return DxyAttributeMultiselectInputComponent;

            case AttributeMetaType.EntityLogicalParent:
                return DxyAttributeLogicalParentInputComponent;
            case AttributeMetaType.EntityLogicalPath:
                return DxyAttributeLogicalPathInputComponent;
            case AttributeMetaType.EntityLinkShortcut:
                return DxyAttributeLinkShortcutInputComponent;
            case AttributeMetaType.TimeSeriesObject:
                return DxyAttributeTimeSeriesInputComponent;

            default:
                return null;
        }
    }

    static getNewAttributeInputComponentType(
        ami: AttributeMetaInfo,
    ): Type<AttributeFieldType> {
        if (ami.AttributeKey === 'LongDescription') {
            return DescriptionFieldComponent;
        }
        if (ami.AttributeKey === 'Description') {
            return SummaryFieldComponent;
        }
        switch (ami?.AttributeType) {
            case AttributeMetaType.Text:
            case AttributeMetaType.MultiLineText:
                return TextAttributeFieldComponent;
            case AttributeMetaType.FormattedText:
                return RichTextAttributeFieldComponent;
        }

        return DxyEntityAttributeInputComponent.getLegacyAttributeInputComponentType(
            ami,
        );
    }

    //#endregion

    @Input() fieldInfo: AttributeFieldInfo;
    @Input() enablePopover?: boolean;
    @Input() @HostBinding('class.mini') mini = false;
    /** Use translation input when attribute is enabled in the multilingual configuration */
    @Input() translatable = false;

    /** Emitted when a menu is opened or closed. The argument is true on open. */
    @Output() readonly onPanelOpenClose = new EventEmitter<boolean>();

    // Note: we use a ng-container instead of getting the ViewContainerRef from the constructor,
    // so the inner component is created inside this component instead of after it in the html.
    @ViewChild('container', { read: ViewContainerRef })
    private viewContainerRef: ViewContainerRef;

    private component: ComponentRef<AttributeFieldType>;

    constructor() {
        super();
    }

    ngAfterViewInit() {
        this.initInnerComponent();
    }

    //#region API
    public focusField() {
        this.component?.instance.focusField();
    }
    //#endregion

    private initInnerComponent() {
        const fieldInfo = this.fieldInfo;
        if (!fieldInfo) {
            return;
        }
        this.log('initInnerComponent');

        const entity = fieldInfo.entityForm?.getEntityData?.();

        const translatable =
            this.attributeValueTranslationService.displayTranslatedValues(
                fieldInfo?.attributeMeta?.AttributePath,
            ) &&
            entity &&
            this.translatable;
        const shouldUseNewInputs =
            translatable ||
            this.featureFlagService.isFeatureEnabled('autodescription');

        const componentType = shouldUseNewInputs
            ? DxyEntityAttributeInputComponent.getNewAttributeInputComponentType(
                  fieldInfo.attributeMeta,
              )
            : DxyEntityAttributeInputComponent.getLegacyAttributeInputComponentType(
                  fieldInfo.attributeMeta,
              );
        const component =
            componentType &&
            this.viewContainerRef.createComponent<AttributeFieldType>(
                componentType,
            );

        const instance = component?.instance;
        if (!instance) {
            return;
        }

        this.setE2eId(component.location, fieldInfo);

        if (instance instanceof DxyAttributeBaseInput) {
            instance.mini = this.mini;
            instance.fieldInfo = fieldInfo;
            instance.enablePopover = this.enablePopover;
            super.subscribe(instance.onPanelOpenClose, (isOpen) =>
                this.onPanelOpenClose.emit(isOpen),
            );
        } else {
            if (instance.mini) {
                component.setInput('mini', this.mini);
            }
            if (instance.attributeMetaInfo) {
                component.setInput(
                    'attributeMetaInfo',
                    fieldInfo.attributeMeta,
                );
            }
            component.setInput('entity', entity);
            if (
                !fieldInfo.entityForm.isAttributeEditEnabled(
                    fieldInfo.attributeMeta,
                )
            ) {
                component.setInput('readonly', true);
            }
            // Hack for reactivity - as new fields does not use the entityForm to update the entity
            this.subscribe(fieldInfo.entityForm.formEntityUpdated$, (value) => {
                if (
                    value.attribute.AttributeKey !==
                    fieldInfo.attributeMeta.AttributeKey
                ) {
                    return;
                }
                entity.setAttributeValue(
                    value.attribute.AttributeKey,
                    value.newValue,
                );

                (component.instance as AttributeFieldComponent).store.setEntity(
                    CoreUtil.clone(entity),
                );
                this.changeDetector.detectChanges();
            });
        }

        this.component = component;

        // prevents NG0100 error
        this.changeDetector.detectChanges();
    }
    private setE2eId(
        elementRef: ElementRef<HTMLElement>,
        fieldInfo: AttributeFieldInfo,
    ) {
        if (!elementRef?.nativeElement || !fieldInfo) {
            return;
        }
        DxyDataTestIdDirective.setE2EId(
            elementRef.nativeElement,
            fieldInfo.attributeMeta.AttributePath,
        );
    }
}
