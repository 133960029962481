import { LogFn } from '@datagalaxy/core-util';
import { IFilterFormAttributes } from '../../shared/attribute/attribute-filter/attribute-filter-form/IFilterFormModel';
import { CoreUtil } from '@datagalaxy/core-util';
import { BaseAttributeFilterFormModel } from '../../shared/attribute/attribute-filter/attribute-filter-form/BaseAttributeFilterFormModel';
import { AttributeDataService } from '../../shared/attribute/attribute-data.service';
import { WorkspaceIdentifier } from '@datagalaxy/webclient/workspace/utils';
import { IWorkspaceIdentifier } from '@datagalaxy/webclient/workspace/domain';
import { DgZone } from '@datagalaxy/webclient/domain';
import { AttributeMetaInfo } from '@datagalaxy/webclient/attribute/domain';

export class CurrentSearch extends BaseAttributeFilterFormModel {
    public sortKey: string;
    public readonly dgZone = DgZone.Search;
    //#endregion

    public get isAllSpaces() {
        return !this.spaceIdr?.spaceId;
    }

    /**
     * Search is considered empty if there is no valid filter items
     * and if the searchTerm filter is empty
     */
    public get isSearchEmpty(): boolean {
        return !this.computeFilters()?.length;
    }

    constructor(
        formAttributes: IFilterFormAttributes,
        public spaceIdr: IWorkspaceIdentifier,
        public log?: LogFn,
    ) {
        super(
            DgZone.Search,
            WorkspaceIdentifier.fromOrClientSpace(spaceIdr),
            formAttributes,
            log,
        );
        this.initFilterItems();
        this.setupSourceAttributes();
    }

    public setSpaceAndVersion(spaceIdr: IWorkspaceIdentifier) {
        this.spaceIdr = WorkspaceIdentifier.fromOrClientSpace(spaceIdr);
        this.adaptToSpaceSelection();
    }

    public clone() {
        return CoreUtil.cloneDeep(this);
    }

    public clearFilters() {
        this.filterItems.length = 0;
        this.initFilterItems();
        this.onFilterItemsChanged();
    }

    public clearSearchTerm() {
        this.searchTerm = '';
    }

    public clear(keepSpaceAndVersion?: boolean) {
        this.clearFilters();
        if (keepSpaceAndVersion) {
            return;
        }
        this.setSpaceAndVersion(null);
    }

    protected setupAdditionalFilters() {}

    /**
     * Clear filterItems then set searchTermFilter
     */
    private initFilterItems() {
        const searchTermFilter = this.getSearchTermFilterItem(this);
        this.filterItems.length = 0;
        this.filterItems.push(searchTermFilter);
    }

    private adaptToSpaceSelection() {
        this.log('adaptToSpaceSelection');
        let filter: TAMIPredicate;
        if (this.isAllSpaces) {
            filter = (ami) =>
                !AttributeDataService.isSystemDataTypeRefAttribute(ami); // modeler column data type
        }
        this.setupSourceAttributes(filter);
        if (filter) {
            this.removeFilterItems(
                this.filterItems,
                (afm) => !filter(afm.attributeMeta),
            );
        }
    }

    /**
     * Build sourceAttributes from allAttributes by using filter predicate
     * Also filterForm entityTypeAttribute is added to replace any EntityType from allAttributes
     */
    private setupSourceAttributes(filter?: TAMIPredicate) {
        const typeFilter: TAMIPredicate = (ami) => ami.AttributeKey != 'Type';
        const predicate: TAMIPredicate = filter
            ? (ami) => typeFilter(ami) && filter(ami)
            : typeFilter;
        const filteredAttributes = this.allAttributes?.filter(predicate) ?? [];
        this.sourceAttributes = [
            this.entityTypeAttribute,
            ...filteredAttributes,
        ];
    }
}

type TAMIPredicate = (ami: AttributeMetaInfo) => boolean;
