import {
    Component,
    HostBinding,
    Input,
    OnInit,
    ViewChild,
    forwardRef,
} from '@angular/core';
import {
    MatLegacyMenuTrigger as MatMenuTrigger,
    LegacyMenuPositionX as MenuPositionX,
    LegacyMenuPositionY as MenuPositionY,
    MatLegacyMenuModule,
} from '@angular/material/legacy-menu';
import { CoreUtil } from '@datagalaxy/core-util';
import { TranslateService } from '@ngx-translate/core';
import {
    CardinalPlacement,
    CardinalUtil,
    DirectionTRBL,
} from '@datagalaxy/core-2d-util';
import { BaseOptionListComponent } from '../../base';
import { IGraphicalToolbarOption } from '../graphical.types';
import { DxyRendererComponent } from '../../components/renderer/renderer.component';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';
import { DxyDataTestIdDirective } from '@datagalaxy/ui/testing';
import { DxyMultiselectListComponent } from '../../components/multiselect-list/multiselect-list.component';
import { NgIf, NgTemplateOutlet, NgClass, NgFor } from '@angular/common';
import { DxyIconButtonDirective } from '@datagalaxy/ui/buttons';

/**
 * ## Role
 * Horizontal or vertical toolbar (list of options and sub-options)
 */
@Component({
    selector: 'dxy-graphical-toolbar',
    templateUrl: 'graphical-toolbar.component.html',
    standalone: true,
    imports: [
        NgIf,
        NgTemplateOutlet,
        DxyIconButtonDirective,
        MatLegacyMenuModule,
        NgClass,
        DxyMultiselectListComponent,
        NgFor,
        DxyDataTestIdDirective,
        forwardRef(() => DxyGraphicalToolbarComponent),
        MatLegacyTooltipModule,
        DxyRendererComponent,
    ],
})
export class DxyGraphicalToolbarComponent
    extends BaseOptionListComponent
    implements OnInit
{
    @Input() options: IGraphicalToolbarOption[];

    /** (optional) Pre-defined position using cardinal points, and h(horizontal) or v(vertical) for corners */
    @Input() placement?: CardinalPlacement;

    /** (overwritten if *placement* is defined, else required if sub-options)
     * Side for sub-menu deployment: To the...
     * - 'left' or 'right' for a vertical toolbar,
     * - 'top' or 'bottom' for an horizontal toolbar */
    @Input() menuSide: DirectionTRBL = 'bottom';

    /** (overwritten if *menuSide* or *placement* are defined)
     * - 'v' for vertical
     * - 'h' for horizontal */
    @Input() orientation: 'h' | 'v';

    /** for internal usage */
    @Input() _childOption: IGraphicalToolbarOption;

    /** external class set by client component - is also set on any sub toolbar or mat-menu */
    @Input() class: string;

    /** set to true if the toolbar is contained in an element having the *graphical-toolbar-root* class */
    @Input() isInToolbarRoot: boolean;

    public get isBurgerMenu() {
        return this._childOption?.isBurgerMenu;
    }
    public get isInBurgerMenu() {
        return (
            this._parent instanceof DxyGraphicalToolbarComponent &&
            (this._parent.isBurgerMenu || this._parent.isInBurgerMenu)
        );
    }
    public get isHorizontal() {
        return (
            this.orientation == 'h' &&
            !this.isBurgerMenu &&
            !this.isInBurgerMenu
        );
    }
    public get isVertical() {
        return (
            this.orientation == 'v' || this.isBurgerMenu || this.isInBurgerMenu
        );
    }
    public get menuSideClass() {
        return this.menuSide ? `menu-side-${this.menuSide}` : '';
    }
    public get matMenuClass() {
        return `dxy-graphical-toolbar--mat-menu ${
            this.class ? this.class + ' ' : ''
        }${this.menuSideClass}${this.isBurgerMenu ? ' no-max-width' : ''}`;
    }
    public get xPosition(): MenuPositionX {
        return this.menuSide == 'left' ? 'before' : 'after';
    }
    public get yPosition(): MenuPositionY {
        return this.menuSide == 'top' ? 'above' : 'below';
    }
    public get matTooltipPosition() {
        return super.getMatTooltipPosition(
            this.tooltipsPlacement ?? this.menuSide,
        );
    }

    @ViewChild('menuTrigger') protected menuTrigger: MatMenuTrigger;

    private placementClass: string;
    @HostBinding('class.graphical-toolbar-root')
    private get hostClassGraphicalToolbarRoot() {
        return !this.isInToolbarRoot;
    }
    @HostBinding('class.vertical') private get hostClassVertical() {
        return this.isVertical;
    }
    @HostBinding('class.horizontal') private get hostClassHorizontal() {
        return this.isHorizontal;
    }
    @HostBinding('class') private get hostClass() {
        return `${this.class ?? ''} ${this.placementClass}`;
    }

    constructor(translate: TranslateService) {
        super(translate);
    }

    ngOnInit() {
        const info = CardinalUtil.getPlacementInfo(this.placement);
        this.menuSide = info?.dir ?? this.menuSide;
        this.orientation = this.menuSide
            ? this.menuSide == 'left' || this.menuSide == 'right'
                ? 'v'
                : 'h'
            : this.orientation;
        this.placementClass = !info ? '' : `pos-${info.pos}`;
    }

    public getOptionId(_index: number, option: IGraphicalToolbarOption) {
        return option.id;
    }

    public isSubMenu(option: IGraphicalToolbarOption) {
        return this.isMultiSelect(option) || super.isDropDown(option);
    }
    public isMultiSelect(option: IGraphicalToolbarOption) {
        return !!option.getMultiSelectData;
    }
    public getMultiSelectData(option: IGraphicalToolbarOption) {
        return option.getMultiSelectData();
    }
    public isCurrentActive(option: IGraphicalToolbarOption) {
        return CoreUtil.fromFnOrValue(option.isCurrentActive);
    }
    public isButtonDisabled(option: IGraphicalToolbarOption) {
        return this.isCurrentActive(option) || super.isDisabled(option);
    }

    public onMenuTriggerClick(event: Event) {
        // prevent parent mat-menu to close when opening sub-menu
        if (this._parent?.isMenuOpen) {
            event.stopPropagation();
        }
    }

    public onMenuOpenedClosed(isOpen: boolean) {
        if (isOpen) {
            this.setupMenuPanelOverlayClick();
        }
        super.onMenuOpenedClosed(isOpen);
    }

    // detect click on panel's overlay, (because we added margins to the mat-menu-panel)
    private setupMenuPanelOverlayClick() {
        // the overlay is created on each menu opening
        const panelOverlay = document.getElementById(
            this.menuTrigger.menu.panelId,
        ).parentElement;
        panelOverlay.addEventListener('click', () =>
            this.menuTrigger.closeMenu(),
        );
    }
}
