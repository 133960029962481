import { Injectable } from '@angular/core';
import {
    EntityAttributeUpdatedEvent,
    ServerType,
} from '@datagalaxy/dg-object-model';
import { TranslateService } from '@ngx-translate/core';
import { StringUtil } from '@datagalaxy/core-util';
import { AttributeDataService } from '../../shared/attribute/attribute-data.service';
import { IDgEventInterpolationProvider } from './dg-event-interpolation-provider';
import {
    AttributeMetaInfo,
    AttributeMetaType,
} from '@datagalaxy/webclient/attribute/domain';

@Injectable({ providedIn: 'root' })
export class EntityAttributeUpdatedInterpolationProvider
    implements IDgEventInterpolationProvider
{
    public static eventTypes = [EntityAttributeUpdatedEvent];

    constructor(
        private attributeDataService: AttributeDataService,
        private translate: TranslateService,
    ) {}

    public async resolveParameters(typedEvent: EntityAttributeUpdatedEvent) {
        const serverType = typedEvent.EntityHdd.ServerType;
        const serverTypeName = ServerType[serverType];
        if (!serverTypeName) {
            return {};
        }
        await this.attributeDataService.loadAttributes([serverTypeName]);
        const attribute = this.attributeDataService.getAttribute(
            serverType,
            typedEvent.attributeKey,
        );
        return this.getValuesTranslateParams(typedEvent, attribute);
    }

    private getValuesTranslateParams(
        event: EntityAttributeUpdatedEvent,
        attribute: AttributeMetaInfo,
    ) {
        const value = this.getAttributeValueTranslation(attribute, event.Value);
        const previousValue = this.getAttributeValueTranslation(
            attribute,
            event.PreviousValue,
        );
        const withValue = !StringUtil.isNullOrEmpty(value);
        return {
            value,
            previousValue,
            withValue,
            withPreviousValue:
                withValue && !StringUtil.isNullOrEmpty(previousValue),
        };
    }

    private getAttributeValueTranslation(
        attribute: AttributeMetaInfo,
        value: string,
    ): string {
        if (value == null) {
            return undefined;
        }
        switch (attribute.AttributeType) {
            case AttributeMetaType.Text:
                return value.length ? value : undefined;
            case AttributeMetaType.Boolean: {
                if (value == null) {
                    return null;
                }
                const translateKey = `UI.ActivityLogData.BooleanPropertyValues.${value.toLowerCase()}`;
                return this.translate.instant(translateKey);
            }

            case AttributeMetaType.ValueList:
                if (attribute.IsCdp) {
                    return attribute.ListValues?.find((v) => v.Value == value)
                        ?.Key;
                }
                return this.attributeDataService.getSystemAttributeValueListOptionDisplayName(
                    attribute.ListTypeName,
                    value,
                );

            default:
                return undefined;
        }
    }
}
