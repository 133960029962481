import { Injectable } from '@angular/core';
import { ModuleService } from './module.service';
import {
    DgModule,
    DgModuleDefinition,
    DgModuleName,
} from '@datagalaxy/shared/dg-module/domain';
import { EntityEventService } from '../shared/entity/services/entity-event.service';
import { getLocalId } from '@datagalaxy/utils';
import { WorkspaceIdentifier } from '@datagalaxy/webclient/workspace/utils';
import { BaseStateService } from '@datagalaxy/utils';
import { ModuleAccessInfo } from '@datagalaxy/webclient/security/data-access';
import { ModuleSignalrService } from './module-signalr/module-signalr.service';
import { WorkspaceStore } from '@datagalaxy/webclient/workspace/data-access';
import { ModelerDataUtil } from '../shared/util/ModelerDataUtil';

@Injectable({ providedIn: 'root' })
export class ModuleStore extends BaseStateService<ModuleStoreState> {
    public get modules(): DgModuleDefinition[] {
        return this.state.modules.map((m) => m.definition);
    }

    public get module(): DgModuleDefinition | undefined {
        return this.state.modules.find(
            (m) => m.definition.name === this.state.currentModule,
        )?.definition;
    }

    public get dgModule(): DgModule | undefined {
        const module = this.module;
        return module
            ? DgModule[this.state.currentModule as DgModuleName]
            : undefined;
    }

    constructor(
        private moduleService: ModuleService,
        private entityEventService: EntityEventService,
        private moduleSignalrService: ModuleSignalrService,
        private workspaceStore: WorkspaceStore,
    ) {
        super({
            modules: [],
            loading: false,
        });

        // Subscribe to entity creation events to load accessible modules
        // a space admin, can always create a new dictionary, so if he does not have
        // access to dictionaries, we need to reload the modules (should be managed
        // with a realtime event from backend but not for the moment)
        this.entityEventService.subscribeEntityCreation(null, (event) => {
            if (ModelerDataUtil.isModelerServerType(event.entity.ServerType)) {
                const spaceId = getLocalId(
                    WorkspaceIdentifier.fromEntity(event.entity).spaceId,
                );
                this.loadAccessibleModules(spaceId, event.entity.VersionId);
            }
        });

        this.moduleSignalrService.updatedModule$.subscribe((event) => {
            void this.handleRightAccessChanges(
                event.WorkspaceId,
                event.VersionId,
            );
        });

        this.moduleSignalrService.updatedSource$.subscribe((event) => {
            void this.handleRightAccessChanges(
                event.WorkspaceId,
                event.VersionId,
            );
        });

        this.workspaceStore.selectCurrentSpace().subscribe((space) => {
            if (space) {
                this.loadAccessibleModules(space.spaceId, space.versionId);
            }
        });
    }

    public setActiveView(view: 'list' | 'grid' | 'datamap') {
        this.setState({ activeView: view });
    }

    public setCurrentModule(module: DgModuleName) {
        this.setState({ currentModule: module });
    }

    public selectModules() {
        return this.select((state) => state.modules.map((m) => m.definition));
    }

    public selectActiveView() {
        return this.select((state) => state.activeView);
    }

    public selectModule() {
        return this.select((state) =>
            state.modules.find(
                (m) => m.definition.name === state.currentModule,
            ),
        );
    }

    public selectDgModule() {
        return this.select((state) => {
            const module = state.modules.find(
                (m) => m.definition.name === state.currentModule,
            );
            return module ? DgModule[module.definition.name] : undefined;
        });
    }

    public loadAccessibleModules(spaceId: string, versionId: string) {
        this.setState({ loading: true });
        void this.fetchModules(spaceId, versionId);
    }

    public hasAccess(module: DgModule) {
        return this.modules.some((m) => DgModule[m.name] === module);
    }

    private async fetchModules(spaceId: string, versionId: string) {
        const result =
            await this.moduleService.loadCurrentUserAccessibleModules(
                spaceId,
                versionId,
            );
        this.setState({ modules: result, loading: false });
    }

    private handleRightAccessChanges(workspaceId: string, versionId: string) {
        const space = this.workspaceStore.currentSpace;
        if (getLocalId(space?.spaceId) !== workspaceId) {
            return;
        }
        this.loadAccessibleModules(workspaceId, versionId);
    }
}

export interface ModuleInfo {
    access: ModuleAccessInfo;
    definition: DgModuleDefinition;
}

export interface ModuleStoreState {
    modules: ModuleInfo[];
    loading: boolean;
    currentModule?: DgModuleName;
    activeView?: 'list' | 'grid' | 'datamap';
}
