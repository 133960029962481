import {
    Component,
    ElementRef,
    Input,
    NgZone,
    Optional,
    Self,
    ViewChild,
} from '@angular/core';
import { NgControl, FormsModule } from '@angular/forms';
import {
    MatLegacyInput as MatInput,
    MatLegacyInputModule,
} from '@angular/material/legacy-input';
import { TranslateService } from '@ngx-translate/core';
import { DxyBaseFocusableFieldComponent } from '@datagalaxy/ui/fields';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { EllipsisTooltipDirective } from '@datagalaxy/ui/tooltip';
import { NgIf, NgClass } from '@angular/common';
import { DxyIconButtonDirective } from '@datagalaxy/ui/buttons';

@Component({
    selector: 'dxy-field-password',
    templateUrl: 'field-password.component.html',
    standalone: true,
    imports: [
        NgIf,
        EllipsisTooltipDirective,
        MatLegacyFormFieldModule,
        MatLegacyTooltipModule,
        MatLegacyInputModule,
        FormsModule,
        NgClass,
        DxyIconButtonDirective,
    ],
})
export class DxyFieldPasswordComponent extends DxyBaseFocusableFieldComponent<string> {
    /** label tooltip for informations */
    @Input() infoTooltip: string;
    @Input() placeholder: string;
    @Input() passwordMaxLength: number;
    @Input() hideMaxLengthHint: boolean;
    /** returns an empty string if the password meets security criteria, else the error message */
    @Input() checkPasswordStrength: (password: string) => string;
    /**
     * Cf: https://developer.mozilla.org/fr/docs/Web/HTML/Attributes/autocomplete
     * The default autocomplete value should be 'off', but it doesnt seem to work correctly
     * Using 'new-password' solve this issue, the browser will not try to autocomplete
     */
    @Input() browserAutocomplete = 'new-password';

    public typeInput = 'password';
    public iconClass = 'glyph glyph-hide';
    public get label() {
        return this.getLabel(this.translate);
    }
    public get labelTooltip() {
        return this.getLabelTooltip(this.translate);
    }
    public get errorMessage() {
        return this.getErrorMessage(this.translate);
    }
    public get showMaxLengthHint() {
        return (
            !!this.passwordMaxLengthValue &&
            this.fieldControl?.focused &&
            !this.hideMaxLengthHint
        );
    }
    protected get passwordMaxLengthValue() {
        if (!this.passwordMaxLength) {
            return 125;
        }
        return this.passwordMaxLength;
    }

    public get readOnlyPassword() {
        const passwordLength = this.value ? this.value.length + 1 : 0;
        return new Array(passwordLength).join('*');
    }

    @ViewChild('fieldControl') protected fieldControl: MatInput;

    private passwordVisible = false;

    constructor(
        private translate: TranslateService,
        elementRef: ElementRef<HTMLElement>,
        ngZone: NgZone,
        @Optional() @Self() ngControl: NgControl,
    ) {
        super(elementRef, ngZone, ngControl);
    }

    public onInputChange(password: string) {
        this.errorMessageText = this.checkPasswordStrength?.(password);
    }

    public togglePasswordVisibility() {
        this.passwordVisible = !this.passwordVisible;
        this.toggleIconClass();
        this.typeInput = this.passwordVisible ? 'text' : 'password';
    }

    public toggleIconClass() {
        this.iconClass = this.iconClass.includes('glyph-show')
            ? 'glyph-hide'
            : 'glyph-show';
    }
}
