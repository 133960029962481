import { CoreUtil } from '@datagalaxy/core-util';
import { CollectionsHelper } from '@datagalaxy/core-util';
import { DomUtil } from '@datagalaxy/core-util';
import {
    Component,
    ElementRef,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import { CommentaryService } from '../commentary.service';
import { EntityCommentaryDTO } from '@datagalaxy/dg-object-model';
import { EntityEventService } from '../../shared/entity/services/entity-event.service';
import { DeleteEntityCommentsParameter } from '@datagalaxy/webclient/comment/data-access';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { IMiniEntityContent } from '@datagalaxy/webclient/entity/domain';
import { DxyCommentaryItemComponent } from '../dxy-commentary-item/dxy-commentary-item.component';
import { SpinnerComponent } from '@datagalaxy/ui/spinner';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { NgIf, NgFor } from '@angular/common';
import {
    DxyButtonDirective,
    DxyIconButtonDirective,
} from '@datagalaxy/ui/buttons';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'dxy-commentary-container',
    templateUrl: 'dxy-commentary-container.component.html',
    styleUrls: ['dxy-commentary-container.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        TranslateModule,
        MatLegacyTooltipModule,
        SpinnerComponent,
        NgFor,
        DxyCommentaryItemComponent,
        DxyButtonDirective,
        DxyIconButtonDirective,
    ],
})
export class DxyCommentaryContainerComponent
    extends DxyBaseComponent
    implements OnChanges, OnInit
{
    @Input() entityData: IMiniEntityContent;
    @Input() showHeader: boolean;
    @Input() readOnly: boolean;

    public commentaries: EntityCommentaryDTO[] = [];
    public isLoading = false;

    private currentEditedCommentaryId: string;
    private oldCommentariesNumber = 0;

    constructor(
        private elementRef: ElementRef<HTMLElement>,
        private commentaryService: CommentaryService,
        private entityEventService: EntityEventService,
    ) {
        super();
    }

    ngOnChanges(changes: SimpleChanges) {
        super.onChange(changes, 'entityData', () => this.updateData());
    }

    ngOnInit() {
        this.subscribeEvents();

        // Handle scroll event
        //TODO
        // DomUtil.getElement(this.elementRef, '.listContainer')?.scroll(() => {
        //         if (!this.isScrollbarVisible()) { return }
        //         this.updateOldInvisibleCommentariesNumber()
        //     })

        this.updateData();
    }
    public getCommentaryId(index: number, commentary: EntityCommentaryDTO) {
        return commentary.ReferenceId;
    }
    public setCurrentEditedCommentary(commentary: EntityCommentaryDTO) {
        if (this.readOnly) {
            return;
        }
        this.currentEditedCommentaryId = commentary.ReferenceId;
        setTimeout(
            () => this.scrollToActiveElement(commentary.ReferenceId),
            50,
        );
    }

    public isCurrentEditedCommentary(commentary: EntityCommentaryDTO) {
        return commentary.ReferenceId === this.currentEditedCommentaryId;
    }

    public initCurrentEditedCommentary() {
        this.currentEditedCommentaryId = null;
    }

    public async deleteCommentary(commentary: EntityCommentaryDTO) {
        if (this.readOnly || !this.entityData?.SocialData) {
            return;
        }

        const deleted = await this.commentaryService.deleteCommentary(
            this.entityData,
            commentary.ReferenceId,
        );

        if (!deleted) {
            return;
        }

        const refId = commentary.ReferenceId;
        CollectionsHelper.remove(
            this.commentaries,
            (c) => c.ReferenceId === refId,
        );
        this.entityData.SocialData.TotalCommentaryCount--;
    }

    public isOldInvisibleCommentariesNumberDisplayed() {
        return this.oldCommentariesNumber > 0 && this.isScrollbarVisible();
    }

    public getOldInvisibleCommentariesTranslateParameters() {
        return { oldElementNumber: this.oldCommentariesNumber };
    }

    public openCommentaryNewModal() {
        if (this.readOnly) {
            return;
        }
        this.commentaryService.openCommentaryNewModal({
            entityData: this.entityData,
        });
    }

    private subscribeEvents() {
        super.registerSubscriptions(
            this.commentaryService.subscribeUpdateCommentary(
                (userData, commentaryDto: EntityCommentaryDTO) => {
                    if (!this.entityData?.SocialData) {
                        return;
                    }
                    if (
                        commentaryDto.HddData.VersionId !=
                        this.entityData.VersionId
                    ) {
                        return;
                    }

                    const entityId = this.entityData.ReferenceId;
                    if (
                        !commentaryDto.HddData.Parents.some(
                            (c) => c.DataReferenceId === entityId,
                        )
                    ) {
                        return;
                    }

                    const existing = this.commentaries.find(
                        (c) => c.ReferenceId == commentaryDto.ReferenceId,
                    );
                    if (existing) {
                        CoreUtil.merge(existing, commentaryDto);
                    } else {
                        this.commentaries.push(commentaryDto);
                        this.entityData.SocialData.TotalCommentaryCount++;
                    }
                },
            ),

            this.commentaryService.subscribeDeleteCommentary(
                (userData, deleteParam: DeleteEntityCommentsParameter) => {
                    if (!this.entityData?.SocialData) {
                        return;
                    }
                    if (
                        deleteParam.VersionId != this.entityData.VersionId ||
                        deleteParam.EntityDataReferenceId !==
                            this.entityData.ReferenceId
                    ) {
                        return;
                    }

                    CollectionsHelper.remove(
                        this.commentaries,
                        (entityCommentary) =>
                            deleteParam.CommentaryIds.indexOf(
                                entityCommentary.ReferenceId,
                            ) != -1,
                    );
                    this.entityData.SocialData.TotalCommentaryCount--;
                },
            ),

            this.entityEventService.subscribeCommentaryCreation(
                (createdComment: EntityCommentaryDTO, external?: boolean) => {
                    if (!this.entityData?.SocialData) {
                        return;
                    }
                    if (
                        createdComment.HddData.VersionId !=
                        this.entityData.VersionId
                    ) {
                        return;
                    }
                    if (
                        !createdComment.HddData.Parents.some(
                            (c) =>
                                c.DataReferenceId ===
                                this.entityData.ReferenceId,
                        )
                    ) {
                        return;
                    }
                    this.commentaries.push(createdComment);
                    if (!external) {
                        setTimeout(() => this.scrollToMostRecentElement(), 100);
                    }
                },
            ),
        );
    }

    private updateData() {
        if (!this.entityData) {
            return;
        }
        this.loadCommentaries();
    }

    private async loadCommentaries() {
        if (!this.entityData) {
            return;
        }
        this.isLoading = true;
        const commentaries = await this.commentaryService.loadCommentaries(
            this.entityData,
        );
        this.commentaries = commentaries;
        this.isLoading = false;
        setTimeout(() => this.scrollToMostRecentElement(), 50);
    }

    private isScrollbarVisible() {
        return DomUtil.isScrollbarVisible(this.elementRef, '.listContainer');
    }

    private scrollToMostRecentElement() {
        const mostRecentElement = DomUtil.getElement(
            this.elementRef,
            '.listItem:last-child',
        );
        if (!mostRecentElement) {
            return;
        }

        const listContainerElement = DomUtil.getElement(
            this.elementRef,
            '.listContainer',
        );
        const scrollPosition = listContainerElement.scrollTop;
        const containerPositionTop =
            DomUtil.getGlobalOffset(listContainerElement).top;
        const itemPositionTop = DomUtil.getGlobalOffset(mostRecentElement).top;

        const scrollTop =
            itemPositionTop - containerPositionTop + scrollPosition;
        listContainerElement.scrollTo({ top: scrollTop });
    }

    private scrollToActiveElement(commentaryId: string) {
        const listContainerElement = DomUtil.getElement(
            this.elementRef,
            '.listContainer',
        );
        const activeElement = DomUtil.getElement(
            this.elementRef,
            `[commentary-id="${commentaryId}"]`,
        );
        const scrollPosition = listContainerElement.scrollTop;
        const containerPositionTop =
            DomUtil.getGlobalOffset(listContainerElement).top;
        if (!activeElement) {
            return;
        }
        const itemPositionTop = DomUtil.getGlobalOffset(activeElement).top;

        const scrollValue =
            itemPositionTop - containerPositionTop + scrollPosition;
        listContainerElement.scrollTo({ top: scrollValue });
    }
}
