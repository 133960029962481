import { deserialize, deserializeAs } from 'cerialize';
import { IHierarchicalData } from './IHierarchicalData';
import { EntityType } from './EntityType';
import { HierarchyDataDescriptor } from './HierarchyDataDescriptor';
import { HierarchyFilteredViewData } from './HierarchyFilteredViewData';

export class HierarchicalData implements IHierarchicalData {
    public static OnDeserialized(instance: HierarchicalData) {
        if (instance.Data) {
            instance.Data.VersionId = instance.VersionId;
        }
        if (instance.Parents?.length) {
            instance.Parents.forEach((p) => {
                p.VersionId = instance.VersionId;
            });
        }
    }

    @deserializeAs(HierarchyDataDescriptor)
    public Parents: HierarchyDataDescriptor[] = [];
    @deserializeAs(HierarchyDataDescriptor) public Data =
        new HierarchyDataDescriptor();
    @deserialize public VersionId!: string;
    @deserialize public TechnologyCode!: string;

    /** In the case of FilteredViews, we get this object filled */
    @deserializeAs(HierarchyFilteredViewData)
    public FilteredViewData?: HierarchyFilteredViewData;

    public constructor(
        hdd?: HierarchyDataDescriptor,
        parents?: HierarchyDataDescriptor[],
        technologyCode?: string,
    ) {
        if (hdd) {
            this.Data = hdd;
        }
        if (parents) {
            this.Parents = parents;
        }
        if (technologyCode) {
            this.TechnologyCode = technologyCode;
        }
        if (hdd?.VersionId) {
            this.setVersionId(hdd.VersionId);
        }
    }

    public setVersionId(versionId?: string | null) {
        this.VersionId = versionId;
        this.Data?.setVersionId(versionId);
        this.Parents.forEach((parent) => parent.setVersionId(versionId));
    }

    public get ServerType() {
        return this.Data.DataServerType;
    }
    public get DataReferenceId() {
        return this.Data.DataReferenceId;
    }
    public get DataServerType() {
        return this.Data.DataServerType;
    }
    public get DataTypeName() {
        return this.Data.DataTypeName;
    }
    public get SubTypeName() {
        return this.Data.SubTypeName;
    }
    public get DisplayName() {
        return this.Data.DisplayName;
    }
    public get TechnicalName() {
        return this.Data.TechnicalName || this.Data.DisplayName;
    }
    public get EntityType() {
        return this.Data.EntityType;
    }
    public get EntityTypeName() {
        return EntityType[this.Data.EntityType as EntityType] as string;
    }
    public get ReferenceId() {
        return this.Data.DataReferenceId;
    }
    public get entityType() {
        return this.Data.EntityType;
    }
}
