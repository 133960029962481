<dxy-spinner *ngIf="loading"></dxy-spinner>
<button
    *ngIf="!loading"
    dxyButton
    [variant]="variant"
    size="medium"
    [disabled]="disabled"
>
    <span *ngIf="!isLess" [translate]="showMoreLabelKey"></span>
    <span *ngIf="isLess" translate>CoreUI.Global.btnLess</span>
    <i class="glyph-more"></i>
</button>
