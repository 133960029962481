<a
    *ngIf="iconButtonVisible"
    [routerLink]="
        ['campaigns', campaignIdentifier.Guid]
            | workspaceUrl: workspaceIdentifier
    "
    class="nav-link-button"
>
    <div class="icon-wrapper">
        <i class="glyph-campaign"></i>
    </div>
    <div class="text-wrapper" dxyEllipsisTooltip>{{ displayName }}</div>
</a>

<dxy-show-more-button
    *ngIf="showMoreButtonVisible"
    [routerLink]="
        ['campaigns', campaignIdentifier.Guid]
            | workspaceUrl: workspaceIdentifier
    "
    [labelKey]="labelKey"
    variant="secondary"
>
</dxy-show-more-button>
