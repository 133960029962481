import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    NgZone,
    OnChanges,
    OnInit,
    Optional,
    Output,
    Self,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { NgControl, FormsModule } from '@angular/forms';
import {
    MatLegacySelect as MatSelect,
    MatLegacySelectModule,
} from '@angular/material/legacy-select';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { CollectionsHelper, CoreUtil, StringUtil } from '@datagalaxy/core-util';
import {
    IFieldSelectAdapter,
    OptionAdapterUtil,
} from '../../field-select.types';
import { IListOptionItem } from '../../components';
import { ListOptionUtil } from '../../IListOption';
import { DxyBaseFocusableFieldComponent } from '@datagalaxy/ui/fields';
import { ISearchTermEvent, SearchInputComponent } from '@datagalaxy/ui/search';
import { DxyDataTestIdDirective } from '@datagalaxy/ui/testing';
import { MatLegacyOptionModule } from '@angular/material/legacy-core';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';
import { DxyOptionItemComponent } from '../../components';
import { EllipsisTooltipDirective } from '@datagalaxy/ui/tooltip';
import { NgIf, NgStyle, NgFor, NgClass } from '@angular/common';

const maxDisplayedOptionCount = 100;

@Component({
    selector: 'dxy-field-select',
    templateUrl: 'field-select.component.html',
    styleUrls: ['field-select.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        EllipsisTooltipDirective,
        NgStyle,
        DxyOptionItemComponent,
        MatLegacyTooltipModule,
        MatLegacyFormFieldModule,
        MatLegacySelectModule,
        FormsModule,
        MatLegacyOptionModule,
        SearchInputComponent,
        DxyDataTestIdDirective,
        NgFor,
        NgClass,
        TranslateModule,
    ],
})
export class DxyFieldSelectComponent<T = unknown>
    extends DxyBaseFocusableFieldComponent<T>
    implements OnInit, OnChanges
{
    /** label tooltip for informations */
    @Input() infoTooltip: string;
    /** Available options. Can be set in the adapter, instead */
    @Input() options?: T[];
    /** Note: New IFieldSelectAdapter<T> is simpler to use than IOptionAdapter:
     * it avoids the creation of IListOption<T>, and can act as the ngModel. */
    @Input() adapter?: IFieldSelectAdapter<T>;
    @Input() placeholder?: string;
    /** When true, a search input is displayed as the first item in the menu */
    @Input() search?: boolean;
    /** Set to true to prevent the search input to take focus when the menu is opened */
    @Input() searchNoFocus?: boolean;
    /** Set to true to use the 'rich' layout (when options have a description text to be displayed)  */
    @Input() useRichLayout?: boolean;
    /** Set to true to use the selected option hint as a tooltip instead (should be true only in mini mode)  */
    @Input() hintAsTooltipWhenMini?: boolean;
    /** Set to true to use tooltip for display option text */
    @Input() displayOptionTooltips?: boolean;
    @Input() openMenuOnFocus: boolean;
    /** Set to true to be able to remove selected value */
    @Input() canRemove: boolean | ((value: T) => boolean);
    /** Never display the no search result placeholder */
    @Input() noSearchPlaceholder = false;
    /** Display the no search result placeholder even if field is untouched */
    @Input() untouchedNoSearchPlaceholderVisible = false;
    /** Extra class to add to the mat-select panel */
    @Input() panelClass = 'dxy-field-select';
    /** Emits when selection changes. To be used when not using ngModel/ngModelChange */
    @Output() readonly selectChange = new EventEmitter<T>();
    /** Emits when the menu is opened or closed, a value of *true* meaning opened */
    @Output() readonly openClose = new EventEmitter<boolean>();
    @Output() readonly onSearchTerm = new EventEmitter<string>();
    @Output() selectedItemClick = new EventEmitter<
        IFieldSelectSelectedItemClickEvent<T>
    >();
    //#region API
    public get hasOpenPanel() {
        return this.isOpen;
    }
    //#endregion

    public groups: IGroup<T>[];
    public searchString = '';
    public get label() {
        return this.getLabel(this.translate);
    }
    public get labelTooltip() {
        return this.getLabelTooltip(this.translate);
    }
    public get errorMessage() {
        return this.getErrorMessage(this.translate);
    }
    public get actualOptions() {
        const options =
            (this.search && this.filteredOptions) || this.allOptions;
        return options.slice(0, maxDisplayedOptionCount);
    }
    public get value() {
        const valueToReturn = Array.isArray(this._value)
            ? this._value[0]
            : this._value;
        return this.adapter?.isModel ? this.adapter.current : valueToReturn;
    }
    public set value(value: T) {
        super.setValue(value);
        if (this.adapter?.isModel) {
            this.adapter.current = value;
        }
    }

    public get selectedOption() {
        if (this._selectedOption?.value === this.value) {
            return this._selectedOption;
        }
        return (this._selectedOption = this.makeOption(this.value));
    }
    public get showRemoveSelectedOption() {
        const canRemove = CoreUtil.fromFnOrValue(this.canRemove, this.value);
        return !this.readonly && canRemove;
    }

    public get hasHint() {
        return (this.hint || this.htmlHint) && !this.hintAsTooltipWhenMini;
    }
    public get hasHintBeforeField() {
        return this.hasHint && this.hintBeforeControl;
    }
    public get hasHintAfterField() {
        return this.hasHint && !this.hintBeforeControl;
    }

    @ViewChild('fieldControl') public fieldControl: MatSelect;
    @ViewChild('searchInput') private searchInput: SearchInputComponent;

    protected get empty() {
        return this.fieldControl?.empty;
    }
    protected get noSearchResultPlaceholderVisible() {
        return (
            (this.searchString || this.untouchedNoSearchPlaceholderVisible) &&
            this.search &&
            !this.actualOptions?.length &&
            !this.noSearchPlaceholder
        );
    }

    private isOpen: boolean;
    private adapterOptions: T[];
    private filteredOptions: IOption<T>[];
    private cachedOptions: IOption<T>[];
    private _selectedOption: IOption<T>;
    private get allOptions() {
        // detects adapter's options change
        if (this.adapterOptions != this.adapter?.options) {
            this.cacheOptions();
        }
        return this.cachedOptions;
    }

    constructor(
        private translate: TranslateService,
        private cd: ChangeDetectorRef,
        elementRef: ElementRef<HTMLElement>,
        ngZone: NgZone,
        @Optional() @Self() ngControl: NgControl,
    ) {
        super(elementRef, ngZone, ngControl);
    }

    ngOnChanges(changes: SimpleChanges) {
        super.onChange(changes, 'options', () => this.cacheOptions());
        super.onChange(changes, 'adapter', () => this.init());
    }

    ngOnInit() {
        super.ngOnInit();
        this.init();
    }

    //#region API
    // overriden
    public doBlur() {
        (this.fieldControl._elementRef.nativeElement as HTMLElement).blur();
    }
    public doFocus() {
        super.doFocus();
        if (this.openMenuOnFocus) {
            setTimeout(() => this.fieldControl.open(), 250);
        }
    }

    //#endregion

    public onSelectionChange(selected: T) {
        this.selectChange.next(selected);
        this.adapter?.onSelectionChange?.(selected);
    }

    public onSelectedItemClick(event: MouseEvent) {
        this.selectedItemClick.emit({ event, data: this.selectedOption.value });
    }

    public onRemoveSelectedOption(event: MouseEvent) {
        event.stopPropagation();
        this.value = null;
        this.onSelectionChange(null);
    }

    public onOpenedClosed(isOpen: boolean) {
        this.openClose.emit((this.isOpen = isOpen));
        if (!isOpen) {
            if (this.search) {
                this.onSearch({ searchString: '' });
            }
            this.filteredOptions = this.cachedOptions;
            return;
        }

        if (this.search && !this.searchNoFocus) {
            setTimeout(() => this.searchInput?.focusInput());
        }
    }

    public getOptionClass(option: T) {
        return this.adapter?.getClass?.(option);
    }
    public getTagColor(option: T) {
        return this.adapter?.getTagColor?.(option);
    }
    public getOptionStyle(option: T) {
        return this.adapter?.getStyle?.(option);
    }
    public getOptionGlyphClass(option: T) {
        return this.adapter?.getGlyphClass?.(option);
    }
    public getIconUrl(option: T) {
        return this.adapter?.getIconUrl?.(option);
    }
    public getRenderData(option: T) {
        return this.adapter?.getRenderData?.(option);
    }
    public getOptionText(option: T) {
        return OptionAdapterUtil.getText(option, this.adapter, this.translate);
    }
    public getOptionSubText(option: T) {
        return OptionAdapterUtil.getSubText(
            option,
            this.adapter,
            this.translate,
        );
    }

    public onSearch(event: ISearchTermEvent) {
        this.searchString = event.searchString;
        this.onSearchTerm.emit(event.searchString);
        this.filteredOptions = event.searchString
            ? StringUtil.filterSearched(
                  event.searchString,
                  this.allOptions,
                  (o) => ListOptionUtil.getText(o, this.translate),
              )
            : null;
        this.makeGroups();
    }

    protected preventBlur(event: FocusEvent) {
        // prevents the blur event when clicking an option in the list
        return this.isRelatedTargetMatOption(event);
    }

    protected getDataTestValue(option: IOption<T>) {
        if (!option) {
            return;
        }
        if (typeof option.value === 'string') {
            return option.value;
        }
        if (this.adapter?.getId instanceof Function) {
            return this.adapter?.getId(option.value);
        }
    }

    private init() {
        this._selectedOption = undefined;
        this.cacheOptions();
        const adapter = this.adapter;
        if (!adapter) {
            return;
        }

        if (adapter.isModel) {
            super.setValue(adapter.current);
        }
        const values = adapter.options ?? this.options;
        if (values && !isNaN(adapter.initialIndex ?? NaN)) {
            this.value = values[adapter.initialIndex];
        }
    }

    private cacheOptions() {
        const options =
            (this.adapterOptions = this.adapter?.options) ?? this.options;
        this.filteredOptions = this.cachedOptions =
            options?.map((d) => this.makeOption(d)) ?? [];
        this.makeGroups();
        this.cd.detectChanges();
    }

    private makeOption(option: T): IOption<T> {
        const adapter = this.adapter,
            getGroupKey = adapter?.getGroupKey;
        return {
            value: option,
            labelText: this.getOptionText(option),
            descriptionTranslateKey: this.getOptionSubText(option),
            tagColor: this.getTagColor?.(option),
            style: this.getOptionStyle(option),
            class: this.getOptionClass(option),
            glyphClass: this.getOptionGlyphClass(option),
            iconUrl: this.getIconUrl(option),
            groupKey: getGroupKey?.(option),
            renderData: this.getRenderData(option),
        };
    }

    private makeGroups() {
        if (!this.adapter?.getGroupKey) {
            this.groups = undefined;
            return;
        }
        const options = this.actualOptions,
            adapter = this.adapter;
        let groupKeys: string[];
        if (adapter.groupKeys) {
            groupKeys = adapter.groupKeys;
        } else {
            const getGroupIndex = adapter.getGroupOrderIndex;
            const sortGroupKeys: (a: string, b: string) => number =
                getGroupIndex
                    ? (a, b) => getGroupIndex(a) - getGroupIndex(b)
                    : (a, b) => a?.localeCompare(b);
            groupKeys = CollectionsHelper.distinct(
                options.map((o) => o.groupKey),
            ).sort(sortGroupKeys);
        }
        this.groups = groupKeys
            .map((gk) => ({
                text: this.getGroupText(gk),
                options: options.filter((o) => o.groupKey == gk),
            }))
            .filter((g) => g.options.length);
    }
    private getGroupText(groupKey: string) {
        const text = this.adapter.getGroupLabelText?.(groupKey);
        if (text) {
            return text;
        }

        const key = this.adapter.getGroupLabelKey?.(groupKey);
        if (key) {
            return this.translate.instant(key);
        }

        return '';
    }
}

export interface IFieldSelectSelectedItemClickEvent<T> {
    event: MouseEvent;
    data: T;
}

interface IOption<T> extends IListOptionItem<T> {
    value: T;
    class?: string;
    style?: object;
    groupKey?: string;
}
interface IGroup<T> {
    text: string;
    options: IOption<T>[];
}
