<div class="modal-header">
    <h3 mat-dialog-title class="modal-title" translate>
        UI.NotificationChannels.MsTeams.DeactivationNotificationsModal.title
    </h3>
    <button dxyIconButton color="transparent" (click)="onCloseCancel()">
        <i class="glyph-cancelsearch"></i>
    </button>
</div>
<div mat-dialog-content translate>
    UI.NotificationChannels.MsTeams.DeactivationNotificationsModal.description
</div>

<dxy-modal-footer
    [actionEnabled]="true"
    [noMandatory]="true"
    [actionBtnLbl]="
        'UI.NotificationChannels.ClientSettings.disconnect' | translate
    "
    [actionLoading]="loading$ | async"
    (onCloseCancel)="onCloseCancel()"
    (onActionPointerDown)="deactivateNotifications()"
></dxy-modal-footer>
