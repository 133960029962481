import {
    Directive,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnDestroy,
    Output,
    SimpleChanges,
    TemplateRef,
    ViewContainerRef,
} from '@angular/core';
import { OverlayRef } from '@angular/cdk/overlay';
import {
    RichTooltipPosition,
    RichTooltipPositionOptions,
} from './rich-tooltip.types';
import { DxyTooltipService } from '../tooltip.service';
import { DxyBaseDirective } from '@datagalaxy/ui/core';

/* eslint-disable @angular-eslint/no-input-rename */
/* eslint-disable @angular-eslint/no-output-on-prefix */
/** Show/hide an overlay containing a template when the element is hovered by the cursor.
 *
 * Usage example:
 * ```
<div [dxyRichTooltip]="content" richTooltipPosition="left">over me</div>
<ng-template #content>I'm the tooltip content</ng-template>
``` */
@Directive({
    selector: '[dxyRichTooltip]',
    standalone: true,
})
export class DxyRichTooltipDirective
    extends DxyBaseDirective
    implements OnChanges, OnDestroy
{
    @Input('dxyRichTooltip') templateRef!: TemplateRef<any>;
    @Input('richTooltipPosition') position: RichTooltipPosition = 'above';
    @Input('richTooltipDisabled') disabled = false;
    @Input('richTooltipIsEnabled') isEnabled?: () => boolean;
    @Input('richTooltipPositionOptions')
    positionOptions?: RichTooltipPositionOptions;

    /** Emitted on show & hide. Argument is true on show */
    @Output() get onShowHide() {
        return (this._onShowHide ??= new EventEmitter<boolean>());
    }
    private _onShowHide?: EventEmitter<boolean>;

    private overlayRef?: OverlayRef;
    private isIn = false;
    private isVisible = false;

    constructor(
        private elementRef: ElementRef<HTMLElement>,
        private viewContainerRef: ViewContainerRef,
        private tooltipService: DxyTooltipService,
    ) {
        super();
    }

    ngOnChanges(changes: SimpleChanges) {
        super.onChange(changes, 'disabled', () => this.onDisabledChange());
    }

    override ngOnDestroy() {
        super.ngOnDestroy();
        this.hideOverlay();
    }

    @HostListener('mouseenter') private mouseenter() {
        this.hide(); // to prevent the case where mouseleave is not called when an element is inserted
        this.isIn = true;
        this.show();
    }
    @HostListener('mouseleave') private mouseleave() {
        this.isIn = false;
        this.hide();
    }
    @HostListener('mouseup') private mouseup() {
        setTimeout(() => {
            if (this.isVisible && this.isDisabled()) {
                this.hide();
            }
        });
    }

    private onDisabledChange() {
        if (this.disabled && this.isVisible) {
            this.isIn = false;
            this.hide();
        } else if (this.isIn && !this.isVisible) {
            this.show();
        }
    }

    private show() {
        if (this.isDisabled()) {
            return;
        }
        this.overlayRef = this.tooltipService.getOverlayRef(
            this.elementRef,
            this.position,
            this.positionOptions,
        );
        this.tooltipService.showOverlayRef(
            this.overlayRef,
            this.templateRef,
            this.viewContainerRef,
        );
        this.isVisible = true;
        this._onShowHide?.emit(this.isVisible);
    }
    private hide() {
        this.hideOverlay();
        this.isVisible = false;
        this._onShowHide?.emit(this.isVisible);
    }

    private isDisabled() {
        return (
            this.disabled ||
            (typeof this.isEnabled == 'function' && !this.isEnabled())
        );
    }

    private hideOverlay() {
        if (!this.overlayRef) {
            return;
        }
        this.tooltipService.hideOverlayRef(this.overlayRef);
    }
}
