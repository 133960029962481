<div
    [matMenuTriggerFor]="menu"
    [class.hidden]="!actions.length"
    [matTooltip]="tooltipTranslateKey | translate"
    tabindex="0"
>
    <button
        *ngIf="isPrimary"
        dxyIconButton
        variant="primary"
        (click)="onButtonClick($event)"
    >
        <i
            class="glyph"
            [class.glyph-add]="isAdd"
            [class.glyph-splitter]="isBurger"
        ></i>
    </button>

    <button
        *ngIf="!isPrimary"
        dxyIconButton
        variant="tertiary"
        (click)="onButtonClick($event)"
    >
        <i
            class="glyph"
            [class.glyph-add]="isAdd"
            [class.glyph-splitter]="isBurger"
        ></i>
    </button>
</div>
<mat-menu
    #menu="matMenu"
    class="dg5-mat-menu no-max-width dxy-dropdown-button-menu"
    yPosition="below"
    xPosition="before"
>
    <ul *ngIf="actions?.length > 1">
        <ng-container *ngFor="let action of actions">
            <li *ngIf="action.isSeparator" class="separator not-an-item"></li>
            <li
                *ngIf="!action.isSeparator"
                class="dropdown-action"
                (click)="action.callback()"
                [dxyLogFunctional]="action.logFunctional"
            >
                <div class="dropdown-action-title">
                    <span
                        *ngIf="action.glyphClass"
                        class="glyph"
                        [ngClass]="action.glyphClass"
                    ></span>
                    <span class="text" [translate]="action.titleKey"></span>
                </div>
                <div
                    *ngIf="action.subTitleKey"
                    class="dropdown-action-subtitle"
                    [translate]="action.subTitleKey"
                ></div>
            </li>
        </ng-container>
    </ul>
</mat-menu>
