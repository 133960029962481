import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { DxyTabsHeaderComponent, ITabsHeaderData } from '@datagalaxy/core-ui';
import { ITabViewData, ITabViewItem } from './dxy-tab-view.types';
import {
    CrudOperation,
    FunctionalLogService,
} from '@datagalaxy/shared/monitoring/data-access';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { NgIf } from '@angular/common';
import {
    ActivatedRoute,
    NavigationEnd,
    Router,
    RouterOutlet,
} from '@angular/router';
import { filter } from 'rxjs/operators';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

/** Tab view with native material style
 * @example <dxy-tab-view [tabsData]="tabsData"></dxy-tab-view>
 *
 * // In order to use alternative custom style you can add "alternative" class:
 * @example <dxy-tab-view [tabsData]="tabsData" class="alternative"></dxy-tab-view>
 */
@Component({
    selector: 'dxy-tab-view',
    templateUrl: 'dxy-tab-view.component.html',
    styleUrls: ['dxy-tab-view.component.scss'],
    standalone: true,
    imports: [DxyTabsHeaderComponent, NgIf, RouterOutlet],
})
export class DxyTabViewComponent
    extends DxyBaseComponent
    implements OnChanges, OnInit
{
    @Input() tabsData: ITabViewData;
    @Input() customClass?: string;

    /** Emitted when selected tab changes (before *onActiveTabItem* is called) */
    @Output() readonly onTabChanging = new EventEmitter<ITabViewItem>();
    /** Emitted when selected tab has changed (after *onActiveTabItem*, or navigation) */
    @Output() readonly onTabChanged = new EventEmitter<ITabViewItem>();

    protected tabsHeaderData: ITabsHeaderData;
    protected noUiView = false;
    protected selectedTabItem: ITabViewItem;

    private customNavigation = false;

    constructor(
        private functionalLogService: FunctionalLogService,
        private router: Router,
        private route: ActivatedRoute,
    ) {
        super();
        this.router.events
            .pipe(
                takeUntilDestroyed(),
                filter((event) => event instanceof NavigationEnd),
            )
            .subscribe(() => this.updateCurrentTabItem());
    }

    ngOnChanges(changes: SimpleChanges) {
        super.onChange(changes, 'tabsData', () => this.init());
    }

    ngOnInit() {
        this.init();
    }

    public async onTabChangeInternal(tabItem: ITabViewItem) {
        return this.activeSelectedTabItem(tabItem);
    }

    private init() {
        const tabItems = this.tabsData?.tabItems;

        this.noUiView = this.tabsData.noUiView;
        this.customNavigation = this.tabsData.customNavigation;
        this.tabsHeaderData = {
            ...this.tabsData,
            isTabItemActive: (ti) => this.isCurrentTabItem(ti),
        };

        if (!tabItems?.some((ti) => this.isCurrentTabItem(ti))) {
            void this.activeSelectedTabItem(tabItems?.[0]);
        }
    }

    private updateCurrentTabItem() {
        const tabItems = this.tabsData?.tabItems;
        const currentTabItem = tabItems?.find((ti) =>
            this.isCurrentTabItem(ti),
        );

        if (currentTabItem) {
            this.selectedTabItem = currentTabItem;
        }
    }

    private isCurrentTabItem(tabItem: ITabViewItem) {
        if (this.noUiView || this.customNavigation) {
            return this.tabsData.isTabItemActive(tabItem);
        }

        const urlTree = this.router.createUrlTree([tabItem.tabStateName], {
            relativeTo: tabItem.isStateNameRelative ? this.route : null,
        });

        return this.router.isActive(urlTree, {
            paths: 'subset',
            queryParams: 'subset',
            fragment: 'ignored',
            matrixParams: 'ignored',
        });
    }

    private logAction(tabItem: ITabViewItem) {
        const code =
            tabItem.customFeatureCode ||
            (this.tabsData.tabFeatureCodeCustomPrefix || 'WORKSPACE_') +
                tabItem.tabId.toUpperCase();
        this.functionalLogService.logFunctionalAction(code, CrudOperation.R);
    }

    private async activeSelectedTabItem(tabItem: ITabViewItem) {
        this.selectedTabItem = tabItem;

        if (!tabItem) {
            return;
        }

        this.logAction(tabItem);
        this.onTabChanging.emit(tabItem);

        if (this.noUiView || this.customNavigation) {
            this.tabsData.onActiveTabItem(tabItem);
        } else {
            await this.router.navigate([tabItem.tabStateName], {
                relativeTo: tabItem.isStateNameRelative ? this.route : null,
            });
        }

        this.onTabChanged.emit(tabItem);
    }
}
