import { Injectable } from '@angular/core';
import { SecurityService } from '../services/security.service';
import { WorkspaceService } from '@datagalaxy/webclient/workspace/data-access';
import { AppDataService } from '../services/app-data.service';
import { CampaignDto } from '@datagalaxy/webclient/campaign/data-access';
import { getReferenceId } from '@datagalaxy/utils';
import { WorkspaceIdentifier } from '@datagalaxy/webclient/workspace/utils';
import {
    IWorkspaceIdentifier,
    WorkspaceDetails,
} from '@datagalaxy/webclient/workspace/domain';
import { UserDto } from '@datagalaxy/webclient/user/domain';

@Injectable({ providedIn: 'root' })
export class CampaignSecurityService {
    public async canCreateOrModifyCampaign(spaceIdr: IWorkspaceIdentifier) {
        const space = await this.workspaceService.getWorkspace(spaceIdr);
        return (
            this.isCurrentUserWorkspaceAdmin(space) &&
            this.securityService.areCampaignsEnabled()
        );
    }

    public canCreateOrModifyCampaignSync(space: WorkspaceDetails) {
        return (
            this.isCurrentUserWorkspaceAdmin(space) &&
            this.securityService.areCampaignsEnabled()
        );
    }
    public async userCanModifyPhaseAndObjects(
        campaign: CampaignDto,
    ): Promise<boolean> {
        const spaceIdr = new WorkspaceIdentifier(
            getReferenceId(campaign.SpaceGuid, campaign.SpaceGuid),
            campaign.VersionId,
        );
        return (
            this.securityService.areCampaignsEnabled() &&
            !campaign.Ended &&
            ((await this.canCreateOrModifyCampaign(spaceIdr)) ||
                (this.isAssignee(campaign.CurrentPhase.Assignees) &&
                    this.securityService.isSteward()))
        );
    }

    constructor(
        private securityService: SecurityService,
        private workspaceService: WorkspaceService,
        private appDataService: AppDataService,
    ) {}

    private isCurrentUserWorkspaceAdmin(currentSpace: WorkspaceDetails) {
        return this.securityService.hasManagementAccess(currentSpace);
    }
    private isAssignee(assignees: UserDto[]): boolean {
        return assignees.some(
            (u) => u.UserId === this.appDataService.currentUserId,
        );
    }
}
