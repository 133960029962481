import { Injectable } from '@angular/core';
import { RestApiService } from '@datagalaxy/data-access';
import { GetObjectUsersAccessResult } from './security';
import { DgModule } from '@datagalaxy/shared/dg-module/domain';

@Injectable({ providedIn: 'root' })
export class ObjectSecurityApiService {
    constructor(private restApiService: RestApiService) {}

    public async getObjectUsersAccess(
        referenceId: string,
        versionId: string,
        module: DgModule,
    ) {
        return await this.restApiService.getPromise<GetObjectUsersAccessResult>(
            `/security/versions/${versionId}/modules/${module}/objects/${referenceId}/users`,
        );
    }
    public async getSpaceUsersAccess(workspaceId: string) {
        return await this.restApiService.getPromise<GetObjectUsersAccessResult>(
            `/security/workspaces/${workspaceId}/users`,
        );
    }
}
