import { BaseServiceParameter } from '@datagalaxy/data-access';
import { PrimaryKeyForeignKeyLink } from './foreign-key';
import { inheritSerialization, serialize, serializeAs } from 'cerialize';

@inheritSerialization(BaseServiceParameter)
export abstract class BaseTechnicalForeignKeyParameter extends BaseServiceParameter {
    @serialize public IsMandatory: boolean;
    @serialize public TechnicalName: string;
    @serialize public DisplayName: string;
    @serialize public Description: string;
    @serializeAs(PrimaryKeyForeignKeyLink)
    public PkFkColumLinks: PrimaryKeyForeignKeyLink[];

    constructor(
        isMandatory: boolean,
        technicalName: string,
        displayName: string,
        description: string,
        pkFkColumLinks: PrimaryKeyForeignKeyLink[],
    ) {
        super();
        this.IsMandatory = isMandatory;
        this.TechnicalName = technicalName;
        this.DisplayName = displayName;
        this.Description = description;
        this.PkFkColumLinks = pkFkColumLinks;
    }
}

@inheritSerialization(BaseTechnicalForeignKeyParameter)
export class CreateTechnicalForeignKeyParameter extends BaseTechnicalForeignKeyParameter {
    @serialize public ParentTableId: string;
    @serialize public ChildTableId: string;

    constructor(
        parentTableId: string,
        childTableId: string,
        isForeignKeyMandatory: boolean,
        foreignKeyTechnicalName: string,
        foreignKeyDisplayName: string,
        foreignKeyDescription: string,
        foreignKeyMappings: PrimaryKeyForeignKeyLink[],
    ) {
        super(
            isForeignKeyMandatory,
            foreignKeyTechnicalName,
            foreignKeyDisplayName,
            foreignKeyDescription,
            foreignKeyMappings,
        );
        this.ParentTableId = parentTableId;
        this.ChildTableId = childTableId;
    }
}

@inheritSerialization(BaseServiceParameter)
export class CreateFunctionalForeignKeyParameter extends BaseServiceParameter {
    @serialize public ParentTableId: string;
    @serialize public ChildTableId: string;
    @serialize public DisplayName: string;
    @serialize public Description: string;

    constructor(
        parentTableId: string,
        childTableId: string,
        displayName: string,
        description: string,
    ) {
        super();
        this.ParentTableId = parentTableId;
        this.ChildTableId = childTableId;
        this.DisplayName = displayName;
        this.Description = description;
    }
}

@inheritSerialization(BaseTechnicalForeignKeyParameter)
export class UpdateTechnicalForeignKeyParameter extends BaseTechnicalForeignKeyParameter {
    @serialize public ParentTableId: string;
    @serialize public FkId: string;

    constructor(
        parentTableId: string,
        fkId: string,
        isForeignKeyMandatory: boolean,
        foreignKeyTechnicalName: string,
        foreignKeyDisplayName: string,
        foreignKeyDescription: string,
        foreignKeyMappings: PrimaryKeyForeignKeyLink[],
    ) {
        super(
            isForeignKeyMandatory,
            foreignKeyTechnicalName,
            foreignKeyDisplayName,
            foreignKeyDescription,
            foreignKeyMappings,
        );
        this.ParentTableId = parentTableId;
        this.FkId = fkId;
    }
}

@inheritSerialization(BaseServiceParameter)
export class UpdateFunctionalForeignKeyParameter extends BaseServiceParameter {
    @serialize public FkId: string;
    @serialize public DisplayName: string;
    @serialize public Description: string;

    constructor(fkId: string, displayName: string, description: string) {
        super();
        this.FkId = fkId;
        this.DisplayName = displayName;
        this.Description = description;
    }
}

@inheritSerialization(BaseServiceParameter)
export class DeleteForeignKeyParameter extends BaseServiceParameter {
    @serialize public FkId: string;
    @serialize public ParentTableId: string;
    @serialize public IsKeepReferenceFk: boolean;

    constructor(
        fkId: string,
        parentTableId: string,
        isKeepReferenceFk: boolean,
    ) {
        super();
        this.FkId = fkId;
        this.ParentTableId = parentTableId;
        this.IsKeepReferenceFk = isKeepReferenceFk;
    }
}

@inheritSerialization(BaseTechnicalForeignKeyParameter)
export class ConvertForeignKeyParameter extends BaseTechnicalForeignKeyParameter {
    @serialize public ParentTableId: string;
    @serialize public FunctionalFkId: string;

    constructor(
        parentTableId: string,
        functionalFkId: string,
        isForeignKeyMandatory: boolean,
        foreignKeyTechnicalName: string,
        foreignKeyDisplayName: string,
        foreignKeyDescription: string,
        foreignKeyMappings: PrimaryKeyForeignKeyLink[],
    ) {
        super(
            isForeignKeyMandatory,
            foreignKeyTechnicalName,
            foreignKeyDisplayName,
            foreignKeyDescription,
            foreignKeyMappings,
        );
        this.ParentTableId = parentTableId;
        this.FunctionalFkId = functionalFkId;
    }
}
