import {
    AfterViewInit,
    Directive,
    ElementRef,
    inject,
    Input,
} from '@angular/core';

/**
 * ## Role
 * Append a dropdown icon
 */
@Directive({
    selector: '[dxyDropdownButton]',
    standalone: true,
})
export class DxyDropdownButtonDirective implements AfterViewInit {
    private el = inject(ElementRef<HTMLElement>);

    @Input() hideDropdownIcon = false;

    ngAfterViewInit() {
        if (this.hideDropdownIcon) {
            return;
        }
        this.appendDropdownIcon();
    }

    private appendDropdownIcon() {
        const dropdownIcon = document.createElement('i');
        dropdownIcon.classList.add('glyph-arrow-drop-down');
        dropdownIcon.classList.add('dropdown-icon');
        this.el.nativeElement.appendChild(dropdownIcon);
    }
}
