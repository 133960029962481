<div
    [matMenuTriggerFor]="menu"
    [class.disabled]="!isMenuVisible"
    (menuOpened)="openClose.emit(true)"
    (menuClosed)="openClose.emit(false)"
    class="selected-workspace"
>
    @if (selectedSpace && selectedSpace?.ReferenceId) {
        <dxy-workspace-icon
            size="small"
            [workspace]="selectedSpace | workspaceAdapter"
        ></dxy-workspace-icon>
    }
    <span
        class="displayname"
        [attr.data-tracker-id]="config()?.trackerIds?.selectedTextTrackerId"
        (click)="onDisplayNameClickInternal($event)"
    >
        {{ selectedSpace?.DisplayName }}
    </span>

    @if (spaceSelectable) {
        <button
            dxyIconButton
            type="button"
            variant="tertiary"
            size="small"
            dxyDataTestId="workspace-select"
            [attr.data-tracker-id]="
                config()?.trackerIds?.selectedCaretTrackerId
            "
        >
            <i class="glyph-arrow-drop-down"></i>
        </button>
    }
</div>
<mat-menu #menu="matMenu" class="dg5-mat-menu">
    <dxy-workspace-menu-list
        [workspaces]="options() | workspaceListAdapter"
        [selectedWorkspaceId]="selectedSpace?.ReferenceId"
        [showAllSpacesSection]="!!config()?.showAllSpaceRedirection"
        [readonlyFavorite]="!!config()?.readonlyFavorite"
        (workspaceSelected)="onSpaceClick($event)"
        (allSpacesSelected)="goToClientSpacesList()"
        (toggleFavorite)="onStarClick($event)"
    ></dxy-workspace-menu-list>
</mat-menu>
