import {
    AfterViewInit,
    Component,
    ElementRef,
    HostListener,
    Input,
    NgZone,
    OnInit,
    Optional,
    Self,
    ViewChild,
} from '@angular/core';
import { NgIf, NgTemplateOutlet } from '@angular/common';
import { EllipsisTooltipDirective } from '@datagalaxy/ui/tooltip';
import {
    MatLegacyInput as MatInput,
    MatLegacyInputModule,
} from '@angular/material/legacy-input';
import { FormsModule, NgControl } from '@angular/forms';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';
import {
    MatLegacyAutocomplete as MatAutocomplete,
    MatLegacyAutocompleteModule,
} from '@angular/material/legacy-autocomplete';
import { DxyDataTestIdDirective } from '@datagalaxy/ui/testing';
import { DxyBaseFocusableFieldComponent } from '../base-focusable-field.component';
import { InputMaxLength } from '../field.types';
import { TranslateService } from '@ngx-translate/core';
import { KeyboardUtil } from '@datagalaxy/utils';

/**
 * ## Role
 * Text field with label, hint and error message
 */
@Component({
    standalone: true,
    selector: 'dxy-field-text',
    templateUrl: 'field-text.component.html',
    styleUrls: ['field-text.component.scss'],
    imports: [
        NgIf,
        EllipsisTooltipDirective,
        MatLegacyInputModule,
        FormsModule,
        MatLegacyTooltipModule,
        MatLegacyAutocompleteModule,
        DxyDataTestIdDirective,
        NgTemplateOutlet,
    ],
})
export class DxyFieldTextComponent
    extends DxyBaseFocusableFieldComponent<string>
    implements OnInit, AfterViewInit
{
    //#region static
    /** Maybe changed by app */
    public static defaultMultiline = false;
    /** Maybe changed by app */
    public static defaultAutoSizeMultiline = true;
    /** Maybe changed by app */
    public static defaultAutoBreakLine = true;
    //#endregion

    /** label tooltip for informations */
    @Input() infoTooltip = '';
    @Input() placeholder = '';

    @Input() multiLine = DxyFieldTextComponent.defaultMultiline;
    /** If true and multiline, the textarea will fit to its content */
    @Input() autoSizeMultiLine = DxyFieldTextComponent.defaultAutoSizeMultiline;
    /** If true a textarea will be used for simple text,
     * this will allow to display long text on multiple lines.
     * Take notes that we are preventing new lines and that we send a submit event
     * on enter like an input would
     */
    @Input() autoBreakLine = DxyFieldTextComponent.defaultAutoBreakLine;
    /** If value and autoSizeMultiLine is true, the textarea will have a max number of rows */
    @Input() autoSizeMultiLineMaxRows?: number;
    @Input() maxLength = InputMaxLength;
    @Input() hideMaxLengthHint = false;
    @Input() matAutocomplete?: MatAutocomplete;
    /**
     * If true, a submit event will be sent to the closest form on enter key
     * This way we can emulate the behavior of an input[type="text"] using textarea
     */
    @Input() public triggerSubmitOnEnter = true;

    public get label() {
        return this.getLabel(this.translate);
    }

    public get labelTooltip() {
        return this.getLabelTooltip(this.translate);
    }

    public get errorMessage() {
        return this.getErrorMessage(this.translate);
    }

    public get showMaxLengthHint() {
        return !!(
            this.maxLength &&
            this.maxLength !== InputMaxLength &&
            this.fieldControl?.focused &&
            !this.hideMaxLengthHint
        );
    }

    public get useTextArea() {
        return this.multiLine || this.autoBreakLine;
    }

    @ViewChild('inputRef') public inputRef?: ElementRef<
        HTMLInputElement | HTMLTextAreaElement
    >;
    @ViewChild('fieldControl') protected fieldControl?: MatInput;

    protected override get empty() {
        return !!this.fieldControl?.empty;
    }

    protected get dataTestId() {
        if (this.ngControl) {
            return `${this.ngControl.name}-field-text`;
        }
        return '';
    }

    constructor(
        private translate: TranslateService,
        elementRef: ElementRef<HTMLElement>,
        ngZone: NgZone,
        @Optional() @Self() ngControl: NgControl,
    ) {
        super(elementRef, ngZone, ngControl);
    }

    override ngOnInit() {
        super.ngOnInit();
        this.placeholder ??= '';
        this.log('ngOnInit-done');
    }

    override ngAfterViewInit() {
        super.ngAfterViewInit();
        this.log('ngAfterViewInit-done');
    }

    @HostListener('keydown', ['$event'])
    public onKeyDown(event: KeyboardEvent) {
        if (this.multiLine) {
            return;
        } else if (
            KeyboardUtil.preventEnterKey(event) &&
            this.autoBreakLine &&
            this.triggerSubmitOnEnter
        ) {
            this.elementRef?.nativeElement
                .closest('form')
                ?.dispatchEvent(new Event('submit'));
        }
    }

    public override doBlur() {
        this.log('doBlur');
        this.inputRef?.nativeElement.blur();
    }
}
