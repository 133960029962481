import { GridTreeBehavior } from './grid-tree.types';
import { ServerTreeDataSourceConfig } from '../grid-config';
import { Row } from '@tanstack/table-core';

export class ServerTree<TRow> implements GridTreeBehavior<TRow> {
    private treeMap = new Map<TRow, TRow[]>();
    private loadingSet = new Set<TRow>();

    public get isExpanded() {
        if (!this.dataSource.isExpanded) {
            return undefined;
        }

        /**
         * When Table will perform a check on if the row is expanded, it will
         * start to asynchronously load the children of the row.
         * Then, when it's done, the row will be expanded.
         */
        return (row: Row<TRow>) => {
            const children = this.getChildren(row.original);
            /**
             * Do not try to affect isExpanded to a variable, it will lose the
             * reference to the dataSource object.
             */
            const expanded = this.dataSource.isExpanded?.(row.original);

            if (this.loadingSet.has(row.original)) {
                return false;
            }

            if (!children.length && expanded) {
                void this.loadChildren(row.original).then(() => {
                    row.toggleExpanded(true);
                });

                return false;
            }

            return expanded ?? false;
        };
    }

    public get expandAll() {
        return false;
    }

    constructor(
        public readonly dataSource: ServerTreeDataSourceConfig<TRow>,
        private onChange?: () => void,
    ) {}

    canExpand(row: TRow): boolean {
        return this.dataSource.canExpand(row);
    }

    getChildren(row: TRow): TRow[] {
        return (
            this.dataSource.getChildren?.(row) || this.treeMap.get(row) || []
        );
    }

    async loadChildren(row: TRow): Promise<TRow[]> {
        const cached = this.getChildren(row);

        if (cached.length) {
            return cached;
        }

        this.loadingSet.add(row);
        const res = await this.dataSource.loadChildren(row);
        this.loadingSet.delete(row);

        this.treeMap.set(row, res);

        this.onChange?.();

        return res;
    }

    isRowLoading(row: TRow): boolean {
        return this.loadingSet.has(row) || false;
    }

    setExpanded(row: TRow, expanded: boolean): void {
        this.dataSource.setExpanded?.(row, expanded);
    }
}
