import { clamp } from '@datagalaxy/core-util';
import { IXYRO } from './2d.types';
import { Rect, RectSide } from './Rect';
import { IIntersect2d, Vect2 } from './Vect2';
import { IRectNode, IRectSidePoint } from './rect.types';

/** ## Role
 * Associates a side and a normalized distance along that side to a rectangular item.
 * ## Features
 * - fixed/unfixed states (by client, by system)
 * - position computation:
 *   - closest to another rectangle side point
 *   - closest to a given point */
export class RectSidePoint<N = unknown> implements IRectSidePoint<N> {
    //#region static
    private static readonly _u = new Vect2();
    private static readonly _v = new Vect2();
    private static readonly _w = new Vect2();
    private static readonly _i = {} as IIntersect2d;
    //#region static

    /**
     * Sets the given *endpoint*'s width and height to zero and centers it on
     * the given *p* point. By default, sets its distance and side to *undefined*
     * so they can be re-computed, for example by calling *setSideAndDistance*.
     */
    public static makeZeroSized<D>(
        endpoint: IRectSidePoint<D>,
        p: IXYRO,
        side?: RectSide,
        distance?: number,
    ) {
        Rect.set(endpoint.node.rect, p.x, p.y, 0, 0);
        endpoint.side = side;
        endpoint.distance = distance;
        return endpoint;
    }

    public static copy<D>(ep: IRectSidePoint, result: IRectSidePoint<D>) {
        Rect.copy(result.node.rect, ep.node.rect);
        result.side = ep.side;
        result.distance = ep.distance;
        return result;
    }
    //#endregion - static

    /** absolute position of this side point */
    public readonly position = new Vect2();
    /** true when the side has been specified or set by the client */
    public get isClientSide() {
        return !this.autoSide;
    }
    /** true when no side has been specified by the client */
    public get isAutoSide() {
        return this.autoSide;
    }
    /** true when the current side has been fixed by the system */
    public get isSideAutoFixed() {
        return this.fixedAutoSide;
    }
    /** true when the side is fixed (either specified by the client, or computed by the system) */
    public get isSideFixed() {
        return this.fixedAutoSide || !this.autoSide;
    }

    private autoSide: boolean;
    private fixedAutoSide = false;

    constructor(
        public node: IRectNode<N>,
        public side?: RectSide,
        public distance?: number,
    ) {
        this.autoSide = !this.side;
    }

    /** Sets the side and distance, and marks the side as client-fixed */
    public fixSide(side: RectSide, distance?: number) {
        this.side = side;
        this.distance = distance;
        this.fixSideIfSet();
    }

    /** If the side is set, marks it as client-fixed, else as auto */
    public fixSideIfSet() {
        this.autoSide = !this.side;
    }

    /** Marks the side as auto-fixed */
    public fixAutoSide() {
        this.fixedAutoSide = true;
    }
    /** Unmarks the side as auto-fixed, so it can be computed dynamically if not set by the client */
    public unfixSide(makeAutoSide?: boolean) {
        this.fixedAutoSide = false;
        if (makeAutoSide) {
            this.autoSide = true;
        }
        this.clearSideForAuto(true);
    }

    /** Sets the side as 'not fixed by the client', so it can be computed dynamically */
    public setAutoSide(clearSide?: boolean) {
        this.autoSide = true;
        if (!clearSide) {
            return;
        }
        this.fixedAutoSide = false;
    }

    /** If the side is not fixed, clears it, so it can be recomputed dynamically */
    public clearSideForAuto(preserveDistance?: boolean) {
        if (this.autoSide && !this.fixedAutoSide) {
            this.side = undefined;
            if (!preserveDistance) {
                this.distance = undefined;
            }
        }
    }

    /** Updates the *position* property (the side point's absolute position) */
    public updatePosition() {
        return Rect.sidePoint(
            this.node.rect,
            this.side,
            this.position,
            this.distance,
        ).truncateDecimals(true);
    }

    /** Moves this point along the contour of the rectangle, the nearest possible to the given point.
     * Sets *side* and *distance* properties,
     * marks the side as fixed by the client,
     * updates and returns the new *position* */
    public move(p: IXYRO, preserveDistance?: boolean, noDecimals?: boolean) {
        const r = this.node.rect;
        const s = (this.side = Rect.nearestSide(r, p));
        this.fixedAutoSide = this.autoSide = false;
        if (!preserveDistance) {
            const u = Rect.sideStart(r, s, RectSidePoint._u);
            const v = Rect.sideEnd(r, s, RectSidePoint._v);
            const w = Rect.center(r, RectSidePoint._w);
            const i = Vect2.intersect(u, v, w, p, RectSidePoint._i);
            if (noDecimals) {
                Vect2.round(i, i);
            }
            const d = Vect2.orthogonalSegmentLength(u, i);
            const l = Vect2.orthogonalSegmentLength(u, v);
            this.distance = clamp(d / l, 0, 1);
        }
        return this.updatePosition();
    }

    public sideAndDistance() {
        return {
            side: this.side,
            distance: this.distance,
        };
    }
}
