import {
    ArrowBuilder,
    IDiagramShapeSpec,
    ShapeId,
    ShapeType,
} from '../../shapes';

export class ArrowSpecs {
    private static readonly thicknesses = [1, 2, 4, 6];
    private readonly registry = new Map<string, IDiagramShapeSpec>();

    private static instance: ArrowSpecs | null = null;

    public static getInstance() {
        if (ArrowSpecs.instance) {
            return ArrowSpecs.instance;
        }
        return (ArrowSpecs.instance = new ArrowSpecs());
    }

    private constructor() {
        this.initArrowSpecs();
    }

    private initArrowSpecs() {
        ArrowBuilder.generateArrowShapeSpecs(ArrowSpecs.thicknesses).forEach(
            (spec, shapeId) => {
                spec.noDecimals = true;
                this.register(shapeId, spec);
            },
        );

        ArrowSpecs.thicknesses.forEach((thickness) => {
            this.register(this.makeShapeId(ShapeId.circlePlain10, thickness), {
                type: ShapeType.circle,
                radius: 5,
                shapeClass: 'circle plain',
            });
        });
    }
    private makeShapeId(shapeId: ShapeId, thickness: number) {
        return `${ShapeId[shapeId]}-${thickness}`;
    }

    public register(shapeId: string, spec: IDiagramShapeSpec) {
        this.registry.set(shapeId, spec);
        return this;
    }
    public getSpec(shapeId: ShapeId, thickness: number) {
        const fullSHapeId = this.makeShapeId(shapeId, thickness);
        return shapeId ? this.registry.get(fullSHapeId) : null;
    }
    public getSpecRecess(shapeId: ShapeId, thickness: number) {
        return this.getSpec(shapeId, thickness)?.recess ?? 0;
    }
}
