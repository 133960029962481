import { DateTimeUtil } from '@datagalaxy/core-util';
import * as moment from 'moment';
import {
    EntityTaskDTO,
    ObjectTaskStatus,
    ObjectTaskType,
    TaskAssignedEvent,
    TaskCreatedEvent,
    TaskUpdatedEvent,
} from '@datagalaxy/dg-object-model';
import { HierarchyDataDescriptor } from '@datagalaxy/dg-object-model';
import { registerTypedEventTranslationAdapter } from '../../dg-event-translation-adapter-registry';
import { IDgEventTranslationAdapter } from '../../../interfaces/IDgEventTranslationAdapter';
import {
    IDgEventNavLink,
    IEventTranslationInputs,
} from '../../../interfaces/IEventTranslationInputs';
import { getUserEventTranslationInputs } from '../common/dg-event-translation-adapter-helpers';
import {
    AttributeUpdatesTranslation,
    IAttributeTranslationValue,
    IAttributeUpdateTranslationInputs,
} from '../../../interfaces/IAttributeUpdateTranslationInputs';
import { DgEventTranslationAdapterBase } from '../common/dg-event-translation-adapter-base';

const eventTypes = [TaskCreatedEvent, TaskAssignedEvent, TaskUpdatedEvent];
export type TTaskEvent = InstanceType<(typeof eventTypes)[number]>;

/**
 * ## Role
 * Adapter to get tasks events translation inputs
 */
@registerTypedEventTranslationAdapter(eventTypes)
export class TaskEventTranslationAdapter
    extends DgEventTranslationAdapterBase
    implements IDgEventTranslationAdapter
{
    private attributesUpdates = new AttributeUpdatesTranslation();

    constructor(private event: TTaskEvent) {
        super(event);
    }

    public getTranslationInputs() {
        const inputs = getUserEventTranslationInputs(this.event);
        const hdd = this.getTaskEntityHdd();
        this.addTranslateParams(hdd, inputs);
        inputs.navLinks = this.getNavLinks(hdd);
        return inputs;
    }

    private addTranslateParams(
        hdd: HierarchyDataDescriptor,
        inputs: IEventTranslationInputs,
    ) {
        const params = inputs.translateParameters;
        params.push({
            key: 'entityName',
            value: hdd?.DisplayName,
        });
        params.push({
            key: 'taskTitle',
            value: this.event.Task?.Title,
        });
        if (this.event instanceof TaskUpdatedEvent) {
            const updates = this.getUpdatedProperties(this.event);
            this.attributesUpdates.updates = updates;

            params.push({
                key: 'propertiesCount',
                value: updates.length,
            });
            params.push({
                key: 'properties',
                valuesTranslationKeys: updates.map((p) => p.attributeNameKey),
            });
        }
    }
    private getNavLinks(hdd: HierarchyDataDescriptor) {
        const navLinks = [this.getTaskNavLink(hdd), this.getEntityNavLink(hdd)];
        if (this.event instanceof TaskUpdatedEvent) {
            navLinks.push({
                linkCssClass: 'nav-link-updates',
                parameters: this.attributesUpdates,
            });
        }
        return navLinks;
    }

    private getTaskEntityHdd() {
        return this.event.Task?.HddData?.Parents?.[0];
    }

    private getUpdatedProperties(event: TaskUpdatedEvent) {
        const properties: (keyof EntityTaskDTO)[] = [
            'Title',
            'Text',
            'AssignedToUserId',
            'Status',
            'Response',
            'Type',
            'DueTime',
        ];
        const task = event.Task;
        const prevTask = event.PreviousTask;
        const updates: IAttributeUpdateTranslationInputs[] = properties
            .filter((p) => task?.[p] != prevTask?.[p])
            .map((pn) => this.getAttributeUpdateTranslationInputs(event, pn));
        return updates;
    }

    private getAttributeUpdateTranslationInputs(
        event: TaskUpdatedEvent,
        propertyName: keyof EntityTaskDTO,
    ): IAttributeUpdateTranslationInputs {
        const task = event.Task;
        const prevTask = event.PreviousTask;
        const attributeNameKey = `ObjectTask.${propertyName}`;
        const previousValue = this.getPropertyValue(
            propertyName,
            prevTask?.[propertyName],
        );
        const value = this.getPropertyValue(propertyName, task?.[propertyName]);

        return {
            attributeNameKey,
            previousValue,
            value,
        };
    }

    private getTaskNavLink(hdd: HierarchyDataDescriptor): IDgEventNavLink {
        return {
            ...this.getEntityNavLink(hdd),
            linkCssClass: 'nav-link-task',
            parameters: this.event.Task,
        } as IDgEventNavLink;
    }

    protected getPropertyValue(
        propertyName: keyof EntityTaskDTO,
        value: unknown,
    ): IAttributeTranslationValue {
        if (propertyName == 'AssignedToUserId') {
            return { userGuid: value as string };
        }

        if (propertyName == 'DueTime') {
            return {
                text: DateTimeUtil.formatForDisplay(
                    value as Date,
                    false,
                    moment.locale(),
                    true,
                ),
            };
        }

        if (propertyName == 'Type') {
            value = ObjectTaskType[value as string];
            if (value == undefined) {
                return { text: '' };
            }
            return { translateKey: `DgServerTypes.ObjectTaskType.${value}` };
        }

        if (propertyName == 'Status') {
            value = ObjectTaskStatus[value as string];
            if (value == undefined) {
                return { text: '' };
            }
            return { translateKey: `TaskStatus.${value}` };
        }

        return { richText: value as string };
    }
}
