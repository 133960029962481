import { Pipe, PipeTransform } from '@angular/core';
import { RichTextContent } from './RichTextContent';
import {
    defaultRichTextPipeOptions,
    RichTextPipeOptions,
} from './rich-text.pipe.types';

@Pipe({
    name: 'richText',
    standalone: true,
})
export class RichTextPipe implements PipeTransform {
    transform(
        text: string,
        options: RichTextPipeOptions = defaultRichTextPipeOptions,
    ) {
        return RichTextContent.getRawText(text, options.hideIfNotJsonString);
    }
}
