import { ActivatedRouteSnapshot, Route, Routes } from '@angular/router';
import { inject } from '@angular/core';
import { EntityService } from '../shared/entity/services/entity.service';
import { LineageVersionTogglerService } from '../impactAnalysis/entity-impact-analysis/lineage-version-toggler.service';
import { ImpactAnalysisTool } from '../shared/util/app-types/impact-analysis.types';
import {
    EntityType,
    FirstClassType,
    FirstClassTypeUtil,
    ServerType,
} from '@datagalaxy/dg-object-model';
import { ServerConstants } from '@datagalaxy/shared/server/domain';
import { ModelerService } from '../modeler/services/modeler.service';
import { HddUtil } from '../shared/util/HddUtil';
import { WorkspaceStore } from '@datagalaxy/webclient/workspace/data-access';
import { GlossaryService } from '../glossary/glossary.service';
import { EntityDashboardTab } from './entity-dashboard.types';
import PropertyName = ServerConstants.PropertyName;

function createEntityDashboardGridRoute(
    path: string,
    serverType: ServerType,
    wantedColumns: string[],
    tabContainerEntityServerType: ServerType,
    tab: EntityDashboardTab,
    filterEntityTypes?: EntityType[],
): Route {
    return {
        path,
        loadComponent: () =>
            import(
                './entity-dashboard-entity-grid/entity-dashboard-entity-grid.component'
            ).then((m) => m.EntityDashboardEntityGridComponent),
        resolve: {
            serverType: () => serverType,
            defaultAttributeKeys: () => wantedColumns,
            entityTypes: () => filterEntityTypes,
            gridStatePersistenceId: () =>
                [
                    'EntityTabGrid',
                    FirstClassType[
                        FirstClassTypeUtil.firstClassTypeFromServerType(
                            tabContainerEntityServerType,
                        )
                    ],
                    FirstClassType[
                        FirstClassTypeUtil.firstClassTypeFromServerType(
                            serverType,
                        )
                    ],
                    filterEntityTypes?.join('-'),
                ]
                    .filter((o) => o)
                    .join('.'),
        },
        data: {
            entityDashboardTab: tab,
        },
    };
}

export const entityDetailsRoute: Route = {
    path: 'details',
    loadComponent: () =>
        import(
            '../shared/entity/dxy-entity-details/dxy-entity-details.component'
        ).then((m) => m.DxyEntityDetailsComponent),
    resolve: {
        entityAttributes: (route: ActivatedRouteSnapshot) => {
            const entityService = inject(EntityService);
            const entityData = route.parent.data.entityData;

            return entityService.getEntityAttributesForDetails(
                entityData?.ServerType,
            );
        },
        spaceId: () => inject(WorkspaceStore).currentSpace,
        reloadOnSourceChange: () => true,
    },
    data: {
        entityDashboardTab: EntityDashboardTab.details,
    },
};

export const entityImplementationsRoute: Route = {
    path: 'implementations',
    loadComponent: () =>
        import(
            '../glossary/dxy-glossary-implementation/dxy-glossary-implementation.component'
        ).then((m) => m.DxyGlossaryImplementationComponent),
    resolve: {
        lineageDataSource: (route: ActivatedRouteSnapshot) =>
            inject(GlossaryService).getImplementationLineageDataSource(
                route.parent.data.entityData,
            ),
    },
    data: {
        entityDashboardTab: EntityDashboardTab.implementation,
    },
};

export const entityLineageRoute: Route = {
    path: 'analysis',
    loadComponent: () =>
        import(
            '../impactAnalysis/entity-impact-analysis/entity-impact-analysis.component'
        ).then((m) => m.EntityImpactAnalysisComponent),
    resolve: {
        defaultTool: async () => {
            const lineageVersionTogglerService = inject(
                LineageVersionTogglerService,
            );
            const userPrefersLegacyLineage =
                await lineageVersionTogglerService.shouldUseLegacyLineage();

            return userPrefersLegacyLineage
                ? ImpactAnalysisTool.LineageBoth
                : ImpactAnalysisTool.Lineage;
        },
        selectedTool: (route: ActivatedRouteSnapshot) => {
            const strTool = route.queryParams.tool;
            return strTool
                ? (parseInt(route.queryParams.tool, 10) as ImpactAnalysisTool)
                : undefined;
        },
    },
    data: {
        entityDashboardTab: EntityDashboardTab.impactAnalysis,
    },
};

export const entityDiagram: Route = {
    path: 'diagrams-list',
    loadComponent: () =>
        import('../diagrams/diagrams-list/diagrams-list.component').then(
            (m) => m.DiagramsListComponent,
        ),
    resolve: {
        // TODO: persist old settings
        persistenceGridId: () => 'diagram-list',
        canCreateDiagram: () => true,
    },
    data: {
        entityDashboardTab: EntityDashboardTab.diagram,
    },
};

export const entityLinkedObjects: Route = {
    path: 'links',
    loadComponent: () =>
        import(
            '../shared/entity/dxy-entity-links/dxy-entity-links.component'
        ).then((m) => m.DxyEntityLinksComponent),
    data: {
        entityDashboardTab: EntityDashboardTab.linkedObjects,
    },
};

export const entityDataQualityRoute: Route = {
    path: 'data-quality',
    loadComponent: () =>
        import(
            '../data-quality/data-quality-tab-component/data-quality-tab.component'
        ).then((m) => m.DataQualityTabComponent),
    data: {
        entityDashboardTab: EntityDashboardTab.dataQuality,
    },
};

export const entityMappingRoute: Route = {
    path: 'mapping',
    loadComponent: () =>
        import(
            '../data-processing/mapping/dp-mapping/dp-mapping.component'
        ).then((m) => m.DpMappingComponent),
    data: {
        entityDashboardTab: EntityDashboardTab.mapping,
    },
};

export const entityColumnsRoute: Route = createEntityDashboardGridRoute(
    'columns',
    ServerType.Column,
    [
        'Type',
        'DataTypeDisplayName',
        'Order',
        'TechnicalName',
        'DisplayName',
        'Description',
        'SizeString',
        'IsMandatory',
        'IsTechnicalData',
        'IsForeignKey',
        'IsPrimaryKey',
    ],
    ServerType.Table,
    EntityDashboardTab.columns,
);

export const entityTablesRoute: Route = createEntityDashboardGridRoute(
    'tables',
    ServerType.Table,
    [
        'Type',
        'TechnicalName',
        'DisplayName',
        'Description',
        'TechnicalComments',
    ],
    ServerType.Model,
    EntityDashboardTab.tables,
);

export const entityContainersRoute: Route = createEntityDashboardGridRoute(
    'containers',
    ServerType.Container,
    [
        PropertyName.TechnicalName,
        PropertyName.LegacySubTypeAttributeKey,
        PropertyName.Description,
        PropertyName.LogicalChildrenCount,
        PropertyName.LogicalAllLevelChildrenCount,
    ],
    ServerType.Model,
    EntityDashboardTab.containers,
);

export const entityDashboardFieldsRoute: Route = createEntityDashboardGridRoute(
    'usage-fields',
    ServerType.SoftwareElement,
    ['Description', 'Type'],
    ServerType.SoftwareElement,
    EntityDashboardTab.fields,
    [EntityType.UsageField],
);

export const entityDashboardComponentsRoute: Route =
    createEntityDashboardGridRoute(
        'usage-components',
        ServerType.SoftwareElement,
        ['DisplayName', 'Description', 'ComponentType'],
        ServerType.SoftwareElement,
        EntityDashboardTab.components,
        [EntityType.UsageComponent],
    );

export const entityModelSettings: Route = {
    path: 'settings',
    loadComponent: () =>
        import(
            '../modeler/data-types/dxy-data-type-model-settings/dxy-data-type-model-settings.component'
        ).then((m) => m.DxyDataTypeModelSettingsComponent),
    resolve: {
        modelData: (route: ActivatedRouteSnapshot) =>
            inject(ModelerService).getModelForDataTypeModelSettings(
                route.parent.data.entityData,
            ),
    },
    data: {
        entityDashboardTab: EntityDashboardTab.settings,
    },
};

export const entityDashboardPrimaryKeysRoute: Route = {
    path: 'primary-keys',
    loadComponent: () =>
        import(
            '../modeler/pkfk/dxy-primary-key-grid/dxy-primary-key-grid.component'
        ).then((m) => m.DxyPrimaryKeyGridComponent),
    resolve: {
        modelId: (route: ActivatedRouteSnapshot) => {
            const modelerService = inject(ModelerService);
            const entityData = route.parent.data.entityData;
            const modelId = HddUtil.getModelId(entityData?.HddData);
            return modelerService.loadModelForPrimaryKeyGrid(modelId);
        },
    },
    data: {
        entityDashboardTab: EntityDashboardTab.pks,
    },
};

export const entityDashboardForeignKeysRoute: Route = {
    path: 'foreign-keys',
    loadComponent: () =>
        import(
            '../modeler/pkfk/foreign-key-grid/dxy-foreign-key-grid.component'
        ).then((m) => m.DxyForeignKeyGridComponent),
    resolve: {
        modelData: (route: ActivatedRouteSnapshot) => {
            return inject(ModelerService).getModelForForeignKeyGrid(
                route.parent.data.entityData,
            );
        },
    },
    data: {
        entityDashboardTab: EntityDashboardTab.fks,
    },
};

export const entityDashboardDenodoSample: Route = {
    path: 'sample',
    loadComponent: () =>
        import(
            '../modeler/denodo/dxy-denodo-table-sample/dxy-denodo-table-sample.component'
        ).then((m) => m.DxyDenodoTableSampleComponent),
    data: {
        entityDashboardTab: EntityDashboardTab.sample,
    },
};

export const entityDashboardRoutes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: 'details',
    },
    entityDetailsRoute,
    entityLinkedObjects,
    entityImplementationsRoute,
    entityDiagram,
    entityLineageRoute,
    entityDataQualityRoute,
    entityMappingRoute,
    entityContainersRoute,
    entityTablesRoute,
    entityColumnsRoute,
    entityModelSettings,
    entityDashboardFieldsRoute,
    entityDashboardComponentsRoute,
    entityDashboardPrimaryKeysRoute,
    entityDashboardForeignKeysRoute,
    entityDashboardDenodoSample,
];
