import { EntityItem } from '@datagalaxy/webclient/entity/domain';

export enum EntityDashboardTab {
    unknown = 0,
    //#region glossary, usage, processing, catalog
    details,
    implementation,
    impactAnalysis,
    //#endregion
    //#region glossary, usage, processing
    mapping,
    linkedObjects,
    //#endregion
    // usage
    fields,
    components,
    //#region catalog
    dataQuality,
    diagram,
    settings,
    columns,
    containers,
    tables,
    pks,
    fks,
    sample,
    //#endregion
}

export interface EntityDashboardTabInfos {
    id: EntityDashboardTab;
    stateName: string;
    getTranslateKey: (entity: EntityItem) => string;
    getCount?: (entity: EntityItem) => number;
    getHidden?: (entity: EntityItem) => boolean;
}
