import { BaseUnitaryFieldData } from './BaseUnitaryFieldData';
import { IUnitaryFieldData } from './IUnitaryFieldData';
import { UnitaryFieldValidator } from './UnitaryFieldValidator';

/** Object that:
 * - holds a value (string, number, boolean)
 * - knows if it has been modified
 * - supports validation, save and undo (using UnitaryFieldValidator)
 * - can be passed to dxy-unitary-field-actions as its input */
export class ValueUnitaryFieldData<
    T extends string | number | boolean,
> extends BaseUnitaryFieldData<T> {
    constructor(
        fieldKey: string,
        validator?: UnitaryFieldValidator<IUnitaryFieldData>,
    ) {
        super(fieldKey, validator);
    }

    public setInitialValue(value: T) {
        super.setInitialValue(value);
        this.currentValue = this.initialValue;
    }

    public isDirty() {
        return this.currentValue != this.initialValue;
    }
    public resetAfterUndo() {
        this.currentValue = this.initialValue;
    }
    public resetAfterSave() {
        this.initialValue = this.currentValue;
    }
}
