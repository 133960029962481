import { CollectionsHelper } from '@datagalaxy/core-util';
import {
    ProjectVersion,
    ProjectVersionStatus,
} from '@datagalaxy/webclient/versioning/data-access';
import { IWorkspaceIdentifier } from '@datagalaxy/webclient/workspace/domain';

export class VersionStore {
    private readonly versionsById: Map<string, ProjectVersion>;

    constructor() {
        this.versionsById = new Map<string, ProjectVersion>();
    }

    public init(versions: ProjectVersion[]) {
        this.clear();
        versions.forEach((version) => this.set(version));
    }

    public set(newVersion: ProjectVersion) {
        this.versionsById.set(newVersion.ProjectVersionId, newVersion);
    }

    public removeById(versionId: string) {
        this.versionsById.delete(versionId);
    }

    private hasByStatus(
        versionStatus: ProjectVersionStatus,
        predicate?: (version: ProjectVersion) => boolean,
    ) {
        return this.getByStatus(versionStatus, predicate).length > 0;
    }

    private getByStatus(
        versionStatus: ProjectVersionStatus,
        predicate?: (version: ProjectVersion) => boolean,
    ) {
        return CollectionsHelper.filterMap(
            this.versionsById,
            (version) =>
                version.VersionStatus === versionStatus &&
                (!predicate || predicate(version)),
        );
    }

    public hasActive() {
        return this.hasByStatus(ProjectVersionStatus.Active);
    }

    public getActives() {
        return this.getByStatus(ProjectVersionStatus.Active);
    }

    public hasOfficial() {
        return this.hasByStatus(ProjectVersionStatus.Official);
    }

    public getOfficial() {
        const officialVersions = this.getByStatus(
            ProjectVersionStatus.Official,
        );
        return officialVersions[0];
    }

    public hasArchived() {
        return this.hasByStatus(ProjectVersionStatus.Archived);
    }

    public getAllArchived() {
        return this.getByStatus(ProjectVersionStatus.Archived);
    }

    public hasReleaseCandidate() {
        return this.hasByStatus(ProjectVersionStatus.ReleaseCandidate);
    }

    public getReleaseCandidates() {
        return this.getByStatus(ProjectVersionStatus.ReleaseCandidate);
    }

    public isEmpty() {
        return this.versionsById.size == 0;
    }
    public hasAny(
        statuses: ProjectVersionStatus[],
        predicate?: (version: ProjectVersion) => boolean,
    ) {
        return statuses.some((s) => this.hasByStatus(s, predicate));
    }

    public clear() {
        this.versionsById.clear();
    }

    public getUserDefault() {
        return CollectionsHelper.findFirstInMap(
            this.versionsById,
            (v) => v.IsUserDefaultVersion,
        );
    }

    public getFirst() {
        return this.versionsById.values().next().value as ProjectVersion;
    }

    public getById(versionId: string) {
        return this.versionsById.get(versionId);
    }
    public updateUserDefaultVersion(versionId: string) {
        this.versionsById.forEach((version) => {
            version.IsUserDefaultVersion =
                version.ProjectVersionId === versionId;
        });
    }

    public getBySpaceIdentifier(spaceIdr: IWorkspaceIdentifier) {
        if (!spaceIdr || !spaceIdr.spaceId || !spaceIdr.versionId) {
            return undefined;
        }
        const pv = this.versionsById.get(spaceIdr.versionId);
        return pv && pv.spaceId == spaceIdr.spaceId ? pv : undefined;
    }

    public getState(): IVersionStoreState {
        return {
            isEmpty: this.isEmpty(),
            hasOfficial: this.hasOfficial(),
            hasActive: this.hasActive(),
            hasReleaseCandidates: this.hasReleaseCandidate(),
            hasArchived: this.hasArchived(),
            official: this.getOfficial(),
            actives: this.getActives(),
            releaseCandidates: this.getReleaseCandidates(),
            archived: this.getAllArchived(),
        };
    }
}

export interface IVersionStoreState {
    isEmpty?: boolean;
    hasOfficial?: boolean;
    hasActive?: boolean;
    hasArchived?: boolean;
    hasReleaseCandidates?: boolean;
    official?: ProjectVersion;
    actives?: ProjectVersion[];
    releaseCandidates?: ProjectVersion[];
    archived?: ProjectVersion[];
}
