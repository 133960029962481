import { Injectable } from '@angular/core';
import { SignalRService } from '@datagalaxy/data-access';
import { EventLog } from '@datagalaxy/dg-object-model';
import {
    LiveImportTranslationEventName,
    TranslationBatchEventName,
} from '@datagalaxy/webclient/multilingual/domain';
import { filter } from 'rxjs';
import { MultilingualStateService } from './multilingual-state.service';
import { MultilingualApiService } from '@datagalaxy/webclient/multilingual/data-access';
import { byEventType } from './multilingual-realtime-service.utils';

@Injectable({
    providedIn: 'root',
})
export class MultilingualRealtimeService {
    constructor(
        private signalRservice: SignalRService,
        private stateService: MultilingualStateService,
        private multilingualApiService: MultilingualApiService,
    ) {}

    public startEventLogListening() {
        this.signalRservice.startConnection();
        this.signalRservice.eventLog$
            .pipe(filter(byEventType))
            .subscribe((eventLog) => {
                this.handleEventLog(eventLog);
            });
    }

    private handleEventLog(eventLog: EventLog) {
        switch (eventLog.eventType) {
            case TranslationBatchEventName.Completed:
            case TranslationBatchEventName.Created:
                this.refreshPendingBatches();
                break;
            case LiveImportTranslationEventName.Started:
                this.stateService.addLiveImportTranslation(
                    eventLog.objectId ?? '',
                );
                break;
            case LiveImportTranslationEventName.Completed:
                this.stateService.removeLiveImportTranslation(
                    eventLog.objectId ?? '',
                );
        }
    }

    private async refreshPendingBatches() {
        const pendingBatches =
            await this.multilingualApiService.getPendingBatches();
        this.stateService.updatePendingBatches(pendingBatches);
    }
}
