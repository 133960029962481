import { Component, inject, OnInit } from '@angular/core';
import {
    DgModule,
    DgModuleDefinition,
    getModuleDefinition,
} from '@datagalaxy/shared/dg-module/domain';
import { BaseCellComponent } from '@datagalaxy/core-ui';
import { INavLinkCellParams } from '../nav-link.types';
import { getReferenceId } from '@datagalaxy/utils';
import { WorkspaceIdentifier } from '@datagalaxy/webclient/workspace/utils';
import { TranslateModule } from '@ngx-translate/core';
import { EllipsisTooltipDirective } from '@datagalaxy/ui/tooltip';
import { NgClass } from '@angular/common';
import { ModuleService } from '../../../module/module.service';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'dxy-nav-link-module',
    templateUrl: './nav-link-module.component.html',
    styleUrls: ['../nav-link.scss'],
    standalone: true,
    imports: [NgClass, EllipsisTooltipDirective, TranslateModule],
})
export class NavLinkModuleComponent
    extends BaseCellComponent<
        unknown,
        INavLinkModuleParameter,
        INavLinkCellParams<INavLinkModuleParameter>
    >
    implements OnInit
{
    private moduleService = inject(ModuleService);
    protected moduleDefinition!: DgModuleDefinition;

    constructor() {
        super();
    }

    protected get glyphClass() {
        return this.moduleDefinition.glyphClass;
    }

    protected get translateKey() {
        return this.moduleDefinition?.translateKey;
    }

    protected async gotoModule() {
        const spaceIdr = new WorkspaceIdentifier(
            getReferenceId(this.value.spaceId, this.value.spaceId),
            this.value.versionId,
        );
        return await this.moduleService.goToModule(
            this.moduleDefinition.name,
            spaceIdr,
        );
    }

    ngOnInit() {
        this.moduleDefinition = getModuleDefinition(
            DgModule[this.value.module],
        );
    }
}

export interface INavLinkModuleParameter {
    module: DgModule;
    spaceId: string;
    versionId: string;
}
