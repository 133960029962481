import {
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { TeamService } from '../team.service';
import { UserCellComponent } from '@datagalaxy/webclient/user/ui';
import {
    TeamDto,
    TeamMembershipRequest,
    UpdateMembershipRequestType,
} from '@datagalaxy/webclient/team/data-access';
import {
    CrudActionType,
    CrudOperation,
    FunctionalLogService,
} from '@datagalaxy/shared/monitoring/data-access';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { GridConfig, TColDef } from '@datagalaxy/ui/grid';
import { IActionOption } from '@datagalaxy/core-ui';
import { GridComponent } from '@datagalaxy/ui/grid';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-team-requests',
    templateUrl: 'team-requests.component.html',
    styleUrls: ['team-requests.component.scss'],
    standalone: true,
    imports: [NgIf, GridComponent, TranslateModule],
})
export class TeamRequestsComponent
    extends DxyBaseComponent
    implements OnInit, OnChanges
{
    @Input() teamData: TeamDto;
    @Input() teamRequests: TeamMembershipRequest[] = [];

    protected gridConfig: GridConfig<TeamMembershipRequest> = {
        getItemId: (row) => row.UserId,
        sorting: {
            activeSort: {
                columnId: 'FullName',
            },
        },
    };

    protected columns: TColDef<TeamMembershipRequest>[] = [
        {
            id: 'FullName',
            headerLabel: this.translate.instant('UI.Teams.Members.nameColumn'),
            customCellComponent: UserCellComponent,
            getInputs: (request: TeamMembershipRequest) => ({
                userId: request.UserId,
                actions: [
                    {
                        glyphClass: 'glyph-check validate-btn',
                        callback: () => {
                            this.functionalLogService.logFunctionalAction(
                                'TEAM_ACCESS_REQUEST',
                                CrudOperation.A,
                                CrudActionType.Accept,
                            );
                            this.teamService.updateTeamMembershipRequest(
                                this.teamData.TeamUid,
                                UpdateMembershipRequestType.Accept,
                                request.UserId,
                            );
                        },
                        tooltipText: this.translate.instant(
                            'UI.Teams.Requests.acceptLbl',
                        ),
                    },
                    {
                        glyphClass: 'glyph-cancelsearch reject-btn',
                        callback: () => {
                            this.functionalLogService.logFunctionalAction(
                                'TEAM_ACCESS_REQUEST',
                                CrudOperation.A,
                                CrudActionType.Decline,
                            );
                            this.teamService.updateTeamMembershipRequest(
                                this.teamData.TeamUid,
                                UpdateMembershipRequestType.Reject,
                                request.UserId,
                            );
                        },
                        tooltipText: this.translate.instant(
                            'UI.Teams.Requests.rejectLbl',
                        ),
                    },
                ] as IActionOption[],
            }),
        },
    ];

    public hasRequests: boolean;

    constructor(
        private translate: TranslateService,
        private teamService: TeamService,
        private functionalLogService: FunctionalLogService,
    ) {
        super();
    }

    ngOnChanges(changes: SimpleChanges) {
        super.onChange(
            changes,
            'teamRequests',
            async () => await this.initAsync(),
        );
    }

    ngOnInit() {
        this.initAsync().then();
    }

    private async initAsync() {
        this.hasRequests = !!this.teamRequests?.length;
        if (!this.hasRequests) {
            return;
        }
    }
}
