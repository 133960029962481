import { deserialize, deserializeAs, inheritSerialization } from 'cerialize';
import {
    BaseDTO,
    EntityType,
    HierarchicalData,
} from '@datagalaxy/dg-object-model';
import { FilterModuleName } from './filtered-view-module';
import { CollectionsHelper } from '@datagalaxy/core-util';
import { Filter, IHasFilters } from './filter';
import { DgZone } from '@datagalaxy/webclient/domain';

@inheritSerialization(BaseDTO)
export class FilteredViewDto extends BaseDTO implements IHasFilters {
    @deserialize public FilteredViewId = -1;
    @deserialize public FilteredViewUid?: string;
    @deserializeAs(FilterModuleName) public ModuleName?: FilterModuleName;
    @deserialize public DgZone: DgZone;
    @deserialize public DisplayName: string;
    @deserialize public Description?: string;
    @deserialize public IsPrivate?: boolean;
    @deserialize public SpaceUid?: string;
    /**
     * This corresponds to the filtered view's default space versionId
     * This is not the filtered view versionId, this info is not saved
     */
    @deserialize public DefaultVersionId?: string;
    @deserialize public IsFavorite: boolean;
    @deserializeAs(HierarchicalData) public HddData?: HierarchicalData;
    @deserializeAs(Filter) public ListFilter: Filter[];

    public autoEntityTypeFilter?: EntityType | null;

    /** unchanged list of the filters from the Db */
    public savedListFilter: Filter[];
    public savedSpaceUid?: string;
    public savedDefaultVersionId?: string;
    public entitiesCount?: number;

    public get hasSavedFilters() {
        return this.savedListFilter.length > 0;
    }

    public get filters() {
        return this.ListFilter;
    }

    public set filters(filters: Filter[]) {
        this.ListFilter = filters;
    }

    public static equals(a: FilteredViewDto, b: FilteredViewDto) {
        if (a === b) {
            return true;
        }
        return (
            !!(a && b) &&
            a.FilteredViewId == b.FilteredViewId &&
            a.FilteredViewUid == b.FilteredViewUid &&
            a.ModuleName == b.ModuleName &&
            a.DgZone == b.DgZone &&
            a.DisplayName == b.DisplayName &&
            a.Description == b.Description &&
            a.IsPrivate == b.IsPrivate &&
            a.SpaceUid == b.SpaceUid &&
            a.DefaultVersionId == b.DefaultVersionId &&
            a.IsFavorite == b.IsFavorite &&
            a.CreationUserId == b.CreationUserId &&
            a.autoEntityTypeFilter == b.autoEntityTypeFilter &&
            CollectionsHelper.contentEquals(
                a.ListFilter,
                b.ListFilter,
                true,
                true,
                (a, b) => Filter.equals(a, b, true),
            ) &&
            a.savedDefaultVersionId == b.savedDefaultVersionId &&
            CollectionsHelper.contentEquals(
                a.savedListFilter,
                b.savedListFilter,
                true,
                true,
                (a, b) => Filter.equals(a, b, true),
            )
        );
    }

    constructor(dgZone: DgZone, displayName: string) {
        super();
        this.DgZone = dgZone;
        this.DisplayName = displayName;
        this.ListFilter = [];
        this.savedListFilter = [];
        this.IsFavorite = false;
    }

    public init() {
        this.savedListFilter = this.ListFilter.slice();
        this.savedSpaceUid = this.SpaceUid;
        this.savedDefaultVersionId = this.DefaultVersionId;
        return this;
    }

    public reset() {
        this.ListFilter = this.savedListFilter.slice();
        this.SpaceUid = this.savedSpaceUid;
        this.DefaultVersionId = this.savedDefaultVersionId;
        this.autoEntityTypeFilter = null;
        return this;
    }

    public copyTo(
        other: FilteredViewDto,
        opt?: {
            includeListFilter?: boolean;
            includeSavedListFilter?: boolean;
            includeViewId?: boolean;
        },
    ) {
        if (!other) {
            return;
        }
        other.DisplayName = this.DisplayName;
        other.Description = this.Description;
        other.IsPrivate = this.IsPrivate;
        other.IsFavorite = this.IsFavorite;
        other.ModuleName = this.ModuleName;
        if (opt?.includeListFilter) {
            other.ListFilter = this.ListFilter.slice();
        }
        if (opt?.includeSavedListFilter) {
            other.savedListFilter = this.savedListFilter.slice();
        }
        if (opt?.includeViewId) {
            other.FilteredViewId = this.FilteredViewId;
        }
    }
}
