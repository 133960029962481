import { Inject, Injectable } from '@angular/core';
import { IEntityIdentifier } from '@datagalaxy/dg-object-model';
import {
    COMMENTARY_MODAL_SERVICE_TOKEN,
    CommentaryModalService,
} from '@datagalaxy/webclient/comment/feature';
import { EntityLoader } from '@datagalaxy/webclient/entity/feature';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class ReportAiGeneratedTextService {
    constructor(
        @Inject(COMMENTARY_MODAL_SERVICE_TOKEN)
        private commentaryModalService: CommentaryModalService,
        private entityLoader: EntityLoader,
        private translate: TranslateService,
    ) {}

    public async reportDescription(entityIdr: IEntityIdentifier) {
        await this.report(
            entityIdr,
            'Suggestions.AutoDescription.reportDescriptionComment',
        );
    }

    public async reportSummary(entityIdr: IEntityIdentifier) {
        await this.report(
            entityIdr,
            'Suggestions.AutoDescription.reportSummaryComment',
        );
    }

    private async report(entityIdr: IEntityIdentifier, translationKey: string) {
        const message = this.translate.instant(translationKey);
        const entityData = await this.entityLoader.loadEntity(entityIdr);
        const owners = entityData.getDataOwners().map((id) => id.split(':')[1]);
        this.commentaryModalService.openCommentaryNewModal({
            entityData,
            message,
            targetUserIds: owners,
        });
    }
}
