<div *ngIf="showHeader" class="panel-content-tool">
    <div class="panel-content-toolname" translate>
        UI.EntityDockingPane.Tool.ActivityLog
    </div>
    <div class="actions-container">
        <button
            *ngIf="!isLoadingMessageDisplayed"
            dxyIconButton
            variant="tertiary"
            (click)="updateActivityLogEntries()"
            [matTooltip]="
                'UI.ActivityLogData.ReloadActivityLogData' | translate
            "
            matTooltipPosition="before"
            dxyLogFunctional="REFRESH_ACTIVITY_LOG,R"
        >
            <i class="glyph-reload"></i>
        </button>
    </div>
</div>

<div class="content-container panel-content-body">
    <div class="filter-wrapper">
        <dxy-value-list-filter
            labelKey="UI.SpaceHome.Widgets.SpaceActivityLogs.Preview.UserFilter"
            [items]="userFilterOptions"
            [filter]="userFilter"
            [noOperator]="true"
            [adapter]="userFilterAdapter"
            [hideIcon]="true"
            [hasRemove]="hasUserFilter"
            (remove)="clearUserFilterAndReload()"
            (filterChange)="onUserFilterChange($event)"
        >
            <dxy-user-cell
                *ngIf="userId"
                [userId]="userId"
                [hideUserName]="true"
                [mini]="true"
                customIconTemplate
            ></dxy-user-cell>
        </dxy-value-list-filter>
        <dxy-date-filter
            labelKey="UI.SpaceHome.Widgets.SpaceActivityLogs.Preview.DateFilter"
            [filter]="dateFilter"
            [hasRemove]="hasDateFilter"
            [noOperator]="true"
            (filterChange)="onDateFilterChange($event)"
            (remove)="clearDateFilterAndReload()"
        ></dxy-date-filter>
        <div
            *ngIf="isErrorMessageDisplayed"
            class="alert alert-warning"
            role="alert"
        >
            <span translate>UI.ActivityLogData.RequestErrorMsg</span>
        </div>
    </div>
    <div
        *ngIf="!isLoadingMessageDisplayed"
        class="activity-body-main scroll-container"
        (scroll)="onScroll($event)"
    >
        <div *ngIf="isEmptyMessageDisplayed" class="dxy-placeholder">
            <img
                src="/images/placeholders/activity-log.svg"
                class="dxy-placeholder-img"
                alt="No log"
            />
            <div class="dxy-placeholder-text" translate>
                UI.ActivityLogData.NoLogItemsMsg
            </div>
        </div>
        <dxy-activity-log-element
            *ngFor="let element of activityLogElements"
            [activityLogEntryElement]="element"
            [disableNavigation]="disableNavigation"
        ></dxy-activity-log-element>
    </div>
    <dxy-spinner *ngIf="isLoadingMessageDisplayed"></dxy-spinner>
</div>
