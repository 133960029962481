import { Component, EventEmitter, Input, Output } from '@angular/core';
import { fadeOutAtEnterAnimation } from '@datagalaxy/ui/animation';
import { IUnitaryFieldActionsInput } from '../IUnitaryFieldActionsInput';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { TranslateModule } from '@ngx-translate/core';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';
import { DxyDataTestIdDirective } from '@datagalaxy/ui/testing';
import { NgIf, NgClass } from '@angular/common';

/** Validating icons for a unitarily managed field (any dxy-attribute-*-input) */
@Component({
    selector: 'dxy-unitary-field-actions',
    templateUrl: './dxy-unitary-field-actions.component.html',
    animations: [fadeOutAtEnterAnimation()],
    standalone: true,
    imports: [
        NgIf,
        NgClass,
        DxyDataTestIdDirective,
        MatLegacyTooltipModule,
        TranslateModule,
    ],
})
export class DxyUnitaryFieldActionsComponent extends DxyBaseComponent {
    @Input() input: IUnitaryFieldActionsInput;
    @Input() hasToolbarAction: boolean;
    @Input() toolbarActionGlyphClass: string;

    @Output() readonly toolbarAction = new EventEmitter<void>();

    public get hasLeftActions() {
        return this.hasToolbarAction;
    }
    public get isRestoreVisible() {
        return this.isActive && !!this.input.canRestorePreviousValue;
    }
    public get isUndoVisible() {
        return this.input.isUndoVisible?.();
    }
    public get isValidateVisible() {
        return this.input.isValidateVisible?.();
    }
    public get hasUnitaryValidation() {
        return this.input.hasUnitaryValidation?.();
    }
    public get isValidated() {
        return this.input.isValidated;
    }
    public get isActive() {
        return this.input.isActive;
    }
    public get isValidating() {
        return this.input.isValidating;
    }
    public get isErrorValidating() {
        return this.input.isErrorInValidating;
    }
    public get recentlyModifiedExternally() {
        return this.input.recentlyModifiedExternally;
    }

    constructor() {
        super();
    }

    public onClickRestore(event: PointerEvent) {
        event.preventDefault();
        this.input.restorePreviousValue?.();
    }
    public onClickUndo(event: PointerEvent) {
        event.preventDefault();
        this.input.undo?.();
    }
    public onClickValidate(event: PointerEvent) {
        event.preventDefault();
        this.input.validate?.();
    }
    public onClickToolbarAction(event: PointerEvent) {
        event.preventDefault();
        this.toolbarAction.emit();
    }
}
