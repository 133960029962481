<div class="modal-header">
    <h3 mat-dialog-title class="modal-title" translate>
        UI.Glossary.components.glossaryLinksGenerationModal.title
    </h3>
    <button dxyIconButton color="transparent" (click)="onCloseCancel()">
        <i class="glyph-cancelsearch"></i>
    </button>
</div>

<!-- Help Infos -->
<ng-container *ngIf="showHelp">
    <div mat-dialog-content class="help-info">
        <img [src]="helpImage" />
        <h2 class="help-title" translate>
            UI.Glossary.components.glossaryLinksGenerationModal.helpInfos.subTitle
        </h2>
        <p class="help-description" translate>
            UI.Glossary.components.glossaryLinksGenerationModal.helpInfos.description
        </p>
        <div>
            <a
                dxyButton
                variant="secondary"
                dxyFreshdeskHelpLink
                [freshDeskArticle]="this.LinkSuggestionHelpdeskArticle"
            >
                <i class="glyph-help"></i>
                <span translate
                    >UI.Glossary.components.glossaryLinksGenerationModal.helpInfos.onlineHelp</span
                >
            </a>
        </div>
    </div>
    <div class="modal-footer dxy-actions">
        <mat-checkbox class="skip-step" [(ngModel)]="skipThisStep">
            <span translate
                >UI.Glossary.components.glossaryLinksGenerationModal.helpInfos.skipStep</span
            >
        </mat-checkbox>
        <button
            dxyButton
            variant="secondary"
            type="button"
            (click)="onCloseCancel()"
        >
            <span translate>UI.Global.btnCancel</span>
        </button>
        <button dxyButton type="submit" (click)="goNextStep()">
            <span translate>UI.Global.btnNext</span>
        </button>
    </div>
</ng-container>
<!-- End Help Infos -->

<form *ngIf="!showHelp" [formGroup]="formGroup" (ngSubmit)="onSubmit()">
    <div mat-dialog-content>
        <div class="links-source-container">
            <img src="/images/glossary-links-generation/metabot.svg" />
            <div class="links-source">
                <i [ngClass]="sourceGlyphClass"></i>
                <span>{{ sourceName }}</span>
            </div>
            <img src="/images/glossary-links-generation/separator.svg" />
        </div>
        <dxy-entity-selector-field
            formControlName="targets"
            labelKey="UI.Glossary.components.glossaryLinksGenerationModal.form.targets"
            [options]="targetsSelectOptions"
            [menuTakeFullWidth]="true"
            [isMultiValue]="true"
            [mandatory]="true"
            [hint]="
                'UI.Glossary.components.glossaryLinksGenerationModal.form.targetsHint'
                    | translate
            "
            [openPreviewOnSelectedItemClick]="true"
            (openClose)="onPanelOpenClose($event)"
        ></dxy-entity-selector-field>
    </div>
    <div class="modal-footer dxy-actions">
        <div class="mandatory-field">
            <span translate>UI.EntityCreateModal.mandatoryField</span>
        </div>
        <button
            dxyButton
            variant="secondary"
            type="button"
            (click)="goPreviousStep()"
        >
            <span translate>UI.Global.btnPrevious</span>
        </button>
        <button
            dxyButton
            type="submit"
            [disabled]="!formGroup.valid || loadingValue"
            dxyLogFunctional="GENERATE_GLOSSARY,C"
        >
            <span translate
                >UI.Glossary.components.generateFromSourceModal.form.generate</span
            >
        </button>
    </div>
</form>
