import { TooltipPosition } from '../tooltip/tooltip-position';
import { InjectionToken } from '@angular/core';

export interface TooltipOptions {
    /**
     * Default tooltip position
     */
    position: TooltipPosition;
    /**
     * Delay in ms before showing the tooltip
     */
    showDelay: number;
    /**
     * Delay in ms before hiding the tooltip
     */
    hideDelay: number;
}

export const DXY_TOOLTIP_OPTIONS = new InjectionToken<TooltipOptions>(
    'DXY_TOOLTIP_OPTIONS',
    {
        providedIn: 'root',
        factory: () => ({
            position: 'above',
            showDelay: 500,
            hideDelay: 500,
        }),
    },
);
