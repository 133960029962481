import {
    deserialize,
    deserializeAs,
    inheritSerialization,
    SerializableEnumeration,
} from 'cerialize';
import { CoreUtil } from '@datagalaxy/core-util';
import { ModelType, ServerType } from '@datagalaxy/dg-object-model';
import {
    BaseMasterData,
    IHas$type,
    IIsData,
    ITypeMetadata,
} from '../master-data';
import { ITable, Table, TableRef } from '../table';
import { Column } from '../column';
import { ForeignKey } from '../foreign-key';
import { PrimaryKey } from '../primary-key';
import {
    CModelSettings,
    IModelSettings,
    ModelSettingsRef,
} from '../model-settings';
import { CRefData, IRef, Ref, subRef } from '../ref';

export enum ContainerType {
    Model = 1,
    Directory = 2,
    Equipment = 3,
}
SerializableEnumeration(ContainerType);

export interface IModel {
    ReferenceId: string;
    Type: ModelType;
    SchemaName: string;
    DisplayName: string;
    Description: string;
    SettingsRef: IRef<IModelSettings>;
    Tables: IRef<ITable>[];
}

/* Note: Model content is received via the Data/LoadData api route, from masterDataService.getModelWithoutTables.
Model content objects are loaded with calls to other routes */
export class Model extends BaseMasterData implements IModel {
    static readonly tmd: ITypeMetadata = {
        parentPropertyName: 'Models',
        childPropertyName: 'ProjectRef',
    };
    static readonly keys: (keyof IModel)[] = [
        'Type',
        'SchemaName',
        'DisplayName',
        'Description',
        'SettingsRef',
        'Tables',
    ];

    //#region IModel
    @deserializeAs(Number) public Type = ModelType.Relational;
    @deserialize public SchemaName!: string;
    @deserialize public DisplayName!: string;
    @deserialize public Description!: string;
    @deserializeAs(ModelSettingsRef.fromId, 'SettingsId') public SettingsRef =
        new ModelSettingsRef();
    @deserializeAs(TableRef.fromIds, 'TableIds') public Tables: TableRef[] = []; //#abasedata-metadata
    //#endregion

    public readonly ServerType = ServerType.Model;

    static from(o: IModel & Partial<IHas$type>) {
        return CoreUtil.buildPartial(
            Model,
            o,
            Model.keys,
            o?.ReferenceId,
            o?.$type,
        );
    }

    public get SubTypeName() {
        return ModelType[this.Type];
    }

    public get TypeName() {
        return ServerType[this.ServerType];
    }

    constructor(id?: string, $type?: string) {
        super(id, $type);
    }
}

/** Returned by the server when the content of a model is modified. Received from:
 * - api: in a ModelerApiResult
 * - real-time: as is
 */
export class ModelerData {
    @deserialize public VersionId!: string;
    @deserializeAs(Table) public Tables!: Table[];
    @deserializeAs(Column) public Columns!: Column[];
    @deserializeAs(PrimaryKey) public PrimaryKeys!: PrimaryKey[];
    @deserializeAs(ForeignKey) public ForeignKeys!: ForeignKey[];
}

/** LoadDataResult Model legacy generic format adapter - for the Data/LoadData route */
@inheritSerialization(CRefData)
export class CModel extends CRefData<Model> implements IModel, IIsData {
    //static OnDeserialized(instance: any, json: any): void { console.log('OnDeserialized', instance, json) }

    readonly ctorType = Model;
    readonly serverType = ServerType.Model;
    @deserializeAs(subRef(CModelSettings), 'SettingsRef')
    private cModelSettings!: CModelSettings;
    //#region IModel
    @deserializeAs(Number, 'ModelType') Type!: ModelType;
    @deserialize SchemaName!: string;
    @deserialize DisplayName!: string;
    @deserialize Description!: string;

    get SettingsRef() {
        return this.cModelSettings?.asRef();
    }

    readonly Tables: Ref<Table>[] = [];

    //#endregion
    asData() {
        return Model.from(this);
    }

    protected getProducers() {
        return [this.cModelSettings];
    }
}
