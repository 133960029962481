import {
    deserialize,
    deserializeAs,
    inheritSerialization,
    SerializableEnumeration,
    serialize,
    serializeAs,
} from 'cerialize';
import {
    BaseServiceParameter,
    BaseServiceResult,
} from '@datagalaxy/data-access';
import {
    Workspace,
    SpaceSecurityProfileType,
    WorkspaceDetails,
} from '@datagalaxy/webclient/workspace/domain';

@inheritSerialization(BaseServiceResult)
export class LoadSpacesResult extends BaseServiceResult {
    @deserializeAs(Workspace) public Projects!: Workspace[];

    constructor() {
        super();
    }
}

@inheritSerialization(BaseServiceParameter)
export class CreateSpaceParameter extends BaseServiceParameter {
    @serialize public DisplayName: string;
    @serialize public Description: string;
    @serialize public DefaultOwnerUserId: string;
    @serialize public DefaultStewardUserId: string;
    @serializeAs(SpaceSecurityProfileType)
    public SecurityProfileType: SpaceSecurityProfileType;
    @serialize public Trigram: string;

    constructor(
        projectName: string,
        projectTrigram: string,
        projectDescription: string,
        defaultDataOwnerUserId: string,
        defaultDataStewardUserId: string,
        securityProfileType: SpaceSecurityProfileType = SpaceSecurityProfileType.Private,
    ) {
        super();
        this.DisplayName = projectName;
        this.Trigram = projectTrigram;
        this.Description = projectDescription;
        this.DefaultOwnerUserId = defaultDataOwnerUserId;
        this.DefaultStewardUserId = defaultDataStewardUserId;
        this.SecurityProfileType = securityProfileType;
    }
}

export enum PreSetupSpaceTrigramStatus {
    Ok,
    Exists,
    InvalidSpaceName,
    InvalidTrigram,
}
SerializableEnumeration(PreSetupSpaceTrigramStatus);

@inheritSerialization(BaseServiceResult)
export class PreSetupSpaceTrigramResult extends BaseServiceResult {
    @deserialize public Trigram!: string;
    @deserializeAs(PreSetupSpaceTrigramStatus)
    public PreSetupSpaceTrigramStatus!: PreSetupSpaceTrigramStatus;
}

@inheritSerialization(BaseServiceParameter)
export class UpdateSpaceParameter extends BaseServiceParameter {
    @serialize public SpaceId: string;
    @serialize public DisplayName: string | null;
    @serialize public Description: string | null;
    @serialize public IsDefaultSpace?: boolean;
    @serialize public Trigram: string | null;
    @serializeAs(SpaceSecurityProfileType)
    public SecurityProfileType?: SpaceSecurityProfileType;

    constructor(
        spaceId: string,
        displayName: string | null,
        trigram: string | null,
        description: string | null,
        isDefaultSpace?: boolean,
        securityProfileType?: SpaceSecurityProfileType,
    ) {
        super();
        this.SpaceId = spaceId;
        this.DisplayName = displayName;
        this.Trigram = trigram;
        this.Description = description;
        this.IsDefaultSpace = isDefaultSpace;
        this.SecurityProfileType = securityProfileType;
    }
}

@inheritSerialization(BaseServiceParameter)
export class CheckSpaceTrigramValidityParameter extends BaseServiceParameter {
    @serialize public SpaceId: string;
    @serialize public Trigram: string;

    constructor(spaceId: string, trigram: string) {
        super();
        this.SpaceId = spaceId;
        this.Trigram = trigram;
    }
}

export class UpdateSpaceDto {
    @deserialize public SpaceId!: string;
    @deserialize public DataTypeName!: string;
    @deserialize public DisplayName!: string;
    @deserialize public Description!: string;
    @deserialize public Trigram!: string;
}

@inheritSerialization(BaseServiceParameter)
export class LoadProjectParameter extends BaseServiceParameter {
    @serialize public ProjectReferenceId?: string | null;

    constructor(projectId?: string | null, versionId?: string | null) {
        super();
        this.ProjectReferenceId = projectId;
        this.VersionId = versionId || '';
    }
}

@inheritSerialization(BaseServiceResult)
export class LoadProjectResult extends BaseServiceResult {
    @deserialize public IsBadProjectId!: boolean;
    @deserializeAs(WorkspaceDetails) public Project!: WorkspaceDetails;
}

@inheritSerialization(BaseServiceResult)
export class CreateProjectResult extends BaseServiceResult {
    @deserialize public IsErrorBadParentId!: string;
    @deserializeAs(WorkspaceDetails) public Project!: WorkspaceDetails;
}

@inheritSerialization(BaseServiceParameter)
export class DeleteProjectParameter extends BaseServiceParameter {
    @serialize public ParentOrganizationId: string;
    @serialize public ProjectId: string;

    constructor(parentOrganizationReferenceId: string, projectId: string) {
        super();
        this.ParentOrganizationId = parentOrganizationReferenceId;
        this.ProjectId = projectId;
    }
}

@inheritSerialization(BaseServiceResult)
export class DeleteProjectResult extends BaseServiceResult {
    @deserialize public DeletedProjectId!: string;
}
