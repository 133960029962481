import { Injectable } from '@angular/core';
import { BackendApiService } from '@datagalaxy/data-access';
import {
    CreateAttributeParameter,
    DeleteAttributeParameter,
    GenericAttributeResult,
    GetAttributesParameter,
    GetAttributesResult,
    GetModuleAttributesParameter,
    GetModuleAttributesResult,
    PreDeleteAttributeResult,
    UpdateAttributeParameter,
} from './attribute';
import {
    CreateAttributeListValueParameter,
    GenericAttributeListValueResult,
    PreUpdateAttributeListValueParameter,
    PreUpdateAttributeListValueResult,
    UpdateAttributeListValueParameter,
} from './attribute-list-value';
import {
    CreateAttributeTagParameter,
    GenericAttributeTagResult,
    GetAttributeTagsParameter,
    GetAttributeTagsResult,
    PreCreateAttributeTagParameter,
    PreCreateAttributeTagResult,
    UpdateAttributeTagParameter,
} from './attribute-tag';
import {
    SetTextQualityVoteParameter,
    SetTextQualityVoteResult,
} from './formatted-text-vote';

@Injectable({ providedIn: 'root' })
export class AttributeApiService {
    constructor(private backendApiService: BackendApiService) {}

    public async getAttributes(param: GetAttributesParameter) {
        return await this.backendApiService.postPromise(
            'Entity/GetAttributes',
            param,
            GetAttributesResult,
        );
    }

    public async getModuleAttributes(param: GetModuleAttributesParameter) {
        return await this.backendApiService.postPromise(
            'Attribute/GetModuleAttributes',
            param,
            GetModuleAttributesResult,
        );
    }

    public async createAttribute(param: CreateAttributeParameter) {
        return await this.backendApiService.postPromise(
            'Attribute/CreateAttribute',
            param,
            GenericAttributeResult,
        );
    }

    public async preDeleteAttribute(param: DeleteAttributeParameter) {
        return await this.backendApiService.postPromise(
            'Attribute/PreDeleteAttribute',
            param,
            PreDeleteAttributeResult,
        );
    }

    public async deleteAttribute(param: DeleteAttributeParameter) {
        return await this.backendApiService.postPromise(
            'Attribute/DeleteAttribute',
            param,
            GenericAttributeResult,
        );
    }

    public async updateAttribute(param: UpdateAttributeParameter) {
        return await this.backendApiService.postPromise(
            'Attribute/UpdateAttribute',
            param,
            GenericAttributeResult,
        );
    }

    public async createAttributeListValue(
        param: CreateAttributeListValueParameter,
    ) {
        return await this.backendApiService.postPromise(
            'Attribute/CreateAttributeListValue',
            param,
            GenericAttributeListValueResult,
        );
    }

    public async preUpdateAttributeListValue(
        param: PreUpdateAttributeListValueParameter,
    ) {
        return await this.backendApiService.postPromise(
            'Attribute/PreUpdateAttributeListValue ',
            param,
            PreUpdateAttributeListValueResult,
        );
    }

    public async updateAttributeListValue(
        param: UpdateAttributeListValueParameter,
    ) {
        return await this.backendApiService.postPromise(
            'Attribute/UpdateAttributeListValue',
            param,
            GenericAttributeListValueResult,
        );
    }

    public async getAttributeTags(param: GetAttributeTagsParameter) {
        return await this.backendApiService.postPromise(
            'Attribute/GetAttributeTags',
            param,
            GetAttributeTagsResult,
        );
    }

    public async preCreateAttributeTag(param: PreCreateAttributeTagParameter) {
        return await this.backendApiService.postPromise(
            'Attribute/PreCreateAttributeTag',
            param,
            PreCreateAttributeTagResult,
        );
    }

    public async createAttributeTag(param: CreateAttributeTagParameter) {
        return await this.backendApiService.postPromise(
            'Attribute/CreateAttributeTag',
            param,
            GenericAttributeTagResult,
        );
    }

    public async updateAttributeTag(param: UpdateAttributeTagParameter) {
        return await this.backendApiService.postPromise(
            'Attribute/UpdateAttributeTag',
            param,
            GenericAttributeTagResult,
        );
    }

    public async setFormattedTextUserVote(p: SetTextQualityVoteParameter) {
        return await this.backendApiService.postPromise(
            'DataScience/SetFormattedTextUserVote',
            p,
            SetTextQualityVoteResult,
        );
    }
}
