import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import { BaseCellComponent } from '@datagalaxy/core-ui/cell-components';
import { getLanguageName } from '@datagalaxy/webclient/multilingual/ui';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { LanguageUserSettingsService } from '../language-user-settings.service';
import { AsyncPipe, NgIf } from '@angular/common';
import { withLoading } from '@datagalaxy/core-ui';
import { Subject } from 'rxjs';
import { DxyButtonDirective } from '@datagalaxy/ui/buttons';
import { CurrentUserService } from '@datagalaxy/webclient/user/data-access';

/**
 * ## Role
 * Button inside a notification to change user language
 */
@Component({
    selector: 'dxy-change-language-button.component',
    templateUrl: './change-language-button.component.html',
    styleUrls: ['./change-language-button.component.scss'],
    standalone: true,
    imports: [DxyButtonDirective, TranslateModule, AsyncPipe, NgIf],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangeLanguageButtonComponent
    extends BaseCellComponent
    implements OnInit, OnChanges
{
    @Input() languageCode!: string;

    protected get languageName(): string {
        return getLanguageName(this.languageCode);
    }

    protected get buttonVisible(): boolean {
        return (
            this.languageCode !== this.currentUserService.userInfo?.LanguageCode
        );
    }

    protected buttonText$ = new Subject<string>();

    constructor(
        private languageUserSettingsService: LanguageUserSettingsService,
        private currentUserService: CurrentUserService,
        private translate: TranslateService,
    ) {
        super();
    }
    ngOnInit() {
        this.languageCode = this.value;
        this.getButtonMessage();
    }

    override ngOnChanges(changes: SimpleChanges) {
        super.ngOnChanges(changes);
        super.onChange(changes, 'languageCode', () => {
            this.getButtonMessage();
        });
    }

    @withLoading()
    protected async changeLanguage() {
        await this.languageUserSettingsService.setUserLanguage(
            this.languageCode,
        );
        location.reload();
    }

    private getButtonMessage() {
        if (
            this.languageCode === this.currentUserService.userInfo?.LanguageCode
        ) {
            this.getButtonMessageInCurrentLanguage();
            return;
        }
        this.getButtonMessageInNewAvailableLanguage();
    }

    private getButtonMessageInCurrentLanguage() {
        this.buttonText$.next(
            this.translate.instant('Multilingual.changeLanguageButton', {
                language: this.languageName,
            }),
        );
    }

    private getButtonMessageInNewAvailableLanguage() {
        super.subscribe(
            this.translate.getTranslation(this.languageCode),
            (translations) => {
                const compiledTranslations =
                    this.translate.compiler.compileTranslations(
                        translations,
                        this.languageCode,
                    );
                const result = this.translate.getParsedResult(
                    compiledTranslations,
                    'Multilingual.changeLanguageButton',
                    {
                        language: this.languageName,
                    },
                );
                this.buttonText$.next(result);
            },
        );
    }
}
