import { Injectable } from '@angular/core';
import {
    BackendApiService,
    BaseServiceParameter,
    BaseServiceResult,
} from '@datagalaxy/data-access';
import {
    CreateTechnologyParameter,
    CreateTechnologyResult,
    DeleteTechnologyParameter,
    DeleteTechnologyResult,
    GetTechnologiesResult,
    PreCreateTechnologyResult,
    PreUpdateTechnologyResult,
} from './technology';

@Injectable({ providedIn: 'root' })
export class TechnologyApiService {
    constructor(private backendApiService: BackendApiService) {}

    public async getTechnologies(param: BaseServiceParameter) {
        return await this.backendApiService.postPromise(
            'Technology/GetTechnologies',
            param,
            GetTechnologiesResult,
        );
    }

    public async preCreateTechnology(param: CreateTechnologyParameter) {
        return await this.backendApiService.postPromise(
            'Technology/PreCreateTechnology',
            param,
            PreCreateTechnologyResult,
        );
    }

    public async createTechnology(param: CreateTechnologyParameter) {
        return await this.backendApiService.postPromise(
            'Technology/CreateTechnology',
            param,
            CreateTechnologyResult,
        );
    }

    public async preUpdateTechnology(param: CreateTechnologyParameter) {
        return await this.backendApiService.postPromise(
            'Technology/PreUpdateTechnology',
            param,
            PreUpdateTechnologyResult,
        );
    }

    public async updateTechnology(param: CreateTechnologyParameter) {
        return await this.backendApiService.postPromise(
            'Technology/UpdateTechnology',
            param,
            BaseServiceResult,
        );
    }

    public async preDeleteTechnology(param: DeleteTechnologyParameter) {
        return await this.backendApiService.postPromise(
            'Technology/PreDeleteTechnology',
            param,
            DeleteTechnologyResult,
        );
    }

    public async deleteTechnology(param: DeleteTechnologyParameter) {
        return await this.backendApiService.postPromise(
            'Technology/DeleteTechnology',
            param,
            BaseServiceResult,
        );
    }
}
