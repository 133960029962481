import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';

/**
 * #Archi-services-others: AppConfigService, RealTimeCommService
 */
@Injectable({ providedIn: 'root' })
export class NavigationEventService {
    public get openDeleteModal$() {
        return this.openDeleteModal.asObservable();
    }

    private readonly openDeleteModal = new Subject<void>();

    public notifyDeleteModalOpen() {
        this.openDeleteModal.next();
    }
}
