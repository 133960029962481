import {
    ChangeDetectionStrategy,
    Component,
    Input,
    NgZone,
    OnChanges,
    Optional,
    Self,
    SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { DxyFieldsModule } from '@datagalaxy/core-ui/fields';
import { IFieldSelectAdapter, IMultiSelectData } from '@datagalaxy/core-ui';
import { getAttributeGlyphClass } from '../attribute-glyph-util';
import { DxyBaseValueAccessorComponent } from '@datagalaxy/ui/core';
import { AttributeMetaInfo } from '@datagalaxy/webclient/attribute/domain';

@Component({
    selector: 'dxy-attribute-selector',
    standalone: true,
    imports: [CommonModule, DxyFieldsModule],
    templateUrl: './attribute-selector.component.html',
    styleUrls: ['./attribute-selector.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttributeSelectorComponent
    extends DxyBaseValueAccessorComponent<AttributeMetaInfo[]>
    implements ControlValueAccessor, OnChanges
{
    @Input() labelKey!: string;
    @Input() hint?: string;
    @Input() mandatory = false;
    @Input() availableAttributes: AttributeMetaInfo[] = [];
    @Input() disabled = false;
    @Input() readonly = false;

    protected multiSelectData: IMultiSelectData<AttributeMetaInfo> =
        this.buildMultiSelectData();

    protected get errorMessageKey() {
        if (
            this.mandatory &&
            this.touched &&
            !this.multiSelectData.selectedItems?.length
        ) {
            return 'UI.Attribute.msgRequired';
        }
        return undefined;
    }

    private touched = false;

    private attributeAdapter: IFieldSelectAdapter<AttributeMetaInfo> = {
        getText: (a: AttributeMetaInfo) =>
            a.translatedDisplayName ?? a.DisplayName,
        getId: (a: AttributeMetaInfo) => a.AttributePath ?? '',
        getGlyphClass: (a: AttributeMetaInfo) => getAttributeGlyphClass(a),
    };

    constructor(ngZone: NgZone, @Optional() @Self() ngControl: NgControl) {
        super(ngControl, ngZone);
    }

    ngOnChanges(changes: SimpleChanges) {
        this.onChange(
            changes,
            'availableAttributes',
            () => {
                this.multiSelectData = this.buildMultiSelectData();
            },
            true,
        );
    }

    public override writeValue(value: AttributeMetaInfo[]) {
        super.writeValue(value);
        if (!this.availableAttributes?.length) {
            this.multiSelectData = this.buildMultiSelectData();
        }
    }
    protected onOpenClose(opened: boolean) {
        if (opened) {
            return;
        }
        this.touched = true;
        this.propagateTouched?.();
    }

    private buildMultiSelectData(): IMultiSelectData<AttributeMetaInfo> {
        const items = this.availableAttributes?.length
            ? this.availableAttributes
            : (this.value ?? []);
        return {
            selectedItems: this.value,
            items,
            adapter: this.attributeAdapter,
            searchParams: {
                enabled: true,
            },
            hasSelectAll: true,
            onSelectionChange: (selectedAttributes: AttributeMetaInfo[]) => {
                this.onValueChanged(selectedAttributes);
            },
        };
    }
}
