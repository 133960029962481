import {
    deserialize,
    deserializeAs,
    inheritSerialization,
    serialize,
    serializeAs,
} from 'cerialize';
import {
    BaseServiceParameter,
    BaseServiceResult,
} from '@datagalaxy/data-access';
import {
    DataProcessingItemDto,
    DataProcessingItemType,
} from './data-processing';

@inheritSerialization(BaseServiceParameter)
export class CreateDataProcessingItemParameter extends BaseServiceParameter {
    @serialize public DataProcessingId: string;
    @serialize public DisplayName: string;
    @serialize public TechnicalName: string;
    @serialize public Description?: string;
    @serialize public InputIds: string[];
    @serialize public OutputIds: string[];
    @serializeAs(DataProcessingItemType)
    public ItemType: DataProcessingItemType;

    constructor(
        dataProcessingId: string,
        displayName: string,
        itemType: DataProcessingItemType,
        inputLinkedData: string[],
        outputLinkedData: string[],
    ) {
        super();
        this.DataProcessingId = dataProcessingId;
        this.DisplayName = displayName;
        this.TechnicalName = displayName;
        this.ItemType = itemType;
        this.InputIds = inputLinkedData;
        this.OutputIds = outputLinkedData;
    }
}

@inheritSerialization(BaseServiceResult)
export class CreateDataProcessingItemResult extends BaseServiceResult {
    @deserializeAs(DataProcessingItemDto)
    public CreatedItem!: DataProcessingItemDto;
}

@inheritSerialization(BaseServiceParameter)
export class DeleteDataProcessingItemParameter extends BaseServiceParameter {
    @serialize public DataProcessingId: string;
    @serialize public DataProcessingItemId: string;

    constructor(dataProcessingId: string, dataProcessingItemId: string) {
        super();
        this.DataProcessingId = dataProcessingId;
        this.DataProcessingItemId = dataProcessingItemId;
    }
}

@inheritSerialization(BaseServiceResult)
export class DeleteDataProcessingItemResult extends BaseServiceResult {
    @deserialize public DeletedItemId!: string;
}

@inheritSerialization(BaseServiceParameter)
export class UpdateDataProcessingItemParameter extends BaseServiceParameter {
    @serialize public DataProcessingItemId: string;
    @serialize public DisplayName: string;
    @serialize public TechnicalName?: string;
    @serialize public Description?: string;
    @serialize public LongDescription?: string;
    @serializeAs(DataProcessingItemType)
    public ItemType?: DataProcessingItemType;

    constructor(
        dataProcessingItemId: string,
        displayName: string,
        itemType?: DataProcessingItemType,
        description?: string,
        longDescription?: string,
        technicalName?: string,
    ) {
        super();
        this.DataProcessingItemId = dataProcessingItemId;
        this.DisplayName = displayName;
        this.TechnicalName = technicalName;
        this.ItemType = itemType;
        this.Description = description;
        this.LongDescription = longDescription;
    }
}

@inheritSerialization(BaseServiceResult)
export class UpdateDataProcessingItemResult extends BaseServiceResult {
    @deserializeAs(DataProcessingItemDto)
    public UpdatedItem!: DataProcessingItemDto;
}
