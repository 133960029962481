import {
    patchState,
    signalStoreFeature,
    withComputed,
    withMethods,
    withState,
} from '@ngrx/signals';
import { computed, inject } from '@angular/core';
import {
    touch,
    withCommonAttribute,
} from '../../../common/common-attribute-feature';
import { withTranslation } from '../translation-feature/translation-feature';
import { StringUtil } from '@datagalaxy/core-util';
import { textValueInitalValue, TextValueState } from './text-value-state';
import {
    setFulfilled,
    setPending,
} from '@datagalaxy/shared/signal-store-common';
import { TextAttributeValueUpdater } from '../text-attribute-value-updater';

export function withTextValue() {
    return signalStoreFeature(
        withState<TextValueState>(textValueInitalValue),
        withCommonAttribute(),
        withTranslation(),
        withComputed(
            ({
                readonly,
                updatedValue,
                entity,
                attributeKey,
                translatedText,
            }) => {
                const metaModelValue = computed(
                    () =>
                        entity()?.getAttributeValue<string>(attributeKey()) ??
                        '',
                );
                return {
                    edited: computed(
                        () => !readonly() && updatedValue() !== undefined,
                    ),
                    metaModelValue,
                    value: computed(() => {
                        const t = translatedText();
                        const m = metaModelValue();
                        return StringUtil.isNullOrEmpty(t) ? m : (t ?? '');
                    }),
                };
            },
        ),
        withMethods(
            (
                store,
                textAttributeValueUpdater = inject(TextAttributeValueUpdater),
            ) => ({
                setUpdatedValue(value: string) {
                    patchState(store, { updatedValue: value }, touch());
                },
                async persistUpdatedValue(): Promise<void> {
                    patchState(store, touch());
                    const entity = store.entity();
                    if (!entity) {
                        return;
                    }
                    const previousValue = store.value();
                    if (
                        store.updatedValue() === previousValue ||
                        store.updatedValue() === undefined
                    ) {
                        patchState(store, { updatedValue: undefined });
                        return;
                    }
                    patchState(store, setPending());
                    try {
                        await textAttributeValueUpdater.update({
                            entity,
                            attributePath: store.attributePath() ?? '',
                            updatedValue: store.updatedValue() ?? '',
                            metaModelValue: store.metaModelValue(),
                        });
                        patchState(store, { updatedValue: undefined });
                    } finally {
                        patchState(store, setFulfilled());
                    }
                },
            }),
        ),
    );
}
