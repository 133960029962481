import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
    MatExpansionPanel,
    MatExpansionModule,
} from '@angular/material/expansion';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { NgIf } from '@angular/common';
import { DxyIconButtonDirective } from '@datagalaxy/ui/buttons';

@Component({
    selector: 'dxy-collapsible',
    templateUrl: './collapsible.component.html',
    styleUrls: ['./collapsible.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [MatExpansionModule, NgIf, DxyIconButtonDirective],
})
export class DxyCollapsibleComponent
    extends DxyBaseComponent
    implements OnChanges, OnInit
{
    @Input() isCollapsed?: boolean;
    @Input() titleText?: string;
    @Input() titleKey?: string;
    @Input() descriptionText?: string;
    @Input() descriptionKey?: string;
    @Input() collapseDescription?: boolean;
    /**
     * If set, it will show a badge with the count next to the title
     * Note: the value 0 will not be showed by default
     */
    @Input() titleCount?: number;
    /** When true, no expand caret, title, nor description are displayed.
     * Expand/collapse state can still be changed via the *isCollapsed* input. */
    @Input() noHeader?: boolean;
    /** Emitted after an expand or collapse */
    @Output() readonly onExpandCollapse =
        new EventEmitter<IExpandCollapseEvent>();
    /** to be used for bi-directional binding of *isCollapsed* */
    @Output() readonly isCollapsedChange = new EventEmitter<boolean>();

    public isIconCollapsed?: boolean;
    public get title() {
        return this.titleText
            ? this.titleText
            : this.titleKey
              ? this.translateService.instant(this.titleKey)
              : '';
    }
    public get description() {
        return this.descriptionText
            ? this.descriptionText
            : this.descriptionKey
              ? this.translateService.instant(this.descriptionKey)
              : '';
    }

    @ViewChild(MatExpansionPanel) private panel?: MatExpansionPanel;
    private isFromUser = false;

    constructor(private translateService: TranslateService) {
        super();
    }

    ngOnChanges(changes: SimpleChanges) {
        super.onChange<boolean>(changes, 'isCollapsed', (cur, prev) => {
            if (!!cur == !!prev) {
                return;
            }
            const isCollapsed = cur;
            this.log('onIsCollapsedChange', isCollapsed);
            if (isCollapsed) {
                this.panel?.close();
            } else {
                this.panel?.open();
            }
        });
    }
    ngOnInit() {
        this.isIconCollapsed = this.isCollapsed;

        this.fixCollapsibleGlobalCssPerformanceProperty();
    }

    public onHeaderClick() {
        this.log('onHeaderClick');
        this.isFromUser = true;
    }

    public onOpenClose(open: boolean) {
        this.log('onOpenClose', open);
        this.isIconCollapsed = !open;
    }
    public onAfterExpandCollapse(expanded: boolean) {
        this.log('afterExpandCollapse', expanded, this.isFromUser);
        const wasCollapsed = !!this.isCollapsed,
            isCollapsed = !expanded;
        const isFromUser = this.isFromUser;
        this.isFromUser = false;
        if (wasCollapsed != isCollapsed) {
            this.isCollapsedChange.emit(isCollapsed);
        }
        this.onExpandCollapse.emit({ isCollapsed, isFromUser });
    }

    private fixCollapsibleGlobalCssPerformanceProperty() {
        /**
         * Angular material collapsible use a hack to prevent a collapsed panel
         * child element from being focused, using the css property: [style*="visibility: hidden"]
         * The issue with that is that the component have its style encapsulated to none,
         * so it applies to all the components in the app. And with chromium it causes a performance
         * issue since it will force style recalculation on all the components in the app.
         * This is really bad for performance especially for the DKS (lineage/diagram, ect.)
         *
         * You can retrieve a github issue talking about it : https://github.com/angular/components/issues/27946
         *
         * So the trick here is just to override the global style to not use the hack.
         *
         * You can retrieve the explanation of the hack here: https://github.com/angular/components/blob/15.2.9/src/material/expansion/expansion-panel.scss#L54
         */
        const styles = document.querySelectorAll('style');
        const ruleToRemove =
            '.mat-expansion-panel-content[style*="visibility: hidden"] *';

        const style = Array.from(styles).find((s: any) =>
            s.innerText.includes(ruleToRemove),
        );

        if (!style) {
            return;
        }

        style.textContent =
            style.textContent?.replace(
                ruleToRemove,
                'mat-expansion-panel-content-forbidden-rule',
            ) ?? '';
    }
}

export interface IExpandCollapseEvent {
    isCollapsed: boolean;
    isFromUser: boolean;
}
