import { inject, Pipe, PipeTransform } from '@angular/core';
import { EntityDashboardService } from './entity-dashboard.service';
import { IHierarchicalData } from '@datagalaxy/dg-object-model';

@Pipe({
    name: 'entityUrl',
    standalone: true,
})
export class EntityUrlPipe implements PipeTransform {
    private entityDashboardService = inject(EntityDashboardService);

    transform(hierarchicalData: IHierarchicalData): string | any[] {
        return this.entityDashboardService.getEntityUrl(hierarchicalData);
    }
}
