import { Subject } from 'rxjs';
import { INgZone, ZoneUtils } from '@datagalaxy/utils';

/** GUI sub-component providing show/hide context-menu (icon and list) for a hovered item */
export class BurgerMenuHoverManager<TItem, TContext> {
    /** set to true to force logging to console */
    static readonly debug: boolean = false;

    public get onShowHideBurgerIcon$() {
        return this.onShowHideBurgerIcon.asObservable();
    }
    private onShowHideBurgerIcon = new Subject<boolean>();

    /** the hovered item */
    private item: TItem;
    /** a context associated with the item, before showing the menu */
    private context: TContext;
    /** the item for which the menu is displayed, undefined when the menu is hidden */
    private menuItem: TItem;
    /** the currently displayed menu html element, when its enter/leave events have been bound */
    private boundMenu: Element;
    /** a timer for keeping menu shown while hovering between item, icon and menu */
    private timerLeave: number;
    /** a timer for hiding the menu when hovering out of the icon, not to menu */
    private timerHideMenu: number;
    /** a timer for calling the server only when hovering the same item in a while */
    private timerGetContext: number;

    private readonly onMenuEnter: EventListener = () => {
        this.cancelLeave();
        this.cancelHideMenu();
    };
    private readonly onMenuLeave: EventListener = () =>
        this.startLeave(this.menuItem);

    public debug: boolean;

    constructor(
        private burgerMenu: IBurgerMenuProvider<TItem, TContext>,
        private showHideBurgerIcon: (
            show: boolean,
            item: TItem,
            context?: TContext,
        ) => void,
        private getContext?: (item: TItem) => TContext | Promise<TContext>,
        private onItemInOut?: (isIn: boolean, item: TItem) => void,
        private options?: IBurgerMenuHoverManagerOptions,
    ) {
        if (BurgerMenuHoverManager.debug || options?.debug) {
            this.debug = true;
        }
    }

    /** mandatory - to be called on events like mouseenter/mouseleave for an item - to show/hide the menu icon */
    public onHoverItem(isIn: boolean, item: TItem) {
        if (item == undefined) {
            return;
        }
        if (isIn) {
            // entering the item
            this.cancelLeave();
            if (item == this.menuItem) {
                // menu is still visible
                // from menu to item: nothing to do
            } else {
                if (this.menuItem) {
                    // menu is still visible, but item changed
                    this.leave(this.menuItem);
                }
                this.enter(item);
            }
        } else {
            // leaving the item
            if (this.menuItem) {
                // menu is visible => leaving, or item->menu
                this.startLeave(this.menuItem);
            } else {
                // menu is not visible
                this.cancelLeave();
                this.leave(item);
            }
        }
    }

    /** mandatory - to be called when the icon has been added to the DOM - to bind events
     *  NOTE: The TContext of the BurgerMenu can be provided locally by the IRendererActionDef
     *  using the iconBurgerMenuContext parameter
     * */

    public onBurgerIconCreated(
        icon: Element,
        iconBurgerMenuContext?: TContext,
    ) {
        this.log('onBurgerIconCreated', !!this.options?.ngZone, icon);
        if (!icon) {
            return;
        }
        ZoneUtils.zoneExecute(
            () => {
                icon.addEventListener('click', (e: PointerEvent) => {
                    e.stopPropagation();
                    this.onIconClicked(icon, iconBurgerMenuContext);
                });
                icon.addEventListener('dblclick', (e: PointerEvent) =>
                    e.stopPropagation(),
                );
                icon.addEventListener('mouseleave', () => this.startHideMenu());
                icon.addEventListener('mouseenter', () => {
                    this.cancelHideMenu();
                    this.cancelLeave();
                });
            },
            this.options?.ngZone,
            true,
        );
    }

    /** mandatory - to be called when the menu has been shown or hidden - to binds/unbinds mouse enter/leave events */
    public onBurgerMenuShowHide(show: boolean, menu: Element) {
        this.log('onBurgerMenuShowHide', show, menu);
        if (show && menu) {
            this.bindMenu(true, menu);
        }
    }

    /** optional - to be called on item click event, to hide the menu and update the icon visibility */
    public updateIcon(item: TItem) {
        this.log('updateIcon', item, item == this.menuItem, item == this.item);
        this.hideMenu();
        if (item == this.item) {
            this.showIconIfAvailable(item);
        }
    }

    /** used to execute dom manipulation and events dispatching outside of the angular zone, to prevent app freezing */
    protected setTimeout(fn: () => void, delayMs?: number) {
        //this.log('setTimeout', delayMs, !!this.options?.ngZone)
        return ZoneUtils.zoneTimeout(fn, delayMs, this.options?.ngZone, true);
    }

    private startLeave(item: TItem) {
        this.cancelLeave();
        this.timerLeave = this.setTimeout(() => this.leave(item), 222);
    }
    private cancelLeave() {
        window.clearTimeout(this.timerLeave);
        this.timerLeave = undefined;
    }
    private leave(item: TItem) {
        this.log('leave', item);

        window.clearTimeout(this.timerGetContext);
        this.bindMenu(false, this.boundMenu);
        this.hideMenu();
        this.context = this.item = undefined;
        this.showHideBurgerIcon(false, item);
        this.onShowHideBurgerIcon.next(false);
        if (typeof this.onItemInOut == 'function') {
            this.onItemInOut(false, item);
        }
    }
    private enter(item: TItem) {
        this.log('enter', item);

        window.clearTimeout(this.timerGetContext);

        this.item = item;

        this.showIconIfAvailable(item);
    }

    private showIconIfAvailable(item: TItem) {
        this.log('showIconIfAvailable', item);

        if (typeof this.onItemInOut == 'function') {
            this.onItemInOut(true, item);
        }

        const doShowHide = () => {
            const hasAvail =
                typeof this.burgerMenu.isAvailableFor == 'function';
            const showIcon =
                !hasAvail || this.burgerMenu.isAvailableFor(item, this.context);
            this.log('showIconIfAvailable-showIcon', showIcon, item);
            this.showHideBurgerIcon(showIcon, item, this.context);
            this.onShowHideBurgerIcon.next(showIcon);
        };

        if (typeof this.getContext == 'function') {
            const showHideWithCtx = (ctx: TContext) => {
                this.context = ctx;
                this.log(
                    'showIconIfAvailable-getContext-result',
                    this.context,
                    item,
                );
                doShowHide();
            };
            const getCtxAndShowHide = () => {
                this.log('showIconIfAvailable-getContext', item);
                const ctx = this.getContext(item);
                const ctxPromise = ctx as Promise<TContext>;
                if (typeof ctxPromise?.then == 'function') {
                    ctxPromise.then(showHideWithCtx);
                } else {
                    showHideWithCtx(ctx as TContext);
                }
            };
            if (this.options?.getContextDelayMs) {
                this.timerGetContext = this.setTimeout(
                    getCtxAndShowHide,
                    this.options?.getContextDelayMs,
                );
            } else {
                getCtxAndShowHide();
            }
        } else {
            doShowHide();
        }
    }

    private onIconClicked(icon: Element, iconBurgerMenuContext?: TContext) {
        this.log('onIconClicked', icon);
        if (this.menuItem) {
            this.hideMenu();
        } else {
            this.showMenu(icon, iconBurgerMenuContext);
        }
    }
    private hideMenu() {
        this.cancelHideMenu();
        this.log('hideMenu');
        this.menuItem = undefined;
        this.burgerMenu.hide();
    }
    private showMenu(icon: Element, iconBurgerMenuContext?: TContext) {
        this.log('showMenu', icon);
        if (
            this.burgerMenu.show(
                icon,
                this.item,
                iconBurgerMenuContext || this.context,
            )
        ) {
            this.menuItem = this.item;
        }
    }
    private bindMenu(show: boolean, menu: Element) {
        this.log('bindMenu', show, menu);
        if (!menu) {
            return;
        }
        if (show) {
            ZoneUtils.zoneExecute(() => {
                menu.addEventListener('mouseenter', this.onMenuEnter);
                menu.addEventListener('mouseleave', this.onMenuLeave);
            }, this.options?.ngZone);
        } else {
            menu.removeEventListener('mouseenter', this.onMenuEnter);
            menu.removeEventListener('mouseleave', this.onMenuLeave);
        }
        this.boundMenu = show ? menu : undefined;
    }
    private startHideMenu() {
        this.cancelHideMenu();
        this.timerHideMenu = this.setTimeout(() => this.hideMenu(), 333);
    }
    private cancelHideMenu() {
        window.clearTimeout(this.timerHideMenu);
        this.timerHideMenu = undefined;
    }

    protected log(...args: any[]) {
        if (!this.debug) {
            return;
        }
        args.forEach((o, i, a) => {
            if (o && typeof o.toDebugString == 'function') {
                a[i] = o.toDebugString(true);
            }
        });
        console.log((this.constructor as any).name, ...args);
    }
}

/** interface for a context-menu menu provider to be used with the BurgerMenuHoverManager */
export interface IBurgerMenuProvider<TItem, TContext> {
    /** build the menu list content and show the menu,
     * returns true if the menu has been shown and is not empty */
    show: (icon: Element, item?: TItem, context?: TContext) => boolean;

    /** hide the menu */
    hide: () => void;

    /** return true if the context menu is available for this item and context */
    isAvailableFor?: (item: TItem, context?: TContext) => boolean;
}

export interface IBurgerMenuHoverManagerOptions {
    debug?: boolean;
    getContextDelayMs?: number;
    isModalContext?: boolean;
    ngZone?: INgZone;
}
