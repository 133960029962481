<div class="modal-header">
    <h3 mat-dialog-title class="modal-title" translate>
        UI.NotificationChannels.MsTeams.NotificationsActivationModal.title
    </h3>
    <button dxyIconButton color="transparent" (click)="onCloseCancel()">
        <i class="glyph-cancelsearch"></i>
    </button>
</div>

<div mat-dialog-content>
    <img src="/images/collaboration/ms-teams-install.png" />
    <p translate>
        UI.NotificationChannels.MsTeams.NotificationsActivationModal.description
    </p>
    <p>
        <span translate
            >UI.NotificationChannels.MsTeams.NotificationsActivationModal.helpMessage</span
        >
        &nbsp;
        <a
            href="https://datagalaxy.freshdesk.com/a/solutions/articles/35000223900?lang=fr"
            target="_blank"
        >
            <span translate
                >UI.NotificationChannels.MsTeams.NotificationsActivationModal.helpButton</span
            >
        </a>
    </p>
</div>

<dxy-modal-footer
    [noMandatory]="true"
    [actionEnabled]="true"
    [actionBtnLbl]="
        'UI.NotificationChannels.MsTeams.NotificationsActivationModal.activateButton'
            | translate
    "
    [actionLoading]="loading$ | async"
    (onCloseCancel)="onCloseCancel()"
    (onActionPointerDown)="activateNotifications()"
    featureCode="MS_TEAMS_NOTIFICATIONS_ACTIVATION"
></dxy-modal-footer>
