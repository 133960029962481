<button
    dxyIconButton
    variant="tertiary"
    [matMenuTriggerFor]="menu"
    [matTooltip]="'UI.GridTemplate.Settings.manageColumns' | translate"
    matTooltipPosition="above"
>
    <span class="glyph glyph-grid-columns"></span>
</button>
<mat-menu #menu="matMenu" class="dg5-mat-menu">
    <div class="menu not-an-item">
        <dxy-search-input
            *ngIf="enableSearch"
            class="no-border-top"
            [showCancelSearch]="true"
            (onSearchString)="onSearch($event.searchString)"
        ></dxy-search-input>
        <button type="button" mat-menu-item (click)="onResetClick()">
            <span class="glyph-reload glyph"></span>
            <span class="text">
                {{ 'UI.GridTemplate.Settings.resetState' | translate }}
            </span>
        </button>
        <div class="menu-body not-an-item" (click)="$event.stopPropagation()">
            <div *ngFor="let column of columns$ | async" class="column">
                <dxy-checkbox
                    [checked]="isColumnVisible(column)"
                    (change)="onColumnToggleVisibility(column)"
                >
                    <div class="label">
                        <i
                            *ngIf="hasBeforeGlyph(column)"
                            class="before-icon"
                            [ngClass]="column.glyph?.before"
                        ></i>

                        <div class="label">
                            <span dxyEllipsisTooltip>
                                {{ column.headerLabel ?? '' }}
                            </span>
                        </div>
                    </div>
                </dxy-checkbox>

                <i
                    *ngIf="column.glyph?.after"
                    class="after-icon"
                    [ngClass]="column.glyph?.after"
                ></i>
            </div>
        </div>
    </div>
</mat-menu>
