import {
    MatDialogRef,
    MAT_DIALOG_DATA,
    MatDialogModule,
} from '@angular/material/dialog';
import { Component, Inject, OnInit } from '@angular/core';
import { DxyBaseModalComponent } from '@datagalaxy/ui/dialog';
import { DataProcessingUiService } from '../services/data-processing-ui.service';
import { EditedDpi, IDpiEditResolve } from './dpi-edit-modal.types';
import { IMentionResolver } from '@datagalaxy/core-ui/rich-text';
import { EnumNumberFieldSelectAdapter } from '@datagalaxy/core-ui';
import { RichTextMentionService } from '../../shared/richText/mentions/rich-text-mention.service';
import {
    DataProcessingItemDto,
    DataProcessingItemType,
} from '@datagalaxy/webclient/data-processing/data-access';
import { DxyRichTextFieldComponent } from '@datagalaxy/core-ui/rich-text';
import { DxyFieldSelectComponent } from '@datagalaxy/core-ui/fields';
import { FormsModule } from '@angular/forms';
import { DxyFieldTextComponent } from '@datagalaxy/ui/fields';
import {
    DxyButtonDirective,
    DxyIconButtonDirective,
} from '@datagalaxy/ui/buttons';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-dpi-edit-modal',
    templateUrl: 'dpi-edit-modal.component.html',
    standalone: true,
    imports: [
        MatDialogModule,
        TranslateModule,
        DxyButtonDirective,
        DxyIconButtonDirective,
        DxyFieldTextComponent,
        FormsModule,
        DxyFieldSelectComponent,
        DxyRichTextFieldComponent,
    ],
})
export class DpiEditModalComponent
    extends DxyBaseModalComponent<IDpiEditResolve, void>
    implements OnInit
{
    public edited: EditedDpi;
    public isSaveEnabled: boolean;
    public itemTypeAdapter: EnumNumberFieldSelectAdapter<DataProcessingItemType>;
    public readonly mentionResolvers: IMentionResolver[];

    constructor(
        private dataProcessingUiService: DataProcessingUiService,
        private richTextMentionService: RichTextMentionService,
        dialogRef: MatDialogRef<DpiEditModalComponent, void>,
        @Inject(MAT_DIALOG_DATA) data: IDpiEditResolve,
    ) {
        super(dialogRef, data);
        this.mentionResolvers =
            this.richTextMentionService.getSpaceUserMentionResolvers(
                this.data.spaceIdr.spaceId,
            );
    }

    ngOnInit() {
        this.edited = this.dataProcessingUiService.getEditedDpi(this.data.dpi);
        this.itemTypeAdapter = new EnumNumberFieldSelectAdapter(
            DataProcessingItemType,
            {
                getTextKey: DataProcessingItemDto.getTypeTranslateKey,
                onSelectionChange: (v) => (this.edited.ItemType = v),
                initialValue: this.edited.ItemType,
            },
        );
        this.updateSaveEnabled();
    }

    public getLabelText(attributeKey: keyof EditedDpi) {
        return this.dataProcessingUiService.getEditedDpiLabel(attributeKey);
    }

    public updateSaveEnabled() {
        this.isSaveEnabled = this.dataProcessingUiService.canSaveEditedDpi(
            this.edited,
        );
    }

    public async onSubmit() {
        await this.dataProcessingUiService.updateEditedDpi(
            this.data.dpi,
            this.edited,
            this.data.spaceIdr,
        );
        super.onCloseSubmit();
    }
}
