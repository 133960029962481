import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import {
    HTTP_INTERCEPTORS,
    HttpBackend,
    HttpClient,
    HttpInterceptor,
} from '@angular/common/http';
import { InterceptingHandler } from '../intercepting-handler';

export const REST_HTTP_INTERCEPTORS = new InjectionToken<HttpInterceptor[]>(
    'An abstraction on feature HttpInterceptor[]',
);

@Injectable()
export class RestHttpClient extends HttpClient {
    constructor(
        backend: HttpBackend,
        @Optional() @Inject(HTTP_INTERCEPTORS) interceptors: HttpInterceptor[],
        @Inject(REST_HTTP_INTERCEPTORS) restInterceptors: HttpInterceptor[],
    ) {
        super(
            new InterceptingHandler(backend, [
                ...(interceptors || []),
                ...restInterceptors,
            ]),
        );
    }
}
