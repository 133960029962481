import { Directive, ElementRef, HostListener, input } from '@angular/core';
import { MenuComponent } from './menu/menu.component';

@Directive({
    selector: '[dxyMenuTrigger]',
    standalone: true,
})
export class MenuTriggerDirective {
    menu = input.required<MenuComponent>({
        alias: 'dxyMenuTrigger',
    });

    constructor(private elementRef: ElementRef) {}

    @HostListener('click') toggleMenu() {
        if (this.menu().isOpen()) {
            this.menu().close();
        } else {
            this.menu().open(this.elementRef);
        }
    }
}
