import { CoreUtil, StringUtil } from '@datagalaxy/core-util';
import { DomUtil, THTMLElement } from '@datagalaxy/core-util';
import {
    ICellParams,
    ICellRenderData,
    TCellRenderer,
} from './cell-components/cell-components.types';
import {
    emitFunctionalEventIfNeeded,
    IFunctionalEventEmitter,
} from './functional-log.util';
import { ITranslate } from './services';

export type THTMLContent<TContext> =
    | string
    | HTMLElement
    | ((data?: TContext) => HTMLElement);

export interface IListOption<TData = unknown, TContext = TData>
    extends IOptionWithText<TContext>,
        IOptionWithGlyph<TContext>,
        IOptionWithCallback<TContext>,
        IOptionWithTooltip<TContext>,
        IOptionWithDisable<TContext>,
        IOptionWithLogFunctional<TContext>,
        IOptionWithDescription,
        IOptionWithRenderer,
        IOptionWithTestId<TContext>,
        IOptionWithTrackerId<TContext>,
        IOptionWithHidden<TContext>,
        IOptionWithCategory {
    /** html content to be appended after the text */
    htmlContent?: THTMLContent<TContext>;

    /** class name for the option's li element */
    optionClass?: string | string[] | ((data?: TContext) => string);

    /** class name for the selected text and option-item span wrappers  */
    optionTextClass?: string;

    /** sub-options */
    options?: IListOption<TData, TContext>[];
    /** for to display sub-options as flat list instead of a dropdown */
    noDropDown?: boolean;

    /** Displays a line instead of a clickable option. Hidden if disabled. */
    isSeparator?: boolean;

    data?: TData;
}

export interface IOptionWithText<TContext> {
    /** key for the option's text to translate */
    labelKey?: string | ((data?: TContext) => string);
    /** translated option's text */
    labelText?: string | ((data?: TContext) => string);
}
export interface IOptionWithGlyph<TContext = void> {
    /** class name for the option's glyph */
    glyphClass?: string | ((data?: TContext) => string);

    /** color for the glyph */
    glyphColor?: string;
}
export interface IOptionWithCallback<TContext = void> {
    /** option's callback for the click event */
    callback?: (data?: TContext) => void;
}
export interface IOptionWithTooltip<TContext = void> {
    tooltipText?: string;
    // TODO (fbo) rename to tooltipKey
    tooltipTranslateKey?: string | ((data?: TContext) => string);
    tooltipPlacement?: 'top' | 'right' | 'bottom' | 'left';
    tooltipHtml?: boolean;
    tooltipClass?: string;
}
export interface IOptionWithDisable<TContext = void> {
    /* true or function returning true for this option to be ie grayed out */
    disabled?: boolean | ((data: TContext) => boolean);
}
export interface IOptionWithHidden<TContext = void> {
    /** When true, the option is not displayed - Allows to hide an option while showing disabled ones */
    hidden?: boolean | ((data?: TContext) => boolean);
}
export interface IOptionWithLogFunctional<TContext = void> {
    /** a string to be used to log a functional event */
    logFunctional?: string | ((data?: TContext) => string);
}
export interface IOptionWithDescription {
    /** key for the option's description to translate */
    descriptionTranslateKey?: string;
}

export interface IOptionWithRenderer<
    TValue = any,
    TEntity = any,
    TRenderer extends TCellRenderer<TEntity, TValue> = TCellRenderer<
        TEntity,
        TValue
    >,
    TCellParams extends ICellParams<TEntity, TValue> = ICellParams<
        TEntity,
        TValue
    >,
> {
    renderData?: ICellRenderData<TValue, TEntity, TRenderer, TCellParams>;
}

export interface IOptionWithTestId<TContext = void> {
    /** id for the end-to-end test framework */
    dataTestId?: string | ((data?: TContext) => string);
}
export interface IOptionWithTrackerId<TContext = void> {
    /** id for the tracking framework */
    dataTrackerId?: string | ((data?: TContext) => string);
}

export interface IOptionWithCategory {
    /** key for IOptionCategory */
    categoryKey?: string;
}

export interface IListOptionsProvider<
    TItem,
    TMenuContext,
    TOptionData,
    TOptionContext,
> {
    getOptions(
        item: TItem,
        context?: TMenuContext,
    ): IListOption<TOptionData, TOptionContext>[];
}

export interface IContextMenuActionProvider<TItem, TMenuContext, TOptionContext>
    extends IListOptionsProvider<TItem, TMenuContext, unknown, TOptionContext> {
    isAvailableFor(item: TItem, context?: TMenuContext): boolean;
    /** This is set by a ContextMenuManager. Called right before action execution. Used to hide the menu */
    onExecute?: () => void;
}

export interface IOptionCategory {
    key: string;
    displayName?: string;
    translateKey?: string;
}

export class ListOptionUtil {
    /** Renders the html content of a group of dynamic IListOption's (ie having a defined 'htmlContent' property).
     * Must be called from the ngAfterViewInit function of an angular component.
     * List option elements in HTML must have a 'data-opt-index' attribute being the index of the option in the given options array;
     * Example: *ngFor="let opt of options; let index=index"  ...  [attr.data-opt-index]="index"
     */
    public static renderDynamicContents(
        options: IListOption[],
        element: THTMLElement,
        cssFilter = '.dynamic-content',
        debug = false,
    ) {
        const hostElement = DomUtil.getElement(element);
        const filteredElements =
            hostElement &&
            Array.from(
                hostElement.querySelectorAll(
                    `${cssFilter ?? ''}[data-opt-index]`,
                ),
            );
        debug &&
            CoreUtil.log(
                'renderDynamicContents',
                options,
                hostElement,
                cssFilter,
                filteredElements.length,
            );
        if (!filteredElements?.length || !options?.length) {
            return;
        }
        filteredElements.forEach((el) => {
            const index = el.getAttribute('data-opt-index');
            const option = options[+index];
            const htmlContent =
                index == undefined ? undefined : option?.htmlContent;
            debug &&
                CoreUtil.log('renderDynamicContents-each', index, htmlContent);
            if (typeof htmlContent == 'function') {
                el.innerHTML = '';
                el.append(htmlContent(option?.data));
            } else if (htmlContent instanceof HTMLElement) {
                el.innerHTML = '';
                el.append(htmlContent);
            } else if (htmlContent) {
                el.innerHTML = htmlContent;
            }
        });
    }

    public static renderDynamicContent(
        option: IListOption,
        element: THTMLElement,
        cssFilter = '.dynamic-content',
        data = option?.data,
        debug = false,
    ) {
        const hostElement = DomUtil.getElement(element);
        const el = hostElement.querySelector(cssFilter);
        debug &&
            CoreUtil.log(
                'renderDynamicContent',
                option,
                data,
                hostElement,
                cssFilter,
                el,
            );

        if (!el || !option) {
            return;
        }
        const htmlContent = option.htmlContent;

        if (typeof htmlContent == 'function') {
            el.innerHTML = '';
            el.append(htmlContent(data));
        } else if (htmlContent instanceof HTMLElement) {
            el.innerHTML = '';
            el.append(htmlContent);
        } else if (htmlContent) {
            el.innerHTML = htmlContent;
        }
    }

    public static isDropDown(option: IListOption) {
        return option && !option.isSeparator && !!option.options?.length;
    }
    public static isAction(option: IListOption) {
        return option && !option.isSeparator && !option?.options;
    }
    public static isSeparator(option: IListOption) {
        return option?.isSeparator;
    }
    public static hasRichLayout<TContext>(opt: IListOption, data?: TContext) {
        return (
            ListOptionUtil.hasText(opt, data) &&
            ListOptionUtil.hasDescription(opt)
        );
    }
    public static getOptionClass<TContext>(
        opt: IListOption<unknown, TContext>,
        data?: TContext,
    ) {
        return CoreUtil.fromFnOrValue(opt?.optionClass, data) ?? '';
    }

    public static getHtmlContent<TContext>(
        opt: IListOption<unknown, TContext>,
        data?: TContext,
    ) {
        return CoreUtil.fromFnOrValue(opt?.htmlContent, data);
    }

    public static isDisabled<TContext>(
        option: IOptionWithDisable<TContext>,
        data?: TContext,
    ) {
        return !!CoreUtil.fromFnOrValue(option?.disabled, data);
    }

    public static isHidden<TContext>(
        option: IOptionWithHidden<TContext>,
        data?: TContext,
    ) {
        return !!CoreUtil.fromFnOrValue(option?.hidden, data);
    }

    public static hasTooltip<TContext>(opt: IOptionWithTooltip<TContext>) {
        return !!(opt.tooltipText || opt.tooltipTranslateKey);
    }

    public static getTooltipText<TContext>(
        opt: IOptionWithTooltip<TContext>,
        translate: ITranslate,
        replaceBR = false,
        data?: TContext,
    ): string {
        let text = opt.tooltipText;
        if (!text) {
            const translateKey = CoreUtil.fromFnOrValue(
                opt.tooltipTranslateKey,
                data,
            );
            text = translateKey && translate.instant(translateKey);
        }
        return replaceBR ? StringUtil.replaceHtmlBR(text) : text;
    }

    public static getTooltipClass<TContext>(opt: IOptionWithTooltip<TContext>) {
        return opt.tooltipClass;
    }

    public static hasText<TContext>(
        opt: IOptionWithText<TContext>,
        data?: TContext,
    ) {
        return (
            CoreUtil.fromFnOrValue(opt.labelKey, data) ||
            CoreUtil.fromFnOrValue(opt.labelText, data)
        );
    }
    public static getText<TContext>(
        opt: IOptionWithText<TContext>,
        translate: ITranslate,
        data?: TContext,
    ): string {
        const labelKey = CoreUtil.fromFnOrValue(opt.labelKey, data);
        return (
            CoreUtil.fromFnOrValue(opt.labelText, data) ||
            (labelKey && translate.instant(labelKey)) ||
            ''
        );
    }

    public static hasDescription(opt: IOptionWithDescription) {
        return !!opt?.descriptionTranslateKey;
    }
    public static getDescription(
        opt: IOptionWithDescription,
        translate: ITranslate,
    ) {
        const translateKey = opt?.descriptionTranslateKey;
        return translateKey ? translate.instant(translateKey) : null;
    }

    public static hasGlyphClass<TContext>(opt: IOptionWithGlyph<TContext>) {
        return !!opt?.glyphClass;
    }
    public static getGlyphClass<TContext>(
        opt: IOptionWithGlyph<TContext>,
        data?: TContext,
    ) {
        return CoreUtil.fromFnOrValue(opt?.glyphClass, data) ?? '';
    }

    public static getDataTestId<TContext>(
        opt: IOptionWithTestId<TContext>,
        data?: TContext,
    ) {
        return CoreUtil.fromFnOrValue(opt?.dataTestId, data);
    }
    public static getDataTrackerId<TContext>(
        opt: IOptionWithTrackerId<TContext>,
        data?: TContext,
    ) {
        return CoreUtil.fromFnOrValue(opt?.dataTrackerId, data);
    }

    public static emitFunctionalEventIfNeeded<TContext>(
        option: IOptionWithLogFunctional<TContext>,
        onLogFunctional: IFunctionalEventEmitter,
        event: Event,
        data?: TContext,
    ) {
        return emitFunctionalEventIfNeeded(
            option?.logFunctional,
            onLogFunctional,
            event,
            data,
        );
    }
    public static onClick<TContext>(
        option: IOptionWithCallback<TContext> &
            IOptionWithLogFunctional<TContext>,
        onLogFunctional: IFunctionalEventEmitter,
        event: Event,
        data?: TContext,
    ) {
        if (!option) {
            return;
        }
        ListOptionUtil.emitFunctionalEventIfNeeded(
            option,
            onLogFunctional,
            event,
            data,
        );
        option.callback?.(data);
    }

    public static addMoreClass(classes: string | string[], moreClass: string) {
        if (!moreClass) {
            return classes;
        }
        if (Array.isArray(classes)) {
            return [...classes, ...moreClass.split(' ')];
        }
        if (classes?.length) {
            return classes + ` ${moreClass}`;
        }
        return moreClass;
    }
}
