import { Injectable, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { BaseService } from '@datagalaxy/core-ui';
import { TranslateService } from '@ngx-translate/core';
import { ITypedEvent } from '@datagalaxy/dg-object-model';
import {
    DgEventTranslator,
    IDgEventNavLink,
} from '@datagalaxy/translation-common';
import {
    IDgEventNavLinkCell,
    IEventTranslationResult,
} from './dg-event-translation.type';
import { DgEventNavLinkCellAdapter } from './dg-event-nav-link-cell-adapter';
import { CoreUtil } from '@datagalaxy/core-util';
import { TeamService } from '../team/team.service';
import { InterpolationProviderService } from './interpolation-providers/interpolation-provider-service';

/**
 * ## Role
 * Build translation for an event
 */
@Injectable({ providedIn: 'root' })
export class TypedEventTranslationFactory extends BaseService {
    private translator = new DgEventTranslator();

    constructor(
        private translate: TranslateService,
        private teamService: TeamService,
        private navLinkAdapter: DgEventNavLinkCellAdapter,
        private interpolationProvider: InterpolationProviderService,
        private domSanitizer: DomSanitizer,
    ) {
        super();
    }

    public async translateEvent(
        typedEvent: ITypedEvent,
        isUserMention?: boolean,
        mentionedTeamGuid?: string,
    ): Promise<IEventTranslationResult> {
        const extraParameters =
            await this.getExtraInterpolationVariables(typedEvent);

        const translatedEvent = this.translator.translate({
            event: typedEvent,
            translateMethod: (translateKey: string, parameters: any) => {
                const allParameters = CoreUtil.merge(
                    extraParameters,
                    parameters,
                );
                return this.instantTranslate(translateKey, allParameters);
            },
            isUserMention,
            mentionedTeamGuid,
            mentionedTeamName: mentionedTeamGuid
                ? this.getTeamName(mentionedTeamGuid)
                : undefined,
        });

        translatedEvent.html = this.sanitize(translatedEvent.html);

        const navLinkCells = (
            await Promise.all(
                translatedEvent.navLinks?.map(
                    async (n) => await this.mapToNavLink(n),
                ),
            )
        ).filter((n) => n);

        return {
            innerHTML: translatedEvent.html,
            rawText: translatedEvent.summary,
            sourceEvent: typedEvent,
            navLinkCells,
            customAvatarUrl: extraParameters?.customAvatarUrl,
        };
    }
    private getTeamName(teamGuid: string): string {
        return this.teamService.getTeamFromCache(teamGuid)?.TeamName;
    }
    private async getExtraInterpolationVariables(typedEvent: ITypedEvent) {
        const provider =
            this.interpolationProvider.getInterpolationProvider(typedEvent);
        if (!provider) {
            return {};
        }
        const result = provider.resolveParameters(typedEvent) ?? {};
        const parameters = result instanceof Promise ? await result : result;
        return parameters;
    }
    private async mapToNavLink(
        navLink: IDgEventNavLink,
    ): Promise<IDgEventNavLinkCell> {
        return await this.navLinkAdapter.adapt(navLink);
    }

    private instantTranslate = (translateKey: string, parameters: any) => {
        return this.translate.instant(translateKey, parameters);
    };

    private sanitize(html: string) {
        return this.domSanitizer.sanitize(SecurityContext.HTML, html);
    }
}
