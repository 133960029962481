import { Injectable } from '@angular/core';
import { BackendApiService, BaseServiceResult } from '@datagalaxy/data-access';
import {
    CreateEntityCommentParameter,
    CreateEntityCommentResult,
    DeleteEntityCommentsParameter,
    GetEntityCommentsParameter,
    GetEntityCommentsResult,
    UpdateEntityCommentParameter,
    UpdateEntityCommentResult,
} from './comment';

@Injectable({ providedIn: 'root' })
export class CommentApiService {
    constructor(private backendApiService: BackendApiService) {}

    public async createEntityComment(param: CreateEntityCommentParameter) {
        return await this.backendApiService.postPromise(
            'Social/CreateEntityCommentary',
            param,
            CreateEntityCommentResult,
        );
    }

    public async getEntityComments(param: GetEntityCommentsParameter) {
        return await this.backendApiService.postPromise(
            'Social/GetEntityCommentaries',
            param,
            GetEntityCommentsResult,
        );
    }

    public async updateEntityComment(param: UpdateEntityCommentParameter) {
        return await this.backendApiService.postPromise(
            'Social/UpdateEntityCommentary',
            param,
            UpdateEntityCommentResult,
        );
    }

    public async deleteEntityComments(param: DeleteEntityCommentsParameter) {
        return await this.backendApiService.postPromise(
            'Social/DeleteEntityCommentaries',
            param,
            BaseServiceResult,
        );
    }
}
