import {
    appendEditor,
    createDefaultImageWriter,
    DokaImageEditor,
    overlayEditor,
} from 'doka';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Inject,
    Input,
    NgZone,
    OnChanges,
    OnInit,
    Optional,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import {
    MatLegacyMenuTrigger as MatMenuTrigger,
    MatLegacyMenuModule,
} from '@angular/material/legacy-menu';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomUtil, IOptionsWaitFor, wait } from '@datagalaxy/core-util';
import {
    CropMode,
    DokaHelper,
    IDestCropState,
} from '@datagalaxy/core-doka-util';
import { DxyDialogService } from '../../dialog';
import {
    ISize,
    IUiImageInputParams,
    IUiImageInputResolve,
    IUiImageInputResult,
    UiImageInputMode,
} from '../ui-image-input.types';
import { CoreEventsService } from '../../services';
import { DxyBaseModalComponent, ModalSize } from '@datagalaxy/ui/dialog';
import { ZoneUtils } from '@datagalaxy/utils';
import { TranslateModule } from '@ngx-translate/core';
import { FileDropDirective } from '@datagalaxy/ui/forms';
import { NgIf } from '@angular/common';
import { DxyIconButtonDirective } from '@datagalaxy/ui/buttons';

// Note: this component is used both as a modal and not
@Component({
    selector: 'dxy-image-input',
    templateUrl: './image-input.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgIf,
        FileDropDirective,
        TranslateModule,
        MatLegacyMenuModule,
        DxyIconButtonDirective,
    ],
})
export class DxyImageInputComponent
    extends DxyBaseModalComponent<IUiImageInputResolve, IUiImageInputResult>
    implements OnInit, OnChanges, AfterViewInit
{
    @Input() public params: IUiImageInputParams;
    /**
     * Specifies if we use the component inside a modal
     * It prevents the component to consider itself being in modal edit mode
     * since MAT_DIALOG_DATA could be injected from the parent's modal
     */
    @Input() public isInModalDialog: boolean;

    public imgSrc: string;
    public trustedImgSrc: SafeUrl;
    public isEditorVisible = false;
    public droppedFiles: File[];

    public get isWrapperVisible() {
        return this.isPlaceHolderVisible || this.isImageVisible;
    }
    public get isMenuVisible() {
        return (
            !this.isInModal &&
            !this.isNoEdit &&
            (this.isImageVisible || this.isPlaceHolderVisible)
        );
    }
    public get isActionEditVisible() {
        return this.hasImage && !this.isDefaultImage;
    }
    public get isActionResetVisible() {
        return this.canDeleteImage && !!this.params?.defaultImageUrl;
    }
    public get isActionDeleteVisible() {
        return this.canDeleteImage && !this.params?.defaultImageUrl;
    }

    public get isPlaceHolderVisible() {
        return (
            !this.isInModal &&
            !this.isEditorVisible &&
            this.isInitDone &&
            !this.hasImage
        );
    }
    public get placeHolderText() {
        const o = this.params.placeHolderText;
        return typeof o == 'function' ? o() : o;
    }
    public get placeHolderClass() {
        const o = this.params.placeHolderClass;
        return (typeof o == 'function' ? o() : o) ?? '';
    }

    public get isImageVisible() {
        return !this.isInModal && !this.isEditorVisible && this.hasImage;
    }
    public get isRound() {
        return this.params?.crop == CropMode.round;
    }
    public get isDefaultImage() {
        return !!this.imgSrc && this.imgSrc == this.params?.defaultImageUrl;
    }

    @ViewChild('menuTrigger') menuTrigger: MatMenuTrigger;

    private element: HTMLElement;
    private isInitDone: boolean;
    private isInModal: boolean;
    private editor: DokaImageEditor;
    private gettingImage: Promise<string>;

    private readonly overlayIn = (e: MouseEvent) => {
        this.isInOverlay = true;
        this.showHideOverlay(e);
    };
    private readonly overlayOut = (e: MouseEvent) => {
        this.isInOverlay = false;
        this.showHideOverlay(e);
    };
    private readonly dragStart = (e: DragEvent) => {
        this.isDragging = true;
        this.showHideOverlay(e);
    };
    private readonly dragEnd = (e: DragEvent) => {
        this.isDragging = false;
        this.showHideOverlay(e);
    };
    private isInOverlay: boolean;
    private isDragging: boolean;
    private isMenuOpen = false;

    private get isInplaceEdit() {
        return this.isInplaceEditMini || this.isInplaceEditFull;
    }
    private get isInplaceEditMini() {
        return this.mode == UiImageInputMode.inplaceEditMini;
    }
    private get isInplaceEditFull() {
        return this.mode == UiImageInputMode.inplaceEditFull;
    }
    private get isModalEdit() {
        return this.mode == UiImageInputMode.modalEdit;
    }
    private get isNoEdit() {
        return !this.mode;
    }
    private get mode() {
        return this.params?.mode;
    }
    private get hasImage() {
        return !!this.imgSrc;
    }
    private get canDeleteImage() {
        return (
            this.params?.deleteImage && this.hasImage && !this.isDefaultImage
        );
    }

    constructor(
        private ngZone: NgZone,
        private sanitizer: DomSanitizer,
        private changeDetector: ChangeDetectorRef,
        private elementRef: ElementRef<HTMLElement>,
        private coreEventsService: CoreEventsService,
        private dxyDialogService: DxyDialogService,
        // Decorated with Optional because this component is used both as a dialog, and not.
        @Optional()
        dialogRef: MatDialogRef<DxyImageInputComponent, IUiImageInputResult>,
        @Optional() @Inject(MAT_DIALOG_DATA) data: IUiImageInputResolve,
    ) {
        super(dialogRef, data);
        super.subscribe(coreEventsService.windowKeyDownEscape$, (e) =>
            this.onKeydownEscape(e),
        );
    }

    ngOnChanges(changes: SimpleChanges) {
        super.onChange(changes, 'params', () => this.onParamsChanged());
    }
    ngOnInit() {
        this.preInit();
    }
    ngAfterViewInit() {
        setTimeout(() => this.postInit());
    }

    public trustUrl(url: string) {
        return this.sanitizer.bypassSecurityTrustUrl(url);
    }

    public onMenuOpenClose(open: boolean) {
        this.log('onMenuOpenClose', open);
        this.isMenuOpen = open;
        this.showHideOverlay();
    }

    public onFilesDropped() {
        this.log('onFilesDropped', this.droppedFiles);
        this.startEdit(this.droppedFiles[0]);
    }
    public async onActionUploadClick() {
        const blob = await DomUtil.getUserImage();
        this.startEdit(blob);
    }
    public onActionEditClick() {
        this.closeMenu();
        this.startEdit(this.imgSrc);
    }
    public onActionResetClick() {
        this.closeMenu();
        this.doDeleteReset();
    }
    public onActionDeleteClick() {
        this.closeMenu();
        this.doDeleteReset();
    }

    private onParamsChanged() {
        if (this.isInModal) {
            return;
        }
        this.log('onParamsChanged', this.params);
        this.editor?.destroy();
        this.editor = this.gettingImage = undefined;
        this.postInit();
    }

    private preInit() {
        this.log('preInit', {
            params: this.params,
            logId: this.logId,
            modalData: this.data,
        });
        if (this.data && !this.isInModalDialog) {
            this.isInModal = true;
            this.params = this.data.params;
            this.logId = `${this.logId ?? ''}(in-modal)`;
            return;
        }
    }

    private async postInit() {
        this.element = DomUtil.getElement(this.elementRef);
        this.log('postInit', !!this.element);
        if (this.isInModal) {
            this.setupEdit(this.data.src);
            this.isInitDone = true;
            return;
        }

        const r = this.params?.getImageUrl?.();
        this.gettingImage =
            r == null || typeof r == 'string' ? Promise.resolve(r) : r;
        this.setMaxSize();
        this.setRatioSize();
        this.initUiEvents();
        const imgSrc = await this.gettingImage;
        this.setImgSrc(imgSrc || this.params?.defaultImageUrl);
        this.isInitDone = true;
        await this.toggleEditor(false);
    }
    private setMaxSize() {
        const maxSize = this.params?.imageSize || this.params?.editSize;
        const current = this.getCurrentMaxSize();
        const value = maxSize || current;
        this.log('setMaxSize', value, maxSize, current);
        value && this.setCssVarPx('imageMaxSizePx', value);
    }

    private setRatioSize() {
        const ratio = this.params?.forcedRatio;
        if (!ratio) {
            return;
        }

        const maxSize = this.getCurrentMaxSize();
        if (ratio >= 1) {
            const maxHeight = maxSize / ratio;
            this.setCssVarPx('imageHeightPx', maxHeight);
        } else {
            const maxWidth = maxSize / ratio;
            this.setCssVarPx('imageWidthPx', maxWidth);
        }
    }

    private initUiEvents() {
        // for debug:
        // this.element.querySelectorAll<HTMLElement>('.image, .placeholder, .wrapper, .overlay, .dropzone, .action-menu')
        //     .forEach(el => ['mouseenter', 'mouseleave', 'dragenter', 'dragleave'].forEach(s => el.addEventListener(s, (e: Event) =>
        //         console.log('showHide', e.type, (e.currentTarget as HTMLElement).className))))

        const elOverlay = this.element.querySelector<HTMLElement>('.overlay');
        ZoneUtils.zoneExecute(
            () => {
                if (elOverlay) {
                    elOverlay.addEventListener('mouseenter', this.overlayIn);
                    elOverlay.addEventListener('mouseleave', this.overlayOut);
                    elOverlay.addEventListener('dragenter', this.overlayIn);
                } else {
                    this.log('elOverlay not found');
                }

                const elDropZone =
                    this.element.querySelector<HTMLElement>('.dropzone');
                if (elDropZone) {
                    elDropZone.addEventListener('dragenter', this.dragStart);
                    elDropZone.addEventListener('dragleave', this.dragEnd);
                    elDropZone.addEventListener('drop', () => this.dragEnd);
                } else {
                    this.log('elDropZone not found');
                }
            },
            this.ngZone,
            true,
        );
    }

    private startEdit(src?: string | Blob) {
        if (this.isInplaceEdit) {
            this.setupEdit(src);
        } else if (this.isModalEdit) {
            this.openModal(src);
        }
    }

    /** open a new instance of this component in a modal */
    private async openModal(src?: string | Blob) {
        this.log('openModal', src);
        let result: IUiImageInputResult;
        try {
            result = await this.getModalresult({ params: this.params, src });
            this.log('modal closed', result);
            const file = result?.imageFile;
            if (!file) {
                return;
            }
            const size: ISize = this.params?.upscale
                ? undefined
                : result.imageSize;
            this.updateImage(file, size);
            this.onEditorDone(file);
        } catch (reason) {
            this.log('modal dismissed', reason);
            this.onEditorDone();
        }
    }

    private setupEdit(src: string | Blob) {
        this.log('setupEdit', !!this.editor, src);
        if (this.editor) {
            this.editor.src = src;
            this.toggleEditor(true);
        } else {
            this.initEditor(src);
        }
    }
    private async initEditor(src: string | Blob) {
        this.log('initEditor');
        const container = this.setupContainer(); // while image is visible
        await this.toggleEditor(true); // before editor init
        DokaHelper.registerPlugins();
        const init = this.isInplaceEditMini ? overlayEditor : appendEditor;
        const editor = init(container, this.makeEditorOptions(src));
        if (editor) {
            editor.on('load', (res: unknown) =>
                this.onEditorLoaded(res, editor.element),
            );
            editor.on('process', (res: IDestCropState) =>
                this.onEditorProcessed(res),
            );
            editor.on('close', () => this.onEditorClosed());
        } else {
            this.log('!! no editor', container);
        }
        this.editor = editor;
    }
    private setupContainer() {
        const container = this.element.querySelector<HTMLElement>('.editor');
        const size = this.params?.editSize;
        let clas: string,
            w: number,
            h = size;
        if (this.isInplaceEditMini) {
            const bcr = this.element
                .querySelector<HTMLElement>('.image')
                .getBoundingClientRect();
            (w = bcr.width), (h = bcr.height);
            if (size && h < size) {
                h = size;
            }
            if (w <= h) {
                w = h + 1;
            } // editor must be width > height
            clas = 'overlay';
        } else if (this.isInModal) {
            clas = 'in-modal';
        }
        this.log('setEditorSize', w, h);
        this.setCssVarPx('editorMaxWidthPx', w);
        this.setCssVarPx('editorHeightPx', h);
        clas && container.classList.add(clas);
        return container;
    }
    private makeEditorOptions(src: string | Blob) {
        const wantedSize =
            this.params?.finalSize ??
            this.params?.editSize ??
            this.getCurrentMaxSize();
        const finalSize = wantedSize ? wantedSize * 2 : wantedSize;
        const outputImageSizeMaxPx = finalSize ?? 500,
            upscale = this.params?.upscale;
        const mimeType = this.params?.mimeType ?? 'image/png';
        const imageWriter = this.params?.storeImage
            ? DokaHelper.createThumbnailImageWriter(
                  (main: File, thumbnail: File) =>
                      this.onEditorStore(main, thumbnail),
                  outputImageSizeMaxPx,
                  undefined,
                  upscale,
                  mimeType,
              )
            : createDefaultImageWriter();
        return DokaHelper.makeOptions({
            src,
            imageWriter,
            mini: this.isInplaceEditMini,
            cropMode: this.params?.crop,
            noResize: !this.params?.showResize,
            forcedRatio: this.params?.forcedRatio,
            languageCode: this.coreEventsService.uiLanguage,
            enableButtonClose: this.isInModal || this.isInplaceEditFull,
            targetSize: finalSize,
            targetUpscale: upscale,
        });
    }

    private onEditorLoaded(res: unknown, editorElement: HTMLElement) {
        this.log('onEditorLoaded', res, !!editorElement);
        this.addE2eIds(editorElement);
    }
    private async addE2eIds(editorElement: HTMLElement) {
        this.log('addE2eIds-start', editorElement);
        const opt: IOptionsWaitFor = {
            // test every 0.5s, for 10s max
            pollDelayMs: 500,
            timeoutMs: 10000,
            resolveWithNullOnError: true,
        };
        const elSaveButton = await DomUtil.waitForChildElement(
            editorElement,
            '.DokaNavTools .DokaButton.DokaButtonExport',
            opt,
        );
        elSaveButton?.setAttribute(
            'data-testid',
            'image-input-doka-save-button',
        );
        this.log('addE2eIds-end', elSaveButton);
    }
    private async onEditorStore(imageFile: File, thumbnail: File) {
        const storeFn = this.params?.storeImage;
        this.log('onEditorStore', !!storeFn, imageFile, thumbnail);
        const success = await storeFn?.(imageFile, thumbnail);
        this.log('onEditorStore-done', success);
    }
    private onEditorProcessed(res: IDestCropState) {
        this.log('onEditorProcessed', res);
        const file = res.dest;
        const size: ISize = this.params?.upscale
            ? undefined
            : res.imageState.crop;
        if (this.isInplaceEdit) {
            this.updateImage(file, size);
            this.toggleEditor(false);
            this.onEditorDone(file);
        } else if (this.isInModal) {
            this.inModalClose(file, size);
        } else {
            this.updateImage(file, size);
            this.onEditorDone(file);
        }
    }
    private onEditorClosed() {
        this.log('onEditorClosed');
        if (this.isInModal) {
            this.inModalClose();
            return;
        }
        if (this.isInplaceEdit) {
            this.toggleEditor(false);
        }
        this.onEditorDone();
    }

    private inModalClose(imageFile?: File, imageSize?: ISize) {
        this.log('inModalClose', imageFile);
        const result = imageFile ? { imageFile, imageSize } : undefined;
        this.editor.destroy();
        this.onModalClose(result);
    }

    private onEditorDone(file?: File) {
        this.log('onEditorDone', file);
        this.params?.onDone?.(file);
    }

    private async toggleEditor(showEditor: boolean) {
        this.log('toggleEditor', showEditor);
        this.isEditorVisible = showEditor;
        this.changeDetector.detectChanges();
        return wait(undefined, this.ngZone, true);
    }
    private updateImage(file: File, size?: ISize) {
        this.log('updateImage', size);
        this.setImgSrc(URL.createObjectURL(file));
        size && this.setImageSize(size);
        this.changeDetector.detectChanges();
    }
    private setImageSize(size: ISize) {
        const max = this.getCurrentMaxSize();
        const isLandscape = size.width > size.height;
        this.setCssVarPx(
            'imageWidthPx',
            isLandscape ? Math.min(max, size.width) : undefined,
        );
        this.setCssVarPx(
            'imageHeightPx',
            isLandscape ? Math.min(max, size.height) : undefined,
        );
    }
    private getCurrentMaxSize() {
        return parseInt(
            this.element.style.getPropertyValue('--imageMaxSizePx'),
            10,
        );
    }
    private setCssVarPx(varName: string, value: number) {
        this.element.style.setProperty(
            `--${varName}`,
            value ? `${value}px` : 'unset',
        );
    }

    private async doDeleteReset() {
        if (this.params?.deleteImage) {
            await this.params.deleteImage();
        }
        this.setImgSrc(this.params?.defaultImageUrl);
        const maxSize = this.getCurrentMaxSize();
        this.setCssVarPx('imageWidthPx', maxSize);
        this.setCssVarPx('imageHeightPx', maxSize);
        this.changeDetector.detectChanges();
    }

    private setImgSrc(imgSrc: string) {
        this.imgSrc = imgSrc;
        this.trustedImgSrc = this.sanitizer.bypassSecurityTrustUrl(imgSrc);
    }

    private async getModalresult(data: IUiImageInputResolve) {
        return this.dxyDialogService.open<
            DxyImageInputComponent,
            IUiImageInputResolve,
            IUiImageInputResult
        >({
            componentType: DxyImageInputComponent,
            size: ModalSize.Large,
            data,
        });
    }
    private onModalClose(result: IUiImageInputResult) {
        if (result) {
            this.result = result;
            super.onCloseSubmit();
        } else {
            super.onCloseCancel();
        }
    }

    private closeMenu() {
        this.menuTrigger.closeMenu();
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    private showHideOverlay(event?: MouseEvent) {
        const show = this.isInOverlay || this.isDragging || this.isMenuOpen;
        this.element
            .querySelector<HTMLElement>('.wrapper .overlay')
            ?.classList.toggle('off', !show);
    }

    private onKeydownEscape(event: KeyboardEvent) {
        if (!this.isMenuOpen) {
            return;
        }
        event.stopPropagation();
        this.closeMenu();
    }
}
