import { Injectable } from '@angular/core';
import { EntityType } from '@datagalaxy/dg-object-model';
import { SecurityService } from '../services/security.service';
import {
    CreateRuleParameter,
    DataQualityApiService,
    DataQualityResult,
    DataQualityRule,
    UpdateRuleParameter,
} from '@datagalaxy/webclient/data-quality/data-access';
import { DxyModalService } from '../shared/dialogs/DxyModalService';
import { RuleCreateModalComponent } from './rule-create-modal/rule-create-modal.component';
import {
    IRuleCreateModalInput,
    IRuleCreateModalOutput,
} from './rule-create-modal/rule-create-modal.types';
import { RuleEditModalComponent } from './rule-edit-modal/rule-edit-modal.component';
import {
    IRuleEditModalInput,
    IRuleEditModalOutput,
} from './rule-edit-modal/rule-edit-modal.types';
import { RuleEditable } from './data-quality.types';
import { Subject } from 'rxjs';
import { DialogType } from '@datagalaxy/ui/dialog';

@Injectable({ providedIn: 'root' })
export class DataQualityService {
    public AllowStructureEntityTypes = new Set([
        EntityType.View,
        EntityType.Table,
        EntityType.File,
        EntityType.Document,
        EntityType.Directory,
    ]);

    public AllowFieldEntityTypes = new Set([
        EntityType.Field,
        EntityType.Column,
        EntityType.SubStructure,
    ]);

    public get ruleUpdated$() {
        return this.ruleUpdatedSource.asObservable();
    }

    private readonly ruleUpdatedSource = new Subject<RuleEditable>();

    public get ruleDeleted$() {
        return this.ruleDeletedSource.asObservable();
    }

    private readonly ruleDeletedSource = new Subject<RuleEditable>();

    constructor(
        private dataQualityApiService: DataQualityApiService,
        private securityService: SecurityService,
        private dxyModalService: DxyModalService,
    ) {}

    public static getDataQualityResultTranslateKey(result: DataQualityResult) {
        const keyPrefix = 'UI.Modeler.DataQuality.';
        switch (result) {
            case DataQualityResult.Passed:
                return keyPrefix + 'passed';
            case DataQualityResult.Failed:
                return keyPrefix + 'critical';
            case DataQualityResult.Warning:
                return keyPrefix + 'warning';
            default:
                return keyPrefix + 'unknown';
        }
    }

    public async getEntityRules(entityId: string): Promise<DataQualityRule[]> {
        const result =
            await this.dataQualityApiService.getEntityRules(entityId);
        return result.rules;
    }

    public async getRuleDetails(ruleId: string): Promise<DataQualityRule> {
        return await this.dataQualityApiService.getRuleDetails(ruleId);
    }

    public async deleteRuleCheck(
        ruleId: string,
        checkId: string,
    ): Promise<void> {
        await this.dataQualityApiService.deleteRuleCheck(ruleId, checkId);
    }

    public async createRule(
        createRuleParameter: CreateRuleParameter,
    ): Promise<{ ruleId: string }> {
        return await this.dataQualityApiService.createRule(createRuleParameter);
    }

    public async updateRule(
        ruleId: string,
        updateRuleParameter: UpdateRuleParameter,
    ): Promise<void> {
        await this.dataQualityApiService.updateRule(
            ruleId,
            updateRuleParameter,
        );
    }

    public async deleteRule(ruleId: string): Promise<void> {
        await this.dataQualityApiService.deleteRule(ruleId);
    }

    public isDataQualityEnabled(entityType: EntityType) {
        return (
            this.securityService.isDataQualityEnabled() &&
            (this.AllowStructureEntityTypes.has(entityType) ||
                this.AllowFieldEntityTypes.has(entityType))
        );
    }

    public async openRuleCreationModal(
        entityId: string,
        entityType: EntityType,
    ): Promise<{ ruleId: string } | undefined> {
        const result = await this.dxyModalService.open<
            RuleCreateModalComponent,
            IRuleCreateModalInput,
            IRuleCreateModalOutput
        >({
            componentType: RuleCreateModalComponent,
            data: {
                entityId,
                entityType,
            },
        });
        if (!result) {
            return;
        }
        return await this.createRule({
            statement: result.statement,
            workspaceId: entityId.split(':')[0],
            code: result.code,
            type: result.type,
            entityId: entityId,
            fieldIds: result.fieldIds,
        });
    }

    public async openRuleEditionModal(
        rule: RuleEditable,
    ): Promise<{ ruleId: string } | undefined> {
        const result = await this.dxyModalService.open<
            RuleEditModalComponent,
            IRuleEditModalInput,
            IRuleEditModalOutput
        >({
            componentType: RuleEditModalComponent,
            data: {
                ruleId: rule.id,
                ...rule,
            },
        });
        if (!result) {
            return;
        }
        await this.updateRule(rule.id, {
            statement: result.statement,
            type: result.type,
            code: result.code,
            fieldIds: result.fieldIds,
        });
        this.ruleUpdatedSource.next({
            ...rule,
            ...result,
        });
    }

    public async openRuleDeletionModal(rule: RuleEditable): Promise<boolean> {
        const result = await this.dxyModalService.confirm({
            titleKey: 'UI.DataQualityRules.deletionModal.title',
            messageKey: 'UI.DataQualityRules.deletionModal.message',
            type: DialogType.Action,
            confirmButtonKey: 'UI.Dialog.btnDelete',
        });
        if (!result) {
            return result;
        }
        await this.deleteRule(rule.id);
        this.ruleDeletedSource.next(rule);
    }
}
