import { Injectable } from '@angular/core';
import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { CurrentUserService } from './current-user';

/**
 * ## Role
 * Add User language header to the request
 */
@Injectable()
export class UserLanguageHeaderInterceptor implements HttpInterceptor {
    constructor(private currentUserService: CurrentUserService) {}

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler,
    ): Observable<HttpEvent<any>> {
        const userLanguage = this.currentUserService.languageCode;

        if (userLanguage) {
            req = req.clone({
                setHeaders: {
                    'datagalaxy-user-language': userLanguage,
                },
            });
        }
        return next.handle(req);
    }
}
