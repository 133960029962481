import { inject, Injectable } from '@angular/core';
import { FeatureFlagService } from '@datagalaxy/webclient/feature-flag';
import { Router } from '@angular/router';
import { CurrentUserService } from '@datagalaxy/webclient/user/data-access';

@Injectable({ providedIn: 'root' })
export class ClientRouterService {
    private featureFlagService = inject(FeatureFlagService);
    private currentUser = inject(CurrentUserService);
    private router = inject(Router);

    public getClientUrlCommands(clientId?: string): any[] {
        const authV2Enabled =
            this.featureFlagService.isFeatureEnabled('ENABLE_AUTH_V2');

        return authV2Enabled
            ? ['/']
            : ['/client', clientId || this.currentUser.clientId];
    }

    public goToClient(clientId: string) {
        return this.router.navigate(this.getClientUrlCommands(clientId));
    }

    public async goToWorkspaceList(clientId?: string) {
        const clientUrlPath = this.getClientUrlCommands(clientId);
        return this.router.navigate([...clientUrlPath, 'spaces-list']);
    }

    public async goToDashboard(dashboardId?: number) {
        const clientUrlPath = this.getClientUrlCommands(
            this.currentUser.clientId || '',
        );
        return this.router.navigate([
            ...clientUrlPath,
            'dashboard',
            dashboardId ? dashboardId : '',
        ]);
    }
}
