import {
    Component,
    ElementRef,
    Input,
    NgZone,
    Optional,
    Self,
    ViewChild,
} from '@angular/core';
import { FormsModule, NgControl } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DxyHyperlinkFieldControlComponent } from '../controls/hyperlink-field-control/hyperlink-field-control.component';
import { IHyperlinkModel } from './hyperlink.types';
import { DxyBaseFocusableFieldComponent } from '@datagalaxy/ui/fields';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { EllipsisTooltipDirective } from '@datagalaxy/ui/tooltip';
import { NgIf } from '@angular/common';

@Component({
    selector: 'dxy-field-hyperlink',
    templateUrl: 'field-hyperlink.component.html',
    standalone: true,
    imports: [
        NgIf,
        EllipsisTooltipDirective,
        DxyHyperlinkFieldControlComponent,
        FormsModule,
        MatLegacyFormFieldModule,
        TranslateModule,
    ],
})
export class DxyFieldHyperlinkComponent extends DxyBaseFocusableFieldComponent<IHyperlinkModel> {
    /** When true (and readonly is false) the inputs are always visible */
    @Input() editOnly: boolean;
    /** specific label for the Name input, in case we do not want the global label */
    @Input() nameLabel: string;
    /** optional specific class for both input labels */
    @Input() inputsLabelClass: string;

    public get label() {
        return this.getLabel(this.translate);
    }
    public get labelTooltip() {
        return this.getLabelTooltip(this.translate);
    }
    public get errorMessage() {
        return this.getErrorMessage(this.translate);
    }
    public get hasUrl(): boolean {
        return this.fieldControl.hasUrl;
    }

    @ViewChild('fieldControl')
    protected fieldControl: DxyHyperlinkFieldControlComponent;

    protected get empty() {
        return this.fieldControl?.empty;
    }

    constructor(
        private translate: TranslateService,
        elementRef: ElementRef<HTMLElement>,
        ngZone: NgZone,
        @Optional() @Self() ngControl: NgControl,
    ) {
        super(elementRef, ngZone, ngControl);
    }
}
