import {
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import { EnumNumberFieldSelectAdapter } from '@datagalaxy/core-ui';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { UiOptionSelectDataType } from '@datagalaxy/core-ui';
import { CollectionsHelper } from '@datagalaxy/core-util';
import { IMultiSelectData } from '@datagalaxy/core-ui';
import {
    SimpleUnitaryFieldValidator,
    UnitaryFieldData,
} from '../../shared/fields/unitary/UnitaryFieldValidator';
import { ValueUnitaryFieldData } from '../../shared/fields/unitary/ValueUnitaryFieldData';
import { IUnitaryFieldActionsInput } from '../../shared/fields/unitary/IUnitaryFieldActionsInput';
import { TeamService } from '../team.service';
import { SecurityService } from '../../services/security.service';
import { RichTextMentionService } from '../../shared/richText/mentions/rich-text-mention.service';
import { UserService } from '../../services/user.service';
import { AppDataService } from '../../services/app-data.service';
import { MultiSelectAdapter } from '../../shared/shared-ui/UiMultiSelect.util';
import {
    TeamAccessType,
    TeamDto,
    TeamMemberDto,
} from '@datagalaxy/webclient/team/data-access';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { UserPublicData } from '@datagalaxy/webclient/user/domain';
import { NgIf } from '@angular/common';
import { DxyFieldMultiSelectComponent } from '@datagalaxy/core-ui/fields';
import { DxyFieldEmailComponent } from '@datagalaxy/core-ui/fields';
import { DxyRichTextFieldComponent } from '@datagalaxy/core-ui/rich-text';
import { DxyFieldSelectComponent } from '@datagalaxy/core-ui/fields';
import { DxyUnitaryFieldActionsComponent } from '../../shared/fields/unitary/dxy-unitary-field-actions/dxy-unitary-field-actions.component';
import { DxyFieldTextComponent } from '@datagalaxy/ui/fields';
import { FormsModule } from '@angular/forms';
import { DxyButtonDirective } from '@datagalaxy/ui/buttons';

@Component({
    selector: 'app-team-profile',
    templateUrl: 'team-profile.component.html',
    styleUrls: ['team-profile.component.scss'],
    standalone: true,
    imports: [
        FormsModule,
        DxyFieldTextComponent,
        DxyUnitaryFieldActionsComponent,
        DxyFieldSelectComponent,
        DxyRichTextFieldComponent,
        DxyFieldEmailComponent,
        DxyFieldMultiSelectComponent,
        NgIf,
        DxyButtonDirective,
        TranslateModule,
    ],
})
export class TeamProfileComponent
    extends DxyBaseComponent
    implements OnInit, OnChanges
{
    @Input() teamData: TeamDto;
    @Input() showJoinBtn: boolean;
    @Input() hasEditRights: boolean;
    @Input() teamOwnersInput: TeamMemberDto[];

    public teamAccessAdapter: EnumNumberFieldSelectAdapter<TeamAccessType>;
    public currentAccessHint: string;

    public readonly validator = new SimpleUnitaryFieldValidator(
        (ufd) => this.updateTeam(ufd),
        { log: this.logger, noResetAfterSave: false },
    );
    public readonly teamName = new ValueUnitaryFieldData<string>(
        'TeamName',
        this.validator,
    );
    public readonly teamDescription = new ValueUnitaryFieldData<string>(
        'Description',
        this.validator,
    );
    public readonly teamEmail = new ValueUnitaryFieldData<string>(
        'Email',
        this.validator,
    );
    public fieldEmailCheckSyntax: (s: string) => string;

    public readonly accessType = new ValueUnitaryFieldData<TeamAccessType>(
        'AccessType',
        this.validator,
    );
    public teamOwners: IUnitaryFieldActionsInput;
    public teamOwnersData: IMultiSelectData<UserPublicData>;

    public get mentionResolvers() {
        return this.richTextMentionService.getAllUserMentionResolvers();
    }

    public get emailErrorMsg() {
        return this.fieldEmailCheckSyntax(this.teamEmail.currentValue);
    }

    public get isJoinBtnVisible() {
        return this.showJoinBtn && !this.teamData.IsCurrentUserMember;
    }
    public get joinTranslateKey() {
        return this.teamData.CurrentUserHasMembershipRequest
            ? 'UI.Teams.Profile.pendingJoinTeamBtn'
            : this.teamData.AccessType == TeamAccessType.Open
              ? 'UI.Teams.Profile.joinTeamBtn'
              : 'UI.Teams.Profile.askJoinTeamBtn';
    }

    constructor(
        private translate: TranslateService,
        private teamService: TeamService,
        private securityService: SecurityService,
        private richTextMentionService: RichTextMentionService,
        private userService: UserService,
        private appDataService: AppDataService,
    ) {
        super();
    }

    ngOnChanges(changes: SimpleChanges) {
        super.onChanges(changes, ['teamOwnersInput'], () => {
            if (!this.teamOwnersData) {
                return;
            }
            this.initUsersMultiselectDataAsync();
        });
    }

    ngOnInit() {
        this.teamName.setInitialValue(this.teamData.TeamName);
        this.teamDescription.setInitialValue(this.teamData.Description);
        this.teamEmail.setInitialValue(this.teamData.Email);
        this.teamEmail.hasExternalError = () => !!this.emailErrorMsg;
        // nullable email check
        this.fieldEmailCheckSyntax = (s: string) =>
            !s ? '' : this.securityService.emailFormatCheckFn(s);

        this.currentAccessHint = this.translate.instant(
            `UI.Teams.CreateModal.${
                TeamAccessType[this.teamData.AccessType]
            }AccessHint`,
        );
        this.teamAccessAdapter = new EnumNumberFieldSelectAdapter(
            TeamAccessType,
            {
                initialValue: this.teamData.AccessType,
                getTextKey: (t) =>
                    `UI.Teams.CreateModal.${TeamAccessType[t]}Access`,
                getSubTextKey: (t) =>
                    `UI.Teams.CreateModal.${TeamAccessType[t]}AccessHint`,
                getGlyphClass: (t) => {
                    switch (t) {
                        case TeamAccessType.Private:
                            return 'glyph-lock';
                        case TeamAccessType.Limited:
                            return 'glyph-user-unlock';
                        case TeamAccessType.Open:
                            return 'glyph-person';
                    }
                },
                onSelectionChange: async (t) => {
                    this.currentAccessHint = this.translate.instant(
                        `UI.Teams.CreateModal.${TeamAccessType[t]}AccessHint`,
                    );

                    const newTeamData = new TeamDto();
                    newTeamData.TeamUid = this.teamData.TeamUid;
                    newTeamData.AccessType = t;
                    const updateResult =
                        await this.teamService.updateTeam(newTeamData);
                    this.teamData = updateResult.UpdatedTeam;
                },
            },
        );

        this.initUsersMultiselectDataAsync().then();
    }

    public getTranslateKey(suffix: string) {
        return `UI.Teams.Profile.${suffix}`;
    }

    public async onClickJoinTeamBtn() {
        if (this.teamData.AccessType == TeamAccessType.Limited) {
            await this.teamService.openAskMembershipModal(
                this.teamData.TeamUid,
            );
            this.teamData.CurrentUserHasMembershipRequest = true;
        } else {
            const currentUserId = this.appDataService.currentUserId;
            await this.teamService.addTeamMembers(
                this.teamData,
                [currentUserId],
                [],
                true,
            );
            this.teamData.IsCurrentUserMember = true;
            this.teamService.notifyJoinTeam(this.teamData.TeamUid);
        }
    }

    public onEmailChange() {
        if (this.emailErrorMsg) {
            this.teamEmail.setError(this.emailErrorMsg);
            return;
        }
        this.teamEmail.clearError();
    }

    private async updateTeam(ufd: UnitaryFieldData) {
        const newTeamData = new TeamDto();
        newTeamData.TeamUid = this.teamData.TeamUid;
        newTeamData[ufd.fieldKey] = ufd.currentValue;

        return await this.teamService.updateTeam(newTeamData);
    }

    private async initUsersMultiselectDataAsync() {
        const items = await this.userService.getNonDeletedUsers();
        const selectedItems = items?.filter((user) =>
            this.teamData.TeamOwners?.some(
                (owner) => owner.ReferenceId == user.ReferenceId,
            ),
        );
        this.teamOwnersData = {
            items,
            dataType: UiOptionSelectDataType.user,
            searchParams: {
                enabled: true,
            },
            selectedItems,
            adapter: MultiSelectAdapter.userPublicData,
            onSelectionChange: (selectedItems) =>
                this.onSelectionChange(selectedItems),
        };

        // const ufd: IUnitaryFieldData = new ValueUnitaryFieldData('accessType', this.validator)
        //this.teamOwners = this.validator.buildActionsInput(ufd)
    }

    private async onSelectionChange(selectedItems) {
        const selectedMembers = selectedItems.map((item) =>
            this.getMemberFromUser(item),
        );
        if (
            !CollectionsHelper.anyDifference(
                selectedMembers.map((selected) => selected.UserId),
                this.teamData.TeamOwners.map((owner) => owner.UserId),
            )
        ) {
            return;
        }
        const newTeamData = new TeamDto();
        newTeamData.TeamUid = this.teamData.TeamUid;
        newTeamData.TeamOwners = selectedMembers;
        newTeamData.MembersCount = this.teamData.MembersCount;
        const updateResult = await this.teamService.updateTeam(newTeamData);
        this.teamData = updateResult.UpdatedTeam;
    }

    private getMemberFromUser(user: UserPublicData): TeamMemberDto {
        const member = new TeamMemberDto();
        member.UserId = user.UserId;
        member.IsTeamOwner = true;
        return member;
    }
}
