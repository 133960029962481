import { ColumnSizingState } from '@tanstack/table-core';
import { MapUtils } from '@datagalaxy/utils';

export class GridColumnSizeUtils {
    public static fitColumnSizingToWidth(
        columns: { id: string; width: number; minWidth?: number }[],
        availableWidth: number,
    ): ColumnSizingState {
        const map = new Map<string, number>();

        let totalWidth =
            MapUtils.sum(columns, (c) => c.width) || columns.length;

        if (totalWidth > availableWidth) {
            columns
                .filter((c) => c.minWidth !== undefined)
                .forEach((col) => {
                    map.set(col.id, col.minWidth ?? 0);
                    totalWidth -= col.width ?? 0;
                    availableWidth -= col.minWidth ?? 0;
                });
        }

        columns
            .filter((col) => !map.get(col.id))
            .forEach((col) => {
                const width = col.width || 1;

                const res = Math.max(
                    Math.floor((width / totalWidth) * availableWidth),
                    0,
                );

                map.set(col.id, res);
            });
        return MapUtils.toRecord(map);
    }
}
