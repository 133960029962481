<div
    *ngIf="currentStep$ | async as currentStep"
    tabindex="-1"
    class="vcontainerDiv modal-container"
>
    <div class="modal-header">
        <h3 mat-dialog-title class="modal-title">
            {{ currentStep.step?.title }}
        </h3>
        <button dxyIconButton color="transparent" (click)="closeModal()">
            <i class="glyph-cancelsearch"></i>
        </button>
    </div>

    <div mat-dialog-content class="funnel-modal-content">
        <ng-template #stepContent></ng-template>
    </div>

    <div class="modal-footer">
        <div>
            <dxy-funnel-stepper
                *ngIf="currentStep.step?.stepperVisible"
                [currentIndex]="currentStep.currentStepperIndex"
                [stepCount]="currentStep.stepperCount"
            ></dxy-funnel-stepper>
        </div>
        <div class="dxy-actions">
            <button
                *ngFor="let action of actions"
                dxyButton
                [dxyFunnelModalAction]="action"
                [dxyFunnelModalActionStepValid]="currentStepValid$ | async"
                [variant]="action.variant ?? 'secondary'"
                [color]="action.color ?? 'default'"
            >
                {{ action.label }}
            </button>
        </div>
    </div>
</div>
