import { deserializeAs, inheritSerialization, serialize } from 'cerialize';
import {
    BaseServiceParameter,
    BaseServiceResult,
} from '@datagalaxy/data-access';
import { EntityType } from '@datagalaxy/dg-object-model';
import { CategoryTemplateDto, CategoryTemplateType } from './category-template';

@inheritSerialization(BaseServiceParameter)
export class BaseCategoryTemplateParameter extends BaseServiceParameter {
    @serialize SpaceId?: string;

    constructor(spaceId?: string) {
        super();
        this.SpaceId = spaceId;
    }
}

@inheritSerialization(BaseServiceResult)
export class GetCategoryTemplateListResult extends BaseServiceResult {
    @deserializeAs(CategoryTemplateDto)
    public CategoryTemplateDtoList!: CategoryTemplateDto[];
}

@inheritSerialization(BaseServiceResult)
export class GetCategoryTemplateResult extends BaseServiceResult {
    @deserializeAs(CategoryTemplateDto)
    public CategoryTemplateDto!: CategoryTemplateDto;
}

@inheritSerialization(BaseServiceParameter)
export class ResetCategoryTemplateParameter extends BaseServiceParameter {
    @serialize SpaceId: string;
    @serialize CategoryTemplateGuid: string;

    constructor(spaceId: string, templateId: string) {
        super();
        this.SpaceId = spaceId;
        this.CategoryTemplateGuid = templateId;
    }
}

@inheritSerialization(BaseServiceParameter)
export class UpdateCategoryTemplateParameter extends BaseServiceParameter {
    @serialize SpaceId: string;
    @serialize CategoryTemplateGuid: string;
    @serialize CategoryTemplate: string;
    @serialize TemplateType: CategoryTemplateType;

    constructor(
        spaceId: string,
        templateId: string,
        templateType: CategoryTemplateType,
        categoryTemplate: string,
    ) {
        super();
        this.SpaceId = spaceId;
        this.CategoryTemplateGuid = templateId;
        this.CategoryTemplate = categoryTemplate;
        this.TemplateType = templateType;
    }
}

@inheritSerialization(BaseServiceParameter)
export class ApplyScreenCategoryTemplateParameter extends BaseServiceParameter {
    @serialize SpaceId: string;
    @serialize CategoryTemplateGuid: string;
    @serialize EntityTypes: EntityType[];
    @serialize TemplateType: CategoryTemplateType;

    constructor(
        spaceId: string,
        templateId: string,
        templateType: CategoryTemplateType,
        entityTypes: EntityType[],
    ) {
        super();
        this.SpaceId = spaceId;
        this.CategoryTemplateGuid = templateId;
        this.EntityTypes = entityTypes;
        this.TemplateType = templateType;
    }
}
