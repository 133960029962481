import {
    BaseServiceParameter,
    BaseServiceResult,
} from '@datagalaxy/data-access';
import {
    deserialize,
    deserializeAs,
    inheritSerialization,
    serialize,
} from 'cerialize';
import {
    HierarchicalData,
    IEntityIdentifier,
} from '@datagalaxy/dg-object-model';
import { DataTypeDTO } from './data-type';
import { getSpaceIdFromEntity } from '@datagalaxy/webclient/utils';

@inheritSerialization(BaseServiceParameter)
export class PreDeleteDataTypeMappingParameter extends BaseServiceParameter {
    @serialize public TechnologyId: string;

    constructor(technologyId: string) {
        super();
        this.TechnologyId = technologyId;
    }
}

@inheritSerialization(BaseServiceResult)
export class PreDeleteDataTypeMappingResult extends BaseServiceResult {
    @deserializeAs(HierarchicalData) public Models!: HierarchicalData[];
    @deserialize public CanDeleteTechnology!: boolean;
}

@inheritSerialization(BaseServiceParameter)
export class GetModelDataTypesParameter extends BaseServiceParameter {
    @serialize public ModelDataReferenceId: string;
    @serialize public ProjectReferenceId?: string;

    static fromModelIdr(modelIdr: IEntityIdentifier) {
        const spaceId = getSpaceIdFromEntity(modelIdr);
        return new GetModelDataTypesParameter(
            modelIdr.ReferenceId,
            spaceId,
            modelIdr.VersionId,
        );
    }

    constructor(entityId: string, projectId?: string, versionId?: string) {
        super();
        this.ModelDataReferenceId = entityId;
        this.ProjectReferenceId = projectId;
        this.VersionId = versionId || '';
    }
}

@inheritSerialization(BaseServiceResult)
export class GetModelDataTypesResult extends BaseServiceResult {
    @deserialize public IsErrorUnknownModelReferenceId!: boolean;
    @deserialize public IsErrorUnknownProjectReferenceId!: boolean;
    @deserializeAs(DataTypeDTO) public DataTypes!: DataTypeDTO[];
}
