import {
    AfterViewInit,
    Component,
    HostBinding,
    TemplateRef,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import { BottomBannerService, IBottomBanner } from './bottom-banner.service';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { Observable } from 'rxjs';
import { animate, style, transition, trigger } from '@angular/animations';
import { DxyIconButtonDirective } from '@datagalaxy/ui/buttons';

@Component({
    standalone: true,
    selector: 'dxy-bottom-banner',
    templateUrl: 'bottom-banner.component.html',
    styleUrls: ['bottom-banner.component.scss'],
    imports: [CommonModule, TranslateModule, DxyIconButtonDirective],
    animations: [
        trigger('animation', [
            transition(':enter', [
                style({
                    opacity: 0,
                    bottom: -500,
                }),
                animate('1s ease-in-out', style({ opacity: 1, bottom: 0 })),
            ]),
            transition(':leave', [
                style({
                    opacity: 1,
                    bottom: 0,
                }),
                animate('1s ease-in-out', style({ opacity: 0, bottom: -500 })),
            ]),
        ]),
    ],
})
export class BottomBannerComponent
    extends DxyBaseComponent
    implements AfterViewInit
{
    @ViewChild(TemplateRef) private templateRef?: TemplateRef<any>;
    @HostBinding('@animation') animationState = true;

    protected banner$: Observable<IBottomBanner | null>;

    private portal?: TemplatePortal;
    private overlayRef?: OverlayRef;

    constructor(
        private bottomBannerService: BottomBannerService,
        private overlay: Overlay,
        private viewContainerRef: ViewContainerRef,
    ) {
        super();

        this.banner$ = this.bottomBannerService.bannerShowHide$;

        super.subscribe(this.bottomBannerService.bannerShowHide$, (banner) => {
            this.onShowHide(banner);
        });
    }

    ngAfterViewInit() {
        if (!this.templateRef) {
            return;
        }
        this.portal = new TemplatePortal(
            this.templateRef,
            this.viewContainerRef,
        );
    }

    public onDismissClick() {
        this.bottomBannerService.hideBanner();
    }

    private onShowHide(banner?: IBottomBanner | null) {
        if (!banner) {
            this.overlayRef?.detach();
            return;
        }

        const positionStrategy = this.overlay.position().global();
        this.overlayRef = this.overlay.create({
            positionStrategy,
            panelClass: 'bottom-banner-overlay',
        });

        this.overlayRef.attach(this.portal);
    }
}
