import { inject, Injectable } from '@angular/core';
import { IWorkspaceIdentifier } from '@datagalaxy/webclient/workspace/domain';
import { NavigationEnd, Router } from '@angular/router';
import { filter, map, switchMap } from 'rxjs';
import { getLocalId } from '@datagalaxy/webclient/utils';
import { CurrentUserService } from '@datagalaxy/webclient/user/data-access';
import { WorkspaceStore } from '@datagalaxy/webclient/workspace/data-access';
import { ClientRouterService } from '@datagalaxy/webclient/client/feature';

@Injectable({ providedIn: 'root' })
export class WorkspaceRouterService {
    private currentUser = inject(CurrentUserService);
    private workspaceStore = inject(WorkspaceStore);
    private clientRouter = inject(ClientRouterService);
    private router = inject(Router);

    public get isWorkspaceUrl$() {
        return this.router.events.pipe(
            filter((event) => event instanceof NavigationEnd),
            switchMap(() =>
                this.workspaceStore
                    .selectCurrentSpace()
                    .pipe(
                        map((workspace) =>
                            workspace
                                ? this.isWorkspaceUrlActive(workspace)
                                : false,
                        ),
                    ),
            ),
        );
    }

    public getWorkspaceUrlCommands(spaceIdr: IWorkspaceIdentifier): any[] {
        const client = this.clientRouter.getClientUrlCommands(
            this.currentUser.clientId || '',
        );
        const workspace = [
            'space',
            getLocalId(spaceIdr.spaceId),
            spaceIdr.versionId,
        ];

        return [...client, ...workspace];
    }

    public isWorkspaceUrlActive(workspaceIdr: IWorkspaceIdentifier) {
        const workspaceUrl =
            this.getWorkspaceUrlCommands(workspaceIdr).join('/');

        return this.router.isActive(workspaceUrl, {
            paths: 'subset',
            queryParams: 'subset',
            fragment: 'ignored',
            matrixParams: 'ignored',
        });
    }

    public async goToSpaceHome(spaceIdr: IWorkspaceIdentifier) {
        const path = this.getWorkspaceUrlCommands(spaceIdr);
        return this.router.navigate(path);
    }
}
