<div class="modal-header">
    <h3 mat-dialog-title class="modal-title" translate>
        UI.TechnologyAdmin.createModalTitle
    </h3>
    <button dxyIconButton color="transparent" (click)="onCloseCancel()">
        <i class="glyph-cancelsearch"></i>
    </button>
</div>
<form (ngSubmit)="onCloseSubmit()">
    <div mat-dialog-content>
        <dxy-field-text
            name="displayName"
            labelKey="UI.TechnologyAdmin.nameLbl"
            [(ngModel)]="technologyName"
            [takeFocus]="false"
            (ngModelChange)="onNameChange()"
            [ngModelOptions]="ngModelOptions"
            [errorMessageKey]="displayNameErrorKey"
            required
        ></dxy-field-text>
        <dxy-field-text
            name="code"
            labelKey="UI.TechnologyAdmin.codeLbl"
            [(ngModel)]="technologyCode"
            (ngModelChange)="onCodeChange()"
            [ngModelOptions]="ngModelOptions"
            [errorMessageKey]="codeErrorKey"
            required
        ></dxy-field-text>
        <dxy-field-text
            name="description"
            labelKey="UI.TechnologyAdmin.descriptionLbl"
            [(ngModel)]="technologyDescription"
        ></dxy-field-text>
        <dxy-field-multi-select
            name="modules"
            labelKey="UI.TechnologyAdmin.modulesLbl"
            [data]="modulesMultiSelect"
            [(ngModel)]="technologyModules"
            (ngModelChange)="onModuleChange($event)"
            [mandatory]="true"
            [errorMessageKey]="moduleErrorKey"
        ></dxy-field-multi-select>
        <div class="image-wrapper">
            <span class="image-label" translate
                >UI.TechnologyAdmin.iconLbl</span
            >
            <dxy-image-input [params]="imageParams"></dxy-image-input>
        </div>
    </div>
    <dxy-modal-footer
        [actionEnabled]="canCreate"
        [actionBtnLbl]="'UI.Global.btnCreate' | translate"
        [actionLoading]="isLoading"
        (onCloseCancel)="onCloseCancel()"
        [featureCode]="featureCode"
    ></dxy-modal-footer>
</form>
