import { Injectable } from '@angular/core';
import { RestApiService } from '@datagalaxy/data-access';
import { ObjectAccessType, SetObjectSecurityResult } from '../security';
import { DataGalaxyModule } from '@datagalaxy/shared/dg-module/domain';
import {
    GetUserModulesAccessQueryResult,
    GetUserModulesAccessResult,
    SetUserModuleSecurityCommand,
} from './module-security.queries';

@Injectable({ providedIn: 'root' })
export class ModuleSecurityApiService {
    constructor(private restApiService: RestApiService) {}

    public async setUserModuleSecurity(
        userGuid: string,
        spaceGuid: string,
        module: DataGalaxyModule,
        referenceId: string,
        accessType: ObjectAccessType,
    ) {
        const param = {
            ReferenceId: referenceId,
            AccessType: accessType,
        };
        return await this.restApiService.putPromise<
            SetObjectSecurityResult,
            SetUserModuleSecurityCommand
        >(
            `/security/workspaces/${spaceGuid}/user/${userGuid}/module/${module}`,
            param,
        );
    }

    public async getUserAccessibleModules(
        workspaceId: string,
        versionId: string,
        userGuid: string,
    ): Promise<GetUserModulesAccessQueryResult> {
        return await this.restApiService.getPromise<GetUserModulesAccessQueryResult>(
            `/security/workspaces/${workspaceId}/versionId/${versionId}/user/${userGuid}/modules`,
        );
    }

    public async getUserModulesSecurityData(
        spaceGuid: string,
        versionId: string,
        userGuid: string,
    ): Promise<GetUserModulesAccessResult> {
        return await this.restApiService.getPromise<GetUserModulesAccessResult>(
            `/security/workspaces/${spaceGuid}/versionId/${versionId}/user/${userGuid}/objects`,
        );
    }
}
