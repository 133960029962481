import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '@datagalaxy/core-ui';
import { DomainName } from './domain-name';
import { map } from 'rxjs';
import { IMsWellKnownOpenIdConfiguration } from './ms-well-known-open-id-configuration';
import { MsTeamsApiService } from '../../../notification-channels/api/ms-teams-api.service';
import { UserNotificationChannelsStateService } from '../../../notification-channels/state/user-notification-channels-state.service';
import { msTeamsNotificationChannel } from '../../../notification-channels/ms-teams-notification-channel';
import { ToasterService } from '../../../services/toaster.service';
import { CoreUtil } from '@datagalaxy/core-util';

@Injectable({ providedIn: 'root' })
export class MsTeamsIntegrationService extends BaseService {
    constructor(
        private httpClient: HttpClient,
        private msTeamsApiService: MsTeamsApiService,
        private userNotificationChannelsStateService: UserNotificationChannelsStateService,
        private toasterService: ToasterService,
    ) {
        super();
    }

    public getMsTeamsTenantId(domain: DomainName) {
        const msWellKnownOpenIdConfigurationUrl = `https://login.microsoftonline.com/${domain}/.well-known/openid-configuration`;
        return this.httpClient
            .get<IMsWellKnownOpenIdConfiguration>(
                msWellKnownOpenIdConfigurationUrl,
            )
            .pipe(
                map((response) => {
                    const tokenEndpoint = response?.token_endpoint;
                    if (!tokenEndpoint) {
                        throw new Error('Unable to retrieve tenant Id');
                    }
                    const endpointParts = tokenEndpoint.split('/');
                    return endpointParts[3];
                }),
            );
    }

    public async activate(tenantId: string) {
        await this.msTeamsApiService.activateTeamsIntegration(tenantId);
        this.userNotificationChannelsStateService.setClientIntegration(
            msTeamsNotificationChannel.name,
            true,
        );
    }

    public async deactivate() {
        try {
            await this.msTeamsApiService.deactivateTeamsIntegration();
            this.userNotificationChannelsStateService.setClientIntegration(
                msTeamsNotificationChannel.name,
                false,
            );
        } catch (error) {
            CoreUtil.warn(error);
            this.toasterService.errorToast({
                messageKey: 'UI.NotificationChannels.MsTeams.deactivationError',
            });
        }
    }
}
