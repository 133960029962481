import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { TranslateModule } from '@ngx-translate/core';
import { SpinnerComponent } from '@datagalaxy/ui/spinner';
import { NgIf } from '@angular/common';
import { ButtonVariant, DxyButtonDirective } from '@datagalaxy/ui/buttons';

/**
 * Togglable Show more/Show less button
 */
@Component({
    selector: 'dxy-show-more-button',
    templateUrl: './show-more-button.component.html',
    styleUrls: ['./show-more-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf, SpinnerComponent, TranslateModule, DxyButtonDirective],
})
export class DxyShowMoreButtonComponent extends DxyBaseComponent {
    /** If true, button text changes to "Show less"*/
    @Input() isLess = false;
    @Input() variant: ButtonVariant = 'tertiary';
    @Input() labelKey?: string;
    @Input() loading = false;
    @Input() disabled = false;

    protected get showMoreLabelKey() {
        return this.labelKey ?? 'CoreUI.Global.btnMore';
    }

    constructor() {
        super();
    }
}
