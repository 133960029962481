import {
    Component,
    ElementRef,
    HostListener,
    Input,
    OnInit,
    ViewChild,
} from '@angular/core';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { DomUtil, StringUtil } from '@datagalaxy/core-util';
import { DxyAttributeBaseInput } from '../../DxyAttributeBaseInput';
import { KeyboardUtil } from '@datagalaxy/utils';
import { DxyFieldTextComponent } from '@datagalaxy/ui/fields';
import { AttributeMetaType } from '@datagalaxy/webclient/attribute/domain';
import { ServerConstants } from '@datagalaxy/shared/server/domain';
import { DxyUnitaryFieldActionsComponent } from '../../../../fields/unitary/dxy-unitary-field-actions/dxy-unitary-field-actions.component';
import { NgIf } from '@angular/common';
import { FormsModule } from '@angular/forms';

/**
 * /!\ The use of textarea for attributeType Text is intentional
 * It allows to have a simple text displayed on multiple lines.
 *
 * Take notes that we are preventing new lines and that we send a submit event
 * on enter like an input would
 */
@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'dxy-attribute-text-input',
    templateUrl: './dxy-attribute-text-input.component.html',
    styleUrls: ['./dxy-attribute-text-input.component.scss'],
    standalone: true,
    imports: [
        DxyFieldTextComponent,
        FormsModule,
        NgIf,
        DxyUnitaryFieldActionsComponent,
        TranslateModule,
    ],
})
export class DxyAttributeTextInputComponent
    extends DxyAttributeBaseInput<string>
    implements OnInit
{
    @Input() public placeholderKey?: string;
    /**
     * If true, a submit event will be sent to the closest form on enter key
     * This way we can emulate the behavior of an input[type="text"] using textarea
     */
    @Input() public triggerSubmitOnEnter = true;

    @ViewChild('field') field: DxyFieldTextComponent;

    public get textMaxLength() {
        // NOTE: 524288 is the default html Limit, however, practically, 1024 seems to be a fair maximum...
        return this.attributeType == AttributeMetaType.Text
            ? ServerConstants.AttributeConstants.AttributeTextLimit
            : 1024;
    }
    //#region IAttributeBaseInputOverride
    public get isEditEnabled() {
        return (
            this.attributeType !== AttributeMetaType.EntityLogicalPathString &&
            super.isEditEnabled
        );
    }
    //#endregion

    private keyUpTimeout: any;
    constructor(
        elementRef: ElementRef<HTMLElement>,
        translate: TranslateService,
    ) {
        super(elementRef, translate);
    }

    ngOnInit() {
        super.ngOnInit();
    }

    //#region IAttributeBaseInputOverride
    public isValid(newValue: any, onSetData = true) {
        let errorMsgKey: string;
        // NOTE: On Set Data, it is possible to have an empty value even if field is mandatory (like DisplayName)
        if (
            !newValue &&
            this.isAttributeMandatory &&
            !this.isAttributeCDP &&
            !onSetData
        ) {
            errorMsgKey = 'UI.Attribute.msgRequired';
        } else if (
            this.attributeType == AttributeMetaType.Text &&
            StringUtil.containsNewLine(newValue)
        ) {
            errorMsgKey = 'UI.Attribute.carriageDisallowed';
        }
        if (errorMsgKey) {
            super.setInternalError(this.translate.instant(errorMsgKey));
            return false;
        }

        return true;
    }
    public focusField() {
        this.field.doFocus();
    }
    public blurField() {
        this.field.doBlur();
    }
    //#endregion

    @HostListener('keyup', ['$event'])
    public onKeyUp(event: KeyboardEvent) {
        clearTimeout(this.keyUpTimeout);
        if (KeyboardUtil.isEnterKey(event)) {
            this.checkMaximumLengthReached(event);
            this.onEnterKeyDown().then();
            return;
        }
        this.keyUpTimeout = setTimeout(() => {
            this.checkMaximumLengthReached(event);
        }, 333);
    }

    private checkMaximumLengthReached(_event: KeyboardEvent): boolean {
        if (this.hasInternalError) {
            return;
        }

        const input = this.getInput();
        if (!input) {
            return;
        }

        if (input.value.length >= this.textMaxLength) {
            super.setInternalError(
                this.translate.instant('UI.Input.maxDisplayNameLengthWarning'),
            );
        } else {
            super.clearInternalError();
        }
    }

    private getInput() {
        return DomUtil.getElement<HTMLTextAreaElement | HTMLInputElement>(
            this.elementRef,
            'textarea',
        );
    }
}
