import { Component, Input } from '@angular/core';
import {
    BaseCollectionCellComponent,
    ICollectionCellParams,
} from '@datagalaxy/core-ui/cell-components';
import {
    IAttributeCollectionCellParams,
    IAttributeCollectionCellParamsBuilder,
} from './attribute-collection-cell.types';
import { TagAttributeCollectionCellParamsBuilder } from './tag-attribute-collection-cell-params-builder';
import { UserAttributeCollectionCellParamsBuilder } from './user-attribute-collection-cell-params-builder';
import { EntityUiService } from '../../../entity/services/entity-ui.service';
import { UserService } from '../../../../services/user.service';
import { IconAttributeCollectionCellParamsBuilder } from './icon-attribute-collection-cell-params-builder';
import { AttributeDataService } from '../../../attribute/attribute-data.service';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';
import {
    AttributeMetaInfo,
    AttributeMetaValue,
    AttributeObjectValue,
} from '@datagalaxy/webclient/attribute/domain';
import { DxyCollectionCellComponent } from '@datagalaxy/core-ui/cell-components';
import { NgIf } from '@angular/common';

/**
 * ## Role
 * Display an attribute value collection
 */
@Component({
    selector: 'app-attribute-collection-cell',
    templateUrl: 'attribute-collection-cell.component.html',
    standalone: true,
    imports: [NgIf, DxyCollectionCellComponent],
})
export class AttributeCollectionCellComponent extends BaseCollectionCellComponent<
    EntityItem,
    AttributeObjectValue[],
    IAttributeCollectionCellParams
> {
    @Input() entity: EntityItem;
    @Input() attributeMeta: AttributeMetaInfo;
    @Input() values: (AttributeMetaValue | AttributeObjectValue)[];
    @Input() hideLabel = false;
    @Input() moreCountAsOption = false;
    @Input() maxLines = 1;
    @Input() hideEllipsedLabel = true;
    @Input() enablePopover: boolean;

    public collectionCellParams: ICollectionCellParams;

    constructor(
        private userService: UserService,
        private entityUiService: EntityUiService,
    ) {
        super();
    }

    protected override updateBindings() {
        super.updateBindings();
        this.attributeMeta = this.params.attributeMeta;
        this.values = this.value;
        this.entity = this.data;
        this.maxLines = this.params.maxLines ?? this.maxLines;
        this.enablePopover = this.params.enablePopover;
    }

    public override updateLayout() {
        super.updateLayout();
        const builder = this.getParamsBuilder();
        const params = builder.buildParams();
        if (!params?.inputs) {
            return;
        }
        params.inputs = {
            ...params.inputs,
            containerWidth: this.containerWidth,
            lineHeight: this.lineHeight ?? params.inputs?.lineHeight,
            hideLabel: this.hideLabel,
            hideEllipsedLabel: this.hideEllipsedLabel,
            moreCountAsOption: this.moreCountAsOption,
            maxLines: this.maxLines,
            uniqueTooltip: true,
            fitContentWidth: this.fitContentWidth,
        };
        this.collectionCellParams = params;
    }

    private getParamsBuilder(): IAttributeCollectionCellParamsBuilder {
        const attributeType =
            this.attributeMeta.SourceAttributeType ||
            this.attributeMeta.AttributeType;
        if (AttributeDataService.isUserOrPersonAttribute(attributeType)) {
            return new UserAttributeCollectionCellParamsBuilder(
                this.entity,
                this.values,
                this.userService,
                this.entityUiService,
                this.mini,
                this.enablePopover,
            );
        } else if (AttributeDataService.isTagAttribute(attributeType)) {
            return new TagAttributeCollectionCellParamsBuilder(
                this.values,
                this.mini,
            );
        } else {
            return new IconAttributeCollectionCellParamsBuilder(this.values);
        }
    }
}
