import {
    Component,
    ElementRef,
    HostListener,
    Input,
    OnChanges,
    SimpleChanges,
} from '@angular/core';
import {
    BaseCellComponent,
    ICellParams,
    IUserBadgeCellData,
} from '@datagalaxy/core-ui/cell-components';
import { EntityUiService } from '../../../entity/services/entity-ui.service';
import { UserService } from '../../../../services/user.service';
import { IHasHddData } from '@datagalaxy/dg-object-model';
import {
    EntityItem,
    IMiniEntityContent,
} from '@datagalaxy/webclient/entity/domain';
import { DxyUserBadgeCellComponent } from '@datagalaxy/core-ui/cell-components';

/**
 * ## Role
 * User badge with a popover on long mouse hover displaying
 * buttons to create tasks or comments and send emails to user
 */
@Component({
    selector: 'app-dg-user-badge-cell',
    templateUrl: 'dg-user-badge-cell.component.html',
    standalone: true,
    imports: [DxyUserBadgeCellComponent],
})
export class DgUserBadgeCellComponent
    extends BaseCellComponent<IHasHddData, string, IDgUserBadgeCellParams>
    implements OnChanges
{
    @Input() userId?: string;
    @Input() entityData?: IHasHddData;
    @Input() disablePopover?: boolean;
    public userData?: IUserBadgeCellData;

    private get el() {
        return this.elementRef.nativeElement;
    }

    private get entityContent(): IMiniEntityContent {
        if (this.entityData instanceof EntityItem) {
            return this.entityData;
        }
        const hdd = this.entityData.HddData?.Parents?.[0];
        if (!hdd) {
            return;
        }
        return {
            entityType: hdd.entityType,
            ReferenceId: hdd.ReferenceId,
            SecurityData: null,
            ServerType: hdd.ServerType,
            VersionId: hdd.VersionId,
        };
    }

    constructor(
        private userService: UserService,
        private entityUiService: EntityUiService,
        private elementRef: ElementRef<HTMLElement>,
    ) {
        super();
    }

    ngOnChanges(changes: SimpleChanges) {
        super.ngOnChanges(changes);
        super.onAnyChangesBut(
            changes,
            this,
            ['params'],
            () => this.updateLayout(),
            true,
        );
    }

    protected override updateBindings() {
        this.userId = this.value;
        this.entityData = this.params.data;
        this.disablePopover = this.params.disablePopover;
        this.disablePopover = this.params.disablePopover;
    }

    public override updateLayout() {
        if (!this.userId) {
            return;
        }
        this.userData = this.userService.getUserBadgeDataFromId(this.userId);
    }

    @HostListener('mouseover') private mouseOver() {
        if (this.disablePopover || !this.entityData) {
            return;
        }
        const user = this.userService.getPublicUserData(this.userId);
        this.entityUiService.openEntityUserListPopover(
            this.el,
            this.entityContent,
            user,
        );
    }
}

export interface IDgUserBadgeCellParams
    extends ICellParams<IHasHddData, string> {
    disablePopover?: boolean;
}
