<div *ngIf="!noHeader" class="title-header">
    <span class="title" [translate]="buttonTextKey"></span>
    <button dxyIconButton variant="tertiary" (click)="close.emit()">
        <i class="glyph-cancelsearch"></i>
    </button>
</div>
<div *ngIf="noHeader" class="side-close-button">
    <button dxyIconButton variant="tertiary" (click)="close.emit()">
        <i class="glyph-collapse"></i>
    </button>
</div>
<ng-content select="[side-panel-content]"></ng-content>
