import { BurgerMenuActionProviderBase, IActionDef } from '@datagalaxy/core-ui';
import { TeamService } from '../team.service';
import { TeamDto, TeamMemberDto } from '@datagalaxy/webclient/team/data-access';

export class TeamMembersBurgerMenuActionProvider extends BurgerMenuActionProviderBase<
    TeamMembersBurgerMenuAction,
    TeamMemberDto,
    void
> {
    private setOwnerActionDef: ITeamMembersActionDef = {
        actionId: TeamMembersBurgerMenuAction.setOwner,
        isAvailable: (teamMember) =>
            !teamMember.IsTeamOwner && this.hasEditRights,
        glyphClass: 'glyph-team-owner',
        functionalLog: 'DEFINE_TEAM_ADMIN,R',
        execute: (teamMember) => this.updateTeam(teamMember),
    };
    private async updateTeam(teamMember: TeamMemberDto) {
        if (
            this.teamData.TeamOwners.some(
                (owner) => owner.UserId == teamMember.UserId,
            )
        ) {
            return;
        }
        const newTeam = new TeamDto();
        newTeam.TeamUid = this.teamData.TeamUid;
        newTeam.TeamOwners = this.teamData.TeamOwners.slice();
        newTeam.TeamOwners.push(teamMember);
        await this.teamService.updateTeam(newTeam);
    }

    private deleteMemberActionDef: ITeamMembersActionDef = {
        actionId: TeamMembersBurgerMenuAction.deleteMember,
        isAvailable: this.hasEditRights,
        glyphClass: 'glyph-delete',
        execute: (teamMember) => this.openDeleteTeamMembersModal(teamMember),
    };
    private async openDeleteTeamMembersModal(teamMember: TeamMemberDto) {
        await this.teamService.openDeleteTeamMembersModal(this.teamData, [
            teamMember,
        ]);
    }

    constructor(
        private teamService: TeamService,
        public teamData: TeamDto,
        private hasEditRights: boolean,
    ) {
        super();
        this.actionDefs = [this.setOwnerActionDef, this.deleteMemberActionDef];
    }

    protected getTranslateKey(actionId: TeamMembersBurgerMenuAction) {
        if (actionId == TeamMembersBurgerMenuAction.deleteMember) {
            return 'UI.Global.btnDelete';
        } else {
            return 'UI.Teams.Members.setAdminBtn';
        }
    }
}

enum TeamMembersBurgerMenuAction {
    setOwner,
    deleteMember,
}

interface ITeamMembersActionDef
    extends IActionDef<TeamMembersBurgerMenuAction, TeamMemberDto, void> {}
