import { Injectable } from '@angular/core';
import { BackendApiService, BaseServiceResult } from '@datagalaxy/data-access';
import {
    GenerateGlossaryCommand,
    PreGenerateGlossaryQuery,
    PreGenerateGlossaryQueryResult,
} from './generation';

@Injectable({ providedIn: 'root' })
export class GlossaryApiService {
    constructor(private backendApiService: BackendApiService) {}

    public async generateGlossary(param: GenerateGlossaryCommand) {
        return await this.backendApiService.postPromise(
            'GlossaryGeneration/generate',
            param,
            BaseServiceResult,
        );
    }

    public async preGenerateGlossary(param: PreGenerateGlossaryQuery) {
        return await this.backendApiService.postPromise(
            'GlossaryGeneration/pregenerate',
            param,
            PreGenerateGlossaryQueryResult,
        );
    }
}
