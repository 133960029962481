import { AiGeneratedString } from '@datagalaxy/webclient/suggestion/domain';

export interface AutoDescriptionState {
    acceptedSuggestion: AiGeneratedString | undefined;
    pendingSuggestion: AiGeneratedString | undefined;
    generationError: unknown;
}

export const initialState: AutoDescriptionState = {
    acceptedSuggestion: undefined,
    pendingSuggestion: undefined,
    generationError: undefined,
};
