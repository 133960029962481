import { Dom2dUtil, Rect } from '@datagalaxy/core-2d-util';
import { CoreUtil, DomUtil } from '@datagalaxy/core-util';
import {
    BaseGraphicalManager,
    IGraphicalManagerOptions,
} from '../BaseGraphicalManager';
import { IDiagramMarkerSpec } from './marker.types';
import { ArrowBuilder } from './ArrowBuilder';
import { ShapeType } from './shape.types';

/* Notes
    For diagram arrows, we have the following constrains:
    - the tip of the arrow is visually the end of the connector
    - the reference point (aka where the connector's path joins the arrow marker) depends on the arrow's shape
    - the marker's *viewbox* must allow the entire shape to be visible when using large stroke width.
    - To properly draw the connector's path, we need to pass it the *recess* value,
      that is the distance between the tip and the ref point of the arrow on the x axis.

    Arrow path is defined with the arrow axis oriented left to right, so the tip is on the right.
*/

/** ## Role
 * Sub-component for managing svg shapes used as markers */
export class MarkerManager extends BaseGraphicalManager {
    //#region static
    private static readonly thicknesses = [1, 2, 4, 6];

    private static makeMarker(spec: IDiagramMarkerSpec) {
        const marker = Dom2dUtil.makeMarker(spec);
        if (spec.pathD) {
            const path = marker.appendChild(
                DomUtil.createSvgElement('path', spec.shapeClass),
            );
            path.setAttribute('d', spec.pathD);
        }
        if (spec.addDummies) {
            if (Array.isArray(spec.viewBox)) {
                const rect = marker.appendChild(
                    DomUtil.createSvgElement('rect', 'viewbox'),
                );
                const [x, y, w, h] = spec.viewBox;
                Dom2dUtil.setBounds(rect, new Rect(x, y, w, h), false);
            }
            if (
                (!spec.refX || typeof spec.refX == 'number') &&
                (!spec.refY || typeof spec.refY == 'number')
            ) {
                const cref = marker.appendChild(
                    DomUtil.createSvgElement('circle', 'ref'),
                );
                Dom2dUtil.setNumberAttr(cref, 'cx', (spec.refX as number) || 0);
                Dom2dUtil.setNumberAttr(cref, 'cy', (spec.refY as number) || 0);
                Dom2dUtil.setNumberAttr(cref, 'r', 0.5);
            }
            {
                const ctip = marker.appendChild(
                    DomUtil.createSvgElement('circle', 'tip'),
                );
                Dom2dUtil.setNumberAttr(
                    ctip,
                    'cx',
                    ((spec.refX as number) || 0) + spec.recess,
                );
                Dom2dUtil.setNumberAttr(ctip, 'cy', (spec.refY as number) || 0);
                Dom2dUtil.setNumberAttr(ctip, 'r', 0.5);
            }
        }
        return marker;
    }

    /** plain  arrow, as used in the lineage */
    private static readonly linkArrow: IDiagramMarkerSpec = {
        type: ShapeType.arrow,
        viewBox: '0 -10 20 20',
        refX: 15,
        refY: 0,
        markerWidth: 10,
        markerHeight: 10,
        pathD: 'M0,-10 L20,0 L0,10 z',
    };
    /** small plain circle, as used in the lineage */
    private static readonly linkDot = {
        type: ShapeType.circle,
        viewBox: '0 0 10 10',
        refX: 5,
        refY: 5,
        markerWidth: 5,
        markerHeight: 5,
        cx: 5,
        cy: 5,
        r: 5,
    };

    //#endregion

    private svgDefs: SVGDefsElement;
    private options: IMarkerManagerOptions;
    /** key is markerId */
    private readonly registry = new Map<string, IRegisteredMarker>();

    constructor() {
        super();
    }

    public init(defs: SVGDefsElement, options: IMarkerManagerOptions) {
        if (!defs) {
            CoreUtil.warn('no defs');
            return;
        }
        this.svgDefs = defs;
        this.options = options;
        this.log('init', options);
        super.initInternal(options);
        this.registerDefaults();
        return this;
    }

    public register(markerId: string, spec: IDiagramMarkerSpec) {
        const marker = MarkerManager.makeMarker(spec);
        this.registerMarker(markerId, spec, marker);
        return this;
    }

    public getSpec(markerId: string) {
        return markerId ? this.registry.get(markerId)?.spec : null;
    }
    public getMarkerIdsByType(shapeType?: ShapeType) {
        const markerIds: string[] = [];
        this.registry.forEach((data, markerId) => {
            if (!shapeType || data.spec.type == shapeType) {
                markerIds.push(markerId);
            }
        });
        return markerIds;
    }

    public getRecess(markerId: string) {
        return this.getSpec(markerId)?.recess ?? 0;
    }

    private registerMarker(
        markerId: string,
        spec: IDiagramMarkerSpec,
        marker: SVGMarkerElement,
    ) {
        if (!this.svgDefs) {
            return;
        }
        if (this.options.markerIdPrefix) {
            markerId = `${this.options.markerIdPrefix}${markerId}`;
        }
        this.svgDefs.querySelector(`#${markerId}`)?.remove();
        marker.id = markerId;
        this.registry.set(markerId, { marker, spec });
        this.svgDefs.append(marker);
    }

    private registerDefaults() {
        {
            //linkArrow
            const spec = MarkerManager.linkArrow;
            const marker = Dom2dUtil.makeMarker(spec);
            this.registerMarker('arrowLinkArrow', spec, marker);
        }

        ArrowBuilder.generateArrowMarkerSpecs(
            MarkerManager.thicknesses,
            undefined,
            this.options.addDummies,
        ).forEach((spec, markerId) => this.register(markerId, spec));

        this.log('registerDefaults', this.registry);
    }
}

interface IRegisteredMarker<
    TSpec extends IDiagramMarkerSpec = IDiagramMarkerSpec,
> {
    marker: SVGMarkerElement;
    spec: TSpec;
}

export interface IMarkerManagerOptions extends IGraphicalManagerOptions {
    markerIdPrefix?: string;
    addDummies?: boolean;
}
