import { Injectable } from '@angular/core';
import { BaseService } from '@datagalaxy/core-ui';
import {
    ILinkSuggestionWithHDD,
    ISuggestion,
    ISuggestionGroup,
} from '../suggestion.types';
import { CollectionsHelper } from '@datagalaxy/core-util';
import { ObjectLinkType } from '@datagalaxy/dg-object-model';
import { EntityCardCellComponent } from '../../shared/entityCard/entity-card/entity-card-cell.component';
import { SuggestionType } from '@datagalaxy/webclient/suggestion/types';

@Injectable({ providedIn: 'root' })
export class LinkSuggestionAdapterFactory extends BaseService {
    public getLinkSuggestionLists(
        suggestionLinks: ILinkSuggestionWithHDD[],
    ): ISuggestionGroup[] {
        const linkTypes = CollectionsHelper.distinct(
            suggestionLinks?.map((s) => s.linkSuggestion.LinkType),
        );

        return linkTypes?.map((linkType) => {
            const suggestions = suggestionLinks.filter(
                (s) => s.linkSuggestion.LinkType === linkType,
            );

            return {
                type: SuggestionType.Link,
                groupKey: `ObjectLinks_${ObjectLinkType[linkType]}`,
                labelKey: `DgServerTypes.ObjectLinkType.${ObjectLinkType[linkType]}`,
                canAcceptMultiValue: true,
                suggestions: suggestions.map((s) => this.getLinkSuggestion(s)),
            };
        });
    }

    public getLinkSuggestion(ls: ILinkSuggestionWithHDD): ISuggestion {
        const linkTypeStr = ObjectLinkType[ls.linkSuggestion.LinkType];
        return {
            id: ls.linkSuggestion.Guid,
            score: ls.linkSuggestion.Score,
            type: SuggestionType.Link,
            title: `DgServerTypes.ObjectLinkType.${linkTypeStr}`,
            data: ls,
            entity: ls.source,
            renderData: {
                renderer: EntityCardCellComponent,
                param: {
                    data: { HddData: ls.target },
                    inputs: {
                        isMiniCard: false,
                        hideBreadcrumb: false,
                        noLabelNavLink: true,
                    },
                },
            },
        };
    }
}
