import {
    ChangeDetectionStrategy,
    Component,
    input,
    OnChanges,
    output,
} from '@angular/core';
import { CoreUiModule, IActionOption } from '@datagalaxy/core-ui';
import { NgClass } from '@angular/common';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { WorkspaceIconComponent } from '../workspace-icon';
import { IWorkspace } from '../workspace.types';
import { WorkspaceCellComponent } from '../workspace-cell';
import { DxyDataTestIdDirective } from '@datagalaxy/ui/testing';

@Component({
    standalone: true,
    selector: 'dxy-workspace-menu-list',
    templateUrl: './workspace-menu-list.component.html',
    styleUrls: ['./workspace-menu-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        MatLegacyTooltipModule,
        NgClass,
        TranslateModule,
        CoreUiModule,
        WorkspaceIconComponent,
        WorkspaceCellComponent,
        DxyDataTestIdDirective,
    ],
})
export class WorkspaceMenuListComponent implements OnChanges {
    workspaces = input.required<IWorkspace[]>();
    selectedWorkspaceId = input<string | null>();
    showAllSpacesSection = input(false);
    readonlyFavorite = input(false);

    workspaceSelected = output<IWorkspace>();
    allSpacesSelected = output<void>();
    toggleFavorite = output<IWorkspace>();

    protected actions: IActionOption<IWorkspace>[] = [];

    ngOnChanges() {
        this.actions = this.getAvailableActions();
    }

    protected trackByWorkspaceId(_index: number, workspace: IWorkspace) {
        return workspace.id;
    }

    private getAvailableActions(): IActionOption<IWorkspace>[] {
        return [
            {
                tooltipTranslateKey: 'UI.Tooltip.DefaultSpace',
                glyphClass: 'glyph-star-fav',
                hidden: (workspace?: IWorkspace) =>
                    !workspace || !workspace.favorite,
                alwaysVisible: true,
                callback: this.readonlyFavorite()
                    ? undefined
                    : (workspace?: IWorkspace) =>
                          workspace && this.toggleFavorite.emit(workspace),
            },
            {
                tooltipTranslateKey: 'UI.Tooltip.DefaultSpace',
                glyphClass: 'glyph-star',
                hidden: (workspace?: IWorkspace) =>
                    !workspace || workspace.favorite || this.readonlyFavorite(),
                callback: (workspace?: IWorkspace) =>
                    workspace && this.toggleFavorite.emit(workspace),
            },
        ];
    }
}
