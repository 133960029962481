import {
    deserialize,
    deserializeAs,
    inheritSerialization,
    SerializableEnumeration,
    serialize,
    serializeAs,
} from 'cerialize';
import {
    BaseServiceParameter,
    BaseServiceResult,
} from '@datagalaxy/data-access';
import { PreCreateEntityStatus } from '@datagalaxy/webclient/attribute/data-access';
import {
    EntityType,
    EntityTypeUtil,
    ServerType,
} from '@datagalaxy/dg-object-model';
import { LinkEntityData, VersionedDataIdentifier } from './entity';
import { IUpdateLinksResult } from '../entity-link';
import { CollectionsHelper } from '@datagalaxy/core-util';
import { Filter } from '@datagalaxy/webclient/filter/domain';
import {
    EntityItem,
    EntityLinkItem,
    EntityTypeMeta,
    HddDataKind,
} from '@datagalaxy/webclient/entity/domain';
import { AttributeObjectValue } from '@datagalaxy/webclient/attribute/domain';
import { ServerConstants } from '@datagalaxy/shared/server/domain';

export enum CreateEntityOperation {
    Create,
    Validate,
    CopyFromExisting,
}
SerializableEnumeration(CreateEntityOperation);

export class AttributeValueInfo {
    @serialize public AttributePath?: string;
    @serialize public Value: any;
    @serialize public UpdateAttributeAction?: string;

    constructor(
        attributePath?: string,
        value?: any,
        updateAttributeAction?: string,
    ) {
        this.AttributePath = attributePath;
        this.Value = value;
        this.UpdateAttributeAction = updateAttributeAction;
    }
}

@inheritSerialization(AttributeValueInfo)
export class AttributeValueExtendedInfo extends AttributeValueInfo {
    @deserialize public ObjectValues!: AttributeObjectValue[];
}

@inheritSerialization(BaseServiceParameter)
export class CreateEntityParameter extends BaseServiceParameter {
    @serialize public ParentReferenceId: string;
    @serialize public DataTypeName: string;
    @serialize public SubTypeName?: string;
    @serialize public EntityType: string;
    @serialize public DisplayName: string | null;
    @serialize public TechnicalName?: string;
    @serialize public Description?: string;
    @serialize public CheckInParentSpace?: boolean;
    @serialize public IncludeMeta: boolean;
    @serializeAs(CreateEntityOperation)
    public Operation?: CreateEntityOperation;
    @serializeAs(VersionedDataIdentifier)
    public ExistingEntityIdentifier?: VersionedDataIdentifier;
    @serialize public ReferenceDataValueCode?: string;
    @serialize public AttributeValues?: AttributeValueInfo[];

    constructor(
        parentReferenceId: string,
        versionId: string,
        entityType: EntityType,
        displayName: string | null,
        description?: string,
        referenceDataValueCode?: string,
        technicalName?: string,
        attributeValues?: AttributeValueInfo[],
    ) {
        super();
        const serverType = EntityTypeUtil.getServerType(entityType);
        this.ParentReferenceId = parentReferenceId;
        this.VersionId = versionId;
        this.DataTypeName = (serverType && ServerType[serverType]) || '';
        this.EntityType = EntityType[entityType];
        this.DisplayName = displayName;
        this.TechnicalName = technicalName;
        this.Description = description;
        this.IncludeMeta = false;
        this.ReferenceDataValueCode = referenceDataValueCode;
        this.AttributeValues = attributeValues;
    }
}

@inheritSerialization(BaseServiceResult)
export class CreateEntityResult extends BaseServiceResult {
    @deserializeAs(EntityTypeMeta) public Meta!: EntityTypeMeta;
    @deserializeAs(EntityItem) public CreatedEntity!: EntityItem;
    @deserializeAs(CreateEntityOperation)
    public UsedOperation!: CreateEntityOperation;
    @deserializeAs(VersionedDataIdentifier)
    public ExistingEntityIdentifier!: VersionedDataIdentifier;
    @deserialize public IsErrorUnknownDataTypeName!: boolean;
    @deserialize public IsErrorMissingSubTypeName!: boolean;
    @deserialize public IsErrorUnkwownParentReferenceId!: boolean;
}

@inheritSerialization(BaseServiceResult)
export class PreCreateEntityResult extends BaseServiceResult {
    @deserializeAs(PreCreateEntityStatus) public Status!: PreCreateEntityStatus;
    @deserializeAs(EntityItem) public ExistingItem!: EntityItem;
    @deserialize public ReferenceDataValueCode!: string;
}

@inheritSerialization(BaseServiceParameter)
export class DeleteEntityParameter extends BaseServiceParameter {
    @serialize public DataReferenceIdList: string[];
    @serialize public DataTypeName: string;

    constructor(dataReferenceIds: string[], dataTypeName: string) {
        super();
        this.DataReferenceIdList = dataReferenceIds;
        this.DataTypeName = dataTypeName;
    }
}

@inheritSerialization(BaseServiceResult)
export class DeleteEntityResult extends BaseServiceResult {
    @deserialize public IsErrorUnknownDataReferenceId!: boolean;
    @deserialize public IsErrorUnknownDataTypeName!: boolean;
    @deserialize public TotalDeletedCount!: number;
}

@inheritSerialization(BaseServiceParameter)
export class CloneEntityParameter extends BaseServiceParameter {
    @serialize public DataReferenceId: string;
    @serialize public DataTypeName: string;
    @serialize public IncludeLinks: boolean;
    @serialize public IncludeChildren: boolean;
    @serialize public DisplayName?: string; // added in v3.5
    @serialize public TechnicalName?: string; // added in v3.5

    constructor(
        dataReferenceId: string,
        dataTypeName: string,
        includeLinks: boolean,
        includeChildren: boolean,
        displayName?: string,
        technicalName?: string,
    ) {
        super();
        this.DataReferenceId = dataReferenceId;
        this.DataTypeName = dataTypeName;
        this.IncludeLinks = includeLinks;
        this.IncludeChildren = includeChildren;
        this.DisplayName = displayName;
        this.TechnicalName = technicalName;
    }
}

@inheritSerialization(BaseServiceResult)
export class CloneEntityResult extends BaseServiceResult {
    @deserialize public IsErrorUnknownDataReferenceId!: boolean;
    @deserialize public IsErrorUnknownDataTypeName!: boolean;
    @deserializeAs(EntityTypeMeta) public Meta!: EntityTypeMeta;
    @deserializeAs(EntityItem) public ClonedEntity!: EntityItem;
}

@inheritSerialization(BaseServiceParameter)
export class FusionEntitiesParameter extends BaseServiceParameter {
    @serialize public DataTypeName: string;
    @serialize public TargetReferenceId: string;
    @serialize public SourceReferenceIds: string[];
    @serialize public IncludeDetails: boolean;
    @serialize public IncludeRelations: boolean;
    @serialize public IncludeImplementations: boolean;
    @serialize public IsVerificationCallOnly: boolean;

    constructor(
        dataTypeName: string,
        targetReferenceId: string,
        sourceReferenceIds: string[],
        includeDetails: boolean,
        includeRelations: boolean,
        includeImplementations: boolean,
        isVerificationCallOnly: boolean,
    ) {
        super();
        this.DataTypeName = dataTypeName;
        this.TargetReferenceId = targetReferenceId;
        this.SourceReferenceIds = sourceReferenceIds;
        this.IncludeDetails = includeDetails;
        this.IncludeRelations = includeRelations;
        this.IncludeImplementations = includeImplementations;
        this.IsVerificationCallOnly = isVerificationCallOnly;
    }
}

@inheritSerialization(BaseServiceResult)
export class FusionEntitiesResult extends BaseServiceResult {
    @deserialize public IsErrorSubTypeIncompatibility!: boolean;
    @deserialize public IsErrorTargetIncompatibility!: boolean;
    @deserialize public IsErrorTargetNotFound!: boolean;
    @deserialize public IsErrorSourceNotFound!: boolean;
    @deserialize public IsErrorHasExistingLogicalParentLinks!: boolean;
    @deserializeAs(EntityItem) public FusionedEntity!: EntityItem;
    @deserialize public DeletedEntityIds!: string[];
    @deserialize public DeletedLinks!: string[];
    @deserialize public IsVerificationOnlyCall!: boolean;
}

@inheritSerialization(BaseServiceParameter)
export class GetDefaultValuesOnCreateEntityParameter extends BaseServiceParameter {
    @serialize public ParentReferenceId?: string;
    @serialize public IncludedAttributes?: string[];
}

@inheritSerialization(BaseServiceResult)
export class GetDefaultValuesOnCreateEntityResult extends BaseServiceResult {
    @deserialize public AttributeValues!: AttributeValueExtendedInfo[];
}

@inheritSerialization(BaseServiceParameter)
export class LoadEntityParameter extends BaseServiceParameter {
    @serialize public DataReferenceId: string;
    @serialize public DataTypeName: string;
    @serialize public IncludeSecurity?: boolean;
    @serialize public IncludeMeta: boolean;
    @serialize public IncludeHdd?: boolean;
    @serialize public IncludeSocialData?: boolean;
    @serialize public IncludeLogicalHierarchyData?: boolean;
    @serialize public UsePrefixedAttributes: boolean;

    // IncludeObjectValues: Ability to receive all 'complex' data such as Persons/Users/Tags in JSON Object Form,
    // This data can then be used to avoid having Asynchronous calls for the Tags definitions/User profile image, etc.
    @serialize public IncludeObjectValues: boolean;
    @serialize public SkipObjectExistenceValidation: boolean;
    @serialize public IncludedAttributesFilter?: string[];

    constructor(
        dataReferenceId: string,
        serverType: ServerType,
        versionId: string,
        includeHddSecurityAndSocialData?: boolean,
        includedAttributesFilter?: string[],
    ) {
        super();
        this.DataReferenceId = dataReferenceId;
        this.DataTypeName = ServerType[serverType];
        this.VersionId = versionId;
        this.IncludeSecurity = includeHddSecurityAndSocialData;
        this.IncludeSocialData = includeHddSecurityAndSocialData;
        this.IncludeMeta = false;
        this.IncludeHdd = includeHddSecurityAndSocialData;
        this.IncludeObjectValues = true;
        this.IncludedAttributesFilter = includedAttributesFilter;
        this.UsePrefixedAttributes = false;
        this.SkipObjectExistenceValidation = true;
    }
}

@inheritSerialization(BaseServiceResult)
export class LoadEntityResult extends BaseServiceResult {
    @deserializeAs(EntityTypeMeta) public Meta!: EntityTypeMeta;
    @deserializeAs(EntityItem) public Entity!: EntityItem;
    @deserialize public IsErrorBadId!: boolean;
    @deserialize public IsErrorUnknownDataReferenceId!: boolean;
}

@inheritSerialization(BaseServiceParameter)
export class LoadMultiEntityParameter extends BaseServiceParameter {
    @serializeAs(HddDataKind) public HddDataKind: HddDataKind;
    @serialize public ParentReferenceId: string;
    @serialize public DataTypeNames: string[];
    @serialize public IncludeMeta: boolean;
    @serialize public StartIndex: number;
    @serialize public IncludeHdd: boolean;
    @serialize public SkipParentExistenceValidation: boolean;
    @serialize public IncludeNoAccessData: boolean;
    // IncludeObjectValues: Ability to receive all 'complex' data such as Persons/Users/Tags in JSON Object Form,
    // This data can then be used to avoid having Asynchronous calls for the Tags definitions/User profile image, etc.
    @serialize public IncludeObjectValues?: boolean;
    @serialize public Size: number;
    @serialize public MultiColumnsSearchString: string;
    @serialize public SortKey: string;
    @serialize public SearchColumns: string;
    @serialize public FilterKey?: string;
    @serialize public IncludedAttributesFilter: string[];
    @serializeAs(Filter) public Filters: Filter[];

    @serialize public IsLogicalHierarchyMode: boolean;
    @serialize public IncludeContextualData: boolean;
    @serialize public MaxHierarchyLevel?: number;
    @serialize public ExcludedIds?: string[] | null;
    @serialize public DataReferenceIdList: string[] | null;
    @serialize public IncludeOnlyHasWriteAccess?: boolean;
    @serialize public IncludeHData?: boolean;

    constructor(
        parentReferenceId: string,
        dataTypes: ServerType | ServerType[],
        includeHdd: boolean,
        startIndex: number,
        size: number,
        multiColumnsSearchString: string,
        searchColumns: string,
        sortKey: string,
        includedAttributesFilter: string[],
        filters: Filter[],
        hddDataKind = HddDataKind.Both,
        isLogicalHierarchyMode = false,
        maxHierarchyLevel?: number,
        excludedIds: string[] | null = null,
        dataReferenceIdList: string[] | null = null,
        includeOnlyHasWriteAccess?: boolean,
        includeHData = true,
        includeNoAccessData = false,
    ) {
        super();
        this.HddDataKind = hddDataKind;
        this.ParentReferenceId = parentReferenceId;
        this.DataTypeNames = (
            Array.isArray(dataTypes) ? dataTypes : [dataTypes]
        ).map((e) => ServerType[e]);
        this.IncludeHdd = includeHdd;
        this.IncludeMeta = false;
        this.StartIndex = startIndex;
        this.Size = size;
        this.MultiColumnsSearchString = multiColumnsSearchString;
        this.SearchColumns = searchColumns;
        this.SortKey = sortKey || ServerConstants.Search.ScoreSortKey;
        this.IncludedAttributesFilter = includedAttributesFilter;
        this.Filters = filters;
        this.SkipParentExistenceValidation = true;
        this.IsLogicalHierarchyMode = isLogicalHierarchyMode;
        this.IncludeContextualData = isLogicalHierarchyMode;
        this.MaxHierarchyLevel = maxHierarchyLevel;
        this.ExcludedIds = excludedIds;
        this.DataReferenceIdList = dataReferenceIdList;
        this.IncludeOnlyHasWriteAccess = includeOnlyHasWriteAccess;
        this.IncludeHData = includeHData;
        this.IncludeNoAccessData = includeNoAccessData;
    }
}

export interface ILoadMultiResult<TEntity> {
    IsSuccess: boolean;
    Entities: TEntity[];
    TotalCount: number;
    StartIndex: number;
    Size: number;
}

@inheritSerialization(BaseServiceResult)
export class LoadMultiEntityResult<TEntity extends EntityItem>
    extends BaseServiceResult
    implements ILoadMultiResult<EntityItem>
{
    @deserializeAs(EntityTypeMeta) public Meta!: EntityTypeMeta;
    @deserializeAs(EntityItem) public Entities!: TEntity[];
    @deserialize public IsErrorBadId!: boolean;
    @deserialize public StartIndex!: number;
    @deserialize public Size!: number;
    @deserialize public TotalCount!: number;
}

export enum UpdateAttributeAction {
    SetValue = 0,
    AddExistingData = 1,
    AddNewData = 2,
    DeleteData = 3,
    DeleteTimeSeriesAllValues = 4,
}
SerializableEnumeration(UpdateAttributeAction);

@inheritSerialization(BaseServiceParameter)
export class UpdateEntityAttributeParameter extends BaseServiceParameter {
    @serialize public DataReferenceIdList: string[];
    @serialize public DataTypeName: string;
    @serialize public IncludeAllAttributes: boolean;
    @serialize public IncludeSecurity = false;
    @serialize public IncludeMeta = false;
    @serialize public AttributeKey: string;
    @serialize public AttributeValue: any;
    @serialize public UpdateAttributeAction: string;
    @serialize public IncludeQualityData?: boolean;

    constructor(
        dataReferenceIds: string[],
        dataTypeName: string,
        includeAllAttributes: boolean,
        attributeKey: string,
        attributeValue: any,
        action: UpdateAttributeAction = UpdateAttributeAction.SetValue,
        includeQualityData?: boolean,
        includeSecurityData = false,
    ) {
        super();
        this.DataReferenceIdList = dataReferenceIds;
        this.IncludeAllAttributes = includeAllAttributes;
        this.DataTypeName = dataTypeName;
        this.AttributeKey = attributeKey;
        this.AttributeValue = attributeValue;
        this.UpdateAttributeAction = UpdateAttributeAction[action];
        this.IncludeQualityData = includeQualityData;
        this.IncludeSecurity = includeSecurityData;
    }
}

@inheritSerialization(BaseServiceParameter)
export class SetEntitiesTechnologyParameter extends BaseServiceParameter {
    @serialize public DataReferenceIdList: string[];
    @serialize public TechnologyCode: string;
    @serialize public IncludeQualityData: boolean;

    constructor(
        technology: string,
        dataReferenceIds: string[],
        includeQualityData: boolean,
    ) {
        super();
        this.DataReferenceIdList = dataReferenceIds;
        this.TechnologyCode = technology;
        this.IncludeQualityData = includeQualityData;
    }
}

@inheritSerialization(BaseServiceParameter)
export class SetEntitiesParentParameter extends BaseServiceParameter {
    @serialize public ParentReferenceId: string;
    @serialize public DataReferenceIdList: string[];
    @serialize public LinkEntityAttributes: string[];
    @serialize public LinkedEntityAttributes: string[];
    @serialize public UpdatedEntityIncludeObjectValues: boolean;

    constructor(
        parentReferenceId: string,
        dataReferenceIds: string[],
        linkEntityAttributes: string[],
        linkedEntityAttributes: string[],
    ) {
        super();
        this.ParentReferenceId = parentReferenceId;
        this.DataReferenceIdList = dataReferenceIds;
        this.LinkEntityAttributes = linkEntityAttributes;
        this.LinkedEntityAttributes = linkedEntityAttributes;
        this.UpdatedEntityIncludeObjectValues = true;
    }
}

@inheritSerialization(BaseServiceResult)
export class SetEntitiesParentResult
    extends BaseServiceResult
    implements IUpdateLinksResult
{
    @deserialize IsErrorMissingLinkEntityAttributes!: boolean;
    @deserialize IsErrorAlreadyExistingLink!: boolean;
    @deserialize IsErrorForbiddenLinkType!: boolean;
    @deserialize IsErrorCantCreateLinkToHimself!: boolean;
    @deserialize IsErrorWouldCreateCycle!: boolean;
    @deserialize IsReplacingExistingLogicalLinks!: boolean;
    @deserialize IsErrorWouldBreakUniquenessRule!: boolean;
    @deserializeAs(LinkEntityData) public Links!: LinkEntityData[];
    @deserializeAs(EntityItem) public UpdatedEntities!: EntityItem[];
    @deserializeAs(EntityLinkItem)
    public ReplacedLogicalLinks!: EntityLinkItem[];

    // Get entities which Parent changed
    public getMovedEntities(): EntityItem[] {
        const linkedEntities = CollectionsHelper.flattenGroups(
            this.Links,
            (link) => link.LinkedEntities,
        );
        //console.log('getMovedEntities', linkedEntities, this.UpdatedEntities)
        return linkedEntities?.length ? linkedEntities : this.UpdatedEntities;
    }
}

@inheritSerialization(BaseServiceParameter)
export class WatchEntityParameter extends BaseServiceParameter {
    @serialize EntityReferenceId: string;

    constructor(entityReferenceId: string) {
        super();
        this.EntityReferenceId = entityReferenceId;
    }
}

@inheritSerialization(BaseServiceResult)
export class UpdateEntityAttributeResult extends BaseServiceResult {
    @deserializeAs(EntityItem) public UpdatedEntities!: EntityItem[];
    @deserializeAs(EntityTypeMeta) public Meta!: EntityTypeMeta;
    @deserialize public IsErrorUnknownDataReferenceId!: boolean;
    @deserialize public IsErrorUnknownDataTypeName!: boolean;

    public get UpdatedEntity(): EntityItem {
        return this.UpdatedEntities && this.UpdatedEntities[0];
    }
}
