import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit,
} from '@angular/core';
import { DxyBaseFilterComponent } from '../base-filter/base-filter.component';
import { ITextFilterData, TextFilterOperator } from './text-filter.types';
import { CollectionsHelper } from '@datagalaxy/core-util';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { TextFilterUtils } from './text-filter.utils';
import { DxyAutoFocusInputDirective } from '../../directives/auto-focus-input.directive';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgIf } from '@angular/common';
import { DxyFilterButtonComponent } from '../filter-button/filter-button.component';

/**
 * ## Role
 * Display a text filter button
 */
@Component({
    selector: 'dxy-text-filter',
    templateUrl: './text-filter.component.html',
    styleUrls: ['./text-filter.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        DxyFilterButtonComponent,
        NgIf,
        TranslateModule,
        FormsModule,
        DxyAutoFocusInputDirective,
    ],
})
export class DxyTextFilterComponent
    extends DxyBaseFilterComponent<ITextFilterData, TextFilterOperator>
    implements OnInit
{
    public get isResolved() {
        return TextFilterUtils.isResolved(this.filter);
    }
    public get showTextarea() {
        return !TextFilterUtils.isValueLessOperator(this.operator);
    }
    public get isEmptyOperator() {
        return this.operator === TextFilterOperator.FieldIsEmpty;
    }
    public get showTextSeparator() {
        return !this.showTextarea;
    }
    public get showOperator() {
        return this.operator !== TextFilterOperator.TextContains;
    }
    public get operatorTranslateKey() {
        if (this.operator === TextFilterOperator.TextEquals) {
            return '=';
        }
        return this.getOperatorTranslateKey(this.operator);
    }

    private readonly textChanged = new Subject<string>();

    constructor(private cd: ChangeDetectorRef) {
        super();
    }

    ngOnInit() {
        this.init();

        super.registerSubscription(
            this.textChanged
                .pipe(debounceTime(444), distinctUntilChanged())
                .subscribe(() => {
                    this.onFilterChange();
                }),
        );
    }

    public onOperatorChange(operator: TextFilterOperator) {
        this.log('onOperatorChange', operator);
        this.filter.operator = operator;
        this.onFilterChange();
    }

    public onTextChange() {
        this.textChanged.next(this.filter.value);
    }

    public getOperatorTranslateKey(operator: TextFilterOperator) {
        return TextFilterUtils.getOperatorTranslateKey(operator);
    }

    private init() {
        if (!this.filter) {
            this.filter = {};
        }
        if (!this.operator) {
            this.operator = TextFilterOperator.TextContains;
        }
        this.operators ??= CollectionsHelper.getEnumValues<TextFilterOperator>(
            TextFilterOperator,
        ).filter(
            (o) =>
                !this.excludedOperators?.includes(o) &&
                o !== TextFilterOperator.Unknown,
        );
    }

    private onFilterChange() {
        this.cd.detectChanges();
        this.filterChange.emit(this.filter);
    }
}
