import { EntityItem } from '@datagalaxy/webclient/entity/domain';
import {
    CrudActionType,
    CrudOperation,
    FunctionalLogService,
} from '@datagalaxy/shared/monitoring/data-access';
import {
    EntityType,
    HierarchicalData,
    IEntityIdentifier,
    IHasHddData,
    ServerType,
} from '@datagalaxy/dg-object-model';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class EntityFunctionalLogService {
    public static getEntityActionFeatureCode(
        serverTypeName: string,
        subTypeName?: string,
    ) {
        let actionKey: string;
        if (subTypeName) {
            const prefix = EntityFunctionalLogService.getTypeNameCode(
                serverTypeName?.toUpperCase(),
                true,
            );
            const suffix = EntityFunctionalLogService.getSubTypeNameCode(
                subTypeName?.toUpperCase(),
            );
            actionKey = `${prefix}_${suffix}`;
        } else {
            actionKey = EntityFunctionalLogService.getTypeNameCode(
                serverTypeName.toUpperCase(),
                false,
            );
        }
        return actionKey;
    }

    public static getEntityActionFeatureCodeCrud(
        serverTypeName: string,
        subTypeName: string | undefined,
        crudOperation: CrudOperation,
    ) {
        const featureCode =
            EntityFunctionalLogService.getEntityActionFeatureCode(
                serverTypeName,
                subTypeName,
            );
        return `${featureCode},${CrudOperation[crudOperation]}`;
    }

    public static getHddDataActionFeatureCode(hdContainer: IHasHddData) {
        const hdd = hdContainer?.HddData?.Data;
        if (!hdd) {
            return;
        }
        return EntityFunctionalLogService.getEntityActionFeatureCode(
            hdd.DataTypeName,
            hdd.SubTypeName,
        );
    }

    public static getEntityIdrActionFeatureCodeCrud(
        entityIdr: IEntityIdentifier,
        crudOperation: CrudOperation,
    ) {
        return EntityFunctionalLogService.getEntityActionFeatureCodeCrud(
            ServerType[entityIdr.ServerType],
            entityIdr.entityType ? EntityType[entityIdr.entityType] : undefined,
            crudOperation,
        );
    }

    public static getEntityItemActionFeatureCode(entityData: EntityItem) {
        if (!entityData) {
            return;
        }
        return EntityFunctionalLogService.getEntityActionFeatureCode(
            entityData.DataTypeName,
            entityData.SubTypeName,
        );
    }

    public static getFeatureCode(
        entityData: {
            HddData?: HierarchicalData;
            ServerTypeName?: string;
            ServerType?: ServerType;
        },
        featureCode: string,
        codeCrud: string,
    ) {
        if (!entityData) {
            return null;
        }

        const hd = entityData.HddData;
        let dataTypeName = '';
        let subType = '';
        if (hd) {
            dataTypeName = hd.Data.DataTypeName;
            subType = hd.Data.SubTypeName;
        } else if (entityData.ServerType) {
            dataTypeName = ServerType[entityData.ServerType];
        } else if (entityData.ServerTypeName) {
            dataTypeName = entityData.ServerTypeName;
        }

        const entityFeatureCode =
            EntityFunctionalLogService.getEntityActionFeatureCode(
                dataTypeName,
                subType,
            );
        return `${entityFeatureCode}_${featureCode},${codeCrud}`;
    }

    public static getTypeNameCode(name: string, hasSubType: boolean) {
        switch (name) {
            case 'SOFTWAREELEMENT':
                return 'USAGE';
            case 'COLUMN':
                return hasSubType ? 'FIELD' : 'FIELD_COLUMN';
            case 'MODEL':
                return 'SOURCE';
            case 'TABLE':
                return hasSubType ? 'STRUCTURE' : 'STRUCTURE_TABLE';
            default:
                return name;
        }
    }

    private static getSubTypeNameCode(name: string) {
        switch (name) {
            case 'NONRELATIONAL':
                return 'FILESTORE';
            case 'RELATIONAL':
                return 'DATABASE';
            default:
                return name;
        }
    }

    constructor(private functionalLogService: FunctionalLogService) {}

    public logEntityItemAction(
        entity: EntityItem,
        crudOperation: CrudOperation,
        crudActionType?: CrudActionType,
        suffix?: string,
    ) {
        if (!entity) {
            return;
        }
        return this.logEntityAction(
            entity.TypeName,
            crudOperation,
            entity.SubTypeName,
            crudActionType,
            suffix,
        );
    }

    public logEntityAction(
        entityTypeName: string,
        crudOperation: CrudOperation,
        subtype?: string,
        crudActionType?: CrudActionType,
        suffix?: string,
    ) {
        const featureCode = `${EntityFunctionalLogService.getEntityActionFeatureCode(
            entityTypeName,
            subtype,
        )}${suffix ?? ''}`;

        this.functionalLogService.logFunctionalAction(
            featureCode,
            crudOperation,
            crudActionType,
        );
    }
}
