@if (workspace().iconUrl) {
    <img
        [src]="workspace().iconUrl"
        class="workspace-icon"
        alt="workspace-icon"
    />
} @else {
    <div class="workspace-icon dg5-palette bg" [ngClass]="trigramColorClass">
        {{ workspace().trigram }}
    </div>
}
