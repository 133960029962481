import {
    patchState,
    signalStoreFeature,
    type,
    withComputed,
    withMethods,
    withState,
} from '@ngrx/signals';
import { tapResponse } from '@ngrx/operators';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { AttributeFieldState } from '../../../common/attribute-field-state';
import { computed, inject } from '@angular/core';
import { AttributeValueTranslationService } from '@datagalaxy/webclient/multilingual/feature';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';
import { of, pipe, switchMap } from 'rxjs';
import {
    TranslationState,
    translationStateInitialValue,
} from './translation-state';
import { FeatureFlagService } from '@datagalaxy/webclient/feature-flag';

export function withTranslation() {
    return signalStoreFeature(
        { state: type<AttributeFieldState>() },
        withState<TranslationState>(translationStateInitialValue),
        withMethods(
            (
                store,
                attributeValueTranslationService = inject(
                    AttributeValueTranslationService,
                ),
                featureFlagService = inject(FeatureFlagService),
            ) => ({
                loadTranslations: rxMethod<void>(
                    pipe(
                        switchMap(() => {
                            if (
                                !featureFlagService.isFeatureEnabled(
                                    'multilingual',
                                ) ||
                                !attributeValueTranslationService.displayTranslatedValues(
                                    store.attributePath(),
                                )
                            ) {
                                return of();
                            }
                            return attributeValueTranslationService
                                .getAttributeValueTranslationResultFromEntityItem$(
                                    store.entity() as EntityItem,
                                    store.attributePath(),
                                )
                                .pipe(
                                    tapResponse({
                                        next: (result) => {
                                            patchState(store, {
                                                translationResult: result,
                                            });
                                        },
                                        error: (_error) => {},
                                    }),
                                );
                        }),
                    ),
                ),
            }),
        ),
        withComputed(({ translationResult }) => ({
            translation: computed(() => {
                return translationResult()?.translation;
            }),
            translatedText: computed(() => {
                return translationResult()?.value;
            }),
            aiOrigin: computed(() => {
                return translationResult()?.aiOrigin;
            }),
        })),
    );
}
