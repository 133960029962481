import { BaseGraphicalManager } from '../../BaseGraphicalManager';
import { D3Helper, SD3, TD3ZoomEvent } from '../../../D3Helper';
import { select } from 'd3-selection';
import {
    zoom as d3zoom,
    zoomIdentity,
    zoomTransform,
    ZoomTransform,
} from 'd3-zoom';
import { IZoomedViewContext } from './zoom-adapters/zoom-transform.types';
import { distinctUntilChanged, map, Subject } from 'rxjs';
import {
    IXYRect,
    IXYRO,
    MovePhase,
    Point,
    Rect,
} from '@datagalaxy/core-2d-util';
import { easeCubicInOut } from 'd3-ease';
import { ZoomedViewAdapter } from './zoom-adapters/ZoomedViewAdapter';
import { GraphManager } from '../../graph-manager';
import { clamp } from '@datagalaxy/core-util';
import {
    IPanZoom,
    IPanZoomEvent,
    IPanZoomOptions,
    IZoomBestFitOptions,
} from './pan-zoom.types';

/**
 * Graph Surface tool to handle zoom & pan gesture
 *
 * ## Features
 * - Pan
 *    - by drag
 *    - view centering
 * - Zoom
 *    - by mouse wheel & gesture
 *    - step zoom-in on double-click
 *    - smooth step +/-
 *    - smooth best-fit
 *    - min/max
 */
export class PanZoomTool extends BaseGraphicalManager implements IPanZoom {
    /** Coordinates converter to/from zoomed view */
    public adapter: ZoomedViewAdapter;

    private readonly zvc: IZoomedViewContext = {
        zt: zoomIdentity,
        offset: { x: 0, y: 0 },
    };
    private readonly zoomSubject = new Subject<IPanZoomEvent>();
    private readonly zoomBehaviour = d3zoom<HTMLElement, unknown>();
    /** zoom transform holder, and container of *zoomContent* */
    private zoomContainer: SD3<HTMLElement>;
    /** temporary var to store the current scaleTo animation target value  */
    private scaleToTransitionTarget: number;

    public get zoomed$() {
        return this.zoomSubject.asObservable();
    }
    public get scale$() {
        return this.zoomed$.pipe(
            map((e) => e.k),
            distinctUntilChanged(),
        );
    }
    public get position(): Point {
        return { x: this.zvc.zt.x, y: this.zvc.zt.y };
    }
    public get scale() {
        return this.zvc.zt.k;
    }

    private get disabled() {
        return this.options.disablePanAndZoom;
    }
    private get zoomSteps() {
        const defaultSteps = [7, 10, 15, 20, 33, 50, 75, 100, 125, 150, 200];
        return this.options?.zoomSteps || defaultSteps;
    }
    private get center() {
        const rect = this.zoomContainer.node().getBoundingClientRect();
        return { x: rect.width / 2, y: rect.height / 2 };
    }

    constructor(
        container: HTMLElement,
        private graph: GraphManager,
        private options?: IPanZoomOptions,
    ) {
        super();

        this.adapter = new ZoomedViewAdapter(this.zvc);

        const zoomRange: [number, number] = options.disablePanAndZoom
            ? [1, 1]
            : options.zoomRange;
        this.zoomBehaviour.scaleExtent(zoomRange ?? [0.07, 2]);
        this.setZoomContainer(container);
    }

    public updateOffset(offset: Point) {
        this.zvc.offset = offset;
    }

    public dispose() {
        super.dispose();
        this.zoomBehaviour.on('start zoom end', null);
    }

    public zoomToFit(
        opt: IZoomBestFitOptions = this.options.zoomBestFit,
    ): IPanZoomEvent {
        const rects = this.graph.items.map((node) => node.rect);
        if (rects?.length) {
            const bb = Rect.boundingBox(rects);
            return this.zoomToRect(bb, opt);
        } else {
            return this.centerView(undefined, 0, true, opt?.onEnd);
        }
    }

    /** Applies the needed zoom and pan so the provided area fits in the viewport */
    public zoomToRect(area: IXYRect, opt?: IZoomBestFitOptions): IPanZoomEvent {
        const margin = opt?.margin === undefined ? 20 : (opt?.margin ?? 0);
        const duration =
            opt?.durationMs === undefined ? 333 : (opt?.durationMs ?? 0);
        this.log('zoomBestFit', area, opt, margin, duration);

        // ensure we have latest dimensions
        const rect = this.zoomContainer.node().getBoundingClientRect();
        if (!rect) {
            return;
        }
        const w = rect.width;
        const h = rect.height;

        // extract values, not to mess received area object
        let { x: bx, y: by, width: bw, height: bh } = area;

        // we want the margin not to be scaled
        // so we augment the bounding box by pre-unscaled margin
        const f = Math.min(w / bw, h / bh);
        const apply = (n: number) => (n ?? 0) / f;
        let mt: number, mr: number, mb: number, ml: number;
        if (Array.isArray(margin)) {
            [mt, mr, mb, ml] = margin.map(apply);
        } else {
            mt = mr = mb = ml = apply(margin);
        }
        bx -= ml;
        bw += ml + mr;
        by -= mt;
        bh += mt + mb;

        // move the view so its center is the center of the bounding box
        // and scale it so the bounding box fits the viewport
        const x = (w - bw) / 2 - bx,
            y = (h - bh) / 2 - by,
            k = clamp(
                Math.min(w / bw, h / bh),
                opt?.zoomMin ?? 0,
                opt?.zoomMax,
            );

        return this.zoomTo(x, y, k, duration, opt?.onEnd);
    }

    public stepIn(): void {
        const zoomSteps = this.zoomSteps;
        const currentZoom = this.scaleToTransitionTarget || this.zvc.zt.k;
        const nextStep = zoomSteps.find((step) => step > currentZoom * 100);

        if (!nextStep) {
            return;
        }

        this.scaleToTransitionTarget = nextStep;
        this.scaleTo(nextStep / 100, 300);
    }

    public stepOut(): void {
        const zoomSteps = this.zoomSteps;
        const currentZoom = this.scaleToTransitionTarget || this.zvc.zt.k;
        const previousStep = zoomSteps
            .reverse()
            .find((step) => step < currentZoom * 100);

        if (!previousStep) {
            return;
        }

        this.scaleTo(previousStep / 100, 300);
    }

    /**
     * Translate the zoom surface by (dx, dy)
     * Be aware that it will apply the scale factor on those delta values
     */
    public translateBy(dx: number, dy: number, durationMs = 0) {
        this.zoomContainer
            .transition()
            .duration(durationMs)
            .call(this.zoomBehaviour.translateBy, dx, dy);
    }

    /** centers the view on the given point or the viewport center,
     * leaving the zoom factor unchanged or resetting it */
    public centerView(
        center: IXYRO = this.center,
        durationMs = 500,
        resetZoom?: boolean,
        onEnd?: () => void,
    ): IPanZoomEvent {
        const x = this.center.x - center.x;
        const y = this.center.y - center.y;
        const k = resetZoom ? 1 : this.zvc.zt.k;
        this.zoomTo(x, y, k, durationMs, onEnd);
        return { x, y, k, phase: MovePhase.start };
    }

    public extent(origin: Point, end: Point) {
        this.zoomBehaviour.extent([
            [origin.x, origin.y],
            [end.x, end.y],
        ]);
    }

    private scaleTo(dk: number, durationMs = 0) {
        this.scaleToTransitionTarget = dk;
        this.zoomContainer.interrupt('scaleTo');
        this.zoomContainer
            .transition('scaleTo')
            .duration(durationMs)
            .ease(easeCubicInOut)
            .call(this.zoomBehaviour.scaleTo, dk)
            .on('end', () => (this.scaleToTransitionTarget = null));
    }

    private zoomTo(
        x: number,
        y: number,
        k: number,
        durationMs = 500,
        onEnd?: () => void,
    ): IPanZoomEvent {
        const { x: cx, y: cy } = this.center;
        const zt0 = this.getCurrentZoomTransform();
        const zti = D3Helper.zoomTransformToIdentity(zt0, cx, cy);
        const zt = D3Helper.zoomTransformScaleBy(zti, k, cx, cy).translate(
            x,
            y,
        );
        this.updateZoomXYK(zt.x, zt.y, k, durationMs).on('end', () =>
            onEnd?.(),
        );
        return { x: zt.x, y: zt.y, k, phase: MovePhase.start };
    }

    private doMouseMove(ze: TD3ZoomEvent) {
        this.updateViewContextZt(ze.transform, 'onZoomInternal');
    }

    private doMouseUp(ze: TD3ZoomEvent) {
        this.updateViewContextZt(ze.transform, 'zoomEnd');
    }

    private onZoomStart(event: TD3ZoomEvent) {
        this.zoomSubject.next({ ...event.transform, phase: MovePhase.start });
    }

    private onZoom(event: TD3ZoomEvent) {
        const isManuallyTriggered = !event.sourceEvent;

        if (isManuallyTriggered) {
            this.doMouseMove(event);
        } else {
            switch (event.sourceEvent?.type) {
                case 'dblclick':
                case 'wheel':
                case 'mousemove': {
                    this.doMouseMove(event);
                    break;
                }
            }
        }
        this.zoomSubject.next({ ...event.transform, phase: MovePhase.move });
    }

    private onZoomEnd(event: TD3ZoomEvent) {
        switch (event.sourceEvent?.type) {
            case 'dblclick':
            case 'wheel':
            case 'mouseup': {
                this.doMouseUp(event);
                break;
            }
        }
        this.zoomSubject.next({ ...event.transform, phase: MovePhase.end });
    }

    private setZoomContainer(container: HTMLElement) {
        this.zoomContainer = select(container);
        this.zoomContainer.call(this.zoomBehaviour);

        /**
         * Ignores mousedown events on secondary buttons, since those buttons
         * are typically intended for other purposes, such as the context menu.
         * And ignore events when disabled
         */
        const filterEvents = (event) => !event.button && !this.disabled;

        this.zoomBehaviour
            .filter(filterEvents)
            .on('start', (event: TD3ZoomEvent) => this.onZoomStart(event))
            .on('zoom', (event: TD3ZoomEvent) => this.onZoom(event))
            .on('end', (event: TD3ZoomEvent) => this.onZoomEnd(event));
    }

    private updateViewContextZt(zt?: ZoomTransform, origin?: string): void {
        this.verbose && this.log('updateViewContextZt', origin, !!zt);
        this.zvc.zt = zoomTransform(this.zoomContainer.node());
    }

    private getCurrentZoomTransform() {
        return this.zoomContainer && zoomTransform(this.zoomContainer.node());
    }

    private updateZoomXYK(tx: number, ty: number, k: number, durationMs = 500) {
        const transform = zoomIdentity.translate(tx, ty).scale(k);

        return this.zoomContainer
            .transition()
            .duration(durationMs)
            .ease(easeCubicInOut)
            .call(this.zoomBehaviour.transform, transform);
    }
}
