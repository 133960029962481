import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
} from '@angular/core';
import { IProjectVersionSelectedEvent } from '../space.types';
import { ISpaceSelectorTrackerIds } from '../space-selector.types';
import { DxyVersioningSelectorComponent } from '../../../versioning/dxy-versioning-selector/dxy-versioning-selector.component';
import { TranslateModule } from '@ngx-translate/core';
import { AsyncPipe, NgIf } from '@angular/common';
import { WorkspaceStore } from '@datagalaxy/webclient/workspace/data-access';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { combineLatest, map, Observable, ReplaySubject } from 'rxjs';
import {
    WorkspaceDropdownComponent,
    WorkspaceDropdownConfig,
} from '@datagalaxy/webclient/workspace/feature';
import {
    IWorkspaceIdentifier,
    Workspace,
} from '@datagalaxy/webclient/workspace/domain';

@Component({
    selector: 'dxy-space-version-selector',
    templateUrl: 'dxy-space-version-selector.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgIf,
        TranslateModule,
        DxyVersioningSelectorComponent,
        WorkspaceDropdownComponent,
        AsyncPipe,
    ],
})
export class DxySpaceVersionSelectorComponent
    extends DxyBaseComponent
    implements OnInit, OnChanges
{
    @Input() spaceIdr: IWorkspaceIdentifier;
    @Input() readonly = false;
    @Input() hasLabel: boolean;
    @Input() enableFavorite: boolean;

    @Input() allowAll: boolean;
    @Input() projectsOnly: boolean;
    @Input() onlyImportable: boolean;
    @Input() onlyImportableCatalog: boolean;

    @Input() showAllSpaceRedirection: boolean;
    @Input() hideOfficialVersion: boolean;
    @Input() displaySelectedWorkspaceAsLink: boolean;

    @Input() trackerIds: ISpaceSelectorTrackerIds;

    @Output() readonly onSpaceVersionSelected =
        new EventEmitter<IWorkspaceIdentifier>();

    /** Emitted when a menu is opened or closed. The argument is true on open. */
    @Output() readonly onMenuOpenClose = new EventEmitter<boolean>();

    protected spaces$: Observable<Workspace[]>;
    protected workspaceSelectorConfig?: WorkspaceDropdownConfig;

    public get showVersionLabel() {
        return false;
    }
    public get showOfficialVersion() {
        return false;
    }
    public get showOfficialVersionLabel() {
        return false;
    }

    protected isVersioned$: Observable<boolean>;
    protected spaceIdSubject = new ReplaySubject<string>();

    constructor(private workspaceStore: WorkspaceStore) {
        super();
    }

    ngOnChanges() {
        this.spaceIdSubject.next(this.spaceIdr?.spaceId);
        this.workspaceSelectorConfig = {
            readonly: this.readonly,
            readonlyFavorite: !this.enableFavorite,
            showAllSpaceRedirection: this.showAllSpaceRedirection,
            displaySelectedWorkspaceAsLink: this.displaySelectedWorkspaceAsLink,
            trackerIds: {
                selectedTextTrackerId: this.trackerIds?.spaceSelectedText,
                selectedCaretTrackerId: this.trackerIds?.spaceDropdownCaret,
            },
        };
    }

    ngOnInit() {
        this.spaces$ = this.workspaceStore.selectSpaces({
            includeAllSpaces: this.allowAll,
            projectsOnly: this.projectsOnly,
            onlyImportable: this.onlyImportable,
            onlyImportableCatalog: this.onlyImportableCatalog,
        });

        this.isVersioned$ = combineLatest(
            this.spaces$,
            this.spaceIdSubject,
        ).pipe(
            map(([spaces, spaceId]) => {
                const space = spaces.find(
                    (space) => space.ReferenceId === spaceId,
                );

                if (space instanceof Workspace) {
                    return space.IsVersioningEnabled;
                }
                return false;
            }),
        );
    }

    public onSpaceSelected(navSpace: Workspace) {
        this.onSpaceVersionSelected.emit(navSpace);
    }

    public onVersionSelected(event: IProjectVersionSelectedEvent) {
        this.onSpaceVersionSelected.emit({
            spaceId: this.spaceIdr.spaceId,
            versionId: event.projectVersion.ProjectVersionId,
        });
    }

    public onMenuOpenCloseInternal(isOpen: boolean) {
        this.onMenuOpenClose.emit(isOpen);
    }
}
