import { BaseService } from '@datagalaxy/core-ui';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CoreUtil } from '@datagalaxy/core-util';
import { Constants } from '../../util/Constants';
import { IFreshDeskContact } from './fresh-desk.types';

@Injectable({ providedIn: 'root' })
export class FreshDeskApiService extends BaseService {
    private readonly apiKey: string = btoa('fL9pITNpqun3T1Qo5Chg');
    private readonly freshdeskTicketTypeKey = 'ticket_type';

    constructor(private httpClient: HttpClient) {
        super();
    }

    public async createTicket(data: FormData): Promise<any> {
        await CoreUtil.toPromise(
            this.httpClient.post(
                `${Constants.FreshdeskUrl.BaseApiUrl}/tickets`,
                data,
                {
                    headers: {
                        Authorization: `Basic ${this.apiKey}`,
                    },
                },
            ),
        );
    }

    public async fetchTicketTypes(): Promise<string[]> {
        const response = await CoreUtil.toPromise(
            this.httpClient.get<{ name: string; choices: string[] }[]>(
                `${Constants.FreshdeskUrl.BaseApiUrl}/ticket_fields?type=default_ticket_type`,
                {
                    headers: { Authorization: `Basic ${this.apiKey}` },
                    observe: 'response',
                },
            ),
        );
        const fields = response.body;
        const field = fields?.find(
            (f) => f.name == this.freshdeskTicketTypeKey,
        );
        const choices = field?.choices;
        if (!choices) {
            throw response.statusText;
        }
        return choices;
    }

    public async updateContactLang(
        email: string,
        lang: string,
    ): Promise<object> {
        const contact = await this.fetchContactInfos(email);
        if (!contact) {
            return;
        }
        await CoreUtil.toPromise(
            this.httpClient.put(
                `${Constants.FreshdeskUrl.BaseApiUrl}/contacts/${contact.id}`,
                { language: lang },
                {
                    headers: {
                        Authorization: `Basic ${this.apiKey}`,
                        'Content-Type': 'application/json',
                    },
                },
            ),
        );
    }

    private async fetchContactInfos(email: string) {
        const responseBody = await CoreUtil.toPromise(
            this.httpClient.get<IFreshDeskContact[]>(
                `${Constants.FreshdeskUrl.BaseApiUrl}/contacts?email=${email}`,
                { headers: { Authorization: `Basic ${this.apiKey}` } },
            ),
        );
        return responseBody[0];
    }
}
