import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { EntitySuggestionStoreService } from '../entity-suggestion-store.service';
import { ISuggestion, ISuggestionGroup } from '../suggestion.types';
import { map, Observable } from 'rxjs';
import { CollectionsHelper } from '@datagalaxy/core-util';
import { tap } from 'rxjs/operators';
import { SuggestionService } from '../suggestion.service';
import { ServerType } from '@datagalaxy/dg-object-model';
import { SuggestionType } from '@datagalaxy/webclient/suggestion/types';
import { FreshDeskService } from '../../shared/support/freshdesk/fresh-desk.service';
import { ArrayUtils } from '@datagalaxy/utils';
import { SuggestionCellComponent } from '../suggestion-cell/suggestion-cell.component';
import { SuggestionChoiceActionsComponent } from '../suggestion-choice-actions/suggestion-choice-actions.component';
import { DxyCollapsibleComponent } from '@datagalaxy/core-ui';
import { SpinnerComponent } from '@datagalaxy/ui/spinner';
import { TranslateModule } from '@ngx-translate/core';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';
import { DxyButtonDirective } from '@datagalaxy/ui/buttons';

/**
 * ## Role
 * collapsible sections containing suggestions for each attribute type
 *
 * ## Notes
 * Used only once in entity-panel-body
 */
@Component({
    selector: 'app-suggestion-container',
    templateUrl: 'suggestion-container.component.html',
    styleUrls: ['suggestion-container.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgIf,
        TranslateModule,
        SpinnerComponent,
        NgFor,
        DxyCollapsibleComponent,
        SuggestionChoiceActionsComponent,
        SuggestionCellComponent,
        AsyncPipe,
        DxyButtonDirective,
    ],
})
export class SuggestionContainerComponent {
    @Input() serverType: ServerType;
    @Input() showHeader: boolean;
    @Input() readOnly: boolean;

    protected suggestions$: Observable<ISuggestionSection[]>;
    protected loading$: Observable<boolean>;

    private previousSuggestions: ISuggestion[];

    constructor(
        private entitySuggestionStore: EntitySuggestionStoreService,
        private freshDeskService: FreshDeskService,
        private suggestionService: SuggestionService,
    ) {
        this.suggestions$ = this.entitySuggestionStore
            .selectSuggestionGroups()
            .pipe(
                tap((suggestions) => this.logViewedSuggestions(suggestions)),
                map((attributeSuggestions) =>
                    this.buildSections(attributeSuggestions),
                ),
            );
        this.loading$ = this.entitySuggestionStore.selectLoading();
    }

    public onClickDocButton() {
        this.freshDeskService.openMetabotArticle();
    }

    private buildSections(
        attributeSuggestionGroups: ISuggestionGroup[],
    ): ISuggestionSection[] {
        const typesOrder = [
            SuggestionType.Tag,
            SuggestionType.Dcp,
            SuggestionType.Link,
        ];
        const suggestionTypes = attributeSuggestionGroups
            ?.map((as) => as.type)
            .filter(CollectionsHelper.filterDistinct)
            .sort(ArrayUtils.sortByIndexOf(typesOrder));

        return suggestionTypes?.map((section) => ({
            displayName: `UI.SuggestionContainer.Sections.${SuggestionType[section]}`,
            suggestionGroup: attributeSuggestionGroups.filter(
                (as) => as.type === section,
            ),
        }));
    }

    private async logViewedSuggestions(suggestionGroups: ISuggestionGroup[]) {
        const suggestions: ISuggestion[] = suggestionGroups.flatMap(
            (sg) => sg.suggestions,
        );
        const previousIds = this.previousSuggestions?.map((s) => s.id);
        const diff = suggestions.filter((s) => !previousIds?.includes(s.id));
        await this.suggestionService.logViewedSuggestions(diff);
        this.previousSuggestions = suggestions;
    }
}

interface ISuggestionSection {
    displayName: string;
    suggestionGroup: ISuggestionGroup[];
}
