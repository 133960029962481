import { Directive, HostBinding, Input } from '@angular/core';
import { ButtonColor } from '../common/button-color';
import { ButtonSize } from '../common/button-size';
import { ButtonVariant } from '../common/button-variant';

/**
 * ## Role
 * Add DataGalaxy CSS classes to a button based on inputs values
 */
@Directive({
    selector: '[dxyButton]',
    standalone: true,
})
export class DxyButtonDirective {
    @Input() variant: ButtonVariant = 'primary';
    @Input() color: ButtonColor = 'default';
    @Input() size: ButtonSize = 'large';

    @HostBinding('class') get classes() {
        return `dxy-button dxy-button--${this.variant} dxy-button--${this.color} dxy-button--${this.size}`;
    }
}
