import {
    Inject,
    InjectionToken,
    ModuleWithProviders,
    NgModule,
} from '@angular/core';
import {
    DEFAULT_LANGUAGE,
    FakeMissingTranslationHandler,
    MissingTranslationHandler,
    TranslateCompiler,
    TranslateLoader,
    TranslateModule,
    TranslateParser,
    TranslateService,
    TranslateStore,
    USE_DEFAULT_LANG,
    USE_EXTEND,
    USE_STORE,
} from '@ngx-translate/core';
import { HttpBackend } from '@angular/common/http';
import { DxyTranslateMessageFormatCompiler } from './translate-message-format-compiler';
import { DxyTranslateParser } from './translate-parser';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import {
    defaultTranslationConfig,
    ITranslationConfig,
} from './translate.types';

export const DXY_TRANSLATE_CONFIG = new InjectionToken<ITranslationConfig>(
    'dxy-translate-config',
    { providedIn: 'root', factory: () => defaultTranslationConfig },
);

function translateHttpLoaderFactory(
    http: HttpBackend,
    config: ITranslationConfig,
) {
    return new MultiTranslateHttpLoader(http, config.resources);
}

export interface DxyTranslateModuleConfig {
    extend?: boolean;
}

@NgModule({
    imports: [TranslateModule],
    providers: [DxyTranslateMessageFormatCompiler, DxyTranslateParser],
    exports: [TranslateModule],
})
export class DxyTranslateModule {
    public static forRoot(
        config?: DxyTranslateModuleConfig,
    ): ModuleWithProviders<DxyTranslateModule> {
        return {
            ngModule: DxyTranslateModule,
            providers: [
                {
                    provide: TranslateLoader,
                    deps: [HttpBackend, DXY_TRANSLATE_CONFIG],
                    useFactory: translateHttpLoaderFactory,
                },
                {
                    provide: TranslateCompiler,
                    useClass: DxyTranslateMessageFormatCompiler,
                },
                { provide: TranslateParser, useClass: DxyTranslateParser },
                {
                    provide: MissingTranslationHandler,
                    useClass: FakeMissingTranslationHandler,
                },
                TranslateStore,
                { provide: USE_STORE, useValue: false },
                { provide: USE_DEFAULT_LANG, useValue: 'en' },
                { provide: USE_EXTEND, useValue: config?.extend },
                { provide: DEFAULT_LANGUAGE, useValue: 'en' },
                TranslateService,
            ],
        };
    }

    public static forChild(
        config?: DxyTranslateModuleConfig,
    ): ModuleWithProviders<DxyTranslateModule> {
        return {
            ngModule: DxyTranslateModule,
            providers: [{ provide: USE_EXTEND, useValue: config?.extend }],
        };
    }

    constructor(
        translate: TranslateService,
        @Inject(DXY_TRANSLATE_CONFIG) private config: ITranslationConfig,
    ) {
        this.configureTranslateService(translate, config);
    }

    private configureTranslateService(
        translate: TranslateService,
        config: ITranslationConfig,
    ) {
        /**
         * We don't want to override root conf when using forChild module
         */
        if (translate.currentLang) {
            return;
        }
        let preferredLang = config.preferedLang ?? translate.getBrowserLang();
        const langUnion = config.locales?.join('|');
        preferredLang = preferredLang.match(new RegExp(langUnion))
            ? preferredLang
            : 'en';

        translate.addLangs(config.locales);
        translate.setDefaultLang(config.defaultLang);
        translate.use(preferredLang);

        this.log('configureTranslateService done', config);
    }

    private log(...args: unknown[]) {
        this.config.doLog && console.log(...args);
    }
}
