<ng-template #templateRef>
    <div
        *ngIf="banner$ | async as banner"
        class="bottom-banner banner-slide"
        [ngClass]="banner.bannerClass || ''"
        [@animation]
    >
        <button
            dxyIconButton
            variant="tertiary"
            class="banner-dismiss-button"
            (click)="onDismissClick()"
        >
            <i class="glyph-cancelsearch"></i>
        </button>
        <div class="banner-row" [ngClass]="banner.rowClass || ''">
            <img
                *ngIf="banner.imageSrc"
                [src]="banner.imageSrc"
                class="banner-image"
                loading="lazy"
                sizes="(max-width: 479px) 50vw, 219.875px"
            />
            <div class="banner-col">
                <div class="banner-title" [translate]="banner.title"></div>
                <div class="banner-text" [translate]="banner.text"></div>
            </div>
        </div>
    </div>
</ng-template>
