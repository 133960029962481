import {
    ChangeDetectionStrategy,
    Component,
    inject,
    Input,
} from '@angular/core';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';
import { combineLatest, ReplaySubject, switchMap } from 'rxjs';
import { AttributeValueTranslationService } from '../../translations';
import { AsyncPipe, NgIf } from '@angular/common';
import { AttributeMetaInfo } from '@datagalaxy/webclient/attribute/domain';
import { EllipsisTooltipDirective, RichTextPipe } from '@datagalaxy/core-ui';

@Component({
    selector: 'dxy-translation-text-attribute-value',
    styleUrls: ['./translation-text-attribute-value.component.scss'],
    templateUrl: './translation-text-attribute-value.component.html',
    imports: [AsyncPipe, NgIf, RichTextPipe, EllipsisTooltipDirective],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TranslatedTextAttributeValueComponent {
    @Input() set entity(e: EntityItem) {
        this.entity$.next(e);
    }
    @Input() set attribute(a: AttributeMetaInfo) {
        this.attribute$.next(a);
    }

    private readonly entity$ = new ReplaySubject<EntityItem>();
    private readonly attribute$ = new ReplaySubject<AttributeMetaInfo>();
    private attributeValueTranslationService = inject(
        AttributeValueTranslationService,
    );

    protected translatedText$ = combineLatest([
        this.entity$,
        this.attribute$,
    ]).pipe(
        switchMap(([entity, attribute]) =>
            this.attributeValueTranslationService.getAttributeValueTranslationResultFromEntityItem$(
                entity,
                attribute?.AttributePath ?? '',
            ),
        ),
    );
}
