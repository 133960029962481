import {
    IGetAdaptedRectsOptions,
    ITransformAdapter,
    IZoomedViewContext,
} from './zoom-transform.types';
import {
    IdentityAdapter,
    OffsetAdapter,
    D3ZoomTransformAdapter,
    D3FixedZoomTransformAdapter,
} from './ZoomTransformAdapter';
import { IXYRect, IXYRectRO } from '@datagalaxy/core-2d-util';

/** ## Role
 * 2d-view coordinates converters
 * *zoomed* and *fixed* views are superimposed layers */
export class ZoomedViewAdapter {
    /** data -> zoomed view */
    public readonly zoom: ITransformAdapter;
    /** zoomed view -> data */
    public readonly unzoom: ITransformAdapter;
    /** fixed view -> data */
    public readonly unoffset: ITransformAdapter;
    /** pass-through */
    public readonly identity = new IdentityAdapter();
    /** fixed view -> zoomed view */
    public readonly fixedToZoomed: ITransformAdapter;
    /** zoomed view -> fixed view */
    public readonly zoomedToFixed: ITransformAdapter;
    /** data -> zoomed or fixed view */
    public readonly offset: ITransformAdapter;

    constructor(private readonly ctx: Readonly<IZoomedViewContext>) {
        this.offset = new OffsetAdapter(this.ctx);
        this.unoffset = new OffsetAdapter(this.ctx, true);
        this.zoom = new D3ZoomTransformAdapter(this.ctx);
        this.unzoom = new D3ZoomTransformAdapter(this.ctx, true);
        this.fixedToZoomed = new D3FixedZoomTransformAdapter(this.ctx);
        this.zoomedToFixed = new D3FixedZoomTransformAdapter(this.ctx, true);
    }

    public getRectConverter(opt?: IGetAdaptedRectsOptions): ITransformAdapter {
        return opt?.zoom
            ? this.zoom
            : opt?.unzoom
              ? this.unzoom
              : opt?.subtractSurfaceOffset
                ? this.unoffset
                : this.identity;
    }

    public transformRectInZoomedView<T extends IXYRect>(
        usePrePost: boolean,
        r: IXYRectRO,
        transform: (r: IXYRectRO, result?: T) => T,
        result?: T,
    ) {
        const pre = usePrePost ? this.fixedToZoomed : this.identity;
        const post = usePrePost ? this.zoomedToFixed : this.identity;
        pre.rectTo(r, result);
        transform(r, result);
        post.rectTo(r, result);
        return result;
    }
}
