<dxy-rich-text-field
    #field
    [ngModel]="store.value()"
    (ngModelChange)="onValueChange($event)"
    [readonly]="readonly() || store.readonlyAttribute()"
    [labelText]="store.labelText()"
    [labelTooltipText]="store.description()"
    (focus)="onFieldFocus()"
    (blur)="onFieldBlur($event)"
    toolbarDisplayMode="focus"
    [noTabCapture]="true"
>
    <div class="label-actions" label-actions>
        <dxy-translation-input-actions
            *dxyIfFeatureFlag="'multilingual'"
            [attributeName]="store.labelText()"
            [entityIdentifier]="entity()"
            [metalModelValue]="store.metaModelValue()"
            [translationResult]="store.translationResult()"
        />
    </div>
</dxy-rich-text-field>

@if (store.edited()) {
    <button
        type="button"
        class="validate-button"
        dxyIconButton
        size="small"
        [disabled]="store.isPending()"
    >
        <i class="glyph-check"></i>
    </button>
}
