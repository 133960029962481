import { inject, Injectable } from '@angular/core';
import { WorkspaceRouterService } from '@datagalaxy/webclient/workspace/feature';
import { WorkspaceIdentifier } from '@datagalaxy/webclient/workspace/utils';
import { ActivatedRoute, NavigationEnd, Params, Router } from '@angular/router';
import { DataUtil } from '../shared/util/DataUtil';
import { DgModule, DgModuleName } from '@datagalaxy/shared/dg-module/domain';
import { getLocalId } from '@datagalaxy/webclient/utils';
import {
    EntityType,
    EntityTypeUtil,
    IEntityIdentifier,
    IHierarchicalData,
    ServerType,
} from '@datagalaxy/dg-object-model';
import { HddUtil } from '../shared/util/HddUtil';
import { EntityIdentifier } from '@datagalaxy/webclient/entity/utils';
import {
    entityColumnsRoute,
    entityContainersRoute,
    entityDashboardComponentsRoute,
    entityDashboardDenodoSample,
    entityDashboardFieldsRoute,
    entityDashboardForeignKeysRoute,
    entityDashboardPrimaryKeysRoute,
    entityDataQualityRoute,
    entityDetailsRoute,
    entityDiagram,
    entityImplementationsRoute,
    entityLineageRoute,
    entityLinkedObjects,
    entityMappingRoute,
    entityModelSettings,
    entityTablesRoute,
} from './entity-dashboard.routes';
import { DiagramService } from '../diagrams/diagram/diagram.service';
import { ImpactAnalysisTool } from '../shared/util/app-types/impact-analysis.types';
import { ModuleService } from '../module/module.service';
import {
    EntityDashboardTab,
    EntityDashboardTabInfos,
} from './entity-dashboard.types';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';
import { ModelerDataUtil } from '../shared/util/ModelerDataUtil';
import { ServerConstants } from '@datagalaxy/shared/server/domain';
import { ViewTypeService } from '../services/viewType.service';
import { DataQualityService } from '../data-quality/data-quality.service';
import { UserService } from '../services/user.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { filter } from 'rxjs/operators';
import PropertyName = ServerConstants.PropertyName;

export interface EntityDashboardRouteOptions {
    subPath?: string | unknown[];
    queryParams?: Params;
    preserveModuleParams?: boolean;
    fullPage?: boolean;
}

function throwMissingTab(p: never): never {
    throw new Error('Missing EntityDashboardTab switch case for: ' + p);
}

@Injectable({
    providedIn: 'root',
})
export class EntityDashboardService {
    private workspaceRouter = inject(WorkspaceRouterService);
    private diagramService = inject(DiagramService);
    private moduleService = inject(ModuleService);
    private viewTypeService = inject(ViewTypeService);
    private userService = inject(UserService);
    private dataQualityService = inject(DataQualityService);
    private router = inject(Router);
    private route = inject(ActivatedRoute);

    private currentTab: EntityDashboardTab;

    constructor() {
        this.router.events
            .pipe(
                takeUntilDestroyed(),
                filter((event) => event instanceof NavigationEnd),
            )
            .subscribe(() => {
                const route = this.getDeepestChild(this.route);
                this.currentTab = route.snapshot.data.entityDashboardTab;
            });
    }

    public getCurrentTab() {
        return this.currentTab;
    }

    public getEntityDashboardUrlCommands(
        entity: IEntityIdentifier,
        options?: EntityDashboardRouteOptions,
    ) {
        const workspaceIdentifier = WorkspaceIdentifier.fromEntity(entity);
        const dgModule = DataUtil.getModuleFromEntityType(entity.entityType);
        const url = this.router.url;
        const viewMode = options?.preserveModuleParams
            ? url.includes('/flat')
                ? 'flat'
                : 'tree'
            : 'tree';

        const modulePath = this.moduleService.getModuleUrlPath(
            DgModule[dgModule] as DgModuleName,
            workspaceIdentifier,
            {
                fullPage: options?.fullPage,
                viewMode,
            },
        );
        const tab = this.getTab(this.getCurrentTab());
        const remainingPath = options?.subPath
            ? Array.isArray(options.subPath)
                ? options.subPath
                : [options?.subPath]
            : tab
              ? [tab.stateName]
              : [];

        return [
            ...modulePath,
            getLocalId(entity.ReferenceId),
            ...remainingPath,
        ];
    }

    public goToEntityDashboard(
        entity: IEntityIdentifier,
        options?: EntityDashboardRouteOptions,
    ) {
        return this.router.navigate(
            this.getEntityDashboardUrlCommands(entity, options),
            {
                queryParams: options?.queryParams,
            },
        );
    }

    /**
     * Redirect on the entityDashboard if the entity is compatible with it
     * otherwise redirect to the appropriate page
     */
    public goToEntity(hdd: IHierarchicalData) {
        const url = this.getEntityUrl(hdd);

        if (url) {
            return this.router.navigateByUrl(url);
        }

        switch (hdd.ServerType) {
            case ServerType.FilteredView: {
                // Filtered views are not anymore returned from search
                return;
            }
            case ServerType.ObjectTask:
            case ServerType.ObjectCommentary: {
                // Comment are not anymore redirected directly
                // Tasks opens in entity preview
                return;
            }
        }
    }

    public goToEntityLineage(entityIdentifier: IEntityIdentifier) {
        return this.goToEntityDashboard(entityIdentifier, {
            subPath: entityLineageRoute.path,
            queryParams: {
                tool: ImpactAnalysisTool.None,
            },
        });
    }

    public goToEntityExplorer(entityIdentifier: IEntityIdentifier) {
        return this.goToEntityDashboard(entityIdentifier, {
            subPath: entityLineageRoute.path,
            queryParams: {
                tool: ImpactAnalysisTool.Explorer,
            },
        });
    }

    public goToEntityImpactAnalysis(
        entityIdentifier: IEntityIdentifier,
        tool: ImpactAnalysisTool,
    ) {
        return this.goToEntityDashboard(entityIdentifier, {
            subPath: entityLineageRoute.path,
            queryParams: {
                tool,
            },
        });
    }

    public getEntityUrl(hdd: IHierarchicalData): string {
        const urlTree = this.getEntityUrlCommands(hdd);
        return this.router.createUrlTree(urlTree).toString();
    }

    private getEntityUrlCommands(hdd: IHierarchicalData): unknown[] {
        const spaceIdr = new WorkspaceIdentifier(
            hdd.DataReferenceId,
            hdd.VersionId,
        );
        const entityIdentifier = EntityIdentifier.fromIHierarchicalData(hdd);

        switch (hdd.ServerType) {
            case ServerType.Project:
                return this.workspaceRouter.getWorkspaceUrlCommands(spaceIdr);
            case ServerType.FilteredView:
                // No URL;
                return;
            case ServerType.DataProcessingItem: {
                const dataProcessingDataDescriptor =
                    HddUtil.getParentDataDescriptorByType(hdd, [
                        ServerType.DataProcessing,
                    ]);
                if (!dataProcessingDataDescriptor) break;

                return this.getEntityDashboardUrlCommands(
                    EntityIdentifier.fromHdd(dataProcessingDataDescriptor),
                    {
                        subPath: entityMappingRoute.path,
                    },
                );
            }
            case ServerType.PrimaryKey: {
                const modelDataDescriptor =
                    HddUtil.getParentDataDescriptorByType(hdd, [
                        ServerType.Model,
                    ]);

                return this.getEntityDashboardUrlCommands(
                    EntityIdentifier.fromHdd(modelDataDescriptor),
                    {
                        subPath: entityDashboardPrimaryKeysRoute.path,
                    },
                );
            }
            case ServerType.ForeignKey: {
                const modelDataDescriptor =
                    HddUtil.getParentDataDescriptorByType(hdd, [
                        ServerType.Model,
                    ]);

                return this.getEntityDashboardUrlCommands(
                    EntityIdentifier.fromHdd(modelDataDescriptor),
                    {
                        subPath: entityDashboardForeignKeysRoute.path,
                    },
                );
            }
            case ServerType.DataType:
            case ServerType.DataTypeMappingItem: {
                const modelDataDescriptor =
                    HddUtil.getParentDataDescriptorByType(hdd, [
                        ServerType.Model,
                    ]);

                return this.getEntityDashboardUrlCommands(
                    EntityIdentifier.fromHdd(modelDataDescriptor),
                    {
                        subPath: entityModelSettings.path,
                    },
                );
            }
            case ServerType.ObjectTask:
            case ServerType.ObjectCommentary: {
                // No url
                return;
            }
            case ServerType.LocalSynonym: {
                const dataDescriptor = HddUtil.getParentDataDescriptorByType(
                    hdd,
                    [ServerType.Property],
                );
                if (!dataDescriptor) {
                    break;
                }
                return this.getEntityDashboardUrlCommands(
                    EntityIdentifier.fromHdd(dataDescriptor),
                );
            }
            case ServerType.ModelDiagram:
            case ServerType.Diagram: {
                return this.diagramService.getDiagramUrlCommands(
                    entityIdentifier,
                );
            }
            case ServerType.DiagramNode: {
                const diagramHdd = HddUtil.getFirstHddByType(
                    hdd,
                    ServerType.Diagram,
                );
                return this.diagramService.getDiagramUrlCommands(
                    EntityIdentifier.fromHdd(diagramHdd),
                );
            }
            default: {
                return this.getEntityDashboardUrlCommands(entityIdentifier);
            }
        }
    }

    public getTab(tab?: EntityDashboardTab): EntityDashboardTabInfos | null {
        const isTechnicalView = this.viewTypeService.isTechnicalView;

        const getTranslateKey = (entity: EntityItem) => {
            const dgModule = DataUtil.getModuleFromEntityType(
                entity.entityType,
            );
            const tradKeyPrefix =
                DataUtil.getModuleTranslateKeyPrefix(dgModule);

            return `${tradKeyPrefix}tabView.${EntityDashboardTab[tab]}`;
        };

        if (!tab) {
            return null;
        }

        switch (tab) {
            case EntityDashboardTab.details:
                return {
                    id: EntityDashboardTab.details,
                    stateName: entityDetailsRoute.path,
                    getHidden: (entity: EntityItem) =>
                        !this.hasDetailsAccess(entity.entityType),
                    getTranslateKey,
                };
            case EntityDashboardTab.implementation:
                return {
                    id: EntityDashboardTab.implementation,
                    stateName: entityImplementationsRoute.path,
                    getHidden: (entity: EntityItem) =>
                        !this.hasImplementationAccess(entity),
                    getCount: (entity: EntityItem) =>
                        entity.getAttributeValue<number>(
                            PropertyName.ImplementationLinkCount,
                        ),
                    getTranslateKey,
                };
            case EntityDashboardTab.linkedObjects:
                return {
                    id: EntityDashboardTab.linkedObjects,
                    stateName: entityLinkedObjects.path,
                    getHidden: (entity: EntityItem) =>
                        !this.hasObjectLinksAccess(entity.entityType),
                    getCount: (entity: EntityItem) =>
                        entity.getAttributeValue<number>(
                            PropertyName.EntityLinkCount,
                        ),
                    getTranslateKey,
                };
            case EntityDashboardTab.mapping:
                return {
                    id: EntityDashboardTab.mapping,
                    getHidden: (entity: EntityItem) =>
                        !this.hasMappingAccess(entity.entityType),
                    stateName: entityMappingRoute.path,
                    getTranslateKey,
                };
            case EntityDashboardTab.diagram:
                return {
                    id: EntityDashboardTab.diagram,
                    stateName: entityDiagram.path,
                    getHidden: (entity: EntityItem) =>
                        !this.hasDiagramAccess(entity),
                    getCount: (entity: EntityItem) =>
                        entity.getAttributeValue<number>(
                            PropertyName.EntityDiagramCount,
                        ),
                    getTranslateKey,
                };
            case EntityDashboardTab.impactAnalysis:
                return {
                    id: EntityDashboardTab.impactAnalysis,
                    stateName: entityLineageRoute.path,
                    getTranslateKey,
                };
            case EntityDashboardTab.components:
                return {
                    id: EntityDashboardTab.components,
                    stateName: entityDashboardComponentsRoute.path,
                    getHidden: (entity: EntityItem) =>
                        !this.hasComponentsAccess(entity.entityType),
                    getTranslateKey,
                };
            case EntityDashboardTab.fields:
                return {
                    id: EntityDashboardTab.fields,
                    stateName: entityDashboardFieldsRoute.path,
                    getHidden: (entity: EntityItem) =>
                        !this.hasFieldsAccess(entity.entityType),
                    getTranslateKey,
                };
            case EntityDashboardTab.dataQuality:
                return {
                    id: EntityDashboardTab.dataQuality,
                    stateName: entityDataQualityRoute.path,
                    getHidden: (entity: EntityItem) =>
                        !this.hasDataQualityAccess(entity),
                    getTranslateKey,
                };
            case EntityDashboardTab.settings:
                return {
                    id: EntityDashboardTab.settings,
                    stateName: entityModelSettings.path,
                    getHidden: (entity: EntityItem) =>
                        !this.hasSettingsAccess(entity),
                    getTranslateKey,
                };
            case EntityDashboardTab.columns:
                return {
                    id: EntityDashboardTab.columns,
                    stateName: entityColumnsRoute.path,
                    getHidden: (entity: EntityItem) =>
                        !this.hasColumnsAccess(entity),
                    getCount: (entity: EntityItem) =>
                        entity.getAttributeValue<number>(
                            PropertyName.TableColumnCount,
                        ),
                    getTranslateKey: (entity) =>
                        !this.isModelRelational(entity)
                            ? 'UI.Modeler.Navigator.fieldListTab'
                            : isTechnicalView
                              ? 'UI.Modeler.Navigator.columnListTabTechnical'
                              : 'UI.Modeler.Navigator.columnListTabFunctional',
                };
            case EntityDashboardTab.containers:
                return {
                    id: EntityDashboardTab.containers,
                    stateName: entityContainersRoute.path,
                    getHidden: (entity: EntityItem) =>
                        !this.hasContainersAccess(entity),
                    getCount: (entity: EntityItem) =>
                        entity.getAttributeValue<number>(
                            PropertyName.ContainerContainerCount,
                        ),
                    getTranslateKey: (entity) =>
                        this.isModelRelational(entity)
                            ? 'UI.Modeler.Navigator.modelListTab'
                            : 'UI.Modeler.Navigator.containerListTab',
                };
            case EntityDashboardTab.tables:
                return {
                    id: EntityDashboardTab.tables,
                    stateName: entityTablesRoute.path,
                    getHidden: (entity: EntityItem) =>
                        !this.hasTablesAccess(entity),
                    getCount: (entity: EntityItem) =>
                        entity.getAttributeValue<number>(
                            PropertyName.ContainerTableCount,
                        ),
                    getTranslateKey: (entity) =>
                        !this.isModelRelational(entity)
                            ? 'UI.Modeler.Navigator.structureListTab'
                            : isTechnicalView
                              ? 'UI.Modeler.Navigator.tableListTabTechnical'
                              : 'UI.Modeler.Navigator.tableListTabFunctional',
                };
            case EntityDashboardTab.pks:
                return {
                    id: EntityDashboardTab.pks,
                    stateName: entityDashboardPrimaryKeysRoute.path,
                    getHidden: (entity: EntityItem) =>
                        !this.hasPrimaryKeysAccess(entity),
                    getCount: (entity: EntityItem) =>
                        entity.getAttributeValue<number>(
                            PropertyName.ModelPrimaryKeyCount,
                        ),
                    getTranslateKey: () =>
                        isTechnicalView
                            ? 'UI.Modeler.tabView.primaryKeyList'
                            : 'UI.Modeler.tabView.identifierList',
                };
            case EntityDashboardTab.fks:
                return {
                    id: EntityDashboardTab.fks,
                    stateName: entityDashboardForeignKeysRoute.path,
                    getHidden: (entity: EntityItem) =>
                        !this.hasForeignKeysAccess(entity),
                    getCount: (entity: EntityItem) =>
                        entity.getAttributeValue<number>(
                            PropertyName.ModelForeignKeyCount,
                        ),
                    getTranslateKey: () =>
                        isTechnicalView
                            ? 'UI.Modeler.tabView.foreignKeyList'
                            : 'UI.Modeler.tabView.referenceList',
                };
            case EntityDashboardTab.sample:
                return {
                    id: EntityDashboardTab.sample,
                    getHidden: (entity: EntityItem) =>
                        !this.hasSampleAccess(entity),
                    stateName: entityDashboardDenodoSample.path,
                    getTranslateKey: () => 'UI.Modeler.tabView.denodoSampleTab',
                };
            default:
                return throwMissingTab(tab);
        }
    }

    public getEntityTabs(entity: EntityItem): EntityDashboardTabInfos[] {
        const tabs = this.getModuleTabs(entity);

        return tabs
            .map((tab) => this.getTab(tab))
            .filter((tab) => !tab.getHidden?.(entity));
    }

    public hasDashboardTab(entity: EntityItem, tab: EntityDashboardTab) {
        const tabs = this.getModuleTabs(entity);
        const res = tabs.map((tab) => this.getTab(tab));
        const found = res.find((t) => t.id === tab);

        return found && !found.getHidden?.(entity);
    }

    private getModuleTabs(entity: IEntityIdentifier) {
        const dgModule = DataUtil.getModuleFromEntityType(entity.entityType);

        switch (dgModule) {
            case DgModule.Glossary:
                return [
                    EntityDashboardTab.details,
                    EntityDashboardTab.linkedObjects,
                    EntityDashboardTab.implementation,
                    EntityDashboardTab.diagram,
                    EntityDashboardTab.impactAnalysis,
                ];

            case DgModule.Processing:
                return [
                    EntityDashboardTab.details,
                    EntityDashboardTab.linkedObjects,
                    EntityDashboardTab.mapping,
                    EntityDashboardTab.diagram,
                    EntityDashboardTab.impactAnalysis,
                ];

            case DgModule.Usage:
                return [
                    EntityDashboardTab.details,
                    EntityDashboardTab.components,
                    EntityDashboardTab.fields,
                    EntityDashboardTab.linkedObjects,
                    EntityDashboardTab.diagram,
                    EntityDashboardTab.impactAnalysis,
                ];

            case DgModule.Catalog:
                return [
                    EntityDashboardTab.details,
                    EntityDashboardTab.dataQuality,
                    EntityDashboardTab.linkedObjects,
                    EntityDashboardTab.containers,
                    EntityDashboardTab.tables,
                    EntityDashboardTab.columns,
                    EntityDashboardTab.pks,
                    EntityDashboardTab.fks,
                    EntityDashboardTab.diagram,
                    EntityDashboardTab.sample,
                    EntityDashboardTab.impactAnalysis,
                    EntityDashboardTab.settings,
                ];
        }
    }

    private hasDetailsAccess(entityType: EntityType) {
        switch (entityType) {
            case EntityType.Organization:
            case EntityType.Project:
            case EntityType.FilteredView:
            case EntityType.DataProcessingItem:
            case EntityType.PrimaryKey:
            case EntityType.ForeignKey:
            case EntityType.FreeDiagram:
            case EntityType.PhysicalDiagram:
                return false;
            default:
                return true;
        }
    }

    private hasImplementationAccess(entityIdentifier: IEntityIdentifier) {
        return DataUtil.isGlossaryMappingEnabled(entityIdentifier.entityType);
    }

    private hasObjectLinksAccess(entityType: EntityType) {
        switch (entityType) {
            case EntityType.BusinessDomainGroup:
                return false;
            default:
                return true;
        }
    }

    private hasDiagramAccess(entity: EntityItem) {
        const dgModule = DataUtil.getModuleFromEntityType(entity.entityType);

        return (
            dgModule !== DgModule.Catalog ||
            (DataUtil.isModelDiagramsEnabled(entity?.HddData) &&
                entity.ServerType === ServerType.Table)
        );
    }

    private hasMappingAccess(entityType: EntityType) {
        return DataUtil.isDataProcessingMappingEnabled(entityType);
    }

    private hasComponentsAccess(entityType: EntityType) {
        return EntityTypeUtil.hasEntityTypeAsChild(
            entityType,
            EntityType.UsageComponent,
        );
    }

    private hasFieldsAccess(entityType: EntityType) {
        return EntityTypeUtil.hasEntityTypeAsChild(
            entityType,
            EntityType.UsageField,
        );
    }

    private hasSettingsAccess(entity: EntityItem) {
        return (
            !!entity &&
            entity.ServerType == ServerType.Model &&
            entity.SecurityData?.HasWriteAccess
        );
    }

    private hasForeignKeysAccess(entity: EntityItem) {
        const count = entity.getAttributeValue<number>(
            PropertyName.ModelForeignKeyCount,
        );
        return this.isModelRelational(entity) && count > 0;
    }

    private hasPrimaryKeysAccess(entity: EntityItem) {
        const count = entity.getAttributeValue<number>(
            PropertyName.ModelPrimaryKeyCount,
        );
        return this.isModelRelational(entity) && count != undefined;
    }

    private hasDataQualityAccess(entity: EntityItem) {
        return this.dataQualityService.isDataQualityEnabled(entity?.entityType);
    }

    private hasContainersAccess(entity: EntityItem) {
        return (
            entity.ServerType == ServerType.Container ||
            entity.ServerType == ServerType.Model
        );
    }

    private hasTablesAccess(entity: EntityItem) {
        return (
            entity.ServerType == ServerType.Table ||
            entity.ServerType == ServerType.Model
        );
    }

    private hasColumnsAccess(entity: EntityItem) {
        return entity.ServerType == ServerType.Table;
    }

    private hasSampleAccess(entity: EntityItem) {
        const sampleParams = this.userService.clientDenodoSampleParameters;
        if (sampleParams?.featureEnabled) {
            const modelName = entity.HddData.Parents.find(
                (ancestor) =>
                    ancestor.DataReferenceId ==
                    this.getModelDescriptor(entity)?.ReferenceId,
            )?.TechnicalName;
            if (
                modelName.toLowerCase() ==
                sampleParams.sourceTechnicalName.toLowerCase()
            ) {
                return true;
            }
        }
        return false;
    }

    private getModelDescriptor(entity: EntityItem) {
        return entity?.ServerType == ServerType.Model
            ? entity.HddData.Data
            : HddUtil.getParentDataDescriptorByType(entity.HddData, [
                  ServerType.Model,
              ]);
    }

    private isModelRelational(entity: EntityItem) {
        const modelDescriptor = this.getModelDescriptor(entity);
        return ModelerDataUtil.isModelRelational(
            entity.ServerType,
            modelDescriptor,
        );
    }

    private getDeepestChild(route: ActivatedRoute): ActivatedRoute {
        while (route.firstChild) {
            route = route.firstChild;
        }
        return route;
    }
}
