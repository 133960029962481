import { computed } from '@angular/core';
import {
    patchState,
    signalStoreFeature,
    type,
    withComputed,
    withMethods,
    withState,
} from '@ngrx/signals';
import { AutoDescriptionState, initialState } from './auto-description-state';
import { AttributeFieldState } from '../../../common/attribute-field-state';
import { withRequestStatus } from '@datagalaxy/shared/signal-store-common';
import { AiGenerationStatus } from '@datagalaxy/webclient/suggestion/feature';

export function withAutoDescription() {
    return signalStoreFeature(
        { state: type<AttributeFieldState>() },
        withState<AutoDescriptionState>(initialState),
        withRequestStatus(),
        withComputed(
            ({
                acceptedSuggestion,
                isPending,
                generationError,
                pendingSuggestion,
            }) => {
                const generationStatus = computed<AiGenerationStatus>(() => {
                    if (isPending()) {
                        return 'generating';
                    }
                    const error: unknown = generationError();
                    if (error) {
                        return { error };
                    }
                    return acceptedSuggestion() ? 'generated' : 'default';
                });
                return {
                    generationStatus,
                    aiGenerationResultVisible: computed(() => {
                        return !!pendingSuggestion() || !!generationError();
                    }),
                };
            },
        ),
        withMethods((store) => ({
            rejectSuggestion() {
                patchState(store, {
                    pendingSuggestion: undefined,
                    generationError: undefined,
                });
            },
        })),
    );
}
