<dxy-burger-menu-button
    [options]="menuOptions"
    [buttonColor]="translationKo ? 'destructive' : 'ai'"
    [caretIconInButton]="true"
    glyphClass="glyph-language-ai"
    [dxyTooltip]="
        (translationKo
            ? 'Multilingual.AttributeTranslationButton.koTooltip'
            : 'Multilingual.AttributeTranslationButton.tooltip'
        ) | translate
    "
>
    <dxy-menu-header
        menu-header
        [color]="translationKo ? 'danger' : 'ai'"
        [title]="
            (translationKo
                ? 'Multilingual.AttributeTranslationButton.koTitle'
                : 'Multilingual.AttributeTranslationButton.title'
            )
                | translate
                    : {
                          sourceLanguageCode: sourceLanguageCode.toUpperCase(),
                      }
        "
    >
        @if (!translationKo) {
            @if (loading$ | async) {
                <dxy-spinner class="xs no-padding"></dxy-spinner>
            } @else {
                <p>{{ translationSource }}</p>
            }
        }
    </dxy-menu-header>
</dxy-burger-menu-button>
