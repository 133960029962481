import { Injectable } from '@angular/core';
import {
    BackendApiService,
    BaseServiceParameter,
    BaseServiceResult,
    RestApiService,
} from '@datagalaxy/data-access';
import {
    ApplySuggestionUserChoiceCommand,
    GenerateSuggestionLinkCommand,
    GetEntitySuggestionsQueryResult,
    GetLinksSuggestionsCountQueryResult,
    GetUserSuggestionsParameter,
    GetUserSuggestionsResult,
    LogViewedSuggestionsParameter,
    UpdateEntityAttributeFromSuggestionParameter,
    UserHasSuggestionsResult,
} from './suggestion-attribute';
import { UpdateEntityAttributeResult } from '@datagalaxy/webclient/entity/data-access';
import { ObjectLinkType } from '@datagalaxy/dg-object-model';
import { GenericDeserialize } from 'cerialize';
import { LinkSuggestionOrder } from '@datagalaxy/webclient/suggestion/domain';

@Injectable({ providedIn: 'root' })
export class SuggestionApiService {
    constructor(
        private backendApiService: BackendApiService,
        private restApiService: RestApiService,
    ) {}

    public async setSuggestionAttributeUserChoice(
        p: UpdateEntityAttributeFromSuggestionParameter,
    ) {
        return await this.backendApiService.postPromise(
            'DataScience/SetSuggestionAttributeUserChoice',
            p,
            UpdateEntityAttributeResult,
        );
    }

    public async logViewedSuggestions(p: LogViewedSuggestionsParameter) {
        return await this.backendApiService.postPromise(
            'DataScience/LogViewedSuggestions',
            p,
            BaseServiceResult,
        );
    }

    public async getUserSuggestions(
        p: GetUserSuggestionsParameter,
    ): Promise<GetUserSuggestionsResult> {
        return await this.backendApiService.postPromise(
            'DataScience/GetUserSuggestions',
            p,
            GetUserSuggestionsResult,
        );
    }

    public async hasSuggestions(): Promise<UserHasSuggestionsResult> {
        return await this.backendApiService.postPromise(
            'DataScience/CheckUserHasSuggestions',
            new BaseServiceParameter(),
            UserHasSuggestionsResult,
        );
    }

    public async getEntityLinkSuggestions(
        versionId: string,
        entityGuid: string,
    ): Promise<GetEntitySuggestionsQueryResult> {
        const result =
            await this.restApiService.getPromise<GetEntitySuggestionsQueryResult>(
                `linksuggestion/version/${versionId}/entity/${entityGuid}`,
            );
        return GenericDeserialize(result, GetEntitySuggestionsQueryResult);
    }

    public async applySuggestionUserChoice(
        param: ApplySuggestionUserChoiceCommand,
    ) {
        return await this.restApiService.postPromise(
            'LinkSuggestion/apply',
            param,
        );
    }

    public async generateSuggestionLinksFromEntity(
        spaceGuid: string,
        versionId: string,
        entityGuid: string,
        targetIds: string[],
    ) {
        const param = {
            TargetGuids: targetIds,
        };
        return await this.restApiService.postPromise<GenerateSuggestionLinkCommand>(
            `linksuggestion/workspace/${spaceGuid}/version/${versionId}/entity/${entityGuid}/generate`,
            param,
        );
    }

    public async generateSuggestionLinksFromModule(
        spaceGuid: string,
        versionId: string,
        targetIds: string[],
        moduleName?: string,
    ) {
        const param = {
            TargetGuids: targetIds,
        };
        return await this.restApiService.postPromise<GenerateSuggestionLinkCommand>(
            `linksuggestion/workspace/${spaceGuid}/version/${versionId}/module/${moduleName}/generate`,
            param,
        );
    }

    public async getEntityLinkSuggestionsCount(
        versionId: string,
        entityGuid: string,
    ) {
        return await this.restApiService.getPromise<GetLinksSuggestionsCountQueryResult>(
            `linksuggestion/version/${versionId}/entity/${entityGuid}/count`,
        );
    }

    public async getAllLinkSuggestions(
        spaceGuid: string,
        versionId: string,
        offset: number,
        size: number,
        orderBy: LinkSuggestionOrder,
        linkType: ObjectLinkType,
    ) {
        const params = {
            WorkspaceId: spaceGuid,
            VersionId: versionId,
            Guids: [],
            LinkType: linkType,
            offset: offset.toString(),
            size: size.toString(),
            orderBy: orderBy.toString(),
        };

        const result =
            await this.restApiService.postPromise<GetEntitySuggestionsQueryResult>(
                `linksuggestion/search`,
                params,
            );
        return GenericDeserialize(result, GetEntitySuggestionsQueryResult);
    }
}
