import { environment } from '../../../environments/environment';
import { AttributeDataService } from '../../shared/attribute/attribute-data.service';
import { EModuleType } from './EModuleType';
import { EOperationType } from './EOperationType';
import { ImportContext } from './ImportContext';
import { ModelType } from '@datagalaxy/dg-object-model';
import { ImportDataOperation } from '@datagalaxy/webclient/data-port/data-access';

export abstract class ImportModule {
    //#region static

    private static readonly _baseI18nKey = 'Import.Wizard';

    /* returns the module for the specified operation type */
    public static getModule(
        operation: EOperationType,
        importContext: ImportContext,
        attributeDataService?: AttributeDataService,
    ): ImportModule {
        function initModule(operation: EOperationType): ImportModule {
            switch (operation) {
                case EOperationType.Properties:
                case EOperationType.GlossaryRelations:
                    return new ImportModuleGlossary(importContext);
                case EOperationType.Sources:
                case EOperationType.Containers:
                case EOperationType.Structures:
                case EOperationType.Fields:
                case EOperationType.PKs:
                case EOperationType.FKs:
                case EOperationType.FFKs:
                case EOperationType.CatalogRelations:
                    return new ImportModuleCatalog(importContext);
                case EOperationType.DataProcessings:
                case EOperationType.DataProcessingItems:
                case EOperationType.DataProcessingInput:
                case EOperationType.DataProcessingOutput:
                case EOperationType.DataProcessingRelations:
                    return new ImportModuleDataProcessings(importContext);
                case EOperationType.SoftwareElements:
                case EOperationType.SoftwareRelations:
                    return new ImportModuleSoftwareElements(importContext);
                case EOperationType.AttributeTags:
                    return new ImportModuleAttributes(
                        importContext,
                        attributeDataService,
                    );
                case EOperationType.Users:
                    return new ImportModuleUsers(importContext);
                case EOperationType.TimeSeries:
                    return new ImportModuleTimeSeries(importContext);
                case EOperationType.Teams:
                    return new ImportModuleTeams(importContext);
                default:
                    throw 'unknown operation type: ' + EModuleType[operation];
            }
        }

        const module = initModule(operation);
        return module;
    }

    /** returns true if the specified operationType attributes list is hardcoded, false if it is to be retrieved from the API */
    public static isOperationHardCoded(operationType: EOperationType) {
        switch (operationType) {
            case EOperationType.GlossaryRelations:
            case EOperationType.DataProcessingRelations:
            case EOperationType.CatalogRelations:
            case EOperationType.SoftwareRelations:
            case EOperationType.PKs:
            case EOperationType.DataProcessingInput:
            case EOperationType.DataProcessingOutput:
            case EOperationType.DataProcessingItems:
                return true;

            default:
                return false;
        }
    }

    /** converts EOperationType to ImportDataOperation */
    public static GetImportDataOperation(operationType: EOperationType) {
        const OT = EOperationType;
        const IDO = ImportDataOperation;

        switch (operationType) {
            case EOperationType.undefined:
                break;

            // module glossary
            case OT.Properties:
                return IDO.ImportGlossary;
            case OT.GlossaryRelations:
                return IDO.ImportGlossaryLink;

            // module model
            case OT.Sources:
                return IDO.ImportCatalogSource;
            case OT.Containers:
                return IDO.ImportCatalogContainer;
            case OT.Structures:
                return IDO.ImportCatalogStructure;
            case OT.Fields:
                return IDO.ImportCatalogField;
            case OT.PKs:
                return IDO.ImportCatalogPrimaryKey;
            case OT.FKs:
                return IDO.ImportCatalogForeignKey;
            case OT.FFKs:
                return IDO.ImportCatalogFunctionalKey;
            case OT.CatalogRelations:
                return IDO.ImportCatalogLink;

            // module: DataProcessings
            case OT.DataProcessings:
                return IDO.ImportProcessing;
            case OT.DataProcessingInput:
                return IDO.ImportProcessingInput;
            case OT.DataProcessingOutput:
                return IDO.ImportProcessingOutput;
            case OT.DataProcessingItems:
                return IDO.ImportProcessingItem;
            case OT.DataProcessingRelations:
                return IDO.ImportProcessingLink;

            // module SoftwareElements
            case OT.SoftwareElements:
                return IDO.ImportUsage;
            case OT.SoftwareRelations:
                return IDO.ImportUsageLink;

            // module AttributeTags
            case OT.AttributeTags:
                return IDO.ImportAttributeTag;

            // module Users
            case OT.Users:
                return IDO.ImportUser;

            // module TimeSeries
            case OT.TimeSeries:
                return IDO.ImportEntityTimeSeries;

            // module Teams
            case OT.Teams:
                return IDO.ImportTeam;

            default:
                if (!environment.production) {
                    console.warn(
                        'operation type not mapped to an ImportDataOperation:' +
                            EOperationType[operationType],
                    );
                }
                return undefined;
        }
    }

    public static getImportOperationFunctionalCode(
        operationType: EOperationType,
    ): string {
        const OT = EOperationType;
        switch (operationType) {
            case OT.Properties:
                return 'GLOSSARY_PROPERTIES';
            case OT.GlossaryRelations:
                return 'GLOSSARY_LINKED_OBJECTS';
            case OT.Sources:
                return 'DICTIONARY_SOURCES';
            case OT.Containers:
                return 'DICTIONARY_CONTAINERS';
            case OT.Structures:
                return 'DICTIONARY_STRUCTURES';
            case OT.Fields:
                return 'DICTIONARY_FIELDS';
            case OT.PKs:
                return 'DICTIONARY_PK';
            case OT.FKs:
                return 'DICTIONARY_FK';
            case OT.FFKs:
                return 'DICTIONARY_RELATIONS';
            case OT.CatalogRelations:
                return 'DICTIONARY_LINKED_OBJECTS';
            case OT.DataProcessings:
                return 'DATA_PROCESSING_PROCESSES';
            case OT.DataProcessingItems:
                return 'DATA_PROCESSING_DPI';
            case OT.DataProcessingInput:
                return 'DATA_PROCESSING_IN';
            case OT.DataProcessingOutput:
                return 'DATA_PROCESSING_OUT';
            case OT.DataProcessingRelations:
                return 'DATA_PROCESSING_LINKED_OBJECTS';
            case OT.SoftwareElements:
                return 'USES_USES';
            case OT.SoftwareRelations:
                return 'USES_LINKED_OBJECTS';
            case OT.AttributeTags:
                return 'TAGS';
            case OT.Users:
                return 'USERS';
            case OT.TimeSeries:
                return 'TIME_SERIES';
            case OT.Teams:
                return 'TEAMS';
            default:
                return 'UNKNOWN';
        }
    }

    //#endregion - static

    public readonly i18nKey: string;
    public abstract readonly operationTypes: EOperationType[];

    // make const readonly
    public moduleLogString = '';

    protected constructor(
        public readonly type: EModuleType,
        public readonly importContext: ImportContext,
        baseI18nKey = ImportModule._baseI18nKey,
    ) {
        this.i18nKey = `${baseI18nKey}.${EModuleType[type]}`;
    }

    /** returns true if the specified operation is enabled in the current state of the module */
    public abstract isOpeEnabled(ot: EOperationType): boolean;

    /** returns the translation key for the specified operation, if it is part of this module */
    public getOpeTextKey(ot: EOperationType) {
        return this.operationTypes.includes(ot)
            ? `${this.i18nKey}.${EOperationType[ot]}`
            : undefined;
    }
}

export class ImportModuleGlossary extends ImportModule {
    public moduleLogString = 'GLOSSARY';

    public readonly operationTypes = [
        EOperationType.Properties,
        EOperationType.GlossaryRelations,
    ];

    public get imageName() {
        return 'glyph-glossary';
    }

    constructor(importContext: ImportContext) {
        super(EModuleType.Glossary, importContext);
    }

    /** returns true if the specified operation is enabled in the current state of the module */
    public isOpeEnabled(ot: EOperationType) {
        if (!this.operationTypes.includes(ot)) {
            return false;
        }
        if (ot == EOperationType.GlossaryRelations) {
            return this.importContext.glossaryHasProperties;
        }
        return true;
    }
}

export class ImportModuleCatalog extends ImportModule {
    public isUpdate: boolean;
    public sourceId: string;
    public newSourceName: string;
    public sourceType: ModelType = null;
    public isEntityOnly: boolean;
    public hasSourceCreationAccess = true;
    public moduleLogString = 'DICTIONARY';

    public readonly operationTypes = [
        EOperationType.Sources,
        EOperationType.Containers,
        EOperationType.Structures,
        EOperationType.Fields,
        EOperationType.PKs,
        EOperationType.FKs,
        EOperationType.FFKs,
        EOperationType.CatalogRelations,
    ];

    public get imageName() {
        return 'glyph-catalog';
    }

    constructor(importContext: ImportContext) {
        super(EModuleType.Catalog, importContext);
        this.isUpdate = !!importContext.initialEntityId;
        this.sourceId = importContext.initialEntityId;
        this.sourceType = importContext.initialSourceType;
    }

    private isValidNewModel() {
        return !this.isUpdate && !!(this.newSourceName && this.sourceType);
    }

    private isValidExistingModel() {
        return this.isUpdate && !!this.sourceId;
    }

    /** returns true if the specified operation is enabled in the current state of the module */
    public isOpeEnabled(ot: EOperationType) {
        if (!this.operationTypes.includes(ot)) {
            return false;
        }
        if (
            ot != EOperationType.Sources &&
            ot != EOperationType.CatalogRelations
        ) {
            if (!this.isUpdate && !this.newSourceName) {
                return false;
            }
            if (this.isUpdate && !this.sourceId) {
                return false;
            }
        }
        switch (ot) {
            case EOperationType.Containers:
            case EOperationType.Structures:
            case EOperationType.Fields:
                return this.isValidNewModel() || this.isValidExistingModel();
            case EOperationType.PKs:
            case EOperationType.FKs: {
                return (
                    this.isValidExistingModel() &&
                    this.sourceType == ModelType.Relational
                );
            }
            case EOperationType.FFKs:
                return this.isValidExistingModel();
            default:
                return true;
        }
    }
}

export class ImportModuleDataProcessings extends ImportModule {
    public moduleLogString = 'DATA_PROCESSING';

    public readonly operationTypes = [
        EOperationType.DataProcessings,
        EOperationType.DataProcessingInput,
        EOperationType.DataProcessingOutput,
        EOperationType.DataProcessingItems,
        EOperationType.DataProcessingRelations,
    ];

    public get imageName() {
        return 'glyph-dataprocessing';
    }

    constructor(importContext: ImportContext) {
        super(EModuleType.DataProcessings, importContext);
    }

    /** returns true if the specified operation is enabled in the current state of the module */
    public isOpeEnabled(ot: EOperationType) {
        if (!this.operationTypes.includes(ot)) {
            return false;
        }
        switch (ot) {
            case EOperationType.DataProcessingInput:
                return (
                    this.importContext.sourceExists &&
                    this.importContext.dataProcessingsHasDpElements
                );

            case EOperationType.DataProcessingOutput:
                return (
                    this.importContext.sourceExists &&
                    this.importContext.dataProcessingsHasDpElements
                );

            case EOperationType.DataProcessingItems:
                return (
                    this.importContext.sourceExists &&
                    this.importContext.dataProcessingsHasDpElements
                );

            default:
                return true;
        }
    }
}

export class ImportModuleSoftwareElements extends ImportModule {
    public moduleLogString = 'USES';

    public readonly operationTypes = [
        EOperationType.SoftwareElements,
        EOperationType.SoftwareRelations,
    ];

    public get imageName() {
        return 'glyph-software';
    }

    constructor(importContext: ImportContext) {
        super(EModuleType.SoftwareElements, importContext);
    }

    /** returns true if the specified operation is enabled in the current state of the module */
    public isOpeEnabled(ot: EOperationType) {
        return this.operationTypes.includes(ot);
    }
}

export class ImportModuleAttributes extends ImportModule {
    public readonly operationTypes = [EOperationType.AttributeTags];
    private attributeDataService: AttributeDataService;

    constructor(
        importContext: ImportContext,
        attributeDataService: AttributeDataService,
    ) {
        super(EModuleType.Attribute, importContext);
        this.attributeDataService = attributeDataService;
    }

    /** returns true if the specified operation is enabled in the current state of the module */
    public isOpeEnabled(ot: EOperationType) {
        return this.operationTypes.includes(ot);
    }
}

export class ImportModuleUsers extends ImportModule {
    public readonly operationTypes = [EOperationType.Users];

    public get imageName() {
        return 'glyph-user-unlock';
    }

    constructor(importContext: ImportContext) {
        super(EModuleType.User, importContext);
    }

    /** returns true if the specified operation is enabled in the current state of the module */
    public isOpeEnabled(ot: EOperationType) {
        return this.operationTypes.includes(ot);
    }
}

export class ImportModuleTimeSeries extends ImportModule {
    public readonly operationTypes = [EOperationType.TimeSeries];

    public get imageName() {
        return '';
    }

    constructor(importContext: ImportContext) {
        super(EModuleType.TimeSeries, importContext);
    }

    /** returns true if the specified operation is enabled in the current state of the module */
    public isOpeEnabled(ot: EOperationType) {
        return this.operationTypes.includes(ot);
    }
}

export class ImportModuleTeams extends ImportModule {
    public readonly operationTypes = [EOperationType.Teams];

    constructor(importContext: ImportContext) {
        super(EModuleType.Teams, importContext);
    }

    /** returns true if the specified operation is enabled in the current state of the module */
    public isOpeEnabled(ot: EOperationType) {
        return ot == EOperationType.Teams;
    }
}
