import * as moment from 'moment';
import { Subject, fromEvent, Observable, Subscription } from 'rxjs';
import { NgZone, OnDestroy } from '@angular/core';
import { DomUtil } from '@datagalaxy/core-util';
import { Injectable } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { BaseService } from '../base';
import { KeyboardUtil } from '@datagalaxy/utils';

/**
    #Archi-service-leaf (Does not reference any other app service)
*/
@Injectable({ providedIn: 'root' })
export class CoreEventsService extends BaseService implements OnDestroy {
    //#region UI language

    /**
     * @deprecated use currentUserService.selectLanguage() instead
     */
    public get uiLanguageChanged$() {
        return this.uiLanguageChanged.asObservable();
    }
    private readonly uiLanguageChanged = new Subject<string>();

    /**
     * Sets the current UI language code, in translation and date formatting (Moment).
     * *uiLanguageChanged$* will dispatch an event only after translate-service *onLangChange*
     * own event, because the service might need to fetch appropriate translation files
     * (See constructor subscription)
     * */
    public set uiLanguage(lang: string) {
        lang = lang?.trim().toLowerCase();
        const change = lang != this._uiLanguage;
        this.log('set-uiLanguage', lang, this._uiLanguage, change);
        // fix for Angular translateService not being initialized
        //if(!change) { return }

        this._uiLanguage = lang;
        this.translate.use(lang);
        moment.locale(lang);
    }
    /** Current UI language code used in translation and date formatting (Moment) */
    public get uiLanguage() {
        return this._uiLanguage;
    }
    private _uiLanguage: string;

    //#endregion

    //#region window resize

    /** Fired on window resize,
     * and when *emitMainViewResize* is called (main view, left nav side bar, right docking pane) */
    public get mainViewResize$() {
        return this.mainViewResize.asObservable();
    }
    private readonly mainViewResize = new Subject<void>();

    public get windowResize$() {
        return this._windowResize$;
    }
    private _windowResize$: Observable<Event>;
    private readonly subscriptions = new Subscription();

    //#endregion

    //#region window-keydown-escape
    public get windowKeyDownEscape$() {
        return this.windowKeyDownEscape.asObservable();
    }
    private readonly windowKeyDownEscape = new Subject<KeyboardEvent>();
    //#endregion

    constructor(
        private translate: TranslateService,
        ngZone: NgZone,
    ) {
        super();

        ngZone.runOutsideAngular(
            () => (this._windowResize$ = fromEvent(window, 'resize')),
        );
        this.subscriptions.add(
            this._windowResize$.subscribe(() => this.emitMainViewResize()),
        );
        this.subscriptions.add(
            DomUtil.addListener(
                window,
                'keydown',
                (e: KeyboardEvent) =>
                    KeyboardUtil.isEscapeKey(e) &&
                    this.windowKeyDownEscape.next(e),
                ngZone,
                true,
            ),
        );
        this.subscriptions.add(
            this.translate.onLangChange.subscribe(
                (langChangeEvent: LangChangeEvent) => {
                    this.uiLanguageChanged.next(langChangeEvent.lang);
                },
            ),
        );
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    public onNavigationSideBarToggled() {
        this.log('onNavigationSideBarToggled');
        this.emitMainViewResize(100);
    }

    public emitMainViewResize(timeoutMs = 0) {
        this.log('emitMainViewResize', timeoutMs);
        setTimeout(() => this.mainViewResize.next(), timeoutMs);
    }
}
