import { environment } from './environments/environment';
import { CoreUtil } from '@datagalaxy/core-util';
import { getAppConfig } from './app/app.config';
import { APP_CONFIG, AppConfig, IConfig } from '@datagalaxy/webclient/config';
import { AppComponent } from './app/app.component';
import { bootstrapApplication } from '@angular/platform-browser';

CoreUtil.isProduction = environment.production;

function bootstrapApp(config: IConfig) {
    const authV2Enabled = config.appConfig.enableAuthV2 === 'true';
    const providers = [{ provide: APP_CONFIG, useValue: config }];

    return bootstrapApplication(AppComponent, {
        providers: [...providers, ...getAppConfig(authV2Enabled).providers],
    });
}

AppConfig.load()
    .then((config) => bootstrapApp(config))
    .catch((err) => console.error(err));
