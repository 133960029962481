export function buildUrlSearchParams(
    params?: Record<string, string | string[]>,
) {
    const searchParams = new URLSearchParams();
    Object.entries({ ...params }).forEach(([key, value]) => {
        if (Array.isArray(value)) {
            value.forEach((value) =>
                searchParams.append(key, value?.toString()),
            );
            return;
        }
        if (value !== undefined) {
            searchParams.append(key, value.toString());
        }
    });
    return searchParams.toString();
}
