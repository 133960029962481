import {
    TeamMembersAddedEvent,
    TeamMembersDeletedEvent,
    TeamMembershipRequestCreatedEvent,
    TeamMembershipRequestResponseEvent,
    TeamOwnersAddedEvent,
} from '@datagalaxy/dg-object-model';
import { IDgEventTranslationAdapter } from '../../../interfaces/IDgEventTranslationAdapter';
import { registerTypedEventTranslationAdapter } from '../../dg-event-translation-adapter-registry';
import { DgEventTranslationAdapterBase } from '../common/dg-event-translation-adapter-base';
import { getUserEventTranslationInputs } from '../common/dg-event-translation-adapter-helpers';
import { getTeamNavLink } from '../common/dg-event-translation-nav-links.helpers';

const eventTypes = [
    TeamMembersAddedEvent,
    TeamMembersDeletedEvent,
    TeamMembershipRequestCreatedEvent,
    TeamMembershipRequestResponseEvent,
    TeamOwnersAddedEvent,
];
export type TTeamEvent = InstanceType<(typeof eventTypes)[number]>;

/**
 * ## Role
 * Adapter to translate team events
 */
@registerTypedEventTranslationAdapter(eventTypes)
export class TeamEventTranslationAdapter
    extends DgEventTranslationAdapterBase
    implements IDgEventTranslationAdapter
{
    constructor(private event: TTeamEvent) {
        super(event);
    }

    public getTranslationInputs() {
        const inputs = getUserEventTranslationInputs(this.event);
        inputs.translateParameters.push({
            key: 'teamName',
            value: this.event.TeamName,
        });
        if (this.event instanceof TeamMembershipRequestResponseEvent) {
            inputs.translateParameters.push({
                key: 'accepted',
                value: this.event.Accepted?.toString(),
            });
        }

        if (
            this.event instanceof TeamMembersAddedEvent &&
            this.event.IsMembershipRequest
        ) {
            inputs.translateKey = 'DgEvent.TeamMembersAddedEventOpenMembership';
        }

        inputs.navLinks.push(
            getTeamNavLink(
                this.event,
                this.event.TeamGuid,
                this.event.TeamName,
            ),
        );
        return inputs;
    }
}
