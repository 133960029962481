import {
    createTable,
    getCoreRowModel,
    getSortedRowModel,
    Table,
    TableOptionsResolved,
    TableState,
    Updater,
} from '@tanstack/table-core';

function onTableStateChange<TRow>(
    table: Table<TRow>,
    onStateChange: () => void,
) {
    return (updater: Updater<TableState>) => {
        table.setOptions((prev) => {
            const state =
                typeof updater === 'function'
                    ? updater(table.getState())
                    : updater;

            return {
                ...prev,
                state: {
                    ...prev.state,
                    ...state,
                },
            };
        });
        onStateChange();
    };
}

export function createDefaultTable<TRow>(
    onStateChange: () => void,
): Table<TRow> {
    const resolvedOptions: TableOptionsResolved<TRow> = {
        state: {
            columnPinning: {},
            rowSelection: {},
            columnSizing: {},
            pagination: {
                pageSize: Number.MAX_SAFE_INTEGER,
                pageIndex: 0,
            },
        },
        columns: [],
        data: [],
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        columnResizeMode: 'onChange',
        renderFallbackValue: null,
        onStateChange: () => {},
    };

    const table: Table<TRow> = createTable<TRow>(resolvedOptions);

    table.setOptions((prev) => {
        return {
            ...prev,
            onStateChange: onTableStateChange(table, onStateChange),
        };
    });

    return table;
}
