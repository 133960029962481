import { IndividualConfig, ToastrService } from 'ngx-toastr';
import { Injectable } from '@angular/core';
import { ToastActionBtn, ToastType } from './toast/toast.types';

/**
 * ## Role
 * This service is responsible for showing toasts.
 *
 */

@Injectable({
    providedIn: 'root',
})
export class Toaster {
    constructor(private toastrService: ToastrService) {}

    public successToast(options: ShowToastOptions) {
        this.showToast(ToastType.Success, options);
    }

    public errorToast(options: ShowToastOptions) {
        this.showToast(ToastType.Error, options);
    }

    public warningToast(options: ShowToastOptions) {
        this.showToast(ToastType.Warning, options);
    }

    public infoToast(options: ShowToastOptions) {
        this.showToast(ToastType.Info, options);
    }

    public showToast(toastType: ToastType, options: ShowToastOptions) {
        const title = options.title ?? '';
        const message = options.message ?? '';

        const toaster = this.toastrService.show(message, title, {
            progressBar: true,
            closeButton: true,
            timeOut: options.timeOut || 5000,
            extendedTimeOut: options.timeOut || 5000,
        } as Partial<IndividualConfig>);

        toaster.toastRef.componentInstance.color = toastType;
        toaster.toastRef.componentInstance.actionsBtn = options.actionsBtn;
    }
    public async waitAllToastersAreClosed() {
        if (!this.toastrService.currentlyActive) {
            return;
        }
        const toasts = this.toastrService.toasts;
        if (!toasts?.length) {
            return;
        }
        await Promise.all(
            toasts.map(
                (toast) =>
                    new Promise<void>((resolve) => {
                        toast.onHidden.subscribe(() => resolve());
                    }),
            ),
        );
    }
}

export interface ShowToastOptions {
    title?: string;
    message?: string;
    timeOut?: number;
    actionsBtn?: ToastActionBtn[];
}
