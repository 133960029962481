/**
 * Represents a problem details object as defined in RFC 7807
 * Problem Details are a way to carry machine-readable details of errors in a HTTP response to avoid the need to define new error response formats for HTTP APIs.
 * @see https://tools.ietf.org/html/rfc7807
 */
export class ProblemDetails {
    constructor(
        public type: string,
        public title: string,
        public status: number,
        public detail: string,
    ) {}

    public static isProblemDetails(error: any) {
        return !!(
            error?.type &&
            error?.title &&
            error?.detail &&
            error?.status
        );
    }

    public static fromJson(json: any): ProblemDetails {
        if (!ProblemDetails.isProblemDetails(json)) {
            throw new Error('Json object is not a problem details');
        }
        return new ProblemDetails(
            json.type,
            json.title,
            json.status,
            json.detail,
        );
    }
}
