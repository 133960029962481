import { Injectable } from '@angular/core';
import { IUserNotificationChannelsState } from './user-notification-channels-state';
import { NotificationChannelsApiService } from '../api/notification-channels.api.service';
import { CoreUtil } from '@datagalaxy/core-util';
import { ClientServiceManagerService } from '../../client/client-service-manager.service';
import { BaseStateService } from '@datagalaxy/utils';

/**
 * ## Role
 * User notification channels State management
 */
@Injectable({ providedIn: 'root' })
export class UserNotificationChannelsStateService extends BaseStateService<IUserNotificationChannelsState> {
    constructor(
        private notificationChannelsApiService: NotificationChannelsApiService,
        private clientServiceManagerService: ClientServiceManagerService,
    ) {
        const defaultState = {
            userChannels: [],
            clientIntegrations: [],
        };
        super(defaultState);
        this.clientServiceManagerService.doCleanup$.subscribe(() =>
            this.setState(defaultState),
        );
    }

    public selectState() {
        return this.select((s) => s);
    }

    public selectUserChannels() {
        return this.select((s) => s.userChannels);
    }

    public selectUserChannel(chanelName: string) {
        return this.select((s) =>
            s.userChannels.find((uc) => uc.ChannelName == chanelName),
        );
    }

    public selectClientIntegrations() {
        return this.select((s) => s.clientIntegrations);
    }

    public selectClientIntegration(channelName: string) {
        return this.select((s) =>
            s.clientIntegrations.find((ci) => ci.ChannelName == channelName),
        );
    }

    public async initUserNotificationChannels(forceReload?: boolean) {
        if (!forceReload && this.state.userChannels.length) {
            return;
        }
        const result =
            await this.notificationChannelsApiService.getCurrentUserSettings();
        this.setState({
            userChannels: result.UserNotificationChannels,
        });
    }

    public async initClientIntegration(forceReload?: boolean) {
        if (!forceReload && this.state.clientIntegrations.length) {
            return;
        }
        const result =
            await this.notificationChannelsApiService.getClientIntegrations();
        this.setState({
            clientIntegrations: result.Integrations,
        });
    }

    public setClientIntegration(channelName: string, activated: boolean) {
        const previousIntegrations =
            this.state.clientIntegrations.filter(
                (ci) => ci.ChannelName != channelName,
            ) ?? [];

        const stateUpdate = {
            clientIntegrations: [
                ...previousIntegrations,
                {
                    ChannelName: channelName,
                    Activated: activated,
                },
            ],
        } as Partial<IUserNotificationChannelsState>;

        const previousUserChannel = this.state.userChannels.find(
            (uc) => uc.ChannelName == channelName,
        );
        if (previousUserChannel) {
            const newUserChannel = CoreUtil.cloneDeep(previousUserChannel);
            newUserChannel.Activable = activated;
            stateUpdate.userChannels = [
                ...this.state.userChannels.filter(
                    (uc) => uc.ChannelName != channelName,
                ),
                newUserChannel,
            ];
        }
        this.setState(stateUpdate);
    }

    public setUserChannelActivation(channelName: string, activated: boolean) {
        this.setState({
            userChannels: [
                ...this.state.userChannels.map((c) =>
                    c.ChannelName != channelName
                        ? c
                        : {
                              ...c,
                              ChannelSetting: {
                                  Default: activated,
                              },
                          },
                ),
            ],
        });
    }

    public setUserChannelAsked(channelName: string, asked: boolean) {
        this.setState({
            userChannels: [
                ...this.state.userChannels.map((c) =>
                    c.ChannelName != channelName
                        ? c
                        : {
                              ...c,
                              IntegrationAsked: asked,
                          },
                ),
            ],
        });
    }

    public getClientChannelActivated(channelName: string) {
        return this.getValue(
            (s) =>
                s.clientIntegrations.find((ci) => ci.ChannelName == channelName)
                    ?.Activated,
        );
    }

    public getUserChannelActivable(channelName: string) {
        return this.getValue(
            (s) =>
                s.userChannels.find((ci) => ci.ChannelName == channelName)
                    ?.Activable,
        );
    }
}
