import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

/**
 * @deprecated Use Signal store instead https://ngrx.io/guide/signals/signal-store
 */
export abstract class BaseStateService<T> {
    protected state$: BehaviorSubject<T>;
    private debug = false;

    public get state(): T {
        return this.state$.getValue();
    }

    constructor(initialState: T) {
        this.state$ = new BehaviorSubject<T>(initialState);
    }

    public select<K>(mapFn: (state: T) => K): Observable<K> {
        return this.state$.asObservable().pipe(
            map((state: T) => mapFn(state)),
            distinctUntilChanged(),
        );
    }

    protected getValue<K>(mapFn: (state: T) => K): K {
        return mapFn(this.state);
    }

    protected setState(newStatePart: Partial<T>) {
        if (!newStatePart) {
            return;
        }
        const newState = {
            ...this.state,
            ...newStatePart,
        };
        if (this.debug) {
            logStateDifferences(this.state, newState);
        }
        this.state$.next(newState);
    }
}

const consoleColors = {
    red: '\x1b[31m',
    green: '\x1b[32m',
    yellow: '\x1b[33m',
    reset: '\x1b[0m',
};

function logStateDifferences<T>(previousState: T, newState: T) {
    console.log('---------Start-----------');
    logStateDifferencesRecursively(previousState, newState);
    console.log('---------End-------------');
}

function logStateDifferencesRecursively(
    previousStatePart: any,
    newStatePart: any,
    path = 'root',
) {
    for (const key in previousStatePart) {
        if (previousStatePart?.[key] == undefined) {
            continue;
        }
        if (
            typeof previousStatePart[key] === 'object' &&
            typeof newStatePart?.[key] === 'object'
        ) {
            logStateDifferencesRecursively(
                previousStatePart[key],
                newStatePart[key],
                `${path}.${key}`,
            );
        } else if (newStatePart?.[key] == undefined) {
            console.log(
                `${consoleColors.red}REMOVED${consoleColors.reset} ${path}.${key} has changed:`,
                previousStatePart[key],
                '->',
                newStatePart?.[key],
            );
        } else if (previousStatePart[key] !== newStatePart[key]) {
            console.log(
                `${consoleColors.yellow}UPDATED${consoleColors.reset} ${path}.${key} has changed:`,
                previousStatePart[key],
                '->',
                newStatePart[key],
            );
        }
    }

    for (const key in newStatePart) {
        if (previousStatePart[key] == undefined) {
            console.log(
                `${consoleColors.green}ADDED${consoleColors.reset} ${path}.${key} has been added:`,
                newStatePart[key],
            );
        }
    }
}
