import { Injectable, Injector } from '@angular/core';
import {
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { BaseServiceParameter } from '@datagalaxy/data-access';
import { CoreUtil } from '@datagalaxy/core-util';
import { WorkspaceStore } from '@datagalaxy/webclient/workspace/data-access';

/**
 * ## Role
 * Add the current workspace versionId to the request parameters
 *
 * This is for retro compatibility with the old api service but each request
 * should specify the versionId on itself to avoid some case where a request
 * is made without a current space context and suddenly fail
 */
@Injectable()
export class CurrentSpaceVersionIdInterceptor implements HttpInterceptor {
    constructor(private injector: Injector) {}

    intercept(req: HttpRequest<BaseServiceParameter>, next: HttpHandler) {
        const workspaceStore = this.injector.get(WorkspaceStore);
        const versionId = workspaceStore.currentSpace?.versionId;
        const param = req.body;

        if (
            !versionId ||
            param.apiGenericDontSetVersionId ||
            param.VersionId ||
            param.VersionId == ''
        ) {
            return next.handle(req);
        }

        const cloneReq: HttpRequest<BaseServiceParameter> = req.clone({
            body: CoreUtil.assign(req.body, { VersionId: versionId }),
        });

        return next.handle(cloneReq);
    }
}
