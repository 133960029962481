import {
    autoserializeAs,
    deserialize,
    deserializeAs,
    inheritSerialization,
    SerializableEnumeration,
    serialize,
    serializeAs,
} from 'cerialize';
import {
    BaseServiceParameter,
    BaseServiceResult,
} from '@datagalaxy/data-access';
import {
    EntityType,
    HierarchicalData,
    IEntitySnapshot,
    ObjectLinkType,
    TypeLinkDataInfo,
} from '@datagalaxy/dg-object-model';
import { LinkEntityData } from '../entity';
import {
    EntityItem,
    EntityLinkItem,
    LinkedDataGroup,
} from '@datagalaxy/webclient/entity/domain';
import { ServerConstants } from '@datagalaxy/shared/server/domain';

@inheritSerialization(BaseServiceParameter)
export class GetLinkedDataParameter extends BaseServiceParameter {
    @serialize public DataReferenceId: string;
    @autoserializeAs(ObjectLinkType) public IncludedTypes?: ObjectLinkType[];
    @serialize public IncludedTargetTypeNames?: string[];

    /**
     * Specifying this flag allows to obtain the full details of the linked entities in the result
     * */
    @serialize public IncludeEntities: boolean;
    @serialize public IncludeSecurity: boolean;
    @serialize public IncludeObjectValues: boolean;

    /**
     * Specifying this flag allows to filter the exact list of wanted attributes on the Entities
     * One should limit that list to the displayed attributes in a grid
     * */
    @serialize public IncludedAttributesFilter: string[];

    constructor(id: string, includeEntities = false) {
        super();
        this.DataReferenceId = id;

        //NOTE: Example values
        this.IncludeEntities = includeEntities;
        this.IncludeSecurity = this.IncludeEntities;
        this.IncludeObjectValues = this.IncludeEntities;

        this.IncludedAttributesFilter = this.IncludeEntities
            ? [
                  ServerConstants.PropertyName.DisplayName,
                  ServerConstants.PropertyName.TechnicalName,
                  ServerConstants.PropertyName.EntityStatus,
                  ServerConstants.PropertyName.EntityStatus,
                  ServerConstants.PropertyName.Description,
              ]
            : [];
    }
}

@inheritSerialization(BaseServiceResult)
export class GetLinkedDataResult extends BaseServiceResult {
    @deserializeAs(LinkedDataGroup) public Groups!: LinkedDataGroup[];
    /** NOTE: The Entities from all the groups are included in this flattened list if IncludeEntities is used in the parameter */
    @deserializeAs(EntityItem) public Entities!: EntityItem[];
    public InaccessibleLinkGroups!: InaccessibleLinkGroups;

    public static OnDeserialized(instance: GetLinkedDataResult, json: any) {
        if (json.InaccessibleLinkGroups) {
            instance.InaccessibleLinkGroups = json.InaccessibleLinkGroups;
        }
    }
}

export interface InaccessibleLinkGroups {
    [key: string]: IEntitySnapshot[];
}

export enum UpdateLinkAction {
    SetGoldenLink = 0,
    RemoveGoldenLink = 1,
    UpdateObjectLinkType = 2,
}
SerializableEnumeration(UpdateLinkAction);

@inheritSerialization(BaseServiceParameter)
export class UpdateEntityLinkParameter extends BaseServiceParameter {
    @serialize public EntityLinkReferenceId: string;
    @serializeAs(UpdateLinkAction) public UpdateAction: UpdateLinkAction;
    @serializeAs(ObjectLinkType) public ObjectLinkType?: ObjectLinkType;

    constructor(
        entityLinkReferenceId: string,
        updateAction: UpdateLinkAction,
        objectLinkType?: ObjectLinkType,
    ) {
        super();
        this.EntityLinkReferenceId = entityLinkReferenceId;
        this.UpdateAction = updateAction;
        this.ObjectLinkType = objectLinkType;
    }
}

@inheritSerialization(BaseServiceResult)
export class UpdateEntityLinkResult extends BaseServiceResult {
    @deserialize public UpdatedEntityIds!: string[];
    @deserializeAs(EntityLinkItem)
    public UpdatedEntityLinkItems!: EntityLinkItem[];
}

export enum AddLinkAction {
    Append,
    Replace,
}
SerializableEnumeration(AddLinkAction);

@inheritSerialization(BaseServiceParameter)
export class AddLinkedEntitiesParameter extends BaseServiceParameter {
    @serialize public DataReferenceIdList: string[];
    @serialize public LinkedDataReferenceIdList: string[];
    @serialize public LinkEntityAttributes: string[] | null;
    @serialize public LinkedEntityAttributes: string[];
    @serialize public UpdatedEntityIncludeObjectValues: boolean;
    @serialize public UpdatedEntityIncludedAttributesFilter: string[];
    @serialize public IsLogicalReplacementConfirmation: boolean;

    @autoserializeAs(ObjectLinkType) public LinkType: ObjectLinkType;
    @serializeAs(AddLinkAction) public AddLinkAction?: AddLinkAction;

    public static createModern(
        dataReferenceIdList: string[],
        linkType: ObjectLinkType,
        linkedIds: string[],
        linkEntityAttributes: string[] | null = null,
        linkedEntityAttributes: string[] | null = null,
        updatedEntityAttributes: string[] | null = null,
        addLinkAction: AddLinkAction = AddLinkAction.Append,
    ): AddLinkedEntitiesParameter {
        const parameter = new AddLinkedEntitiesParameter(
            dataReferenceIdList,
            linkedIds,
            linkType,
            linkEntityAttributes,
            linkedEntityAttributes,
            updatedEntityAttributes,
        );
        parameter.LinkType = linkType;
        parameter.AddLinkAction = addLinkAction;
        return parameter;
    }

    constructor(
        dataReferenceIdList: string[],
        linkedDataReferenceIds: string[],
        linkType: ObjectLinkType,
        linkEntityAttributes: string[] | null,
        linkedEntityAttributes: string[] | null,
        updatedEntityAttributes: string[] | null,
    ) {
        super();
        this.DataReferenceIdList = dataReferenceIdList;
        this.LinkedDataReferenceIdList = linkedDataReferenceIds;
        this.LinkType = linkType;
        this.LinkEntityAttributes = linkEntityAttributes;
        this.LinkedEntityAttributes = linkedEntityAttributes || [
            ServerConstants.PropertyName.EntityLinkCount,
        ];
        this.UpdatedEntityIncludedAttributesFilter =
            updatedEntityAttributes || [
                ServerConstants.PropertyName.EntityLinkCount,
                ServerConstants.PropertyName.SoftwareTotalLinkCount,
                ServerConstants.PropertyName.ImplementationLinkCount,
            ];
        this.UpdatedEntityIncludeObjectValues = true;
        this.IsLogicalReplacementConfirmation = true;
    }
}

export interface IUpdateLinksResult {
    IsErrorWouldCreateCycle: boolean;
    IsErrorWouldBreakUniquenessRule: boolean;

    errorDetailsKey: string;
    ErrorDetails: string;
}

@inheritSerialization(BaseServiceResult)
export class AddLinkedEntitiesResult
    extends BaseServiceResult
    implements IUpdateLinksResult
{
    @deserialize IsErrorMissingLinkEntityAttributes!: boolean;
    @deserialize IsErrorAlreadyExistingLink!: boolean;
    @deserialize IsErrorForbiddenLinkType!: boolean;
    @deserialize IsErrorCantCreateLinkToHimself!: boolean;
    @deserialize IsErrorWouldCreateCycle!: boolean;
    @deserialize IsReplacingExistingLogicalLinks!: boolean;
    @deserialize IsErrorWouldBreakUniquenessRule!: boolean;

    @deserialize SourceTargetsDictionary!: Map<string, string[]>;
    @deserialize RemovedLinkedEntitiesBySourceDictionary!: Map<
        HierarchicalData,
        HierarchicalData[]
    >;
    @deserializeAs(LinkEntityData) public Links!: LinkEntityData[];
    @deserializeAs(EntityItem) public UpdatedEntities!: EntityItem[];
    @deserializeAs(EntityLinkItem)
    public ReplacedLogicalLinks!: EntityLinkItem[];

    public static OnDeserialized(instance: AddLinkedEntitiesResult, json: any) {
        const sourceTargetsDictionary: Map<string, string[]> = new Map<
            string,
            string[]
        >();
        for (const key in json.SourceTargetsDictionary) {
            const value = json.SourceTargetsDictionary[key];
            sourceTargetsDictionary.set(key, value);
        }
        sourceTargetsDictionary.delete('$type');
        instance.SourceTargetsDictionary = sourceTargetsDictionary;

        const removedLinkedEntitiesBySourceDictionary: Map<
            HierarchicalData,
            HierarchicalData[]
        > = new Map<HierarchicalData, HierarchicalData[]>();
        if (json.RemovedLinkedEntitiesBySourceDictionary) {
            json.RemovedLinkedEntitiesBySourceDictionary.forEach(
                (value: any) => {
                    removedLinkedEntitiesBySourceDictionary.set(
                        value['Key'],
                        value['Value'],
                    );
                },
            );
        }
        instance.RemovedLinkedEntitiesBySourceDictionary =
            removedLinkedEntitiesBySourceDictionary;
    }
}

@inheritSerialization(BaseServiceParameter)
export class DeleteLinkedEntitiesParameter extends BaseServiceParameter {
    @serialize public DataReferenceIdList: string[];
    @serialize public LinkedDataReferenceIdList: string[];
    @serialize public UpdatedEntityIncludeObjectValues: boolean;
    @serialize public UpdatedEntityIncludedAttributesFilter?: string[];

    @autoserializeAs(ObjectLinkType) public LinkType: ObjectLinkType;
    @serialize public IsClearValue = false;

    public static createModern(
        dataReferenceIds: string[],
        linkType: ObjectLinkType,
        linkedIds: string[],
        isClearValue = false,
        includedAttributes: string[] | null = null,
    ): DeleteLinkedEntitiesParameter {
        const parameter = new DeleteLinkedEntitiesParameter(
            dataReferenceIds,
            linkedIds,
            linkType,
        );
        parameter.UpdatedEntityIncludedAttributesFilter =
            includedAttributes || [
                ServerConstants.PropertyName.EntityLinkCount,
                ServerConstants.PropertyName.SoftwareTotalLinkCount,
                ServerConstants.PropertyName.ImplementationLinkCount,
            ];
        parameter.IsClearValue = isClearValue;
        return parameter;
    }

    constructor(
        dataReferenceIds: string[],
        linkedDataReferenceIds: string[],
        linkType: ObjectLinkType,
    ) {
        super();
        this.DataReferenceIdList = dataReferenceIds;
        this.LinkedDataReferenceIdList = linkedDataReferenceIds;
        this.LinkType = linkType;
        this.UpdatedEntityIncludeObjectValues = true;
    }
}

@inheritSerialization(BaseServiceResult)
export class DeleteLinkedEntitiesResult
    extends BaseServiceResult
    implements IUpdateLinksResult
{
    @deserialize public DeletedEntityIds!: string[];
    @deserializeAs(EntityItem) public UpdatedEntities!: EntityItem[];
    @deserialize public IsErrorUnknownDataTypeName!: boolean;
    @deserialize public IsErrorUnknownDataReferenceIds!: boolean;
    @deserialize public IsErrorUnknownLinkAttributeName!: boolean;
    @deserialize public IsErrorUnknownLinkedEntityTypeName!: boolean;
    @deserialize public IsErrorWouldBreakUniquenessRule!: boolean;
    @deserialize public IsErrorWouldCreateCycle!: boolean;
    @deserialize public SourceTargetsDictionary!: Map<string, string[]>;

    static OnDeserialized(instance: DeleteLinkedEntitiesResult, json: any) {
        const sourceTargetsDictionary: Map<string, string[]> = new Map<
            string,
            string[]
        >();
        for (const key in json.SourceTargetsDictionary) {
            const value = json.SourceTargetsDictionary[key];
            sourceTargetsDictionary.set(key, value);
        }
        sourceTargetsDictionary.delete('$type');
        instance.SourceTargetsDictionary = sourceTargetsDictionary;
    }
}

@inheritSerialization(BaseServiceResult)
export class BaseMultiEntitiesLinksParameter extends BaseServiceParameter {
    @serialize public DataReferenceId?: string;
    @serialize public LinkedDataReferenceId?: string;
    @serialize public LinkedDataReferenceIdList?: string[];
}

@inheritSerialization(BaseMultiEntitiesLinksParameter)
export class GetAvailableLinkTypesParameter extends BaseMultiEntitiesLinksParameter {
    @serialize public SourceEntityType: EntityType;
    @serializeAs(EntityType) public TargetEntityTypes: any[];

    constructor(
        sourceEntityType: EntityType,
        targetEntityTypes: EntityType[],
        linkedDataReferenceIdList: string[],
    ) {
        super();
        this.SourceEntityType = sourceEntityType;
        this.TargetEntityTypes = targetEntityTypes.map((c) => EntityType[c]);
        this.LinkedDataReferenceIdList = linkedDataReferenceIdList;
    }
}

@inheritSerialization(BaseServiceResult)
export class GetAvailableLinkTypesResult extends BaseServiceResult {
    @deserializeAs(TypeLinkDataInfo)
    public AvailableLinkTypes!: TypeLinkDataInfo[];
}

export interface GetPropertyGoldenLinksQuery {
    SourceGuids: string[];
}
