import {
    ChangeDetectionStrategy,
    Component,
    HostBinding,
    input,
} from '@angular/core';
import { fadeInFadeOutAnimation } from '@datagalaxy/ui/animation';

@Component({
    selector: 'dxy-tooltip',
    standalone: true,
    imports: [],
    templateUrl: './tooltip.component.html',
    styleUrl: './tooltip.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [fadeInFadeOutAnimation('100ms')],
})
export class TooltipComponent {
    text = input.required<string>();

    @HostBinding('@fadeInFadeOut') animation = true;
}
