import { Component } from '@angular/core';
import { BaseCellComponent } from '@datagalaxy/core-ui/cell-components';
import { ViewTypeService } from '../../../services/viewType.service';
import { AttributeDataService } from '../../../shared/attribute/attribute-data.service';
import { EntityUiService } from '../../../shared/entity/services/entity-ui.service';
import { HierarchicalData } from '@datagalaxy/dg-object-model';
import { IEntityNavLinkCellParams } from '../nav-link.types';
import { EntityTypeUtils } from '@datagalaxy/webclient/entity/utils';
import { EllipsisTooltipDirective } from '@datagalaxy/ui/tooltip';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';
import { NgIf, NgClass } from '@angular/common';

/**
 * ## Role
 * Link to open an entity preview panel
 */
@Component({
    selector: 'app-entity-nav-link',
    templateUrl: './entity-nav-link.component.html',
    styleUrls: ['../nav-link.scss'],
    standalone: true,
    imports: [NgIf, NgClass, MatLegacyTooltipModule, EllipsisTooltipDirective],
})
export class EntityNavLinkComponent extends BaseCellComponent<
    unknown,
    HierarchicalData,
    IEntityNavLinkCellParams<HierarchicalData>
> {
    constructor(
        private viewTypeService: ViewTypeService,
        private entityUiService: EntityUiService,
        private attributeDataService: AttributeDataService,
    ) {
        super();
    }

    protected iconGlyphClass?: string;
    protected iconTooltip?: string;
    protected technoImgUrl?: string;
    protected technoTooltip?: string;

    protected get displayName() {
        return this.viewTypeService.getTechnicalOrDisplayName(this.value) ?? '';
    }
    public get hasIcon() {
        return this.iconGlyphClass || this.technoImgUrl;
    }

    protected override updateBindings() {
        const technologyCode = this.value.TechnologyCode;
        this.iconGlyphClass = EntityTypeUtils.getColoredGlyphClass(
            this.value.entityType,
        );
        this.iconTooltip = this.attributeDataService.getEntityTypeTranslation(
            this.value.EntityType,
        );
        if (!technologyCode) {
            return;
        }

        const displaySettings =
            this.entityUiService.getUserEntityCardDisplaySettings(
                technologyCode,
            );
        const isGlyphDisplay = displaySettings.isGlyphDisplay;
        if (isGlyphDisplay) {
            return;
        }
        this.iconGlyphClass = null;
        this.technoImgUrl = displaySettings.technoImgUrl;
        this.technoTooltip = displaySettings.technoTooltip;
    }

    public async openEntityPreview() {
        const hd = this.value;
        if (this.params?.disableNavigation) {
            return;
        }
        this.entityUiService.openPreviewOrDetailsOrDefault({ HddData: hd });
    }
}
