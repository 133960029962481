<form class="attribute-form-container">
    <dxy-field-text
        name="teamName"
        [labelKey]="getTranslateKey('TeamName')"
        [(ngModel)]="teamName.currentValue"
        (focus)="teamName.onFocus()"
        (blur)="teamName.onBlur($event)"
        (keypress.enter)="teamName.onEnterKey()"
        [required]="true"
        [readonly]="!hasEditRights"
    ></dxy-field-text>
    <dxy-unitary-field-actions [input]="teamName"></dxy-unitary-field-actions>

    <dxy-field-select
        name="accessType"
        [labelKey]="getTranslateKey('AccessType')"
        [adapter]="teamAccessAdapter"
        [hint]="currentAccessHint"
        [mandatory]="true"
        [useRichLayout]="true"
        [readonly]="!hasEditRights"
    ></dxy-field-select>

    <dxy-rich-text-field
        name="description"
        [labelKey]="getTranslateKey('Description')"
        [(ngModel)]="teamDescription.currentValue"
        (focus)="teamDescription.onFocus()"
        (blur)="teamDescription.onBlur($event)"
        (keypress.enter)="teamDescription.onEnterKey()"
        [noTabCapture]="true"
        toolbarDisplayMode="focus"
        [mentionResolvers]="mentionResolvers"
        [readonly]="!hasEditRights"
    ></dxy-rich-text-field>
    <dxy-unitary-field-actions
        [input]="teamDescription"
    ></dxy-unitary-field-actions>

    <dxy-field-email
        name="email"
        [labelKey]="getTranslateKey('Email')"
        [(ngModel)]="teamEmail.currentValue"
        (ngModelChange)="onEmailChange()"
        (focus)="teamEmail.onFocus()"
        (blur)="teamEmail.onBlur($event)"
        (keypress.enter)="teamEmail.onEnterKey()"
        [checkEmailSyntax]="fieldEmailCheckSyntax"
        [readonly]="!hasEditRights"
    ></dxy-field-email>
    <dxy-unitary-field-actions [input]="teamEmail"></dxy-unitary-field-actions>
    <dxy-field-multi-select
        name="owners"
        [labelKey]="getTranslateKey('Owners')"
        [data]="teamOwnersData"
        [readonly]="!hasEditRights"
    ></dxy-field-multi-select>
</form>

<button
    *ngIf="isJoinBtnVisible"
    dxyButton
    (click)="onClickJoinTeamBtn()"
    [disabled]="teamData.CurrentUserHasMembershipRequest"
>
    <span [translate]="joinTranslateKey"></span>
</button>
