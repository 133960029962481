import { Injectable } from '@angular/core';
import {
    GetObjectUsersAccessResult,
    ObjectSecurityApiService,
} from '@datagalaxy/webclient/security/data-access';
import { UserService } from './user.service';
import { getLocalId } from '@datagalaxy/webclient/utils';
import { DgModule } from '@datagalaxy/shared/dg-module/domain';

@Injectable({ providedIn: 'root' })
export class ObjectSecurityService {
    constructor(
        private objectSecurityApiService: ObjectSecurityApiService,
        private userService: UserService,
    ) {}

    public async getObjectUsersAccess(
        referenceId: string,
        versionId: string,
        module: DgModule,
    ) {
        const userAccesses =
            await this.objectSecurityApiService.getObjectUsersAccess(
                referenceId,
                versionId,
                module,
            );
        return this.makeUserListFromAccesses(userAccesses);
    }

    public async getWorkspaceUsersAccess(workspaceId: string) {
        const localId = getLocalId(workspaceId);
        const userAccesses =
            await this.objectSecurityApiService.getSpaceUsersAccess(localId);
        return this.makeUserListFromAccesses(userAccesses);
    }

    private makeUserListFromAccesses(userAccesses: GetObjectUsersAccessResult) {
        const userIdsWithAccess = userAccesses.UsersAccess.filter(
            (u) => u?.UserId,
        ).map((u) => u.UserId);
        return this.userService
            .getUserList()
            .filter(
                (user) =>
                    user.UserId && userIdsWithAccess.includes(user.UserId),
            );
    }
}
