@if (generationStatus() === 'generated') {
    <button
        dxyIconButton
        dxyDropdownButton
        [dxyTooltip]="'Suggestions.AiGeneration.generated' | translate"
        variant="tertiary"
        color="ai"
        size="medium"
        dxyDropdownButton
        [dxyMenuTrigger]="menu"
        (click)="$event.stopPropagation()"
    >
        <i class="glyph-text-ai"></i>
    </button>
    <dxy-menu #menu>
        <dxy-menu-header color="ai" [title]="menuTitle()">
            {{ menuSubtitle() }}
        </dxy-menu-header>
        @if (!readonly()) {
            <button dxyMenuItem (click)="emitGenerateOnClick($event)">
                <i class="glyph-redo-ai"></i>
                {{ 'Suggestions.AiGeneration.regenerate' | translate }}
            </button>
        }
        <button dxyMenuItem (click)="report.emit()">
            <i class="glyph-jirabug"></i>
            {{ 'Suggestions.AiGeneration.report' | translate }}
        </button>
    </dxy-menu>
} @else if (!readonly()) {
    <button
        dxyIconButton
        [dxyTooltip]="generateButtonTooltip()"
        variant="tertiary"
        color="ai"
        size="medium"
        [disabled]="generationStatus() === 'generating'"
        (click)="emitGenerateOnClick($event)"
    >
        <i class="glyph-text-ai"></i>
    </button>
}
