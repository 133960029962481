import { deserialize, inheritSerialization } from 'cerialize';
import { UserDto } from '@datagalaxy/webclient/user/domain';

@inheritSerialization(UserDto)
export class SpaceGovernanceUserDto extends UserDto {
    @deserialize AttributeKey: string;
    @deserialize IsDefaultUser: boolean;
    @deserialize IsUsedInEntities?: boolean;

    constructor(
        referenceId: string,
        firstName: string,
        lastName: string,
        email: string,
        userId: string,
        isDefaultUser: boolean,
        attributeKey: string,
        imageHash: string,
        thumbnailHash: string,
        isUsedInEntities?: boolean,
    ) {
        super();
        this.ReferenceId = referenceId;
        this.Email = email;
        this.UserId = userId;
        this.IsDefaultUser = isDefaultUser;
        this.AttributeKey = attributeKey;
        this.FirstName = firstName;
        this.LastName = lastName;
        this.ProfileImageHash = imageHash;
        this.ProfileThumbnailHash = thumbnailHash;
        this.IsUsedInEntities = isUsedInEntities;
    }

    public get emailPrefix(): string {
        return this.Email?.split('@')[0] || '';
    }

    public get filterString(): string {
        return `${this.emailPrefix}${this.FullName}`;
    }
}
