import { CollectionsHelper } from '@datagalaxy/core-util';
import {
    ComparisonItem,
    ProjectVersion,
    ProjectVersionStatus,
    ProjectVersionStatusChange,
} from '@datagalaxy/webclient/versioning/data-access';
import { generateGuid } from '@datagalaxy/utils';

export class VersioningDashboardData {
    private _projectId: string;
    private _versionId: string;
    private _isVersioningEnabled: boolean;
    private _projectVersion: ProjectVersion = null;

    constructor(
        projectId: string,
        versionId: string,
        isVersioningEnabled: boolean,
    ) {
        this._projectId = projectId;
        this._versionId = versionId;
        this._isVersioningEnabled = isVersioningEnabled;
    }

    public getProjectId() {
        return this._projectId;
    }

    public getVersionId() {
        return this._versionId;
    }

    public isVersioningEnabled() {
        return this._isVersioningEnabled;
    }

    public getProjectVersion() {
        return this._projectVersion;
    }

    public setProjectVersion(projectVersion: ProjectVersion) {
        this._isVersioningEnabled = true;
        this._versionId = projectVersion.ProjectVersionId;
        this._projectVersion = projectVersion;
    }

    public setDefaultVersion(isDefault: boolean) {
        if (this.isVersioningEnabled()) {
            this._projectVersion.IsUserDefaultVersion = isDefault;
        }
        return null;
    }

    public getProjectVersionDescription() {
        if (this.isVersioningEnabled()) {
            return this._projectVersion.VersionDescription;
        }
        return null;
    }

    public isVersionByDefault() {
        if (this.isVersioningEnabled()) {
            return this._projectVersion.IsUserDefaultVersion;
        }
        return null;
    }

    public getProjectVersionName() {
        if (this.isVersioningEnabled()) {
            return this._projectVersion.VersionName;
        }
        return null;
    }

    public getProjectVersionCreationTime() {
        if (this.isVersioningEnabled()) {
            return this._projectVersion.VersionCreationTime;
        }
        return null;
    }

    public getProjectVersionCreationUserId() {
        if (this.isVersioningEnabled()) {
            return this._projectVersion.VersionCreationUserId;
        }
        return null;
    }

    public getProjectVersionSourceVersionName() {
        if (this.isVersioningEnabled()) {
            return this._projectVersion.SourceVersionName;
        }
        return null;
    }
}

export class VersioningNavigationData {
    private readonly _projectVersionsMap: Map<string, ProjectVersion>;

    constructor() {
        this._projectVersionsMap = new Map<string, ProjectVersion>();
    }

    public updateVersions(
        projectVersions: ProjectVersion[],
        withClear: boolean,
    ) {
        if (withClear) {
            this._projectVersionsMap.clear();
        }
        projectVersions.forEach((version) => this.updateVersion(version));
    }

    public updateVersion(projectVersion: ProjectVersion) {
        this._projectVersionsMap.set(
            projectVersion.ProjectVersionId,
            projectVersion,
        );
    }

    public getVersionById(versionId: string) {
        return this._projectVersionsMap.get(versionId);
    }

    public getOfficialVersion() {
        return (
            this.getVersionsByStatus(ProjectVersionStatus.Official)[0] || null
        );
    }

    public hasOfficialVersion() {
        return !!this.getOfficialVersion();
    }

    public hasActiveVersions() {
        return this.getActiveVersions().length > 0;
    }

    public getActiveVersions() {
        return this.getVersionsByStatus(ProjectVersionStatus.Active);
    }

    public hasCandidateVersions() {
        return this.getCandidateVersions().length > 0;
    }

    public getCandidateVersions() {
        return this.getVersionsByStatus(ProjectVersionStatus.ReleaseCandidate);
    }

    public getArchivedVersions() {
        return this.getVersionsByStatus(ProjectVersionStatus.Archived);
    }

    private getVersionsByStatus(projectVersionStatus: ProjectVersionStatus) {
        return CollectionsHelper.filterMap(
            this._projectVersionsMap,
            (version) => version.VersionStatus === projectVersionStatus,
        );
    }
}

export class VersionCompareModuleData {
    public clientUid: string;

    constructor(
        public ModuleName: string,
        public Items: ComparisonItem[],
    ) {
        this.clientUid = generateGuid();
    }
}

export class VersioningStatusGraphData {
    private _projectId: string;
    private _versionId: string;
    private _currentStatus: ProjectVersionStatus;
    private _statusChangeMap: Map<string, ProjectVersionStatusChange>;

    constructor(projectId: string, versionId: string) {
        this._projectId = projectId;
        this._versionId = versionId;
        this._currentStatus = null;
        this._statusChangeMap = new Map<string, ProjectVersionStatusChange>();
    }

    public getProjectId() {
        return this._projectId;
    }

    public getVersionId() {
        return this._versionId;
    }

    public setCurrentStatus(newStatus: ProjectVersionStatus) {
        this._currentStatus = newStatus;
    }

    public setStatusChangeList(
        newStatusChangeList: ProjectVersionStatusChange[],
    ) {
        newStatusChangeList.forEach((status) =>
            this._statusChangeMap.set(
                ProjectVersionStatus[status.Status],
                status,
            ),
        );
    }

    public isCurrentVersionStatus(versionStatus: ProjectVersionStatus) {
        return this._currentStatus === versionStatus;
    }

    private hasVersionStatusChange(versionStatus: ProjectVersionStatus) {
        return this._statusChangeMap.has(ProjectVersionStatus[versionStatus]);
    }

    public getChangeVersionStatusDate(versionStatus: ProjectVersionStatus) {
        if (this.hasVersionStatusChange(versionStatus)) {
            return this._statusChangeMap.get(
                ProjectVersionStatus[versionStatus],
            ).StatusChangeTime;
        } else {
            return null;
        }
    }

    public isStatusChangeAvailable(newStatus: ProjectVersionStatus) {
        return this._currentStatus < newStatus;
    }
}
