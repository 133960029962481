import {
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import {
    ComparatorItemClient,
    VersionCompareContext,
} from '../../entity/compare-entity.types';
import { ScreenService } from '../../../screens/screen.service';
import { IScreenData } from '../screens-layout.types';
import { ScreenCategory } from '@datagalaxy/webclient/screen/data-access';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';
import { AttributeMetaInfo } from '@datagalaxy/webclient/attribute/domain';
import { DxyScreenLayoutCategoryComponent } from '../dxy-screen-layout-category/dxy-screen-layout-category';
import { NgFor } from '@angular/common';

/**
 * ## Role
 * Display the list of screen layout categories configured for an entity item type
 */
@Component({
    selector: 'dxy-screen-layout',
    templateUrl: 'dxy-screen-layout.component.html',
    standalone: true,
    imports: [NgFor, DxyScreenLayoutCategoryComponent],
})
export class DxyScreenLayoutComponent
    extends DxyBaseComponent
    implements OnChanges, OnInit
{
    //#region IScreenLayout
    @Input() spaceId: string;
    @Input() entityData: EntityItem;
    @Input() entityAttributes: AttributeMetaInfo[];
    @Input() readOnly?: boolean;
    @Input() noNavLink?: boolean;
    //#endregion
    @Input() reloadOnSourceChange = true;
    @Input() isVersioningCompare: boolean;
    @Input() comparatorItems: Map<string, ComparatorItemClient>;
    @Input() versionCompareContext: VersionCompareContext;

    public displayedCategories: ScreenCategory[];
    public screenData?: IScreenData;

    constructor(private screenService: ScreenService) {
        super();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.reloadOnSourceChange) {
            super.onChanges(changes, ['entityData', 'spaceId'], () =>
                this.updateCategories(),
            );
            super.onChanges(
                changes,
                ['entityAttributes', 'readOnly', 'noNavLink'],
                () => this.updateScreenData(),
            );
        }
    }

    ngOnInit() {
        this.updateCategories();
    }

    private updateCategories() {
        const screenDto = this.screenService.getSpaceScreenForEntity(
            this.entityData,
            this.isVersioningCompare,
        );
        if (!screenDto) {
            return;
        }

        const screenLayout = this.isVersioningCompare
            ? this.screenService.resolveScreenLayoutWithAttribute(
                  screenDto,
                  this.comparatorItems,
              )
            : this.screenService.resolveScreenLayout(screenDto);

        this.displayedCategories =
            screenLayout?.Categories.filter(
                (c) => !c.IsHidden && !c.IsHeader,
            ) ?? [];
        this.updateScreenData();
    }

    private updateScreenData() {
        this.screenData = {
            entityAttributes: this.entityAttributes,
            entityData: this.entityData,
            readOnly: this.readOnly,
            spaceId: this.spaceId,
            noNavLink: this.noNavLink,
        };
    }
}
