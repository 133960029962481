import { Component } from '@angular/core';
import { BaseCellComponent } from '@datagalaxy/core-ui/cell-components';
import { INavLinkCellParams } from '../nav-link.types';
import { INotificationButtonParams } from './INotificationButtonParams';
import { TranslateModule } from '@ngx-translate/core';
import { EllipsisTooltipDirective } from '@datagalaxy/ui/tooltip';
import { NgIf, NgClass } from '@angular/common';
import { DxyButtonDirective } from '@datagalaxy/ui/buttons';

/**
 * ## Role
 * Button inside a notification to execute any action
 */
@Component({
    selector: 'app-notification-button',
    templateUrl: './notification-button.component.html',
    styleUrls: ['./notification-button.component.scss', '../nav-link.scss'],
    standalone: true,
    imports: [
        NgIf,
        NgClass,
        EllipsisTooltipDirective,
        TranslateModule,
        DxyButtonDirective,
    ],
})
export class NotificationButtonComponent extends BaseCellComponent<
    unknown,
    INotificationButtonParams,
    INavLinkCellParams<INotificationButtonParams>
> {
    constructor() {
        super();
    }

    protected iconGlyphClass?: string;
    protected buttonTextKey?: string;

    protected get hasIcon() {
        return !!this.iconGlyphClass;
    }

    protected override updateBindings() {
        this.iconGlyphClass = this.value.iconGlyphClass;
        this.buttonTextKey = this.value.buttonTextKey;
    }

    public onClick(event: MouseEvent) {
        event.stopPropagation();
        this.value?.callback?.();
    }
}
