import * as _ from 'lodash';

export class StringUtil {
    static isNullOrEmpty(text?: string) {
        return text == undefined || !text.length;
    }

    static isNullOrWhiteSpace(text: string) {
        return text == undefined || !text.length || !text.trim().length;
    }

    static removeDiacritics(text: string) {
        if (text == undefined) {
            return '';
        }
        return _.deburr(text);
    }

    /**
     * Returns the given string with accents removed and all lowercase.
     * If null or undefined then an empty string is returned.
     * @deprecated Use `StringUtils.normalize()` from `@datagalaxy/utils` instead
     */
    static normalizeForSearch(text: string) {
        return StringUtil.removeDiacritics(text).toLowerCase().trim();
    }

    /** Case and accent insensitive filtering of given objects.
     * If *objects* or *searchTerm* is empty, objects is returned as-is
     * If *wordStartsWith* is true, objects will be filtered on words start exact match */
    static filterSearched<T>(
        searchTerm: string,
        objects: T[],
        getText: (o: T) => string,
        wordStartsWith = false,
    ) {
        if (!objects?.length || StringUtil.isNullOrEmpty(searchTerm)) {
            return objects;
        }
        const normalizedSearchedTerm =
            StringUtil.normalizeForSearch(searchTerm);
        if (wordStartsWith) {
            return objects.filter((o) =>
                StringUtil.normalizeForSearch(getText(o))
                    .split(' ')
                    .some((word) => word.startsWith(normalizedSearchedTerm)),
            );
        }
        return objects.filter((o) =>
            StringUtil.normalizeForSearch(getText(o)).includes(
                normalizedSearchedTerm,
            ),
        );
    }

    /**
     * - For filtering an array, use *filterSearched* instead.
     * - For case and accent sensitive, when *contains* is not empty, prefer `text?.includes(contains)` instead. */
    static contains(
        text: string,
        contains: string,
        caseInsensitive = false,
        accentInsensitive = false,
    ) {
        if (
            StringUtil.isNullOrEmpty(text) ||
            StringUtil.isNullOrEmpty(contains)
        ) {
            return false;
        }
        if (accentInsensitive) {
            text = StringUtil.removeDiacritics(text);
            contains = StringUtil.removeDiacritics(contains);
        }
        if (caseInsensitive) {
            text = text.toUpperCase();
            contains = contains.toUpperCase();
        }
        return text.includes(contains);
    }
    static containsAny(
        text: string,
        contains: string[],
        caseInsensitive = false,
        accentInsensitive = false,
    ) {
        return (
            contains?.length > 0 &&
            contains.some((c) =>
                StringUtil.contains(
                    text,
                    c,
                    caseInsensitive,
                    accentInsensitive,
                ),
            )
        );
    }

    static startsWith(
        text: string,
        startsWith: string,
        caseInsensitive = false,
        accentInsensitive = false,
    ): boolean {
        if (
            StringUtil.isNullOrEmpty(text) ||
            StringUtil.isNullOrEmpty(startsWith)
        ) {
            return false;
        }
        if (accentInsensitive) {
            text = StringUtil.removeDiacritics(text);
            startsWith = StringUtil.removeDiacritics(startsWith);
        }
        if (caseInsensitive) {
            text = text.toUpperCase();
            startsWith = startsWith.toUpperCase();
        }
        return text.startsWith(startsWith);
    }

    static endsWith(
        text: string,
        endsWith: string,
        caseInsensitive = false,
        accentInsensitive = false,
    ) {
        if (
            StringUtil.isNullOrEmpty(text) ||
            StringUtil.isNullOrEmpty(endsWith) ||
            endsWith.length > text.length
        ) {
            return false;
        }
        if (accentInsensitive) {
            text = StringUtil.removeDiacritics(text);
            endsWith = StringUtil.removeDiacritics(endsWith);
        }
        if (caseInsensitive) {
            text = text.toUpperCase();
            endsWith = endsWith.toUpperCase();
        }
        return text.indexOf(endsWith) == text.length - endsWith.length;
    }

    static boldifyFoundText(
        text: string,
        searchedText: string,
        caseInsensitive = false,
        accentInsensitive = false,
        firstOccurrenceOnly = false,
    ) {
        if (
            StringUtil.isNullOrEmpty(text) ||
            StringUtil.isNullOrEmpty(searchedText)
        ) {
            return text;
        }
        let flags = '';
        flags += firstOccurrenceOnly ? '' : 'g';
        flags += caseInsensitive ? 'i' : '';
        const textWithoutDiac = accentInsensitive
            ? this.removeDiacritics(text)
            : text;
        const searchedTextWithoutDiac = accentInsensitive
            ? this.removeDiacritics(searchedText)
            : searchedText;

        const testReg = new RegExp(searchedTextWithoutDiac, flags);
        let match: RegExpExecArray;
        // Parse the 'no diacritics' text to find occurrences, get the match indexes, and boldify the original string occurrences to preserve diacritics
        while ((match = testReg.exec(textWithoutDiac)) != null) {
            const addedChars = text.length - textWithoutDiac.length;
            // Start index of match in the initial string => we need to update this index because we added chars to initial string
            const matchStartIndex = match.index + addedChars;
            const matchEndIndex =
                match.index + searchedText.length + addedChars;
            const originalOccurrence = text.substring(
                matchStartIndex,
                matchEndIndex,
            );
            text = StringUtil.replaceStringAtIndex(
                text,
                originalOccurrence.bold(),
                matchStartIndex,
                matchEndIndex,
            );
        }

        return text;
    }

    static replaceStringAtIndex(
        text: string,
        replace: string,
        startIndex: number,
        endIndex: number,
    ) {
        if (
            !text ||
            replace == null ||
            startIndex > endIndex ||
            startIndex < 0 ||
            endIndex > text.length
        ) {
            return text;
        }
        return (
            text.substring(0, startIndex) + replace + text.substring(endIndex)
        );
    }

    /** returns the part following the given prefix */
    static getSuffix(text: string, prefix: string) {
        return text && prefix && text.startsWith(prefix)
            ? text.substr(prefix.length)
            : undefined;
    }

    /** Returns a string where the end of the given string has been replaced by the new given suffix.
     * The optional *onReplaced* function is called only when *searchedSuffix* has been found. */
    static replaceSuffix(
        text: string,
        searchedSuffix: string,
        newSuffix: string,
        onReplaced?: (result: string) => void,
    ) {
        if (!text || !searchedSuffix) {
            return text;
        }
        const p = text.lastIndexOf(searchedSuffix);
        if (p == -1) {
            return text;
        }
        const result = text.substring(0, p) + (newSuffix ?? '');
        onReplaced?.(result);
        return result;
    }

    /** Returns a string where the end of the given string has been removed if it matches the given suffix */
    static removeSuffix(text: string, suffix: string) {
        if (!text || !suffix) {
            return text;
        }
        const p = text.lastIndexOf(suffix);
        if (p == -1) {
            return text;
        }
        return text.substring(0, p);
    }

    /** returns 'this-is-a-string' when given 'thisIsAString' */
    static toKebabCase(text: string) {
        return text
            ?.replace(/([a-z0-9]|(?=[A-Z]))([A-Z])/g, '$1-$2')
            .toLowerCase();
    }

    /** returns 'thisIsAString' when given 'this-is-a-string' */
    static toCamelCase(text: string) {
        return text?.replace(/-./g, (x) => x[1].toUpperCase());
    }

    /** returns 'this is a string' when given 'thisIsAString' */
    static toHumanCase(text: string) {
        return text
            ?.replace(/([a-z0-9]|(?=[A-Z]))([A-Z])/g, '$1 $2')
            .toLowerCase();
    }

    /** sets the first character in upper case */
    static capitalize(text: string) {
        return text?.length ? text[0].toUpperCase() + text.substring(1) : text;
    }

    /** replaces every &lt;br&gt;, &lt;br/&gt;, &amp;#13; by \n */
    public static replaceHtmlBR(text: string) {
        return text?.replace(/(&#13;)|(<br\s*\/?>)/gi, '\n');
    }

    /** returns true if the given string contains \n or \r, otherwise false */
    public static containsNewLine(text: string) {
        return /\n|\r/.test(text);
    }

    /** Examples:
     * - `123 => 123`
     * - `1234 => 1 234`
     * - `1234.56 => 1 234.56`
     * - `1234,56 => 1 234,56`
     * */
    public static formatNumberGroupDigits(nb: number | string) {
        if (nb == undefined) {
            return '';
        }

        const data = nb.toString();
        if (!data) {
            return '';
        }

        const separator = data.includes('.') ? '.' : ',';
        const parts = data.split(separator);
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
        return parts.length > 1 ? parts.join(separator) : parts[0];
    }

    /**
     * Format numbers with one decimal plus symbol name (ex: 1 000 000 => 1 M)
     * [Confluence](https://datagalaxy.atlassian.net/wiki/spaces/DG3/pages/1695154314/SPEC+Format+d+affichage#EXG-001.1)
     */
    public static formatNumber(value: number) {
        const absValue = Math.abs(value);
        const si = [
            { value: 1, symbol: '' },
            { value: 1e3, symbol: 'k' },
            { value: 1e6, symbol: 'M' },
            { value: 1e9, symbol: 'G' },
            { value: 1e12, symbol: 'T' },
            { value: 1e15, symbol: 'P' },
            { value: 1e18, symbol: 'E' },
        ];
        const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;

        let i: number;
        for (i = si.length - 1; i > 0; i--) {
            if (absValue >= si[i].value) {
                break;
            }
        }
        const minus = value < 0 ? '-' : '';
        const reducedValue = (absValue / si[i].value)
            .toFixed(1)
            .replace(rx, '$1');
        return `${minus}${reducedValue} ${si[i].symbol}`;
    }

    /**
     * Simple Hash Code computation logic, based on Java's implementation
     * Taken from: https://stackoverflow.com/questions/194846/is-there-any-kind-of-hash-code-function-in-javascript
     * 69 positive answers, seems good
     * */
    public static hashCode(source: string) {
        let hash = 0;
        if (!source) {
            return hash;
        }
        for (let i = 0; i < source.length; i++) {
            const character = source.charCodeAt(i);
            hash = (hash << 5) - hash + character;
            hash = hash & hash; // Convert to 32bit integer
        }
        return hash;
    }

    public static getRawTextFromHtmlString(htmlString: string) {
        return htmlString?.replace(/<[^>]+>/g, '');
    }
}
