<div class="modal-header">
    <h3 mat-dialog-title class="modal-title" translate>
        UI.TicketModal.form.title
    </h3>
    <button dxyIconButton color="transparent" (click)="onCloseCancel()">
        <i class="glyph-cancelsearch"></i>
    </button>
</div>
<form (ngSubmit)="onCloseSubmit()" [class.form-disabled]="isApiUnreachable">
    <div mat-dialog-content [class.modal-disable]="creationLoading">
        <dxy-field-select
            name="type"
            labelKey="UI.TicketModal.form.type"
            [adapter]="typeAdapter"
            [(ngModel)]="form.type"
            (ngModelChange)="updateCanCreate()"
            [mandatory]="true"
            (openClose)="onPanelOpenClose($event)"
            data-tracker-id="Supportmodal-type"
        ></dxy-field-select>

        <dxy-field-text
            name="subject"
            labelKey="UI.TicketModal.form.subject"
            [(ngModel)]="form.subject"
            (ngModelChange)="updateCanCreate()"
            [mandatory]="true"
        ></dxy-field-text>

        <dxy-rich-text-field
            name="description"
            labelKey="UI.TicketModal.form.description"
            [(ngModel)]="form.description"
            (ngModelChange)="updateCanCreate()"
            [mandatory]="true"
            [noTabCapture]="true"
            [hint]="getDescriptionPlaceholder(form.type) | translate"
            [class.field-with-hint]="isIncident"
            toolbarDisplayMode="focus"
            [mentionResolvers]="mentionResolvers"
            (onAcquireMentionData)="onPanelOpenClose($event)"
        ></dxy-rich-text-field>

        <dxy-field-file-upload
            name="files"
            labelKey="UI.Attribute.FileUpload.Attachment"
            [maxFileSize]="15728640"
            [(ngModel)]="files"
            (ngModelChange)="updateCanCreate()"
            [allowsMultiple]="true"
        ></dxy-field-file-upload>
    </div>
    <dxy-modal-footer
        [actionEnabled]="canCreate"
        [actionBtnLbl]="'UI.Dialog.NewItem.lblCreate' | translate"
        [actionLoading]="creationLoading"
        (onCloseCancel)="onCloseCancel()"
        [showCreateAnother]="true"
        [isCheckedCreateAnother]="isCheckedCreateAnother"
        (onChangeCreateAnother)="onChangeCreateAnother()"
    ></dxy-modal-footer>
</form>

<div *ngIf="isApiUnreachable" class="api-unreachable-panel">
    <span translate>UI.TicketModal.form.unavailable</span>
    <div>
        <span translate>UI.TicketModal.form.freshdeskAlternativePt1</span>
        <a
            href="https://datagalaxy.freshdesk.com/fr/support/tickets/new"
            target="_blank"
            translate
            >UI.TicketModal.form.freshdeskAlternativePt2</a
        >
    </div>
</div>
