import { NgModule, Optional, SkipSelf } from '@angular/core';
import {
    BACKEND_API_CONFIG,
    publicApiUrl,
    RestApiService,
    RestHttpClient,
} from '@datagalaxy/data-access';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
    providers: [
        RestHttpClient,
        {
            provide: 'RestDataQualityApiService',
            useFactory: (restHttpClient: RestHttpClient) => {
                return new RestApiService(
                    restHttpClient,
                    publicApiUrl,
                    'data-quality',
                );
            },
            deps: [RestHttpClient, BACKEND_API_CONFIG],
        },
    ],
    imports: [HttpClientModule],
})
export class DataQualityApiModule {
    constructor(@Optional() @SkipSelf() parentModule?: DataQualityApiModule) {
        if (parentModule) {
            throw new Error(
                'DataQualityApiModule is already loaded. Import it in the AppModule only',
            );
        }
    }
}
