//[from](https://gist.github.com/jose-mdz/4a8894c152383b9d7a870c24a04447e4)

import {
    IXY,
    IXYRect,
    IXYRectRO,
    IXYRO,
    Point,
    Vect2,
} from '@datagalaxy/core-2d-util';

/**
 * Orthogonal Connector Router
 *   - Given two rectangles and their connection points, returns the path for an orthogonal connector.
 *
 * https://jose.page
 * 2020
 */

type BasicCardinalPoint = 'n' | 'e' | 's' | 'w';
export type OrthoSide = 'top' | 'right' | 'bottom' | 'left';
type BendDirection = BasicCardinalPoint | 'unknown' | 'none';

/**
 * A size tuple
 */
interface Size {
    width: number;
    height: number;
}

/**
 * A line between two points
 */
interface Line {
    a: Point;
    b: Point;
    fixed?: boolean;
}

/**
 * Represents a Rectangle by location and size
 */
export interface OrthoRect extends Size {
    left: number;
    top: number;
}

/**
 * Represents a connection point on a routing request
 */
interface ConnectorPoint {
    shape: OrthoRect;
    side: OrthoSide;
    distance: number;
}

/**
 * Byproduct data emitted by the routing algorithm
 */
export interface OrthogonalConnectorByproduct {
    hRulers: number[];
    vRulers: number[];
    spots: Point[];
    grid: Rectangle[];
    connections: Line[];
    originA?: Point;
    originB?: Point;
    start?: Point;
    end?: Point;
    unwantedSpots?: Point[];
}

/**
 * Routing request data
 */
export interface OrthogonalConnectorOpts {
    pointA: ConnectorPoint;
    pointB: ConnectorPoint;
    shapeMargin?: number;
    globalBoundsMargin?: number;
    globalBounds?: OrthoRect;
}

/**
 * Utility Point creator
 * @param x
 * @param y
 */
function makePt(x: number, y: number): Point {
    return { x: x | 0, y: y | 0 };
}

/**
 * Computes distance between two points
 * @param a
 * @param b
 */
function distance(a: Point, b: Point): number {
    const dx = b.x - a.x,
        dy = b.y - a.y;
    return Math.sqrt(dx * dx + dy * dy);
}

/**
 * Abstracts a Rectangle and adds geometric utilities
 */
export class Rectangle {
    static get empty(): Rectangle {
        return new Rectangle(0, 0, 0, 0);
    }

    static fromRect(r: OrthoRect): Rectangle {
        return new Rectangle(r.left, r.top, r.width, r.height);
    }

    static truncateDecimals(r: Rectangle) {
        return new Rectangle(r.left | 0, r.top | 0, r.width | 0, r.height | 0);
    }

    static fromLTRB(
        left: number,
        top: number,
        right: number,
        bottom: number,
    ): Rectangle {
        return new Rectangle(left, top, right - left, bottom - top);
    }

    constructor(
        readonly left: number,
        readonly top: number,
        readonly width: number,
        readonly height: number,
    ) {}

    contains(p: Point, strict?: boolean): boolean {
        return strict
            ? p.x > this.left &&
                  p.x < this.right &&
                  p.y > this.top &&
                  p.y < this.bottom
            : p.x >= this.left &&
                  p.x <= this.right &&
                  p.y >= this.top &&
                  p.y <= this.bottom;
    }

    inflate(horizontal: number, vertical: number): Rectangle {
        return Rectangle.fromLTRB(
            this.left - horizontal,
            this.top - vertical,
            this.right + horizontal,
            this.bottom + vertical,
        );
    }

    intersects(rectangle: Rectangle): boolean {
        const thisX = this.left;
        const thisY = this.top;
        const thisW = this.width;
        const thisH = this.height;
        const rectX = rectangle.left;
        const rectY = rectangle.top;
        const rectW = rectangle.width;
        const rectH = rectangle.height;

        return (
            rectX < thisX + thisW &&
            thisX < rectX + rectW &&
            rectY < thisY + thisH &&
            thisY < rectY + rectH
        );
    }

    union(r: Rectangle): Rectangle {
        const x = [this.left, this.right, r.left, r.right];
        const y = [this.top, this.bottom, r.top, r.bottom];
        return Rectangle.fromLTRB(
            Math.min(...x),
            Math.min(...y),
            Math.max(...x),
            Math.max(...y),
        );
    }

    get center(): Point {
        return {
            x: this.left + this.width / 2,
            y: this.top + this.height / 2,
        };
    }

    get right(): number {
        return this.left + this.width;
    }

    get bottom(): number {
        return this.top + this.height;
    }

    get location(): Point {
        return makePt(this.left, this.top);
    }

    get northEast(): Point {
        return { x: this.right, y: this.top };
    }

    get southEast(): Point {
        return { x: this.right, y: this.bottom };
    }

    get southWest(): Point {
        return { x: this.left, y: this.bottom };
    }

    get northWest(): Point {
        return { x: this.left, y: this.top };
    }

    get east(): Point {
        return makePt(this.right, this.center.y);
    }

    get north(): Point {
        return makePt(this.center.x, this.top);
    }

    get south(): Point {
        return makePt(this.center.x, this.bottom);
    }

    get west(): Point {
        return makePt(this.left, this.center.y);
    }

    get size(): Size {
        return { width: this.width, height: this.height };
    }

    get toIXYRect(): IXYRect {
        return {
            x: this.left,
            y: this.top,
            width: this.width,
            height: this.height,
        };
    }
}

// Get interseting point of line segment & rectangle (if any)
function lineRectCollide([a, b]: [IXYRO, IXYRO], rect: IXYRectRO) {
    // p=line startpoint, p2=line endpoint
    const p = { x: a.x, y: a.y },
        p2 = { x: b.x, y: b.y };

    // top rect line
    let q = { x: rect.x, y: rect.y };
    let q2 = { x: rect.x + rect.width, y: rect.y };
    if (lineSegmentsCollide(p, p2, q, q2)) return true;
    // right rect line
    q = q2;
    q2 = { x: rect.x + rect.width, y: rect.y + rect.height };
    if (lineSegmentsCollide(p, p2, q, q2)) return true;
    // bottom rect line
    q = q2;
    q2 = { x: rect.x, y: rect.y + rect.height };
    if (lineSegmentsCollide(p, p2, q, q2)) return true;
    // left rect line
    q = q2;
    q2 = { x: rect.x, y: rect.y };
    if (lineSegmentsCollide(p, p2, q, q2)) return true;

    // not intersecting with any of the 4 rect sides
    return false;
}

// p0 & p1 form a 1rst segment, p2 & p3 form the 2nd segment
// Get interseting point of 2 line segments (if any)
// Attribution: http://paulbourke.net/geometry/pointlineplane/
function lineSegmentsCollide(p0: IXY, p1: IXY, p2: IXY, p3: IXY) {
    let unknownA =
        (p3.x - p2.x) * (p0.y - p2.y) - (p3.y - p2.y) * (p0.x - p2.x);
    let unknownB =
        (p1.x - p0.x) * (p0.y - p2.y) - (p1.y - p0.y) * (p0.x - p2.x);
    const denominator =
        (p3.y - p2.y) * (p1.x - p0.x) - (p3.x - p2.x) * (p1.y - p0.y);

    // Test if Coincident
    // If the denominator and numerator for the ua and ub are 0
    //    then the two lines are coincident.
    if (unknownA === 0 && unknownB === 0 && denominator == 0) return null;

    // Test if Parallel
    // If the denominator for the equations for ua and ub is 0
    //     then the two lines are parallel.
    if (denominator === 0) return null;

    // test if line segments are colliding
    unknownA /= denominator;
    unknownB /= denominator;
    const isIntersecting =
        unknownA >= 0 && unknownA <= 1 && unknownB >= 0 && unknownB <= 1;

    return isIntersecting;
}

/**
 * Represents a node in a graph, whose data is a Point
 */
class PointNode {
    public distance = Number.MAX_SAFE_INTEGER;
    public shortestPath: PointNode[] = [];
    public adjacentNodes: Map<PointNode, number> = new Map();
    constructor(public data: Point) {}
}

/***
 * Represents a Graph of Point nodes
 */
class PointGraph {
    private readonly index = new Map<number, Map<number, PointNode>>();

    add(p: Point) {
        const ys = this.index.get(p.x);
        if (ys == undefined) {
            const map = new Map<number, PointNode>();
            map.set(p.y, new PointNode(p));
            this.index.set(p.x, map);
        } else if (!ys.has(p.y)) {
            ys.set(p.y, new PointNode(p));
        }
    }

    private getLowestDistanceNode(unsettledNodes: Set<PointNode>): PointNode {
        let lowestDistanceNode: PointNode | null = null;
        let lowestDistance = Number.MAX_SAFE_INTEGER;
        for (const node of unsettledNodes) {
            const nodeDistance = node.distance;
            if (nodeDistance < lowestDistance) {
                lowestDistance = nodeDistance;
                lowestDistanceNode = node;
            }
        }
        return lowestDistanceNode;
    }

    calculateShortestPathFromSource(
        graph: PointGraph,
        source: PointNode,
        destination: PointNode,
        shouldFavorDirection = false,
    ): PointGraph {
        const settledNodes: Set<PointNode> = new Set(),
            unsettledNodes: Set<PointNode> = new Set(),
            referenceDistance = distance(destination.data, source.data),
            mainAxis = shouldFavorDirection
                ? source.data.x === destination.data.x
                    ? 'y'
                    : source.data.y === destination.data.y
                      ? 'x'
                      : null
                : null,
            crossAxis: keyof IXY =
                mainAxis === 'x' ? 'y' : mainAxis === 'y' ? 'x' : null;

        let favorStartPassDone = crossAxis === null;

        source.distance = 0;
        unsettledNodes.add(source);

        while (unsettledNodes.size != 0) {
            const currentNode = this.getLowestDistanceNode(unsettledNodes);
            unsettledNodes.delete(currentNode);

            for (const [
                adjacentNode,
                edgeWeight,
            ] of currentNode.adjacentNodes) {
                if (settledNodes.has(adjacentNode)) continue;

                const edgeWeightUpdated =
                    (edgeWeight *
                        distance(destination.data, adjacentNode.data)) /
                    referenceDistance;
                this.calculateMinimumDistance(
                    adjacentNode,
                    edgeWeightUpdated,
                    currentNode,
                );
                unsettledNodes.add(adjacentNode);
            }

            // Favor adjacent west/bottom node when source and destination share same coordinates on either x or y
            if (!favorStartPassDone && currentNode !== source) {
                favorStartPassDone = true;
                const nodeValue = currentNode.data[crossAxis];
                currentNode.adjacentNodes.forEach((_, candidateNode) => {
                    if (
                        candidateNode.data[mainAxis] ===
                        currentNode.data[mainAxis]
                    ) {
                        const nextNodeValue = candidateNode.data[crossAxis];
                        const positiveDirection =
                            source.data[mainAxis] - currentNode.data[mainAxis] <
                            0;
                        candidateNode.distance *=
                            crossAxis === 'x'
                                ? positiveDirection
                                    ? nextNodeValue < nodeValue
                                        ? 1
                                        : 100
                                    : nextNodeValue < nodeValue
                                      ? 100
                                      : 1
                                : positiveDirection
                                  ? nextNodeValue < nodeValue
                                      ? 100
                                      : 1
                                  : nextNodeValue < nodeValue
                                    ? 1
                                    : 100;
                    }
                });
            }

            settledNodes.add(currentNode);

            if (currentNode === destination && destination.shortestPath.length)
                destination.shortestPath.push(destination);
        }

        return graph;
    }

    private calculateMinimumDistance(
        evaluationNode: PointNode,
        edgeWeight: number,
        sourceNode: PointNode,
    ) {
        const sourceDistance = sourceNode.distance;

        if (sourceDistance + edgeWeight < evaluationNode.distance) {
            evaluationNode.distance = sourceDistance + edgeWeight;
            const shortestPath: PointNode[] = [...sourceNode.shortestPath];
            shortestPath.push(sourceNode);
            evaluationNode.shortestPath = shortestPath;
        }
    }

    connect(a: Point, b: Point, dist?: number) {
        const nodeA = this.get(a);
        const nodeB = this.get(b);

        if (!nodeA || !nodeB) {
            throw new Error(`A point was not found`);
        }

        nodeA.adjacentNodes.set(nodeB, dist ?? distance(a, b));
    }

    has(p: Point): boolean {
        return this.index.get(p.x)?.has(p.y) ?? false;
    }

    get(p: Point): PointNode | null {
        return this.index.get(p.x)?.get(p.y) ?? null;
    }

    getAllPoints() {
        const res: Point[] = [];
        this.index.forEach((ys, x) => ys.forEach((_, y) => res.push({ x, y })));
        return res;
    }
    getAllNodes() {
        const res: { point: Point; node: PointNode }[] = [];
        this.index.forEach((ys, x) =>
            ys.forEach((node, y) => res.push({ point: { x, y }, node })),
        );
        return res;
    }
}

/**
 * Gets the actual point of the connector based on the distance parameter
 * @param p
 */
export function computePt(p: ConnectorPoint): Point {
    const b = Rectangle.truncateDecimals(Rectangle.fromRect(p.shape));
    switch (p.side) {
        case 'bottom':
            return makePt(b.left + b.width * p.distance, b.bottom);
        case 'top':
            return makePt(b.left + b.width * p.distance, b.top);
        case 'left':
            return makePt(b.left, b.top + b.height * p.distance);
        case 'right':
            return makePt(b.right, b.top + b.height * p.distance);
    }
}

/**
 * Extrudes the connector point by margin depending on it's side
 * @param cp
 * @param margin
 */
function extrudeCp(cp: ConnectorPoint, margin: number): Point {
    const { x, y } = computePt(cp);
    switch (cp.side) {
        case 'top':
            return makePt(x, y - margin);
        case 'right':
            return makePt(x + margin, y);
        case 'bottom':
            return makePt(x, y + margin);
        case 'left':
            return makePt(x - margin, y);
    }
}

/**
 * Returns flag indicating if the side belongs on a vertical axis
 * @param side
 */
function isVerticalSide(side: OrthoSide): boolean {
    return side == 'top' || side == 'bottom';
}

/**
 * Creates a grid of rectangles from the specified set of rulers, contained on the specified bounds
 * @param verticals
 * @param horizontals
 * @param bounds
 */
function rulersToGrid(
    verticals: number[],
    horizontals: number[],
    bounds: Rectangle,
): Grid {
    const result: Grid = new Grid();

    verticals.sort((a, b) => a - b);
    horizontals.sort((a, b) => a - b);

    let lastX = bounds.left;
    let lastY = bounds.top;
    let column = 0;
    let row = 0;

    for (const y of horizontals) {
        for (const x of verticals) {
            result.set(row, column++, Rectangle.fromLTRB(lastX, lastY, x, y));
            lastX = x;
        }

        // Last cell of the row
        result.set(
            row,
            column,
            Rectangle.fromLTRB(lastX, lastY, bounds.right, y),
        );
        lastX = bounds.left;
        lastY = y;
        column = 0;
        row++;
    }

    lastX = bounds.left;

    // Last fow of cells
    for (const x of verticals) {
        result.set(
            row,
            column++,
            Rectangle.fromLTRB(lastX, lastY, x, bounds.bottom),
        );
        lastX = x;
    }

    // Last cell of last row
    result.set(
        row,
        column,
        Rectangle.fromLTRB(lastX, lastY, bounds.right, bounds.bottom),
    );

    return result;
}

/**
 * Returns an array without repeated points
 * @param points
 */
function reducePoints(points: Point[]): Point[] {
    const result: Point[] = [];
    const map = new Map<number, number[]>();

    points.forEach((p) => {
        const { x, y } = p;
        const arr = map.get(y) || map.set(y, []).get(y);
        if (!arr.includes(x)) {
            arr.push(x);
        }
    });

    for (const [y, xs] of map) {
        for (const x of xs) {
            result.push(makePt(x, y));
        }
    }

    return result;
}

/**
 * Returns a set of spots generated from the grid, avoiding colliding spots with specified obstacles
 * @param grid
 * @param obstacles
 */
function gridToSpots(
    grid: Grid,
    isUnwantedSpot: (p: Point) => boolean,
): Point[] {
    const gridPoints: Point[] = [];

    for (const [row, data] of grid.data) {
        const firstRow = row == 0;
        const lastRow = row == grid.rows - 1;

        for (const [col, r] of data) {
            const firstCol = col == 0;
            const lastCol = col == grid.columns - 1;
            const nw = firstCol && firstRow;
            const ne = firstRow && lastCol;
            const se = lastRow && lastCol;
            const sw = lastRow && firstCol;

            if (nw || ne || se || sw) {
                gridPoints.push(
                    r.northWest,
                    r.northEast,
                    r.southWest,
                    r.southEast,
                );
            } else if (firstRow) {
                gridPoints.push(r.northWest, r.north, r.northEast);
            } else if (lastRow) {
                gridPoints.push(r.southEast, r.south, r.southWest);
            } else if (firstCol) {
                gridPoints.push(r.northWest, r.west, r.southWest);
            } else if (lastCol) {
                gridPoints.push(r.northEast, r.east, r.southEast);
            } else {
                gridPoints.push(
                    r.northWest,
                    r.north,
                    r.northEast,
                    r.east,
                    r.southEast,
                    r.south,
                    r.southWest,
                    r.west,
                    r.center,
                );
            }
        }
    }

    // for(const r of grid) {
    //     gridPoints.push(
    //         r.northWest, r.north, r.northEast, r.east,
    //         r.southEast, r.south, r.southWest, r.west, r.center);
    // }

    // Reduce repeated points and filter out (those who touch shapes)
    return reducePoints(gridPoints).filter((p) => !isUnwantedSpot(p));
}

/**
 * Creates a graph connecting the specified points orthogonally
 * @param spots
 */
function createGraph(spots: Point[]): {
    graph: PointGraph;
    connections: Line[];
} {
    const hotXs: number[] = [];
    const hotYs: number[] = [];
    const graph = new PointGraph();
    const connections: Line[] = [];

    spots.forEach((p) => {
        const { x, y } = p;
        if (!hotXs.includes(x)) hotXs.push(x);
        if (!hotYs.includes(y)) hotYs.push(y);
        graph.add(p);
    });

    hotXs.sort((a, b) => a - b);
    hotYs.sort((a, b) => a - b);

    const connect = (a: Point, b: Point) => {
        graph.connect(a, b);
        graph.connect(b, a);
        connections.push({ a, b });
    };

    const ny = hotYs.length,
        nx = hotXs.length;
    for (let i = 0; i < ny; i++) {
        for (let j = 0; j < nx; j++) {
            const b = makePt(hotXs[j], hotYs[i]);
            if (!graph.has(b)) continue;

            if (j > 0) {
                const a = makePt(hotXs[j - 1], hotYs[i]);
                if (graph.has(a)) {
                    connect(a, b);
                }
            }

            if (i > 0) {
                const a = makePt(hotXs[j], hotYs[i - 1]);
                if (graph.has(a)) {
                    connect(a, b);
                }
            }
        }
    }

    return { graph, connections };
}

/**
 * Solves the shortest path for the origin-destination path of the graph
 * @param graph
 * @param origin
 * @param destination
 */
function shortestPath(
    graph: PointGraph,
    origin: Point,
    destination: Point,
    obstaclesMidPoint: Point,
): Point[] {
    const originNode = graph.get(origin);
    const destinationNode = graph.get(destination);

    if (!originNode) {
        throw new Error(`Origin node {${origin.x},${origin.y}} not found`);
    }

    if (!destinationNode) {
        throw new Error(`Origin node {${origin.x},${origin.y}} not found`);
    }

    const shouldFavorDirection =
        (obstaclesMidPoint.x === origin.x && origin.x === destination.x) ||
        (obstaclesMidPoint.y === origin.y && origin.y === destination.y);

    graph.calculateShortestPathFromSource(
        graph,
        originNode,
        destinationNode,
        shouldFavorDirection,
    );

    return destinationNode.shortestPath.map((n) => n.data);
}

/**
 * Given two segments represented by 3 points,
 * determines if the second segment bends on an orthogonal direction or not, and which.
 *
 * @param a
 * @param b
 * @param c
 * @return Bend direction, unknown if not orthogonal or 'none' if straight line
 */
function getBend(a: Point, b: Point, c: Point): BendDirection {
    const equalX = a.x === b.x && b.x === c.x;
    const equalY = a.y === b.y && b.y === c.y;
    const segment1Horizontal = a.y === b.y;
    const segment1Vertical = a.x === b.x;
    const segment2Horizontal = b.y === c.y;
    const segment2Vertical = b.x === c.x;

    if (equalX || equalY) {
        return 'none';
    }

    if (
        !(segment1Vertical || segment1Horizontal) ||
        !(segment2Vertical || segment2Horizontal)
    ) {
        return 'unknown';
    }

    if (segment1Horizontal && segment2Vertical) {
        return c.y > b.y ? 's' : 'n';
    } else if (segment1Vertical && segment2Horizontal) {
        return c.x > b.x ? 'e' : 'w';
    }

    throw new Error('Nope');
}

/**
 * Simplifies the path by removing unnecessary points, based on orthogonal pathways
 * @param points
 */
function simplifyPath(points: Point[]): Point[] {
    if (points.length <= 2) {
        return points;
    }

    const r: Point[] = [points[0]];
    for (let i = 1; i < points.length; i++) {
        const cur = points[i];

        if (i === points.length - 1) {
            r.push(cur);
            break;
        }

        const prev = points[i - 1];
        const next = points[i + 1];
        const bend = getBend(prev, cur, next);

        if (bend !== 'none') {
            r.push(cur);
        }
    }
    return r;
}

/**
 * Calculate the slope of a segment
 * Returns float, or -0|0|-Infinity|Infinity when points have 1 axis in common
 */
function slope(p1: Point, p2: Point) {
    return (p2.y - p1.y) / (p2.x - p1.x);
}

/**
 * Simplify contiguous points visually forming stairs
 * Path bends on the left, then on the right, left, right etc.
 *     |_      Replaced with    |
 *       |_        --->         |___
 *
 * Method: replace in between points with a single point
 */
function fixPathStairs(path: Point[], obstacles: Rectangle[]) {
    const center1 = obstacles[0].center,
        center2 = obstacles[1].center;
    const obstaclesMidPoint = Vect2.midPoint(center1, center2);

    const stairs: number[] = [],
        path2 = path.slice(0);
    let i = 0,
        previousSlopeSign: boolean = null,
        processStairs = false;

    while (i < path2.length) {
        processStairs = false;
        // Stairs effect requires at least 3 points
        if (path2[i + 2]) {
            // Slope sign (between extremis of a bend, 2pts) tells whether the bend is done toward left or right relative to the tip of the path
            // Ex :    Turn left  →  ______|
            //         Turn right →  ______
            //                             |
            const currentSlopeSign = ((s) => (s !== 0 ? s > 0 : null))(
                slope(path2[i], path2[i + 2]),
            );

            // Flat slope and stairs spotted
            if (currentSlopeSign === null && stairs.length) {
                processStairs = true;
                // Either no slope has been detected yet, or previous and new slopes are equally signed
            } else if (
                previousSlopeSign === null ||
                currentSlopeSign === previousSlopeSign
            ) {
                // First slot is reserved to the starting point of the stairs section
                !stairs.length && stairs.push(i);
                // A stairs section is made of AT LEAST 3 points, hence [i, i+(n>=2)...]
                //                                                 start ^  ^---------^ end
                stairs.push(i + 2);
            } else {
                processStairs = true;
            }

            previousSlopeSign = currentSlopeSign;
        } else if (stairs.length) processStairs = true;

        // A stairs section has to be processed
        if (processStairs) {
            const startIndex = stairs[0];

            // Replace as many stairs as possible with 1 candidate point
            while (stairs.length > 1) {
                const endIndex = stairs[stairs.length - 1],
                    startPoint = path2[startIndex],
                    endPoint = path2[endIndex],
                    // Candidates are two points that form a rectangle when connected to the stairs section extremis
                    candidates = [
                        { x: path2[startIndex].x, y: path2[endIndex].y },
                        { x: path2[endIndex].x, y: path2[startIndex].y },
                    ],
                    // Determine the point that will replace the stairs section
                    candidate: Point = candidates
                        // Favor candidate point that is farthest as crow flies from the obstacles midpoint (middle point between the centers of both 2 obstacles)
                        .sort((ca, cb) => {
                            const distA = distance(ca, obstaclesMidPoint),
                                distB = distance(cb, obstaclesMidPoint);
                            return distA > distB ? -1 : distB < distA ? 1 : 0;
                            // Take the 1rst candidate that has no collisions with obstacles edges (if any)
                        })
                        .find((candidate) => {
                            return !obstacles.some(
                                (o) =>
                                    lineRectCollide(
                                        [startPoint, candidate],
                                        o.toIXYRect,
                                    ) ||
                                    lineRectCollide(
                                        [endPoint, candidate],
                                        o.toIXYRect,
                                    ),
                            );
                        });

                if (candidate) {
                    const delLength = endIndex - startIndex - 1;
                    path2.splice(startIndex + 1, delLength, candidate);
                    i = startIndex;

                    break;
                }
                stairs.pop();
            }

            // Get ready for a new potential stairs section detection
            previousSlopeSign = null;
            processStairs = false;
            stairs.splice(0);
        }

        i++;
    }

    return path2;
}

/**
 * Helps create the grid portion of the algorithm
 */
class Grid {
    private _rows = 0;
    private _cols = 0;

    readonly data: Map<number, Map<number, Rectangle>> = new Map();

    constructor() {}

    set(row: number, column: number, rectangle: Rectangle) {
        this._rows = Math.max(this.rows, row + 1);
        this._cols = Math.max(this.columns, column + 1);

        const rowMap: Map<number, Rectangle> =
            this.data.get(row) || this.data.set(row, new Map()).get(row);

        rowMap.set(column, rectangle);
    }

    get(row: number, column: number): Rectangle | null {
        const rowMap = this.data.get(row);

        if (rowMap) {
            return rowMap.get(column) || null;
        }

        return null;
    }

    rectangles(): Rectangle[] {
        const r: Rectangle[] = [];

        for (const data of this.data.values()) {
            r.push(...data.values());
        }

        return r;
    }

    get columns(): number {
        return this._cols;
    }

    get rows(): number {
        return this._rows;
    }
}

/**
 * Main logic wrapped in a class to hold a space for potential future functionallity
 */
export class OrthogonalConnector {
    static readonly byproduct: OrthogonalConnectorByproduct = {
        hRulers: [],
        vRulers: [],
        spots: [],
        grid: [],
        connections: [],
    };

    static route(
        opts: OrthogonalConnectorOpts,
        updateByproduct?: boolean,
    ): Point[] {
        const { pointA, pointB, globalBoundsMargin } = opts;
        const spots: Point[] = [];
        const verticals: number[] = [];
        const horizontals: number[] = [];
        const sideA = pointA.side,
            sideAVertical = isVerticalSide(sideA);
        const sideB = pointB.side,
            sideBVertical = isVerticalSide(sideB);
        const start = computePt(pointA); // Seems to be equal to OrthogonalConnectorByproduct.originA
        const end = computePt(pointB); // Seems to be equal to OrthogonalConnectorByproduct.originB
        const shapeA = Rectangle.truncateDecimals(
            Rectangle.fromRect(pointA.shape),
        );
        const shapeB = Rectangle.truncateDecimals(
            Rectangle.fromRect(pointB.shape),
        );
        const bigBounds = opts?.globalBounds
            ? Rectangle.fromRect(opts.globalBounds)
            : null;
        const shapeMargin = opts.shapeMargin;
        const inflatedA = shapeA.inflate(shapeMargin, shapeMargin);
        const inflatedB = shapeB.inflate(shapeMargin, shapeMargin);

        const inflatedBounds = inflatedA
            .union(inflatedB)
            .inflate(globalBoundsMargin, globalBoundsMargin);

        // Curated bounds to stick to
        const bounds = bigBounds
            ? Rectangle.fromLTRB(
                  Math.max(inflatedBounds.left, bigBounds.left),
                  Math.max(inflatedBounds.top, bigBounds.top),
                  Math.min(inflatedBounds.right, bigBounds.right),
                  Math.min(inflatedBounds.bottom, bigBounds.bottom),
              )
            : inflatedBounds;
        // Add edges to rulers
        for (const b of [inflatedA, inflatedB]) {
            verticals.push(b.left, b.right);
            horizontals.push(b.top, b.bottom);
        }

        // Rulers at origins of shapes
        (sideAVertical ? verticals : horizontals).push(
            sideAVertical ? start.x : start.y,
        );
        (sideBVertical ? verticals : horizontals).push(
            sideBVertical ? end.x : end.y,
        );

        // Origin and destination by extruding antennas
        const origin = extrudeCp(pointA, shapeMargin);
        const destination = extrudeCp(pointB, shapeMargin);
        // Points of shape antennas
        spots.push(origin, destination);

        // Sort rulers
        verticals.sort((a, b) => a - b);
        horizontals.sort((a, b) => a - b);

        // Create grid
        const grid = rulersToGrid(verticals, horizontals, bounds);

        const unwantedSpots: Point[] = [];
        const isUnwantedSpot = (p: Point) => {
            if (
                // points that touch shapes
                inflatedA.contains(p) ||
                inflatedB.contains(p)
            ) {
                unwantedSpots.push(p);
                return true;
            }
        };
        const gridPoints = gridToSpots(grid, isUnwantedSpot);

        // Add to spots
        spots.push(...gridPoints);

        // Create graph
        const { graph, connections } = createGraph(spots);

        if (updateByproduct) {
            const bp = this.byproduct;
            bp.spots = spots;
            bp.vRulers = verticals;
            bp.hRulers = horizontals;
            bp.grid = grid.rectangles();
            bp.connections = connections;
            bp.start = start;
            bp.end = end;
            bp.unwantedSpots = unwantedSpots;
        }

        const obstaclesMidPoint = Vect2.midPoint(shapeA.center, shapeB.center);

        const path = shortestPath(
            graph,
            origin,
            destination,
            obstaclesMidPoint,
        );
        if (!path.length) return [];

        const simplifiedPath = simplifyPath(path);
        // Stairs removal is only attempted between antenas (start/end extruded copies)
        const fixedStairsPath = fixPathStairs(simplifiedPath.slice(1, -1), [
            inflatedA,
            inflatedB,
        ]);
        return simplifyPath([start, ...fixedStairsPath, end]);
    }
}
