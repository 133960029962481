import { Injectable } from '@angular/core';
import {
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { BaseServiceParameter } from '../backend';
import { finalize } from 'rxjs';
import { ObjectUtils } from '@datagalaxy/utils';

/**
 * ## Role
 * Increment a counter for each request to order them sequentially and track
 * the last request number handled by the server
 */
@Injectable()
export class SequenceNumberInterceptor implements HttpInterceptor {
    private nextSequenceNumber = 0;
    private lastReceivedSequenceNumber: number | undefined;

    intercept(req: HttpRequest<BaseServiceParameter>, next: HttpHandler) {
        const reqClone: HttpRequest<BaseServiceParameter> = req.clone({
            body: ObjectUtils.assign(req.body, {
                SequenceNumber: this.nextSequenceNumber++,
                LastReceivedSequenceNumber: this.lastReceivedSequenceNumber,
            }),
        });

        return next
            .handle(reqClone)
            .pipe(
                finalize(
                    () =>
                        (this.lastReceivedSequenceNumber =
                            reqClone.body?.SequenceNumber),
                ),
            );
    }
}
