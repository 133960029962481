import { EntityItem } from '@datagalaxy/webclient/entity/domain';
import { IEntityCreationContext } from './entity-creation-context';
import { EntityCreationOrigin } from './entity-creation-origin';

export class EntityCreatedEvent {
    public readonly context: IEntityCreationContext;
    constructor(
        public entity: EntityItem,
        /** from entity-create-modal, true when the *create another* check-box is checked */
        isMultiCreation?: boolean,
        origin?: EntityCreationOrigin,
    ) {
        this.context = { origin, isMultiCreation };
    }
}
