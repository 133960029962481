import { inject, Injectable } from '@angular/core';
import {
    AuthApiService,
    GetExternalAuthenticationMethodParameter,
} from '@datagalaxy/webclient/auth/data-access';
import { Router } from '@angular/router';
import { LegacyLoginService } from '../../services/login.service';
import { JwtUtil } from '../../shared/util/Jwt.util';
import { AppDataService } from '../../services/app-data.service';
import { CoreUtil } from '@datagalaxy/core-util';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';

/***
 * @deprecated unused in authV2 process
 */
export enum ExternalLoginStatus {
    OK = 1,
    FailedUnknownUser = -1,
    FailedNoLicenseAvailable = -2,
}

@Injectable({
    providedIn: 'root',
})
export class LegacySsoAuthService {
    private window = inject(Window);
    private authApiService = inject(AuthApiService);
    private loginService = inject(LegacyLoginService);
    private appDataService = inject(AppDataService);
    private translate = inject(TranslateService);
    private router = inject(Router);

    private isSsoEnabled: boolean;
    private ssoUrl: string;
    private isAuthenticated = new BehaviorSubject<boolean>(false);
    private loadedConfig = new BehaviorSubject<boolean>(false);

    public get enabled() {
        return this.isSsoEnabled;
    }

    public get authenticated() {
        return this.isAuthenticated.value;
    }

    public get authenticated$() {
        return this.isAuthenticated.asObservable();
    }

    public get loaded$() {
        return this.loadedConfig.asObservable();
    }

    public get authUrl() {
        return this.ssoUrl;
    }

    public async loadSsoConfig() {
        const parameter = new GetExternalAuthenticationMethodParameter(
            this.window.location.href,
        );

        try {
            const res =
                await this.authApiService.getExternalAuthenticationMethod(
                    parameter,
                );

            if (!res.IsExternal) {
                return;
            }

            this.isSsoEnabled = true;
            this.ssoUrl = res.AuthenticationUrl;
        } catch (error) {
            return this.router.navigate(['/error'], {
                queryParams: {
                    errorMessage: 'UI.Login.lblAuthenticationFailed',
                    accessLevel: 'login',
                },
            });
        } finally {
            this.loadedConfig.next(true);
        }
    }

    public async postLogin(token: string) {
        try {
            const res = await this.loginService.externalLogin(token);

            this.isAuthenticated.next(true);

            return res;
        } catch {
            this.isAuthenticated.next(false);
        }
    }

    public handlePostLoginError(status: string, token: string) {
        let loginContent = '';

        // Since no user or client, we use the Default Language Code from browser
        this.appDataService.setCurrentLanguageCodeFromBrowser();

        try {
            const tokenContent = JwtUtil.parseJwt(token);
            loginContent = tokenContent.login;
        } catch (e) {
            CoreUtil.warn(e);
        }

        const loginStatus: ExternalLoginStatus = parseInt(status);
        let errorMessage = '';
        switch (loginStatus) {
            case ExternalLoginStatus.FailedUnknownUser:
                errorMessage = this.translate.instant(
                    'UI.Login.ExternalLoginStatus.FailedUnknownUser',
                    { login: loginContent },
                );
                break;
            case ExternalLoginStatus.FailedNoLicenseAvailable:
                errorMessage = this.translate.instant(
                    'UI.Login.ExternalLoginStatus.FailedNoLicenseAvailable',
                    { login: loginContent },
                );
                break;
        }

        return [
            'error',
            {
                queryParams: {
                    errorMessage,
                    accessLevel: 'nologin',
                },
            },
        ];
    }
}
