import { Injectable } from '@angular/core';
import {
    CreateSpaceArticleParameter,
    DeleteSpaceArticleParameter,
    GetSpaceArticleResult,
    GetSpaceArticlesParameter,
    GetSpaceArticlesResult,
    UpdateSpaceArticleParameter,
} from './article';
import {
    CheckSpaceTrigramValidityParameter,
    CreateProjectResult,
    CreateSpaceParameter,
    DeleteProjectParameter,
    DeleteProjectResult,
    LoadProjectParameter,
    LoadProjectResult,
    LoadSpacesResult,
    PreSetupSpaceTrigramResult,
    UpdateSpaceParameter,
} from './workspace';
import {
    GetSpaceAdministrationUsersParameter,
    GetSpaceAdministrationUsersResult,
    GetSpaceGovernanceUsersParameter,
    GetSpaceUsersParameter,
    GetSpaceUsersResult,
    SpaceGovernanceUserResult,
    UpdateSpaceGovernanceUserParameter,
} from './user';
import {
    BackendApiService,
    BaseServiceParameter,
    BaseServiceResult,
} from '@datagalaxy/data-access';

@Injectable({ providedIn: 'root' })
export class WorkSpaceApiService {
    constructor(private backendApiService: BackendApiService) {}

    public async loadSpaces() {
        return await this.backendApiService.postPromise(
            'Data/LoadSpaces',
            new BaseServiceParameter(),
            LoadSpacesResult,
        );
    }

    public async loadProject(param: LoadProjectParameter) {
        return await this.backendApiService.postPromise(
            'Version/LoadProject',
            param,
            LoadProjectResult,
        );
    }

    public async updateDefaultSpace(param: UpdateSpaceParameter) {
        return await this.backendApiService.postPromise(
            'Version/UpdateDefaultSpace',
            param,
            BaseServiceResult,
        );
    }

    public async createProject(param: CreateSpaceParameter) {
        return await this.backendApiService.postPromise(
            'Version/CreateProject',
            param,
            CreateProjectResult,
        );
    }

    public async deleteProject(param: DeleteProjectParameter) {
        return await this.backendApiService.postPromise(
            'Version/DeleteProject',
            param,
            DeleteProjectResult,
        );
    }

    public async updateSpace(param: UpdateSpaceParameter) {
        return await this.backendApiService.postPromise(
            'Version/UpdateSpace',
            param,
            BaseServiceResult,
        );
    }

    public async updateSpaceGovernanceUser(
        param: UpdateSpaceGovernanceUserParameter,
    ) {
        return await this.backendApiService.postPromise(
            'Version/UpdateSpaceGovernanceUser',
            param,
            SpaceGovernanceUserResult,
        );
    }

    public async getSpaceGovernanceUsers(
        param: GetSpaceGovernanceUsersParameter,
    ) {
        return await this.backendApiService.postPromise(
            'Version/GetSpaceGovernanceUsers',
            param,
            SpaceGovernanceUserResult,
        );
    }

    public async getClientSpaceGovernanceUsers(
        param: GetSpaceGovernanceUsersParameter,
    ) {
        return await this.backendApiService.postPromise(
            'Version/GetClientSpaceGovernanceUsers',
            param,
            SpaceGovernanceUserResult,
        );
    }

    public async preCreateSpace(param: CreateSpaceParameter) {
        return await this.backendApiService.postPromise(
            'Version/PreCreateSpace',
            param,
            PreSetupSpaceTrigramResult,
        );
    }

    public async CheckSpaceTrigramValidity(
        param: CheckSpaceTrigramValidityParameter,
    ) {
        return await this.backendApiService.postPromise(
            'Version/CheckSpaceTrigramValidity',
            param,
            PreSetupSpaceTrigramResult,
        );
    }

    public async getSpaceUsers(param: GetSpaceUsersParameter) {
        return await this.backendApiService.postPromise(
            'Space/GetSpaceUsers',
            param,
            GetSpaceUsersResult,
        );
    }

    public async getSpaceAdministrationUsers(
        param: GetSpaceAdministrationUsersParameter,
    ) {
        return await this.backendApiService.postPromise(
            'Space/GetSpaceAdministrationUsers',
            param,
            GetSpaceAdministrationUsersResult,
        );
    }

    public async getSpaceArticles(param: GetSpaceArticlesParameter) {
        return await this.backendApiService.postPromise(
            'Article/GetSpaceArticles',
            param,
            GetSpaceArticlesResult,
        );
    }

    public async createSpaceArticle(param: CreateSpaceArticleParameter) {
        return await this.backendApiService.postPromise(
            'Article/CreateArticle',
            param,
            GetSpaceArticleResult,
        );
    }

    public async updateSpaceArticle(param: UpdateSpaceArticleParameter) {
        return await this.backendApiService.postPromise(
            'Article/UpdateArticle',
            param,
            GetSpaceArticleResult,
        );
    }

    public async deleteSpaceArticle(param: DeleteSpaceArticleParameter) {
        return await this.backendApiService.postPromise(
            'Article/DeleteArticle',
            param,
            BaseServiceResult,
        );
    }
}
