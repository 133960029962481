import { Injectable } from '@angular/core';
import { RestApiService } from '@datagalaxy/data-access';
import { GetPropertyGoldenLinksQuery } from './entity-link';
import { getLocalId } from '@datagalaxy/webclient/utils';
import { IWorkspaceIdentifier } from '@datagalaxy/webclient/workspace/domain';
import { GetPropertyGoldenLinksResult } from '@datagalaxy/webclient/entity/domain';

@Injectable({ providedIn: 'root' })
export class EntityLinkApiService {
    constructor(private restApiService: RestApiService) {}

    public async getGoldenLinks(
        spaceIdr: IWorkspaceIdentifier,
        param: GetPropertyGoldenLinksQuery,
    ) {
        return await this.restApiService.postPromise<GetPropertyGoldenLinksResult>(
            `workspaces/${getLocalId(spaceIdr.spaceId)}/versions/${
                spaceIdr.versionId
            }/properties/golden-links`,
            param,
        );
    }
}
