import { deserialize, deserializeAs } from 'cerialize';
import { EntityLinkTypeKind } from './EntityLinkTypeKind.enum';
import { ObjectLinkType } from './ObjectLinkType.enum';

export class BaseLinkDataInfo {
    // Note: Starting from 2.24, we are regrouping ObjectLinkType and EntityLinkTypeKind values in this field:
    @deserializeAs(ObjectLinkType)
    public UniversalObjectLinkType: ObjectLinkType;
    // Note: Starting from 2.24, we are regrouping ObjectLinkType and EntityLinkTypeKind values in this field:
    @deserializeAs(ObjectLinkType)
    public UniversalObjectLinkTypeReverse: ObjectLinkType;

    @deserializeAs(ObjectLinkType) public ObjectLinkType: ObjectLinkType;
    @deserializeAs(ObjectLinkType) public ObjectLinkTypeReverse: ObjectLinkType;
    @deserializeAs(EntityLinkTypeKind)
    public EntityLinkTypeKind?: EntityLinkTypeKind;
    @deserialize public EntityLinkType: string;
    @deserialize public IsReverseEntityLink: boolean;
    @deserialize public IsReverseObjectLinkType: boolean;
    @deserialize public IsTargetUnique: boolean;
    @deserialize public IsGoldenLink: boolean;

    public static fromGroup(g: BaseLinkDataInfo) {
        return new BaseLinkDataInfo(
            g.ObjectLinkType,
            g.EntityLinkTypeKind,
            g.EntityLinkType,
            g.IsReverseEntityLink,
        );
    }

    constructor(
        linkType?: ObjectLinkType,
        linkTypeKind?: EntityLinkTypeKind,
        entityLinkType?: string,
        isReverse?: boolean,
    ) {
        this.ObjectLinkType = linkType;
        this.EntityLinkTypeKind = linkTypeKind;
        this.EntityLinkType = entityLinkType;
        this.IsReverseEntityLink = isReverse;
    }

    public get linkTypeString() {
        return ObjectLinkType[this.ObjectLinkType];
    }

    public get universalLinkTypeString() {
        return ObjectLinkType[this.UniversalObjectLinkType];
    }

    public get displayNameTranslateKey() {
        const linkTypeString = ObjectLinkType[this.UniversalObjectLinkType];
        return `DgServerTypes.ObjectLinkType.${linkTypeString}`;
    }
}
