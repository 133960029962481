import { BaseService } from '@datagalaxy/core-ui';
import { Injectable } from '@angular/core';
import { ServerType } from '@datagalaxy/dg-object-model';
import { DataUtil } from '../../util/DataUtil';
import { SecurityService } from '../../../services/security.service';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';
import { DgModule } from '@datagalaxy/shared/dg-module/domain';
import { EntitySecurityData } from '@datagalaxy/webclient/security/domain';
import { WorkspaceDetails } from '@datagalaxy/webclient/workspace/domain';

@Injectable({ providedIn: 'root' })
export class EntitySecurityService extends BaseService {
    constructor(private securityService: SecurityService) {
        super();
    }

    public getSecurityData(
        projectData: WorkspaceDetails,
        serverType: ServerType,
    ) {
        switch (serverType) {
            case ServerType.Property:
                return projectData.GlossarySecurityData;
            case ServerType.DataProcessing:
                return projectData.DataProcessingContainerSecurityData;
            case ServerType.SoftwareElement:
                return projectData.SoftwareContainerSecurityData;
            case ServerType.Model:
                return projectData.CatalogSecurityData;
            default:
                return null;
        }
    }

    public hasWriteAccessToAnyModule(space: WorkspaceDetails) {
        return (
            space.ProjectSecurityData.HasWriteAccess ||
            space.GlossarySecurityData.HasCreateAccess ||
            space.DataProcessingContainerSecurityData.HasCreateAccess ||
            space.SoftwareContainerSecurityData.HasCreateAccess ||
            space.CatalogSecurityData.HasCreateAccess
        );
    }

    /**
     * @returns true when user have at least HasImportAccess right on a module
     * or on a catalog source
     */
    public hasImportAccessToAnyModule(space: WorkspaceDetails) {
        return space.CanImportEntities || space.CanImportEntitiesOnCatalog;
    }

    /**
     * @returns true when user have HasImportAccess right on module
     */
    public hasImportAccessToModule(space: WorkspaceDetails, module: DgModule) {
        const securityData = this.getModuleRootSecurityData(module, space);
        return (
            securityData?.HasImportAccess || securityData?.hasModuleImportAccess
        );
    }

    public hasWriteAccessSecurity(securityData: EntitySecurityData) {
        return !!securityData?.HasWriteAccess;
    }

    public hasWriteAccessEntitySecurity(
        securityData: EntitySecurityData,
        entityData?: EntityItem,
    ) {
        return !!(
            securityData?.HasWriteAccess &&
            (this.securityService.isSteward() ||
                (entityData &&
                    this.securityService.isEntityCreatedByCurrentUser(
                        entityData,
                    )))
        );
    }

    public hasAdminAccessSecurity(securityData: EntitySecurityData) {
        return !!securityData?.HasAdministratorAccess;
    }

    public hasManagementAccessSecurity(securityData: EntitySecurityData) {
        return !!securityData?.HasManagementAccess;
    }

    public hasImportAccess(securityData: EntitySecurityData) {
        return (
            !!securityData?.HasImportAccess ||
            securityData?.hasModuleImportAccess
        );
    }

    public hasEntityCreateAccess(securityData: EntitySecurityData) {
        return !!securityData?.HasCreateAccess;
    }

    // Exception: For Model, we must be Entity Admin to be able to delete the entity
    public hasEntityDeleteAccess(
        serverType: ServerType,
        securityData: EntitySecurityData,
    ) {
        return !!securityData?.HasDeleteAccess;
    }

    public getModuleImportSecurityData(
        serverType: ServerType,
        spaceData: WorkspaceDetails,
    ) {
        return this.getModuleRootSecurityDataFromServerType(
            serverType,
            spaceData,
        );
    }

    public getModuleRootSecurityDataFromServerType(
        serverType: ServerType,
        spaceData: WorkspaceDetails,
    ) {
        const dgModule = DataUtil.getModuleFromServerType(serverType);
        return this.getModuleRootSecurityData(dgModule, spaceData);
    }
    public getModuleRootSecurityData(
        dgModule: DgModule,
        spaceData: WorkspaceDetails,
    ) {
        switch (dgModule) {
            case DgModule.Glossary:
                return spaceData.GlossarySecurityData;
            case DgModule.Processing:
                return spaceData.DataProcessingContainerSecurityData;
            case DgModule.Usage:
                return spaceData.SoftwareContainerSecurityData;
            case DgModule.Catalog:
                return spaceData.CatalogSecurityData;
        }
    }
}
