import {
    AfterViewChecked,
    ChangeDetectorRef,
    Directive,
    DoCheck,
    OnDestroy,
} from '@angular/core';
import { DxyBaseDirective } from '../directive';

/** Provides helpers for an Angular component:
 * - logging,
 * - events subscription and unsubscription. */
@Directive()
export abstract class DxyBaseComponent
    extends DxyBaseDirective
    implements OnDestroy, DoCheck, AfterViewChecked
{
    private static _debugDoCheck = false;
    private static _debugCD = false;
    private static _debugCDinc = false;

    private _cd = 0;

    protected constructor() {
        super();
    }

    ngDoCheck() {
        DxyBaseComponent._debugDoCheck &&
            console.log(this.constructor.name, 'ngDoCheck');
    }

    ngAfterViewChecked() {
        this.logCD();
    }

    /** Prevents [the NG0100 ExpressionChangedAfterItHasBeenCheckedError](https://stackoverflow.com/questions/43375532/expressionchangedafterithasbeencheckederror-explained).
     *
     * Notes:
     * - This is to be called in ngAfterViewChecked.
     * - Use only if no other way is found.
     */
    protected preventNg0100Error(changeDetector: ChangeDetectorRef) {
        this.logCD();
        changeDetector.detectChanges();
    }

    private logCD() {
        DxyBaseComponent._debugCD &&
            console.log(
                this.constructor.name,
                'CD',
                DxyBaseComponent._debugCDinc ? this._cd++ : '',
            );
    }
}
