import { RectSide } from '@datagalaxy/core-2d-util';
import {
    ConnectorGeometry,
    ConnectorGeometrySidePoint,
    ConnectorGeometryType,
    IPlumbAnchorPlacement,
    TConnectorGeometry,
} from './geometry.types';

/** utility to convert to/from jsplumb OrthogonalConnectorGeometry */
export class GeometryConverter {
    /**
     * Converts jsplumb orthogonalConnectorGeometry to our connectors geometry,
     * or adapts between our versions
     */
    public static convert(geometry: TConnectorGeometry): ConnectorGeometry {
        if (!geometry) {
            return;
        }

        switch (geometry.type) {
            case ConnectorGeometryType.ortho:
                return geometry;
            case ConnectorGeometryType.jspOrtho:
            default: {
                return {
                    type: ConnectorGeometryType.ortho,
                    points: geometry.segments,
                    src: this.rectSideFromAnchorPlacement(geometry.source),
                    tgt: this.rectSideFromAnchorPlacement(geometry.target),
                };
            }
        }
    }

    /** modifies the given geometry so source and target are exchanged */
    public static invertGeometry(g: TConnectorGeometry) {
        if (!g) {
            return;
        }
        if (g.type == ConnectorGeometryType.ortho) {
            const t = g.tgt;
            g.tgt = g.src;
            g.src = t;
            g.points?.reverse();
            return g;
        }
        if (!g.type) {
            const t = g.target;
            g.target = g.source;
            g.source = t;
            g.segments?.reverse();
            return g;
        }
    }

    public static rectSideFromAnchorPlacement(
        p: IPlumbAnchorPlacement,
    ): ConnectorGeometrySidePoint {
        /* ox, oy:
            H A->B  s: 1, 0 t:-1, 0
            H B<-A  s:-1, 0 t: 1, 0
            V A->B  s: 0, 1 t: 0,-1
            V B<-A  s: 0,-1 t: 0, 1
        */
        if (!p) {
            return;
        }
        let side: RectSide, distance: number;
        switch (p.ox) {
            case -1:
                side = RectSide.left;
                distance = p.y;
                break;
            case 0:
                switch (p.oy) {
                    case -1:
                        side = RectSide.top;
                        break;
                    case 1:
                        side = RectSide.bottom;
                        break;
                }
                distance = p.x;
                break;
            case 1:
                side = RectSide.right;
                distance = p.y;
                break;
        }
        return { side, distance };
    }
}
