import { Pipe, PipeTransform } from '@angular/core';
import { WorkspaceService } from '@datagalaxy/webclient/workspace/data-access';
import { IWorkspace, IWorkspaceIcon } from '@datagalaxy/webclient/workspace/ui';
import {
    IHasSpaceIconData,
    Workspace,
    WorkspaceDetails,
} from '@datagalaxy/webclient/workspace/domain';

type WorkspaceClass = WorkspaceDetails | Workspace;

function transformWorkspaceIcon(
    value: IHasSpaceIconData,
    workspaceService: WorkspaceService,
): IWorkspaceIcon {
    return {
        trigram: value.Trigram,
        iconUrl: workspaceService.getWorkspaceIconUrl(value),
    };
}

export function transformWorkspace(
    value: WorkspaceClass,
    workspaceService: WorkspaceService,
): IWorkspace {
    return {
        spaceId: value.spaceId,
        versionId: value.versionId,
        displayName: value.DisplayName,
        id: value.ReferenceId as string,
        favorite: value.IsDefaultSpace,
        editable: value.SecurityData?.HasAdministratorAccess,
        ...transformWorkspaceIcon(value, workspaceService),
    };
}

@Pipe({
    standalone: true,
    name: 'workspaceAdapter',
})
export class WorkspaceAdapterPipe implements PipeTransform {
    constructor(private workspaceService: WorkspaceService) {}

    transform(value: WorkspaceClass): IWorkspace {
        return transformWorkspace(value, this.workspaceService);
    }
}

@Pipe({
    standalone: true,
    name: 'workspaceIconAdapter',
})
export class WorkspaceIconAdapterPipe implements PipeTransform {
    constructor(private workspaceService: WorkspaceService) {}

    transform(value: IHasSpaceIconData): IWorkspaceIcon {
        return transformWorkspaceIcon(value, this.workspaceService);
    }
}

@Pipe({
    standalone: true,
    name: 'workspaceListAdapter',
})
export class WorkspaceListAdapterPipe implements PipeTransform {
    constructor(private workspaceService: WorkspaceService) {}

    transform(values: WorkspaceClass[]): IWorkspace[] {
        return values.map((value) =>
            transformWorkspace(value, this.workspaceService),
        );
    }
}
