import { IUnitaryFieldActionsInput } from './IUnitaryFieldActionsInput';
import { IUnitaryFieldData, IUnitaryFieldDataOf } from './IUnitaryFieldData';
import { UnitaryFieldValidator } from './UnitaryFieldValidator';

/** Base class for an object that:
 * - holds a data (value, object, array)
 * - knows if it has been modified
 * - supports validation, save and undo (using UnitaryFieldValidator)
 * - can be passed to dxy-unitary-field-actions as its input */
export abstract class BaseUnitaryFieldData<T>
    implements IUnitaryFieldDataOf<T>, IUnitaryFieldActionsInput
{
    public initialValue: T;
    public currentValue: T;
    public initialValueSet: boolean;
    public errorMessage: string;
    public isServerError: boolean;
    public recentlyModifiedExternally = false;
    public isErrorInValidating = false;
    public get actionsInput() {
        return this as IUnitaryFieldActionsInput;
    }

    constructor(
        public readonly fieldKey: string,
        private validator?: UnitaryFieldValidator<IUnitaryFieldData>,
    ) {}

    public init(
        validator: UnitaryFieldValidator<IUnitaryFieldData>,
        initialValue?: T,
    ) {
        this.validator = validator;
        if (initialValue != undefined) {
            this.setInitialValue(initialValue);
        }
    }

    public setInitialValue(value: T) {
        this.initialValue = value;
        this.initialValueSet = true;
    }

    public hasUnitaryValidation() {
        return true;
    }

    public abstract isDirty(): boolean;

    public hasExternalError() {
        return !!this.errorMessage && !this.isServerError;
    }

    public isUndoVisible() {
        return this.isDirty() || (this.errorMessage && this.isServerError);
    }
    public undo() {
        this.validator.undo(this);
    }

    public isValidateVisible() {
        return this.isDirty() && !this.errorMessage;
    }
    public async validate() {
        await this.validator.validate(this);
    }

    public setError(errorMessage: string, isServerError = false) {
        this.errorMessage = errorMessage;
        this.isServerError = isServerError;
    }
    public clearError() {
        this.errorMessage = this.isServerError = undefined;
    }

    public abstract resetAfterSave(): void;
    public abstract resetAfterUndo(): void;

    public onFocus() {
        this.validator.onFieldFocus(this);
    }
    public onBlur(event: FocusEvent) {
        this.validator.onFieldBlur(this, event);
    }
    public onEnterKey() {
        this.validator.validate(this);
    }
}
