import { FilterUtil } from '../../../util/FilterUtil';
import { BaseFilterListItemModel } from '../models/BaseFilterListItemModel';
import { AttributeFilterModel } from '../attribute-filter/attributeFilterModel';
import { CollectionsHelper } from '@datagalaxy/core-util';
import { IFilterFormModel } from '../attribute-filter-form/IFilterFormModel';
import { FilterOperator } from '@datagalaxy/webclient/filter/domain';
import {
    AttributeMetaInfo,
    AttributeMetaValue,
} from '@datagalaxy/webclient/attribute/domain';
import { ServerConstants } from '@datagalaxy/shared/server/domain';

export class AttributeValueListFilterModel extends BaseFilterListItemModel {
    public get value() {
        return this.values?.[0];
    }
    public set value(value: AttributeMetaValue) {
        value = this.ListValues?.find((v) => v.Key == value.Key);
        this.values = value ? [value] : [];
    }

    constructor(
        filterFormData: IFilterFormModel,
        attributeMeta: AttributeMetaInfo,
        operators: FilterOperator[],
    ) {
        super(filterFormData, attributeMeta, operators);
    }

    getValuesAsArray() {
        return FilterUtil.isMultiValueOperator(this.operator)
            ? this.isCdp
                ? this.values.map((a) => a.Value)
                : this.values.map((a) => a.Key)
            : this.values?.length
              ? this.isCdp
                  ? [this.value.Value]
                  : [this.value.Key]
              : [];
    }

    isValid() {
        if (FilterUtil.isValuelessOperator(this.operator)) {
            return true;
        }

        return FilterUtil.isMultiValueOperator(this.operator)
            ? this.values?.length > 0
            : this.value != undefined;
    }

    hasValue(): boolean {
        if (this.attributeKey == ServerConstants.PropertyName.Technology) {
            return this.hasValues;
        }
        return super.hasValue();
    }

    setValuesFromDb(dbValues: string[]) {
        const amvs = this.ListValues;
        if (FilterUtil.isMultiValueOperator(this.operator)) {
            this.values = this.isCdp
                ? amvs.filter((lv) => dbValues.includes(lv.Value))
                : amvs.filter((lv) => dbValues.includes(lv.Key));
        } else {
            const v = dbValues.join();
            this.value = this.isCdp
                ? amvs.find((c) => c.Value == v)
                : amvs.find((c) => c.Key == v);
        }
    }

    copy(other: AttributeValueListFilterModel) {
        this.operator = other.operator;
        if (FilterUtil.isMultiValueOperator(this.operator)) {
            const keys = other.values.map((amv) => amv.Key);
            this.values = other.ListValues?.filter((lv) =>
                keys.includes(lv.Key),
            );
        } else {
            const key = other.value.Key;
            this.value = other.ListValues?.find((lv) => lv.Key == key);
        }
    }

    protected sameTypeAndValue(other: AttributeFilterModel) {
        const equals = this.isCdp
            ? (a: AttributeMetaValue, b: AttributeMetaValue) =>
                  (!a && !b) || a.Value == b.Value
            : (a: AttributeMetaValue, b: AttributeMetaValue) =>
                  (!a && !b) || a.Key == b.Key;
        return (
            other instanceof AttributeValueListFilterModel &&
            CollectionsHelper.contentEquals(
                this.values,
                other.values,
                true,
                true,
                equals,
            )
        );
    }
}
