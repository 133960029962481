import { BaseService } from '@datagalaxy/core-ui';
import { ImplementationLineageDataSource } from './dxy-glossary-implementation/ImplementationLineageDataSource';
import { Injectable } from '@angular/core';
import { IEntityIdentifier, ObjectLinkType } from '@datagalaxy/dg-object-model';
import { EntityService } from '../shared/entity/services/entity.service';
import { DxyModalService } from '../shared/dialogs/DxyModalService';
import { EntityLinkService } from '../entity-links/entity-link.service';
import { GlossaryGenerateFromSourceModalComponent } from './glossary-generate-from-source-modal/glossary-generate-from-source-modal.component';
import {
    GlossaryGenerateFromSourceModalInput,
    GlossaryGenerateFromSourceModalOutput,
} from './glossary-generate-from-source-modal/glossary-generate-from-source-modal.types';
import { UserService } from '../services/user.service';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
    EntityApiService,
    GetLinkedDataParameter,
} from '@datagalaxy/webclient/entity/data-access';
import {
    GenerateGlossaryCommand,
    GlossaryApiService,
    PreGenerateGlossaryQuery,
} from '@datagalaxy/webclient/glossary/data-access';
import { GlossaryLinksGenerationModalComponent } from './glossary-links-generation-modal/glossary-links-generation-modal.component';
import {
    IGlossaryLinksGenerationInput,
    IGlossaryLinksGenerationOutput,
} from './glossary-links-generation-modal/glossary-links-generation-modal.types';
import { IWorkspaceIdentifier } from '@datagalaxy/webclient/workspace/domain';
import {
    EntityItem,
    LinkedDataGroup,
} from '@datagalaxy/webclient/entity/domain';
import { DgModule } from '@datagalaxy/shared/dg-module/domain';

@Injectable({ providedIn: 'root' })
export class GlossaryService extends BaseService {
    constructor(
        private glossaryApiService: GlossaryApiService,
        private linkedObjectService: EntityLinkService,
        private dxyModalService: DxyModalService,
        private entityService: EntityService,
        private entityApiService: EntityApiService,
        private userService: UserService,
    ) {
        super();
    }

    public getImplementationLineageDataSource(property: EntityItem) {
        return (
            property &&
            new ImplementationLineageDataSource(
                (entity: IEntityIdentifier, ...linkTypes: ObjectLinkType[]) =>
                    this.getLinkedData(entity, linkTypes),
                property,
                (...args: any[]) => this.log(...args),
            )
        );
    }

    private async getLinkedData(
        entity: IEntityIdentifier,
        linkTypes: ObjectLinkType[],
    ) {
        const p = new GetLinkedDataParameter(entity.ReferenceId);
        p.setVersionId(entity?.VersionId);
        p.IncludedTypes = linkTypes;
        return await this.entityApiService.getLinkedData(p);
    }

    public async openLinkCreationModal(
        entityItem: EntityItem,
        linkedDataGroups: LinkedDataGroup[],
    ) {
        this.log('openLinkCreationModal', entityItem, linkedDataGroups);
        return this.linkedObjectService.openLinkCreationModal(
            entityItem,
            linkedDataGroups,
            {
                includeEntityLinks: true,
                onlyUniversalLinkTypes: [
                    ObjectLinkType.HasForRecordingSystem,
                    ObjectLinkType.IsImplementedBy,
                ],
            },
        );
    }

    public async generateGlossary(
        sourcesIds: string[],
        targetId?: string,
        targetName?: string,
    ) {
        const param = new GenerateGlossaryCommand();
        param.DataReferenceIdList = sourcesIds;
        param.TargetUniverseName = targetName;
        param.TargetUniverseReferenceId = targetId;
        return this.glossaryApiService.generateGlossary(param);
    }

    public async preGenerateGlossary(sourcesIds: string[]) {
        const param = new PreGenerateGlossaryQuery(sourcesIds);
        return this.glossaryApiService.preGenerateGlossary(param);
    }

    public async openGenerateFromSourceModal(
        spaceIdr?: IWorkspaceIdentifier,
        dgModule?: DgModule,
        sources?: EntityItem[],
    ) {
        const modulesEntitiesCount = spaceIdr
            ? await this.entityService.getModulesEntitiesCountForGlossaryAutoGeneration(
                  spaceIdr,
              )
            : null;
        const res = await this.userService.getUserSettingValue(
            'glossary-auto-generation',
            'skip-help',
        );
        const skipHelp = coerceBooleanProperty(res.Value);
        return await this.dxyModalService.open<
            GlossaryGenerateFromSourceModalComponent,
            GlossaryGenerateFromSourceModalInput,
            GlossaryGenerateFromSourceModalOutput
        >({
            componentType: GlossaryGenerateFromSourceModalComponent,
            data: {
                skipHelp,
                spaceIdr,
                selectedDgModule: dgModule,
                modulesEntitiesCount,
                sources,
            },
        });
    }

    public async openLinksGenerationModalForModule(
        dgModule: DgModule,
        spaceIdr?: IWorkspaceIdentifier,
    ) {
        return this.openLinksGenerationModal(true, dgModule, null, spaceIdr);
    }

    public async openLinksGenerationModalForEntities(
        dgModule: DgModule,
        sourceEntity?: EntityItem,
        spaceIdr?: IWorkspaceIdentifier,
    ) {
        return this.openLinksGenerationModal(
            false,
            dgModule,
            sourceEntity,
            spaceIdr,
        );
    }

    private async openLinksGenerationModal(
        isGenerationForModule: boolean,
        dgModule: DgModule,
        sourceEntity?: EntityItem,
        spaceIdr?: IWorkspaceIdentifier,
    ) {
        const res = await this.userService.getUserSettingValue(
            'glossary-links-generation',
            'skip-help',
        );
        const skipHelp = coerceBooleanProperty(res.Value);
        return await this.dxyModalService.open<
            GlossaryLinksGenerationModalComponent,
            IGlossaryLinksGenerationInput,
            IGlossaryLinksGenerationOutput
        >({
            componentType: GlossaryLinksGenerationModalComponent,
            data: {
                sourceModule: dgModule,
                sourceEntity,
                spaceIdr,
                skipHelp,
                isGenerationForModule,
            },
        });
    }

    public glossaryAutoGenerationFuncLog(dgModule: DgModule) {
        switch (dgModule) {
            case DgModule.Glossary:
                return 'GLOSSARY_GENERATION_FROM_GLOSSARY,R';
            case DgModule.Catalog:
                return 'GLOSSARY_GENERATION_FROM_DICTIONARY,R';
            case DgModule.Usage:
                return 'GLOSSARY_GENERATION_FROM_USAGE,R';
        }
    }
}
