import { AfterViewInit, Directive, ElementRef, inject } from '@angular/core';

@Directive({
    selector: '[dxyMenuList]',
    standalone: true,
})
export class MenuListDirective implements AfterViewInit {
    private el = inject(ElementRef<HTMLElement>);

    ngAfterViewInit() {
        this.appendCssClass();
    }

    private appendCssClass() {
        this.el.nativeElement.classList.add('dxy-menu-list');
    }
}
