import { DefaultLogEntryAdapter } from './default-log-entry-adapter';
import { IActivityLogEntryTranslationData } from './activity-log-entry-translation.type';
import { HierarchicalData } from '@datagalaxy/dg-object-model';
import {
    ActivityLogEntry,
    ActivityLogEntryType,
} from '@datagalaxy/webclient/activity-log/domain';

/**
 * ## Role
 * Activity log adapter for logs concerning comments
 */
export class CommentaryLogEntryAdapter extends DefaultLogEntryAdapter {
    protected override getTranslateKey(entry: ActivityLogEntry) {
        const entryType = entry.EntryType;
        const key =
            entryType === ActivityLogEntryType.AddObject ||
            entryType === ActivityLogEntryType.Create
                ? 'addCommentary'
                : entryType == ActivityLogEntryType.Update
                  ? 'updateCommentary'
                  : entryType == ActivityLogEntryType.DeleteObject
                    ? 'deleteCommentary'
                    : '';
        return key && `${this.baseTranslateKey}${key}`;
    }

    protected override getHData(entry: ActivityLogEntry) {
        const hdd = this.getUsedDataDescriptor(entry, entry.Parents[0]);
        return hdd && new HierarchicalData(hdd, entry.Parents);
    }

    protected override setUpdateData(
        entry: ActivityLogEntry,
        data: IActivityLogEntryTranslationData,
    ) {
        const key = 'updateCommentary';
        this.setUpdateTranlateParams(key, entry, data);
    }
}
