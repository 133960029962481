<div *ngIf="showSplitButton" dxyButtonGroup>
    <button dxyButton [disabled]="disabled">
        {{ btnText }}
        <i *ngIf="isLoading" class="dg5-spinner xs-spinner"></i>
    </button>
    <button
        dxyIconButton
        dxyDropdownButton
        size="large"
        [matMenuTriggerFor]="menu"
        (click)="$event.stopImmediatePropagation()"
        (menuOpened)="openClose.emit(true)"
        (menuClosed)="openClose.emit(false)"
        [disabled]="disabled"
    ></button>
</div>

<button *ngIf="!showSplitButton" dxyButton [disabled]="disabled">
    {{ btnText }}
    <i *ngIf="isLoading" class="dg5-spinner xs-spinner"></i>
</button>

<mat-menu
    #menu="matMenu"
    class="dg5-mat-menu no-max-width split-button--mat-menu"
    yPosition="above"
    xPosition="before"
>
    <dxy-option-list [options]="options"></dxy-option-list>
</mat-menu>
