import { FilterModuleName } from '@datagalaxy/webclient/filter/domain';
import { DgZone } from '@datagalaxy/webclient/domain';
import { DgModule } from '@datagalaxy/shared/dg-module/domain';

export class FilteredViewsUtil {
    public static moduleNameFromZoneAndModule(
        dgZone: DgZone,
        dgModule?: DgModule,
    ): FilterModuleName | undefined {
        if (dgZone === DgZone.Search) {
            return FilterModuleName.MainSearch;
        } else if (dgZone === DgZone.Module) {
            switch (dgModule) {
                case DgModule.Catalog:
                    return FilterModuleName.Catalog;
                case DgModule.Processing:
                    return FilterModuleName.Processing;
                case DgModule.Glossary:
                    return FilterModuleName.Glossary;
                case DgModule.Usage:
                    return FilterModuleName.Usage;
            }
        }
        return undefined;
    }

    public static dgModuleFromModuleName(moduleName: FilterModuleName) {
        switch (moduleName) {
            case FilterModuleName.Glossary:
                return DgModule.Glossary;
            case FilterModuleName.Catalog:
                return DgModule.Catalog;
            case FilterModuleName.Processing:
                return DgModule.Processing;
            case FilterModuleName.Usage:
                return DgModule.Usage;
            case FilterModuleName.MainSearch:
            default:
                return DgModule.unknown;
        }
    }

    public static moduleNameOrderIndex(moduleName: FilterModuleName) {
        return [
            FilterModuleName.Glossary,
            FilterModuleName.Catalog,
            FilterModuleName.Processing,
            FilterModuleName.Usage,
            FilterModuleName.MainSearch,
        ].indexOf(moduleName);
    }

    public static getFilteredViewNameKeyFromModuleName(
        moduleName: FilterModuleName,
    ): string {
        switch (moduleName) {
            case FilterModuleName.Glossary:
                return 'glossary';
            case FilterModuleName.Catalog:
                return 'catalog';
            case FilterModuleName.Processing:
                return 'dataProcessing';
            case FilterModuleName.Usage:
                return 'software';
            case FilterModuleName.MainSearch:
            default:
                return '';
        }
    }
}
