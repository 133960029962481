import { Injectable } from '@angular/core';
import { BackendApiService } from '@datagalaxy/data-access';
import {
    ClientIntegrationParameter,
    ClientIntegrationResult,
    GetClientIntegrationsResult,
    GetUserIntegrationParameter,
    GetUserIntegrationResult,
} from './integration';

@Injectable({ providedIn: 'root' })
export class IntegrationApiService {
    constructor(private backendApiService: BackendApiService) {}

    public async getClientIntegrations(param: ClientIntegrationParameter) {
        return await this.backendApiService.postPromise(
            'Integration/GetClientIntegrations',
            param,
            GetClientIntegrationsResult,
        );
    }

    public async createClientIntegration(param: ClientIntegrationParameter) {
        return await this.backendApiService.postPromise(
            'Integration/CreateClientIntegration',
            param,
            ClientIntegrationResult,
        );
    }
    public async editClientIntegration(param: ClientIntegrationParameter) {
        return await this.backendApiService.postPromise(
            'Integration/UpdateClientIntegration',
            param,
            ClientIntegrationResult,
        );
    }
    public async deleteClientIntegration(param: ClientIntegrationParameter) {
        return await this.backendApiService.postPromise(
            'Integration/DeleteClientIntegration',
            param,
            ClientIntegrationResult,
        );
    }
    public async regenerateClientIntegration(
        param: ClientIntegrationParameter,
    ) {
        return await this.backendApiService.postPromise(
            'Integration/RegenerateToken',
            param,
            ClientIntegrationResult,
        );
    }

    public async getUserIntegration(param: GetUserIntegrationParameter) {
        return await this.backendApiService.postPromise(
            'Integration/GetUserIntegration',
            param,
            GetUserIntegrationResult,
        );
    }
}
