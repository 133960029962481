import { IFilterFormModel } from '../attribute-filter-form/IFilterFormModel';
import { BaseFilterListItemModel } from '../models/BaseFilterListItemModel';
import { AttributeFilterModel } from '../attribute-filter/attributeFilterModel';
import { FilterUtil } from '../../../util/FilterUtil';
import { HierarchicalData } from '@datagalaxy/dg-object-model';
import {
    AttributeMetaInfo,
    AttributeMetaType,
} from '@datagalaxy/webclient/attribute/domain';
import { FilterOperator } from '@datagalaxy/webclient/filter/domain';

export class AttributeEntityFilterModel extends BaseFilterListItemModel {
    public get parentId() {
        return this._parentId ?? this.spaceIdr?.spaceId;
    }
    public set parentId(value: string) {
        this._parentId = value;
    }
    private _parentId: string;

    constructor(
        filterFormData: IFilterFormModel,
        attributeMeta: AttributeMetaInfo,
        operators: FilterOperator[],
    ) {
        super(filterFormData, attributeMeta, operators);
        this.operator = FilterOperator.ListContains;
    }

    isValid() {
        if (FilterUtil.isValuelessOperator(this.operator)) {
            return true;
        }

        return this.values?.length > 0;
    }

    public getFilterValueDisplayName(
        getFilterValueName: (o: HierarchicalData) => string,
        getFilterValueNameAll: () => string,
    ) {
        if (this.values?.length) {
            return this.values
                .map((v) => getFilterValueName(v.hddData))
                .join(', ');
        }

        if (
            ![
                AttributeMetaType.EntityReference,
                AttributeMetaType.ObjectLink,
                AttributeMetaType.AllLinkedData,
            ].includes(this.attributeType)
        ) {
            return '';
        }

        return getFilterValueNameAll();
    }

    copy(filterItem: AttributeEntityFilterModel) {
        this.operator = filterItem.operator;
        this.values = filterItem.values?.slice();
    }

    protected sameTypeAndValue(other: AttributeFilterModel) {
        return (
            other instanceof AttributeEntityFilterModel &&
            super.sameValuesByKey(other)
        );
    }
}
