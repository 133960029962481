import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    OnInit,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DxyAttributeBaseInput } from '../../DxyAttributeBaseInput';
import { AttributeDataService } from '../../../attribute-data.service';
import { EntityTypeUtils } from '@datagalaxy/webclient/entity/utils';
import { NgClass } from '@angular/common';
import { EllipsisTooltipDirective } from '@datagalaxy/ui/tooltip';

/**
 * ## Role Display the entity type in a readonly text field
 */
@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'dxy-attribute-entity-type-input',
    templateUrl: './dxy-attribute-entity-type-input.component.html',
    styleUrls: ['./dxy-attribute-entity-type-input.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [EllipsisTooltipDirective, NgClass],
})
export class DxyAttributeEntityTypeInputComponent
    extends DxyAttributeBaseInput<string>
    implements OnInit
{
    protected value = '';
    protected glyphClass = '';

    constructor(
        elementRef: ElementRef<HTMLElement>,
        translate: TranslateService,
        private attributeDataService: AttributeDataService,
    ) {
        super(elementRef, translate);
    }

    ngOnInit() {
        const entityData = this.getEntityData();
        const entityType = entityData && entityData.EntityType;
        if (entityType === undefined) {
            return;
        }
        this.value =
            this.attributeDataService.getEntityTypeTranslation(entityType);

        this.glyphClass = EntityTypeUtils.getColoredGlyphClass(entityType);
        super.ngOnInit();
    }
}
