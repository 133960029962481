<div
    *ngIf="showActionButton"
    [matTooltip]="tooltipText"
    [matTooltipClass]="tooltipClass"
>
    <button
        type="button"
        dxyButton
        [variant]="action.buttonVariant ?? 'tertiary'"
        [color]="action.buttonColor ?? 'default'"
        size="medium"
        [class.dxy-icon-button]="!text"
        [disabled]="isDisabled"
        [ngClass]="action.optionClass"
        (click)="onOptionClicked($event)"
        (mousedown)="onOptionMouseDown($event)"
        [dxyDataTestId]="dataTestId"
    >
        <i *ngIf="glyphClass" [ngClass]="glyphClass"></i> {{ text }}
    </button>
</div>
<dxy-burger-menu-button
    *ngIf="showBurgerMenuButton"
    class="burger-menu-button"
    [glyphClass]="action.burgerMenuGlyphClass"
    [tooltipKey]="tooltipText"
    [options]="burgerMenuOptions"
    [data]="data"
    [captureMouseDown]="actionsCaptureMouseDown"
    [dataTestId]="dataTestId"
    (openClose)="openClose.emit($event)"
    (onLogFunctional)="onLogFunctional.emit($event)"
></dxy-burger-menu-button>
