import {
    LocalSynonymCreatedEvent,
    LocalSynonymDeletedEvent,
    LocalSynonymUpdatedEvent,
} from '@datagalaxy/dg-object-model';
import { getEntityActivityEventTranslationInputs } from '../common/dg-event-translation-adapter-helpers';
import { registerTypedEventTranslationAdapter } from '../../dg-event-translation-adapter-registry';
import { IDgEventTranslationAdapter } from '../../../interfaces/IDgEventTranslationAdapter';
import {
    IDgEventNavLink,
    IEventTranslationInputs,
} from '../../../interfaces/IEventTranslationInputs';
import { AttributeUpdatesTranslation } from '../../../interfaces/IAttributeUpdateTranslationInputs';
import { DgEventTranslationAdapterBase } from '../common/dg-event-translation-adapter-base';

const eventTypes = [
    LocalSynonymCreatedEvent,
    LocalSynonymUpdatedEvent,
    LocalSynonymDeletedEvent,
];
type TLocalSynonymEvent = InstanceType<(typeof eventTypes)[number]>;

/**
 * ## Role
 * Adapter to translate local synonym events
 */
@registerTypedEventTranslationAdapter(eventTypes)
export class LocalSynonymEventTranslationAdapter
    extends DgEventTranslationAdapterBase
    implements IDgEventTranslationAdapter
{
    constructor(private event: TLocalSynonymEvent) {
        super(event);
    }

    public getTranslationInputs(): IEventTranslationInputs {
        const inputs = getEntityActivityEventTranslationInputs(this.event);
        inputs.translateParameters.push({
            key: 'displayName',
            value: this.event.DisplayName,
        });
        inputs.translateParameters.push({
            key: 'description',
            value: this.event.Description,
        });

        if (this.event instanceof LocalSynonymUpdatedEvent) {
            this.setPreviousValueTranslationData(inputs, this.event);
        }
        return inputs;
    }

    private setPreviousValueTranslationData(
        inputs: IEventTranslationInputs,
        event: LocalSynonymUpdatedEvent,
    ) {
        inputs.translateParameters.push({
            key: 'previousDisplayName',
            value: event.PreviousDisplayName,
        });
        inputs.translateParameters.push({
            key: 'previousDescription',
            value: event.PreviousDescription,
        });
        const updateTranslations = new AttributeUpdatesTranslation();
        if (event.PreviousDisplayName != event.DisplayName) {
            updateTranslations.updates.push({
                attributeNameKey: 'common.displayName',
                previousValue: { text: event.PreviousDisplayName },
                value: { text: event.DisplayName },
            });
        }
        if (event.PreviousDescription != event.Description) {
            updateTranslations.updates.push({
                attributeNameKey: 'common.summary',
                previousValue: { text: event.PreviousDescription },
                value: { text: event.Description },
            });
        }
        if (!updateTranslations.updates.length) {
            return;
        }

        const navLink = {
            linkCssClass: 'nav-link-updates',
            parameters: updateTranslations,
        } as IDgEventNavLink<AttributeUpdatesTranslation>;
        inputs.navLinks.push(navLink);
    }
}
