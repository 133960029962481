import { Injectable } from '@angular/core';
import { RestApiService } from '@datagalaxy/data-access';
import {
    CampaignExistsQueryResult,
    CampaignsFromObjectResult,
    CreateCampaignCommand,
    CreateCampaignCommandResult,
    GetCampaignQueryResult,
    GetCampaignsQueryResult,
    UpdateCampaignCommand,
} from './campaign';
import { SetCampaignPhaseCommandResult } from './campaign-phase';
import {
    AddCampaignEntitiesCommand,
    AddCampaignEntitiesCommandResult,
    RemoveCampaignEntitiesCommand,
    RemoveCampaignEntitiesCommandResult,
} from './campaign-entity';
import { GetCampaignEventsQueryResult } from './campaign-event';

@Injectable({ providedIn: 'root' })
export class CampaignApiService {
    constructor(private restApiService: RestApiService) {}

    public async createCampaign(param: CreateCampaignCommand) {
        return await this.restApiService.postPromise<
            CreateCampaignCommandResult,
            CreateCampaignCommand
        >('campaigns', param);
    }

    public getCampaigns(spaceGuid: string, versionGuid: string) {
        return this.restApiService.getPromise<GetCampaignsQueryResult>(
            `campaigns/space/${spaceGuid}/version/${versionGuid}`,
        );
    }

    public async getCampaign(campaignGuid: string) {
        return await this.restApiService.getPromise<GetCampaignQueryResult>(
            `campaigns/${campaignGuid}`,
        );
    }

    public async getCampaignEvents(campaignGuid: string) {
        return await this.restApiService.getPromise<GetCampaignEventsQueryResult>(
            `campaigns/${campaignGuid}/events`,
        );
    }

    public async addCampaignEntities(param: AddCampaignEntitiesCommand) {
        return await this.restApiService.putPromise<
            AddCampaignEntitiesCommandResult,
            AddCampaignEntitiesCommand
        >(`campaigns/${param.Guid}/entities/version/${param.VersionId}`, param);
    }

    public async removeCampaignEntities(param: RemoveCampaignEntitiesCommand) {
        return await this.restApiService.putPromise<
            RemoveCampaignEntitiesCommandResult,
            RemoveCampaignEntitiesCommand
        >(`campaigns/${param.Guid}/entities/remove`, param);
    }

    public async setCampaignPhase(campaignGuid: string, phaseGuid: string) {
        return await this.restApiService.putPromise<SetCampaignPhaseCommandResult>(
            `campaigns/${campaignGuid}/phase/${phaseGuid}`,
        );
    }

    public async deleteCampaign(campaignGuid: string) {
        return await this.restApiService.deletePromise(
            `campaigns/${campaignGuid}`,
        );
    }

    public async updateCampaign(param: UpdateCampaignCommand) {
        return await this.restApiService.putPromise<
            void,
            UpdateCampaignCommand
        >(`campaigns/${param.CampaignGuid}`, param);
    }

    public async campaignExistsWithName(spaceGuid: string, name: string) {
        return await this.restApiService.getPromise<CampaignExistsQueryResult>(
            `campaigns/space/${spaceGuid}/name/${name}`,
        );
    }

    public async getCampaignsFromObject(
        referenceId: string,
        versionGuid: string,
    ) {
        return await this.restApiService.getPromise<CampaignsFromObjectResult>(
            `campaigns/entities/${referenceId}/version/${versionGuid}`,
        );
    }
}
