import { Injectable } from '@angular/core';
import {
    CampaignBriefDto,
    CampaignDto,
} from '@datagalaxy/webclient/campaign/data-access';
import { CampaignService } from './campaign.service';
import { CollectionsHelper } from '@datagalaxy/core-util';
import { EntityEventService } from '../shared/entity/services/entity-event.service';
import { coerceArray } from '@angular/cdk/coercion';
import { getLocalId } from '@datagalaxy/webclient/utils';
import { CampaignSignalrService } from '@datagalaxy/webclient/campaign/data-access';
import { BaseStateService } from '@datagalaxy/utils';
import { IWorkspaceIdentifier } from '@datagalaxy/webclient/workspace/domain';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';
import { filter } from 'rxjs';

export interface CampaignListState {
    spaceIdr: IWorkspaceIdentifier;
    initialized: boolean;
    campaigns: CampaignBriefDto[];
    selectedCampaign?: CampaignDto;
    loadingCampaigns: boolean;
    loadingCampaign: boolean;
}

@Injectable({ providedIn: 'root' })
export class CampaignStoreService extends BaseStateService<CampaignListState> {
    private static initialState: CampaignListState = {
        spaceIdr: null,
        loadingCampaigns: false,
        loadingCampaign: false,
        initialized: false,
        campaigns: [],
        selectedCampaign: null,
    };

    constructor(
        private campaignService: CampaignService,
        private campaignSignalR: CampaignSignalrService,
        private entityEventService: EntityEventService,
    ) {
        super(CampaignStoreService.initialState);
        this.subscribeEvents();
    }

    public selectState() {
        return this.select((s) => s);
    }

    public selectCampaigns() {
        return this.select((s) => s.campaigns);
    }

    public selectLoadingCampaigns() {
        return this.select((s) => s.loadingCampaigns);
    }

    public selectCampaign() {
        // filter out null values. Campaign could be null briefly when navigating
        return this.select((s) => s.selectedCampaign).pipe(filter((c) => !!c));
    }

    public clearState() {
        this.setState(CampaignStoreService.initialState);
    }

    public loadCampaignListState(spaceIdr: IWorkspaceIdentifier) {
        if (this.state.initialized) {
            return;
        }

        this.setState({ loadingCampaigns: true });

        this.campaignService.getCampaigns(spaceIdr).then((campaigns) => {
            this.setState({
                loadingCampaigns: false,
                initialized: true,
                campaigns,
                spaceIdr,
            });
        });
    }

    public loadCampaignDetailState(guid: string) {
        this.setState({ loadingCampaign: true });

        this.campaignService.getCampaign(guid).then((campaign) => {
            this.setState({
                loadingCampaign: false,
                selectedCampaign: campaign,
            });
        });
    }

    public async createCampaign(
        spaceIdr: IWorkspaceIdentifier,
        name: string,
        description: string,
        workflowId: string,
        ownerId: string,
        referenceIds: string[],
    ) {
        const campaignGuid = await this.campaignService.createCampaign(
            spaceIdr,
            name,
            description,
            workflowId,
            ownerId,
            referenceIds,
        );
        const campaign = await this.campaignService.getCampaign(campaignGuid);

        this.setState({
            campaigns: [...this.state.campaigns, campaign],
        });
        return campaignGuid;
    }

    public async setCampaignPhase(campaignGuid: string, phaseGuid: string) {
        const updatedCampaign = await this.campaignService.setCampaignPhase(
            campaignGuid,
            phaseGuid,
        );
        this.setState({
            selectedCampaign: updatedCampaign,
            campaigns: [
                ...this.state.campaigns.map((c) => {
                    if (c.Guid === campaignGuid) {
                        return {
                            ...c,
                            CurrentPhase: updatedCampaign.CurrentPhase,
                        };
                    }
                    return c;
                }),
            ],
        });
        return updatedCampaign;
    }

    public async deleteCampaign(campaignGuid: string) {
        await this.campaignService.deleteCampaign(campaignGuid);
        this.setState({
            campaigns: this.state.campaigns.filter(
                (c) => c.Guid !== campaignGuid,
            ),
        });
    }

    public async updateCampaign(name: string, description: string) {
        const selectedCampaign = this.state.selectedCampaign;
        await this.campaignService.updateCampaign(
            selectedCampaign.Guid,
            name,
            description,
            selectedCampaign.Owner.UserId,
        );

        const updatedCampaign = {
            ...selectedCampaign,
            Name: name,
            Description: description,
        };

        this.setState({
            campaigns: [
                ...CollectionsHelper.replaceOne(
                    this.state.campaigns,
                    (c) => c.Guid === updatedCampaign.Guid,
                    updatedCampaign,
                ),
            ],
            selectedCampaign: updatedCampaign,
        });
    }

    public async addCampaignEntities(
        campaignGuid: string,
        versionId: string,
        entityIds: string[],
    ) {
        const res = await this.campaignService.addCampaignEntities(
            campaignGuid,
            versionId,
            entityIds,
        );

        this.setState({ selectedCampaign: res });
        return res;
    }

    public async removeCampaignEntities(entityIds: string[]) {
        const res = await this.campaignService.removeCampaignEntities(
            this.state.selectedCampaign.Guid,
            entityIds,
        );

        this.setState({ selectedCampaign: res });
        return res;
    }

    private subscribeEvents() {
        this.campaignSignalR.created$.subscribe((event) =>
            this.onCampaignCreated(event.data),
        );
        this.campaignSignalR.updated$.subscribe((event) =>
            this.onCampaignUpdated(event.data),
        );
        this.campaignSignalR.phaseChanged$.subscribe((event) =>
            this.onCampaignUpdated(event.data),
        );
        this.campaignSignalR.entitiesAdded$.subscribe((event) =>
            this.onCampaignUpdated(event.data),
        );
        this.campaignSignalR.entitiesRemoved$.subscribe((event) =>
            this.onCampaignUpdated(event.data),
        );
        this.entityEventService.subscribeEntityUpdate(null, (event) =>
            this.onEntitiesUpdate(event),
        );
        this.entityEventService.subscribeEntityBulkUpdate(null, (event) =>
            this.onEntitiesUpdate(event),
        );
    }

    private async onCampaignUpdated(campaign: CampaignDto) {
        if (campaign.SpaceGuid !== getLocalId(this.state.spaceIdr?.spaceId)) {
            return;
        }
        const state = this.state;
        this.setState({
            campaigns: [
                ...CollectionsHelper.replaceOne(
                    state.campaigns,
                    (c) => c.Guid === campaign.Guid,
                    campaign,
                ),
            ],
            selectedCampaign:
                state.selectedCampaign?.Guid === campaign.Guid
                    ? campaign
                    : state.selectedCampaign,
        });
    }

    private onCampaignCreated(campaign: CampaignDto) {
        if (campaign.SpaceGuid !== getLocalId(this.state.spaceIdr?.spaceId)) {
            return;
        }
        this.setState({
            campaigns: [...this.state.campaigns, campaign],
        });
    }

    private async onEntitiesUpdate(entityItems: EntityItem | EntityItem[]) {
        const selectedCampaign = this.state.selectedCampaign;
        if (!selectedCampaign) {
            return;
        }
        const entities = coerceArray(entityItems);
        const hasEntity = entities.some((entity) =>
            selectedCampaign.ReferenceIds?.includes(entity.ReferenceId),
        );
        if (!hasEntity) {
            return;
        }
        const campaign = await this.campaignService.getCampaign(
            selectedCampaign.Guid,
        );
        if (!campaign) {
            return;
        }
        this.setState({ selectedCampaign: campaign });
    }
}
