import {
    baseMiniTagCollectionCellInputs,
    baseTagCollectionCellInputs,
    DxyColorPointCellComponent,
    ICollectionCellInputs,
    ICollectionCellParams,
    IColorPointCellData,
} from '@datagalaxy/core-ui/cell-components';
import { IAttributeCollectionCellParamsBuilder } from './attribute-collection-cell.types';
import {
    AttributeMetaValue,
    AttributeObjectValue,
} from '@datagalaxy/webclient/attribute/domain';

/** Builder to create ICollectionCellParams for an attribute tag list */
export class TagAttributeCollectionCellParamsBuilder
    implements IAttributeCollectionCellParamsBuilder
{
    private baseCellInputs: ICollectionCellInputs;

    constructor(
        private values: (AttributeObjectValue | AttributeMetaValue)[],
        private isMini?: boolean,
    ) {
        this.baseCellInputs = this.isMini
            ? baseMiniTagCollectionCellInputs
            : baseTagCollectionCellInputs;
    }

    buildParams(): ICollectionCellParams {
        return {
            inputs: {
                ...this.baseCellInputs,
                options: this.buildOptions(),
            },
            isMini: this.isMini,
        };
    }

    private buildOptions() {
        return this.values?.map((v) => {
            const { color, labelText } =
                v instanceof AttributeMetaValue
                    ? this.buildOptionFormMeta(v)
                    : this.buildOptionFormObject(v);
            return {
                renderData: {
                    renderer: DxyColorPointCellComponent,
                    param: {
                        value: {
                            color,
                            size: this.baseCellInputs.itemSize,
                        } as IColorPointCellData,
                    },
                },
                labelText,
            };
        });
    }
    private buildOptionFormMeta(value: AttributeMetaValue): {
        color: string;
        labelText: string;
    } {
        return {
            color: value.Color,
            labelText: value.translatedDisplayName,
        };
    }
    private buildOptionFormObject(value: AttributeObjectValue): {
        color: string;
        labelText: string;
    } {
        return {
            color: value.TagColor,
            labelText: value.DisplayName,
        };
    }
}
