import { BaseUnitaryFieldData } from './BaseUnitaryFieldData';
import { IUnitaryFieldActionsInput } from './IUnitaryFieldActionsInput';
import { IUnitaryFieldData } from './IUnitaryFieldData';
import { IBaseErrorData } from '../../util/app-types/errors.types';
import { BaseServiceResult, isApiError } from '@datagalaxy/data-access';

/** Provides unitary validation/save/undo for a set of fields */
export class UnitaryFieldValidator<T extends IUnitaryFieldData> {
    constructor(private opt: IUnitaryFieldValidatorOptions<T>) {}

    /** Returns the object to be passed to dxy-unitary-field-actions to display action icons  */
    public buildActionsInput(ufd: T): IUnitaryFieldActionsInput {
        const opt = this.opt;

        const hasUnitaryValidation = opt.hasUnitaryValidation
            ? () => opt.hasUnitaryValidation(ufd)
            : () => true;

        const isUndoVisible = () =>
            !opt.disableUndoBtn?.(ufd) &&
            ((hasUnitaryValidation() && ufd.isDirty()) ||
                (ufd.errorMessage && ufd.isServerError));

        const isValidateVisible = () =>
            hasUnitaryValidation() &&
            ufd.isDirty() &&
            !ufd.errorMessage &&
            !opt.hasExternalError?.(ufd);

        return {
            hasUnitaryValidation,

            get isActive() {
                return ufd.isActive;
            },
            get isValidated() {
                return ufd.isValidated;
            },
            get isValidating() {
                return ufd.isValidating;
            },
            get isErrorInValidating() {
                return ufd.isErrorInValidating;
            },

            isUndoVisible,
            undo: () => this.undo(ufd),

            isValidateVisible,
            validate: async () => await this.validate(ufd),

            get recentlyModifiedExternally() {
                return opt.recentlyModifiedExternally?.(ufd);
            },

            get canRestorePreviousValue() {
                return opt.canRestorePreviousValue?.(ufd);
            },
            restorePreviousValue() {
                opt.restorePreviousValue?.(ufd);
            },
        };
    }

    /** To be wired to a dxy-field-*'s *focus* event */
    public onFieldFocus(ufd: T) {
        this.log(ufd, 'onFieldFocus');
        this.setActive(ufd, true);
    }
    /** To be wired to a dxy-field-*'s *blur* event */
    public async onFieldBlur(ufd: T, event?: Event) {
        if (!ufd.isActive) {
            return;
        }
        if (ufd.isBluring || this.opt.disableValidationOnBlur?.(ufd)) {
            return;
        }
        const canValidate =
            !this.opt.canValidateOnBlur ||
            this.opt.canValidateOnBlur(ufd, event);
        ufd.isBluring = true;
        this.log(ufd, 'onFieldBlur', canValidate);
        try {
            if (canValidate) {
                await this.validate(ufd);
            }
        } finally {
            ufd.isBluring = false;
        }
    }

    public setActive(ufd: T, active: boolean) {
        active = !!active;
        const change = ufd.isActive != active;
        ufd.isActive = active;
        if (change) {
            this.log(ufd, 'isActive-change -> ', ufd.isActive);
        }
        if (ufd.isActive) {
            if (change) {
                this.opt.onActiveChange?.(ufd);
            }
            ufd.isErrorInValidating = false;
            this.opt.onActivated?.(ufd);
        } else {
            //Re-init original value
            ufd.initialValueSet = false;
        }
    }

    public async validate(ufd: T) {
        this.log(ufd, 'validate');
        let validated = false;
        if (await this.opt.onBeforeValidate(ufd)) {
            await this.validateInternal(ufd);
            validated = true;
        }
        this.opt.onAfterValidate?.(ufd, validated);
        this.opt.refreshUI?.();
    }

    public undo(ufd: T) {
        if (ufd.isValidating) {
            return;
        }
        this.log(ufd, 'undo', !!this.opt.resetToInitialState);

        this.opt.resetToInitialState?.(ufd);

        ufd.initialValueSet = false;
        this.resetMessages(ufd);
        ufd.isErrorInValidating = false;
        ufd.isValidated = true;
        this.setActive(ufd, false);

        this.opt.onAfterUndo?.(ufd);
        this.opt.refreshUI?.();
    }

    private async validateInternal(ufd: T) {
        if (ufd.isValidating) {
            this.log(ufd, 'validateInternal-isValidating');
            return;
        }
        ufd.isValidating = true;

        this.resetMessages(ufd);

        if (!ufd.isDirty()) {
            this.log(ufd, 'validateInternal-notDirty');
            this.setActive(ufd, false);
            ufd.initialValueSet = false;
            ufd.isValidating = false;
            return;
        }

        this.opt.emitNativeChangeEvent?.(ufd);

        if (this.opt.hasExternalError(ufd)) {
            this.log(ufd, 'validateInternal-hasExternalError');
            ufd.isValidating = false;
            return;
        }

        ufd.isErrorInValidating = false;
        ufd.isValidated = false;

        if (this.opt.isValid && !this.opt.isValid(ufd)) {
            this.log(ufd, 'validateInternal-invalid');
            this.setErrorInValidating(ufd, ufd.errorMessage, false);
        }

        try {
            const result = await this.opt.serverUpdate(ufd);
            this.log(ufd, 'validateInternal-serverUpdate-result', result);
            ufd.isErrorInValidating = false;
            ufd.isValidating = false;
            ufd.initialValueSet = false;
            ufd.isValidated = true;
            await this.opt.onServerSuccess(ufd, result);
            this.setActive(ufd, false);
        } catch (err) {
            ufd.isValidating = false;
            if (isApiError(err)) {
                this.setErrorInValidating(ufd, err.message, true, err.error);
            } else {
                throw err;
            }
        }
    }
    private setErrorInValidating(
        ufd: T,
        errorMessage?: string,
        isServerError?: boolean,
        result?: BaseServiceResult | IBaseErrorData,
    ) {
        ufd.isValidating = false;
        ufd.isValidated = false;
        ufd.isErrorInValidating = true;
        if (errorMessage != undefined) {
            this.opt.setError(ufd, errorMessage, isServerError, result);
        }
    }

    private resetMessages(ufd: T) {
        this.opt.resetMessages?.(ufd);
        this.opt.clearError(ufd);
    }

    private log(ufd: T, ...args: any[]) {
        this.opt.log?.(this.constructor.name, ufd.fieldKey, ...args);
    }
}

export interface IUnitaryFieldValidatorOptions<T extends IUnitaryFieldData> {
    //#region mandatory
    onBeforeValidate: (ufd: T) => Promise<boolean>;
    serverUpdate: (ufd: T) => Promise<BaseServiceResult>;
    onServerSuccess: (ufd: T, result: BaseServiceResult) => Promise<void>;
    hasExternalError: (ufd: T) => boolean;
    setError: (
        ufd: T,
        error: string,
        isServerError: boolean,
        result?: BaseServiceResult | IBaseErrorData,
    ) => void;
    clearError: (ufd: T) => void;
    //#endregion
    //#region optional
    log?: (...args: any[]) => void;
    resetMessages?: (ufd: T) => void;
    resetToInitialState?: (ufd: T) => void;
    onActiveChange?: (ufd: T) => void;
    onActivated?: (ufd: T) => void;
    emitNativeChangeEvent?: (ufd: T) => void;
    isValid?: (ufd: T) => boolean;
    onAfterValidate?: (ufd: T, validated: boolean) => void;
    onAfterUndo?: (ufd: T) => void;
    hasUnitaryValidation?: (ufd: T) => boolean;
    disableUndoBtn?: (ufd: T) => boolean;
    recentlyModifiedExternally?: (ufd: T) => boolean;
    canRestorePreviousValue?: (ufd: T) => boolean;
    restorePreviousValue?: (ufd: T) => void;
    disableValidationOnBlur?: (ufd: T) => boolean;
    canValidateOnBlur?: (ufd: T, event: Event) => boolean;
    refreshUI?: () => void;
    //#endregion - optional
}

/** Provides unitary validation/save/undo for a set of fields, using UnitaryFieldData objects */
export class SimpleUnitaryFieldValidator extends UnitaryFieldValidator<UnitaryFieldData> {
    constructor(
        serverUpdate: (ufd: UnitaryFieldData) => Promise<BaseServiceResult>,
        opt?: {
            noResetAfterSave?: boolean;
            log?: (...args: any[]) => void;
        },
    ) {
        super({
            onBeforeValidate: () => Promise.resolve(true),
            serverUpdate,
            log: opt?.log,
            onServerSuccess: () => Promise.resolve(),
            hasExternalError: (ufd) => ufd.hasExternalError(),
            setError: (ufd, error, isServerError) =>
                ufd.setError(error, isServerError),
            clearError: (ufd) => ufd.clearError(),
            onAfterUndo: (ufd) => ufd.resetAfterUndo(),
            onAfterValidate: opt?.noResetAfterSave
                ? undefined
                : (ufd) => ufd.resetAfterSave(),
        });
    }
}
export type UnitaryFieldData = BaseUnitaryFieldData<any>;
