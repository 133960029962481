import {
    AfterViewInit,
    Directive,
    ElementRef,
    HostListener,
    inject,
    input,
} from '@angular/core';
import { MenuComponent } from '../menu/menu.component';

@Directive({
    selector: '[dxyMenuItem]',
    standalone: true,
})
export class MenuItemDirective implements AfterViewInit {
    doNotCloseMenuOnClick = input(false);

    private el = inject(ElementRef<HTMLElement>);

    private menu = inject(MenuComponent);

    ngAfterViewInit() {
        this.appendCssClass();
    }

    @HostListener('click')
    onClick() {
        if (!this.menu || this.doNotCloseMenuOnClick()) {
            return;
        }
        this.menu.close();
    }

    private appendCssClass() {
        this.el.nativeElement.classList.add('dxy-menu-item');
    }
}
