<div
    class="quality-score-btn"
    [matTooltip]="'UI.TextQualityScore.Dropdown.btnTT' | translate"
    [matMenuTriggerFor]="menu"
>
    <div *ngIf="isOutdated" class="badge-halo"></div>
    <dxy-percentage-donut
        [percentage]="score"
        [noPercentCharacter]="true"
    ></dxy-percentage-donut>
</div>

<mat-menu #menu="matMenu" xPosition="before" class="dg5-mat-menu no-max-width">
    <div class="content-wrapper not-an-item">
        <div class="qs-section">
            <div class="qs-percentage">
                <i class="glyph glyph-robot2"></i>
                <span class="text" translate=""
                    >UI.TextQualityScore.Dropdown.PercentageTitle</span
                >
                <span>{{ score }}%</span>
            </div>
            <div class="qs-percentage-description" translate>
                UI.TextQualityScore.Dropdown.PercentageDescription
            </div>
        </div>
        <div class="separator-line"></div>
        <div class="qs-section">
            <div *ngIf="totalVoteCount" class="qs-votes">
                <div class="qs-votes-section">
                    <span class="qs-votes-count"
                        ><span translate
                            >UI.TextQualityScore.Dropdown.UserVotes</span
                        >
                        ({{ totalVoteCount }})</span
                    >
                </div>
                <div class="qs-votes-section">
                    <span class="qs-likes-count">{{ totalLikesCount }}</span>
                    <i class="glyph glyph-like-up"></i>
                    <div class="qs-votebar">
                        <div class="qs-likesbar"></div>
                    </div>
                    <i class="glyph glyph-like-down"></i>
                    <span class="qs-dislikes-count">{{
                        totalDislikesCount
                    }}</span>
                </div>
            </div>
            <div class="qs-user-vote dxy-actions">
                <span class="text" [matTooltip]="voteHintTooltip | translate">{{
                    voteHint | translate
                }}</span>
                <button
                    dxyIconButton
                    variant="tertiary"
                    color="success"
                    (click)="onToggleLike($event)"
                    [ngClass]="{ active: isLiked }"
                    [matTooltip]="likeTooltip | translate"
                >
                    <i class="glyph-like-up"></i>
                </button>
                <button
                    dxyIconButton
                    variant="tertiary"
                    color="destructive"
                    (click)="onToggleDislike($event)"
                    [ngClass]="{ active: isDisliked }"
                    [matTooltip]="dislikeTooltip | translate"
                >
                    <i class="glyph-like-down"></i>
                </button>
            </div>
        </div>
    </div>
</mat-menu>
