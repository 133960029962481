import { Component, forwardRef, Inject, OnInit } from '@angular/core';
import { DxyBaseModalComponent } from '@datagalaxy/ui/dialog';
import {
    MAT_DIALOG_DATA,
    MatDialogRef,
    MatDialogModule,
} from '@angular/material/dialog';
import {
    ICampaignAddEntitiesForm,
    ICampaignDetailsAddObjectModalInput,
    ICampaignDetailsAddObjectModalOutput,
} from './campaign-details-add-object-modal.types';
import {
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
    FormsModule,
    ReactiveFormsModule,
} from '@angular/forms';
import { IEntitySelectorInputs } from '../../shared/entitySelector/entity-selector.types';
import {
    CrudOperation,
    FunctionalLogService,
} from '@datagalaxy/shared/monitoring/data-access';
import { CampaignStoreService } from '../campaign-store.service';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';
import { AsyncPipe } from '@angular/common';
import { DxyModalFooterComponent } from '../../shared/dialogs/dxy-modal-footer/dxy-modal-footer.component';
import { DxyEntitySelectorFieldComponent } from '../../shared/entitySelector/dxy-entity-selector-field/dxy-entity-selector-field.component';
import { TranslateModule } from '@ngx-translate/core';
import { DxyIconButtonDirective } from '@datagalaxy/ui/buttons';

@Component({
    selector: 'app-campaign-details-add-object-modal',
    templateUrl: './campaign-details-add-object-modal.component.html',
    standalone: true,
    imports: [
        MatDialogModule,
        TranslateModule,
        DxyIconButtonDirective,
        FormsModule,
        ReactiveFormsModule,
        forwardRef(() => DxyEntitySelectorFieldComponent),
        DxyModalFooterComponent,
        AsyncPipe,
    ],
})
export class CampaignDetailsAddObjectModalComponent
    extends DxyBaseModalComponent<
        ICampaignDetailsAddObjectModalInput,
        ICampaignDetailsAddObjectModalOutput
    >
    implements OnInit
{
    protected formGroup: FormGroup<ICampaignAddEntitiesForm>;
    protected entities: EntityItem[] = [];
    protected entitySelectorData: IEntitySelectorInputs;

    protected get spaceIdr() {
        return this.data.spaceIdr;
    }
    protected get campaign() {
        return this.data.campaign;
    }

    constructor(
        @Inject(MAT_DIALOG_DATA) data: ICampaignDetailsAddObjectModalInput,
        dialogRef: MatDialogRef<CampaignDetailsAddObjectModalComponent>,
        fb: FormBuilder,
        private campaignStore: CampaignStoreService,
        private functionalLogService: FunctionalLogService,
    ) {
        super(dialogRef, data);
        this.formGroup = fb.group({
            entities: new FormControl(null, Validators.required),
        });
    }

    ngOnInit() {
        this.init();
    }

    protected async onSubmit() {
        const { entities } = this.formGroup.value;
        const entityIds = entities.map((entity) => entity.ReferenceId);
        const res = await this.campaignStore.addCampaignEntities(
            this.campaign.Guid,
            this.campaign.VersionId,
            entityIds,
        );
        this.functionalLogService.logFunctionalAction(
            'ADD_OBJECTS_TO_CAMPAIGN',
            CrudOperation.A,
        );
        this.result = { campaign: res };
        this.onCloseSubmit();
    }

    private init() {
        const ids = this.campaign.ReferenceIds;

        this.entitySelectorData = {
            spaceIdr: this.spaceIdr,
            excludedIds: ids,
        };
    }
}
