import {
    IDecoratedTypeEventConstructor,
    ITypedEvent,
    TEventTypeName,
} from '@datagalaxy/dg-object-model';
import { IDgEventTranslationAdapter } from '../interfaces/IDgEventTranslationAdapter';

export const dgEventTranslationAdapterMappings = new Map<
    TEventTypeName,
    DgEventTranslationAdapterBaseConstructor
>();

/**
 * decorator factory to register a typed events translation adapter
 * @param eventTypes List of event types this adapter supports
 * @example
 * -@registerTypedEventTranslationAdapter([MyObjectCreateEvent, MyObjectUpdatedEvent])
 * export class MyObjectEventTranslationAdapter
    extends BaseUserEventTranslationAdapter<TEvent> {
        ...
    }
 */
export function registerTypedEventTranslationAdapter(
    eventTypes: Array<new () => ITypedEvent>,
) {
    return function <
        TConstructor extends DgEventTranslationAdapterBaseConstructor,
    >(constructor: TConstructor) {
        for (const eventTypeName of eventTypes.map(
            getEventTypeNameFromConstructor,
        )) {
            addEntry(eventTypeName, constructor);
        }
        return constructor;
    };
}

function getEventTypeNameFromConstructor(constructor: new () => ITypedEvent) {
    return (constructor as unknown as IDecoratedTypeEventConstructor)
        .eventTypeName;
}

function addEntry(
    eventTypeName: string,
    constructor: DgEventTranslationAdapterBaseConstructor,
) {
    if (dgEventTranslationAdapterMappings.has(eventTypeName)) {
        return;
    }
    dgEventTranslationAdapterMappings.set(eventTypeName, constructor);
}

export type DgEventTranslationAdapterBaseConstructor = new (
    ...args: any[]
) => IDgEventTranslationAdapter;
