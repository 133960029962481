import {
    ChangeDetectionStrategy,
    Component,
    computed,
    input,
} from '@angular/core';
import { IEntityIdentifier } from '@datagalaxy/dg-object-model';
import { AttributeValueTranslationResult } from '../attribute-value-translation-result';
import { AttributeTranslationButtonComponent } from '../attribute-translation-button/attribute-translation-button.component';

@Component({
    selector: 'dxy-translation-input-actions',
    standalone: true,
    imports: [AttributeTranslationButtonComponent],
    providers: [],
    templateUrl: './translation-input-actions.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TranslationInputActionsComponent {
    entityIdentifier = input.required<IEntityIdentifier>();
    attributeName = input.required<string>();
    metalModelValue = input<string>('');
    translationResult = input<AttributeValueTranslationResult | undefined>();

    aiOriginTranslation = computed(() => {
        const translationResult = this.translationResult();
        return translationResult?.aiOrigin && translationResult.translation
            ? translationResult.translation
            : undefined;
    });
}
