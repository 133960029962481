import { inject } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivateFn,
    Router,
    RouterStateSnapshot,
} from '@angular/router';
import { FirstAccessService } from './first-access.service';
import { FeatureFlagService } from '@datagalaxy/webclient/feature-flag';

export const FirstAccessGuard: CanActivateFn = async () => {
    const firstAccessService = inject(FirstAccessService);
    const router = inject(Router);

    const isFirstAccessCompleted = await firstAccessService.isCompleted();

    if (isFirstAccessCompleted) {
        return router.createUrlTree(['/']);
    }

    return true;
};

export const ClientFirstAccessGuard: CanActivateFn = async (
    _route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
) => {
    const firstAccessService = inject(FirstAccessService);
    const featureFlagService = inject(FeatureFlagService);
    const router = inject(Router);

    const isFirstAccessCompleted = await firstAccessService.isCompleted();

    if (isFirstAccessCompleted) {
        return true;
    }

    firstAccessService.saveUrl(state.url);

    if (featureFlagService.isFeatureEnabled('ENABLE_AUTH_V2')) {
        return router.navigate(['/first-access']);
    }

    return router.navigate(['/login', 'first-access']);
};
