import { Injectable } from '@angular/core';
import { BaseService } from '@datagalaxy/core-ui';
import { DiagramIdentifier } from '../DiagramIdentifier';
import { DiagramData } from './DiagramData';
import { EntitySecurityService } from '../../shared/entity/services/entity-security.service';
import { AppDataService } from '../../services/app-data.service';
import { ModelerService } from '../../modeler/services/modeler.service';
import {
    DiagramEdgeDto,
    DiagramNodeDto,
    PublishingStatus,
} from '@datagalaxy/webclient/diagram/data-access';
import { LicenseLevel } from '@datagalaxy/webclient/license/domain';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';
import { ServerConstants } from '@datagalaxy/shared/server/domain';
import { WorkspaceStore } from '@datagalaxy/webclient/workspace/data-access';

@Injectable({ providedIn: 'root' })
export class DiagramSecurityService extends BaseService {
    //#region static

    public static getDiagramPublishingStatus(diagram: EntityItem) {
        return PublishingStatus[
            diagram?.getAttributeValue<string>(
                ServerConstants.Diagram.PublishingStatus,
            )
        ] as PublishingStatus;
    }
    public static isPrivateDiagram(diagram: EntityItem) {
        return (
            DiagramIdentifier.isDiagramEntity(diagram) &&
            DiagramSecurityService.getDiagramPublishingStatus(diagram) ==
                PublishingStatus.Private
        );
    }
    public static isPublicDiagram(diagram: EntityItem) {
        return (
            DiagramIdentifier.isDiagramEntity(diagram) &&
            DiagramSecurityService.getDiagramPublishingStatus(diagram) ==
                PublishingStatus.Public
        );
    }

    //#endregion

    public get isReader() {
        return (
            this.appDataService.currentUserLicenseLevel == LicenseLevel.Reader
        );
    }

    constructor(
        private entitySecurityService: EntitySecurityService,
        private workspaceStore: WorkspaceStore,
        private appDataService: AppDataService,
    ) {
        super();
    }

    public isReaderUserAndPublicDiagram(diagram: EntityItem) {
        return this.isReader && DiagramSecurityService.isPublicDiagram(diagram);
    }

    public canPublishDiagram(diagram: EntityItem) {
        return (
            DiagramSecurityService.isPrivateDiagram(diagram) &&
            diagram?.SecurityData?.HasWriteAccess &&
            !this.isReader
        );
    }
    public canRenameDiagram(diagram: EntityItem) {
        return (
            DiagramIdentifier.isDiagramEntity(diagram) &&
            diagram?.SecurityData?.HasManagementAccess &&
            !this.isReaderUserAndPublicDiagram(diagram)
        );
    }
    public canDeleteDiagram(diagram: EntityItem) {
        return (
            DiagramIdentifier.isDiagramEntity(diagram) &&
            diagram?.SecurityData?.HasDeleteAccess &&
            !this.isReaderUserAndPublicDiagram(diagram)
        );
    }
    public canCloneDiagram(diagram: EntityItem) {
        return DiagramIdentifier.isDiagramEntity(diagram);
    }

    public canUpdateDiagramContent(diagram: EntityItem) {
        return (
            DiagramIdentifier.isDiagramEntity(diagram) &&
            diagram?.SecurityData?.HasWriteAccess &&
            !this.isReaderUserAndPublicDiagram(diagram)
        );
    }

    public canUpdateDiagramAttributes(diagram: EntityItem) {
        return (
            DiagramIdentifier.isDiagramEntity(diagram) &&
            diagram?.SecurityData?.HasManagementAccess &&
            !this.isReaderUserAndPublicDiagram(diagram)
        );
    }

    public canCreateEntities(diagram: EntityItem) {
        return (
            DiagramIdentifier.isDiagramEntity(diagram) &&
            this.entitySecurityService.hasWriteAccessToAnyModule(
                this.workspaceStore.currentSpace,
            ) &&
            !this.isReaderUserAndPublicDiagram(diagram)
        );
    }
    public canCreateModelEntity(diagramData: DiagramData) {
        return (
            diagramData?.hasModel &&
            this.hasWriteAccessOnAllSources(diagramData) &&
            !this.isReaderUserAndPublicDiagram(diagramData.diagramEntity)
        );
    }

    public canManagePkSettings(diagramData: DiagramData) {
        return (
            this.hasWriteAccessOnMonoSource(diagramData) &&
            !this.isReaderUserAndPublicDiagram(diagramData.diagramEntity)
        );
    }
    public canManageFkSettings(diagramData: DiagramData) {
        return (
            this.hasWriteAccessOnMonoSource(diagramData) &&
            !this.isReaderUserAndPublicDiagram(diagramData.diagramEntity)
        );
    }

    public canCloneNodeAndEntity(diagramData: DiagramData, ne: DiagramNodeDto) {
        return (
            diagramData &&
            this.canUpdateDiagramContent(diagramData.diagramEntity) &&
            this.hasNodeEntityWriteAccess(diagramData, ne) &&
            (!diagramData.hasModel ||
                this.hasNodeModelWriteAccess(diagramData, ne))
        );
    }
    public canDeleteNodeAndEntity(
        diagramData: DiagramData,
        ne: DiagramNodeDto,
    ) {
        return (
            diagramData &&
            ne &&
            ne.EntityReferenceId != diagramData.diagramId &&
            this.canUpdateDiagramContent(diagramData.diagramEntity) &&
            diagramData.getNodeEntity(ne)?.SecurityData.HasDeleteAccess &&
            (!diagramData.hasModel ||
                this.hasNodeModelWriteAccess(diagramData, ne))
        );
    }

    public getFKEdgeAccessRights(diagramData: DiagramData, ee: DiagramEdgeDto) {
        if (!ee) {
            return {};
        }
        const dd = diagramData;
        const info = {
            source: dd.getModelEntityByModelId(
                this.getNodeModelId(dd.getNodeByRefId(ee.SourceNodeId)),
            ),
            target: dd.getModelEntityByModelId(
                this.getNodeModelId(dd.getNodeByRefId(ee.TargetNodeId)),
            ),
        };
        const hasWriteAccess = this.hasWriteAccessOnBoth(info);
        return {
            edit: hasWriteAccess,
            delete: hasWriteAccess,
            changeEnd: hasWriteAccess,
        };
    }
    private getNodeModelId(ne: DiagramNodeDto) {
        return ModelerService.getModelId(ne);
    }
    public getEntityLinkEdgeAccessRights(
        diagramData: DiagramData,
        ee: DiagramEdgeDto,
    ) {
        const hasWriteAccess = this.hasWriteAccessOnBoth(
            diagramData?.getEdgeEntities(ee),
        );
        return {
            delete: hasWriteAccess,
        };
    }

    public isEdgeReadOnly(diagramData: DiagramData, ee: DiagramEdgeDto) {
        const { source, target } = diagramData?.getEdgeEntities(ee) || {};
        return (
            !source?.SecurityData?.HasWriteAccess ||
            !target?.SecurityData?.HasWriteAccess
        );
    }

    private hasWriteAccessOnMonoSource(diagramData: DiagramData) {
        return diagramData?.monoSourceEntity?.SecurityData?.HasWriteAccess;
    }
    private hasWriteAccessOnAllSources(diagramData: DiagramData) {
        return diagramData?.sourceEntities.every(
            (se) => se.SecurityData?.HasWriteAccess,
        );
    }

    private hasNodeEntityWriteAccess(
        diagramData: DiagramData,
        ne: DiagramNodeDto,
    ) {
        return diagramData?.getNodeEntity(ne)?.SecurityData?.HasWriteAccess;
    }
    private hasNodeModelWriteAccess(
        diagramData: DiagramData,
        ne: DiagramNodeDto,
    ) {
        if (!diagramData) {
            return;
        }
        const modelId = this.getNodeModelId(ne);
        const modelEntity = diagramData.getModelEntityByModelId(modelId);
        return modelEntity?.SecurityData?.HasWriteAccess;
    }

    private hasWriteAccessOnBoth(entities: {
        source: EntityItem;
        target: EntityItem;
    }) {
        return (
            entities &&
            entities.source?.SecurityData.HasWriteAccess &&
            entities.target?.SecurityData.HasWriteAccess
        );
    }
}
