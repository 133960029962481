import { Injectable } from '@angular/core';
import { SuggestionService } from './suggestion.service';
import { CollectionsHelper } from '@datagalaxy/core-util';
import {
    IEntitySuggestionState,
    ILinkSuggestionWithHDD,
    ISuggestionGroup,
    ISuggestionUserChoice,
} from './suggestion.types';
import { Observable } from 'rxjs';
import { RealTimeCommService } from '../services/realTimeComm.service';
import { AttributeSuggestionAdapterFactory } from './suggestion-adapter/attribute-suggestion-adapter.factory';
import { LinkSuggestionAdapterFactory } from './suggestion-adapter/link-suggestion-adapter.factory';
import { GenericDeserialize } from 'cerialize';
import { HierarchicalData } from '@datagalaxy/dg-object-model';
import { SuggestionData } from '@datagalaxy/webclient/suggestion/types';
import { LinkSuggestionDto } from '@datagalaxy/webclient/suggestion/data-access';
import { getLocalId } from '@datagalaxy/utils';
import { EntityEventService } from '../shared/entity/services/entity-event.service';
import { BaseStateService } from '@datagalaxy/utils';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';

/**
 * ## Role
 * Store and handle suggestion events for an entity
 */
@Injectable({ providedIn: 'root' })
export class EntitySuggestionStoreService extends BaseStateService<IEntitySuggestionState> {
    public get suggestionsCount() {
        return CollectionsHelper.sum(
            this.suggestionGroups,
            (group) => group?.suggestions?.length,
        );
    }

    public get suggestionGroups() {
        return this.getSuggestionGroupsFromState(this.state);
    }

    constructor(
        realTimeCommService: RealTimeCommService,
        entityEventService: EntityEventService,
        private suggestionService: SuggestionService,
        private attributeSuggestionAdapter: AttributeSuggestionAdapterFactory,
        private linkSuggestionAdapter: LinkSuggestionAdapterFactory,
    ) {
        super({
            loading: false,
        });

        realTimeCommService.subscribeAttributeSuggestionEvent(
            (_userId: string, suggestions: SuggestionData[]) =>
                this.updateSuggestionsFromRTEvent(
                    suggestions.map((s) =>
                        GenericDeserialize(s, SuggestionData),
                    ),
                ),
        );

        entityEventService.subscribeEntityLinkAdd(null, (entity) => {
            if (this.state.entity?.ReferenceId === entity?.ReferenceId) {
                this.loadEntitySuggestions(this.state.entity);
            }
        });

        suggestionService.userChoice$.subscribe((event) =>
            this.updateSuggestionsOnUserChoice(event),
        );
    }

    public selectAttributeSuggestionGroup(
        attributeKey: string,
    ): Observable<ISuggestionGroup | null> {
        return this.select((state) => {
            const suggestions = state.attributeSuggestions;
            if (!suggestions) {
                return null;
            }

            const suggestionGroup =
                this.attributeSuggestionAdapter.getAttributeSuggestionGroup(
                    state.entity?.ServerType,
                    attributeKey,
                    suggestions,
                );
            return suggestionGroup.suggestions?.length ? suggestionGroup : null;
        });
    }

    public selectSuggestionGroups() {
        return this.select((state) => this.getSuggestionGroupsFromState(state));
    }

    public selectLoading() {
        return this.select((state) => state.loading);
    }

    public setEntity(entity: EntityItem) {
        if (this.state.entity?.ReferenceId === entity.ReferenceId) {
            return;
        }
        this.setState({
            entity,
            loading: true,
        });
        void this.loadEntitySuggestions(entity);
    }

    public clearState() {
        this.setState({
            entity: null,
            loading: false,
        });
    }

    private async loadEntitySuggestions(entity: EntityItem) {
        const versionId = getLocalId(entity.VersionId);
        const entityGuid = getLocalId(entity.ReferenceId);
        const linkResult =
            await this.suggestionService.getEntityLinkSuggestions(
                versionId,
                entityGuid,
            );

        this.setState({
            entity,
            loading: false,
            attributeSuggestions: entity.EntitySuggestions,
            linkSuggestions: this.buildLinkILinkSuggestionWithHDDs(
                linkResult.LinkSuggestions,
                linkResult.HierarchicalData,
            ),
        });
    }

    private getSuggestionGroupsFromState(
        state: IEntitySuggestionState,
    ): ISuggestionGroup[] {
        return CollectionsHelper.concat<ISuggestionGroup>(
            this.attributeSuggestionAdapter.getAttributeSuggestionLists(
                state.entity?.ServerType,
                state.attributeSuggestions,
            ),
            this.linkSuggestionAdapter.getLinkSuggestionLists(
                state.linkSuggestions,
            ),
        );
    }

    /**
     * ## Note
     * For now, only attribute suggestions for Tags & CDP are emitted from realtime event
     * So we have to keep link suggestions
     */
    private updateSuggestionsFromRTEvent(suggestions: SuggestionData[]) {
        const entity = this.state.entity;
        const suggestionElements = suggestions
            .filter(
                (se) =>
                    se.ReferenceId === entity.ReferenceId &&
                    se.VersionId === entity.VersionId,
            )
            .flatMap((se) => se.SuggestionElements);

        this.setState({
            ...this.state,
            attributeSuggestions: suggestionElements,
        });
    }

    private updateSuggestionsOnUserChoice(event: ISuggestionUserChoice) {
        const ids = event.ids;
        const state = this.state;

        this.setState({
            ...state,
            attributeSuggestions: state.attributeSuggestions.filter(
                (se) => !ids.includes(se.Hashcode),
            ),
            linkSuggestions: state.linkSuggestions.filter(
                (ls) => !ids.includes(ls.linkSuggestion.Guid),
            ),
        });
    }

    private buildLinkILinkSuggestionWithHDDs(
        linkSuggestions: LinkSuggestionDto[],
        hierarchicalData: HierarchicalData[],
    ): ILinkSuggestionWithHDD[] {
        return linkSuggestions.map((ls) => {
            const hdSource = hierarchicalData.find(
                (hd) =>
                    getLocalId(hd.Data?.DataReferenceId) === ls.SourceGuid &&
                    hd.VersionId === ls.VersionId,
            );
            const hdTarget = hierarchicalData.find(
                (hd) =>
                    getLocalId(hd.Data?.DataReferenceId) === ls.TargetGuid &&
                    hd.VersionId === ls.VersionId,
            );
            return {
                linkSuggestion: ls,
                target: hdTarget,
                source: hdSource,
            };
        });
    }
}
