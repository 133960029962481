import { Directive, Input, OnInit, SimpleChange } from '@angular/core';
import { GridComponent, PersistedGridState } from '@datagalaxy/ui/grid';
import { debounceTime } from 'rxjs/operators';
import { DxyBaseDirective } from '@datagalaxy/ui/core';
import { UserGridSettingsService } from './user-grid-settings.service';

@Directive({
    standalone: true,
    selector: 'dxy-grid[appGridUserSettings]',
})
export class GridUserSettingsDirective
    extends DxyBaseDirective
    implements OnInit
{
    @Input() appGridUserSettings: string;
    @Input('appGridUserSettingsDisabled') disabled?: boolean;

    constructor(
        private host: GridComponent<any>,
        private userGridSettingsService: UserGridSettingsService,
    ) {
        super();
    }

    ngOnInit() {
        if (this.disabled) {
            return;
        }

        void this.initAsync();

        const gridState$ = this.host.gridStateChange.pipe(debounceTime(500));

        super.subscribe(gridState$, (gridState) =>
            this.onGridStateChange(gridState),
        );
    }

    private async initAsync() {
        const gridId = this.appGridUserSettings;
        this.host.startLoading();
        const gridState =
            await this.userGridSettingsService.getGridState(gridId);

        if (!gridState) {
            this.host.endLoading();
            return;
        }
        this.host.endLoading();
        this.host.gridState = gridState;
        this.host.ngOnChanges({
            gridState: new SimpleChange(null, gridState, false),
        });
    }

    private async onGridStateChange(gridState: PersistedGridState) {
        if (this.disabled) {
            return;
        }

        await this.userGridSettingsService.saveGridState(
            this.appGridUserSettings,
            gridState,
        );
    }
}
