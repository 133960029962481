import { Directive, ElementRef, inject, input } from '@angular/core';
import {
    Overlay,
    OverlayPositionBuilder,
    OverlayRef,
} from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { TooltipComponent } from '../tooltip/tooltip.component';
import { TooltipPosition, tooltipPositions } from '../tooltip/tooltip-position';
import { fromEvent, switchMap, takeUntil, timer, filter } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { DXY_TOOLTIP_OPTIONS } from './tooltip-options';

@Directive({
    selector: '[dxyTooltip]',
    standalone: true,
})
export class TooltipDirective {
    text = input.required<string>({ alias: 'dxyTooltip' });
    position = input<TooltipPosition>('above', { alias: 'dxyTooltipPosition' });

    private overlay = inject(Overlay);
    private overlayPositionBuilder = inject(OverlayPositionBuilder);
    private elementRef = inject(ElementRef<HTMLElement>);
    private options = inject(DXY_TOOLTIP_OPTIONS);

    private overlayRef?: OverlayRef;

    constructor() {
        const element = this.elementRef.nativeElement;
        const mouseEnter$ = fromEvent(element, 'mouseenter');
        const mouseLeave$ = fromEvent(element, 'mouseleave');
        const mouseClick$ = fromEvent(element, 'click');

        mouseEnter$
            .pipe(
                takeUntilDestroyed(),
                filter(() => !this.overlayRef?.hasAttached()),
                switchMap(() =>
                    timer(this.options.showDelay).pipe(takeUntil(mouseLeave$)),
                ),
            )
            .subscribe(() => this.show());

        mouseLeave$
            .pipe(
                takeUntilDestroyed(),
                switchMap(() =>
                    timer(this.options.hideDelay).pipe(takeUntil(mouseEnter$)),
                ),
            )
            .subscribe(() => this.hide());

        mouseClick$.pipe(takeUntilDestroyed()).subscribe(() => this.hide());
    }

    private show() {
        if (this.overlayRef) {
            this.overlayRef.dispose();
        }

        const text = this.text();

        if (!text) {
            return;
        }

        const tooltipPortal = new ComponentPortal(TooltipComponent);
        const positionStrategy = this.overlayPositionBuilder
            .flexibleConnectedTo(this.elementRef)
            .withPositions(tooltipPositions[this.position()])
            .withPush(false);

        this.overlayRef = this.overlay.create({
            positionStrategy,
        });

        const tooltipRef = this.overlayRef.attach(tooltipPortal);

        tooltipRef.setInput('text', text);

        tooltipRef.changeDetectorRef.detectChanges();
    }

    private hide() {
        this.overlayRef?.detach();
    }
}
