import { Component, Inject, OnInit } from '@angular/core';
import {
    MatDialogRef,
    MAT_DIALOG_DATA,
    MatDialogModule,
} from '@angular/material/dialog';
import { DxyBaseModalComponent } from '@datagalaxy/ui/dialog';
import { VersionStore } from '../helpers/versionStore';
import { VersioningEventService } from '../services/versioning-event.service';
import { VersioningUiService } from '../services/versioning-ui.service';
import { IVersioningNavigatorModalResolve } from '../versioning-navigator.types';
import { ProjectVersion } from '@datagalaxy/webclient/versioning/data-access';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';
import { NgIf, NgFor } from '@angular/common';
import {
    DxyButtonDirective,
    DxyIconButtonDirective,
} from '@datagalaxy/ui/buttons';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'dxy-versioning-navigator-modal',
    templateUrl: './dxy-versioning-navigator-modal.component.html',
    styleUrls: ['./dxy-versioning-navigator-modal.component.scss'],
    standalone: true,
    imports: [
        MatDialogModule,
        TranslateModule,
        DxyIconButtonDirective,
        DxyButtonDirective,
        NgIf,
        NgFor,
        MatLegacyTooltipModule,
    ],
})
export class DxyVersioningNavigatorModalComponent
    extends DxyBaseModalComponent<IVersioningNavigatorModalResolve, void>
    implements OnInit
{
    public get hasOfficialVersion() {
        return this.versionStore.hasOfficial();
    }
    public get hasActiveVersions() {
        return this.versionStore.hasActive();
    }
    public get hasArchivedVersions() {
        return this.versionStore.hasArchived();
    }
    public get hasCandidateVersions() {
        return this.versionStore.hasReleaseCandidate();
    }
    public get hasAdminAccess() {
        return this.projectData?.ProjectSecurityData.HasManagementAccess;
    }

    public get activeVersions() {
        return this.versionStore.getActives();
    }
    public get candidateVersions() {
        return this.versionStore.getReleaseCandidates();
    }
    public get archivedVersions() {
        return this.versionStore.getAllArchived();
    }
    public get officialVersionName() {
        return this.getVersionName(this.versionStore.getOfficial());
    }

    private readonly versionStore = new VersionStore();
    public get projectData() {
        return this.data?.projectData;
    }

    constructor(
        private versioningUiService: VersioningUiService,
        private versioningEventService: VersioningEventService,
        public dialogRef: MatDialogRef<
            DxyVersioningNavigatorModalComponent,
            void
        >,
        @Inject(MAT_DIALOG_DATA) data: IVersioningNavigatorModalResolve,
    ) {
        super(dialogRef, data);
    }

    ngOnInit() {
        super.subscribe(this.versioningEventService.createVersion$, (event) =>
            this.onAddOrUpdateVersion(event.data),
        );
        super.subscribe(this.versioningEventService.updateVersion$, (event) =>
            this.onAddOrUpdateVersion(event.data),
        );
        super.subscribe(
            this.versioningEventService.updateVersionStatus$,
            (event) => this.onUpdateVersionStatus(event.data),
        );
        this.loadProjectVersions();
    }

    public goToVersion(projectVersion: ProjectVersion) {
        this.versioningUiService
            .goToVersion(projectVersion)
            .then(() => this.onCloseCancel());
    }

    public goToOfficialVersion() {
        this.goToVersion(this.versionStore.getOfficial());
    }

    public async onCreateProjectVersion() {
        const result = await this.versioningUiService.createProjectVersion(
            this.projectData.ReferenceId,
        );
        if (result) {
            this.goToVersion(result.CreatedVersion);
        }
    }

    public getVersionName(projectVersion: ProjectVersion) {
        return projectVersion.VersionName;
    }

    private loadProjectVersions() {
        this.versioningUiService
            .getProjectVersions(this.projectData.ReferenceId)
            .then((projectVersions) => this.versionStore.init(projectVersions));
    }

    private isVersionIncludedInCurrentProject(projectReferenceId: string) {
        return this.projectData.ReferenceId === projectReferenceId;
    }

    private onAddOrUpdateVersion(version: ProjectVersion) {
        if (
            this.isVersionIncludedInCurrentProject(version.ProjectReferenceId)
        ) {
            this.versionStore.set(version);
        }
    }

    private onUpdateVersionStatus(versions: ProjectVersion[]) {
        versions
            .filter((version) =>
                this.isVersionIncludedInCurrentProject(
                    version.ProjectReferenceId,
                ),
            )
            .forEach((version) => this.versionStore.set(version));
    }
}
