type TItem = { getWidth(): number };
type TScrollerItem = {
    w: number;
    start: number;
    end: number;
};

const EXTRA_SCROLL_PADDING = 40;

export class FitlerCarouselScroller {
    public translateX = 0;
    public get minTranslateX() {
        const min = Math.min(this.width - this.filtersWidth, 0);
        return min < -1 ? min : 0;
    }
    private get filtersWidth() {
        return this.scrollerItems.reduce((previousValue, item) => {
            return previousValue + item.w;
        }, 0);
    }
    private get scrollerItems(): TScrollerItem[] {
        let totalW = 0;
        return this.items.map((i) => {
            return {
                w: i.getWidth(),
                start: totalW,
                end: (totalW += i.getWidth()),
            };
        });
    }

    constructor(
        public items: TItem[],
        public width: number,
    ) {}

    /**
     * Scroll to the left ensuring the first left ellipsed item will be entirely visible
     */
    public scrollLeft() {
        if (!this.hasAScroll()) {
            return;
        }
        const start = -this.translateX;
        const firstEllipsedItem = this.scrollerItems
            .filter((i) => i.start < start)
            .sort((a, b) => b.end - a.end)[0];

        if (!firstEllipsedItem) {
            return;
        }

        if (firstEllipsedItem.start == 0) {
            this.translateX = 0;
            return;
        }

        const delta = firstEllipsedItem.end - start;
        const newX =
            this.translateX + (this.width - delta - EXTRA_SCROLL_PADDING);
        this.translateX = Math.min(newX, 0);
    }
    /**
     * Scroll to the right ensuring the first right ellipsed item will be entirely visible
     */
    public scrollRight() {
        if (!this.hasAScroll()) {
            return;
        }
        const start = -this.translateX;
        const end = start + this.width;
        const firstEllipsedItem = this.scrollerItems
            .filter((i) => i.end > end)
            .sort((a, b) => a.start - b.start)[0];

        if (!firstEllipsedItem) {
            return;
        }

        if (firstEllipsedItem.end == this.scrollerItems.slice(-1)[0].end) {
            this.scrollToEnd();
            return;
        }

        const delta = end - firstEllipsedItem.start;
        const newX =
            this.translateX - (this.width - delta - EXTRA_SCROLL_PADDING);
        this.translateX = Math.max(newX, this.minTranslateX);
    }

    public refresh() {
        if (!this.hasAScroll()) {
            this.translateX = 0;
            return;
        }
        if (this.translateX < this.minTranslateX) {
            this.scrollToEnd();
        }
    }

    public scrollToEnd() {
        this.translateX = this.minTranslateX;
    }

    private hasAScroll() {
        if (!this.items?.length) {
            return false;
        }
        return this.filtersWidth > this.width;
    }
}
