import {
    autoserializeAs,
    inheritSerialization,
    SerializableEnumeration,
} from 'cerialize';
import { BaseServiceParameter } from '@datagalaxy/data-access';

export enum CrudOperation {
    C,
    R,
    U,
    D,
    A,
}
SerializableEnumeration(CrudOperation);

/** Note: this is a string enum */
export enum CrudActionType {
    Add = 'ADD',
    Hide = 'HIDE',
    Unhide = 'UNHIDE',
    Favorite = 'FAVORITE',
    Unfavorite = 'UNFAVORITE',
    ViewDiagram = 'VIEW_DIAGRAM',
    Import = 'IMPORT',
    Download = 'DOWNLOAD',
    Export = 'EXPORT',
    Remove = 'REMOVE',
    Accept = 'ACCEPT',
    Decline = 'DECLINE',
    AcceptAll = 'ACCEPT_ALL',
    DeclineAll = 'DECLINE_ALL',
    Like = 'LIKE',
    Dislike = 'DISLIKE',
    CancelLike = 'CANCEL_LIKE',
    CancelDislike = 'CANCEL_DISLIKE',
    CheckImport = 'CHECK_IMPORT',
    Propagate = 'PROPAGATE',
    RetrieveTemplate = 'RETRIEVE_TEMPLATE',
    On = 'ON',
    Off = 'OFF',
    ChangeConnectorPortSide = 'CHANGE_CONNECTOR_PORT_SIDE',
    ExpandUpstream = 'EXPAND_UPSTREAM',
    ExpandDownstream = 'EXPAND_DOWNSTREAM',
    ExpandBusiness = 'EXPAND_BUSINESS',
    ExpandTechnical = 'EXPAND_TECHNICAL',
    UndoDownStream = 'COLLAPSE_DOWNSTREAM',
    UndoUpStream = 'COLLAPSE_UPSTREAM',
    UndoBusinessStream = 'COLLAPSE_BUSINESS',
    UndoTechnicalStream = 'COLLAPSE_TECHNICAL',
    SaveAsDiagram = 'SAVE_AS_DIAGRAM',
}
SerializableEnumeration(CrudActionType);

@inheritSerialization(BaseServiceParameter)
export class LogFunctionalActionParameter extends BaseServiceParameter {
    @autoserializeAs(String) public FeatureCode: string;
    @autoserializeAs(CrudOperation) public CrudOperation: CrudOperation;
    @autoserializeAs(Object) public ClientContext: object;
    @autoserializeAs(String) public SourceDOM: string;
    @autoserializeAs(String) public ActionType: string;

    constructor(
        featureCode: string,
        crudOperation: CrudOperation,
        clientContext: object,
        sourceDOM: string,
        actionType: CrudActionType,
    ) {
        super();
        this.FeatureCode = featureCode;
        this.CrudOperation = crudOperation;
        this.ClientContext = clientContext;
        this.SourceDOM = sourceDOM;
        this.ActionType = actionType;
    }
}
