import {
    Component,
    EventEmitter,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
    MatLegacyMenuTrigger as MatMenuTrigger,
    MatLegacyMenuModule,
} from '@angular/material/legacy-menu';
import { IFunctionalEvent } from '../../IFunctionalEvent';
import {
    BurgerMenuService,
    CoreEventsService,
    IBurgerMenuConfiguration,
    IBurgerMenuData,
} from '../../services';
import { IListOption, ListOptionUtil } from '../../IListOption';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { DxyBurgerMenuComponent } from '../burger-menu/burger-menu.component';

/**
 * ## Role
 * Global app burger menu
 *
 * ## Notes
 * - Have to be instanciated only once
 * - Must be used throught BurgerMenuService
 */
@Component({
    selector: 'dxy-mat-burger-menu',
    templateUrl: './mat-burger-menu.component.html',
    styleUrls: ['./mat-burger-menu.component.scss'],
    standalone: true,
    imports: [MatLegacyMenuModule, DxyBurgerMenuComponent],
})
export class DxyMatBurgerMenuComponent
    extends DxyBaseComponent
    implements OnInit
{
    @Output() readonly onLogFunctional = new EventEmitter<IFunctionalEvent>();

    public options: IListOption[];
    public position?: { top: number; left: number };

    public get visible() {
        return this.menuTrigger.menuOpen;
    }

    @ViewChild(MatMenuTrigger) private menuTrigger: MatMenuTrigger;

    constructor(
        private translate: TranslateService,
        private burgerMenuService: BurgerMenuService,
        coreEventsService: CoreEventsService,
    ) {
        super();
        super.subscribe(coreEventsService.windowKeyDownEscape$, (e) =>
            this.onKeydownEscape(e),
        );
    }

    ngOnInit() {
        super.subscribe(this.burgerMenuService.onBurgerMenu$, (data) =>
            this.onBurgerMenuEvent(data),
        );
    }

    public getLabel(option: IListOption) {
        return ListOptionUtil.getText(option, this.translate);
    }

    public onClick(opt: IListOption, event: Event) {
        if (opt.logFunctional) {
            this.onLogFunctional.emit({
                text: String(opt.logFunctional),
                origin: event.target as HTMLElement,
            });
        }
        opt.callback();
    }

    public onMenuClose() {
        this.burgerMenuService.close();
    }

    private onBurgerMenuEvent(data: IBurgerMenuData) {
        if (data.open) {
            this.openMenu(data.configuration);
            return;
        }
        this.closeMenu();
    }

    private openMenu(configuration: IBurgerMenuConfiguration) {
        this.options = configuration.options;
        this.position = configuration.position;
        this.menuTrigger.openMenu();
    }

    private closeMenu() {
        this.menuTrigger?.closeMenu();
    }

    private onKeydownEscape(event: KeyboardEvent) {
        if (!this.visible) {
            return;
        }
        event.stopPropagation();
        this.burgerMenuService.close();
    }
}
