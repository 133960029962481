import { IContextMenuActionProvider, IListOption } from '../IListOption';
import { CoreUtil } from '@datagalaxy/core-util';

/** base class for a sub-component providing drop-down menu options */
export abstract class BurgerMenuActionProviderBase<TAction, TItem, TContext>
    implements IContextMenuActionProvider<TItem, TContext, TItem>
{
    public onExecute: () => void;

    protected actionDefs: IActionDef<TAction, TItem, TContext>[];

    constructor(public debug = false) {}

    /** returns true if the context menu is available for this item and state - ie has any available action */
    public isAvailableFor(item: TItem, context: TContext) {
        const result = this.actionDefs.some((a) =>
            CoreUtil.fromFnOrValue(a.isAvailable, item, context),
        );
        this.log('isAvailableFor', item, context, result);
        return result;
    }

    /** return the menu options for the given item and context */
    public getOptions(
        item: TItem,
        context: TContext,
    ): IListOption<void, TItem>[] {
        return (
            this.actionDefs
                ?.filter((ad) => ad && ad.actionId != undefined && ad.execute)
                .map((ad) => ({
                    callback: (data: TItem) => {
                        this.log('callback', ad.actionId, item, data);
                        if (typeof this.onExecute == 'function') {
                            this.onExecute();
                        }
                        ad.execute(data, context);
                    },
                    hidden: (data: TItem) =>
                        !CoreUtil.fromFnOrValue(ad.isAvailable, data, context),
                    labelKey: this.getTranslateKey(ad.actionId, context, item),
                    glyphClass:
                        ad.glyphClass == undefined
                            ? undefined
                            : (data: TItem) => {
                                  const c = CoreUtil.fromFnOrValue(
                                      ad.glyphClass,
                                      data,
                                      context,
                                  );
                                  return c ? c + ' option-glyph' : undefined;
                              },
                    tooltipTranslateKey:
                        ad.tooltipKey == undefined
                            ? undefined
                            : (data: TItem) =>
                                  CoreUtil.fromFnOrValue(
                                      ad.tooltipKey,
                                      data,
                                      context,
                                  ),
                    htmlContent: this.getHtmlContent(ad.actionId, context),
                    optionClass:
                        ad.optionClass == undefined
                            ? undefined
                            : (data: TItem) =>
                                  CoreUtil.fromFnOrValue(
                                      ad.optionClass,
                                      data,
                                      context,
                                  ),
                    disabled:
                        ad.disabled == undefined
                            ? undefined
                            : (data: TItem) =>
                                  CoreUtil.fromFnOrValue(
                                      ad.disabled,
                                      data,
                                      context,
                                  ),
                    dataTestId: ad.dataTestId,
                    logFunctional: ad.functionalLog,
                    isSeparator: ad.isSeparator,
                })) ?? []
        );
    }

    protected abstract getTranslateKey(
        actionId: TAction,
        context?: TContext,
        item?: TItem,
    ): string;

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    protected getHtmlContent(
        _actionId: TAction,
        _context: TContext,
    ): string | HTMLElement | ((data?: TItem) => HTMLElement) {
        return;
    }

    protected log(...args: any[]) {
        if (this.debug) {
            console.log((this.constructor as any).name, ...args);
        }
    }
}

export interface IActionDef<TAction, TItem, TContext> {
    /** enum value or number to identify the action and get its translated text */
    actionId: TAction;
    /** return true if the option if available for the item and context */
    isAvailable: boolean | ((item: TItem, context: TContext) => boolean);
    /** option's callback for the click event */
    execute(item: TItem, context: TContext): void | Promise<void>;
    tooltipKey?: string | ((item: TItem, context: TContext) => string);
    /** class name for the option's glyph */
    glyphClass?: string | ((item: TItem, context: TContext) => string);
    /** class name for the option's element */
    optionClass?: string | ((item: TItem, context: TContext) => string);
    /** functional code, for a *IFunctionalEvent* to be emitted on action execution */
    functionalLog?: string;
    /** id for the end-to-end test framework */
    dataTestId?: string | (() => string);
    disabled?: boolean | ((item: TItem, context: TContext) => boolean);
    isSeparator?: boolean;
}
