import {
    deserialize,
    deserializeAs,
    inheritSerialization,
    SerializableEnumeration,
} from 'cerialize';
import { UserDto } from '@datagalaxy/webclient/user/domain';

export enum PhaseType {
    Initial,
    Regular,
    Final,
}
SerializableEnumeration(PhaseType);

export class CampaignPhaseBriefDto {
    @deserialize public Guid!: string;
    @deserialize public Name!: string;
    @deserialize public Description!: string;
}

export enum TransitionDirection {
    Forward,
    Backward,
}
SerializableEnumeration(TransitionDirection);

export class PhaseTransitionDto {
    @deserialize public Name!: string;
    @deserialize public Description!: string;
    @deserialize public SourcePhase!: CampaignPhaseBriefDto;
    @deserialize public TargetPhase!: CampaignPhaseBriefDto;
    @deserialize public Direction!: TransitionDirection;
    @deserialize public ExpectedAttributeValuesStrings!: string[];
    @deserialize public ObjectsStatusDictionary!: Map<string, boolean>;
    @deserialize public Active!: boolean;

    public static OnDeserialized(instance: PhaseTransitionDto) {
        instance.ObjectsStatusDictionary = new Map(
            Object.entries(instance.ObjectsStatusDictionary).filter(
                ([k]) => k != '$id' && k != '$type',
            ),
        );
    }
}

@inheritSerialization(CampaignPhaseBriefDto)
export class CampaignPhaseDto extends CampaignPhaseBriefDto {
    @deserializeAs(PhaseType) public PhaseType!: PhaseType;
    @deserializeAs(PhaseTransitionDto)
    public Transitions!: PhaseTransitionDto[];
    @deserializeAs(UserDto) public Assignees!: UserDto[];
}
