<dxy-action-list
    [actions]="actions()"
    [data]="workspace()"
    [fixButtonPositions]="fixActionsPosition()"
>
    <dxy-workspace-icon
        size="small"
        [workspace]="workspace()"
    ></dxy-workspace-icon>
    <span dxyEllipsisTooltip>{{ workspace().displayName }}</span>
</dxy-action-list>
