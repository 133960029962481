import { pointer, select } from 'd3-selection';
import { Axis, axisBottom, axisLeft, AxisScale } from 'd3-axis';
import {
    scaleBand,
    scaleLinear,
    scaleOrdinal,
    scaleTime,
    ScaleBand,
    ScaleLinear,
    ScaleOrdinal,
    ScaleTime,
} from 'd3-scale';
import { EnterElement } from 'd3-selection';
import { format } from 'd3-format';
import { line } from 'd3-shape';
import { bisector } from 'd3-array';
import * as moment from 'moment';
import { SD3 } from '@datagalaxy/core-d3-util';
import { CollectionsHelper, StringUtil } from '@datagalaxy/core-util';

// no reference to any component or component baseclass is allowed here

export class GraphWidgetHelper {
    //#region global
    public static getColorPalette(
        keys: string[],
    ): ScaleOrdinal<string, string> {
        return scaleOrdinal<string, string>()
            .domain(keys)
            .range([
                '#3D5CFE',
                '#28aae1',
                '#50c516',
                '#ff7b00',
                '#ca004a',
                '#ffff33',
                '#a65628',
                '#f781bf',
                '#999999',
            ]);
    }
    //#endregion

    //#region time line graph
    /**
     * Rebuild the complete timeline for each IDKV
     */
    public static buildCumulativeTimeLineGraphData(
        table: IDKV[],
        keys: string[],
        startDate: moment.Moment,
        endDate: moment.Moment,
    ): ITimeData[] {
        const data: ITimeData[] = [];
        const dates = CollectionsHelper.distinct(table.map((d) => d.d)).sort(
            (a, b) => moment(a).valueOf() - moment(b).valueOf(),
        );
        dates.push(endDate.toISOString());

        for (const key of keys) {
            let entityCount = table.find((d) => d.k === key)?.y;
            const timeData: ITimeData = {
                key,
                points: dates.map((date) => {
                    const value = table.find(
                        (d) => d.d === date && d.k === key,
                    );

                    if (value) {
                        entityCount += value.v;
                    }
                    return { date: moment(date), value: entityCount };
                }),
            };
            if (timeData.points?.length) {
                data.push(timeData);
            }
        }
        return data;
    }

    public static buildTimeLineGraphData(
        table: IDKV[],
        keys: string[],
    ): ITimeData[] {
        const data: ITimeData[] = [];

        for (const key of keys) {
            const timeData: ITimeData = {
                key: key,
                points: table
                    .filter((d) => d.k === key && d.v !== undefined)
                    .map((d) => ({
                        date: moment(d.d),
                        value: d.v,
                    })),
            };
            if (timeData.points?.length) {
                data.push(timeData);
            }
        }
        return data;
    }

    /**
     * draws a set of polylines based on the given time-based data
     */
    public static drawTimeLineGraph(options: ITimeLineGraphOptions): void {
        if (!options?.data?.length) {
            return;
        }

        const svgWidth = options.elementContainer.getBoundingClientRect().width;
        const svgHeight =
            options.elementContainer.getBoundingClientRect().height;
        const d3svg = this.setupTimeLineGraphSvg(
            options.elementContainer,
            svgHeight,
            svgWidth,
            options.margins,
        );
        const { yAxis, yScale, xAxis, xScale } = this.setupTimeLineGraphAxis(
            options,
            svgHeight,
            svgWidth,
        );
        //const hoverSurface = this.setupHoverSurface(d3svg, svgWidth, svgHeight)
        const colors = this.getColorPalette(
            options.data.map((d) => d.key.toString()),
        );

        this.drawTimeLineGraphAxis(
            d3svg,
            yAxis,
            xAxis,
            svgHeight,
            options.margins,
        );
        const lineGroups = this.drawTimeLineGraphLines(
            options.data,
            d3svg,
            yScale,
            xScale,
            svgWidth,
            options.margins,
            colors,
        );
        this.drawTimeLineGraphLegend(
            d3svg,
            svgWidth,
            svgHeight,
            lineGroups,
            options.margins,
            colors,
            options.getDisplayName,
        );
        //this.drawCircleFocus(options.data, d3svg, hoverSurface, yScale, xScale)
    }

    public static drawDotTimeLineGraph(options: ITimeLineGraphOptions): void {
        if (!options?.data.length) {
            return;
        }

        const svgWidth = options.elementContainer.getBoundingClientRect().width;
        const svgHeight =
            options.elementContainer.getBoundingClientRect().height;
        const d3svg = this.setupTimeLineGraphSvg(
            options.elementContainer,
            svgHeight,
            svgWidth,
            options.margins,
        );
        const { yAxis, yScale, xAxis, xScale } = this.setupTimeLineGraphAxis(
            options,
            svgHeight,
            svgWidth,
        );
        const colors = this.getColorPalette(
            options.data.map((d) => d.key.toString()),
        );

        this.drawTimeLineGraphAxis(
            d3svg,
            yAxis,
            xAxis,
            svgHeight,
            options.margins,
        );
        this.drawDashedTimeLine(
            options.data[0].points,
            d3svg,
            yScale,
            xScale,
            colors(options.data[0].key),
            options.drawDashArrayFn,
        );
        this.addDotOnTimeLineGraphLines(
            options.data,
            d3svg,
            yScale,
            xScale,
            colors,
        );
    }

    private static setupHoverSurface(
        d3svg: SD3<SVGElement>,
        width: number,
        height: number,
    ): SD3<SVGRectElement> {
        return d3svg
            .append('rect')
            .style('fill', 'none')
            .style('pointer-events', 'all')
            .attr('width', width)
            .attr('height', height);
    }

    private static drawCircleFocus(
        data: ITimeData[],
        d3svg: SD3<SVGElement>,
        svgSurfaceRect: SD3<SVGRectElement>,
        yScale: ScaleLinear<number, number>,
        xScale: ScaleTime<number, number>,
    ) {
        const circle = d3svg
            .append('g')
            .append('circle')
            .style('fill', 'none')
            .attr('stroke', 'black')
            .attr('r', 8.5)
            .style('opacity', 0);

        const circleText = d3svg
            .append('g')
            .append('text')
            .style('opacity', 0)
            .attr('text-anchor', 'left')
            .attr('alignment-baseline', 'middle');

        const mouseover = (
            circle: SD3<SVGCircleElement>,
            circleText: SD3<SVGTextElement>,
        ) => {
            circle.style('opacity', 1);
            circleText.style('opacity', 1);
        };

        const mousemove = (
            rectangle: unknown,
            data: ITimeData[],
            yScale: ScaleLinear<number, number>,
            xScale: ScaleTime<number, number>,
            circle: SD3<SVGCircleElement>,
            circleText: SD3<SVGTextElement>,
        ) => {
            // recover coordinate we need
            const bisect = bisector((d: ITimeValue) => d.date.toDate()).left;
            const x0 = xScale.invert(pointer(rectangle)[0]);
            const timeData = data.find((d) => d.selected) || data[0];
            const index = bisect(timeData.points, x0, 1);

            const selectedData = timeData.points[index];

            circle
                .attr('cx', xScale(selectedData.date))
                .attr('cy', yScale(selectedData.value));
            circleText
                .attr('class', 'line-circle-text')
                .html(selectedData.value.toString())
                .attr('x', xScale(selectedData.date) + 15)
                .attr('y', yScale(selectedData.value));
        };

        const mouseout = (
            circle: SD3<SVGCircleElement>,
            circleText: SD3<SVGTextElement>,
        ) => {
            circle.style('opacity', 0);
            circleText.style('opacity', 0);
        };

        svgSurfaceRect
            .on('mouseover', () => mouseover(circle, circleText))
            .on('mouseout', () => mouseout(circle, circleText))
            .on('mousemove', function (event, data) {
                mousemove(this, data, yScale, xScale, circle, circleText);
            });
    }

    private static setupTimeLineGraphSvg(
        elementContainer: HTMLElement,
        height: number,
        width: number,
        margins: IMargins,
    ): SD3<SVGGElement> {
        const svgElement = select(elementContainer).append('svg');
        const x = margins.left;
        const y = margins.top;

        return svgElement
            .attr('width', width)
            .attr('height', height)
            .append('g')
            .attr('class', 'svg-content')
            .attr('transform', `translate(${x}, ${y})`);
    }

    private static setupTimeLineGraphAxis(
        options: ITimeLineGraphOptions,
        svgHeight: number,
        svgWidth: number,
    ) {
        const maxY = CollectionsHelper.maxValue(options.data, (d) =>
            CollectionsHelper.maxValue(d.points, (d2) => d2.value),
        );
        const minY = CollectionsHelper.minValue(options.data, (d) =>
            CollectionsHelper.minValue(d.points, (d2) => d2.value),
        );
        const yScale = scaleLinear()
            .domain([minY, maxY])
            .range([svgHeight - options.margins.bottom, options.margins.top]);
        const yAxis = axisLeft<number>(yScale)
            .tickValues(
                yScale.ticks(5).filter((tick) => Number.isInteger(tick)),
            )
            .tickFormat((d) => StringUtil.formatNumber(d));

        const xScale = scaleTime()
            .domain([options.startDate, options.endDate])
            .range([
                0,
                svgWidth - options.margins.right - options.margins.left,
            ]);

        const xTickValues = options.customDateTicks
            ? options.customDateTicks.map((d) => d.toDate())
            : xScale.ticks(5);
        const xAxis = axisBottom<Date>(xScale)
            .tickValues(xTickValues)
            .tickFormat(options.translateDate);

        return { yAxis, yScale, xAxis, xScale };
    }

    private static drawTimeLineGraphAxis(
        d3svg: SD3<SVGElement>,
        yAxis: Axis<number>,
        xAxis: Axis<Date>,
        svgHeight: number,
        margins: IMargins,
    ): void {
        d3svg.append('g').attr('class', 'y axis').call(yAxis);
        d3svg
            .append('g')
            .attr('class', 'x axis')
            .attr('transform', `translate(0,${svgHeight - margins.bottom})`)
            .call(xAxis);
    }

    private static drawDashedTimeLine(
        points: ITimeValue[],
        svg: SD3<SVGGElement>,
        yScale: AxisScale<number>,
        xScale: AxisScale<Date>,
        color: string,
        drawDashArrayFn: (pointA: ITimeValue, pointB: ITimeValue) => boolean,
    ) {
        for (let index = 0; index < points.length - 1; index++) {
            const pointA = points[index];
            const pointB = points[index + 1];

            const isMissingData = drawDashArrayFn(pointA, pointB);

            svg.append('line')
                .style('stroke', color)
                .style('stroke-width', 4)
                .attr('x1', xScale(pointA.date.toDate()))
                .attr('y1', yScale(pointA.value))
                .attr('x2', xScale(pointB.date.toDate()))
                .attr('y2', yScale(pointB.value))
                .attr('class', isMissingData ? 'transparent-line' : 'line')
                .style('opacity', isMissingData ? 0.3 : 1);
        }
    }

    private static addDotOnTimeLineGraphLines(
        data: ITimeData[],
        svg: SD3<SVGGElement>,
        yScale: AxisScale<number>,
        xScale: AxisScale<Date>,
        colors: ScaleOrdinal<string, string>,
    ) {
        svg.selectAll('dots')
            .data(data)
            .enter()
            .append('g')
            .style('fill', (d) => colors(d.key))
            .selectAll('points')
            .data((d) => d.points)
            .enter()
            .append('circle')
            .attr('cx', (d) => xScale(d.date.toDate()))
            .attr('cy', (d) => yScale(d.value))
            .attr('r', 2)
            .attr('class', 'line-dot');
    }

    private static drawTimeLineGraphLines(
        data: ITimeData[],
        svg: SD3<SVGGElement>,
        yScale: AxisScale<number>,
        xScale: AxisScale<Date>,
        svgWidth: number,
        margins: IMargins,
        colors: ScaleOrdinal<string, string>,
    ) {
        const d3LineDraw = line<ITimeValue>()
            .x((d) => xScale(d.date.toDate()))
            .y((d) => yScale(d.value));

        const lineGroups = svg
            .append('g')
            .selectAll('.svg-content')
            .data(data)
            .enter();

        // Draw path for each entity Types
        const paths = lineGroups
            .append('path')
            .attr('fill', 'none')
            .attr('stroke', (d) => colors(d.key.toString()))
            .attr('class', 'lines')
            .attr('d', (d) => d3LineDraw(d.points))
            .attr('stroke-width', 2)
            .on('mouseenter', function () {
                const path = this as SVGPathElement;
                paths.each(function () {
                    this.setAttribute('stroke-width', '2');
                });
                path.parentElement.append(path);
                path.setAttribute('stroke-width', '5');
            })
            .on('mouseleave', function (event, d) {
                if (d.selected) {
                    return;
                }
                paths?.each(function () {
                    this.setAttribute('stroke-width', '2');
                });
                if (!data.some((d) => d.selected)) {
                    return;
                }
                const path = paths?.nodes()[paths.size() - 1];
                if (path) {
                    path.parentElement.append(path);
                    path.setAttribute('stroke-width', '5');
                }
            })
            .on('click', function (event, d) {
                const path = this as SVGPathElement;
                paths.each(function () {
                    this.setAttribute('stroke-width', '2');
                });
                data.forEach((d) => (d.selected = false));
                // Brings path in front
                path.parentElement.append(path);
                path.setAttribute('stroke-width', '5');
                d.selected = true;
            });

        // Draw the total count for each entity Types
        // Disabled until we tune it for text overlap
        /*
        lineGroups.append("text")
            .attr("transform", (d) => {
                const x = svgWidth - margins.left - margins.right + 10
                const y = yScale(d.points[d.points.length - 1].value) + 5
                return `translate(${x}, ${y})`
            })
            .attr("class", "lines-count")
            .text((d, index) => d.points[d.points.length - 1].value.toString())
         */

        return lineGroups;
    }
    private static drawTimeLineGraphLegend(
        svg: SD3<SVGGElement>,
        svgWidth: number,
        svgHeight: number,
        lineGroups: SD3<EnterElement>,
        margins: IMargins,
        colors: ScaleOrdinal<string, string>,
        getDisplayName: (key: string) => string,
    ) {
        const legend = svg
            .append('foreignObject')
            .attr('width', svgWidth)
            .attr('height', margins.bottom)
            .attr('transform', () => {
                const x = 0;
                const y = svgHeight - margins.bottom + 20;
                return `translate(${x}, ${y})`;
            });

        lineGroups
            .filter((d: ITimeData) => !!d.points?.length)
            .each((d: ITimeData) => {
                const div = legend.append('xhtml:div').attr('class', 'legend');

                div.append('xhtml:span')
                    .attr(
                        'style',
                        `background-color:${colors(d.key.toString())}`,
                    )
                    .attr('class', 'legend-point');
                div.append('xhtml:span')
                    .attr('class', 'legend-text')
                    .html(getDisplayName(d.key));
            });
    }
    //#endregion

    //#region horizontal bar graph
    /**
     * Draws an horizontal bar graph for a set of key
     */
    public static drawBarGraph(
        data: IKV[],
        elementContainer: HTMLElement,
        margins: IMargins,
        colorKeys: string[],
    ) {
        if (!data?.length) {
            return;
        }
        const svgWidth = elementContainer.getBoundingClientRect().width;
        const svgHeight = elementContainer.getBoundingClientRect().height;
        const size: ISize = { width: svgWidth, height: svgHeight };
        const d3svg = this.setupBarGraphSvg(elementContainer, size, margins);
        const { yAxis, yScale, xAxis, xScale } = this.setupBarGraphAxis(
            data,
            size,
            margins,
        );
        const colors = this.getColorPalette(colorKeys);

        this.drawBarGraphAxis(d3svg, yAxis, xAxis, svgHeight, margins);
        this.drawBarGraphBars(data, d3svg, yScale, xScale, colors);
    }

    private static setupBarGraphSvg(
        elementContainer: HTMLElement,
        size: ISize,
        margins: IMargins,
    ): SD3<SVGGElement> {
        const svgElement = select(elementContainer).append('svg');
        const x = margins.left;
        const y = margins.top;

        return svgElement
            .attr('width', size.width)
            .attr('height', size.height)
            .append('g')
            .attr('class', 'svg-content')
            .attr('transform', `translate(${x}, ${y})`);
    }

    private static setupBarGraphAxis(
        data: IKV[],
        size: ISize,
        margins: IMargins,
    ) {
        const yScale = scaleBand()
            .domain(data.map((d) => d.k))
            .range([size.height - margins.bottom, 0]);
        const yAxis = axisLeft(yScale);

        const xMax = CollectionsHelper.maxValue(data, (d) => d.v);
        const xScale = scaleLinear<number>()
            .domain([0, xMax])
            .range([0, size.width - margins.right - margins.left]);
        const xAxis = axisBottom<number>(xScale)
            .tickValues(
                xScale.ticks(5).filter((tick) => Number.isInteger(tick)),
            )
            .tickFormat(format('.0f'));

        return { yAxis, yScale, xAxis, xScale };
    }

    private static drawBarGraphAxis(
        svg: SD3<SVGElement>,
        yAxis: Axis<unknown>,
        xAxis: Axis<unknown>,
        svgHeight: number,
        margins: IMargins,
    ): void {
        svg.append('g').attr('class', 'y axis').call(yAxis);

        svg.append('g')
            .attr('class', 'x axis')
            .attr('transform', `translate(0,${svgHeight - margins.bottom})`)
            .call(xAxis);
    }

    private static drawBarGraphBars(
        data: IKV[],
        svg: SD3<SVGElement>,
        yScale: ScaleBand<string>,
        xScale: ScaleLinear<number, number>,
        colors: ScaleOrdinal<string, string>,
    ) {
        const bars = svg.selectAll('.bar').data(data).enter().append('g');

        bars.append('rect')
            .attr('class', 'bar')
            .attr('y', (d) => yScale(d.k))
            .attr('height', yScale.bandwidth())
            .attr('x', 0)
            .attr('width', (d) => xScale(d.v))
            .attr('fill', (d) => colors(d.k));
    }
    //#endregion

    /**
     * Generate 4 dates values based on endDate and nbDays
     * ex: J-90/J-60/J-30/J for nbDays = 90
     */
    public static getTimeTickValuesFromEndDate(
        nbDays: number,
        endDate: moment.Moment,
    ) {
        const datesDomain = [];
        const scale = nbDays / 3;

        while (nbDays >= 0) {
            const date = moment(endDate).subtract(nbDays, 'days');
            datesDomain.push(date);
            nbDays -= scale;
        }
        return datesDomain;
    }

    public static formatGraphDate(
        date: Date,
        endDate: Date,
        translate: (key: string, interpolateParams?: object) => string,
    ): string {
        const diffDays = moment(endDate).diff(date, 'days');

        return translate(
            `UI.DashboardGrid.WidgetType.evolutionOfEntityCountByEntityType.xAxis`,
            { diffDays: diffDays },
        );
    }
}

interface IKV {
    k: string;
    v: number;
}
interface IDKV extends IKV {
    d: string;
    y: number;
}

export interface IMargins {
    top: number;
    bottom: number;
    left: number;
    right: number;
}

export interface ISize {
    width: number;
    height: number;
}

export interface ITimeValue {
    date: moment.Moment;
    value: number;
}

export interface ITimeData {
    key: string;
    points: ITimeValue[];
    selected?: boolean;
}

export interface ITimeLineGraphOptions {
    data: ITimeData[];
    elementContainer: HTMLElement;
    margins: IMargins;
    startDate: moment.Moment;
    endDate: moment.Moment;
    customDateTicks?: moment.Moment[];
    getDisplayName?: (key: string) => string;
    translateDate?: (date: Date) => string;
    drawDashArrayFn?: (pointA: ITimeValue, pointB: ITimeValue) => boolean;
}
