import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { DxyBaseModalComponent } from '@datagalaxy/ui/dialog';
import {
    MAT_DIALOG_DATA,
    MatDialogRef,
    MatDialogModule,
} from '@angular/material/dialog';
import { ForeignKeyColumnMappingContainer } from '../ForeignKeyColumnMappingContainer';
import {
    IForeignKeySettingModalInput,
    IForeignKeySettingsModalOutput,
} from '../pkfk.types';
import { ModelerService } from '../../services/modeler.service';
import { ViewTypeService } from '../../../services/viewType.service';
import { Table } from '@datagalaxy/webclient/modeler/data-access';
import { KeyboardUtil } from '@datagalaxy/utils';
import { DxyLogFunctionalDirective } from '../../../directives/dxy-log-functional.directive';
import { DxyForeignKeyColumnsTableComponent } from '../dxy-foreign-key-columns-table/dxy-foreign-key-columns-table.component';
import { DxyFieldBooleanComponent } from '@datagalaxy/core-ui/fields';
import { DxyFieldTextComponent } from '@datagalaxy/ui/fields';
import { NgIf } from '@angular/common';
import { FormsModule } from '@angular/forms';
import {
    DxyButtonDirective,
    DxyIconButtonDirective,
} from '@datagalaxy/ui/buttons';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'dxy-foreign-key-technical-settings-modal',
    templateUrl: './dxy-foreign-key-settings-modal.component.html',
    styleUrls: ['./dxy-foreign-key-settings-modal.component.scss'],
    standalone: true,
    imports: [
        MatDialogModule,
        TranslateModule,
        DxyIconButtonDirective,
        DxyButtonDirective,
        FormsModule,
        NgIf,
        DxyFieldTextComponent,
        DxyFieldBooleanComponent,
        DxyForeignKeyColumnsTableComponent,
        DxyLogFunctionalDirective,
    ],
})
export class DxyForeignKeySettingsModalComponent
    extends DxyBaseModalComponent<
        IForeignKeySettingModalInput,
        IForeignKeySettingsModalOutput
    >
    implements OnInit
{
    public readonly fkColumnMappingContainer =
        new ForeignKeyColumnMappingContainer();
    public isFkMandatory: boolean;
    public fkTechnicalName: string;
    public fkDisplayName: string;
    public fkDescription: string;
    public get isConvertFkAvailable() {
        return (
            this.hasWriteAccess &&
            this.modelerService.hasTechnicalPk(this.parentTable) &&
            this.isFunctionalOnly
        );
    }
    public get isFunctionalOnly() {
        return this.data.isFunctionalOnly;
    }
    public get modalTitle() {
        return this.isFunctionalOnly
            ? 'UI.Modeler.ForeignKeyFunctionalSettingsModal.title'
            : 'UI.Modeler.ForeignKeyTechnicalSettingsModal.title';
    }
    public get displayNameLabel() {
        return this.isFunctionalOnly
            ? 'UI.Modeler.ForeignKeyFunctionalSettingsModal.lblDisplayName'
            : 'UI.Modeler.ForeignKeyTechnicalSettingsModal.lblDisplayName';
    }
    public get descriptionLabel() {
        return this.isFunctionalOnly
            ? 'UI.Modeler.ForeignKeyFunctionalSettingsModal.lblDescription'
            : 'UI.Modeler.ForeignKeyTechnicalSettingsModal.lblDescription';
    }
    public get childTableLabel() {
        return this.isFunctionalOnly
            ? 'UI.Modeler.ForeignKeyFunctionalSettingsModal.lblChildEntityName'
            : 'UI.Modeler.ForeignKeyTechnicalSettingsModal.lblChildTableName';
    }
    public get parentTableLabel() {
        return this.isFunctionalOnly
            ? 'UI.Modeler.ForeignKeyFunctionalSettingsModal.lblParentEntityName'
            : 'UI.Modeler.ForeignKeyTechnicalSettingsModal.lblParentTableName';
    }
    public get displayNamePlaceholder() {
        return this.isFunctionalOnly
            ? 'UI.Modeler.ForeignKeyFunctionalSettingsModal.lblDisplayName'
            : 'UI.Modeler.ForeignKeyTechnicalSettingsModal.lblDisplayName';
    }
    public get descriptionPlaceholder() {
        return this.isFunctionalOnly
            ? 'UI.Modeler.ForeignKeyFunctionalSettingsModal.lblDescription'
            : 'UI.Modeler.ForeignKeyTechnicalSettingsModal.lblDescription';
    }
    public get technicalNameErrorKey() {
        return this.fkTechnicalName ? '' : 'UI.Attribute.msgRequired';
    }
    public get displayNameErrorKey() {
        return this.fkDisplayName ? '' : 'UI.Attribute.msgRequired';
    }

    public get parentTable() {
        return this.data.parentTable;
    }
    public get hasWriteAccess() {
        return this.data.hasWriteAccess;
    }
    public get childTable() {
        return this.data.childTable;
    }
    public get canSave() {
        return this.hasWriteAccess && this.isMappingValid;
    }

    private get isMappingValid() {
        return this.fkColumnMappingContainer.isAllMappingsValid();
    }
    private convertForeignKey: boolean;

    constructor(
        dialogRef: MatDialogRef<
            DxyForeignKeySettingsModalComponent,
            IForeignKeySettingsModalOutput
        >,
        @Inject(MAT_DIALOG_DATA) data: IForeignKeySettingModalInput,
        private modelerService: ModelerService,
        private viewTypeService: ViewTypeService,
    ) {
        super(dialogRef, data);
    }

    ngOnInit() {
        this.log('ngOnInit', this.data);
        const { currentFk, parentTable, childTable, isFunctionalOnly } =
            this.data;

        if (currentFk) {
            this.isFkMandatory = currentFk.IsMandatory;
            this.fkTechnicalName = currentFk.TechnicalName;
            this.fkDisplayName = currentFk.DisplayName;
            this.fkDescription = currentFk.Description;
        } else {
            const defaultName = isFunctionalOnly
                ? this.modelerService.getDefaultFunctionalForeignKeyName(
                      parentTable,
                      childTable,
                  )
                : this.modelerService.getDefaultTechnicalFkName(
                      parentTable,
                      childTable,
                  );
            this.isFkMandatory = false;
            this.fkTechnicalName = defaultName;
            this.fkDisplayName = defaultName;
            this.fkDescription = null;
        }

        // Set up foreign key column mappings
        const primaryKey = this.modelerService.getTablePk(parentTable);
        const fkMappings = this.modelerService.getFkColumnMapping(
            primaryKey,
            childTable,
            currentFk,
        );
        this.fkColumnMappingContainer.addMapping(...fkMappings);

        // Update mappings validation state
        this.fkColumnMappingContainer.updateMappingsValidity();
    }

    public onUpdateFkMandatory() {
        this.fkColumnMappingContainer.updateMappingsValidity();
    }

    public onConvertFk() {
        this.data.isFunctionalOnly = false;
        this.convertForeignKey = true;
    }

    public onCloseSubmit() {
        this.result = {
            isForeignKeyMandatory: this.isFkMandatory,
            foreignKeyTechnicalName: this.fkTechnicalName,
            foreignKeyDisplayName: this.fkDisplayName,
            foreignKeyDescription: this.fkDescription,
            foreignKeyColumnMappingContainer: this.fkColumnMappingContainer,
            convertForeignKey: this.convertForeignKey,
        };
        super.onCloseSubmit();
    }

    public getObjetDisplayName(object: Table) {
        return this.viewTypeService.getTechnicalOrDisplayName(object);
    }

    @HostListener('keydown', ['$event'])
    private keyDownModal(event: KeyboardEvent) {
        if (KeyboardUtil.isEnterKey(event) && this.isMappingValid) {
            this.onCloseSubmit();
        }
    }
}
