import {
    ChangeDetectionStrategy,
    Component,
    HostBinding,
    input,
} from '@angular/core';
import { MessageColor } from '../message-color';
import { NgStyle } from '@angular/common';

@Component({
    selector: 'dxy-message-timer',
    standalone: true,
    imports: [NgStyle],
    templateUrl: './message-timer.component.html',
    styleUrl: './message-timer.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessageTimerComponent {
    color = input.required<MessageColor>();
    duration = input<number>(0);

    @HostBinding('class')
    private get hostClass() {
        return this.color();
    }
}
