import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnChanges,
    SimpleChanges,
} from '@angular/core';
import { IHierarchicalData } from '@datagalaxy/dg-object-model';
import { EntityTypeUtils } from '@datagalaxy/webclient/entity/utils';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { NgClass, NgIf } from '@angular/common';
import { EllipsisTooltipDirective } from '@datagalaxy/ui/tooltip';
import { EntityDashboardService } from '../../../../entity-dashboard/entity-dashboard.service';

/**
 * ## Role
 * Display entity in a small card with a link to navigate to entity
 */
@Component({
    standalone: true,
    selector: 'app-dg-entity-link-shortcut-cell',
    templateUrl: 'dg-entity-link-shortcut-cell.component.html',
    styleUrls: ['dg-entity-link-shortcut-cell.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [NgClass, EllipsisTooltipDirective, NgIf],
})
export class DgEntityLinkShortcutCellComponent
    extends DxyBaseComponent
    implements OnChanges
{
    @Input() hdd: IHierarchicalData;

    public glyphClass?: string;
    public href?: string;

    constructor(private entityDashboardService: EntityDashboardService) {
        super();
    }

    ngOnChanges(changes: SimpleChanges): void {
        super.onChange(changes, 'hdd', () => this.initLink(), true);
    }

    public onClick() {
        if (this.href) {
            return;
        }
        return this.entityDashboardService.goToEntity(this.hdd);
    }

    private initLink() {
        if (!this.hdd) {
            return;
        }
        this.glyphClass = EntityTypeUtils.getColoredGlyphClass(
            this.hdd.EntityType,
        );
        this.href = this.entityDashboardService.getEntityUrl(this.hdd);
    }
}
