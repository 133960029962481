import { FormControl } from '@angular/forms';
import {
    AttributeMetaInfo,
    AttributeMetaType,
} from '@datagalaxy/webclient/attribute/domain';
import { ServerConstants } from '@datagalaxy/shared/server/domain';

export interface AttributesForm {
    attributes: FormControl<AttributeMetaInfo[] | null>;
}

const translatableAttributeTypes = [
    AttributeMetaType.Text,
    AttributeMetaType.FormattedText,
    AttributeMetaType.MultiLineText,
];

const excludedAttributeNames = [
    ServerConstants.PropertyName.Urn,
    ServerConstants.PropertyName.TechnicalName,
    'DataSample',
    'Expression',
    'UsageFieldFormat',
    'ExternalId',
    'ExternalSourceConnection',
    'GeographicalLocation',
    'ExternalTopUsers',
    'Query',
    'ExternalLastExecutionStatus',
    'ExternalTechnologyType',
    'StorageSizeUnit',
    'ExternalModificationUser',
    'Code',
    'CurrentTechnology',
    'SchemaName',
    'PrimaryKeyTechnicalName',
    'TableDisplayName',
    'TableTechnicalName',
    'VisualData',
    'ExternalTags',
];

export const isTranslatableAttribute = (
    attribute: AttributeMetaInfo,
): boolean => {
    return (
        !excludedAttributeNames.some(
            (name) => name === attribute.DisplayName,
        ) &&
        attribute.AttributeType != undefined &&
        !attribute.IsSystemTechnicalField &&
        attribute.IsComputed == false &&
        attribute.IsReadOnly == false &&
        translatableAttributeTypes.includes(attribute.AttributeType)
    );
};
