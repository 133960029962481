import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { DxyRichTextFieldComponent } from '@datagalaxy/core-ui/rich-text';
import {
    IMentionResolver,
    RichTextContent,
} from '@datagalaxy/core-ui/rich-text';
import { DxyAttributeBaseInput } from '../../DxyAttributeBaseInput';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { RichTextMentionService } from '../../../../richText/mentions/rich-text-mention.service';
import { TextQualityVoteStatus } from '@datagalaxy/webclient/attribute/domain';
import { DxyAttributeQualityScoreDropdownComponent } from '../../../dxy-attribute-quality-score-dropdown/dxy-attribute-quality-score-dropdown.component';
import { DxyUnitaryFieldActionsComponent } from '../../../../fields/unitary/dxy-unitary-field-actions/dxy-unitary-field-actions.component';
import { NgIf } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { DataUtil } from '../../../../util/DataUtil';

@Component({
    selector: 'dxy-attribute-formatted-text-input',
    templateUrl: './dxy-attribute-formatted-text-input.component.html',
    standalone: true,
    imports: [
        DxyRichTextFieldComponent,
        FormsModule,
        NgIf,
        DxyUnitaryFieldActionsComponent,
        DxyAttributeQualityScoreDropdownComponent,
        TranslateModule,
    ],
})
export class DxyAttributeFormattedTextInputComponent
    extends DxyAttributeBaseInput<string>
    implements OnInit
{
    @Input() placeholderKey?: string;

    @ViewChild('field') field: DxyRichTextFieldComponent;

    public mentionResolvers: IMentionResolver[];

    public get qualityTextStatistics() {
        return this.textQualityUserVote?.TextQualityStat;
    }
    public get qualityTextVote() {
        return this.textQualityUserVote?.Vote;
    }
    public get qualityTextOutdated() {
        return this.textQualityUserVote?.IsVoteOutdated;
    }
    public get isToolBarDisplayed() {
        return this.field.isToolbarVisible;
    }

    private isModalOpen: boolean;

    constructor(
        elementRef: ElementRef<HTMLElement>,
        translate: TranslateService,
        private richTextMentionService: RichTextMentionService,
    ) {
        super(elementRef, translate);
    }

    ngOnInit() {
        super.ngOnInit();
        const entityData = this.entityForm?.getEntityData();
        const module = DataUtil.getModuleFromServerType(entityData?.ServerType);
        this.mentionResolvers =
            this.richTextMentionService.getDefaultMentionResolvers(
                entityData.ReferenceId,
                entityData.VersionId,
                module,
            );
    }

    //#region IAttributeBaseInputOverride

    public toggleDisplayToolBar() {
        this.field.toggleToolbar();
    }

    public isEmpty() {
        if (this.isActive) {
            return false;
        }
        return RichTextContent.isEmptyRawText(this.getData());
    }

    public canValidateOnBlur(_event: Event) {
        return !this.isModalOpen;
    }

    public focusField() {
        this.field.doFocus();
    }
    public blurField() {
        this.field.doBlur();
    }

    public onSetUserVote(vote: TextQualityVoteStatus) {
        this.onTextQualityUserVote(vote);
    }

    //#endregion - IAttributeBaseInputOverride

    public onAcquireMentionData(isStart: boolean) {
        this.onPanelOpenClose.emit((this.isModalOpen = isStart));
    }
}
