<mat-table
    class="resizable"
    [class.debug]="debug"
    [dataSource]="dataSource"
    matSort
    [matSortActive]="sortBy"
    [matSortDirection]="sortDir"
    (matSortChange)="onSortChange()"
    cdkDropList
    [cdkDropListDisabled]="orderByDragDisabled"
    (cdkDropListDropped)="orderByDragOnDrop($event)"
>
    <!-- children-rows show/hide column -->
    <ng-container [matColumnDef]="chidrenColId">
        <mat-header-cell
            *matHeaderCellDef
            [dxyResizeColumn]="chidrenColId"
            [style]="getStyleObjById(chidrenColId)"
        >
            <button
                *ngIf="isChildrenMasterVisible()"
                dxyIconButton
                variant="tertiary"
                (click)="showHideChildrenMasterClick($event)"
            >
                <i
                    class="glyph-arrow-drop-down"
                    [ngClass]="getMasterParentClass()"
                ></i>
            </button>
        </mat-header-cell>
        <mat-cell *matCellDef="let it" [style]="getStyleObjById(chidrenColId)">
            <button
                *ngIf="hasChildren(it)"
                dxyIconButton
                variant="tertiary"
                (click)="showHideChildrenClick(it, $event)"
            >
                <i
                    class="glyph-arrow-drop-down"
                    [ngClass]="getParentClass(it)"
                ></i>
            </button>
        </mat-cell>
    </ng-container>

    <!-- rows multi-select column -->
    <ng-container [matColumnDef]="selectionColId">
        <mat-header-cell
            *matHeaderCellDef
            [dxyResizeColumn]="selectionColId"
            [style]="getStyleObjById(selectionColId)"
        >
            <mat-checkbox
                (change)="$event ? selectMasterToggle() : null"
                [checked]="isAnySelected && isAllSelected"
                [indeterminate]="isAnySelected && !isAllSelected"
            ></mat-checkbox>
        </mat-header-cell>
        <mat-cell
            *matCellDef="let it"
            [style]="getStyleObjById(selectionColId)"
        >
            <mat-checkbox
                *ngIf="isSelectable(it)"
                (click)="$event.stopPropagation()"
                (change)="$event ? selectToggle(it) : null"
                [checked]="isSelected(it)"
            ></mat-checkbox>
        </mat-cell>
    </ng-container>

    <!-- drag&drop row ordering column -->
    <ng-container [matColumnDef]="orderByDragColId">
        <mat-header-cell
            *matHeaderCellDef
            [dxyResizeColumn]="orderByDragColId"
            [style]="getStyleObjById(orderByDragColId)"
        >
            {{ getHeaderTextById(orderByDragColId) }}
        </mat-header-cell>
        <mat-cell
            *matCellDef="let it"
            [class.order-by-drag]="!orderByDragDisabled"
            [style]="getStyleObjById(orderByDragColId)"
        >
            <span
                class="grab-icon"
                [class.hidden]="orderByDragDisabled"
                [class.glyph-drag]="true"
            ></span>
            <span *ngIf="orderByDragShowValue" class="text">{{
                getOrderByDragValue(it)
            }}</span>
        </mat-cell>
    </ng-container>

    <!-- cell-edit columns -->
    <ng-container *ngFor="let cd of colDefs" [matColumnDef]="getColId(cd)">
        <!-- column header -->
        <mat-header-cell
            *matHeaderCellDef
            [dxyResizeColumn]="getColId(cd)"
            mat-sort-header
            [disabled]="isSortDisabled(cd)"
            [start]="getSortStart(cd)"
            [class.action-cell-header]="isType_action(cd)"
            [style]="getStyleObj(cd)"
            [matTooltip]="getHeaderText(cd)"
        >
            {{ getHeaderText(cd) }}
        </mat-header-cell>
        <!-- cell -->
        <mat-cell
            *matCellDef="let it"
            [class.action-cell]="isType_action(cd) && !isActionDisabled(it, cd)"
            [class.input-cell]="!isReadonly(it, cd) && !isType_action(cd)"
            [style]="getStyleObj(cd)"
        >
            <ng-container *ngIf="!isDisabled(it, cd)">
                <!-- cell-editor: text -->
                <ng-container *ngIf="isType_textOrDefault(cd)">
                    <ng-container *ngIf="isReadonly(it, cd)">{{
                        getText(it, cd)
                    }}</ng-container>
                    <input
                        *ngIf="!isReadonly(it, cd)"
                        mat-input
                        [ngModel]="getValue(it, cd)"
                        (ngModelChange)="onValueChange($event, it, cd)"
                        (focusin)="onFocusIn($event, it, cd)"
                    />
                </ng-container>

                <!-- cell-editor: checkbox -->
                <ng-container *ngIf="isType_booleanYesNo(cd)">
                    <ng-container *ngIf="isReadonly(it, cd)">{{
                        getText(it, cd)
                    }}</ng-container>
                    <mat-checkbox
                        *ngIf="!isReadonly(it, cd)"
                        [ngModel]="getValue(it, cd)"
                        (ngModelChange)="onValueChange($event, it, cd)"
                        (focusin)="onFocusIn($event, it, cd)"
                    ></mat-checkbox>
                </ng-container>

                <!-- cell-editor: slider -->
                <ng-container *ngIf="isType_booleanSlider(cd)">
                    <ng-container *ngIf="isReadonly(it, cd)">{{
                        getText(it, cd)
                    }}</ng-container>
                    <mat-slide-toggle
                        *ngIf="!isReadonly(it, cd)"
                        [checked]="getValue(it, cd)"
                        (change)="onValueChange($event, it, cd)"
                        class="toggle"
                        [class.displayed-on-hover]="cd.isDisplayedOnHover"
                        [matTooltip]="getActionTooltip(it, cd)"
                        color="primary"
                    ></mat-slide-toggle>
                </ng-container>

                <!-- cell-editor: radio -->
                <mat-radio-group
                    *ngIf="isType_booleanRadio(cd)"
                    [ngModel]="getValue(it, cd)"
                    (ngModelChange)="onValueChange($event, it, cd)"
                >
                    <mat-radio-button
                        [value]="true"
                        [matTooltip]="getActionTooltip(it, cd)"
                        [disabled]="isReadonly(it, cd)"
                    ></mat-radio-button>
                </mat-radio-group>

                <!-- cell-editor: single-select -->
                <ng-container *ngIf="isType_selector(cd)">
                    <ng-container *ngIf="isReadonly(it, cd)">
                        <span
                            class="selector-readonly-glyph glyph"
                            [ngClass]="
                                getCurrentOption(it, cd)?.glyphClass ?? 'hidden'
                            "
                        ></span>
                        {{ getOptionText(getCurrentOption(it, cd)) }}
                    </ng-container>
                    <mat-select
                        *ngIf="!isReadonly(it, cd)"
                        [ngModel]="getValue(it, cd)"
                        (ngModelChange)="onValueChange($event, it, cd)"
                        (focusin)="onFocusIn($event, it, cd)"
                    >
                        <mat-select-trigger>
                            <span
                                class="glyph"
                                [ngClass]="
                                    getCurrentOption(it, cd)?.glyphClass ??
                                    'hidden'
                                "
                            ></span>
                            {{ getOptionText(getCurrentOption(it, cd)) }}
                        </mat-select-trigger>
                        <mat-option
                            *ngFor="let o of getOptions(it, cd)"
                            [value]="o?.value"
                        >
                            <span
                                class="glyph"
                                [ngClass]="o?.glyphClass"
                            ></span>
                            {{ getOptionText(o) }}
                        </mat-option>
                    </mat-select>
                </ng-container>

                <!-- cell-editor: custom cell component -->
                <ng-container *ngIf="isType_custom(cd)">
                    <dxy-edit-grid-custom-cell
                        [contentParams]="getCustomCellParam(it, cd)"
                    >
                    </dxy-edit-grid-custom-cell>
                </ng-container>

                <!-- action-cell -->
                <button
                    *ngIf="isType_action(cd) && !isActionDisabled(it, cd)"
                    dxyIconButton
                    variant="tertiary"
                    [size]="options?.compact ? 'small' : 'medium'"
                    [class.displayed-on-hover]="cd.isDisplayedOnHover"
                    (click)="onActionClick($event, it, cd)"
                    [matTooltip]="getActionTooltip(it, cd)"
                >
                    <i [ngClass]="getActionGlyphClass(it, cd)"></i>
                </button>
            </ng-container>
        </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="colIds"></mat-header-row>
    <mat-row
        *matRowDef="let item; columns: colIds"
        [ngClass]="getRowClass(item)"
        cdkDrag
        [cdkDragData]="item"
        [cdkDragDisabled]="orderByDragDisabled"
        (cdkDragStarted)="orderByDragOnDragStart($event)"
        [cdkDragLockAxis]="orderByDragLockAxis"
        [cdkDragPreviewClass]="orderByDragPreviewClasses"
    ></mat-row>
</mat-table>
