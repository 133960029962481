import { Injectable } from '@angular/core';
import { BackendApiService } from '@datagalaxy/data-access';
import {
    ApplyScreenCategoryTemplateParameter,
    BaseCategoryTemplateParameter,
    GetCategoryTemplateListResult,
    GetCategoryTemplateResult,
    ResetCategoryTemplateParameter,
} from './category-template';
import {
    BulkUpdateScreenResult,
    CreateSpaceScreenParameter,
    GenericScreenResult,
    GetClientScreensParameter,
    GetScreensResult,
    GetSpaceScreensParameter,
    ResetClientScreenParameter,
    ResetSpaceScreenParameter,
    UpdateClientScreenParameter,
    UpdateSpaceScreenParameter,
} from './screen';

@Injectable({ providedIn: 'root' })
export class ScreenApiService {
    constructor(private backendApiService: BackendApiService) {}

    public async getSpaceScreens(param: GetSpaceScreensParameter) {
        return await this.backendApiService.postPromise(
            'Screen/GetSpaceScreens',
            param,
            GetScreensResult,
        );
    }

    public async getClientScreens(param: GetClientScreensParameter) {
        return await this.backendApiService.postPromise(
            'Screen/GetClientScreens',
            param,
            GetScreensResult,
        );
    }

    public async createSpaceScreen(param: CreateSpaceScreenParameter) {
        return await this.backendApiService.postPromise(
            'Screen/CreateSpaceScreen',
            param,
            GenericScreenResult,
        );
    }

    public async updateClientScreen(param: UpdateClientScreenParameter) {
        return await this.backendApiService.postPromise(
            'Screen/UpdateClientScreen',
            param,
            GenericScreenResult,
        );
    }

    public async updateSpaceScreen(param: UpdateSpaceScreenParameter) {
        return await this.backendApiService.postPromise(
            'Screen/UpdateSpaceScreen',
            param,
            GenericScreenResult,
        );
    }

    public async resetSpaceScreen(param: ResetSpaceScreenParameter) {
        return await this.backendApiService.postPromise(
            'Screen/ResetSpaceScreen',
            param,
            GenericScreenResult,
        );
    }

    public async resetClientScreen(param: ResetClientScreenParameter) {
        return await this.backendApiService.postPromise(
            'Screen/ResetClientScreen',
            param,
            GenericScreenResult,
        );
    }

    public async getScreenCategoryTemplates(
        param: BaseCategoryTemplateParameter,
    ) {
        return await this.backendApiService.postPromise(
            'ScreenTemplate/GetScreenCategoryTemplates',
            param,
            GetCategoryTemplateListResult,
        );
    }

    public async updateScreenCategoryTemplate(
        param: BaseCategoryTemplateParameter,
    ) {
        return await this.backendApiService.postPromise(
            'ScreenTemplate/UpdateScreenCategoryTemplate',
            param,
            GetCategoryTemplateResult,
        );
    }

    public async resetScreenCategoryTemplate(
        param: ResetCategoryTemplateParameter,
    ) {
        return await this.backendApiService.postPromise(
            'ScreenTemplate/ResetScreenCategoryTemplate',
            param,
            GetCategoryTemplateResult,
        );
    }

    public async applyScreenCategoryTemplate(
        param: ApplyScreenCategoryTemplateParameter,
    ) {
        return await this.backendApiService.postPromise(
            'ScreenTemplate/ApplyScreenCategoryTemplate',
            param,
            BulkUpdateScreenResult,
        );
    }
}
