import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { CollectionsHelper } from '@datagalaxy/core-util';
import { EntityService } from '../shared/entity/services/entity.service';
import {
    IEntityPanelData,
    IEntityPanelMoreData,
    IEntityPanelToolsData,
    IEntityToolParams,
    IEntityToolState,
} from '../shared/entity/interfaces/entity-panel.interface';
import {
    EntityPanelTool,
    EntityPanelTool as Tool,
} from './entity-panels.types';
import { BaseService } from '@datagalaxy/core-ui';
import { ActivityLogDataOptions } from '../activityLog/activityLog.types';
import { ScreenService } from '../screens/screen.service';
import {
    EntityTypeUtil,
    IHasHddData,
    ServerType,
} from '@datagalaxy/dg-object-model';
import { WorkspaceService } from '@datagalaxy/webclient/workspace/data-access';
import { getSpaceIdFromEntityId } from '@datagalaxy/webclient/utils';
import { ProjectVersion } from '@datagalaxy/webclient/versioning/data-access';
import {
    EntityHierarchicalDataUtils,
    EntityIdentifier,
    EntityUtils,
} from '@datagalaxy/webclient/entity/utils';
import { WorkspaceIdentifier } from '@datagalaxy/webclient/workspace/utils';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';
import { WorkspaceDetails } from '@datagalaxy/webclient/workspace/domain';

/** ## Role
 *  Base class for a service managing entity tools
 */
@Injectable()
export abstract class BaseEntityToolService<
    TParams extends IEntityToolParams,
    TActiveState extends IEntityToolState,
> extends BaseService {
    //#region static
    public static sameEntityPanelData(
        a: IEntityPanelData,
        b: IEntityPanelData,
    ) {
        return (
            a === b ||
            (!a && !b) ||
            (a &&
                b &&
                EntityIdentifier.areSame(a.entityData, b.entityData) &&
                a.isKeyboardEventEnabled == b.isKeyboardEventEnabled &&
                a.allPanesReadOnly == b.allPanesReadOnly &&
                a.isCollapsedOnInit == b.isCollapsedOnInit &&
                a.showToggleButton == b.showToggleButton &&
                a.readOnly == b.readOnly &&
                a.noNavLink == b.noNavLink &&
                BaseEntityToolService.sameEntityPanelToolsData(a, b))
        );
    }
    private static sameEntityPanelToolsData(
        a: IEntityPanelData,
        b: IEntityPanelData,
    ) {
        return (
            (!a && !b) ||
            (a &&
                b &&
                CollectionsHelper.contentEquals(
                    a.orderedTools,
                    b.orderedTools,
                    true,
                    true,
                ) &&
                a.activeTool == b.activeTool &&
                BaseEntityToolService.sameEntityPanelMoreData(
                    a.entityMoreData,
                    b.entityMoreData,
                ))
        );
    }
    private static sameEntityPanelMoreData(
        a: IEntityPanelMoreData,
        b: IEntityPanelMoreData,
    ) {
        return (
            (!a && !b) ||
            (a &&
                b &&
                CollectionsHelper.contentEquals(
                    a.attributesMetaData,
                    b.attributesMetaData,
                    false,
                    true,
                ) &&
                ActivityLogDataOptions.areSame(
                    a.activityLogDataOptions,
                    b.activityLogDataOptions,
                ) &&
                a.versioningComparatorToolParam ==
                    b.versioningComparatorToolParam)
        );
    }
    //#endregion

    public parameters: IEntityToolParams;

    //#region activeState

    protected defaultActiveState = {
        activeTool: undefined,
        activeToolContext: undefined,
        activeToolParam: undefined,
        forcedTool: undefined,
        preferredTool: undefined,
    } as TActiveState;

    protected activeState = new BehaviorSubject<TActiveState>(
        this.defaultActiveState,
    );
    public get activeState$() {
        return this.activeState.asObservable();
    }
    public get activeStateValue() {
        return this.activeState.value;
    }

    public activeToolChanged$: Observable<Tool> = this.activeState$.pipe(
        map((s) => s.activeTool),
    );

    protected setActiveState(newState: Partial<TActiveState>) {
        this.activeState.next({
            ...this.activeStateValue,
            ...newState,
        });
    }
    protected setPreferedTool(preferredTool: Tool) {
        this.setActiveState({ preferredTool } as Partial<TActiveState>);
    }
    protected setForcedTool(forcedTool: Tool) {
        this.setActiveState({ forcedTool } as Partial<TActiveState>);
    }
    //#endregion

    //#region IEntityPanelService
    public get entityIdr() {
        return this.parameters?.entityIdr;
    }
    public get activeTool() {
        return this.activeStateValue?.activeTool;
    }
    public get activeToolParam() {
        return this.activeStateValue?.activeToolParam;
    }
    //#endregion

    constructor(
        protected screenService: ScreenService,
        protected entityService: EntityService,
        private workspaceService: WorkspaceService,
    ) {
        super();
    }

    //#region IEntityPanelService

    public updateActiveTool(preferredToolName?: string) {
        this.log('updateActiveTool', 'requested:', preferredToolName);

        const preferredTool = Tool[preferredToolName],
            prevPreferredTool = this.activeStateValue.preferredTool;

        this.setPreferedTool(preferredTool);

        // Force tool if requested
        if (this.activeStateValue.forcedTool != undefined) {
            this.setActiveTool(this.activeStateValue.forcedTool);
            this.setForcedTool(undefined);
            this.log('updateActiveTool', 'forcedTool', Tool[this.activeTool]);
            return true;
        }

        // active tool undefined => set it from the preferred tool
        // preferred tool changed (means the current page changed) => set it as active tool
        if (
            preferredTool != undefined &&
            (this.activeTool == undefined || preferredTool != prevPreferredTool)
        ) {
            this.setActiveTool(preferredTool);
            this.log('updateActiveTool', 'preferredTool', Tool[preferredTool]);
        } else {
            this.log('updateActiveTool', 'currentTool', Tool[this.activeTool]);
        }

        return false;
    }

    public clearActiveTool() {
        this.setActiveState({
            activeTool: undefined,
            activeToolParam: undefined,
        } as Partial<TActiveState>);
    }

    public setActiveTool(value: Tool, activeToolParam?: unknown) {
        if (value == undefined) {
            return;
        }
        const newState = { ...this.activeStateValue } as Partial<TActiveState>;
        if (this.activeStateValue.activeTool != value) {
            this.log(
                'setActiveTool change',
                Tool[this.activeStateValue.activeTool],
                '->',
                Tool[value],
                activeToolParam,
            );
            newState.activeTool = value;
            newState.activeToolParam = activeToolParam;
        } else {
            this.log('setActiveTool unchanged', Tool[value], activeToolParam);
            newState.activeToolParam = activeToolParam;
        }
        this.setActiveState(newState);
        return this.activeStateValue.activeTool;
    }

    public isActiveTool(tool: Tool) {
        return this.activeTool == tool;
    }

    public async getToolsData(
        entity: EntityItem,
        readOnly: boolean,
        orderedPossibleTools: Tool[],
        preferredTool?: Tool,
        orderedDefaultTools?: Tool[],
    ): Promise<IEntityPanelToolsData> {
        this.debug &&
            this.log('getToolsData', {
                entity,
                readOnly,
                orderedPossibleTools,
                preferredTool,
                orderedDefaultTools,
            });

        const space = await this.workspaceService.getWorkspace(
            WorkspaceIdentifier.fromEntity(entity),
        );
        const isVersioningEnabled =
            space instanceof WorkspaceDetails && space.IsVersioningEnabled;

        const enableToolDetails = !!this.parameters?.enableToolDetails;
        const isToolValid = (
            tool: EntityPanelTool,
            isValidWhenNoEntity = false,
        ) =>
            this.isToolValid(
                tool,
                entity,
                isValidWhenNoEntity,
                isVersioningEnabled,
                enableToolDetails,
                readOnly,
            );

        const orderedTools = orderedPossibleTools.filter((tool) =>
            isToolValid(tool),
        );
        if (preferredTool == undefined) {
            preferredTool = orderedTools[0];
        }

        const prevActiveTool = this.activeTool;

        // if active tool is invalid, change it
        let activeTool = prevActiveTool;
        if (!activeTool || !isToolValid(activeTool)) {
            // to the preferred tool,
            let defaultTool = preferredTool;
            orderedDefaultTools ??= orderedPossibleTools;
            if (
                orderedDefaultTools?.length &&
                !isToolValid(defaultTool, true)
            ) {
                // or the first valid default tool.
                defaultTool =
                    orderedDefaultTools.find((tool) => isToolValid(tool)) ??
                    defaultTool;
            }
            activeTool = this.setActiveTool(defaultTool);
        }

        const entityMoreData = await this.getMoreData(
            entity,
            readOnly,
            ...orderedTools,
        );
        return { orderedTools, activeTool, entityMoreData };
    }

    //#endregion

    //#endregion

    //#region called by any component or service

    /** setup the panel for the specified entity, optionally with the given tool */
    public async setupPanel(parameters: TParams) {
        this.log('super-setupPanel', parameters);
        if (parameters) {
            if (parameters.forcedTool != undefined) {
                this.forceActiveTool(Tool[parameters.forcedTool]);
                this.updateActiveTool();
            }
            if (parameters.activeTool != undefined) {
                this.updateActiveTool(parameters.activeTool);
            }
            if (parameters.activeToolContext != undefined) {
                this.setActiveState({
                    activeToolContext: parameters.activeToolContext,
                } as Partial<TActiveState>);
            }
        }
        this.parameters = parameters;
    }

    //#region IEntityPanelService

    /** sets the tool that will be used next time the panel is shown
     * (used by navigation service for to setup the panel for the target state)
     * (used by diagramUiService to force active tool at setup)
     */
    public forceActiveTool(value: Tool) {
        if (value == undefined) {
            return;
        }
        this.setForcedTool(value);
    }

    //#endregion

    //#endregion called by any component or service

    //#region internal

    protected clearData() {
        this.parameters = { entityIdr: undefined };
        this.setActiveState(this.defaultActiveState);
    }

    private isToolValid(
        tool: Tool,
        entity: EntityItem,
        whenNoEntity: boolean,
        isVersioningEnabled: boolean,
        enableToolDetails: boolean,
        readOnly?: boolean,
    ) {
        switch (tool) {
            case Tool.Details:
                return enableToolDetails;

            case Tool.LinkedObject:
                return whenNoEntity || this.canHaveLinkedObjects(entity);

            case Tool.Diagrams:
                return (
                    !this.canHaveAssets(entity) &&
                    entity.Type !== ServerType.DataProcessingItem
                );

            case EntityPanelTool.Assets:
                return !!entity && this.canHaveAssets(entity);

            case Tool.EntityTree:
                return whenNoEntity || this.canHaveEntityTree(entity);

            case Tool.VersionComparison:
                return (
                    !readOnly &&
                    isVersioningEnabled &&
                    (whenNoEntity || this.canHaveVersions(entity))
                );

            case Tool.Suggestion:
                return !readOnly;

            case Tool.ActivityLog:
                return true;

            default:
                return whenNoEntity || !!entity;
        }
    }

    private canHaveLinkedObjects(entity: EntityItem) {
        return EntityTypeUtil.canHaveLinks(entity.EntityType);
    }

    private canHaveEntityTree(entity: EntityItem) {
        return EntityTypeUtil.canHaveChildren(entity.EntityType);
    }

    private canHaveAssets(entity: EntityItem) {
        return EntityTypeUtil.canHaveAssets(entity.EntityType);
    }

    private canHaveVersions(entity: EntityItem) {
        return this.isPartOfAProject(entity);
    }

    private isPartOfAProject(entity: EntityItem) {
        const hdd = (entity as IHasHddData)?.HddData;
        return hdd
            ? EntityHierarchicalDataUtils.isProjectHddData(hdd)
            : entity?.ServerType == ServerType.Project;
    }

    private async getMoreData(
        entity: EntityItem,
        readOnly: boolean,
        ...forTools: Tool[]
    ): Promise<IEntityPanelMoreData> {
        const moreData: IEntityPanelMoreData = {};
        const isFor = (tool: EntityPanelTool) => forTools.indexOf(tool) != -1;

        if (isFor(EntityPanelTool.ActivityLog)) {
            moreData.activityLogDataOptions =
                this.getActivityLogDataOptions(entity);
        }

        if (isFor(EntityPanelTool.VersionComparison)) {
            const param = this.activeToolParam;
            moreData.versioningComparatorToolParam =
                param instanceof ProjectVersion ? param : undefined;
        }

        if (isFor(EntityPanelTool.Details)) {
            const spaceId = getSpaceIdFromEntityId(entity.ReferenceId);
            await this.screenService.ensureSpaceScreensAreLoaded(
                spaceId,
                entity.VersionId,
            );
            const amis = await this.entityService.getEntityAttributesForDetails(
                entity.ServerType,
            );
            moreData.attributesMetaData = readOnly
                ? EntityUtils.getAttributesReadOnlyClones(amis)
                : amis;
        }

        return moreData;
    }

    private getActivityLogDataOptions(entity: EntityItem) {
        return entity
            ? new ActivityLogDataOptions(
                  entity.ReferenceId,
                  ServerType[entity.ServerType],
                  WorkspaceIdentifier.fromEntity(entity),
              )
            : new ActivityLogDataOptions();
    }

    //#endregion
}
