import {
    ChangeDetectionStrategy,
    Component,
    computed,
    inject,
    input,
    output,
} from '@angular/core';
import { ProblemDetails } from '@datagalaxy/data-access';
import { DxyButtonDirective } from '@datagalaxy/ui/buttons';
import { MessageComponent } from '@datagalaxy/ui/message';
import { AiWritingComponent } from '@datagalaxy/ui/spinner';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'dxy-text-attribute-ai-generation-result',
    standalone: true,
    imports: [
        DxyButtonDirective,
        TranslateModule,
        MessageComponent,
        AiWritingComponent,
    ],
    templateUrl: './text-attribute-ai-generation-result.component.html',
    styleUrl: './text-attribute-ai-generation-result.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextAttributeAiGenerationResultComponent {
    generating = input<boolean>(false);
    error = input<unknown>();

    generationErrorMessage = computed(() => {
        const error = this.error();
        if (error instanceof ProblemDetails) {
            return this.translate.instant(
                `ProblemDetails.${error.title}.message`,
            );
        }
        return this.translate.instant(
            'Suggestions.AutoDescription.genericGenerationErrorMessage',
        );
    });

    technicalError = computed(() => {
        const error = this.error();
        return !(error instanceof ProblemDetails);
    });

    cancel = output<void>();
    accept = output<void>();

    translate = inject(TranslateService);
}
