import { Observable } from 'rxjs';
import { DxyBaseModalComponent } from './base-modal-component';
import {
    IModalDialogEvent,
    IModalDialog,
    IModalDialogResult,
    IInformOptions,
    IPromptOptions,
    IConfirmOptions,
    IShowDialogOptions,
} from './core-dialog.types';
import { IModalInstanceOptions } from './core-modal.types';
import { IModalSettings } from './IModalSettings';
import { InjectionToken } from '@angular/core';
import { DialogResult } from './enums';

export interface IDialogService {
    onDialogOpened$: Observable<IModalDialogEvent>;
    onDialogClosed$: Observable<IModalDialog>;
    onDialogResult$: Observable<IModalDialogResult>;
    currentDialogClosed$: Observable<void>;

    isActiveModal: boolean;
    isActiveRootModal: boolean;

    inform(opt: IInformOptions): Promise<void>;
    prompt(opt: IPromptOptions): Promise<string>;
    confirm(opt: IConfirmOptions): Promise<boolean>;
    confirmMessage(message: string, noTitle?: boolean): Promise<boolean>;
    promptOrDefault(
        message: string,
        defaultValue: string,
        noTitle?: boolean,
    ): Promise<string>;
    showDialog(options: IShowDialogOptions): Promise<DialogResult>;
    dismissAll(): void;
    open<
        TComponent extends DxyBaseModalComponent<TData, TResult>,
        TData,
        TResult,
    >(
        options: IModalSettings<TComponent, TData, TResult>,
    ): Promise<TResult>;
    adaptOptions<
        TComponent extends DxyBaseModalComponent<TData, TResult>,
        TData = unknown,
        TResult = unknown,
    >(
        options: IModalSettings<TComponent, TData, TResult>,
    ): void;
    makeInstanceOptions<
        TComponent extends DxyBaseModalComponent<TData, TResult>,
        TData = unknown,
        TResult = unknown,
    >(
        options: IModalSettings<TComponent, TData, TResult>,
    ): IModalInstanceOptions;
}

export const DIALOG_SERVICE_TOKEN = new InjectionToken<IDialogService>(
    'DxyDialogService',
);
