import { ArrowSpecs } from './arrow-specs';
import { DomUtil } from '@datagalaxy/core-util';
import { Dom2dUtil } from '@datagalaxy/core-2d-util';
import { ArrowComponentProps } from './arrow.types';
import { ShapeId } from '../../shapes';
import { GraphicalColor } from '@datagalaxy/shared/graphical/domain';

/**
 * Component class which represent a connector arrow
 */
export class ConnectorArrow {
    public static readonly class = 'gs-connector-arrow';

    public readonly path?: SVGPathElement;

    constructor(
        private readonly props: ArrowComponentProps,
        private arrowSpecs: ArrowSpecs,
    ) {
        this.path = DomUtil.createSvgElement('path');
        this.props.thickness ??= 1;
        this.props.color ??= GraphicalColor.Black;
    }

    public dispose() {
        this.path?.remove();
    }

    public updateProps(newProps: Partial<ArrowComponentProps>): void {
        Object.assign(this.props, newProps);
    }

    public draw(): void {
        const { shapeId, thickness, angle, position } = this.props;
        const shapeSpec = this.arrowSpecs.getSpec(shapeId, thickness);

        if (!shapeSpec) {
            this.path.remove();
            throw Error(`
                There is no shape specs corresponding
                to shapeId: ${ShapeId[shapeId]}, thickness: ${thickness}
            `);
        }

        const { noDecimals, close, points } = shapeSpec;
        const path = Dom2dUtil.pointsPathD(points, {
            roundDecimals: noDecimals,
            close,
        });

        const classes = this.getClasses(shapeSpec.shapeClass);
        DomUtil.replaceAllClasses(this.path, classes);
        this.path.setAttribute('d', path);

        const style = this.path.style;
        style.transform = `translate(${position?.x || 0}px, ${
            position?.y || 0
        }px) rotate(${angle || 0}deg)`;
    }

    private getClasses(shapeClass: string): string[] {
        const { color } = this.props;
        const colorClass = color ? `color-${GraphicalColor[color]}` : '';
        const shapeClasses = shapeClass ? shapeClass.split(/\s+/) : [];

        return [ConnectorArrow.class, colorClass, ...shapeClasses].filter(
            Boolean,
        );
    }
}
