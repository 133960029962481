import {
    autoserialize,
    deserialize,
    deserializeAs,
    inheritSerialization,
    serialize,
} from 'cerialize';
import {
    BaseMasterData,
    IHas$type,
    IHasReferenceId,
    IIsData,
    ITypeMetadata,
} from '../master-data';
import { ServerType } from '@datagalaxy/dg-object-model';
import { CoreUtil } from '@datagalaxy/core-util';
import { generateReferenceId } from '@datagalaxy/webclient/utils';
import { CDataTypeRef, DataTypeRef, IDataType } from '../data-type';
import {
    DataTypeMappingItemRef,
    DataTypeMappingRef,
} from './data-type-mapping-ref';
import { DataTypeSettingsRef } from '../data-type-settings';
import { asRefs, CRefData, IRef, subRef } from '../ref';

/** Named set of data type definitions */
export interface IDataTypeMapping extends IHasReferenceId {
    DisplayName: string;
    SystemName: string;
    Items: IRef<IDataTypeMappingItem>[];
}

/** Data type definition (implementation of a meta definition) */
export interface IDataTypeMappingItem extends IHasReferenceId {
    DisplayName: string;
    DataTypeRef: IRef<IDataType>;
    Size: number;
    Precision: number;
}

@inheritSerialization(BaseMasterData)
export class DataTypeMappingItem
    extends BaseMasterData
    implements IDataTypeMappingItem
{
    static readonly tmd: ITypeMetadata = {
        childPropertyName: 'ParentRef',
    };
    static readonly keys: (keyof IDataTypeMappingItem)[] = [
        'DisplayName',
        'Size',
        'Precision',
        'DataTypeRef',
    ];

    /**
     * $id and $type are override, so they can be serialized first
     * This is to respect a weird constraint on backend side, if those properties
     * are serialized in wrong order, the request (SaveData) will fail
     */
    @autoserialize override $id!: string;
    @autoserialize override $type!: string;

    //#region IDataTypeMappingItem
    @autoserialize public DisplayName!: string;
    @autoserialize public Size!: number;
    @autoserialize public Precision!: number;
    @deserializeAs(DataTypeRef.fromId, 'DataTypeId')
    @serialize
    public DataTypeRef = new DataTypeRef();
    //#endregion
    @deserializeAs(DataTypeMappingRef.fromId, 'ParentId')
    public ParentRef = new DataTypeMappingRef();

    public readonly ServerType = ServerType.DataTypeMappingItem;

    static from(o: IDataTypeMappingItem & Partial<IHas$type>) {
        return CoreUtil.buildPartial(
            DataTypeMappingItem,
            o,
            DataTypeMappingItem.keys,
            o?.ReferenceId,
            o?.$type,
        );
    }

    static fromOther(other: DataTypeMappingItem, contextId: string) {
        const dtmi = DataTypeMappingItem.from(other);
        dtmi.setId(generateReferenceId(contextId));
        dtmi.DataTypeRef = new DataTypeRef(other.DataTypeRef.$id);
        return dtmi;
    }

    static OnDeserialized(instance: any, json: any) {
        CoreUtil.log('OnDeserialized', instance, json);
    }

    constructor(id?: string, $type?: string) {
        super(id, $type);
    }
}

@inheritSerialization(BaseMasterData)
export class DataTypeMapping
    extends BaseMasterData
    implements IDataTypeMapping
{
    static readonly tmd: ITypeMetadata = {
        parentPropertyName: 'Mappings',
        childPropertyName: 'ParentRef',
    };
    static readonly keys: (keyof IDataTypeMapping)[] = [
        'DisplayName',
        'SystemName',
        'Items',
    ];

    /**
     * $id and $type are override, so they can be serialized first
     * This is to respect a weird constraint on backend side, if those properties
     * are serialized in wrong order, the request (SaveData) will fail
     */
    @autoserialize override $id!: string;
    @autoserialize override $type!: string;

    //#region IDataTypeMapping
    @autoserialize public DisplayName!: string;
    @autoserialize public SystemName!: string;
    @deserializeAs(DataTypeMappingItemRef.fromIds, 'ItemIds')
    @serialize
    public Items: DataTypeMappingItemRef[] = [];
    @deserializeAs(DataTypeSettingsRef.fromId, 'ParentId')
    public ParentRef = new DataTypeSettingsRef();
    //#endregion

    public readonly ServerType = ServerType.DataTypeMapping;

    static from(o: IDataTypeMapping & Partial<IHas$type>) {
        return CoreUtil.buildPartial(
            DataTypeMapping,
            o,
            DataTypeMapping.keys,
            o?.ReferenceId,
            o?.$type,
        );
    }

    static fromItems(
        items: DataTypeMappingItem[],
        contextId: string,
        parentRef: DataTypeSettingsRef,
    ) {
        const dtm = new DataTypeMapping();
        dtm.setId(generateReferenceId(contextId));
        dtm.DisplayName = 'New Technology';
        dtm.SystemName = 'Technology';
        dtm.Items = items.map(
            (dtmi) => new DataTypeMappingItemRef(dtmi.ReferenceId),
        );
        dtm.ParentRef = parentRef;
        return dtm;
    }

    static OnDeserialized(instance: any, json: any) {
        CoreUtil.log('OnDeserialized', instance, json);
    }

    constructor(id?: string, $type?: string) {
        super(id, $type);
    }
}

/** DataTypeMappingItem legacy generic format adapter */
@inheritSerialization(CRefData)
export class CMappingItem
    extends CRefData<DataTypeMappingItem>
    implements IDataTypeMappingItem, IIsData
{
    readonly ctorType = DataTypeMappingItem;
    readonly serverType = ServerType.DataTypeMappingItem;
    @deserializeAs(subRef(CDataTypeRef), 'DataTypeRef')
    private cDataTypeRef!: CDataTypeRef;
    //#region IMappingItem
    @deserialize DisplayName!: string;
    @deserialize Size!: number;
    @deserialize Precision!: number;

    protected override getProducers() {
        return [];
    }

    get DataTypeRef() {
        return this.cDataTypeRef?.asRef();
    }

    //#endregion
    asData() {
        return DataTypeMappingItem.from(this);
    }
}

/** DataTypeMapping legacy generic format adapter */
@inheritSerialization(CRefData)
export class CDataTypeMapping
    extends CRefData<DataTypeMapping>
    implements IDataTypeMapping, IIsData
{
    readonly ctorType = DataTypeMapping;
    readonly serverType = ServerType.DataTypeMapping;
    @deserializeAs(subRef(CMappingItem), 'Items')
    private cItems!: CMappingItem[];
    //#region IDataTypeMapping
    @deserialize DisplayName!: string;
    @deserialize SystemName!: string;

    get Items() {
        return asRefs(this.cItems);
    }

    //#endregion
    asData() {
        return DataTypeMapping.from(this);
    }

    protected getProducers() {
        return this.cItems;
    }
}
