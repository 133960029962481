import Quill, {
    Delta,
    DeltaOperation,
    DeltaStatic,
    Sources,
    StringMap,
    TextChangeHandler,
} from 'quill';
import {
    AfterViewInit,
    ApplicationRef,
    ChangeDetectorRef,
    Component,
    ComponentFactoryResolver,
    ElementRef,
    EventEmitter,
    HostListener,
    Inject,
    InjectionToken,
    Injector,
    Input,
    NgZone,
    OnChanges,
    OnDestroy,
    OnInit,
    Optional,
    Output,
    Self,
    SimpleChanges,
    TemplateRef,
    Type,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import {
    FormGroupDirective,
    NgControl,
    NgForm,
    FormsModule,
} from '@angular/forms';
import { FocusMonitor } from '@angular/cdk/a11y';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatLegacyFormFieldControl as MatFormFieldControl } from '@angular/material/legacy-form-field';
import { CoreUtil, DomUtil } from '@datagalaxy/core-util';
import { IRichTextContentAdapterOf } from '../rich-text-editor.types';
import {
    IMentionComponent,
    IMentionResolver,
    IRichTextMentionData,
} from '../../core-rich-text-mention.types';
import {
    IQuillContent,
    QuillEmbededMention,
} from '../rich-text-editor-quill.types';
import {
    IRichtextDefaults,
    richTextDefaults,
} from '../../core-rich-text-defaults';
import { DxyBaseMatFormFieldControl } from '../../../base';
import {
    GlobalPositionStrategy,
    Overlay,
    OverlayRef,
} from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { DxyOptionItemComponent, IListOptionItem } from '../../../components';
import { KeyboardUtil } from '@datagalaxy/utils';
import { IBaseField } from '@datagalaxy/ui/fields';
import { DxyIconButtonDirective } from '@datagalaxy/ui/buttons';

export const RICH_TEXT_DEFAULTS = new InjectionToken<IRichtextDefaults>(
    'RICH_TEXT_DEFAULTS',
);

@Component({
    selector: 'dxy-rich-text-field-control',
    templateUrl: 'rich-text-field-control.component.html',
    styleUrls: ['rich-text-field-control.component.scss'],
    providers: [
        {
            provide: MatFormFieldControl,
            useExisting: DxyRichTextFieldControlComponent,
        },
    ],
    standalone: true,
    imports: [FormsModule, DxyIconButtonDirective],
})
export class DxyRichTextFieldControlComponent
    extends DxyBaseMatFormFieldControl<string>
    implements IBaseField, AfterViewInit, OnDestroy, OnChanges, OnInit
{
    /** Defaults to global *richTextDefaults.quillContentAdapter* */
    @Input() adapter: IRichTextContentAdapterOf<DeltaOperation[]>;
    /** Note: When this is provided, the *adapter* must implement mention management  */
    @Input() mentionResolvers: IMentionResolver[];
    @Input() readonly: boolean;
    @Input() showToolbar: boolean;
    /** If true then tab key and shift+tab are not considered as text.
     * This allows to use tab and shift+tab as focusout events (aka switch to next/previous input) */
    @Input() noTabCapture: boolean;

    /** Fires before and after acquiring mention data.
     * The boolean argument is true on before, false on after */
    @Output() readonly onAcquireMentionData = new EventEmitter<boolean>();

    public showToolbarOverlay: boolean;

    public get empty() {
        return this.adapter.isEmpty(this.currentContent);
    }

    public get focused() {
        return this._focused;
    }
    public set focused(value: boolean) {
        super.setFocused(value);
        if (!value) {
            return;
        }
        this.focus();
        if (this.isFocusOriginKeyboard) {
            this.selectAll();
        }
    }

    @ViewChild('paneElement') private paneElement: ElementRef<HTMLElement>;
    @ViewChild('toolbarElement')
    private toolbarElement: ElementRef<HTMLElement>;

    @ViewChild('mentionDropdown', { read: ViewContainerRef })
    private viewContainerRef: ViewContainerRef;
    @ViewChild('mentionWrapper') mentionWrapper: TemplateRef<HTMLDivElement>;
    private overlayRef: OverlayRef;

    private editor: Quill;
    private get isMentioning() {
        return this.currentMentionResolver?.isMentionDropdownOpen;
    }
    private set isMentioning(value: boolean) {
        this.currentMentionResolver.isMentionDropdownOpen = value;
    }
    private isMentionOverlayInitDone = false;
    private isDropdownOutOfScreenX = false;
    private isDropdownOutOfScreenY = false;
    private currentMentionResolver: IMentionResolver;
    private editorElement: HTMLElement;
    private currentContent: IQuillContent;
    private _isAcquiringMentionData: boolean;
    private readonly textChangeHandler: TextChangeHandler = (
        delta: DeltaStatic,
        oldDelta: DeltaStatic,
        source: Sources,
    ) => this.onTextChange(delta, oldDelta, source);

    private get mentionContainerElement() {
        return this.viewContainerRef.element.nativeElement.parentNode;
    }

    @HostListener('document:keyup', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent) {
        if (KeyboardUtil.isEscapeKey(event) && this.viewContainerRef?.length) {
            this.closeMentionDropdown();
            event.stopPropagation();
        }
    }

    @HostListener('document:click', ['$event'])
    handleClickEvent() {
        if (this.viewContainerRef?.length) {
            this.closeMentionDropdown();
        }
    }

    constructor(
        private injector: Injector,
        private app: ApplicationRef,
        private componentFactoryResolver: ComponentFactoryResolver,
        private vcRef: ViewContainerRef,
        @Optional() @Self() ngControl: NgControl,
        @Optional() parentForm: NgForm,
        @Optional() parentFormGroup: FormGroupDirective,
        private overlay: Overlay,
        defaultErrorStateMatcher: ErrorStateMatcher,
        focusMonitor: FocusMonitor,
        elementRef: ElementRef<HTMLElement>,
        ngZone: NgZone,
        @Optional()
        @Inject(RICH_TEXT_DEFAULTS)
        private richTextDefaults?: IRichtextDefaults,
    ) {
        super(
            'dxy-rich-text-field-control',
            ngControl,
            parentForm,
            parentFormGroup,
            defaultErrorStateMatcher,
            focusMonitor,
            elementRef,
            ngZone,
        );
    }

    ngOnChanges(changes: SimpleChanges) {
        super.onChanges(changes, ['disabled', 'readonly'], () => {
            const disable =
                changes.disabled?.currentValue ||
                changes.readonly?.currentValue;
            this.editor?.enable(!disable);
        });

        super.onChange(changes, 'placeholder', () => {
            this.editor.root.dataset.placeholder = this.placeholder;
        });
    }
    ngOnInit() {
        super.ngOnInit();
        this.adapter ??=
            this.richTextDefaults?.quillContentAdapter ??
            richTextDefaults.quillContentAdapter;
        this.currentContent ??= this.adapter.deserialize(this.value ?? '');
    }
    ngAfterViewInit() {
        this.initEditor();
        this.initEditorData(this.currentContent);
        setTimeout(() => {
            this.editorElement = DomUtil.getElement(
                this.elementRef,
                '.ql-editor',
            );
            this.overlayRef = this.overlay.create();
            const templatePortal = new TemplatePortal(
                this.mentionWrapper,
                this.vcRef,
            );
            this.overlayRef.attach(templatePortal);
            this.disableToolbarTabs();
            this.subscribeToolbarPickerEvents();
        });
    }
    ngOnDestroy() {
        this.editor?.off('text-change', this.textChangeHandler);
        super.ngOnDestroy();
    }

    public writeValue(value: string): void {
        if (!this.adapter) {
            // happens once before onInit
            return;
        }
        const doc = (this.currentContent = this.adapter.deserialize(value));
        this.initEditorData(doc);
        super.writeValue(this.serialize(doc));
    }

    public onOverlayClick() {
        /* get active picker options element */
        const pickerOptions =
            this.elementRef.nativeElement.querySelector<HTMLElement>(
                '.ql-expanded .ql-picker-options',
            );
        if (!pickerOptions) {
            return;
        }

        this.togglePicker(pickerOptions);
    }

    //#region API
    public focus() {
        this.setCursorAtEnd();
    }
    public blur() {
        this.editor.blur();
    }
    public addMention(mention: IRichTextMentionData) {
        setTimeout(() => this._addMention(mention));
    }
    public addHtml(html: string) {
        setTimeout(() => this._addHtml(html));
    }
    //#endregion

    protected preventBlurOnFocusOut(event: FocusEvent) {
        if (
            this._isAcquiringMentionData ||
            super.preventBlurOnFocusOut(event)
        ) {
            return true;
        }
        this.editor.blur();
        return false;
    }

    private selectAll() {
        this.editor.setSelection(0, this.editor.getLength(), 'api');
    }
    private setCursorAtEnd() {
        this.editor.setSelection(this.editor.getLength(), 0, 'api');
    }

    private initEditor() {
        // Register custom mention parameters
        Quill.register(QuillEmbededMention, true);
        const keyboardBindings: StringMap = {};

        if (this.noTabCapture) {
            /* Override tab keybinding handler and keep event propagation by returning true.
             * This means that quill editor will not display tabs as text. */
            keyboardBindings.tab = { key: 9, handler: () => true };
        }

        const editor = (this.editor = new Quill(
            this.paneElement.nativeElement,
            {
                modules: {
                    formula: false,
                    toolbar: this.toolbarElement.nativeElement,
                    keyboard: { bindings: keyboardBindings },
                },
                placeholder: this.placeholder,
                debug: 'warn',
                readOnly: false,
                theme: 'snow',
            },
        ));

        editor.on('text-change', this.textChangeHandler);

        if (this.readonly || this.disabled) {
            editor.enable(false);
        }

        return editor;
    }

    /** Sets tabindex to -1 on toolbar elements allow us to use tab key to switch on the next input */
    private disableToolbarTabs() {
        this.toolbarElement.nativeElement
            .querySelectorAll('button, .ql-picker-label')
            .forEach((btn) => btn.setAttribute('tabindex', '-1'));
    }

    /**
     * Listen every events that can be triggered around quill Picker class
     * (cf : https://github.com/quilljs/quill/blob/master/ui/picker.js)
     *
     * Each select.toolbar-button elements will be represented by Picker class
     * Each picker element contains a label button and options list element
     */
    private subscribeToolbarPickerEvents() {
        const pickerLabelEls =
            this.toolbarElement.nativeElement.querySelectorAll(
                '.ql-picker-label',
            );

        pickerLabelEls.forEach((pickerLabelEl) => {
            const options =
                pickerLabelEl.parentElement.querySelector<HTMLElement>(
                    '.ql-picker-options',
                );

            this.registerForDestroy(
                DomUtil.addListener(pickerLabelEl, 'mousedown', () =>
                    this.togglePicker(options),
                ),
                DomUtil.addListener(
                    pickerLabelEl,
                    'keydown',
                    (event: KeyboardEvent) => {
                        if (
                            KeyboardUtil.isEscapeKey(event) ||
                            KeyboardUtil.isEnterKey(event)
                        ) {
                            this.togglePicker(options);
                        }
                    },
                ),
                DomUtil.addListener(options, 'click', () =>
                    this.togglePicker(options),
                ),
            );
        });
    }

    /**
     * Reposition picker element with fixed position when toggle on + apply overlay to avoid scroll
     * On toggle off, we remove the overlay and apply picker's default position
     * If the picker element hasn't enough space, we position it on top of his label
     */
    private togglePicker(pickerOptions: HTMLElement) {
        if (this.showToolbarOverlay) {
            this.showToolbarOverlay = false;
            this.injector.get(ChangeDetectorRef).detectChanges();
            pickerOptions.removeAttribute('style');
        } else {
            const bound = pickerOptions.getBoundingClientRect();
            const bodyBound = document.body.getBoundingClientRect();
            const labelHeight = pickerOptions.parentElement
                .querySelector('.ql-picker-label')
                ?.getBoundingClientRect().height;
            const top =
                bodyBound.bottom < bound.bottom
                    ? bound.top - bound.height - labelHeight
                    : bound.top;

            Object.assign(pickerOptions.style, {
                position: 'fixed',
                top: `${top}px`,
                width: `${bound.width}px`,
                height: `${bound.height}px`,
                minWidth: 'unset',
            });

            /* we need to wait a bit for quill to handle internal events before we can display overlay */
            setTimeout(() => (this.showToolbarOverlay = true), 200);
        }
    }

    private initEditorData(doc: IQuillContent) {
        if (!this.editor || !doc) {
            return;
        }
        if (doc.Delta?.length > 0) {
            const deltaClass = Quill.import('delta');
            const delta: Delta = new deltaClass(doc.Delta);
            this.editor.setContents(delta, 'api');
        } else if (!doc.Delta?.length && doc.HtmlText) {
            const delta = this.editor.clipboard.convert(doc.HtmlText);
            this.editor.setContents(delta, 'api');
        } else {
            this.editor.setText(doc.RawText ?? '', 'api');
        }
        this.compileMentions();
    }

    private async onTextChange(
        delta: DeltaStatic,
        oldDelta: DeltaStatic,
        source: Sources,
    ) {
        // Note: 'user' here is the source of the event, not the type of mention
        if (source !== 'user') {
            return;
        }
        if (!this.isMentionOverlayInitDone) {
            this.initMentionOverlayPosition();
        }

        if (this.isInsertMention(delta)) {
            if (this.isMentioning) {
                this.closeMentionDropdown();
            }
            this.currentMentionResolver = this.getMentionResolver(delta);
            this.isMentioning = true;
        } else if (this.isInsertSpace(delta) && this.isMentioning) {
            this.isMentioning = false;
            this.closeMentionDropdown();
        }

        if (this.isMentioning) {
            await this.showMentionOptions(delta);
            const mentionStartIndex = this.editor.getSelection().index;
            if (this.isInsertMention(delta)) {
                this.mentionContainerElement.style.visibility = 'hidden';
                setTimeout(() =>
                    this.initCurrentMentionOffset(mentionStartIndex),
                );
            } else if (
                this.isDropdownOutOfScreenX ||
                this.isDropdownOutOfScreenY
            ) {
                setTimeout(() =>
                    this.adjustCurrentMentionDropdownPosition(
                        mentionStartIndex,
                    ),
                );
            }
        }
        this.updateCurrentContentWithEditor();
    }

    private async showMentionOptions(delta: DeltaStatic) {
        let availableOptions: IListOptionItem[];
        this.onAcquireMentionData.emit((this._isAcquiringMentionData = true));
        try {
            const mentionString = this.getCurrentMentionString(delta);
            availableOptions =
                await this.currentMentionResolver.getAvailableOptions?.(
                    mentionString,
                );

            const mentionStartIndex = this.editor
                .getText()
                .slice(0, this.editor.getSelection().index)
                .lastIndexOf(this.currentMentionResolver.matchChar);

            this.viewContainerRef.clear();
            availableOptions.forEach((option) => {
                const component = this.viewContainerRef.createComponent(
                    DxyOptionItemComponent,
                );
                DomUtil.addListener(
                    component.location.nativeElement,
                    'click',
                    () =>
                        this.onClickMentionOption(
                            option,
                            mentionStartIndex,
                            mentionString.length + 1,
                        ),
                    this.ngZone,
                    false,
                );
                option.class = 'mat-menu-item';
                component.instance.option = option;
            });
        } finally {
            this.onAcquireMentionData.emit(
                (this._isAcquiringMentionData = false),
            );
        }
    }

    private initCurrentMentionOffset(mentionStartIndex: number) {
        const bodyRect = document.body.getBoundingClientRect();
        const mentionBounds = this.editor.getBounds(mentionStartIndex);
        const menuRect = this.mentionContainerElement.getBoundingClientRect();

        /* If dropdown is out of screen => move it up and save position to avoid blink when typing */

        this.isDropdownOutOfScreenX =
            mentionBounds.left + menuRect.right > bodyRect.right;
        this.isDropdownOutOfScreenY =
            mentionBounds.top + menuRect.bottom > bodyRect.bottom;
        this.mentionContainerElement.style.marginTop = `${
            mentionBounds.top -
            (this.isDropdownOutOfScreenY ? menuRect.height : -15)
        }px`;
        this.mentionContainerElement.style.marginLeft = `${
            mentionBounds.left -
            (this.isDropdownOutOfScreenX ? menuRect.width : 15)
        }px`;
        this.mentionContainerElement.style.visibility = 'visible';
    }

    /* Depending on previously computed position, just adapt */
    private adjustCurrentMentionDropdownPosition(mentionStartIndex: number) {
        const mentionBounds = this.editor.getBounds(mentionStartIndex);
        const menuRect = this.mentionContainerElement.getBoundingClientRect();

        if (this.isDropdownOutOfScreenY) {
            this.mentionContainerElement.style.marginTop = `${
                mentionBounds.top - menuRect.height
            }px`;
        }
        if (this.isDropdownOutOfScreenX) {
            this.mentionContainerElement.style.marginLeft = `${
                mentionBounds.left - menuRect.width
            }px`;
        }
    }

    private initMentionOverlayPosition() {
        const topPx = `${this.editorElement.getBoundingClientRect().top}px`;
        const leftPx = `${this.editorElement.getBoundingClientRect().left}px`;
        this.overlayRef.updatePositionStrategy(
            new GlobalPositionStrategy().top(topPx).left(leftPx),
        );
        this.isMentionOverlayInitDone = !!this.editorElement;
    }

    private onClickMentionOption(
        option: IListOptionItem,
        mentionStartIndex: number,
        mentionLength: number,
    ) {
        mentionStartIndex =
            this.computePreviousMentionsCount(mentionStartIndex) +
            mentionStartIndex;
        this.editor.deleteText(mentionStartIndex, mentionLength, 'api');
        this._addMention(
            this.currentMentionResolver.getMentionFromData(option.data),
            mentionStartIndex,
        );
        this.closeMentionDropdown();
    }

    private closeMentionDropdown() {
        this.isMentioning = false;
        this.currentMentionResolver = null;
        this.viewContainerRef.clear();
        this.mentionContainerElement.style.marginLeft = '0px';
        this.mentionContainerElement.style.marginTop = '0px';
    }

    /** initialIndex is the minimum index of new mention (if no previous mention)
     * we need to manually add the number of previous mentions to mentionStartIndex (based on this.editor.getText())
     * for this.editor.deleteText() to work properly */
    private computePreviousMentionsCount(initialIndex: number) {
        let oldPreviousMentionsCount = null;
        let previousMentionsCount = 0;
        while (previousMentionsCount != oldPreviousMentionsCount) {
            oldPreviousMentionsCount = previousMentionsCount;
            previousMentionsCount = this.editor
                .getContents(0, initialIndex + oldPreviousMentionsCount)
                .ops.filter((op) => op.insert?.mention).length;
        }
        return previousMentionsCount;
    }

    private updateCurrentContentWithEditor() {
        const doc = this.currentContent;
        doc.Delta = this.editor.getContents().ops;
        doc.RawText = this.editor.getText().trim();
        doc.HtmlText = this.editorElement.innerHTML;

        this.value = this.serialize(doc);
    }

    private isInsertMention(delta: DeltaStatic) {
        return delta.ops.some((op) =>
            this.mentionResolvers?.some((r) => r.matchChar === op?.insert),
        );
    }

    private isInsertSpace(delta: DeltaStatic) {
        return delta.ops.some((op) => ' ' === op?.insert);
    }

    private getCurrentMentionString(delta: DeltaStatic) {
        const mentionString = this.editor.getText();
        const deltaRetainIndex = delta.ops.find((op) => op.retain)?.retain ?? 0;
        const mentionStartIndex =
            mentionString
                .slice(0, deltaRetainIndex + 1)
                .lastIndexOf(this.currentMentionResolver.matchChar) + 1;
        const mentionEndIndex = mentionString
            .slice(mentionStartIndex)
            .indexOf(' ');
        return mentionString.slice(mentionStartIndex, mentionEndIndex) ?? '';
    }

    private getMentionResolver(delta: DeltaStatic) {
        return this.mentionResolvers?.find((r) =>
            delta.ops.some((op) => op?.insert === r.matchChar),
        );
    }

    private _addMention(mention: IRichTextMentionData, index = 0) {
        if (!this.adapter.addMention) {
            CoreUtil.warn('rich-text adapter does not implement addMention');
            return;
        }
        // invokes QuillEmbededMention.create
        this.editor.insertEmbed(index, 'mention', mention.mentionId, 'api');
        this.editor.insertText(index + 1, ' ', 'api');
        this.editor.setSelection(index + 2, 0, 'api');
        this.adapter.addMention(this.currentContent, mention);
        this.updateCurrentContentWithEditor();
        this.compileMentions();
        return index + 2;
    }

    private _addHtml(html: string) {
        this.editor.clipboard.dangerouslyPasteHTML(html, 'api');
        this.updateCurrentContentWithEditor();
    }

    private getDisplayedMentionElements() {
        return Array.from(
            this.paneElement.nativeElement.querySelectorAll(
                QuillEmbededMention.tagName,
            ),
        );
    }

    private compileMentions() {
        this.getDisplayedMentionElements().forEach((element) => {
            const mentionId = element.getAttribute(
                QuillEmbededMention.mentionIdAttribute,
            );
            const componentType = this.getComponentTypeFromMentionId(mentionId);
            componentType &&
                this.compileMention(mentionId, componentType, element);
        });
    }

    private getComponentTypeFromMentionId(mentionId: string) {
        const filter = (r: IMentionResolver) =>
            r &&
            (r.canRender == undefined ||
                r.canRender(mentionId, this.currentContent));
        return this.mentionResolvers?.find(filter)?.componentType;
    }

    private compileMention(
        mentionId: string,
        componentType: Type<IMentionComponent>,
        element: Element,
    ) {
        const factory =
            this.componentFactoryResolver.resolveComponentFactory(
                componentType,
            );
        const compRef = factory.create(this.injector, [], element);
        compRef.instance.mentionId = mentionId;
        compRef.instance.richTextDoc = this.currentContent;
        this.app.attachView(compRef.hostView);
    }

    private serialize(doc: IQuillContent) {
        return this.adapter?.isEmpty(doc) ? '' : this.adapter.serialize(doc);
    }
}
