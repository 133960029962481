import { TextAttributeValueSuggestion } from '@datagalaxy/webclient/suggestion/domain';

export interface AutoDescriptionState {
    acceptedSuggestion: TextAttributeValueSuggestion | undefined;
    pendingSuggestion: TextAttributeValueSuggestion | undefined;
    generationError: unknown;
}

export const initialState: AutoDescriptionState = {
    acceptedSuggestion: undefined,
    pendingSuggestion: undefined,
    generationError: undefined,
};
