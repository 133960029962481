import { FeatureFlagService } from '@datagalaxy/webclient/feature-flag';
import { MultilingualStateService } from './multilingual-state.service';
import {
    MultilingualApiService,
    MultilingualLanguageSetting,
    MultilingualSettings,
} from '@datagalaxy/webclient/multilingual/data-access';
import { Injectable } from '@angular/core';
import { take } from 'rxjs';
import { MultilingualRealtimeService } from './multilingual-realtime-service';

@Injectable()
export class MultilingualInitializer {
    constructor(
        private featureFlagService: FeatureFlagService,
        private multilingualApiService: MultilingualApiService,
        private multilingualStateService: MultilingualStateService,
        private realtimeService: MultilingualRealtimeService,
    ) {
        // Needed because with authV1 the service is instanciated before feature flags are loaded
        // To remmove when authV2 is fully implemented
        featureFlagService.ready$.pipe(take(1)).subscribe(() => {
            this.init();
        });
    }

    private async init() {
        try {
            if (!this.featureFlagService.isFeatureEnabled('multilingual')) {
                return;
            }
            this.multilingualStateService.startLoading();
            const settings: MultilingualSettings =
                await this.multilingualApiService.getSettings();
            const targetLanguages: MultilingualLanguageSetting[] =
                await this.multilingualApiService.getTargetLanguages();
            const attributeSettings =
                await this.multilingualApiService.getAttributeSettings();
            settings.targetLanguages = targetLanguages;
            settings.attributes = attributeSettings;
            const pendingBatches =
                await this.multilingualApiService.getPendingBatches();
            this.multilingualStateService.init({
                featureEnabled: true,
                settings,
                pendingBatches,
            });
            this.realtimeService.startEventLogListening();
        } catch (error) {
            console.warn('Error initializing multilingual state', error);
        } finally {
            this.multilingualStateService.stopLoading();
        }
    }
}
