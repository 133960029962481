import { TDomElement } from './2d.types';
import { Dom2dUtil } from './dom2d.util';
import { Rect } from './Rect';

/** SVG or HTML draggable handle element, to move or resize a dom element by drag */
export class DragHandle<
    T extends IDragHandleOptions = IDragHandleOptions,
> extends Rect {
    /** The DOM element for this handle. Can be SVG or HTML */
    public element: TDomElement;
    /** True if the DOM element is to be modified using style properties (html or svg element), or attributes (svg element) */
    protected useStyle: boolean;

    constructor(protected options: T = {} as T) {
        super();
    }

    /** Sets the handle's DOM element position and size */
    public init(
        /** The DOM element for this handle. Can be SVG or HTML */
        element: TDomElement,
        /** True if *element* is to be modified using style properties, instead of attributes - Defaults to true for HTMLElement, false for SVGElement */
        useStyle = element instanceof HTMLElement,
        /** true to center the element using css `translate(-50%,-50%)` */
        centerWithTransform?: boolean,
        /** Rotation angle of the element, from the horizontal axis, in radians. Applied to the element style *rotate* transform. - Defaults to 0 */
        angleRad?: number,
    ) {
        this.element = element;
        this.useStyle = useStyle;
        this.update();
        Dom2dUtil.setSize(element, this.width, this.height, useStyle);
        if (centerWithTransform || angleRad != undefined) {
            element.style.transform = `${
                centerWithTransform ? 'translate(-50%,-50%)' : ''
            } ${angleRad ? `rotate(${angleRad}rad)` : ''}`;
        }
        this.afterInit();
        return this;
    }

    /** Updates the handle's DOM element position */
    public update() {
        Dom2dUtil.setLocation(this.element, this.x, this.y, this.useStyle);
        return this;
    }

    public draw() {
        Dom2dUtil.setLocation(this.element, this.x, this.y, this.useStyle);
    }

    /** Removes the handle's element from the DOM */
    public remove() {
        this.element?.remove();
        return this;
    }

    protected afterInit() {}
}

export interface IDragHandleOptions {
    /** true to truncate decimals in coordinates */
    noDecimals?: boolean;
}
