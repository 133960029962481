import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    Input,
    NgZone,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import {
    MatLegacySelectionList as MatSelectionList,
    MatLegacySelectionListChange as MatSelectionListChange,
    MatLegacyListModule,
} from '@angular/material/legacy-list';
import {
    CdkVirtualScrollViewport,
    CdkFixedSizeVirtualScroll,
    CdkVirtualForOf,
} from '@angular/cdk/scrolling';
import {
    getOptionItemFromAdapter,
    OptionAdapterUtil,
} from '../../field-select.types';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { CollectionsHelper, DomUtil, StringUtil } from '@datagalaxy/core-util';
import { IListOptionItem } from '../option-item/option-item.types';
import { UiOptionSelectDataType } from '../../UiOptionSelect.types';
import { IMultiSelectData } from './multiselect-list.types';
import { ITabItem } from '../tabs-header/tabs-header.types';
import { DxyTabsHeaderComponent } from '../tabs-header/tabs-header.component';
import { map, Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { ListOptionUtil } from '../../IListOption';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { ISearchTermEvent, SearchInputComponent } from '@datagalaxy/ui/search';
import { DxyOptionItemComponent } from '../option-item/option-item.component';
import { DxyDataTestIdDirective } from '@datagalaxy/ui/testing';
import { MatPseudoCheckboxModule } from '@angular/material/core';
import { SpinnerComponent } from '@datagalaxy/ui/spinner';
import { NgIf, NgTemplateOutlet } from '@angular/common';

/**
 * ## Role
 * Multiselect list
 *
 * Note: headerBeforeSearch and headerAfterSearch ngContent sections are
 * here to allow filters/quickFilters inclusion. When generic filters
 * are available, it would be great to include them directly in the component
 */
@Component({
    selector: 'dxy-multiselect-list',
    templateUrl: './multiselect-list.component.html',
    styleUrls: ['./multiselect-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgIf,
        DxyTabsHeaderComponent,
        NgTemplateOutlet,
        SpinnerComponent,
        SearchInputComponent,
        TranslateModule,
        MatPseudoCheckboxModule,
        MatLegacyListModule,
        CdkVirtualScrollViewport,
        CdkFixedSizeVirtualScroll,
        CdkVirtualForOf,
        DxyDataTestIdDirective,
        DxyOptionItemComponent,
    ],
})
export class DxyMultiselectListComponent<T = unknown>
    extends DxyBaseComponent
    implements OnInit, OnChanges, AfterViewInit
{
    public static defaultItemSize = 45;
    public static entityDefaultSize = 54;
    /**
     * We don't want to show the search bar unless we have more than minItemsNumberToShowSearch items
     * The only exception is if the search is external (data.onSearch)
     */
    public static minItemsNumberToShowSearch = 7;
    public static minItemsNumberToShowTabs = 6;

    @Input() data: IMultiSelectData<T>;

    @Output() selectionChange = new EventEmitter<T[]>();

    @ViewChild('searchInput') private searchInput: SearchInputComponent;
    @ViewChild('header') private header: ElementRef<HTMLElement>;
    @ViewChild(MatSelectionList) matSelectionList: MatSelectionList;
    @ViewChild(DxyTabsHeaderComponent) tabsHeader: DxyTabsHeaderComponent;
    @ViewChild(CdkVirtualScrollViewport)
    private cdkVirtualScrollViewport: CdkVirtualScrollViewport;

    @HostBinding('style.--multiselect-list-header-height')
    get headerHeightCss() {
        return `${this.headerHeight ?? 0}px`;
    }

    @HostBinding('style.--multiselect-list-header-translate-height')
    get headerTranslateHeightCss() {
        return `-${this.isHeaderVisible ? 0 : this.headerHeight}px`;
    }

    @HostBinding('style.--multiselect-list-viewport-height')
    get viewportHeightCss() {
        return `${this.viewportHeight ?? 0}px`;
    }

    public filteredOptions: IListOptionItem<T>[];
    public filteredSelectedOptions: IListOptionItem<T>[];
    public searchTerm: string;
    /** Item size in pixels, used by scroll viewport */
    public itemSize: number;
    public minBufferPx: number;
    public maxBufferPx: number;
    public viewportHeight: number;
    public headerHeight: number;
    public loading: boolean;
    public headerTabs: ITabItem[] = [
        {
            tabId: MultiselectTabs.all,
            tabTranslateKey: 'CoreUI.MultiSelect.All',
        },
        { tabId: MultiselectTabs.selected, showEmptyDataCount: true },
    ];
    public selectedTab: ITabItem;
    public isHeaderVisible = true;
    public compareWith = (newValue: IListOptionItem<T>) =>
        this.isSelected(newValue);

    public get dataType() {
        return this.data?.dataType;
    }

    public get noDataMessage() {
        return this.data?.noDataMessage;
    }

    public get hasSelectAll() {
        return this.data?.hasSelectAll;
    }

    public get items() {
        return this.data?.items;
    }

    public get selectedItems() {
        return this.data?.selectedItems;
    }

    public set selectedItems(values: T[]) {
        this.data.selectedItems = values;
    }

    public get adapter() {
        return this.data?.adapter;
    }

    public get showSearchBox() {
        if (!this.data?.searchParams?.enabled) {
            return false;
        }
        const paramThreshold = this.data?.searchParams?.threshold;
        const isThresholdDisabled = paramThreshold === false;

        const threshold = paramThreshold
            ? paramThreshold
            : DxyMultiselectListComponent.minItemsNumberToShowSearch;

        return (
            this.searchTerm ||
            this.shownOptions?.length > threshold ||
            isThresholdDisabled
        );
    }

    public get showClearSearchTerm() {
        return !!this.searchTerm?.length;
    }

    public get showNotFound() {
        return this.searchTerm && !this.filteredOptions.length;
    }

    public get showSelectAll() {
        return this.hasSelectAll && this.hasMoreThanOneItem;
    }

    public get hasMoreThanOneItem() {
        return this.shownOptions?.length > 1;
    }

    public get selectAllState() {
        if (this.isAllSelected) {
            return 'checked';
        } else if (this.isSomeSelected) {
            return 'indeterminate';
        } else {
            return 'unchecked';
        }
    }

    public get selectAllKey() {
        return this.isAllSelected
            ? 'CoreUI.Global.UnselectAll'
            : 'CoreUI.Global.SelectAll';
    }

    public get showFoundCount() {
        return !!this.searchTerm && !this.showSelectAll;
    }

    public get isAllSelected() {
        return !this.filteredOptions?.some(
            (option) => !this.isSelected(option),
        );
    }

    public get isSomeSelected() {
        return (
            !this.isAllSelected &&
            this.filteredOptions?.some((option) => this.isSelected(option))
        );
    }

    public get showAllOptions() {
        return (
            !this.selectedTab || this.selectedTab?.tabId === MultiselectTabs.all
        );
    }

    public get showSelectedOptions() {
        return this.selectedTab?.tabId === MultiselectTabs.selected;
    }

    public get showNoDataMessage() {
        return !this.items?.length && !!this.noDataMessage;
    }

    public get hasSelectedValues() {
        return !!this.selectedItems?.length;
    }

    public get hasScrollBar() {
        return DomUtil.isScrollbarVisible(
            this.elementRef,
            '.menu-section-scroll',
        );
    }

    public get showSelectAllForSelectedTab() {
        const selectedItems = this.shownOptions.filter((selectedItem) => {
            return !selectedItem.disabled && this.isSelected(selectedItem);
        });
        return selectedItems.length > 1;
    }

    protected get showTabs() {
        return (
            this.items?.length >
                DxyMultiselectListComponent.minItemsNumberToShowTabs ||
            this.data?.onSearch
        );
    }

    protected get getCheckedType() {
        if (
            this.shownOptions.some((selectedItem) => {
                return selectedItem.disabled;
            })
        ) {
            return 'indeterminate';
        }
        return 'checked';
    }

    private get shownOptions() {
        return this.showAllOptions
            ? this.filteredOptions
            : this.filteredSelectedOptions;
    }

    /**
     * Multiselect data items transformed to IListOptionItem
     */
    private cachedOptions: IListOptionItem<T>[];
    /**
     * Multiselect data selected items transformed to IListOptionItem
     */
    private cachedSelectedOptions: IListOptionItem<T>[];
    private scrollSubscription: Subscription;

    constructor(
        private elementRef: ElementRef<HTMLElement>,
        private changeDetector: ChangeDetectorRef,
        private translate: TranslateService,
        private ngZone: NgZone,
    ) {
        super();
    }

    ngOnChanges(changes: SimpleChanges) {
        super.onChange(changes, 'data', () => this.init());
    }

    ngOnInit() {
        this.init();
    }

    ngAfterViewInit() {
        this.refreshViewPort();
    }

    //#region API
    /**
     * Refresh viewport and tab-header ink bar
     * This method is useful if this component is init without being visible at first
     * Like when it is used inside a mat-menu, on openMenu we need to call this method
     * to refresh UI
     */
    public refreshUi() {
        this.refreshViewPort();
        this.tabsHeader?.realignInkBar();
    }

    public focusSearch() {
        this.searchInput?.focusInput();
    }

    public async triggerSearch() {
        await this.onSearched({
            searchString: this.searchTerm,
            isFirstSearch: false,
        });
    }

    //#endregion

    //#region events

    public async onTabChange(tab: ITabItem) {
        this.selectedTab = tab;
        await this.onSearched({
            searchString: '',
            isFirstSearch: false,
        });

        if (this.showSelectedOptions) {
            this.filteredSelectedOptions = this.cachedSelectedOptions;
        } else {
            this.filteredOptions = this.cachedOptions;
        }
        if (!this.isHeaderVisible) {
            this.toggleHeader(true);
        }
        this.changeDetector.detectChanges();
        this.refreshViewPort();
        this.subscribeToViewPortScroll();
    }

    public async onSearched(event: ISearchTermEvent) {
        const searchTerm = (this.searchTerm = event.searchString);
        if (this.data.onSearch) {
            this.loading = true;
            this.isHeaderVisible = true;
            this.data.items = this.sortOptions(
                await this.data.onSearch(searchTerm),
            );
            this.loading = false;
            this.init();
        } else {
            this.filterOptions();
        }
        if (!this.filteredOptions?.length) {
            this.data.onEmptySearchResult?.(searchTerm);
        }
    }

    public onSelectedOptionsSearch(event: ISearchTermEvent) {
        const searchTerm = (this.searchTerm = event.searchString);

        this.filteredSelectedOptions = StringUtil.filterSearched(
            searchTerm,
            this.cachedSelectedOptions,
            (d) => ListOptionUtil.getText(d, this.translate),
        );
    }

    public async onToggleAllOptions() {
        const isSelected = this.isAllSelected;
        const itemsToBeToggled = this.filteredOptions
            .filter((item) => this.isSelected(item) === isSelected)
            .map((item) => item.data);
        await this.toggleItems(isSelected, itemsToBeToggled);
    }

    public async onUnselectAll() {
        const selectedShowedOptions = this.selectedItems.filter(
            (selectedItem) => {
                return this.shownOptions
                    .filter((opt) => !opt.disabled)
                    .map((opt) => opt.data)
                    .includes(selectedItem);
            },
        );
        await this.toggleItems(true, selectedShowedOptions);
    }

    public async onSelectOption(event: MatSelectionListChange) {
        const canBeToggledFn = this.data.canBeToggled;
        const selectedItems = this.selectedItems?.slice() || [];

        await Promise.all(
            event.options.map(async (matListOption) => {
                const data = (matListOption.value as IListOptionItem<T>).data;
                if (canBeToggledFn) {
                    /**
                     * force MatListOption to previous select value
                     * we don't want it to blink if canBeToggle return false
                     */
                    const previouslySelected = (matListOption.selected =
                        !matListOption.selected);
                    const canBeToggled = await canBeToggledFn(
                        data,
                        previouslySelected,
                    );
                    if (!canBeToggled) {
                        return;
                    }
                    matListOption.selected = !previouslySelected;
                }
                if (matListOption.selected) {
                    selectedItems.push(data);
                } else {
                    CollectionsHelper.removeElement(selectedItems, data);
                }
            }),
        );
        this.onSelectionChange(selectedItems);
    }

    //#endregion events

    public isSelected(option: IListOptionItem<T>) {
        return this.selectedItems?.includes(option.data);
    }

    public isOptionDisabled(option: IListOptionItem<T>) {
        return !!option.disabled;
    }

    private refreshViewPort(scrollTop = true) {
        const options = this.showAllOptions
            ? this.filteredOptions
            : this.filteredSelectedOptions;
        this.viewportHeight =
            options?.length && this.itemSize
                ? options?.length * this.itemSize
                : 0;
        this.log(`ViewportHeight: ${this.viewportHeight}px`);
        this.changeDetector.detectChanges();
        this.headerHeight = this.header?.nativeElement.clientHeight;
        this.log(`headerHeight: ${this.headerHeight}px`);
        this.changeDetector.detectChanges();
        const isVisible = !!this.elementRef.nativeElement.offsetHeight;
        if (!isVisible) {
            return;
        }
        this.cdkVirtualScrollViewport?.checkViewportSize();
        if (scrollTop) {
            this.cdkVirtualScrollViewport?.scrollToIndex(0);
        }
    }

    private init() {
        this.isHeaderVisible = true;
        this.filteredOptions = this.cachedOptions = this.makeOptions();
        this.setItemSize();
        this.setSelectedTabsCount();
        this.makeSelectedOptions();
        this.refreshViewPort();
        this.subscribeToViewPortScroll();
    }

    private onSelectionChange(selectedItems: T[]) {
        this.selectedItems = selectedItems;
        this.data?.onSelectionChange?.(selectedItems);
        this.selectionChange.emit(selectedItems);
        this.setSelectedTabsCount();
        this.makeSelectedOptions();

        if (this.showSelectedOptions) {
            /**
             * MatSelectionList can't track selected items correctly after removing one of them
             * when we unselect it, so we need to force them to be selected
             * Note: we could disable templateCacheSize for cdkVirtualFor to avoid this trick
             * but this is not advised since it would affect performance on big selected list
             */
            this.matSelectionList?.options.forEach(
                (option) => (option.selected = true),
            );
        }
        this.changeDetector.detectChanges();
    }

    private makeSelectedOptions() {
        this.cachedSelectedOptions = this.sortOptions(this.selectedItems)?.map(
            (value) =>
                getOptionItemFromAdapter(value, this.adapter, this.translate),
        );
        this.filteredSelectedOptions = StringUtil.filterSearched(
            this.searchTerm,
            this.cachedSelectedOptions,
            (d) => ListOptionUtil.getText(d, this.translate),
        );
    }

    private setSelectedTabsCount() {
        const selectedTab = this.headerTabs[1];
        const count = this.selectedItems?.length;
        selectedTab.contentDataCount = count;
        selectedTab.tabText = this.translate.instant(
            'CoreUI.MultiSelect.Selected',
            { count },
        );
        this.headerTabs = [...this.headerTabs];
    }

    private getOptionText(option: T) {
        return OptionAdapterUtil.getText(option, this.adapter, this.translate);
    }

    private makeOptions(): IListOptionItem<T>[] {
        return this.sortOptions(this.items)?.map((item) =>
            getOptionItemFromAdapter(item, this.adapter, this.translate),
        );
    }

    private sortOptions(items: T[]) {
        return this.data?.sortOptions
            ? this.data.sortOptions(items)
            : CollectionsHelper.orderBy(items, (item) =>
                  StringUtil.normalizeForSearch(this.getOptionText(item)),
              );
    }

    private filterOptions() {
        this.filteredOptions = StringUtil.filterSearched(
            this.searchTerm,
            this.cachedOptions,
            (d) => ListOptionUtil.getText(d, this.translate),
        );
        this.refreshViewPort(true);
    }

    private setItemSize() {
        this.itemSize =
            this.dataType === UiOptionSelectDataType.entityReference
                ? DxyMultiselectListComponent.entityDefaultSize
                : DxyMultiselectListComponent.defaultItemSize;
        this.minBufferPx = 5 * this.itemSize;
        this.maxBufferPx = 10 * this.itemSize;
    }

    private async toggleItems(isSelected: boolean, items: T[]) {
        const canBeAllToggledFn = this.data.canBeAllToggled;
        const selectedItems = this.selectedItems?.slice() || [];
        let itemsToToggle: T[];

        if (canBeAllToggledFn) {
            itemsToToggle = await canBeAllToggledFn(items, isSelected);
        } else {
            itemsToToggle = items;
        }
        if (isSelected) {
            CollectionsHelper.removeElements(selectedItems, itemsToToggle);
        } else {
            const notAlreadySelected = itemsToToggle.filter(
                (it) => !selectedItems.includes(it),
            );
            selectedItems.push(...notAlreadySelected);
        }

        this.onSelectionChange(selectedItems);
        this.changeDetector.detectChanges();
    }

    /**
     * Listen viewport scroll events
     * On scroll down, we will hide the header, and vice versa on scroll up
     */
    private subscribeToViewPortScroll() {
        this.scrollSubscription?.unsubscribe();
        let virtualScrollOffset = 0;
        this.ngZone.runOutsideAngular(() => {
            this.scrollSubscription = this.cdkVirtualScrollViewport
                ?.elementScrolled()
                .pipe(
                    map(() => {
                        const previousOffset = virtualScrollOffset;
                        const currentOffset =
                            this.cdkVirtualScrollViewport.measureScrollOffset(
                                'top',
                            );
                        virtualScrollOffset = currentOffset;

                        if (
                            previousOffset < currentOffset &&
                            currentOffset >=
                                this.header?.nativeElement.clientHeight
                        ) {
                            return ScrollEvent.scrollDown;
                        } else if (previousOffset >= currentOffset) {
                            return ScrollEvent.scrollUp;
                        }
                        return null;
                    }),
                    distinctUntilChanged(),
                )
                .subscribe((event: ScrollEvent) => {
                    this.log(`Virtual scroll event: ${ScrollEvent[event]}`);
                    if (!event) {
                        return;
                    }
                    this.toggleHeader(event === ScrollEvent.scrollUp);
                });
        });
    }

    private toggleHeader(isVisible: boolean) {
        this.isHeaderVisible = isVisible;
        this.changeDetector.detectChanges();
    }
}

enum MultiselectTabs {
    all = 'all',
    selected = 'selected',
}

enum ScrollEvent {
    unknown = 0,
    scrollUp,
    scrollDown,
}
