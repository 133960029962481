<ng-container *ngIf="sidePanelSpec">
    <button
        *ngIf="!sidePanelExpanded"
        dxyButton
        variant="secondary"
        class="side-panel-button graphical-toolbar-button"
        [ngClass]="sidePanelSide"
        (click)="onSidePanelButtonClick()"
    >
        <i
            *ngIf="sidePanelSpec.buttonGlyphClass"
            [ngClass]="sidePanelSpec.buttonGlyphClass"
        ></i>
        <span [translate]="sidePanelSpec.buttonTextKey"></span>
    </button>
    <dxy-graphical-side-panel
        class="side-panel"
        [ngClass]="sidePanelSide"
        [noHeader]="sidePanelSpec.noHeader"
        [buttonTextKey]="sidePanelSpec.buttonTextKey"
        [class.side-panel-expanded]="sidePanelExpanded"
        [class.side-panel-collapsed]="!sidePanelExpanded"
        [class.side-panel-fullscreen]="isFullScreenLocal"
        (close)="onSidePanelCloseClick()"
    >
        <ng-content
            side-panel-content
            select="[side-panel-content]"
        ></ng-content>
    </dxy-graphical-side-panel>
</ng-container>

<dxy-graphical-toolbar
    class="left-toolbar"
    [ngClass]="toolbarsClass"
    placement="w"
    [options]="actionButtons"
    (functional)="onFunctional($event)"
    logId="left-toolbar"
    [class.side-panel-expanded]="sidePanelExpanded"
    [class.side-panel-collapsed]="!sidePanelExpanded"
    [ngClass]="sidePanelSide"
></dxy-graphical-toolbar>

<div
    *ngIf="!noZoom"
    class="bottom-right-toolbar graphical-toolbar-root"
    [class.side-panel-expanded]="sidePanelExpanded"
    [class.side-panel-collapsed]="!sidePanelExpanded"
    [ngClass]="sidePanelSide"
>
    <div class="wrapper-v">
        <div class="mini-map" [class.visible]="showMiniMap">
            <ng-content select="[mini-map-content]"></ng-content>
        </div>
        <div class="wrapper-h">
            <dxy-graphical-toolbar
                [class]="toolbarsClass"
                [isInToolbarRoot]="true"
                placement="s"
                [options]="viewButtons"
                (functional)="onFunctional($event)"
                logId="bottom-right-toolbar"
            ></dxy-graphical-toolbar>
        </div>
    </div>
</div>

<div class="content-wrapper">
    <div
        class="content"
        [ngClass]="sidePanelSide"
        [class.side-panel-expanded]="sidePanelExpanded"
        [class.side-panel-collapsed]="!sidePanelExpanded"
    >
        <ng-content></ng-content>
    </div>
</div>
