import {
    ChangeDetectorRef,
    Directive,
    HostBinding,
    inject,
    Input,
    OnChanges,
    SimpleChanges,
} from '@angular/core';
import { ICellComponent, ICellParams } from './cell-components.types';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { CoreUtil } from '@datagalaxy/core-util';

/**
 * ## Role
 * Base class for a renderer component.
 * Can be used with omni-grid and IListOption
 */
@Directive()
export abstract class BaseCellComponent<
        TEntity = any,
        TValue = any,
        TCellParams extends ICellParams<TEntity, TValue> = ICellParams<
            TEntity,
            TValue
        >,
    >
    extends DxyBaseComponent
    implements ICellComponent<TEntity, TValue, TCellParams>, OnChanges
{
    @Input() params: TCellParams;

    @HostBinding('class.is-mini') get miniTagClass() {
        return this.isMini;
    }

    public get data(): TEntity {
        return this.params?.data;
    }
    public get value(): TValue {
        return this.params?.value;
    }
    public get isMini() {
        return this.params?.isMini;
    }

    protected cd = inject(ChangeDetectorRef);

    constructor() {
        super();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.onChange(
            changes,
            'params',
            () => {
                this.updateBindings();
                this.updateLayout();
            },
            true,
        );
    }

    //#region IGridCellComponent/ICellRendererAngularComp

    // https://www.codeproject.com/Articles/5266363/agGrid-for-Angular-The-Missing-Manual

    agInit(params: TCellParams): void {
        // Catch errors to ensure grid rendering is not stopped for a single cell error
        try {
            this.params = params;
            this.initInternal();
            this.updateBindings();
            this.updateLayout();
        } catch (error) {
            CoreUtil.warn('error during ag-grid cell init', { params, error });
        }
    }

    /** Get the cell to refresh.
     *
     * Return true if successful. Return false if not (or you don't have refresh logic), then the grid will refresh the cell for you.
     *
     * Return true by default, if you want a specific update strategy, feel free to override this method
     *
     * [Refresh documentation] https://www.ag-grid.com/angular-data-grid/view-refresh/
     */
    refresh(params: TCellParams): boolean {
        this.params = params;
        this.updateBindings();
        this.updateLayout();
        this.cd?.detectChanges();
        return true;
    }

    //#endregion

    // to be overridden
    /** method called by agInit only to initialize some component mechanism (for exemple: listeners) */
    protected initInternal() {}

    // to be overridden
    /** method called by agInit and refresh to update all component bindings */
    protected updateBindings() {}

    // to be overridden
    /** method called by agInit and refresh to update non bindings component element
     * also called independently by omni-grid: see IOmniGridApi.refreshCellsLayout
     * Example: Resizing a column could trigger a new component width calculation
     */
    public updateLayout() {}
}
