import {
    ActivatedRouteSnapshot,
    CanActivateFn,
    Router,
    RouterStateSnapshot,
} from '@angular/router';
import { inject } from '@angular/core';
import { AppConfigService } from '@datagalaxy/webclient/config';

/**
 * Removes the hash fragment (/#/) from the URL.
 * This guard ensures backward compatibility with old AngularJS URLs.
 *
 * Examples:
 * - Converts 'http://example.com/#/home' to 'http://example.com/home'
 * - Converts 'http://example.com/#/about' to 'http://example.com/about'
 */
export const AppHashGuard: CanActivateFn = (
    _route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
) => {
    const router = inject(Router);
    const appConfig = inject(AppConfigService);

    if (!appConfig.ENABLE_AUTH_V2) {
        return true;
    }

    if (state.url.indexOf('/#') > -1) {
        return router.parseUrl(state.url.replace('/#', ''));
    }

    return true;
};
