import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';

/**
 * ## Role
 * Remove any query param from url
 */
@Injectable({
    providedIn: 'root',
})
export class QueryParamRemover {
    private router = inject(Router);

    async remove(queryParams: string[]) {
        const urlTree = this.router.getCurrentNavigation().finalUrl;
        const currentQueryParams = urlTree.queryParams;

        const newParams = {};
        for (const qp of queryParams) {
            delete currentQueryParams[qp];
            newParams[qp] = undefined;
        }

        return await this.router.navigateByUrl(urlTree);
    }
}
