import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    forwardRef,
    OnChanges,
    OnInit,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { BaseAttributeFilter } from '../base-attribute-filter.component';
import { AttributeValueListFilterModel } from './AttributeValueListFilterModel';
import { AttributeFilterService } from '../attribute-filter.service';
import {
    IValueListFilterConfig,
    IValueListFilterData,
    ValueListFilterOperator,
    DxyValueListFilterComponent,
} from '@datagalaxy/core-ui/filters';
import { ICellRenderData } from '@datagalaxy/core-ui/cell-components';
import { AttributeDataService } from '../../attribute-data.service';
import { EntityCardCellComponent } from '../../../entityCard/entity-card/entity-card-cell.component';
import { multiSelectEntityCardParams } from '../../../entityCard/entity-card/entity-card-cell.types';
import { UserCellComponent } from '@datagalaxy/webclient/user/ui';
import { IOptionAdapter } from '@datagalaxy/core-ui';
import { CollectionsHelper, StringUtil } from '@datagalaxy/core-util';
import { AttributeFilterAction } from '../attribute-filter.types';
import {
    AttributeMetaType,
    AttributeMetaValue,
} from '@datagalaxy/webclient/attribute/domain';
import { FilterOperator } from '@datagalaxy/webclient/filter/domain';
import { AttributeCollectionCellComponent } from '../../../shared-ui/cells/attribute-collection-cell/attribute-collection-cell.component';
import { NgIf } from '@angular/common';

/**
 * ## Role
 * Display a value list attribute filter
 */
@Component({
    selector: 'app-attribute-value-list-filter',
    templateUrl: './attribute-value-list-filter.component.html',
    styleUrls: ['./attribute-value-list-filter.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        DxyValueListFilterComponent,
        NgIf,
        forwardRef(() => AttributeCollectionCellComponent),
    ],
})
export class AttributeValueListFilterComponent
    extends BaseAttributeFilter<AttributeValueListFilterModel>
    implements OnInit, OnChanges
{
    @ViewChild(DxyValueListFilterComponent)
    filterComponent: DxyValueListFilterComponent<AttributeMetaValue>;

    filter: IValueListFilterData<AttributeMetaValue>;
    filterOptions: AttributeMetaValue[];
    filterAdapter: IOptionAdapter<AttributeMetaValue> = {
        getIconUrl: (amv) => amv?.iconUrl,
        getId: (amv) => amv?.Key,
        getText: (amv) => amv?.translatedDisplayName,
        getClass: (amv) => (this.isQuickFilter && !amv ? 'user-choice' : ''),
        getTextKey: (amv) =>
            this.isQuickFilter && !amv ? 'UI.QuickFilters.letUserChoice' : '',
        getRenderData: (amv) => this.getRenderData(amv),
        getTagColor: (amv) => this.getTagColor(amv),
        getGlyphClass: (amv) => amv?.glyphClass,
        getStyle: (amv) => this.getStyle(amv),
    };
    filterConfig: IValueListFilterConfig = {
        hasSearch: true,
        hasSelectAll: true,
        sortOptions: (options) => this.sortOptions(options),
    };

    public get isHierarchical() {
        return this.attributeType === AttributeMetaType.Hierarchy;
    }
    public get isTechnology() {
        return this.attributeType === AttributeMetaType.Technology;
    }
    public get isTagAttribute() {
        return AttributeDataService.isTagAttribute(this.attributeType);
    }
    public get isUserAttribute() {
        return AttributeDataService.isUserOrPersonAttribute(this.attributeType);
    }
    public get showUserCollection() {
        return this.isUserAttribute && this.filter?.values?.length;
    }
    public get showTagCollection() {
        return this.isTagAttribute && this.filter?.values?.length;
    }
    public get hideIcon() {
        return this.isTagAttribute || this.isUserAttribute;
    }
    public get operators() {
        return this.filterItemData.operators.map((op) =>
            this.getTypedOperator(op),
        );
    }

    constructor(
        attributeFilterService: AttributeFilterService,
        private cd: ChangeDetectorRef,
    ) {
        super(attributeFilterService);
    }

    ngOnInit() {
        super.ngOnInit();
        this.initAsync();
        this.subscribeEvents();
    }

    ngOnChanges(changes: SimpleChanges) {
        super.onChange(changes, 'filterItemData', () => this.initAsync());
    }

    public onFilterChange() {
        this.filterItemData.operator =
            FilterOperator[ValueListFilterOperator[this.filter.operator]];
        this.filterItemData.values = this.filter.values;
        this.onAction.emit(AttributeFilterAction.itemChanged);
    }

    private async initAsync() {
        const amvs = (this.filterOptions =
            await this.getAttributeMetaValueOptions());
        const selectedItems = amvs?.filter((amv) =>
            this.filterItemData.values?.some((it) => it.Key === amv.Key),
        );

        if (this.isQuickFilter) {
            this.filterOptions.unshift(null);
        }

        this.filter = {
            operator: this.getTypedOperator(this.filterItemData.operator),
            values: selectedItems,
        };
        this.cd.detectChanges();
    }

    private subscribeEvents() {
        this.subscribe(this.filterItemData.itemChanged$, () =>
            this.initAsync(),
        );
    }

    private async getAttributeMetaValueOptions() {
        return (
            (await this.attributeFilterService.getAvailableValues(
                this.filterItemData,
            )) || this.listValues
        );
    }

    private sortOptions(amvs: AttributeMetaValue[]) {
        return this.isHierarchical || this.isTechnology
            ? amvs
            : CollectionsHelper.orderBy(amvs, (amv) =>
                  StringUtil.normalizeForSearch(amv.translatedDisplayName),
              );
    }

    private getTypedOperator(filterOperator: FilterOperator) {
        const operatorText = FilterOperator[filterOperator];
        return ValueListFilterOperator[operatorText];
    }

    //region adapter methods
    private getStyle(amv: AttributeMetaValue) {
        const marginLeft = (amv?.getParents?.()?.length || 0) * 20;
        return this.isHierarchical ? { marginLeft: `${marginLeft}px` } : null;
    }

    private getRenderData(amv: AttributeMetaValue): ICellRenderData {
        if (!amv) {
            return undefined;
        }
        if (AttributeDataService.isEntityRefAttribute(this.attributeType)) {
            return {
                renderer: EntityCardCellComponent,
                param: {
                    ...multiSelectEntityCardParams,
                    data: amv,
                },
            };
        } else if (
            AttributeDataService.isUserOrPersonAttribute(this.attributeType)
        ) {
            return {
                renderer: UserCellComponent,
                param: { value: { userId: amv?.Key } },
            };
        }
        return undefined;
    }

    private getTagColor(amv: AttributeMetaValue) {
        return this.isTagAttribute ? amv?.Color : undefined;
    }
    //region adapter methods
}
