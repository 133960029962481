import { Injectable } from '@angular/core';
import {
    BackendApiService,
    BaseServiceParameter,
    BaseServiceResult,
} from '@datagalaxy/data-access';
import {
    BaseTeamParameter,
    CloneTeamParameter,
    CreateTeamParameter,
    CreateTeamResult,
    DeleteTeamsParameter,
    GetTeamPublicDataResult,
    GetTeamResult,
    GetTeamsParameter,
    GetTeamsResult,
    PreCreateTeamParameter,
    PreCreateTeamResult,
    PredeleteTeamsParameter,
    PreDeleteTeamsResult,
    UpdateTeamParameter,
    UpdateTeamResult,
} from './team';
import { GetTeamAuditDataResult } from './team-audit';
import {
    AddTeamMembersParameter,
    AddTeamMembersResult,
    CreateTeamMembershipRequestResult,
    DeleteTeamMembersParameter,
    GetTeamMembersResult,
    UpdateMembershipRequestParameter,
} from './team-member';

@Injectable({ providedIn: 'root' })
export class TeamApiService {
    constructor(private backendApiService: BackendApiService) {}

    public async getTeams(param: GetTeamsParameter) {
        return await this.backendApiService.postPromise(
            'Team/GetTeams',
            param,
            GetTeamsResult,
        );
    }

    public async getTeamsPublicData(param: BaseServiceParameter) {
        return await this.backendApiService.postPromise(
            'Team/GetTeamPublicDatas',
            param,
            GetTeamPublicDataResult,
        );
    }

    public async getTeam(param: BaseTeamParameter) {
        return await this.backendApiService.postPromise(
            'Team/GetTeam',
            param,
            GetTeamResult,
        );
    }

    public async getTeamMembers(param: BaseTeamParameter) {
        return await this.backendApiService.postPromise(
            'Team/GetTeamMembers',
            param,
            GetTeamMembersResult,
        );
    }

    public async createTeam(param: CreateTeamParameter) {
        return await this.backendApiService.postPromise(
            'Team/CreateTeam',
            param,
            CreateTeamResult,
        );
    }

    public async cloneTeam(param: CloneTeamParameter) {
        return await this.backendApiService.postPromise(
            'Team/CloneTeam',
            param,
            CreateTeamResult,
        );
    }

    public async preCreateTeam(param: PreCreateTeamParameter) {
        return await this.backendApiService.postPromise(
            'Team/PreCreateTeam',
            param,
            PreCreateTeamResult,
        );
    }

    public async deleteTeams(param: DeleteTeamsParameter) {
        return await this.backendApiService.postPromise(
            'Team/DeleteTeams',
            param,
            BaseServiceResult,
        );
    }

    public async updateTeam(param: UpdateTeamParameter) {
        return await this.backendApiService.postPromise(
            'Team/UpdateTeam',
            param,
            UpdateTeamResult,
        );
    }

    public async addTeamMembers(param: AddTeamMembersParameter) {
        return await this.backendApiService.postPromise(
            'Team/AddTeamMembers',
            param,
            AddTeamMembersResult,
        );
    }

    public async deleteTeamMembers(param: DeleteTeamMembersParameter) {
        return await this.backendApiService.postPromise(
            'Team/DeleteTeamMembers',
            param,
            BaseServiceResult,
        );
    }

    public async createTeamMembershipRequest(param: BaseTeamParameter) {
        return await this.backendApiService.postPromise(
            'Team/CreateMembershipRequest',
            param,
            CreateTeamMembershipRequestResult,
        );
    }

    public async updateTeamMembershipRequest(
        param: UpdateMembershipRequestParameter,
    ) {
        return await this.backendApiService.postPromise(
            'Team/UpdateMembershipRequest',
            param,
            GetTeamMembersResult,
        );
    }

    public async getTeamHistory(param: BaseTeamParameter) {
        return await this.backendApiService.postPromise(
            'Team/GetTeamAuditData',
            param,
            GetTeamAuditDataResult,
        );
    }

    public async preDeleteTeam(param: PredeleteTeamsParameter) {
        return await this.backendApiService.postPromise(
            'Team/PreDeleteTeams',
            param,
            PreDeleteTeamsResult,
        );
    }
}
