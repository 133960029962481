import { EntityItem } from '@datagalaxy/webclient/entity/domain';
import { BaseStateService } from '@datagalaxy/utils';
import { EntityUtils } from '@datagalaxy/webclient/entity/utils';
import { CollectionsHelper, CoreUtil } from '@datagalaxy/core-util';
import { EntityEventService } from '../shared/entity/services/entity-event.service';

export interface EntitiesState {
    entities: EntityItem[];
}

export class EntitiesStore extends BaseStateService<EntitiesState> {
    constructor(entityEventService: EntityEventService) {
        super({ entities: [] });

        /**
         * Entity creation event should not be handled here, but instead by
         * the consumer of this store.
         *
         * A new entity can't just be inserted in the store without having
         * the context. For instance, the entity could be from the catalog,
         * while we are handling glossary entities.
         */

        entityEventService.subscribeEntityUpdate(null, (entity) =>
            this.updateEntity(entity),
        );

        entityEventService.subscribeEntityBulkUpdate(null, (entities) =>
            this.updateEntities(entities),
        );

        /**
         * Be aware that the delete event will just delete the entity from the store.
         * It will not delete the entity's children for instance.
         * So consider is as a fallback for now.
         *
         * In the future, we could add a cascade delete feature.
         * Using entities hierarchical data, we could delete all the children
         */
        entityEventService.subscribeEntityDelete(null, (data) =>
            this.deleteEntities(data.deletedIds),
        );
    }

    reset() {
        this.setState({ entities: [] });
    }

    selectEntities() {
        return this.select((state) => state.entities);
    }

    setEntities(entities: EntityItem[]): void {
        this.setState({ entities });
    }

    addEntities(entities: EntityItem[]): void {
        this.setState({
            entities: [...this.state.entities, ...entities],
        });
    }

    updateEntity(entity: EntityItem): void {
        this.updateEntities([entity]);
    }

    updateEntities(entities: EntityItem[]): void {
        const updatedEntities = entities
            .map((entity) => {
                const cachedEntity = this.state.entities.find(
                    (e) => e.ReferenceId === entity.ReferenceId,
                );

                if (!cachedEntity) {
                    return;
                }

                EntityUtils.mergeEntity(cachedEntity, entity, true);

                return CoreUtil.cloneDeep(cachedEntity);
            })
            .filter((entity) => entity) as EntityItem[];

        CollectionsHelper.replace(this.state.entities, (old) => {
            const newEntity = updatedEntities.find(
                (entity) => entity.ReferenceId === old.ReferenceId,
            );

            return newEntity ? newEntity : old;
        });

        this.setState({
            entities: [...this.state.entities],
        });
    }

    deleteEntities(ids: string[]): void {
        this.setState({
            entities: [
                ...this.state.entities.filter(
                    (entity) => !ids.includes(entity.ReferenceId),
                ),
            ],
        });
    }

    getEntityById(id: string) {
        return this.state.entities.find((entity) => entity.ReferenceId === id);
    }
}
