import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { WorkspaceIconComponent } from '../workspace-icon';
import {
    CoreUiModule,
    DxyActionListComponent,
    EllipsisTooltipDirective,
    IActionOption,
} from '@datagalaxy/core-ui';
import { IWorkspace } from '../workspace.types';

/**
 * ## Role
 * Display a workspace icon + its displayName and optional action buttons/menus
 */
@Component({
    standalone: true,
    selector: 'dxy-workspace-cell',
    templateUrl: './workspace-cell.component.html',
    styleUrls: ['./workspace-cell.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        WorkspaceIconComponent,
        CoreUiModule,
        DxyActionListComponent,
        EllipsisTooltipDirective,
    ],
})
export class WorkspaceCellComponent {
    workspace = input.required<IWorkspace>();
    fixActionsPosition = input(false);
    actions = input<IActionOption<IWorkspace>[]>([]);
}
