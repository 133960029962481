import { DateTimeUtil } from '@datagalaxy/core-util';
import * as moment from 'moment';
import { DateFilterOperator, IDateFilterData } from './date-filter.types';

/**
 * Abstract class for periods
 * provide operator, date range and a validation methods to check if a date is in range
 */
export abstract class FilterPeriod {
    constructor(protected dateFilterData: IDateFilterData) {}
    protected now = moment();
    protected get today() {
        return this.now.clone().startOf('day');
    }
    protected get week() {
        return this.now.clone().startOf('week');
    }
    protected get month() {
        return this.now.clone().startOf('month');
    }
    protected get year() {
        return this.now.clone().startOf('year');
    }
    public static operator: DateFilterOperator;
    protected _startDate: moment.Moment | undefined;
    public get startDate() {
        return this._startDate;
    }
    protected _endDate: moment.Moment | undefined;
    public get endDate() {
        return this._endDate?.clone().add(-1, 'millisecond');
    }
    /** True if mi is in range */
    public isValidDate(mi: moment.MomentInput) {
        if (!mi) {
            return !this.startDate;
        }
        const date = DateTimeUtil.getValidMoment(mi, moment.locale());
        if (this.startDate && this.startDate > date) {
            return false;
        }
        if (this.endDate && this.endDate < date) {
            return false;
        }
        return true;
    }
}

export class PastHourPeriod extends FilterPeriod {
    public static operator = DateFilterOperator.DateTimePastHour;
    protected _startDate = this.now.clone().add(-1, 'hour');
    protected _endDate = this.now;
}

export class TodayPeriod extends FilterPeriod {
    public static operator = DateFilterOperator.DateTimeToday;
    protected _startDate = this.today;
    protected _endDate = this.today.add(1, 'day');
}
export class BeforeTodayPeriod extends FilterPeriod {
    public static operator = DateFilterOperator.DateTimeBeforeToday;
    protected _startDate = undefined;
    protected _endDate = this.today;
}

export class YesterdayPeriod extends FilterPeriod {
    public static operator = DateFilterOperator.DateTimeYesterday;
    protected _startDate = this.today.add(-1, 'day');
    protected _endDate = this.today;
}
export class CurrentWeekPeriod extends FilterPeriod {
    public static operator = DateFilterOperator.DateTimeCurrentWeek;
    protected _startDate = this.week;
    protected _endDate = this.week.add(1, 'week');
}

export class PastWeekPeriod extends FilterPeriod {
    public static operator = DateFilterOperator.DateTimePastWeek;
    protected _startDate = this.week.add(-1, 'week');
    protected _endDate = this.week;
}
export class BeforeCurrentWeekPeriod extends FilterPeriod {
    public static operator = DateFilterOperator.DateTimeBeforeCurrentWeek;
    protected _startDate = undefined;
    protected _endDate = this.week;
}

export class BeforePastWeekPeriod extends FilterPeriod {
    public static operator = DateFilterOperator.DateTimeBeforePastWeek;
    protected _startDate = undefined;
    protected _endDate = this.week.add(-1, 'week');
}

export class CurrentMonthPeriod extends FilterPeriod {
    public static operator = DateFilterOperator.DateTimeCurrentMonth;
    protected _startDate = this.month;
    protected _endDate = this.month.add(1, 'month');
}
export class PastMonthPeriod extends FilterPeriod {
    public static operator = DateFilterOperator.DateTimePastMonth;
    protected _startDate = this.month.add(-1, 'month');
    protected _endDate = this.month;
}
export class BeforeCurrentMonthPeriod extends FilterPeriod {
    public static operator = DateFilterOperator.DateTimeBeforeCurrentMonth;
    protected _startDate = undefined;
    protected _endDate = this.month;
}

export class BeforePastMonthPeriod extends FilterPeriod {
    public static operator = DateFilterOperator.DateTimeBeforePastMonth;
    protected _startDate = undefined;
    protected _endDate = this.month.add(-1, 'month');
}
export class CurrentYearPeriod extends FilterPeriod {
    public static operator = DateFilterOperator.DateTimeCurrentYear;
    protected _startDate = this.year;
    protected _endDate = this.year.add(1, 'year');
}
export class FieldIsEmptyPeriod extends FilterPeriod {
    public static operator = DateFilterOperator.FieldIsEmpty;
    protected _startDate = undefined;
    protected _endDate = undefined;
    public override isValidDate(mi: moment.MomentInput) {
        return !mi;
    }
}
export class FieldHasValuePeriod extends FilterPeriod {
    public static operator = DateFilterOperator.FieldHasValue;
    protected _startDate = undefined;
    protected _endDate = undefined;
    public override isValidDate(mi: moment.MomentInput) {
        return !!mi;
    }
}

export class RangeContainsPeriod extends FilterPeriod {
    constructor(dateFilterData: IDateFilterData) {
        super(dateFilterData);
        this._startDate = dateFilterData.startDate;
        this._endDate = dateFilterData.endDate;
    }
    public static operator = DateFilterOperator.RangeContains;
}

export class DateFilterPeriodFactory {
    public static getPeriodFromDateFilter(filter: IDateFilterData) {
        const operator = filter?.operator;
        if (!operator) {
            return;
        }

        switch (filter.operator) {
            case DateFilterOperator.RangeContains:
                return new RangeContainsPeriod(filter);
            case DateFilterOperator.DateTimePastHour:
                return new PastHourPeriod(filter);
            case DateFilterOperator.DateTimeToday:
                return new TodayPeriod(filter);
            case DateFilterOperator.DateTimeBeforeToday:
                return new BeforeTodayPeriod(filter);
            case DateFilterOperator.DateTimeYesterday:
                return new YesterdayPeriod(filter);
            case DateFilterOperator.DateTimeCurrentWeek:
                return new CurrentWeekPeriod(filter);
            case DateFilterOperator.DateTimePastWeek:
                return new PastWeekPeriod(filter);
            case DateFilterOperator.DateTimeBeforeCurrentWeek:
                return new BeforeCurrentWeekPeriod(filter);
            case DateFilterOperator.DateTimeBeforePastWeek:
                return new BeforePastWeekPeriod(filter);
            case DateFilterOperator.DateTimeCurrentMonth:
                return new CurrentMonthPeriod(filter);
            case DateFilterOperator.DateTimeBeforeCurrentMonth:
                return new BeforeCurrentMonthPeriod(filter);
            case DateFilterOperator.DateTimePastMonth:
                return new PastMonthPeriod(filter);
            case DateFilterOperator.DateTimeBeforePastMonth:
                return new BeforePastMonthPeriod(filter);
            case DateFilterOperator.DateTimeCurrentYear:
                return new CurrentYearPeriod(filter);
            case DateFilterOperator.FieldIsEmpty:
                return new FieldIsEmptyPeriod(filter);
            case DateFilterOperator.FieldHasValue:
                return new FieldHasValuePeriod(filter);
            default:
                console.warn(
                    `FilterPeriod doesn't exist for operator ${filter.operator}`,
                );
                break;
        }
    }
}
