import { Component, HostListener, Inject } from '@angular/core';
import {
    MatDialogRef,
    MAT_DIALOG_DATA,
    MatDialogModule,
} from '@angular/material/dialog';
import { DxyBaseModalComponent } from '@datagalaxy/ui/dialog';
import { EnumNumberFieldSelectAdapter } from '@datagalaxy/core-ui';
import { IGenerationSettingsModalInput } from './script-generation-settings.types';
import { ModelerService } from '../../services/modeler.service';
import {
    GenerateScriptCaseOption,
    GenerateScriptLineEndingOption,
    GenerateScriptQuoteOption,
    GenerateScriptResult,
} from '@datagalaxy/webclient/modeler/data-access';
import { KeyboardUtil } from '@datagalaxy/utils';
import { DxyModalFooterComponent } from '../../../shared/dialogs/dxy-modal-footer/dxy-modal-footer.component';
import { DxyFieldBooleanComponent } from '@datagalaxy/core-ui/fields';
import { DxyFieldSelectComponent } from '@datagalaxy/core-ui/fields';
import { FormsModule } from '@angular/forms';
import { DxyIconButtonDirective } from '@datagalaxy/ui/buttons';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'dxy-script-generation-settings-modal',
    templateUrl: './dxy-script-generation-settings-modal.component.html',
    standalone: true,
    imports: [
        MatDialogModule,
        TranslateModule,
        DxyIconButtonDirective,
        FormsModule,
        DxyFieldSelectComponent,
        DxyFieldBooleanComponent,
        DxyModalFooterComponent,
    ],
})
export class DxyScriptGenerationSettingsModalComponent extends DxyBaseModalComponent<
    IGenerationSettingsModalInput,
    GenerateScriptResult
> {
    public readonly quoteStyle =
        new EnumNumberFieldSelectAdapter<GenerateScriptQuoteOption>(
            GenerateScriptQuoteOption,
            {
                getTextKey: (o) =>
                    this.getTextKey(
                        'quoteOptions',
                        GenerateScriptQuoteOption[o],
                    ),
                initialValue: GenerateScriptQuoteOption.None,
            },
        );
    public readonly caseStyle =
        new EnumNumberFieldSelectAdapter<GenerateScriptCaseOption>(
            GenerateScriptCaseOption,
            {
                getTextKey: (o) =>
                    this.getTextKey('caseOptions', GenerateScriptCaseOption[o]),
                initialValue: GenerateScriptCaseOption.None,
                orderedValues: [
                    GenerateScriptCaseOption.None,
                    GenerateScriptCaseOption.ToLowercase,
                    GenerateScriptCaseOption.ToUppercase,
                    GenerateScriptCaseOption.WarnIfUppercase,
                    GenerateScriptCaseOption.WarnIfLowercase,
                ],
            },
        );
    public readonly lineEnding =
        new EnumNumberFieldSelectAdapter<GenerateScriptLineEndingOption>(
            GenerateScriptLineEndingOption,
            {
                getTextKey: (o) =>
                    this.getTextKey(
                        'lineEndingOptions',
                        GenerateScriptLineEndingOption[o],
                    ),
                initialValue: GenerateScriptLineEndingOption.Default,
            },
        );
    public prefixWithSchemaName = false;
    public dropBeforeCreate = false;
    public creationLoading = false;

    constructor(
        private modelerService: ModelerService,
        dialogRef: MatDialogRef<
            DxyScriptGenerationSettingsModalComponent,
            GenerateScriptResult
        >,
        @Inject(MAT_DIALOG_DATA) data: IGenerationSettingsModalInput,
    ) {
        super(dialogRef, data);
    }

    public onCloseSubmit() {
        this.create().then(() => super.onCloseSubmit());
    }

    @HostListener('keydown', ['$event'])
    private keyDownModal(event: KeyboardEvent) {
        if (KeyboardUtil.isEnterKey(event)) {
            this.onCloseSubmit();
        }
    }

    private getTextKey(infix: string, value: string) {
        return `UI.Ddl.scriptGenerationSettingsModal.${infix}.lbl${value}`;
    }

    private async create() {
        this.creationLoading = true;
        try {
            const { mappingId, serverType, itemId } = this.data;
            this.result = await this.modelerService.generateDdlScript(
                mappingId,
                serverType,
                itemId,
                {
                    quote: this.quoteStyle.current,
                    case: this.getSelectedCaseOption(this.caseStyle.current),
                    lineEnding: this.lineEnding.current,
                    dropBeforeCreate: this.dropBeforeCreate,
                    prefixWithSchemaName: this.prefixWithSchemaName,
                },
            );
        } finally {
            this.creationLoading = false;
        }
    }

    private getSelectedCaseOption(v: GenerateScriptCaseOption) {
        // Note: GenerateScriptCaseOption is a flags enum (values can be combined)
        switch (v) {
            case GenerateScriptCaseOption.WarnIfUppercase:
                return v + GenerateScriptCaseOption.ToLowercase;
            case GenerateScriptCaseOption.WarnIfLowercase:
                return v + GenerateScriptCaseOption.ToUppercase;
            default:
                return v;
        }
    }
}
