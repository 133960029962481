import { FocusOrigin } from '@angular/cdk/a11y';
import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import { TranslateService } from '@ngx-translate/core';
import { DomUtil, TTooltipPlacement } from '@datagalaxy/core-util';
import {
    IListOption,
    IOptionWithDisable,
    IOptionWithHidden,
    IOptionWithTooltip,
    ListOptionUtil,
} from '../IListOption';
import { IFunctionalEvent } from '../IFunctionalEvent';
import { DxyBaseComponent } from '@datagalaxy/ui/core';

/**
 * ## Role
 * Base class for a component displaying a list of options
 */
@Directive()
export abstract class BaseOptionListComponent extends DxyBaseComponent {
    //#region static
    /** the current top open instance */
    private static topOpen: BaseOptionListComponent;
    //#endregion

    /** for internal usage */
    @Input() _parent: BaseOptionListComponent;
    @Input() tooltipsPlacement: TTooltipPlacement;
    /** On events: *backdrop click*, *escape key pressed*:
     * - when true (default), every menu in the hierarchy closes;
     * - when false, only the top open menu closes, leaving the parent menus open.
     *
     * Note that on event *option click*, every menu in the hierarchy closes, regardless of this option */
    @Input() closeHierarchyOnMenuClose = true;

    /** Emits when the menu panel is opened or closed, a value of *true* meaning opened */
    @Output() readonly openClose = new EventEmitter<boolean>();
    @Output() readonly functional = new EventEmitter<IFunctionalEvent>();

    public get isMenuOpen() {
        return this._isMenuOpen;
    }
    public get nestedLevel() {
        return this._parent ? 1 + this._parent.nestedLevel : 0;
    }
    public get matTooltipPosition() {
        return this.getMatTooltipPosition(this.tooltipsPlacement);
    }

    /** implementation example: `@ViewChild('menuTrigger') protected menuTrigger: MatMenuTrigger` */
    protected abstract menuTrigger?: MatMenuTrigger;

    protected captureMouseDown?: boolean;

    private _isMenuOpen = false;

    constructor(protected translate: TranslateService) {
        super();
    }

    public getData(opt: IListOption) {
        return opt?.data;
    }

    public isDisabled(opt: IOptionWithDisable) {
        return ListOptionUtil.isDisabled(opt, this.getData(opt));
    }
    public isHidden(opt: IOptionWithHidden) {
        return ListOptionUtil.isHidden(opt, this.getData(opt));
    }
    public isDropDown(opt: IListOption) {
        return ListOptionUtil.isDropDown(opt);
    }
    public isAction(opt: IListOption) {
        return ListOptionUtil.isAction(opt);
    }
    public isSeparator(opt: IListOption) {
        return ListOptionUtil.isSeparator(opt);
    }
    public getTooltipText(opt: IOptionWithTooltip, forMultiLine = false) {
        return ListOptionUtil.getTooltipText(
            opt,
            this.translate,
            forMultiLine,
            this.getData(opt),
        );
    }
    public hasRichLayout(opt: IListOption) {
        return ListOptionUtil.hasRichLayout(opt);
    }
    public hasText(opt: IListOption) {
        return ListOptionUtil.hasText(opt);
    }
    public getText(opt: IListOption): string {
        return ListOptionUtil.getText(opt, this.translate);
    }
    public hasDescription(opt: IListOption) {
        return ListOptionUtil.hasDescription(opt);
    }
    public getDescription(opt: IListOption) {
        return ListOptionUtil.getDescription(opt, this.translate);
    }
    public getOptionClass(opt: IListOption) {
        return ListOptionUtil.getOptionClass(opt, this.getData(opt));
    }
    public getGlyphClass(opt: IListOption) {
        return ListOptionUtil.getGlyphClass(opt, this.getData(opt));
    }
    public hasGlyphClass(opt: IListOption) {
        return ListOptionUtil.hasGlyphClass(opt);
    }
    public getDataTestId(opt: IListOption) {
        return ListOptionUtil.getDataTestId(opt, this.getData(opt));
    }
    public getDataTrackerId(opt: IListOption) {
        return ListOptionUtil.getDataTrackerId(opt, this.getData(opt));
    }

    public onFunctionalInternal(event: IFunctionalEvent) {
        this.functional.emit(event);
    }

    public onMenuOpenedClosed(isOpen: boolean) {
        this.log('(super) onMenuOpenedClosed', isOpen);
        this._isMenuOpen = isOpen;

        if (isOpen) {
            BaseOptionListComponent.topOpen = this;
        } else if (this == BaseOptionListComponent.topOpen) {
            BaseOptionListComponent.topOpen = this._parent;
            if (this.closeHierarchyOnMenuClose) {
                this._parent?.menuTrigger?.closeMenu();
            }
        }

        this.openClose.emit(isOpen);
    }

    public onOptionClicked(option: IListOption, event: MouseEvent) {
        if (this.isDisabled(option)) {
            return;
        }
        ListOptionUtil.emitFunctionalEventIfNeeded(
            option,
            this.functional,
            event,
        );
        if (!this.closeHierarchyOnMenuClose) {
            this.closeMenuHierarchy();
        }
        this.onBeforeOptionCallback(option, event);
        option.callback?.(this.getData(option));
    }

    public onEscapeKey(event: Event) {
        if (this == BaseOptionListComponent.topOpen) {
            this.log('onEscapeKey-topOpen');
            if (!this.closeHierarchyOnMenuClose) {
                event.stopPropagation();
            }
            this.menuTrigger.closeMenu();
        }
    }

    /** Calls focus() on the menuTrigger element.
     * NOTE: A null origin means *blur* instead of *focus* */
    public focus(origin?: FocusOrigin) {
        this.log('focus-menuTrigger', origin);
        this.menuTrigger.focus(origin);
    }

    public getNestedLogId(index: number) {
        return `${this.logId ?? '?'}.${this.nestedLevel}[${index}]`;
    }

    public onOptionMouseDown(action: IListOption, event: MouseEvent) {
        if (!this.captureMouseDown) {
            return;
        }
        event.preventDefault();
        event.stopPropagation();
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    protected onBeforeOptionCallback(option: IListOption, event: MouseEvent) {}

    protected getMatTooltipPosition(tooltipsPlacement: TTooltipPlacement) {
        return DomUtil.getMatTooltipPosition(tooltipsPlacement);
    }
    protected closeMenuHierarchy() {
        let e: BaseOptionListComponent = this;
        while (e) {
            if (e._isMenuOpen) {
                e.menuTrigger.closeMenu();
            }
            e = e._parent;
        }
    }
}
