import {
    AfterViewInit,
    ChangeDetectorRef,
    Directive,
    ElementRef,
    Inject,
    Input,
    NgZone,
    Optional,
    ViewContainerRef,
} from '@angular/core';
import {
    CDK_DRAG_CONFIG,
    CdkDrag,
    CdkDropList,
    DragDrop,
    DragDropConfig,
    DragDropRegistry,
    DragRef,
    DropListRef,
} from '@angular/cdk/drag-drop';
import { DOCUMENT } from '@angular/common';
import { ScrollDispatcher, ViewportRuler } from '@angular/cdk/overlay';
import { Directionality } from '@angular/cdk/bidi';
import { DxySortListDirective, DXY_SORT_LIST } from './sort-list.directive';
import { DxyBaseDirective } from '@datagalaxy/ui/core';

/**
 * This directive allows and element to be sorted horizontally and vertically
 * It needs to be paired with dxySortList directive to work correctly like in this example:
 ```
 <div [dxySortList]="data">
    <whatever-you-want *ngFor="let d of data; let i = index" [dxySortListItem]="i">
    </whatever-you-want>
 </div>
 ```
 * Internally it applies material cdkDropList on the element and cdkDrag on child Element,
 * this way the element can be dragged out to another cdkDropList
 */
@Directive({
    selector: '[dxySortListItem]',
    standalone: true,
})
export class DxySortListItemDirective<T>
    extends DxyBaseDirective
    implements AfterViewInit
{
    @Input('dxySortListItem') dxySortListItemIndex: number;

    constructor(
        @Inject(DOCUMENT) private document: Document,
        @Inject(DXY_SORT_LIST) private group: DxySortListDirective<T>,
        @Optional() @Inject(CDK_DRAG_CONFIG) private config: DragDropConfig,
        @Optional() private dir: Directionality,
        private elementRef: ElementRef<HTMLElement>,
        private ngZone: NgZone,
        private viewPortRuler: ViewportRuler,
        private cd: ChangeDetectorRef,
        private scrollDispatcher: ScrollDispatcher,
        private viewContainerRef: ViewContainerRef,
    ) {
        super();
    }

    ngAfterViewInit() {
        const dragDrop = new DragDrop(
            this.document,
            this.ngZone,
            this.viewPortRuler,
            new DragDropRegistry<DragRef, DropListRef>(
                this.ngZone,
                this.document,
            ),
        );
        const itemRef = new ElementRef<HTMLElement>(
            this.elementRef.nativeElement.firstChild as HTMLElement,
        );
        const dropList = new CdkDropList(
            this.elementRef,
            dragDrop,
            this.cd,
            this.scrollDispatcher,
        );
        this.elementRef.nativeElement.setAttribute(
            'style',
            'display: flex;flex-direction: row;',
        );

        dropList.orientation = 'horizontal';
        const cdkDrag = new CdkDrag(
            itemRef,
            dropList,
            this.document,
            this.ngZone,
            this.viewContainerRef,
            this.config,
            this.dir,
            dragDrop,
            this.cd,
        );

        dropList.addItem(cdkDrag);

        this.group.addDropList(dropList);

        dropList.dropped.subscribe((event) =>
            this.group.drop(event, this.dxySortListItemIndex),
        );
        cdkDrag.started.subscribe(() =>
            this.group.picked(this.dxySortListItemIndex),
        );
    }
}
