import { select, selectAll } from 'd3-selection';
import { CollectionsHelper, DomUtil } from '@datagalaxy/core-util';
import {
    Dom2dUtil,
    IXYRO,
    MovePhase,
    Rect,
    RectSide,
    rectSides,
    TDomElement,
} from '@datagalaxy/core-2d-util';
import { TD3Subject } from '../../../D3Helper';
import { BaseGraphicalManager } from '../../BaseGraphicalManager';
import { EdgeSide, IEndpoint } from '../../edge';
import { EndpointHandle } from './endpoint-handle';
import { D3DragEvent, drag as d3drag } from 'd3-drag';
import { LayerManager, SelectionTool, SurfaceLayer } from '../../index';
import { Connector, ConnectorEndpoint } from '../../connector';
import { Subject } from 'rxjs';
import { ZoomedViewAdapter } from '../pan-zoom/zoom-adapters/ZoomedViewAdapter';
import { GraphManager } from '../../graph-manager';
import { EndpointToolOptions } from './endpoint.types';

type EndpointDragEvent<NodeData> = D3DragEvent<
    HTMLElement,
    EndpointHandle<NodeData>,
    TD3Subject
>;

/**
 * ## Role
 * Display connector endpoints and manage de/re-connection gestures
 */
export class EndpointTool<
    NodeData = unknown,
    EdgeData = unknown,
> extends BaseGraphicalManager {
    private static readonly epClass = 'gs-endpoint';

    private dragBehaviour = d3drag<
        HTMLElement,
        EndpointHandle<NodeData>,
        TD3Subject
    >();
    private editingConnectors: Connector<NodeData, EdgeData>[];
    /** end of the connector that is being dragged */
    private readonly connectorPortChanged = new Subject<
        Connector<NodeData, EdgeData>[]
    >();
    private readonly connectorPortDragged = new Subject<void>();
    public get connectorPortChanged$() {
        return this.connectorPortChanged.asObservable();
    }
    public get connectorPortDragged$() {
        return this.connectorPortDragged.asObservable();
    }

    constructor(
        private zoomAdapter: ZoomedViewAdapter,
        private selectionTool: SelectionTool<NodeData, EdgeData>,
        private graph: GraphManager,
        private options: EndpointToolOptions,
        private layerManager: LayerManager,
    ) {
        super();
        this.dragBehaviour
            .subject((_, handle) =>
                this.zoomAdapter.fixedToZoomed.xy(
                    handle.rsp.position.x,
                    handle.rsp.position.y,
                ),
            )
            .on(
                'drag',
                (
                    event: EndpointDragEvent<NodeData>,
                    handle: EndpointHandle<NodeData>,
                ) => {
                    this.onHandleMove(event, handle, MovePhase.move);
                },
            )
            .on(
                'end',
                (
                    event: EndpointDragEvent<NodeData>,
                    handle: EndpointHandle<NodeData>,
                ) => {
                    this.onHandleMove(event, handle, MovePhase.end);
                },
            );

        super.subscribe(selectionTool.edgeSelection$, (event) =>
            this.onSelectionChange(event),
        );
    }

    public draw(c: Connector<NodeData, EdgeData> & { points?: IXYRO[] }) {
        if (this.options?.disabled) {
            return;
        }
        this.drawEndpoint(c, true);
        this.drawEndpoint(c, false);
    }

    /** removes both source and target elements associated to the given connected endpoints */
    public remove(c: Connector<NodeData, EdgeData>) {
        if (!c) {
            return;
        }
        this.log('remove', c);
        this.connectedEndpoints(c).remove();
        const srcNode = c.srcNode;
        const tgtNode = c.tgtNode;
        this.layerManager.bringBackToInitial(srcNode);
        this.layerManager.bringBackToInitial(tgtNode);
        srcNode.el.classList.remove('port-editing');
        tgtNode.el.classList.remove('port-editing');
    }

    public onHandleMove(
        event: D3DragEvent<HTMLElement, EndpointHandle<NodeData>, TD3Subject>,
        handle: EndpointHandle<NodeData>,
        phase: MovePhase,
    ) {
        const adapter = this.zoomAdapter.zoomedToFixed;
        const point = adapter.xy(event.x, event.y);
        const { movable, moveSideOnly } = this.options || {};
        if (!movable) {
            return true;
        }
        if (moveSideOnly && phase == MovePhase.end) {
            this.showHideSidePoints(handle.rsp, false);
        } else if (phase == MovePhase.move && point && (point.x || point.y)) {
            if (moveSideOnly) {
                handle.rsp.distance = 0.5;
                handle.moveOnSide(point, true);
                this.showHideSidePoints(handle.rsp, true);
            } else {
                handle.moveOnSide(point, false);
            }
        }

        const connector = this.editingConnectors.find((c) => c === handle.c);
        this.updateConnectorsPorts([connector]);
        this.draw(connector);

        if (phase === MovePhase.end) {
            this.connectorPortDragged.next();
        }
    }

    private onSelectionChange(connectors: Connector<NodeData, EdgeData>[]) {
        const removedEdges = this.editingConnectors?.filter(
            (c) => !connectors.includes(c),
        );
        this.editingConnectors = connectors;

        removedEdges?.forEach((c) => this.remove(c));

        connectors?.forEach((connector) => {
            this.draw(connector);
        });
    }

    private drawEndpoint(
        c: Connector<NodeData, EdgeData>,
        drawSrc: boolean,
        angle?: number,
    ) {
        const ep = drawSrc ? c.src : c.tgt;
        this.verbose && this.log('drawEndpoint', drawSrc, angle, ep, c);
        const {
            size,
            class: epClass,
            sourceClass,
            targetClass,
        } = this.options || {};

        ep.handle ??= new EndpointHandle<NodeData>(c, drawSrc, size);
        const nodeEndpoints = select(
            drawSrc ? c.srcPort.el : c.tgtPort.el,
        ).selectAll<TDomElement, EndpointHandle<NodeData>>(
            `div.${EndpointTool.epClass}.active`,
        );
        const handles = nodeEndpoints.data();
        if (!handles.includes(ep.handle)) {
            handles.push(ep.handle);
        }
        const common = function (d: EndpointHandle<NodeData>) {
            if (d == ep.handle) {
                d.init(this, true, true, angle);
            }
            d.rsp.updatePosition();
            this.classList.toggle('active', true);
        };
        this.updateNodes(c);
        nodeEndpoints.data(handles).join(
            (enter) => {
                const appended = enter
                    .append('div')
                    .attr('class', (d) =>
                        DomUtil.addClasses(
                            EndpointTool.epClass,
                            epClass,
                            d.isSrc ? 'src' : 'tgt',
                            d.isSrc ? sourceClass : targetClass,
                        ),
                    )
                    .each(common)
                    .call(this.dragBehaviour);
                const { movable, moveSideOnly } = this.options || {};
                if (movable) {
                    appended
                        .on('dblclick', (event, d) =>
                            this.endpointDblClick(event, d),
                        )
                        .on('click', (event) => event.stopPropagation());
                    if (moveSideOnly) {
                        appended.on('mouseenter', () =>
                            this.showHideSidePoints(ep, true),
                        );
                        appended.on('mouseleave', () =>
                            this.showHideSidePoints(ep, false),
                        );
                    }
                }
                return appended;
            },
            (update) => update.each(common),
            (exit) => exit.remove(),
        );
        this.toggleEditClass(c, true);
    }

    private updateNodes(c: Connector<NodeData, EdgeData>) {
        const srcNode = c.srcNode;
        const tgtNode = c.tgtNode;

        /**
         * Nodes should not be put in tool layer, but since we want to
         * bring ports to the tool layer, we have to bring the node with them
         * when they are included in the node itself
         */
        this.layerManager.bringToFront(srcNode, SurfaceLayer.tool);
        this.layerManager.bringToFront(tgtNode, SurfaceLayer.tool);

        srcNode.el.classList.add('port-editing');
        tgtNode.el.classList.add('port-editing');
    }

    private endpointDblClick(event: MouseEvent, eph: EndpointHandle<NodeData>) {
        if (!this.options?.movable) {
            return;
        }
        event.stopPropagation();
        this.log('endpointDblClick', eph);
        eph.unfixSide(true).updateSideClasses();

        const connector = this.editingConnectors.find((c) => c === eph.c);
        this.updateConnectorsPorts([connector]);
        this.draw(connector);
    }

    private connectedEndpoints(c: Connector<NodeData, EdgeData>) {
        return selectAll([c.srcNode.el, c.tgtNode.el]).selectAll<
            TDomElement,
            EndpointHandle<NodeData>
        >(`div.${EndpointTool.epClass}`);
    }

    private toggleEditClass(
        c: Connector<NodeData, EdgeData>,
        force?: boolean,
        sides?: EdgeSide,
    ) {
        const className = this.options?.editClass || 'active';
        if (!className) {
            return;
        }
        if (!sides || sides == EdgeSide.source) {
            c.src.handle?.toggleClass(className, force);
        }
        if (!sides || sides == EdgeSide.target) {
            c.tgt.handle?.toggleClass(className, force);
        }
    }

    private showHideSidePoints(ep: IEndpoint<NodeData>, show: boolean) {
        const sidePoints = select(ep.node.el).selectAll<TDomElement, RectSide>(
            `div.${EndpointTool.epClass}:not(.active)`,
        );
        if (show) {
            const r = ep.node.rect;
            const sides = CollectionsHelper.getEnumValues(
                RectSide,
                RectSide.none,
                Rect.rectSide(ep.side),
            );
            sidePoints
                .data(sides)
                .join('div')
                .attr('class', EndpointTool.epClass)
                .each(function (this: HTMLDivElement, side: RectSide) {
                    const p = Rect.sidePoint(r, side);
                    // relative to node rectangle
                    Dom2dUtil.setLocation(
                        this,
                        p.x - r.x,
                        p.y - r.y,
                        true,
                        true,
                    );
                });
        } else {
            sidePoints.remove();
        }
    }

    public updateConnectorsPorts(connectors: Connector<NodeData, EdgeData>[]) {
        const separation = this.options?.endsSeparation;

        connectors?.forEach((c) => c.computeEndpoints());

        if (separation) {
            const spacing =
                typeof separation == 'number'
                    ? Math.max(0, separation)
                    : undefined;
            connectors = this.separateEndPoints(connectors, spacing);
        } else if (this.options?.separateSourceAndTarget) {
            this.separateSrcAndTgtEndpoints(connectors);
        }

        connectors.forEach((c) => c.computePoints());
        const updatedSelectedConnectors =
            this.selectionTool.connectorSelection.filter((c) =>
                connectors.includes(c),
            );
        updatedSelectedConnectors.forEach((c) => this.draw(c));

        this.connectorPortChanged.next(connectors);
    }

    private separateSrcAndTgtEndpoints(
        connectors: Connector<NodeData, EdgeData>[],
    ) {
        const allNodes = CollectionsHelper.flattenGroups(
            connectors,
            (c) => [c.srcNode, c.tgtNode],
            true,
            true,
        );

        allNodes.forEach((node) => {
            const cs = this.graph.getConnectors(node) as Connector<
                NodeData,
                EdgeData
            >[];

            const ports = node.getPorts();

            ports.forEach((port) => {
                const portConnectors = cs.filter(
                    (c) => c.tgtPort === port || c.srcPort === port,
                );

                rectSides.forEach((side) => {
                    const sideConnectors = portConnectors.filter(
                        (c) =>
                            (c.src.side === side && c.srcNode === node) ||
                            (c.tgt.side === side && c.tgtNode === node),
                    );
                    const hasSourceAndTarget =
                        sideConnectors.some((c) => c.srcNode === node) &&
                        sideConnectors.some((c) => c.tgtNode === node);

                    if (!hasSourceAndTarget) {
                        return;
                    }

                    sideConnectors?.forEach((c) => {
                        if (c.srcNode === node) {
                            if (c.src.side === RectSide.left) {
                                c.src.distance = 2 / 3;
                            }
                            if (c.src.side === RectSide.right) {
                                c.src.distance = 1 / 3;
                            }
                        }

                        if (c.tgtNode === node) {
                            if (c.tgt.side === RectSide.left) {
                                c.tgt.distance = 1 / 3;
                            }
                            if (c.tgt.side === RectSide.right) {
                                c.tgt.distance = 2 / 3;
                            }
                        }
                    });
                });
            });
        });
    }

    private separateEndPoints(
        connectors: Connector<NodeData, EdgeData>[],
        spacing?: number,
    ) {
        const fullSpacing = !spacing;
        const allNodes = CollectionsHelper.flattenGroups(
            connectors,
            (c) => [c.srcNode, c.tgtNode],
            true,
            true,
        );
        const connectorsToRedraw = new Set(connectors);
        const sortV = (a: ConnectorEndpoint, b: ConnectorEndpoint) =>
            a.other.node.rect.y - b.other.node.rect.y;
        const sortH = (a: ConnectorEndpoint, b: ConnectorEndpoint) =>
            a.other.node.rect.x - b.other.node.rect.x;
        const sidePoints: ConnectorEndpoint[] = [];

        allNodes.forEach((node) => {
            const cs = this.graph.getConnectors(node) as Connector<
                NodeData,
                EdgeData
            >[];
            if (!cs) {
                return;
            }

            const ports = node.getPorts();

            ports.forEach((port) => {
                const connectors = cs.filter(
                    (c) => c.tgtPort === port || c.srcPort === port,
                );
                rectSides.forEach((side) => {
                    sidePoints.length = 0;
                    connectors.forEach((c) => {
                        const ce = c.srcNode == node ? c.src : c.tgt;

                        if (ce.side == side) {
                            sidePoints.push(ce);
                            connectorsToRedraw.add(c);
                        }
                    });
                    if (sidePoints.length == 1) {
                        sidePoints[0].distance = 0.5;
                    } else if (sidePoints.length) {
                        sidePoints.sort(
                            side == RectSide.left || side == RectSide.right
                                ? sortV
                                : sortH,
                        );
                        if (fullSpacing) {
                            const n = sidePoints.length + 1;
                            sidePoints.forEach(
                                (sp, i) => (sp.distance = (i + 1) / n),
                            );
                        } else {
                            const l = Rect.sideLength(node.rect, side);
                            const m =
                                (l - (sidePoints.length - 1) * spacing) / 2;
                            sidePoints.forEach(
                                (sp, i) =>
                                    (sp.distance = (m + i * spacing) / l),
                            );
                        }
                    }
                });
            });
        });
        return Array.from(connectorsToRedraw);
    }
}
