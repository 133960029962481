import { Inject, Injectable } from '@angular/core';
import { SetImageParameter, SetImageResult } from './image';
import { GetClientStatisticsResult } from './statistic';
import { UpdateClientSpaceParameter, UpdateClientSpaceResult } from './client';
import { ClientLoginParameter, ClientLoginResult } from './login';
import { Location } from '@angular/common';
import {
    BACKEND_API_CONFIG,
    BackendApiService,
    BaseServiceParameter,
    IApiConfig,
} from '@datagalaxy/data-access';

@Injectable({ providedIn: 'root' })
export class ClientApiService {
    constructor(
        @Inject(BACKEND_API_CONFIG) private config: IApiConfig,
        private backendApiService: BackendApiService,
    ) {}

    public async clientLogin(param: ClientLoginParameter) {
        return await this.backendApiService.postPromise(
            'Login/ClientLogin',
            param,
            ClientLoginResult,
        );
    }

    public async clientLogout() {
        return await this.backendApiService.postPromise(
            'Login/Logout',
            new BaseServiceParameter(),
        );
    }

    public async keepAlive() {
        return await this.backendApiService.postPromise(
            'Login/KeepAlive',
            new BaseServiceParameter(),
        );
    }

    public async updateClientSpace(
        updateClientSpaceParameter: UpdateClientSpaceParameter,
    ) {
        return await this.backendApiService.postPromise(
            'ClientAdmin/UpdateClientSpace',
            updateClientSpaceParameter,
            UpdateClientSpaceResult,
        );
    }

    public async setImage(setImageParameter: SetImageParameter) {
        return await this.backendApiService.postPromise(
            'ClientAdmin/SetImage',
            setImageParameter,
            SetImageResult,
        );
    }

    public async getClientStatistics(
        getClientStatisticsParameter: BaseServiceParameter,
    ) {
        return await this.backendApiService.postPromise(
            'ClientAdmin/GetClientStatistics',
            getClientStatisticsParameter,
            GetClientStatisticsResult,
        );
    }

    public getImageUrl(hash: string): string {
        return !hash
            ? ''
            : Location.joinWithSlash(
                  this.config.baseUrl,
                  `api/ClientAdmin/GetImage?hash=${encodeURIComponent(hash)}`,
              );
    }
}
