import { BaseGraphicalManager } from '../BaseGraphicalManager';
import { IWidthHeight, TDomElement } from '@datagalaxy/core-2d-util';
import { select, Selection } from 'd3-selection';
import { D3Single, D3SvgSingle } from '../../D3Single';
import { D3Helper, SD3, TD3Subject } from '../../D3Helper';
import { IPanZoomEvent, Viewport } from '../tools';
import { TGraphItem } from '../graph-manager.types';
import { SurfaceLayer } from './layer.types';
import { distinctUntilChanged, map } from 'rxjs';

export class LayerManager extends BaseGraphicalManager {
    private static defaultLayers = [
        SurfaceLayer.grid,
        SurfaceLayer.back,
        SurfaceLayer.svg,
        SurfaceLayer.default,
        SurfaceLayer.front,
        SurfaceLayer.tool,
    ];

    public internalContainer: SD3<HTMLDivElement>;

    /** zoomed layers container */
    public zoomedView: Selection<
        HTMLDivElement,
        unknown,
        HTMLElement,
        TD3Subject
    >;

    /** zoomed svg document */
    public zoomedSvg: Selection<
        SVGSVGElement,
        unknown,
        HTMLElement,
        TD3Subject
    >;

    public svgZoomedView: Selection<
        SVGGElement,
        unknown,
        SVGSVGElement,
        TD3Subject
    >;

    public readonly layers = {
        // ordered back to front
        fixedBack: null as D3Single<HTMLDivElement>,
        zoomed: {
            grid: null as D3Single<SVGRectElement>,
            //#region client layers
            back: null as D3Single<HTMLDivElement>,
            svg: null as D3SvgSingle,
            front: null as D3Single<HTMLDivElement>,
            //#endregion
        },
        /** used for selection area, and resize handles & frame when *options.resize.zoomIndependant* is true */
        fixedFront: null as D3Single<HTMLDivElement>,
    };
    private zIndexLayers = LayerManager.defaultLayers;

    constructor(container: HTMLDivElement, viewport: Viewport) {
        super();
        this.init(container);

        super.subscribe(
            viewport.change$.pipe(
                map((event) => event.bounds),
                distinctUntilChanged(),
            ),
            (event: IWidthHeight) => this.updateViewport(event),
        );
    }

    public dispose() {
        super.dispose();
        const ls = this.layers,
            z = ls.zoomed;
        [ls.fixedBack, ls.fixedFront, z.grid, z.back, z.svg, z.front].forEach(
            (l) => l?.d3.remove(),
        );
        ls.fixedBack = ls.fixedFront = z.grid = z.back = z.svg = z.front = null;
    }

    public updateZoomLayer(zt: IPanZoomEvent) {
        const styleZt = `translate(${zt.x | 0}px,${zt.y | 0}px) scale(${zt.k})`;
        this.zoomedView.style('transform', styleZt);
    }

    public addToTools(el: TDomElement) {
        const zoomedView = this.zoomedView ?? this.svgZoomedView;
        zoomedView.node().append(el);
        el.style.zIndex = this.getLayerIndex(SurfaceLayer.tool);
    }

    public bringToFront(item: TGraphItem, layer?: SurfaceLayer) {
        if (!item?.el) {
            return;
        }
        item.el.style.zIndex = this.getLayerIndex(layer ?? SurfaceLayer.front);
    }

    public bringBackToInitial(item: TGraphItem) {
        item.el.style.zIndex = this.getLayerIndex(item.layer);
    }

    public addItemToSurface(items: TGraphItem[]) {
        items.forEach((item) => {
            if (item.layer !== SurfaceLayer.none) {
                this.zoomedView.node().append(item.el);
                item.el.style.zIndex = this.getLayerIndex(item.layer);
            }
        });
    }

    private updateViewport(bounds: IWidthHeight) {
        const { width: w, height: h } = bounds;
        D3Helper.setWidthHeight(this.internalContainer, w, h, true);
    }

    private getLayerIndex(layer: SurfaceLayer): string {
        if (layer == undefined) {
            return this.zIndexLayers.indexOf(SurfaceLayer.default).toString();
        }
        return this.zIndexLayers.indexOf(layer)?.toString();
    }

    private init(container: HTMLDivElement) {
        const prefixed = (suffix: string) => this.prefixed(suffix);

        const internalContainer = (this.internalContainer = select(container));
        const zoomedView = (this.zoomedView = internalContainer
            .append('div')
            .attr('class', prefixed('zoomed-view')));
        const zoomed = this.layers.zoomed;

        //#region zoomed svg layer
        // zoomed svg document
        const zoomedSvg = (this.zoomedSvg = zoomedView
            .append('svg')
            .attr('class', prefixed('zoomed-svg'))
            .style('position', 'absolute')
            .style('top', 0)
            .style('left', 0)
            .style('pointer-events', 'none')
            .style('overflow', 'visible')
            .style('outline', () => (this.debug ? '2px solid green' : null)));
        const d3defs = zoomedSvg.append('defs');
        const svgZoomedView = (this.svgZoomedView = select(zoomedSvg.node())
            .append('g')
            .attr('class', prefixed('svg-zoomed-view')));
        const d3g = svgZoomedView
            .append('g')
            .attr('class', prefixed('zoomed-svg-layer'))
            .style('pointer-events', 'all');
        zoomed.svg = new D3SvgSingle(d3g, d3defs);
        //#endregion - zoomed svg layer

        const d3fixedFront = this.internalContainer
            .append('div')
            .attr('class', this.prefixed('fixed-front'));
        this.layers.fixedFront = new D3Single(d3fixedFront);
    }

    private prefixed(suffix: string) {
        return `gs-${suffix}`;
    }
}
