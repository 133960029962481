type Updater<T> = <K extends keyof T>(key: K, value: T[K]) => T[K];

export class RecordUtils {
    /**
     * Mutate a record by applying an updater function to each key-value pair.
     * @param record
     * @param updater
     */
    public static mutate<T extends Record<string, any>>(
        record: T,
        updater: Updater<T>,
    ): T {
        const updatedRecord: Partial<T> = {};

        for (const key in record) {
            updatedRecord[key] = updater(key, record[key]);
        }

        return updatedRecord as T;
    }
}
