import {
    ChangeDetectionStrategy,
    Component,
    HostBinding,
    input,
} from '@angular/core';
import { MenuHeaderColor } from './menu-header-color';

@Component({
    selector: 'dxy-menu-header',
    standalone: true,
    imports: [],
    templateUrl: './menu-header.component.html',
    styleUrls: ['./menu-header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuHeaderComponent {
    title = input<string>();
    color = input<MenuHeaderColor>('default');

    @HostBinding('class') get classes() {
        return `${this.color()}`;
    }
}
