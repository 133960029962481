import { Injectable } from '@angular/core';
import { LegacySsoAuthService } from '../legacy-sso/legacy-sso-auth.service';
import { AppConfigService } from '@datagalaxy/webclient/config';
import { LegacyLoginService } from '../../services/login.service';
import { BehaviorSubject } from 'rxjs';

/**
 * @deprecated use new Auth library authentication service
 */
@Injectable({ providedIn: 'root' })
export class LegacyAuthenticationService {
    private url?: string;
    #authenticated$ = new BehaviorSubject(false);

    public get authenticated() {
        return this.#authenticated$.value;
    }

    public get authenticated$() {
        return this.#authenticated$.asObservable();
    }

    public readonly loaded$ = this.legacySsoService.loaded$;

    public get enabled() {
        if (this.appConfig.ENABLE_AUTH_V2) {
            return false;
        }
        return !this.legacySsoService.enabled;
    }

    public get savedUrl() {
        return this.url;
    }

    constructor(
        private legacySsoService: LegacySsoAuthService,
        private appConfig: AppConfigService,
        private loginService: LegacyLoginService,
    ) {}

    public saveUrl(url: string) {
        this.url = url;
    }

    public clearUrl() {
        this.url = undefined;
    }

    public async authenticate(login: string, password: string) {
        try {
            const res = await this.loginService.systemLogin(false, password);

            this.#authenticated$.next(true);

            return res;
        } catch (error) {
            this.#authenticated$.next(false);
            throw error;
        }
    }
}
