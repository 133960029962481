import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { BaseService } from '@datagalaxy/core-ui';
import { AppDataService } from './app-data.service';
import { IUserSpaceAccessTypeUpdateParam } from '../workspace/workspace-administration-feature/dxy-space-authorizations/space-authorization.types';
import { EmailUtil } from '@datagalaxy/core-util';
import {
    GetObjectSecurityParameter,
    GetObjectSecurityParameterItem,
    SecurityApiService,
    SecurityRole,
    SecurityRoleConstant,
} from '@datagalaxy/webclient/security/data-access';
import {
    ClientAccessFlags,
    LoginSecurityData,
} from '@datagalaxy/webclient/client/data-access';
import { AppConfigService } from '@datagalaxy/webclient/config';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';
import { WorkspaceDetails } from '@datagalaxy/webclient/workspace/domain';

@Injectable({ providedIn: 'root' })
export class SecurityService extends BaseService {
    //#region static
    private static isClientAdmin(
        availableRoles: SecurityRole[],
        systemRoles: SecurityRole[],
    ) {
        const technicalAdminRole = SecurityService.getSystemRoleByType(
            SecurityRoleConstant.TechnicalAdministrator,
            systemRoles,
        );
        const administrativeAdminRole = SecurityService.getSystemRoleByType(
            SecurityRoleConstant.AdministrativeAdministrator,
            systemRoles,
        );

        return (
            SecurityService.isRoleAvailable(
                technicalAdminRole,
                availableRoles,
            ) ||
            SecurityService.isRoleAvailable(
                administrativeAdminRole,
                availableRoles,
            )
        );
    }

    private static getSystemRoleByType(
        securityRoleType: SecurityRoleConstant,
        systemRoles: SecurityRole[],
    ) {
        return systemRoles?.find((sr) => sr.Value == securityRoleType);
    }

    private static isRoleAvailable(
        role: SecurityRole,
        availableRoles: SecurityRole[],
    ) {
        return (
            role && SecurityService.containsRoleType(availableRoles, role.Value)
        );
    }

    private static containsRoleType(
        roles: SecurityRole[],
        roletype: SecurityRoleConstant,
    ) {
        return !!roles?.some((r) => r.Value === roletype);
    }

    private static isSubOrganizationEnabled(
        clientAccessFlags: ClientAccessFlags[],
    ) {
        return clientAccessFlags?.includes(
            ClientAccessFlags.EnableSubOrganizations,
        );
    }

    private static isSingleWorkspaceClient(
        clientAccessFlags: ClientAccessFlags[],
    ) {
        return clientAccessFlags?.includes(ClientAccessFlags.IsSingleWorkspace);
    }

    private static isVersioningDisabled(
        clientAccessFlags: ClientAccessFlags[],
    ) {
        return clientAccessFlags?.includes(ClientAccessFlags.DisableVersioning);
    }

    private static isFeedbackDisabled(clientAccessFlags: ClientAccessFlags[]) {
        return clientAccessFlags?.includes(ClientAccessFlags.DisableFeedback);
    }

    private static isOnlineConnectorEnabled(
        clientAccessFlags: ClientAccessFlags[],
    ) {
        return clientAccessFlags?.includes(
            ClientAccessFlags.EnableEmbeddedConnector,
        );
    }

    private static isDesktopConnectorDisabled(
        clientAccessFlags: ClientAccessFlags[],
    ) {
        return clientAccessFlags?.includes(
            ClientAccessFlags.DisableDesktopConnector,
        );
    }

    private static isOnlineConnectorTextEditorEnabled(
        clientAccessFlags: ClientAccessFlags[],
    ) {
        return clientAccessFlags?.includes(
            ClientAccessFlags.EnableOnlineConnectionTextEditor,
        );
    }

    private static isDataQualityEnabled(
        clientAccessFlags: ClientAccessFlags[],
    ) {
        return clientAccessFlags?.includes(ClientAccessFlags.EnableDataQuality);
    }

    private static areCampaignsEnabled(clientAccessFlags: ClientAccessFlags[]) {
        return clientAccessFlags?.includes(ClientAccessFlags.EnableCampaigns);
    }

    private static createGetObjectSecurityDataParameterItem(
        dataReferenceId: string,
        principalId: string,
        withChildrenObjects = false,
        includeNoDataAccess = false,
    ) {
        const gsdpi = new GetObjectSecurityParameterItem();
        gsdpi.DataReferenceId = dataReferenceId;
        gsdpi.SecurityPrincipalId = principalId;
        gsdpi.WithChildrenObjects = withChildrenObjects;
        gsdpi.IncludeNoAccessData = includeNoDataAccess;
        return gsdpi;
    }

    //#endregion - static

    //#region Field validity checks

    /** function that returns an empty string if the given password meets security criteria, else the translated error message */
    public readonly passwordStrengthCheckFn: (password: string) => string;

    /** function that returns an empty string if the given email is valid, else the translated error message */
    public readonly emailFormatCheckFn: (email: string) => string;

    /** function that returns an empty string if the given url is valid, else the translated error message */
    public readonly urlFormatCheckFn: (url: string) => string;

    //#region Password regular expressions
    private readonly passwordRegex = new RegExp(
        "^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[@%+/'!#$^?:`(){}~*]).{8,}$",
    );
    /** The string must contain at least 1 lowercase alphabetical character */
    private readonly passwordLowerCaseRegex = new RegExp('^(?=.*[a-z])');
    /** The string must contain at least 1 uppercase alphabetical character */
    private readonly passwordUppercaseRegex = new RegExp('^(?=.*[A-Z])');
    /** The string must contain at least 1 numeric character */
    private readonly passwordNumericRegex = new RegExp('^(?=.*[0-9])');
    /** The string must contain at least one special character */
    private readonly passwordSpecialRegex = new RegExp(
        "^(?=.*[@%+/'!#$^?:`(){}~*])",
    );
    /** The string must be eight characters or longer */
    private readonly passwordLengthRegex = new RegExp('^.{8,}');
    //#endregion

    //#region Email regular expression
    private readonly urlValidationRegExp = new RegExp(/^https:\/\/(.+)\/$/);
    //#endregion

    //#endregion - Field validity checks

    private clientAccessFlagInfo: IClientAccessFlags;

    private systemRoles: SecurityRole[] = [];
    private availableRoles: SecurityRole[] = [];

    constructor(
        private translate: TranslateService,
        private appConfigService: AppConfigService,
        private appDataService: AppDataService,
        private securityApiService: SecurityApiService,
    ) {
        super();
        this.passwordStrengthCheckFn = (password: string) =>
            this.checkPasswordStrengthTranslated(password);
        this.emailFormatCheckFn = (email: string) =>
            this.checkEmailFormatTranslated(email);
        this.urlFormatCheckFn = (url: string) =>
            this.checkUrlFormatTranslated(url);
    }

    //#region init

    public setSystemRoles(systemSecurityRoles: SecurityRole[]) {
        this.systemRoles = systemSecurityRoles;
        this.availableRoles = [];
    }

    public setUserSecurity(
        clientAccessFlags: string[],
        loginSecurityData: LoginSecurityData,
    ) {
        this.updateAccessFlags(
            clientAccessFlags.map(
                (valueString) =>
                    ClientAccessFlags[valueString] as ClientAccessFlags,
            ),
        );
        this.availableRoles =
            this.systemRoles.filter((sr) =>
                loginSecurityData?.UserRoleLocalIds?.includes(sr.LocalRoleId),
            ) ?? [];
    }

    private updateAccessFlags(clientAccessFlags: ClientAccessFlags[]) {
        this.clientAccessFlagInfo = {
            isSubOrganizationEnabled:
                SecurityService.isSubOrganizationEnabled(clientAccessFlags),
            isSingleWorkspaceClient:
                SecurityService.isSingleWorkspaceClient(clientAccessFlags),
            isVersioningDisabled:
                SecurityService.isVersioningDisabled(clientAccessFlags),
            isFeedbackDisabled:
                SecurityService.isFeedbackDisabled(clientAccessFlags),
            isOnlineConnectorEnabled:
                SecurityService.isOnlineConnectorEnabled(clientAccessFlags),
            isDesktopConnectorDisabled:
                SecurityService.isDesktopConnectorDisabled(clientAccessFlags),
            isOnlineConnectorTextEditorEnabled:
                SecurityService.isOnlineConnectorTextEditorEnabled(
                    clientAccessFlags,
                ),
            isDataQualityEnabled:
                SecurityService.isDataQualityEnabled(clientAccessFlags),
            areCampaignsEnabled:
                SecurityService.areCampaignsEnabled(clientAccessFlags),
        };
    }

    //#endregion

    // from auth-grid

    public get spaceSecurityDataUpdated$() {
        return this.spaceSecurityDataUpdated.asObservable();
    }

    public readonly spaceSecurityDataUpdated =
        new Subject<IUserSpaceAccessTypeUpdateParam>();
    //#region getter methods

    //#region accessFlags - Note: public logic is always 'is enabled'
    public isClientSubOrganizationEnabled() {
        return this.clientAccessFlagInfo?.isSubOrganizationEnabled;
    }

    public isSingleWorkspaceClient() {
        return this.clientAccessFlagInfo?.isSingleWorkspaceClient;
    }

    public isClientVersioningEnabled() {
        return !this.clientAccessFlagInfo?.isVersioningDisabled;
    }

    public isClientFeedbackEnabled() {
        return !this.clientAccessFlagInfo?.isFeedbackDisabled;
    }

    public isClientOnlineConnectorEnabled() {
        return this.clientAccessFlagInfo?.isOnlineConnectorEnabled;
    }

    public isClientDesktopConnectorEnabled() {
        return !this.clientAccessFlagInfo?.isDesktopConnectorDisabled;
    }

    public isOnlineConnectorTextEditorEnabled() {
        return this.clientAccessFlagInfo?.isOnlineConnectorTextEditorEnabled;
    }

    public isDataQualityEnabled() {
        return this.clientAccessFlagInfo?.isDataQualityEnabled;
    }

    public areCampaignsEnabled() {
        return this.clientAccessFlagInfo?.areCampaignsEnabled;
    }

    public isInstanceOnlineConnectorEnabled() {
        return (
            !this.appConfigService.DISABLE_CONNECTORS &&
            this.appConfigService.isEmbeddedConnectorAvailable
        );
    }

    public isOnlineConnectorEnabled() {
        return (
            this.isInstanceOnlineConnectorEnabled() &&
            this.isClientOnlineConnectorEnabled()
        );
    }

    public isConnectorUrnSupportEnabled() {
        return this.appConfigService.ENABLE_CONNECTOR_URN_SUPPORT;
    }

    public isDesktopConnectorEnabled() {
        return (
            !this.appConfigService.DISABLE_CONNECTORS &&
            this.isClientDesktopConnectorEnabled()
        );
    }

    //#endregion - accessFlags

    public getSystemRoles(
        keepSpaceRoles = true,
        keepObjectRoles = true,
        userRoles?: SecurityRole[],
    ) {
        const isUserRoleAvailable = (role: SecurityRole) => {
            const isDataReaderLicence = SecurityService.containsRoleType(
                userRoles,
                SecurityRoleConstant.UserDataReader,
            );
            if (
                isDataReaderLicence &&
                (role.Value === SecurityRoleConstant.ObjectAdministrator ||
                    role.Value === SecurityRoleConstant.DataSteward)
            ) {
                return false;
            }

            const isDataExplorerLicence = SecurityService.containsRoleType(
                userRoles,
                SecurityRoleConstant.UserDataExplorer,
            );
            return !(
                isDataExplorerLicence &&
                role.Value === SecurityRoleConstant.ObjectAdministrator
            );
        };

        const selectedRoles = this.systemRoles.filter((role) => {
            let keepRole = true;
            if (keepSpaceRoles) {
                keepRole &&= role.IsSpaceObjectLevel;
            }
            if (keepObjectRoles) {
                keepRole &&= role.IsDataObjectLevel;
            }
            if (keepRole && userRoles) {
                keepRole = isUserRoleAvailable(role);
            }
            return keepRole;
        });

        return selectedRoles;
    }

    public getRoleByType(securityRoleType: SecurityRoleConstant) {
        return SecurityService.getSystemRoleByType(
            securityRoleType,
            this.systemRoles,
        );
    }

    public getObjectSecurityData(gsdp: GetObjectSecurityParameter) {
        return this.securityApiService.getObjectSecurity(gsdp);
    }

    public hasLevelAccess(minimumRole: SecurityRoleConstant) {
        const roleId = this.getRoleByType(minimumRole).$id;
        return this.availableRoles.some((r) => r.$id == roleId);
    }

    public isSteward() {
        return !this.availableRoles.some(
            (role) =>
                role.Value == SecurityRoleConstant.UserDataExplorer ||
                role.Value == SecurityRoleConstant.UserDataReader,
        );
    }

    public isEntityCreatedByCurrentUser(entityData: EntityItem) {
        return entityData.CreationUserId === this.appDataService.currentUserId;
    }

    public getSpaceSecurityData(space: WorkspaceDetails) {
        return space.ProjectSecurityData;
    }

    public hasManagementAccess(space: WorkspaceDetails) {
        return this.getSpaceSecurityData(space)?.HasManagementAccess;
    }

    public hasAdminAccess(space: WorkspaceDetails) {
        return this.getSpaceSecurityData(space)?.HasAdministratorAccess;
    }

    public isCurrentUserClientAdmin() {
        return SecurityService.isClientAdmin(
            this.availableRoles,
            this.systemRoles,
        );
    }

    //#endregion - getter methods

    //#region create parameters

    public createGetObjectSecurityParameter(
        dataReferenceId: string,
        principalId: string,
        withChildrenObjects: boolean,
        includeNoAccessData: boolean,
    ) {
        const gosp = new GetObjectSecurityParameter();
        const gospi = SecurityService.createGetObjectSecurityDataParameterItem(
            dataReferenceId,
            principalId,
            withChildrenObjects,
            includeNoAccessData,
        );
        gosp.Items.push(gospi);
        return gosp;
    }

    //#endregion - create parameters

    //#region Field validity checks

    /** returns an empty string if the given password meets security criteria, else the translated error message */
    public checkPasswordStrengthTranslated(password: string) {
        const { key, data } = this.checkPasswordStrength(password) ?? {};
        return key ? this.translate.instant(key) + (data ?? '') : '';
    }

    private checkPasswordStrength(password: string): {
        key: string;
        data?: string;
    } {
        if (!password) {
            return { key: 'UI.Attribute.msgRequired' };
        }
        if (this.passwordRegex.test(password)) {
            return;
        }

        if (!this.passwordLowerCaseRegex.test(password)) {
            return {
                key: 'UI.Attribute.Password.error.passwordLowerCaseCheck',
            };
        }
        if (!this.passwordUppercaseRegex.test(password)) {
            return {
                key: 'UI.Attribute.Password.error.passwordUpperCaseCheck',
            };
        }
        if (!this.passwordNumericRegex.test(password)) {
            return { key: 'UI.Attribute.Password.error.passwordNumericCheck' };
        }
        if (!this.passwordSpecialRegex.test(password)) {
            return {
                key: 'UI.Attribute.Password.error.passwordSpecialCharCheck',
                data: ": @%+/'!#$^?:`(){}~*",
            };
        }
        if (!this.passwordLengthRegex.test(password)) {
            return { key: 'UI.Attribute.Password.error.passwordLengthCheck' };
        }
    }

    /** returns an empty string if the given email is valid, else the translated error message */
    public checkEmailFormatTranslated(email: string) {
        const key = this.checkEmailFormat(email);
        return key ? this.translate.instant(key) : '';
    }

    /** returns an empty string if the given email is valid, else the translation key for the error message */
    public checkEmailFormat(email: string) {
        if (!email) {
            return 'UI.Attribute.msgRequired';
        }
        if (!EmailUtil.validateEmailFormat(email)) {
            return 'UI.Attribute.Email.error.emailCheck';
        }
        return '';
    }

    /** returns an empty string if the given url is valid, else the translated error message */
    public checkUrlFormatTranslated(url: string) {
        const key = this.checkUrlFormat(url);
        return key ? this.translate.instant(key) : '';
    }

    /** returns an empty string if the given URL is valid, else the translation key for the error message */
    public checkUrlFormat(url: string) {
        if (!this.urlValidationRegExp.test(url)) {
            return 'UI.Attribute.Url.error.urlCheck';
        }
        return '';
    }

    //#endregion - Field validity checks
}

export interface IClientAccessFlags {
    isSubOrganizationEnabled: boolean;
    isSingleWorkspaceClient: boolean;
    isVersioningDisabled: boolean;
    isFeedbackDisabled: boolean;
    isOnlineConnectorEnabled: boolean;
    isDesktopConnectorDisabled: boolean;
    isOnlineConnectorTextEditorEnabled: boolean;
    isDataQualityEnabled: boolean;
    areCampaignsEnabled: boolean;
}
