import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { BaseService } from '@datagalaxy/core-ui';
import {
    DialogResult,
    DialogType,
    DxyBaseModalComponent,
    IConfirmOptions,
    IInformOptions,
    IModalDialog,
    IModalDialogEvent,
    IModalDialogResult,
    IModalSettings,
    IPromptOptions,
    IShowDialogOptions,
} from '@datagalaxy/ui/dialog';
import { IConfirmDeleteObjectOptions } from './shared-dialogs.types';
import { Subject } from 'rxjs';
import { DxyDialogService } from '@datagalaxy/core-ui/dialog';
import { ServerType } from '@datagalaxy/dg-object-model';
import { FunctionalLogService } from '@datagalaxy/shared/monitoring/data-access';

/** Keeps track of the currently displayed modal 'window'
 *
 * #archi-services-others: DxyDialogService, FunctionalLogService
 * */
@Injectable({ providedIn: 'root' })
export class DxyModalService extends BaseService {
    public get isActiveModal() {
        return !!this.current;
    }
    public get isActiveRootModal() {
        return !this.current?.parent;
    }

    public get currentModalClosed$() {
        return this.currentModalClosed.asObservable();
    }
    private currentModalClosed = new Subject<void>();

    private current: IModalDialog;

    constructor(
        private translate: TranslateService,
        private dxyDialogService: DxyDialogService,
        private functionalLogService: FunctionalLogService,
    ) {
        super();
        dxyDialogService.onDialogOpened$.subscribe((e) =>
            this.onDialogOpened(e),
        );
        dxyDialogService.onDialogClosed$.subscribe((modal) =>
            this.onDialogClosed(modal),
        );
        dxyDialogService.onDialogResult$.subscribe((result) =>
            this.onDialogResult(result),
        );
    }

    //#region Modal

    /** display the given component as a modal dialog */
    public async open<
        TComponent extends DxyBaseModalComponent<TData, TResult>,
        TData = void,
        TResult = void,
    >(options: IModalSettings<TComponent, TData, TResult>) {
        this.log('open-start', options);
        const result = await this.dxyDialogService.open<
            TComponent,
            TData,
            TResult
        >(options);
        this.log('open-end', result);
        return result;
    }

    /** close(cancel) any open modal. */
    public dismissAll() {
        const all: IModalDialog[] = [];
        let c = this.current;
        while (c) {
            all.push(c);
            c = c.parent;
        }
        this.log('dismissAll', !!this.current, all);
        all.forEach((m) => {
            this.current = m;
            this.dismissCurrent();
        });
    }

    private dismissCurrent() {
        if (!this.current) {
            return;
        }
        this.log('dismissCurrent', this.current);
        const instance = this.current.instance;
        try {
            instance.close();
        } finally {
            this.current = null;
        }
    }

    private onDialogOpened(e: IModalDialogEvent) {
        const { modal, options } = e as IModalDialogEvent;
        const newCurrent = {
            parent: options.isFromActiveModal ? this.current : undefined,
            instance: modal.instance,
        };
        this.log('onDialogOpened', modal, options, newCurrent);
        this.current = newCurrent;
    }
    private onDialogClosed(modal: IModalDialog) {
        if (!modal) {
            return;
        }
        this.log('onDialogClosed', modal);
        this.current = modal.parent;
        this.currentModalClosed.next();
    }
    private onDialogResult(result: IModalDialogResult) {
        this.log('onDialogResult', result);
        this.functionalLogService.parseAndLog(
            result.featureCode,
            result.origin,
        );
    }

    //#endregion

    //#region Dialogs

    /** display a modal dialog with title, message,
     * and a single button to close the dialog */
    public async inform(opt: IInformOptions) {
        this.log('inform', opt);
        return await this.dxyDialogService.inform(opt);
    }

    /** display a modal dialog with title, message,
     * a text input field,
     * and 2 buttons for to confirm or cancel */
    public async prompt(opt: IPromptOptions) {
        this.log('prompt', opt);
        return await this.dxyDialogService.prompt(opt);
    }

    //#region showDialog:confirmation

    /** display a modal dialog with title, message,
     * and 2 buttons for to confirm or cancel an action */
    public async confirm(opt: IConfirmOptions) {
        this.log('confirm', opt);
        return await this.dxyDialogService.confirm(opt);
    }

    /** Display a custom dialog using *DxyModalDialogComponent*.
     * Note: Use only if *inform()*, *prompt()* and *confirm()* do not fit your needs,
     * and use *open()* to display another component as a modal. */
    public async showDialog(opt: IShowDialogOptions) {
        this.log('showDialog', opt);
        return await this.dxyDialogService.showDialog(opt);
    }

    public async confirmDeleteObject(
        typeName: string,
        opt?: IConfirmDeleteObjectOptions,
    ) {
        const data = this.getDataForConfirmDeleteObject(typeName, opt);
        try {
            const dialogOpt: IShowDialogOptions = {
                type: DialogType.Action,
                confirmButtonKey: 'UI.Dialog.btnDelete',
                featureCode: opt?.featureCode,
                isFromActiveModal: opt?.isFromActiveModal,
                ...data,
            };
            const result = await this.dxyDialogService.showDialog(dialogOpt);
            return result == DialogResult.Action;
        } catch (e) {
            this.warn(e);
        }
    }

    public async confirmCancelEdit(
        typeName: 'ObjectCommentary' | 'ObjectTask',
        isFromActiveModal = false,
    ) {
        this.log('confirmCancelEdit', typeName);
        const data = this.getDataForConfirmCancelEdit(typeName);
        return this.confirm({
            ...data,
            type: DialogType.YesNo,
            isFromActiveModal,
        });
    }

    /** displays a confirm modal dialog with a single message, displayed as the modal title, or not */
    public async confirmMessage(message: string, noTitle?: boolean) {
        return this.dxyDialogService.confirmMessage(message, noTitle);
    }
    /** displays a prompt modal dialog with a default value, and a single message displayed as the modal title, or not */
    public async promptOrDefault(
        message: string,
        defaultValue: string,
        noTitle?: boolean,
    ) {
        return this.dxyDialogService.promptOrDefault(
            message,
            defaultValue,
            noTitle,
        );
    }

    //#endregion

    private getDataForConfirmDeleteObject(
        typeName: string,
        opt?: IConfirmDeleteObjectOptions,
    ): Partial<IShowDialogOptions> {
        const count = opt?.count ?? 1;
        const removeOnly = opt?.removeOnly ?? false;
        const totalCount = opt?.totalCount ?? -1;
        const spaceName = opt?.spaceName ?? null;
        const type: ServerType = ServerType[typeName] || ServerType.AllTypes;
        typeName = ServerType[type];
        let objectTypeParticle: string;
        switch (type) {
            case ServerType.Project:
                objectTypeParticle = 'deleteProject';
                break;
            case ServerType.Dashboard:
                objectTypeParticle = 'deleteDashboard';
                break;
            case ServerType.EntityLink:
                objectTypeParticle = 'deleteLink';
                break;
            default:
                objectTypeParticle = removeOnly
                    ? 'removeObject'
                    : 'deleteObject';
                break;
        }

        const demonstrativeParticleKey =
            count > 1
                ? 'UI.IModal.generalObject.demonstrativeParticlePlural'
                : 'UI.IModal.generalObject.demonstrativeParticle';
        const titleKey =
            count > 1
                ? `UI.IModal.${objectTypeParticle}Plural.title`
                : `UI.IModal.${objectTypeParticle}.title`;
        const msgParticle = totalCount > count ? 'msgTotal' : 'msg';
        const messageKey =
            count > 1
                ? `UI.IModal.${objectTypeParticle}Plural.${msgParticle}`
                : `UI.IModal.${objectTypeParticle}.${msgParticle}`;
        const typeNameKey =
            count > 1
                ? `DgServerTypes.ServerTypeNamePlural.${typeName}`
                : `DgServerTypes.ServerTypeName.${typeName}`;
        const typeNameGenderKey = `DgServerTypes.ServerTypeNameGender.${typeName}`;

        const typeNameTranslated = this.translate
            .instant(typeNameKey)
            ?.toLocaleLowerCase();
        const typeNameGender = this.translate.instant(typeNameGenderKey);

        let hasUserInput = false;
        let userInputMessage = '';
        let userInputValidationMethod: (input: string) => boolean;
        let userInputClass = '';

        if (totalCount > 1) {
            hasUserInput = true;
            userInputMessage = this.translate.instant(
                'UI.IModal.deleteObject.msgUserInput',
            );
            userInputClass = 'dg_userInput_numeric';
            userInputValidationMethod = (value: string) =>
                totalCount.toString() == value;
        } else if (spaceName != undefined) {
            hasUserInput = true;
            userInputMessage = this.translate.instant(
                'UI.IModal.deleteObject.msgUserInputSpace',
                { spaceName },
            );
            userInputClass = 'dg_userInput_spacename';
            userInputValidationMethod = (value: string) => value == spaceName;
        }

        const demonstrativeParticle = this.translate.instant(
            demonstrativeParticleKey,
            {
                typeNameGender,
                typeName: typeNameTranslated,
                objectsCount: count,
            },
        );

        const title = this.translate.instant(titleKey, {
            typeNameGender,
            typeName: typeNameTranslated,
            demonstrativeParticle,
            objectsCount: count,
        });
        const message = this.translate.instant(messageKey, {
            typeNameGender,
            typeName: typeNameTranslated,
            demonstrativeParticle,
            objectsCount: count,
            totalCount: totalCount,
        });

        return {
            title,
            message,
            hasUserInput,
            userInputMessage,
            userInputClass,
            userInputValidationMethod,
            confirmButtonColor: 'destructive',
        };
    }

    private getDataForConfirmCancelEdit(
        typeName: 'ObjectCommentary' | 'ObjectTask',
    ) {
        return {
            titleKey: 'UI.IModal.cancelEditObject.title',
            message: this.translate.instant('UI.IModal.cancelEditObject.msg', {
                typeNameGender: this.translate.instant(
                    `DgServerTypes.ServerTypeNameGender.${typeName}`,
                ),
                typeName: this.translate
                    .instant(`DgServerTypes.ServerTypeName.${typeName}`)
                    ?.toLocaleLowerCase(),
            }),
        };
    }
}
