import { select, selectAll } from 'd3-selection';
import { MovePhase, TDomElement } from '@datagalaxy/core-2d-util';
import { CollectionsHelper, CoreUtil, DomUtil } from '@datagalaxy/core-util';
import {
    ClickSelectionTool,
    ConnectorEditorTool,
    DragSelectionTool,
    EndpointTool,
    HoverTool,
    INodeDragEvent,
    IPanZoom,
    IPanZoomEvent,
    IResizeEvent,
    ISelection,
    LinkingTool,
    MinimapTool,
    NodeDragTool,
    PanZoomTool,
    ResizeTool,
    SelectionTool,
    Viewport,
} from './tools';
import { ZoomedViewAdapter } from './tools/pan-zoom/zoom-adapters/ZoomedViewAdapter';
import { map, Subject } from 'rxjs';
import {
    GraphSurfaceOptions,
    IExposedD3Div,
    IExposedD3Svg,
    ISurfaceEvents,
    ISurfaceResizeEvent,
    SurfaceNodeDragEvent,
} from './graph-surface.types';
import { BaseGraphicalManager } from './BaseGraphicalManager';
import { LayerManager } from './layer';
import { GraphManager } from './graph-manager';
import { IGraphManager, IGraphStateEvent } from './graph-manager.types';
import { Connector } from './connector';
import { AnimationFrameBuffer } from './utils/animation-frame-buffer';
import { GridManager } from './grid';
import { LayoutManager } from './layout/layout.manager';
import { ManagedItem } from './node/managed-item';
import { KeyboardUtil } from '@datagalaxy/utils';

export class GraphSurface<
    /** (E)lement: the type of DOM elements this component has to manage. Those can be SVG or HTML elements */
    E extends TDomElement = TDomElement,
    /** (D)ata: the type of data associated with each managed DOM element */
    NodeData = unknown,
    /** drag (S)ubject, for drag events */
    S = unknown,
    EdgeData = unknown,
> extends BaseGraphicalManager {
    private static class = 'gs-internal';

    public readonly events: ISurfaceEvents<NodeData, EdgeData>;
    public readonly el: HTMLDivElement;

    //#region tools
    private readonly dragSelectionTool: DragSelectionTool<E, NodeData>;
    private readonly resizeTool: ResizeTool<NodeData>;
    private readonly gridMgr: GridManager;
    private readonly panZoomTool: PanZoomTool;
    private readonly nodeDraggingTool: NodeDragTool<NodeData, EdgeData>;
    private readonly layerManager: LayerManager;
    private readonly selectionTool: SelectionTool<NodeData, EdgeData>;
    private readonly edgeEditorManager: ConnectorEditorTool<NodeData, EdgeData>;
    private readonly clickSelectionTool: ClickSelectionTool;
    private readonly linkingTool: LinkingTool<NodeData, EdgeData>;
    private readonly endpointTool: EndpointTool<NodeData, EdgeData>;
    private readonly viewportManager: Viewport;
    private readonly minimapTool: MinimapTool;
    private readonly graphMgr: GraphManager<NodeData, EdgeData>;
    private readonly hoverTool: HoverTool<NodeData, EdgeData>;
    private readonly layoutManager: LayoutManager<NodeData>;
    //#endregion tools

    private readonly resized = new Subject<ISurfaceResizeEvent<NodeData>>();
    private readonly nodeDragged = new Subject<SurfaceNodeDragEvent>();
    private readonly escapeKeyDown = new Subject<KeyboardEvent>();
    private initialZoomDone: boolean;

    private readonly afb = AnimationFrameBuffer.getInstance();

    private detachKeyboard: () => void;

    //#region public tools interfaces
    public get zoom(): IPanZoom {
        return this.panZoomTool;
    }
    public get selection(): ISelection<NodeData, EdgeData> {
        return this.selectionTool;
    }
    public get graph(): IGraphManager<NodeData, EdgeData> {
        return this.graphMgr;
    }
    public get minimap() {
        return this.minimapTool;
    }
    public get viewport() {
        return this.viewportManager;
    }
    public get grid() {
        return this.gridMgr;
    }
    public get layout() {
        return this.layoutManager;
    }
    //#endregion public tools interfaces

    public get isConnectionBeingDragged() {
        return this.linkingTool.isConnecting;
    }
    public get foreground() {
        return this.front?.el;
    }
    public get svgL() {
        return this.layers.zoomed.svg as IExposedD3Svg;
    }
    public get front() {
        return this.layers.zoomed.front as IExposedD3Div;
    }
    public get selectionOld() {
        return selectAll(
            this.selectionTool.nodeSelection?.map(
                (node) => node.el as SVGElement,
            ),
        );
    }

    private get layers() {
        return this.layerManager.layers;
    }
    /** Coordinates converter to/from zoomed view */
    private get adapter(): ZoomedViewAdapter {
        return this.panZoomTool.adapter;
    }

    private set currentCursor(cursor: string) {
        requestAnimationFrame(() => (this.el.style.cursor = cursor));
    }

    constructor(
        containerOrSelector: string | HTMLElement,
        private options?: GraphSurfaceOptions<E, NodeData, S, EdgeData>,
    ) {
        super();
        const container = DomUtil.getElementOrSelector(containerOrSelector);
        if (!container) {
            CoreUtil.warn('container element not found', containerOrSelector);
            return;
        }

        const el = (this.el = this.createGraphElement(container));

        const go = this.makeManagerOptions(options, options.graph, true);

        this.graphMgr = new GraphManager<NodeData, EdgeData>(go);
        this.panZoomTool = new PanZoomTool(container, this.graphMgr, options);
        this.viewportManager = new Viewport(container, this.panZoomTool);
        this.layerManager = new LayerManager(el, this.viewport);
        this.gridMgr = new GridManager(
            this.layerManager.zoomedView.node(),
            this.viewportManager,
            options?.grid,
        );
        this.selectionTool = new SelectionTool(
            this.graphMgr,
            this.layerManager,
            options?.selection,
        );
        this.nodeDraggingTool = new NodeDragTool<NodeData, EdgeData>(
            this.graphMgr,
            this.gridMgr,
            this.selectionTool,
            this.adapter,
            options?.nodeDrag,
        );
        this.dragSelectionTool = new DragSelectionTool<E, NodeData>(
            select(this.el),
            this.selectionTool,
            this.adapter,
            this.graphMgr,
            options,
        );
        this.resizeTool = new ResizeTool<NodeData>(
            this.layerManager,
            this.adapter,
            this.selectionTool,
            this.gridMgr,
            this.graphMgr,
            options.resize,
        );
        this.endpointTool = new EndpointTool(
            this.adapter,
            this.selectionTool,
            this.graphMgr,
            this.options?.endpoint,
            this.layerManager,
        );
        this.linkingTool = new LinkingTool<NodeData, EdgeData>(
            this.el,
            this.adapter,
            this.graphMgr,
            this.endpointTool,
            options.linking,
        );
        this.edgeEditorManager = new ConnectorEditorTool(
            this.adapter,
            this.layerManager,
            this.endpointTool,
            this.graphMgr,
            this.selectionTool,
            this.options?.graph?.edges,
        );
        this.clickSelectionTool = new ClickSelectionTool(
            this.el,
            this.selectionTool,
            this.graphMgr,
        );
        this.minimapTool = new MinimapTool(
            this.options.minimapContainer,
            this.graphMgr,
            this.viewportManager,
            this.panZoomTool,
        );
        this.hoverTool = new HoverTool<NodeData, EdgeData>(
            this.graphMgr,
            this.options?.hover,
        );
        this.layoutManager = new LayoutManager<NodeData>(
            this.graphMgr,
            this.options?.layout,
        );

        this.events = {
            surfaceClicked$: this.clickSelectionTool.surfaceClicked$,
            edgeClicked$: this.clickSelectionTool.edgeClicked$,
            nodeClicked$: this.clickSelectionTool.nodeClicked$,
            zoomed$: this.panZoomTool.zoomed$,
            resized$: this.resized.asObservable(),
            modeChanged$: this.selectionTool.modeChanged$,
            selectionChanged$: this.selectionTool.selection$.pipe(
                map((items) => items.map((it) => it.data)),
            ),
            selectionUpdated$: this.selectionTool.selectionUpdated$.pipe(
                map((items) => items.map((it) => it.data)),
            ),
            areaRect$: this.dragSelectionTool.areaRect$,
            escapeKey$: this.escapeKeyDown.asObservable(),
            nodeDragged$: this.nodeDragged.asObservable(),
            connectorPortDragged$: this.endpointTool.connectorPortDragged$,
            viewportChanged$: this.viewport.change$.pipe(
                map((event) => event.phase),
            ),
            edgeHovered$: this.hoverTool.connectorHover$,
            nodePortHovered$: this.hoverTool.nodePortHover$,
        };

        this.subscribeEvents();
        this.currentCursor = 'grab';
    }

    public dispose() {
        super.dispose();
        this.detachKeyboard?.();

        this.resizeTool.dispose();
        this.dragSelectionTool.clear(true);
        this.selectionTool.detach();
        this.panZoomTool.dispose();
        this.layerManager.dispose();
        this.minimapTool.dispose();
        this.graphMgr.dispose();
        this.nodeDraggingTool.dispose();

        this.el?.remove();
    }

    public resetInitialZoom() {
        this.initialZoomDone = undefined;
    }

    private subscribeEvents() {
        this.registerSubscriptions(
            this.graphMgr.events$.subscribe((event) =>
                this.onGraphEvent(event),
            ),
            this.resizeTool.resized$.subscribe((event) =>
                this.onNodeResize(event),
            ),
            this.panZoomTool.zoomed$.subscribe((event) =>
                this.onZoomChanged(event),
            ),
            this.nodeDraggingTool.dragged$.subscribe((event) =>
                this.onNodeDragged(event),
            ),
            this.endpointTool.connectorPortChanged$.subscribe((event) =>
                this.onConnectorPortChange(event),
            ),
            this.selectionTool.modeChanged$.subscribe((event) =>
                this.onSelectionModeChange(event),
            ),
        );
        this.attachKeyboard();
    }

    private createGraphElement(container: HTMLElement) {
        container
            .querySelectorAll(`div.${GraphSurface.class}`)
            .forEach((el) => el.remove());

        const el = DomUtil.createElement('div', GraphSurface.class);
        container.append(el);
        return el;
    }

    private attachKeyboard() {
        if (!this.options.watchKeyboard) {
            return;
        }
        this.detachKeyboard = DomUtil.addListener(window, 'keydown', (e) => {
            if (KeyboardUtil.isEscapeKey(e)) {
                this.escapeKeyDown.next(e);
            }
        });
    }

    private onZoomChanged(event: IPanZoomEvent) {
        switch (event.phase) {
            case MovePhase.start:
                this.currentCursor = 'grabbing';
                break;
            case MovePhase.move:
                this.el.classList.add('pan-zooming');
                break;
            case MovePhase.end:
                this.currentCursor = 'grab';
                this.el.classList.remove('pan-zooming');
                break;
        }

        this.afb.addCallback(() => {
            this.layerManager.updateZoomLayer(event);
        });
    }

    private onConnectorPortChange(connectors: Connector<NodeData, EdgeData>[]) {
        this.afb.addCallback(() => {
            const connectorIds = connectors.map((c) => c.id);

            this.graphMgr
                .getConnectorsById(connectorIds)
                ?.forEach((c) => c.draw());
        });
    }

    private onSelectionModeChange(event: 'pan' | 'select') {
        if (event === 'select') {
            this.currentCursor = 'inherit';
        } else {
            this.currentCursor = 'grab';
        }
    }

    private onNodeResize(event: IResizeEvent<NodeData>) {
        const mi = event.item;
        const nodeConnectors = this.graphMgr.getConnectors(mi);

        if (event.phase === MovePhase.move) {
            this.el.classList.add('resizing');
        }

        if (event.phase === MovePhase.end) {
            this.el.classList.remove('resizing');
        }

        if (nodeConnectors?.length) {
            this.endpointTool.updateConnectorsPorts(nodeConnectors);
        }

        this.afb.addCallback(() => {
            this.resizeTool.drawFrames();
            mi.draw();
        });

        this.resized.next({
            node: this.graphMgr.getNodeById(mi.id),
            phase: event.phase,
        });
    }

    private onGraphEvent(event: IGraphStateEvent<NodeData, EdgeData>) {
        if (event.added) {
            const nodes = event.added.nodes;
            const connectors = CollectionsHelper.concat(
                event.added.connectors,
                CollectionsHelper.flatten(
                    nodes?.map((node) => this.graphMgr.getConnectors(node)),
                ),
            );

            const layoutUpdated = this.layoutManager.updateLayout(
                this.graphMgr.nodes,
                this.graphMgr.edges,
            );

            if (layoutUpdated) {
                this.drawAddedGraphItems(
                    this.graphMgr.nodes,
                    this.graphMgr.edges,
                );
            } else {
                this.drawAddedGraphItems(nodes, connectors);
            }
        }

        if (event.updated) {
            const nodes = event.updated.nodes;
            const connectors = CollectionsHelper.concat(
                event.updated.connectors,
                CollectionsHelper.flatten(
                    nodes?.map((node) => this.graphMgr.getConnectors(node)),
                ),
            );

            if (connectors?.length) {
                this.endpointTool.updateConnectorsPorts(connectors);
            }

            this.afb.addCallback(() => {
                this.resizeTool.drawFrames();
                this.edgeEditorManager.draw();
                nodes?.forEach((mi) => mi.draw());
            });
        }

        if (event.removed) {
            event.removed.nodes?.forEach((nodes) => nodes.dispose());
            event.removed.connectors?.forEach((connector) =>
                connector.dispose(),
            );

            const layoutUpdated = this.layoutManager.updateLayout(
                this.graphMgr.nodes,
                this.graphMgr.edges,
            );

            if (layoutUpdated) {
                this.drawAddedGraphItems(
                    this.graphMgr.nodes,
                    this.graphMgr.edges,
                );
            }
        }
    }

    private onNodeDragged(event: INodeDragEvent<NodeData>) {
        switch (event.phase) {
            case MovePhase.start: {
                this.currentCursor = 'grabbing';
                event.nodes
                    .filter((n) => !n.isContainer)
                    .forEach((n) => this.layerManager.bringToFront(n));
                break;
            }
            case MovePhase.end:
            case MovePhase.cancel:
                this.currentCursor = 'grab';
                this.el.classList.remove('dragging');
                event.nodes?.forEach((n) =>
                    this.layerManager.bringBackToInitial(n),
                );
                break;
        }
        if (event.phase !== MovePhase.move) {
            this.nodeDragged.next({
                nodes: event.nodes?.map((n) => this.graphMgr.getNodeById(n.id)),
                phase: event.phase,
            });
            return;
        }
        const managedItems = event.nodes;
        const nodes = managedItems.map((it) =>
            this.graphMgr.getNodeById(it.id),
        );

        this.el.classList.add('dragging');

        this.afb.addCallback(() => {
            this.resizeTool.drawFrames();
            this.edgeEditorManager.draw();
            managedItems.forEach((mi) => mi.draw());
        });

        const nodeConnectors = CollectionsHelper.distinct(
            CollectionsHelper.flatten(
                managedItems.map((mi) => this.graphMgr.getConnectors(mi)),
            ).filter((c) => !!c),
        );

        if (nodeConnectors?.length) {
            this.endpointTool.updateConnectorsPorts(nodeConnectors);
        }

        this.nodeDragged.next({ nodes, phase: event.phase });
    }
    //#endregion - events

    private drawAddedGraphItems(
        nodes: ManagedItem<NodeData>[],
        connectors: Connector<NodeData, EdgeData>[],
    ) {
        /**
         * Draw each node without delaying using requestAnimationFrame, as each node
         * is added only once. This approach ensures that the element becomes quickly
         * available, preventing potential issues such as attempting to interact with
         * an undrawn node's Element (e.g., focus). Drawing the nodes immediately helps
         * avoid pitfalls associated with delayed rendering.
         */
        nodes?.forEach((node) => node.draw());

        this.layerManager.addItemToSurface([...nodes, ...connectors]);

        if (connectors?.length) {
            this.endpointTool.updateConnectorsPorts(connectors);
        }

        if (!this.initialZoomDone && this.options.initialZoomToFit) {
            const event = this.panZoomTool.zoomToFit();
            this.layerManager.updateZoomLayer(event);
        }

        this.initialZoomDone ??= true;
    }
}
