import { deserialize, deserializeAs } from 'cerialize';
import { EntitySocialData } from '@datagalaxy/webclient/social/domain';
import { EntitySecurityData } from '@datagalaxy/webclient/security/domain';
import {
    EntityType,
    IDataIdentifier,
    ServerType,
} from '@datagalaxy/dg-object-model';
import {
    ModuleSecurityData,
    SpaceSecurityProfileType,
} from './workspace-security-profile';
import { IWorkspaceIdentifier } from './workspace-identifier';
import { IHasSpaceIconData } from './workspace';

export class WorkspaceDetails
    implements IWorkspaceIdentifier, IDataIdentifier, IHasSpaceIconData
{
    @deserialize public DisplayName!: string;
    @deserialize public Description!: string;
    @deserialize public ReferenceId!: string;
    @deserialize public CanImportEntities!: boolean;
    @deserialize public CanImportEntitiesOnCatalog!: boolean;
    @deserialize public CanCreateEntities!: boolean;
    @deserializeAs(EntitySecurityData)
    public ProjectSecurityData!: EntitySecurityData;
    @deserializeAs(EntitySocialData) public SocialData!: EntitySocialData;
    @deserialize public ParentOrganizationReferenceId!: string;
    @deserializeAs(ModuleSecurityData)
    public GlossarySecurityData!: ModuleSecurityData;
    @deserialize public VersionId!: string;
    @deserialize public DefaultOwnerUserId!: string;
    @deserialize public DefaultStewardUserId!: string;
    @deserialize public HasMultipleVersions!: boolean;
    @deserializeAs(SpaceSecurityProfileType)
    public SecurityProfileType!: SpaceSecurityProfileType;
    @deserialize public Trigram!: string;
    @deserialize public ImageHash!: string;
    @deserialize public IconHash!: string;
    @deserialize public VersionName!: string;
    @deserialize public VersionStatus!: string;
    @deserialize public VersionDescription!: string;
    @deserialize public IsVersioningEnabled!: boolean;
    @deserializeAs(ModuleSecurityData)
    public DataProcessingContainerSecurityData!: ModuleSecurityData;
    @deserializeAs(ModuleSecurityData)
    public SoftwareContainerSecurityData!: ModuleSecurityData;
    @deserializeAs(EntitySecurityData)
    public ModelsSecurityData!: EntitySecurityData[];
    @deserializeAs(ModuleSecurityData)
    public CatalogSecurityData!: ModuleSecurityData;
    @deserialize public OrganizationReferenceId!: string;
    @deserialize public ModelIds!: string[];

    public readonly ServerType = ServerType.Project;
    public IsDefaultSpace = false;

    public get SecurityData() {
        return this.ProjectSecurityData;
    }

    public get versionId() {
        return this.VersionId;
    }

    public get spaceId() {
        return this.ReferenceId;
    }

    public get DataReferenceId() {
        return this.ReferenceId;
    }

    public get DataTypeName() {
        return ServerType[this.ServerType];
    }

    public get entityType() {
        return EntityType.Project;
    }
}
