import { CollectionsHelper } from '@datagalaxy/core-util';
import { LineageGraphParams } from '../../impactAnalysis/lineage-graph/data/LineageGraphParams';
import {
    HierarchicalData,
    HierarchyDataDescriptor,
    IEntityIdentifier,
    ObjectLinkType,
    ServerType,
} from '@datagalaxy/dg-object-model';
import { HddUtil } from '../../shared/util/HddUtil';
import {
    DataLineageDataLink,
    DataLineageGenerationData,
    DataLineageItem,
    GetDataLineageResult,
    LineageLinkOrientationType,
    LineageOrientation,
} from '@datagalaxy/webclient/explorer/data-access';
import { getLocalId } from '@datagalaxy/webclient/utils';
import {
    GetLinkedDataResult,
    InaccessibleLinkGroups,
} from '@datagalaxy/webclient/entity/data-access';
import {
    EntityItem,
    EntityLinkItem,
    LinkedDataGroup,
} from '@datagalaxy/webclient/entity/domain';

export class ImplementationLineageDataSource extends LineageGraphParams {
    //#region static

    private static makeDataLineageResult(
        entityHdd: HierarchyDataDescriptor,
        linkedDataGroups: LinkedDataGroup[],
        inaccessibleItems: InaccessibleLinkGroups,
    ) {
        const getHds = (linkType: ObjectLinkType) =>
            linkedDataGroups
                .find((g) => g.UniversalObjectLinkType == linkType)
                ?.Items?.map((it) => it.LinkedData) ?? [];
        const implementationHds = getHds(ObjectLinkType.IsImplementedBy);
        const recordingSystemHds = getHds(ObjectLinkType.HasForRecordingSystem);

        const allHds = [...implementationHds, ...recordingSystemHds];

        // 2 columns, containing the graph's root objects' ids :
        const allHdsIds = CollectionsHelper.distinct(
            allHds
                .map((hd) =>
                    HddUtil.getParentDataDescriptorByType(hd, [
                        ServerType.Model,
                    ]),
                )
                .filter((o) => o)
                .map((hdd) => hdd.DataLocalId),
        );
        allHdsIds.push(
            ...ImplementationLineageDataSource.getAllInaccessibleIds(
                inaccessibleItems,
            ),
        );

        const generations = [
            // left column: only the entity
            new DataLineageGenerationData(0, [entityHdd.DataLocalId]),
            // right column: the databases (distinct list of recording systems and implementing entities' database)
            new DataLineageGenerationData(1, allHdsIds),
        ];

        const items = new Map<string, DataLineageItem>();
        items.set(
            entityHdd.DataLocalId,
            ImplementationLineageDataSource.makeItem(entityHdd, true),
        );

        allHds.forEach((hd) => {
            [
                hd.Data,
                ...HddUtil.getHierarchyDataDescriptorList(hd).filter(
                    (o) => o.EntityType,
                ),
            ].forEach((hdd) => {
                if (!hdd?.DataLocalId || items.has(hdd.DataLocalId)) {
                    return;
                }
                items.set(
                    hdd.DataLocalId,
                    ImplementationLineageDataSource.makeItem(
                        hdd,
                        hdd.DataServerType == ServerType.Model,
                    ),
                );
            });
        });

        ImplementationLineageDataSource.addInaccessibleItems(
            items,
            inaccessibleItems,
        );

        const links = ImplementationLineageDataSource.createLinks(
            entityHdd,
            recordingSystemHds,
            implementationHds,
            inaccessibleItems,
            linkedDataGroups,
        );

        const result = new GetDataLineageResult();
        result.SourceDataLocalId = entityHdd.DataLocalId;
        result.SourceDataTypeName = entityHdd.DataTypeName;
        result.generations = generations;
        result.Items = Array.from(items.values());
        result.DataLinks = links;
        result.IsSuccess = true;

        return result;
    }

    private static makeItem(hdd: HierarchyDataDescriptor, isRoot: boolean) {
        const dli = new DataLineageItem();
        dli.DataLocalId = hdd.DataLocalId;
        dli.DisplayName = hdd.DisplayName;
        dli.TechnicalName = hdd.TechnicalName;
        dli.EntityType = hdd.EntityType;
        dli.ParentLocalId = isRoot ? undefined : hdd.ParentList[0];
        dli.HasReadAccess = true;
        return dli;
    }

    private static makeLink(
        sourceHdd: HierarchyDataDescriptor,
        hdd: HierarchyDataDescriptor,
        linkType: ObjectLinkType,
        linkItemsMap: Map<string, EntityLinkItem>,
    ) {
        const link = new DataLineageDataLink(
            linkType,
            undefined,
            undefined,
            undefined,
        );
        link.SourceId = sourceHdd.DataLocalId;
        link.TargetId = hdd.DataLocalId;
        const baseLinkData = linkItemsMap.get(
            `${link.SourceId}-${link.TargetId}-${linkType}`,
        );
        link.IsGoldenLink = baseLinkData.IsGoldenLink;
        link.EntityLinkReferenceId = baseLinkData.DataReferenceId;
        link.UniversalObjectLinkType = linkType;
        link.OrientationType = LineageLinkOrientationType.Unoriented;
        return link;
    }

    private static createLinkItemsMap(linkedDataGroups: LinkedDataGroup[]) {
        const typedLinkItems = CollectionsHelper.flattenGroups(
            linkedDataGroups,
            (group) =>
                group.Items.map((i) => ({
                    type: group.UniversalObjectLinkType,
                    item: i,
                })),
        );
        return new Map<string, EntityLinkItem>(
            typedLinkItems.map((linkItem) => {
                const linkSourceId =
                    getLocalId(
                        linkItem.item.LinkEntityData.Source.DataReferenceId,
                    ) ?? '';
                const linkTargetId =
                    getLocalId(
                        linkItem.item.LinkEntityData.Target.DataReferenceId,
                    ) ?? '';
                return [
                    `${linkSourceId}-${linkTargetId}-${linkItem.type}`,
                    linkItem.item.LinkEntityData,
                ];
            }),
        );
    }

    private static addInaccessibleItems(
        items: Map<string, DataLineageItem>,
        inaccessibleItems: InaccessibleLinkGroups,
    ) {
        const inaccessibleImplementedBy =
            inaccessibleItems[ObjectLinkType[ObjectLinkType.IsImplementedBy]] ??
            [];
        const inaccessibleRecordedSystem =
            inaccessibleItems[
                ObjectLinkType[ObjectLinkType.HasForRecordingSystem]
            ] ?? [];
        const inaccessibleItemsArray = [
            ...inaccessibleImplementedBy,
            ...inaccessibleRecordedSystem,
        ];

        inaccessibleItemsArray?.forEach((i) => {
            const dli = new DataLineageItem();
            dli.DataLocalId = getLocalId(i.ReferenceId);
            dli.EntityType = i.EntityType;
            dli.ParentLocalId = undefined;
            dli.HasReadAccess = false;
            items.set(getLocalId(i.ReferenceId), dli);
        });
    }

    private static createLinks(
        sourceHdd: HierarchyDataDescriptor,
        recordingSystems: HierarchicalData[],
        implementations: HierarchicalData[],
        inaccessibleItems: InaccessibleLinkGroups,
        linkedDataGroups: LinkedDataGroup[],
    ) {
        // The order is important: Since the final DataLineage logic will only keep one link per src->dst,
        // we need to make sure the IsImplementedBy is set the last, for the Golden Link information to be used properly

        const linkItemsMap =
            ImplementationLineageDataSource.createLinkItemsMap(
                linkedDataGroups,
            );

        const recordingSystemLinks = recordingSystems.map((hd) =>
            ImplementationLineageDataSource.makeLink(
                sourceHdd,
                hd.Data,
                ObjectLinkType.HasForRecordingSystem,
                linkItemsMap,
            ),
        );

        const inaccessibleRecordedSystemLinks =
            ImplementationLineageDataSource.createInaccessibleLinks(
                sourceHdd,
                inaccessibleItems,
                ObjectLinkType.HasForRecordingSystem,
            );

        const implementedByLinks = implementations.map((hd) =>
            ImplementationLineageDataSource.makeLink(
                sourceHdd,
                hd.Data,
                ObjectLinkType.IsImplementedBy,
                linkItemsMap,
            ),
        );

        const inaccessibleImplementedByLinks =
            ImplementationLineageDataSource.createInaccessibleLinks(
                sourceHdd,
                inaccessibleItems,
                ObjectLinkType.IsImplementedBy,
            );

        return [
            ...recordingSystemLinks,
            ...inaccessibleRecordedSystemLinks,
            ...implementedByLinks,
            ...inaccessibleImplementedByLinks,
        ];
    }

    private static createInaccessibleLinks(
        sourceHdd: HierarchyDataDescriptor,
        inaccessibleItems: InaccessibleLinkGroups,
        linkType: ObjectLinkType,
    ) {
        const items = inaccessibleItems[ObjectLinkType[linkType]];

        return (
            items?.map((i) => {
                return ImplementationLineageDataSource.makeInaccessibleLink(
                    sourceHdd,
                    getLocalId(i.ReferenceId),
                    linkType,
                );
            }) ?? []
        );
    }

    private static makeInaccessibleLink(
        sourceHdd: HierarchyDataDescriptor,
        targetId: string,
        linkType: ObjectLinkType,
    ) {
        const link = new DataLineageDataLink(
            linkType,
            undefined,
            undefined,
            undefined,
        );
        link.SourceId = sourceHdd.DataLocalId;
        link.TargetId = targetId;
        link.IsGoldenLink = false;
        link.EntityLinkReferenceId = undefined;
        link.UniversalObjectLinkType = linkType;
        link.OrientationType = LineageLinkOrientationType.Unoriented;
        return link;
    }

    private static getAllInaccessibleIds(
        inaccessibleLinkGroups: InaccessibleLinkGroups,
    ) {
        const implementedByIds =
            inaccessibleLinkGroups[
                ObjectLinkType[ObjectLinkType.IsImplementedBy]
            ]?.map((item) => {
                return getLocalId(item.ReferenceId);
            }) ?? [];
        const recordingSystemIds =
            inaccessibleLinkGroups[
                ObjectLinkType[ObjectLinkType.HasForRecordingSystem]
            ]?.map((item) => {
                return getLocalId(item.ReferenceId);
            }) ?? [];

        return [...implementedByIds, ...recordingSystemIds];
    }

    //#endregion

    public linkedData: GetLinkedDataResult;

    constructor(
        private getLinkedData: (
            identifier: IEntityIdentifier,
            ...linkTypes: ObjectLinkType[]
        ) => Promise<GetLinkedDataResult>,
        public readonly entityItem: EntityItem,
        private log?: (...args: any[]) => void,
    ) {
        super(
            entityItem,
            LineageOrientation.Both,
            (forceReload) => this.getDataInternal(forceReload),
            true,
        );
    }

    private async getDataInternal(forceReload: boolean) {
        if (forceReload) {
            this.linkedData = undefined;
        }
        this.linkedData ??= await this.getLinkedData(
            this.entityItem,
            ObjectLinkType.IsImplementedBy,
            ObjectLinkType.HasForRecordingSystem,
        );

        const result = ImplementationLineageDataSource.makeDataLineageResult(
            this.entityItem.HddData.Data,
            this.linkedData.Groups,
            this.linkedData.InaccessibleLinkGroups,
        );
        this.log &&
            this.log(
                'ImplementationLineageDataSource-getDataInternal',
                forceReload,
                this.linkedData,
                result,
            );
        return result;
    }
}
