@if (generating()) {
    <dxy-message color="ai" [displayMode]="'card'">
        <div class="writing" message-content>
            {{ 'Suggestions.AiGeneration.writing' | translate }}
            <dxy-ai-writing />
        </div>
    </dxy-message>
} @else if (error()) {
    <dxy-message
        [color]="technicalError() ? 'danger' : 'ai'"
        [titleGlyphClass]="technicalError() ? 'glyph-warning' : ''"
        [displayMode]="'card'"
        [canClose]="true"
        (closeClick)="cancel.emit()"
    >
        <ng-container message-title>
            {{ 'Suggestions.AiGeneration.generationErrorTitle' | translate }}
        </ng-container>
        <div message-content>
            {{ generationErrorMessage() }}
        </div>
    </dxy-message>
} @else {
    <dxy-message
        color="ai"
        [displayMode]="'card'"
        titleGlyphClass="glyph-ai"
        [canClose]="true"
        (closeClick)="cancel.emit()"
    >
        <ng-container message-title>
            <ng-content select="[title]"></ng-content>
        </ng-container>
        <ng-container message-content>
            <ng-content select="[suggestion]"></ng-content>
            <div class="dxy-actions">
                <button
                    dxyButton
                    type="button"
                    (click)="cancel.emit()"
                    variant="secondary"
                    color="ai"
                    translate
                >
                    Suggestions.AiGeneration.cancel
                </button>
                <button
                    dxyButton
                    type="button"
                    (click)="accept.emit()"
                    variant="primary"
                    color="ai"
                    translate
                >
                    Suggestions.AiGeneration.acceptSuggestion
                </button>
            </div>
        </ng-container>
    </dxy-message>
}
