import {
    deserialize,
    deserializeAs,
    inheritSerialization,
    serialize,
} from 'cerialize';
import {
    BaseMessage,
    BaseServiceParameter,
    BaseServiceResult,
} from '@datagalaxy/data-access';
import { IWorkspaceIdentifier } from '@datagalaxy/webclient/workspace/domain';

export enum EmptyFieldBehavior {
    Default,
    DeleteValues,
}

/** Note: The order and integer values of this enum can be changed freely,
 * as long as the Text version of the value is used in the API */
export enum ImportDataOperation {
    None,
    ImportGlossary,
    ImportGlossaryLink,
    ImportCatalogSource,
    ImportCatalogContainer,
    ImportCatalogStructure,
    ImportCatalogField,
    ImportCatalogPrimaryKey,
    ImportCatalogForeignKey,
    ImportCatalogFunctionalKey,
    ImportCatalogLink,
    ImportProcessing,
    ImportProcessingInput,
    ImportProcessingOutput,
    ImportProcessingItem,
    ImportProcessingLink,
    ImportUsage,
    ImportUsageLink,
    ImportAttributeTag,
    ImportUser,
    ImportEntityTimeSeries,
    ImportTeam,
}

export enum ImportDataFormat {
    Unknown,
    SeparatedText,
    Json,
    Xml,
}

export enum ImportDataOperationParameterType {
    /* NOTE: Only for Catalog Operations except ImportCatalogSource: optional: 0/1 default: 1 */
    IsNewSource = 1,
    /*  NOTE: MANDATORY for Catalog Operations except ImportCatalogSource */
    NewSourceName = 2,

    /* NOTE: MANDATORY for Catalog Operations except ImportCatalogSource: Numeric value of ModelType enum */
    NewSourceType = 3,

    /* NOTE: MANDATORY for Catalog Operations except ImportCatalogSource if IsNewSource = 0 */
    ExistingSourceId = 5,

    CreateFieldDataTypeIfMissing = 6,

    // Unused for now
    Technology = 7,
    // optional
}

export interface IImportDataParameterBase {
    spaceIdr: IWorkspaceIdentifier;
    importOperation: ImportDataOperation;
    importRawContent: string;
    mappings: ImportDataMapping[];
    textFieldSeparator: string;
    encoding: string;
    attributeId: string;
    isPreImport: boolean;
    entityReferenceId?: string;
    attributeKey?: string;
    contentRequestId?: string;
    emptyFieldBehavior?: EmptyFieldBehavior;
}

@inheritSerialization(BaseMessage)
export class ImportDataMessage extends BaseMessage {
    // ImportDataMessageCode: We do not duplicate the list in the client code.
    // Please look in Server-side ImportDataMessageCode.cs file for the full list of the codes
    @deserialize public LineNumber!: number;
    @deserialize public SourceLineContent!: string;
}

export class ImportAttributesMetaInfo {
    @deserialize public AttributeKey?: string;
    @deserialize public AttributeHeaderName?: string;
    @deserialize public IsMandatory?: boolean;
    @deserialize public IsCdp?: boolean;
    @deserialize public IsSystemUser?: boolean;
    @deserialize public Description?: string;
    @deserialize public DataTypeName?: string;
}

export class ImportDataStatisticsItem {
    @deserialize public Name!: string;
    @deserialize public TotalLinesCount?: number;
    @deserialize public TotalCount?: number;
    @deserialize public AddedCount?: number;
    @deserialize public UpdatedCount?: number;
    @deserialize public DeletedCount?: number;
    @deserialize public UnchangedCount?: number;
}

export class ImportDataStatistics {
    @deserializeAs(ImportDataStatisticsItem)
    public Total!: ImportDataStatisticsItem;
    @deserializeAs(ImportDataStatisticsItem)
    public Details!: ImportDataStatisticsItem[];
    @deserialize public TotalLinesCount?: number;
}

@inheritSerialization(BaseServiceResult)
export class ImportDataResult extends BaseServiceResult {
    // NOTE: We do not show this number for now
    @deserialize public IgnoredLineCount!: number;
    @deserialize public NewModelId!: string;
    @deserializeAs(ImportDataMessage) public Messages!: ImportDataMessage[];
    @deserializeAs(ImportDataStatistics) public Stats!: ImportDataStatistics;
    @deserialize public ContentRequestId!: string;

    public isPreImportResult?: boolean;
}

export class ImportDataOperationParameter {
    @serialize public Type: string;
    @serialize public Value: string;

    public constructor(type: ImportDataOperationParameterType, value: string) {
        this.Type = ImportDataOperationParameterType[type];
        this.Value = value;
    }
}

export class ImportDataMapping {
    @serialize public ContentFieldName: string;
    @serialize public DataFieldName: string;

    constructor(dataFieldName: string, contentFieldName: string) {
        this.ContentFieldName = contentFieldName;
        this.DataFieldName = dataFieldName;
    }
}

@inheritSerialization(BaseServiceParameter)
export class ImportDataParameter extends BaseServiceParameter {
    @serialize public ParentReferenceId?: string | null;
    @serialize public ImportOperation: string;
    @serialize public ImportRawContent: string;
    @serialize public Mappings: ImportDataMapping[];
    @serialize public OperationParameters: ImportDataOperationParameter[];
    @serialize public Format: string;
    @serialize public RawContentType: string;
    @serialize public TextEntrySeparator: string;
    @serialize public TextFieldSeparator: string;
    @serialize public EncodingWebName: string;
    @serialize public AttributeUid: string;
    @serialize public EntityReferenceId?: string;
    @serialize public AttributeKey?: string;
    @serialize public ContentRequestId?: string;
    @serialize public EmptyFieldBehavior?: EmptyFieldBehavior;

    constructor(
        importDataParameterBase: IImportDataParameterBase,
        operationParameters: ImportDataOperationParameter[],
    ) {
        super();
        this.ParentReferenceId = importDataParameterBase.spaceIdr?.spaceId;
        this.ImportOperation =
            ImportDataOperation[importDataParameterBase.importOperation];
        this.ImportRawContent = importDataParameterBase.importRawContent;
        this.Mappings = importDataParameterBase.mappings;
        this.OperationParameters = operationParameters;
        this.Format = ImportDataFormat[ImportDataFormat.SeparatedText];
        this.RawContentType = 'application/vnd.ms-excel';
        this.TextEntrySeparator = '\r\n';
        this.TextFieldSeparator = importDataParameterBase.textFieldSeparator;
        this.AttributeUid = importDataParameterBase.attributeId;
        this.EncodingWebName = importDataParameterBase.encoding;
        this.EntityReferenceId = importDataParameterBase.entityReferenceId;
        this.AttributeKey = importDataParameterBase.attributeKey;
        this.ContentRequestId = importDataParameterBase.contentRequestId;
        this.EmptyFieldBehavior = importDataParameterBase.emptyFieldBehavior;
    }
}

@inheritSerialization(BaseServiceParameter)
export class GetImportAttributesParameter extends BaseServiceParameter {
    @serialize ImportOperation: string;
    @serialize SpaceReferenceId: string;

    constructor(importOperation: ImportDataOperation, spaceId: string) {
        super();
        this.ImportOperation = ImportDataOperation[importOperation];
        this.SpaceReferenceId = spaceId;
    }
}

@inheritSerialization(BaseServiceResult)
export class GetImportAttributesResult extends BaseServiceResult {
    @deserializeAs(ImportAttributesMetaInfo)
    public ImportAttributes!: ImportAttributesMetaInfo[];
}
