import { patchState, signalStore, withMethods } from '@ngrx/signals';
import { withTextValue } from '../common/text-value-feature/text-value-feature';
import { withAutoDescription } from '../common/auto-description-feature/auto-description-feature';
import { inject } from '@angular/core';
import { AutoDescriptionApiService } from '@datagalaxy/webclient/suggestion/data-access';
import {
    setFulfilled,
    setIdle,
    setPending,
} from '@datagalaxy/shared/signal-store-common';

export const DescriptionFieldStore = signalStore(
    withTextValue(),
    withAutoDescription(),
    withMethods((store, apiService = inject(AutoDescriptionApiService)) => ({
        async generateDescription() {
            const entity = store.entity();
            if (!entity) {
                return;
            }
            patchState(store, setPending());
            try {
                const pendingSuggestion =
                    await apiService.getDescriptionSuggestion(entity);
                patchState(store, setFulfilled());
                patchState(store, {
                    pendingSuggestion,
                });
            } catch (error) {
                patchState(store, setIdle());
                patchState(store, {
                    generationError: error,
                    pendingSuggestion: undefined,
                });
            }
        },
        async acceptSuggestion() {
            const entityIdr = store.entity();
            const suggestion = store.pendingSuggestion();
            if (!entityIdr || !suggestion?.proposedValue) {
                return;
            }
            patchState(store, { pendingSuggestion: undefined });
            await apiService.acceptDescriptionSuggestion(entityIdr, suggestion);
            patchState(store, { acceptedSuggestion: suggestion });
            store.setUpdatedValue(suggestion.proposedValue);
            store.persistUpdatedValue();
        },
        async loadAcceptedSuggestion() {
            const entityIdr = store.entity();
            if (!entityIdr) {
                return;
            }
            const suggestion =
                await apiService.getAcceptedDescriptionSuggestion(entityIdr);
            patchState(store, { acceptedSuggestion: suggestion });
        },
    })),
);
