import { Injectable } from '@angular/core';
import {
    BackendApiService,
    BaseServiceParameter,
} from '@datagalaxy/data-access';
import { LoadSystemDataResult } from './system-data';

@Injectable({ providedIn: 'root' })
export class SystemApiService {
    constructor(private backendApiService: BackendApiService) {}

    public async loadSystemData(param: BaseServiceParameter) {
        return await this.backendApiService.postPromise(
            'Data/LoadSystemData',
            param,
            LoadSystemDataResult,
        );
    }
}
