/* eslint-disable @typescript-eslint/no-namespace */
export namespace Constants {
    export namespace ActionType {
        export const User = 'User';
        export const Save = 'Save';
        export const State = 'State';
    }

    export const defaultDashboardName = 'Dashboard';

    export namespace FreshdeskUrl {
        export const LangPlaceholder = '{langCode}';

        export const BaseUrl = 'https://datagalaxy.freshdesk.com/';
        export const BaseApiUrl = BaseUrl + 'api/v2';
        export const BaseArticlesUrl = `${BaseUrl}${LangPlaceholder}/support/solutions/articles`;

        /**
         * @deprecated Replace with dxyFreshdeskHelpLink directive
         */
        export const CsvImportHelp = BaseArticlesUrl + '/35000132821';
        /**
         * @deprecated Replace with dxyFreshdeskHelpLink directive
         */
        export const OnlineImportHelp = BaseArticlesUrl + '/35000179788';
        /**
         * @deprecated Replace with dxyFreshdeskHelpLink directive
         */
        export const DesktopImportHelp = BaseArticlesUrl + '/35000132804';
        /**
         * @deprecated Replace with dxyFreshdeskHelpLink directive
         */
        export const CsvImportSamples = BaseArticlesUrl + '/35000139751';
        /**
         * @deprecated Replace with dxyFreshdeskHelpLink directive
         */
        export const OnlineImportTokens = BaseArticlesUrl + '/35000132471';
        /**
         * @deprecated Replace with dxyFreshdeskHelpLink directive
         */
        export const DesktopImportOperations = BaseArticlesUrl + '/35000132850';
    }
}
