import { CoreUtil } from '@datagalaxy/core-util';
import {
    DataType,
    DataTypeMapping,
    DataTypeMappingItem,
} from '@datagalaxy/webclient/modeler/data-access';

export class DataTypeMappingAdminGridItem {
    public get isDataTypePrecisionRequired() {
        return this.dt?.IsPrecisionRequired;
    }
    public get isDataTypeSizeRequired() {
        return this.dt?.IsSizeRequired;
    }
    public get isDataTypeSystem() {
        return this.dt?.IsSystem;
    }
    public get isReadOnly() {
        return this.dtm.isSystem;
    }
    public get dataTypeMappingItem() {
        return this.dtmi;
    }

    public get dataTypeName() {
        return this.dt?.DisplayName;
    }
    public set dataTypeName(value: string) {
        this.dt.DisplayName = value;
    }

    public get dataTypeMappingName() {
        return this.dtmi.DisplayName;
    }
    public set dataTypeMappingName(value: string) {
        this.dtmi.DisplayName = value;
    }

    constructor(
        private dtm: DataTypeMapping,
        private dtmi: DataTypeMappingItem,
        private dt: DataType,
    ) {
        if (!dtm) {
            CoreUtil.warn('no dtm', this);
        }
        if (!dtmi) {
            CoreUtil.warn('no dtmi', this);
        }
        if (!dt) {
            CoreUtil.warn('no dt', this);
        }
    }
}
