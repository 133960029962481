import { Injectable } from '@angular/core';
import { LegacyBackendSignalRService } from '@datagalaxy/data-access';
import { CampaignDto } from './campaign';

@Injectable({ providedIn: 'root' })
export class CampaignSignalrService {
    public readonly created$ = this.signalR.fromUserEvent(
        'CampaignCreatedEvent',
        CampaignDto,
    );

    public readonly updated$ = this.signalR.fromUserEvent(
        'CampaignUpdatedEvent',
        CampaignDto,
    );

    public readonly phaseChanged$ = this.signalR.fromUserEvent(
        'CampaignPhaseChangedEvent',
        CampaignDto,
    );

    public readonly entitiesRemoved$ = this.signalR.fromUserEvent(
        'CampaignEntitiesRemovedEvent',
        CampaignDto,
    );

    public readonly entitiesAdded$ = this.signalR.fromUserEvent(
        'CampaignEntitiesAddedEvent',
        CampaignDto,
    );

    constructor(private signalR: LegacyBackendSignalRService) {}
}
