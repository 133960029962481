import {
    deserialize,
    inheritSerialization,
    SerializableEnumeration,
} from 'cerialize';
import { ABaseSecurityData } from '@datagalaxy/webclient/security/domain';
import { DataGalaxyModule } from '@datagalaxy/shared/dg-module/domain';

export enum SpaceSecurityProfileType {
    Private = 0,
    Limited = 1,
    Open = 2,
}
SerializableEnumeration(SpaceSecurityProfileType);

@inheritSerialization(ABaseSecurityData)
export class ModuleSecurityData extends ABaseSecurityData {
    @deserialize public Module!: DataGalaxyModule;
}
