import { OrthoSide } from './orthogonal-path-finder';
import {
    getRouteConfig,
    OrthoRotationDegree,
    PathDirection,
    rotateDirection,
} from './orthogonal-path-finder-test.util';
import { CollectionsHelper } from '@datagalaxy/core-util';
import { Point } from '@datagalaxy/core-2d-util';

export interface IOrthogonalConnectorRouteScenario {
    sideA: OrthoSide;
    sideB: OrthoSide;
    expectedDirections: PathDirection[];
}

export interface IOrthogonalConnectorRouteTestSuite {
    title?: string;
    settings: {
        boxSize: number;
        boxMargin: number;
        globalMargin: number;
        spaceBetween?: number;
    };
    getPoints: (
        size: number,
        margin: number,
        spaceBetween: number,
    ) => {
        pointA: Point;
        pointB: Point;
    };
    scenarios: IOrthogonalConnectorRouteScenario[];
}

/**
 * +------++------+
 * |      ||      |
 * |      ||      |
 * +------++------+
 * Aligned adjacent boxes with box margin and global margin
 */
export const alignedAdjacentBoxes: IOrthogonalConnectorRouteTestSuite = {
    title: 'Aligned adjacent boxes',
    settings: {
        boxSize: 100,
        boxMargin: 10,
        globalMargin: 20,
    },
    getPoints: (size: number, margin: number) => ({
        pointA: { x: 0, y: 0 },
        pointB: { x: size + margin * 2, y: 0 },
    }),
    scenarios: [
        {
            sideA: 'top',
            sideB: 'bottom',
            expectedDirections: ['up', 'right', 'down', 'left', 'up'],
        },
        {
            sideA: 'top',
            sideB: 'top',
            expectedDirections: ['up', 'right', 'down'],
        },
        { sideA: 'top', sideB: 'left', expectedDirections: [] },
        {
            sideA: 'top',
            sideB: 'right',
            expectedDirections: ['up', 'right', 'down', 'left'],
        },

        {
            sideA: 'bottom',
            sideB: 'bottom',
            expectedDirections: ['down', 'right', 'up'],
        },
        {
            sideA: 'bottom',
            sideB: 'top',
            expectedDirections: ['down', 'right', 'up', 'left', 'down'],
        },
        { sideA: 'bottom', sideB: 'left', expectedDirections: [] },
        {
            sideA: 'bottom',
            sideB: 'right',
            expectedDirections: ['down', 'right', 'up', 'left'],
        },

        { sideA: 'right', sideB: 'bottom', expectedDirections: [] },
        { sideA: 'right', sideB: 'top', expectedDirections: [] },
        { sideA: 'right', sideB: 'left', expectedDirections: [] },
        { sideA: 'right', sideB: 'right', expectedDirections: [] },

        {
            sideA: 'left',
            sideB: 'bottom',
            expectedDirections: ['left', 'down', 'right', 'up'],
        },
        {
            sideA: 'left',
            sideB: 'top',
            expectedDirections: ['left', 'up', 'right', 'down'],
        },
        { sideA: 'left', sideB: 'left', expectedDirections: [] },
        {
            sideA: 'left',
            sideB: 'right',
            expectedDirections: ['left', 'up', 'right', 'down', 'left'],
        },
    ],
};

/**
 * +------+    +------+
 * |      |    |      |
 * |      |    |      |
 * +------+    +------+
 */
const getAlignedNonAdjacentBoxes = (
    smallSpaceBetween: boolean,
): IOrthogonalConnectorRouteTestSuite => ({
    title: `Aligned boxes (non adjacent) ${
        smallSpaceBetween ? '' : '(Large space)'
    }`,
    settings: {
        boxSize: 100,
        boxMargin: 10,
        globalMargin: 20,
        spaceBetween: smallSpaceBetween ? 20 : 100,
    },
    getPoints: (size: number, margin: number, spaceBetween: number) => ({
        pointA: { x: 0, y: 0 },
        pointB: { x: size + margin * 2 + spaceBetween, y: 0 },
    }),
    scenarios: [
        {
            sideA: 'top',
            sideB: 'bottom',
            expectedDirections: ['up', 'right', 'down', 'right', 'up'],
        },
        {
            sideA: 'top',
            sideB: 'top',
            expectedDirections: ['up', 'right', 'down'],
        },
        {
            sideA: 'top',
            sideB: 'left',
            expectedDirections: ['up', 'right', 'down', 'right'],
        },
        {
            sideA: 'top',
            sideB: 'right',
            expectedDirections: ['up', 'right', 'down', 'left'],
        },

        {
            sideA: 'bottom',
            sideB: 'bottom',
            expectedDirections: ['down', 'right', 'up'],
        },
        {
            sideA: 'bottom',
            sideB: 'top',
            expectedDirections: ['down', 'right', 'up', 'right', 'down'],
        },
        {
            sideA: 'bottom',
            sideB: 'left',
            expectedDirections: ['down', 'right', 'up', 'right'],
        },
        {
            sideA: 'bottom',
            sideB: 'right',
            expectedDirections: ['down', 'right', 'up', 'left'],
        },

        {
            sideA: 'right',
            sideB: 'bottom',
            expectedDirections: ['right', 'down', 'right', 'up'],
        },
        {
            sideA: 'right',
            sideB: 'top',
            expectedDirections: ['right', 'up', 'right', 'down'],
        },
        { sideA: 'right', sideB: 'left', expectedDirections: ['right'] },
        {
            sideA: 'right',
            sideB: 'right',
            expectedDirections: ['right', 'down', 'right', 'up', 'left'],
        },

        {
            sideA: 'left',
            sideB: 'bottom',
            expectedDirections: ['left', 'down', 'right', 'up'],
        },
        {
            sideA: 'left',
            sideB: 'top',
            expectedDirections: ['left', 'up', 'right', 'down'],
        },
        {
            sideA: 'left',
            sideB: 'left',
            expectedDirections: ['left', 'up', 'right', 'down', 'right'],
        },
        {
            sideA: 'left',
            sideB: 'right',
            expectedDirections: ['left', 'up', 'right', 'down', 'left'],
        },
    ],
});
export const alignedNonAdjacentBoxesWithSmallSpace =
    getAlignedNonAdjacentBoxes(true);
export const alignedNonAdjacentBoxesWithLargeSpace =
    getAlignedNonAdjacentBoxes(false);

/**
 * +------+
 * |      |
 * |      |
 * +------+
 *
 *              +------+
 *              |      |
 *              |      |
 *              +------+
 */
const getDiagonalNonAlignedBoxes = (
    isSmallSpace: boolean,
): IOrthogonalConnectorRouteTestSuite => ({
    title: `Diagonal non aligned boxes ${isSmallSpace ? '' : '(Large space)'}`,
    settings: {
        boxSize: 100,
        boxMargin: 10,
        globalMargin: 20,
        spaceBetween: isSmallSpace ? 20 : 100,
    },
    getPoints: (size: number, margin: number, spaceBetween: number) => ({
        pointA: { x: 0, y: 0 },
        pointB: {
            x: size + margin * 2 + spaceBetween,
            y: size + margin * 2 + spaceBetween,
        },
    }),
    scenarios: [
        {
            sideA: 'top',
            sideB: 'bottom',
            expectedDirections: ['up', 'right', 'down', 'right', 'up'],
        },
        {
            sideA: 'top',
            sideB: 'top',
            expectedDirections: ['up', 'right', 'down'],
        },
        {
            sideA: 'top',
            sideB: 'left',
            expectedDirections: ['up', 'right', 'down', 'right'],
        },
        {
            sideA: 'top',
            sideB: 'right',
            expectedDirections: ['up', 'right', 'down', 'left'],
        },

        {
            sideA: 'bottom',
            sideB: 'bottom',
            expectedDirections: ['down', 'right', 'up'],
        },
        {
            sideA: 'bottom',
            sideB: 'top',
            expectedDirections: ['down', 'right', 'down'],
        },
        {
            sideA: 'bottom',
            sideB: 'left',
            expectedDirections: ['down', 'right'],
        },
        {
            sideA: 'bottom',
            sideB: 'right',
            expectedDirections: ['down', 'right', 'down', 'left'],
        },

        {
            sideA: 'right',
            sideB: 'bottom',
            expectedDirections: ['right', 'down', 'right', 'up'],
        },
        { sideA: 'right', sideB: 'top', expectedDirections: ['right', 'down'] },
        {
            sideA: 'right',
            sideB: 'left',
            expectedDirections: ['right', 'down', 'right'],
        },
        {
            sideA: 'right',
            sideB: 'right',
            expectedDirections: ['right', 'down', 'left'],
        },

        {
            sideA: 'left',
            sideB: 'bottom',
            expectedDirections: ['left', 'down', 'right', 'up'],
        },
        {
            sideA: 'left',
            sideB: 'top',
            expectedDirections: ['left', 'down', 'right', 'down'],
        },
        {
            sideA: 'left',
            sideB: 'left',
            expectedDirections: ['left', 'down', 'right'],
        },
        {
            sideA: 'left',
            sideB: 'right',
            expectedDirections: ['left', 'down', 'right', 'down', 'left'],
        },
    ],
});
export const diagonalNonAlignedBoxesWithSmallSpace =
    getDiagonalNonAlignedBoxes(true);
export const diagonalNonAlignedBoxesWithLargeSpace =
    getDiagonalNonAlignedBoxes(false);

/**
 * +------+
 * |      |
 * |      |
 * +------+
 *
 *         +------+
 *         |      |
 *         |      |
 *         +------+
 */
export const diagonalBoxesVerticallyAligned: IOrthogonalConnectorRouteTestSuite =
    {
        title: 'Diagonal boxes vertically aligned',
        settings: {
            boxSize: 100,
            boxMargin: 10,
            globalMargin: 20,
            spaceBetween: 20,
        },
        getPoints: (size: number, margin: number, spaceBetween: number) => ({
            pointA: { x: 0, y: 0 },
            pointB: {
                x: size + margin * 2,
                y: size + margin * 2 + spaceBetween,
            },
        }),
        scenarios: [
            {
                sideA: 'top',
                sideB: 'bottom',
                expectedDirections: [
                    'up',
                    'right',
                    'down',
                    'left',
                    'down',
                    'right',
                    'up',
                ],
            },
            {
                sideA: 'top',
                sideB: 'top',
                expectedDirections: ['up', 'right', 'down'],
            },
            {
                sideA: 'top',
                sideB: 'left',
                expectedDirections: [
                    'up',
                    'right',
                    'down',
                    'left',
                    'down',
                    'right',
                ],
            },
            {
                sideA: 'top',
                sideB: 'right',
                expectedDirections: ['up', 'right', 'down', 'left'],
            },

            {
                sideA: 'bottom',
                sideB: 'bottom',
                expectedDirections: ['down', 'right', 'up'],
            },
            {
                sideA: 'bottom',
                sideB: 'top',
                expectedDirections: ['down', 'right', 'down'],
            },
            {
                sideA: 'bottom',
                sideB: 'left',
                expectedDirections: ['down', 'right'],
            },
            {
                sideA: 'bottom',
                sideB: 'right',
                expectedDirections: ['down', 'right', 'down', 'left'],
            },

            {
                sideA: 'right',
                sideB: 'bottom',
                expectedDirections: ['right', 'down', 'left', 'up'],
            },
            {
                sideA: 'right',
                sideB: 'top',
                expectedDirections: ['right', 'down'],
            },
            {
                sideA: 'right',
                sideB: 'left',
                expectedDirections: ['right', 'down', 'left', 'down', 'right'],
            },
            {
                sideA: 'right',
                sideB: 'right',
                expectedDirections: ['right', 'down', 'left'],
            },

            {
                sideA: 'left',
                sideB: 'bottom',
                expectedDirections: ['left', 'down', 'right', 'up'],
            },
            {
                sideA: 'left',
                sideB: 'top',
                expectedDirections: ['left', 'down', 'right', 'down'],
            },
            {
                sideA: 'left',
                sideB: 'left',
                expectedDirections: ['left', 'down', 'right'],
            },
            {
                sideA: 'left',
                sideB: 'right',
                expectedDirections: ['left', 'down', 'right', 'down', 'left'],
            },
        ],
    };

/**
 * +------+
 * |      |
 * |      |
 * +------+
 *         +------+
 *         |      |
 *         |      |
 *         +------+
 */
export const diagonalAdjacentBoxes: IOrthogonalConnectorRouteTestSuite = {
    title: 'Diagonal adjacent boxes',
    settings: {
        boxSize: 100,
        boxMargin: 10,
        globalMargin: 20,
        spaceBetween: 0,
    },
    getPoints: (size: number, margin: number, spaceBetween: number) => ({
        pointA: { x: 0, y: 0 },
        pointB: { x: size + margin * 2, y: size + margin * 2 + spaceBetween },
    }),
    scenarios: [
        {
            sideA: 'top',
            sideB: 'bottom',
            expectedDirections: ['up', 'right', 'down', 'left', 'up'],
        },
        {
            sideA: 'top',
            sideB: 'top',
            expectedDirections: ['up', 'right', 'down'],
        },
        {
            sideA: 'top',
            sideB: 'left',
            expectedDirections: ['up', 'left', 'down', 'right'],
        },
        {
            sideA: 'top',
            sideB: 'right',
            expectedDirections: ['up', 'right', 'down', 'left'],
        },

        {
            sideA: 'bottom',
            sideB: 'bottom',
            expectedDirections: ['down', 'right', 'up'],
        },
        {
            sideA: 'bottom',
            sideB: 'top',
            expectedDirections: ['down', 'right', 'up', 'left', 'down'],
        },
        {
            sideA: 'bottom',
            sideB: 'left',
            expectedDirections: ['down', 'right'],
        },
        {
            sideA: 'bottom',
            sideB: 'right',
            expectedDirections: ['down', 'right', 'up', 'left'],
        },

        {
            sideA: 'right',
            sideB: 'bottom',
            expectedDirections: ['right', 'down', 'left', 'up'],
        },
        { sideA: 'right', sideB: 'top', expectedDirections: ['right', 'down'] },
        {
            sideA: 'right',
            sideB: 'left',
            expectedDirections: ['right', 'down', 'left', 'up', 'right'],
        },
        {
            sideA: 'right',
            sideB: 'right',
            expectedDirections: ['right', 'down', 'left'],
        },

        {
            sideA: 'left',
            sideB: 'bottom',
            expectedDirections: ['left', 'down', 'right', 'up'],
        },
        {
            sideA: 'left',
            sideB: 'top',
            expectedDirections: ['left', 'up', 'right', 'down'],
        },
        {
            sideA: 'left',
            sideB: 'left',
            expectedDirections: ['left', 'down', 'right'],
        },
        {
            sideA: 'left',
            sideB: 'right',
            expectedDirections: ['left', 'down', 'right', 'up', 'left'],
        },
    ],
};

/**
 * +------+
 * |      |
 * |      |
 * +------+
 *         +------+
 *         |      |
 *         |      |
 *         +------+
 */
export const diagonalOverlappingMarginBoxes: IOrthogonalConnectorRouteTestSuite =
    {
        title: 'Diagonal overlapping margin boxes',
        settings: {
            boxSize: 100,
            boxMargin: 10,
            globalMargin: 20,
            spaceBetween: 0,
        },
        getPoints: (size: number, margin: number, spaceBetween: number) => ({
            pointA: { x: 0, y: 0 },
            pointB: {
                x: size + margin * 2 - 10,
                y: size + margin * 2 + spaceBetween - 10,
            },
        }),
        scenarios: [
            {
                sideA: 'top',
                sideB: 'bottom',
                expectedDirections: ['up', 'right', 'down', 'left', 'up'],
            },
            {
                sideA: 'top',
                sideB: 'top',
                expectedDirections: ['up', 'right', 'down'],
            },
            {
                sideA: 'top',
                sideB: 'left',
                expectedDirections: ['up', 'left', 'down', 'right'],
            },
            {
                sideA: 'top',
                sideB: 'right',
                expectedDirections: ['up', 'right', 'down', 'left'],
            },

            {
                sideA: 'bottom',
                sideB: 'bottom',
                expectedDirections: ['down', 'right', 'up'],
            },
            {
                sideA: 'bottom',
                sideB: 'top',
                expectedDirections: ['down', 'right', 'up', 'left', 'down'],
            },
            {
                sideA: 'bottom',
                sideB: 'left',
                expectedDirections: ['down', 'right'],
            },
            {
                sideA: 'bottom',
                sideB: 'right',
                expectedDirections: ['down', 'right', 'up', 'left'],
            },

            {
                sideA: 'right',
                sideB: 'bottom',
                expectedDirections: ['right', 'down', 'left', 'up'],
            },
            {
                sideA: 'right',
                sideB: 'top',
                expectedDirections: ['right', 'down'],
            },
            {
                sideA: 'right',
                sideB: 'left',
                expectedDirections: ['right', 'down', 'left', 'up', 'right'],
            },
            {
                sideA: 'right',
                sideB: 'right',
                expectedDirections: ['right', 'down', 'left'],
            },

            {
                sideA: 'left',
                sideB: 'bottom',
                expectedDirections: ['left', 'down', 'right', 'up'],
            },
            {
                sideA: 'left',
                sideB: 'top',
                expectedDirections: ['left', 'up', 'right', 'down'],
            },
            {
                sideA: 'left',
                sideB: 'left',
                expectedDirections: ['left', 'down', 'right'],
            },
            {
                sideA: 'left',
                sideB: 'right',
                expectedDirections: ['left', 'down', 'right', 'up', 'left'],
            },
        ],
    };

function getAllRotationScenarios(
    testSuite: IOrthogonalConnectorRouteTestSuite,
) {
    const rotationDegrees: OrthoRotationDegree[] = [0, 90, 180, 270];
    return CollectionsHelper.flatten(
        rotationDegrees.map((rotation: OrthoRotationDegree) =>
            testSuite.scenarios.map((scenario) => [
                getRouteConfig(testSuite, scenario, rotation),
                rotateDirection(scenario.expectedDirections, rotation),
            ]),
        ),
    );
}

export const orthogonalRouteTestCases = [
    alignedAdjacentBoxes,
    alignedNonAdjacentBoxesWithSmallSpace,
    alignedNonAdjacentBoxesWithLargeSpace,
    diagonalNonAlignedBoxesWithSmallSpace,
    diagonalNonAlignedBoxesWithLargeSpace,
    diagonalBoxesVerticallyAligned,
    diagonalAdjacentBoxes,
    diagonalOverlappingMarginBoxes,
];

export const orthogonalRouteTestCasesScenarios = CollectionsHelper.flatten(
    orthogonalRouteTestCases.map((settings) =>
        getAllRotationScenarios(settings),
    ),
);
