import { BaseService } from '@datagalaxy/core-ui';
import { CoreUtil } from '@datagalaxy/core-util';
import { Injectable } from '@angular/core';
import { UserService } from '../../services/user.service';
import { BaseServiceParameter } from '@datagalaxy/data-access';
import {
    CreateTechnologyParameter,
    DeleteTechnologyParameter,
    TechnologyApiService,
} from '@datagalaxy/webclient/technology/data-access';
import {
    ImageCategory,
    SetImageParameter,
} from '@datagalaxy/webclient/client/data-access';
import { ClientService } from '../../client/client.service';
import { EntityCardDisplayMode } from '@datagalaxy/webclient/entity/ui';
import { TechnologyStore } from './technology-store.service';
import { userSettingsValues } from '@datagalaxy/webclient/user/domain';
import { TechnologyDto } from '@datagalaxy/webclient/technology/domain';
import { DgModule } from '@datagalaxy/shared/dg-module/domain';

@Injectable({ providedIn: 'root' })
export class TechnologyService extends BaseService {
    public userEntityCardDisplay: EntityCardDisplayMode;

    public get technologies() {
        return this.technologyStore.technologies;
    }

    public get store() {
        return this.technologyStore;
    }

    constructor(
        private clientService: ClientService,
        private technologyStore: TechnologyStore,
        private technologyApiService: TechnologyApiService,
        private userService: UserService,
    ) {
        super();
    }

    public async initAsync() {
        await Promise.all([
            this.initTechnologies(),
            this.initUserEntityCardDisplay(),
        ]);
    }

    public async preCreateTechnology(
        technologyName: string,
        modules: DgModule[],
        technologyCode?: string,
    ) {
        const moduleStrings = modules.map((module) => DgModule[module]);
        const param = new CreateTechnologyParameter(
            technologyName,
            moduleStrings,
            technologyCode,
        );
        return await this.technologyApiService.preCreateTechnology(param);
    }

    public async createTechnology(
        technologyName: string,
        technologyCode: string,
        modules: DgModule[],
        description: string,
    ) {
        const moduleStrings = modules.map((module) => DgModule[module]);
        const param = new CreateTechnologyParameter(
            technologyName,
            moduleStrings,
            technologyCode,
            description,
        );
        const result = await this.technologyApiService.createTechnology(param);
        const createdTechnology = result.CreatedTechnology;
        createdTechnology.dgModules = createdTechnology.Modules.map(
            (m) => DgModule[m],
        );
        return createdTechnology;
    }

    public async preUpdateTechnology(
        technology: TechnologyDto,
        modules: DgModule[],
    ) {
        const moduleStrings = modules.map((module) => DgModule[module]);
        const param = new CreateTechnologyParameter(
            technology.DisplayName,
            moduleStrings,
            technology.TechnologyCode,
        );
        return await this.technologyApiService.preUpdateTechnology(param);
    }

    public async updateTechnology(
        technology: TechnologyDto,
        isDescriptionUpdate: boolean,
    ) {
        const description = isDescriptionUpdate
            ? technology.DescriptionFr
            : null;
        technology.Modules = technology.dgModules.map(
            (module) => DgModule[module],
        );
        const param = new CreateTechnologyParameter(
            technology.DisplayName,
            technology.Modules,
            technology.TechnologyCode,
            description,
        );

        await this.technologyApiService.updateTechnology(param);
        this.technologyStore.dispatchUpdate(technology);
    }

    public async preDeleteTechnology(technologyCode: string) {
        const param = new DeleteTechnologyParameter(technologyCode);
        return this.technologyApiService.preDeleteTechnology(param);
    }

    public async deleteTechnology(technologyCode: string) {
        const param = new DeleteTechnologyParameter(technologyCode);
        await this.technologyApiService.deleteTechnology(param);
        this.technologyStore.dispatchDelete(technologyCode);
    }

    public getTechnologyIconUrl(technology: TechnologyDto, useSvg?: boolean) {
        if (!technology) {
            return 'images/undefined-techno-icon.svg';
        }
        const hash = useSvg
            ? (technology?.SvgImageHash ?? technology?.ImageHash)
            : technology?.ImageHash;
        return !hash ? null : this.clientService.getImageUrl(hash);
    }

    public getTechnologyIconUrlFromCode(technologyCode, useSvg?: boolean) {
        const techno = this.getTechnologyFromCode(technologyCode);
        return this.getTechnologyIconUrl(techno, useSvg);
    }

    public async setTechnologyIcon(
        technologyCode: string,
        file: File,
        isDelete?: boolean,
    ) {
        const imageCategory = ImageCategory.TechnologyImage;
        let param: SetImageParameter;
        if (isDelete) {
            param = SetImageParameter.forDelete(
                imageCategory,
                null,
                technologyCode,
            );
        } else {
            const fileContent = await CoreUtil.readAsDataUrl(file);
            param = new SetImageParameter(
                fileContent,
                file.name,
                file.type,
                imageCategory,
                null,
                technologyCode,
            );
        }
        const result = await this.clientService.setImage(param);
        const techno = this.technologyStore.technologies?.find(
            (t) => t.TechnologyCode == technologyCode,
        );
        if (techno) {
            techno.ImageHash = result.ImageHash;
        }
        this.technologyStore.dispatchUpdate(techno);
        return true;
    }

    public getTechnologyFromCode(technoCode: string) {
        return (
            this.technologyStore.technologies?.find(
                (techno) => techno.TechnologyCode == technoCode,
            ) ?? null
        );
    }

    public async initTechnologies(): Promise<TechnologyDto[]> {
        this.technologyStore.dispatchLoading(true);
        const result = await this.technologyApiService.getTechnologies(
            new BaseServiceParameter(),
        );
        const technologies = result?.Technologies;
        technologies?.forEach(
            (techno) =>
                (techno.dgModules = techno.Modules.map((m) => DgModule[m])),
        );

        this.technologyStore.dispatchLoaded(technologies);
        return technologies;
    }

    private async initUserEntityCardDisplay() {
        const res = await this.userService.getUserSettingValue(
            userSettingsValues.display.category,
            userSettingsValues.display.routes.entityCard,
        );
        this.userEntityCardDisplay =
            EntityCardDisplayMode[res?.Value] ??
            EntityCardDisplayMode.TechnoWithChip;
    }
}
