import { Injectable } from '@angular/core';
import { IEntityIdentifier } from '@datagalaxy/dg-object-model';
import { EntityLoader } from '@datagalaxy/webclient/entity/feature';
import { TranslationApiService } from '@datagalaxy/webclient/multilingual/data-access';
import { MultilingualStateService } from '../multilingual-state.service';
import { EntityTranslationStore } from './entity-translation-store.service';
import { ProblemDetails } from '@datagalaxy/data-access';
import { ObjectTranslation } from '@datagalaxy/webclient/multilingual/domain';
import { HttpStatusCode } from '@angular/common/http';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';
import { SearchApiService } from '@datagalaxy/webclient/search/data-access';
import { CurrentUserService } from '@datagalaxy/webclient/user/data-access';

@Injectable()
export class EntityTranslationLoader {
    private multilingualReady = false;

    private get loadTranslations() {
        return this.multilingualReady;
    }

    constructor(
        private translationApiService: TranslationApiService,
        private translationStore: EntityTranslationStore,
        private currentUserService: CurrentUserService,
        multilingualStateService: MultilingualStateService,
        entityLoader: EntityLoader,
        searchApiService: SearchApiService,
    ) {
        multilingualStateService.selectEnabled().subscribe((enabled) => {
            this.multilingualReady = enabled ?? false;
        });

        entityLoader.entityLoaded$.subscribe((entity) => {
            this.loadTranslationsOnEntityLoaded(entity);
        });

        entityLoader.entitiesLoaded$.subscribe((entities) => {
            this.loadTranslationsOnEntitiesLoaded(entities);
        });

        searchApiService.searchedEntities$.subscribe((entities) => {
            this.loadTranslationsOnEntitiesLoaded(entities);
        });
    }

    private async loadTranslationsOnEntityLoaded(entity: EntityItem) {
        if (!this.loadTranslations) {
            return;
        }
        const identifier: IEntityIdentifier = {
            ReferenceId: entity.ReferenceId,
            ServerType: entity.ServerType,
            VersionId: entity.VersionId,
            entityType: entity.EntityType,
        };
        const languageCode = this.currentUserService.userInfo?.LanguageCode;
        if (!languageCode) {
            return;
        }
        try {
            const objectTranslation =
                await this.translationApiService.getObjectTranslationForLanguage(
                    identifier,
                    languageCode,
                );
            this.translationStore.upsertObjectTranslation(objectTranslation);
        } catch (error) {
            if (
                error instanceof ProblemDetails &&
                error.status === HttpStatusCode.NotFound
            ) {
                console.warn(
                    `Translation not found for entity ${identifier.ReferenceId}`,
                );
                return;
            }
            throw error;
        }
    }

    private loadTranslationsOnEntitiesLoaded(idrs: IEntityIdentifier[]) {
        if (!this.loadTranslations || idrs.length === 0) {
            return;
        }
        const languageCode = this.currentUserService.userInfo?.LanguageCode;
        if (!languageCode) {
            return;
        }

        const batchSize = 20;
        const idrsCopy = [...idrs];
        while (idrsCopy.length > 0) {
            const batch = idrsCopy.splice(0, batchSize);
            this.loadBatchTranslations(batch, languageCode);
        }
        return;
    }

    private loadBatchTranslations(
        idrs: IEntityIdentifier[],
        languageCode: string,
    ) {
        this.translationApiService
            .getObjectsTranslationsForLanguage(idrs, languageCode)
            .then(
                (objectTranslations) => {
                    this.upsertObjectTranslations(objectTranslations);
                },
                (error) => {
                    console.warn(
                        `Error while getting objects translations`,
                        error,
                    );
                },
            );
    }

    private upsertObjectTranslations(objectTranslations: ObjectTranslation[]) {
        this.translationStore.upsertObjectTranslations(objectTranslations);
    }
}
