import { CollectionsHelper } from '@datagalaxy/core-util';
import { IListOptionItem, ITranslate } from '.';
import { ICellRenderData } from './cell-components';

/*
    Important: Keep every interface member optional
*/

/** Methods to extract data of an option for its rendering in a field-select or field-multi-select */
export interface IOptionAdapter<T> extends IIdOptionAdapter<T, string> {}
/** Methods to extract data of an option for its rendering in a field-select or field-multi-select */
export interface IIdOptionAdapter<T, TId> {
    getId?(o: T): TId;

    /** Returns the text to be displayed when no translation is needed */
    getText?(o: T): string;
    /** Returns the translation key of the text to be displayed */
    getTextKey?(o: T): string;
    getClass?(o: T): string;
    getStyle?(o: T): object;
    getGlyphClass?(o: T): string;
    hasIcon?(o: T): boolean;
    getIconUrl?(o: T): string;
    getTextClass?(o: T): string;
    getTooltipText?(o: T): string;
    getTooltipTextKey?(o: T): string;

    /** Second line of text. Managed by field-select. */
    getSubText?(o: T): string;
    /** Translation key of second line of text. Managed by field-select. */
    getSubTextKey?(o: T): string;

    //#region currently only used by field-multi-select
    /** (field-multi-select only) */
    getTagColor?(o: T): string;
    /** (field-multi-select only) */
    getRenderData?(o: T): ICellRenderData;
    //#endregion

    getGroupKey?(o: T): string;
    getGroupLabelText?(groupKey: string): string;
    getGroupLabelKey?(groupKey: string): string;
    getDisabled?(o: T): boolean;
}

/**
 * Return an IListOptionItem using and IOptionAdapter
 * If adapter is not provided and the item is an object, we consider
 * it as an IListOptionItem<IListOptionItem>
 */
export function getOptionItemFromAdapter<T>(
    item: T,
    adapter: IOptionAdapter<T>,
    translate: ITranslate,
): IListOptionItem<T> {
    if (!adapter && typeof item === 'object') {
        return { ...item, data: item };
    }
    return {
        valueId: adapter?.getId?.(item),
        data: item,
        labelText: OptionAdapterUtil.getText(item, adapter, translate),
        descriptionTranslateKey: OptionAdapterUtil.getSubText(
            item,
            adapter,
            translate,
        ),
        tagColor: adapter?.getTagColor?.(item),
        style: adapter?.getStyle?.(item),
        class: adapter?.getClass?.(item),
        glyphClass: adapter?.getGlyphClass?.(item),
        iconUrl: adapter?.getIconUrl?.(item),
        tooltipText: OptionAdapterUtil.getTooltipText(item, adapter, translate),
        renderData: adapter?.getRenderData?.(item),
        disabled: adapter?.getDisabled?.(item),
    };
}

/** Bridge to use the field-select
 * (to avoid creation of multiple properties/getters/setters in the component) */
export interface IFieldSelectAdapter<T>
    extends IFieldSelectAdapterOf<T>,
        IOptionAdapter<T> {}
/** Bridge to use the field-select
 * (to avoid creation of multiple properties/getters/setters in the component) */
export interface IFieldSelectAdapterWithId<T, TId>
    extends IFieldSelectAdapterOf<T>,
        IIdOptionAdapter<T, TId> {
    /** Sets the *current* property by finding it in the *options* using the given id.
     * Note: When implemented, *getId* must also be implemented. */
    selectById?: (id: TId) => void;

    /** Returns the given option's id. */
    getId?: (o: T) => TId;
}
interface IFieldSelectAdapterOf<T> {
    /** Available options to be used by the field-select (instead of its *options* input) */
    options?: T[];

    /** when set, *options[initialIndex]* will be used as the initial selected value */
    initialIndex?: number;

    /** When provided, this is called by the field-select on selection change */
    onSelectionChange?: (selected: T) => void;

    /** When true, the field-select uses this object's *current* property as its *ngModel* */
    isModel?: boolean;

    /** Current selected value (used by the field-select as its *ngModel*).
     * Can be a getter/setter.
     * Note that *isModel* must be true for this to be used. */
    current?: T;

    /** Groups keys.
     * Note: used only when *getGroupKey* is defined and *groupKeys* is not */
    getGroupOrderIndex?(groupKey: string): number;
    /** Groups keys.
     * Note: used only when *getGroupKey* is defined and *getGroupOrderIndex* is not */
    groupKeys?: string[];
}

export function defaultSelectById<T, TId>(id: TId) {
    const self = this as IFieldSelectAdapterWithId<T, TId>;
    self.current = self.options?.find((o) => self.getId(o) === id);
}

export abstract class BaseFieldSelectAdapter<T>
    implements IFieldSelectAdapter<T>
{
    public readonly isModel = true;
    public current: T;

    public options: T[] = [];

    constructor(public onSelectionChange?: (selected: T) => void) {}

    public abstract getText(o: T): string;

    protected initInternal(options: T[], current?: T) {
        this.options = options?.slice() ?? [];
        this.current = current;
        return this;
    }

    protected selectByIdInternal<TId>(id: TId) {
        const self = this as IFieldSelectAdapterWithId<T, TId>;
        self.current = self.options?.find((o) => self.getId(o) === id);
    }
}

/** Implementation of a *IFieldSelectAdapter* for an enum */
export class EnumNumberIdFieldSelectAdapter<T extends number, TId>
    implements IFieldSelectAdapterWithId<T, TId>
{
    public readonly options: T[];
    public readonly isModel = true;
    public current: T;

    /** Returns a string representation of the currently selected enum value */
    public get currentAsString() {
        return this.enumObj[this.current];
    }
    /** Sets the currently selected enum value from the given string representation of an enum value */
    public set currentAsString(value: string) {
        this.current = this.enumObj[value];
    }

    public get currentId() {
        return this.asOptionAdapter.getId?.(this.current);
    }

    private get asOptionAdapter() {
        return this as IIdOptionAdapter<T, TId>;
    }

    constructor(
        private enumObj: { [key: number]: string },
        opt?: IIdOptionAdapter<T, TId> & {
            onSelectionChange?: (selected: T) => void;
            initialValue?: T;
            excludedValues?: T[];
            orderedValues?: T[];
        },
    ) {
        const values = CollectionsHelper.getEnumValues(
            enumObj,
            ...(opt?.excludedValues ?? []),
        );
        this.options = opt?.orderedValues
            ? CollectionsHelper.orderBy(values, (v) =>
                  opt.orderedValues.indexOf(v),
              )
            : values;
        if (!opt) {
            return;
        }
        if (opt.initialValue != undefined) {
            this.current = opt.initialValue;
        }
        const reservedKeys = [
            'options',
            'isModel',
            'current',
            'currentAsString',
            'initialValue',
            'excludedValues',
            'orderedValues',
            'selectById', //'getId',
        ];
        Object.keys(opt)
            .filter((k) => !reservedKeys.includes(k))
            .forEach((k) => (this[k] = opt[k]));
    }
}
/** Implementation of a *IFieldSelectAdapter* for an enum */
export class EnumNumberFieldSelectAdapter<
    T extends number,
> extends EnumNumberIdFieldSelectAdapter<T, string> {
    public getId(u: number) {
        return String(u);
    }
}

export class OptionAdapterUtil {
    public static getText<T>(
        option: T,
        adapter: IOptionAdapter<T>,
        translate: ITranslate,
    ) {
        if (option == undefined) {
            return '';
        }

        if (!adapter || (!adapter.getText && !adapter.getTextKey)) {
            return option.toString?.();
        }

        const text = adapter.getText?.(option);
        if (text) {
            return text;
        }

        const key = adapter.getTextKey?.(option);
        if (key) {
            return translate.instant(key);
        }

        return '';
    }

    public static getSubText<T>(
        option: T,
        adapter: IOptionAdapter<T>,
        translate: ITranslate,
    ) {
        if (option == undefined || !adapter) {
            return '';
        }

        const text = adapter.getSubText?.(option);
        if (text) {
            return text;
        }

        const key = adapter.getSubTextKey?.(option);
        if (key) {
            return translate.instant(key);
        }

        return '';
    }

    public static getTooltipText<T>(
        option: T,
        adapter: IOptionAdapter<T>,
        translate: ITranslate,
    ) {
        if (option == undefined || !adapter) {
            return '';
        }

        const text = adapter.getTooltipText?.(option);
        if (text) {
            return text;
        }

        const key = adapter.getTooltipTextKey?.(option);
        if (key) {
            return translate.instant(key);
        }

        return '';
    }
}
