import { RichTextContent } from '@datagalaxy/core-ui/rich-text';
import { Observable, Subject, Subscription } from 'rxjs';
import { AttributeDataService } from '../attribute-data.service';
import { AttributeFieldInfo, IObjectDataTags } from '../attribute.types';
import { IAttributeBaseInputOverride } from './IAttributeBaseInputOverride';
import { IEntityLinksChangeInfo } from '../../entity/services/entity.service';
import { IBaseErrorData } from '../../util/app-types/errors.types';
import { UnitaryFieldValidator } from '../../fields/unitary/UnitaryFieldValidator';
import { IUnitaryFieldData } from '../../fields/unitary/IUnitaryFieldData';
import { IUnitaryFieldActionsInput } from '../../fields/unitary/IUnitaryFieldActionsInput';
import { EntityFormKind } from '../../entity/interfaces/entity-form.interface';
import { BaseServiceResult } from '@datagalaxy/data-access';
import { ISuggestionGroup } from '../../../suggestions/suggestion.types';
import { UpdateEntityAttributeResult } from '@datagalaxy/webclient/entity/data-access';
import { IUpdatedAttributeData } from '@datagalaxy/webclient/entity/utils';
import {
    AttributeMetaInfo,
    TextQualityVoteStatus,
} from '@datagalaxy/webclient/attribute/domain';

/** Core code for both AttributeBaseInput and DxyAttributeBaseInput */
export class AttributeBaseInputCore<T> implements IUnitaryFieldData {
    //#region static
    /** event shared between all AttributeBaseInput's for to have only one active input */
    private static readonly onEntityEdit = new Subject<
        AttributeBaseInputCore<any>
    >();

    /** provides validation/save/undo */
    private static readonly validator = new UnitaryFieldValidator<
        AttributeBaseInputCore<any>
    >({
        onBeforeValidate: async (self) => self.host.onBeforeValidate(),
        serverUpdate: async (self) =>
            self.host.onAttributeValueChange(self.attributeMeta),
        onServerSuccess: async (self, result) =>
            self.host.onServerSuccess(result),
        hasExternalError: (self) => !!self.externalError,
        setError: (self, errorMessage, isServerError, result) =>
            self.setError(errorMessage, isServerError, result),
        clearError: (self) => self.clearError(),
        log: (self, ...args: any) => self.log?.(...args),
        resetMessages: (self) => self.resetMessages(),
        resetToInitialState: (self) => self.resetToInitialState(),
        onActiveChange: (self) => self.activated.next(),
        onActivated: (self) => AttributeBaseInputCore.onEntityEdit.next(self),
        emitNativeChangeEvent: (self) => self.host.emitNativeChangeEvent(),
        isValid: (self) => self.isValid(),
        onAfterValidate: (self, validated) =>
            self.host.onAfterValidate(validated),
        onAfterUndo: (self) => self.host.onAfterUndo(),
        hasUnitaryValidation: (self) =>
            self.entityForm.isFieldByFieldValidationNeeded,
        disableUndoBtn: (self) => self.entityForm.disableUndoBtn,
        recentlyModifiedExternally: (self) =>
            self.attributeMeta?.hasBeenModifiedExternallyRecently,
        canRestorePreviousValue: (self) => !!self.versionCompareRole,
        restorePreviousValue: (self) => self.restorePreviousValue(),
        disableValidationOnBlur: (self) =>
            self.entityForm?.disableValidationOnBlur,
        canValidateOnBlur: (self, event) => self.host.canValidateOnBlur(event),
    });

    //#endregion  - static

    //#region for component bindings
    public fieldInfo: AttributeFieldInfo;
    //#endregion - for component bindings

    //#region for html bindings
    public suggestionGroup$: Observable<ISuggestionGroup>;
    public readonly actionsInput: IUnitaryFieldActionsInput;
    public get showSuggestions() {
        return (
            this.host.isEditEnabled && !this.entityForm.isSuggestionsDisabled
        );
    }
    public get showTextQualityScore() {
        return (
            !this.entityForm.isSuggestionsDisabled &&
            !!this.textQualityUserVote &&
            this.textQualityUserVote.CurrentTextHashcode !== -1
        );
    }
    //overriden
    public get textQualityUserVote() {
        return this.entityForm.getTextQualityUserVote?.(this.attributePath);
    }
    public get isLabelDisplayed() {
        return this.entityForm.isLabelDisplayed;
    }
    //#endregion - for html bindings

    //#region for protected, not overriden
    public get hasInternalError() {
        return !!this.internalError;
    }
    public get attributeMeta() {
        return this.fieldInfo.attributeMeta;
    }
    public get attributeKey() {
        return this.attributeMeta.AttributeKey;
    }
    public get attributePath() {
        return this.attributeMeta?.AttributePath;
    }
    public get attributeType() {
        return this.attributeMeta.AttributeType;
    }
    public get attributeListValues() {
        return this.attributeMeta.ListValues;
    }
    public get isAttributeReadOnly() {
        return this.attributeMeta.IsReadOnly;
    }
    public get isAttributeMandatory() {
        return this.attributeMeta.IsMandatory;
    }
    public get isAttributeCDP() {
        return this.attributeMeta.IsCdp;
    }
    public get isAttributeMultiValue() {
        return this.attributeMeta.IsMultiValue;
    }
    public get translatedDisplayName() {
        return this.attributeMeta.translatedDisplayName;
    }
    public get entityForm() {
        return this.fieldInfo?.entityForm;
    }
    public get hasLoadReferenceOptions() {
        return !!this.entityForm?.loadReferenceOptions;
    }
    public get formKind() {
        return this.entityForm?.kind;
    }
    /** This is the standard entity form used in the entity-details component */
    public get isGenericEntityForm() {
        return !this.formKind;
    }
    public get isBulkForm() {
        return this.formKind == EntityFormKind.bulk;
    }
    //#endregion - for protected, not overriden

    public get mandatory() {
        return this.isAttributeMandatory && !!this.entityForm?.asterisk;
    }
    public get hint() {
        return this.infoMessage && !this.hasInternalError && !this.externalError
            ? this.infoMessage
            : '';
    }
    public get errorMessage() {
        return this.internalError || this.externalError;
    }

    //#region IUnitaryFieldData
    /** IMPORTANT: For AttributeMultiValue Attribute Types, initialValue and data are ALWAYS expressed as string[]
     * In order to obtain the objectValue, one must use getObjectData */
    public initialValue: any;
    public initialValueSet = false;
    public isBluring = false;
    public isValidated: boolean;
    public isValidating: boolean;
    public isErrorInValidating = false;
    public isActive = false;
    public get fieldKey() {
        return this.attributeKey;
    }
    public get isServerError() {
        return this._isServerError;
    }
    public isDirty() {
        return this.host.isDirty();
    }
    //#endregion - IUnitaryFieldData
    private internalError: string;
    private _isServerError: boolean;
    private infoMessage: string;
    private formEntityUpdatedSubscription: Subscription;
    private initDone = false;
    private activated = new Subject<void>();
    private versionCompareRole: string;
    private get attributeRoleValue() {
        return this.attributeMeta?.roleValue;
    }
    private get isCompareEntityForm() {
        return this.formKind == EntityFormKind.compare;
    }

    private get externalError() {
        return (
            this._externalError ??
            this.fieldInfo?.entityForm.getExternalError?.(this.attributeKey)
        );
    }
    private _externalError: string;

    constructor(
        private host: IAttributeBaseInputOverride,
        private subscribe: <T>(
            eventSubject: Observable<T>,
            action: (eventData?: T) => void,
        ) => Subscription,
        private setLogId: (logId: string) => void,
        private debug: boolean,
        private log: (...args: any[]) => void,
    ) {
        this.actionsInput =
            AttributeBaseInputCore.validator.buildActionsInput(this);
    }

    public init() {
        this.setLogId(this.attributePath);
        this.debug &&
            this.log('init', {
                fieldInfo: this.fieldInfo,
                attributeMeta: this.attributeMeta,
                entityForm: this.entityForm,
            });
        this.suggestionGroup$ = this.entityForm.getSuggestionGroup$?.(
            this.attributeKey,
        );
        this.versionCompareRole =
            this.attributeRoleValue == 'Source' ? 'source' : undefined;
        this.formEntityUpdatedSubscription?.unsubscribe();
        this.formEntityUpdatedSubscription = this.subscribe(
            this.entityForm.formEntityUpdated$,
            (updateData: IUpdatedAttributeData) =>
                this.onEntityUpdated(updateData),
        );
        !this.initDone &&
            this.subscribe(AttributeBaseInputCore.onEntityEdit, (sourceItem) =>
                this.onEntityEdit(sourceItem).then(),
            );
        this.initDone = true;
    }

    //#region default methods for IAttributeBaseInputOverride

    public get defaultIsEditEnabled() {
        return (
            !this.isAttributeReadOnly &&
            !!this.entityForm?.isEditEnabled &&
            (typeof this.entityForm.isAttributeEditEnabled != 'function' ||
                this.entityForm.isAttributeEditEnabled(this.attributeMeta))
        );
    }

    /** called either:
     * - on click on the attribute-content
     * - on focus of the attribute's dxy-field */
    public defaultSetAttributeActive() {
        this.setActive(true);
    }

    public defaultIsDirty() {
        return (
            this.host.isEditEnabled &&
            !this.host.equals(this.initialValue, this.getData())
        );
    }

    public defaultEquals(v1: any, v2: any) {
        return v1 == v2;
    }

    /** return true if not active, and data is undefined or empty if it's an array. */
    public defaultIsEmpty() {
        if (this.isActive) {
            return false;
        }
        const data = this.getData() as any;
        // data.length being undefined doesnt mean that the field is empty !
        // data equals 0 doesnt mean that the field is empty
        if (data != 0 && !data) {
            return true;
        }
        if (data?.length == 0) {
            return true;
        }
        return false;
    }

    public async defaultOnAttributeValueChange(
        attributeMeta: AttributeMetaInfo,
        linkChangeInfo?: IEntityLinksChangeInfo,
    ): Promise<UpdateEntityAttributeResult | BaseServiceResult> {
        if (this.entityForm.onChangeAttributeValue) {
            return await this.entityForm.onChangeAttributeValue(
                attributeMeta.AttributeKey,
                linkChangeInfo,
            );
        }
    }

    public defaultCanValidateOnBlur(_event: Event) {
        return !this.host.isMandatoryAttributeEmpty();
    }

    //#endregion - default methods for IAttributeBaseInputOverride

    /** Sets the internal error state and message */
    public setInternalError(message: string) {
        this.internalError = message || '(error)';
        this._isServerError = false;
    }
    /** Clears the internal error state and message */
    public clearInternalError() {
        this.internalError = undefined;
        this._isServerError = false;
    }

    public getEntityData() {
        return this.entityForm.getEntityData?.();
    }
    public getEntityDataList() {
        return this.entityForm.getEntityDataList?.();
    }
    public setFormValid(valid: boolean) {
        this.entityForm.setFormValid?.(valid, this.host.isCheckControl);
    }
    public getAttributeValue<T>(attributeKey: string) {
        return this.entityForm.getAttributeValue?.(attributeKey) as T;
    }
    public async setAttributeValue(value: T, attributeKey = this.attributeKey) {
        await this.entityForm.setAttributeValue?.(attributeKey, value);
    }
    public setCurrentAttributeKey(attributeKey: string) {
        this.entityForm.setCurrentAttributeKey?.(attributeKey);
    }
    public getSpaceIdr() {
        return this.entityForm.getSpaceIdr?.();
    }

    public getDescription() {
        const description = this.attributeMeta.Description;
        return description
            ? RichTextContent.getRawText(description)
            : undefined;
    }

    public async onEnterKeyDown() {
        this.log('onEnterKeyDown');
        await this.validate().then();
        this.host.blurField();
    }

    public resetInitialValue() {
        this.initialValue = undefined;
        this.initialValueSet = false;
    }

    public resetMessages() {
        this.clearInternalError();
        this.infoMessage = '';
    }

    public getInitialValue() {
        return this.initialValue;
    }
    public setInitialValue(value: T, isForced = false) {
        if (!this.initialValueSet || isForced) {
            this.log('setInitialValue', value);
            this.initialValue = Array.isArray(value) ? value.slice() : value;
            this.initialValueSet = true;
        }
    }

    public async setData(newValue: T) {
        if (!this.host.isValid(newValue, true)) {
            return;
        }
        await this.entityForm?.setAttributeValue?.(this.attributeKey, newValue);
    }

    public getData() {
        let value: T = null;
        if (this.entityForm) {
            if (this.isCompareEntityForm) {
                value = this.entityForm.getAttributeValueForVersionCompare(
                    this.attributeKey,
                    this.attributeRoleValue,
                    false,
                ) as unknown as T;
            } else if (!this.entityForm.getAttributeValue) {
                // optional entityForm.getAttributeValue is not implemented
                return;
            } else {
                value = this.entityForm.getAttributeValue(this.attributeKey);
            }
            this.setInitialValue(value);
        }
        return value;
    }
    public getObjectData() {
        let value: string | IObjectDataTags[];
        let valueIsObjectValues = false;
        if (this.isCompareEntityForm) {
            value = this.entityForm.getAttributeValueForVersionCompare(
                this.attributeKey,
                this.attributeRoleValue,
                true,
            );
        } else if (!this.entityForm.getAttributeValue) {
            // optional method is not implemented
            return;
        } else if (
            AttributeDataService.isTagOrUserOrPersonAttribute(
                this.attributeType,
            )
        ) {
            value = this.entityForm.getAttributeValue(
                `${this.attributeKey}ObjectValues`,
            ) as unknown as IObjectDataTags[];
            valueIsObjectValues = true;
        }

        if (!this.initialValueSet) {
            this.initialValue = valueIsObjectValues
                ? (value as IObjectDataTags[])?.map((v) => v.ObjectId)
                : (value as string);
            this.initialValueSet = true;
        }

        return value;
    }

    public async loadReferenceOptions() {
        return await this.entityForm.loadReferenceOptions?.(this.attributeMeta);
    }
    public onTextQualityUserVote(event: TextQualityVoteStatus) {
        this.entityForm.setTextQualityUserVote?.(this.attributePath, event);
    }

    public async onFieldFocus(_event: FocusEvent) {
        await this.host.setAttributeActive();
    }
    public async onFieldBlur(event?: Event) {
        await AttributeBaseInputCore.validator.onFieldBlur(this, event);
    }
    public async validate(): Promise<void> {
        await AttributeBaseInputCore.validator.validate(this);
    }
    public undo() {
        AttributeBaseInputCore.validator.undo(this);
    }

    private onEntityUpdated(updateData: IUpdatedAttributeData) {
        // This attribute has been modified externally
        if (updateData.attribute.AttributeKey == this.attributeKey) {
            this.initialValue = updateData.newValue;
        }
    }
    private async onEntityEdit(sourceItem: AttributeBaseInputCore<T>) {
        if (!this.isActive || sourceItem == this) {
            return;
        }
        this.setActive(false);
        await this.validate();
    }

    private setActive(active: boolean) {
        active && this.host.onBeforeSetActive();
        this.log('setActive', active, this.host.isEditEnabled);
        if (!this.host.isEditEnabled) {
            return;
        }
        AttributeBaseInputCore.validator.setActive(this, active);
    }

    //#region called by validator

    private resetToInitialState() {
        const data = Array.isArray(this.initialValue)
            ? this.initialValue.slice()
            : this.initialValue;
        this.setData(data);
        if (this.isCompareEntityForm) {
            this.entityForm.undo(this.attributeMeta);
        }
    }

    private restorePreviousValue() {
        if (this.isCompareEntityForm) {
            this.host.onBeforeRestorePreviousValueForCompareEntityForm();
            this.entityForm.restorePreviousValue(this.attributeMeta);
        }
    }

    private setError(
        errorMessage: string,
        isServerError: boolean,
        result?: BaseServiceResult | IBaseErrorData,
    ) {
        this._isServerError = isServerError;
        if (isServerError) {
            this.setInternalError(errorMessage);
            this.host.onServerError(result);
        } else {
            this._externalError = errorMessage;
        }
    }
    private clearError() {
        this.clearInternalError();
        this._externalError = null;
    }

    private isValid() {
        const valid = this.host.isValid(this.getData(), false);
        if (!valid) {
            // Keep the error message if it has been set by the sub class,
            // else set it to empty string (which is currently a valid error message)
            this.internalError && this.setInternalError(this.internalError);
        }
        return valid;
    }

    //#endregion  - called by validator
}
