import * as moment from 'moment';
import {
    AfterViewChecked,
    ChangeDetectorRef,
    Component,
    Inject,
    OnInit,
} from '@angular/core';
import {
    MAT_DIALOG_DATA,
    MatDialogModule,
    MatDialogRef,
} from '@angular/material/dialog';
import { DxyBaseModalComponent } from '@datagalaxy/ui/dialog';
import {
    DxyRichTextFieldComponent,
    IMentionResolver,
    RichTextContent,
} from '@datagalaxy/core-ui/rich-text';
import {
    INewTaskModalResult,
    ITaskFormModalInput,
} from '../new-task-modal.types';
import {
    EnumNumberFieldSelectAdapter,
    IFieldSelectAdapter,
} from '@datagalaxy/core-ui';
import { CoreUtil, DateTimeUtil } from '@datagalaxy/core-util';
import {
    DxyFieldDateComponent,
    DxyFieldSelectComponent,
    IsoStringMomentAdapter,
} from '@datagalaxy/core-ui/fields';
import {
    EntityTaskDTO,
    ObjectTaskStatus,
    ObjectTaskType,
} from '@datagalaxy/dg-object-model';
import { UserFieldSelectAdapter } from '@datagalaxy/webclient/user/ui';
import { TaskService } from '../task.service';
import { RichTextMentionService } from '../../shared/richText/mentions/rich-text-mention.service';
import { getLocalId } from '@datagalaxy/utils';
import { ObjectSecurityService } from '../../services/object-security.service';
import { UserPublicData } from '@datagalaxy/webclient/user/domain';
import {
    EntityItem,
    IMiniEntityContent,
} from '@datagalaxy/webclient/entity/domain';
import { EntityFunctionalLogService } from '@datagalaxy/webclient/entity/data-access';
import { DxyModalFooterComponent } from '../../shared/dialogs/dxy-modal-footer/dxy-modal-footer.component';
import { NgIf } from '@angular/common';
import { DxyFieldTextComponent } from '@datagalaxy/ui/fields';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { DxyIconButtonDirective } from '@datagalaxy/ui/buttons';
import { DataUtil } from '../../shared/util/DataUtil';

@Component({
    selector: 'dxy-new-task-modal',
    templateUrl: './dxy-task-form-modal.component.html',
    standalone: true,
    imports: [
        MatDialogModule,
        TranslateModule,
        FormsModule,
        DxyFieldTextComponent,
        NgIf,
        DxyFieldSelectComponent,
        DxyFieldDateComponent,
        DxyRichTextFieldComponent,
        DxyModalFooterComponent,
        DxyIconButtonDirective,
    ],
})
export class DxyTaskFormModalComponent
    extends DxyBaseModalComponent<ITaskFormModalInput, INewTaskModalResult>
    implements OnInit, AfterViewChecked
{
    public readonly mentionResolvers: IMentionResolver[];

    public taskStatus: ObjectTaskStatus;
    public readonly taskDueTime = new IsoStringMomentAdapter();
    public readonly taskAssignee = new UserFieldSelectAdapter(
        (u) => (this.taskCopy.AssignedToUserId = u.UserId),
    );
    public readonly taskType = new EnumNumberFieldSelectAdapter(
        ObjectTaskType,
        {
            initialValue: ObjectTaskType.None,
            excludedValues: TaskService.obsoleteObjectTaskTypeValues,
            orderedValues: TaskService.orderedObjectTaskTypeValues,
            getTextKey: (o) =>
                `DgServerTypes.ObjectTaskType.${ObjectTaskType[o]}`,
            onSelectionChange: (selected) => (this.taskCopy.Type = selected),
        },
    );
    public readonly taskStatusSelectAdapter: IFieldSelectAdapter<ObjectTaskStatus> =
        {
            isModel: true,
            getTextKey: (v) =>
                `DgServerTypes.ObjectTaskStatus.${ObjectTaskStatus[v]}`,
            onSelectionChange: (v) => (this.taskCopy.Status = v),
        };

    // seems not used anymore
    public isFormCollapsed = true;

    //#region for footer
    public canCreate = false;
    public isCheckedCreateAnother = false;
    public isLoading = false;

    public get featureCode() {
        return EntityFunctionalLogService.getFeatureCode(
            this.entityData,
            'SOCIAL_TASK',
            'C',
        );
    }

    //#endregion

    public get taskTitle() {
        return this.taskCopy.Title;
    }

    public set taskTitle(value: string) {
        this.taskCopy.Title = value;
    }

    public get taskText() {
        return this.taskCopy.Text;
    }

    public set taskText(value: string) {
        this.taskCopy.Text = value;
    }

    public get modalTitleKey() {
        return this.savedTask
            ? 'UI.TaskContainer.lblUpdate'
            : 'UI.TaskContainer.lblAdd';
    }

    public get taskTitleErrorKey() {
        return this.taskTitle ? '' : 'UI.TaskContainer.lblErrorRequiredTitle';
    }

    public get taskTextErrorKey() {
        return this.isEmptyRawText
            ? 'UI.TaskContainer.lblErrorRequiredDescription'
            : '';
    }

    public get actionBtnLabel() {
        return this.isCreation
            ? 'UI.Dialog.NewItem.lblCreate'
            : 'UI.Dialog.NewItem.lblSave';
    }

    public get isEditing() {
        return !!this.savedTask;
    }

    public get isCreation() {
        return !this.savedTask;
    }

    public get savedTask() {
        return 'task' in this.data ? this.data.task : null;
    }

    public get taskAssigneeReady() {
        return !!this.taskAssignee?.options?.length;
    }

    public get isResponseVisible() {
        return this.taskService.isResponseVisible(this.taskCopy.Status);
    }

    public get actionEnabled() {
        return this.isCreation
            ? this.canCreate
            : this.isSubmitUpdateTaskEnabled;
    }

    public get isTitleActive() {
        return (
            this.isCreation ||
            this.taskService.isTitleEditEnabled(
                this.taskStatus,
                this.savedTask,
                this.entityData,
            )
        );
    }

    public get isTextActive() {
        return (
            this.isCreation ||
            this.taskService.isTextEditEnabled(
                this.taskStatus,
                this.savedTask,
                this.entityData,
            )
        );
    }

    public get isCommonFieldActive() {
        return (
            this.isCreation ||
            this.taskService.isCommonFieldEditEnabled(
                this.taskCopy,
                this.entityData,
            )
        );
    }

    public get isStatusReadonly() {
        return (
            !this.isCommonFieldActive ||
            this.savedTask.Status == ObjectTaskStatus.Executed
        );
    }

    public get isResponseActive() {
        return (
            this.taskService.isResponseVisible(this.savedTask.Status) &&
            this.taskService.isCommonFieldEditEnabled(
                this.taskCopy,
                this.entityData,
            )
        );
    }

    private get isEmptyRawText() {
        return RichTextContent.isEmptyRawText(this.taskText);
    }

    public get isSubmitUpdateTaskEnabled() {
        if (this.isSaving) {
            return true;
        }
        const isPristine = (k: string) =>
            this.getTaskPropEqualityTester(k)(
                this.savedTask[k],
                this.taskCopy[k],
            );
        return (
            (this.isResponseActive && !isPristine('Response')) ||
            !this.taskPropKeys.filter((k) => k != 'Response').every(isPristine)
        );
    }

    /** the task property keys, for modification detection. see getTaskPropEqualityTester */
    private readonly taskPropKeys = [
        'Title',
        'Text',
        'Status',
        'AssignedToUserId',
        'Type',
        'DueTime',
        'Response',
    ];
    private entityData: IMiniEntityContent;
    private createdTasks: EntityTaskDTO[] = [];
    private isSaving = false;
    private taskAssigneeDefaultUserId: string;

    /* this is a copy of the working task, used for testing the changes made to the currently edited working task.
     * see captureTaskValues/resetFromCopy */
    public taskCopy = new EntityTaskDTO();

    constructor(
        private taskService: TaskService,
        private objectSecurityService: ObjectSecurityService,
        private changeDetector: ChangeDetectorRef,
        richTextMentionService: RichTextMentionService,
        dialogRef: MatDialogRef<DxyTaskFormModalComponent, INewTaskModalResult>,
        @Inject(MAT_DIALOG_DATA) data: ITaskFormModalInput,
    ) {
        super(dialogRef, data);
        const module = DataUtil.getModuleFromServerType(
            this.data.entityData.ServerType,
        );
        this.mentionResolvers =
            richTextMentionService.getDefaultMentionResolvers(
                this.data.entityData.ReferenceId,
                this.data.entityData.VersionId,
                module,
            );
    }

    ngOnInit() {
        this.init().then();
    }

    ngAfterViewChecked() {
        this.preventNg0100Error(this.changeDetector);
    }

    public updateCanCreate() {
        this.canCreate =
            !this.isSaving &&
            !!(this.entityData && !this.isEmptyRawText && this.taskTitle);
    }

    public onChangeCreateAnother() {
        this.isCheckedCreateAnother = !this.isCheckedCreateAnother;
        this.updateCanCreate();
    }

    public onCloseSubmit() {
        if (this.savedTask) {
            this.update().then();
        } else {
            this.create().then();
        }
    }

    public async onCloseCancel() {
        if (!this.isCreation) {
            const isModified = this.taskPropKeys.some(
                (k) =>
                    !this.getTaskPropEqualityTester(k)(
                        this.savedTask[k],
                        this.taskCopy[k],
                    ),
            );
            if (isModified) {
                const confirmed = await this.taskService.confirmCancelEdit();
                if (!confirmed) {
                    return;
                }
            }
        }
        this.closeSubmitOrCancel();
    }

    public onDateChange() {
        this.taskCopy.DueTime = this.taskDueTime.isoString;
    }

    private async init() {
        const module = DataUtil.getModuleFromServerType(
            this.data.entityData.ServerType,
        );
        this.entityData = this.data.entityData;
        const users = await this.objectSecurityService.getObjectUsersAccess(
            this.entityData.ReferenceId,
            this.entityData.VersionId,
            module,
        );
        this.taskAssigneeDefaultUserId = this.getDefaultAssigneeUserId(users);
        this.taskAssignee.init(users, this.taskAssigneeDefaultUserId);
        this.taskStatusSelectAdapter.options =
            this.taskService.getAvailableStatusValues();

        if (this.isEditing) {
            const taskCopy = (this.taskCopy = CoreUtil.clone(this.savedTask));
            this.initTask(taskCopy);
        }
    }

    private initTask(task: EntityTaskDTO) {
        this.taskAssignee.current = this.taskAssignee.options.find(
            (assignee) => assignee.UserId === task.AssignedToUserId,
        );
        this.taskType.current = task.Type;
        this.taskStatusSelectAdapter.current = task.Status;
        this.taskDueTime.isoString = moment.utc(task.DueTime)?.toISOString();
    }

    private closeSubmitOrCancel() {
        this.result = { createdTasks: this.createdTasks };
        super.onCloseSubmit();
    }

    private async update() {
        if (!this.entityData) {
            return;
        }

        this.isSaving = this.isLoading = true;
        const task = await this.taskService.updateTask(
            this.entityData,
            this.taskCopy,
        );
        if (task) {
            this.createdTasks = [task];
        }
        this.isSaving = this.isLoading = false;
        this.closeSubmitOrCancel();
    }

    private async create() {
        if (!this.entityData) {
            return;
        }

        let task: EntityTaskDTO;
        this.isSaving = this.isLoading = true;
        try {
            task = await this.taskService.createTask(
                this.entityData,
                this.taskTitle,
                this.taskText,
                this.taskAssignee.current?.UserId,
                this.taskType.current,
                this.taskDueTime.isoString,
            );
            this.resetNewTaskValues();
        } finally {
            if (!this.data.targetUserId && this.entityData.SocialData) {
                this.entityData.SocialData.TotalTaskCount++;
            }
            this.isSaving = false;
        }

        if (task) {
            this.createdTasks.push(task);
        }
        this.updateCanCreate();

        if (this.isCheckedCreateAnother) {
            setTimeout(() => {
                this.isLoading = false;
                this.changeDetector.detectChanges();
            }, 500);
        } else {
            this.closeSubmitOrCancel();
        }
    }

    private resetNewTaskValues() {
        this.taskTitle = undefined;
        this.taskText = undefined;
        this.taskAssignee.selectById(this.taskAssigneeDefaultUserId);
        this.taskType.current = ObjectTaskType.None;
        this.taskDueTime.isoString = undefined;
    }

    private getDefaultAssigneeUserId(users: UserPublicData[]) {
        const targetUserId = this.data.targetUserId;
        if (targetUserId) {
            return targetUserId;
        }

        if (!(this.entityData instanceof EntityItem)) {
            return this.taskService.getDefaultNewTaskAssigneeUserId();
        }

        // If entity has any owner
        const ownersIds: string[] = this.entityData.getDataOwners();
        if (ownersIds?.length) {
            // Get first selectable owner
            const selectableOwnerId = ownersIds.find((ownerId) =>
                users.some((user) => user.ReferenceId == ownerId),
            );
            if (selectableOwnerId) {
                return getLocalId(selectableOwnerId);
            }
        }

        // if no owner is selectable (all are readers) : return task creator if s/he is selectable
        const creatorId = this.taskService.getDefaultNewTaskAssigneeUserId();
        const isCreatorSelectable = users.some(
            (user) => user.UserId == creatorId,
        );
        if (isCreatorSelectable) {
            return creatorId;
        }

        // otherwise return first selectable user
        return users[0]?.UserId;
    }

    /** returns an accessor that tests the equality of 2 task instances property values */
    private getTaskPropEqualityTester(taskPropKey: string) {
        switch (taskPropKey) {
            case 'DueTime':
                return DateTimeUtil.isoStringsEqual;
            default:
                return (a: unknown, b: unknown) => a === b;
        }
    }
}
