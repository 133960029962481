import { AfterViewInit, Component, HostBinding, OnInit } from '@angular/core';
import { AppConfigService } from '@datagalaxy/webclient/config';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { removeAllIncomingAriaOwnsAttributes } from './aria-owns-remover';
import { AppVersionUpdateService } from '@datagalaxy/webclient/app/feature';
import {
    BottomBannerComponent,
    BottomBannerService,
} from '@datagalaxy/ui/banner-notification';
import { Title } from '@angular/platform-browser';
import { RouterOutlet } from '@angular/router';
import { AnalyticService } from './services/analytic.service';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
    standalone: true,
    imports: [BottomBannerComponent, RouterOutlet],
})
export class AppComponent
    extends DxyBaseComponent
    implements OnInit, AfterViewInit
{
    @HostBinding('class') theme = 'dxy-light';

    // False for now, bind it to user's preferences in the future
    private darkThemeActive = false;

    constructor(
        private appConfigService: AppConfigService,
        private bottomBannerService: BottomBannerService,
        private appVersionUpdateService: AppVersionUpdateService,
        private title: Title,
        analyticsService: AnalyticService,
    ) {
        super();
        appVersionUpdateService.startAppVersionChangeDetection();
        analyticsService.monitorPageChanges();
    }

    ngOnInit() {
        this.subscribeEvents();

        this.title.setTitle(this.appConfigService.getWindowTitle());

        removeAllIncomingAriaOwnsAttributes();

        this.initMobileWarningBanner();
        this.setupTheme();
    }

    ngAfterViewInit() {
        this.fadeOutSplashScreen();
    }

    private setupTheme() {
        if (this.darkThemeActive) {
            document.body.classList.add('dxy-dark-theme');
        }
    }

    private subscribeEvents() {
        super.subscribe(this.appVersionUpdateService.appVersionChange$, () => {
            this.bottomBannerService.show(
                'UI.Banner.appVersionChange.title',
                'UI.Banner.appVersionChange.text',
                {
                    imageSrc: '/images/banner-image-upversion.svg',
                },
            );
        });
    }

    private initMobileWarningBanner() {
        if (window.matchMedia('(max-width: 600px)').matches) {
            this.bottomBannerService.show(
                'UI.Banner.mobileNotSupported.title',
                'UI.Banner.mobileNotSupported.text',
            );
        }
    }

    private fadeOutSplashScreen() {
        const splash = document.querySelector<HTMLElement>('#boot-splash');
        if (splash) {
            splash.style.opacity = '0';
            splash.style.pointerEvents = 'none';
            setTimeout(() => {
                splash.remove();
            }, 200);
        }
    }
}
