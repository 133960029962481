import { Component } from '@angular/core';
import { DxyBaseModalComponent } from '@datagalaxy/ui/dialog';
import { MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { IForeignKeyDeleteModalResult } from '../pkfk.types';
import { DxyLogFunctionalDirective } from '../../../directives/dxy-log-functional.directive';
import { FormsModule } from '@angular/forms';
import { MatLegacyCheckboxModule } from '@angular/material/legacy-checkbox';
import {
    DxyButtonDirective,
    DxyIconButtonDirective,
} from '@datagalaxy/ui/buttons';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'dxy-foreign-key-delete-modal',
    templateUrl: './dxy-foreign-key-delete-modal.component.html',
    standalone: true,
    imports: [
        MatDialogModule,
        TranslateModule,
        DxyIconButtonDirective,
        DxyButtonDirective,
        MatLegacyCheckboxModule,
        FormsModule,
        DxyLogFunctionalDirective,
    ],
})
export class DxyForeignKeyDeleteModalComponent extends DxyBaseModalComponent<
    void,
    IForeignKeyDeleteModalResult
> {
    public isKeepFunctionalForeignKey: boolean;

    constructor(
        dialogRef: MatDialogRef<
            DxyForeignKeyDeleteModalComponent,
            IForeignKeyDeleteModalResult
        >,
    ) {
        super(dialogRef);
    }

    public onCloseSubmit() {
        this.result = {
            isKeepFunctionalForeignKey: this.isKeepFunctionalForeignKey,
        };
        super.onCloseSubmit();
    }
}
