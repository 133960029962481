import { autoserializeAs, deserialize } from 'cerialize';

export class BaseServiceParameter {
    @autoserializeAs(String) UserSessionId!: string;
    @autoserializeAs(String) VersionId?: string | null;
    @autoserializeAs(String) RealTimeConnectionId!: string;
    @autoserializeAs(String) SequenceNumber!: number;
    @autoserializeAs(String) LastReceivedSequenceNumber!: number;
    @autoserializeAs(String) PreviousRequestStartTimeString!: string;
    @autoserializeAs(String) RequestStartTimeString!: string;
    @autoserializeAs(String) Xid!: string;

    /** true to tell apiService.apiGeneric not to set the versionId when it's empty */
    public apiGenericDontSetVersionId!: boolean;

    constructor() {}

    /** sets the given versionId , and ensures it will not be set by apiService.apiGeneric if empty */
    public setVersionId(versionId?: string | null) {
        this.VersionId = versionId;
        this.apiGenericDontSetVersionId = true;
    }
}

/**
 * Legacy result from the RPC-like API.
 * Should be removed once we migrated all routes to REST.
 */
export class BaseServiceResult {
    /**
     * @deprecated This property shouldn't be used to check if the request has succeeded
     * Instead just catch the error, an ApiServiceError will be thrown with
     * unsuccessful status
     */
    @deserialize IsSuccess!: boolean;
    @deserialize IsErrorMissingRequiredFields!: boolean;
    @deserialize IsErrorBadParameterValues!: boolean;
    @deserialize IsErrorDataCorruption!: boolean;
    @deserialize IsErrorSessionNotFound!: boolean;
    @deserialize IsErrorConflicts!: boolean;
    @deserialize IsErrorAccessDenied!: boolean;
    @deserialize ErrorDetails!: string;

    public errorDetailsKey!: string;

    constructor() {}
}
