import { BehaviorSubject, Subject } from 'rxjs';
import { BaseService } from '@datagalaxy/core-ui';
import { Injectable } from '@angular/core';
import {
    ILinkSuggestionWithHDD,
    ISuggestion,
    ISuggestionGroup,
    ISuggestionParameters,
    ISuggestionRow,
    ISuggestionUserChoice,
} from './suggestion.types';
import { EntityEventService } from '../shared/entity/services/entity-event.service';
import { SuggestionLegacyApiService } from './suggestion-legacy-api.service';
import { UserService } from '../services/user.service';
import {
    SuggestionElement,
    SuggestionType,
} from '@datagalaxy/webclient/suggestion/types';
import { SuggestionApiService } from '@datagalaxy/webclient/suggestion/data-access';
import { getLocalId } from '@datagalaxy/utils';
import { LinkSuggestionAdapterFactory } from './suggestion-adapter/link-suggestion-adapter.factory';
import { ObjectLinkType } from '@datagalaxy/dg-object-model';
import { LinkSuggestionOrder } from '@datagalaxy/webclient/suggestion/domain';
import { userSettingsValues } from '@datagalaxy/webclient/user/domain';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';
import { DgModule } from '@datagalaxy/shared/dg-module/domain';

/**
 * ## Role
 * manage suggestion settings and user choices
 *
 * ## Type
 * Stateless
 */
@Injectable({ providedIn: 'root' })
export class SuggestionService extends BaseService {
    public static getAutoGenerationLinksActionNameFromModule(
        dgModule: DgModule,
    ) {
        switch (dgModule) {
            case DgModule.Glossary:
                return 'SUGGEST_LINKS_FROM_GLOSSARY,R';
            case DgModule.Catalog:
                return 'SUGGEST_LINKS_FROM_DICTIONARY,R';
            case DgModule.Usage:
                return 'SUGGEST_LINKS_FROM_USAGES,R';
        }
    }

    public settingsValue: ISuggestionSettings;

    public get settings$() {
        return this.settings.asObservable();
    }

    private settings = new BehaviorSubject<ISuggestionSettings>(null);

    public get userChoice$() {
        return this.userChoice.asObservable();
    }

    private userChoice = new Subject<ISuggestionUserChoice>();

    public get applyAllLoading$() {
        return this.applyAllLoading.asObservable();
    }

    private applyAllLoading = new BehaviorSubject<boolean>(false);

    constructor(
        private suggestionLegacyApiService: SuggestionLegacyApiService,
        private suggestionApiService: SuggestionApiService,
        private entityEventService: EntityEventService,
        private userService: UserService,
        private linkSuggestionAdapter: LinkSuggestionAdapterFactory,
    ) {
        super();
    }

    public async initAsync() {
        const res = await this.userService.getUserSettingValue(
            userSettingsValues.suggestions.category,
            userSettingsValues.suggestions.routes.settings,
        );

        if (res?.Value) {
            this.settingsValue = JSON.parse(res.Value);
        } else {
            this.settingsValue = { disabled: false };
            await this.userService.setUserSettingValue(
                userSettingsValues.suggestions.category,
                userSettingsValues.suggestions.routes.settings,
                JSON.stringify(this.settingsValue),
            );
        }
        this.settings.next(this.settingsValue);
    }

    public async toggleSuggestions() {
        this.settingsValue.disabled = !this.settingsValue.disabled;
        await this.userService.setUserSettingValue(
            userSettingsValues.suggestions.category,
            userSettingsValues.suggestions.routes.settings,
            JSON.stringify(this.settingsValue),
        );
        this.settings.next(this.settingsValue);
    }

    public async getUserSuggestions(p: ISuggestionParameters) {
        return await this.suggestionLegacyApiService.getUserSuggestions(p);
    }

    public async hasSuggestions() {
        return await this.suggestionLegacyApiService.hasSuggestions();
    }

    public async getEntityLinkSuggestions(
        versionId: string,
        entityGuid: string,
    ) {
        return await this.suggestionApiService.getEntityLinkSuggestions(
            versionId,
            entityGuid,
        );
    }

    public async getAllLinkSuggestions(
        spaceId: string,
        versionId: string,
        offset: number,
        size: number,
        orderBy: LinkSuggestionOrder,
        linkType: ObjectLinkType,
    ) {
        const linkSuggestions =
            await this.suggestionApiService.getAllLinkSuggestions(
                getLocalId(spaceId),
                versionId,
                offset,
                size,
                orderBy,
                linkType,
            );
        return linkSuggestions.LinkSuggestions.map((s) => {
            const hdSource = linkSuggestions.HierarchicalData.find(
                (hd) =>
                    getLocalId(hd.Data?.DataReferenceId) === s.SourceGuid &&
                    hd.VersionId === s.VersionId,
            );
            const hdTarget = linkSuggestions.HierarchicalData.find(
                (hd) =>
                    getLocalId(hd.Data?.DataReferenceId) === s.TargetGuid &&
                    hd.VersionId === s.VersionId,
            );

            const linkSuggessionWithHdd = {
                linkSuggestion: s,
                target: hdTarget,
                source: hdSource,
            } as ILinkSuggestionWithHDD;
            const suggestion = this.linkSuggestionAdapter.getLinkSuggestion(
                linkSuggessionWithHdd,
            );
            return {
                suggestion,
                accepted: undefined,
                HddData: linkSuggessionWithHdd.source,
            } as ISuggestionRow;
        });
    }

    public async generateSuggestionLinksFromEntity(
        spaceId: string,
        versionId: string,
        referenceId: string,
        targetIds: string[],
    ) {
        const spaceGuid = getLocalId(spaceId);
        const targetGuids = targetIds.map((id) => getLocalId(id));
        const entityGuid = getLocalId(referenceId);

        return await this.suggestionApiService.generateSuggestionLinksFromEntity(
            spaceGuid,
            versionId,
            entityGuid,
            targetGuids,
        );
    }

    public async generateSuggestionLinksFromModule(
        spaceId: string,
        versionId: string,
        sourceModule: DgModule,
        targetIds: string[],
    ) {
        const spaceGuid = getLocalId(spaceId);
        const targetGuids = targetIds.map((id) => getLocalId(id));
        return await this.suggestionApiService.generateSuggestionLinksFromModule(
            spaceGuid,
            versionId,
            targetGuids,
            DgModule[sourceModule],
        );
    }

    /**
     * Send all viewed notifications for logs purpose only.
     * Could be a long request, so try to avoid awaiting it in components
     * @param suggestions
     * @returns
     */
    public async logViewedSuggestions(suggestions: ISuggestion[]) {
        if (!suggestions?.length) {
            return;
        }
        await this.suggestionLegacyApiService.logViewedSuggestions(suggestions);
    }

    public async applySuggestion(
        suggestion: ISuggestion | ISuggestionGroup,
        accepted: boolean,
    ) {
        const suggestions =
            'suggestions' in suggestion ? suggestion.suggestions : [suggestion];
        switch (suggestion.type) {
            case SuggestionType.Tag:
            case SuggestionType.Dcp:
                return await this.saveAttributeUserChoice(
                    accepted,
                    suggestions.map((s) => s.data as SuggestionElement),
                );
            case SuggestionType.Link:
                return await this.applySuggestionLink(suggestions, accepted);
        }
    }

    public async applyAllForeachSuggestions(
        suggestions: ISuggestionRow[],
        accepted: boolean,
    ) {
        this.applyAllLoading.next(true);
        const promises = [];
        suggestions.forEach((s) => {
            const promise = this.applySuggestion(s.suggestion, accepted);
            promises.push(promise);
        });
        await Promise.all(promises);
        this.applyAllLoading.next(false);
    }

    public async applyAllInSuggestionsGroupForType(
        suggestionType: SuggestionType,
        suggestions: ISuggestionRow[],
        accepted: boolean,
    ) {
        this.applyAllLoading.next(true);
        const currentSuggestions: ISuggestionGroup = {
            type: suggestionType,
            suggestions: suggestions.map((r) => r.suggestion),
            groupKey: '',
            canAcceptMultiValue: false,
            labelKey: 'UI.Suggestions.Panel.Title.Links',
        };

        await this.applySuggestion(currentSuggestions, accepted);
        this.applyAllLoading.next(false);
    }

    public async getEntityLinkSuggestionsCount(
        versionId: string,
        referenceId: string,
    ) {
        const entityGuid = getLocalId(referenceId);
        const result =
            await this.suggestionApiService.getEntityLinkSuggestionsCount(
                versionId,
                entityGuid,
            );
        return result.Count;
    }

    private async applySuggestionLink(
        suggestions: ISuggestion[],
        accepted: boolean,
    ) {
        await this.saveLinkUserChoice(
            accepted,
            suggestions.map((s) => s.data as ILinkSuggestionWithHDD),
        );
        suggestions.forEach((s) => {
            this.triggerNotifyEntityLinkAdd(s, accepted);
        });
    }

    private triggerNotifyEntityLinkAdd(
        suggestion: ISuggestion,
        accepted: boolean,
    ) {
        const linkSuggestion = suggestion.data as ILinkSuggestionWithHDD;
        const entityItem = new EntityItem();
        entityItem.DataTypeName = linkSuggestion.source.DataTypeName;
        entityItem.Attributes = {};
        entityItem.ReferenceId = linkSuggestion.source.ReferenceId;
        entityItem.DisplayName = linkSuggestion.source.DisplayName;
        entityItem.TechnicalName = linkSuggestion.source.TechnicalName;
        this.entityEventService.notifyEntityLinkAdd(entityItem, accepted);
    }

    private async saveAttributeUserChoice(
        accepted: boolean,
        suggestions: SuggestionElement[],
    ) {
        const updatedEntity =
            await this.suggestionLegacyApiService.setSuggestionAttributeUserChoice(
                accepted,
                suggestions,
            );
        if (!updatedEntity) {
            return;
        }
        this.entityEventService.notifyEntityUpdate(updatedEntity);
        this.userChoice.next({
            accepted,
            ids: suggestions.map((s) => s.Hashcode),
        });
    }

    private async saveLinkUserChoice(
        accepted: boolean,
        suggestions: ILinkSuggestionWithHDD[],
    ) {
        await this.suggestionLegacyApiService.saveLinkUserChoice(
            accepted,
            suggestions,
        );
        this.userChoice.next({
            accepted,
            ids: suggestions.map((s) => s.linkSuggestion.Guid),
        });
    }
}

export interface ISuggestionSettings {
    disabled?: boolean;
}
