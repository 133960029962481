import {
    ChangeDetectionStrategy,
    Component,
    forwardRef,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import {
    AvailableLanguagesService,
    Language,
    languageAdapter,
} from '../../languages';
import {
    ControlValueAccessor,
    FormsModule,
    NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { AlertMessageComponent } from '@datagalaxy/ui/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { DxyFieldSelectComponent } from '@datagalaxy/core-ui';
import { NgIf, NgTemplateOutlet } from '@angular/common';
import { CurrentUserService } from '@datagalaxy/webclient/user/data-access';

@Component({
    selector: 'dxy-target-language-selector',
    templateUrl: './target-language-selector.component.html',
    styleUrls: ['./target-language-selector.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TargetLanguageSelectorComponent),
            multi: true,
        },
    ],
    standalone: true,
    imports: [
        NgIf,
        DxyFieldSelectComponent,
        FormsModule,
        MatLegacyFormFieldModule,
        TranslateModule,
        NgTemplateOutlet,
        AlertMessageComponent,
    ],
})
export class TargetLanguageSelectorComponent
    implements ControlValueAccessor, OnChanges, OnInit
{
    @Input() exludedLanguageCodes: string[] = [];

    protected languages: Language[] = [];
    protected languageAdapter = languageAdapter;

    protected value?: Language;

    protected get errorMessageKey() {
        if (this.touched && !this.value) {
            return 'Multilingual.Administration.TargetLanguageSelector.requiredError';
        } else {
            return undefined;
        }
    }

    protected languageRequestLink: string;

    private touched = false;
    private onChange: any = () => {};
    private onTouched: any = () => {};

    constructor(
        private availableLanguagesService: AvailableLanguagesService,
        private currentUserService: CurrentUserService,
    ) {
        const languageCode =
            this.currentUserService.userInfo?.LanguageCode === 'fr'
                ? 'fr'
                : 'en';
        this.languageRequestLink = `https://datagalaxy.freshdesk.com/${languageCode}/support/tickets/new`;
    }

    ngOnInit() {
        this.updateLanguages();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['exludedLanguageCodes']) {
            this.updateLanguages();
        }
    }

    writeValue(language: Language): void {
        this.value = language;
    }
    registerOnChange(fn: any) {
        this.onChange = fn;
    }
    registerOnTouched(fn: any) {
        this.onTouched = fn;
    }

    setDisabledState?(_isDisabled: boolean) {}

    protected onOpenClose(opened: boolean) {
        if (!opened) {
            this.touched = true;
            this.onTouched();
        }
    }

    protected onValueChange(value: Language) {
        this.onChange(value);
        this.onTouched();
    }

    private updateLanguages() {
        this.languages = this.availableLanguagesService
            .getAvailableLanguages()
            .filter((l) => {
                return !this.exludedLanguageCodes.includes(l.languageCode);
            });
    }
}
