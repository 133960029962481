import { IActivityLogEntryTranslationData } from './activity-log-entry-translation.type';
import { DefaultLogEntryAdapter } from './default-log-entry-adapter';
import {
    ActivityLogEntry,
    ActivityLogEntryType,
} from '@datagalaxy/webclient/activity-log/domain';

/**
 * ## Role
 * Activity log adapter for logs concerning users (adding or removal of owners, stewards)
 */
export class UserLogEntryAdapter extends DefaultLogEntryAdapter {
    public getEntryTranslationData(
        entry: ActivityLogEntry,
    ): IActivityLogEntryTranslationData {
        const data = super.getEntryTranslationData(entry);
        data.translateParams.childDataName = entry.ChildData?.DisplayName;
        data.translateParams.propertyName = this.getPropertyNameTranslation(
            entry,
            entry.Data,
        );
        return data;
    }
    protected override getTranslateKey(entry: ActivityLogEntry) {
        const entryType = entry.EntryType;
        const key =
            entryType == ActivityLogEntryType.AddReference
                ? 'addUser'
                : entryType == ActivityLogEntryType.DeleteReference
                  ? 'removeUser'
                  : '';
        return key && `${this.baseTranslateKey}${key}`;
    }
}
