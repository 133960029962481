import { CoreUtil } from '@datagalaxy/core-util';
import { IFunctionalEvent } from './IFunctionalEvent';

export interface IFunctionalEventEmitter {
    emit: (event: IFunctionalEvent) => void;
}

export type TFunctionalLogCode<TContext = unknown> =
    | string
    | ((data?: TContext) => string);

export function emitFunctionalEventIfNeeded<TContext>(
    functionalCode: TFunctionalLogCode<TContext>,
    onLogFunctional: IFunctionalEventEmitter,
    event: Event,
    data?: TContext,
) {
    if (!onLogFunctional || !functionalCode) {
        return;
    }
    onLogFunctional.emit({
        text: CoreUtil.fromFnOrValue(functionalCode, data),
        origin: event.target as HTMLElement,
    });
}
