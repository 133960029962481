import {
    AfterViewInit,
    Component,
    ElementRef,
    Input,
    OnInit,
} from '@angular/core';
import { DomUtil } from '@datagalaxy/core-util';
import { TeamService } from '../team.service';
import { ITeamHistoryData } from '../team-history/team-history.component';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { DxyProfileAvatarComponent } from '../../shared/shared-ui/dxy-profile-avatar/dxy-profile-avatar.component';
import { NgIf, NgClass } from '@angular/common';

@Component({
    selector: 'app-team-history-item',
    templateUrl: 'team-history-item.component.html',
    styleUrls: ['team-history-item.component.scss'],
    standalone: true,
    imports: [NgIf, NgClass, DxyProfileAvatarComponent],
})
export class TeamHistoryItemComponent
    extends DxyBaseComponent
    implements OnInit, AfterViewInit
{
    @Input() teamHistoryData: ITeamHistoryData;
    public teamImgUrl: string;
    public teamGlyphClass: string;

    constructor(
        private elementRef: ElementRef<HTMLElement>,
        private teamService: TeamService,
    ) {
        super();
    }

    ngOnInit() {
        this.initTeamData();
    }

    ngAfterViewInit() {
        setTimeout(() =>
            ['.team-data', '.maker-data', '.member-data'].forEach((c) =>
                this.replaceLink(c),
            ),
        );
    }

    private initTeamData() {
        this.teamImgUrl = this.teamService.getTeamImageUrl(
            this.teamHistoryData.teamData,
        );
        this.teamGlyphClass = this.teamService.getTeamGlyphClass(
            this.teamHistoryData.teamData,
            true,
        );
    }

    private replaceLink(linkSelector: string) {
        const displayedLink = this.getLink('.logElement-item', linkSelector);
        const dataLink = this.getLink('.hidden-data', linkSelector);
        if (displayedLink && dataLink) {
            displayedLink.parentElement.replaceChild(dataLink, displayedLink);
        }
    }

    private getLink(containerSelector: string, linkSelector: string) {
        return DomUtil.getElement<HTMLLinkElement>(
            this.elementRef,
            `${containerSelector} ${linkSelector}`,
        );
    }
}
