import {
    deserialize,
    deserializeAs,
    inheritSerialization,
    SerializableEnumeration,
} from 'cerialize';
import { ServerType } from './ServerType.enum';
import { BaseLinkDataInfo } from './BaseLinkDataInfo';
import { ObjectLinkType } from './ObjectLinkType.enum';

/* values are stored in DB by their number (see EntityGrid#gridStatePersistenceId),
 * so their order must not be changed.
 *
 * Also, important: if new values are added, one must update the method:
 * AttributeDataService.getOrderedAdminSubTypes
 *
 * IMPORTANT: The exact numeric values must match the Server Values (see EntityType.cs in Server Code)
 */
/** entity sub-types */
export enum EntityType {
    None,
    All,
    BusinessTerm,
    Concept,
    IndicatorGroup,
    Indicator,
    ReferenceData,
    DataFlow,
    DataProcessing,
    Use,
    DataSet,
    OpenDataSet,
    Process,
    Report,
    Algorithm,
    Application,
    Screen,
    Dashboard,
    Feature,
    Table,
    Column,
    NonRelationalModel,
    RelationalModel,
    Dimension,
    DimensionGroup,
    File,
    Field,
    NoSqlModel,
    Document,
    BusinessDomain,
    BusinessDomainGroup,
    Universe,
    Model,
    Directory,
    Equipment,
    SubStructure,
    TagBase,
    Tag,
    View,
    DataProcessingItem,
    ReferenceDataValue,
    UsageField,
    PrimaryKey,
    ForeignKey,
    /** @deprecated since v3.5 - Replaced by PhysicalDiagram. Use PhysicalDiagram or FreeDiagram instead */
    Diagram,
    DiagramNode,
    FilteredView,
    Project,
    Organization,
    UsageComponent,
    FreeDiagram /* renamed from GenericDiagram in v3.5 */,
    /** Diagram representing relations in one or several modeler sources (relational or not). May be refined by a DiagramKind enum value */
    PhysicalDiagram /* added in v3.5 */,
}
SerializableEnumeration(EntityType);
export class EntityTypeChildRule {
    @deserialize public AllowedChildrenTypes: EntityType[] = [];
    @deserializeAs(EntityType) public OptionalParentType?: EntityType;

    public static OnDeserialized(instance: EntityTypeChildRule) {
        instance.AllowedChildrenTypes = instance.AllowedChildrenTypes
            ? instance.AllowedChildrenTypes.map(
                  (c) => EntityType[c as any as string],
              )
            : [];
    }
}

export class EntityTypeParentRule {
    @deserialize public AllowedParentTypes: EntityType[] = [];

    // If IsMandatory is true, we cannot remove the LogicalParentData for the given EntityType
    @deserialize public IsMandatory: boolean;
    // If IsUpdatable is true, we cannot update the LogicalParentData for the given EntityType
    @deserialize public IsUpdatable: boolean;

    public static OnDeserialized(instance: EntityTypeParentRule) {
        instance.AllowedParentTypes = instance.AllowedParentTypes
            ? instance.AllowedParentTypes.map(
                  (c) => EntityType[<string>(<any>c)],
              )
            : [];
    }
}
@inheritSerialization(BaseLinkDataInfo)
export class TypeLinkDataInfo extends BaseLinkDataInfo {
    @deserialize public SourceDataTypeName: string;
    @deserialize public TargetDataTypeNames: string[];
    @deserializeAs(EntityType) public SourceEntityType: EntityType;
    @deserialize public TargetEntityTypes: EntityType[];
    @deserialize public ExcludedEntitiesIds: string[];
    @deserialize public LinkedEntitiesIds: string[];

    public get TargetDataTypes(): ServerType[] {
        return this.TargetDataTypeNames.map((c) => ServerType[c]);
    }

    public get isEntityLink(): boolean {
        return this.ObjectLinkType == ObjectLinkType.EntityLink;
    }

    public static OnDeserialized(instance: TypeLinkDataInfo) {
        if (instance.TargetEntityTypes && instance.TargetEntityTypes.length > 0)
            instance.TargetEntityTypes = instance.TargetEntityTypes.map(
                (c) => EntityType[<string>(<any>c)],
            );
    }
}
export class EntityTypeMapping {
    @deserializeAs(EntityType) public EntityType: EntityType;
    @deserialize public DataTypeName: string;
    @deserialize public SubTypeName: string;
    @deserialize public EnglishName: string;
    @deserialize public FrenchName: string;
    @deserialize public HasWritableTechnologyCode?: boolean;
    @deserializeAs(EntityTypeChildRule)
    public ChildRules: EntityTypeChildRule[];
    @deserializeAs(EntityTypeParentRule)
    public ParentRules: EntityTypeParentRule[];
    @deserializeAs(TypeLinkDataInfo)
    public NonHierarchicalLinkRules: TypeLinkDataInfo[];

    public serverType: ServerType;

    public static OnDeserialized(instance: EntityTypeMapping) {
        instance.serverType = ServerType[instance.DataTypeName];
    }

    public getTranslateKey() {
        return this.SubTypeName
            ? `DgServerTypes.${this.DataTypeName}Type.${this.SubTypeName}`
            : `DgServerTypes.ServerTypeName.${this.DataTypeName}`;
    }
}
