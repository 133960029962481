<div class="modal-header">
    <h3 mat-dialog-title class="modal-title" translate>
        UI.Ddl.scriptGenerationResultModal.title
    </h3>
    <button dxyIconButton color="transparent" (click)="onCloseCancel()">
        <i class="glyph-cancelsearch"></i>
    </button>
</div>
<div mat-dialog-content>
    <dxy-field-text
        *ngIf="script"
        #scriptRef
        labelKey="UI.Ddl.scriptGenerationResultModal.lblResult"
        [readonly]="true"
        (click)="onClickScript()"
        [(ngModel)]="script"
        [multiLine]="true"
        [autoSizeMultiLine]="true"
        [autoSizeMultiLineMaxRows]="20"
    >
    </dxy-field-text>
    <div *ngIf="hasMessages" class="form-group">
        <label class="control-label col-sm-2" translate>
            UI.Ddl.scriptGenerationResultModal.lblMessages
        </label>
        <div class="col-sm-10">
            <div *ngFor="let type of messageTypes" class="message-group">
                <div
                    *ngFor="let msgGroup of getMessageGroups(type)"
                    class="alert alert-{{ type }}"
                >
                    <h4>{{ msgGroup[0].MessageContext }}&nbsp;:</h4>
                    <div *ngFor="let msg of msgGroup">
                        {{ msg.translatedMessage }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button
        dxyButton
        (click)="onCloseSubmit()"
        translate="UI.Dialog.btnOk"
    ></button>
</div>
