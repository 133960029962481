import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
    selector: 'dxy-ai-writing',
    templateUrl: './ai-writing.component.html',
    styleUrls: ['./ai-writing.component.scss'],
    imports: [],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AiWritingComponent {}
