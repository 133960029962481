import { Component, HostBinding, ViewChild } from '@angular/core';
import {
    MatLegacyMenuTrigger as MatMenuTrigger,
    MatLegacyMenuModule,
} from '@angular/material/legacy-menu';
import { BaseCellComponent } from '../../../cell-components/BaseCellComponent';
import { ICaptionPositionSelectorParams } from './caption-position-selector.types';
import {
    CaptionPositionKeys,
    ICaptionPosition,
} from '../caption-position.types';
import { NgFor, NgClass } from '@angular/common';
import { DxyIconButtonDirective } from '@datagalaxy/ui/buttons';

/** ## Role
 * User control for to configure the positioning of a caption.
 *
 * Configurable properties are:
 * - type: inside or outside
 * - horizontal alignement: left, center, right
 * - vertical alignement: top, middle, bottom
 *
 * ## Controls
 * - Trigger icon, with tooltip
 * - 3-row icon-buttons panel, with a separator between rows
 */
@Component({
    selector: 'dxy-caption-position-selector',
    templateUrl: 'caption-position-selector.component.html',
    standalone: true,
    imports: [MatLegacyMenuModule, NgFor, NgClass, DxyIconButtonDirective],
})
export class DxyCaptionPositionSelectorComponent extends BaseCellComponent<
    unknown,
    ICaptionPosition,
    ICaptionPositionSelectorParams
> {
    protected currentValue: ICaptionPosition = {};
    protected get localClass() {
        return `graphical-selector${
            this.params?.customClass ? ' ' + this.params.customClass : ''
        }`;
    }

    @ViewChild(MatMenuTrigger) private menuTrigger: MatMenuTrigger;
    @HostBinding('class') private get hostClass() {
        return this.localClass;
    }

    constructor() {
        super();
    }

    public onEscapeKey() {
        this.menuTrigger.closeMenu();
    }

    public onClick(event: Event, obj: ICaptionPosition) {
        CaptionPositionKeys.forEach((k) => {
            const v = obj[k];
            if (v == undefined) {
                return;
            }
            if (v == this.currentValue[k]) {
                event.stopPropagation();
            }
            this.currentValue[k as string] = v;
        });
        this.params.onValueChange?.(this.currentValue);
    }

    protected override updateBindings(): void {
        this.currentValue = { ...(this.params.value ?? {}) };
    }
}
