import { inject, Injectable } from '@angular/core';
import { ModuleStore } from '../../module/module.store';
import { ActivatedRoute } from '@angular/router';
import { DgModule } from '@datagalaxy/shared/dg-module/domain';

@Injectable({
    providedIn: 'root',
})
export class NavigationFeatureCodeService {
    private moduleStore = inject(ModuleStore);
    private route = inject(ActivatedRoute);

    public getCurrentRouteFeatureCode(): string {
        const module = this.moduleStore.dgModule;

        switch (module) {
            case DgModule.Catalog:
                return 'DICTIONARY';
            case DgModule.Processing:
                return 'PROCESSING';
            case DgModule.Glossary:
                return 'GLOSSARY';
            case DgModule.Usage:
                return 'USAGE';
            default:
                break;
        }

        const routeFeatureCode = this.route.snapshot.data.featureCode;

        return routeFeatureCode || '';
    }
}
