<div class="modal-header">
    <h3 mat-dialog-title class="modal-title" translate>
        UI.EntityTimeSeriesHistoryModal.title
    </h3>
    <button dxyIconButton color="transparent" (click)="onCloseCancel()">
        <i class="glyph-cancelsearch"></i>
    </button>
</div>
<div mat-dialog-content class="modal-body">
    <div class="graph-container">
        <div class="graph-container-menu">
            <div *ngIf="!hasValues" translate>
                UI.EntityTimeSeriesHistoryModal.empty
            </div>
            <div *ngIf="isEditEnabled" class="burger-btn-wrapper">
                <button
                    dxyIconButton
                    variant="secondary"
                    [matMenuTriggerFor]="actionsMenu"
                    [matTooltip]="'UI.NavBar.moreOptionsTooltip' | translate"
                >
                    <i class="glyph-splitter"></i>
                </button>
                <mat-menu #actionsMenu xPosition="before" class="dg5-mat-menu">
                    <ul role="menu" class="dropdown-menu-right">
                        <li
                            role="menuitem"
                            (click)="onClickImport()"
                            data-tracker-id="modal-timeseries-importvalues"
                        >
                            <span class="text" translate
                                >UI.EntityTimeSeriesHistoryModal.importLbl</span
                            >
                        </li>
                        <li
                            *ngIf="hasValues"
                            role="menuitem"
                            (click)="export()"
                        >
                            <span class="text" translate
                                >UI.EntityTimeSeriesHistoryModal.exportLbl</span
                            >
                        </li>
                    </ul>
                </mat-menu>
            </div>
        </div>
        <div *ngIf="hasValues" class="time-series-graph"></div>
    </div>
    <div class="grid-container">
        <dxy-grid
            *ngIf="hasValues"
            [config]="gridConfig"
            [columns]="cols"
            [items]="rows"
        ></dxy-grid>
    </div>
</div>
<div *ngIf="isEditEnabled" class="modal-footer dxy-actions">
    <button
        *ngIf="hasValues"
        dxyButton
        variant="secondary"
        (click)="onClickDeleteAll()"
    >
        <span translate
            >UI.EntityTimeSeriesHistoryModal.deleteAllValuesLbl</span
        >
    </button>
    <button
        dxyButton
        [disabled]="!canAddValue"
        (click)="onCreateOrEdit(true)"
        [matTooltip]="getDisableAddValueTooltip()"
    >
        <span translate>UI.EntityTimeSeriesHistoryModal.addValue</span>
    </button>
</div>
