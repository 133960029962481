<div *ngIf="showHeader" class="panel-content-tool">
    <div class="panel-content-toolname" translate>
        UI.EntityDockingPane.Tool.Commentaries
    </div>
    <div class="actions-container">
        <div *ngIf="!readOnly" class="tool-add-entity">
            <button
                dxyIconButton
                variant="primary"
                [matTooltip]="'UI.CommentaryContainer.lblAdd' | translate"
                matTooltipPosition="before"
                (click)="openCommentaryNewModal()"
            >
                <i class="glyph glyph-add"></i>
            </button>
        </div>
    </div>
</div>
<div class="mainContainer panel-content-body">
    <dxy-spinner *ngIf="isLoading"></dxy-spinner>
    <div class="listContainer" [class.mainContainer--loading]="isLoading">
        <div
            *ngIf="commentaries.length == 0 && !isLoading"
            class="no-item-container"
        >
            <div class="image-container">
                <img
                    src="/images/placeholders/comments.svg"
                    class="dg_no-item-image"
                    alt="no commentary"
                />
            </div>
            <div class="no-item-title" translate>
                UI.CommentaryContainer.titleLbl
            </div>
            <div class="no-item-text" translate>
                UI.CommentaryContainer.lblNoCommentary
            </div>
            <button
                *ngIf="!readOnly"
                dxyButton
                variant="primary"
                (click)="openCommentaryNewModal()"
            >
                <i class="glyph-add"></i
                ><span translate>UI.CommentaryContainer.addCommentaryBtn</span>
            </button>
        </div>
        <dxy-commentary-item
            *ngFor="let commentary of commentaries; trackBy: getCommentaryId"
            class="dg_animate animation-fade listItem"
            [entityData]="entityData"
            [commentary]="commentary"
            (onDeleteCommentary)="deleteCommentary(commentary)"
            (onEditCommentary)="setCurrentEditedCommentary(commentary)"
            [isEditActive]="isCurrentEditedCommentary(commentary)"
            (onAfterFormAction)="initCurrentEditedCommentary()"
            [readOnly]="readOnly"
        ></dxy-commentary-item>
    </div>
</div>
