<div class="modal-header">
    <h3 mat-dialog-title class="modal-title" [translate]="modalTitleKey"></h3>
    <button dxyIconButton color="transparent" (click)="onCloseCancel()">
        <i class="glyph-cancelsearch"></i>
    </button>
</div>
<form (ngSubmit)="onCloseSubmit()">
    <div mat-dialog-content [class.modal-disable]="creationLoading">
        <ng-container *ngFor="let fi of fieldInfos">
            <dxy-entity-attribute-input
                [fieldInfo]="fi"
                (onPanelOpenClose)="onPanelOpenClose($event)"
            ></dxy-entity-attribute-input>
        </ng-container>
    </div>
    <dxy-modal-footer
        [actionEnabled]="canCreate"
        [actionBtnLbl]="actionBtnLbl"
        [actionLoading]="creationLoading"
        (onCloseCancel)="onCloseCancel()"
        [featureCode]="featureCode"
        [showCreateAnother]="!hideCreateAnother"
        [isCheckedCreateAnother]="isCheckedCreateAnother"
        (onChangeCreateAnother)="onChangeCreateAnother()"
    ></dxy-modal-footer>
</form>
