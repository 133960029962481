import { ObjectLinkType } from '@datagalaxy/dg-object-model';
import { FlowType } from './entity-link.types';

export class EntityLinkUtils {
    public static readonly downStreamLinkTypes = [
        ObjectLinkType.HasOutput,
        ObjectLinkType.HasForUsageDestination,
        ObjectLinkType.IsCalledBy,
        ObjectLinkType.IsUsedBy,
        ObjectLinkType.IsInputOf,
        ObjectLinkType.IsUsageSourceFor,
        ObjectLinkType.IsImplementedBy,
    ];

    public static readonly upStreamLinkTypes = [
        ObjectLinkType.HasInput,
        ObjectLinkType.HasForUsageSource,
        ObjectLinkType.Calls,
        ObjectLinkType.Uses,
        ObjectLinkType.IsOutputOf,
        ObjectLinkType.IsUsageDestinationFor,
        ObjectLinkType.Implements,
    ];

    public static readonly directedLinkTypes = [
        ...this.upStreamLinkTypes,
        ...this.downStreamLinkTypes,
    ];

    public static reverseObjectLinkType(olt: ObjectLinkType): ObjectLinkType {
        if (olt < 1000 || olt > 2999) {
            return olt;
        }
        const oltInv = olt < 2000 ? olt + 1000 : olt - 1000;
        return ObjectLinkType[oltInv] ? oltInv : olt;
    }

    public static isEntityDownStreamLinkType(
        linkType: ObjectLinkType,
    ): boolean {
        return this.downStreamLinkTypes.includes(linkType);
    }

    public static isEntityUpStreamLinkType(linkType: ObjectLinkType): boolean {
        return this.upStreamLinkTypes.includes(linkType);
    }

    // [spec](https://datagalaxy.atlassian.net/wiki/spaces/DG3/pages/3307077633/SPEC+-+Diagrammes+libres+-+WIP)
    // [addendum](https://datagalaxy.atlassian.net/browse/DG-5449)
    public static getLinkTypeFlow(olt: ObjectLinkType) {
        if (this.isEntityUpStreamLinkType(olt)) {
            return FlowType.upstream;
        }

        if (this.isEntityDownStreamLinkType(olt)) {
            return FlowType.downstream;
        }

        return FlowType.all;
    }

    public static isGlossaryLinkType(linkType: ObjectLinkType): boolean {
        switch (linkType) {
            case ObjectLinkType.Implements:
            case ObjectLinkType.IsImplementedBy:
                return true;
            default:
                return false;
        }
    }
}
