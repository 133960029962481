<div *ngIf="showHeader" class="panel-content-tool">
    <div class="panel-content-toolname" translate>
        UI.EntityDockingPane.Tool.Suggestion
    </div>
</div>
<div class="dg_suggestionContainer-mainContainer panel-content-body">
    <dxy-spinner
        *ngIf="loading$ | async"
        class="absolute-centered xl"
    ></dxy-spinner>
    <div
        *ngIf="suggestions$ | async as sections"
        class="suggestions-list"
        [class.loading]="loading$ | async"
    >
        <div *ngFor="let section of sections" class="suggestion-container">
            <dxy-collapsible [titleKey]="section.displayName">
                <div
                    *ngFor="let suggestionGroup of section.suggestionGroup"
                    class="section-content"
                >
                    <div class="sub-section-title">
                        <span class="text" translate>{{
                            suggestionGroup.labelKey
                        }}</span>
                        <ng-container *ngIf="!readOnly">
                            <app-suggestion-choice-actions
                                [suggestion]="suggestionGroup"
                                [hideAcceptBtn]="
                                    !suggestionGroup.canAcceptMultiValue
                                "
                                featureCodePrefix="SOCIAL_ATTRIBUTE_ALL_SUGGESTION_"
                            ></app-suggestion-choice-actions>
                        </ng-container>
                    </div>
                    <ul class="suggestion-items">
                        <li
                            *ngFor="
                                let suggestion of suggestionGroup.suggestions
                            "
                            class="suggestion-item"
                        >
                            <app-suggestion-cell
                                [suggestion]="suggestion"
                                featureCodePrefix="SOCIAL_SUGGESTION_"
                            ></app-suggestion-cell>
                        </li>
                    </ul>
                </div>
            </dxy-collapsible>
        </div>
        <div *ngIf="!sections?.length" class="no-item-container">
            <div class="dg_no-item-image">
                <img
                    src="/images/placeholders/metabot.svg"
                    class="dg_no-item-image"
                    alt="no suggestion"
                />
            </div>
            <div class="no-item-title" translate>
                UI.SuggestionContainer.titleLbl
            </div>
            <div class="no-item-text" translate>
                UI.SuggestionContainer.lblNoSuggestion
            </div>
            <button dxyButton variant="secondary" (click)="onClickDocButton()">
                <span translate>UI.SuggestionContainer.moreInfoBtnLbl</span>
            </button>
        </div>
    </div>
</div>
