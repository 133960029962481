import { ConnectedPosition } from '@angular/cdk/overlay';

const menuTriggerSpacingInPx = 3;

export type MenuAlignment = 'left' | 'right';

export const getMenuPosition = (
    alignment: MenuAlignment | undefined,
): ConnectedPosition[] => {
    switch (alignment) {
        case 'left':
            return bottomLeftPosition;
        case 'right':
            return bottomRightPosition;
        default:
            return bottomLeftPosition;
    }
};

const bottomLeftPosition: ConnectedPosition[] = [
    {
        originX: 'start',
        originY: 'bottom',
        overlayX: 'start',
        overlayY: 'top',
        offsetY: menuTriggerSpacingInPx,
    },
    {
        originX: 'start',
        originY: 'top',
        overlayX: 'start',
        overlayY: 'bottom',
        offsetY: -menuTriggerSpacingInPx,
    },
];

const bottomRightPosition: ConnectedPosition[] = [
    {
        originX: 'end',
        originY: 'bottom',
        overlayX: 'end',
        overlayY: 'top',
        offsetY: menuTriggerSpacingInPx,
    },
    {
        originX: 'end',
        originY: 'top',
        overlayX: 'end',
        overlayY: 'bottom',
        offsetY: -menuTriggerSpacingInPx,
    },
];
