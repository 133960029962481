import { IOptionWithRenderer, IOptionWithText } from '../../IListOption';
import { ICellParams, TCellRenderer } from '../cell-components.types';

export interface ICollectionCellInputs<
    TValue = any,
    TEntity = any,
    TRenderer extends TCellRenderer<TEntity, TValue> = TCellRenderer<
        TEntity,
        TValue
    >,
    TCellParams extends ICellParams<TEntity, TValue> = ICellParams<
        TEntity,
        TValue
    >,
> {
    options?: ICollectionCellOption<TValue, TEntity, TRenderer, TCellParams>[];
    maxLines?: number;
    maxItems?: number;
    itemSize?: number;
    spacing?: number;
    ellipsisReplaceText?: string;
    tooltipTitle?: string;
    uniqueTooltip?: boolean;
    lineHeight?: number;
    hideLabel?: boolean;
    hideEllipsedLabel?: boolean;
    moreCountAsOption?: boolean;
    disableTooltip?: boolean;
    enablePopover?: boolean;
    alignRight?: boolean;

    containerWidth?: number;
    mini?: boolean;
    fitContentWidth?: boolean;

    openPopoverFn?: (el: HTMLElement) => void;
}

export const baseTagCollectionCellInputs: ICollectionCellInputs = {
    itemSize: 14,
    spacing: 5,
};
export const baseMiniTagCollectionCellInputs: ICollectionCellInputs = {
    itemSize: 10,
    spacing: 4,
};
export const baseUserCollectionCellInputs: ICollectionCellInputs = {
    itemSize: 30,
    lineHeight: 30,
    spacing: -6,
};
export const baseMiniUserCollectionCellInputs: ICollectionCellInputs = {
    itemSize: 22,
    lineHeight: 22,
    spacing: -6,
};
export const baseIconCollectionCellInputs: ICollectionCellInputs = {
    itemSize: 16,
    spacing: 4,
};

export interface ICollectionCellOption<
    TValue = any,
    TEntity = any,
    TRenderer extends TCellRenderer<TEntity, TValue> = TCellRenderer<
        TEntity,
        TValue
    >,
    TCellParams extends ICellParams<TEntity, TValue> = ICellParams<
        TEntity,
        TValue
    >,
> extends IOptionWithText<TEntity>,
        IOptionWithRenderer<TValue, TEntity, TRenderer, TCellParams> {
    optionClass?: string;
    shortLabelText?: string;
}

export type ICollectionCellParams = ICellParams<
    unknown,
    unknown,
    ICollectionCellInputs
>;

export interface ICollectionCellConfig {
    moreCountLetterWidth?: number;
    labelTextFontSize?: string;
    labelPadding?: number;
    defaultMoreCountSize?: number;
    minimumMoreCountSize?: number;
    minMonoLabelTextWidth?: number;
    minMultiLabelTextWidth?: number;
    defaultMaxLine?: number;
    defaultLineHeight?: number;
    tooltipTitleHeight?: number;
}
