import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnInit,
} from '@angular/core';
import { SpinnerComponent, withLoading } from '@datagalaxy/core-ui';
import { CampaignBriefDto } from '@datagalaxy/webclient/campaign/data-access';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CampaignSecurityService } from '../../../campaign/campaign-security.service';
import { CampaignUiService } from '../../../campaign/campaign-ui.service';
import { CampaignService } from '../../../campaign/campaign.service';
import { UserCollectionCellComponent } from '../../../shared/shared-ui/cells/user-collection-cell/user-collection-cell.component';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { WorkspaceIdentifier } from '@datagalaxy/webclient/workspace/utils';
import { IMiniEntityContent } from '@datagalaxy/webclient/entity/domain';
import { CommonModule } from '@angular/common';
import {
    GridCellType,
    GridComponent,
    GridConfig,
    TColDef,
} from '@datagalaxy/ui/grid';
import { GridUserSettingsDirective } from '../../../user/grid-user-settings/grid-user-settings.directive';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';
import { DxyIconButtonDirective } from '@datagalaxy/ui/buttons';

@Component({
    selector: 'app-campaign-container',
    templateUrl: 'campaign-container.component.html',
    styleUrls: ['campaign-container.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        GridComponent,
        GridUserSettingsDirective,
        SpinnerComponent,
        MatLegacyTooltipModule,
        TranslateModule,
        DxyIconButtonDirective,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CampaignContainerComponent
    extends DxyBaseComponent
    implements OnInit
{
    @Input() entityData: IMiniEntityContent;

    protected get canAddToCampaign() {
        return this.canCreateOrModifyCampaign;
    }
    protected get isEmptyGrid() {
        return !this.campaigns?.length;
    }

    protected gridConfig: GridConfig<CampaignBriefDto> = {
        getItemId: (row) => row.Guid,
        rowCssClass: (row) =>
            row.Ended ? 'campaign-closed' : 'campaign-active',
    };

    protected cols: TColDef<CampaignBriefDto>[] = [
        {
            id: 'name',
            headerLabel: this.getLabel('CampaignName'),
            type: GridCellType.text,
            getValue: (campaign) => campaign.Name,
            sortable: true,
        },
        {
            id: 'owner',
            headerLabel: this.getLabel('Owner'),
            type: GridCellType.custom,
            customCellComponent: UserCollectionCellComponent,
            getInputs: (campaign) => ({
                userIds: [campaign.Owner.UserId],
            }),
            width: 140,
            maxWidth: 140,
        },
        {
            id: 'status',
            headerLabel: this.getLabel('Status'),
            type: GridCellType.text,
            getValue: (campaign) => {
                if (campaign.Ended) return this.getLabel('Closed');
                return this.getLabel('Active');
            },
            sortable: true,
            width: 120,
            maxWidth: 120,
        },
        {
            id: 'phase',
            headerLabel: this.getLabel('Phase'),
            type: GridCellType.text,
            getValue: (campaign) => campaign.CurrentPhase.Name,
            sortable: true,
            width: 140,
            maxWidth: 140,
        },
    ];

    protected campaigns: CampaignBriefDto[];
    private canCreateOrModifyCampaign: boolean;
    private spaceIdr: WorkspaceIdentifier;

    constructor(
        private campaignSecurityService: CampaignSecurityService,
        private campaignUiService: CampaignUiService,
        private campaignService: CampaignService,
        private translate: TranslateService,
        private cdRef: ChangeDetectorRef,
    ) {
        super();
    }

    ngOnInit(): void {
        this.initAsync();
    }

    protected async addToExistingCampaign() {
        await this.campaignUiService.openCampaignSelectFormModal(
            this.spaceIdr,
            [this.entityData.ReferenceId],
        );
        await this.loadCampaigns();
    }

    protected async onRowClick(rowData: CampaignBriefDto) {
        await this.campaignService.goToCampaign(rowData.Guid, this.spaceIdr);
    }
    @withLoading()
    private async initAsync() {
        this.spaceIdr = WorkspaceIdentifier.fromEntity(this.entityData);
        this.canCreateOrModifyCampaign =
            await this.campaignSecurityService.canCreateOrModifyCampaign(
                this.spaceIdr,
            );

        await this.loadCampaigns();
    }

    private async loadCampaigns() {
        this.campaigns = await this.campaignService.getCampaignsFromObject(
            this.entityData.ReferenceId,
            this.entityData.VersionId,
        );
        this.cdRef.detectChanges();
    }

    private getLabel(suffix: string) {
        return this.translate.instant(`UI.Campaign.DockingPanel.${suffix}`);
    }
}
