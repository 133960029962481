import {
    HttpBackend,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseServiceParameter, BaseServiceResult } from './backend';

/** Reference : https://indepth.dev/posts/1455/how-to-split-http-interceptors-between-multiple-backends */

class InterceptorHandler implements HttpHandler {
    constructor(
        private next: HttpHandler,
        private interceptor: HttpInterceptor,
    ) {}

    handle(
        req: HttpRequest<BaseServiceParameter>,
    ): Observable<HttpEvent<BaseServiceResult>> {
        return this.interceptor.intercept(req, this.next);
    }
}

/**
 * Custom HttpHandler that allows us to attach specific http interceptors to
 * a HttpBackend service
 */
export class InterceptingHandler implements HttpHandler {
    private chain: HttpHandler;

    constructor(
        private backend: HttpBackend,
        private interceptors: HttpInterceptor[],
    ) {
        this.chain = this.buildChain();
    }

    handle(
        req: HttpRequest<BaseServiceParameter>,
    ): Observable<HttpEvent<BaseServiceResult>> {
        return this.chain.handle(req);
    }

    private buildChain(): HttpHandler {
        return this.interceptors.reduceRight(
            (next, interceptor) => new InterceptorHandler(next, interceptor),
            this.backend,
        );
    }
}
