import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Inject,
} from '@angular/core';
import { DxyBaseModalComponent } from '@datagalaxy/ui/dialog';
import {
    ICampaignForm,
    ICampaignFormModalInput,
    ICampaignFormModalOutput,
} from './campaign-form-modal.types';
import {
    MAT_DIALOG_DATA,
    MatDialogModule,
    MatDialogRef,
} from '@angular/material/dialog';
import {
    AsyncValidatorFn,
    FormBuilder,
    FormControl,
    FormGroup,
    FormsModule,
    ReactiveFormsModule,
    Validators,
} from '@angular/forms';
import { IFieldSelectAdapter, withLoading } from '@datagalaxy/core-ui';
import { UserFieldSelectAdapter } from '@datagalaxy/webclient/user/ui';
import { AppDataService } from '../../services/app-data.service';
import {
    WorkflowBriefDto,
    WorkflowService,
} from '@datagalaxy/webclient/workflow/data-access';
import { switchMap, tap } from 'rxjs/operators';
import { from, map, Observable, timer } from 'rxjs';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CampaignStoreService } from '../campaign-store.service';
import { CampaignService } from '../campaign.service';
import { ToasterService } from '../../services/toaster.service';
import { DxyModalFooterComponent } from '../../shared/dialogs/dxy-modal-footer/dxy-modal-footer.component';
import { SpinnerComponent } from '@datagalaxy/ui/spinner';
import { DxyFieldSelectComponent } from '@datagalaxy/core-ui/fields';
import { AsyncPipe, NgIf } from '@angular/common';
import { DxyRichTextFieldComponent } from '@datagalaxy/core-ui/rich-text';
import { DxyFieldTextComponent } from '@datagalaxy/ui/fields';
import {
    CrudOperation,
    FunctionalLogService,
} from '@datagalaxy/shared/monitoring/data-access';
import { DxyIconButtonDirective } from '@datagalaxy/ui/buttons';

/**
 * ## Role
 * Display a form to create a campaign
 */
@Component({
    selector: 'app-campaign-form-modal',
    templateUrl: './campaign-form-modal.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        MatDialogModule,
        TranslateModule,
        DxyIconButtonDirective,
        FormsModule,
        ReactiveFormsModule,
        DxyFieldTextComponent,
        DxyRichTextFieldComponent,
        NgIf,
        DxyFieldSelectComponent,
        SpinnerComponent,
        DxyModalFooterComponent,
        AsyncPipe,
    ],
})
export class CampaignFormModalComponent extends DxyBaseModalComponent<
    ICampaignFormModalInput,
    ICampaignFormModalOutput
> {
    protected formGroup: FormGroup<ICampaignForm>;
    protected ownerAdapter = new UserFieldSelectAdapter();
    protected workflowAdapter: IFieldSelectAdapter<WorkflowBriefDto> = {
        getText: (wf) => wf.Name,
    };

    protected workflows$: Observable<WorkflowBriefDto[]>;
    protected get owners() {
        return this.data.owners;
    }
    protected get campaign() {
        return this.data.campaign;
    }

    protected get campaignAlreadyExistsErrorMessage(): string {
        const campaignName = this.formGroup.controls.name;
        if (campaignName?.valid) {
            return undefined;
        }

        return campaignName.errors?.nameAlreadyExists;
    }

    /**
     * For now the owner is disabled since it doesn't provide any
     * usefull features/information
     */
    protected disableOwner = true;

    constructor(
        @Inject(MAT_DIALOG_DATA) data: ICampaignFormModalInput,
        dialogRef: MatDialogRef<CampaignFormModalComponent>,
        private fb: FormBuilder,
        private campaignStore: CampaignStoreService,
        private workflowService: WorkflowService,
        private appDataService: AppDataService,
        private campaignService: CampaignService,
        private functionalLogService: FunctionalLogService,
        private translate: TranslateService,
        private toasterService: ToasterService,
        private cd: ChangeDetectorRef,
    ) {
        super(dialogRef, data);
        this.workflows$ = this.workflowService
            .getWorkflows(this.data.spaceIdr.spaceId)
            .pipe(tap((workflows) => this.setWorkflow(workflows?.[0])));
        this.buildForm();
    }

    protected async onSubmit() {
        if (!this.campaign) {
            await this.save();
        }
    }

    private buildForm() {
        const campaign = this.campaign;
        const currentUser = this.owners.find(
            (u) => u.UserId === this.appDataService.currentUserId,
        );
        this.ownerAdapter.init(this.owners, currentUser.UserId);
        this.formGroup = this.fb.group<ICampaignForm>({
            name: new FormControl(campaign?.Name, {
                validators: [Validators.required],
                asyncValidators: this.nameValidator(),
            }),
            description: new FormControl(
                campaign?.Description,
                Validators.required,
            ),
            workflow: new FormControl(null, Validators.required),
            owner: new FormControl(currentUser),
        });
    }

    @withLoading()
    private async save() {
        const { name, description, workflow, owner } = this.formGroup.value;

        const campaignGuid = await this.campaignStore.createCampaign(
            this.data.spaceIdr,
            name,
            description,
            workflow.Guid,
            owner.UserId,
            this.data.objectReferenceIds,
        );
        this.displaySuccessToaster();

        this.functionalLogService.logFunctionalAction(
            'CREATE_CAMPAIGN',
            CrudOperation.C,
        );
        this.result = { campaignGuid };
        this.onCloseSubmit();
    }

    private displaySuccessToaster() {
        if (this.data.hideToasterOnSuccess) {
            return;
        }
        this.toasterService.infoToast({
            titleKey: 'UI.Campaign.Form.Title.Create',
            messageKey: 'UI.Campaign.Form.CampaignCreatedSuccess',
        });
    }

    private setWorkflow(workflow: WorkflowBriefDto) {
        this.formGroup.patchValue({ workflow });
    }

    private async isCampaignNameAlreadyUsed(name: string) {
        return await this.campaignService.campaignExistsWithName(
            this.data.spaceIdr,
            name,
        );
    }

    private nameValidator(): AsyncValidatorFn {
        return (nameControl: FormControl<string>) => {
            this.cd.detectChanges();

            return timer(500).pipe(
                switchMap(() =>
                    from(this.isCampaignNameAlreadyUsed(nameControl.value)),
                ),
                map((result) => {
                    if (!result?.IsCampaignNameUsed) {
                        return null;
                    }
                    const errorMessage = this.translate.instant(
                        'UI.Campaign.Form.NameExists',
                        { name: nameControl.value },
                    );
                    return { nameAlreadyExists: errorMessage };
                }),
            );
        };
    }
}
