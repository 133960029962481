import {
    Directive,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { AttributeFieldInfo } from '../attribute.types';
import { IAttributeBaseInputOverride } from './IAttributeBaseInputOverride';
import { AttributeBaseInputCore } from './AttributeBaseInputCore';
import { TranslateService } from '@ngx-translate/core';
import { IEntityLinksChangeInfo } from '../../entity/services/entity.service';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import {
    AttributeMetaInfo,
    TextQualityVoteStatus,
} from '@datagalaxy/webclient/attribute/domain';

@Directive()
export abstract class DxyAttributeBaseInput<T>
    extends DxyBaseComponent
    implements IAttributeBaseInputOverride, OnInit
{
    /** mandatory. Contains attributeMeta and entityForm */
    @Input() set fieldInfo(value: AttributeFieldInfo) {
        this.core.fieldInfo = value;
    }
    get fieldInfo() {
        return this.core.fieldInfo;
    }
    @Input() mini = false;
    @Input() enablePopover = false;

    /** Emitted when a menu is opened or closed. The argument is true on open. */
    @Output() readonly onPanelOpenClose = new EventEmitter<boolean>();

    //#region html bindings
    public get suggestionGroup$() {
        return this.core.suggestionGroup$;
    }
    public get actionsInput() {
        return this.core.actionsInput;
    }
    public get isActive() {
        return this.core.isActive;
    }
    public get isLabelDisplayed() {
        return this.core.isLabelDisplayed;
    }
    public get showSuggestions() {
        return this.core.showSuggestions;
    }
    public get showTextQualityScore() {
        return this.core.showTextQualityScore;
    }
    public get textQualityUserVote() {
        return this.core.textQualityUserVote;
    }
    //IAttributeBaseInputOverride
    public get isEditEnabled() {
        return this.core.defaultIsEditEnabled;
    }
    public get isCheckControl() {
        return false;
    }
    //#endregion
    public get ngModel() {
        return this.core.getData();
    }
    public set ngModel(value: T) {
        this.core.setData(value);
    }
    public get labelText() {
        return this.isLabelDisplayed ? this.core.translatedDisplayName : '';
    }
    public get readonly() {
        return !this.isEditEnabled;
    }
    public get hint() {
        return this.core.hint;
    }
    public get errorMessage() {
        return this.core.errorMessage;
    }
    public get mandatory() {
        return this.core.mandatory;
    }
    public get description() {
        return this.getDescription();
    }
    public get serverType() {
        return this.entityForm.getEntityData()?.ServerType;
    }
    //#endregion - html bindings

    //#region protected, not overriden
    // IMPORTANT: if overriding, do the same as in other '#region protected, not overriden'
    protected get isValidating() {
        return this.core.isValidating;
    }
    protected get isValidated() {
        return this.core.isValidated;
    }
    protected get hasInternalError() {
        return this.core.hasInternalError;
    }
    protected get attributeMeta() {
        return this.core.attributeMeta;
    }
    protected get attributeKey() {
        return this.core.attributeKey;
    }
    protected get attributePath() {
        return this.core.attributePath;
    }
    protected get attributeType() {
        return this.core.attributeType;
    }
    protected get attributeListValues() {
        return this.core.attributeListValues;
    }
    protected get isAttributeReadOnly() {
        return this.core.isAttributeReadOnly;
    }
    protected get isAttributeMandatory() {
        return this.core.isAttributeMandatory;
    }
    protected get isAttributeCDP() {
        return this.core.isAttributeCDP;
    }
    protected get isAttributeMultiValue() {
        return this.core.isAttributeMultiValue;
    }
    protected get translatedDisplayName() {
        return this.core.translatedDisplayName;
    }
    protected get entityForm() {
        return this.core.entityForm;
    }
    protected get hasLoadReferenceOptions() {
        return this.core.hasLoadReferenceOptions;
    }
    protected get formKind() {
        return this.core.formKind;
    }
    /** This is the standard entity form used in the entity-details component */
    protected get isGenericEntityForm() {
        return this.core.isGenericEntityForm;
    }
    protected get isBulkForm() {
        return this.core.isBulkForm;
    }
    //#endregion - protected, not overriden

    private core: AttributeBaseInputCore<T>;

    protected constructor(
        protected elementRef: ElementRef<HTMLElement>,
        protected translate: TranslateService,
    ) {
        super();
        this.core = new AttributeBaseInputCore(
            this as IAttributeBaseInputOverride,
            (subject, action) => this.subscribe(subject, action),
            (logId) => (this.logId = logId),
            this.debug,
            (...args) => this.log('(core)', ...args),
        );
    }

    ngOnInit() {
        this.core.init();
    }

    public onFieldFocus(event: FocusEvent) {
        this.core.onFieldFocus(event);
    }
    public onFieldBlur(event: FocusEvent) {
        this.core.onFieldBlur(event);
    }

    //#region IAttributeBaseInputOverride
    // Those methods are called by core, and may be overriden by subclass

    public emitNativeChangeEvent() {
        if (!this.elementRef?.nativeElement?.dispatchEvent) {
            return;
        }
        this.log('emitNativeChangeEvent');
        this.elementRef.nativeElement.dispatchEvent(new Event('change'));
    }

    //#region using core default methods
    /** called when the attribute's field is focused */
    public async setAttributeActive() {
        this.core.defaultSetAttributeActive();
    }
    public isDirty() {
        return this.core.defaultIsDirty();
    }
    public equals(v1: any, v2: any) {
        return this.core.defaultEquals(v1, v2);
    }
    public canValidateOnBlur(event: Event) {
        return this.core.defaultCanValidateOnBlur(event);
    }
    public isEmpty() {
        return this.core.defaultIsEmpty();
    }
    public async onAttributeValueChange(
        attributeMeta: AttributeMetaInfo,
        linkChangeInfo?: IEntityLinksChangeInfo,
    ) {
        return this.core.defaultOnAttributeValueChange(
            attributeMeta,
            linkChangeInfo,
        );
    }
    //#endregion

    //#region using hard-coded value
    public isValid(_newValue: any, _onSetData = true) {
        return true;
    }
    public isMandatoryAttributeEmpty() {
        return false;
    }
    /** called by validate(). validateInternal() is then executed only if true is returned */
    public async onBeforeValidate() {
        return true;
    }
    //#endregion

    //#region empty methods
    public onBeforeRestorePreviousValueForCompareEntityForm() {}
    public toggleDisplayToolBar() {}
    /** called by setActive() when active is true */
    public onBeforeSetActive() {}
    /** called by validateInternal() */
    public onServerError(_result: any) {}
    /** called by validateInternal() */
    public async onServerSuccess(_result: any) {}
    /** called by validate()
     * NOTE: *validated* is the result of onBeforeValidate(), true means validateInternal() has been executed */
    public onAfterValidate(_validated: boolean) {}
    /** called by undo() */
    public onAfterUndo() {}
    /** called (by setAttributeActive(), and dxy-entity-attribute) to make the field to take focus */
    public focusField() {}
    /** called by onEnterKeyDown (after *validate()*) to make the field to blur */
    public blurField() {}
    //#endregion

    //#endregion - IAttributeBaseInputOverride

    //#region protected, not overriden

    /** IMPORTANT: if overriding one of those methods:
     * - make it public
     * - add it to IAttributeBaseInputOverride interface
     * - move it from here to the region IAttributeBaseInputOverride above
     * - if core uses it internally, replace those usages by host.<MethodName>
     * - in core, rename the method to default<MethodName>, and make it called only by DxyAttributeBaseInput
     */

    /** Sets the internal error state and message */
    protected setInternalError(message: string) {
        this.core.setInternalError(message);
    }
    /** Clears the internal error state and message */
    protected clearInternalError() {
        this.core.clearInternalError();
    }
    protected getEntityData() {
        return this.core.getEntityData();
    }
    protected getEntityDataList() {
        return this.core.getEntityDataList();
    }
    protected setFormValid(valid: boolean) {
        this.core.setFormValid(valid);
    }
    protected getAttributeValue<T>(attributeKey: string) {
        return this.core.getAttributeValue(attributeKey) as T;
    }
    protected async setAttributeValue(
        value: T,
        attributeKey = this.attributeKey,
    ) {
        await this.core.setAttributeValue(value, attributeKey);
    }
    protected setCurrentAttributeKey(attributeKey: string) {
        this.core.setCurrentAttributeKey(attributeKey);
    }
    protected getSpaceIdr() {
        return this.core.getSpaceIdr();
    }
    protected getDescription() {
        return this.core.getDescription();
    }
    protected async onEnterKeyDown() {
        await this.core.onEnterKeyDown();
    }
    protected resetInitialValue() {
        this.core.resetInitialValue();
    }
    protected resetMessages() {
        this.core.resetMessages();
    }
    protected getInitialValue() {
        return this.core.getInitialValue();
    }
    protected setInitialValue(value: T, isForced = false) {
        this.core.setInitialValue(value, isForced);
    }
    protected getData() {
        return this.core.getData();
    }
    protected async setData(newValue: T) {
        await this.core.setData(newValue).then();
    }
    protected async loadReferenceOptions() {
        return this.core.loadReferenceOptions();
    }
    protected getObjectData() {
        return this.core.getObjectData();
    }
    protected onTextQualityUserVote(event: TextQualityVoteStatus) {
        this.core.onTextQualityUserVote(event);
    }
    protected async validate() {
        return this.core.validate();
    }
    protected undo() {
        this.core.undo();
    }
    //#endregion - protected, not overriden
}
