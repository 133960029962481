import {
    Component,
    ElementRef,
    Inject,
    OnInit,
    ViewChild,
} from '@angular/core';
import {
    DialogType,
    DxyBaseModalComponent,
    IModalPosition,
} from '@datagalaxy/ui/dialog';
import * as moment from 'moment';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import {
    DxyActionListComponent,
    DxyDgDateTimeCellComponent,
    executeOnce,
    IActionOption,
    withLoading,
} from '@datagalaxy/core-ui';
import {
    MAT_DIALOG_DATA,
    MatDialogModule,
    MatDialogRef,
} from '@angular/material/dialog';
import { ITimeSeriesModalInput, ITimeSeriesRow } from '../time-series.types';
import { EntityService } from '../../shared/entity/services/entity.service';
import { ImportWizardService } from '../../import/services/import-wizard.service';
import { EntityUiService } from '../../shared/entity/services/entity-ui.service';
import { EntityDockingPaneService } from '../../shared/entity/services/entity-docking-pane.service';
import { DxyModalService } from '../../shared/dialogs/DxyModalService';
import { TimeSeriesUiService } from '../../services/time-series-ui.service';
import { ImportContext, ImportTarget } from '../../import/shared/ImportContext';
import { EOperationType } from '../../import/shared/EOperationType';
import { ImportModule } from '../../import/shared/ImportModule';
import { TimeSeriesGraphHelper } from '../TimeSeriesGraphHelper';
import {
    UpdateTimeSeriesAttributeEntryAction,
    UpdateTimeSeriesEntryDto,
} from '@datagalaxy/webclient/attribute/data-access';
import {
    UpdateAttributeAction,
    UpdateEntityAttributeResult,
} from '@datagalaxy/webclient/entity/data-access';
import {
    CrudActionType,
    CrudOperation,
    FunctionalLogService,
} from '@datagalaxy/shared/monitoring/data-access';
import { CoreUtil, DateTimeUtil } from '@datagalaxy/core-util';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';
import {
    TimeSeriesEntry,
    TimeSeriesObject,
} from '@datagalaxy/webclient/attribute/domain';
import {
    GridCellType,
    GridComponent,
    GridConfig,
    TColDef,
} from '@datagalaxy/ui/grid';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';
import { MatLegacyMenuModule } from '@angular/material/legacy-menu';
import { NgIf } from '@angular/common';
import { WorkspaceStore } from '@datagalaxy/webclient/workspace/data-access';
import {
    DxyButtonDirective,
    DxyIconButtonDirective,
} from '@datagalaxy/ui/buttons';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'dxy-time-series-modal',
    templateUrl: './dxy-time-series-modal.component.html',
    styleUrls: ['./dxy-time-series-modal.component.scss'],
    standalone: true,
    imports: [
        MatDialogModule,
        TranslateModule,
        DxyIconButtonDirective,
        DxyButtonDirective,
        NgIf,
        MatLegacyMenuModule,
        MatLegacyTooltipModule,
        GridComponent,
    ],
})
export class DxyTimeSeriesModalComponent
    extends DxyBaseModalComponent<ITimeSeriesModalInput, TimeSeriesObject>
    implements OnInit
{
    @ViewChild(GridComponent) grid: GridComponent<ITimeSeriesRow>;

    protected gridConfig: GridConfig<ITimeSeriesRow> = {
        getItemId: (item) => item.id,
        autoHeight: true,
    };

    protected cols: TColDef<ITimeSeriesRow>[] = [
        {
            id: 'date',
            headerLabel: this.translate.instant(
                'UI.EntityTimeSeriesHistoryModal.dateColumn',
            ),
            minWidth: 300,
            width: 300,
            resizable: false,
            fixed: true,
            sortable: false,
            type: GridCellType.custom,
            customCellComponent: DxyDgDateTimeCellComponent,
            getInputs: (row) =>
                ({
                    dgDate: row.entry.Date,
                    useDgFormat: true,
                    noTime: true,
                }) as Partial<DxyDgDateTimeCellComponent<ITimeSeriesRow>>,
        },
        {
            id: 'value',
            headerLabel: this.translate.instant(
                'UI.EntityTimeSeriesHistoryModal.valueColumn',
            ),
            type: GridCellType.text,
            getValue: (row) => row.entry.Value?.toString(),
            minWidth: 100,
            width: 100,
            resizable: false,
            fixed: true,
            sortable: false,
        },
        {
            id: 'actions',
            headerLabel: '',
            type: GridCellType.custom,
            customCellComponent: DxyActionListComponent,
            getInputs: (row) =>
                ({
                    actions: this.actions,
                    data: row,
                }) as Partial<DxyActionListComponent<ITimeSeriesRow>>,
            minWidth: 100,
            width: 100,
            resizable: false,
            fixed: true,
            sortable: false,
        },
    ];

    protected rows: ITimeSeriesRow[] = [];

    public get hasValues() {
        return this.tsoValue?.Entries?.length > 0;
    }
    public get isEditEnabled() {
        return this.entity.SecurityData.HasWriteAccess && !this.data.readonly;
    }
    public get canAddValue() {
        return !this.tsoValue?.Entries || this.tsoValue?.Entries?.length < 1000;
    }

    private actions: IActionOption<ITimeSeriesRow>[] = [
        {
            glyphClass: 'glyph-edit',
            callback: (data) => this.onCreateOrEdit(false, data),
            hidden: (data) => data.isEditing,
            alwaysVisible: true,
            tooltipText: this.translate.instant(
                'UI.EntityTimeSeriesHistoryModal.updateValueTT',
            ),
        },
        {
            glyphClass: 'glyph-delete',
            buttonColor: 'destructive',
            callback: (data) => this.deleteTimeSeriesValue(data),
            hidden: (data) => data.isEditing,
            alwaysVisible: true,
            tooltipText: this.translate.instant(
                'UI.EntityTimeSeriesHistoryModal.deleteValueTT',
            ),
        },
    ];

    private get attributeMeta() {
        return this.data?.attributeMeta;
    }
    private get tsoValue() {
        return this.data?.data;
    }
    private get entity() {
        return this.data?.entity;
    }

    constructor(
        private elementRef: ElementRef<HTMLElement>,
        private translate: TranslateService,
        private entityService: EntityService,
        private workspaceStore: WorkspaceStore,
        private importWizardService: ImportWizardService,
        private entityDockingPaneService: EntityDockingPaneService,
        private entityUiService: EntityUiService,
        private dxyModalService: DxyModalService,
        private functionalLogService: FunctionalLogService,
        private timeSeriesUiService: TimeSeriesUiService,
        dialogRef: MatDialogRef<DxyTimeSeriesModalComponent, TimeSeriesObject>,
        @Inject(MAT_DIALOG_DATA) data: ITimeSeriesModalInput,
    ) {
        super(dialogRef, data);
    }

    ngOnInit() {
        if (!this.isEditEnabled) {
            this.actions = null;
        }
        this.dialogRef.afterOpened().subscribe(() => this.render(true).then());
    }

    @executeOnce()
    @withLoading()
    public async onCreateOrEdit(isCreate: boolean, row?: ITimeSeriesRow) {
        let position: IModalPosition;
        if (!isCreate) {
            const rowRect = this.grid.getRowRectPosition(row?.id);
            position = { top: `${rowRect.top - 25}px` };
        }
        try {
            this.onPanelOpenClose(true);
            const result =
                await this.timeSeriesUiService.openTimeSeriesEditModal(
                    this.entity,
                    this.tsoValue?.Entries,
                    row?.entry,
                    this.attributeMeta,
                    isCreate,
                    isCreate ? null : position,
                );
            this.onPanelOpenClose(false);

            if (result) {
                this.refreshData(
                    result.updatedTimeSeriesObject,
                    true,
                    result.updatedEntry,
                );
            }
        } catch (e) {
            this.onPanelOpenClose(false);
            CoreUtil.warn(e);
        }
    }

    public async onClickImport() {
        const importContext = new ImportContext(
            this.workspaceStore.currentSpace,
            ImportTarget.TimeSeries,
        );
        importContext.currentOperation = EOperationType.TimeSeries;
        importContext.attributeKey = this.attributeMeta.AttributeKey;
        importContext.entityReferenceId = this.entity.ReferenceId;
        importContext.onModalClosed = (isImportDone: boolean) => {
            if (isImportDone) {
                this.functionalLogService.logFunctionalAction(
                    'TIMESERIES_VALUES',
                    CrudOperation.A,
                    CrudActionType.Import,
                );
                this.entityService.getEntity(this.entity).then((result) => {
                    const timeSeriesObject = result.getTypedAttributeValue(
                        this.attributeMeta,
                    ) as TimeSeriesObject;

                    if (timeSeriesObject) {
                        this.result = timeSeriesObject;
                        this.refreshData(timeSeriesObject, true);
                    }
                });
            }
        };
        importContext.currentModule = ImportModule.getModule(
            EOperationType.TimeSeries,
            importContext,
        );
        await this.importWizardService.openImportWizardModal(importContext);
    }

    public async onClickDeleteAll() {
        const confirmed =
            await this.entityUiService.confirmDeleteTimeSeriesValuesModal();
        if (!confirmed) {
            return;
        }
        const result = await this.entityService.updateEntity(
            this.entity,
            this.attributeMeta.AttributeKey,
            null,
            {
                action: UpdateAttributeAction.DeleteTimeSeriesAllValues,
                includeQuality:
                    this.entityDockingPaneService.isInsightsPaneVisible(),
            },
        );
        const newValue = (
            result as UpdateEntityAttributeResult
        ).UpdatedEntity.getTypedAttributeValue(
            this.attributeMeta,
        ) as TimeSeriesObject;
        this.entity.setAttributeValue(
            this.attributeMeta.AttributeKey,
            newValue,
        );
        this.refreshData(newValue);
    }

    public onCloseCancel() {
        this.result = this.tsoValue;
        super.onCloseSubmit();
    }

    public export() {
        this.entityService.exportEntityTimeSeriesAttribute(
            this.entity,
            this.attributeMeta.AttributeKey,
        );
    }

    public getDisableAddValueTooltip() {
        if (!this.canAddValue) {
            return this.translate.instant(
                'UI.EntityTimeSeriesHistoryModal.disabledAddBtnTT',
            );
        }
    }

    private refreshGrid(rows: ITimeSeriesRow[]) {
        this.rows = rows;
    }

    private async render(refreshRows: boolean, entryToFocus?: TimeSeriesEntry) {
        if (refreshRows) {
            const data: ITimeSeriesRow[] = this.tsoValue?.Entries?.map(
                (entry, index) => ({
                    id: index.toString(),
                    entry: {
                        Value: entry.Value,
                        Date: moment(entry.Date).toDate(),
                    },
                }),
            );
            this.refreshGrid(data);

            if (entryToFocus) {
                const row = data.find((d) =>
                    moment(d.entry.Date).isSame(entryToFocus.Date),
                );
                setTimeout(() => this.grid.scrollToIndex(row.id));
            }
        }

        if (this.hasValues) {
            setTimeout(() => {
                TimeSeriesGraphHelper.drawTimeSeriesGraph(
                    this.tsoValue,
                    this.attributeMeta.TimeSeriesFrequency,
                    this.elementRef.nativeElement.querySelector(
                        '.time-series-graph',
                    ),
                );
            });
        }
    }

    private async deleteTimeSeriesValue(data: ITimeSeriesRow) {
        const param: UpdateTimeSeriesEntryDto = {
            Date: DateTimeUtil.getStringDate(data.entry.Date),
            Value: data.entry.Value,
            Action: UpdateTimeSeriesAttributeEntryAction.DeleteEntry,
        };
        const confirmed: boolean = await this.dxyModalService.confirm({
            titleKey: 'UI.EntityTimeSeriesHistoryModal.deleteValue',
            messageKey: 'UI.EntityTimeSeriesHistoryModal.deleteValueMessage',
            type: DialogType.Action,
            confirmButtonKey: 'UI.Dialog.btnDelete',
        });

        if (confirmed) {
            const result = await this.entityService.updateEntity(
                this.entity,
                this.attributeMeta.AttributeKey,
                param,
                {
                    action: UpdateAttributeAction.SetValue,
                    includeQuality:
                        this.entityDockingPaneService.isInsightsPaneVisible(),
                },
            );
            this.rows = this.rows.filter((r) => r.id !== data.id);
            this.refreshGrid([...this.rows]);
            this.refreshData(result.UpdatedEntities[0]);
        }
    }

    private refreshData(
        data: EntityItem | TimeSeriesObject,
        refreshRows?: boolean,
        entryToFocus?: TimeSeriesEntry,
    ) {
        this.data.data =
            data instanceof EntityItem
                ? (data.getTypedAttributeValue(
                      this.attributeMeta,
                  ) as TimeSeriesObject)
                : data;
        this.elementRef.nativeElement.querySelector('svg')?.remove();
        this.render(refreshRows, entryToFocus);
    }
}
