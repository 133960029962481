import { NgModule, Optional, SkipSelf } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { httpInterceptors } from '../http-interceptors';
import { BackendHttpClient } from './backend-http-client.service';
import { BackendApiService } from './backend-api.service';

@NgModule({
    providers: [...httpInterceptors, BackendHttpClient, BackendApiService],
    imports: [HttpClientModule],
})
export class BackendApiModule {
    constructor(@Optional() @SkipSelf() parentModule?: BackendApiModule) {
        if (!parentModule) {
            return;
        }
        throw new Error(
            'BackendApiModule is already loaded. Import it in the AppModule only',
        );
    }
}
