<button
    #matMenuTrigger="matMenuTrigger"
    [matMenuTriggerFor]="menu"
    dxyIconButton
    dxyDropdownButton
    [hideDropdownIcon]="!caretIconInButton"
    [variant]="buttonVariant"
    [color]="buttonColor"
    [size]="small ? 'small' : 'medium'"
    [class.hidden]="hidden"
    (click)="onClickTrigger($event)"
    (mousedown)="onMouseDown($event)"
    [matTooltip]="tooltip"
    (menuOpened)="openClose.emit(true)"
    (menuClosed)="openClose.emit(false)"
    [dxyDataTestId]="dataTestId"
>
    <span [class]="btnClass"></span>
</button>

<mat-menu
    #menu="matMenu"
    class="dg5-mat-menu no-max-width"
    [class]="menuClass"
    xPosition="before"
>
    <ng-container *ngIf="matMenuTrigger.menuOpen">
        <dxy-burger-menu
            [options]="options"
            [data]="data"
            (onLogFunctional)="onLogFunctional.emit($event)"
        >
            <ng-content select="[menu-header]" menu-header></ng-content>
        </dxy-burger-menu>
    </ng-container>
</mat-menu>
