import {
    deserialize,
    deserializeAs,
    inheritSerialization,
    serialize,
} from 'cerialize';
import {
    BaseServiceParameter,
    BaseServiceResult,
} from '@datagalaxy/data-access';
import { ItemUsage } from '@datagalaxy/dg-object-model';
import {
    AttributeDTO,
    AttributeMetaInfo,
    AttributeMetaType,
    TimeSeriesColorRule,
    TimeSeriesFrequency,
} from '@datagalaxy/webclient/attribute/domain';

@inheritSerialization(BaseServiceParameter)
export class CreateAttributeParameter extends BaseServiceParameter {
    @serialize public ModuleName: string;
    @serialize public AttributeType: string;
    @serialize public DisplayName: string;
    @serialize public Description: string;
    @serialize public DefaultValue: any;
    @serialize public IsRecommended?: boolean;
    @serialize public IsMandatory?: boolean;

    constructor(
        moduleName: string,
        attributeType: AttributeMetaType,
        displayName: string,
        description: string,
    ) {
        super();
        this.ModuleName = moduleName;
        this.DisplayName = displayName;
        this.AttributeType = AttributeMetaType[attributeType];
        this.Description = description;
    }
}

@inheritSerialization(BaseServiceParameter)
export class UpdateAttributeParameter extends BaseServiceParameter {
    @serialize public ModuleName: string;
    @serialize public AttributeKey: string;
    @serialize public DisplayName!: string;
    @serialize public Description!: string;
    @serialize public DefaultValue: any;
    @serialize public IsRecommended?: boolean;
    @serialize public IsMandatory?: boolean;
    @serialize public IsActive?: boolean;
    @serialize public IsIncludedInGlobalSearch?: boolean;
    @serialize public IsExactMatchIndexed?: boolean;
    @serialize public ResetDefaultValue!: boolean;

    @serialize public TimeSeriesFrequency?: TimeSeriesFrequency;
    @serialize public TimeSeriesColorRule?: TimeSeriesColorRule;

    constructor(moduleName: string, attributeKey: string) {
        super();
        this.ModuleName = moduleName;
        this.AttributeKey = attributeKey;
    }
}

@inheritSerialization(BaseServiceResult)
export class GenericAttributeResult extends BaseServiceResult {
    @deserializeAs(AttributeDTO) public Attribute!: AttributeDTO;
    @deserialize public AvailableGlobalSearchAttributeCount!: number;
}

@inheritSerialization(BaseServiceParameter)
export class DeleteAttributeParameter extends BaseServiceParameter {
    @serialize public ModuleName: string;
    @serialize public AttributeKey: string;

    constructor(moduleName: string, attributeKey: string) {
        super();
        this.ModuleName = moduleName;
        this.AttributeKey = attributeKey;
    }
}

@inheritSerialization(BaseServiceParameter)
export class GetAttributesParameter extends BaseServiceParameter {
    @serialize public DataTypeNames: string[];
    @serialize public IncludeReferences: boolean;
    @serialize public IncludeComputed: boolean;
    @serialize public IncludeObjectValues: boolean;
    @serialize public IncludedAttributesFilter!: string[];

    constructor(
        dataTypeNames: string[],
        includeReferences: boolean,
        includeComputed: boolean,
        includeObjectValues: boolean,
    ) {
        super();
        this.IncludeReferences = includeReferences;
        this.IncludeComputed = includeComputed;
        this.IncludeObjectValues = includeObjectValues;
        this.DataTypeNames = dataTypeNames;
    }
}

@inheritSerialization(BaseServiceResult)
export class GetAttributesResult extends BaseServiceResult {
    @deserializeAs(AttributeMetaInfo) public Attributes!: AttributeMetaInfo[];
    @deserialize public IsErrorUnknownDataTypeName!: string;
}

@inheritSerialization(BaseServiceParameter)
export class GetModuleAttributesParameter extends BaseServiceParameter {
    @serialize public ModuleName: string;
    @serialize public IncludeSystemAttributes: boolean;
    @serialize public IncludeListValues: boolean;

    constructor(moduleName: string, includeListValues: boolean) {
        super();
        this.ModuleName = moduleName;
        this.IncludeListValues = includeListValues;
        this.IncludeSystemAttributes = true;
    }
}

@inheritSerialization(BaseServiceResult)
export class GetModuleAttributesResult extends BaseServiceResult {
    @deserializeAs(AttributeDTO) public Attributes!: AttributeDTO[];
    @deserialize public AvailableGlobalSearchAttributeCount!: number;
}

@inheritSerialization(ItemUsage)
export class AttributeUsage extends ItemUsage {
    @deserialize public IsLastQuickFilter!: boolean;
}

@inheritSerialization(BaseServiceResult)
export class PreDeleteAttributeResult extends BaseServiceResult {
    @deserialize public AttributePath!: string;
    @deserialize public CanBeDeleted!: boolean;
    @deserializeAs(AttributeUsage) Usages!: Array<AttributeUsage>;
}
