import { BaseType, select } from 'd3-selection';
import { drag as d3drag } from 'd3-drag';
import {
    Dom2dUtil,
    IShift,
    IWidthHeight,
    IXYRectRO,
    Rect,
} from '@datagalaxy/core-2d-util';
import { ID3DragEvent } from '../../../D3Helper';
import { DomUtil } from '@datagalaxy/core-util';
import { GraphManager } from '../../graph-manager';
import { BaseGraphicalManager } from '../../BaseGraphicalManager';
import { PanZoomTool } from '../pan-zoom';
import { Viewport } from '../viewport';
import { MinimapOptions } from './minimap.types';

/**
 * ## Role:
 * Produce dom elements for a mini visualisation of a set of rectangles in a rectangular view
 * ## Features
 * - elements scaling and positioning
 * - draggable viewbox
 */
export class MinimapTool extends BaseGraphicalManager {
    private elOuter: HTMLDivElement;
    private elInner: HTMLDivElement;
    private elViewbox: HTMLDivElement;
    private scaleFactor: number;
    private minSize: IWidthHeight;
    private margin: number;
    private bb: Rect;
    private shift: IShift;
    private _active: boolean;

    public get active() {
        return this._active;
    }
    public set active(active: boolean) {
        this._active = active;
        if (active) {
            setTimeout(() => this.activate(), 300);
        } else {
            super.dispose();
        }
    }

    constructor(
        containerSelector: HTMLElement | string,
        private graph: GraphManager,
        private viewport: Viewport,
        private zoom: PanZoomTool,
        options?: MinimapOptions,
    ) {
        super();
        if (!containerSelector) {
            return;
        }
        const container = DomUtil.getElementOrSelector(containerSelector);
        if (!container) {
            throw Error(`minimap container not found ${containerSelector}`);
        }

        this.elOuter?.remove();
        this.margin = options?.margin ?? 5;
        this.minSize = options?.minSize ?? { width: 2, height: 2 };
        this.elInner = DomUtil.createElement('div', 'mm-rects');
        this.elViewbox = DomUtil.createElement('div', ['mm', 'view']);
        this.elOuter = DomUtil.createElement(
            'div',
            'zs-minimap-content',
            this.elInner,
            this.elViewbox,
        );
        container.append(this.elOuter);
        const onDrag = (e: ID3DragEvent) => {
            const f = this.scaleFactor;
            this.panWithScale(e.dx / f, e.dy / f);
        };
        const drag = d3drag().on('drag', onDrag);
        select(this.elViewbox).call(drag);
    }
    public dispose() {
        super.dispose();
        this.elOuter?.remove();
        this.clearRects();
    }

    /** update the viewbox element and, if provided, the rectangles elements */
    private activate() {
        const viewport = this.viewport.vb;
        this.updateRects(this.graph.nodes.map((node) => node.rect));
        this.draw(this.elViewbox, viewport);

        super.subscribe(this.graph.nodesEvents$, () => {
            const rects = this.graph.nodes.map((node) => node.rect);
            if (!rects?.length) {
                return;
            }
            this.updateRects(rects);
        });
        super.subscribe(this.zoom.zoomed$, () => {
            this.draw(this.elViewbox, this.viewport.vb);
        });
    }

    private clearRects() {
        select(this.elInner).selectAll('div.mm.r').remove();
    }

    private scale(r: IXYRectRO) {
        return Rect.from(r).transformSelf(
            (x) => (x - this.bb.x) * this.scaleFactor,
            (y) => (y - this.bb.y) * this.scaleFactor,
        );
    }

    private adapt(r: IXYRectRO) {
        return this.scale(r)
            .shift(this.shift)
            .augment(-2 * this.margin)
            .clampSize(this.minSize)
            .round();
    }

    private draw(el: HTMLDivElement | BaseType, r: IXYRectRO) {
        requestAnimationFrame(() =>
            Dom2dUtil.setBounds(el as HTMLDivElement, this.adapt(r), true),
        );
    }

    private updateRects(rects?: IXYRectRO[]) {
        // get the drawing surface size
        const size = Rect.from(this.elOuter.getBoundingClientRect());
        // compute bounding box & scale
        this.bb = Rect.boundingBox(rects);
        this.scaleFactor = this.bb.scaleFactorToFit(size);
        // scale to fit the bounding box into the drawing surface
        const scaledbb = this.scale(this.bb);
        // center bounding box in the drawing surface
        this.shift = {
            dx: (size.width - scaledbb.width) / 2,
            dy: (size.height - scaledbb.height) / 2,
        };
        // remove + create rect elements
        const self = this;
        select(this.elInner)
            .selectAll('div.mm.r')
            .data(rects)
            .join('div')
            .attr('class', 'mm r')
            .each(function (r) {
                self.draw(this, r);
            });
    }

    /** moves the view the given amount (unzoomed), leaving the zoom factor unchanged */
    private panWithScale(dx: number, dy: number, durationMs = 0) {
        this.zoom.translateBy(-dx, -dy, durationMs);
    }
}
