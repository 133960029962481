import { DefaultLogEntryAdapter } from './default-log-entry-adapter';
import { IActivityLogEntryTranslationData } from './activity-log-entry-translation.type';
import {
    HierarchicalData,
    HierarchyDataDescriptor,
} from '@datagalaxy/dg-object-model';
import {
    ActivityLogEntry,
    ActivityLogEntryType,
} from '@datagalaxy/webclient/activity-log/domain';
import { AttributeMetaType } from '@datagalaxy/webclient/attribute/domain';
import { isGuid } from '@datagalaxy/utils';
import { ServerConstants } from '@datagalaxy/shared/server/domain';
import PropertyName = ServerConstants.PropertyName;

/**
 * ## Role
 * Activity log adapter for logs concerning tasks
 */
export class TaskLogEntryAdapter extends DefaultLogEntryAdapter {
    protected override getTranslateKey(entry: ActivityLogEntry) {
        const entryType = entry.EntryType;
        const key =
            entryType === ActivityLogEntryType.AddObject ||
            entryType === ActivityLogEntryType.Create
                ? 'addTask'
                : entryType == ActivityLogEntryType.Update
                  ? 'updateTask'
                  : entryType == ActivityLogEntryType.DeleteObject
                    ? 'deleteTask'
                    : '';
        return key && `${this.baseTranslateKey}${key}`;
    }

    protected override getHData(entry: ActivityLogEntry) {
        const hdd = entry.Parents.filter((p) => p.DisplayName)?.[0];
        return hdd && new HierarchicalData(hdd, entry.Parents);
    }

    protected override getChildHData(entry: ActivityLogEntry) {
        const task = entry.ChildData ?? entry.Data;
        const hdd = this.getUsedDataDescriptor(entry, task);
        return hdd && new HierarchicalData(hdd, entry.Parents);
    }

    protected override setUpdateData(
        entry: ActivityLogEntry,
        data: IActivityLogEntryTranslationData,
    ) {
        const isAssignedToUserUpdate =
            entry.PropertyName == PropertyName.TaskAssignedToUser;
        const key = isAssignedToUserUpdate
            ? 'updateTaskAssignedUser'
            : 'updateTaskWithOldValue';
        this.setUpdateTranlateParams(key, entry, data);
    }

    protected override getPropertyNameTranslation(
        entry: ActivityLogEntry,
        hierarchyDataDescriptor: HierarchyDataDescriptor,
    ) {
        if (entry.PropertyName == PropertyName.TaskResponse) {
            return this.translate.instant('UI.TaskContainer.lblTaskResponse');
        }
        if (entry.PropertyName == PropertyName.Title) {
            return this.translate.instant(
                'UI.TaskContainer.lblPlaceHolderTitle',
            );
        }
        return super.getPropertyNameTranslation(entry, hierarchyDataDescriptor);
    }

    protected override getPropertyValueTranslation(
        entry: ActivityLogEntry,
        isForOldValue: boolean,
    ): string {
        const value = isForOldValue
            ? entry.OldValueString
            : entry.NewValueString;
        switch (entry.attributeType) {
            case AttributeMetaType.Text:
                if (
                    entry.PropertyName == PropertyName.TaskAssignedToUser &&
                    isGuid(value)
                ) {
                    return this.userService.getUserName(value);
                }
                return value;
            case AttributeMetaType.ValueList: {
                const attributeName = entry.PropertyName;
                if (attributeName === PropertyName.TaskStatus) {
                    return this.translate.instant(
                        `UI.TaskContainer.StatusTabs.${value}`,
                    );
                }
                if (attributeName === PropertyName.TaskType) {
                    return this.translate.instant(
                        `DgServerTypes.ObjectTaskType.${value}`,
                    );
                }
                const attribute = this.attributeDataService.getAttribute(
                    entry.ServerType,
                    attributeName,
                );
                return this.attributeDataService.getSystemAttributeValueListOptionDisplayName(
                    attribute.ListTypeName,
                    value,
                );
            }
            default:
                return super.getPropertyValueTranslation(entry, isForOldValue);
        }
    }
}
