import * as moment from 'moment';
import { CoreUtil } from './core-util';

export class DateTimeUtil {
    static readonly cdpDateFormat = 'YYYY-MM-DD';

    static getFromIso(value: string, onlyIfValid = false) {
        const m = value && moment(value);
        return !m || (onlyIfValid && !m.isValid()) ? undefined : m;
    }

    static getFromUser(
        userValue: string,
        momentUserFormat: string,
        onlyIfValid = false,
    ) {
        const m = moment(userValue, momentUserFormat).local();
        return onlyIfValid && !m.isValid() ? undefined : m;
    }

    static getUtcFromUser(
        userValue: string,
        momentUserFormat: string,
        onlyIfValid = false,
    ) {
        const m = moment(userValue, momentUserFormat).utc();
        return onlyIfValid && !m.isValid() ? undefined : m;
    }

    static getValidMoment(
        mi: moment.Moment | moment.MomentInput,
        locale?: string,
    ) {
        if (!mi) {
            return;
        }
        if (!locale) {
            locale =
                typeof (mi as moment.Moment).locale == 'function'
                    ? (mi as moment.Moment).locale()
                    : moment.locale();
        }
        const m = moment(mi).locale(locale);
        if (!m.isValid()) {
            return;
        }
        return m;
    }

    /* fr: 11:43  en: 11:43 AM */
    static getFormatTime() {
        return 'LT';
    }

    /** with *numbers* false (default):
     * - withTimePart true:   fr: 4 octobre 2019 11:43    en: October 4, 2019 11:43 AM
     * - withTimePart false:  fr: 4 octobre 2019          en: October 4, 2019
     * - customDgFormat true: fr: 4 Fev 2021              en: Feb 4th, 2021
     *
     * with *numbers* true:
     * - withTimePart true:   fr: 04/10/2019 11:43:34     en: 10/04/2019 11:43:34 PM
     * - withTimePart false:  fr: 04/10/2019              en: 10/04/2019
     * - customDgFormat true: fr: 4 Fev 2021              en: Feb 4th, 2021
     */
    static getFormatForDisplay(
        withTimePart: boolean,
        locale?: string,
        customDgFormat = false,
        numbers = false,
    ): string {
        if (customDgFormat) {
            return locale == 'en'
                ? withTimePart
                    ? 'MMM Do YYYY HH:mm'
                    : 'MMM Do YYYY'
                : withTimePart
                  ? 'Do MMM YYYY HH:mm'
                  : 'Do MMM YYYY';
        }
        return withTimePart
            ? numbers
                ? locale == 'fr'
                    ? 'L HH:mm:ss'
                    : 'L LTS'
                : 'LLL'
            : numbers
              ? 'L'
              : 'LL';
    }

    static format(m: moment.Moment, format: string) {
        return format && m && m.isValid() ? m.local().format(format) : '';
    }

    static formatTime(mi: moment.Moment | moment.MomentInput, locale?: string) {
        const m = DateTimeUtil.getValidMoment(mi, locale);
        const f = DateTimeUtil.getFormatTime();
        return DateTimeUtil.format(m, f);
    }

    /**
     * Note: if *locale* is not specified, Moment's current locale will be used.
     *
     * with *numbers* false (default):
     * - withTimePart true:   fr: 4 octobre 2019 11:43    en: October 4, 2019 11:43 AM
     * - withTimePart false:  fr: 4 octobre 2019          en: October 4, 2019
     * - customDgFormat true: fr: 4 Fev 2021              en: Feb 4th, 2021
     *
     * with *numbers* true:
     * - withTimePart true:   fr: 04/10/2019 11:43:34     en: 10/04/2019 11:43:34 PM
     * - withTimePart false:  fr: 04/10/2019              en: 10/04/2019
     * - customDgFormat true: fr: 4 Fev 2021              en: Feb 4th, 2021
     */
    static formatForDisplay(
        mi: moment.Moment | moment.MomentInput,
        withTimePart: boolean,
        locale?: string,
        useDgFormat?: boolean,
        numbers = false,
    ): string {
        const m = DateTimeUtil.getValidMoment(mi, locale);
        const f = DateTimeUtil.getFormatForDisplay(
            withTimePart,
            locale,
            useDgFormat,
            numbers,
        );
        return DateTimeUtil.format(m, f);
    }

    static compareIsoStrings(isoA: string, isoB: string) {
        const mA = DateTimeUtil.getFromIso(isoA, true);
        const mB = DateTimeUtil.getFromIso(isoB, true);
        return mA && mB ? mA.diff(mB) : mA ? 1 : mB ? -1 : 0;
    }
    static isoStringsEqual(isoA: string, isoB: string) {
        const mA = DateTimeUtil.getFromIso(isoA, true);
        const mB = DateTimeUtil.getFromIso(isoB, true);
        return (!mA && !mB) || (mA && mB && mA.isSame(mB));
    }

    static trimTime(m: moment.Moment, noHourMinute = false, noSecond = false) {
        if (m) {
            m.millisecond(0);
            if (noSecond) {
                m.second(0);
            }
            if (noHourMinute) {
                m.hour(0).minute(0);
            }
        }
        return m;
    }

    static areSame(
        a: moment.Moment,
        b: moment.Moment,
        granularity?: moment.unitOfTime.StartOf,
    ) {
        return (!a && !b) || (a && b && a.isSame(b, granularity));
    }

    static areSameMoment(
        a: moment.Moment,
        b: moment.Moment,
        includeTime: boolean,
    ) {
        return DateTimeUtil.areSame(a, b, includeTime ? 'ms' : 'd');
    }

    static compareMoments(a: moment.Moment, b: moment.Moment) {
        const mA = a.isValid() ? a : null;
        const mB = b.isValid() ? b : null;
        return mA && mB ? mA.diff(mB) : !mA && !mB ? 0 : mA ? 1 : -1;
    }

    /** Returns a string, date or Moment from the given Moment and *dateType* */
    static fromMomentByDateType(m: moment.Moment, dateType: TDateType) {
        if (!m) {
            return;
        }

        switch (dateType) {
            case 'string-iso':
                return m.toISOString();
            case 'string-cdp-date':
                return m.format(DateTimeUtil.cdpDateFormat);
            case 'date':
                return m.toDate();
            case 'moment':
                return m;

            default:
                if (!CoreUtil.isProduction) {
                    console.warn('not implemented', dateType);
                }
                return;
        }
    }

    /** Returns a Moment from the given value and *dateType* */
    static getMomentFromDateType(v: moment.MomentInput, dateType: TDateType) {
        if (!v) {
            return;
        }

        switch (dateType) {
            case 'string-cdp-date':
                return moment(v, DateTimeUtil.cdpDateFormat);

            default:
                return moment(v);
        }
    }

    /** Returns a date in string format YYYY-mm-dd without taking care of the timezone */
    static getStringDate(date: Date) {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Les mois commencent à 0
        const day = date.getDate().toString().padStart(2, '0');

        return `${year}-${month}-${day}`;
    }
}

export type TDateType = 'date' | 'moment' | 'string-iso' | 'string-cdp-date';
