import { Observable, Subscription } from 'rxjs';
import { IVersioningSelectorInputs } from './IVersioningSelectorInputs';
import { DialogType } from '@datagalaxy/ui/dialog';
import { EventEmitter } from '@angular/core';
import { IProjectVersionSelectedEvent } from '../../workspace/workspace-version-selector/space.types';
import { IVersionStoreState, VersionStore } from './versionStore';
import { DxyModalService } from '../../shared/dialogs/DxyModalService';
import { VersioningUiService } from '../services/versioning-ui.service';
import { VersioningEventService } from '../services/versioning-event.service';
import {
    WorkspaceService,
    WorkspaceStore,
} from '@datagalaxy/webclient/workspace/data-access';
import {
    ProjectVersion,
    ProjectVersionStatus,
} from '@datagalaxy/webclient/versioning/data-access';
import { WorkspaceIdentifier } from '@datagalaxy/webclient/workspace/utils';
import {
    IWorkspaceIdentifier,
    Workspace,
    WorkspaceDetails,
} from '@datagalaxy/webclient/workspace/domain';

/** Core code for versioning-selector and dxy-versioning-selector components */
export class VersioningSelectorCore implements IVersioningSelectorInputs {
    //#region IVersioningSelectorInputs
    public projectIdr: IWorkspaceIdentifier;
    public noNavigator: boolean;
    public openOnDisplayNameClick = true;
    public isVersionSelectable: boolean;
    public enableFavorite: boolean;
    public hideOfficialVersion?: boolean;
    public readonly onProjectVersionSelected =
        new EventEmitter<IProjectVersionSelectedEvent>();
    public readonly onDisplayNameClick = new EventEmitter<void>();
    public readonly onProjectVersionsLoading = new EventEmitter<boolean>();
    //#endregion

    public get hasOfficialVersion() {
        return this.versionStoreState.hasOfficial;
    }
    public get hasActiveVersions() {
        return this.versionStoreState.hasActive;
    }
    public get activeVersions() {
        return this.versionStoreState.actives;
    }
    public get hasReleaseCandidateVersions() {
        return this.versionStoreState.hasReleaseCandidates;
    }
    public get releaseCandidateVersions() {
        return this.versionStoreState.releaseCandidates;
    }
    public get archivedVersions() {
        return this.versionStoreState.archived;
    }
    public get hasArchivedVersions() {
        return this.versionStoreState.hasArchived;
    }
    public get showDropDown() {
        return !this.versionStoreState.isEmpty;
    }
    public get selectedVersionName() {
        return this.selected?.VersionName ?? this.currentVersionName;
    }
    public get officialVersion() {
        return this.versionStoreState.official;
    }
    public get showStars() {
        return this.enableFavorite;
    }
    public get showNavigatorLink() {
        return !this.noNavigator;
    }

    private versionStoreState: IVersionStoreState = { isEmpty: true };
    private readonly versionStore = new VersionStore();
    public selected: ProjectVersion;
    private get isCurrentSpace() {
        return (
            this.projectIdr?.spaceId ===
            this.workspaceStore.currentSpace?.spaceId
        );
    }
    private get currentVersionName() {
        return this.isCurrentSpace
            ? (this.workspaceStore.currentSpace as WorkspaceDetails)
                  ?.VersionName
            : undefined;
    }

    constructor(
        private dxyModalService: DxyModalService,
        private versioningUiService: VersioningUiService,
        private versioningEventService: VersioningEventService,
        private workspaceService: WorkspaceService,
        private workspaceStore: WorkspaceStore,
        private subscribe: <T>(
            eventSubject: Observable<T>,
            action: (eventData?: T) => void,
        ) => Subscription,
        private registerSubscription: (
            subscription: Subscription,
        ) => Subscription,
        private log: (...args: any[]) => void,
    ) {}

    public async init(host: IVersioningSelectorInputs) {
        this.getInputs(host, true);
        this.log('init', this.projectIdr);
        if (!this.projectIdr) {
            this.projectIdr = WorkspaceIdentifier.from(
                this.workspaceStore.currentSpace,
            );
        }
        await this.loadProjectVersions(this.projectIdr);
        this.subscribeEvents();
    }

    public hasAny(
        statuses: ProjectVersionStatus[],
        predicate?: (version: ProjectVersion) => boolean,
    ) {
        return this.versionStore.hasAny(statuses, predicate);
    }

    public getFeatureCode(version: ProjectVersion) {
        return `VERSION_DEFAULT,${version.IsUserDefaultVersion ? 'D' : 'C'}`;
    }

    public isFavoriteButtonVisible(version: ProjectVersion) {
        return this.enableFavorite || version?.IsUserDefaultVersion;
    }

    public onInputChange(host: IVersioningSelectorInputs) {
        this.getInputs(host);
    }

    public async onProjectIdrChanged(spaceIdr: IWorkspaceIdentifier) {
        if (WorkspaceIdentifier.areSame(spaceIdr, this.projectIdr)) {
            return;
        }
        this.projectIdr = spaceIdr;
        await this.loadProjectVersions(spaceIdr);
    }

    public onVersionClick(version: ProjectVersion) {
        this.setSelected(version, true);
    }

    public async onNavigatorLinkClick() {
        const space = await this.workspaceService.getWorkspace(this.projectIdr);
        if (space instanceof WorkspaceDetails) {
            await this.versioningUiService.openVersioningNavigatorModal(space);
        }
    }

    public async onStarClick(event: Event, version: ProjectVersion) {
        if (!this.enableFavorite) {
            return;
        }
        this.log('onStarClick', version);
        const isDefault = !version.IsUserDefaultVersion;
        event.stopPropagation();
        return this.versioningUiService.updateDefaultVersion(
            version,
            isDefault,
        );
    }

    private getInputs(host: IVersioningSelectorInputs, isInit = false) {
        this.log('getInputs', isInit);

        host.openOnDisplayNameClick ??= true;

        if (isInit) {
            this.projectIdr = host.projectIdr;
        }
        this.noNavigator = host.noNavigator;
        this.isVersionSelectable = host.isVersionSelectable;
        this.enableFavorite = host.enableFavorite;
        this.hideOfficialVersion = host.hideOfficialVersion;
        this.openOnDisplayNameClick = host.openOnDisplayNameClick;
    }

    private subscribeEvents() {
        this.subscribe(this.versioningEventService.createVersion$, (event) =>
            this.onAddVersion(event.data),
        );
        this.subscribe(this.versioningEventService.updateVersion$, (event) =>
            this.onUpdateVersion(event.data),
        );
        this.subscribe(
            this.versioningEventService.updateDefaultVersion$,
            (event) => this.onUpdateDefaultVersion(event.data),
        );
        this.subscribe(
            this.versioningEventService.updateVersionStatus$,
            (event) => this.onUpdateVersionStatus(event.data, event.external),
        );
    }

    private async loadProjectVersions(
        spaceIdr: IWorkspaceIdentifier,
    ): Promise<void> {
        this.log('loadProjectVersions', spaceIdr);

        this.onProjectVersionsLoading.emit(true);

        const projectId = spaceIdr?.spaceId;
        if (!projectId) {
            this.log('loadProjectVersions result: no projectId');
            this.versionStore.clear();
            this.versionStoreState = this.versionStore.getState();
            this.onProjectVersionsLoading.emit(false);
            this.setSelected(null);
            return;
        }

        const projectVersions =
            await this.versioningUiService.getProjectVersions(projectId);
        this.log('loadProjectVersions result', projectId, projectVersions);
        const selected = await this.getSelectedVersion(
            spaceIdr,
            projectId,
            projectVersions,
        );
        this.onProjectVersionsLoading.emit(false);
        this.setSelected(selected);
    }

    private async getSelectedVersion(
        spaceIdr: IWorkspaceIdentifier,
        projectId: string,
        projectVersions: ProjectVersion[],
    ) {
        if (this.hideOfficialVersion) {
            projectVersions = projectVersions.filter(
                (version) =>
                    version.VersionStatus != ProjectVersionStatus.Official &&
                    version.VersionStatus != ProjectVersionStatus.Archived,
            );
        }

        this.versionStore.init(projectVersions);
        this.versionStoreState = this.versionStore.getState();

        if (spaceIdr.versionId) {
            const selected = this.versionStore.getBySpaceIdentifier(spaceIdr);
            if (selected) {
                return selected;
            }
        }

        if (this.selected?.ProjectReferenceId != projectId) {
            this.selected = null;
        }

        if (this.selected) {
            return this.selected;
        }

        return this.getDefaultVersion();
    }

    private onUpdateVersion(version: ProjectVersion) {
        this.versionStore.set(version);
        this.versionStoreState = this.versionStore.getState();
        if (WorkspaceIdentifier.areSame(this.selected, version)) {
            this.selected.VersionName = version.VersionName;
        }
    }

    private onAddVersion(version: ProjectVersion) {
        if (WorkspaceIdentifier.haveSameProject(version, this.projectIdr)) {
            this.versionStore.set(version);
            this.versionStoreState = this.versionStore.getState();
        }
    }

    private async onUpdateVersionStatus(
        versions: ProjectVersion[],
        external: boolean,
    ) {
        const currentSpaceVersionsAffected = versions.filter((version) =>
            WorkspaceIdentifier.haveSameProject(version, this.projectIdr),
        );

        currentSpaceVersionsAffected.forEach((version) =>
            this.versionStore.set(version),
        );
        this.versionStoreState = this.versionStore.getState();

        const versionId = this.selected?.ProjectVersionId;
        const modifiedSelected =
            this.selected &&
            versions.find((v) => v.ProjectVersionId === versionId);
        if (!modifiedSelected) {
            return;
        }

        this.selected = modifiedSelected;

        if (external) {
            await this.dxyModalService.inform({
                titleKey: 'UI.Versioning.StatusGraph.changeStatusModal.title',
                messageKey: 'UI.Versioning.StatusGraph.changeStatusModal.info',
                type: DialogType.Close,
            });
        }
    }

    private async onUpdateDefaultVersion(
        updatedProjectVersion: ProjectVersion,
    ) {
        this.versionStore.updateUserDefaultVersion(
            updatedProjectVersion.versionId,
        );
    }

    private setSelected(version: ProjectVersion, isUserAction = false) {
        const prev = this.selected;
        this.selected = version;
        this.log('setSelected', prev, '->', this.selected);
        if (prev == this.selected) {
            return;
        }

        if (!isUserAction) {
            return;
        }
        this.onProjectVersionSelected.emit({
            projectVersion: this.selected,
            isUserAction,
        });
    }

    private async getDefaultVersion() {
        const space = this.workspaceStore.currentSpace;
        const pv =
            this.versionStore.getBySpaceIdentifier(space) ??
            this.versionStore.getFirst();
        return pv
            ? pv
            : space instanceof WorkspaceDetails
              ? this.makePseudoVersion(space)
              : null;
    }
    private makePseudoVersion(p: WorkspaceDetails | Workspace) {
        let v = new ProjectVersion();
        v.ProjectReferenceId = p.ReferenceId;
        v.ProjectName = p.DisplayName;

        if (p instanceof WorkspaceDetails) {
            v.ProjectVersionId = p.VersionId;
            v.VersionName = p.VersionName;
            v.VersionDescription = p.VersionDescription;
        } else if (p instanceof Workspace) {
            v.ProjectVersionId = p.UserDefaultVersionId ?? p.DefaultVersionId;
            v.VersionName = p.UserDefaultVersionName ?? p.DefaultVersionName;
            v.VersionDescription = p.Description;
        } else {
            v = null;
        }
        return v;
    }
}
