import { Subject } from 'rxjs';
import { CollectionsHelper, CoreUtil, LogFn } from '@datagalaxy/core-util';
import { BaseService, IDragDropConfig } from '@datagalaxy/core-ui';
import {
    AttributeFilterService,
    IChangeFilterItemResult,
} from '../shared/attribute/attribute-filter/attribute-filter.service';
import { IFilterFormAttributes } from '../shared/attribute/attribute-filter/attribute-filter-form/IFilterFormModel';
import { FacetItem } from './facets/models/FacetItem';
import { AttributeFilterModel } from '../shared/attribute/attribute-filter/attribute-filter/attributeFilterModel';
import { SearchFacetService } from './facets/search-facet.service';
import { Injectable } from '@angular/core';
import { AttributeDataService } from '../shared/attribute/attribute-data.service';
import { CurrentSearch } from './models/CurrentSearch';
import { DataGroup, IGroupingFactory } from './search.types';
import {
    EntityType,
    EntityTypeUtil,
    HierarchyDataDescriptor,
} from '@datagalaxy/dg-object-model';
import { EntityService } from '../shared/entity/services/entity.service';
import { FilteredViewService } from '../shared/filter/services/filteredView.service';
import { SearchUsage } from '../shared/util/DgServerTypesApi';
import { SearchSettingsService } from './search-settings.service';
import { SearchFacetSettingsService } from './facets/search-facet-settings.service';
import { BaseServiceParameter } from '@datagalaxy/data-access';
import { AttributeFilter } from '@datagalaxy/webclient/attribute/data-access';
import { getContextId, getReferenceId } from '@datagalaxy/webclient/utils';
import {
    FilteredEntityItem,
    GetFilteredEntitiesParameter,
    GetFilteredEntitiesResult,
    GetRecentSearchesParameter,
    SaveRecentSearchFiltersParameter,
    SearchApiService,
} from '@datagalaxy/webclient/search/data-access';
import { EntityServerTypeUtils } from '@datagalaxy/webclient/entity/utils';
import { WorkspaceIdentifier } from '@datagalaxy/webclient/workspace/utils';
import { IWorkspaceIdentifier } from '@datagalaxy/webclient/workspace/domain';
import {
    Filter,
    FilteredViewDto,
    FilterOperator,
} from '@datagalaxy/webclient/filter/domain';
import { userSettingsValues } from '@datagalaxy/webclient/user/domain';
import {
    AttributeMetaInfo,
    AttributeMetaType,
    AttributeMetaValue,
} from '@datagalaxy/webclient/attribute/domain';
import { DgZone } from '@datagalaxy/webclient/domain';
import { ServerConstants } from '@datagalaxy/shared/server/domain';
import { EntityGridStateService } from '../entity/entity-grid/entity-grid/entity-grid-state.service';
import { WorkspaceStore } from '@datagalaxy/webclient/workspace/data-access';
import { Router } from '@angular/router';
import { ClientRouterService } from '@datagalaxy/webclient/client/feature';
import { searchResults } from '../client/client.routes';
import { CurrentUserService } from '@datagalaxy/webclient/user/data-access';
import PropertyName = ServerConstants.PropertyName;
import SearchTermFilterKey = ServerConstants.Search.SearchTermFilterKey;

@Injectable({ providedIn: 'root' })
export class SearchService extends BaseService {
    private debugDetailed = false;

    //#region static
    public static readonly mainSearchDefaultDisplayedColumns = [
        PropertyName.DisplayName,
        PropertyName.Description,
        PropertyName.EntityStatus,
        PropertyName.DataOwnersObjectValues,
        PropertyName.DataStewardsObjectValues,
        PropertyName.DomainsObjectValues,
    ];

    public static isSpaceGovUserFilterElement(attributeKey: string) {
        return AttributeDataService.isSpaceGovUserAttributeKey(attributeKey);
    }
    public static isTagFilterElement(attributeKey: string) {
        return (
            attributeKey ==
            ServerConstants.AttributeConstants.SystemTagsAttributeKey
        );
    }
    public static isBusinessDomainFilterElement(attributeKey: string) {
        return (
            attributeKey ==
            ServerConstants.AttributeConstants.BusinessDomainsAttributeKey
        );
    }

    public static getFilterItemMatchingFacet(
        currentSearch: CurrentSearch,
        facet: FacetItem,
    ): AttributeFilterModel {
        let isMatch: (afm: AttributeFilterModel) => boolean;
        switch (facet.attributeType) {
            case AttributeMetaType.DateTime: {
                const op = FilterOperator[facet.valueId];
                isMatch = (afm) => afm.operator == op;
                break;
            }
            case AttributeMetaType.Boolean: {
                const booleanAsString =
                    facet.valueId === '0'
                        ? false.toString()
                        : facet.valueId === '1'
                          ? true.toString()
                          : undefined;
                isMatch = (afm) => afm.containsValue(booleanAsString, false);
                break;
            }
            default:
                isMatch = (afm) =>
                    afm.containsValue(facet.valueId, facet.compareAsLocalId);
                break;
        }
        return currentSearch.filterItems.find(
            (afm) => afm.attributeKey == facet.attributeKey && isMatch(afm),
        );
    }

    public static groupResults(
        data: GetFilteredEntitiesResult,
        groupFactory: IGroupingFactory<FilteredEntityItem>,
        setAttributeFilters = false,
    ) {
        const entities = data.Entities ?? [];
        const groups = SearchService.buildDataGroups(entities, groupFactory);
        const moreResultsTotalCount =
            data.TotalCount > entities.length ? data.TotalCount : undefined;
        const result = new SearchResult(
            entities,
            groups,
            moreResultsTotalCount,
        );
        if (setAttributeFilters) {
            result.attributeFilters = data.AttributeFilters;
        }
        return result;
    }
    private static buildDataGroups<T extends object>(
        data: T[],
        groupFactory: IGroupingFactory<T>,
    ) {
        const {
            getGroupKey,
            getInGroupSortIndex,
            getGroupDisplayName,
            getGroupSortIndex,
        } = groupFactory;
        const groups = CollectionsHelper.groupBy(
            data,
            getGroupKey,
            (groupKey, items) => {
                if (getInGroupSortIndex) {
                    items = CollectionsHelper.orderBy(
                        items,
                        getInGroupSortIndex,
                    );
                }
                return new DataGroup(
                    groupKey,
                    items,
                    getGroupDisplayName?.(groupKey),
                );
            },
        );
        return getGroupSortIndex
            ? CollectionsHelper.orderBy(groups, (g) => getGroupSortIndex(g.key))
            : groups;
    }

    //#endregion -- static

    //#region events

    public get mainSearchSpaceChange$() {
        return this.mainSearchSpaceChange.asObservable();
    }
    private mainSearchSpaceChange = new Subject<IWorkspaceIdentifier>();

    public notifyMainSearchSpaceChange(spaceIdr: IWorkspaceIdentifier) {
        this.mainSearchSpaceChange.next(spaceIdr);
    }

    public notifySpotlightPanelOpen(open: boolean) {
        this.isSpotlightPanelOpen.next(open);
    }
    public get isSpotlightPanelOpen$() {
        return this.isSpotlightPanelOpen.asObservable();
    }
    private isSpotlightPanelOpen = new Subject<boolean>();

    //#endregion

    public isCurrentPreviewOnAllSpaces: boolean;

    public get spotlightDragDrop() {
        return this._spotlightDragDrop;
    }
    private _spotlightDragDrop: IDragDropConfig<HierarchyDataDescriptor>;

    private readonly formAttributes: IFilterFormAttributes;
    private currentSearch: CurrentSearch;
    private isInitDone = false;

    constructor(
        private searchApiService: SearchApiService,
        private searchSettingsService: SearchSettingsService,
        private searchFacetSettingsService: SearchFacetSettingsService,
        private entityService: EntityService,
        private attributeDataService: AttributeDataService,
        private filteredViewService: FilteredViewService,
        private attributeFilterService: AttributeFilterService,
        private entityGridState: EntityGridStateService,
        private workspaceStore: WorkspaceStore,
        private router: Router,
        private clientRouter: ClientRouterService,
        private currentUser: CurrentUserService,
    ) {
        super();
        this.formAttributes = {
            allAttributes: [],
            sourceAttributes: [],
            availableAttributes: [],
        };
    }

    public async initAsync() {
        // #Archi-doubleLogin: When using autologin (that is, not in production), a double login may occur
        if (this.isInitDone) {
            return;
        }
        this.isInitDone = true;
        this.log('initAsync');
        this.initEntityTypeAttribute();
        await Promise.all([
            this.searchSettingsService.initAsync(),
            this.searchFacetSettingsService.updateSettings(),
            this.updateFilteringAttributes('init'),
        ]);

        this.attributeDataService.attributeCacheUpdated$.subscribe(() =>
            this.updateFilteringAttributes('attributeCacheUpdated'),
        );

        // not to block init at next login
        setTimeout(() => (this.isInitDone = false), 5000);
    }

    public async updateFilteringAttributes(from: string) {
        this.debug && console.time('updateFilteringAttributes');
        const attributes =
            await this.attributeDataService.getAttributesForFiltering();
        this.debug &&
            this.log(
                'updateFilteringAttributes',
                from,
                this.debugDetailed
                    ? attributes?.map(
                          (a) =>
                              `\n${a.AttributePath}: ${a.translatedDisplayName}`,
                      )
                    : attributes?.length,
            );
        this.formAttributes.allAttributes = attributes ?? [];
        this.formAttributes.searchTermAttributeKey = SearchTermFilterKey;
        const searchTermAttribute = new AttributeMetaInfo({
            attributeKey: SearchTermFilterKey,
            attributePath: SearchTermFilterKey,
            attributeType: AttributeMetaType.Text,
        });
        this.formAttributes.allAttributes.push(searchTermAttribute);
        this.debug && console.timeEnd('updateFilteringAttributes');
    }

    //#region CurrentSearch
    public getNewSearch(log?: LogFn) {
        this.log('getNewSearch', this.formAttributes);
        return new CurrentSearch(
            CoreUtil.cloneDeep(this.formAttributes),
            null,
            log,
        );
    }
    public getCurrentSearch(log?: LogFn) {
        const currentSearch =
            this.currentSearch ?? (this.currentSearch = this.getNewSearch());
        if (log) {
            currentSearch.log = log;
        }
        return currentSearch;
    }
    public saveNewFilteredViewFromCurrentSearch(
        currentSearch: CurrentSearch,
        name: string,
        description: string,
        isPrivate: boolean,
    ) {
        return this.filteredViewService.saveNewFilterView(
            currentSearch.spaceIdr.spaceId,
            name,
            description,
            isPrivate,
            currentSearch.computeFilters(),
            DgZone.Search,
        );
    }
    public clearCurrentSearch(keepSpaceAndVersion = false) {
        this.getCurrentSearch()?.clear(keepSpaceAndVersion);
    }
    public setCurrentSearch(currentSearch: CurrentSearch) {
        this.currentSearch = currentSearch ?? this.getNewSearch();
    }
    public getCurrentSearchSpaceIdr(): IWorkspaceIdentifier {
        return (
            this.currentSearch &&
            WorkspaceIdentifier.from(this.currentSearch.spaceIdr)
        );
    }
    //#endregion

    public async mainSearch(currentSearch: CurrentSearch, maxCount: number) {
        this.log('mainSearch', currentSearch);

        const attributeKeys =
            await this.entityGridState.getGridStateAttributeKeys(
                userSettingsValues.omniGrid.routes.mainSearchResults,
            );

        // we need the result entities to be loaded with the attributes matching the displayed columns (user's, or default)
        const neededAttributeKeys = attributeKeys?.length
            ? attributeKeys
            : SearchService.mainSearchDefaultDisplayedColumns;
        const entityAttributes =
            await this.entityService.getEntityAttributesForMainSearch();
        this.log(
            'mainSearch-entityAttributes',
            entityAttributes?.length,
            neededAttributeKeys,
        );

        const parameterOptions: IGetFilteredEntitiesParameter = {
            spaceIdr: currentSearch.spaceIdr,
            filters: currentSearch.computeFilters(),
            maxCount,
        };

        const p = this.createGetFilteredEntitiesParameter(parameterOptions);
        // needed for displayed columns/cell renderers
        p.IncludedAttributesFilter = neededAttributeKeys;
        // needed for facet filters
        p.IncludedResultAttributesFilter =
            this.searchFacetSettingsService.getAttributeKeys();
        p.SortKey = currentSearch.sortKey;
        p.SearchUsage = SearchUsage.SearchResult;
        this.log('mainSearch-getFilteredEntities', p);
        const res = await this.searchApiService.getFilteredEntities(p);
        return this.makeSearchResult(res, entityAttributes);
    }

    public async getLastSearches(size: number) {
        const param = new GetRecentSearchesParameter();
        param.Size = size;
        return await this.searchApiService.getLastSearches(param);
    }

    public async saveLastSearch(currentSearch: CurrentSearch) {
        if (currentSearch.isSearchEmpty) {
            return;
        }

        const spaceIdr = currentSearch.spaceIdr;
        const filtersList = currentSearch.computeFilters();

        const param = {
            Filters: filtersList,
            SpaceId: getContextId(spaceIdr.spaceId),
            VersionId: spaceIdr.versionId,
        } as SaveRecentSearchFiltersParameter;
        return await this.searchApiService.saveLastSearch(param);
    }

    public async clearLastSearches() {
        return await this.searchApiService.clearLastSearches(
            new BaseServiceParameter(),
        );
    }

    // used by dxy-spotlight-input
    public async searchPreview(
        currentSearch: CurrentSearch,
        opt: {
            searchUsage: SearchUsage;
            initQuickFilters?: boolean;
            maxCount?: number;
            excludedIds?: string[];
            includedIds?: string[];
            includedAttributesFilter?: string[];
            includeSearchPreferences?: boolean;
        },
    ) {
        this.log('searchPreview', currentSearch);
        this.isCurrentPreviewOnAllSpaces = !currentSearch.spaceIdr?.spaceId;
        const parameterOptions: IGetFilteredEntitiesParameter = {
            spaceIdr: currentSearch.spaceIdr,
            filters: currentSearch.computeFilters(),
            includeQuickFilters: true,
            maxCount: opt.maxCount || 50,
            searchUsage: opt.searchUsage,
            excludedIds: opt.excludedIds,
            includedIds: opt.includedIds,
            includedAttributesFilter: opt.includedAttributesFilter,
            includeSearchPreferences: opt.includeSearchPreferences,
        };
        if (opt.initQuickFilters) {
            parameterOptions.maxCount = 0;
        }
        const p = this.createGetFilteredEntitiesParameter(parameterOptions);
        this.log('searchPreview-getFilteredEntities', p);
        const result = await this.searchApiService.getFilteredEntities(p);
        return this.makeSearchResult(result);
    }

    public async searchBusinessDomains(
        spaceIdr: IWorkspaceIdentifier,
        searchTerm: string,
    ) {
        return this.searchEntities(
            spaceIdr,
            searchTerm,
            [EntityType.BusinessDomain],
            'searchBusinessDomains',
        );
    }

    public async searchEntities(
        spaceIdr: IWorkspaceIdentifier,
        searchTerm: string,
        entityTypes: EntityType[],
        logString?: string,
        maxCount?: number,
    ) {
        const filters = [
            new Filter(
                ServerConstants.Search.EntityTypeFilterKey,
                FilterOperator.ListContains,
                entityTypes.map((et) => EntityType[et]),
            ),
        ];

        const p = this.createGetFilteredEntitiesParameter({
            spaceIdr,
            searchTerm,
            filters,
            maxCount,
        });
        p.SearchUsage = SearchUsage.EntitySelector;
        this.log(logString || 'searchEntities', p);

        const res = await this.searchApiService.getFilteredEntities(p);

        return res.Entities;
    }

    public async getFilteredViewSearchEntitiesCount(
        filteredView: FilteredViewDto,
    ) {
        if (!filteredView) {
            return null;
        }
        const space =
            this.workspaceStore.currentSpace ?? this.currentSearch.spaceIdr;
        const spaceId = space.spaceId;
        const versionId = space.versionId;

        const spaceIdr = new WorkspaceIdentifier(
            getReferenceId(spaceId, spaceId),
            spaceId == filteredView.SpaceUid
                ? filteredView.DefaultVersionId
                : versionId,
        );
        const parameterOptions = {
            spaceIdr,
            filters: filteredView.ListFilter,
            maxCount: 0,
        };
        const p = this.createGetFilteredEntitiesParameter(parameterOptions);
        p.SearchUsage = SearchUsage.FilterPanel;
        const res = await this.searchApiService.getFilteredEntities(p);
        return res.TotalCount;
    }

    public async getFilteredViewSearchEntities(
        filteredView: FilteredViewDto,
        maxCount = 45,
    ) {
        if (!filteredView) {
            return null;
        }
        const spaceIdr = new WorkspaceIdentifier(
            getReferenceId(filteredView.SpaceUid, filteredView.SpaceUid),
            filteredView.DefaultVersionId,
        );
        const parameterOptions = {
            spaceIdr,
            filters: filteredView.ListFilter,
            maxCount,
        };
        const p = this.createGetFilteredEntitiesParameter(parameterOptions);
        p.SearchUsage = SearchUsage.FilterPanel;
        const res = await this.searchApiService.getFilteredEntities(p);

        return res;
    }

    public async getLastEntities(
        spaceIdr: IWorkspaceIdentifier,
        sortKey: string,
        includedAttributesFilter: string[],
        searchUsageValue: string,
        maxCount?: number,
    ) {
        const p = this.createGetFilteredEntitiesParameter({
            spaceIdr,
            maxCount,
        });
        p.IncludedAttributesFilter = includedAttributesFilter;
        p.SortKey = sortKey;
        const entityTypes = EntityTypeUtil.getEntityTypes(
            EntityServerTypeUtils.firstClassEntityServerTypes,
        );
        p.Filters = [
            new Filter(
                '_EntityType',
                FilterOperator.ListContains,
                entityTypes.map((et) => EntityType[et]),
            ),
        ];
        p.SearchUsage = searchUsageValue;

        const res = await this.searchApiService.getFilteredEntities(p);
        return res.Entities;
    }

    public async goToMainSearchResultsFromSpotlight(
        currentSearch: CurrentSearch,
    ) {
        this.log('goToMainSearchResultsFromSpotlight', currentSearch);
        const fv =
            this.filteredViewService.getFilteredViewFromCurrentSearch(
                currentSearch,
            );
        this.filteredViewService.setCurrentFilteredView(fv);
        await this.goToSearchResults();
        if (!currentSearch.isSearchEmpty) {
            await this.saveLastSearch(currentSearch);
        }
    }

    public async getCurrentSearchFromFilteredView(fv: FilteredViewDto) {
        return this.getCurrentSearchFromFilters(
            fv.filters,
            fv.SpaceUid,
            fv.DefaultVersionId,
        );
    }

    public goToMainSearchResultsFromAllFilteredView(
        spaceIdr?: IWorkspaceIdentifier,
    ) {
        this.log('goToMainSearchResultsFromAllFilteredView', spaceIdr);
        const newSearch = this.getCurrentSearch();
        if (spaceIdr) {
            newSearch.setSpaceAndVersion(spaceIdr);
        }
        newSearch.searchTerm = '';
        this.filteredViewService.setCurrentFilteredView(
            this.filteredViewService.getNewFilteredView(DgZone.Search),
        );
        this.setCurrentSearch(newSearch);
        this.goToSearchResults();
    }

    public goToSearchResults() {
        return this.router.navigate([
            ...this.clientRouter.getClientUrlCommands(
                this.currentUser.clientId || '',
            ),
            searchResults.path,
        ]);
    }

    public hasCurrentFilterItem(
        amv: AttributeMetaValue,
        currentSearch: CurrentSearch,
    ) {
        if (!currentSearch) {
            return;
        }
        const ak = amv.attributeInfo.AttributeKey;
        const compareAsLocalId =
            AttributeFilterService.isToBeComparedAsLocalId(ak);
        return (
            amv &&
            currentSearch.filterItems.some(
                (afm) =>
                    afm.attributeKey == ak &&
                    afm.containsValue(amv.Key, compareAsLocalId),
            )
        );
    }

    public updateFilterItemsFromSpotLightItem(
        amv: AttributeMetaValue,
        currentSearch: CurrentSearch,
    ) {
        this.log('updateFilterItemsFromSpotLightItem', amv, currentSearch);
        if (!amv || !currentSearch) {
            return;
        }
        const compareAsLocalId = AttributeFilterService.isToBeComparedAsLocalId(
            amv.attributeInfo.AttributeKey,
        );
        const res = AttributeFilterService.addValueToFilterItems(
            amv,
            compareAsLocalId,
            currentSearch,
        );
        this.log('updateFilterItemsFromSpotLightItem-add-result', res);
    }

    public async getCurrentSearchFromFilters(
        filters: Filter[],
        spaceId: string,
        versionId: string,
    ) {
        const currentSearch = this.getNewSearch(this.getLogger('mainSearch'));
        const spaceReferenceId = spaceId
            ? getReferenceId(spaceId, spaceId)
            : null;
        const spaceIdr = new WorkspaceIdentifier(spaceReferenceId, versionId);

        currentSearch.setSpaceAndVersion(spaceIdr);

        await this.attributeFilterService.loadServerDataForFilters(
            currentSearch,
            filters,
        );
        currentSearch.setupFilterItems(filters);
        return currentSearch;
    }

    public updateFilterItemsFromChangedFacet(
        facet: FacetItem,
        currentSearch: CurrentSearch,
    ) {
        if (!facet) {
            return;
        }
        let change: IChangeFilterItemResult;
        if (facet.isChecked) {
            // add facet as filter-item of filter-item value
            const op = SearchFacetService.getPreferredOperator(facet);
            change = AttributeFilterService.addValueToFilterItems(
                facet.attributeValue,
                facet.compareAsLocalId,
                currentSearch,
                op,
            );
            this.log('updateFilterItemsFromChangedFacet-add-result', change);
        } else {
            // remove matching filter-item value or filter-item matching the facet
            change = AttributeFilterService.removeValueFromFilterItems(
                facet.attributeValue,
                facet.compareAsLocalId,
                currentSearch,
            );
            this.log('updateFilterItemsFromChangedFacet-remove-result', change);
        }
    }

    public setupSpotlightDragDrop(
        spotlightDragDrop: IDragDropConfig<HierarchyDataDescriptor>,
    ) {
        this._spotlightDragDrop = spotlightDragDrop;
    }
    public clearSpotlightDragDrop() {
        this._spotlightDragDrop = null;
    }

    private initEntityTypeAttribute() {
        this.formAttributes.entityTypeAttribute =
            this.attributeDataService.createDynamicAttribute(
                ServerConstants.Search.EntityTypeFilterKey,
                'Type',
                AttributeMetaType.ValueList,
                false,
            );
        this.updateEntityTypeAttributeValues();
    }
    private updateEntityTypeAttributeValues() {
        const ami = this.formAttributes.entityTypeAttribute;
        const amvs =
            this.attributeDataService.makeAllEntityTypesAttributeValuesForSearch(
                ami,
            );
        ami.ListValues.length = 0;
        ami.ListValues.push(...amvs);
    }

    private createGetFilteredEntitiesParameter(
        opt: IGetFilteredEntitiesParameter,
    ) {
        const p = new GetFilteredEntitiesParameter();
        p.ParentReferenceId = opt.spaceIdr?.spaceId;
        // if not explicitly defined, includeSearchPreferences is true
        p.IncludeSearchPreferences = opt.includeSearchPreferences !== false;
        p.VersionId = opt.spaceIdr?.spaceId ? opt.spaceIdr.versionId : '';
        p.MaxCount = opt.maxCount ?? 500;
        if (opt.searchTerm != undefined) {
            p.SearchTerm = opt.searchTerm;
        }
        if (opt.filters?.length) {
            p.Filters = opt.filters;
        }
        p.IncludeSecurityData = true;
        p.IncludedAttributesFilter = [
            'DisplayName',
            'TechnicalName',
            ...(opt?.includedAttributesFilter
                ? opt.includedAttributesFilter
                : []),
        ];
        p.IncludeResultFilterData = true;
        p.SearchUsage = opt.searchUsage;
        p.ExcludedIds = opt.excludedIds;
        p.DataReferenceIdList = opt.includedIds;
        if (opt.includeQuickFilters) {
            p.IncludeQuickFilters = true;
        }
        return p;
    }

    private makeSearchResult(
        result: GetFilteredEntitiesResult,
        entityAttributes?: AttributeMetaInfo[],
    ) {
        const groupResults = SearchService.groupResults(
            result,
            this.searchSettingsService.groupingFactory,
            true,
        );
        groupResults.entityAttributes = entityAttributes;
        groupResults.quickFilters = result.QuickFilters;
        this.log('makeSearchResult', result, groupResults);
        return groupResults;
    }
}

export class SearchResult {
    public readonly count: number;
    public get allItems() {
        return this._items;
    }

    /** facet filters */
    public attributeFilters: AttributeFilter[];
    public quickFilters: Filter[];

    public entityAttributes?: AttributeMetaInfo[];

    constructor(
        private _items: FilteredEntityItem[],
        public groups: DataGroup<FilteredEntityItem>[] = [],
        public moreResultsTotalCount?: number,
    ) {
        this.count = _items.length;
    }
}

export interface IComparableSearchFilterItem {
    attributeKey: string;
    attributeType: AttributeMetaType;
    containsValue(value: string, compareAsLocalId: boolean): boolean;
    equals(other: IComparableSearchFilterItem): boolean;
}

export interface IGetFilteredEntitiesParameter {
    spaceIdr?: IWorkspaceIdentifier;
    searchTerm?: string;
    filters?: Filter[];
    maxCount?: number;
    includeQuickFilters?: boolean;
    searchUsage?: SearchUsage;
    excludedIds?: string[];
    includedIds?: string[];
    includedAttributesFilter?: string[];
    includeSearchPreferences?: boolean;
}
