<div *ngIf="showHeader" class="panel-content-tool">
    <div class="panel-content-toolname" translate>
        UI.EntityDockingPane.Tool.Tasks
    </div>
    <div class="actions-container">
        <div *ngIf="canAddTask" class="tool-add-entity">
            <button
                dxyIconButton
                variant="primary"
                [matTooltip]="'UI.TaskContainer.lblAdd' | translate"
                matTooltipPosition="left"
                (click)="openTaskNewModal()"
            >
                <i class="glyph glyph-add"></i>
            </button>
        </div>
    </div>
</div>
<div class="mainContainer panel-content-body">
    <div *ngIf="isLoading" class="dg5-spinner-container">
        <span class="dg5-spinner"></span>
    </div>
    <div class="taskContainer" [class.loading]="isLoading">
        <div class="filterContainer">
            <div class="filterItem" [matMenuTriggerFor]="statusMenu">
                <span
                    class="default-status-align"
                    [translate]="activeTabStatusTranslateKey"
                ></span>
                <span
                    *ngIf="!isLoading && tasks.length"
                    class="dg5-badge default-status-align"
                    >{{ activeTasks.length }}</span
                >
                <button
                    dxyIconButton
                    variant="tertiary"
                    class="settings-icon-right"
                >
                    <i class="glyph-arrow-down"></i>
                </button>
            </div>
            <mat-menu #statusMenu="matMenu" class="dg5-mat-menu">
                <ul class="status-menu">
                    <li (click)="changeFilter()">
                        <span translate>UI.TaskContainer.lblFilterAll</span>
                        <span
                            *ngIf="!isLoading && tasks.length > 0"
                            class="dg5-badge badge-right"
                            >{{ tasks.length }}</span
                        >
                    </li>
                    <li
                        *ngFor="let status of availableStatusFilters"
                        (click)="changeFilter(status)"
                    >
                        <span
                            [translate]="getStatusTabTranslateKey(status)"
                        ></span>
                        <span
                            *ngIf="showStatusCount(status)"
                            class="dg5-badge badge-right"
                        >
                            {{ getStatusCount(status) }}
                        </span>
                    </li>
                </ul>
            </mat-menu>
        </div>
        <div class="task-items-container" (scroll)="onScroll()" #itemsContainer>
            <div *ngIf="!tasks.length && !isLoading" class="no-item-container">
                <div class="image-container">
                    <img
                        src="/images/placeholders/tasks.svg"
                        class="dg_no-item-image"
                        alt="no task"
                    />
                </div>
                <div class="no-item-title" translate>
                    UI.TaskContainer.titleLbl
                </div>
                <div class="no-item-text" translate>
                    UI.TaskContainer.lblNoTask
                </div>
                <button
                    *ngIf="!readOnly"
                    dxyButton
                    variant="primary"
                    (click)="openTaskNewModal()"
                >
                    <i class="glyph-add"></i
                    ><span translate>UI.TaskContainer.addTaskBtn</span>
                </button>
            </div>
            <dxy-task-item
                *ngFor="let task of activeTasks; trackBy: getTaskId"
                [attr.data-task-id]="task.ReferenceId"
                class="dg_animate animation-fade"
                [task]="task"
                [isEditActive]="isCurrentEditedTask(task)"
                [entityData]="entityData"
                [readOnly]="readOnly"
                (onAfterFormAction)="initCurrentEditedTask()"
                (onDeleteItem)="deleteTask(task)"
                (onEditItem)="setCurrentEditedTask(task)"
                (onStatusChange)="scrollToActiveElement(task)"
            ></dxy-task-item>
        </div>
    </div>
</div>
