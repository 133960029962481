import {
    ChangeDetectionStrategy,
    Component,
    HostBinding,
    input,
    output,
} from '@angular/core';
import { MessageColor } from '../message-color';
import { MessageContentComponent } from '../message-content/message-content.component';
import { MessageDisplayMode } from './message-display-mode';

@Component({
    selector: 'dxy-message',
    standalone: true,
    imports: [MessageContentComponent],
    templateUrl: './message.component.html',
    styleUrl: './message.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessageComponent {
    color = input.required<MessageColor>();
    timerDuration = input<number>(0);
    canClose = input<boolean>();
    titleGlyphClass = input<string>();
    displayMode = input<MessageDisplayMode>('full');

    closeClick = output();

    @HostBinding('class')
    private get hostClasses(): string {
        return `${this.displayMode()} ${this.color()}`;
    }
}
