import {
    ChangeDetectionStrategy,
    Component,
    computed,
    contentChild,
    input,
    output,
} from '@angular/core';
import { MessageColor } from '../message-color';
import { MessageTimerComponent } from '../message-timer/message-timer.component';
import { DxyIconButtonDirective } from '@datagalaxy/ui/buttons';
import { NgClass } from '@angular/common';

@Component({
    selector: 'dxy-message-content',
    standalone: true,
    imports: [MessageTimerComponent, DxyIconButtonDirective, NgClass],
    templateUrl: './message-content.component.html',
    styleUrl: './message-content.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessageContentComponent {
    color = input.required<MessageColor>();
    timerDuration = input<number>(0);
    canClose = input<boolean>();
    titleGlyphClass = input<string>();

    closeClick = output();

    private title = contentChild('message-title');
    private hasTitle = computed(() => !!this.title);

    protected iconClasses = computed(
        () => `${this.titleGlyphClass()} ${this.color()}`,
    );

    protected hasHeader = computed(
        () => this.canClose() || this.hasTitle() || this.titleGlyphClass(),
    );
}
