import {
    Component,
    EventEmitter,
    forwardRef,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import {
    UntypedFormBuilder,
    UntypedFormGroup,
    Validators,
    FormsModule,
    ReactiveFormsModule,
} from '@angular/forms';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { debounceTime, distinctUntilChanged } from 'rxjs';
import { DiagramDataService } from '../diagram/diagram-data.service';
import { DiagramIdentifier } from '../DiagramIdentifier';
import {
    IDiagramConfig,
    IDiagramForm,
    IDiagramTypeInfo,
} from './diagram-configurator.types';
import { EntityType, IEntityIdentifier } from '@datagalaxy/dg-object-model';
import { IEntitySelectorData } from '../../shared/entitySelector/entity-selector.types';
import { PreCreateEntityStatus } from '@datagalaxy/webclient/attribute/data-access';
import { DiagramKind } from '@datagalaxy/webclient/diagram/data-access';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { IWorkspaceIdentifier } from '@datagalaxy/webclient/workspace/domain';
import { DxyEntitySelectorFieldComponent } from '../../shared/entitySelector/dxy-entity-selector-field/dxy-entity-selector-field.component';
import { DxyFieldTextComponent } from '@datagalaxy/ui/fields';
import { NgFor, NgIf } from '@angular/common';
import { DxyButtonDirective } from '@datagalaxy/ui/buttons';

/**
 * ## Role
 * UI component to configure a diagram:
 * - if a new diagram, by selecting its type, defining its name, and source if a physical diagram;
 * - if an existing physical diagram which source has been deleted, by selecting its source. */
@Component({
    selector: 'app-diagram-configurator',
    templateUrl: './diagram-configurator.component.html',
    styleUrls: ['./diagram-configurator.component.scss'],
    standalone: true,
    imports: [
        FormsModule,
        ReactiveFormsModule,
        NgFor,
        NgIf,
        DxyButtonDirective,
        TranslateModule,
        DxyFieldTextComponent,
        forwardRef(() => DxyEntitySelectorFieldComponent),
    ],
})
export class DiagramConfiguratorComponent
    extends DxyBaseComponent
    implements OnInit
{
    @Input() spaceIdr: IWorkspaceIdentifier;
    /** source (ie database) entity, for a physical diagram */
    @Input() sourceIdr?: IEntityIdentifier;
    /** to disallow changing the given source (if any). Used For a new diagram, created from a catalog source */
    @Input() isSourceReadonly?: boolean;
    /** for an existing diagram */
    @Input() diagramKind?: DiagramKind;
    /** for an existing diagram */
    @Input() displayName?: string;

    @Output() readonly configChanged = new EventEmitter<IDiagramConfig>();
    @Output() readonly panelOpenClose = new EventEmitter<boolean>();

    public readonly diagramTypes: IDiagramTypeInfo[] = [
        {
            diagramKind: DiagramKind.MonoSourceDatabase,
            labelKey: 'DgServerTypes.ServerTypeName.ModelDiagram',
            descriptionKey:
                'UI.Diagrams.creationModal.physicalDiagramDescription',
            imageUrl: '/images/diagrams/physical-diagram.svg',
        },
        {
            diagramKind: DiagramKind.Free,
            labelKey: 'DgServerTypes.DiagramType.FreeDiagram',
            descriptionKey: 'UI.Diagrams.creationModal.freeDiagramDescription',
            imageUrl: '/images/diagrams/free-diagram.svg',
            isDefault: true,
        },
    ];
    public selectedDiagramType: IDiagramTypeInfo;
    public diagramForm: UntypedFormGroup;
    public entitySelectorOptions: IEntitySelectorData;

    public get isNameReadonly() {
        return !!this.diagramKind;
    }
    public get isTypeReadOnly() {
        return !!this.diagramKind;
    }

    constructor(
        private translate: TranslateService,
        private formBuilder: UntypedFormBuilder,
        private diagramDataService: DiagramDataService,
    ) {
        super();
    }

    ngOnInit() {
        this.log(
            'init',
            this.spaceIdr,
            this.sourceIdr,
            DiagramKind[this.diagramKind],
        );
        this.selectedDiagramType = this.diagramTypes.find((dt) =>
            this.diagramKind
                ? dt.diagramKind == this.diagramKind
                : dt.isDefault,
        );
        this.initEntitySelector();
        this.initForm();
    }

    public needsSource(diagramType: IDiagramTypeInfo) {
        return DiagramIdentifier.isModelerDiagramKind(diagramType.diagramKind);
    }

    public selectDiagramType(diagramType: IDiagramTypeInfo) {
        this.selectedDiagramType = diagramType;
        if (
            diagramType.diagramKind == DiagramKind.MonoSourceDatabase &&
            this.sourceIdr &&
            this.isSourceReadonly
        ) {
            this.diagramForm.controls.sourceIdr.setValue(this.sourceIdr);
        }
        this.updateModelIdrValidator();
        this.onChanged();
    }

    public onChanged() {
        this.diagramForm.updateValueAndValidity();
        const config = this.diagramForm.valid ? this.diagramForm.value : null;
        this.log('onChanged', config);
        this.configChanged.emit(config);
    }

    private initForm() {
        if (this.sourceIdr) {
            this.selectedDiagramType = this.diagramTypes.find(
                (dt) => dt.diagramKind == DiagramKind.MonoSourceDatabase,
            );
        }
        this.diagramForm = this.formBuilder.group({
            sourceIdr: this.sourceIdr,
            displayName: [
                this.displayName ?? '',
                { validators: [Validators.required] },
            ],
        });
        this.updateModelIdrValidator();

        this.registerSubscription(
            this.diagramForm.controls.displayName.valueChanges
                .pipe(debounceTime(444), distinctUntilChanged())
                .subscribe(async (value) => {
                    await this.onDisplayNameChange(value);
                    this.onChanged();
                }),
        );
    }

    private initEntitySelector() {
        this.entitySelectorOptions = {
            includedEntityTypes: [EntityType.RelationalModel],
            spaceIdr: this.spaceIdr,
            initialSearch: true,
            minChars: 0,
        };
    }

    private updateModelIdrValidator() {
        const ctrl = this.diagramForm.controls.sourceIdr;
        const needSource = this.needsSource(this.selectedDiagramType);
        ctrl.setValidators(needSource ? [Validators.required] : null);
        if (!needSource) {
            ctrl.setValue(undefined);
        }
        ctrl.updateValueAndValidity();
        this.onDisplayNameChange(this.diagramForm.controls.displayName.value);
    }

    // archi-forms(revi) use angular async validator mechanism
    private async isDisplayNameUnique(displayName: string): Promise<boolean> {
        // archi-diagrams (revi) To be removed when diagram types are merged
        if (this.needsSource(this.selectedDiagramType)) {
            return true;
        }
        const sourceIdr = (this.diagramForm.value as IDiagramForm).sourceIdr;
        const diagramKind = sourceIdr
            ? DiagramKind.MonoSourceDatabase
            : DiagramKind.Free;
        const result = await this.diagramDataService.preCreateDiagram(
            displayName.trim(),
            this.spaceIdr,
            diagramKind,
        );
        return result.Status != PreCreateEntityStatus.Exists;
    }

    private async onDisplayNameChange(displayName: string) {
        if (!displayName) {
            return;
        }
        this.diagramForm.markAsPending();
        const isValid = await this.isDisplayNameUnique(displayName);
        const error = isValid
            ? null
            : {
                  existingName: this.translate.instant(
                      'UI.Dialog.NewItem.Diagram.lblNameAlreadyExisting',
                      { name: displayName },
                  ),
              };
        this.diagramForm.controls.displayName.setErrors(error);
        this.diagramForm.markAsTouched();
    }
}
