import { Component, forwardRef, Inject, OnInit } from '@angular/core';
import { DxyBaseModalComponent } from '@datagalaxy/ui/dialog';
import {
    IRuleCreateModalInput,
    IRuleCreateModalOutput,
    IRuleForm,
} from './rule-create-modal.types';
import {
    MAT_DIALOG_DATA,
    MatDialogModule,
    MatDialogRef,
} from '@angular/material/dialog';
import { DataQualityRuleType } from '@datagalaxy/webclient/data-quality/data-access';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { IFieldSelectAdapter, withLoading } from '@datagalaxy/core-ui';
import {
    FormBuilder,
    FormControl,
    FormGroup,
    FormsModule,
    ReactiveFormsModule,
    Validators,
} from '@angular/forms';
import { IDxyEntitySelectorInputs } from '../../shared/entitySelector/entity-selector.types';
import { EntityType, ServerType } from '@datagalaxy/dg-object-model';
import {
    EntityApiService,
    LoadMultiEntityParameter,
} from '@datagalaxy/webclient/entity/data-access';
import { RuleCodeValidatorService } from '../validators/rule-code-validator.service';
import { AsyncPipe } from '@angular/common';
import { DxyModalFooterComponent } from '../../shared/dialogs/dxy-modal-footer/dxy-modal-footer.component';
import { DxyEntitySelectorFieldComponent } from '../../shared/entitySelector/dxy-entity-selector-field/dxy-entity-selector-field.component';
import { DxyFieldSelectComponent } from '@datagalaxy/core-ui/fields';
import { DxyFieldTextComponent } from '@datagalaxy/ui/fields';
import { WorkspaceStore } from '@datagalaxy/webclient/workspace/data-access';
import { DxyIconButtonDirective } from '@datagalaxy/ui/buttons';

/**
 * ## Role
 * Modal to create a new rule
 */
@Component({
    selector: 'app-rule-create-modal',
    templateUrl: './rule-create-modal.component.html',
    standalone: true,
    imports: [
        MatDialogModule,
        TranslateModule,
        DxyIconButtonDirective,
        FormsModule,
        ReactiveFormsModule,
        DxyFieldTextComponent,
        DxyFieldSelectComponent,
        forwardRef(() => DxyEntitySelectorFieldComponent),
        DxyModalFooterComponent,
        AsyncPipe,
    ],
})
export class RuleCreateModalComponent
    extends DxyBaseModalComponent<IRuleCreateModalInput, IRuleCreateModalOutput>
    implements OnInit
{
    private fieldTypesMap = new Map<EntityType, ServerType[]>([
        [EntityType.View, [ServerType.Column]],
        [EntityType.Table, [ServerType.Column]],
        [EntityType.File, [ServerType.Column]],
        [EntityType.Document, [ServerType.Column]],
        [EntityType.Directory, [ServerType.Column]],
    ]);

    protected formGroup: FormGroup<IRuleForm>;
    private readonly spaceId: string;

    public entitySelectorOptions: IDxyEntitySelectorInputs;

    constructor(
        private entityApiService: EntityApiService,
        private workspaceStore: WorkspaceStore,
        private translate: TranslateService,
        dialogRef: MatDialogRef<
            RuleCreateModalComponent,
            IRuleCreateModalOutput
        >,
        @Inject(MAT_DIALOG_DATA) data: IRuleCreateModalInput,
        private fb: FormBuilder,
        private ruleCodeValidatorService: RuleCodeValidatorService,
    ) {
        super(dialogRef, data);
        const currentSpace = workspaceStore.currentSpace;
        this.spaceId = currentSpace.ReferenceId.split(':')[0];
        this.formGroup = this.fb.group<IRuleForm>({
            code: new FormControl(null, {
                asyncValidators:
                    this.ruleCodeValidatorService.uniqueCodeRuleValidator(),
            }),
            type: new FormControl(null, Validators.required),
            statement: new FormControl(null, Validators.required),
            fieldIds: new FormControl([]),
        });
    }

    public ruleTypesAdapter: IFieldSelectAdapter<DataQualityRuleType> = {
        options: Object.values(DataQualityRuleType),
        getText: (type: DataQualityRuleType) =>
            this.translate.instant(
                `UI.DataQualityRules.DataQualityRuleType.${type}`,
            ),
        onSelectionChange: (t: DataQualityRuleType) => {
            this.formGroup.controls.type.setValue(t);
        },
    };

    protected get codeErrorMessage() {
        const code = this.formGroup.controls.code;
        if (code?.valid) {
            return undefined;
        }
        return code.errors?.ruleCodeAlreadyExists;
    }

    ngOnInit() {
        this.log('ngOnInit', this);
        void this.initAsync();
    }

    @withLoading()
    private async initAsync() {
        const versionId = this.workspaceStore.currentSpace.versionId;
        const entities = await this.entityApiService.loadMultiEntity(
            new LoadMultiEntityParameter(
                this.data.entityId,
                this.fieldTypesMap.get(this.data.entityType),
                false,
                0,
                1000,
                '',
                '',
                '',
                [],
                [],
            ),
        );
        const includedIds = entities.Entities.map((e) => e.ReferenceId);
        this.entitySelectorOptions = {
            spaceIdr: {
                spaceId: this.spaceId,
                versionId: versionId,
            },
            includeSearchPreferences: false,
            includedIds,
        };
    }

    onCloseSubmit() {
        const { statement, type, code, fieldIds } = this.formGroup.value;
        this.result = {
            statement,
            type,
            code,
            fieldIds: fieldIds.map((e) => e.ReferenceId),
        };
        // create rule
        super.onCloseSubmit();
    }
}
