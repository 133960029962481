import { BehaviorSubject, Observable, Subscription } from 'rxjs';

/**
 * This is *NOT* the base class for an angular component
 *
 * (see core-ui/src/base/DxyBaseComponent)
 *
 * Base class providing helpers for:
 * - loading
 * - logging
 * - events subscription and unsubscription
 */
export abstract class BaseComponent {
    protected _debug = false;
    protected _logId?: string;

    public get loading$() {
        return this.loadingSubject.asObservable();
    }

    public get loadingValue() {
        return this.loadingSubject.value;
    }

    protected loadingSubject = new BehaviorSubject<boolean>(false);

    /** rxjs subscriptions to unregister when the component is being disposed */
    protected registeredSubscriptions?: Subscription;

    /** methods to call when the component is being disposed */
    protected registeredDestroyers?: (() => void)[];

    /** name of the component used in the log method */
    private _constructorName?: string;

    //#region loading
    public startLoading() {
        return this.loadingSubject.next(true);
    }

    public endLoading() {
        return this.loadingSubject.next(false);
    }

    //#enregion

    //#region subscriptions

    /** subscribes an action to an event subject.
     * The subscription will be automatically unsubscribed on component's disposing */
    protected subscribe<T>(
        eventSubject: Observable<T>,
        action: (eventData?: T) => void,
    ) {
        if (!eventSubject || !action) {
            return;
        }
        const subscription = eventSubject.subscribe(action);
        return this.registerSubscription(subscription);
    }

    /** registers the given subscription so it will be automatically unsubscribed on component's disposing */
    protected registerSubscription(subscription: Subscription) {
        if (!subscription) {
            return;
        }
        (this.registeredSubscriptions ??= new Subscription()).add(subscription);
        return subscription;
    }

    /** registers the given subscriptions so they will be automatically unsubscribed on component's disposing */
    protected registerSubscriptions(...subscriptions: Subscription[]) {
        subscriptions.forEach((s) => this.registerSubscription(s));
    }

    /** unsubscribe the given subscription */
    protected unsubscribe(subscription: Subscription) {
        if (!subscription) {
            return;
        }
        subscription.unsubscribe();
        this.registeredSubscriptions?.remove(subscription);
    }

    /** unregisters the given group if not null,
     * creates and returns a new registered group,
     * with the given subscriptions */
    protected renewSubscriptionGroup(
        group: Subscription,
        ...subs: Subscription[]
    ) {
        this.unsubscribe(group);
        const newGroup = new Subscription();
        subs.forEach((s) => s && newGroup.add(s));
        this.registerSubscription(newGroup);
        return newGroup;
    }

    /** registers one or many functions to be executed when the component is being disposed */
    protected registerForDestroy(...destroyers: (() => void)[]) {
        (this.registeredDestroyers ??= []).push(...destroyers);
    }

    //#endregion

    //#region logging

    protected get logger() {
        return (...args: unknown[]) => this.log(...args);
    }

    protected getLogger(logId: string) {
        return (...args: unknown[]) => this.log(logId, ...args);
    }

    /** Logs the given arguments to console if debug is true, with this object's constructor name.
     * A good practice is to pass the calling method's name as first argument.
     * Please avoid heavy computation on arguments, to keep the call fast when logging is off */
    protected log(...args: unknown[]) {
        if (!this._debug) {
            return;
        }
        if (this._logId) {
            console.log(this._constructorName, this._logId, ...args);
        } else {
            console.log(this._constructorName, ...args);
        }
    }

    protected logData(title: string, data: object, ...args: unknown[]) {
        if (!this._debug) {
            return;
        }
        if (typeof data == 'object') {
            this.log(
                title,
                ...Object.entries(data)
                    .map((k, v) => [`\n\t${k}:`, v])
                    .reduce((p, c) => p.concat(c), []),
                ...args,
            );
        } else {
            this.log(title, data, ...args);
        }
    }

    protected logTrace(...args: unknown[]) {
        if (!this._debug) {
            return;
        }
        const c = console as { trace?(...args: unknown[]): void };
        if (this._logId) {
            c.trace?.(this.constructor.name, this._logId, ...args);
        } else {
            c.trace?.(this.constructor.name, ...args);
        }
    }

    //#endregion
}
