<dxy-message-content
    [canClose]="canClose()"
    (closeClick)="closeClick.emit()"
    [color]="color()"
    [timerDuration]="timerDuration()"
    [titleGlyphClass]="titleGlyphClass()"
>
    <ng-content select="[message-title]" message-title></ng-content>
    <ng-content select="[message-content]" message-content></ng-content>
</dxy-message-content>
