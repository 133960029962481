<div class="modal-header">
    <h3
        mat-dialog-title
        class="modal-title"
        translate="UI.Campaign.Details.Objects.AddObjectModal.Title"
    ></h3>
    <button dxyIconButton color="transparent" (click)="onCloseCancel()">
        <i class="glyph-cancelsearch"></i>
    </button>
</div>
<form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
    <div mat-dialog-content>
        <dxy-entity-selector-field
            formControlName="entities"
            labelKey="UI.Campaign.Details.Objects.AddObjectModal.Form.Objects"
            [options]="entitySelectorData"
            [isMultiValue]="true"
            [menuTakeFullWidth]="true"
            [mandatory]="true"
            [openPreviewOnSelectedItemClick]="true"
        ></dxy-entity-selector-field>
    </div>
    <dxy-modal-footer
        [actionEnabled]="formGroup.valid"
        [actionBtnLbl]="
            'UI.Campaign.Details.Objects.AddObjectModal.Submit' | translate
        "
        [actionLoading]="loading$ | async"
        (onCloseCancel)="onCloseCancel()"
        featureCode="CAMPAIGN,C"
    ></dxy-modal-footer>
</form>
