import {
    IShift,
    IXY,
    IXYRect,
    IXYRectRO,
    Rect,
} from '@datagalaxy/core-2d-util';
import {
    IOffsetContext,
    ITransformAdapter,
    IZoomedViewContext,
} from './zoom-transform.types';

/** ## Role
 * Transforms 2d-coordinates to or from a view */
abstract class BaseTransformAdapter implements ITransformAdapter {
    /* Note: This type of definition for methods ensures that 'this' is always defined */

    public readonly xy = <T extends IXY>(
        x: number,
        y: number,
        result = {} as T,
    ) => {
        result.x = this.x(x);
        result.y = this.y(y);
        return result;
    };

    public readonly dxy = <T extends IShift>(
        dx: number,
        dy: number,
        result = {} as T,
    ) => {
        result.dx = this.dx(dx);
        result.dy = this.dy(dy);
        return result;
    };

    public readonly point = <T extends IXY>(p: IXY, result = {} as T) => {
        result.x = this.x(p.x);
        result.y = this.y(p.y);
        return result;
    };

    public readonly rectTo = <T extends IXYRect>(r: IXYRectRO, result?: T) =>
        Rect.transform(r, this.x, this.y, result);

    public readonly rectSelf = <T extends IXYRect>(r: T) =>
        Rect.transform(r, this.x, this.y, r);

    constructor(
        public readonly x: (x: number) => number = (x) => x,
        public readonly y: (y: number) => number = (y) => y,
        public readonly dx: (dx: number) => number = (dx) => dx,
        public readonly dy: (dy: number) => number = (dy) => dy,
    ) {}
}

/** ## Role
 * 2d identity transform (returns given coordinates) */
export class IdentityAdapter extends BaseTransformAdapter {}

/** ## Role
 * Transforms 2d-coordinates by adding (default) or removing (invert) the view container offset */
export class OffsetAdapter extends BaseTransformAdapter {
    constructor(
        readonly ctx: Readonly<IOffsetContext>,
        invert = false,
    ) {
        super(
            invert ? (x) => x - ctx.offset.x : (x) => x + ctx.offset.x,
            invert ? (y) => y - ctx.offset.y : (y) => y + ctx.offset.y,
        );
    }
}

/** ## Role
 * Transforms 2d-coordinates to or from a zoomed view.
 * (*from* if *invert* is true )  */
export class D3ZoomTransformAdapter extends BaseTransformAdapter {
    constructor(
        readonly ctx: Readonly<IZoomedViewContext>,
        invert = false,
        parent?: ITransformAdapter,
    ) {
        super(
            invert
                ? parent
                    ? (x) => ctx.zt.invertX(parent.x(x))
                    : (x) => ctx.zt.invertX(x)
                : parent
                  ? (x) => ctx.zt.applyX(parent.x(x))
                  : (x) => ctx.zt.applyX(x),
            invert
                ? parent
                    ? (y) => ctx.zt.invertY(parent.y(y))
                    : (y) => ctx.zt.invertY(y)
                : parent
                  ? (y) => ctx.zt.applyY(parent.y(y))
                  : (y) => ctx.zt.applyY(y),
            invert
                ? parent
                    ? (dx) => parent.dx(dx) / ctx.zt.k
                    : (dx) => dx / ctx.zt.k
                : parent
                  ? (dx) => parent.dx(dx) * ctx.zt.k
                  : (dx) => dx * ctx.zt.k,
            invert
                ? parent
                    ? (dy) => parent.dy(dy) / ctx.zt.k
                    : (dy) => dy / ctx.zt.k
                : parent
                  ? (dy) => parent.dy(dy) * ctx.zt.k
                  : (dy) => dy * ctx.zt.k,
        );
    }
}

export class D3FixedZoomTransformAdapter extends BaseTransformAdapter {
    constructor(
        readonly ctx: Readonly<IZoomedViewContext>,
        invert = false,
    ) {
        super(
            invert ? (dx) => dx / ctx.zt.k : (dx) => dx * ctx.zt.k,
            invert ? (dy) => dy / ctx.zt.k : (dy) => dy * ctx.zt.k,
            invert ? (dx) => dx / ctx.zt.k : (dx) => dx * ctx.zt.k,
            invert ? (dy) => dy / ctx.zt.k : (dy) => dy * ctx.zt.k,
        );
    }
}
