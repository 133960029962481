import { Injectable } from '@angular/core';
import { BaseService } from '@datagalaxy/core-ui';
import { RestApiService } from '@datagalaxy/data-access';
import { ActivateSlackIntegrationCommand } from './commands/ActivateSlackIntegrationCommand';
import { ActivateSlackNotificationsCommand } from './commands/ActivateSlackNotificationsCommand';
import { SlackRedirectionQueryParams } from '../slack-redirection.types';
import { SlackRedirectionQueryResult } from './queries/SlackRedirectionQueryResult';

@Injectable({ providedIn: 'root' })
export class SlackApiService extends BaseService {
    constructor(private restApiService: RestApiService) {
        super();
    }

    public async getSlackRedirectionUrl(redirectUrl: string) {
        const params = new URLSearchParams();
        params.append(SlackRedirectionQueryParams.redirectUri, redirectUrl);
        return await this.restApiService.postPromise<
            SlackRedirectionQueryResult,
            void
        >(`notifications/slack/redirection?${params.toString()}`);
    }

    public async activateIntegration(code: string) {
        const parameter = new ActivateSlackIntegrationCommand();
        parameter.SlackCode = code;
        await this.restApiService.postPromise(
            'notifications/integrations/slack',
            parameter,
        );
    }

    public async deactivateIntegration() {
        await this.restApiService.deletePromise(
            'notifications/integrations/slack',
        );
    }

    public async activateNotifications(code: string) {
        const parameter = new ActivateSlackNotificationsCommand();
        parameter.SlackCode = code;
        await this.restApiService.postPromise(
            'notifications/channels/slack',
            parameter,
        );
    }

    public async deactivateNotifications() {
        await this.restApiService.deletePromise('notifications/channels/slack');
    }

    public async askIntegrationActivation() {
        await this.restApiService.postPromise(
            'notifications/integrations/ask/slack',
        );
    }
}
