import { DataProcessingLinkEntityType } from '../../data-processing.types';
import { DataProcessingLinkDto } from './DataProcessingLinkDto';
import { GraphicalEntityData } from './GraphicalEntityData';

export class GraphicalEntityDataDpLink extends GraphicalEntityData {
    public LinkData: DataProcessingLinkDto;

    public get EntityReferenceId() {
        return this.LinkedDataReferenceId;
    }
    public get EntityType(): DataProcessingLinkEntityType {
        return this.LinkData.EntityType;
    }
    public get LinkedDataReferenceId(): string {
        return this.LinkData.LinkHierarchyData.DataReferenceId;
    }
    public get isColumn() {
        return this.EntityType === DataProcessingLinkEntityType.Column;
    }

    constructor(dpLink: DataProcessingLinkDto) {
        super(
            dpLink.LinkHierarchyData.Data,
            dpLink.LinkHierarchyData.Parents,
            dpLink.LinkHierarchyData.TechnologyCode,
        );
        this.LinkData = dpLink;
        this.DataReferenceId = dpLink.ReferenceId;
        this.GraphicalUniqueId = dpLink.ReferenceId;
    }
}
