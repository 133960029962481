import { CoreUtil } from '@datagalaxy/core-util';
import { DxyAttributeBaseInput } from '../inputs/DxyAttributeBaseInput';
import {
    Component,
    ElementRef,
    forwardRef,
    Input,
    OnInit,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ComparatorItemClient } from '../../entity/compare-entity.types';
import { AttributeFieldInfo } from '../attribute.types';
import { DxyEntityAttributeInputComponent } from '../dxy-entity-attribute-input/dxy-entity-attribute-input.component';
import { DxyCollapsibleComponent } from '@datagalaxy/core-ui';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'dxy-attribute-compare-item',
    templateUrl: 'dxy-attribute-compare-item.component.html',
    standalone: true,
    imports: [
        DxyCollapsibleComponent,
        forwardRef(() => DxyEntityAttributeInputComponent),
    ],
})
export class DxyAttributeCompareItemComponent
    extends DxyAttributeBaseInput<void>
    implements OnInit
{
    @Input() comparatorItemClient: ComparatorItemClient;

    public itemLabel: string;
    public attributeFieldInfoCopySource: AttributeFieldInfo;
    public attributeFieldInfoCopyTarget: AttributeFieldInfo;

    constructor(
        elementRef: ElementRef<HTMLElement>,
        translate: TranslateService,
    ) {
        super(elementRef, translate);
    }

    ngOnInit() {
        const cic = this.comparatorItemClient;
        const ami = this.attributeMeta;
        if (ami !== cic.attributeMeta) {
            CoreUtil.warn('attributeMeta mismatch');
        }
        this.itemLabel = ami.translatedDisplayName;
        cic.newValue = cic.targetValue;

        const vcc = this.entityForm.versionCompareContext;

        const source = CoreUtil.clone(ami);
        source.roleValue = 'Source';
        source.translatedDisplayName = vcc.sourceVersion;
        this.attributeFieldInfoCopySource = new AttributeFieldInfo(
            source,
            this.entityForm,
        );

        const target = CoreUtil.clone(ami);
        target.roleValue = 'Target';
        target.IsReadOnly = true;
        target.translatedDisplayName = vcc.targetVersion;
        this.attributeFieldInfoCopyTarget = new AttributeFieldInfo(
            target,
            this.entityForm,
        );
    }
}
