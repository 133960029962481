import { IDataIdentifier } from '@datagalaxy/dg-object-model';
import { IDpMappingItemOptions } from '../dp-mapping.types';
import { GraphicalEntityData } from './GraphicalEntityData';
import { GraphicalItem } from './GraphicalItem';

export class GraphicalContainer implements IDataIdentifier {
    //#region IDataIdentifier (for type compatiblity in app-dp-item-element.component )
    public DataReferenceId: string;
    public DataTypeName: string;
    //#endregion

    public readonly collapsedItem: GraphicalItem;
    public uniqueId: string;
    public displayName: string;
    public parentData: GraphicalEntityData;
    public isCollapsed: boolean;
    public parent: GraphicalContainer;
    public updateContainerContent: () => void;

    public get itemsCount() {
        return this.items.size;
    }

    private readonly items = new Map<string, GraphicalItem>();

    constructor(
        parentData: GraphicalEntityData,
        dataListName = '',
        isCollapsed = false,
        itemOptions?: IDpMappingItemOptions,
    ) {
        this.uniqueId = parentData.GraphicalUniqueId;
        this.displayName = parentData.DisplayName;
        this.parentData = parentData;
        this.isCollapsed = isCollapsed;
        if (dataListName) {
            itemOptions ??= {};
            itemOptions.id = `${dataListName}_${this.uniqueId}`;
        }

        this.collapsedItem = new GraphicalItem(parentData, itemOptions);
        this.collapsedItem.container = this;
    }

    public addItem(graphicalItem: GraphicalItem) {
        if (!graphicalItem || this.items.has(graphicalItem.id)) {
            return false;
        }
        this.setItem(graphicalItem.id, graphicalItem);
        return true;
    }
    public setItem(key: string, item: GraphicalItem) {
        if (item) {
            item.container = this;
        }
        this.items.set(key, item);
    }
    public hasItem(key: string) {
        return this.items.has(key);
    }
    public getItems() {
        return Array.from(this.items.values());
    }
    public getItemKeys() {
        return Array.from(this.items.keys());
    }
    public getItem(key: string) {
        return this.items.get(key);
    }
    public removeItem(key: string) {
        return this.items.delete(key);
    }
}
