import { Component, Input, OnInit } from '@angular/core';
import {
    BaseCellComponent,
    baseIconCollectionCellInputs,
    DxyIconCellComponent,
    ICollectionCellInputs,
    ICollectionCellOption,
    ICollectionCellParams,
} from '@datagalaxy/core-ui/cell-components';
import { DataQualityCheck } from '@datagalaxy/webclient/data-quality/data-access';
import { DateTimeUtil } from '@datagalaxy/core-util';
import { GlyphUtil } from '../../shared/util/GlyphUtil';
import { DxyCollectionCellComponent } from '@datagalaxy/core-ui/cell-components';
import { NgIf } from '@angular/common';

/**
 * ## Role
 * Display last quality checks in a collection cell in an omnigrid
 */
@Component({
    selector: 'app-quality-last-check-cell',
    templateUrl: 'last-checks-cell.component.html',
    styleUrls: ['last-checks-cell.component.scss'],
    standalone: true,
    imports: [NgIf, DxyCollectionCellComponent],
})
export class LastChecksCellComponent
    extends BaseCellComponent<any, DataQualityCheck[]>
    implements OnInit
{
    @Input() enablePopover?: boolean;
    @Input() checks: DataQualityCheck[];

    protected collectionCellParams?: ICollectionCellParams;

    ngOnInit() {
        this.updateLayout();
    }

    public override updateLayout() {
        super.updateLayout();
        const collectionInputs: ICollectionCellInputs = {
            ...baseIconCollectionCellInputs,
            options: this.checks?.map((ruleCheck) =>
                this.buildOption(ruleCheck),
            ),
            hideLabel: true,
            disableTooltip: this.enablePopover,
        };
        this.collectionCellParams = {
            inputs: collectionInputs,
            isMini: true,
        };
    }

    protected override updateBindings() {
        this.checks = this.value;
    }

    private buildOption(ruleCheck: DataQualityCheck): ICollectionCellOption {
        const checkDatetime = DateTimeUtil.formatForDisplay(
            ruleCheck.creationTime,
            true,
            undefined,
            true,
        );
        return {
            labelText: this.makeLabelText(checkDatetime, ruleCheck.message),
            renderData: {
                renderer: DxyIconCellComponent,
                param: {
                    value: {
                        glyphClass: GlyphUtil.getDataQualityGlyphClass(
                            ruleCheck.status,
                        ),
                    },
                },
            },
        };
    }

    private makeLabelText(ruleCheckCreationTime: string, message: string) {
        return `${ruleCheckCreationTime}${message ? ` : ${message}` : ''}`;
    }
}
