import { Inject, Injectable } from '@angular/core';
import {
    RestApiService,
    V3_REST_API_SERVICE_TOKEN,
} from '@datagalaxy/data-access';
import { IEntityIdentifier } from '@datagalaxy/dg-object-model';
import { TextAttributeValueSuggestion } from '@datagalaxy/webclient/suggestion/domain';

@Injectable({ providedIn: 'root' })
export class AutoDescriptionApiService {
    constructor(
        @Inject(V3_REST_API_SERVICE_TOKEN)
        private restApiService: RestApiService,
    ) {}

    public getDescriptionSuggestion(entityIdr: IEntityIdentifier) {
        return this.restApiService.postPromise<TextAttributeValueSuggestion>(
            `objects/${entityIdr.ReferenceId}/description-suggestion`,
        );
    }

    public getAttributeValueSuggestions(entityIdr: IEntityIdentifier) {
        return this.restApiService.getPromise<TextAttributeValueSuggestion>(
            `objects/${entityIdr.ReferenceId}/attribute-value-suggestions`,
        );
    }

    public updateDescriptionSuggestion(
        entityIdr: IEntityIdentifier,
        suggestion: TextAttributeValueSuggestion,
    ) {
        return this.restApiService.putPromise<TextAttributeValueSuggestion>(
            `objects/${entityIdr.ReferenceId}/description-suggestion`,
            suggestion,
        );
    }

    public getSummarySuggestion(entityIdr: IEntityIdentifier) {
        return this.restApiService.postPromise<TextAttributeValueSuggestion>(
            `objects/${entityIdr.ReferenceId}/summary-suggestion`,
        );
    }

    public updateSummarySuggestion(
        entityIdr: IEntityIdentifier,
        suggestion: TextAttributeValueSuggestion,
    ) {
        return this.restApiService.putPromise<TextAttributeValueSuggestion>(
            `objects/${entityIdr.ReferenceId}/summary-suggestion`,
            suggestion,
        );
    }
}
