import { BaseGraphicalManager } from '../../BaseGraphicalManager';
import { GraphManager } from '../../graph-manager';
import { GraphEdgeEvent, GraphNodeEvent } from '../../graph-manager.types';
import { selectAll } from 'd3-selection';
import { Connector } from '../../connector';
import {
    IGraphEdgeHoverEvent,
    IGraphNodePortHoverEvent,
    IHoverToolOptions,
} from './hover-tool.types';
import { Subject } from 'rxjs';
import { CollectionsHelper } from '@datagalaxy/core-util';
import { NodePort } from '../../ports';

export class HoverTool<
    NodeData = unknown,
    EdgeData = unknown,
> extends BaseGraphicalManager {
    private readonly connectorHover = new Subject<
        IGraphEdgeHoverEvent<NodeData, EdgeData>
    >();

    private readonly nodePortHover = new Subject<
        IGraphNodePortHoverEvent<NodeData>
    >();

    private ports: NodePort<NodeData>[] = [];

    public get connectorHover$() {
        return this.connectorHover.asObservable();
    }

    public get nodePortHover$() {
        return this.nodePortHover.asObservable();
    }

    constructor(
        private graph: GraphManager<NodeData, EdgeData>,
        private options: IHoverToolOptions,
    ) {
        super();
        super.subscribe(graph.edgesEvents$, (event) =>
            this.onGraphEdgeEvent(event),
        );
        super.subscribe(graph.nodesEvents$, (event) =>
            this.onGraphNodesEvent(event),
        );
    }

    private onGraphEdgeEvent(event: GraphEdgeEvent) {
        if (event.removed?.length) {
            selectAll(event.removed.map((c) => c.el)).on(
                'mouseenter mouseleave',
                null,
            );
        } else if (event.added?.length) {
            const connectors = event.added;

            selectAll(connectors.map((c) => c.el))
                .data(connectors)
                .on('mouseenter', (event, c) => this.onEdgeHover(event, c))
                .on('mouseleave', (event, c) => this.onEdgeLeave(event, c));
        }
    }

    private onGraphNodesEvent(event: GraphNodeEvent<NodeData>) {
        if (event.removed?.length) {
            selectAll(event.removed.map((c) => c.el)).on(
                'mouseenter mouseleave',
                null,
            );
        } else if (event.added?.length) {
            const nodes = event.added;
            const ports = CollectionsHelper.flatten(
                nodes.map((c) => c.getPorts()),
            );

            this.ports.push(...ports);

            selectAll(ports.map((p) => p.el))
                .on('mouseenter', (event) =>
                    this.onPortHover(event, this.getPort(event)),
                )
                .on('mouseleave', (event) =>
                    this.onPortLeave(event, this.getPort(event)),
                );
        }
    }

    private onEdgeHover(event: MouseEvent, connector: Connector) {
        connector.updateProps({
            cssClass: [this.options?.hoverClass, connector.cssClass].join(' '),
        });
        connector.draw();
        this.notifyEdgeHover(connector, true, event);
    }

    private onEdgeLeave(event: MouseEvent, connector: Connector) {
        connector.updateProps({
            cssClass: connector.cssClass
                .replace(this.options?.hoverClass, '')
                .trim(),
        });
        connector.draw();
        this.notifyEdgeHover(connector, false, event);
    }

    private onPortHover(event: MouseEvent, port: NodePort<NodeData>) {
        this.nodePortHover.next({ port, show: true, event });
    }

    private onPortLeave(event: MouseEvent, port: NodePort<NodeData>) {
        this.nodePortHover.next({ port, show: false, event });
    }

    private notifyEdgeHover(
        connector: Connector,
        show: boolean,
        event: MouseEvent,
    ) {
        const edge = this.graph.getEdgeFromConnector(
            connector as Connector<NodeData, EdgeData>,
        );
        this.connectorHover.next({ edge, show, event });
    }

    private getPort(event: MouseEvent) {
        return this.ports.find((port) =>
            port.el.contains(event.target as Node),
        );
    }
}
