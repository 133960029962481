import { IActivityLogEntryTranslationData } from './activity-log-entry-translation.type';
import { DefaultLogEntryAdapter } from './default-log-entry-adapter';
import {
    ActivityLogEntry,
    ActivityLogEntryType,
} from '@datagalaxy/webclient/activity-log/domain';

/**
 * ## Role
 * Activity log adapter for logs concerning entity links
 */
export class EntityLinkLogEntryAdapter extends DefaultLogEntryAdapter {
    public override getEntryTranslationData(
        entry: ActivityLogEntry,
    ): IActivityLogEntryTranslationData {
        const data = super.getEntryTranslationData(entry);
        data.translateParams.entityLinkName =
            this.getEntityLinkName(entry) ?? '→';
        data.translateKey = this.getTranslateKey(entry);
        return data;
    }

    public getTranslateKey(entry: ActivityLogEntry) {
        const entryType = entry.EntryType;
        const key = [
            ActivityLogEntryType.Create,
            ActivityLogEntryType.AddObject,
        ].includes(entryType)
            ? 'AddEntityLink'
            : entryType == ActivityLogEntryType.Update
              ? 'UpdateEntityLink'
              : entryType == ActivityLogEntryType.DeleteObject
                ? 'DeleteEntityLink'
                : '';
        return key && `${this.baseTranslateKey}${key}`;
    }

    protected override getHData(entry: ActivityLogEntry) {
        const id = entry.EntityLinkSourceId;
        return this.getHDataById(entry, id);
    }

    protected override getChildHData(entry: ActivityLogEntry) {
        const id = entry.EntityLinkTargetId;
        return this.getHDataById(entry, id);
    }
}
