import { ButtonVariant } from '@datagalaxy/ui/buttons';
import { IMultiSelectData } from '../components/multiselect-list/multiselect-list.types';
import { IListOption } from '../IListOption';
import { GraphicalColor } from '@datagalaxy/shared/graphical/domain';

export interface IGraphicalToolbarOption extends IListOption {
    id?: string;
    options?: IGraphicalToolbarOption[];

    getMultiSelectData?: () => IMultiSelectData;

    /** Should be true when the option is the active one in its toolbar.
     * Note: it will not be clickable */
    isCurrentActive?: boolean | (() => boolean);

    /** when true, sub-options are displayed as an array of *stride* width. */
    stride?: number;

    /** when true, and *options* is defined, options are styled for a burger-menu */
    isBurgerMenu?: boolean;

    buttonVariant?: ButtonVariant;
}

export interface IBoundingBox {
    left: number;
    top: number;
    width: number;
    height: number;
}

/**
 * @See GraphicalColor
 */
export const GraphicalColorSorted = [
    GraphicalColor.Indigo,
    GraphicalColor.Ruby,
    GraphicalColor.Magenta,
    GraphicalColor.Orange,
    GraphicalColor.Wood,
    GraphicalColor.Green,
    GraphicalColor.Lime,
    GraphicalColor.Yellow,
    GraphicalColor.Reflex,
    GraphicalColor.Reflex2,
    GraphicalColor.Blue,
    GraphicalColor.Turquoise,
    GraphicalColor.Gray,
    GraphicalColor.LightGray,
    GraphicalColor.Black,
    GraphicalColor.White,
    GraphicalColor._multi,
    GraphicalColor._none,
];
