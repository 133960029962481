import { IColDef } from '../grid-column/grid-column.types';
import { ColumnDef, SortingTableState } from '@tanstack/table-core';
import { GridColumnUtils } from '../grid-column/grid-column.utils';
import { GridConfig } from '../grid-config';

export class TanStackGridAdapter {
    public static convertColumns<TRow>(
        columns: IColDef<TRow>[],
    ): ColumnDef<TRow>[] {
        const avgSize = GridColumnUtils.getAverageColumnSize(columns);
        return columns.map((col) => ({
            id: col.id,
            header: col.headerLabel,
            accessorFn: (row) => GridColumnUtils.getColumnValue(col, row),
            sortingFn: (a, b) =>
                GridColumnUtils.getColumnSorting(col, a.original, b.original),
            minSize: col.minWidth,
            maxSize: col.maxWidth,
            size: col.width || avgSize,
            meta: col,
            enableSorting: !!col.sortable,
            enableResizing: col.resizable,
        }));
    }

    public static getColumnsInitialState<TRow>(
        config?: GridConfig<TRow>,
    ): SortingTableState {
        const activeSortingConfig = config?.sorting?.activeSort;

        const sorting = activeSortingConfig
            ? [
                  {
                      id: activeSortingConfig.columnId,
                      desc: activeSortingConfig.direction === 'desc',
                  },
              ]
            : [];

        return {
            sorting,
        };
    }
}
