import { GenericDeserialize, Serialize } from 'cerialize';
import { CollectionsHelper, CoreUtil } from '@datagalaxy/core-util';
import { BaseService } from '@datagalaxy/core-ui';
import { Injectable } from '@angular/core';
import {
    EntityType,
    EntityTypeUtil,
    IEntityIdentifier,
    ServerType,
} from '@datagalaxy/dg-object-model';
import { ToasterService } from '../services/toaster.service';
import { ViewTypeUtil } from '../shared/util/ViewTypeUtil';
import {
    IRealTimeAttributeHandler,
    IRealTimeUpdateScreenHandler,
    RealTimeCommService,
    UpdateScreenAction,
    UpdateScreenRtData,
} from '../services/realTimeComm.service';
import { ClientAdminAttributeService } from '../client-admin-attribute/client-admin-attribute.service';
import { ComparatorItemClient } from '../shared/entity/compare-entity.types';
import { TranslateService } from '@ngx-translate/core';
import {
    ApplyScreenCategoryTemplateParameter,
    BaseCategoryTemplateParameter,
    CategoryTemplateDto,
    CategoryTemplateType,
    CreateSpaceScreenParameter,
    GenericScreenResult,
    GetClientScreensParameter,
    GetSpaceScreensParameter,
    ResetCategoryTemplateParameter,
    ResetClientScreenParameter,
    ResetSpaceScreenParameter,
    ScreenApiService,
    ScreenCategory,
    ScreenDTO,
    ScreenLayout,
    ScreenProperty,
    UpdateCategoryTemplateParameter,
    UpdateClientScreenParameter,
    UpdateSpaceScreenParameter,
} from '@datagalaxy/webclient/screen/data-access';
import { WorkspaceIdentifier } from '@datagalaxy/webclient/workspace/utils';
import {
    AttributeDTO,
    AttributeMetaType,
} from '@datagalaxy/webclient/attribute/domain';
import { ServerConstants } from '@datagalaxy/shared/server/domain';
import { WorkspaceDetails } from '@datagalaxy/webclient/workspace/domain';

@Injectable({ providedIn: 'root' })
export class ScreenService extends BaseService {
    //#region static

    public static getScreenKey(screen: ScreenDTO) {
        return screen
            ? `${screen.ModuleName}.${screen.SubTypeName || '_none'}`
            : '';
    }

    public static getTranslateKey(name: string): string {
        return ViewTypeUtil.getTranslateKey('UI.Components.ScreenAdmin', name);
    }

    public static getScreenDisplayNameTranslateKey(screen: ScreenDTO) {
        if (screen.isTemplateScreen) {
            return 'UI.NavSideBar.adminAllTypesScreens';
        }
        const baseTranslateKey = `${ScreenService.getTranslateKey(
            'displayName',
        )}.Details.${screen.ModuleName}`;
        if (screen.SubTypeName) {
            return `${baseTranslateKey}.${screen.SubTypeName}`;
        }
        return baseTranslateKey;
    }

    public static readonly componentName = {
        entityForm: 'entity-form',
        compareEntityForm: 'compare-entity-form',
        entitySynonyms: 'entity-synonyms',
    };

    private static readonly componentNameDefault =
        ScreenService.componentName.entityForm;

    public static getScreenLayoutCategoryUsedComponentName(
        screenCategory: ScreenCategory,
        isVersioningCompare: boolean,
    ) {
        const componentName =
            isVersioningCompare || screenCategory.IsSystem
                ? screenCategory.UsedComponent
                : ScreenService.componentNameDefault;
        return componentName;
    }

    private static getComponentName(
        screenCategory: ScreenCategory,
        screenType = ScreenType.entityForm,
    ) {
        return (
            ScreenService.getSpecificComponentName(
                screenCategory?.SpecialKey,
            ) ?? ScreenService.getDefaultComponentName(screenType)
        );
    }
    private static getSpecificComponentName(specialKey: string) {
        switch (specialKey) {
            case ServerConstants.ScreenCategory.SynonymsSpecialKey:
                return ScreenService.componentName.entitySynonyms;
            case ServerConstants.ScreenCategory.HeaderSpecialKey:
                return ScreenService.getDefaultComponentName(
                    ScreenType.entityForm,
                );
            default:
                return;
        }
    }
    private static getDefaultComponentName(screenFor: ScreenType) {
        switch (screenFor) {
            case ScreenType.versionCompare:
                return ScreenService.componentName.compareEntityForm;
            default:
                return ScreenService.componentNameDefault;
        }
    }
    //#endregion

    private isClientModuleScreensLoaded: boolean;

    private currentVersionId: string;
    private currentSpaceId: string;

    private clientModuleScreens = new Map<string, Map<string, ScreenDTO>>();
    private spaceModuleScreens = new Map<string, Map<string, ScreenDTO>>();

    constructor(
        private toasterService: ToasterService,
        private attributeAdminService: ClientAdminAttributeService,
        private screenApiService: ScreenApiService,
        private realTimeCommService: RealTimeCommService,
        private translate: TranslateService,
    ) {
        super();
    }

    public init() {
        this.isClientModuleScreensLoaded = false;
        this.clientModuleScreens.clear();
        this.spaceModuleScreens.clear();
        this.currentSpaceId = null;
        this.currentVersionId = null;
    }

    public initSpaceScreens() {
        this.spaceModuleScreens.clear();
        this.currentSpaceId = null;
        this.currentVersionId = null;
    }

    public subscribeScreenUpdate(handler: IRealTimeUpdateScreenHandler) {
        return this.realTimeCommService.subscribeUpdateScreen(handler);
    }

    public subscribeAttributeDelete(handler: IRealTimeAttributeHandler) {
        return this.realTimeCommService.subscribeAttributeDelete(handler);
    }

    public getScreen(spaceId: string, moduleName: string, subTypeName: string) {
        const screens = spaceId
            ? this.spaceModuleScreens
            : this.clientModuleScreens;
        const moduleScreens = screens.get(moduleName);
        return subTypeName
            ? moduleScreens?.get(subTypeName)
            : CollectionsHelper.getFirstValueInMap(moduleScreens);
    }

    public getScreensFromSettings(
        space: WorkspaceDetails,
        serverTypeNameFilter: string,
    ) {
        const moduleScreens = (
            space ? this.spaceModuleScreens : this.clientModuleScreens
        ).get(serverTypeNameFilter);
        return (moduleScreens && Array.from(moduleScreens.values())) || [];
    }

    public async ensureSpaceScreensAreLoaded(
        spaceId: string,
        versionId: string,
    ) {
        if (this.hasSpaceScreens(spaceId, versionId)) {
            return Promise.resolve();
        }

        this.initSpaceScreens();
        await this.loadSpaceScreens(spaceId, versionId);
    }

    public async loadSpaceScreens(spaceId: string, versionId?: string) {
        this.log('loadSpaceScreens', spaceId, versionId);
        const screens = await this.getSpaceScreens(spaceId, null, versionId);
        this.currentSpaceId = spaceId;
        this.currentVersionId = versionId;
        screens.forEach((s) =>
            this.addScreenToLocalCache(this.spaceModuleScreens, s),
        );
    }

    public async loadClientScreens(clearCache = true) {
        this.log('loadClientScreens', clearCache);

        if (clearCache) {
            this.clearScreenLocalCache();
        } else if (
            this.isClientModuleScreensLoaded &&
            this.clientModuleScreens.size > 0
        ) {
            return;
        }

        const screens = await this.getClientScreens();
        screens.forEach((s) =>
            this.addScreenToLocalCache(this.clientModuleScreens, s),
        );
        this.isClientModuleScreensLoaded = true;
    }

    public clearScreenLocalCache() {
        this.clientModuleScreens.clear();
    }

    private addScreenToLocalCache(
        cache: Map<string, Map<string, ScreenDTO>>,
        screen: ScreenDTO,
    ) {
        if (!cache.has(screen.ModuleName)) {
            cache.set(screen.ModuleName, new Map<string, ScreenDTO>());
        }
        const moduleMap = cache.get(screen.ModuleName);
        const subTypeName = screen.SubTypeName || '_none';
        moduleMap.set(subTypeName, screen);
    }

    private hasSpaceScreens(spaceId: string, versionId: string) {
        return (
            this.currentSpaceId == spaceId &&
            this.currentVersionId == versionId &&
            this.spaceModuleScreens &&
            this.spaceModuleScreens.size != 0
        );
    }

    public getSpaceScreenForEntity(
        entityIdr: IEntityIdentifier,
        clone = false,
    ) {
        if (!entityIdr) {
            return;
        }
        const spaceId = WorkspaceIdentifier.fromEntity(entityIdr)?.spaceId;
        const mapping = EntityTypeUtil.getMapping(entityIdr.entityType);
        const screenData = this.getSpaceScreen(
            spaceId,
            mapping.DataTypeName,
            mapping.SubTypeName,
        );
        return screenData && clone ? CoreUtil.clone(screenData) : screenData;
    }
    //#Archi-screens (fbo) spaceId is not used, but a screenDto has one. what is the logic ?
    private getSpaceScreen(
        spaceId: string,
        typeName: string,
        subTypeName?: string,
    ): ScreenDTO {
        if (this.spaceModuleScreens.size == 0) {
            this.warn('Should have called loadSpaceScreens');
        }

        const subType = subTypeName || '_none';
        const moduleScreens = this.spaceModuleScreens.get(typeName);
        const screen = moduleScreens.get(subType);
        return screen;
    }

    private isClientDefinedScreen(screen: ScreenDTO) {
        return screen.IsClientLevel;
    }

    private isCurrentScreen(currentScreen: ScreenDTO, screenKey: string) {
        return (
            !!currentScreen &&
            ScreenService.getScreenKey(currentScreen) === screenKey
        );
    }

    public resolveScreenLayout(screen: ScreenDTO) {
        const mergedScreenLayout = new ScreenLayout();
        mergedScreenLayout.Categories = [];

        if (!screen) {
            this.warn('resolveScreenLayout: no screen');
        }

        const customPartScreenLayout =
            screen && this.deserializeScreenLayout(screen.Layout);

        if (customPartScreenLayout) {
            customPartScreenLayout.Categories.forEach(
                (sc) =>
                    (sc.UsedComponent = ScreenService.getComponentName(
                        sc,
                        ScreenType.entityForm,
                    )),
            );

            mergedScreenLayout.Categories.push(
                ...customPartScreenLayout.Categories,
            );
        }

        return mergedScreenLayout;
    }

    public resolveScreenLayoutWithAttribute(
        screen: ScreenDTO,
        comparatorItems: Map<string, ComparatorItemClient>,
    ) {
        const mergedScreenLayout = new ScreenLayout();
        const propertiesOutOfScreen: ScreenProperty[] = [],
            resultPropertiesOutOfScreen: ComparatorItemClient[] = [];
        const customPartScreenLayout =
            screen && this.deserializeScreenLayout(screen.Layout);
        if (customPartScreenLayout) {
            // Merge categories
            const mergedCats = mergedScreenLayout.Categories;
            customPartScreenLayout.Categories.forEach((sc) => {
                sc.UsedComponent = ScreenService.getComponentName(
                    sc,
                    ScreenType.versionCompare,
                );
                const foundProperties = [];
                comparatorItems.forEach((cic) => {
                    const foundProperty = sc.Properties?.find(
                        (atp) => atp.Key == cic.attributeMeta.AttributeKey,
                    );
                    if (foundProperty) {
                        foundProperties.push(foundProperty);
                        propertiesOutOfScreen.push(foundProperty);
                    }
                });
                if (foundProperties.length) {
                    sc.Properties = foundProperties;
                    mergedCats.push(sc);
                }
            });
        }
        comparatorItems.forEach((item) => {
            const key = item.attributeMeta.AttributeKey;
            const differenceValue = propertiesOutOfScreen?.find(
                (ps) => ps.Key == key,
            );
            if (!differenceValue) {
                resultPropertiesOutOfScreen.push(item);
            }
        });
        if (resultPropertiesOutOfScreen) {
            const screenProperties: ScreenProperty[] =
                resultPropertiesOutOfScreen.map((prop) => ({
                    Key: prop.attributeMeta.AttributeKey,
                    IsSystem: true,
                }));
            if (screenProperties.length) {
                const outofScreen: ScreenCategory = {
                    DisplayName: 'dgScreenOffScreen',
                    Id: 'dgScreenOffScreen',
                    IsHidden: false,
                    IsOpen: false,
                    IsSpecialSystem: false,
                    IsHeader: false,
                    Properties: screenProperties,
                    SpecialKey: '',
                    IsSystem: true,
                    UsedComponent:
                        ScreenService.componentName.compareEntityForm,
                };
                mergedScreenLayout.Categories.splice(0, 0, outofScreen);
            }
        }
        return mergedScreenLayout;
    }

    private deserializeScreenLayout(jsonScreenLayout: string): ScreenLayout {
        try {
            return GenericDeserialize<ScreenLayout>(
                JSON.parse(jsonScreenLayout),
                ScreenLayout,
            );
        } catch (e) {
            this.warn(e);
            return null;
        }
    }

    private deserializeScreenCategory(
        jsonScreenCategory: string,
    ): ScreenCategory {
        try {
            return GenericDeserialize(
                JSON.parse(jsonScreenCategory),
                ScreenCategory,
            );
        } catch (e) {
            this.warn(e);
            return null;
        }
    }

    public serializeScreenLayout(screenLayout: ScreenLayout) {
        return JSON.stringify(Serialize(screenLayout));
    }

    public serializeScreenCategory(screenLayout: ScreenCategory) {
        return JSON.stringify(Serialize(screenLayout));
    }

    private getAttributeFromScreenPropertiesByField(
        screen: ScreenDTO,
        attributeKey: string,
    ) {
        return screen.Attributes?.find(
            (attr) => attr.AttributeKey === attributeKey,
        );
    }

    public async createSpaceScreen(
        spaceId: string,
        clientScreen: ScreenDTO,
        screenLayout: ScreenLayout,
    ) {
        const layout = this.serializeScreenLayout(screenLayout);
        const param = new CreateSpaceScreenParameter(
            spaceId,
            clientScreen.ModuleName,
            clientScreen.SubTypeName,
            layout,
        );
        const result = await this.screenApiService.createSpaceScreen(param);
        this.addScreenToLocalCache(this.spaceModuleScreens, result.Screen);
        return result.Screen;
    }

    public async updateScreenLayout(
        screen: ScreenDTO,
        screenLayout: ScreenLayout,
    ) {
        const serializedLayout = this.serializeScreenLayout(screenLayout);
        let result: GenericScreenResult;
        if (screen.IsClientLevel) {
            const cparam = new UpdateClientScreenParameter(
                screen.ModuleName,
                screen.SubTypeName,
                serializedLayout,
            );
            result = await this.screenApiService.updateClientScreen(cparam);
        } else {
            const sparam = new UpdateSpaceScreenParameter(
                screen.SpaceId,
                screen.ModuleName,
                screen.SubTypeName,
                serializedLayout,
            );
            result = await this.screenApiService.updateSpaceScreen(sparam);
        }

        if (result.Screen.IsSpaceLevel) {
            this.addScreenToLocalCache(this.spaceModuleScreens, result.Screen);
        }
        if (result.Screen.IsClientLevel) {
            this.addScreenToLocalCache(this.clientModuleScreens, result.Screen);
        }
        return result.Screen;
    }

    public isAttributeUsedInScreen(screen: ScreenDTO, attribute: AttributeDTO) {
        return !!this.getAttributeFromScreenPropertiesByField(
            screen,
            attribute.AttributeKey,
        );
    }

    public getScreenPropertyDisplayName(
        serverType: ServerType,
        screenProperty: ScreenProperty,
    ) {
        return this.attributeAdminService.getAttributeDisplayName(
            serverType,
            screenProperty.Key,
        );
    }

    public getScreenCategoryNameKey(screenCategory: ScreenCategory) {
        const { DisplayName, Id } = screenCategory;
        return DisplayName && Id != DisplayName
            ? DisplayName
            : `UI.Components.ScreenAdmin.categories.${Id}`;
    }

    private async getSpaceScreens(
        spaceId: string,
        moduleName: string,
        versionId?: string,
    ) {
        // For now, we get all the screens for one space
        const param = new GetSpaceScreensParameter(
            spaceId,
            moduleName,
            versionId,
        );
        const result = await this.screenApiService.getSpaceScreens(param);
        return result.Screens;
    }

    private async getClientScreens() {
        const param = new GetClientScreensParameter();
        const result = await this.screenApiService.getClientScreens(param);
        return result.Screens;
    }

    public async resetClientScreen(screen: ScreenDTO) {
        const param = new ResetClientScreenParameter(
            screen.ModuleName,
            screen.SubTypeName,
        );
        const result = await this.screenApiService.resetClientScreen(param);
        this.addScreenToLocalCache(this.clientModuleScreens, result.Screen);
        return result.Screen;
    }

    public async resetSpaceScreen(spaceId: string, screen: ScreenDTO) {
        const param = new ResetSpaceScreenParameter(
            spaceId,
            screen.ModuleName,
            screen.SubTypeName,
        );
        const result = await this.screenApiService.resetSpaceScreen(param);
        this.addScreenToLocalCache(this.spaceModuleScreens, result.Screen);
        return result.Screen;
    }

    public updateScreenRealTime(
        updateScreenRtData: UpdateScreenRtData,
        space: WorkspaceDetails,
    ) {
        const screen = updateScreenRtData.screen;

        if (space) {
            if (screen.IsSpaceLevel && screen.SpaceId !== space.ReferenceId) {
                return false;
            }

            const currentSpaceScreen = this.getSpaceScreen(
                space.ReferenceId,
                screen.ModuleName,
                screen.SubTypeName,
            );

            let addToSpaceCache: boolean;
            if (screen.IsClientLevel) {
                addToSpaceCache =
                    currentSpaceScreen.IsClientLevel ||
                    updateScreenRtData.action ==
                        UpdateScreenAction.ResetSpaceScreen;
            } else {
                addToSpaceCache = true;
            }

            if (!addToSpaceCache) {
                return false;
            }

            this.addScreenToLocalCache(this.spaceModuleScreens, screen);
            return true;
        }

        if (screen.IsSpaceLevel) {
            return false;
        }

        this.addScreenToLocalCache(this.clientModuleScreens, screen);
        return true;
    }

    public async getScreenCategoryTemplates(spaceId: string) {
        const param = new BaseCategoryTemplateParameter(spaceId);
        return await this.screenApiService.getScreenCategoryTemplates(param);
    }

    public async updateScreenCategoryTemplate(
        categoryTemplateDto: CategoryTemplateDto,
    ) {
        categoryTemplateDto.CategoryTemplate =
            this.buildLayoutFromTemplate(categoryTemplateDto);
        const param = new UpdateCategoryTemplateParameter(
            categoryTemplateDto.SpaceId,
            categoryTemplateDto.TemplateUid,
            categoryTemplateDto.TemplateType,
            categoryTemplateDto.CategoryTemplate,
        );
        return await this.screenApiService.updateScreenCategoryTemplate(param);
    }

    public async resetScreenCategoryTemplate(
        spaceId: string,
        templateId: string,
    ) {
        const param = new ResetCategoryTemplateParameter(spaceId, templateId);
        const result =
            await this.screenApiService.resetScreenCategoryTemplate(param);
        return result.CategoryTemplateDto;
    }

    public async applyScreenCategoryTemplate(
        spaceId: string,
        templateId: string,
        templateType: CategoryTemplateType,
        entityTypes: EntityType[],
    ) {
        const param = new ApplyScreenCategoryTemplateParameter(
            spaceId,
            templateId,
            templateType,
            entityTypes,
        );
        const result =
            await this.screenApiService.applyScreenCategoryTemplate(param);
        this.toasterService.successToast({
            titleKey:
                'UI.Components.ScreenTemplateAdmin.applySuccessToaster.title',
            messageKey:
                'UI.Components.ScreenTemplateAdmin.applySuccessToaster.message',
        });
        return result;
    }

    private buildLayoutFromTemplate(
        categoryTemplateDto: CategoryTemplateDto,
    ): string {
        const screenCategory = this.deserializeScreenCategory(
            categoryTemplateDto.CategoryTemplate,
        );
        screenCategory.Properties = categoryTemplateDto.Attributes.map(
            (attribute) =>
                ({
                    Key: attribute.AttributeKey,
                    IsSystem: attribute.IsSystem,
                }) as ScreenProperty,
        );
        return this.serializeScreenCategory(screenCategory);
    }

    public async applyScreenCategoryHeaderTemplate(
        spaceId: string,
        screen: ScreenDTO,
    ) {
        const templates = await this.getScreenCategoryTemplates(spaceId);
        const selectedTemplate = templates.CategoryTemplateDtoList.find(
            (template) => template.TemplateType == CategoryTemplateType.Header,
        );
        const entityType = EntityTypeUtil.getEntityType(
            screen.ModuleName,
            screen.SubTypeName,
        );
        return await this.applyScreenCategoryTemplate(
            spaceId,
            selectedTemplate.TemplateUid,
            selectedTemplate.TemplateType,
            [entityType],
        );
    }

    public validateHeaderAttribute(attribute: AttributeDTO) {
        const forbiddenPropertyKeys = [
            AttributeMetaType.FormattedText,
            AttributeMetaType.Text,
            AttributeMetaType.MultiLineText,
            AttributeMetaType.MultiValueList,
        ];

        if (forbiddenPropertyKeys.includes(attribute?.AttributeType)) {
            this.toasterService.warningToast({
                messageKey:
                    'UI.Components.ScreenAdmin.msgHeaderForbiddenAttributeType',
                messageParams: {
                    attributeType: this.translate.instant(
                        `DgServerTypes.AttributeType.${
                            AttributeMetaType[attribute?.AttributeType]
                        }`,
                    ),
                },
            });
            return false;
        }
        return true;
    }
}

export enum ScreenType {
    entityForm = 0,
    versionCompare,
}
