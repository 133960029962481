<div>
    <div *ngIf="hasLabel" class="space-label" translate>
        UI.SpaceVersionSelector.workspace
    </div>
    <dxy-workspace-dropdown
        class="space-selector"
        data-dtname="Space List Menu"
        [class.has-label]="hasLabel"
        [workspaceId]="spaceIdr?.spaceId"
        [options]="spaces$ | async"
        [config]="workspaceSelectorConfig"
        (selectWorkspace)="onSpaceSelected($event)"
        (openClose)="onMenuOpenCloseInternal($event)"
    ></dxy-workspace-dropdown>
</div>

<ng-container *ngIf="isVersioned$ | async">
    <div class="separator"></div>

    <div *ngIf="showOfficialVersion" class="all-spaces-version-text">
        <div *ngIf="showOfficialVersionLabel" class="space-label" translate>
            UI.SpaceVersionSelector.version
        </div>
        <div translate>UI.SpaceVersionSelector.officialVersion</div>
    </div>
    <div>
        <div *ngIf="hasLabel" class="space-label" translate>
            UI.SpaceVersionSelector.version
        </div>
        <dxy-versioning-selector
            class="version-selector"
            [projectIdr]="spaceIdr"
            [enableFavorite]="enableFavorite"
            [readonly]="readonly"
            [isVersionSelectable]="!readonly"
            [openOnDisplayNameClick]="!displaySelectedWorkspaceAsLink"
            [hideOfficialVersion]="hideOfficialVersion"
            [selectedTextTrackerId]="trackerIds?.versionSelectedText"
            [selectedCaretTrackerId]="trackerIds?.versionDropdownCaret"
            [logId]="logId"
            (onProjectVersionSelected)="onVersionSelected($event)"
            (openClose)="onMenuOpenCloseInternal($event)"
        ></dxy-versioning-selector>
    </div>
</ng-container>
