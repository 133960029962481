import { Inject, Injectable } from '@angular/core';
import {
    RestApiService,
    V3_REST_API_SERVICE_TOKEN,
} from '@datagalaxy/data-access';
import { MultilingualSettings } from './multilingual-settings';
import { MultilingualAttributeSetting } from './multilingual-attribute-setting';
import { MultilingualLanguageSetting } from './multilingual-language-setting';
import { MultilingualLexiconStatistics } from './multilingual-lexicon-statistics';
import { HttpHeaders } from '@angular/common/http';
import { getRawTextFromFile } from '@datagalaxy/core-util';
import { TranslationBatch } from '@datagalaxy/webclient/multilingual/domain';

@Injectable({ providedIn: 'root' })
export class MultilingualApiService {
    constructor(
        @Inject(V3_REST_API_SERVICE_TOKEN)
        private restApiService: RestApiService,
    ) {}

    public getSettings() {
        return this.restApiService.getPromise<MultilingualSettings>(
            `clientspace/multi-lingual`,
        );
    }
    public updateSettings(settings: MultilingualSettings) {
        return this.restApiService.putPromise<MultilingualSettings>(
            `clientspace/multi-lingual`,
            settings,
        );
    }

    public addAttributeSettings(
        attributeSettings: MultilingualAttributeSetting[],
    ) {
        return this.restApiService.postPromise<MultilingualAttributeSetting[]>(
            `clientspace/multi-lingual/attribute-collection`,
            attributeSettings,
        );
    }
    public addTargetLanguage(targetLanguage: MultilingualLanguageSetting) {
        return this.restApiService.postPromise(
            `clientspace/multi-lingual/target-languages`,
            targetLanguage,
        );
    }

    public getTargetLanguages() {
        return this.restApiService.getPromise<MultilingualLanguageSetting[]>(
            `clientspace/multi-lingual/target-languages`,
        );
    }

    public getLanguagePendingBatches(languageCode: string) {
        return this.restApiService.getPromise<TranslationBatch[]>(
            `clientspace/multi-lingual/batches?targetLanguageCode=${languageCode}&status=pending`,
        );
    }

    public getPendingBatches() {
        return this.restApiService.getPromise<TranslationBatch[]>(
            `clientspace/multi-lingual/batches?status=pending`,
        );
    }

    public getAttributeSettings() {
        return this.restApiService.getPromise<MultilingualAttributeSetting[]>(
            `clientspace/multi-lingual/attributes`,
        );
    }

    public removeAttributeSetting(id: string) {
        return this.restApiService.deletePromise(
            `clientspace/multi-lingual/attributes/${id}`,
        );
    }

    public async uploadLexicon(file: File) {
        if (!file) {
            return;
        }
        const fileContent = await getRawTextFromFile(file);
        let headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'text/csv');
        await this.restApiService.putPromise(
            `clientspace/multi-lingual/lexicon`,
            fileContent,
            undefined,
            {
                headers,
            },
        );
    }

    public async downloadLexicon() {
        const result = await this.restApiService.downloadPromise(
            'clientspace/multi-lingual/lexicon',
        );
        return result.body;
    }

    public getLexiconStatistics() {
        return this.restApiService.get<MultilingualLexiconStatistics>(
            `clientspace/multi-lingual/lexicon/statistics`,
        );
    }
}
