<div class="modal-header">
    <h3 mat-dialog-title class="modal-title" translate>
        UI.Synonyms.CreationModal
    </h3>
    <button dxyIconButton color="transparent" (click)="onCloseCancel()">
        <i class="glyph-cancelsearch"></i>
    </button>
</div>
<form (ngSubmit)="onCloseSubmit()">
    <div mat-dialog-content>
        <dxy-field-text
            name="name"
            labelKey="UI.Components.AttributeAdmin.form.DisplayName"
            [(ngModel)]="result.displayName"
            (ngModelChange)="updateCanCreate()"
            [mandatory]="true"
        ></dxy-field-text>
        <dxy-field-text
            name="description"
            labelKey="UI.Components.AttributeAdmin.createModal.lblDescription"
            [(ngModel)]="result.description"
            (ngModelChange)="updateCanCreate()"
        ></dxy-field-text>
    </div>
    <dxy-modal-footer
        [actionEnabled]="canCreate"
        [actionBtnLbl]="actionBtnLblKey | translate"
        (onCloseCancel)="onCloseCancel()"
    ></dxy-modal-footer>
</form>
