import { ITypedEvent, dgEventTypeMappings } from '@datagalaxy/dg-object-model';
import { EntityAttributeUpdatedInterpolationProvider } from './entity-attribute-updated-interpolation-provider';
import { MetaBotInterpolationProvider } from './metabot-interpolation-provider';
import { Injectable, Injector } from '@angular/core';
import { IDgEventInterpolationProvider } from './dg-event-interpolation-provider';
import { MsTeamsInterpolationProvider } from './ms-teams-interpolation-provider';
import { SlackInterpolationProvider } from './slack-interpolation-provider';

const providers = [
    {
        eventTypes: EntityAttributeUpdatedInterpolationProvider.eventTypes,
        providerType: EntityAttributeUpdatedInterpolationProvider,
    },
    {
        eventTypes: MetaBotInterpolationProvider.eventTypes,
        providerType: MetaBotInterpolationProvider,
    },
    {
        eventTypes: MsTeamsInterpolationProvider.eventTypes,
        providerType: MsTeamsInterpolationProvider,
    },
    {
        eventTypes: SlackInterpolationProvider.eventTypes,
        providerType: SlackInterpolationProvider,
    },
];

@Injectable({ providedIn: 'root' })
export class InterpolationProviderService {
    constructor(private injector: Injector) {}

    public getInterpolationProvider(
        typedEvent: ITypedEvent,
    ): IDgEventInterpolationProvider | undefined {
        if (!typedEvent) {
            return;
        }
        const eventConstructor = dgEventTypeMappings.get(
            typedEvent.EventTypeName,
        );
        if (!eventConstructor) {
            return;
        }
        const adapterConstructor = providers.find((p) =>
            p.eventTypes
                .map((et) => et.prototype)
                .includes(eventConstructor.prototype),
        )?.providerType;

        if (!adapterConstructor) {
            return;
        }
        const adapter =
            this.injector.get<IDgEventInterpolationProvider>(
                adapterConstructor,
            );
        return adapter;
    }
}
