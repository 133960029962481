import {
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import {
    MatLegacyMenuTrigger as MatMenuTrigger,
    MatLegacyMenuModule,
} from '@angular/material/legacy-menu';
import { ServerType } from '@datagalaxy/dg-object-model';
import { SuggestionService } from '../../../suggestions/suggestion.service';
import {
    ISuggestion,
    ISuggestionGroup,
} from '../../../suggestions/suggestion.types';
import {
    CrudActionType,
    CrudOperation,
    FunctionalLogService,
} from '@datagalaxy/shared/monitoring/data-access';
import { SuggestionType } from '@datagalaxy/webclient/suggestion/types';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { AttributeMetaInfo } from '@datagalaxy/webclient/attribute/domain';
import { SuggestionCellComponent } from '../../../suggestions/suggestion-cell/suggestion-cell.component';
import { NgFor, NgIf } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';
import { DxyIconButtonDirective } from '@datagalaxy/ui/buttons';

/**
 * ## Role
 * Dropdown button to display suggestions inside an attribute input
 */
@Component({
    selector: 'app-attribute-suggestion-list',
    templateUrl: './attribute-suggestion-list.component.html',
    styleUrls: ['./attribute-suggestion-list.component.scss'],
    standalone: true,
    imports: [
        MatLegacyMenuModule,
        DxyIconButtonDirective,
        MatLegacyTooltipModule,
        TranslateModule,
        NgFor,
        SuggestionCellComponent,
        NgIf,
    ],
})
export class AttributeSuggestionListComponent
    extends DxyBaseComponent
    implements OnInit, OnChanges
{
    @Input() suggestionGroup: ISuggestionGroup;
    @Input() serverType: ServerType;
    @Input() attributeInfo: AttributeMetaInfo;

    /** if true, enable multi suggestion acceptation */
    @Input() isMultiValue: boolean;

    public filteredSuggestions: ISuggestion[] = [];

    public get title() {
        return `UI.Suggestions.MultiValue.Title.${this.featureSuffix}`;
    }
    public get description() {
        return `UI.Suggestions.MultiValue.Description.${this.featureSuffix}`;
    }

    private get featureSuffix() {
        return SuggestionType[this.suggestionGroup.type].toUpperCase();
    }

    @ViewChild(MatMenuTrigger) matMenuTrigger: MatMenuTrigger;

    constructor(
        private functionalLogService: FunctionalLogService,
        private suggestionService: SuggestionService,
    ) {
        super();
    }

    ngOnInit() {
        this.filterSuggestions();
    }

    ngOnChanges(changes: SimpleChanges) {
        super.onChange(changes, 'suggestionGroup', () =>
            this.onSuggestionsChange(),
        );
    }

    public async onUserChoiceForAll(accepted: boolean) {
        this.log('onUserChoiceForAll');
        const crudActionType = accepted
            ? CrudActionType.AcceptAll
            : CrudActionType.DeclineAll;

        this.functionalLogService.logFunctionalAction(
            `ROBOT_ALL_SUGGESTION_${this.featureSuffix}`,
            CrudOperation.A,
            crudActionType,
        );
        await this.suggestionService.applySuggestion(
            this.suggestionGroup,
            accepted,
        );
    }

    public onMenuOpened() {
        this.logViewedSuggestions(this.filteredSuggestions); // unawaited on purpose
        this.logMenuToggle(true);
    }

    public onMenuClosed() {
        this.logMenuToggle(false);
    }

    private onSuggestionsChange() {
        const previousFilteredSuggestions = this.filteredSuggestions;
        this.filterSuggestions();
        if (!this.matMenuTrigger.menuOpen) {
            return;
        }
        const previousIds = previousFilteredSuggestions?.map((s) => s.id);
        const diff = this.filteredSuggestions?.filter(
            (s) => !previousIds.includes(s.id),
        );
        this.logViewedSuggestions(diff); // unawaited on purpose
    }

    private filterSuggestions() {
        this.filteredSuggestions = this.suggestionGroup.suggestions.slice(
            0,
            10,
        );
    }

    private async logViewedSuggestions(suggestions: ISuggestion[]) {
        await this.suggestionService.logViewedSuggestions(suggestions);
    }

    private logMenuToggle(open: boolean) {
        this.functionalLogService.logFunctionalAction(
            `ROBOT_LIST_SUGGESTION_${this.featureSuffix}`,
            CrudOperation.A,
            open ? CrudActionType.Unhide : CrudActionType.Hide,
        );
    }
}
