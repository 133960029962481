<div class="modal-header">
    <h3 mat-dialog-title class="modal-title" translate>
        UI.Modeler.ForeignKeyDeleteModal.title
    </h3>
    <button dxyIconButton color="transparent" (click)="onCloseCancel()">
        <i class="glyph-cancelsearch"></i>
    </button>
</div>
<div mat-dialog-content>
    <p translate>UI.Modeler.ForeignKeyDeleteModal.msgDelete</p>
    <mat-checkbox [(ngModel)]="isKeepFunctionalForeignKey">
        <span class="checkbox-text" translate
            >UI.Modeler.ForeignKeyDeleteModal.lblKeepFunctionalForeignKey</span
        >
    </mat-checkbox>
</div>
<div class="modal-footer dxy-actions">
    <button
        type="button"
        dxyButton
        variant="secondary"
        name="cancel"
        (click)="onCloseCancel()"
    >
        <span translate>UI.Global.btnCancel</span>
    </button>
    <button
        type="button"
        dxyButton
        name="submit"
        dxyLogFunctional="DIAGRAM_FK_TABLE,D"
        (click)="onCloseSubmit()"
    >
        <span translate>UI.Global.btnDelete</span>
    </button>
</div>
