import {
    deserialize,
    deserializeAs,
    inheritSerialization,
    serialize,
} from 'cerialize';
import {
    BaseServiceParameter,
    BaseServiceResult,
} from '@datagalaxy/data-access';
import { TeamAccessType, TeamDto, TeamPublicData } from './team';
import { ItemUsage } from '@datagalaxy/dg-object-model';

@inheritSerialization(BaseServiceParameter)
export class GetTeamsParameter extends BaseServiceParameter {
    @serialize public IsAdministration: boolean;

    constructor(IsAdministration: boolean) {
        super();
        this.IsAdministration = IsAdministration;
    }
}

@inheritSerialization(BaseServiceResult)
export class GetTeamsResult extends BaseServiceResult {
    @deserializeAs(TeamDto) public Teams!: TeamDto[];

    constructor() {
        super();
    }
}

@inheritSerialization(BaseServiceResult)
export class GetTeamResult extends BaseServiceResult {
    @deserializeAs(TeamDto) public Team!: TeamDto;

    constructor() {
        super();
    }
}

@inheritSerialization(BaseServiceParameter)
export class BaseTeamParameter extends BaseServiceParameter {
    @serialize public TeamUid: string;

    constructor(teamId: string) {
        super();
        this.TeamUid = teamId;
    }
}

@inheritSerialization(BaseServiceParameter)
export class UpdateTeamParameter extends BaseServiceParameter {
    @serialize public TeamUid: string;
    @serialize public TeamName: string;
    @serialize public Email: string;
    @serialize public AccessType: TeamAccessType;
    @serialize public Description: string;
    @serialize public TeamOwners: string[];

    constructor(
        teamId: string,
        teamName: string,
        accessType: TeamAccessType,
        description: string,
        email: string,
        teamOwners: string[],
    ) {
        super();
        this.TeamUid = teamId;
        this.TeamName = teamName;
        this.Email = email;
        this.AccessType = accessType;
        this.Description = description;
        this.TeamOwners = teamOwners;
    }
}

@inheritSerialization(BaseServiceParameter)
export class CreateTeamParameter extends BaseServiceParameter {
    @serialize public TeamName: string;
    @serialize public AccessType: TeamAccessType;
    @serialize public Description?: string;

    constructor(
        teamName: string,
        accessType: TeamAccessType,
        description?: string,
    ) {
        super();
        this.TeamName = teamName;
        this.AccessType = accessType;
        this.Description = description;
    }
}

@inheritSerialization(BaseServiceParameter)
export class CloneTeamParameter extends BaseServiceParameter {
    @serialize public TeamUid: string;

    constructor(teamId: string) {
        super();
        this.TeamUid = teamId;
    }
}

@inheritSerialization(BaseServiceResult)
export class CreateTeamResult extends BaseServiceResult {
    @deserialize CreatedTeam!: TeamDto;

    constructor() {
        super();
    }
}

@inheritSerialization(BaseServiceParameter)
export class PreCreateTeamParameter extends BaseServiceParameter {
    @serialize public TeamName: string;

    constructor(teamName: string) {
        super();
        this.TeamName = teamName;
    }
}

@inheritSerialization(BaseServiceResult)
export class PreCreateTeamResult extends BaseServiceResult {
    @deserialize IsTeamNameUsed!: boolean;

    constructor() {
        super();
    }
}

@inheritSerialization(BaseServiceResult)
export class UpdateTeamResult extends BaseServiceResult {
    @deserialize UpdatedTeam!: TeamDto;

    constructor() {
        super();
    }
}

@inheritSerialization(BaseServiceParameter)
export class DeleteTeamsParameter extends BaseServiceParameter {
    @serialize public TeamUidList: string[];

    constructor(teamIds: string[]) {
        super();
        this.TeamUidList = teamIds;
    }
}

@inheritSerialization(BaseServiceResult)
export class GetTeamPublicDataResult extends BaseServiceResult {
    @deserializeAs(TeamPublicData) public TeamPublicDatas!: TeamPublicData[];

    constructor() {
        super();
    }
}

@inheritSerialization(BaseServiceParameter)
export class PredeleteTeamsParameter extends BaseServiceParameter {
    @serialize public TeamGuids!: string[];
}

@inheritSerialization(ItemUsage)
export class TeamUsage extends ItemUsage {
    @deserialize TeamGuids!: string[];
}

@inheritSerialization(BaseServiceResult)
export class PreDeleteTeamsResult extends BaseServiceResult {
    @deserialize public CanBeDeleted!: boolean;
    @deserializeAs(TeamUsage) public Usages!: TeamUsage[];
}
