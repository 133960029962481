import {
    ChangeDetectionStrategy,
    Component,
    input,
    output,
} from '@angular/core';
import {
    DxyDropdownButtonDirective,
    DxyIconButtonDirective,
} from '@datagalaxy/ui/buttons';
import { TooltipDirective } from '@datagalaxy/ui/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import {
    MenuComponent,
    MenuHeaderComponent,
    MenuItemDirective,
    MenuTriggerDirective,
} from '@datagalaxy/ui/menu';
import { AiGenerationStatus } from '../ai-generation-status';

@Component({
    selector: 'dxy-text-attribute-ai-generation-button',
    standalone: true,
    imports: [
        DxyIconButtonDirective,
        DxyDropdownButtonDirective,
        MenuComponent,
        MenuItemDirective,
        MenuTriggerDirective,
        TooltipDirective,
        TranslateModule,
        MenuHeaderComponent,
    ],
    templateUrl: './text-attribute-ai-generation-button.component.html',
    styleUrl: './text-attribute-ai-generation-button.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextAttributeAiGenerationButtonComponent {
    generateButtonTooltip = input<string>('');
    menuTitle = input<string>('');
    menuSubtitle = input<string>('');
    generationStatus = input<AiGenerationStatus>('default');
    readonly = input<boolean>(false);

    generate = output<void>();
    report = output<void>();

    emitGenerateOnClick(event: MouseEvent) {
        this.generate.emit();
        event.stopPropagation();
    }
}
