import { EntityItem } from '@datagalaxy/webclient/entity/domain';

export interface AttributeFieldState {
    entity: EntityItem | undefined;
    attributePath: string;
    touched: boolean;
    readonly: boolean;
}

export const attributeFieldStateDefaultValue: AttributeFieldState = {
    touched: false,
    attributePath: '',
    entity: undefined,
    readonly: true,
};
