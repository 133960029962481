import { InjectionToken, NgModule, Optional, SkipSelf } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { RestApiService } from './rest-api.service';
import { RestHttpClient } from './rest-http-client.service';
import { restHttpInterceptors } from './rest-http-interceptors';
import { BACKEND_API_CONFIG, IApiConfig } from '../types';
import { v3ApiUrl } from './v3-api-url';

export const V3_REST_API_SERVICE_TOKEN = new InjectionToken<RestApiService>(
    'Rest Api service for API v3 endpoints',
);
@NgModule({
    providers: [
        ...restHttpInterceptors,
        RestHttpClient,
        {
            provide: RestApiService,
            useFactory: (
                restHttpClient: RestHttpClient,
                config: IApiConfig,
            ) => {
                return new RestApiService(
                    restHttpClient,
                    config.baseUrl,
                    'api',
                );
            },
            deps: [RestHttpClient, BACKEND_API_CONFIG],
        },
        {
            provide: V3_REST_API_SERVICE_TOKEN,
            useFactory: (restHttpClient: RestHttpClient) => {
                return new RestApiService(restHttpClient, v3ApiUrl, 'v3');
            },
            deps: [RestHttpClient],
        },
    ],
    imports: [HttpClientModule],
})
export class RestApiModule {
    constructor(@Optional() @SkipSelf() parentModule?: RestApiModule) {
        if (parentModule) {
            throw new Error(
                'RestApiModule is already loaded. Import it in the AppModule only',
            );
        }
    }
}
