<!-- icon buttons vertical bar -->
<div class="entity-panel-body-buttons vcontainerDiv">
    <div class="pane-button-container expand-container">
        <button
            *ngIf="showToggleButton"
            dxyIconButton
            variant="secondary"
            (click)="onTogglePaneClick()"
        >
            <i class="glyph-arrow-drop-right" [class.collapsed]="collapsed"></i>
        </button>
    </div>
    <div
        *ngFor="let pane of panes"
        class="pane-button-container"
        [attr.data-dtname]="pane.dtName"
        [dxyLogFunctional]="pane.funcLogCode"
    >
        <button
            dxyIconButton
            variant="tertiary"
            size="medium"
            class="pane-button"
            (click)="onPaneIconClick(pane)"
            [class.active]="pane.isActive"
            [matTooltip]="pane.tooltipTranslateKey | translate"
            matTooltipPosition="left"
            [attr.data-dtname]="pane.dtName"
        >
            <i [ngClass]="pane.glyphClass"></i>
        </button>
        <div *ngIf="pane.hasPill || pane.isBeta" class="addon-container">
            <div
                *ngIf="pane.hasPill"
                class="addon notif-badge"
                [attr.data-dtname]="pane.dtName"
            >
                {{ pane.pillText }}
            </div>
            <div
                *ngIf="pane.isBeta"
                class="addon beta-notif"
                [attr.data-dtname]="pane.dtName"
            >
                Beta
            </div>
        </div>
    </div>
</div>
<!-- (one active tool displayed at a time) -->
<div *ngIf="!collapsed" class="entity-panel-body-content vcontainerDiv">
    <div *ngIf="showEmptyData" class="dg_messageNoSelectedEntity">
        <div><span class="glyph-info"></span></div>
        <div translate>UI.EntityDockingPane.NoSelectedEntity</div>
    </div>
    <div *ngIf="hasEntityData" class="panel-content-main">
        <div *ngIf="loading | async" class="panel-content-loading">
            <div class="panel-content-tool">
                <div class="panel-content-toolname" translate>
                    {{ headerName }}
                </div>
            </div>
            <dxy-spinner class="xl"></dxy-spinner>
        </div>
        <div *ngIf="isActiveTool(Tool.Details)" class="panel-content-main-tool">
            <dxy-entity-details
                [spaceId]="spaceId"
                [entityData]="entityData"
                [entityAttributes]="attributesMetaData"
                [reloadOnSourceChange]="true"
                [isEntityOwner]="true"
                [showHeader]="true"
                [readOnly]="isReadOnlyTool(Tool.Details)"
                [noNavLink]="isNoNavLinkTool(Tool.Details)"
                logId="entity-panel-body"
            ></dxy-entity-details>
        </div>
        <div
            *ngIf="isActiveTool(Tool.Insights)"
            class="panel-content-main-tool"
        >
            <dxy-insight-data
                [currentEntity]="entityData"
                [showHeader]="true"
            ></dxy-insight-data>
        </div>
        <div
            *ngIf="useDataQuality && isActiveTool(Tool.DataQuality)"
            class="panel-content-main-tool"
        >
            <app-data-quality-container
                [entityData]="entityData"
            ></app-data-quality-container>
        </div>
        <div
            *ngIf="isActiveTool(Tool.EntityTree)"
            class="panel-content-main-tool"
        >
            <dxy-entity-tree
                *ngIf="!useTableColumns"
                [entityData]="entityData"
                [showHeader]="true"
                [readOnly]="isReadOnlyTool(Tool.EntityTree)"
                [dragDrop]="dragDropConfig"
            ></dxy-entity-tree>
            <app-entity-table-columns
                *ngIf="useTableColumns"
                [entityData]="entityData"
                [showHeader]="true"
                [readOnly]="isReadOnlyTool(Tool.EntityTree)"
            ></app-entity-table-columns>
        </div>
        <div
            *ngIf="usePrimaryKey && isActiveTool(Tool.PrimaryKey)"
            class="panel-content-main-tool"
        >
            <app-primary-key-preview-pane
                [entityData]="entityData"
                [showHeader]="true"
                [readOnly]="isReadOnlyTool(Tool.PrimaryKey)"
            ></app-primary-key-preview-pane>
        </div>
        <div
            *ngIf="isActiveTool(Tool.LinkedObject)"
            class="panel-content-main-tool"
        >
            <app-entity-linked-objects
                [entityData]="entityData"
                [showHeader]="true"
                [readOnly]="isReadOnlyTool(Tool.LinkedObject)"
                [dragDrop]="dragDropConfig"
            ></app-entity-linked-objects>
        </div>
        <div
            *ngIf="isActiveTool(Tool.Diagrams)"
            class="panel-content-main-tool"
        >
            <app-diagrams-list
                [excludedDefaultColumns]="diagramsExcludedDefaultColumns"
                [entityData]="entityData"
                [showHeader]="true"
            ></app-diagrams-list>
        </div>
        <div *ngIf="isActiveTool(Tool.Assets)" class="panel-content-main-tool">
            <app-entity-assets
                [entityData]="entityData"
                [noNavLink]="isNoNavLinkTool(Tool.Assets)"
                [showHeader]="true"
            ></app-entity-assets>
        </div>
        <div *ngIf="isActiveTool(Tool.Tasks)" class="panel-content-main-tool">
            <dxy-task-container
                [entityData]="entityData"
                [showHeader]="true"
                [readOnly]="isReadOnlyTool(Tool.Tasks)"
            ></dxy-task-container>
        </div>
        <div
            *ngIf="isActiveTool(Tool.Commentaries)"
            class="panel-content-main-tool"
        >
            <dxy-commentary-container
                [entityData]="entityData"
                [showHeader]="true"
                [readOnly]="isReadOnlyTool(Tool.Commentaries)"
            ></dxy-commentary-container>
        </div>
        <div
            *ngIf="isActiveTool(Tool.Suggestion)"
            class="panel-content-main-tool"
        >
            <app-suggestion-container
                [serverType]="serverType"
                [showHeader]="true"
                [readOnly]="isReadOnlyTool(Tool.Suggestion)"
            ></app-suggestion-container>
        </div>
        <div
            *ngIf="isActiveTool(Tool.VersionComparison)"
            class="panel-content-main-tool"
        >
            <dxy-versioning-comparator-single-object
                class="dg_component-content flex-grow"
                [currentEntity]="entityData"
                [toolParam]="versioningComparatorToolParam"
                [showHeader]="true"
                [readOnly]="isReadOnlyTool(Tool.VersionComparison)"
            ></dxy-versioning-comparator-single-object>
        </div>
        <div
            *ngIf="areCampaignsEnabled && isActiveTool(Tool.Campaign)"
            class="panel-content-main-tool"
        >
            <app-campaign-container
                [entityData]="entityData"
            ></app-campaign-container>
        </div>
        <div
            *ngIf="isActiveTool(Tool.ActivityLog)"
            class="panel-content-main-tool"
        >
            <dxy-activity-log-container
                [activityLogDataOptions]="activityLogDataOptions"
                [showHeader]="true"
                [disableNavigation]="noNavLink"
            ></dxy-activity-log-container>
        </div>
    </div>
</div>
