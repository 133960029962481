import {
    deserialize,
    deserializeAs,
    inheritSerialization,
    SerializableEnumeration,
    serialize,
    serializeAs,
} from 'cerialize';
import {
    BaseServiceParameter,
    BaseServiceResult,
} from '@datagalaxy/data-access';
import { Model, ModelerData } from './model';
import { IIdr, ServerType } from '@datagalaxy/dg-object-model';
import { MasterDataSerializer } from '../master-data/master-data-serializer';
import { BaseMasterData } from '../master-data';
import { emptyRef } from '../ref';

@inheritSerialization(BaseServiceParameter)
export class ModelerApiParameter extends BaseServiceParameter {
    @serialize public ModelId: string;
    @serialize public TableIds?: string[];
    @serialize public ForeignKeyIds?: string[];

    constructor(
        modelId: string,
        tableIds?: string[],
        foreignKeyIds?: string[],
    ) {
        super();
        this.ModelId = modelId;
        this.TableIds = tableIds;
        this.ForeignKeyIds = foreignKeyIds;
    }
}

@inheritSerialization(BaseServiceResult)
export class ModelerApiResult extends BaseServiceResult {
    @deserializeAs(ModelerData) public ModelData = new ModelerData();
}

export class LoadDataParamItem {
    static readonly HighestLoadDataMaxDepth = 2147483647;

    @serialize public DataReferenceId = emptyRef;
    @serialize public DataTypeName!: string;
    @serialize public ExcludedFields: string[] | null = null;
    @serialize public MaxDepth!: number;

    static forModelWithoutTables(modelId: string) {
        const ldi = new LoadDataParamItem();
        ldi.DataReferenceId = modelId;
        ldi.DataTypeName = ServerType[ServerType.Model];
        ldi.ExcludedFields = ['Tables'];
        ldi.MaxDepth = LoadDataParamItem.HighestLoadDataMaxDepth;
        return ldi;
    }
}

@inheritSerialization(BaseServiceParameter)
export class LoadDataParameter extends BaseServiceParameter {
    @serializeAs(LoadDataParamItem) public Items!: LoadDataParamItem[];

    static forModelWithoutTables(modelId: string) {
        const ldp = new LoadDataParameter();
        const ldi = LoadDataParamItem.forModelWithoutTables(modelId);
        ldp.Items = [ldi];
        return ldp;
    }
}

export class LoadDataResultItem {
    @deserializeAs(MasterDataSerializer.forLoadDataResultItemAsModel())
    public DataContent!: Model;
}

@inheritSerialization(BaseServiceResult)
export class LoadDataResult extends BaseServiceResult {
    @deserializeAs(LoadDataResultItem) public Items: LoadDataResultItem[] = [];
}

export enum SaveDataOperation {
    None,
    AddNewData,
    AddDataReference,
    UpdateData,
    DeleteData,
    UpdateProperty,
    DeleteDataReference,
    MoveDataReference,
}
SerializableEnumeration(SaveDataOperation);

/** Item sent in a SaveDataParameter */
export class SaveDataParamItem {
    @serialize public DataReferenceId = '';
    @serialize public ParentReferenceId = emptyRef;
    @serialize public PropertyName = '';
    @serialize public ParentPropertyName = '';
    @serialize public ParentDataTypeName = '';
    @serialize public DataOperation: SaveDataOperation;
    @serialize public DataTypeName = '';
    @serializeAs(BaseMasterData) public DataContent!: BaseMasterData;
    @serialize public PropertyValue: any = null;

    public static forMoveDataReference(
        item: IIdr,
        parent: IIdr,
        parentPropertyName: string,
        moveValue: number,
    ) {
        const sdi = new SaveDataParamItem(
            SaveDataOperation.MoveDataReference,
            item,
            parent,
        );
        sdi.ParentPropertyName = parentPropertyName;
        sdi.PropertyValue = moveValue.toString();
        return sdi;
    }

    public static forAddDataReference(
        item: IIdr,
        parent: IIdr,
        parentPropertyName: string,
    ) {
        const sdi = new SaveDataParamItem(
            SaveDataOperation.AddDataReference,
            item,
            parent,
        );
        sdi.ParentPropertyName = parentPropertyName;
        return sdi;
    }

    public static forAddNewData(
        parent: IIdr,
        newData: BaseMasterData,
        parentPropertyName: string,
        dataContent$type: string,
    ) {
        const sdi = new SaveDataParamItem(
            SaveDataOperation.AddNewData,
            newData,
            parent,
        );
        sdi.ParentPropertyName = parentPropertyName;
        sdi.DataContent = newData;
        sdi.DataContent.$type = dataContent$type;
        console.log(sdi);
        return sdi;
    }

    public static forUpdateProperty(
        data: IIdr,
        propertyName: string,
        propertyValue: any,
    ) {
        const sdi = new SaveDataParamItem(
            SaveDataOperation.UpdateProperty,
            data,
        );
        sdi.PropertyName = propertyName;
        sdi.PropertyValue = Array.isArray(propertyValue)
            ? propertyValue.join(',')
            : propertyValue;
        return sdi;
    }

    public static forDeleteData(
        item: IIdr,
        parent: IIdr,
        parentPropertyName: string,
        isReferenceOnly: boolean,
    ) {
        const sdi = new SaveDataParamItem(
            isReferenceOnly
                ? SaveDataOperation.DeleteDataReference
                : SaveDataOperation.DeleteData,
            item,
            parent,
        );
        sdi.ParentPropertyName = parentPropertyName;
        return sdi;
    }

    public static forDeleteDataReference(
        item: IIdr,
        parent: IIdr,
        parentPropertyName: string,
    ) {
        const sdi = new SaveDataParamItem(
            SaveDataOperation.DeleteDataReference,
            item,
            parent,
        );
        sdi.ParentPropertyName = parentPropertyName;
        return sdi;
    }

    private static isDeleteOrAdd(sdpi: SaveDataParamItem) {
        switch (sdpi?.DataOperation) {
            case SaveDataOperation.DeleteData:
            case SaveDataOperation.AddNewData:
            case SaveDataOperation.DeleteDataReference:
            case SaveDataOperation.AddDataReference:
                return true;
            default:
                return false;
        }
    }

    private static dataAsIdr(sdi: SaveDataParamItem) {
        return (
            sdi &&
            ({
                ReferenceId: sdi.DataReferenceId,
                ServerType:
                    ServerType[sdi.DataTypeName as keyof typeof ServerType],
            } as IIdr)
        );
    }

    private static parentAsIdr(sdi: SaveDataParamItem) {
        return (
            sdi &&
            ({
                ReferenceId: sdi.ParentReferenceId,
                ServerType:
                    ServerType[
                        sdi.ParentDataTypeName as keyof typeof ServerType
                    ],
            } as IIdr)
        );
    }

    public get isDeleteOrAdd() {
        return SaveDataParamItem.isDeleteOrAdd(this);
    }

    constructor(op: SaveDataOperation, data: IIdr, parent?: IIdr) {
        this.DataOperation = op;

        this.DataReferenceId = data.ReferenceId;
        this.DataTypeName = ServerType[data.ServerType];

        if (parent) {
            this.ParentReferenceId = parent.ReferenceId;
            this.ParentDataTypeName = ServerType[parent.ServerType];
        }
    }

    public dataAsIdr() {
        return SaveDataParamItem.dataAsIdr(this);
    }

    public parentAsIdr() {
        return SaveDataParamItem.parentAsIdr(this);
    }
}

@inheritSerialization(BaseServiceParameter)
export class SaveDataParameter extends BaseServiceParameter {
    @serializeAs(SaveDataParamItem) public Items: SaveDataParamItem[] = [];

    constructor(items?: SaveDataParamItem[]) {
        super();
        if (items) {
            this.Items.push(...items.filter((o) => o));
        }
    }
}

export class SaveDataResultDeletedReferenceItem {
    @deserialize public IsObjectDeleted!: boolean;
    @deserialize public DataReferenceId!: string;
    @deserialize public DataTypeName!: string;
    @deserialize public ParentReferenceId!: string;
    @deserialize public ParentTypeName!: string;
    @deserialize public ParentPropertyName!: string;

    public get DataServerType() {
        return ServerType[this.DataTypeName as keyof typeof ServerType];
    }

    public get ParentServerType() {
        return ServerType[this.ParentPropertyName as keyof typeof ServerType];
    }

    constructor() {}

    public dataAsIdr() {
        return {
            ReferenceId: this.DataReferenceId,
            ServerType: this.DataServerType,
        };
    }

    public parentAsIdr() {
        return {
            ReferenceId: this.ParentReferenceId,
            ServerType: this.ParentServerType,
        };
    }
}

export class SaveDataResultUpdatedPropertyItem {
    @deserialize public PropertyName!: string;
    @deserialize public DataTypeName!: string;
    @deserialize public DataReferenceId!: string;
    @deserialize public PropertyValue!: string;

    public get DataServerType() {
        return ServerType[this.DataTypeName as keyof typeof ServerType];
    }

    constructor() {}

    public asIdr() {
        return {
            ReferenceId: this.DataReferenceId,
            ServerType: this.DataServerType,
        };
    }
}

export class SaveDataResultItem {
    @deserialize public IsSuccess!: boolean;
    @deserialize public DataReferenceId!: string;
    @deserialize public ParentDataReferenceId!: string;
    @deserializeAs(SaveDataResultDeletedReferenceItem)
    public DeletedReferences: SaveDataResultDeletedReferenceItem[] = [];
    @deserializeAs(SaveDataResultUpdatedPropertyItem)
    public UpdatedProperties: SaveDataResultUpdatedPropertyItem[] = [];
}

@inheritSerialization(BaseServiceResult)
export class SaveDataResult extends BaseServiceResult {
    @deserializeAs(SaveDataResultItem) public Items: SaveDataResultItem[] = [];
}

/** Contains the SaveDataParameter and SaveDataResult used on a Data/SaveData call.
 * Provides helpers getters & methods. */
export class SavingPair {
    public get IsSuccess() {
        return this.sdr.IsSuccess;
    }

    constructor(
        public sdp: SaveDataParameter,
        public sdr: SaveDataResult,
    ) {}

    public getPairItems(): SavingPairItem[] {
        return this.sdp.Items.map(
            (sdi, i) => new SavingPairItem(sdi, this.sdr.Items[i]),
        );
    }
}

export class SavingPairItem {
    /** item requested operation */
    public get dataOperation() {
        return this.sdpi.DataOperation;
    }

    public get isSuccess() {
        return this.sdri.IsSuccess;
    }

    constructor(
        /** api parameter item */
        public sdpi: SaveDataParamItem,
        /** api result item */
        public sdri: SaveDataResultItem,
    ) {}
}
