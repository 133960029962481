import { Directive, ElementRef, HostBinding } from '@angular/core';

/**
 * ## Role
 * Add DataGalaxy CSS class to a div containing buttons to group them
 */
@Directive({
    selector: '[dxyButtonGroup]',
    standalone: true,
})
export class DxyButtonGroupDirective {
    @HostBinding('class') get classes() {
        return `dxy-button-group`;
    }

    @HostBinding('class.has-primary-buttons') get hasPrimaryButtons() {
        const children = this.getChildren();
        return (
            !children?.length ||
            children.some((button) =>
                button.classList.contains('dxy-button--primary'),
            )
        );
    }

    private getChildren(): HTMLElement[] {
        const children = this.el.nativeElement.querySelectorAll('.dxy-button');
        return Array.from(children) as HTMLElement[];
    }

    constructor(private el: ElementRef) {}
}
