import { Injectable } from '@angular/core';
import { BaseService } from '@datagalaxy/core-ui';
import { Subject } from 'rxjs';
import { ApiServiceError, ProblemDetails } from '@datagalaxy/data-access';

@Injectable({ providedIn: 'root' })
export class LoggingHttpService extends BaseService {
    public get sessionRecovering$() {
        return this._sessionRecovering.asObservable();
    }
    public notifySessionRecovering() {
        this._sessionRecovering.next();
    }
    private readonly _sessionRecovering = new Subject<void>();

    public get sessionRecovered$() {
        return this._sessionRecovered.asObservable();
    }
    public notifySessionRecovered(recovered: boolean) {
        this._sessionRecovered.next(recovered);
    }
    private readonly _sessionRecovered = new Subject<boolean>();

    public get genericErrorForToaster$() {
        return this._genericErrorForToaster.asObservable();
    }
    public notifyGenericError(error: ApiServiceError) {
        this._genericErrorForToaster.next(error);
    }
    private readonly _genericErrorForToaster = new Subject<ApiServiceError>();

    public get problemDetailsForToaster$() {
        return this._problemDetailsForToaster.asObservable();
    }
    public notifyProblemDetails(problemDetails: ProblemDetails) {
        this._problemDetailsForToaster.next(problemDetails);
    }
    private readonly _problemDetailsForToaster = new Subject<ProblemDetails>();
}
