import {
    deserialize,
    deserializeAs,
    inheritSerialization,
    serialize,
} from 'cerialize';
import {
    BaseServiceParameter,
    BaseServiceResult,
} from '@datagalaxy/data-access';
import { HierarchyDataDescriptor } from '@datagalaxy/dg-object-model';
import { TechnologyDto } from '@datagalaxy/webclient/technology/domain';
import { DgModule } from '@datagalaxy/shared/dg-module/domain';

@inheritSerialization(BaseServiceResult)
export class GetTechnologiesResult extends BaseServiceResult {
    @deserializeAs(TechnologyDto) public Technologies!: TechnologyDto[];

    constructor() {
        super();
    }
}

@inheritSerialization(BaseServiceParameter)
export class CreateTechnologyParameter extends BaseServiceParameter {
    @serialize public DisplayName: string;
    @serialize public Modules: string[];
    @serialize public TechnologyCode: string;
    @serialize public Description?: string;

    constructor(
        displayName: string,
        modules: string[],
        technologyCode?: string,
        description?: string,
    ) {
        super();
        this.DisplayName = displayName;
        this.Modules = modules;
        this.TechnologyCode = technologyCode || '';
        this.Description = description;
    }
}

@inheritSerialization(BaseServiceResult)
export class PreCreateTechnologyResult extends BaseServiceResult {
    @deserialize public UpdatedTechnologyCode!: string;
    @deserialize public IsMissingModule!: boolean;
    @deserialize public IsMissingDisplayName!: boolean;
    @deserializeAs(TechnologyDto)
    public ExistingTechnologyWithDisplayName!: TechnologyDto;
    @deserializeAs(TechnologyDto)
    public ExistingTechnologyWithCode!: TechnologyDto;

    constructor() {
        super();
    }
}

@inheritSerialization(BaseServiceResult)
export class CreateTechnologyResult extends BaseServiceResult {
    @deserializeAs(TechnologyDto) public CreatedTechnology!: TechnologyDto;

    constructor() {
        super();
    }
}

@inheritSerialization(BaseServiceResult)
export class PreUpdateTechnologyResult extends BaseServiceResult {
    @deserializeAs(DgModule) public UsedModules!: DgModule;

    constructor() {
        super();
    }
}

@inheritSerialization(BaseServiceParameter)
export class DeleteTechnologyParameter extends BaseServiceParameter {
    @serialize public TechnologyCode: string;

    constructor(technologyCode: string) {
        super();
        this.TechnologyCode = technologyCode;
    }
}

@inheritSerialization(BaseServiceResult)
export class TechnologySpaceUsage extends BaseServiceResult {
    @deserialize public SpaceHdd!: HierarchyDataDescriptor;
    @deserialize public UsageCount!: number;

    constructor() {
        super();
    }
}

@inheritSerialization(BaseServiceResult)
export class DeleteTechnologyResult extends BaseServiceResult {
    @deserialize public SpaceUsages!: TechnologySpaceUsage[];

    constructor() {
        super();
    }
}
