import { DomUtil } from '@datagalaxy/core-util';
import { select } from 'd3-selection';
import { Point, Vect2 } from '@datagalaxy/core-2d-util';
import { LineStyle, PathComponentProps } from './path.types';
import { IPathBuilder } from './path-builder';
import { getPathBuilder } from './path-builder/path-builder.strategy';
import { ArrowComponentProps, ArrowSpecs, ConnectorArrow } from '../arrow';
import { GraphicalColor } from '@datagalaxy/shared/graphical/domain';

export class ConnectorPath {
    public static readonly class = 'gs-connector-path';

    protected static readonly _v = new Vect2();

    public g?: SVGGElement;
    private stroke?: SVGPathElement;
    private outline?: SVGPathElement;
    private pathBuilder: IPathBuilder;
    private arrow?: ConnectorArrow;
    private arrowPosition?: Point;

    constructor(private props: PathComponentProps) {
        const g = (this.g = DomUtil.createSvgElement('g', ConnectorPath.class));
        const stroke = (this.stroke = DomUtil.createSvgElement('path'));
        const outline = (this.outline = DomUtil.createSvgElement('path', [
            ConnectorPath.class,
            'outline',
        ]));

        g.append(stroke);
        g.append(outline);

        this.pathBuilder = getPathBuilder(props.pathBuilderOptions?.routing);

        this.props.color ??= GraphicalColor.Black;
        this.props.thickness ??= 1;

        this.updateProps(this.props);
    }

    public updateProps(newProps: Partial<PathComponentProps>) {
        const spec = Object.assign(this.props, newProps);

        const arrowInfos = this.getArrowPositionAndAngle(spec);
        const arrowSpec = {
            ...arrowInfos,
            color: spec.color,
            shapeId: spec.shapeId,
            thickness: spec.thickness,
        };

        this.updateArrow(arrowSpec);
    }

    private getArrowPositionAndAngle(props: PathComponentProps) {
        const { points, shapeId, thickness, inverted } = props;
        if (!points?.length) {
            return { position: null, angle: null };
        }

        const angle = this.calculateArrowAngle(points);
        const arrowHeadPoint = inverted ? points[0] : points[points.length - 1];
        const recess = ArrowSpecs.getInstance().getSpecRecess(
            shapeId,
            thickness,
        );
        const position = (this.arrowPosition = this.translatePoint(
            angle,
            arrowHeadPoint,
            -recess,
        ));

        return { position, angle };
    }

    public dispose() {
        this.stroke?.remove();
        this.outline?.remove();
        this.arrow?.dispose();
        this.g?.remove();
        this.g = this.outline = this.stroke = this.arrow = null;
    }

    public draw() {
        const { points, debug, inverted, pathBuilderOptions } = this.props;
        const pathD = this.pathBuilder.computePathD(points, {
            ...pathBuilderOptions,
            inverted,
            arrowPosition: this.arrowPosition,
        });
        const outlinePathD = this.pathBuilder.computePathD(
            points,
            pathBuilderOptions,
        );
        this.stroke.setAttribute('d', pathD);
        this.outline.setAttribute('d', outlinePathD);

        const classes = this.getStrokeClasses();
        DomUtil.replaceAllClasses(this.stroke, classes);

        this.arrow?.draw();

        if (debug) {
            this.drawDebugPoints(this.props.points);
        }
    }

    private getStrokeClasses() {
        const { color, thickness, lineStyle } = this.props;
        const colorClass = color ? `color-${GraphicalColor[color]}` : '';
        const thicknessClass = thickness ? `thick-${thickness}` : '';
        const lineClass = lineStyle ? `line-style-${LineStyle[lineStyle]}` : '';

        return ['stroke', colorClass, thicknessClass, lineClass];
    }

    private updateArrow(spec: Partial<ArrowComponentProps>) {
        const { shapeId, thickness } = spec;
        const hasArrow = shapeId && thickness;

        if (!hasArrow) {
            this.arrow?.dispose();
            this.arrow = null;
            return;
        }

        if (!this.arrow) {
            this.arrow = new ConnectorArrow(spec, ArrowSpecs.getInstance());
            this.g.append(this.arrow.path);
        }

        this.arrow.updateProps(spec);
    }

    private calculateArrowAngle(points: Point[]) {
        const inverted = this.props.inverted;
        const length = points?.length;
        if (length < 2) {
            return 0;
        }

        const startIdx = inverted ? 1 : length - 2;
        const endIdx = inverted ? 0 : length - 1;

        return Vect2.segmentAngle(points[startIdx], points[endIdx]);
    }

    private translatePoint(angle: number, point: Point, distance = 0): Point {
        const angleInRadians = (Math.PI / 180) * angle;
        const x = point.x + distance * Math.cos(angleInRadians);
        const y = point.y + distance * Math.sin(angleInRadians);
        return { x: Math.round(x), y: Math.round(y) };
    }

    public drawDebugPoints(points: Point[]) {
        const dbg = select(this.g)
            .selectAll('g.dbg')
            .data([0])
            .join('g')
            .attr('class', 'dbg')
            .style('pointer-events', 'none');

        if (!points?.length) {
            dbg.selectAll('rect.s,circle.p,text.pid,text.pxy').remove();
        }

        // circle
        dbg.selectAll('circle.p')
            .data(points)
            .join('circle')
            .attr('class', 'p')
            .attr('cx', (d) => d.x)
            .attr('cy', (d) => d.y)
            .attr('r', 5)
            .style('fill', 'none')
            .style('stroke', 'green');
        // id text
        dbg.selectAll('text.pid')
            .data(points)
            .join('text')
            .attr('class', 'pid')
            .attr('x', (d) => d.x + 5)
            .attr('y', (d) => d.y + 10)
            .text((_, i) => i)
            .style('fill', 'green');
        // coordinates text
        dbg.selectAll('text.pxy')
            .data(points)
            .join('text')
            .attr('class', 'pxy')
            .attr('x', (d) => d.x + 5)
            .attr('y', (d) => d.y - 5)
            .text((d) => `${d.x},${d.y}`);
    }
}
