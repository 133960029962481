import { deserializeAs, inheritSerialization, serialize } from 'cerialize';
import {
    BaseServiceParameter,
    BaseServiceResult,
} from '@datagalaxy/data-access';
import { PreCreateEntityStatus } from '../attribute';
import { AttributeTagDTO } from '@datagalaxy/webclient/attribute/domain';

@inheritSerialization(BaseServiceParameter)
export class CreateAttributeTagParameter extends BaseServiceParameter {
    @serialize public ModuleName: string;
    @serialize public AttributeKey: string;
    @serialize public DisplayName: string;
    @serialize public Description: string;
    @serialize public IsActive: boolean;
    @serialize public Color: string;

    constructor(
        moduleName: string,
        attributeKey: string,
        displayName: string,
        description: string,
        isActive: boolean,
        color: string,
    ) {
        super();
        this.ModuleName = moduleName;
        this.AttributeKey = attributeKey;
        this.DisplayName = displayName;
        this.Description = description;
        this.IsActive = isActive;
        this.Color = color;
    }
}

@inheritSerialization(BaseServiceResult)
export class GenericAttributeTagResult extends BaseServiceResult {
    @deserializeAs(AttributeTagDTO) public AttributeTag!: AttributeTagDTO;
}

@inheritSerialization(BaseServiceParameter)
export class PreCreateAttributeTagParameter extends BaseServiceParameter {
    @serialize public ModuleName: string;
    @serialize public AttributeKey: string;
    @serialize public DisplayName: string;

    constructor(moduleName: string, attributeKey: string, displayName: string) {
        super();
        this.ModuleName = moduleName;
        this.AttributeKey = attributeKey;
        this.DisplayName = displayName;
    }
}

@inheritSerialization(BaseServiceResult)
export class PreCreateAttributeTagResult extends BaseServiceResult {
    @deserializeAs(PreCreateEntityStatus) public Status!: PreCreateEntityStatus;
    @deserializeAs(AttributeTagDTO) public ExistingItem!: AttributeTagDTO;
}

@inheritSerialization(BaseServiceParameter)
export class GetAttributeTagsParameter extends BaseServiceParameter {
    @serialize public ModuleName: string;
    @serialize public AttributeKey: string;
    @serialize public SearchKey: string;
    @serialize public IncludeActiveOnly: boolean;

    constructor(
        moduleName: string,
        attributeKey: string,
        includeActiveOnly: boolean,
        searchKey: string,
    ) {
        super();
        this.ModuleName = moduleName;
        this.AttributeKey = attributeKey;
        this.SearchKey = searchKey;
        this.IncludeActiveOnly = includeActiveOnly;
    }
}

@inheritSerialization(BaseServiceResult)
export class GetAttributeTagsResult extends BaseServiceResult {
    @deserializeAs(AttributeTagDTO)
    public AttributeTags!: Array<AttributeTagDTO>;
}

@inheritSerialization(BaseServiceParameter)
export class UpdateAttributeTagParameter extends BaseServiceParameter {
    @serialize public AttributeId: string;
    @serialize public AttributeTagId: string;
    @serialize public DisplayName: string;
    @serialize public Description: string;
    @serialize public IsActive: boolean;
    @serialize public Color: string;
    @serialize public UserKeywordsInput: string;
    @serialize public IsUserSuggestionEnabled: boolean;
    @serialize public ParentTagId: string;
    @serialize public ActivateChildTags: boolean;

    constructor(
        attributeId: string,
        tagId: string,
        displayName: string,
        description: string,
        isActive: boolean,
        color: string,
        userKeywordsInput: string,
        isUserSuggestionEnabled: boolean,
        parentTagId: string,
        activateChildTags: boolean,
    ) {
        super();
        this.AttributeId = attributeId;
        this.AttributeTagId = tagId;
        this.DisplayName = displayName;
        this.Description = description;
        this.IsActive = isActive;
        this.Color = color;
        this.UserKeywordsInput = userKeywordsInput;
        this.IsUserSuggestionEnabled = isUserSuggestionEnabled;
        this.ParentTagId = parentTagId;
        this.ActivateChildTags = activateChildTags;
    }
}
