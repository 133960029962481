import { DataSource } from '@angular/cdk/table';
import { Injectable } from '@angular/core';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import {
    BaseUiService,
    EditCellType,
    IEditGridData,
    ISelectorOption,
    IValueChange,
} from '@datagalaxy/core-ui';
import {
    IGetColumnSpecOptions,
    ITableColumnsEditGridBridge,
    ITableColumnsGridBridgeOptions,
    ITableColumnsGridColumSpec,
} from '../table-columns-ui.types';
import { ModelerService } from './modeler.service';
import { ViewTypeService } from '../../services/viewType.service';
import { AppEventsService } from '../../services/AppEvents.service';
import { ITableColumnsEvent } from '../modeler.types';
import { IEntityIdentifier, IHasHddData } from '@datagalaxy/dg-object-model';
import {
    Column,
    DataType,
    Model,
} from '@datagalaxy/webclient/modeler/data-access';

@Injectable({ providedIn: 'root' })
export class TableColumnsUIService extends BaseUiService {
    constructor(
        private modelerService: ModelerService,
        private viewTypeService: ViewTypeService,
        private appEventsService: AppEventsService,
    ) {
        super();
    }

    //#region for entity-table-columns
    public subscribeTableColumnsChange(
        tableId: string,
        action: (event: ITableColumnsEvent) => void,
    ) {
        return this.modelerService.subscribeTableColumnEvents((e) => {
            if (e.table.ReferenceId == tableId) {
                action(e);
            }
        });
    }

    public async loadModel(tableEntity: IEntityIdentifier & IHasHddData) {
        return this.modelerService.loadModelForTableEntity(tableEntity);
    }

    public getTable(model: Model, tableId: string) {
        return this.modelerService.getTable(model, tableId);
    }
    //#endregion

    /** returns an object that contains the EditGrid's data and methods to update it */
    public getEditGridBridge(
        tableId: string,
        model: Model,
        opt: ITableColumnsGridBridgeOptions = {},
    ) {
        this.log('getEditGridBridge', tableId, model, opt);
        if (!tableId) {
            this.warn('no tableId');
            return;
        }
        if (!model) {
            this.warn('no model');
            return;
        }

        const columnSpecs =
            opt.columnSpecs ?? this.getEditGridColumnSpecs(model, opt);
        const dataSource = new MatTableDataSource<Column>();
        const subscription = this.subscribeUI(
            this.appEventsService.viewTypeChange$,
            () =>
                opt.updateGridColDefs?.(
                    this.getGridColDefs(columnSpecs, model),
                ),
        );
        const updateDataSource = () => {
            dataSource.data = this.getTableColumns(tableId);
        };
        const bridge: ITableColumnsEditGridBridge = {
            gridData: this.getEditGridData(model, columnSpecs, dataSource, opt),
            onGridValueChanged: (event) =>
                this.onGridValueChanged(event, opt.onChangeInModel),
            onDestroy: () => subscription.unsubscribe(),
            updateDataSource,
        };
        updateDataSource();
        return bridge;
    }

    private getEditGridColumnSpecs(model: Model, opt?: IGetColumnSpecOptions) {
        const dataTypeOptions = this.getDataTypeOptions(model);
        return this.getColumnSpecs(dataTypeOptions, opt);
    }

    public async addColumn(model: Model, tableId: string) {
        const table = this.getTable(model, tableId);
        if (!table) {
            this.warn('no table', tableId);
            return;
        }
        const newCol = this.modelerService.newColumn(table);
        await this.modelerService.createColumnNoReload(newCol);
    }

    public getTableColumns(tableId: string): Column[] {
        return this.modelerService.getTableColumnsFromTableId(tableId);
    }

    private getEditGridData(
        model: Model,
        columnSpecs: ITableColumnsGridColumSpec[],
        dataSource?: DataSource<Column>,
        opt: {
            isReadOnly?: () => boolean;
            gridModeCompact?: boolean;
        } = {},
    ): IEditGridData<Column> {
        return {
            dataSource,
            colDefs: this.getGridColDefs(columnSpecs, model),
            options: {
                readonly: opt.isReadOnly,
                compact: opt.gridModeCompact,
                orderByDrag: {
                    getValue: (it) => it.DisplayOrder,
                    onDrop: (event) =>
                        this.modelerService.changeColumnOrder(
                            event.item.data,
                            event.previousIndex,
                            event.currentIndex,
                        ),
                },
                orderByDragColumnWidth: 30,
            },
        };
    }

    private async onGridValueChanged(
        event: IValueChange<Column>,
        onChangeInModel?: () => void,
    ) {
        this.log('onGridValueChanged', event);
        const {
            item: column,
            colDef,
            currentValue: newValue,
            previousValue: oldValue,
        } = event;
        const fieldName = colDef.field;
        switch (colDef.id ?? fieldName) {
            case 'IsPrimaryKey': {
                const table = this.modelerService.getColumnTable(column);
                if (newValue) {
                    try {
                        if (
                            !(await this.modelerService.addColumnToPrimaryKey(
                                table,
                                column,
                            ))
                        ) {
                            column.IsPrimaryKey = oldValue;
                            break;
                        }
                    } catch {
                        column.IsPrimaryKey = oldValue;
                    }
                    onChangeInModel?.();
                } else {
                    let removed = false;
                    try {
                        removed =
                            await this.modelerService.removeColumnFromPrimaryKey(
                                table,
                                column,
                            );
                    } catch {
                        column.IsPrimaryKey = oldValue;
                    }
                    if (removed) {
                        onChangeInModel?.();
                    }
                }
                break;
            }

            case 'IsMandatory':
                await this.modelerService.updateMandatory(
                    column,
                    newValue,
                    oldValue,
                );
                onChangeInModel?.();
                break;

            case 'Type':
                await this.modelerService.updateColumnType(column);
                break;

            case 'DataSize':
                await this.modelerService.updateColumnDataSize(column);
                break;

            default:
                await this.modelerService.updateGenericEntityProperty(
                    column,
                    fieldName,
                    newValue,
                    oldValue,
                );
                break;
        }
    }

    private getDataTypeOptions(model: Model): ISelectorOption<DataType>[] {
        return this.modelerService.getDataTypes(model).map((dt) => ({
            value: dt,
            text: dt.DisplayName,
        }));
    }

    private getGridColDefs(
        columnSpecs: ITableColumnsGridColumSpec[],
        model: Model,
    ) {
        this.log('getGridColDefs');
        const technicalOnly = this.viewTypeService.isTechnicalView;
        const pkFkOnly = this.modelerService.isRelationalModel(model);
        return columnSpecs.filter(
            (cs) =>
                (cs.functionalOnly == undefined ||
                    cs.functionalOnly != technicalOnly) &&
                (cs.technicalOnly == undefined ||
                    cs.technicalOnly == technicalOnly) &&
                (cs.pkFkOnly == undefined || cs.pkFkOnly == pkFkOnly),
        );
    }

    private getColumnSpecs(
        dataTypeOptions: ISelectorOption<DataType>[],
        opt: IGetColumnSpecOptions = {},
    ) {
        const compact = opt.gridModeCompact;
        const columnSpecs: ITableColumnsGridColumSpec[] = [
            //#region functionalOnly
            {
                field: 'DisplayName',
                functionalOnly: true,
                type: EditCellType.text,
                debounceTimeMs: 444,
                width: '30%',
            },
            {
                field: 'Description',
                functionalOnly: true,
                type: EditCellType.text,
                debounceTimeMs: 444,
                width: '40%',
            },
            {
                field: 'IsTechnicalData',
                functionalOnly: true,
                type: EditCellType.booleanYesNo,
                width: compact ? 50 : '20%',
            },
            //#endregion - functionalOnly
            //#region technicalOnly
            {
                field: 'TechnicalName',
                technicalOnly: true,
                type: EditCellType.text,
                debounceTimeMs: 444,
                width: '30%',
            },
            {
                id: 'Type',
                getText: (it) =>
                    this.modelerService.getColumnDataTypeDisplayName(it),
                getValue: (it) => this.modelerService.getColumnDataType(it),
                onValueChange: (it, dataType) =>
                    this.modelerService.setColumnDataType(it, dataType),
                technicalOnly: true,
                type: EditCellType.selector,
                getOptions: () => dataTypeOptions,
                width: '20%',
                minWidth: 50,
            },
            {
                id: 'DataSize',
                field: 'SizeAndPrecision',
                getValue: (it) =>
                    this.modelerService.getColumnSizeAndPrecision(it),
                onValueChange: (it, value) =>
                    this.modelerService.setColumnSizeAndPrecision(it, value),
                technicalOnly: true,
                type: EditCellType.text,
                debounceTimeMs: 444,
                isReadOnly: (it) =>
                    !this.modelerService.isColumnSizeEditable(it),
                width: compact ? 50 : '10%',
            },
            {
                id: 'IsMandatory',
                field: 'IsMandatory',
                technicalOnly: true,
                type: EditCellType.booleanYesNo,
                width: compact ? 25 : '15%',
            },
            {
                field: 'IsPrimaryKey',
                technicalOnly: true,
                pkFkOnly: true,
                type: EditCellType.booleanYesNo,
                width: '10%',
            },
            {
                field: 'IsForeignKey',
                technicalOnly: true,
                pkFkOnly: true,
                type: EditCellType.booleanYesNo,
                readonly: true,
                width: compact ? 25 : 50,
            },
            //#endregion - technicalOnly
            {
                id: 'DeleteItem',
                type: EditCellType.action,
                actionCallback: async (column) =>
                    await this.modelerService.deleteColumn(column),
                actionGlyphClass: 'glyph-delete',
                actionDisabled: (it) =>
                    !this.modelerService.isColumnDeleteEnabled(it),
                isDisplayedOnHover: true,
                headerText: '',
                width: 45,
            },
        ];
        columnSpecs.forEach((cd) => {
            cd.headerKey = cd.headerText
                ? undefined
                : `UI.ColumnGridColumns.${cd.id ?? cd.field}.titleDiagram`;
            cd.noSort = true;
            if (compact) {
                cd.minWidth ??= 25;
            }
        });
        return columnSpecs;
    }
}
