import { Injectable } from '@angular/core';
import { BackendApiService, RestApiService } from '@datagalaxy/data-access';
import { IServerTimeData } from './server-time';
import { IServerHealthCheck } from './health-check';
import { ClientErrorParameter } from './log-error';
import { LogFunctionalActionParameter } from './log-functional';

@Injectable({ providedIn: 'root' })
export class MonitoringApiService {
    constructor(
        private backendApiService: BackendApiService,
        private restApiService: RestApiService,
    ) {}

    public async getServerTime(): Promise<IServerTimeData> {
        return await this.restApiService.postPromise<IServerTimeData>(
            'Monitoring/GetTime',
        );
    }

    public async healthCheck(): Promise<IServerHealthCheck> {
        return await this.restApiService.postPromise<IServerHealthCheck>(
            'Monitoring/HealthCheck',
        );
    }

    public async logFunctionalAction(param: LogFunctionalActionParameter) {
        return await this.backendApiService.postPromise(
            'Monitoring/LogFunctionalAction',
            param,
        );
    }

    public async logError(param: ClientErrorParameter) {
        return await this.backendApiService.postPromise(
            'Monitoring/LogError',
            param,
        );
    }
}
