import { Injectable } from '@angular/core';
import { DataIdentifier, ServerType } from '@datagalaxy/dg-object-model';
import { UpdateSpaceDto } from './workspace';
import { filter, map } from 'rxjs';
import { GenericDeserialize } from 'cerialize';
import {
    NotificationMessage,
    NotificationMessageCode,
} from '@datagalaxy/webclient/notification/data-access';
import {
    LegacyBackendSignalRService,
    SignalrUserEvent,
} from '@datagalaxy/data-access';
import { Workspace } from '@datagalaxy/webclient/workspace/domain';

@Injectable({ providedIn: 'root' })
export class WorkspaceSignalrService {
    public readonly projectCreated$ = this.signalR.fromUserEvent(
        'clientRtCreateProject',
        Workspace,
    );

    public readonly projectUpdated$ = this.signalR
        .fromUserEvent('clientRtUpdateSpace', UpdateSpaceDto)
        .pipe(
            filter(
                (event: SignalrUserEvent<UpdateSpaceDto>) =>
                    event.data.DataTypeName === ServerType[ServerType.Project],
            ),
            map((event: SignalrUserEvent<UpdateSpaceDto>) => {
                const navProject = new Workspace();
                const updatedSpace = event.data;
                navProject.ReferenceId = updatedSpace.SpaceId;
                navProject.DisplayName = updatedSpace.DisplayName;
                navProject.Description = updatedSpace.Description;
                return navProject;
            }),
        );

    public readonly deleted$ = this.signalR.fromUserEvent(
        'clientRtDeleteSpace',
        DataIdentifier,
    );

    public readonly securityUpdated$ = this.signalR
        .fromEvent('notifyMessage', (notification: NotificationMessage) =>
            GenericDeserialize(notification, NotificationMessage),
        )
        .pipe(
            filter(
                (notification: NotificationMessage) =>
                    notification.MessageCode ===
                    NotificationMessageCode[
                        NotificationMessageCode.SecurityRightsChange
                    ],
            ),
        );

    constructor(private signalR: LegacyBackendSignalRService) {}
}
