import { DxyBaseModalComponent } from '@datagalaxy/ui/dialog';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { MsTeamsIntegrationService } from '../ms-teams-integration.service';
import { withLoading } from '@datagalaxy/core-ui';
import { AsyncPipe } from '@angular/common';
import { DxyModalFooterComponent } from '../../../../shared/dialogs/dxy-modal-footer/dxy-modal-footer.component';
import { TranslateModule } from '@ngx-translate/core';
import { DxyIconButtonDirective } from '@datagalaxy/ui/buttons';

/**
 * ## Role
 * MsTeams Integration deactivation modal
 */
@Component({
    selector: 'app-ms-teams-integration-deactivation-modal',
    templateUrl: './ms-teams-integration-deactivation-modal.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        MatDialogModule,
        TranslateModule,
        DxyModalFooterComponent,
        AsyncPipe,
        DxyIconButtonDirective,
    ],
})
export class MsTeamsIntegrationDeactivationModalComponent extends DxyBaseModalComponent<
    void,
    boolean
> {
    constructor(
        dialogRef: MatDialogRef<
            MsTeamsIntegrationDeactivationModalComponent,
            boolean
        >,
        private msTeamsIntegrationService: MsTeamsIntegrationService,
    ) {
        super(dialogRef, undefined);
    }

    @withLoading()
    protected async deactivateTeamsIntegration() {
        await this.msTeamsIntegrationService.deactivate();
        this.result = true;
        this.onCloseSubmit();
    }
}
