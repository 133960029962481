import {
    baseMiniUserCollectionCellInputs,
    baseUserCollectionCellInputs,
    ICollectionCellOption,
    ICollectionCellParams,
} from '@datagalaxy/core-ui/cell-components';
import {
    DgUserBadgeCellComponent,
    IDgUserBadgeCellParams,
} from '../dg-user-badge-cell/dg-user-badge-cell.component';
import { IAttributeCollectionCellParamsBuilder } from './attribute-collection-cell.types';
import { EntityUiService } from '../../../entity/services/entity-ui.service';
import { UserService } from '../../../../services/user.service';
import { getLocalId } from '@datagalaxy/webclient/utils';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';
import {
    AttributeMetaValue,
    AttributeObjectValue,
} from '@datagalaxy/webclient/attribute/domain';

/** Builder to create ICollectionCellParams for an attribute user list */
export class UserAttributeCollectionCellParamsBuilder
    implements IAttributeCollectionCellParamsBuilder
{
    constructor(
        private data: EntityItem,
        private values: (AttributeObjectValue | AttributeMetaValue)[],
        private userService: UserService,
        private entityUiService: EntityUiService,
        private isMini?: boolean,
        private enablePopover?: boolean,
    ) {}

    buildParams(): ICollectionCellParams {
        const base = this.isMini
            ? baseMiniUserCollectionCellInputs
            : baseUserCollectionCellInputs;
        return {
            inputs: {
                ...base,
                options: this.buildOptions(),
                openPopoverFn: (el: HTMLElement) => this.openPopover(el),
                disableTooltip: this.enablePopover,
                enablePopover: this.enablePopover,
            },
            isMini: this.isMini,
        };
    }

    openPopover(el: HTMLElement) {
        const params = this.values?.map((value) =>
            value instanceof AttributeMetaValue
                ? this.buildOptionFromMeta(value)
                : this.buildOptionFromObject(value),
        );
        if (!params?.length) {
            return;
        }
        const users = params
            .map(
                (param) =>
                    param && this.userService.getPublicUserData(param.userId),
            )
            .filter((user) => !!user);
        this.entityUiService.openEntityUserListPopover(el, this.data, users);
    }

    buildOptions(): ICollectionCellOption[] {
        return (
            this.values?.map((v) => this.buildOption(v))?.filter((o) => o) ?? []
        );
    }

    buildOption(
        value: AttributeObjectValue | AttributeMetaValue,
    ): ICollectionCellOption {
        const params =
            value instanceof AttributeMetaValue
                ? this.buildOptionFromMeta(value)
                : this.buildOptionFromObject(value);
        return (
            params && {
                renderData: {
                    renderer: DgUserBadgeCellComponent,
                    param: {
                        data: this.data,
                        value: params.userId,
                        disablePopover: this.enablePopover,
                    } as IDgUserBadgeCellParams,
                },
                labelText: params.userFullName,
                shortLabelText: params.lastName,
            }
        );
    }
    buildOptionFromObject(value: AttributeObjectValue): IBuildOptionResult {
        return {
            userId: value.PersonUid,
            userFullName: value.PersonFullName,
            firstName: value.PersonFirstName,
            lastName: value.PersonLastName,
        };
    }
    buildOptionFromMeta(value: AttributeMetaValue): IBuildOptionResult {
        const userId = getLocalId(value.Key);
        const userObjectValue = this.userService.getUserAsObjectValue(userId);
        return userObjectValue && this.buildOptionFromObject(userObjectValue);
    }
}

interface IBuildOptionResult {
    userId: string;
    userFullName: string;
    firstName: string;
    lastName: string;
}
