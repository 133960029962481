import {
    Dom2dUtil,
    IGetRectOptions,
    IXYRectRO,
    Rect,
    TDomElement,
} from '@datagalaxy/core-2d-util';
import { CollectionsHelper, CoreUtil } from '@datagalaxy/core-util';
import {
    BaseGraphicalManager,
    IGraphicalManagerOptions,
} from './BaseGraphicalManager';
import {
    GraphEdgeEvent,
    GraphNodeEvent,
    IAddNodesOptions,
    IGraphEvent,
    IGraphManager,
    IGraphManagerOptions,
    IGraphStateEvent,
    TGraphItem,
} from './graph-manager.types';
import { map } from 'rxjs';
import { ManagedItem } from './node/managed-item';
import type { IDiagNode, IDiagNodeData, INewEdgeInfo } from '../diagramming';
import {
    EdgeSpec,
    Graph,
    GraphEvent,
    GraphState,
    IAddItemsOptions,
    NodeSpec,
    SurfaceLayer,
    TDiagEdge,
    TGetAdaptedRect,
    TGetData,
} from './index';
import {
    Connector,
    GeometryConverter,
    IConnectorSpec,
    IEdgeOrthoConnectorOptions,
} from './connector';
import * as uuid from 'uuid';
import { IEdgeManagerOptions } from './edge';

/** ## Role
 * Sub-component for managing a set of graphical nodes and edges.
 * - add/remove nodes & edges by API
 * - manual connection by node source-port element drag
 * - manual de/reconnection by connector end-point element drag
 * */
export class GraphManager<NodeData = unknown, EdgeData = unknown>
    extends BaseGraphicalManager
    implements IGraphManager<NodeData, EdgeData>
{
    private graph = new Graph<
        ManagedItem<NodeData>,
        Connector<NodeData, EdgeData>
    >();
    protected options: IEdgeManagerOptions<NodeData>;

    public get events$() {
        return this.graph.events$.pipe(
            map((event) => this.graphEventToGraphEvent(event)),
        );
    }

    public get nodes$() {
        return this.events$.pipe(map(() => this.graph.getNodes()));
    }
    public get nodesEvents$() {
        return this.events$.pipe(
            map((event) => this.graphStateEventToNodeEvent(event)),
        );
    }
    public get nodes(): ManagedItem<NodeData>[] {
        return this.graph.getNodes().map((n) => n.data);
    }

    public get edgesEvents$() {
        return this.events$.pipe(
            map((event) => this.graphStateEventToEdgeEvent(event)),
        );
    }
    public get edges(): Connector<NodeData, EdgeData>[] {
        return this.graph.getEdges().map((e) => e.data);
    }

    public get items$() {
        return this.events$.pipe(
            map((event) => this.graphStateEventToGraphEvent(event)),
        );
    }
    public get items() {
        return [...this.nodes, ...this.edges];
    }

    constructor(
        options: IGraphManagerOptions<NodeData> & IGraphicalManagerOptions,
    ) {
        super();
        this.options = super.makeManagerOptions(
            options,
            { ...options.edges, noDecimals: options.noDecimals },
            true,
        );
    }

    public add(
        nodeSpecs: NodeSpec<NodeData>[],
        edgeSpecs: EdgeSpec<NodeData, EdgeData>[],
    ): void {
        const items = nodeSpecs?.map((spec) => {
            const item = new ManagedItem<NodeData>(spec);
            return this.registerNode(item, spec);
        });

        const edges = edgeSpecs
            ?.map((es) => this.makeEdgeFromSpec(es, items))
            .filter((e) => !!e)
            .map((e) => ({
                id: e.id,
                source: e.source.id,
                target: e.target.id,
                data: e.connector as Connector<NodeData, EdgeData>,
            }));

        this.graph.add(items, edges);
    }

    //#region nodes
    /** Registers the provided elements and data,
     * appends elements to the specified layer
     * (which defaults to *svg* for SVGElement and *back* for HTMLElement),
     * sets each element its position and size,
     * makes them draggable and connectible */
    public addNodesFromElements(
        els: TDomElement[],
        opt?: IAddItemsOptions<NodeData> & IAddNodesOptions<NodeData>,
    ): IDiagNode<NodeData>[] {
        const addedNodes: IDiagNode<NodeData>[] = [];
        if (!els?.length) {
            return;
        }
        this.addItemsFromElements(els as TDomElement[], opt, (mi, el) =>
            addedNodes.push(this.registerNode(mi, { id: el.id })),
        );
        this.graph.add(addedNodes, []);
        return addedNodes;
    }

    public addNodes(nodeSpecList: NodeSpec<NodeData>[]): void {
        this.add(nodeSpecList, []);
    }

    public getNodes(): IDiagNode<NodeData>[] {
        return this.graph.getNodes();
    }

    public getNodeById(id: string): IDiagNode<NodeData> {
        return this.graph.getNode(id);
    }

    public updateNode(id: string, partial: Partial<NodeSpec>): void {
        this.updateNodes([id], partial);
    }

    public updateNodes(ids: string[], partial: Partial<NodeSpec>): void {
        this.graph.updateNode(ids, (data) => data.updateProps(partial));
    }

    public removeNodesById(...ids: string[]): void {
        this.graph.removeNodes(...ids);
    }

    public removeNodes(...ids: string[]): void {
        this.removeNodesById(...ids);
    }

    //#endregion nodes

    //#region edges
    /** Creates and displays the specified edges */
    public addEdges(...edgeSpecs: EdgeSpec<NodeData, EdgeData>[]): void {
        this.add([], edgeSpecs);
    }

    public getEdgeById(edgeId: string): TDiagEdge<NodeData, EdgeData> {
        return this.getEdgeFromConnector(this.graph.getEdge(edgeId)?.data);
    }

    public getEdgeFromConnector(
        c: Connector<NodeData, EdgeData>,
    ): TDiagEdge<NodeData, EdgeData> {
        if (!c) {
            return;
        }
        return {
            id: c.id,
            source: { id: c.srcNode.id, data: c.srcNode },
            target: { id: c.tgtNode.id, data: c.tgtNode },
            geometry: c.getGeometry(),
            data: c.data,
            connector: c,
        };
    }

    public getEdges(): TDiagEdge<NodeData, EdgeData>[] {
        return this.graph
            .getEdges()
            .map((e) => this.getEdgeFromConnector(e.data));
    }

    public getNodesEdges(...ids: string[]): TDiagEdge<NodeData, EdgeData>[] {
        return this.graph
            .getNodesEdges(...ids)
            .map((e) => this.getEdgeFromConnector(e.data));
    }

    public getConnectorById(id: string): Connector<NodeData, EdgeData> {
        return this.graph.getEdge(id)?.data;
    }

    public getConnectorsById(ids: string[]): Connector<NodeData, EdgeData>[] {
        return this.graph.getEdgesByIds(ids).map((e) => e.data);
    }

    public getConnectors(
        node: ManagedItem<NodeData>,
    ): Connector<NodeData, EdgeData>[] {
        return this.graph.getNodesEdges(node.id).map((e) => e.data);
    }

    public updateEdge(
        id: string,
        partial: Partial<EdgeSpec<NodeData, EdgeData>>,
    ): void {
        this.updateEdges([id], partial);
    }

    public updateEdges(
        ids: string[],
        partial: Partial<
            Omit<EdgeSpec<NodeData, EdgeData>, 'source' | 'target'>
        >,
    ): void {
        this.graph.updateEdge(ids, (data) =>
            data.updateProps({
                ...partial,
                geometry: GeometryConverter.convert(partial.geometry),
            }),
        );
    }

    public clearEdgesGeometry(ids: string[]) {
        const connectors = ids.map(
            (id) =>
                this.getEdgeById(id)?.connector as Connector<
                    NodeData,
                    EdgeData
                >,
        );
        connectors.forEach((c) => c.clearGeometry());
        this.graph.updateEdge(ids, (data) => data.clearGeometry());
    }

    public removeEdges(...ids: string[]) {
        this.graph.removeEdges(...ids);
    }

    public remove(nodeIds: string[], edgeIds: string[]) {
        this.graph.remove(nodeIds, edgeIds);
    }

    //#endregion edges

    public dispose() {
        this.graph.clear();
    }

    public makeEdge(
        es: INewEdgeInfo,
        connector: Connector<NodeData>,
        source: IDiagNode<NodeData>,
        target: IDiagNode<NodeData>,
    ): TDiagEdge<NodeData, EdgeData> {
        return {
            id: es?.id,
            data: es?.data as EdgeData,
            geometry: connector.getGeometry() ? es.geometry : undefined,
            source,
            target,
            connector,
        };
    }

    private makeEdgeFromSpec(
        edgeSpec: EdgeSpec<NodeData, EdgeData>,
        items: IDiagNode<NodeData>[],
    ) {
        const source =
            this.getNodeById(edgeSpec.source) ||
            items.find((n) => n.id == edgeSpec.source);
        const target =
            this.getNodeById(edgeSpec.target) ||
            items.find((n) => n.id == edgeSpec.target);

        if (!source || !target) {
            CoreUtil.warn('Source and/or target is missing', edgeSpec);
            return;
        }

        const connector = this.makeConnector({
            ...edgeSpec,
            layer: edgeSpec.layer ?? SurfaceLayer.svg,
            source: source.data,
            target: target.data,
            geometry: GeometryConverter.convert(edgeSpec.geometry),
        });
        return this.makeEdge(edgeSpec, connector, source, target);
    }

    /** Registers the provided elements and data,
     * appends elements to the specified layer
     * (which defaults to *svg* for SVGElement and *front* for HTMLElement),
     * sets each element's rectangle its position and size,
     * and makes them draggable.
     * Note that updating the element's actual (style) position is left to the client,
     * which can be done by using the *itemUpdated* event */
    private addItemsFromElements(
        els: TDomElement[],
        opt: IAddItemsOptions<NodeData> & IAddNodesOptions<NodeData> = {},
        onRegistered?: (item: ManagedItem<NodeData>, el: TDomElement) => void,
    ) {
        this._debug && this.log('addItems', els, opt);
        if (!els?.length) {
            return;
        }
        const data = this.getData(els, opt.data);
        return els.map((el, i) => {
            const d = data?.[i] as NodeData;
            const layer = this.getLayer(
                CoreUtil.fromFnOrValue(opt.layer, d, el),
                el,
            );
            const spec: NodeSpec = {
                id: el.id ?? uuid.v4(),
                el,
                layer,
                ports: CoreUtil.fromFnOrValue(opt.ports, d, el),
                data: data?.[i] as NodeData,
                rect: this.getRect(el, d, opt.getRect, opt.container),
                unDraggable: CoreUtil.fromFnOrValue(opt.undraggable, d, el),
            };
            const item = new ManagedItem<NodeData>(spec);
            onRegistered?.(item, el);
            return item;
        });
    }

    private registerNode(
        mi: ManagedItem<NodeData>,
        spec: {
            id?: string;
            type?: string;
            isContainer?: boolean;
            uncontainable?: boolean;
        },
    ) {
        const data: IDiagNodeData<NodeData> = mi;
        const node: IDiagNode<NodeData> = {
            data,
            id: spec.id,
            type: spec.type,
        };
        return node;
    }

    private getData(els: TDomElement[], getData?: TGetData<NodeData>) {
        return typeof getData == 'function'
            ? els?.map(getData)
            : CollectionsHelper.toArray(getData);
    }

    private getRect(
        el: TDomElement,
        d: NodeData,
        getRect?: IXYRectRO | TGetAdaptedRect<NodeData>,
        container?: HTMLElement,
    ) {
        if (typeof getRect == 'function') {
            return Rect.from(getRect(d, el));
        }
        const r = getRect as IXYRectRO;
        if (r && (r.width != undefined || r.height != undefined)) {
            return Rect.from(r);
        }
        const domRect = Dom2dUtil.getRect(el, getRect as IGetRectOptions);

        if (container) {
            const containerRect = Rect.from(container.getBoundingClientRect());
            return Rect.shift(domRect, {
                dx: -containerRect.x,
                dy: -containerRect.y,
            });
        }

        return Rect.from(domRect);
    }

    private getLayer(
        layer: SurfaceLayer,
        el: TDomElement,
        defaultForHTML = SurfaceLayer.default,
    ) {
        if (layer === SurfaceLayer.none) {
            return layer;
        }
        return (layer ?? el instanceof SVGElement)
            ? SurfaceLayer.svg
            : defaultForHTML;
    }

    private makeConnector(spec: IConnectorSpec<NodeData, EdgeData>) {
        const opts = this.options;
        const co = this.options.connectors;
        const oco = co as IEdgeOrthoConnectorOptions;

        spec.cssClass ??= opts.class;
        spec.color ??= co?.color;
        spec.gap ??= co?.gap || 0;

        const pathBuilderOptions = spec.pathBuilderOptions ?? {
            routing: 'orthogonal',
        };

        if (pathBuilderOptions?.routing === 'orthogonal') {
            const opt = pathBuilderOptions;
            spec.pathBuilderOptions = {
                routing: 'orthogonal',
                radius: opt?.radius ?? oco?.radius ?? 40,
                shapeMargin: opt?.shapeMargin ?? oco?.shapeMargin ?? 20,
                boundsMargin: opt?.boundsMargin ?? oco?.boundsMargin ?? 20,
            };
        }

        return new Connector<NodeData, EdgeData>(spec);
    }

    //#region state

    private graphStateEventToNodeEvent(
        event: IGraphStateEvent<NodeData, EdgeData>,
    ): GraphNodeEvent<NodeData> {
        return {
            added: event.added?.nodes,
            removed: event.removed?.nodes,
            updated: event.updated?.nodes,
        };
    }

    private graphStateEventToEdgeEvent(
        event: IGraphStateEvent<NodeData, EdgeData>,
    ): GraphEdgeEvent {
        return {
            added: event.added?.connectors,
            removed: event.removed?.connectors,
            updated: event.updated?.connectors,
        };
    }

    private graphStateEventToGraphEvent(
        event: IGraphStateEvent<NodeData, EdgeData>,
    ): IGraphEvent {
        return {
            added: CollectionsHelper.concat<TGraphItem>(
                event.added?.nodes,
                event.added?.connectors,
            ),
            removed: CollectionsHelper.concat<TGraphItem>(
                event.removed?.nodes,
                event.removed?.connectors,
            ),
            updated: CollectionsHelper.concat<TGraphItem>(
                event.updated?.nodes,
                event.updated?.connectors,
            ),
        };
    }

    private graphEventToGraphEvent(
        event: GraphEvent<
            GraphState<ManagedItem<NodeData>, Connector<NodeData, EdgeData>>
        >,
    ): IGraphStateEvent<NodeData, EdgeData> {
        return {
            added: event.added && {
                nodes: event.added?.nodes.map((n) => n.data),
                connectors: event.added?.edges.map((e) => e.data),
            },
            removed: event.removed && {
                nodes: event.removed?.nodes.map((n) => n.data),
                connectors: event.removed?.edges.map((e) => e.data),
            },
            updated: event.updated && {
                nodes: event.updated?.nodes.map((n) => n.data),
                connectors: event.updated?.edges.map((e) => e.data),
            },
        };
    }
    //#endregion state
}
