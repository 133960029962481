import { InjectionToken } from '@angular/core';
import { AttributeMetaInfo } from '@datagalaxy/webclient/attribute/domain';

export interface AttributesFinder {
    findAttributeByPath(
        attributePath: string,
    ): Promise<AttributeMetaInfo | undefined>;
    findAttributesByDataTypeName(
        dataTypeName: string,
    ): Promise<AttributeMetaInfo[]>;
    searchAttributes(
        predicate: (ami: AttributeMetaInfo) => boolean,
    ): Promise<AttributeMetaInfo[]>;
}

export const ATTRIBUTES_FINDER_TOKEN = new InjectionToken<AttributesFinder>(
    'AttributesFinder',
);
