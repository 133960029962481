import { Injectable } from '@angular/core';
import { BackendApiService } from '@datagalaxy/data-access';
import {
    ChangeVersionStatusParameter,
    ChangeVersionStatusResult,
    CreateVersionParameter,
    CreateVersionResult,
    EnableVersioningParameter,
    EnableVersioningResult,
    GetProjectVersionsParameter,
    GetProjectVersionsResult,
    UpdateDefaultVersionParameter,
    UpdateVersionParameter,
    UpdateVersionResult,
} from './version';
import {
    CompareVersionsParameter,
    CompareVersionsResult,
    EntityCompareVersionsItem,
} from './compare-entity';

@Injectable({ providedIn: 'root' })
export class VersioningApiService {
    constructor(private backendApiService: BackendApiService) {}

    public async activateVersioning(param: EnableVersioningParameter) {
        return await this.backendApiService.postPromise(
            'Version/EnableVersioning',
            param,
            EnableVersioningResult,
        );
    }

    public async getProjectVersions(param: GetProjectVersionsParameter) {
        return await this.backendApiService.postPromise(
            'Version/GetProjectVersions',
            param,
            GetProjectVersionsResult,
        );
    }

    public async createVersion(param: CreateVersionParameter) {
        return await this.backendApiService.postPromise(
            'Version/CreateVersion',
            param,
            CreateVersionResult,
        );
    }

    public async changeVersionStatus(param: ChangeVersionStatusParameter) {
        return await this.backendApiService.postPromise(
            'Version/ChangeVersionStatus',
            param,
            ChangeVersionStatusResult,
        );
    }

    public async updateProjectVersion(param: UpdateVersionParameter) {
        return await this.backendApiService.postPromise(
            'Version/UpdateVersion',
            param,
            UpdateVersionResult,
        );
    }

    public async UpdateDefaultVersion(param: UpdateDefaultVersionParameter) {
        return await this.backendApiService.postPromise(
            'Version/UpdateDefaultVersion',
            param,
            UpdateVersionResult,
        );
    }

    public async compareVersions(param: CompareVersionsParameter) {
        return await this.backendApiService.postPromise(
            'Version/CompareVersions',
            param,
            CompareVersionsResult,
        );
    }

    public async entityCompareVersions(param: CompareVersionsParameter) {
        return await this.backendApiService.postPromise(
            'Version/EntityCompareVersions',
            param,
            EntityCompareVersionsItem,
        );
    }
}
