<dxy-spinner
    *ngIf="loading$ | async; else tableTemplate"
    class="absolute-centered xl"
></dxy-spinner>

<ng-template #tableTemplate>
    <table *ngIf="table$ | async as table">
        <thead
            *ngIf="!config?.hideHeader"
            cdkDropList
            cdkDropListOrientation="horizontal"
            [cdkDropListDisabled]="!orderingEnabled"
            (cdkDropListDropped)="onHeaderCellDrop($event)"
        >
            <tr
                *ngFor="let headerGroup of table.getHeaderGroups()"
                class="header-row"
            >
                <th *ngIf="selectionEnabled" class="header-cell column-select">
                    <dxy-checkbox
                        (checkedChange)="table.toggleAllRowsSelected()"
                        [checked]="table.getIsAllRowsSelected()"
                        [indeterminate]="table.getIsSomeRowsSelected()"
                    >
                    </dxy-checkbox>
                </th>

                <th
                    *ngFor="
                        let header of headerGroup.headers;
                        trackBy: trackByHeader
                    "
                    class="header-cell header-cell-{{ header.id }}"
                    [style.width.px]="header.getSize()"
                    [class.sortable]="header.column.getCanSort()"
                    (click)="onColumnSorting(header.column)"
                    cdkDrag
                    cdkDragLockAxis="x"
                    [cdkDragDisabled]="header.column.getIsPinned()"
                    cdkDragBoundary="cdk-header-row"
                    [cdkDragData]="header"
                >
                    <span class="header-text" dxyEllipsisTooltip>
                        {{ header.column.columnDef.header }}
                    </span>
                    <dxy-icon
                        *ngIf="header.column.getCanSort()"
                        [icon]="getSortIcon(header)"
                        size="xs"
                    ></dxy-icon>
                    <div
                        *ngIf="header.column.getCanResize()"
                        class="resize-handle"
                        (mousedown)="onResizeHeader($event, header)"
                        (click)="$event.stopPropagation()"
                    >
                        <span class="resize-bar"></span>
                    </div>
                </th>
            </tr>
        </thead>
        <tbody>
            <cdk-virtual-scroll-viewport
                [itemSize]="rowHeight"
                (scrolledIndexChange)="onScrollIndexChange($event)"
            >
                <tr
                    *cdkVirtualFor="let row of tableRows; trackBy: trackByRow"
                    class="row"
                    [ngClass]="config?.rowCssClass?.(row.original) || ''"
                    [class.group-row]="row.getIsGrouped()"
                    [class.active]="isActive(row)"
                    [class.hover-cursor]="config?.rowCursorOnHover"
                    (click)="onRowClick(row)"
                    [id]="row.id"
                >
                    <td
                        *ngIf="row.getIsGrouped()"
                        class="group-cell"
                        [attr.colspan]="table.getVisibleLeafColumns().length"
                    >
                        <dxy-grid-expand-button
                            [expanded]="row.getIsExpanded()"
                            (toggle)="row.toggleExpanded()"
                        ></dxy-grid-expand-button>
                        <div>{{ getGroupingText(row) }}</div>
                    </td>
                    <ng-container *ngIf="!row.getIsGrouped()">
                        <td *ngIf="selectionEnabled" class="cell column-select">
                            <dxy-checkbox
                                (click)="$event.stopPropagation()"
                                (change)="toggleRowSelection(row)"
                                [checked]="row.getIsSelected()"
                            >
                            </dxy-checkbox>
                        </td>
                        <td
                            *ngFor="
                                let cell of row.getVisibleCells();
                                let cellIndex = index;
                                trackBy: trackByCol
                            "
                            class="cell"
                            [class.expand-cell]="row.getCanExpand()"
                            [style.width.px]="cell.column.getSize()"
                        >
                            <ng-container *ngIf="cellIndex === 0 && isTree">
                                <div
                                    class="spacer"
                                    [style.padding-left.px]="
                                        row.depth * 16 +
                                        (row.getCanExpand() ? 0 : 32)
                                    "
                                ></div>
                                <dxy-grid-expand-button
                                    *ngIf="row.getCanExpand()"
                                    [loading]="isRowLoadingChildren(row)"
                                    [expanded]="row.getIsExpanded()"
                                    (toggle)="onToggleExpansion(row)"
                                ></dxy-grid-expand-button>
                            </ng-container>
                            <dxy-grid-cell
                                [col]="getCellColumn(cell)"
                                [item]="row.original"
                            ></dxy-grid-cell>
                        </td>
                    </ng-container>
                </tr>
                <tr
                    *ngIf="infiniteScrollLoading$ | async"
                    class="infinite-loading-row"
                >
                    <td><dxy-spinner></dxy-spinner></td>
                </tr>
            </cdk-virtual-scroll-viewport>
        </tbody>
    </table>
</ng-template>
