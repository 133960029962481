import { Component, Input } from '@angular/core';
import { IActionOption } from '@datagalaxy/core-ui';
import {
    BaseCellComponent,
    ICellParams,
} from '@datagalaxy/core-ui/cell-components';
import { IIconCellData } from '@datagalaxy/core-ui';
import { DxyIconCellComponent } from '@datagalaxy/core-ui/cell-components';
import { NgIf } from '@angular/common';
import { DxyActionListComponent } from '@datagalaxy/core-ui';

/**
 * ## Role
 * Display a text and action buttons in an omnigrid
 */
@Component({
    selector: 'app-icon-and-tools-cell',
    templateUrl: 'icon-and-tools-cell.component.html',
    styleUrls: ['icon-and-tools-cell.component.scss'],
    standalone: true,
    imports: [DxyActionListComponent, NgIf, DxyIconCellComponent],
})
export class IconAndToolsCellComponent<
    TEntity = unknown,
> extends BaseCellComponent<TEntity, IIconCellData> {
    @Input() iconCellData: IIconCellData;
    @Input() actions: IActionOption<TEntity>[];
    @Input() actionsData: TEntity;

    protected get iconCellParams(): ICellParams<TEntity, IIconCellData> {
        return {
            value: this.iconCellData,
        };
    }

    constructor() {
        super();
    }

    protected override updateBindings() {
        this.iconCellData = this.value;
        this.actions = this.params.actions;
        this.actionsData = this.data;
    }
}
