import { IHasHddData, IHierarchicalData } from '@datagalaxy/dg-object-model';

export interface IDisplayedHDataOf<T> extends DisplayedHData {
    originItem?: T;
}

export class DisplayedHData implements IHasHddData {
    public get HddData(): IHierarchicalData {
        return this.hierarchicalData;
    }

    constructor(
        public displayName: string,
        public glyphClassName: string,
        public hierarchicalData: IHierarchicalData,
        public isLinkEnabled: boolean,
        public navToTab?: string,
        public originItem?: any,
    ) {}
}
