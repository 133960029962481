import { Inject, Injectable } from '@angular/core';
import { AppConfigService } from '@datagalaxy/webclient/config';
import { OpenFeatureService } from '@datagalaxy/open-feature';
import { FeatureFlag } from './feature-flag';
import { FeatureFlagStatus } from './feature-flag-status.enum';
import { Observable } from 'rxjs';

const LOCAL_STORAGE_FLAGS_KEY = 'FeatureFlags';

/**
 * ## Role
 * List all deactivable features with its status.
 * Could be a feature based on Env variable or in memory array
 */
@Injectable({
    providedIn: 'root',
})
export class FeatureFlagService {
    public ready$: Observable<void>;

    private localStorageFlags: FeatureFlag[] = [];
    private flags: FeatureFlag[] = [];

    constructor(
        private configService: AppConfigService,
        private openFeatureService: OpenFeatureService,
        @Inject(Window) private window: Window,
    ) {
        this.initDefaultFlags();
        this.addWindowMethods();
        this.ready$ = this.openFeatureService.ready$;
    }

    public isFeatureEnabled(featureCode: string): boolean {
        if (this.openFeatureService.getBooleanFeatureFlag(featureCode)) {
            return true;
        }
        const activeFeatureFlagPredicate = (ff: FeatureFlag) =>
            ff.featureCode == featureCode &&
            ff.status != FeatureFlagStatus.Deactivated;
        return (
            (this.configService as any)[featureCode] ||
            this.flags.find(activeFeatureFlagPredicate) ||
            false
        );
    }

    public haveFeatureEnabled(featureCodes: string[]) {
        return featureCodes.some((name) => this.isFeatureEnabled(name));
    }

    private initDefaultFlags() {
        const defaultFlags = [
            {
                featureCode: 'acm-lot-2',
                status: FeatureFlagStatus.Deactivated,
            },
            {
                featureCode: 'lineage-backward-exploratory',
                status: FeatureFlagStatus.Deactivated,
            },
        ];
        this.flags = this.applyLocalStorageFlagsValue(defaultFlags);
    }

    private applyLocalStorageFlagsValue(defaultFlags: FeatureFlag[]) {
        const flags =
            (JSON.parse(
                localStorage.getItem(LOCAL_STORAGE_FLAGS_KEY) ?? '[]',
            ) as FeatureFlag[]) ?? [];
        if (flags && Array.isArray(flags)) {
            flags.forEach((flag) => {
                const existingFlag = defaultFlags.find(
                    (f) => f.featureCode === flag.featureCode,
                );
                if (existingFlag) {
                    existingFlag.status = flag.status ?? existingFlag.status;
                } else {
                    defaultFlags.push(flag);
                }
            });
        }
        this.localStorageFlags = flags;
        return defaultFlags;
    }

    private addWindowMethods() {
        (this.window as any)['storeFeatureFlag'] = (flag: FeatureFlag) =>
            this.storeFeatureFlag(flag);
        (this.window as any)['resetStoredFeatureFlags'] = () =>
            this.resetStoredFeatureFlags();
    }
    private storeFeatureFlag(flag: FeatureFlag) {
        if (!flag?.featureCode || !flag?.status) {
            return;
        }
        this.localStorageFlags = [
            ...this.localStorageFlags.filter(
                (f) => f.featureCode !== flag.featureCode,
            ),
            flag,
        ];
        this.addFeatureFlags([flag]);
        this.persistFeatureFlags();
    }

    private addFeatureFlags(flags: FeatureFlag[]) {
        this.flags = [
            ...this.flags.filter(
                (f) => !flags.some((fl) => fl.featureCode === f.featureCode),
            ),
            ...flags,
        ];
    }

    private persistFeatureFlags() {
        localStorage.setItem(
            LOCAL_STORAGE_FLAGS_KEY,
            JSON.stringify(this.localStorageFlags),
        );
        this.initDefaultFlags();
    }

    private resetStoredFeatureFlags() {
        this.localStorageFlags = [];
        this.persistFeatureFlags();
    }
}
