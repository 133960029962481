import { CollectionsHelper } from '@datagalaxy/core-util';
import { FacetGroupType } from './FacetGroupType';
import { FacetItem } from './FacetItem';
import { StringUtil } from '@datagalaxy/core-util';

export class FacetGroup {
    public isCollapsed: boolean;
    public isHidden = false;

    public displayedItems: FacetItem[];

    constructor(
        public readonly groupType: FacetGroupType,
        public readonly displayName: string,
        public readonly items: FacetItem[] = [],
        public readonly subGroups?: FacetGroup[],
        public readonly isCollapsible = false,
        public itemsCountLimit = 5,
    ) {
        this.displayedItems = this.items.slice();
    }

    public onSearchTermChange(searchTerm: string) {
        this.displayedItems = StringUtil.filterSearched(
            searchTerm,
            this.items,
            (item: FacetItem) => item.displayName,
        );
        if (!this.subGroups?.length) {
            return;
        }
        this.subGroups.forEach((g) => g.onSearchTermChange(searchTerm));
    }

    public isVisible() {
        return !this.isHidden && !this.isEmpty();
    }

    /** returns true if no items nor subgroups or subgroup items (matching searchTerm if there is one) */
    public isEmpty() {
        return (
            !this.displayedItems?.length &&
            (!this.subGroups?.length ||
                this.subGroups.every((g) => g.isEmpty()))
        );
    }

    /** return this group's items and subgroups items */
    public getAllItems(): FacetItem[] {
        return [
            ...this.items,
            ...CollectionsHelper.flattenGroups(this.subGroups ?? [], (g) =>
                g.getAllItems(),
            ),
        ];
    }
    /** return this group's items and subgroups items */
    public getItems(predicate: (ft: FacetItem) => boolean): FacetItem[] {
        return [
            ...this.items.filter(predicate),
            ...CollectionsHelper.flattenGroups(this.subGroups ?? [], (g) =>
                g.getItems(predicate),
            ),
        ];
    }
}
