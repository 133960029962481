<div class="modal-header">
    <h3 mat-dialog-title class="modal-title" translate>
        UI.Ddl.scriptGenerationSettingsModal.title
    </h3>
    <button dxyIconButton color="transparent" (click)="onCloseCancel()">
        <i class="glyph-cancelsearch"></i>
    </button>
</div>
<form (ngSubmit)="onCloseSubmit()">
    <div mat-dialog-content [class.modal-disable]="creationLoading">
        <dxy-field-select
            name="quoteStyle"
            labelKey="UI.Ddl.scriptGenerationSettingsModal.lblGenerateScriptQuoteOption"
            [adapter]="quoteStyle"
            (openClose)="onPanelOpenClose($event)"
        ></dxy-field-select>
        <dxy-field-select
            name="caseStyle"
            labelKey="UI.Ddl.scriptGenerationSettingsModal.lblGenerateScriptCaseOption"
            [adapter]="caseStyle"
            (openClose)="onPanelOpenClose($event)"
        ></dxy-field-select>
        <dxy-field-select
            name="lineEnding"
            labelKey="UI.Ddl.scriptGenerationSettingsModal.lblGenerateScriptLineEndingOption"
            [adapter]="lineEnding"
            (openClose)="onPanelOpenClose($event)"
        ></dxy-field-select>
        <dxy-field-boolean
            name="dropBeforeCreate"
            labelKey="UI.Ddl.scriptGenerationSettingsModal.lblDropBeforeCreate"
            [(ngModel)]="dropBeforeCreate"
        ></dxy-field-boolean>
        <dxy-field-boolean
            name="prefixWithSchemaName"
            labelKey="UI.Ddl.scriptGenerationSettingsModal.lblPrefixWithSchemaName"
            [(ngModel)]="prefixWithSchemaName"
        ></dxy-field-boolean>
    </div>
    <dxy-modal-footer
        [actionEnabled]="true"
        [actionBtnLbl]="'UI.Dialog.btnOk' | translate"
        [actionLoading]="creationLoading"
        (onCloseCancel)="onCloseCancel()"
        featureCode="DIAGRAM_GENERATE_DDL,C"
    ></dxy-modal-footer>
</form>
