<div class="modal-header">
    <h3 mat-dialog-title class="modal-title" translate>
        UI.Modeler.PrimaryKeySettingsModal.titleModal
    </h3>
    <button dxyIconButton color="transparent" (click)="onCloseCancel()">
        <i class="glyph-cancelsearch"></i>
    </button>
</div>
<form #form="ngForm">
    <div mat-dialog-content>
        <app-primary-key-settings-content
            [data]="data"
            (outputChange)="onOutputChange($event)"
        ></app-primary-key-settings-content>
    </div>
    <div class="modal-footer dxy-actions">
        <button
            type="button"
            dxyButton
            variant="secondary"
            name="cancel"
            (click)="onCloseCancel()"
        >
            <span translate>UI.Global.btnCancel</span>
        </button>
        <button
            type="button"
            dxyButton
            name="submit"
            (click)="onCloseSubmit()"
            dxyLogFunctional="DIAGRAM_PK_TABLE,C"
            [disabled]="!isValidPkSettings"
            [matTooltip]="'UI.Dialog.NewItem.ttCreateButton' | translate"
        >
            <span translate>UI.Global.btnUpdate</span>
        </button>
    </div>
</form>
