import { Directive, Input, OnDestroy, SimpleChanges } from '@angular/core';
import { BaseComponent } from '@datagalaxy/utils';
import { Subject } from 'rxjs';

/** Provides helpers for an Angular component:
 * - logging,
 * - events subscription and unsubscription. */
@Directive()
export abstract class DxyBaseDirective
    extends BaseComponent
    implements OnDestroy
{
    /** Identifier of this component in debug traces */
    public get logId() {
        return this._logId ?? '';
    }

    @Input()
    public set logId(value: string) {
        this._logId = value;
    }

    /** set to true to activate traces in non production mode */
    public get debug() {
        return this._debug;
    }

    @Input()
    public set debug(value: boolean) {
        this._debug = value;
    }

    protected destroyNotifier = new Subject();

    ngOnDestroy() {
        this.dxyOnDestroy();
    }

    /** to be called directly by a mixin class (multi-inheritance), when ngOnDestroy is already overriden */
    protected dxyOnDestroy() {
        this.registeredSubscriptions?.unsubscribe();
        this.registeredDestroyers?.forEach((destroy) => {
            try {
                destroy?.();
            } catch (e) {}
        });
        this.destroyNotifier?.next(true);
        this.destroyNotifier?.complete();
    }

    //#region change tracking

    /** helper for to react to a single bound property change.
     * Please be aware that ngOnChanges may be called before ngOnInit */
    protected onChange<T>(
        changes: SimpleChanges,
        propertyName: keyof this & string,
        action: (currentValue: T, previousValue: T) => void,
        executeOnFirstChange = false,
    ) {
        const change = changes[propertyName as string],
            isFirstChange = change?.isFirstChange();

        if (change && (executeOnFirstChange || !isFirstChange)) {
            this.debug &&
                this.log(
                    'onChange',
                    propertyName,
                    isFirstChange /*, change.currentValue, change.previousValue*/,
                );
            action(change.currentValue, change.previousValue);
        }
    }

    /** helper for to react to a grouped bound property changes.
     * Please be aware that ngOnChanges may be called before ngOnInit */
    protected onChanges(
        changes: SimpleChanges,
        propertyNames: (keyof this & string)[],
        action: () => void,
        executeOnFirstChange = false,
    ) {
        const filter = (k: string) =>
            changes[k] && (executeOnFirstChange || !changes[k].isFirstChange());
        if (propertyNames.some(filter)) {
            this.debug &&
                this.log('onChanges', ...(propertyNames?.filter(filter) || []));
            action();
        }
    }

    /** helper for to react on a grouped bound property changes.
     * Please be aware that ngOnChanges may be called before $onInit */
    protected onAnyChanges(
        changes: SimpleChanges,
        host: DxyBaseDirective,
        action: () => void,
        executeOnFirstChange = false,
    ) {
        return this.onChanges(
            changes,
            Object.keys(host) as (keyof this & string)[],
            action,
            executeOnFirstChange,
        );
    }

    /** helper for to react on a grouped bound property changes.
     * Please be aware that ngOnChanges may be called before $onInit */
    protected onAnyChangesBut(
        changes: SimpleChanges,
        host: DxyBaseDirective,
        excludedPropertyNames: (keyof this & string)[],
        action: () => void,
        executeOnFirstChange = false,
    ) {
        const propNames = (Object.keys(host) as (keyof this & string)[]).filter(
            (k) => !excludedPropertyNames || !excludedPropertyNames.includes(k),
        );
        return this.onChanges(changes, propNames, action, executeOnFirstChange);
    }

    //#endregion
}
