import { ConnectedPosition } from '@angular/cdk/overlay';

export type TooltipPosition = 'above' | 'below' | 'left' | 'right';

const abovePosition: ConnectedPosition = {
    originX: 'center',
    originY: 'top',
    overlayX: 'center',
    overlayY: 'bottom',
    offsetY: -20,
};

const belowPosition: ConnectedPosition = {
    originX: 'center',
    originY: 'bottom',
    overlayX: 'center',
    overlayY: 'top',
    offsetY: 20,
};

const leftPosition: ConnectedPosition = {
    originX: 'start',
    originY: 'center',
    overlayX: 'end',
    overlayY: 'center',
    offsetX: -20,
};

const rightPosition: ConnectedPosition = {
    originX: 'end',
    originY: 'center',
    overlayX: 'start',
    overlayY: 'center',
    offsetX: 20,
};

export const tooltipPositions: Record<TooltipPosition, ConnectedPosition[]> = {
    above: [abovePosition, belowPosition],
    below: [belowPosition, abovePosition],
    left: [leftPosition, rightPosition],
    right: [rightPosition, leftPosition],
};
