import {
    deserialize,
    deserializeAs,
    inheritSerialization,
    serialize,
} from 'cerialize';
import {
    BaseServiceParameter,
    BaseServiceResult,
} from '@datagalaxy/data-access';
import { ProjectVersion, ProjectVersionStatus } from './version';
import { WorkspaceDetails } from '@datagalaxy/webclient/workspace/domain';

@inheritSerialization(BaseServiceParameter)
export class EnableVersioningParameter extends BaseServiceParameter {
    @serialize public ProjectReferenceId: string;
    @serialize public VersionName: string;
    @serialize public VersionDescription: string;

    constructor(
        projectId: string,
        versionName: string,
        versionDescription: string,
    ) {
        super();
        this.ProjectReferenceId = projectId;
        this.VersionName = versionName;
        this.VersionDescription = versionDescription;
    }
}

@inheritSerialization(BaseServiceResult)
export class EnableVersioningResult extends BaseServiceResult {
    @deserialize public IsErrorVersioningDisabled!: boolean;
    @deserializeAs(ProjectVersion) public UpdatedVersion!: ProjectVersion;
    @deserialize public IsErrorBadProjectId!: boolean;
    @deserialize public IsErrorMissingVersionName!: boolean;
    @deserialize public IsErrorBadVersionId!: boolean;
}

@inheritSerialization(BaseServiceParameter)
export class GetProjectVersionsParameter extends BaseServiceParameter {
    @serialize public ProjectReferenceId: string;
    @serialize public ProjectVersionId: string;

    constructor(projectId: string, projectVersionId: string) {
        super();
        this.ProjectReferenceId = projectId;
        this.ProjectVersionId = projectVersionId;
    }
}

@inheritSerialization(BaseServiceResult)
export class GetProjectVersionsResult extends BaseServiceResult {
    @deserializeAs(ProjectVersion) public ProjectVersions!: ProjectVersion[];
    @deserialize public IsErrorVersioningEnabled!: boolean;
    @deserialize public IsErrorBadProjectId!: boolean;
}

@inheritSerialization(BaseServiceParameter)
export class CreateVersionParameter extends BaseServiceParameter {
    @serialize public ProjectReferenceId: string;
    @serialize public SourceVersionId: string;
    @serialize public VersionName: string;
    @serialize public VersionDescription: string;

    constructor(
        projectId: string,
        projectVersionName: string,
        projectVersionDescription: string,
        sourceVersionId: string,
    ) {
        super();
        this.ProjectReferenceId = projectId;
        this.VersionName = projectVersionName;
        this.VersionDescription = projectVersionDescription;
        this.SourceVersionId = sourceVersionId;
    }
}

@inheritSerialization(BaseServiceResult)
export class CreateVersionResult extends BaseServiceResult {
    @deserializeAs(ProjectVersion) public CreatedVersion!: ProjectVersion;
    @deserialize public IsErrorVersioningDisabled!: boolean;
    @deserialize public IsErrorBadProjectId!: boolean;
    @deserialize public IsErrorBadSourceVersionId!: boolean;
    @deserialize public IsErrorMissingVersionName!: boolean;
}

@inheritSerialization(BaseServiceParameter)
export class ChangeVersionStatusParameter extends BaseServiceParameter {
    @serialize public ProjectReferenceId: string;
    @serialize public ProjectVersionId: string;
    @serialize public NewVersionStatus: string;

    constructor(
        projectId: string,
        versionId: string,
        newStatus: ProjectVersionStatus,
    ) {
        super();
        this.ProjectReferenceId = projectId;
        this.ProjectVersionId = versionId;
        this.NewVersionStatus = ProjectVersionStatus[newStatus];
    }
}

@inheritSerialization(BaseServiceResult)
export class ChangeVersionStatusResult extends BaseServiceResult {
    @deserializeAs(ProjectVersion) public ProjectVersions!: ProjectVersion[];
    @deserialize public IsErrorStatusDenied!: boolean;
    @deserialize public IsErrorBadProjectId!: string;
    @deserialize public IsErrorBadVersionId!: string;
    @deserialize public IsErrorVersioningDisabled!: boolean;
    @deserializeAs(WorkspaceDetails) public Project!: WorkspaceDetails;
}

@inheritSerialization(BaseServiceParameter)
export class UpdateVersionParameter extends BaseServiceParameter {
    @serialize public ProjectReferenceId: string;
    @serialize public ProjectVersionId: string;
    @serialize public VersionName: string | null;
    @serialize public VersionDescription: string | null;

    constructor(
        projectId: string,
        versionId: string,
        newName: string,
        newDescription: string,
    ) {
        super();
        this.ProjectReferenceId = projectId;
        this.ProjectVersionId = versionId;
        this.VersionName = newName || null;
        this.VersionDescription = newDescription || null;
    }
}

@inheritSerialization(BaseServiceParameter)
export class UpdateDefaultVersionParameter extends BaseServiceParameter {
    @serialize public ProjectReferenceId: string;
    @serialize public ProjectVersionId: string;
    @serialize public IsDefaultVersion: boolean;

    constructor(
        projectId: string,
        versionId: string,
        isDefaultVersion: boolean,
    ) {
        super();
        this.ProjectReferenceId = projectId;
        this.ProjectVersionId = versionId;
        this.IsDefaultVersion = isDefaultVersion;
    }
}

@inheritSerialization(BaseServiceResult)
export class UpdateVersionResult extends BaseServiceResult {
    @deserializeAs(ProjectVersion) public UpdatedVersion!: ProjectVersion;
    @deserialize public IsErrorBadProjectId!: boolean;
    @deserialize public IsErrorBadVersionId!: boolean;
    @deserialize public IsErrorVersioningDisabled!: boolean;
}
