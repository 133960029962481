import { Inject, Injectable } from '@angular/core';
import {
    EntityItem,
    EntityTypeMeta,
} from '@datagalaxy/webclient/entity/domain';
import {
    ATTRIBUTES_FINDER_TOKEN,
    AttributesFinder,
} from '@datagalaxy/webclient/attribute/feature';

@Injectable({ providedIn: 'root' })
export class EntityMetaService {
    constructor(
        @Inject(ATTRIBUTES_FINDER_TOKEN)
        private attributesFinder: AttributesFinder,
    ) {}

    public async setEntityMeta(
        entity: EntityItem,
        meta: EntityTypeMeta,
    ): Promise<void> {
        if (meta) {
            entity.Meta = meta;
            return;
        }

        const attributes =
            await this.attributesFinder.findAttributesByDataTypeName(
                entity.DataTypeName,
            );
        return await this.setEntityMeta(entity, new EntityTypeMeta(attributes));
    }
}
