import { BaseService } from '@datagalaxy/core-ui';
import { Injectable } from '@angular/core';
import { EntityLinkService } from '../../entity-links/entity-link.service';
import {
    IDataIdentifier,
    IEntityIdentifier,
    ObjectLinkType,
    ServerType,
} from '@datagalaxy/dg-object-model';
import { AttributeDataService } from '../../shared/attribute/attribute-data.service';
import {
    AddLinkAction,
    AddLinkedEntitiesResult,
    DeleteLinkedEntitiesResult,
    EntityApiService,
    GetLinkedDataParameter,
} from '@datagalaxy/webclient/entity/data-access';
import {
    CreateDataProcessingItemParameter,
    DataProcessingApiService,
    DataProcessingItemDto,
    DataProcessingItemType,
    DeleteDataProcessingItemParameter,
    UpdateDataProcessingItemParameter,
} from '@datagalaxy/webclient/data-processing/data-access';
import {
    EntityService,
    ILoadMultiEntityOptions,
} from '../../shared/entity/services/entity.service';
import { ApiServiceError, ApiServiceErrorType } from '@datagalaxy/data-access';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';

@Injectable({ providedIn: 'root' })
export class DataProcessingService extends BaseService {
    constructor(
        private dataProcessingApiService: DataProcessingApiService,
        private entityService: EntityService,
        private entityApiService: EntityApiService,
        private linkedObjectService: EntityLinkService,
    ) {
        super();
    }

    public async createDataProcessingItem(
        dataProcessingIdr: IEntityIdentifier,
        displayName: string,
        itemType: DataProcessingItemType,
        input: IDataIdentifier,
        output: IDataIdentifier,
    ) {
        const inputIds = input ? [input.DataReferenceId] : null;
        const outputIds = input ? [output.DataReferenceId] : null;

        const parameter = new CreateDataProcessingItemParameter(
            dataProcessingIdr.ReferenceId,
            displayName,
            itemType,
            inputIds,
            outputIds,
        );
        parameter.setVersionId(dataProcessingIdr?.VersionId);
        const result =
            await this.dataProcessingApiService.createDataProcessingItem(
                parameter,
            );
        return result.CreatedItem;
    }

    public async deleteDataProcessingItem(
        dpIdentifier: IEntityIdentifier,
        dpiId: string,
    ) {
        const parameter = new DeleteDataProcessingItemParameter(
            dpIdentifier.ReferenceId,
            dpiId,
        );
        parameter.setVersionId(dpIdentifier?.VersionId);
        return await this.dataProcessingApiService.deleteDataProcessingItem(
            parameter,
        );
    }

    public async updateDataProcessingItem(
        item: DataProcessingItemDto,
        versionId: string,
        values: Map<string, string>,
    ) {
        const getValue = (k: string) => (values.has(k) ? values.get(k) : null);
        const parameterName = getValue('DisplayName');
        const parameterTechnicalName = getValue('TechnicalName');
        const parameterDescription = getValue('Description');
        const parameterLongDesc = getValue('LongDescription');
        const parameterType = values.has('ItemType')
            ? DataProcessingItemType[values.get('ItemType')]
            : null;

        const parameter = new UpdateDataProcessingItemParameter(
            item.ReferenceId,
            parameterName,
            parameterType,
            parameterDescription,
            parameterLongDesc,
            parameterTechnicalName,
        );
        parameter.setVersionId(versionId);
        try {
            const result =
                await this.dataProcessingApiService.updateDataProcessingItem(
                    parameter,
                );
            return result.UpdatedItem;
        } catch (error) {
            if (
                error instanceof ApiServiceError &&
                error.type === ApiServiceErrorType.UnmodifiedContent
            ) {
                return item;
            }
        }
    }

    public async getDPItem(
        dpiRefId: IEntityIdentifier,
    ): Promise<DataProcessingItemDto> {
        const dpiEntityItem =
            await this.entityService.getEntityForDetails(dpiRefId);
        return new DataProcessingItemDto(dpiEntityItem);
    }

    //#region DP EntityLink

    public async getDPEntityLinks(dataProcessing: EntityItem) {
        const paramLinkedData = new GetLinkedDataParameter(
            dataProcessing.ReferenceId,
            true,
        );
        paramLinkedData.VersionId = dataProcessing.VersionId;
        paramLinkedData.IncludedTypes = [
            ObjectLinkType.HasInput,
            ObjectLinkType.HasOutput,
        ];
        paramLinkedData.IncludedAttributesFilter.push(
            ...['DataTypeDisplayName', 'SizeString', 'IsPrimaryKey'],
        );

        return await this.entityApiService.getLinkedData(paramLinkedData);
    }

    public async getDPItems(dataProcessing: EntityItem) {
        return this.getEntities(
            dataProcessing,
            ServerType.DataProcessingItem,
            AttributeDataService.dataProcessingItemAttributes,
            dataProcessing.VersionId,
            true,
        );
    }

    public async getEntities(
        parent: IDataIdentifier,
        serverType: ServerType,
        includedAttributes: string[],
        versionId: string,
        includeHdd: boolean,
    ) {
        const parameter: ILoadMultiEntityOptions = {
            parentReferenceId: parent.DataReferenceId,
            dataTypes: serverType,
            includeHdd,
            startIndex: 0,
            size: 1000,
            includedAttributesFilter: includedAttributes,
            versionId: versionId,
        };
        return await this.entityService.loadMultiEntity(parameter);
    }

    public async addDPEntityLinks(
        objectLinkType: ObjectLinkType,
        dataProcessingIdr: IEntityIdentifier,
        graphicalEntityDataList: IDataIdentifier[],
        linkedEntityAttributes?: string[],
        updatedEntityIncludedAttributesFilter?: string[],
    ): Promise<AddLinkedEntitiesResult> {
        const sourceIds = [dataProcessingIdr.ReferenceId];
        const targetIds = graphicalEntityDataList.map(
            (ged) => ged.DataReferenceId,
        );
        return await this.linkedObjectService.linkData(
            objectLinkType,
            sourceIds,
            targetIds,
            dataProcessingIdr.VersionId,
            AddLinkAction.Append,
            linkedEntityAttributes,
            updatedEntityIncludedAttributesFilter,
        );
    }

    public async deleteDPEntityLinks(
        objectLinkType: ObjectLinkType,
        dataProcessingIdr: IEntityIdentifier,
        linkedDataIds: string[],
    ): Promise<DeleteLinkedEntitiesResult> {
        const sourceIds = [dataProcessingIdr.ReferenceId];
        return await this.linkedObjectService.bulkUnlinkData(
            objectLinkType,
            sourceIds,
            dataProcessingIdr.ServerType,
            linkedDataIds,
            dataProcessingIdr.VersionId,
        );
    }

    //#endregion - DP EntityLink
}
