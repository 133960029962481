import { CoreUtil } from '@datagalaxy/core-util';
import { BaseComponent } from '@datagalaxy/utils';

/** Base class for graphical management components */
export abstract class BaseGraphicalManager extends BaseComponent {
    public get debug() {
        return this._debug;
    }
    public set debug(value: boolean) {
        this._debug = value;
    }
    protected verbose: boolean;

    protected constructor() {
        super();
    }

    public dispose() {
        this.registeredSubscriptions?.unsubscribe();
        this.registeredDestroyers?.forEach((destroy) => {
            try {
                destroy?.();
            } catch (e) {}
        });
    }

    /** Sets *debug* and *verbose* properties */
    protected initInternal(
        options: IGraphicalManagerOptions,
        verbose?: boolean,
    ) {
        if (options?.debug) {
            this._debug = true;
        }
        this.verbose =
            this._debug && (this.verbose || verbose || options?.verbose);
    }

    /** Adds *debug* and *verbose* values from *masterOptions* to the given *mgrOptions*.
     * *result* is *mgrOptions*, or a new object if *mgrOptions* is undefined.
     * Returns *result*. */
    protected makeManagerOptions<G extends IGraphicalManagerOptions, M>(
        masterOptions: G,
        mgrOptions: M,
        propagateSelfDebug?: boolean,
        result = (mgrOptions ?? {}) as M & G,
    ) {
        if (propagateSelfDebug) {
            result.debug = masterOptions.debug || this.debug;
        }
        return CoreUtil.mergePartialSourceDefined(
            result,
            masterOptions,
            'debug',
            'verbose',
        );
    }
}

export interface IGraphicalManagerOptions {
    /** to log events to the console */
    debug?: boolean;
    /** to log more events to the console */
    verbose?: boolean;
}
