import { Injectable } from '@angular/core';
import { BackendApiService } from '@datagalaxy/data-access';
import { ObservableInput } from 'rxjs';
import { GetActivityLogParameter, GetActivityLogResult } from './log';

@Injectable({ providedIn: 'root' })
export class ActivityLogApiService {
    constructor(private backendApiService: BackendApiService) {}

    public async getActivityLog(
        param: GetActivityLogParameter,
        cancelNotifier?: ObservableInput<any>,
    ): Promise<GetActivityLogResult> {
        const result = await this.backendApiService.postPromise(
            'Search/GetActivityLog',
            param,
            GetActivityLogResult,
            { cancelNotifier },
        );
        result?.Entries?.filter((e) => !e.VersionId).forEach((e) =>
            e.setVersionId(param.VersionId),
        );
        return result;
    }
}
