import { selectAll } from 'd3-selection';
import { CollectionsHelper, DomUtil } from '@datagalaxy/core-util';
import { Point, Rect, TDomElement } from '@datagalaxy/core-2d-util';
import { BaseGraphicalManager } from '../../BaseGraphicalManager';
import { TD3Subject } from '../../../D3Helper';
import { GraphNodeEvent } from '../../graph-manager.types';
import { GraphManager } from '../../graph-manager';
import { drag as d3drag } from 'd3-drag';
import { Connector } from '../../connector';
import { ZoomedViewAdapter } from '../pan-zoom/zoom-adapters/ZoomedViewAdapter';
import { EndpointTool } from '../endpoint';
import * as uuid from 'uuid';
import { NodePort, PortKind } from '../../ports';
import { LinkingToolOptions, PortDragEvent } from './linking.types';
import { EdgeSpec } from '../../edge';
import { LinkingUtils } from './linking.utils';

/**
 * ## Role
 * Responsible for drawing connection between two node ports
 */
export class LinkingTool<
    NodeData = unknown,
    EdgeData = unknown,
> extends BaseGraphicalManager {
    private dragBehaviour = d3drag<TDomElement, NodePort, TD3Subject>();
    /** Temporary connector that live during connecting drag */
    private drawingConnector: Connector<NodeData, EdgeData>;
    private ports: NodePort<NodeData>[] = [];
    private fakeNodeId: string;

    public get isConnecting() {
        return !!this.drawingConnector;
    }

    constructor(
        container: HTMLElement,
        private zoomAdapter: ZoomedViewAdapter,
        private graph: GraphManager<any, any>,
        private endpointTool: EndpointTool,
        private options: LinkingToolOptions<NodeData, EdgeData>,
    ) {
        super();
        this.subscribe(graph.nodesEvents$, (event) =>
            this.onNodesChange(event),
        );

        this.dragBehaviour
            /**
             * We use the container as the reference for the drag event since
             * we are not really dragging the ports, but linking them across the
             * container view. This allows us to have the mouse coordinates relative
             * to the container and not the port element.
             */
            .container(() => container)
            .subject((event) => this.getSourcePort(event))
            .on('drag', (event: PortDragEvent<NodeData>) =>
                this.onLink(event, event.subject),
            )
            .on('end', (event: PortDragEvent<NodeData>) =>
                this.onPortEnd(event, event.subject),
            );
    }

    private onLink(
        event: PortDragEvent<NodeData>,
        sourcePort: NodePort<NodeData>,
    ) {
        const targetPort = this.getTargetPort(event, sourcePort);
        if (!this.isConnecting) {
            // Drag start is handled here instead of drag start event to avoid
            // conflicts with the click event for custom ports
            this.createTemporaryLink(sourcePort);
        }

        const adapter = this.zoomAdapter.unzoom;
        const point = adapter.xy(event.x, event.y);

        if (targetPort) {
            const c = this.drawingConnector;
            if (c.tgtPort.id === targetPort.id) {
                return;
            }
            this.createTemporaryLink(sourcePort, targetPort);
        } else {
            if (this.drawingConnector.tgtNode.id !== this.fakeNodeId) {
                this.createTemporaryLink(sourcePort);
            }
            this.updateLinkTargetPositionToCursor(sourcePort, point);
        }
    }

    private onPortEnd(
        event: PortDragEvent<NodeData>,
        sourcePort: NodePort<NodeData>,
    ) {
        const targetPort = this.getTargetPort(event, sourcePort);

        if (this.isConnecting) {
            this.graph.removeNodesById(this.fakeNodeId);
            this.fakeNodeId = null;
        }

        if (targetPort) {
            void this.connect(sourcePort, targetPort);
        } else if (this.isConnecting) {
            this.drawingConnector = null;
        }
    }

    private getSourcePort(event: PortDragEvent<NodeData>) {
        return this.ports.find(
            (port) =>
                port.el.contains(event.sourceEvent.target) && port.isSource,
        );
    }

    private getTargetPort(
        event: PortDragEvent<NodeData>,
        sourcePort: NodePort<NodeData>,
    ): NodePort<NodeData> {
        if (!event.target) {
            return;
        }

        const ports = this.ports.filter(
            (port) =>
                port.el.contains(event.sourceEvent.target) && port.isTarget,
        );

        if (!ports?.length) {
            return;
        }

        const port =
            ports?.length > 1
                ? ports.find((port) => port.kind !== PortKind.node)
                : ports[0];

        return this.allowConnect(sourcePort, port) ? port : null;
    }

    private createTemporaryLink(
        sourcePort: NodePort<NodeData>,
        targetPort?: NodePort<NodeData>,
    ) {
        if (this.drawingConnector) {
            this.graph.removeEdges(this.drawingConnector.id);
        }
        const spec = this.getConnectingSpec(sourcePort, null);

        if (!targetPort && !this.fakeNodeId) {
            this.graph.addNodes([
                {
                    id: (this.fakeNodeId = uuid.v4()),
                    el: DomUtil.createElement('div', 'fake-node'),
                    /**
                     * We use width and height of 1 to ensure the side is respected
                     * by the routing algorithm, otherwise with 0 size the side can
                     * change
                     */
                    rect: new Rect(0, 0, 1, 1),
                },
            ]);
        }

        const edgeId = uuid.v4();
        this.graph.addEdges({
            ...spec,
            id: edgeId,
            source: sourcePort.node.id,
            target: targetPort?.node.id || this.fakeNodeId,
            cssClass: 'connecting',
        });
        this.drawingConnector = this.graph.getEdgeById(edgeId)
            .connector as Connector<NodeData, EdgeData>;
    }

    private updateLinkTargetPositionToCursor(
        sourcePort: NodePort<NodeData>,
        cursor: Point,
    ) {
        const side = LinkingUtils.getQuadrantRelativeToRectangle(
            sourcePort.rect,
            cursor,
            45,
        );
        this.drawingConnector.setSrcSide(side);
        this.drawingConnector.setTgtSide(Rect.oppositeSide(side));
        Rect.setPosition(
            this.drawingConnector.tgt.node.rect,
            cursor.x,
            cursor.y,
        );
        this.endpointTool.updateConnectorsPorts([this.drawingConnector]);
    }

    private async connect(
        sourcePort: NodePort<NodeData>,
        targetPort: NodePort<NodeData>,
    ) {
        let spec: EdgeSpec<NodeData>;
        const source = sourcePort.node.id,
            target = targetPort.node.id;
        const connector = this.drawingConnector;
        /**
         * Release the drawingConnector while waiting for the link specs which can
         * be async and take time
         */
        this.drawingConnector = null;
        if (this.allowConnect(sourcePort, targetPort)) {
            const getSpec = this.options.getNewEdgeInfo;
            if (getSpec) {
                spec = await getSpec(source, target);
            } else {
                spec = { id: uuid.v4(), source, target };
            }
        }
        this.graph.removeEdges(connector.id);
        if (!spec) {
            return;
        }
        this.graph.addEdges(spec);
    }

    private allowConnect(
        source: NodePort<NodeData>,
        target: NodePort<NodeData>,
    ) {
        const allowConnect = this.options?.allowConnect;
        if (typeof allowConnect == 'function') {
            const src = this.getNodeById(source);
            const tgt = this.getNodeById(target);
            return !!(src && tgt) && allowConnect(src, tgt);
        }
        return allowConnect ?? true;
    }

    private getConnectingSpec(
        source: NodePort<NodeData>,
        target: NodePort<NodeData>,
    ) {
        const getConnectingSpec = this.options?.getConnectingSpec;
        if (typeof getConnectingSpec == 'function') {
            const src = this.getNodeById(source);
            const tgt = this.getNodeById(target);
            return getConnectingSpec(src, tgt);
        }
        return getConnectingSpec;
    }

    private getNodeById(port?: NodePort<NodeData>) {
        if (!port) {
            return;
        }
        return this.graph.getNodeById(port.node.id);
    }

    private onNodesChange(event: GraphNodeEvent) {
        const nodes = event.added;
        if (!nodes?.length) {
            return;
        }

        const ports: NodePort<NodeData>[] = CollectionsHelper.flatten(
            nodes?.map((node) => node.getPorts()),
        ) as NodePort<NodeData>[];
        const sourcePorts = ports.filter((port) => port.el && port.isSource);
        const elements = sourcePorts?.map((port) => port.el);
        this.ports.push(...ports);

        selectAll(elements).call(this.dragBehaviour);
    }
}
