import {
    deserialize,
    inheritSerialization,
    SerializableEnumeration,
    serialize,
} from 'cerialize';
import {
    BaseServiceParameter,
    BaseServiceResult,
} from '@datagalaxy/data-access';

export enum ImageCategory {
    SpaceIcon,
    ClientSearchImage,
    TechnologyImage,
    TechnologyThumbnail,
    TechnologySvg,
    TeamImage,
    SpaceHome,
}
SerializableEnumeration(ImageCategory);

@inheritSerialization(BaseServiceParameter)
export class SetImageParameter extends BaseServiceParameter {
    @serialize public FileContent: string | null;
    @serialize public FileName: string | null;
    @serialize public FileType: string | null;
    @serialize public ImageCategory: ImageCategory;
    /** Needed only for space image/icon update */
    @serialize public SpaceId?: string;
    /** Needed only for technology image update */
    @serialize public TechnologyCode?: string;
    /** Needed only for team image update */
    @serialize public TeamUid?: string;
    @serialize public IsDelete?: boolean;

    public static forDelete(
        imageCategory: ImageCategory,
        spaceId?: string,
        technologyCode?: string,
        teamId?: string,
    ) {
        const p = new SetImageParameter(
            null,
            null,
            null,
            imageCategory,
            spaceId,
            technologyCode,
            teamId,
        );
        p.IsDelete = true;
        return p;
    }

    constructor(
        fileContent: string | null,
        fileName: string | null,
        fileType: string | null,
        imageCategory: ImageCategory,
        spaceId?: string,
        technologyCode?: string,
        teamId?: string,
    ) {
        super();
        this.FileContent = fileContent;
        this.FileName = fileName;
        this.FileType = fileType;
        this.ImageCategory = imageCategory;
        this.SpaceId = spaceId;
        this.TechnologyCode = technologyCode;
        this.TeamUid = teamId;
    }
}

@inheritSerialization(BaseServiceResult)
export class SetImageResult extends BaseServiceResult {
    @deserialize public ImageHash!: string;
}
