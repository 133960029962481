import {
    FlexibleConnectedPositionStrategyOrigin,
    OverlayRef,
} from '@angular/cdk/overlay';
import {
    Component,
    Input,
    TemplateRef,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import {
    DxyTooltipService,
    ITooltipContentShowParams,
    RichTooltipPosition,
    RichTooltipPositionOptions,
} from '@datagalaxy/ui/tooltip';
import { NgClass } from '@angular/common';

/** ## Role
 * Allows to show & hide a tooltip (text or rich) positionned next to or over any native element or zone on screen
 */
@Component({
    selector: 'dxy-rich-tooltip-content',
    templateUrl: 'rich-tooltip-content.component.html',
    standalone: true,
    imports: [NgClass],
})
export class DxyRichTooltipContentComponent extends DxyBaseComponent {
    @Input() contentClass: string;

    public text: string;
    @ViewChild('template') private templateRef: TemplateRef<unknown>;
    private overlayRef: OverlayRef;

    //#region API

    public showHide(data: ITooltipContentShowParams) {
        if (data) {
            this.show(data.target, data.text, data.position, data.options);
        } else {
            this.hide();
        }
    }

    public show(
        /** ElementRef, Element, or { x, y, width, height } */
        target: FlexibleConnectedPositionStrategyOrigin,
        /** text to render as content. If not provided, transcluded content will be used */
        text?: string,
        position: RichTooltipPosition = 'above',
        options?: RichTooltipPositionOptions,
    ) {
        this.log('show', text);
        if (this.overlayRef) {
            this.hide();
        }
        this.text = text;
        this.overlayRef = this.tooltipService.getOverlayRef(
            target,
            position,
            options,
        );
        this.tooltipService.showOverlayRef(
            this.overlayRef,
            this.templateRef,
            this.viewContainerRef,
        );
    }

    public hide() {
        this.log('hide');
        this.tooltipService.hideOverlayRef(this.overlayRef);
        this.text = '';
        this.overlayRef = undefined;
    }

    //#endregion

    constructor(
        private tooltipService: DxyTooltipService,
        private viewContainerRef: ViewContainerRef,
    ) {
        super();
    }
}
