import * as moment from 'moment';
import { CollectionsHelper, DateTimeUtil } from '@datagalaxy/core-util';
import { ModelerDataUtil } from './ModelerDataUtil';
import {
    EntityLinkTypeKind,
    EntityType,
    EntityTypeUtil,
    IHasHddData,
    IHierarchicalData,
    ServerType,
} from '@datagalaxy/dg-object-model';
import { HddUtil } from './HddUtil';
import { EntityServerTypeUtils } from '@datagalaxy/webclient/entity/utils';
import {
    DataGalaxyModule,
    DgModule,
} from '@datagalaxy/shared/dg-module/domain';

export class DataUtil {
    private static readonly modulesByEntityType = new Map<
        EntityType,
        DgModule
    >();

    public static isAvailableForDetailsPage(st: ServerType) {
        switch (st) {
            case ServerType.Model:
            case ServerType.Container:
            case ServerType.Table:
            case ServerType.Column:
            case ServerType.Property:
            case ServerType.DataProcessing:
            case ServerType.SoftwareElement:
                return true;
            default:
                return false;
        }
    }

    public static getSearchableServerTypes() {
        return [
            ...EntityServerTypeUtils.firstClassEntityServerTypes,
            ...EntityServerTypeUtils.secondClassEntityServerTypes,
        ];
    }

    public static getFirstClassEntityServerTypeNames() {
        return EntityServerTypeUtils.firstClassEntityServerTypes.map(
            (st) => ServerType[st],
        );
    }

    public static isModelRelationalHddData(hddData: IHierarchicalData) {
        const modelDescriptor = HddUtil.getModelHdd(hddData);
        return ModelerDataUtil.isModelRelational(
            hddData.DataServerType,
            modelDescriptor,
        );
    }

    public static getServerTypesFromModule(dgModule?: DgModule) {
        return dgModule
            ? dgModule == DgModule.Catalog
                ? ModelerDataUtil.getOrderedModelerServerTypes()
                : [DataUtil.getDefaultServerTypeFromModule(dgModule)]
            : EntityServerTypeUtils.firstClassEntityServerTypes.slice();
    }

    public static getDefaultServerTypeFromModule(
        dgModule: DgModule,
        useModelerInsteadOfModel = false,
    ) {
        switch (dgModule) {
            case DgModule.Glossary:
                return ServerType.Property;
            case DgModule.Catalog:
                return useModelerInsteadOfModel
                    ? ServerType.Modeler
                    : ServerType.Model;
            case DgModule.Processing:
                return ServerType.DataProcessing;
            case DgModule.Usage:
                return ServerType.SoftwareElement;
            case DgModule.Diagram:
                return ServerType.Diagram;
        }
    }

    public static getEntityTypesFromModule(dgModule: DgModule) {
        const serverTypes =
            dgModule == DgModule.Catalog
                ? ModelerDataUtil.modelerServerTypes
                : [DataUtil.getDefaultServerTypeFromModule(dgModule)];
        return EntityTypeUtil.getEntityTypes(serverTypes);
    }

    public static getModuleFromEntityType(entityType: EntityType) {
        if (!entityType) {
            return DgModule.unknown;
        }

        let dgModule = DataUtil.modulesByEntityType.get(entityType);
        if (dgModule == undefined) {
            //console.log('cache miss', EntityType[entityType])
            const serverType = EntityTypeUtil.getServerType(entityType);
            dgModule = DataUtil.getModuleFromServerType(serverType);
            DataUtil.modulesByEntityType.set(entityType, dgModule);
        } else {
            //console.log('cache hit', EntityType[entityType])
        }
        return dgModule;
    }

    public static getModuleFromServerType(serverType: ServerType) {
        switch (serverType) {
            case ServerType.PrimaryKey:
            case ServerType.ForeignKey:
            case ServerType.Model:
            case ServerType.Container:
            case ServerType.Table:
            case ServerType.Column:
            case ServerType.Modeler:
                return DgModule.Catalog;

            case ServerType.Property:
            case ServerType.LocalSynonym:
                return DgModule.Glossary;

            case ServerType.DataProcessing:
            case ServerType.DataProcessingItem:
                return DgModule.Processing;

            case ServerType.SoftwareElement:
                return DgModule.Usage;

            case ServerType.Diagram:
                return DgModule.Diagram;

            default:
                return DgModule.unknown;
        }
    }

    public static getModuleTranslateKeyPrefix(dgModule: DgModule) {
        switch (dgModule) {
            case DgModule.Catalog:
                return 'UI.Modeler.';
            case DgModule.Glossary:
                return 'UI.Glossary.';
            case DgModule.Processing:
                return 'UI.DataProcessing.';
            case DgModule.Usage:
                return 'UI.Software.';
            case DgModule.Diagram:
                return 'UI.Diagrams.';
        }
    }

    public static getModuleTranslateKey(dgModule: DgModule | DataGalaxyModule) {
        return `DgServerTypes.DataGalaxyModule.${DgModule[dgModule]}`;
    }

    public static getPossibleParentSpaceDataTypes(serverType: ServerType) {
        switch (serverType) {
            case ServerType.ObjectTask:
            case ServerType.Property:
            case ServerType.ObjectCommentary:
                return [ServerType.Project];
            case ServerType.Column:
            case ServerType.Container:
            case ServerType.DataType:
            case ServerType.DataTypeMappingItem:
            case ServerType.DataProcessing:
            case ServerType.DataProcessingItem:
            case ServerType.ForeignKey:
            case ServerType.Model:
            case ServerType.Diagram:
            case ServerType.PrimaryKey:
            case ServerType.SoftwareElement:
            case ServerType.Table:
                return [ServerType.Project];
            default:
                return [];
        }
    }

    public static canHaveDataMapView(dgModule: DgModule) {
        switch (dgModule) {
            case DgModule.Glossary:
            case DgModule.Processing:
            case DgModule.Usage:
                return true;

            default:
                return false;
        }
    }

    public static getFormattedDate(m: moment.Moment) {
        return DateTimeUtil.formatForDisplay(m, true) || null;
    }

    public static getFormattedDateTime(m: moment.Moment) {
        return (
            DateTimeUtil.formatForDisplay(m, true, undefined, false, true) ||
            null
        );
    }

    public static isGlossaryMappingEnabled(entityType: EntityType): boolean {
        return (
            EntityTypeUtil.isCompatibleLinkType(
                ServerType.Property,
                entityType,
                EntityLinkTypeKind.IsImplementedBy,
            ) ||
            EntityTypeUtil.isCompatibleLinkType(
                ServerType.Property,
                entityType,
                EntityLinkTypeKind.PropertyHasRecordingSystem,
            )
        );
    }

    public static isLinkedObjectsEnabled(entityType: EntityType): boolean {
        return (
            entityType != EntityType.BusinessDomainGroup &&
            entityType != EntityType.UsageField
        );
    }

    public static isModelDiagramsEnabled(entityHddData: IHierarchicalData) {
        return DataUtil.isModelRelationalHddData(entityHddData);
    }

    public static isDataProcessingMappingEnabled(entityType: EntityType) {
        return entityType == EntityType.DataProcessing;
    }

    public static isAvailableResultItemLineageLink(item: IHasHddData) {
        switch (item?.HddData?.Data?.DataServerType) {
            case ServerType.Model:
            case ServerType.Table:
            case ServerType.Column:
            case ServerType.Property:
            case ServerType.DataProcessing:
            case ServerType.SoftwareElement:
                return true;
            default:
                return false;
        }
    }

    public static isAvailableForPreviewPanel(item: IHasHddData) {
        switch (item?.HddData?.Data?.DataServerType) {
            case ServerType.Model:
            case ServerType.Container:
            case ServerType.Table:
            case ServerType.Column:
            case ServerType.Property:
            case ServerType.DataProcessing:
            case ServerType.SoftwareElement:
            case ServerType.Diagram:
                return true;
            default:
                return false;
        }
    }

    public static isCreateStructureAvailable(hddData: IHierarchicalData) {
        if (ModelerDataUtil.supportsStructureAsChild(hddData.DataServerType)) {
            return true;
        }

        if (!HddUtil.hasHierarchicalChildren(hddData)) {
            return false;
        }

        const mapping = EntityTypeUtil.getMapping(hddData.EntityType);
        return CollectionsHelper.hasAny(mapping.ChildRules, (cr) =>
            cr.AllowedChildrenTypes.some((e) => e == EntityType.SubStructure),
        );
    }
}
