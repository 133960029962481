import { AttributeFilterModel } from '../attribute-filter/attributeFilterModel';
import { IFilterFormModel } from '../attribute-filter-form/IFilterFormModel';
import { FilterUtil } from '../../../util/FilterUtil';
import { FilterOperator } from '@datagalaxy/webclient/filter/domain';
import { AttributeMetaInfo } from '@datagalaxy/webclient/attribute/domain';

export class AttributeNumberFilterModel extends AttributeFilterModel {
    public static readonly defaultAvailableOperators = [
        FilterOperator.Equals,
        FilterOperator.Greater,
        FilterOperator.GreaterEqual,
        FilterOperator.Lower,
        FilterOperator.LowerEqual,
        FilterOperator.RangeContains,
        FilterOperator.FieldIsEmpty,
        FilterOperator.FieldHasValue,
    ];

    public static readonly quickFilterAvailableOperators = [
        FilterOperator.Equals,
    ];

    public static availableOperators(isQuickFilter?: boolean) {
        return isQuickFilter
            ? AttributeNumberFilterModel.quickFilterAvailableOperators
            : AttributeNumberFilterModel.defaultAvailableOperators;
    }

    public value: string;
    public value2: string;
    public errorMsg: string;
    public hasErrorMsg: boolean;

    constructor(
        filterFormData: IFilterFormModel,
        attributeMeta: AttributeMetaInfo,
        operators: FilterOperator[],
    ) {
        super(attributeMeta, operators, filterFormData);
        this.value = '0';
        this.value2 = null;
    }

    getValuesAsArray() {
        const table = [];
        if (this.value != undefined) {
            table.push(this.value.toString());
        }
        if (this.value2 != undefined) {
            table.push(this.value2.toString());
        }
        return table;
    }

    isValid() {
        return (
            this.value != undefined ||
            this.value2 != undefined ||
            FilterUtil.isNullableOperator(this.operator)
        );
    }

    setValuesFromDb(values: string[]) {
        this.value = values && values[0];
        this.value2 = values && values[1];
    }

    copy(filterItem: AttributeNumberFilterModel) {
        this.operator = filterItem.operator;
        this.value = filterItem.value;
        this.value2 = filterItem.value2;
    }

    protected sameTypeAndValue(other: AttributeFilterModel) {
        return (
            other instanceof AttributeNumberFilterModel &&
            ((other.value == undefined && this.value == undefined) ||
                other.value == this.value) &&
            ((other.value2 == undefined && this.value2 == undefined) ||
                other.value2 == this.value2)
        );
    }
}
