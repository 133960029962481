<div *ngIf="showHeader" class="panel-content-tool">
    <div class="panel-content-toolname" translate>
        UI.EntityDockingPane.Tool.VersionComparison
    </div>
</div>
<div class="version-selection-box">
    <!-- Selector section of comparator-->
    <div class="version-selection-wrapper dg_versioning-for-docking-panel">
        <div class="version-name">
            <mat-label translate
                >UI.Versioning.Comparator.currentVersion</mat-label
            >
            <div class="name">{{ currentVersionName }}</div>
        </div>
        <div class="version-name">
            <mat-label translate
                >UI.Versioning.Comparator.currentVersionWith</mat-label
            >
            <dxy-versioning-selector
                [projectIdr]="versionCompareSpace"
                [noNavigator]="true"
                [isVersionSelectable]="true"
                [includeArchived]="true"
                (onProjectVersionSelected)="onProjectVersionSelected($event)"
                (onProjectVersionsLoading)="onProjectVersionsLoading($event)"
                logId="versioning-comparator-single-object"
                [moreText]="moreText"
                menuClass="versioning-comparator-single-object-version-selector-menu"
            ></dxy-versioning-selector>
        </div>
    </div>
    <div *ngIf="!isLoading" class="current-version-row">
        <div *ngIf="showCountCompareDifference" class="current-version-col">
            <span class="label-difference">{{ countCompareDifference }}</span>
        </div>
        <div
            *ngIf="
                hasNoResult &&
                !showCountCompareDifference &&
                hasNoResultModule &&
                !showCountCompareDifference
            "
            class="dg_versioning-comparator-empty-results"
        >
            <span translate>UI.Versioning.Comparator.emptyDifference</span>
        </div>
    </div>
</div>

<!-- Results section of comparator-->

<div *ngIf="isProjectResultCompareVersion">
    <dxy-versioning-comparator-section
        *ngFor="let data of versionCompareModuleItems; trackBy: getModuleDataId"
        [moduleName]="data.ModuleName"
        [comparisonItems]="data.Items"
        [selectedVersion]="selectedVersion"
        class="vcontainerDiv flex-grow"
    >
    </dxy-versioning-comparator-section>
</div>
<div
    *ngIf="!isProjectResultCompareVersion && hasResults"
    class="dg_versioning-comparator-content-wrapper dg_docking-panel-result"
>
    <dxy-screen-layout
        [spaceId]="currentSpaceId"
        [entityData]="currentEntity"
        [entityAttributes]="entityAttributes"
        [reloadOnSourceChange]="reloadOnSourceChange"
        [isVersioningCompare]="isVersioningCompare"
        [comparatorItems]="comparatorItems"
        [versionCompareContext]="versionCompareContext"
    ></dxy-screen-layout>
</div>

<!-- No other version to compare with OR no difference -->
<div *ngIf="noResultAvailable" class="no-item-container">
    <div class="image-container">
        <img
            src="/images/placeholders/version-comparison.svg"
            class="dg_no-item-image"
            alt="no task"
        />
    </div>
    <div class="no-item-title" translate>UI.Versioning.Comparator.titleLbl</div>
    <div class="no-item-text" translate>
        UI.Versioning.Comparator.lblNotAvailable
    </div>
    <button dxyButton variant="secondary" (click)="onClickDocButton()">
        <span translate>UI.Versioning.Comparator.notAvailableBtnLbl</span>
    </button>
</div>

<div *ngIf="isLoading" class="dg5-spinner-container">
    <span class="dg5-spinner"></span>
</div>
