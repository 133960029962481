import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    forwardRef,
    Input,
    OnChanges,
    SimpleChanges,
    TemplateRef,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import { DomUtil } from '@datagalaxy/core-util';
import { UiSpinnerService } from '@datagalaxy/core-ui';
import { IActivityLogNavLinkData } from '../activityLog.types';
import { HierarchicalData, ServerType } from '@datagalaxy/dg-object-model';
import { ActivityLogService } from '../activityLog.service';
import { EntityPreviewPanelService } from '../../shared/entity/services/entity-preview-panel.service';
import { EntityPanelTool } from '../../entity-panels/entity-panels.types';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { EntityIdentifier } from '@datagalaxy/webclient/entity/utils';
import { WorkspaceIdentifier } from '@datagalaxy/webclient/workspace/utils';
import { ActivityLogEntry } from '@datagalaxy/webclient/activity-log/domain';
import { EntityCardCellComponent } from '../../shared/entityCard/entity-card/entity-card-cell.component';
import { NgIf } from '@angular/common';
import { WorkspaceRouterService } from '@datagalaxy/webclient/workspace/feature';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'dxy-activity-log-item',
    templateUrl: 'dxy-activity-log-item.component.html',
    styleUrls: ['dxy-activity-log-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf, forwardRef(() => EntityCardCellComponent)],
})
export class DxyActivityLogItemComponent
    extends DxyBaseComponent
    implements AfterViewInit, OnChanges
{
    @Input() activityLogEntry?: ActivityLogEntry;
    @Input() disableNavigation?: boolean;

    public linkData: IActivityLogNavLinkData;
    public innerHTML: string;

    @ViewChild('dataLinks', { read: ViewContainerRef })
    dataLinksView: ViewContainerRef;
    @ViewChild('dataLinksTemplate') dataLinksTemplate: TemplateRef<HTMLElement>;

    constructor(
        private activityLogService: ActivityLogService,
        private elementRef: ElementRef<HTMLElement>,
        private uiSpinnerService: UiSpinnerService,
        private entityPreviewPanelService: EntityPreviewPanelService,
        private workspaceRouter: WorkspaceRouterService,
        private cd: ChangeDetectorRef,
    ) {
        super();
    }

    ngAfterViewInit() {
        this.initAsync();
    }

    ngOnChanges(changes: SimpleChanges) {
        super.onChanges(changes, ['activityLogEntry'], () => this.initAsync());
    }

    public async onClickEntityLink(hdata: HierarchicalData) {
        if (this.disableNavigation) {
            return;
        }
        return await this.uiSpinnerService.executeWithSpinner(async () => {
            const entityIdr = this.getLinkedEntityIdentifier(hdata);
            if (entityIdr.ServerType == ServerType.Project) {
                return this.workspaceRouter.goToSpaceHome(
                    WorkspaceIdentifier.fromEntity(entityIdr),
                );
            }
            return await this.entityPreviewPanelService.setupPanel({
                entityIdr,
                activeTool: EntityPanelTool[this.linkData.panelTool],
            });
        });
    }

    private async initAsync() {
        const entry = this.activityLogEntry;
        const tal =
            this.activityLogService.buildActivityLogEntryTranslation(entry);

        if (tal?.innerHTML) {
            this.innerHTML = tal.innerHTML;
            this.linkData = tal.navLinkData;
            this.dataLinksView.createEmbeddedView(this.dataLinksTemplate);
            this.cd.detectChanges();
            this.replaceLinks();
            this.log('initAsync', { entry, tal, linkData: this.linkData });
            return;
        }
    }

    private replaceLinks() {
        ['.nav-link-parent', '.nav-link-child'].forEach((c) =>
            this.replaceLink(c),
        );
    }

    private replaceLink(linkSelector: string) {
        const displayedLink = this.getLink('.logElement-item', linkSelector);
        const dataLink = this.getLink('.data-links', linkSelector);
        this.debug &&
            this.log(
                'replaceLink',
                linkSelector,
                displayedLink?.textContent,
                !!dataLink,
            );
        if (displayedLink && dataLink) {
            displayedLink.parentElement.replaceChild(dataLink, displayedLink);
        }
    }

    private getLink(containerSelector: string, linkSelector: string) {
        return DomUtil.getElement<HTMLLinkElement>(
            this.elementRef,
            `${containerSelector} ${linkSelector}`,
        );
    }

    private getLinkedEntityIdentifier(hdata: HierarchicalData) {
        let hdd = hdata.EntityType
            ? hdata
            : hdata.Parents.find((parent) => parent.EntityType);
        if (hdd.ServerType == ServerType.DataProcessingItem) {
            const parent = hdata.Parents?.find(
                (p) => p.ServerType != ServerType.DataProcessingItem,
            );
            if (parent) {
                hdd = parent;
            }
        }
        return new EntityIdentifier(
            hdd.DataReferenceId,
            hdd.VersionId,
            hdd.EntityType,
        );
    }
}
