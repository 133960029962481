import {
    ChangeDetectorRef,
    Directive,
    EventEmitter,
    HostBinding,
    HostListener,
    Input,
    Output,
    inject,
} from '@angular/core';
import { FunnelAction, FunnelActionType } from '../funnel-action';
import { FunnelStateService } from '../store/funnel-state.service';

const primaryActionTypes: FunnelActionType[] = ['next', 'finish'];

@Directive({
    selector: '[dxyFunnelModalAction]',
    standalone: true,
})
export class FunnelModalActionDirective {
    @Input() dxyFunnelModalAction!: FunnelAction;
    @Input() dxyFunnelModalActionStepValid: boolean | null = true;

    @Output() colorChange = new EventEmitter();

    private cd = inject(ChangeDetectorRef);

    private loading = false;

    @HostBinding('attr.disabled') get disabled() {
        if (this.loading) {
            return true;
        }
        const actionType = this.dxyFunnelModalAction.actionType;
        if (!primaryActionTypes.includes(actionType)) {
            return null;
        }
        return this.dxyFunnelModalActionStepValid ? null : true;
    }

    @HostListener('click') private async executeFunnelAction() {
        this.loading = true;
        try {
            const result = await Promise.resolve(
                this.dxyFunnelModalAction.callback?.(),
            );
            this.loading = false;
            this.cd.detectChanges();
            if (
                this.dxyFunnelModalAction.actionType === 'confirmation' &&
                !result
            ) {
                return;
            }

            this.funnelStateService.executeFunnelAction(
                this.dxyFunnelModalAction,
            );
        } catch (e) {
            this.loading = false;
            this.cd.detectChanges();
            throw e;
        }
    }

    constructor(private funnelStateService: FunnelStateService) {}
}
