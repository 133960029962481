import { Injectable } from '@angular/core';
import { BackendApiService } from '@datagalaxy/data-access';
import {
    CreateDataProcessingItemParameter,
    CreateDataProcessingItemResult,
    DeleteDataProcessingItemParameter,
    DeleteDataProcessingItemResult,
    UpdateDataProcessingItemParameter,
    UpdateDataProcessingItemResult,
} from './data-processing';

@Injectable({ providedIn: 'root' })
export class DataProcessingApiService {
    constructor(private backendApiService: BackendApiService) {}

    public async createDataProcessingItem(
        param: CreateDataProcessingItemParameter,
    ) {
        return await this.backendApiService.postPromise(
            'DataProcessing/CreateDataProcessingItem',
            param,
            CreateDataProcessingItemResult,
        );
    }

    public async deleteDataProcessingItem(
        param: DeleteDataProcessingItemParameter,
    ) {
        return await this.backendApiService.postPromise(
            'DataProcessing/DeleteDataProcessingItem',
            param,
            DeleteDataProcessingItemResult,
        );
    }

    public async updateDataProcessingItem(
        param: UpdateDataProcessingItemParameter,
    ) {
        return await this.backendApiService.postPromise(
            'DataProcessing/UpdateDataProcessingItem',
            param,
            UpdateDataProcessingItemResult,
        );
    }
}
