import {
    HierarchyDataDescriptor,
    IDataIdentifier,
    IEntityIdentifier,
    ServerType,
} from '@datagalaxy/dg-object-model';
import { getSpaceIdFromEntityId } from '@datagalaxy/webclient/utils';
import { IWorkspaceIdentifier } from '@datagalaxy/webclient/workspace/domain';

/** concrete IWorkspaceIdentifier, and static utility lib for IWorkspaceIdentifier management */
export class WorkspaceIdentifier implements IWorkspaceIdentifier {
    public static getServerTypeName(spaceIdr: IWorkspaceIdentifier) {
        const serverType = WorkspaceIdentifier.getServerType(spaceIdr);
        return serverType ? ServerType[serverType] : undefined;
    }

    public static getServerType(
        spaceIdr: IWorkspaceIdentifier,
    ): ServerType.Project | undefined {
        return spaceIdr ? ServerType.Project : undefined;
    }

    public static areSame(a: IWorkspaceIdentifier, b: IWorkspaceIdentifier) {
        return (
            (!a && !b) ||
            (a &&
                b &&
                (!a.spaceId && !b.spaceId
                    ? !a.versionId && !b.versionId
                    : a.spaceId === b.spaceId) &&
                ((!a.versionId && !b.versionId) || a.versionId === b.versionId))
        );
    }

    public static toString(spaceIdr: IWorkspaceIdentifier) {
        return spaceIdr
            ? `${spaceIdr.spaceId}v${spaceIdr.versionId ?? ''}`
            : undefined;
    }

    public static fromString(value: string) {
        const splitted = value?.split('v');
        if (splitted?.length != 2) {
            return;
        }
        return new WorkspaceIdentifier(splitted[0], splitted[1] || null);
    }

    public static haveSameProject(
        a: IWorkspaceIdentifier,
        b: IWorkspaceIdentifier,
    ) {
        return a && b && (a.spaceId == b.spaceId || (!a.spaceId && !b.spaceId));
    }

    /** returns true if undefined, or spaceId and versionId are undefined */
    public static isAllSpaces(spaceIdr: IWorkspaceIdentifier) {
        return !spaceIdr?.spaceId;
    }

    /** returns a SpaceIdentifier:
     * - from the given ISpaceIdentifier if it has a spaceId,
     * - otherwise a ClientSpace identifier */
    public static fromOrClientSpace(spaceIdr: IWorkspaceIdentifier) {
        return (
            WorkspaceIdentifier.from(spaceIdr) ?? new WorkspaceIdentifier(null)
        );
    }

    /** returns a SpaceIdentifier from the given ISpaceIdentifier if it has a spaceId, otherwise null.
     * @param spaceIdr
     * @param defaultToClientSpace When true and the given spaceIdr is null, undefined or has no spaceId,
     * returns a SpaceIdentifier with null spaceId and versionId (thus targeting the ClientSpace), otherwise returns null. */
    public static from(
        spaceIdr: IWorkspaceIdentifier,
        defaultToClientSpace = false,
    ) {
        return !spaceIdr?.spaceId
            ? defaultToClientSpace
                ? new WorkspaceIdentifier(null)
                : null
            : new WorkspaceIdentifier(spaceIdr.spaceId, spaceIdr.versionId);
    }

    /** returns a SpaceIdentifier from the given IDataIdentifier */
    public static fromDataIdentifier(
        dataIdentifier?: IDataIdentifier,
        versionId?: string,
    ): IWorkspaceIdentifier | null {
        const spaceId = getSpaceIdFromEntityId(dataIdentifier?.DataReferenceId);
        return dataIdentifier
            ? new WorkspaceIdentifier(spaceId, versionId)
            : null;
    }

    /** returns a SpaceIdentifier from the given HierarchyDataDescriptor */
    public static fromHdd(
        hdd?: HierarchyDataDescriptor,
    ): IWorkspaceIdentifier | null {
        const spaceId = getSpaceIdFromEntityId(hdd?.DataReferenceId);
        return hdd ? new WorkspaceIdentifier(spaceId, hdd.VersionId) : null;
    }

    /** returns a SpaceIdentifier from the given entity by:
     * - extracting the spaceId from the ReferenceId,
     * - copying the versionId
     * @param entityIdr
     * @param defaultToClientSpace
     * When true and the given entityIdr is null or undefined, returns a SpaceIdentifier
     * with null spaceId and versionId (thus targeting the ClientSpace), otherwise returns undefined. */
    public static fromEntity(
        entityIdr: IEntityIdentifier,
        defaultToClientSpace = false,
    ) {
        return entityIdr
            ? new WorkspaceIdentifier(
                  getSpaceIdFromEntityId(entityIdr.ReferenceId),
                  entityIdr.VersionId,
              )
            : defaultToClientSpace
              ? new WorkspaceIdentifier(null)
              : undefined;
    }

    /** will be considered :
     * - the client space if no or null spaceId is given,
     * - an organization if no or null versionId is given,
     * - a project otherwise */
    constructor(
        public readonly spaceId?: string | null,
        public readonly versionId?: string | null,
    ) {
        if (!this.spaceId) {
            this.spaceId = this.versionId = null;
        }
    }
}
