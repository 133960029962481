<div class="toast-color-bar" [ngClass]="colorClass"></div>

<div
    *ngIf="hasTimerBar"
    class="toast-timer-color-bar"
    [style.height]="timerBarProgress + '%'"
></div>

<div
    *ngIf="notifTitle"
    class="toast-title"
    [class]=""
    [attr.aria-label]="notifTitle"
>
    {{ notifTitle }}
</div>
<div
    *ngIf="notifMessage"
    class="toast-message"
    [class]=""
    [attr.aria-label]="notifMessage"
    [innerHTML]="notifMessage"
></div>
<div *ngIf="hasActionBtn" class="buttons-wrapper">
    <button
        *ngFor="let btn of actionsBtn"
        dxyButton
        [variant]="getActionBtnVariant(btn)"
        (click)="btn?.callback()"
    >
        <span *ngIf="btn.glyphClass" [ngClass]="btn.glyphClass"></span>
        <span>{{ btn.text }}</span>
    </button>
</div>
<button *ngIf="showCloseBtn" dxyIconButton variant="tertiary" class="close-btn">
    <i class="glyph-cancelsearch"></i>
</button>
