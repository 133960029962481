import { DomUtil, rad2deg, TCSSStylePropertyKeys } from '@datagalaxy/core-util';
import { IRoundRect, IShift, IXYRectRO, IXYRO, TDomElement } from './2d.types';
import { Rect } from './Rect';
import { Vect2 } from './Vect2';
import { AngleDeg, AngleRad, Percentage } from './UnitValue';

/** ## Role
 * Low-level 2-dimension DOM element manipulation utility */
export class Dom2dUtil {
    /** sets top and left style properties if useStyle is true, else x and y attributes */
    public static setLocation<E extends TDomElement>(
        el: E,
        x: number,
        y?: number,
        useStyle?: boolean,
        truncate?: boolean,
    ) {
        if (!el) {
            return;
        }
        if (useStyle) {
            Dom2dUtil.stylePx(el, 'left', x, truncate);
            Dom2dUtil.stylePx(el, 'top', y, truncate);
        } else {
            Dom2dUtil.setNumberAttr(el, 'x', x, truncate);
            Dom2dUtil.setNumberAttr(el, 'y', y, truncate);
        }
        return el;
    }

    /** sets width and height style properties if useStyle is true, else width and height attributes */
    public static setSize<E extends TDomElement>(
        el: E,
        width: number,
        height?: number,
        useStyle?: boolean,
        truncate?: boolean,
    ) {
        if (!el) {
            return;
        }
        if (useStyle) {
            Dom2dUtil.stylePx(el, 'width', width, truncate);
            Dom2dUtil.stylePx(el, 'height', height, truncate);
        } else {
            Dom2dUtil.setNumberAttr(el, 'width', width, truncate);
            Dom2dUtil.setNumberAttr(el, 'height', height, truncate);
        }
        return el;
    }
    /** sets x, y (or left & top), width, height, and rx, ry if defined (rx, ry are corner radii) */
    public static setBounds<E extends TDomElement>(
        el: E,
        r: IRoundRect,
        useStyle?: boolean,
        truncate?: boolean,
    ) {
        if (!el || !r) {
            return;
        }
        if (useStyle) {
            Dom2dUtil.stylePx(el, 'left', r.x, truncate);
            Dom2dUtil.stylePx(el, 'top', r.y, truncate);
            Dom2dUtil.stylePx(el, 'width', r.width, truncate);
            Dom2dUtil.stylePx(el, 'height', r.height, truncate);
        } else {
            Dom2dUtil.setNumberAttr(el, 'x', r.x, truncate);
            Dom2dUtil.setNumberAttr(el, 'y', r.y, truncate);
            Dom2dUtil.setNumberAttr(el, 'width', r.width, truncate);
            Dom2dUtil.setNumberAttr(el, 'height', r.height, truncate);
        }
        if (r.rx != null || r.ry != null) {
            if (useStyle) {
                el.style.borderRadius = `${
                    Dom2dUtil.px(r.rx, truncate) ?? ''
                } ${Dom2dUtil.px(r.ry, truncate) ?? ''}`;
            } else {
                Dom2dUtil.setNumberAttr(el, 'rx', r.rx, truncate);
                Dom2dUtil.setNumberAttr(el, 'ry', r.ry, truncate);
            }
        }
        return el;
    }

    public static getRect<T extends TDomElement>(n: T, opt?: IGetRectOptions) {
        const isSVGGraphics = n instanceof SVGGraphicsElement;
        const r =
            isSVGGraphics && !opt?.useClientRect
                ? n.getBBox()
                : n.getBoundingClientRect();
        if (
            opt?.applyLocalTranslation &&
            (isSVGGraphics || n instanceof HTMLElement)
        ) {
            const t = isSVGGraphics
                ? Dom2dUtil.getSVGElementTransformTranslation(n)
                : Dom2dUtil.getHTMLElementTransformTranslation(n);
            if (t) {
                Rect.transform(
                    r,
                    (x) => x + t.x,
                    (y) => y + t.y,
                    r,
                );
            }
        }
        return opt?.noDecimals ? Rect.truncateDecimals(r) : r;
    }

    public static getSVGElementTransformTranslation(
        element: SVGGraphicsElement,
    ) {
        const transform = element?.transform?.baseVal.consolidate();
        const m = transform && transform.matrix;
        return m ? { x: m.e, y: m.f } : { x: 0, y: 0 };
    }

    public static getHTMLElementTransformTranslation(element: HTMLElement) {
        const style = window.getComputedStyle(element);
        const matrix = new DOMMatrixReadOnly(style.transform);
        return { x: matrix.m41, y: matrix.m42 };
    }

    //#region - SVG

    public static parseViewBox(vb: TViewbox) {
        if (typeof vb == 'string') {
            return vb;
        }
        if (Array.isArray(vb)) {
            return vb.toString();
        }
        if (vb.width != undefined && vb.height != undefined) {
            if (vb.x != undefined || vb.y != undefined) {
                return Rect.asArray(vb).toString();
            }
            return Dom2dUtil.getCenteredViewBox(vb.width, vb.height);
        }
    }

    public static getCenteredViewBox(w: number, h: number) {
        return [-w / 2, -h / 2, w, h].toString();
    }

    public static autoViewBox(el: SVGGraphicsElement) {
        document.body.appendChild(el);
        const { x, y, width, height } = el.getBBox();
        document.body.removeChild(el);
        return [x, y, width, height].toString();
    }

    /** Given 2 points, returns the rotation transform to apply
     * for a text displayed at mid distance of those points to be readable from left to right */
    public static getSVGRotationTransformLTR(
        p: IXYRO,
        q: IXYRO,
        precision = 6,
    ) {
        const r = Vect2.getRotationLTR(p, q);
        return `rotate(${rad2deg(r.a).toPrecision(
            precision,
        )},${r.c.x.toPrecision(precision)},${r.c.y.toPrecision(precision)})`;
    }

    /** given an array of points, returns the value for the d atttribute of a path svg element */
    public static pointsPathD(
        points: IXYRO[],
        spec: IPointsPathOptions,
    ): string {
        const { shift, roundDecimals, truncateDecimals, close } = spec,
            dx = shift?.dx || 0,
            dy = shift?.dy || 0;
        const round = roundDecimals
            ? Math.round
            : truncateDecimals
              ? (n: number) => n | 0
              : (n: number) => n;
        return (
            points
                .map(
                    (p, i) =>
                        `${i ? 'L' : 'M'}${round(p.x + dx)} ${round(p.y + dy)}`,
                )
                .join(' ') + (close ? ' z' : '')
        );
    }

    /** returns an SVGMarkerElement created from the given spec */
    public static makeMarker(spec: IMarkerDef | IMarkerSpec) {
        if (!spec) {
            return;
        }
        const marker = DomUtil.createSvgElement('marker');
        const id = (spec as IMarkerDef).id;
        if (id) {
            marker.setAttribute('id', id);
        }
        marker.setAttribute('viewBox', Dom2dUtil.parseViewBox(spec.viewBox));
        marker.setAttribute('refX', spec.refX.toString());
        marker.setAttribute('refY', spec.refY.toString());
        marker.setAttribute('markerWidth', spec.markerWidth.toString());
        marker.setAttribute('markerHeight', spec.markerHeight.toString());
        if (spec.class) {
            marker.setAttribute('class', spec.class);
        }
        if (spec.orient) {
            marker.setAttribute('orient', spec.orient.toString());
        }
        if (spec.markerUnits) {
            marker.setAttribute('markerUnits', spec.markerUnits);
        }
        return marker;
    }

    //#endregion - SVG

    private static stylePx(
        el: TDomElement,
        key: TCSSStylePropertyKeys,
        n: number,
        truncate?: boolean,
    ) {
        if (isNaN(n ?? NaN)) {
            return;
        }
        el.style[key] = `${truncate ? n | 0 : n || 0}px`;
    }
    public static setNumberAttr(
        el: TDomElement,
        key: string,
        n: number,
        truncate?: boolean,
    ) {
        if (isNaN(n ?? NaN)) {
            return;
        }
        el.setAttribute(key, (truncate ? n | 0 : n || 0).toString());
    }
    private static px(n: number, truncate?: boolean) {
        return isNaN(n ?? NaN) ? undefined : `${truncate ? n | 0 : n || 0}px`;
    }
}

export interface ITopLeftWidthHeight {
    top?: number;
    left?: number;
    width?: number;
    height?: number;
}

export interface IGetRectOptions {
    /** When true, element coordinates are obtained using *getBoundingClientRect*.
     * When false and the element is an SVGGraphicsElement instance, getBBox is used instead. */
    useClientRect?: boolean;
    /** When true and an element has a translation transform, it is applied to the computed coordinates */
    applyLocalTranslation?: boolean;
    /** When true, coordinates are truncated to integers */
    noDecimals?: boolean;
}

export interface IPointsPathOptions {
    shift?: IShift;
    roundDecimals?: boolean;
    truncateDecimals?: boolean;
    close?: boolean;
}

export type TViewbox = string | [number, number, number, number] | IXYRectRO;

/** Definition of an SVG [marker](https://developer.mozilla.org/en-US/docs/Web/SVG/Element/marker) element */
export interface IMarkerDef extends IMarkerSpec {
    id: string;
}

/** Parameters for an SVG [marker](https://developer.mozilla.org/en-US/docs/Web/SVG/Element/marker) element */
export interface IMarkerSpec {
    /** https://developer.mozilla.org/en-US/docs/Web/SVG/Attribute/viewBox */
    viewBox: TViewbox;
    /** https://developer.mozilla.org/en-US/docs/Web/SVG/Attribute/refX */
    refX: TMarkerRefValue;
    /** https://developer.mozilla.org/en-US/docs/Web/SVG/Attribute/refY */
    refY: TMarkerRefValue;
    /** https://developer.mozilla.org/en-US/docs/Web/API/SVGMarkerElement/markerWidth */
    markerWidth: TMarkerSizeValue;
    /** https://developer.mozilla.org/en-US/docs/Web/API/SVGMarkerElement/markerHeight */
    markerHeight: TMarkerSizeValue;
    /** https://developer.mozilla.org/en-US/docs/Web/SVG/Attribute/orient */
    orient?: TMarkerOrient;
    /** css class name applied to the marker */
    class?: string;
    /** defaults to 'stroke-width' */
    markerUnits?: 'userSpaceOnUse' | 'stroke-width';
}
export type TMarkerOrient =
    | number
    | AngleRad
    | AngleDeg
    | 'auto-start-reverse'
    | 'auto';
export type TMarkerRefValue = number | Percentage | 'left' | 'center' | 'right';
export type TMarkerSizeValue = number | Percentage;
