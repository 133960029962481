import { deserialize, deserializeAs, SerializableEnumeration } from 'cerialize';
import { AttributeMetaType } from './attribute-meta-type';
import {
    EntityLinkTypeKind,
    EntityType,
    FirstClassType,
    ObjectLinkType,
} from '@datagalaxy/dg-object-model';
import { AttributeMetaValue } from './attribute-meta-value';
import { TimeSeriesColorRule, TimeSeriesFrequency } from './time-serie';

export enum AttributeCategory {
    none = 0,
    Common = 1,
    GovernanceUser = 2,
    Gdpr = 3,
    Link = 4,
}
SerializableEnumeration(AttributeCategory);

export enum HierarchyKind {
    None,
    System,
    User,
    Hybrid,
}

SerializableEnumeration(HierarchyKind);

export enum ComputedPropertyAttributeComputationType {
    /**
     * Non classified Computation such as ABaseData.EntityType
     */
    Other,

    /**
     * Usually used for Links Aggregation Counting
     */
    Relational,

    /**
     * Usually used for having a direct attribute value from a linked object (e.g. TableElement.TableDisplayName)
     */
    DirectValue,

    /**
     * Used for a direct object Reference such as PrimaryKey.PkTableRef
     */
    DataReference,

    /**
     * Used for Shortcut Attributes
     */
    LinkShortcut,

    /**
     * Link Directly stored in Elastic Search (all ObjectLinks_xxxxx attributes)
     */
    EsLink,

    /**
     * Raw Text extraction from the associated SourceAttribute Rich Text content
     */
    RawText,
}
SerializableEnumeration(ComputedPropertyAttributeComputationType);

/**
 * This class exists in order to fine tune the applicability of an Attribute of type EntityLinkShortcut
 * It allows to define if some Shortcut attributes are available or not on specific screens
 */
export class LinkShortcutRestrictionRule {
    @deserializeAs(EntityLinkTypeKind) public Kind!: EntityLinkTypeKind;
    @deserializeAs(ObjectLinkType)
    public UniversalObjectLinkType!: ObjectLinkType;
    @deserialize public AllowedSourceTypes!: EntityType[];
    @deserialize public AllowedTargetTypes!: EntityType[];

    public static OnDeserialized(
        instance: LinkShortcutRestrictionRule,
        json: any,
    ) {
        LinkShortcutRestrictionRule.init(instance, json);
    }

    private static init(instance: LinkShortcutRestrictionRule, json: any) {
        if (instance.AllowedSourceTypes?.length)
            instance.AllowedSourceTypes = json.AllowedSourceTypes.map(
                (c: keyof typeof EntityType) => EntityType[c],
            );

        if (instance.AllowedTargetTypes?.length)
            instance.AllowedTargetTypes = json.AllowedTargetTypes.map(
                (c: keyof typeof EntityType) => EntityType[c],
            );
    }
}

export class AttributeCommonDto {
    /**
     * ObservabilityAttributes have been added in 2.39. They are all included in the "AllTypes" module
     * for sake of simplicity. However, the specific type rules below allow to restrain the FirstClassTypes
     * and EntityTypes on which each attribute is to be included.
     */
    @deserialize public IsObservabilityAttribute!: boolean;
    /** WARNING: CDP attributes with a different DataTypeName may have the same AttributeKey.
     * One may use AttributePath for unicity */
    @deserialize public AttributeKey!: string;
    /**
     * - for a non cdp: same as AttributeKey
     * - for a cdp : `.{AttributeKey}` if *IsAllTypes*, otherwise `{FirstClassType}.{AttributeKey}`
     */
    @deserialize public AttributePath?: string;
    @deserializeAs(AttributeCategory)
    public AttributeCategory!: AttributeCategory;
    @deserialize public DisplayName!: string;
    @deserializeAs(AttributeMetaType) public AttributeType?: AttributeMetaType;
    @deserialize public IsMandatory!: boolean;
    /**
     * Provided by the server only for CDPs.
     *
     * Important: Avoid using this for storing client-side data,
     * as an AttributeMetaInfo can be used in different contexts at the same time.
     * This may be OK though with dynamic-created, locally-used AttributeMetaInfos,
     * like when using AttributeDataService.createDynamicAttribute()
     */
    @deserializeAs(AttributeMetaValue) public ListValues!: AttributeMetaValue[];
    //#Archi-moduleName (fbo) ServerType string, here . Only first class name + 'AllTypes' ?
    @deserialize public ModuleName!: string;
    // This flag is used only with tags
    @deserialize public IsActive!: boolean;
    @deserializeAs(HierarchyKind) public HierarchyKind!: HierarchyKind;
    /* This marks a Non-User Visible field such as: Screen.IsSystemLayoutOverridden */
    @deserialize public IsSystemTechnicalField!: boolean;
    @deserialize public IsCdp!: boolean;
    @deserialize public Description!: string;
    @deserialize public ExcludedEntityTypes!: EntityType[];
    @deserialize public IsMultiValue!: boolean;
    @deserialize public EntityLinkTypeKinds: EntityLinkTypeKind[] = [];
    @deserializeAs(ObjectLinkType) public ObjectLinkType!: ObjectLinkType;
    @deserializeAs(ComputedPropertyAttributeComputationType)
    public ComputationType?: ComputedPropertyAttributeComputationType;
    @deserializeAs(TimeSeriesFrequency)
    public TimeSeriesFrequency?: TimeSeriesFrequency;
    @deserializeAs(TimeSeriesColorRule)
    public TimeSeriesColorRule?: TimeSeriesColorRule;
    @deserializeAs(LinkShortcutRestrictionRule)
    public ShortcutRestrictionRules!: LinkShortcutRestrictionRule[];
    /**
     * Observability attributes (IsObservabilityAttribute = true) Specific Type Rules
     *  */
    @deserialize
    public ObservabilityAttributeIncludedFirstClassTypes!: FirstClassType[];
    @deserialize
    public ObservabilityAttributeIncludedEntityTypes!: EntityType[];
    /**
     * Specifies if the attribute can be used for filtering
     * If true it means that we can build a Filter with its attributeKey
     */
    @deserialize public IsFilteringEnabled!: boolean;
    @deserialize public DefaultValue: any;

    public isTranslated?: boolean;
    public translatedDisplayName?: string;

    public static OnDeserialized(instance: AttributeCommonDto, json: any) {
        this.init(instance, json);
    }

    protected static init(instance: AttributeCommonDto, json: any) {
        if (json.ExcludedEntityTypes?.length)
            instance.ExcludedEntityTypes = json.ExcludedEntityTypes.map(
                (c: keyof typeof EntityType) => EntityType[c],
            );

        if (json.ObservabilityAttributeIncludedFirstClassTypes?.length)
            instance.ObservabilityAttributeIncludedFirstClassTypes =
                json.ObservabilityAttributeIncludedFirstClassTypes.map(
                    (c: keyof typeof FirstClassType) => FirstClassType[c],
                );

        if (json.ObservabilityAttributeIncludedEntityTypes?.length)
            instance.ObservabilityAttributeIncludedEntityTypes =
                json.ObservabilityAttributeIncludedEntityTypes.map(
                    (c: keyof typeof EntityType) => EntityType[c],
                );

        instance.EntityLinkTypeKinds = json.EntityLinkTypeKinds?.map(
            (c: keyof typeof EntityLinkTypeKind) => EntityLinkTypeKind[c],
        );
    }

    public get IsSystem() {
        return !this.IsCdp;
    }
}
