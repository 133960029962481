import { inject, Pipe, PipeTransform } from '@angular/core';
import { WorkspaceRouterService } from './workspace-router.service';
import { IWorkspaceIdentifier } from '@datagalaxy/webclient/workspace/domain';

@Pipe({
    standalone: true,
    name: 'workspaceUrl',
})
export class WorkspaceUrlPipe implements PipeTransform {
    private workspaceRouter = inject(WorkspaceRouterService);

    transform(
        remainingPath: string | any[],
        workspaceIdentifier: IWorkspaceIdentifier,
    ): any[] {
        const path =
            this.workspaceRouter.getWorkspaceUrlCommands(workspaceIdentifier);
        const remaining = Array.isArray(remainingPath)
            ? remainingPath
            : remainingPath.split('/');

        return [...path, ...remaining];
    }
}
