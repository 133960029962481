import {
    ChangeDetectionStrategy,
    Component,
    input,
    OnChanges,
    output,
    SimpleChanges,
} from '@angular/core';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { WorkspaceStore } from '@datagalaxy/webclient/workspace/data-access';
import {
    IWorkspace,
    WorkspaceIconComponent,
    WorkspaceMenuListComponent,
} from '@datagalaxy/webclient/workspace/ui';
import { MatLegacyMenuModule } from '@angular/material/legacy-menu';
import {
    WorkspaceAdapterPipe,
    WorkspaceListAdapterPipe,
} from '../workspace-adapter.pipe';
import { WorkspaceDropdownConfig } from './workspace-dropdown.types';
import { Workspace } from '@datagalaxy/webclient/workspace/domain';
import { DxyDataTestIdDirective } from '@datagalaxy/ui/testing';
import { DxyIconButtonDirective } from '@datagalaxy/ui/buttons';
import { ClientRouterService } from '@datagalaxy/webclient/client/feature';

@Component({
    standalone: true,
    selector: 'dxy-workspace-dropdown',
    templateUrl: './workspace-dropdown.component.html',
    styleUrls: ['./workspace-dropdown.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        MatLegacyMenuModule,
        WorkspaceIconComponent,
        WorkspaceAdapterPipe,
        WorkspaceMenuListComponent,
        WorkspaceListAdapterPipe,
        DxyDataTestIdDirective,
        DxyIconButtonDirective,
    ],
})
export class WorkspaceDropdownComponent
    extends DxyBaseComponent
    implements OnChanges
{
    workspaceId = input<string>();
    options = input.required<Workspace[]>();
    config = input<WorkspaceDropdownConfig>();

    /** Emitted when a menu is opened or closed. The argument is true on open. */
    openClose = output<boolean>();
    selectWorkspace = output<Workspace>();

    protected selectedSpace: Workspace | undefined;

    protected get isMenuVisible() {
        return this.spaceSelectable;
    }

    protected get spaceSelectable() {
        return !this.config()?.readonly && this.options()?.length >= 1;
    }

    constructor(
        private workspaceStore: WorkspaceStore,
        private clientRouter: ClientRouterService,
    ) {
        super();
    }

    ngOnChanges(changes: SimpleChanges) {
        super.onChanges(
            changes,
            ['workspaceId', 'options'],
            () => {
                // double equals is intentional here to catch null and undefined for allSpaces
                this.selectedSpace = this.options()?.find(
                    (space) => space.ReferenceId == this.workspaceId(),
                );
            },
            true,
        );
    }

    protected onDisplayNameClickInternal(event: Event) {
        if (!this.config()?.displaySelectedWorkspaceAsLink) {
            return;
        }
        event.stopPropagation();

        if (this.selectedSpace) {
            this.selectWorkspace.emit(this.selectedSpace);
        }
    }

    protected onSpaceClick(workspace: IWorkspace) {
        const navSpace = this.options().find(
            (option) => option.spaceId === workspace.id,
        );

        if (!navSpace) {
            return;
        }

        this.selectWorkspace.emit(navSpace);
    }

    protected async onStarClick(workspace: IWorkspace) {
        const navSpace = this.options().find(
            (option) => option.spaceId === workspace.id,
        );

        if (!navSpace) {
            return;
        }

        this.workspaceStore.updateDefaultSpace(
            navSpace,
            !navSpace.IsDefaultSpace,
        );
    }

    protected async goToClientSpacesList() {
        return this.clientRouter.goToWorkspaceList();
    }
}
