import { computed } from '@angular/core';
import { AttributeMetaInfo } from '@datagalaxy/webclient/attribute/domain';
import {
    patchState,
    signalStoreFeature,
    withComputed,
    withMethods,
    withState,
} from '@ngrx/signals';

interface AttributeMetaInfoState {
    attributeMetaInfo: AttributeMetaInfo | undefined;
}

export function withAttributeMetaInfo() {
    return signalStoreFeature(
        withState<AttributeMetaInfoState>({
            attributeMetaInfo: undefined,
        }),
        withComputed(({ attributeMetaInfo }) => ({
            labelText: computed(
                () =>
                    attributeMetaInfo()?.translatedDisplayName ??
                    attributeMetaInfo()?.DisplayName ??
                    '',
            ),
            description: computed(() => attributeMetaInfo()?.Description ?? ''),
            mandatory: computed(
                () => attributeMetaInfo()?.IsMandatory ?? false,
            ),
            readonlyAttribute: computed(
                () => attributeMetaInfo()?.IsReadOnly ?? false,
            ),
        })),
        withMethods((store) => ({
            setAttributeMetaInfo(attributeMetaInfo: AttributeMetaInfo) {
                patchState(store, { attributeMetaInfo });
            },
        })),
    );
}
