import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    HostListener,
    Input,
    OnDestroy,
} from '@angular/core';
import { CoreUtil, DomUtil, ICssFontProperties } from '@datagalaxy/core-util';
import { TranslateService } from '@ngx-translate/core';
import { BaseCollectionCellComponent } from './BaseCollectionCellComponent';
import {
    ICollectionCellConfig,
    ICollectionCellOption,
    ICollectionCellParams,
} from './collection-cell.types';
import { ListOptionUtil } from '../../IListOption';
import {
    DxyRichTooltipDirective,
    EllipsisTooltipDirective,
} from '@datagalaxy/ui/tooltip';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';
import { DxyRendererComponent } from '../../components/renderer/renderer.component';
import { NgFor, NgIf, SlicePipe } from '@angular/common';

/**
 * ## Role
 * Cell component for a collection of objects. This collection is ellipsis if not enough space
 *
 * ## Features
 * - Display maximum number of elements and a "more count" indicator if necessary
 * - Can determine parent width if containerWidth input is null
 * - Can manage space between items with "spacing" input
 * - Can display a shortened label per item if not enough space for a full label
 */
@Component({
    selector: 'dxy-collection-cell',
    templateUrl: 'collection-cell.component.html',
    styleUrls: ['collection-cell.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgIf,
        DxyRichTooltipDirective,
        NgFor,
        DxyRendererComponent,
        MatLegacyTooltipModule,
        EllipsisTooltipDirective,
        SlicePipe,
    ],
})
export class DxyCollectionCellComponent
    extends BaseCollectionCellComponent<unknown, unknown, ICollectionCellParams>
    implements OnDestroy
{
    // #region static
    /** Maybe changed by app by calling DxyCollectionCellComponent.setConfig */
    private static defaultConfig: ICollectionCellConfig = {
        moreCountLetterWidth: 6,
        labelTextFontSize: '11px',
        labelPadding: 4,
        defaultMoreCountSize: 24,
        minimumMoreCountSize: 10,
        minMonoLabelTextWidth: 40,
        minMultiLabelTextWidth: 50,
        defaultMaxLine: 1,
        defaultLineHeight: 20,
        tooltipTitleHeight: 30,
    };

    private static config = DxyCollectionCellComponent.defaultConfig;

    /** Set component configuration. To be called by app to change default value if needed */
    public static setConfig(config: ICollectionCellConfig) {
        DxyCollectionCellComponent.config = {
            ...DxyCollectionCellComponent.defaultConfig,
            ...config,
        };
    }

    public get config() {
        return DxyCollectionCellComponent.config;
    }
    //#endregion

    @Input() options?: ICollectionCellOption[];
    @Input() maxLines = 1;
    @Input() maxItems?: number;
    @Input() itemSize = 20;
    @Input() spacing = 4;
    /**
     * Replace collection display with text when there is not enough space
     */
    @Input() ellipsisReplaceText?: string;
    @Input() tooltipTitle?: string;
    /** Disable items & more count tooltip */
    @Input() disableTooltip?: boolean;
    /** Disable more count tooltip only*/
    @Input() disableMoreCountTooltip?: boolean;
    @Input() enablePopover?: boolean;
    @Input() openPopoverFn?: (el: HTMLElement) => void;
    /** Display a unique tooltip on collection mouse hover */
    @Input() uniqueTooltip = false;
    @Input() lineHeight = 20;
    /** Hide all options text label */
    @Input() hideLabel = false;
    /** Hide all options text label if ellipsed */
    @Input() hideEllipsedLabel = false;
    @Input() moreCountAsOption = false;
    @Input() alignRight = false;

    public displayedItems: ICollectionItem[];
    public ellipsisText?: string;
    public maxHeight?: string;
    public itemsPerLine = 1;
    public maxMoreCountOptions = 0;

    public get maxWidthString() {
        return `${this.fullWidth ?? 70}px`;
    }
    public get spacingString() {
        return `${this.spacing}px`;
    }
    public get moreCountSizeString() {
        return `${this.moreCountSize ?? 20}px`;
    }
    public get lineHeightString() {
        return `${this.lineHeight}px`;
    }
    public get el() {
        return this.elementRef.nativeElement;
    }
    public get labelsHidden() {
        return this.hideLabel || this.hideLabelToGetSpace;
    }
    public get uniqueTooltipEnabled() {
        return (
            this.uniqueTooltip &&
            (this.options?.length > 1 || this.tooltipTitle?.length > 0)
        );
    }
    public get showSeparateTooltip() {
        return !this.uniqueTooltipEnabled && !this.disableTooltip;
    }
    public get showUniqueTooltip() {
        return this.uniqueTooltipEnabled && !this.disableTooltip;
    }
    protected get showMoreCountTooltip() {
        return this.showSeparateTooltip && !this.disableMoreCountTooltip;
    }

    private moreCountSize =
        DxyCollectionCellComponent.config.defaultMoreCountSize;
    private fullWidth?: number;
    private hideLabelToGetSpace = false;
    private availableLabelWidthPerItem?: number;
    private itemTextCssFontProperties?: ICssFontProperties;

    constructor(
        private elementRef: ElementRef<HTMLElement>,
        private translate: TranslateService,
    ) {
        super();
    }

    ngOnDestroy() {
        super.ngOnDestroy();
        this.destroyTooltip();
    }

    protected override updateBindings() {
        super.updateBindings();
        this.options = [];
        CoreUtil.assign(this, this.params.inputs);
        this.maxLines ??= this.config.defaultMaxLine;
        this.lineHeight ??= this.config.defaultLineHeight;
    }

    public override updateLayout() {
        super.updateLayout();
        this.fullWidth = this.getFullWidth();
        this.ellipsisText = undefined;
        this.maxHeight = `${this.maxLines * this.lineHeight}px`;
        this.itemsPerLine = this.getMaxItemsPerLine();
        this.itemTextCssFontProperties = {
            ...DomUtil.getCssFontProperties(this.el),
            fontSize: this.config.labelTextFontSize,
        };

        if (!this.options) {
            this.displayedItems = [];
            return;
        }
        this.displayedItems = this.options.map((o) =>
            this.makeCollectionItem(o),
        );

        const maxDisplayableItems = this.getMaxDisplayableItems();

        if (maxDisplayableItems < this.displayedItems.length) {
            this.setupEllipsis();
        }
        this.updateItemLabelsVisibility();
    }

    /** Add tooltip items into DOM only at mouseenter*/
    public ajustTooltipItems() {
        this.maxMoreCountOptions = 0;
        const rect = this.el.getBoundingClientRect();
        const top = rect.bottom;
        const viewHeight = window.innerHeight;
        let maxHeight = viewHeight - top - 10;
        if (this.tooltipTitle) {
            maxHeight -= this.config.tooltipTitleHeight;
        }
        if (maxHeight < 0) {
            return;
        }
        this.maxMoreCountOptions = Math.floor(
            maxHeight / (this.lineHeight + 10),
        );
        if (this.maxMoreCountOptions < 1) {
            return;
        }
        if (this.maxMoreCountOptions < this.options.length) {
            this.maxMoreCountOptions--;
        }
    }
    /** remove tooltip items from DOM at mouseleave */
    public destroyTooltip() {
        this.maxMoreCountOptions = 0;
    }

    public getText(item: ICollectionItem) {
        return ListOptionUtil.getText(item, this.translate);
    }

    /** Setup ellipsis with ellipsisReplaceText or more items count */
    private setupEllipsis() {
        this.moreCountSize = this.moreCountAsOption
            ? this.itemSize + this.spacing
            : this.config.defaultMoreCountSize;
        if (this.ellipsisReplaceText && !this.moreCountAsOption) {
            this.moreCountSize =
                this.config.minimumMoreCountSize +
                this.ellipsisReplaceText.length *
                    this.config.moreCountLetterWidth;
            this.ellipsisText = this.ellipsisReplaceText;
        }
        this.removeEllipsedItems();
        if (this.ellipsisReplaceText && !this.moreCountAsOption) {
            return;
        }
        if (!this.moreCountAsOption) {
            this.ajustEllipsisWidth();
        }
        this.ellipsisText = `+${Math.min(
            99,
            this.options.length - this.displayedItems.length,
        )}`;
    }

    @HostListener('mouseenter')
    private mouseover() {
        if (!this.enablePopover) {
            return;
        }
        this.openPopoverFn?.(this.elementRef.nativeElement);
    }

    /** Ajust number of ellipsed items with ellipse text length */
    private ajustEllipsisWidth() {
        const count = this.options.length - this.displayedItems.length;
        if (count >= 10) {
            this.moreCountSize += this.config.moreCountLetterWidth;
        }
        this.removeEllipsedItems();
    }

    private removeEllipsedItems() {
        const displayableItemsCount = this.getMaxDisplayableItems(true);
        this.displayedItems.splice(displayableItemsCount);
    }

    private getMaxDisplayableItems(withMoreCount = false) {
        let count = this.itemsPerLine * (this.maxLines - 1);
        count += this.getMaxItemsPerLine(withMoreCount);
        return count;
    }

    private getMaxItemsPerLine(withMoreCount = false) {
        const availableWidth =
            this.fullWidth -
            this.itemSize -
            (withMoreCount ? this.moreCountSize : 0);
        if (availableWidth < 0) {
            return 0;
        }
        let count = 1; // We can at least display 1 item
        count += Math.floor(availableWidth / (this.itemSize + this.spacing));
        return count;
    }

    /** Remove item labels if not enough space */
    private updateItemLabelsVisibility() {
        if (this.displayedItems?.length < this.options.length) {
            this.hideLabelToGetSpace = true;
            return;
        }
        const nbItems = this.displayedItems.length;
        const availableWidth =
            this.fullWidth -
            this.itemSize -
            (this.itemSize + this.spacing) * (nbItems - 1);
        this.availableLabelWidthPerItem = availableWidth / nbItems;
        if (this.hideEllipsedLabel) {
            this.displayedItems.forEach((item) =>
                this.setNonEllipsedLabelText(item),
            );
            this.hideLabelToGetSpace = this.displayedItems.some(
                (item) => !item.itemText,
            );
            return;
        }
        this.hideLabelToGetSpace =
            this.availableLabelWidthPerItem <
            (nbItems == 1
                ? this.config.minMonoLabelTextWidth
                : this.config.minMultiLabelTextWidth);
    }

    /** Returns collection full width: maxItems width or containerWidth or parent width */
    private getFullWidth() {
        if (this.maxItems) {
            const totalSpacing = this.spacing * (this.maxItems - 1);
            const totalItemsSize = this.itemSize * this.maxItems;
            const moreCountMaxSize =
                this.config.defaultMoreCountSize +
                this.config.moreCountLetterWidth;
            return totalItemsSize + totalSpacing + moreCountMaxSize;
        }
        return this.containerWidth ?? this.el.parentElement?.offsetWidth ?? 60;
    }

    /** Set label to display. Could be a short label if not enough space  */
    private setNonEllipsedLabelText(item: ICollectionItem) {
        item.itemText = '';
        const text = ListOptionUtil.getText(item, this.translate);
        const textWidth = this.getLabelTextWidth(text);
        if (textWidth <= this.availableLabelWidthPerItem) {
            item.itemText = text;
            return;
        }
        if (!item.shortLabelText) {
            return;
        }
        const shortTextWidth = this.getLabelTextWidth(item.shortLabelText);
        if (shortTextWidth <= this.availableLabelWidthPerItem) {
            item.itemText = item.shortLabelText;
            return;
        }
    }

    private getLabelTextWidth(text: string) {
        const textWidth = DomUtil.getTextWidth(
            text,
            this.itemTextCssFontProperties,
        );
        return (
            textWidth +
            this.config.labelPadding +
            (this.spacing < 0 ? this.config.labelPadding : 0)
        );
    }

    private makeCollectionItem(option: ICollectionCellOption): ICollectionItem {
        const labelText = ListOptionUtil.getText(option, this.translate);
        return {
            ...option,
            tooltipText: labelText,
            itemText: labelText,
        };
    }
}

interface ICollectionItem extends ICollectionCellOption {
    tooltipText: string;
    itemText?: string;
}
