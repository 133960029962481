<div class="modal-header">
    <h3
        mat-dialog-title
        class="modal-title"
        translate="UI.Diagrams.publishModal.title"
    ></h3>
    <button dxyIconButton color="transparent" (click)="onCloseCancel()">
        <i class="glyph-cancelsearch"></i>
    </button>
</div>

<form (ngSubmit)="publishDiagram()">
    <div mat-dialog-content [class.modal-disable]="loading$ | async">
        {{ 'UI.Diagrams.publishModal.warningMessage' | translate }}
    </div>
    <dxy-modal-footer
        [actionEnabled]="actionEnabled$ | async"
        [actionBtnLbl]="'UI.Global.btnPublish' | translate"
        [actionLoading]="loading$ | async"
        [noMandatory]="true"
        (onCloseCancel)="onCloseCancel()"
    ></dxy-modal-footer>
</form>
