import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';
import {
    BackendApiService,
    BaseServiceParameter,
    isUnsuccessfulApiError,
} from '@datagalaxy/data-access';
import { ExportDataParameter, ExportDataResult } from './export';
import {
    GetImportAttributesParameter,
    GetImportAttributesResult,
    ImportDataParameter,
    ImportDataResult,
} from './import';

@Injectable({ providedIn: 'root' })
export class DataPortApiService {
    constructor(private backendApiService: BackendApiService) {}

    public async getImportAttributes(parameter: GetImportAttributesParameter) {
        return await this.backendApiService.postPromise(
            'Data/GetImportAttributes',
            parameter,
            GetImportAttributesResult,
        );
    }

    public async preImportData(
        parameter: ImportDataParameter,
        zippedContent: Blob,
    ) {
        return await this.performImportData(
            parameter,
            zippedContent,
            'Data/PreImportCachedData',
            'Data/PreImportDataMultipart',
        );
    }

    public async importData(
        parameter: ImportDataParameter,
        zippedContent: Blob,
    ) {
        return await this.performImportData(
            parameter,
            zippedContent,
            'Data/ImportCachedData',
            'Data/ImportDataMultipart',
        );
    }

    private async performImportData(
        parameter: ImportDataParameter,
        zippedContent: Blob,
        cachedDataEndpoint: string,
        multiPartEnpoint: string,
    ) {
        let endpoint: string;
        let data: any;
        if (parameter.ContentRequestId) {
            endpoint = cachedDataEndpoint;
            data = parameter;
        } else {
            endpoint = multiPartEnpoint;
            data = await this.prepareMultipartImportApiPostData(
                parameter,
                zippedContent,
            );
        }

        try {
            return await this.backendApiService.postPromise(
                endpoint,
                data,
                ImportDataResult,
                {
                    skipSerialization: true,
                },
            );
        } catch (error) {
            if (isUnsuccessfulApiError<ImportDataResult>(error)) {
                return error.error;
            }
            throw error;
        }
    }

    public async exportData(edp: ExportDataParameter) {
        const result = await this.backendApiService.postPromise(
            'Data/ExportData',
            edp,
            ExportDataResult,
        );
        this.downloadBase64File(
            result.Filename,
            result.ZipFileContent,
            'application/x-zip-compressed',
        );
    }

    private downloadBase64File(
        filename: string,
        fileContent: string,
        fileMimeType: string,
    ) {
        const binary = atob(fileContent.replace(/\s/g, ''));
        const view = new Uint8Array(new ArrayBuffer(binary.length));
        for (let i = 0, l = binary.length; i < l; i++) {
            view[i] = binary.charCodeAt(i);
        }
        saveAs(new Blob([view], { type: fileMimeType }), filename);
    }

    private async prepareMultipartImportApiPostData(
        apiParameter: BaseServiceParameter,
        zippedContent: Blob,
    ) {
        if (!(apiParameter instanceof ImportDataParameter)) {
            throw new Error(
                'Do not use prepareMultipartImportPostDataAndConfig for other calls than ImportData',
            );
        }

        const importParameter = apiParameter as ImportDataParameter;
        const formData = new FormData();
        formData.append('parameter', JSON.stringify(importParameter));
        formData.append('importZippedContent', zippedContent);

        return formData;
    }
}
