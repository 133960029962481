import { Injectable } from '@angular/core';
import { WorkflowBriefDto } from './workflow';
import { getLocalId } from '@datagalaxy/utils';
import { WorkflowApiService } from './workflow-api.service';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class WorkflowService {
    constructor(private workflowApiService: WorkflowApiService) {}

    public getWorkflows(spaceId: string): Observable<WorkflowBriefDto[]> {
        const localSpaceId = getLocalId(spaceId);
        if (!localSpaceId) {
            throw new Error('Missing spaceId');
        }
        return this.workflowApiService.getWorkflows(localSpaceId);
    }
}
