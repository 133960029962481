import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    HostListener,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import {
    MatLegacyInput as MatInput,
    MatLegacyInputModule,
} from '@angular/material/legacy-input';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { KeyboardUtil } from '@datagalaxy/utils';
import { FormsModule } from '@angular/forms';
import { NgIf } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { DxyIconButtonDirective } from '@datagalaxy/ui/buttons';

@Component({
    standalone: true,
    selector: 'dxy-search-input',
    templateUrl: 'search-input.component.html',
    styleUrls: ['search-input.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        MatLegacyInputModule,
        FormsModule,
        NgIf,
        TranslateModule,
        DxyIconButtonDirective,
    ],
})
export class SearchInputComponent
    extends DxyBaseComponent
    implements OnInit, AfterViewInit
{
    @Input() searchString?: string;
    @Input() placeholderKey?: string;
    @Input() showCancelSearch?: boolean;
    /** When true, prevents the control to take focus when initialized */
    @Input() noFocus?: boolean;
    /** When true, stops propagation of the keydown event, except for *escape* and *enter* */
    @Input() captureKeyboard?: boolean;

    /** debounced */
    // #archi-naming (revi) rename all on prefixed event emitter
    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
    @Output() readonly onSearchString = new EventEmitter<ISearchTermEvent>();

    /** For searchString bi-directional binding */
    @Output() public readonly searchStringChange = new Subject<
        string | undefined
    >();

    public get isClearSearchVisible() {
        return this.showCancelSearch && !!this.searchString;
    }
    public get focused() {
        return this.input?.focused;
    }

    private isFirstSearch = true;

    @ViewChild('matInputRef') private input?: MatInput;

    constructor() {
        super();
    }

    ngOnInit() {
        this.placeholderKey ??= 'CoreUI.Global.SearchOoo';
        this.searchStringChange
            .pipe(debounceTime(444), distinctUntilChanged())
            .subscribe((searchString) => {
                this.onSearchString.emit({
                    searchString,
                    isFirstSearch: this.isFirstSearch,
                });
            });
    }
    ngAfterViewInit() {
        !this.noFocus && setTimeout(() => this.focusInput());
    }

    //#region API
    public focusInput() {
        this.input?.focus();
    }
    public clear(event: Event) {
        event.stopPropagation();
        this.searchString = '';
        this.onSearch();
    }
    //#endregion

    public onSearchBarClick(event: Event) {
        event.stopPropagation();
    }

    public onSearch(searchString?: string) {
        this.searchString = searchString;
        this.searchStringChange.next(searchString);
        this.isFirstSearch = false;
    }

    @HostListener('keydown', ['$event'])
    private keydown(event: KeyboardEvent) {
        if (
            this.captureKeyboard &&
            this.focused &&
            !KeyboardUtil.isEscapeKey(event) &&
            !KeyboardUtil.isEnterKey(event)
        ) {
            event.stopPropagation();
        }
    }
}

export interface ISearchTermEvent {
    searchString: string | undefined;
    isFirstSearch?: boolean;
}
