<div class="modal-header">
    <h3 mat-dialog-title class="modal-title" translate>
        UI.Glossary.components.generateFromSourceModal.title
    </h3>
    <button dxyIconButton color="transparent" (click)="onCloseCancel()">
        <i class="glyph-cancelsearch"></i>
    </button>
</div>

<!-- Help Infos -->
<ng-container *ngIf="showHelp">
    <div mat-dialog-content class="help-info">
        <img [src]="helpImage" />
        <h2 class="help-title" translate>
            UI.Glossary.components.generateFromSourceModal.helpInfos.subTitle
        </h2>
        <p class="help-description" translate>
            UI.Glossary.components.generateFromSourceModal.helpInfos.description
        </p>
        <div *ngIf="showOnlineHelp">
            <button dxyButton variant="tertiary" size="medium">
                <i class="glyph-help"></i>
                <span translate
                    >UI.Glossary.components.generateFromSourceModal.helpInfos.onlineHelp</span
                >
            </button>
        </div>
    </div>
    <div class="modal-footer dxy-actions">
        <mat-checkbox class="skip-step" [(ngModel)]="skipThisStep">
            <span translate
                >UI.Glossary.components.generateFromSourceModal.helpInfos.skipStep</span
            >
        </mat-checkbox>
        <button
            dxyButton
            variant="secondary"
            type="button"
            (click)="onCloseCancel()"
        >
            <span translate>UI.Global.btnCancel</span>
        </button>
        <button dxyButton type="submit" (click)="goNextStep()">
            <span translate>UI.Global.btnNext</span>
        </button>
    </div>
</ng-container>
<!-- End Help Infos -->

<form *ngIf="!showHelp" [formGroup]="formGroup" (ngSubmit)="onSubmit()">
    <div mat-dialog-content>
        <div class="source-module">
            <img [src]="selectedModuleImage" />
            <div class="dxy-actions">
                <button
                    *ngFor="let module of modules"
                    dxyButton
                    variant="secondary"
                    type="button"
                    [class.active]="module === selectedModule"
                    (click)="selectModuleType(module)"
                    [disabled]="isModuleDisabled(module)"
                >
                    <span [translate]="module.labelKey"></span>
                </button>
            </div>
        </div>
        <dxy-space-version-selector
            *ngIf="showSpaceSelector"
            class="no-shadow no-padding"
            [hasLabel]="true"
            [spaceIdr]="spaceIdr"
            (onSpaceVersionSelected)="onSpaceVersionSelected($event)"
        ></dxy-space-version-selector>
        <div
            *ngIf="showSourceError"
            class="mat-error"
            [translate]="sourcesErrorMessage"
        ></div>
        <div *ngIf="showWriteAccessError" class="mat-error" translate>
            UI.Glossary.components.generateFromSourceModal.form.space.writeAccessError
        </div>
        <dxy-entity-selector-field
            *ngIf="showSourceSelector"
            formControlName="sources"
            [labelKey]="getFormTranslateKey('source.title')"
            [options]="sourceOptions"
            [hint]="sourceHint"
            [menuTakeFullWidth]="true"
            [isMultiValue]="true"
            [mandatory]="true"
            [openPreviewOnSelectedItemClick]="true"
            (ngModelChange)="onSourcesChange($event)"
            (openClose)="onPanelOpenClose($event)"
        ></dxy-entity-selector-field>
        <div *ngIf="showSourceLimitWarning" class="limit-warning">
            <i class="glyph-warning"></i>
            <span
                translate
                [translateParams]="{
                    countLimit: sourceCountLimit | numberFormat,
                }"
            >
                UI.Glossary.components.generateFromSourceModal.form.sourceLimitWarning
            </span>
        </div>
        <dxy-field-radio
            *ngIf="hasSelectedSources"
            formControlName="useExistingUniverse"
            [labelKey]="getFormTranslateKey('targetExisting.title')"
            [hint]="getFormTranslateKey('targetExisting.hint') | translate"
            [hintBeforeControl]="true"
            [mandatory]="true"
            [options]="targetExistingOptions"
            (ngModelChange)="onTargetTypeChange()"
        ></dxy-field-radio>
        <dxy-entity-selector-field
            *ngIf="showUniverseSelect"
            formControlName="targetUniverse"
            [labelKey]="getFormTranslateKey('target.title')"
            [options]="targetOptions"
            [menuTakeFullWidth]="true"
            [mandatory]="true"
            (openClose)="onPanelOpenClose($event)"
        ></dxy-entity-selector-field>
        <dxy-field-text
            *ngIf="showUniverseInput"
            formControlName="targetUniverse"
            [labelKey]="getFormTranslateKey('target.title')"
            [hint]="getFormTranslateKey('target.newTargetHint') | translate"
            [hintBeforeControl]="true"
            [mandatory]="true"
            [errorMessageText]="universeAlreadyExistsErrorMessage"
        ></dxy-field-text>
    </div>
    <div class="modal-footer dxy-actions">
        <div class="mandatory-field">
            <span translate>UI.EntityCreateModal.mandatoryField</span>
        </div>
        <button
            dxyButton
            variant="secondary"
            type="button"
            (click)="goPreviousStep()"
        >
            <span translate>UI.Global.btnPrevious</span>
        </button>
        <button
            dxyButton
            type="submit"
            [disabled]="!formGroup.valid || loadingValue"
            dxyLogFunctional="GENERATE_GLOSSARY,C"
        >
            <span translate
                >UI.Glossary.components.generateFromSourceModal.form.generate</span
            >
        </button>
    </div>
</form>
