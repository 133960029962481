import { Injectable } from '@angular/core';
import { BaseService } from '@datagalaxy/core-ui';
import { Subject, filter, map } from 'rxjs';
import {
    EntityApiService,
    WatchEntityParameter,
} from '@datagalaxy/webclient/entity/data-access';

/**
 * ## Role
 * Save entity watching value and notify the change
 */
@Injectable({ providedIn: 'root' })
export class WatchEntityApiService extends BaseService {
    private watchEntityUpdate = new Subject<IWatchEntityUpdate>();

    constructor(private entityApiService: EntityApiService) {
        super();
    }

    public async watchEntity(entityReferenceId: string) {
        const parameters = new WatchEntityParameter(entityReferenceId);
        await this.entityApiService.watchEntity(parameters);
        this.notifyUpdate(entityReferenceId, true);
    }

    public async unwatchEntity(entityReferenceId: string) {
        const parameters = new WatchEntityParameter(entityReferenceId);
        await this.entityApiService.unwatchEntity(parameters);
        this.notifyUpdate(entityReferenceId, false);
    }

    public getWatchUpdateObservable(entityReferenceId: string) {
        return this.watchEntityUpdate.pipe(
            filter((update) => update.entityReferenceId == entityReferenceId),
            map((update) => update.watching),
        );
    }

    private notifyUpdate(entityReferenceId: string, watching: boolean) {
        this.watchEntityUpdate.next({
            entityReferenceId,
            watching: watching,
        });
    }
}

interface IWatchEntityUpdate {
    entityReferenceId: string;
    watching: boolean;
}
