import { deserialize, inheritSerialization } from 'cerialize';
import { BaseDTO } from '@datagalaxy/dg-object-model';

@inheritSerialization(BaseDTO)
export class AttributeTagDTO extends BaseDTO {
    @deserialize public TagId!: string;
    @deserialize public DisplayName!: string;
    @deserialize public Description!: string;
    @deserialize public IsActive!: boolean;
    @deserialize public Color!: string;
    @deserialize public IsSystem!: boolean;
    @deserialize public HasSystemKeywords!: boolean;
    @deserialize public InternalName!: string;
    @deserialize public UserKeywordsInput!: string;
    @deserialize public IsUserSuggestionEnabled!: boolean;
    @deserialize public ParentTagId!: string;
    @deserialize public ActivateChildTags!: boolean;

    // Used for Attribute Tags only
    @deserialize public AttributeKey!: string;
    @deserialize public AttributeId!: string;

    // Client-Side Only:
    public localOrder?: number;
    public parentValue?: AttributeTagDTO;
    children?: AttributeTagDTO[] = [];

    public get id() {
        return this.TagId;
    }

    public getParents(): AttributeTagDTO[] {
        const localParents = [];
        let start = <AttributeTagDTO>this;
        while (start.parentValue) {
            localParents.push(start.parentValue);
            start = start.parentValue;
        }

        return localParents;
    }

    public get filterSourceString(): string {
        if (!this.children || this.children.length == 0) {
            return this.DisplayName.toLowerCase();
        }

        let sourceString = this.DisplayName;
        this.children.forEach((c) => {
            sourceString = sourceString.concat(`_${c.filterSourceString}`);
        });

        return sourceString;
    }
}
