import { NgZone } from '@angular/core';
import { CollectionsHelper } from '@datagalaxy/core-util';
import { ZoneUtils } from '@datagalaxy/utils';

/** ## Role
 * Debounce/throttle the update of multiple items at once */
export class ItemsUpdateDebouncer<TItem, TUpdateData = void> {
    private readonly items: TItem[] = [];
    private timer: number;

    constructor(
        private readonly p: IItemsUpdateDebouncerParams<TItem, TUpdateData>,
    ) {}

    public update(items: TItem[], prepareData?: TUpdateData) {
        this.cancel();
        this.p.prepare?.(items, prepareData);
        CollectionsHelper.appendIfNotExist(this.items, items);
        this.timer = ZoneUtils.zoneTimeout(
            async () => {
                const itemsToPersist = this.items.slice();
                this.items.length = 0;
                await this.p.persist(itemsToPersist);
            },
            this.p.delayMs,
            this.p.ngZone,
            this.p.outsideAngular,
        );
    }

    public cancel() {
        window.clearTimeout(this.timer);
        this.timer = undefined;
    }
}

interface IItemsUpdateDebouncerParams<TItem, TUpdateData> {
    /** the function to debounce (e.g. the one that calls the server to persist the items) */
    persist: (items: TItem[]) => Promise<void>;
    /** minimum delay between 2 *persist* execution, in milliseconds */
    delayMs?: number;
    /** called on each *update*, for to prepare/update items before they are sent to the server */
    prepare?: (items: TItem[], prepareData: TUpdateData) => void;
    /** needed if *outsideAngular* is true */
    ngZone?: NgZone;
    /** when true and *ngZone* is defined, *persist* execution will not trigger change detection */
    outsideAngular?: boolean;
}
