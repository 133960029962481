import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnChanges,
    SimpleChanges,
} from '@angular/core';
import { StringUtil } from '@datagalaxy/core-util';
import { BaseCellComponent } from '../../cell-components/BaseCellComponent';
import { ITrendPillData, TrendPillColorRule } from './trend-pill.types';
import { NgClass, NgIf } from '@angular/common';

/**
 * ## Role
 * Pill showing if a trend evolve good or bad
 */
@Component({
    standalone: true,
    selector: 'dxy-trend-pill',
    templateUrl: 'trend-pill.component.html',
    styleUrls: ['trend-pill.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [NgClass, NgIf],
})
export class DxyTrendPillComponent<TEntity = unknown>
    extends BaseCellComponent<TEntity, ITrendPillData>
    implements OnChanges
{
    @Input() trend?: number;
    @Input() colorRule?: TrendPillColorRule;

    public evolutionColorClass?: string;
    public arrowRotationClass?: string;
    public evolutionDisplayedValue?: string;

    public get hasIncreased() {
        return this.trend >= 1;
    }
    public get hasDecreased() {
        return this.trend <= -1;
    }
    public get hasNotChanged() {
        return Math.abs(this.trend ?? 0) < 1;
    }

    public get visible() {
        return !this.noTrend || this.colorRule != undefined;
    }

    private get noTrend() {
        return this.trend == undefined;
    }

    constructor() {
        super();
    }

    ngOnChanges(changes: SimpleChanges) {
        super.onChanges(
            changes,
            ['trend', 'colorRule'],
            () => this.updateBindings(),
            true,
        );
    }

    protected override updateBindings() {
        if (this.params) {
            this.trend = this.value?.trend;
            this.colorRule = this.value?.colorRule;
        }
        if (!this.visible) {
            return;
        }
        this.evolutionColorClass = this.getEvolutionColorClass();
        this.arrowRotationClass = this.getArrowRotationClass();
        this.evolutionDisplayedValue = this.getEvolutionDisplayedValue();
    }

    private getEvolutionColorClass() {
        if (this.isNeutralEvolution()) {
            return 'neutral-evolution';
        }
        if (this.isGoodEvolution()) {
            return 'good-evolution';
        }
        if (this.isBadEvolution()) {
            return 'bad-evolution';
        }
    }

    private getArrowRotationClass() {
        if (this.noTrend) {
            return this.getNoTrendArrowRotationClass();
        }
        if (this.hasIncreased) {
            return 'up-arrow';
        }
        if (this.hasNotChanged) {
            return 'right-arrow';
        }
        return '';
    }
    private getNoTrendArrowRotationClass() {
        if (this.colorRule == TrendPillColorRule.shouldIncrease) {
            return 'up-arrow';
        }
        if (this.colorRule == TrendPillColorRule.none) {
            return 'right-arrow';
        }
        return '';
    }

    private isGoodEvolution() {
        return (
            (this.hasIncreased &&
                this.colorRule == TrendPillColorRule.shouldIncrease) ||
            (this.hasDecreased &&
                this.colorRule == TrendPillColorRule.shouldDecrease)
        );
    }

    private isBadEvolution() {
        return (
            (this.hasDecreased &&
                this.colorRule == TrendPillColorRule.shouldIncrease) ||
            (this.hasIncreased &&
                this.colorRule == TrendPillColorRule.shouldDecrease)
        );
    }

    private isNeutralEvolution() {
        return this.colorRule == TrendPillColorRule.none || this.hasNotChanged;
    }

    private getEvolutionDisplayedValue() {
        if (this.trend == 0) {
            return '0 %';
        }
        if (!this.trend) {
            return undefined;
        }
        if (!Number.isFinite(this.trend)) {
            return '';
        }
        if (
            this.trend === Number.MAX_VALUE ||
            this.trend === -Number.MAX_VALUE
        ) {
            return '';
        }
        const signString = this.trend >= 0 ? '+ ' : '- ';
        // Round absolute value of trend
        const rawValue = Math.abs(this.trend);
        const trend = StringUtil.formatNumber(rawValue);
        return `${signString}${trend} %`;
    }
}
