import * as moment from 'moment';
import { unitOfTime } from 'moment';
import {
    GraphWidgetHelper,
    ITimeValue,
} from '../widgets-dashboard/widgets/insight/GraphWidgetHelper';
import {
    TimeSeriesFrequency,
    TimeSeriesObject,
} from '@datagalaxy/webclient/attribute/domain';

export class TimeSeriesGraphHelper {
    public static drawTimeSeriesGraph(
        timeSeriesObject: TimeSeriesObject,
        timeSeriesFrequency: TimeSeriesFrequency,
        elementContainer: HTMLElement,
        limit?: number,
    ) {
        if (!timeSeriesObject?.Entries?.length) {
            return;
        }

        let values = timeSeriesObject.Entries;

        if (limit) {
            const valuesLength = timeSeriesObject.Entries.length;
            if (limit < valuesLength) {
                values = timeSeriesObject.Entries.slice(valuesLength - limit);
            }
        }

        const startDate = moment(values[0].Date);
        const endDate = moment(values[values.length - 1].Date);

        GraphWidgetHelper.drawDotTimeLineGraph({
            data: [
                {
                    key: 'timeSeries',
                    points: values.map((entry) => ({
                        date: moment(entry.Date),
                        value: entry.Value,
                    })),
                },
            ],
            startDate,
            endDate,
            translateDate: (d: Date) => moment(d).format('D MMM YYYY'),
            elementContainer,
            customDateTicks: this.buildCustomDateTicks(startDate, endDate),
            margins: { top: 5, bottom: 30, left: 40, right: 30 },
            drawDashArrayFn: (pointA: ITimeValue, pointB: ITimeValue) => {
                let frequency: unitOfTime.Diff;
                switch (timeSeriesFrequency) {
                    case TimeSeriesFrequency.Daily:
                        frequency = 'day';
                        break;
                    case TimeSeriesFrequency.Weekly:
                        frequency = 'week';
                        break;
                    case TimeSeriesFrequency.Monthly:
                        frequency = 'month';
                        break;
                    default:
                        return false;
                }
                return pointB.date.diff(pointA.date, frequency) > 1;
            },
        });
    }

    private static buildCustomDateTicks(
        startDate: moment.Moment,
        endDate: moment.Moment,
    ) {
        const ticks = [startDate];
        const diffDays = endDate.diff(startDate, 'days');

        if (diffDays > 2) {
            ticks.push(moment(startDate).add(diffDays / 3, 'days'));
            ticks.push(moment(startDate).add((diffDays / 3) * 2, 'days'));
        }
        return [...ticks, endDate];
    }
}
