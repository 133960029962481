import {
    CampaignEndedEvent,
    UserAssigneePhaseChangedEvent,
    CampaignEntitiesRemovedEvent,
    CampaignCreatedEvent,
    CampaignPhaseChangedEvent,
    CampaignUpdatedEvent,
    CampaignEntitiesAddedEvent,
    ICampaignIdentifier,
} from '@datagalaxy/dg-object-model';
import { registerTypedEventTranslationAdapter } from '../../dg-event-translation-adapter-registry';
import { IDgEventTranslationAdapter } from '../../../interfaces/IDgEventTranslationAdapter';
import {
    IDgEventNavLink,
    IEventTranslationInputs,
} from '../../../interfaces/IEventTranslationInputs';
import { getUserEventTranslationInputs } from '../common/dg-event-translation-adapter-helpers';
import { DgEventTranslationAdapterBase } from '../common/dg-event-translation-adapter-base';
import { resolveBaseRelativeUrl } from '../common/dg-event-translation-nav-links.helpers';

const eventWithEntities = [
    CampaignEndedEvent,
    CampaignEntitiesAddedEvent,
    CampaignEntitiesRemovedEvent,
    UserAssigneePhaseChangedEvent,
];

const eventTypes = [
    ...eventWithEntities,
    CampaignCreatedEvent,
    CampaignPhaseChangedEvent,
    CampaignUpdatedEvent,
];
type TCampaignEvent = InstanceType<(typeof eventTypes)[number]>;

/**
 * ## Role
 * Adapter to translate all campaign events
 */
@registerTypedEventTranslationAdapter(eventTypes)
export class CampaignEventTranslationAdapter
    extends DgEventTranslationAdapterBase
    implements IDgEventTranslationAdapter
{
    constructor(private event: TCampaignEvent) {
        super(event);
    }

    getTranslationInputs(): IEventTranslationInputs {
        const inputs = getUserEventTranslationInputs(this.event);
        inputs.translateParameters.push({
            key: 'campaignName',
            value: this.event.CampaignName,
        });
        inputs.navLinks = this.getNavLinks();

        if ('PhaseName' in this.event) {
            inputs.translateParameters.push({
                key: 'phaseName',
                value: this.event.PhaseName as string,
            });
        }

        this.addObjectsParams(inputs);
        return inputs;
    }

    private getNavLinks() {
        const navLinks = [this.getCampaignNavLink()];
        return navLinks;
    }

    private addObjectsParams(inputs: IEventTranslationInputs) {
        if (!eventWithEntities.some((t) => this.event instanceof t)) {
            return;
        }

        const referenceIds =
            this.event instanceof CampaignEntitiesAddedEvent
                ? this.event.AddedEntitiesReferenceIds
                : this.event instanceof CampaignEntitiesRemovedEvent
                  ? this.event.RemovedEntitiesReferenceIds
                  : this.event instanceof UserAssigneePhaseChangedEvent
                    ? this.event.ReferenceIds
                    : [];

        inputs.translateParameters.push({
            key: 'objectsCount',
            value: referenceIds?.length ?? 0,
        });

        if (!referenceIds?.length) {
            return;
        }
        inputs.navLinks.push({
            linkCssClass: 'nav-link-show-more',
            parameters: this.event,
        });
    }

    private getCampaignNavLink(): IDgEventNavLink {
        const {
            SpaceGuid: workSpaceGuid,
            VersionId: versionGuid,
            CampaignGuid: campaignGuid,
            CampaignName: campaignName,
        } = this.event;
        const baseUrl = resolveBaseRelativeUrl(
            this.event,
            workSpaceGuid,
            versionGuid,
        );
        return {
            linkCssClass: 'nav-link-campaign',
            relativeUrl: `${baseUrl}campaigns/${campaignGuid}`,
            parameters: {
                Guid: campaignGuid,
                Name: campaignName,
                SpaceGuid: workSpaceGuid,
                VersionId: versionGuid,
            } as ICampaignIdentifier,
        };
    }
}
