import { DxyBaseModalComponent } from '@datagalaxy/ui/dialog';
import { Component, Inject } from '@angular/core';
import {
    MAT_DIALOG_DATA,
    MatDialogModule,
    MatDialogRef,
} from '@angular/material/dialog';
import { EnumNumberFieldSelectAdapter } from '@datagalaxy/core-ui';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { TeamService } from '../team.service';
import {
    CreateTeamResult,
    TeamAccessType,
} from '@datagalaxy/webclient/team/data-access';
import {
    CrudOperation,
    FunctionalLogService,
} from '@datagalaxy/shared/monitoring/data-access';
import { DxyModalFooterComponent } from '../../shared/dialogs/dxy-modal-footer/dxy-modal-footer.component';
import { DxyRichTextFieldComponent } from '@datagalaxy/core-ui/rich-text';
import { DxyFieldSelectComponent } from '@datagalaxy/core-ui/fields';
import { DxyFieldTextComponent } from '@datagalaxy/ui/fields';
import { FormsModule } from '@angular/forms';
import { DxyIconButtonDirective } from '@datagalaxy/ui/buttons';

@Component({
    selector: 'app-team-create-modal',
    templateUrl: 'team-create-modal.component.html',
    standalone: true,
    imports: [
        MatDialogModule,
        TranslateModule,
        DxyIconButtonDirective,
        FormsModule,
        DxyFieldTextComponent,
        DxyFieldSelectComponent,
        DxyRichTextFieldComponent,
        DxyModalFooterComponent,
    ],
})
export class TeamCreateModalComponent extends DxyBaseModalComponent<
    void,
    CreateTeamResult
> {
    public teamName: string;
    public teamDescription: string;
    public teamAccessType: TeamAccessType;

    public readonly teamAccessAdapter = new EnumNumberFieldSelectAdapter(
        TeamAccessType,
        {
            getTextKey: (t) =>
                `UI.Teams.CreateModal.${TeamAccessType[t]}Access`,
            getSubTextKey: (t) =>
                `UI.Teams.CreateModal.${TeamAccessType[t]}AccessHint`,
            getGlyphClass: (t) => this.teamService.getTeamAccessIcon(t),
            onSelectionChange: (t) => {
                this.teamAccessType = t;
                this.updateCanCreate();
            },
        },
    );
    public canCreate = false;
    public isLoading = false;
    public teamNameErrorMessage = '';

    constructor(
        dialogRef: MatDialogRef<TeamCreateModalComponent>,
        @Inject(MAT_DIALOG_DATA) data: void,
        private translate: TranslateService,
        private teamService: TeamService,
        private functionalLogService: FunctionalLogService,
    ) {
        super(dialogRef, data);
    }

    private updateCanCreate() {
        this.canCreate = !!this.teamName && this.teamAccessType != undefined;
    }

    public async onNameUpdate() {
        const result = await this.teamService.preCreateTeam(this.teamName);
        if (result.IsTeamNameUsed) {
            this.canCreate = false;
            this.teamNameErrorMessage = this.translate.instant(
                'UI.Teams.CreateModal.nameUserError',
            );
            return;
        }
        this.teamNameErrorMessage = '';
        this.updateCanCreate();
    }

    public async onCloseSubmit() {
        this.isLoading = true;
        this.result = await this.teamService.createTeam(
            this.teamName,
            this.teamAccessType,
            this.teamDescription,
        );
        this.isLoading = false;
        super.onCloseSubmit();
    }

    public onCloseCancel() {
        this.functionalLogService.logFunctionalAction(
            'CANCEL_CREATE_TEAM',
            CrudOperation.R,
        );
        super.onCloseCancel();
    }
}
