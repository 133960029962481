<dxy-spinner *ngIf="loading" class="no-margin"></dxy-spinner>
<button
    *ngIf="!loading"
    dxyIconButton
    variant="tertiary"
    size="small"
    (click)="onToggle($event)"
>
    <dxy-icon
        [icon]="expanded ? 'glyph-arrow-drop-down' : 'glyph-arrow-drop-right'"
    ></dxy-icon>
</button>
