import { Inject, Injectable } from '@angular/core';
import {
    getBaseObjectRouteUrl,
    RestApiService,
    V3_REST_API_SERVICE_TOKEN,
} from '@datagalaxy/data-access';
import { IEntityIdentifier } from '@datagalaxy/dg-object-model';
import { AiGeneratedString } from '@datagalaxy/webclient/suggestion/domain';

@Injectable({ providedIn: 'root' })
export class AutoDescriptionApiService {
    constructor(
        @Inject(V3_REST_API_SERVICE_TOKEN)
        private restApiService: RestApiService,
    ) {}

    public getDescriptionSuggestion(entityIdr: IEntityIdentifier) {
        return this.restApiService.postPromise<AiGeneratedString>(
            `${getBaseObjectRouteUrl(entityIdr)}/attributes/description/propose-value`,
        );
    }

    public getAcceptedDescriptionSuggestion(entityIdr: IEntityIdentifier) {
        return this.restApiService.getPromise<AiGeneratedString>(
            `${getBaseObjectRouteUrl(entityIdr)}/attributes/description/accepted-suggestion`,
        );
    }

    public acceptDescriptionSuggestion(
        entityIdr: IEntityIdentifier,
        suggestion: AiGeneratedString,
    ) {
        return this.restApiService.postPromise<AiGeneratedString>(
            `${getBaseObjectRouteUrl(entityIdr)}/attributes/description/accepted-suggestion`,
            suggestion,
        );
    }

    public getSummarySuggestion(entityIdr: IEntityIdentifier) {
        return this.restApiService.postPromise<AiGeneratedString>(
            `${getBaseObjectRouteUrl(entityIdr)}/attributes/summary/propose-value`,
        );
    }

    public getAcceptedSummarySuggestion(entityIdr: IEntityIdentifier) {
        return this.restApiService.getPromise<AiGeneratedString>(
            `${getBaseObjectRouteUrl(entityIdr)}/attributes/summary/accepted-suggestion`,
        );
    }

    public acceptSummarySuggestion(
        entityIdr: IEntityIdentifier,
        suggestion: AiGeneratedString,
    ) {
        return this.restApiService.postPromise<AiGeneratedString>(
            `${getBaseObjectRouteUrl(entityIdr)}/attributes/summary/accepted-suggestion`,
            suggestion,
        );
    }
}
