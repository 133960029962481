import * as moment from 'moment';
import { BaseService } from '@datagalaxy/core-ui';
import { RichTextContent } from '@datagalaxy/core-ui/rich-text';
import {
    INewTaskModalResult,
    ITaskFormModalInput,
} from './new-task-modal.types';
import { CollectionsHelper } from '@datagalaxy/core-util';
import { Injectable } from '@angular/core';
import { DxyTaskFormModalComponent } from './dxy-task-form-modal/dxy-task-form-modal.component';
import {
    EntityTaskDTO,
    IEntityIdentifier,
    ObjectTaskStatus,
    ObjectTaskType,
} from '@datagalaxy/dg-object-model';
import { DxyModalService } from '../shared/dialogs/DxyModalService';
import { EntityEventService } from '../shared/entity/services/entity-event.service';
import { AppDataService } from '../services/app-data.service';
import {
    CreateEntityTaskParameter,
    DeleteEntityTasksParameter,
    GetEntityTasksParameter,
    GetUserTasksParameter,
    TaskApiService,
    UpdateEntityTaskParameter,
} from '@datagalaxy/webclient/task/data-access';
import { IWorkspaceIdentifier } from '@datagalaxy/webclient/workspace/domain';
import { Filter } from '@datagalaxy/webclient/filter/domain';
import { IMiniEntityContent } from '@datagalaxy/webclient/entity/domain';
import { ServerConstants } from '@datagalaxy/shared/server/domain';
import { EntityFunctionalLogService } from '@datagalaxy/webclient/entity/data-access';

@Injectable({ providedIn: 'root' })
export class TaskService extends BaseService {
    /** Returns the ordered, non-obsolete task types */
    public static getOrderedTaskTypes() {
        return CollectionsHelper.orderBy(
            CollectionsHelper.getEnumValues<ObjectTaskType>(
                ObjectTaskType,
                ...TaskService.obsoleteObjectTaskTypeValues,
            ),
            (e) => TaskService.orderedObjectTaskTypeValues.indexOf(e),
        );
    }
    /** Returns the ordered, non-obsolete task types, including the current given one even if obsolete.
     * To be used for backward compatibility. */
    public static getOrderedTaskTypesIncludingObsoleteCurrent(
        current: ObjectTaskType,
    ) {
        const taskTypes = TaskService.getOrderedTaskTypes();
        if (current != null && !taskTypes.includes(current)) {
            taskTypes.push(current);
        }
        return taskTypes;
    }
    // NOTE: These values are supported for backward comp, but should
    // not be used on new tasks/task updates
    public static readonly obsoleteObjectTaskTypeValues: ObjectTaskType[] = [
        ObjectTaskType.UpdatePII,
        ObjectTaskType.AddContact,
        ObjectTaskType.AddDescription,
        ObjectTaskType.AddDomain,
        ObjectTaskType.AddPII,
        ObjectTaskType.UpdateDomain,
    ];
    public static readonly orderedObjectTaskTypeValues = [
        ObjectTaskType.AddObject,
        ObjectTaskType.UpdateAttribute,
        ObjectTaskType.UpdateContact,
        ObjectTaskType.MoveObject,
        ObjectTaskType.GrantAccess,
        ObjectTaskType.ValidateChange,
        ObjectTaskType.UpdateDescription,
        ObjectTaskType.Duplicate,
        ObjectTaskType.Question,
        ObjectTaskType.DataQualityReview,
    ];

    private static adaptEntityTaskDto(dto: EntityTaskDTO) {
        dto.RawText = RichTextContent.getRawText(dto.Text);
        dto.HddData.Data.VersionId = dto.HddData.VersionId;
    }

    private static readonly availableStatusMap =
        CollectionsHelper.objArrayToMap(
            CollectionsHelper.getEnumValues<ObjectTaskStatus>(ObjectTaskStatus),
            (status) => status,
            (status) => {
                switch (status) {
                    case ObjectTaskStatus.Requested:
                        return [
                            ObjectTaskStatus.Requested,
                            ObjectTaskStatus.InProgress,
                            ObjectTaskStatus.Executed,
                            ObjectTaskStatus.Refused,
                        ];
                    case ObjectTaskStatus.InProgress:
                        return [
                            ObjectTaskStatus.InProgress,
                            ObjectTaskStatus.Executed,
                            ObjectTaskStatus.Refused,
                        ];
                    case ObjectTaskStatus.Executed:
                        return [ObjectTaskStatus.Executed];
                    case ObjectTaskStatus.Refused:
                        return [ObjectTaskStatus.Refused];
                }
            },
        );

    private get currentUserId() {
        return this.appDataService.currentUserId;
    }

    constructor(
        private taskApiService: TaskApiService,
        private dxyModalService: DxyModalService,
        private entityEventService: EntityEventService,
        private appDataService: AppDataService,
    ) {
        super();
    }

    // Get informations
    public getCreationDate(task: EntityTaskDTO): string {
        return moment(task.CreationTime).fromNow();
    }

    public getDefaultNewTaskAssigneeUserId() {
        return this.currentUserId;
    }

    public async createTask(
        entityIdr: IEntityIdentifier,
        newTaskTitle: string,
        newTaskText: string,
        newTaskAssignedUserId: string,
        newTaskType: ObjectTaskType,
        newTaskDueTime: string,
    ) {
        const createParameter = new CreateEntityTaskParameter(
            entityIdr.ReferenceId,
            entityIdr.ServerType,
            entityIdr.VersionId,
            newTaskTitle,
            newTaskText,
            newTaskAssignedUserId,
            newTaskType,
            newTaskDueTime,
        );

        const result =
            await this.taskApiService.createEntityTask(createParameter);
        return result.CreatedTask;
    }

    public async loadTasks(entityData: IEntityIdentifier) {
        const loadParameter = new GetEntityTasksParameter(
            entityData.ReferenceId,
            entityData.ServerType,
            false,
        );
        loadParameter.VersionId = entityData.VersionId;
        const result = await this.taskApiService.getEntityTasks(loadParameter);
        return result.Tasks;
    }

    public async updateTask(
        entityData: IEntityIdentifier,
        task: EntityTaskDTO,
    ): Promise<EntityTaskDTO> {
        const parameter = new UpdateEntityTaskParameter(
            entityData.ReferenceId,
            entityData.VersionId,
            entityData.ServerType,
            task.ReferenceId,
            task.Title,
            task.Text,
            task.AssignedToUserId,
            task.Status,
            task.Response,
            task.Type,
            task.DueTime,
        );
        const result = await this.taskApiService.updateEntityTask(parameter);
        TaskService.adaptEntityTaskDto(result.UpdatedTask);
        this.entityEventService.notifyTaskUpdate(result.UpdatedTask);
        return result.UpdatedTask;
    }

    public async confirmThenDeleteTask(
        entityData: IEntityIdentifier,
        task: EntityTaskDTO,
    ) {
        const featureCode = EntityFunctionalLogService.getFeatureCode(
            entityData,
            'SOCIAL_TASK',
            'D',
        );
        const confirmed = await this.dxyModalService.confirmDeleteObject(
            ServerConstants.TypeName.ObjectTask,
            { featureCode },
        );
        if (!confirmed) {
            return false;
        }

        const parameter = new DeleteEntityTasksParameter(
            entityData.ReferenceId,
            entityData.VersionId,
            entityData.ServerType,
            task.ReferenceId,
        );
        await this.taskApiService.deleteEntityTasks(parameter);
        this.entityEventService.notifyTaskDelete(parameter);
        return true;
    }

    public async confirmCancelEdit() {
        return await this.dxyModalService.confirmCancelEdit(
            ServerConstants.TypeName.ObjectTask,
        );
    }

    public isCurrentUserTaskOwner(task: EntityTaskDTO) {
        return task?.CreationUserId === this.currentUserId;
    }

    public getAvailableStatusValues(currentStatus?: ObjectTaskStatus) {
        return currentStatus == undefined
            ? CollectionsHelper.getEnumValues<ObjectTaskStatus>(
                  ObjectTaskStatus,
              )
            : TaskService.availableStatusMap.get(currentStatus);
    }

    public isFinalStatus(currentStatus: ObjectTaskStatus) {
        return (
            currentStatus === ObjectTaskStatus.Executed ||
            currentStatus === ObjectTaskStatus.Refused
        );
    }

    public isTitleEditEnabled(
        currentStatus: ObjectTaskStatus,
        task: EntityTaskDTO,
        entityData: IMiniEntityContent,
    ) {
        return (
            (this.isCurrentUserAdmin(entityData) &&
                this.isStatusRequested(currentStatus)) ||
            this.isCurrentUserTaskOwner(task)
        );
    }

    public isTextEditEnabled(
        currentStatus: ObjectTaskStatus,
        task: EntityTaskDTO,
        entityData: IMiniEntityContent,
    ) {
        return (
            (this.isCurrentUserAdmin(entityData) &&
                this.isStatusRequested(currentStatus)) ||
            this.isCurrentUserTaskOwner(task)
        );
    }

    public isCommonFieldEditEnabled(
        task: EntityTaskDTO,
        entityData: IMiniEntityContent,
    ) {
        return (
            this.isCurrentUserAdmin(entityData) ||
            this.isCurrentUserTaskOwner(task) ||
            this.isCurrentUserAssigned(task)
        );
    }

    private isCurrentUserAdmin(entityData: IMiniEntityContent) {
        return (
            entityData &&
            entityData.SecurityData &&
            entityData.SecurityData.HasAdministratorAccess
        );
    }

    private isCurrentUserAssigned(task: EntityTaskDTO) {
        return task?.AssignedToUserId === this.currentUserId;
    }

    private isStatusRequested(currentStatus: ObjectTaskStatus) {
        return currentStatus === ObjectTaskStatus.Requested;
    }

    public isResponseVisible(currentStatus: ObjectTaskStatus) {
        return this.isFinalStatus(currentStatus);
    }

    public getStatusTranslateKey(taskStatus: ObjectTaskStatus) {
        return `DgServerTypes.ObjectTaskStatus.${ObjectTaskStatus[taskStatus]}`;
    }

    public getTypeTranslateKey(taskType: ObjectTaskType) {
        return `DgServerTypes.ObjectTaskType.${ObjectTaskType[taskType]}`;
    }

    public isEditEnabled(task: EntityTaskDTO, entityData: IMiniEntityContent) {
        return !!task && this.isCommonFieldEditEnabled(task, entityData);
    }

    public isDeleteEnabled(
        task: EntityTaskDTO,
        entityData: IMiniEntityContent,
    ) {
        return (
            !!task &&
            (!!entityData?.SecurityData?.HasAdministratorAccess ||
                this.isCurrentUserTaskOwner(task))
        );
    }

    public async openTaskFormModal(
        entityData: IMiniEntityContent,
        targetUserId?: string,
        task?: EntityTaskDTO,
    ) {
        const result = await this.dxyModalService.open<
            DxyTaskFormModalComponent,
            ITaskFormModalInput,
            INewTaskModalResult
        >({
            componentType: DxyTaskFormModalComponent,
            data: { entityData, targetUserId, task },
        });
        if (!task && result?.createdTasks?.length) {
            this.entityEventService.notifyTaskCreation(result.createdTasks[0]);
        }
        return result?.createdTasks?.[0];
    }

    public async getUserTasks(
        spaceIdr: IWorkspaceIdentifier,
        searchTerm: string,
        filters?: Filter[],
    ) {
        const param = new GetUserTasksParameter(
            spaceIdr?.spaceId,
            searchTerm,
            filters,
        );
        param.VersionId = spaceIdr?.spaceId ? spaceIdr?.versionId : '';
        this.log('getUserTasks', param);
        const result = await this.taskApiService.getUserTasks(param);
        result.Tasks.forEach(TaskService.adaptEntityTaskDto);
        this.log('getUserTasks-result', result);
        return result;
    }
}
