import { filter, Subject } from 'rxjs';
import { EntityEvent, EntityEventName } from './entity-event';
import { Injectable } from '@angular/core';

type EntityEventSelector = (event: EntityEvent) => boolean;

@Injectable({ providedIn: 'root' })
export class EntityEventDispatcher {
    private entityEventSubject = new Subject<EntityEvent>();

    public dispatchEvent(event: EntityEvent) {
        this.entityEventSubject.next(event);
    }

    public subscribeToEventsByName(
        eventName: EntityEventName,
        next: (event: EntityEvent) => void,
    ) {
        return this.subscribeToEvents((e) => e.eventName === eventName, next);
    }

    private subscribeToEvents(
        entityEventSelector: EntityEventSelector,
        next: (event: EntityEvent) => void,
    ) {
        return this.entityEventSubject
            .pipe(filter(entityEventSelector))
            .subscribe(next);
    }
}
