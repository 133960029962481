import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Inject,
} from '@angular/core';
import { DxyBaseModalComponent } from '@datagalaxy/ui/dialog';
import {
    ICampaignSelectForm,
    ICampaignSelectFormModalInput,
    ICampaignSelectFormModalOutput,
} from './campaign-select-form-modal.types';
import {
    MAT_DIALOG_DATA,
    MatDialogRef,
    MatDialogModule,
} from '@angular/material/dialog';
import {
    AsyncValidatorFn,
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
    FormsModule,
    ReactiveFormsModule,
} from '@angular/forms';
import { IFieldSelectAdapter, withLoading } from '@datagalaxy/core-ui';
import { from, map, Observable } from 'rxjs';
import {
    CrudOperation,
    FunctionalLogService,
} from '@datagalaxy/shared/monitoring/data-access';
import { CampaignService } from '../campaign.service';
import { CampaignBriefDto } from '@datagalaxy/webclient/campaign/data-access';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { ToasterService } from '../../services/toaster.service';
import { DxyModalFooterComponent } from '../../shared/dialogs/dxy-modal-footer/dxy-modal-footer.component';
import { SpinnerComponent } from '@datagalaxy/ui/spinner';
import { DxyFieldSelectComponent } from '@datagalaxy/core-ui/fields';
import { NgIf, AsyncPipe } from '@angular/common';
import { DxyIconButtonDirective } from '@datagalaxy/ui/buttons';

/**
 * ## Role
 * Display a form to select a campaign
 */
@Component({
    selector: 'app-campaign-select-form-modal',
    templateUrl: './campaign-selection-form-modal.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        MatDialogModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        NgIf,
        DxyFieldSelectComponent,
        SpinnerComponent,
        DxyModalFooterComponent,
        AsyncPipe,
        DxyIconButtonDirective,
    ],
})
export class CampaignSelectFormModalComponent extends DxyBaseModalComponent<
    ICampaignSelectFormModalInput,
    ICampaignSelectFormModalOutput
> {
    protected formGroup: FormGroup<ICampaignSelectForm>;
    protected campaignAdapter: IFieldSelectAdapter<CampaignBriefDto> = {
        getText: (c) => c.Name,
    };

    protected campaigns$: Observable<CampaignBriefDto[]>;

    protected get objectAlreadyInCampaignErrorMessage(): string {
        const campaignSelected = this.formGroup.controls.campaign;
        if (campaignSelected?.valid) {
            return undefined;
        }

        return campaignSelected.errors?.objectAlreadyInCampaign;
    }

    constructor(
        @Inject(MAT_DIALOG_DATA) data: ICampaignSelectFormModalInput,
        dialogRef: MatDialogRef<CampaignSelectFormModalComponent>,
        private fb: FormBuilder,
        private campaignService: CampaignService,
        private functionalLogService: FunctionalLogService,
        private cd: ChangeDetectorRef,
        private translateService: TranslateService,
        private toasterService: ToasterService,
    ) {
        super(dialogRef, data);
        this.campaigns$ = from(
            this.campaignService.getCampaigns(this.data.spaceIdr),
        ).pipe(map((campaigns) => campaigns.filter((c) => !c.Ended)));
        this.buildForm();
    }

    protected async onSubmit() {
        await this.save();
    }

    private buildForm() {
        this.formGroup = this.fb.group<ICampaignSelectForm>({
            campaign: new FormControl(null, {
                validators: [Validators.required],
                asyncValidators: this.campaignSelectedValidator(),
            }),
        });
    }

    @withLoading()
    private async save() {
        const campaignSelected = this.formGroup.value;
        const res = await this.campaignService.addCampaignEntities(
            campaignSelected.campaign.Guid,
            campaignSelected.campaign.VersionId,
            this.data.objectReferenceIds,
        );

        this.displaySuccessToaster();

        this.functionalLogService.logFunctionalAction(
            'ADD_OBJECTS_TO_CAMPAIGN',
            CrudOperation.A,
        );
        this.result = { campaignGuid: res.Guid };

        this.onCloseSubmit();
    }

    private displaySuccessToaster() {
        this.toasterService.infoToast({
            titleKey: 'UI.Campaign.Form.Title.AddToCampaign',
            messageKey: 'UI.Campaign.Form.ObjectAddedSuccess',
            messageParams: { count: this.data.objectReferenceIds.length },
        });
    }

    private async isObjectAlreadyInCampaign(campaignGuid) {
        const detailedCampaign =
            await this.campaignService.getCampaign(campaignGuid);
        return this.data.objectReferenceIds.some((id) =>
            detailedCampaign?.ReferenceIds.includes(id),
        );
    }

    private campaignSelectedValidator(): AsyncValidatorFn {
        return (campaignControl: FormControl<CampaignBriefDto>) => {
            this.cd.detectChanges();

            return from(
                this.isObjectAlreadyInCampaign(campaignControl.value?.Guid),
            ).pipe(
                map((result) => {
                    if (!result) {
                        return null;
                    }
                    const errorMessage = this.translateService.instant(
                        'UI.Campaign.Form.Errors.ObjectAlreadyInCampaign',
                    );
                    return { objectAlreadyInCampaign: errorMessage };
                }),
            );
        };
    }
}
