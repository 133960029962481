import { IWorkspaceIdentifier } from './workspace-identifier';
import { IDataIdentifier, ServerType } from '@datagalaxy/dg-object-model';
import { deserialize, deserializeAs } from 'cerialize';
import { EntitySecurityData } from '@datagalaxy/webclient/security/domain';
import { SpaceGovernanceUserDto } from './workspace-governance-user-dto';
import { SpaceSecurityProfileType } from './workspace-security-profile';

export interface IHasSpaceIconData {
    Trigram: string;
    IconHash: string;
}

export class Workspace
    implements IWorkspaceIdentifier, IDataIdentifier, IHasSpaceIconData
{
    @deserialize public DisplayName!: string;
    @deserialize public Description!: string;
    @deserialize public ReferenceId!: string | null;
    // Will be true if user has import rights on ANY version on ANY module in the NavSpace
    @deserialize public CanImportEntities!: boolean;
    @deserialize public CanCreateEntities!: boolean;
    @deserialize public CanImportEntitiesOnCatalog!: boolean;
    @deserialize public ParentReferenceId!: string;
    @deserializeAs(EntitySecurityData) public SecurityData!: EntitySecurityData;
    @deserializeAs(SpaceGovernanceUserDto)
    public DefaultOwnerUser!: SpaceGovernanceUserDto;
    @deserializeAs(SpaceGovernanceUserDto)
    public DefaultStewardUser!: SpaceGovernanceUserDto;
    @deserializeAs(SpaceSecurityProfileType)
    public SecurityProfileType?: SpaceSecurityProfileType;
    @deserialize public Trigram!: string;
    @deserialize public ImageHash!: string;
    @deserialize public IconHash!: string;
    @deserialize public DefaultVersionName!: string;
    /**
     * NOTE: Default Version Is:
     * When Versioning Disabled,
     *      the "technical" version Id for the only version in the project
     * Otherwise,
     *      First Official Version,
     *      Or, First Work Version,
     *      Or, First Archived Version
     *      Or, First Release Candidate Version
     */
    @deserialize public DefaultVersionId!: string;
    @deserialize public AnyWorkVersionId!: string;
    /**
     * NOTE: UserDefaultVersionId is the User Favorite version for the Project, if set. May
     * be null
     */
    @deserialize public UserDefaultVersionId?: string;
    @deserialize public UserDefaultVersionName?: string;
    @deserialize public IsVersioningEnabled!: boolean;

    public IsDefaultSpace = false;

    public get Type() {
        return ServerType.Project;
    }

    public get TypeName() {
        return ServerType[ServerType.Project];
    }

    //#region ISpaceIdentifier
    public get versionId() {
        return this.UserDefaultVersionId || this.DefaultVersionId;
    }

    public get spaceId() {
        return this.ReferenceId;
    }

    //#endregion

    public get DataReferenceId() {
        return this.ReferenceId as string;
    }

    public get DataTypeName() {
        return this.TypeName;
    }
}
