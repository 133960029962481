import {
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import { IDragDropConfig } from '@datagalaxy/core-ui';
import { IEntityIdentifier, ServerType } from '@datagalaxy/dg-object-model';
import { IDropdownButtonOption } from '../../shared-ui/dropdownButton.types';
import { EntityUiService } from '../services/entity-ui.service';
import { DataUtil } from '../../util/DataUtil';
import { ModelerDataUtil } from '../../util/ModelerDataUtil';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { WorkspaceIdentifier } from '@datagalaxy/webclient/workspace/utils';
import { IWorkspaceIdentifier } from '@datagalaxy/webclient/workspace/domain';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';
import { ServerConstants } from '@datagalaxy/shared/server/domain';
import { EntityCreationOrigin } from '@datagalaxy/webclient/entity/feature';
import { ModuleEntitiesConfig } from '../../../module/module-entities/module-entities.types';
import { EntityPreviewPanelService } from '../services/entity-preview-panel.service';
import { ModuleEntitiesComponent } from '../../../module/module-entities/module-entities.component';
import { DxyDropdownButtonComponent } from '../../shared-ui/dxy-dropdown-button/dxy-dropdown-button.component';
import { TranslateModule } from '@ngx-translate/core';
import { NgIf } from '@angular/common';
import { DxyButtonDirective } from '@datagalaxy/ui/buttons';

@Component({
    selector: 'dxy-entity-tree',
    templateUrl: 'dxy-entity-tree.component.html',
    styleUrls: ['dxy-entity-tree.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        TranslateModule,
        DxyDropdownButtonComponent,
        ModuleEntitiesComponent,
        DxyButtonDirective,
    ],
})
export class DxyEntityTreeComponent
    extends DxyBaseComponent
    implements OnChanges, OnInit
{
    @Input() entityData: EntityItem;
    @Input() showHeader: boolean;
    @Input() readOnly: boolean;
    @Input() dragDrop: IDragDropConfig<IEntityIdentifier>;

    protected addButtonActions: IDropdownButtonOption[];
    protected spaceIdr: IWorkspaceIdentifier;
    protected gridConfig: ModuleEntitiesConfig;

    private readonly actionOrigin = EntityCreationOrigin.dockingPane;

    protected get hasWriteAccess() {
        return (
            !this.readOnly && !!this.entityData?.SecurityData?.HasWriteAccess
        );
    }

    protected get dgModule() {
        return DataUtil.getModuleFromEntityType(this.entityData?.entityType);
    }

    protected get hasData() {
        return (
            this.entityData?.getAttributeValue<number>(
                ServerConstants.PropertyName.LogicalChildrenCount,
            ) > 0
        );
    }

    constructor(
        private entityUiService: EntityUiService,
        private entityPreviewService: EntityPreviewPanelService,
    ) {
        super();
    }

    ngOnChanges(changes: SimpleChanges) {
        super.onChange(changes, 'entityData', () => this.initData());
    }

    ngOnInit() {
        this.log('$onInit (readOnly)', this.entityData, this.readOnly);

        this.initData();

        this.gridConfig = {
            header: {
                hide: true,
            },
            entityRootId: this.entityData?.ReferenceId,
            rowCursorOnHover: true,
            entity: {
                dragDrop: this.dragDrop,
            },
        };
    }

    protected async onEntityClick(entity: EntityItem) {
        await this.entityPreviewService.setupPanel({
            entityIdr: entity,
        });
    }

    private initData() {
        this.spaceIdr = WorkspaceIdentifier.fromEntity(this.entityData);
        this.createAddActions();
    }

    private createAddActions() {
        const serverType = this.entityData.ServerType;
        const addButtonActions: IDropdownButtonOption[] = [];

        if (ModelerDataUtil.isModelerServerType(serverType)) {
            const showCreateContainer =
                serverType == ServerType.Model ||
                serverType == ServerType.Container;
            const showCreateStructure = DataUtil.isCreateStructureAvailable(
                this.entityData.HddData,
            );
            const showCreateField = serverType == ServerType.Table;

            if (showCreateContainer) {
                addButtonActions.push({
                    titleKey: 'UI.Global.btnCreateContainer',
                    callback: () => this.createChild(ServerType.Container),
                });
            }
            if (showCreateStructure) {
                addButtonActions.push({
                    titleKey: 'UI.Global.btnCreateStructure',
                    callback: () => this.createChild(ServerType.Table),
                });
            }
            if (showCreateField) {
                addButtonActions.push({
                    titleKey: 'UI.Global.btnCreateChild',
                    callback: () => this.createChild(ServerType.Column),
                });
            }
        } else {
            addButtonActions.push({
                titleKey: 'UI.Global.btnCreateChild',
                callback: () => this.createChild(serverType),
            });
        }

        this.addButtonActions = addButtonActions;
    }

    private async createChild(childServerType: ServerType) {
        await this.entityUiService.onCreateChild(
            this.entityData,
            this.actionOrigin,
            childServerType,
        );
    }
}
