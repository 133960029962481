import { CollectionsHelper } from '@datagalaxy/core-util';
import { Subject } from 'rxjs';
import { EOperationType } from './EOperationType';
import { CsvFieldInfo } from './CsvFieldInfo';
import { ImportModule, ImportModuleCatalog } from './ImportModule';
import { AttributeMatch } from './AttributeMatch';
import { AttributeCategory } from './AttributeCategory';
import { ModelType } from '@datagalaxy/dg-object-model';
import { CsvParseOptions } from './CsvParseOptions';
import {
    EmptyFieldBehavior,
    ImportDataMessage,
    ImportDataResult,
} from '@datagalaxy/webclient/data-port/data-access';
import { WorkspaceIdentifier } from '@datagalaxy/webclient/workspace/utils';
import { AttributeDTO } from '@datagalaxy/webclient/attribute/domain';
import { DgModule } from '@datagalaxy/shared/dg-module/domain';

export class ImportContext {
    public spaceIdr?: WorkspaceIdentifier;
    public onModalClosed?: (isImportDone: boolean) => void;
    public attribute: AttributeDTO;

    // current import operation
    public currentOperation: EOperationType;
    public entityReferenceId?: string;
    public attributeKey?: string;
    public emptyFieldBehavior = EmptyFieldBehavior.Default;

    public showCsvDetails = true;
    public showEmbeddedDetails = true;
    public startFromOnlineSettings = false;
    public get startFromCsvSettings() {
        return this.importTarget > ImportTarget.Entities;
    }

    public initialModule?: DgModule;
    public initialEntityId?: string;
    public initialSourceType?: ModelType;
    public hasInitialWorkspace?: boolean;
    public isTargetAlreadySet = false;
    public importMode?: ImportMode;
    public initialImportMode?: ImportMode;
    public importTarget: ImportTarget;
    public showOnlineConnectorsOnly: boolean;

    //#region csv settings
    public csvParseOptions = new CsvParseOptions();
    public csvFileName: string;
    public csvFields = new Array<CsvFieldInfo>();
    public csvFile: File;
    public csvParsedData: any[];

    // current import module
    public currentModule: ImportModule | ImportModuleCatalog;

    // Constrains variables for import operations enable/disable
    public glossaryHasProperties = true;
    public sourceExists = true;
    public dataProcessingsHasDpElements = true;
    //#endregion

    //#region csv pre-import
    public lastImportResult: ImportDataResult;
    public hasChangedMapping: boolean;
    // Used to avoid Check's message blink on init
    public isMappingInitDone = false;

    // attributes, for drag/drop in wizard step 3
    public attributeMatches: AttributeMatch[];
    public categories: AttributeCategory[];
    public isAttributesAutoMapped = false;
    public isImportDone = false;
    public contentRequestId = '';

    public notifyRefreshMessages(fieldName: string) {
        return this.onRefreshMessages.next(fieldName);
    }
    public get onRefreshMessages$() {
        return this.onRefreshMessages.asObservable();
    }
    private onRefreshMessages = new Subject<string>();
    //#endregion

    //#region errors/warnings data
    public get areAllMandatoryFieldsMatched() {
        return (
            CollectionsHelper.count(
                this.attributeMatches,
                (o) => o.isMandatory && !o.isMatched,
            ) == 0
        );
    }

    public get hasImportErrors() {
        return this.lastImportResult?.Messages.some(
            (message) => message.IsError,
        );
    }

    public get errorsCount() {
        return this.lastImportResult?.Messages.filter(
            (message) => message.IsError,
        ).length;
    }

    public get hasImportWarnings() {
        return this.lastImportResult?.Messages.some(
            (message) => message.IsWarning,
        );
    }

    public get warningsCount() {
        return this.lastImportResult?.Messages.filter(
            (message) => message.IsWarning,
        ).length;
    }
    //#endregion

    constructor(
        spaceIdr?: WorkspaceIdentifier,
        importTarget?: ImportTarget,
        module?: DgModule,
        sourceId?: string,
        importMode?: ImportMode,
        startFromOnlineSettings?: boolean,
        showOnlineConnectorsOnly?: boolean,
    ) {
        if (spaceIdr) {
            this.spaceIdr = WorkspaceIdentifier.from(spaceIdr);
        }
        this.hasInitialWorkspace = !!this.spaceIdr;
        this.initialModule = module ?? DgModule.unknown;
        this.initialEntityId = sourceId;
        this.importTarget = importTarget ?? ImportTarget.Entities;
        this.initialImportMode =
            (importMode ?? this.importTarget == ImportTarget.Entities)
                ? ImportMode.Unknown
                : ImportMode.Csv;
        this.startFromOnlineSettings = startFromOnlineSettings;
        this.showOnlineConnectorsOnly = showOnlineConnectorsOnly;
    }

    public onUnmapData(messageGroup: IMessageGroup) {
        const parameters = messageGroup.messages[0].Parameters;
        const fieldName = parameters[parameters.length - 1];
        if (!fieldName) {
            return;
        }

        const attributeMatch = this.attributeMatches.find(
            (attribute) => attribute.attributeName == fieldName,
        );
        const csvField = this.csvFields.find(
            (csvField) => csvField.name == attributeMatch.csvFieldName,
        );
        attributeMatch.csvFieldName = undefined;
        if (csvField) {
            csvField.isMatched = false;
        }
        this.hasChangedMapping = true;
        this.notifyRefreshMessages(fieldName);
    }
}

export enum ImportMode {
    Unknown,
    Csv,
    OnlineConnector,
    DesktopConnector,
}

export enum ImportTarget {
    Entities,
    Users,
    Tags,
    TimeSeries,
    Teams,
}

export interface IMessageGroup {
    code: string;
    messages: ImportDataMessage[];
    isError: boolean;
    isWarning: boolean;
}
