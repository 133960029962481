import {
    deserialize,
    deserializeAs,
    inheritSerialization,
    SerializableEnumeration,
} from 'cerialize';
import { BaseServiceResult } from '@datagalaxy/data-access';
import { UserDto } from '@datagalaxy/webclient/user/domain';

export enum TeamAuditActionType {
    CreateTeam = 0,
    UpdateTeamName = 1,
    UpdateTeamAccessType = 2,
    UpdateTeamDescription = 3,
    UpdateTeamEmail = 4,
    AcceptMembershipRequest = 5,
    RejectMembershipRequest = 6,
    SetTeamOwner = 7,
    RemoveTeamOwner = 8,
    CloneTeam = 9,
    AddTeamMember = 10,
    DeleteTeamMember = 11,
}
SerializableEnumeration(TeamAuditActionType);

export class TeamAuditExtraData {
    @deserialize SourceTeamUid?: string;
}

export class TeamAuditData {
    @deserialize TeamUid!: string;
    @deserialize ActionType!: TeamAuditActionType;
    @deserialize ActionMaker!: UserDto;
    @deserialize Member!: UserDto;
    @deserialize CreationTime!: string;
    @deserialize NewValue!: string;
    @deserialize OldValue!: string;
    @deserialize ExtraDataJSON?: string;
    @deserialize ExtraData?: TeamAuditExtraData;

    public static OnDeserialized(instance: TeamAuditData) {
        if (instance.ExtraDataJSON) {
            //#archi-deserialization: constructor is not called. should use GenericDeserialize(instance.ExtraDataJSON, TeamAuditExtraData)
            instance.ExtraData = JSON.parse(
                instance.ExtraDataJSON,
            ) as TeamAuditExtraData;
        }
    }
}

@inheritSerialization(BaseServiceResult)
export class GetTeamAuditDataResult extends BaseServiceResult {
    @deserializeAs(TeamAuditData) TeamAuditDataList!: TeamAuditData[];

    constructor() {
        super();
    }
}
