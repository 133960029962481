import {
    ChangeDetectionStrategy,
    Component,
    inject,
    input,
    OnInit,
    viewChild,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DxyRichTextFieldComponent } from '@datagalaxy/core-ui';
import { TranslateModule } from '@ngx-translate/core';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';
import { TranslationInputActionsComponent } from '@datagalaxy/webclient/multilingual/feature';
import { DxyIconButtonDirective } from '@datagalaxy/ui/buttons';
import { FeatureFlagDirective } from '@datagalaxy/webclient/feature-flag';
import {
    ReportAiGeneratedTextService,
    TextAttributeAiGenerationButtonComponent,
    TextAttributeAiGenerationResultComponent,
} from '@datagalaxy/webclient/suggestion/feature';
import { DescriptionFieldStore } from './description-field-store';
import { AttributeFieldComponent } from '../../common/attribute-field-component.interface';

@Component({
    selector: 'dxy-description-field',
    templateUrl: './description-field.component.html',
    styleUrls: ['./description-field.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    providers: [DescriptionFieldStore],
    imports: [
        DxyRichTextFieldComponent,
        FormsModule,
        TranslateModule,
        TranslationInputActionsComponent,
        DxyIconButtonDirective,
        FeatureFlagDirective,
        TextAttributeAiGenerationButtonComponent,
        TextAttributeAiGenerationResultComponent,
    ],
})
export class DescriptionFieldComponent
    implements OnInit, AttributeFieldComponent
{
    readonly store = inject(DescriptionFieldStore);
    readonly reportService = inject(ReportAiGeneratedTextService);

    entity = input.required<EntityItem>();
    readonly = input<boolean>(false);

    field = viewChild(DxyRichTextFieldComponent);

    ngOnInit() {
        this.store.init({
            entity: this.entity(),
            attributePath: 'LongDescription',
            readonly: this.readonly(),
        });
        this.store.loadTranslations();
        this.store.loadAcceptedSuggestion();
    }

    public onValueChange(value: string) {
        this.store.setUpdatedValue(value);
    }
    public async onFieldBlur(_$event: FocusEvent) {
        await this.store.persistUpdatedValue();
    }
    public onFieldFocus() {
        this.store.touch();
    }

    public focusField() {
        this.field()?.doFocus();
    }

    protected reportGeneration() {
        this.reportService.reportDescription(this.entity());
    }
}
