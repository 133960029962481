import { Injectable } from '@angular/core';
import { ModuleSecurityService } from './module-security.service';
import { allModules, DgModuleName } from '@datagalaxy/shared/dg-module/domain';
import { ModuleInfo } from './module.store';
import { ArrayUtils } from '@datagalaxy/utils';
import { ObjectAccessType } from '@datagalaxy/webclient/security/data-access';
import { getLocalId } from '@datagalaxy/webclient/utils';
import { TranslateService } from '@ngx-translate/core';
import { ModuleSignalrService } from './module-signalr/module-signalr.service';
import { Toaster } from '@datagalaxy/ui/toaster';
import { WorkspaceRouterService } from '@datagalaxy/webclient/workspace/feature';
import { WorkspaceStore } from '@datagalaxy/webclient/workspace/data-access';
import { CurrentUserService } from '@datagalaxy/webclient/user/data-access';
import { IWorkspaceIdentifier } from '@datagalaxy/webclient/workspace/domain';
import { Router } from '@angular/router';
import { EntityGridView } from './module-entities/module-entities-store.service';

export interface ModuleRouteOptions {
    fullPage?: boolean;
    viewMode?: EntityGridView;
}

@Injectable({ providedIn: 'root' })
export class ModuleService {
    constructor(
        private moduleSecurityService: ModuleSecurityService,
        private currentUser: CurrentUserService,
        private moduleSignalrService: ModuleSignalrService,
        private translate: TranslateService,
        private workspaceStore: WorkspaceStore,
        private toaster: Toaster,
        private workspaceRouter: WorkspaceRouterService,
        private router: Router,
    ) {}

    public getModuleUrlPath(
        module: DgModuleName,
        workspaceIdentifier: IWorkspaceIdentifier,
        options?: ModuleRouteOptions,
    ) {
        const workspacePath =
            this.workspaceRouter.getWorkspaceUrlCommands(workspaceIdentifier);
        const { viewMode, fullPage } = options || {};

        return [
            ...workspacePath,
            module.toLowerCase(),
            'all',
            'list',
            viewMode === 'flat' ? 'flat' : 'tree',
            fullPage ? 'full' : 'browse',
        ];
    }

    public goToModule(
        module: DgModuleName,
        workspaceIdentifier: IWorkspaceIdentifier,
    ) {
        const url = this.getModuleUrlPath(module, workspaceIdentifier);
        return this.router.navigate(url);
    }

    public isModuleUrlActive(
        module: DgModuleName,
        workspaceIdentifier: IWorkspaceIdentifier,
    ) {
        const moduleUrl = this.getModuleUrlPath(
            module,
            workspaceIdentifier,
        ).join('/');

        return this.router.isActive(moduleUrl, {
            paths: 'subset',
            queryParams: 'subset',
            fragment: 'ignored',
            matrixParams: 'ignored',
        });
    }

    public async loadCurrentUserAccessibleModules(
        spaceId: string,
        versionId: string,
    ): Promise<ModuleInfo[]> {
        const currentUser = this.currentUser.userInfo;

        const res = await this.moduleSecurityService.getUserAccessibleModules(
            spaceId,
            versionId,
            currentUser.UserId,
        );

        return res
            .map((moduleAccessInfo) => ({
                definition: allModules.find(
                    (m) => m.name === moduleAccessInfo.Module,
                ),
                access: moduleAccessInfo,
            }))
            .sort(ArrayUtils.sortByIndexOf(allModules, (m) => m.definition));
    }

    public handleModuleRightAccessChanges() {
        return [
            this.moduleSignalrService.updatedModule$.subscribe((event) => {
                void this.handleRightAccessChanges(
                    event.WorkspaceId,
                    event.ObjectAccessType,
                );
            }),
            this.moduleSignalrService.updatedSource$.subscribe((event) => {
                void this.handleRightAccessChanges(
                    event.WorkspaceId,
                    event.ObjectAccessType,
                );
            }),
        ];
    }

    private handleRightAccessChanges(
        workspaceId: string,
        objectAccessType: ObjectAccessType,
    ) {
        this.displayRightAccessChangeNotification();
        const space = this.workspaceStore.currentSpace;
        if (
            objectAccessType === ObjectAccessType.NoAccess &&
            getLocalId(space?.spaceId) === workspaceId
        ) {
            return this.workspaceRouter.goToSpaceHome(space);
        }
    }

    private displayRightAccessChangeNotification() {
        this.toaster.warningToast({
            title: this.translate.instant(
                'UI.Notification.SecurityRightsUpdate.title',
            ),
            message: this.translate.instant(
                'UI.Notification.SecurityRightsUpdate.msg',
            ),
        });
    }
}
