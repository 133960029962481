import { CollectionsHelper, CoreUtil } from '@datagalaxy/core-util';
import { BaseService, UiUtil } from '@datagalaxy/core-ui';
import { IUserBadgeCellData } from '@datagalaxy/core-ui/cell-components';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { AppDataService } from './app-data.service';
import { ToasterService } from './toaster.service';
import {
    BaseUserListParameter,
    BaseUserServiceParameter,
    CommunicationSetting,
    CreateUserParameter,
    DeleteUserSettingValueParameter,
    GenericPersonProfileDataResult,
    GetUserSettingCategoryValuesParameter,
    GetUserSettingsParameter,
    GetUserSettingValueParameter,
    LoadKnownUsersParameter,
    LoadKnownUsersResult,
    SetCommunicationSettingParameter,
    SetPersonProfileDataParameter,
    SetUserProfileImageParameter,
    SetUserSettingsParameter,
    SetUserSettingValueParameter,
    UpdateUserLicenseParameter,
    UserAdminData,
    UserApiService,
    UserSettingsDto,
} from '@datagalaxy/webclient/user/data-access';
import * as moment from 'moment';
import {
    BaseServiceParameter,
    catchUnsuccessfulApiErrorOrThrow,
} from '@datagalaxy/data-access';
import {
    GetUserIntegrationParameter,
    IntegrationApiService,
} from '@datagalaxy/webclient/integration/data-access';
import { getLocalId } from '@datagalaxy/webclient/utils';
import {
    CrudOperation,
    FunctionalLogService,
} from '@datagalaxy/shared/monitoring/data-access';
import { IDenodoSampleParameters } from '@datagalaxy/webclient/modeler/data-access';
import {
    SecurityApiService,
    SetUserSecurityParameter,
} from '@datagalaxy/webclient/security/data-access';
import {
    DataPortApiService,
    ExportDataOperation,
    ExportDataParameter,
} from '@datagalaxy/webclient/data-port/data-access';
import { NotificationRealTimeBehavior } from '@datagalaxy/webclient/client/data-access';
import { UserGroup } from '@datagalaxy/webclient/system/data-access';
import { UserPublicService } from '@datagalaxy/webclient/user/ui';
import { AppConfigService } from '@datagalaxy/webclient/config';
import {
    IUserCommonDto,
    UserPublicData,
    userSettingsValues,
} from '@datagalaxy/webclient/user/domain';
import { AttributeObjectValue } from '@datagalaxy/webclient/attribute/domain';
import { SpaceGovernanceUserDto } from '@datagalaxy/webclient/workspace/domain';

@Injectable({ providedIn: 'root' })
export class UserService extends BaseService implements UserPublicService {
    //#region events
    public get onInit$() {
        return this.onInit.asObservable();
    }
    private readonly onInit = new Subject<void>();
    //#endregion

    public get clientDenodoSampleParameters() {
        return this._clientDenodoSampleParameters;
    }
    private _clientDenodoSampleParameters: IDenodoSampleParameters;

    public get isAnyGoldenSettingsUser() {
        return !!this.goldenSettingsUserLocalId;
    }
    public get isLoggedInGoldenSettingsUser() {
        return (
            this.isAnyGoldenSettingsUser &&
            this.loggedInUserId.toLowerCase() ==
                this.goldenSettingsUserLocalId.toLowerCase()
        );
    }

    private users: Map<string, UserPublicData>;

    private get loggedInUserId() {
        return this.appDataService.currentUserId;
    }
    private get loggedInClientId() {
        return this.appDataService.clientId;
    }
    private get goldenSettingsUserLocalId() {
        return this.appDataService.clientGoldenSettingsUserLocalId;
    }

    private systemUserGroups: UserGroup[] = [];

    constructor(
        private dataPortApiService: DataPortApiService,
        private userApiService: UserApiService,
        private integrationApiService: IntegrationApiService,
        private appDataService: AppDataService,
        private appConfigService: AppConfigService,
        private toasterService: ToasterService,
        private functionalLogService: FunctionalLogService,
        private securityApiService: SecurityApiService,
    ) {
        super();
    }

    public init() {
        this.log('init');
        this.onInit.next();
        this.initUserDenodoSampleParameter(); // unawaited on purpose
    }

    public setSystemUsersGroups(systemUserGroups: UserGroup[]) {
        this.systemUserGroups = systemUserGroups;
    }

    public async reloadClientUserList() {
        await this.loadClientUserData();
        return Array.from(this.users.values());
    }

    public async loadClientUserData() {
        const param = new LoadKnownUsersParameter();
        const gpr = await this.userApiService.loadKnownUsers(param);
        const map = new Map<string, UserPublicData>();
        this.users = map;
        this.loadResult(map, gpr);
    }

    public getCurrentUser(): UserAdminData {
        const appData = this.appDataService;
        const user = this.getPublicUserData(appData.currentUserId);
        const adminUser = new UserAdminData();

        CoreUtil.assign(adminUser, user);

        adminUser.LicenseStartsOn = appData.currentUserLicenseStartsOn;
        adminUser.LicenseExpiresOn = appData.currentUserLicenseExpiresOn;
        adminUser.IsLicenseExpired = moment(
            adminUser.LicenseExpiresOn,
        ).isBefore();
        adminUser.IsLicenseNotStarted = moment(
            adminUser.LicenseStartsOn,
        ).isAfter();
        adminUser.IsSupport = appData.currentUserIsSupport;
        adminUser.FirstConnectionOn = appData.currentUserFirstConnectionOn;
        adminUser.LastConnectionOn = appData.currentUserLastConnectionOn;

        return adminUser;
    }

    public async getNonDeletedUsers(
        forceReload = false,
        includeMetaBot?: boolean,
    ) {
        if (!forceReload) {
            const result = this.getUserList({ includeMetaBot });
            if (result) {
                return result;
            }
        }
        await this.loadClientUserData();
        return this.getUserList({ includeMetaBot });
    }

    /** This is the source list for the Space Governance Users management : All (non-deleted) users are valid candidates */
    public async getUsersDataAsSpaceGovDtos() {
        const res = await this.getNonDeletedUsers();
        return res.map(
            (user) =>
                new SpaceGovernanceUserDto(
                    user.ReferenceId,
                    user.FirstName,
                    user.LastName,
                    user.Email,
                    user.UserId,
                    false,
                    '',
                    user.ProfileImageHash,
                    user.ProfileThumbnailHash,
                ),
        );
    }

    public getPublicUserData(userId: string) {
        const userLocalId = getLocalId(userId);
        return this.users?.get(userLocalId);
    }

    public async getUserInsights(userId: string) {
        const parameter = new BaseUserServiceParameter(userId);
        const result = await this.userApiService.getUserInsights(parameter);
        result.propagateEntityVersionId();
        return result;
    }

    private loadResult(
        users: Map<string, UserPublicData>,
        gpr: LoadKnownUsersResult,
    ) {
        gpr.Users.filter((p) => !p.IsUserDeleted).forEach((p) =>
            users.set(p.UserId, p),
        );
    }

    public getUserName(userId: string) {
        const userLocalId = getLocalId(userId);
        const userData = this.getPublicUserData(userLocalId);
        return userData?.FullName || '';
    }

    public getUserBadgeDataFromId(userId: string): IUserBadgeCellData {
        if (!userId) {
            return;
        }
        const user = this.getPublicUserData(userId);
        if (!user) {
            return;
        }
        return this.getUserBadgeData(user);
    }

    public getUserBadgeData(user: IUserCommonDto): IUserBadgeCellData {
        const initials = user.Initials;
        return {
            userId: user.UserId,
            initials: user.Initials,
            colorClass: UiUtil.getColorClassFromString(initials),
            hasImage: !!user?.ProfileImageHash || user.isMetaBot,
            imageUrl: this.getUserThumbnailUrl(user),
        };
    }

    public getUserImageUrl(userId: string, noDefault = false): string {
        const userData = this.getPublicUserData(getLocalId(userId));
        const hash = userData?.ProfileImageHash;
        if (!hash) {
            return noDefault ? null : this.getUserDefaultImageUrl();
        }
        return this.getUserImageUrlInternal(hash);
    }
    public getUserThumbnailUrl(user: IUserCommonDto): string {
        if (user.isMetaBot) {
            return this.getMetaBotUserDefaultImageUrl();
        }
        const hash = user?.ProfileThumbnailHash ?? user?.ProfileImageHash;
        return this.getUserImageUrlInternal(hash);
    }
    private getUserDefaultImageUrl() {
        return `/images/user-placeholder-large.png?k=${encodeURIComponent(
            this.appConfigService.BUILD_RANDOM_KEY,
        )}`;
    }
    private getMetaBotUserDefaultImageUrl() {
        return `/images/avatar-metabot.svg?k=${encodeURIComponent(
            this.appConfigService.BUILD_RANDOM_KEY,
        )}`;
    }
    private getUserImageUrlInternal(hash: string): string {
        return this.userApiService.getUserImage(hash);
    }

    public getUserAsObjectValue(userLocalId: string) {
        const ppd = this.getPublicUserData(userLocalId);
        if (!ppd) return null;
        const aov = new AttributeObjectValue();
        aov.PersonUid = ppd.UserId;
        aov.DisplayName = ppd.FullName;
        aov.PersonEmail = ppd.Email;
        aov.PersonFirstName = ppd.FirstName;
        aov.PersonLastName = ppd.LastName;
        aov.PersonFullName = ppd.FullName;
        return aov;
    }

    /** returns users from cache */
    public getUserList(opt?: {
        includeSystemUsers?: boolean;
        includeSupportUsers?: boolean;
        includeDeletedUsers?: boolean;
        includeMetaBot?: boolean;
    }) {
        return CollectionsHelper.filterMap(
            this.users,
            (u) =>
                (!u.IsToken && !u.IsUserDeleted && !u.IsSupport) ||
                (opt?.includeDeletedUsers && u.IsUserDeleted) ||
                (opt?.includeSupportUsers && u.IsSupport) ||
                (opt?.includeSystemUsers && u.IsToken) ||
                (opt?.includeMetaBot && u.isMetaBot),
        );
    }

    public getUserGroupList() {
        return this.systemUserGroups;
    }

    public async getUserSettings(
        user: UserAdminData,
    ): Promise<UserSettingsDto> {
        const clientLocalId = user.ClientId;
        const userLocalId = user.UserId;
        const param = new GetUserSettingsParameter(clientLocalId, userLocalId);
        const result = await this.userApiService.getUserSettings(param);
        return result?.UserSettings;
    }

    public async getUserIntegration(clientId: string) {
        const getIntegrationParameter = new GetUserIntegrationParameter(
            clientId,
        );
        return await this.integrationApiService.getUserIntegration(
            getIntegrationParameter,
        );
    }

    public async setUserLanguage(user: UserPublicData, languageCode: string) {
        const clientLocalId = user.ClientId;
        const userLocalId = user.UserId;
        const param = new SetUserSettingsParameter(clientLocalId, userLocalId);
        param.UserLanguageCode = languageCode;
        await this.userApiService.setUserSettings(param);
        this.appDataService.setCurrentLanguageCode(languageCode);
    }

    public async setNotificationCleanupPeriod(
        clientId: string,
        userId: string,
        cleanupPeriodInDays: number,
    ) {
        const param = new SetUserSettingsParameter(clientId, userId);
        param.NotificationCleanupPeriod = cleanupPeriodInDays;
        return await this.userApiService.setUserSettings(param);
    }
    public async setUserProfileImage(
        userId: string,
        imageFile: File,
        thumbnailFile: File,
    ) {
        return this.setUserProfileImageInternal(
            userId,
            false,
            imageFile,
            thumbnailFile,
        );
    }
    public async deleteUserProfileImage(userId: string) {
        return this.setUserProfileImageInternal(userId, true);
    }
    private async setUserProfileImageInternal(
        userId: string,
        isDelete: boolean,
        imageFile?: File,
        thumbnailFile?: File,
    ) {
        const param = new SetUserProfileImageParameter(userId);
        if (isDelete) {
            param.IsDelete = true;
        } else {
            if (!imageFile) {
                return;
            }

            param.FileName = imageFile.name;
            param.FileContent = await CoreUtil.readAsDataUrl(imageFile);
            param.ThumbnailContent =
                await CoreUtil.readAsDataUrl(thumbnailFile);
        }
        const result = await this.userApiService.setUserProfileImage(param);
        const user = result.Person;
        const existingUser = this.getPublicUserData(user.UserId);
        if (existingUser) {
            existingUser.ProfileImageHash = user.ProfileImageHash;
            existingUser.ProfileThumbnailHash = user.ProfileThumbnailHash;
        }
        this.users.set(user.UserId, user);
        return true;
    }

    private openErrorToastr(messageKey: string) {
        this.toasterService.errorToast({
            titleKey: 'AdminUI.Toastr.errorTitle',
            messageKey: `AdminUI.Toastr.${messageKey}`,
        });
    }

    public async setUserProfileData(currentUser: UserPublicData) {
        const userLocalId = currentUser.UserId;
        const param = new SetPersonProfileDataParameter(userLocalId);
        param.FirstName = currentUser.FirstName;
        param.LastName = currentUser.LastName;
        param.Title = currentUser.Title;
        param.Service = currentUser.Service;
        param.Role = currentUser.Role;
        param.Email = currentUser.Email;

        try {
            const result = await this.userApiService.setUserProfileData(param);
            if (currentUser.UserId == this.loggedInUserId) {
                this.appDataService.setCurrentUserInfo(
                    currentUser.FirstName,
                    currentUser.LastName,
                );
            }
            this.users.set(currentUser.UserId, currentUser);
            return result.Person;
        } catch (e) {
            catchUnsuccessfulApiErrorOrThrow<GenericPersonProfileDataResult>(
                e,
                (error) => {
                    if (error.error.IsErrorEmailAlreadyExists) {
                        this.openErrorToastr('emailAlreadyExists');
                    } else {
                        this.openErrorToastr('updateUserError');
                    }
                },
            );
        }
    }

    public async setUserSecurity(userId: string, isClientAdmin: boolean) {
        const parameter = new SetUserSecurityParameter(userId, isClientAdmin);
        return await this.securityApiService.setUserSecurity(parameter);
    }

    public async removeUserFromClient(userId: string) {
        const parameter = new BaseUserServiceParameter(userId);
        await this.userApiService.removeUserFromClient(parameter);
        this.users.delete(userId);
    }

    public isCurrentUser(currentUser: UserPublicData) {
        return this.loggedInUserId == currentUser.UserId;
    }

    public async setNotificationRealTimeBehavior(
        notificationRealTimeBehavior: NotificationRealTimeBehavior,
    ) {
        const param = new SetUserSettingsParameter(
            this.loggedInClientId,
            this.loggedInUserId,
        );
        param.NotificationRealTimeBehavior = notificationRealTimeBehavior;
        await this.userApiService.setUserSettings(param);
    }

    public async updateUserLicense(userId: string, licenseId: number) {
        const parameter = new UpdateUserLicenseParameter(userId, licenseId);
        const result = await this.userApiService.updateUserLicense(parameter);
        const user = result.CreatedUser;
        this.users.set(user.UserId, user);
        return user;
    }

    public async removeLicenseForUser(userId: string) {
        const parameter = new SetPersonProfileDataParameter(userId);
        await this.userApiService.removeLicenseForUser(parameter);
    }

    //#region CRUD

    /** when an Administrator creates a new user */
    public async createUser(
        email: string,
        firstName: string,
        lastName: string,
        isSystemAdmin: boolean,
        isClientAdmin: boolean,
        licenseId: number,
        title: string,
        service: string,
        role: string,
        customFunctionalLog?: string,
    ) {
        this.functionalLogService.logFunctionalAction(
            customFunctionalLog ?? 'ADMIN_CS_USERS',
            CrudOperation.C,
        );
        const parameter = new CreateUserParameter(
            email,
            firstName,
            lastName,
            isSystemAdmin,
            isClientAdmin,
            false,
            false,
            '',
            false,
            title,
            service,
            role,
        );
        parameter.LicenseId = licenseId;
        const result = await this.userApiService.createUser(parameter);
        this.users?.set(result.CreatedUser.UserId, result.CreatedUser);
        return result;
    }

    public async deleteUser(userId: string) {
        const parameter = new BaseUserServiceParameter(userId);
        await this.userApiService.deleteUser(parameter);
        this.users.delete(userId);
    }

    public preDeleteUser(userId: string) {
        const parameter = new BaseUserServiceParameter(userId);
        return this.userApiService.preDeleteUser(parameter);
    }

    //#endregion

    //#region Export

    public exportUsers(userIds?: string[]) {
        const parameter = new ExportDataParameter(
            null,
            ExportDataOperation.ExportUsers,
            null,
            userIds,
        );
        this.dataPortApiService.exportData(parameter);
    }

    //#endregion

    public getLicenses() {
        const parameter = new BaseServiceParameter();
        return this.userApiService.getLicenses(parameter);
    }

    public async getUsersForCreation(email?: string): Promise<UserAdminData[]> {
        const param = new BaseUserListParameter();
        param.Emails = [email];
        const res = await this.userApiService.loadUserList(param);

        return res?.Users;
    }

    //#region UserSettingValue

    public async getUserSettingValue(
        category: string,
        route: string,
        useGoldenSettingsIfAny = false,
        useGoldenSettingsOnlyIfNoSettings = false,
    ) {
        const goldenSettingsUserLocalId = useGoldenSettingsIfAny
            ? this.goldenSettingsUserLocalId
            : undefined;
        if (
            goldenSettingsUserLocalId &&
            useGoldenSettingsOnlyIfNoSettings &&
            !this.isLoggedInGoldenSettingsUser
        ) {
            this.log(
                'getUserSettingValue-useGoldenSettingsOnlyIfNoSettings',
                category,
                route,
                goldenSettingsUserLocalId,
            );
            const result = await this.userApiService.getUserSettingValue(
                new GetUserSettingValueParameter(category, route),
            );
            if (result.Value) {
                return result;
            }

            this.log(
                'getUserSettingValue-useGoldenSettingsOnlyIfNoSettings-noValue',
                category,
                route,
                goldenSettingsUserLocalId,
            );
            return this.userApiService.getUserSettingValue(
                new GetUserSettingValueParameter(
                    category,
                    route,
                    goldenSettingsUserLocalId,
                ),
            );
        }
        this.log(
            'getUserSettingValue',
            category,
            route,
            useGoldenSettingsIfAny,
            goldenSettingsUserLocalId,
        );
        return this.userApiService.getUserSettingValue(
            new GetUserSettingValueParameter(
                category,
                route,
                goldenSettingsUserLocalId,
            ),
        );
    }

    /** the tuple (category, route) identifies the value for the current user */
    public setUserSettingValue(
        category: string,
        route: string,
        value: string,
        meta?: string,
    ) {
        return this.userApiService.setUserSettingValue(
            new SetUserSettingValueParameter(category, route, value, meta),
        );
    }
    public deleteUserSettingValue(category: string, route: string) {
        return this.userApiService.deleteUserSettingValue(
            new DeleteUserSettingValueParameter(category, route),
        );
    }

    public getUserSettingCategoryValues(category: string) {
        return this.userApiService.getUserSettingCategoryValues(
            new GetUserSettingCategoryValuesParameter(category),
        );
    }
    public getUserSettingCategoryRoutes(category: string) {
        return this.userApiService.getUserSettingCategoryRoutes(
            new GetUserSettingCategoryValuesParameter(category),
        );
    }
    //#endregion

    public async getUserCommunicationSettings() {
        const res = await this.userApiService.getUserCommunicationSettings();
        return res?.CommunicationSettings;
    }

    public setUserCommunicationSetting(
        communicationSetting: CommunicationSetting,
    ) {
        const param = new SetCommunicationSettingParameter();
        param.CommunicationSetting = communicationSetting;
        return this.userApiService.setUserCommunicationSetting(param);
    }

    //#region PAT

    public getUserPersonalAccessToken(userId?: string) {
        return this.userApiService.getUserPersonalAccessToken(
            new BaseUserServiceParameter(userId),
        );
    }
    public regeneratePersonalAccessToken(isRegeneration = true) {
        return isRegeneration
            ? this.userApiService.regeneratePersonalAccessToken(
                  new BaseServiceParameter(),
              )
            : this.userApiService.generatePersonalAccessToken(
                  new BaseServiceParameter(),
              );
    }
    public revokePersonalAccessToken(userId?: string) {
        return this.userApiService.revokePersonalAccessToken(
            new BaseUserServiceParameter(userId),
        );
    }

    //#endregion

    private async initUserDenodoSampleParameter() {
        const result = await this.getUserSettingValue(
            userSettingsValues.denodo.category,
            userSettingsValues.denodo.routes.settings,
        );
        this.log('initUserDenodoSampleParameter', result);
        this._clientDenodoSampleParameters = JSON.parse(
            result.Value ?? null,
        ) as IDenodoSampleParameters;
    }
}
