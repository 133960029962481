import { MonitoringApiService } from '@datagalaxy/shared/monitoring/data-access';
import { Route, Routes } from '@angular/router';
import { inject } from '@angular/core';
import { legacyAuthRoutes } from './account/account.routes';
import { AuthGuardMatch, DisabledAuthGuardMatch } from './auth/auth.guard';
import { AppHashGuard } from './app.guard';
import { LegacySsoAuthGuardMatch } from './auth/legacy-sso/legacy-sso-auth.guard';

/**
 * @deprecated Related to auth v1
 */
const legacyRoot: Route = {
    path: '',
    redirectTo: '/client/default',
    pathMatch: 'full',
    canMatch: [DisabledAuthGuardMatch],
};

/**
 * @deprecated Related to auth v1
 */
const legacyClientRoutes: Route = {
    path: 'client',
    loadChildren: () =>
        import('./client/client.routes').then((m) => m.legacyClientRoutes),
    canMatch: [DisabledAuthGuardMatch],
};

const healthCheckRoute: Route = {
    path: 'is-up',
    loadComponent: () =>
        import('./app-main/dxy-is-up/dxy-is-up.component').then(
            (m) => m.DxyIsUpComponent,
        ),
    resolve: {
        upData: () => inject(MonitoringApiService).healthCheck(),
    },
};

const seeYouSoonRoute: Route = {
    path: `see-you-soon`,
    loadComponent: () =>
        import('@datagalaxy/webclient/auth/ui').then(
            (m) => m.SeeYouSoonComponent,
        ),
    canMatch: [LegacySsoAuthGuardMatch],
};

const expiredLicense: Route = {
    path: 'expired-trial',
    loadComponent: () =>
        import(
            './account/account-expired-trial-page/account-expired-trial-page.component'
        ).then((m) => m.AccountExpiredTrialPageComponent),
};

export const error: Route = {
    path: 'error',
    loadComponent: () =>
        import('./errorPages/dxy-error-page/dxy-error-page.component').then(
            (m) => m.DxyErrorPageComponent,
        ),
};

const clientRoutes: Route = {
    path: '',
    loadChildren: () =>
        import('./client/client.routes').then((m) => m.clientRoutes),
    canMatch: [AuthGuardMatch],
};

export const appRoutes: Routes = [
    {
        path: '',
        canActivate: [AppHashGuard],
        children: [
            healthCheckRoute,
            seeYouSoonRoute,
            expiredLicense,
            error,
            clientRoutes,
            ...legacyAuthRoutes,
            legacyClientRoutes,
            legacyRoot,
        ],
    },
    { path: '**', redirectTo: '/' },
];
