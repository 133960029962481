import { SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { CoreUtil } from '@datagalaxy/core-util';
import { TranslateService } from '@ngx-translate/core';
import { ActivityLogEntryDataTypeName } from '../activityLog.constants';
import {
    IActivityLogItemTranslateParams,
    IActivityLogNavLinkData,
} from '../activityLog.types';
import {
    IActivityLogEntryAdapterParams,
    IActivityLogEntryTranslationAdapter,
    ITranslatedActivityLogEntry,
} from './activity-log-entry-translation.type';
import { CommentaryLogEntryAdapter } from './commentary-log-entry-adapter';
import { DefaultLogEntryAdapter } from './default-log-entry-adapter';
import { DiagramLogEntryAdapter } from './diagram-log-entry-adapter';
import { EntityLinkLogEntryAdapter } from './entity-link-log-entry-adapter';
import { TaskLogEntryAdapter } from './task-log-entry-adapter';
import { UserLogEntryAdapter } from './user-log-entry-adapter';
import { AttributeDataService } from '../../shared/attribute/attribute-data.service';
import { UserService } from '../../services/user.service';
import { ServerType } from '@datagalaxy/dg-object-model';
import { HierarchyDataDescriptor } from '@datagalaxy/dg-object-model';
import { EntityPanelTool } from '../../entity-panels/entity-panels.types';
import { EntityLinkService } from '../../entity-links/entity-link.service';
import { WorkspaceLogEntryAdapter } from './workspace-log-entry-adapter';
import { ActivityLogEntry } from '@datagalaxy/webclient/activity-log/domain';
import { ServerConstants } from '@datagalaxy/shared/server/domain';

/**
 * ## Role
 * Build translation for each activity log entry
 *
 * ## Notes
 * This factory uses adapters to build translations.
 * The adapter to use is automatically determined from entry
 * but you can pass your own adapter for very specific cases (cf. NotificationLogEntryAdapter)
 */
export class ActivityLogEntryTranslationFactory {
    private commentaryAdapter: CommentaryLogEntryAdapter;
    private taskAdapter: TaskLogEntryAdapter;
    private entityLinkAdapter: EntityLinkLogEntryAdapter;
    private userAdapter: UserLogEntryAdapter;
    private defaultLogAdapter: DefaultLogEntryAdapter;
    private diagramLogAdapter: DiagramLogEntryAdapter;
    private workspaceLogAdapter: WorkspaceLogEntryAdapter;

    constructor(
        private domSanitizer: DomSanitizer,
        private translate: TranslateService,
        private LinkedObjectService: EntityLinkService,
        private attributeDataService: AttributeDataService,
        private userService: UserService,
    ) {
        this.createAdapters();
    }

    public translateEntry(
        entry: ActivityLogEntry,
        adapter?: IActivityLogEntryTranslationAdapter,
    ) {
        adapter ??= this.getAdapter(entry);
        return this.build(entry, adapter);
    }

    private getAdapter(
        entry: ActivityLogEntry,
    ): IActivityLogEntryTranslationAdapter {
        if (!entry) {
            return;
        }

        //#region #archi-servertype: to remove if no warnings
        if (entry.Data && !(entry.Data instanceof HierarchyDataDescriptor)) {
            CoreUtil.warn('Data is not an hdd instance', entry.Data);
        }
        if (
            entry.ChildData &&
            !(entry.ChildData instanceof HierarchyDataDescriptor)
        ) {
            CoreUtil.warn('ChildData is not an hdd instance', entry.ChildData);
        }
        //#endregion

        if (
            entry.Data?.ServerType == ServerType.ObjectCommentary ||
            entry.ChildData?.ServerType == ServerType.ObjectCommentary
        ) {
            return this.commentaryAdapter;
        }

        if (
            entry.Data?.ServerType == ServerType.ObjectTask ||
            entry.ChildData?.ServerType == ServerType.ObjectTask
        ) {
            return this.taskAdapter;
        }

        if (entry.IsEntityLink) {
            return this.entityLinkAdapter;
        }

        const userPropertyNames = [
            ServerConstants.PropertyName.DataStewards,
            ServerConstants.PropertyName.DataOwners,
        ];
        if (userPropertyNames.includes(entry.PropertyName)) {
            return this.userAdapter;
        }

        const diagramTypeNames = [
            ServerType[ServerType.Diagram],
            ActivityLogEntryDataTypeName.diagramNote,
            ActivityLogEntryDataTypeName.diagramNode,
            ActivityLogEntryDataTypeName.diagramEdge,
        ];
        if (diagramTypeNames.includes(entry.Data.DataTypeName)) {
            return this.diagramLogAdapter;
        }
        // Projects PropertyName does not exists anymore,
        // it's a legacy value existing in some activity logs entries
        if (
            entry.PropertyName == 'Projects' ||
            (entry.Data?.DataServerType == ServerType.Project &&
                !entry.ChildData)
        ) {
            return this.workspaceLogAdapter;
        }

        return this.defaultLogAdapter;
    }

    private createAdapters() {
        const params: IActivityLogEntryAdapterParams = {
            userService: this.userService,
            attributeDataService: this.attributeDataService,
            linkedObjectService: this.LinkedObjectService,
            translate: this.translate,
        };
        this.commentaryAdapter = new CommentaryLogEntryAdapter(params);
        this.taskAdapter = new TaskLogEntryAdapter(params);
        this.entityLinkAdapter = new EntityLinkLogEntryAdapter(params);
        this.userAdapter = new UserLogEntryAdapter(params);
        this.diagramLogAdapter = new DiagramLogEntryAdapter(params);
        this.defaultLogAdapter = new DefaultLogEntryAdapter(params);
        this.workspaceLogAdapter = new WorkspaceLogEntryAdapter(params);
    }

    private build(
        entry: ActivityLogEntry,
        adapter: IActivityLogEntryTranslationAdapter,
    ): ITranslatedActivityLogEntry {
        if (!adapter) {
            return;
        }

        const data = adapter.getEntryTranslationData(entry);
        const translateKey = data.translateKey;
        const tp: Partial<IActivityLogItemTranslateParams> = {
            userFullName:
                entry &&
                this.sanitize(this.userService.getUserName(entry.UserId)),
            ...data.translateParams,
        };
        const navLinkData: IActivityLogNavLinkData = {
            panelTool: this.getPanelTool(entry),
            ...data.navLinkData,
        };

        if (!translateKey) {
            return;
        }

        navLinkData?.hdata?.setVersionId(entry?.VersionId);
        navLinkData?.childHData?.setVersionId(entry?.VersionId);

        // anti-xss sanitization
        ['parentDataName', 'childDataName', 'oldValue', 'newValue'].forEach(
            (k) => {
                if (tp[k]) {
                    tp[k] = this.sanitize(tp[k]);
                }
            },
        );
        return {
            innerHTML: this.translate.instant(translateKey, tp),
            navLinkData: navLinkData,
        };
    }
    private sanitize(html: string) {
        return this.domSanitizer.sanitize(SecurityContext.HTML, html);
    }

    private getPanelTool(entry: ActivityLogEntry) {
        switch (entry?.PropertyName) {
            case ServerConstants.PropertyName.Commentaries:
                return EntityPanelTool.Commentaries;
            case ServerConstants.PropertyName.Tasks:
            case ServerConstants.PropertyName.TaskAssignedToUser:
            case ServerConstants.PropertyName.TaskResponse:
            case ServerConstants.PropertyName.TaskType:
            case ServerConstants.PropertyName.TaskStatus:
                return EntityPanelTool.Tasks;
            default:
                return null;
        }
    }
}
