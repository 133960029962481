import { HubConnection, HubConnectionState } from '@microsoft/signalr';
import * as moment from 'moment';
import { GenericDeserialize } from 'cerialize';
import { Subject } from 'rxjs';
import { BaseService } from '@datagalaxy/core-ui';
import { ErrorHandler, Injectable, SecurityContext } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer } from '@angular/platform-browser';
import {
    EntityCommentaryDTO,
    EntityTaskDTO,
} from '@datagalaxy/dg-object-model';
import { ILoginDataForRealTime } from '../shared/util/app-types/login-data.types';
import {
    NotificationMessage,
    NotificationMessageCode,
} from '../shared/util/server-types/notification.api';
import {
    BaseServiceParameter,
    LegacyBackendSignalRService,
} from '@datagalaxy/data-access';
import { DeleteEntityCommentsParameter } from '@datagalaxy/webclient/comment/data-access';
import { ModelerData } from '@datagalaxy/webclient/modeler/data-access';
import { getContextId } from '@datagalaxy/webclient/utils';
import {
    DeleteEntityParameter,
    SetEntitiesParentResult,
} from '@datagalaxy/webclient/entity/data-access';
import { ProjectVersion } from '@datagalaxy/webclient/versioning/data-access';
import { ScreenDTO } from '@datagalaxy/webclient/screen/data-access';
import { DeleteEntityTasksParameter } from '@datagalaxy/webclient/task/data-access';
import { SuggestionData } from '@datagalaxy/webclient/suggestion/types';
import {
    IUserNotification,
    UserNotification,
} from '@datagalaxy/webclient/notification/data-access';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';
import {
    AttributeTagDTO,
    RtAttributeDTO,
    TextQualityData,
} from '@datagalaxy/webclient/attribute/domain';

/**
 * @deprecated: Use signalRService instead. Refer to webclient-data-access
 * documentation
 */
@Injectable({ providedIn: 'root' })
export class RealTimeCommService extends BaseService {
    private readonly localData: RealTimeData;

    private _realTimeConnectionId: string;
    private _currentVersionId: string = null;
    private _currentSpaceId: string = null;

    private rtEvent = new Subject<{
        type: RealTimeEvent;
        data: any;
        userData: any;
    }>();
    private versioningEvent = new Subject<{
        type: RtVersioningEvent;
        userData: any;
        data: ProjectVersion | ProjectVersion[];
        sourceId?: string;
    }>();
    private genericEvent = new Subject<{ type: EventType; data?: any }>();

    private get hubConnection() {
        return this.signalRService.hubConnection;
    }

    constructor(
        private domSanitizer: DomSanitizer,
        private translate: TranslateService,
        private errorHandler: ErrorHandler,
        private signalRService: LegacyBackendSignalRService,
    ) {
        super();
        this.localData = new RealTimeData();

        this.setupConnection(this.hubConnection);
    }

    //#region #Archi-currentSpace #Archi-currentVersion
    public setCurrentSpaceAndVersion(
        currentSpaceId: string,
        currentVersionId: string,
    ) {
        this._currentSpaceId = currentSpaceId;
        this._currentVersionId = currentVersionId;
    }

    private isEqualToCurrentVersionId(currentVersionId: string) {
        return this._currentVersionId === currentVersionId;
    }

    private isEqualToCurrentSpaceId(spaceId: string) {
        return this._currentSpaceId === spaceId;
    }

    //#endregion

    //#region Data Update

    public subscribeApplySaveData(handler: IRealTimeApplySaveHandler) {
        return this.subscribeEvent<TRealTimeApplySave>(
            EventType.ApplySaveData,
            handler &&
                ((d) =>
                    handler(
                        d.userData,
                        d.saveParameter,
                        d.saveResult,
                        d.sourceId,
                    )),
        );
    }

    private notifyApplySaveData(
        userData: string,
        saveParameter: BaseServiceParameter,
        saveResult: string,
        sourceId: string,
    ) {
        if (this.isEqualToConnectionId(sourceId)) {
            return;
        } else if (
            saveParameter.VersionId &&
            !this.isEqualToCurrentVersionId(saveParameter.VersionId)
        ) {
            return;
        } else {
            this.notifyEvent(EventType.ApplySaveData, {
                userData,
                saveParameter,
                saveResult,
            });
        }
    }

    //#endregion

    public subscribeAttributeSuggestionEvent(
        handler: IRealTimeEventHandler<SuggestionData[]>,
    ) {
        return this.subscribeRealTimeEvent(
            RealTimeEvent.RtSuggestionEvent,
            handler,
        );
    }

    private notifyAttributeSuggestionEvent(
        userId: string,
        suggestions: SuggestionData[],
    ) {
        this.notifyRealTimeEvent(
            RealTimeEvent.RtSuggestionEvent,
            userId,
            suggestions,
        );
    }

    public subscribeAttributeTextQualityScoreEvent(
        handler: IRealTimeEventHandler<TextQualityData[]>,
    ) {
        return this.subscribeRealTimeEvent(
            RealTimeEvent.RtTextQualityEvent,
            handler,
        );
    }

    public notifyAttributeTextQualityScoreEvent(
        userId: string,
        textQualityDatas: TextQualityData[],
    ) {
        this.notifyRealTimeEvent(
            RealTimeEvent.RtTextQualityEvent,
            userId,
            textQualityDatas,
        );
    }

    //#endregion

    //#region Versioning

    private setupConnectionVersioning(connection: HubConnection) {
        connection.on(
            'clientRtEnableVersioning',
            (
                userData: string,
                projectVersionData: string,
                sourceId: string,
            ) => {
                this.notifyEnableVersioning(
                    JSON.parse(userData),
                    JSON.parse(projectVersionData),
                    sourceId,
                );
            },
        );

        connection.on(
            'clientRtUpdateVersion',
            (
                userData: string,
                projectVersionData: string,
                sourceId: string,
            ) => {
                this.notifyUpdateVersion(
                    JSON.parse(userData),
                    JSON.parse(projectVersionData),
                    sourceId,
                );
            },
        );

        connection.on(
            'clientRtCreateVersion',
            (
                userData: string,
                projectVersionData: string,
                sourceId: string,
            ) => {
                this.notifyCreateVersion(
                    JSON.parse(userData),
                    JSON.parse(projectVersionData),
                    sourceId,
                );
            },
        );

        connection.on(
            'clientRtChangeVersionStatus',
            (
                userData: string,
                projectVersionsData: string,
                sourceId: string,
            ) => {
                this.notifyUpdateVersionStatus(
                    JSON.parse(userData),
                    JSON.parse(projectVersionsData),
                    sourceId,
                );
            },
        );
    }

    private notifyEnableVersioning(
        userData: any,
        projectVersionData: ProjectVersion,
        sourceId: string,
    ) {
        const projectVersion = GenericDeserialize(
            projectVersionData,
            ProjectVersion,
        );
        if (!this.isEqualToCurrentVersionId(projectVersion.ProjectVersionId)) {
            return;
        }
        this.versioningEvent.next({
            type: RtVersioningEvent.EnableVersioning,
            userData,
            data: projectVersion,
            sourceId,
        });
    }

    private notifyUpdateVersion(
        userData: any,
        projectVersionData: ProjectVersion,
        sourceId: string,
    ) {
        const projectVersion = GenericDeserialize(
            projectVersionData,
            ProjectVersion,
        );
        this.versioningEvent.next({
            type: RtVersioningEvent.UpdateVersion,
            userData,
            data: projectVersion,
            sourceId,
        });
    }

    private notifyCreateVersion(
        userData: any,
        projectVersionData: ProjectVersion,
        sourceId: string,
    ) {
        const projectVersion = GenericDeserialize(
            projectVersionData,
            ProjectVersion,
        );
        this.versioningEvent.next({
            type: RtVersioningEvent.CreateVersion,
            userData,
            data: projectVersion,
            sourceId,
        });
    }

    private notifyUpdateVersionStatus(
        userData: any,
        projectVersionData: { ProjectVersions: ProjectVersion[] },
        sourceId: string,
    ) {
        const projectVersions = projectVersionData.ProjectVersions.map(
            (projectVersion) =>
                GenericDeserialize(projectVersion, ProjectVersion),
        );
        this.versioningEvent.next({
            type: RtVersioningEvent.CreateVersion,
            userData,
            data: projectVersions,
            sourceId,
        });
    }

    public subscribeVersioning(handlers: {
        EnableVersioning?: ISingleProjectVersionRealTimeHandler;
        CreateVersion?: ISingleProjectVersionRealTimeHandler;
        UpdateVersion?: ISingleProjectVersionRealTimeHandler;
        UpdateVersionStatus?: IMultipleProjectVersionRealTimeHandler;
    }) {
        if (!handlers) {
            return;
        }
        return this.versioningEvent.subscribe((e) => {
            switch (e.type) {
                case RtVersioningEvent.EnableVersioning:
                    handlers.EnableVersioning?.(
                        e.userData,
                        e.data as ProjectVersion,
                        e.sourceId,
                    );
                    break;
                case RtVersioningEvent.CreateVersion:
                    handlers.CreateVersion?.(
                        e.userData,
                        e.data as ProjectVersion,
                        e.sourceId,
                    );
                    break;
                case RtVersioningEvent.UpdateVersion:
                    handlers.UpdateVersion?.(
                        e.userData,
                        e.data as ProjectVersion,
                        e.sourceId,
                    );
                    break;
                case RtVersioningEvent.UpdateVersionStatus:
                    handlers.UpdateVersionStatus?.(
                        e.userData,
                        e.data as ProjectVersion[],
                        e.sourceId,
                    );
                    break;
            }
        });
    }

    //#endregion    Versioning

    //#region Tasks

    private setupConnectionTasks(connection: HubConnection) {
        connection.on(
            'clientRtUpdateTask',
            (userDataJson: string, commentaryDtoJson: string) => {
                const userData = JSON.parse(userDataJson);
                const data = GenericDeserialize(
                    JSON.parse(commentaryDtoJson),
                    EntityTaskDTO,
                );
                this.notifyRealTimeEvent(
                    RealTimeEvent.RtUpdateTaskEvent,
                    userData,
                    data,
                );
            },
        );

        connection.on(
            'clientRtDeleteTasks',
            (userDataJson: string, deleteTaskParameterJson: string) => {
                const userData = JSON.parse(userDataJson);
                const data = GenericDeserialize(
                    JSON.parse(deleteTaskParameterJson),
                    DeleteEntityTasksParameter,
                );
                this.notifyRealTimeEvent(
                    RealTimeEvent.RtDeleteTaskEvent,
                    userData,
                    data,
                );
            },
        );
    }

    public subscribeUpdateTask(handler: IRealTimeUpdateTaskHandler) {
        return this.subscribeRealTimeEvent(
            RealTimeEvent.RtUpdateTaskEvent,
            handler,
        );
    }

    public subscribeDeleteTask(handler: IRealTimeDeleteTasksHandler) {
        return this.subscribeRealTimeEvent(
            RealTimeEvent.RtDeleteTaskEvent,
            handler,
        );
    }

    //#endregion    Tasks

    //#region Entity

    public subscribeDeleteEntityEvent(handler: IRealTimeDeleteEntityHandler) {
        return this.subscribeRealTimeEvent(
            RealTimeEvent.RtDeleteEntityEvent,
            handler,
        );
    }

    public subscribeCreateEntityEvent(handler: IRealTimeCreateEntityHandler) {
        return this.subscribeRealTimeEvent(
            RealTimeEvent.RtCreateEntityEvent,
            handler,
        );
    }

    public subscribeUpdateEntityParentEvent(
        handler: (userData: any, data: SetEntitiesParentResult) => void,
    ) {
        return this.subscribeRealTimeEvent(
            RealTimeEvent.RtUpdateEntityParentEvent,
            handler,
        );
    }

    private notifyDeleteEntityEvent(
        userData: any,
        data: DeleteEntityParameter,
    ) {
        this.notifyRealTimeEvent(
            RealTimeEvent.RtDeleteEntityEvent,
            userData,
            data,
        );
    }

    private notifyCreateEntityEvent(userData: any, data: EntityItem) {
        this.notifyRealTimeEvent(
            RealTimeEvent.RtCreateEntityEvent,
            userData,
            data,
        );
    }

    private notifyUpdateEntityEvent(userData: any, data: EntityItem) {
        this.notifyRealTimeEvent(
            RealTimeEvent.RtUpdateEntityEvent,
            userData,
            data,
        );
    }

    private notifyBulkUpdateEntityEvent(userData: any, data: EntityItem[]) {
        this.notifyRealTimeEvent(
            RealTimeEvent.RtBulkUpdateEntityEvent,
            userData,
            data,
        );
    }

    private notifyUpdateEntityParentEvent(
        userData: any,
        data: SetEntitiesParentResult,
    ) {
        this.notifyRealTimeEvent(
            RealTimeEvent.RtUpdateEntityParentEvent,
            userData,
            data,
        );
    }

    //#endregion

    //#region Tags
    //unused
    public subscribeUpdateTagEvent(handler: IRealTimeAttributeTagHandler) {
        return this.subscribeRealTimeEvent(
            RealTimeEvent.RtUpdateTagEvent,
            handler,
        );
    }

    private notifyUpdateTagEvent(userData: any, updatedTag: AttributeTagDTO) {
        this.notifyRealTimeEvent(
            RealTimeEvent.RtUpdateTagEvent,
            userData,
            updatedTag,
        );
    }

    //#endregion

    //#region Modeler Update

    public subscribeModelerUpdate(handler: IRealTimeModelerDataHandler) {
        return this.subscribeEvent<TRealTimeModelerData>(
            EventType.ModelerUpdate,
            handler &&
                ((d) => handler(d.userData, d.modelerUpdateData, d.sourceId)),
        );
    }

    private notifyModelerUpdate(
        userData: any,
        modelerData: ModelerData,
        sourceId: string,
    ) {
        const modelerUpdateData = GenericDeserialize(modelerData, ModelerData);
        if (this.isEqualToCurrentVersionId(modelerUpdateData.VersionId)) {
            const eventData: TRealTimeModelerData = {
                userData,
                modelerUpdateData,
                sourceId,
            };
            this.notifyEvent(EventType.ModelerUpdate, eventData);
        }
    }

    //#endregion

    //#region Log

    public addLog(message: string) {
        const sanitizedMessage = this.domSanitizer.sanitize(
            SecurityContext.HTML,
            message,
        );
        const logMsg = `${moment(new Date()).format(
            'HH:mm:ss',
        )}:&nbsp;&nbsp;${sanitizedMessage}<br />`;
        this.localData.log = logMsg + this.localData.log;
        this.notifyEvent(EventType.UpdateLog, this.localData.log);
    }

    //#endregion

    //#region UserNotification

    public subscribeUserNotification(
        handler: (notification: UserNotification) => void,
    ) {
        return this.subscribeEvent(EventType.UserNotification, handler);
    }

    private getLocalizedMessage(notificationMessage: NotificationMessage) {
        if (
            notificationMessage.MessageCode ===
            NotificationMessageCode[NotificationMessageCode.Dynamic]
        ) {
            return notificationMessage.RawMessage;
        } else {
            // TODO (MAR): Implement using parameters and i18n logic
            return notificationMessage.RawMessage;
        }
    }

    private onNotification(nm: UserNotification) {
        this.notifyEvent(EventType.UserNotification, nm);
    }

    private onNotificationMessage(nm: NotificationMessage) {
        if (this.handleSpecialMessage(nm)) {
            return;
        }

        const message = this.getLocalizedMessage(nm);
        this.addLog(message);
    }

    private handleSpecialMessage(nm: NotificationMessage) {
        switch (nm.MessageCode) {
            case NotificationMessageCode[
                NotificationMessageCode.UserListChange
            ]:
                this.notifyUserListChange(nm);
                return true;

            case NotificationMessageCode[
                NotificationMessageCode.SecurityRightsChange
            ]:
                this.notifySecurityRightsChangeEvent(nm);
                return true;

            default:
                return false;
        }
    }

    //#endregion

    //#region misc

    public subscribeReloadData(handler: () => void) {
        return this.subscribeEvent(EventType.ReloadData, handler);
    }

    //#archi-cleanup (fbo) Only used by dataService.rtUpdateTradInterpolation
    public updateData(
        entityId: string,
        triggerDataReload: boolean,
        msg: string,
    ) {
        this.hubConnection
            .invoke('updateData', triggerDataReload, entityId, msg)
            .catch((exc) => {
                this.errorHandler.handleError(exc /*, "signalr"*/);
                this.warn(exc);
            });
    }

    public subscribeSecurityRightsChangeEvent(
        handler: (data: NotificationMessage) => void,
    ) {
        return this.subscribeRealTimeEvent(
            RealTimeEvent.RtSecurityRightsChangeEvent,
            (_user, data) => handler(data),
        );
    }

    public subscribeUserListChange(handler: () => any) {
        return this.subscribeRealTimeEvent(
            RealTimeEvent.RtUserListChangeEvent,
            (_user) => handler(),
        );
    }

    public subscribeUpdateEntity(
        handler: (userData: any, data: EntityItem) => void,
    ) {
        return this.subscribeRealTimeEvent(
            RealTimeEvent.RtUpdateEntityEvent,
            handler,
        );
    }

    public subscribeBulkUpdateEntity(
        handler: (userData: any, data: EntityItem[]) => void,
    ) {
        return this.subscribeRealTimeEvent(
            RealTimeEvent.RtBulkUpdateEntityEvent,
            handler,
        );
    }

    public subscribeUpdateScreen(handler: IRealTimeUpdateScreenHandler) {
        return this.subscribeRealTimeEvent(
            RealTimeEvent.RtUpdateScreenEvent,
            handler,
        );
    }

    public subscribeCreateAttribute(handler: IRealTimeAttributeHandler) {
        return this.subscribeRealTimeEvent(
            RealTimeEvent.RtCreateAttributeEvent,
            handler,
        );
    }

    public subscribeUpdateAttribute(handler: IRealTimeAttributeHandler) {
        return this.subscribeRealTimeEvent(
            RealTimeEvent.RtUpdateAttributeEvent,
            handler,
        );
    }

    public subscribeAttributeDelete(handler: IRealTimeAttributeHandler) {
        return this.subscribeRealTimeEvent(
            RealTimeEvent.RtDeleteAttributeEvent,
            handler,
        );
    }

    public subscribeUpdateCommentary(
        handler: IRealTimeUpdateCommentaryHandler,
    ) {
        return this.subscribeRealTimeEvent(
            RealTimeEvent.RtUpdateCommentaryEvent,
            handler,
        );
    }

    public subscribeDeleteCommentary(
        handler: IRealTimeDeleteCommentaryHandler,
    ) {
        return this.subscribeRealTimeEvent(
            RealTimeEvent.RtDeleteCommentaryEvent,
            handler,
        );
    }

    private notifySecurityRightsChangeEvent(nm: NotificationMessage) {
        this.notifyRealTimeEvent(
            RealTimeEvent.RtSecurityRightsChangeEvent,
            null,
            nm,
        );
    }

    private notifyUserListChange(nm: NotificationMessage) {
        this.notifyRealTimeEvent(RealTimeEvent.RtUserListChangeEvent, null, nm);
    }

    //#endregion

    //#region Connection

    public async login(data: ILoginDataForRealTime) {
        await this.signalRService.login(data);
    }

    public async logout() {
        this._realTimeConnectionId = null;
        this._currentSpaceId = null;
        this._currentVersionId = null;
        await this.signalRService.logout();
        this.log('RealTime Logout Succeed');
        this.logConnection('logout', 'stop', null, 'stopped');
    }

    public getConnectionId() {
        return this._realTimeConnectionId;
    }

    private isEqualToConnectionId(sourceId: string) {
        return this._realTimeConnectionId === sourceId;
    }

    private setupConnection(connection: HubConnection) {
        const methodName = 'setupConnection';
        connection.serverTimeoutInMilliseconds = 60000;
        connection.onreconnecting((error) => {
            this.logConnection(
                methodName,
                'onreconnecting',
                () => connection.state === HubConnectionState.Reconnecting,
                `Connection lost due to error "${error}". Reconnecting.`,
            );
        });

        connection.onreconnected((connectionId) => {
            this.logConnection(
                methodName,
                'onreconnected',
                () => connection.state === HubConnectionState.Connected,
                `Connection reestablished. Connected with connectionId "${connectionId}".`,
            );
        });

        connection.onclose((error) => {
            this.logConnection(
                methodName,
                'onclose',
                () => connection.state === HubConnectionState.Disconnected,
                `Connection closed due to error "${error}". Try refreshing this page to restart the connection.`,
            );
        });

        connection.on('welcome', (connectionId: string, message: string) => {
            this._realTimeConnectionId = connectionId;
            this.addLog(message);
        });

        connection.on(
            'clientApplySave',
            (
                userDataJson: string,
                saveParameterJson: string,
                saveResultJson: string,
                sourceId: string,
            ) => {
                const userData = JSON.parse(userDataJson);
                const saveDataParameter = JSON.parse(saveParameterJson);
                this.notifyApplySaveData(
                    userData,
                    saveDataParameter,
                    JSON.parse(saveResultJson),
                    sourceId,
                );
            },
        );

        connection.on(
            'clientUpdateData',
            (triggerDataReload: boolean, message: string, sourceId: string) => {
                if (
                    !this.isEqualToConnectionId(sourceId) &&
                    triggerDataReload
                ) {
                    this.notifyEvent(EventType.ReloadData);
                    const trad = this.translate.instant(
                        'UI.RealTime.DataReload',
                    );
                    message += trad;
                    this.addLog(message);
                } else {
                    this.addLog(message);
                }
            },
        );

        connection.on(
            'clientRtModelerUpdate',
            (
                _actionName: string,
                userData: string,
                modelerUpdateData: string,
                sourceId: string,
            ) => {
                this.notifyModelerUpdate(
                    JSON.parse(userData),
                    JSON.parse(modelerUpdateData),
                    sourceId,
                );
            },
        );

        connection.on(
            'OnSuggestionUpdated',
            (userId: string, suggestionDataListJson: string) => {
                const result = JSON.parse(
                    suggestionDataListJson,
                ) as Array<SuggestionData>;
                const suggestions = result.map((d) =>
                    GenericDeserialize(d, SuggestionData),
                );
                this.notifyAttributeSuggestionEvent(userId, suggestions);
            },
        );

        connection.on(
            'OnTextQualityScoreUpdated',
            (userId: string, textQualityDataListJson: string) => {
                const result = JSON.parse(
                    textQualityDataListJson,
                ) as Array<TextQualityData>;
                const textQualityScore = result.map((d) =>
                    GenericDeserialize(d, TextQualityData),
                );
                this.notifyAttributeTextQualityScoreEvent(
                    userId,
                    textQualityScore,
                );
            },
        );

        connection.on(
            'clientRtCreateEntity',
            (
                userDataJson: string,
                createdEntityJson: string,
                _sourceId: string,
            ) => {
                const userData = JSON.parse(userDataJson);
                const createEntity = GenericDeserialize(
                    JSON.parse(createdEntityJson),
                    EntityItem,
                );

                if (
                    !this.isEqualToCurrentSpaceId(createEntity.ContextId) ||
                    !this.isEqualToCurrentVersionId(createEntity.VersionId)
                ) {
                    return;
                }

                this.notifyCreateEntityEvent(userData, createEntity);
            },
        );

        connection.on(
            'clientRtUpdateEntities',
            (userDataJson: string, updatedEntitiesJson: string) => {
                const userData = JSON.parse(userDataJson);
                const updatedEntities = GenericDeserialize(
                    JSON.parse(updatedEntitiesJson),
                    EntityItem,
                );
                const array = <EntityItem[]>(<any>updatedEntities);

                if (
                    !this.isEqualToCurrentSpaceId(array[0].ContextId) ||
                    !this.isEqualToCurrentVersionId(array[0].VersionId)
                ) {
                    return;
                }

                if (array.length == 1) {
                    this.notifyUpdateEntityEvent(userData, array[0]);
                } else {
                    this.notifyBulkUpdateEntityEvent(userData, array);
                }
            },
        );

        connection.on(
            'clientRtUpdateEntityParent',
            (userDataJson: string, updateEntitiesParentResultJson: string) => {
                const userData = JSON.parse(userDataJson);

                const updateEntitiesParentResult = GenericDeserialize(
                    JSON.parse(updateEntitiesParentResultJson),
                    SetEntitiesParentResult,
                );
                const firstReferenceId =
                    updateEntitiesParentResult.UpdatedEntities[0].ReferenceId;
                const firstContextId = getContextId(firstReferenceId);

                if (
                    !this.isEqualToCurrentSpaceId(firstContextId) ||
                    !this.isEqualToCurrentVersionId(
                        updateEntitiesParentResult.UpdatedEntities[0].VersionId,
                    )
                ) {
                    return;
                }

                this.notifyUpdateEntityParentEvent(
                    userData,
                    updateEntitiesParentResult,
                );
            },
        );

        connection.on(
            'clientRtUpdateTag',
            (userDataJson: string, tagDtoJson: string) => {
                const userData = JSON.parse(userDataJson);
                const updatedTag = GenericDeserialize(
                    JSON.parse(tagDtoJson),
                    AttributeTagDTO,
                );
                this.notifyUpdateTagEvent(userData, updatedTag);
            },
        );

        connection.on(
            'clientRtCreateAttribute',
            (userDataJson: string, rtAttributeDtoJson: string) => {
                const userData = JSON.parse(userDataJson);
                const attribute = GenericDeserialize(
                    JSON.parse(rtAttributeDtoJson),
                    RtAttributeDTO,
                );
                this.notifyRealTimeEvent(
                    RealTimeEvent.RtCreateAttributeEvent,
                    userData,
                    attribute,
                );
            },
        );

        connection.on(
            'clientRtUpdateAttribute',
            (userDataJson: string, rtAttributeDtoJson: string) => {
                const userData = JSON.parse(userDataJson);
                const attribute = GenericDeserialize(
                    JSON.parse(rtAttributeDtoJson),
                    RtAttributeDTO,
                );
                this.notifyRealTimeEvent(
                    RealTimeEvent.RtUpdateAttributeEvent,
                    userData,
                    attribute,
                );
            },
        );

        connection.on(
            'clientRtDeleteAttribute',
            (
                action: string,
                userDataJson: string,
                rtAttributeDtoJson: string,
            ) => {
                const userData = JSON.parse(userDataJson);
                const attribute = GenericDeserialize(
                    JSON.parse(rtAttributeDtoJson),
                    RtAttributeDTO,
                );
                this.notifyRealTimeEvent(
                    RealTimeEvent.RtDeleteAttributeEvent,
                    userData,
                    attribute,
                );
            },
        );

        connection.on(
            'clientRtUpdateScreen',
            (action: string, userDataJson: string, screenDtoJson: string) => {
                const userData = JSON.parse(userDataJson);
                const screenAction = UpdateScreenAction[action];
                const screen = GenericDeserialize(
                    JSON.parse(screenDtoJson),
                    ScreenDTO,
                );
                if (
                    screen.VersionId &&
                    !this.isEqualToCurrentVersionId(screen.VersionId)
                ) {
                    return;
                }
                this.notifyRealTimeEvent(
                    RealTimeEvent.RtUpdateScreenEvent,
                    userData,
                    new UpdateScreenRtData(screen, screenAction),
                );
            },
        );

        this.setupConnectionVersioning(connection);

        connection.on(
            'clientRtDeleteEntities',
            (userDataJson: string, deleteEntitiesParameterJson: string) => {
                const userData = JSON.parse(userDataJson);
                const deleteEntitiesParameter = JSON.parse(
                    deleteEntitiesParameterJson,
                ) as DeleteEntityParameter;

                const firstReferenceId =
                    deleteEntitiesParameter.DataReferenceIdList[0];
                const firstContextId = getContextId(firstReferenceId);

                if (
                    !this.isEqualToCurrentSpaceId(firstContextId) ||
                    !this.isEqualToCurrentVersionId(
                        deleteEntitiesParameter.VersionId,
                    )
                ) {
                    return;
                }

                this.notifyDeleteEntityEvent(
                    userData,
                    deleteEntitiesParameter as DeleteEntityParameter,
                );
            },
        );

        connection.on(
            'clientRtUpdateCommentary',
            (userDataJson: string, commentaryDtoJson: string) => {
                const userData = JSON.parse(userDataJson);
                const ecd = GenericDeserialize<EntityCommentaryDTO>(
                    JSON.parse(commentaryDtoJson),
                    EntityCommentaryDTO,
                );
                this.notifyRealTimeEvent(
                    RealTimeEvent.RtUpdateCommentaryEvent,
                    userData,
                    ecd,
                );
            },
        );

        connection.on(
            'clientRtDeleteCommentaries',
            (userDataJson: string, deleteCommentaryParameterJson: string) => {
                const userData = JSON.parse(userDataJson);
                const dcp = GenericDeserialize<DeleteEntityCommentsParameter>(
                    JSON.parse(deleteCommentaryParameterJson),
                    DeleteEntityCommentsParameter,
                );
                this.notifyRealTimeEvent(
                    RealTimeEvent.RtDeleteCommentaryEvent,
                    userData,
                    dcp,
                );
            },
        );

        this.setupConnectionTasks(connection);

        connection.on('notify', (json: string) => {
            const data = JSON.parse(json) as IUserNotification;
            const nm = new UserNotification(data);
            this.onNotification(nm);
        });

        connection.on('notifyMessage', (json: string) => {
            const data = JSON.parse(json);
            const nm = GenericDeserialize<NotificationMessage>(
                data,
                NotificationMessage,
            );
            this.onNotificationMessage(nm);
        });
    }

    private logConnection(
        callingMethodName: string,
        connectionMethodName: string,
        assert: () => boolean,
        assertedMessage: any,
    ) {
        if (!this.debug) {
            return;
        }
        const methodName = `${this.constructor.name} ${callingMethodName} ${connectionMethodName}`;
        if (assert) {
            this.log(methodName);
            if (!assert()) {
                this.warn(`\t${assertedMessage ?? ''}`);
            }
        } else {
            this.log(methodName, assertedMessage);
        }
    }

    //#endregion

    //#region helpers

    private subscribeRealTimeEvent(
        event: RealTimeEvent,
        handler: IRealTimeEventHandler<any>,
    ) {
        if (!handler) {
            return;
        }
        return this.rtEvent.subscribe((e) => {
            if (e.type == event) {
                handler(e.userData, e.data);
            }
        });
    }

    private notifyRealTimeEvent<T>(
        type: RealTimeEvent,
        userData: any,
        data: T,
    ) {
        this.rtEvent.next({ type, userData, data });
    }

    private subscribeEvent<T>(type: EventType, handler: (data: T) => void) {
        if (!handler) {
            return;
        }
        return this.genericEvent.subscribe((e) => {
            if (e.type == type) {
                handler(e.data);
            }
        });
    }

    private notifyEvent<T>(type: EventType, data?: T) {
        this.genericEvent.next({ type, data });
    }

    //#endregion
}

//#region types

enum RealTimeEvent {
    RtUpdateTagEvent,
    RtDeleteEntityEvent,
    RtCreateEntityEvent,
    RtUserListChangeEvent,
    RtSecurityRightsChangeEvent,
    RtUpdateEntityEvent,
    RtUpdateEntityParentEvent,
    RtBulkUpdateEntityEvent,
    RtUpdateAttributeEvent,
    RtCreateAttributeEvent,
    RtUpdateScreenEvent,
    RtDeleteAttributeEvent,
    RtUpdateCommentaryEvent,
    RtDeleteCommentaryEvent,
    RtUpdateTaskEvent,
    RtDeleteTaskEvent,
    RtSuggestionEvent,
    RtTextQualityEvent,
}

enum EventType {
    unknown = 0,
    ReloadData,
    UserNotification,
    UpdateLog,
    ApplySaveData,
    ModelerUpdate,
}

enum RtVersioningEvent {
    unknown = 0,
    EnableVersioning,
    CreateVersion,
    UpdateVersion,
    UpdateVersionStatus,
}

export enum UpdateScreenAction {
    ResetSpaceScreen,
    CreateSpaceScreen,
    UpdateSpaceScreen,
    UpdateClientScreen,
    CopySpaceScreenDefinition,
    CopyClientScreenDefinition,
}

export class UpdateScreenRtData {
    constructor(
        public screen: ScreenDTO,
        public action: UpdateScreenAction,
    ) {}
}

class RealTimeData {
    public log: string;
    public connectionId: string;
}

type IRealTimeEventHandler<T> = (userData: any, data: T) => void;

type TRealTimeApplySave = {
    userData: any;
    saveParameter: any;
    saveResult: any;
    sourceId: string;
};
type IRealTimeApplySaveHandler = (
    userData: any,
    saveParameter: any,
    saveResult: any,
    sourceId: string,
) => void;

type TRealTimeModelerData = {
    userData: any;
    modelerUpdateData: ModelerData;
    sourceId: string;
};
type IRealTimeModelerDataHandler = (
    userData: any,
    modelerUpdateData: ModelerData,
    sourceId: string,
) => void;

type IRealTimeUpdateTaskHandler = (userData: any, task: EntityTaskDTO) => any;
type IRealTimeDeleteTasksHandler = (
    userData: any,
    deleteParameter: DeleteEntityTasksParameter,
) => any;

type ISingleProjectVersionRealTimeHandler = (
    userData: any,
    projectVersion: ProjectVersion,
    sourceId: string,
) => void;
type IMultipleProjectVersionRealTimeHandler = (
    userData: any,
    projectVersions: ProjectVersion[],
    sourceId: string,
) => void;

type IRealTimeDeleteEntityHandler = (
    userData: any,
    deleteEntityParameter: DeleteEntityParameter,
) => void;
type IRealTimeCreateEntityHandler = (
    userData: any,
    createdEntity: EntityItem,
) => void;
type IRealTimeAttributeTagHandler = (
    userData: any,
    updatedTag: AttributeTagDTO,
) => void;
export type IRealTimeAttributeHandler = (
    userData: any,
    createdAttribute: RtAttributeDTO,
) => void;
export type IRealTimeUpdateScreenHandler = (
    userData: any,
    screenRtData: UpdateScreenRtData,
) => void;
export type IRealTimeUpdateCommentaryHandler = (
    userData: any,
    commentary: EntityCommentaryDTO,
) => void;
export type IRealTimeDeleteCommentaryHandler = (
    userData: any,
    deleteParameter: DeleteEntityCommentsParameter,
) => void;

//#endregion
