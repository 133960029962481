import { Injectable } from '@angular/core';
import {
    OidcSecurityService,
    OpenIdConfiguration,
} from 'angular-auth-oidc-client';
import { firstValueFrom, map } from 'rxjs';
import { clientId } from './default-open-id-config';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Injectable({
    providedIn: 'root',
})
export class AuthenticationService {
    public readonly authenticated$ =
        this.oidcSecurityService.isAuthenticated$.pipe(
            map((res) => res.isAuthenticated),
        );

    private _authenticated = false;

    public get authenticated() {
        return this._authenticated;
    }

    constructor(private oidcSecurityService: OidcSecurityService) {
        this.authenticated$
            .pipe(takeUntilDestroyed())
            .subscribe((authenticated) => {
                this._authenticated = authenticated;
            });
    }

    public async authorize(rememberedUrl?: string) {
        if (rememberedUrl) {
            await this.storeUrl(rememberedUrl);
        }
        this.oidcSecurityService.authorize();
    }

    /**
     * Logout and redirect to the identity provider login page
     */
    public logout() {
        this.oidcSecurityService.revokeAccessToken().subscribe(() => {
            this.oidcSecurityService.logoff().subscribe();
        });
    }

    /**
     * Logout without redirecting to the identity provider login page
     */
    public async logoutServer() {
        await firstValueFrom(this.oidcSecurityService.revokeAccessToken());
        await firstValueFrom(this.oidcSecurityService.revokeRefreshToken());
    }

    /**
     * Logout without revoking any token and without redirecting to the
     * identity provider
     */
    public logoutLocal() {
        this.oidcSecurityService.logoffLocal();
    }

    public async checkAuth(): Promise<{
        authenticated: boolean;
        url?: string;
    }> {
        try {
            const url = await this.retrieveUrl();

            const response = await firstValueFrom(
                this.oidcSecurityService.checkAuth(),
            );
            return {
                authenticated: response.isAuthenticated,
                url,
            };
        } catch {
            return { authenticated: false };
        }
    }

    public async resetPassword() {
        const config = await firstValueFrom(
            this.oidcSecurityService.getConfiguration(),
        );
        this.oidcSecurityService.setState('/');
        if (!config) {
            return;
        }
        location.href = this.buildUpdatePasswordUrl(config);
    }

    /**
     * Get the access token from the storage if the user is authenticated
     */
    public async getAccessTokenFromStorage() {
        /**
         * 'angular-auth-oidc-client' doesn't allow us to get the JWT token in
         * a synchronous way. So we have to use the async way to get the token,
         * which will run a request to the server to get the oidc configuration
         * first if the user is not already authenticated
         *
         * To avoid this oidc config call we ensure that the user is already
         * authenticated before getting the JWT token
         */
        if (!this.authenticated) {
            return;
        }
        return await firstValueFrom(this.oidcSecurityService.getAccessToken());
    }

    /**
     * Retrieved the stored url stored previously before calling the
     * authorize method. Note that this has to be called before the checkAuth
     * method that will clean up the url.
     */
    private async retrieveUrl(): Promise<string> {
        return await firstValueFrom(this.oidcSecurityService.getState());
    }

    private async storeUrl(url: string): Promise<boolean> {
        return await firstValueFrom(this.oidcSecurityService.setState(url));
    }

    private buildUpdatePasswordUrl(config: OpenIdConfiguration) {
        const url = new URL(`${config.authority}/protocol/openid-connect/auth`);
        url.searchParams.append('client_id', clientId);
        url.searchParams.append('redirect_uri', window.location.origin);
        url.searchParams.append('response_type', 'code');
        url.searchParams.append('scope', 'openid');
        url.searchParams.append('kc_action', 'UPDATE_PASSWORD');
        return url.toString();
    }
}
