import * as _ from 'lodash';

export class ObjectUtils {
    /**
     * Assigns own enumerable string keyed properties of source objects to the destination object.
     * Source objects are applied from left to right. Subsequent sources overwrite property assignments of previous sources.
     *
     * Note: This method mutates object and is loosely based on Object.assign.
     */
    static assign<TObject, TSource>(
        object: TObject,
        source: TSource,
    ): TObject & TSource {
        return _.assign(object, source);
    }
}
