import { autoserialize } from 'cerialize';
import { HierarchicalData } from '@datagalaxy/dg-object-model';
import { AttributeMetaInfo } from './attribute-meta-info';
import { UserPublicData } from '@datagalaxy/webclient/user/domain';
import { TechnologyDto } from '@datagalaxy/webclient/technology/domain';
import { AttributeTagDTO } from './attribute-tag/attribute-tag-dto';
import { SpaceGovernanceUserDto } from '@datagalaxy/webclient/workspace/domain';

export class AttributeMetaValue {
    @autoserialize public Key: string;
    @autoserialize public Value: any; //#Archi-typing (fbo) todo:  change to unknown to force callers to srtong type correctly
    @autoserialize public Color: string;
    @autoserialize public DeactivationTime?: string;
    @autoserialize public DeletionTime?: string;

    public NewValue: string | null;
    public parentValue?: AttributeMetaValue;
    public parentKey?: string | null;
    public localOrder?: number;
    public translatedDisplayName?: string;
    public translatedDescription?: string;
    public glyphClass?: string;
    public iconUrl?: string;

    // Use to hold references to Memory Data in client (in this case, hddData for filter-entity-criteria)
    public hddData?: HierarchicalData;
    public spaceGovernanceUserDto?: SpaceGovernanceUserDto;

    public get HddData() {
        return this.hddData;
    }

    public static fromHierarchicalData(
        hd: HierarchicalData,
        ami: AttributeMetaInfo,
        glyphClass?: string,
        isTechnical?: boolean,
    ) {
        const displayName = isTechnical
            ? hd.TechnicalName || hd.DisplayName
            : hd.DisplayName;
        return new AttributeMetaValue(ami, hd.DataReferenceId, displayName, {
            hddData: hd,
            glyphClass: glyphClass,
        });
    }

    public static fromSpaceGovUser(
        user: SpaceGovernanceUserDto,
        ami: AttributeMetaInfo,
    ) {
        return new AttributeMetaValue(ami, user.ReferenceId, user.FullName, {
            translatedDisplayName: user.FullName,
            spaceGovernanceUserDto: user,
        });
    }

    public static fromUserReference(
        user: UserPublicData,
        ami: AttributeMetaInfo,
        useGuid?: boolean,
    ) {
        return new AttributeMetaValue(
            ami,
            useGuid ? user.UserId : user.ReferenceId,
            user.FullName,
        );
    }

    public static fromTechnology(
        technology: TechnologyDto,
        ami: AttributeMetaInfo,
        iconUrl: string,
    ) {
        return new AttributeMetaValue(
            ami,
            technology.TechnologyCode,
            technology.TechnologyCode,
            {
                translatedDisplayName: technology.DisplayName,
                iconUrl,
            },
        );
    }

    public static fromClientTag(tag: AttributeTagDTO, ami: AttributeMetaInfo) {
        return AttributeMetaValue.fromAttributeTag(tag, ami);
    }

    public static fromAttributeTag(
        tag: AttributeTagDTO,
        ami: AttributeMetaInfo,
    ) {
        return new AttributeMetaValue(ami, tag.TagId, tag.DisplayName, {
            color: tag.Color,
            translatedDescription: tag.Description,
        });
    }

    public static fromHierarchicalAttributeTag(
        tag: AttributeTagDTO,
        ami: AttributeMetaInfo,
    ) {
        return new AttributeMetaValue(ami, tag.TagId, tag.DisplayName, {
            color: tag.Color,
            parentKey: tag.ParentTagId,
            localOrder: tag.localOrder,
        });
    }

    constructor(
        public attributeInfo: AttributeMetaInfo,
        key: string,
        value: string,
        opt: {
            color?: string;
            newValue?: string;
            translatedDisplayName?: string;
            translatedDescription?: string;
            parentKey?: string;
            localOrder?: number;
            glyphClass?: string;
            hddData?: HierarchicalData;
            spaceGovernanceUserDto?: SpaceGovernanceUserDto;
            iconUrl?: string;
        } = {},
    ) {
        this.Key = key;
        this.Value = value;
        this.Color = opt.color ?? '#28aae1';
        this.NewValue = opt.newValue ?? null;
        (this.translatedDisplayName = opt.translatedDisplayName ?? value),
            (this.translatedDescription = opt.translatedDescription);
        this.parentKey = opt.parentKey ?? null;
        this.localOrder = opt.localOrder ?? 0;
        this.glyphClass = opt.glyphClass;
        this.hddData = opt.hddData;
        this.spaceGovernanceUserDto = opt.spaceGovernanceUserDto;
        this.iconUrl = opt.iconUrl;
    }

    public getParents() {
        const localParents = [];
        let start: AttributeMetaValue = this;
        while (start.parentValue) {
            localParents.push(start.parentValue);
            start = start.parentValue;
        }
        return localParents;
    }
}
