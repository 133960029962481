<div class="body">
    <ul class="workspaces dg5-list-unstyled">
        @for (workspace of workspaces(); track trackByWorkspaceId) {
            <dxy-workspace-cell
                class="workspace menu-item"
                [class.selected]="selectedWorkspaceId() === workspace.id"
                [dxyDataTestId]="'workspace-' + workspace.id + '-item'"
                [workspace]="workspace"
                [actions]="actions"
                [fixActionsPosition]="true"
                (click)="workspaceSelected.emit(workspace)"
            ></dxy-workspace-cell>
        }
    </ul>
</div>

@if (showAllSpacesSection()) {
    <ul class="footer dg5-list-unstyled">
        <li
            class="menu-item"
            (click)="allSpacesSelected.emit()"
            dxyDataTestId="all-workspaces-item"
        >
            <span translate>UI.SpacesList.showAll</span>
        </li>
    </ul>
}
