import { GenericDeserialize } from 'cerialize';
import { CollectionsHelper, CoreUtil } from '@datagalaxy/core-util';
import { ModelerDataUtil } from './ModelerDataUtil';
import {
    EntityType,
    EntityTypeUtil,
    HierarchicalData,
    HierarchyDataDescriptor,
    IEntitySnapshot,
    IHierarchicalData,
    ServerType,
} from '@datagalaxy/dg-object-model';
import { getLocalId } from '@datagalaxy/utils';
import { ServerConstants } from '@datagalaxy/shared/server/domain';
import { WorkspaceDetails } from '@datagalaxy/webclient/workspace/domain';

/* ATTENTION: Cannot import DataUtil here */

declare type HdInput = {
    readonly ReferenceId: string;
    readonly ServerType: ServerType;
    DisplayName: string;
    SubTypeName?: string;
    VersionId?: string;
};

export class HddUtil {
    static isSpace(hdd: HierarchyDataDescriptor) {
        return !!hdd && hdd.DataLocalId === hdd.DataContextId;
    }

    /** returns the ordered ancestors of the given hierarchicalData, taking into account only one direct parent of each */
    static getHierarchyDataDescriptorList(hdata: IHierarchicalData) {
        return HddUtil.getHierarchyDataDescriptorListInternal(
            hdata.Data,
            hdata.Parents,
        );
    }
    private static getHierarchyDataDescriptorListInternal(
        currentDataDescriptor: HierarchyDataDescriptor,
        parentDataDescriptors: HierarchyDataDescriptor[],
        hierarchyDataDescriptors: HierarchyDataDescriptor[] = [],
    ): HierarchyDataDescriptor[] {
        if (
            currentDataDescriptor.DataTypeName !==
            ServerConstants.TypeName.Project
        ) {
            if (
                currentDataDescriptor.DataServerType == ServerType.FilteredView
            ) {
                if (parentDataDescriptors?.length) {
                    hierarchyDataDescriptors.push(parentDataDescriptors[0]);
                }
            } else {
                const directParentDataDescriptors =
                    currentDataDescriptor.ParentList.map((parentId) =>
                        parentDataDescriptors.find(
                            (o) => o.DataLocalId == parentId,
                        ),
                    );
                const selectedParentDataDescriptor =
                    HddUtil.selectParentDataDescriptor(
                        currentDataDescriptor,
                        directParentDataDescriptors,
                    );
                if (selectedParentDataDescriptor) {
                    hierarchyDataDescriptors.push(selectedParentDataDescriptor);
                    return HddUtil.getHierarchyDataDescriptorListInternal(
                        selectedParentDataDescriptor,
                        parentDataDescriptors,
                        hierarchyDataDescriptors,
                    );
                }
            }
        }
        return hierarchyDataDescriptors;
    }
    private static selectParentDataDescriptor(
        currentDataDescriptor: HierarchyDataDescriptor,
        parentDataDescriptors: HierarchyDataDescriptor[],
    ) {
        if (!currentDataDescriptor || !parentDataDescriptors?.length)
            return null;
        if (parentDataDescriptors.length == 1) return parentDataDescriptors[0];

        switch (currentDataDescriptor.DataServerType) {
            case ServerType.ForeignKey: {
                const dtn = ServerType[ServerType.Table];
                return parentDataDescriptors.find(
                    (hdd) => hdd.DataTypeName == dtn,
                );
            }
            default:
                return parentDataDescriptors[0];
        }
    }

    /** returns the first item of the given item's ancestors corresponding to the given types */
    static getParentDataDescriptorByType(
        hdata: IHierarchicalData,
        serverTypes: ServerType[],
        excludeSelf = false,
    ) {
        return (
            hdata &&
            HddUtil.getParentDataDescriptorByTypeInternal(
                hdata.Data,
                hdata.Parents,
                serverTypes,
                excludeSelf,
            )
        );
    }

    /** returns the first item of the given item's ancestors corresponding to the given types */
    private static getParentDataDescriptorByTypeInternal(
        currentDataDescriptor: HierarchyDataDescriptor,
        parentDataDescriptors: HierarchyDataDescriptor[],
        serverTypes: ServerType[],
        excludeSelf = false,
    ): HierarchyDataDescriptor {
        if (
            !currentDataDescriptor ||
            (!excludeSelf &&
                serverTypes?.includes(currentDataDescriptor.DataServerType))
        ) {
            return currentDataDescriptor;
        }

        const parentList = currentDataDescriptor.ParentList;
        // If no immediate parent, we abandon the recursion
        if (!parentList?.length) {
            return null;
        }

        const id = parentList[0];
        const parentDataDescriptor = parentDataDescriptors?.find(
            (hdd) => hdd.DataLocalId == id,
        );
        if (!parentDataDescriptor) {
            return null;
        }

        return HddUtil.getParentDataDescriptorByTypeInternal(
            parentDataDescriptor,
            parentDataDescriptors,
            serverTypes,
        );
    }

    static getModelId(hd: IHierarchicalData) {
        return HddUtil.getModelHdd(hd)?.DataReferenceId;
    }

    static getModelHdd(hd: IHierarchicalData) {
        return (
            ModelerDataUtil.isModelerServerType(hd.DataServerType) &&
            HddUtil.getFirstHddByType(hd, ServerType.Model)
        );
    }

    static getFirstHddByType(
        hd: IHierarchicalData,
        type: ServerType | ServerType[],
    ) {
        return (
            hd &&
            type &&
            HddUtil.getParentDataDescriptorByType(
                hd,
                Array.isArray(type) ? type : [type],
            )
        );
    }

    static createNoAccessHData(entity: IEntitySnapshot) {
        const hdd = new HierarchicalData();
        const mapping = EntityTypeUtil.getMapping(entity.EntityType);

        hdd.Data.DataReferenceId = entity.ReferenceId;
        hdd.TechnologyCode = entity.TechnologyCode;
        hdd.Data.DataTypeName = mapping.DataTypeName;
        hdd.Data.SubTypeName = mapping.SubTypeName;
        hdd.Data.DisplayName = '';
        hdd.Data.TechnicalName = '';
        hdd.Data.ParentList = [];
        hdd.Data.HasReadAccess = false;

        return hdd;
    }

    static createHDataForSpace(space: WorkspaceDetails) {
        return HddUtil.createHData(space);
    }

    private static createHData(object: HdInput, ...orderedParents: HdInput[]) {
        const parentIds = orderedParents.map((o) => getLocalId(o.ReferenceId));
        const data = HddUtil.createHdd(object, parentIds[0]);
        const parents = orderedParents.map((p, i) =>
            HddUtil.createHdd(p, parentIds[i + 1]),
        );
        const result = new HierarchicalData(data, parents);
        let versionId = data.VersionId;
        if (!versionId) {
            CollectionsHelper.withFirstFound(
                parents,
                (hd) => hd.VersionId != undefined,
                (hd) => (versionId = hd.VersionId),
            );
        }
        if (versionId) {
            result.setVersionId(versionId);
        }
        return result;
    }
    private static createHdd(object: HdInput, parentLocalId: string) {
        const data = new HierarchyDataDescriptor(
            object.ReferenceId,
            object.ServerType,
        );
        data.DisplayName = object.DisplayName;
        data.SubTypeName = object.SubTypeName;
        data.VersionId = object.VersionId;
        data.ParentList = parentLocalId ? [parentLocalId] : [];
        return data;
    }

    static deserializeHierarchicalData<
        TData extends HierarchicalData,
        TArg extends TData | TData[],
    >(objOrArray: TArg): TArg {
        return !objOrArray
            ? (objOrArray as TArg)
            : Array.isArray(objOrArray)
              ? (objOrArray.map(HddUtil.deserializeHierarchicalData) as TArg)
              : (GenericDeserialize(objOrArray, HierarchicalData) as TArg);
    }

    static deserializeHdd<
        TData extends HierarchyDataDescriptor,
        TArg extends TData | TData[],
    >(objOrArray: TArg): TArg {
        return !objOrArray
            ? (objOrArray as TArg)
            : Array.isArray(objOrArray)
              ? (objOrArray.map(HddUtil.deserializeHdd) as TArg)
              : (GenericDeserialize(
                    objOrArray,
                    HierarchyDataDescriptor,
                ) as TArg);
    }

    static extractParentHData(hdata: IHierarchicalData, parentId: string) {
        const parentIndex = hdata.Parents.findIndex(
            (p) => p.DataReferenceId == parentId,
        );
        if (parentIndex == -1) {
            CoreUtil.warn(
                'createHDataFromParent Error: No parent found in hData',
            );
            return null;
        }
        const hdd = hdata.Parents[parentIndex];
        const parents = hdata.Parents.slice(parentIndex + 1);
        return new HierarchicalData(hdd, parents);
    }

    public static hasHierarchicalChildren(hddData: IHierarchicalData): boolean {
        const modelHdd = HddUtil.getModelHdd(hddData);
        return (
            modelHdd &&
            modelHdd.EntityType != EntityType.RelationalModel &&
            modelHdd.EntityType != EntityType.TagBase
        );
    }
}
