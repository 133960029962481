import { registerDgEventType } from './event-type-registry';
import { ITypedEvent, TEventTypeName } from './UserEventBase';

/**
 * decorator factory to register an event type.
 * This decorator will register a decorated version of the event constructor
 * containing a static eventTypeName attribute to be able to deserialize
 * json object based on the event type name
 * @param eventTypeName
 * @example
 * -@registerTypedEvent('MyEvent')
    class MyEvent implements ITypedEvent {
        ...
    }
 */
export function registerTypedEvent(eventTypeName: TEventTypeName) {
    return function <TConstructor extends TypedEventConstructor>(
        constructor: TConstructor,
    ) {
        (
            constructor as unknown as IDecoratedTypeEventConstructor
        ).eventTypeName = eventTypeName;
        registerDgEventType(constructor, eventTypeName);
        return constructor;
    };
}

export interface IDecoratedTypeEventConstructor {
    eventTypeName: string;
}

type TypedEventConstructor = new (...args: any[]) => ITypedEvent;
