import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import {
    MAT_DIALOG_DATA,
    MatDialogRef,
    MatDialogModule,
} from '@angular/material/dialog';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { DxyBaseModalComponent } from '@datagalaxy/ui/dialog';
import { IPublicEditModalResolve } from '../public-edit-modal.types';
import { DxyModalFooterComponent } from '../../dialogs/dxy-modal-footer/dxy-modal-footer.component';
import { MatLegacyRadioModule } from '@angular/material/legacy-radio';
import { NgIf } from '@angular/common';
import { DxyFieldTextComponent } from '@datagalaxy/ui/fields';
import { FormsModule } from '@angular/forms';
import { DxyIconButtonDirective } from '@datagalaxy/ui/buttons';

@Component({
    selector: 'dxy-public-edit-modal',
    templateUrl: './dxy-public-edit-modal.component.html',
    styleUrls: ['./dxy-public-edit-modal.component.scss'],
    standalone: true,
    imports: [
        MatDialogModule,
        DxyIconButtonDirective,
        FormsModule,
        DxyFieldTextComponent,
        NgIf,
        MatLegacyRadioModule,
        TranslateModule,
        DxyModalFooterComponent,
    ],
})
export class DxyPublicEditModalComponent
    extends DxyBaseModalComponent<IPublicEditModalResolve, void>
    implements OnInit, AfterViewInit
{
    public displayName: string;
    public description: string;
    public canCreate: boolean;
    public titleText: string;
    public areRadioBtnsVisible: boolean;
    public actionBtnLblKey: string;
    public featureCode: string;
    public isPrivate: boolean;
    public get featureCodeSecond() {
        return this.data?.dto.IsPrivate != this.isPrivate
            ? this.data?.featureCodePublish
            : undefined;
    }

    private canChangePrivate: boolean;
    private isCreation: boolean;

    constructor(
        dialogRef: MatDialogRef<DxyPublicEditModalComponent>,
        @Inject(MAT_DIALOG_DATA) data: IPublicEditModalResolve,
        private translate: TranslateService,
    ) {
        super(dialogRef, data);
    }

    ngOnInit() {
        this.log('ngOnInit-start', this.data);
        const dto = this.data?.dto;
        if (!dto) {
            return;
        }

        const { edit, canChangePrivate } = this.data;

        if (!edit) {
            dto.IsPrivate = true;
        }
        this.displayName = dto.DisplayName;
        this.description = dto.Description ?? '';
        this.isPrivate = dto.IsPrivate;
        this.titleText =
            this.data?.titleText ||
            (this.data?.titleKey &&
                this.translate.instant(this.data.titleKey)) ||
            '';
        this.actionBtnLblKey = `UI.Dialog.NewItem.${
            edit ? 'lblSave' : 'lblCreate'
        }`;
        this.featureCode = this.data?.featureCode;
        this.areRadioBtnsVisible = canChangePrivate && edit;
        this.isCreation = !edit;
        this.canChangePrivate = canChangePrivate;

        this.updateCanCreate();

        this.log('ngOnInit-end');
    }

    ngAfterViewInit() {
        this.log('ngAfterViewInit-done');
    }

    public getCheckedClassForPrivate(forPrivate: boolean) {
        return `dg_radioButton ${
            this.isPrivate == forPrivate
                ? 'glyph-checkbox-checked'
                : 'glyph-checkbox-unchecked'
        }`;
    }

    public onClickCheckBoxIcon(isPrivate: boolean) {
        if (!this.canChangePrivate) {
            return;
        }
        this.isPrivate = isPrivate;
        this.updateCanCreate();
    }

    public onCloseSubmit() {
        const dto = this.data.dto;
        dto.DisplayName = this.displayName;
        dto.Description = this.description;
        dto.IsPrivate = this.isPrivate;
        super.onCloseSubmit();
    }

    public updateCanCreate() {
        const dto = this.data?.dto;
        this.canCreate =
            !!this.displayName &&
            (this.isCreation ||
                this.displayName != dto?.DisplayName ||
                this.description != (dto?.Description ?? '') ||
                this.isPrivate != dto?.IsPrivate);
    }
}
