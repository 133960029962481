import { DiagramDataService } from './diagram/diagram-data.service';
import { DiagramSecurityService } from './diagram/diagram-security.service';
import { EntityStoreBase } from '../shared/entity/EntityStoreBase';
import {
    EntityType,
    IEntityIdentifier,
    IHierarchicalData,
    ServerType,
} from '@datagalaxy/dg-object-model';
import { EntityEventService } from '../shared/entity/services/entity-event.service';
import { EntityService } from '../shared/entity/services/entity.service';
import { SearchUsage } from '../shared/util/DgServerTypesApi';
import { SearchApiService } from '@datagalaxy/webclient/search/data-access';
import { WorkspaceIdentifier } from '@datagalaxy/webclient/workspace/utils';
import { IWorkspaceIdentifier } from '@datagalaxy/webclient/workspace/domain';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';
import { ServerConstants } from '@datagalaxy/shared/server/domain';

/** ## Role
 * Sub component for managing a list of diagram entities
 * */
export class DiagramStore extends EntityStoreBase {
    private spaceIdr: IWorkspaceIdentifier;
    private entityIdr: IEntityIdentifier;

    private get isForSource() {
        return this.entityIdr?.ServerType == ServerType.Model;
    }

    constructor(
        searchApiService: SearchApiService,
        entityEventService: EntityEventService,
        entityService: EntityService,
        private opt: IGenericDiagramStoreOptions = {},
    ) {
        super(
            searchApiService,
            entityEventService,
            entityService,
            opt.onChange,
            opt.debug,
            opt.logId,
        );
    }

    public async init(scopeIdr: IEntityIdentifier | IWorkspaceIdentifier) {
        const idr = scopeIdr as IEntityIdentifier;
        const st = idr?.ServerType;
        if (
            scopeIdr instanceof WorkspaceIdentifier ||
            st == ServerType.Project
        ) {
            this.spaceIdr = WorkspaceIdentifier.from(
                scopeIdr as IWorkspaceIdentifier,
                true,
            );
            this.entityIdr = undefined;
        } else {
            this.spaceIdr = WorkspaceIdentifier.fromEntity(idr, true);
            this.entityIdr = idr;
        }
        if (this.debug) {
            this.log('init', {
                scopeIdr,
                spaceIdr: this.spaceIdr,
                opt: this.opt,
            });
        }
        await super.initInternal(
            [ServerType.Diagram],
            [EntityType.FreeDiagram, EntityType.PhysicalDiagram],
            this.spaceIdr.spaceId,
            this.spaceIdr.versionId,
            SearchUsage.Diagrams,
            {
                specificAttributeKeys: [
                    ServerConstants.Diagram.DiagramKind,
                    ServerConstants.Diagram.PublishingStatus,
                    ServerConstants.Diagram.ObjectLinks_DiagramHasSource,
                    this.entityIdr
                        ? ServerConstants.Diagram.DiagramEntityIds
                        : undefined,
                    ...(this.opt.additionalAttributeKeys ?? []),
                ].filter((o) => o),
                includeSecurityData: true,
                inDiagramEntityId: this.entityIdr?.ReferenceId,
            },
        );
    }
    public getAllDiagramsCount() {
        return super.size;
    }
    public getAllDiagrams() {
        return super
            .getAll()
            .filter(
                (o) =>
                    o.EntityType != EntityType.PhysicalDiagram ||
                    o.getAttributeValue<IHierarchicalData[]>(
                        ServerConstants.Diagram
                            .ObjectLinks_DiagramHasSource_HData,
                    )?.length > 0,
            );
    }
    public getPublicDiagrams() {
        return this.getAllDiagrams().filter(
            DiagramSecurityService.isPublicDiagram,
        );
    }
    public getPrivateDiagrams() {
        return this.getAllDiagrams().filter(
            DiagramSecurityService.isPrivateDiagram,
        );
    }
    public hasPublicDiagrams() {
        return this.getPublicDiagrams().length > 0;
    }
    public hasPrivateDiagrams() {
        return this.getPrivateDiagrams().length > 0;
    }

    public setAdditionalAttributeKeys(keys: string[]) {
        this.opt.additionalAttributeKeys = keys;
    }

    protected isManagedEntity(diagram: EntityItem): boolean {
        if (
            !WorkspaceIdentifier.areSame(
                WorkspaceIdentifier.fromEntity(diagram, true),
                this.spaceIdr,
            )
        ) {
            return false;
        }
        // console.log('isManagedEntity', {
        //     diagram,
        //     entityIdr: this.entityIdr,
        //     hasEntityId: DiagramDataService.hasEntityId(diagram, this.entityIdr?.ReferenceId),
        //     isForSource: this.isForSource,
        //     hasSourceId: DiagramDataService.hasSourceId(diagram, this.entityIdr?.ReferenceId)
        // })
        if (!this.entityIdr) {
            return true;
        }
        const entityId = this.entityIdr.ReferenceId;
        if (DiagramDataService.hasEntityId(diagram, entityId)) {
            return true;
        }
        return (
            this.isForSource &&
            DiagramDataService.hasSourceId(diagram, entityId)
        );
    }
}

export interface IGenericDiagramStoreOptions {
    onChange?: () => Promise<void>;
    additionalAttributeKeys?: string[];

    debug?: boolean;
    logId?: string;
}
