import { Subscription } from 'rxjs';
import {
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { DxyEditGridComponent } from '@datagalaxy/core-ui';
import { EntityUiService } from '../services/entity-ui.service';
import { ServerType } from '@datagalaxy/dg-object-model';
import { ITableColumnsEditGridBridge } from '../../../modeler/table-columns-ui.types';
import { TableColumnsUIService } from '../../../modeler/services/table-columns-ui.service';
import { IDropdownButtonOption } from '../../shared-ui/dropdownButton.types';
import { Column, Model } from '@datagalaxy/webclient/modeler/data-access';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';
import { EntityCreationOrigin } from '@datagalaxy/webclient/entity/feature';
import { DxyDropdownButtonComponent } from '../../shared-ui/dxy-dropdown-button/dxy-dropdown-button.component';
import { TranslateModule } from '@ngx-translate/core';
import { NgIf } from '@angular/common';

/** ## Role
 * UI component for managing a Table's Columns
 *
 * ## Features
 * - add a column to the table
 * - change table's columns model properties (order, nalme, fk, pk, mandatory)
 *
 * ## Controls
 * - button Add Column
 * - in-place-edit grid with reorder by drag
 */
@Component({
    selector: 'app-entity-table-columns',
    templateUrl: 'entity-table-columns.component.html',
    styleUrls: ['entity-table-columns.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        TranslateModule,
        DxyDropdownButtonComponent,
        DxyEditGridComponent,
    ],
})
export class EntityTableColumnsComponent
    extends DxyBaseComponent
    implements OnInit, OnChanges
{
    @Input() entityData: EntityItem;
    @Input() showHeader: boolean;
    @Input() readOnly: boolean;

    public readonly addButtonActions: IDropdownButtonOption[] = [
        {
            callback: () => this.addColumn(),
        },
    ];

    public gridBridge: ITableColumnsEditGridBridge;
    public get hasWriteAccess() {
        return (
            !this.readOnly && !!this.entityData?.SecurityData?.HasWriteAccess
        );
    }

    @ViewChild(DxyEditGridComponent)
    private editGrid: DxyEditGridComponent<Column>;
    private model: Model;
    private tableSubscription: Subscription;
    private get tableId() {
        return this.entityData?.ReferenceId;
    }

    constructor(
        private entityUiService: EntityUiService,
        private tableColumnsUIService: TableColumnsUIService,
    ) {
        super();
    }

    ngOnChanges(changes: SimpleChanges): void {
        super.onChange(changes, 'entityData', () => this.initAsync());
    }
    ngOnInit() {
        this.initAsync();
    }

    private async initAsync() {
        this.log('initAsync', this.entityData);
        this.model = await this.tableColumnsUIService.loadModel(
            this.entityData,
        );

        this.gridBridge = this.tableColumnsUIService.getEditGridBridge(
            this.tableId,
            this.model,
            {
                isReadOnly: () => !this.hasWriteAccess,
                updateGridColDefs: (colDefs) =>
                    this.editGrid.updateColumns(colDefs),
            },
        );

        this.tableSubscription?.unsubscribe();
        this.tableSubscription = super.registerSubscription(
            this.tableColumnsUIService.subscribeTableColumnsChange(
                this.tableId,
                () => this.gridBridge?.updateDataSource(),
            ),
        );
    }

    private async addColumn() {
        this.log('addColumn');
        const useEntityCreateModal = false; //needed: adapt creation modal for column
        if (useEntityCreateModal) {
            await this.entityUiService.onCreateChild(
                this.entityData,
                EntityCreationOrigin.dockingPane,
                ServerType.Column,
            );
        } else {
            await this.tableColumnsUIService.addColumn(
                this.model,
                this.tableId,
            );
        }
    }
}
