import {
    Component,
    ElementRef,
    forwardRef,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { DxyAttributeBaseInput } from '../../DxyAttributeBaseInput';
import { TranslateService } from '@ngx-translate/core';
import { GenericDeserialize } from 'cerialize/dist/serialize';
import { HddUtil } from '../../../../util/HddUtil';
import { HierarchicalData } from '@datagalaxy/dg-object-model';
import { EntityUiService } from '../../../../entity/services/entity-ui.service';
import { EntityType, IHasHddData } from '@datagalaxy/dg-object-model';
import { EntitySelectorData } from '../../../../entitySelector/entity-selector.types';
import { EntityEventService } from '../../../../entity/services/entity-event.service';
import { HierarchyDataDescriptor } from '@datagalaxy/dg-object-model';
import { DxyEntitySelectorFieldComponent } from '../../../../entitySelector/dxy-entity-selector-field/dxy-entity-selector-field.component';
import { SetEntitiesParentResult } from '@datagalaxy/webclient/entity/data-access';
import { WorkspaceIdentifier } from '@datagalaxy/webclient/workspace/utils';
import { IWorkspaceIdentifier } from '@datagalaxy/webclient/workspace/domain';
import {
    EntityItem,
    LinkedDataItem,
} from '@datagalaxy/webclient/entity/domain';
import { DxyUnitaryFieldActionsComponent } from '../../../../fields/unitary/dxy-unitary-field-actions/dxy-unitary-field-actions.component';
import { NgIf } from '@angular/common';
import { FormsModule } from '@angular/forms';

/*
    used in:
    - creation-modal
    - entity-move-modal
    - entity-attribute-input: parent field in entity details page
 */

@Component({
    selector: 'dxy-attribute-logical-parent-input',
    templateUrl: './dxy-attribute-logical-parent-input.component.html',
    standalone: true,
    imports: [
        forwardRef(() => DxyEntitySelectorFieldComponent),
        FormsModule,
        NgIf,
        DxyUnitaryFieldActionsComponent,
    ],
})
export class DxyAttributeLogicalParentInputComponent
    extends DxyAttributeBaseInput<HierarchyDataDescriptor>
    implements OnInit, OnChanges
{
    @Input() entityType: EntityType;

    @ViewChild(DxyEntitySelectorFieldComponent)
    field: DxyEntitySelectorFieldComponent<LinkedDataItem>;

    //#region html bindings
    public item: IHasHddData;
    public get options() {
        return this.selectorData.instance;
    }
    public get canRemove() {
        return this.canDeleteParent || this.isServerError;
    }
    //#endregion

    private readonly selectorData = new EntitySelectorData();
    private canDeleteParent: boolean;
    private isServerError = false;
    private isEditingEntity: boolean;
    private spaceIdr: IWorkspaceIdentifier;
    private get isParentLocked() {
        return this.isAttributeReadOnly;
    }

    constructor(
        private entityEventService: EntityEventService,
        private entityUiService: EntityUiService,
        elementref: ElementRef<HTMLElement>,
        translate: TranslateService,
    ) {
        super(elementref, translate);
    }

    ngOnChanges(changes: SimpleChanges) {
        super.onChange(changes, 'entityType', () =>
            this.onLocalEntityTypeChanged(),
        );
    }

    ngOnInit() {
        this.spaceIdr ??=
            WorkspaceIdentifier.from(
                this.fieldInfo?.entityForm.getSpaceIdr(),
            ) ??
            WorkspaceIdentifier.fromEntity(this.getActualEntityData(), true);
        this.entityType ??= this.fieldInfo?.entityForm.getLocalEntityType?.();
        super.subscribe(this.fieldInfo?.entityForm.entityTypeChange$, () =>
            this.onLocalEntityTypeChanged(),
        );
        this.isEditingEntity = !this.entityType && this.isGenericEntityForm;

        if (this.isParentLocked) {
            this.initItem();
        } else if (!this.entityType) {
            const entity = this.getEntityData();
            if (entity) {
                super.registerSubscription(
                    this.entityEventService.subscribeEntityParentUpdate(
                        entity.ServerType,
                        (data) => {
                            if (
                                data
                                    .getMovedEntities()
                                    .some(
                                        (e) =>
                                            e.DataReferenceId ==
                                            entity.DataReferenceId,
                                    )
                            ) {
                                this.initItem();
                            }
                        },
                    ),
                );
            }
        }

        super.ngOnInit();
        this.init();
    }

    //#region html bindings

    public async onSelected(resultItem: EntityItem) {
        this.isServerError = false;
        if (this.hasInternalError) {
            if (!resultItem) {
                super.undo();
            } else {
                // TODO: Reset correct initial state
                this.resetMessages();
            }
        }
        const data = resultItem
            ? resultItem.HddData.Data
            : this.isEditingEntity
              ? new HierarchyDataDescriptor(this.spaceIdr.spaceId)
              : null;
        await super.setData(data);
        this.selectorData.setMinChars(1);
    }

    //#endregion

    //#region IAttributeBaseInputOverride

    public onAfterUndo() {
        this.resetData();
        this.isServerError = false;
    }

    public isDirty() {
        const data = this.getData(),
            initialValue = this.getInitialValue();
        return !(
            (!initialValue && !data) ||
            (initialValue &&
                data &&
                initialValue.DataReferenceId &&
                data.DataReferenceId &&
                initialValue.DataReferenceId == data.DataReferenceId)
        );
    }

    public async onServerSuccess(result: any) {
        if (result instanceof SetEntitiesParentResult) {
            const data = this.getData();
            const dataAsHdd = data && HddUtil.deserializeHdd(data);
            if (dataAsHdd) {
                const parentHdd = result.UpdatedEntities.find(
                    (e) => e.DataReferenceId == dataAsHdd.DataReferenceId,
                )?.HddData.Data;
                if (parentHdd) {
                    await super.setData(parentHdd);
                }
            }
            this.setExcludedIds();
            this.resetInitialValue();
        }
    }

    public onServerError() {
        this.isServerError = true;
    }

    //#endregion

    //#region private

    private initItem() {
        const hdd = GenericDeserialize(this.getData(), HierarchyDataDescriptor);
        this.item = hdd && { HddData: new HierarchicalData(hdd, []) };
    }
    private clearItem() {
        this.item = null;
    }

    private getActualEntityData() {
        return this.getEntityDataList()?.[0];
    }

    private onLocalEntityTypeChanged() {
        this.entityType = this.fieldInfo?.entityForm.getLocalEntityType?.();
        if (!this.isParentLocked) {
            this.clearItem();
        }
        this.init();
    }

    private setExcludedIds() {
        const entityData = this.getActualEntityData();
        const excludedIds =
            entityData && this.isEditingEntity
                ? [entityData.DataReferenceId]
                : [];
        const data = this.getData();
        if (data) {
            excludedIds.push(data.DataReferenceId);
        }
        if (this.isBulkForm) {
            const entities = this.getEntityDataList();
            if (entities?.length) {
                excludedIds.push(...entities.map((a) => a.ReferenceId));
            }
        }
        this.selectorData.setExcludedIds(excludedIds);
    }

    private init() {
        this.canDeleteParent = !!this.entityType;
        if (this.isParentLocked) {
            return;
        }

        this.entityType ??= this.getActualEntityData().EntityType;
        this.canDeleteParent = this.entityUiService.canDeleteParent(
            this.entityType,
        );

        this.selectorData.setSpaceIdr(this.spaceIdr);
        this.selectorData.setServerTypesAndIncludedEntityTypesFromEntityType(
            this.entityType,
        );
        this.selectorData.updateInstance();

        this.resetData();
    }

    private resetData() {
        if (this.getData()) {
            this.selectorData.setMinChars(1);
            this.initItem();
        } else {
            this.selectorData.setMinChars(0);
            this.clearItem();
        }

        this.setExcludedIds();
    }

    //#endregion
}
