import {
    EntityType,
    EntityTypeUtil,
    HierarchicalData,
    IDataIdentifier,
    IEntityIdentifier,
    IHasHddData,
    ObjectLinkType,
    ServerType,
} from '@datagalaxy/dg-object-model';
import {
    autoserializeAs,
    deserialize,
    deserializeAs,
    GenericDeserialize,
    SerializableEnumeration,
} from 'cerialize';
import { SuggestionElement } from '@datagalaxy/webclient/suggestion/types';
import {
    AttributeMetaInfo,
    AttributeMetaType,
    AttributeObjectValue,
    EntityLifecycleStatus,
    IManagedTag,
    TextQualityUserVoteDto,
    TimeSeriesObject,
} from '@datagalaxy/webclient/attribute/domain';
import { LinkedDataItem } from './entity-link';
import { EntityTypeMeta } from './entity-type-meta';
import {
    EntitySecurityData,
    IHasSecurityData,
} from '@datagalaxy/webclient/security/domain';
import { EntitySocialData } from '@datagalaxy/webclient/social/domain';
import { ServerConstants } from '@datagalaxy/shared/server/domain';
import PropertyName = ServerConstants.PropertyName;

/** IEntityIdentifier with SecurityData, and optional SocialData */
export interface IMiniEntityContent
    extends IEntityIdentifier,
        IHasSecurityData {
    SocialData?: EntitySocialData;
}

export enum HddDataKind {
    None,
    Both,
    PathFull,
    PhysicalFull,
    PhysicalEmulationModeler,
}
SerializableEnumeration(HddDataKind);

export class EntityItem
    implements
        IDataIdentifier,
        IMiniEntityContent,
        IHasHddData,
        IHasSecurityData
{
    @deserialize public ReferenceId!: string;
    @deserialize public VersionId!: string;

    //#Archi - TODO deserialize values to have proper js types
    /** Instead of *Attributes[attributeKey]*, use getAttributeValue and setAttributeValue */
    @deserialize public Attributes!: object;
    @deserialize public DataTypeName!: string;

    @deserialize public ContextualAllLevelChildrenCount!: number;
    @deserialize public OriginalSortIndex!: number;
    @deserialize public Score?: number;

    @autoserializeAs(HddDataKind) public HddDataKind!: HddDataKind;

    @deserializeAs(EntitySecurityData) public SecurityData!: EntitySecurityData;
    @deserialize public IsWatchedByCurrentUser?: boolean;
    @deserializeAs(HierarchicalData) public HddDataPath!: HierarchicalData;
    @deserializeAs(HierarchicalData, 'HddData')
    public _HddData!: HierarchicalData;
    @deserializeAs(EntitySocialData) public SocialData!: EntitySocialData;
    @deserialize public EntitySuggestions!: SuggestionElement[];
    @deserialize public HasWritableTechnologyCode?: boolean;
    @deserialize public TextQualityUserVoteDtos!: TextQualityUserVoteDto[];

    // Warning: This is not deserialized from Server, but rather the value is set when loading an individual Entity
    public Meta?: EntityTypeMeta;

    public static getDeserializedLinkedDataItems(values: unknown[]) {
        return values?.map((v) => GenericDeserialize(v, LinkedDataItem));
    }

    public get HddData() {
        return this.HddDataPath || this._HddData;
    }

    public setManualHddData(hddData: HierarchicalData) {
        this.HddDataPath = hddData;
        this._HddData = hddData;
    }

    public get HasContextualAllLevelChildrenCount() {
        return this.ContextualAllLevelChildrenCount > 0;
    }

    public get LocalId() {
        return this.ReferenceId.split(':')[1];
    }

    public get ContextId() {
        return this.ReferenceId.split(':')[0];
    }

    /** get entity parent id if exists, otherwise (parent is the project), return undefined */
    public get entityParentId() {
        return this.HddData?.Parents.length > 1
            ? this.HddData?.Parents[0]?.DataReferenceId
            : undefined;
    }

    public get DisplayName() {
        return (
            this.getAttributeValue<string>('DisplayName') ||
            this.HddData?.Data?.DisplayName ||
            ''
        );
    }

    public set DisplayName(newValue: string) {
        this.setAttributeValue('DisplayName', newValue);
    }

    public get Description() {
        return this.getAttributeValue<string>('Description');
    }

    public set Description(newValue: string) {
        this.setAttributeValue('Description', newValue);
    }

    public get TechnicalName() {
        return (
            this.getAttributeValue<string>('TechnicalName') ||
            this.HddData?.Data?.TechnicalName ||
            ''
        );
    }

    public set TechnicalName(newValue: string) {
        this.setAttributeValue('TechnicalName', newValue);
    }

    public get isGdpr() {
        return this.getAttributeValue<boolean>('IsGdpr');
    }

    public get tags() {
        return this.getAttributeValue<IManagedTag[]>('DomainsObjectValues');
    }

    public get Type() {
        return this.ServerType;
    }

    public get TypeName() {
        return this.DataTypeName;
    }

    public get SubTypeName() {
        return (
            this.getAttributeValue<string>('Type') ||
            this.HddData?.Data?.SubTypeName
        );
    }

    public get CreationTime() {
        return this.getAttributeValue<string>(
            ServerConstants.PropertyName.CreationTime,
        );
    }

    public get CreationUserId() {
        return this.getAttributeValue<string>(
            ServerConstants.PropertyName.CreationUserId,
        );
    }

    public get LastModificationTime() {
        return this.getAttributeValue<string>(
            ServerConstants.PropertyName.LastModificationTime,
        );
    }

    public get LastModificationUserId() {
        return this.getAttributeValue<string>(
            ServerConstants.PropertyName.LastModificationUserId,
        );
    }

    public get LogicalPathParentIds() {
        return this.getAttributeValue<string[]>('LogicalPathParentIds') ?? [];
    }

    public get ServerType() {
        return ServerType[this.DataTypeName as keyof typeof ServerType];
    }

    public get DataServerType() {
        return this.ServerType;
    }

    public get EntityType() {
        const entityType = this.getAttributeValue<EntityType>(
            PropertyName.EntityType,
        );

        if (entityType != undefined) {
            return entityType;
        }
        return EntityTypeUtil.getEntityType(this.TypeName, this.SubTypeName);
    }

    public get DataReferenceId() {
        return this.ReferenceId;
    }

    public get LogicalParentId() {
        return this.getAttributeValue<string>('LogicalParentId');
    }

    public get Status(): EntityLifecycleStatus | null {
        const value = this.getAttributeValue<string>(PropertyName.EntityStatus);
        return typeof value == 'string'
            ? EntityLifecycleStatus[value as keyof typeof EntityLifecycleStatus]
            : null;
    }

    public get entityType() {
        return this.EntityType;
    }

    public get isSpace() {
        return this.ServerType == ServerType.Project;
    }

    public getAttributeValue<T = unknown>(attributeKey: string): T {
        return this.Attributes[attributeKey as keyof object] as T;
    }

    public setAttributeValue<T>(attributeKey: string, newValue: T) {
        (this.Attributes[attributeKey as keyof object] as T) = newValue;
    }

    public getAttributeLinkList(linkType: ObjectLinkType): string[] {
        return this.getAttributeValue<string[]>(
            `ObjectLinks_${ObjectLinkType[linkType]}`,
        );
    }

    public getDataStewards() {
        return this.getAttributeValue<string[]>('DataStewards');
    }

    public getDataOwners() {
        return this.getAttributeValue<string[]>('DataOwners');
    }

    public getLinkedDataItems(attributeKey: string): LinkedDataItem[] {
        return EntityItem.getDeserializedLinkedDataItems(
            this.Attributes[attributeKey as keyof object] as any,
        );
    }

    /**
     * This getter verifies the Attribute definition is for the current EntityItem.ServerType
     * It is used mainly in the context of a multi-type grid such as EntityGrid in Catalog Module,
     * where Column Definitions
     * can be specific to a limited list of Server Types.
     *
     * Then it returns the value as the correct form, depending on the AttributeType
     *
     * */
    public getTypedAttributeValue(attributeMeta: AttributeMetaInfo) {
        if (
            (this.EntityType &&
                attributeMeta.ExcludedEntityTypes?.includes(this.EntityType)) ||
            (attributeMeta.IsCdp &&
                !attributeMeta.IsAllTypes &&
                this.ServerType != attributeMeta.serverType)
        ) {
            return undefined;
        }

        const rawValue = this.getAttributeValue(attributeMeta.AttributeKey);

        if (!rawValue) {
            return rawValue;
        }

        switch (attributeMeta.AttributeKey) {
            case PropertyName.EntityStatus: {
                if (typeof rawValue == 'string') {
                    return EntityLifecycleStatus[
                        rawValue as keyof typeof EntityLifecycleStatus
                    ];
                }
            }
        }

        switch (attributeMeta.AttributeType) {
            case AttributeMetaType.TimeSeriesObject:
            case AttributeMetaType.TimeSeriesLastEntries:
            case AttributeMetaType.TimeSeriesLastEntry: {
                if (typeof rawValue == 'string') {
                    return JSON.parse(rawValue) as TimeSeriesObject;
                }

                if (
                    rawValue instanceof TimeSeriesObject ||
                    rawValue instanceof Object
                ) {
                    return rawValue;
                }

                return null;
            }
            case AttributeMetaType.ObjectValueList:
                return <AttributeObjectValue[]>rawValue;
            default:
                return rawValue;
        }
    }
}
