import {
    createDefaultImageReader,
    createDefaultImageWriter,
    DokaImageEditorOptions,
    DokaTargetSize,
    ImageSource,
    locale_en_gb,
    plugin_crop,
    plugin_crop_defaults,
    plugin_crop_locale_en_gb,
    plugin_filter,
    plugin_filter_defaults,
    plugin_filter_locale_en_gb,
    plugin_finetune,
    plugin_finetune_defaults,
    plugin_finetune_locale_en_gb,
    plugin_resize,
    plugin_resize_defaults,
    plugin_resize_locale_en_gb,
    processImage,
    setPlugins,
    Shape,
} from 'doka';
import locale_fr_fr from 'doka/locale/core/fr_fr';
import plugin_crop_locale_fr_fr from 'doka/locale/crop/fr_fr';
import plugin_filter_locale_fr_fr from 'doka/locale/filter/fr_fr';
import plugin_finetune_locale_fr_fr from 'doka/locale/finetune/fr_fr';
import plugin_resize_locale_fr_fr from 'doka/locale/resize/fr_fr';
import {
    CropMode,
    IShapes,
    IShapesState,
    IDestState,
    TAllowedMimeType,
} from './doka-helper.types';

/** static helper methods for doka image editor */
export class DokaHelper {
    //https://pqina.nl/doka/docs/v7
    //https://www.npmjs.com/package/heic2any

    public static getImageDataForDB(imageFile: File) {
        return URL.createObjectURL(imageFile);
    }

    public static registerPlugins() {
        setPlugins(plugin_crop, plugin_filter, plugin_finetune, plugin_resize);
    }

    public static makeLocale(languageCode: string, mini: boolean): any {
        switch (languageCode?.toLowerCase()) {
            case 'fr': {
                const base = {
                    ...locale_fr_fr,
                    ...plugin_crop_locale_fr_fr,
                };
                return mini
                    ? base
                    : Object.assign(
                          base,
                          plugin_filter_locale_fr_fr,
                          plugin_finetune_locale_fr_fr,
                          plugin_resize_locale_fr_fr,
                      );
            }
            default: {
                const base = {
                    ...locale_en_gb,
                    ...plugin_crop_locale_en_gb,
                };
                return mini
                    ? base
                    : Object.assign(
                          base,
                          plugin_filter_locale_en_gb,
                          plugin_finetune_locale_en_gb,
                          plugin_resize_locale_en_gb,
                      );
            }
        }
    }

    public static makeOptions(opt?: {
        //#region dg options
        /** 'fr', 'en' (default) */
        languageCode?: string;
        /** setup for overlay mode (no button bars) */
        mini?: boolean;
        /** remove resize button */
        noResize?: boolean;
        cropMode?: CropMode;
        targetSize?: number;
        targetUpscale?: boolean;
        forcedRatio?: number;
        //#endregion

        //#region doka options
        src?: ImageSource;
        imageWriter?: any[];
        imageState?: any;
        enableButtonClose?: boolean;
        //#endregion
    }): DokaImageEditorOptions {
        const mini = opt?.mini;
        const res = {
            src: opt?.src,
            locale: DokaHelper.makeLocale(opt?.languageCode, mini),
            imageWriter: opt?.imageWriter ?? createDefaultImageWriter(),
            imageState: opt?.imageState,
            enableButtonClose: opt?.enableButtonClose,
            imageReader: createDefaultImageReader(),
            utils: ['crop'],
            ...plugin_crop_defaults,
        };
        if (!mini) {
            res.utils.push('filter', 'finetune');
            Object.assign(
                res,
                plugin_filter_defaults,
                plugin_finetune_defaults,
            );
            if (!opt?.noResize) {
                res.utils.push('resize');
                Object.assign(res, plugin_resize_defaults);
            }
        }
        if (opt?.forcedRatio) {
            opt.cropMode = CropMode.square;
        }

        switch (opt?.cropMode) {
            case CropMode.free:
                DokaHelper.setupNoCropShapes(res);
                break;
            case CropMode.round:
                DokaHelper.setupCropRoundOnly(res);
                break;
            case CropMode.square:
                DokaHelper.setupCropSquareOnly(res, opt?.forcedRatio);
                break;
        }
        if (opt?.targetSize) {
            res.imageTargetSize = DokaHelper.makeTargetSize(
                opt.targetSize,
                opt.targetUpscale,
            );
        }
        return res;
    }

    /** setup options to have no crop shape dropdown */
    public static setupNoCropShapes(opt: DokaImageEditorOptions) {
        // remove preset dropwdown
        opt.cropEnableSelectPreset = false;
    }
    /** setup options to have a square shape dropdown or if forcedRatio is specified a rect shape dropdown */
    public static setupCropSquareOnly(
        opt: DokaImageEditorOptions,
        forcedRatio?: number,
    ) {
        // remove preset dropwdown
        opt.cropEnableSelectPreset = false;

        // keep only square crop shape
        opt.imageCropAspectRatio = forcedRatio ?? 1;
    }

    /** setup options to have only a circular crop mask, and no crop shape dropdown */
    public static setupCropRoundOnly(opt: DokaImageEditorOptions) {
        // remove preset dropwdown
        opt.cropEnableSelectPreset = false;

        // keep only square crop shape
        opt.imageCropAspectRatio = 1;

        // draw the ellipse shape
        opt.willRenderCanvas = (shapes: IShapes, state: IShapesState) => {
            const { x, y, width, height } = state.selectionRect;

            // return updated UI shapes list
            return {
                // copy other shape lists
                ...shapes,

                // add an `ellipse` shape
                interfaceShapes: [
                    {
                        x: x + width * 0.5,
                        y: y + height * 0.5,
                        rx: width * 0.5,
                        ry: height * 0.5,
                        opacity: state.opacity,
                        inverted: true,
                        backgroundColor: [0, 0, 0, 0.5],
                        strokeWidth: 1,
                        strokeColor: [1, 1, 1],
                    } as any as Shape,
                    ...shapes.interfaceShapes,
                ],
            };
        };
        return opt;
    }

    public static createSizeMaxImageWriter(sizeMaxPx = 500, upscale = false) {
        return createDefaultImageWriter({
            copyImageHead: false, // Don't retain image EXIF data
            targetSize: DokaHelper.makeTargetSize(sizeMaxPx, upscale),
        });
    }

    /** returns an imageWriter that creates a thumbnail file along with the main file */
    public static createThumbnailImageWriter(
        withFiles: (main: File, thumbnail: File) => Promise<void>,
        mainSizeMaxPx = 500,
        thumbnailSizeMaxPx = 60,
        upscale = false,
        /** For the main image. For the thumbnail, it will be 'image/png'. */
        mimeType?: TAllowedMimeType,
    ) {
        return createDefaultImageWriter({
            copyImageHead: false, // Don't retain image EXIF data
            targetSize: DokaHelper.makeTargetSize(mainSizeMaxPx, upscale),
            mimeType,
            store: async (stateMain: IDestState) => {
                await processImage(stateMain.dest, {
                    locale: null,
                    imageReader: createDefaultImageReader(),
                    imageWriter: createDefaultImageWriter({
                        mimeType: 'image/png',
                        copyImageHead: false,
                        targetSize: DokaHelper.makeTargetSize(
                            thumbnailSizeMaxPx,
                            upscale,
                        ),
                        store: async (stateThumb: IDestState) => {
                            await withFiles(stateMain.dest, stateThumb.dest);
                            return stateThumb;
                        },
                    }),
                });
                return stateMain;
            },
        });
    }

    public static makeTargetSize(
        maxSizePx: number,
        upscale: boolean,
    ): DokaTargetSize {
        return {
            width: maxSizePx,
            height: maxSizePx,
            fit: 'contain',
            upscale,
        };
    }
}
