import {
    deserialize,
    inheritSerialization,
    SerializableEnumeration,
} from 'cerialize';
import { BaseDTO } from '@datagalaxy/dg-object-model';

export enum UpdateTimeSeriesAttributeEntryAction {
    Unknown,
    DeleteEntry,
    AddEntry,
    UpdateEntry,
}
SerializableEnumeration(UpdateTimeSeriesAttributeEntryAction);

@inheritSerialization(BaseDTO)
export class UpdateTimeSeriesEntryDto {
    @deserialize public Date!: string;
    @deserialize public Value!: number;
    @deserialize public OldDate?: string;
    @deserialize public OldValue?: number;
    @deserialize public Action!: UpdateTimeSeriesAttributeEntryAction;
}
