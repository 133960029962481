import { CoreUtil } from '@datagalaxy/core-util';
import { IContextMenuActionProvider, IListOption } from '.';

/** ## Role
 * Burger-menu actions provider supporting *IListOption*s as input */
export class ListOptionBurgerMenuActionProvider<T>
    implements IContextMenuActionProvider<T, void, T>
{
    public onExecute: () => void;

    constructor(private actions: IListOption<T, T>[]) {}

    public getOptions(item: T): IListOption<T, T>[] {
        this.actions.forEach((a) => (a.data = item));
        return this.actions;
    }
    public isAvailableFor(item: T): boolean {
        return this.actions.some(
            (a) => !CoreUtil.fromFnOrValue(a.hidden, item),
        );
    }
}
