import { Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { BaseService } from '@datagalaxy/core-ui';
import { Injectable } from '@angular/core';
import {
    EntityCommentaryDTO,
    EntityTaskDTO,
    IDataIdentifier,
    ServerType,
} from '@datagalaxy/dg-object-model';
import { RealTimeCommService } from '../../../services/realTimeComm.service';
import {
    DeleteEntityParameter,
    SetEntitiesParentResult,
    UpdateEntityLinkResult,
} from '@datagalaxy/webclient/entity/data-access';
import { DeleteEntityTasksParameter } from '@datagalaxy/webclient/task/data-access';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';
import { AttributeValueTranslationService } from '@datagalaxy/webclient/multilingual/feature';
import {
    EntityCreatedEvent,
    EntityCreator,
    IEntityCreationContext,
} from '@datagalaxy/webclient/entity/feature';
import { EntityEventDispatcher } from '@datagalaxy/webclient/entity/events';

@Injectable({ providedIn: 'root' })
export class EntityEventService extends BaseService {
    private entityChanged = new Subject<IServerTypeEventArg<EntityItem>>();
    private entityCreated = new Subject<
        IServerTypeEventArg<EntityCreatedEvent>
    >();
    private entityUpdated = new Subject<IServerTypeEventArg<EntityItem>>();
    private entityTechnologyUpdated = new Subject<
        IServerTypeEventArg<EntityItem>
    >();
    private entityBulkUpdated = new Subject<
        IServerTypeEventArg<EntityItem[]>
    >();
    private entityDeleted = new Subject<
        IServerTypeEventArg<EntityDeleteEventData>
    >();
    private entityParentUpdated = new Subject<
        IServerTypeEventArg<SetEntitiesParentResult>
    >();
    private entitySecurityUpdated = new Subject<
        IServerTypeEventArg<EntityItem>
    >();

    private entityLinkCreated = new Subject<IServerTypeEventArg<EntityItem>>();
    private entityLinkIdentifierAdded = new Subject<
        IServerTypeEventArg<IDataIdentifier>
    >();
    private entityLinkDeleted = new Subject<IServerTypeEventArg<EntityItem>>();
    private entityLinkIdentifierDeleted = new Subject<
        IServerTypeEventArg<IDataIdentifier>
    >();
    private entityLinkGoldenUpdated = new Subject<
        IRealtimeEventArg<UpdateEntityLinkResult>
    >();

    private taskCreated = new Subject<IRealtimeEventArg<EntityTaskDTO>>();
    private taskUpdated = new Subject<IRealtimeEventArg<EntityTaskDTO>>();
    private taskDeleted = new Subject<
        IRealtimeEventArg<DeleteEntityTasksParameter>
    >();

    private commentCreated = new Subject<
        IRealtimeEventArg<EntityCommentaryDTO>
    >();

    private entityFieldsUpdated = new Subject<EntityItemEventArg>();

    constructor(
        private realTimeCommService: RealTimeCommService,
        private attributeValueTranslationService: AttributeValueTranslationService,
        private entityCreator: EntityCreator,
        private entityEventDispatcher: EntityEventDispatcher,
    ) {
        super();
        this.subscribeExternalEntityEvents();
        this.subscribeExternalTaskEvents();
        this.subscribeToTranslationEvents();
        this.subscribeToEntityCreatorEvents();
        this.subscribeToEntityEventDispatcher();
    }

    //#region EntityItem

    /** when a different entity is selected in the dashboard.
     *
     * Note: For detecting changes on an entity, use *subscribeEntityUpdate* instead */
    public subscribeEntityChange(
        serverType: ServerType,
        handler: IEntityItemEventHandler,
    ) {
        return this.subscribeEventFiltered(
            this.entityChanged,
            serverType,
            handler,
        );
    }
    public notifyEntityChange(entity: EntityItem, external = false) {
        this.entityChanged.next(new EntityItemEventArg(entity, external));
    }

    public subscribeEntityCreationLocal(
        serverType: ServerType,
        handler: IEntityCreationEventHandler<EntityItem>,
    ) {
        return this.subscribeEntityCreation(
            serverType,
            (data, external) =>
                !external && handler(data.entity, false, data.context),
        );
    }
    public subscribeEntityCreation(
        serverType: ServerType,
        handler: IRealTimeEventHandler<EntityCreatedEvent>,
    ) {
        return serverType === null
            ? this.subscribeEvent(this.entityCreated, handler)
            : this.subscribeEventFiltered(
                  this.entityCreated,
                  serverType,
                  handler,
              );
    }
    public notifyEntityCreation(
        entityCreateEvent: EntityCreatedEvent,
        external = false,
    ) {
        this.entityCreated.next(
            new ServerTypeFilteredEventArg(
                entityCreateEvent.entity.ServerType,
                entityCreateEvent,
                external,
            ),
        );
    }

    /** Note: *null* (not *undefined*) ServerType will subscribe to every ServerType values */
    public subscribeEntityUpdate(
        serverType: ServerType | null,
        handler: IEntityItemEventHandler,
    ) {
        return serverType === null
            ? this.subscribeEvent(this.entityUpdated, handler)
            : this.subscribeEventFiltered(
                  this.entityUpdated,
                  serverType,
                  handler,
              );
    }
    public notifyEntityUpdate(entity: EntityItem, external = false) {
        this.entityUpdated.next(new EntityItemEventArg(entity, external));
    }

    public subscribeEntityTechnologyUpdate(
        serverType: ServerType | null,
        handler: IEntityItemEventHandler,
    ) {
        return serverType === null
            ? this.subscribeEvent(this.entityTechnologyUpdated, handler)
            : this.subscribeEventFiltered(
                  this.entityTechnologyUpdated,
                  serverType,
                  handler,
              );
    }
    public notifyEntityTechnologyUpdate(entity: EntityItem, external = false) {
        this.entityTechnologyUpdated.next(
            new EntityItemEventArg(entity, external),
        );
    }

    public subscribeEntityBulkUpdate(
        serverType: ServerType,
        handler: IRealTimeEventHandler<EntityItem[]>,
    ) {
        return serverType === null
            ? this.subscribeEvent(this.entityBulkUpdated, handler)
            : this.subscribeEventFiltered(
                  this.entityBulkUpdated,
                  serverType,
                  handler,
              );
    }
    public notifyEntityBulkUpdateEvent(
        entities: EntityItem[],
        external = false,
    ) {
        if (!entities.length) {
            return;
        }
        this.entityBulkUpdated.next(
            new ServerTypeFilteredEventArg(
                entities[0].ServerType,
                entities,
                external,
            ),
        );
    }

    public subscribeEntityDelete(
        serverType: ServerType | null,
        handler: IRealTimeEventHandler<EntityDeleteEventData>,
    ) {
        return serverType === null
            ? this.subscribeEvent(this.entityDeleted, handler)
            : this.subscribeEventFiltered(
                  this.entityDeleted,
                  serverType,
                  handler,
              );
    }
    public notifyEntityDelete(
        serverType: ServerType,
        entityEventData: EntityDeleteEventData,
        external = false,
    ) {
        this.entityDeleted.next(
            new ServerTypeFilteredEventArg(
                serverType,
                entityEventData,
                external,
            ),
        );
    }

    public subscribeEntityParentUpdate(
        serverType: ServerType,
        handler: IRealTimeEventHandler<SetEntitiesParentResult>,
    ) {
        return this.subscribeEventFiltered(
            this.entityParentUpdated,
            serverType,
            handler,
        );
    }
    public notifyEntityParentUpdate(
        setEntitiesParentResult: SetEntitiesParentResult,
        external = false,
    ) {
        this.entityParentUpdated.next(
            new ServerTypeFilteredEventArg(
                setEntitiesParentResult.UpdatedEntities[0].ServerType,
                setEntitiesParentResult,
                external,
            ),
        );
    }

    public subscribeEntitySecurityUpdate(
        serverType: ServerType,
        handler: IEntityItemEventHandler,
    ) {
        return this.subscribeEventFiltered(
            this.entitySecurityUpdated,
            serverType,
            handler,
        );
    }
    public notifyEntitySecurityUpdate(entity: EntityItem, external = false) {
        this.entitySecurityUpdated.next(
            new EntityItemEventArg(entity, external),
        );
    }

    //#endregion EntityItem

    //#region EntityLink

    public subscribeEntityLinkAdd(
        serverType: ServerType,
        handler: IDataIdentifierEventHandler<EntityItem>,
    ) {
        return serverType === null
            ? this.subscribeEvent(this.entityLinkCreated, handler)
            : this.subscribeEventFiltered(
                  this.entityLinkCreated,
                  serverType,
                  handler,
              );
    }
    public notifyEntityLinkAdd(updatedEntity: EntityItem, external = false) {
        this.log('notifyEntityLinkAdd', external, updatedEntity);
        this.entityLinkCreated.next(
            new EntityItemEventArg(updatedEntity, external),
        );
    }

    public subscribeEntityLinkUpdateGoldenLink(
        handler: IRealTimeEventHandler<UpdateEntityLinkResult>,
    ) {
        return this.subscribeEvent(this.entityLinkGoldenUpdated, handler);
    }
    public notifyEntityLinkUpdateGoldenLink(
        updatedEntityLinks: UpdateEntityLinkResult,
        external = false,
    ) {
        this.log(
            'notifyEntityLinkUpdateGoldenLink',
            external,
            updatedEntityLinks,
        );
        this.entityLinkGoldenUpdated.next(
            new RealTimeEventArg(updatedEntityLinks, false),
        );
    }

    public subscribeEntityLinkIdentifierAdd(
        serverType: ServerType,
        handler: IDataIdentifierEventHandler<IDataIdentifier>,
    ) {
        return this.subscribeEventFiltered(
            this.entityLinkIdentifierAdded,
            serverType,
            handler,
        );
    }
    public notifyEntityLinkIdentifierAdd(
        updatedIdentifier: IDataIdentifier,
        external = false,
    ) {
        const st = ServerType[updatedIdentifier.DataTypeName];
        this.log(
            'notifyEntityLinkIdentifierAdd',
            ServerType[st],
            external,
            updatedIdentifier,
        );
        this.entityLinkIdentifierAdded.next(
            new ServerTypeFilteredEventArg(st, updatedIdentifier, external),
        );
    }

    public subscribeEntityLinkDelete(
        serverType: ServerType,
        handler: IDataIdentifierEventHandler<EntityItem>,
    ) {
        return serverType === null
            ? this.subscribeEvent(this.entityLinkDeleted, handler)
            : this.subscribeEventFiltered(
                  this.entityLinkDeleted,
                  serverType,
                  handler,
              );
    }
    public notifyEntityLinkDelete(updatedEntity: EntityItem, external = false) {
        this.log('notifyEntityLinkDelete', external, updatedEntity);
        this.entityLinkDeleted.next(
            new EntityItemEventArg(updatedEntity, external),
        );
    }

    public subscribeEntityLinkIdentifierDelete(
        serverType: ServerType,
        handler: IDataIdentifierEventHandler<IDataIdentifier>,
    ) {
        return this.subscribeEventFiltered(
            this.entityLinkIdentifierDeleted,
            serverType,
            handler,
        );
    }
    public notifyEntityLinkDeleteIdentifier(
        updatedIdentifier: IDataIdentifier,
        external = false,
    ) {
        this.log(
            'notifyEntityLinkDeleteIdentifier',
            external,
            updatedIdentifier,
        );
        this.entityLinkIdentifierDeleted.next(
            new ServerTypeFilteredEventArg(
                ServerType[updatedIdentifier.DataTypeName],
                updatedIdentifier,
                external,
            ),
        );
    }

    //#endregion EntityLink

    //#region ObjectTask

    public subscribeTaskCreation(
        handler: IRealTimeEventHandler<EntityTaskDTO>,
    ) {
        return this.subscribeEvent(this.taskCreated, handler);
    }
    public notifyTaskCreation(task: EntityTaskDTO, external = false) {
        this.taskCreated.next(new RealTimeEventArg(task, external));
    }

    public subscribeTaskUpdate(handler: IRealTimeEventHandler<EntityTaskDTO>) {
        return this.subscribeEvent(this.taskUpdated, handler);
    }
    public notifyTaskUpdate(task: EntityTaskDTO, external = false) {
        this.taskUpdated.next(new RealTimeEventArg(task, external));
    }

    public subscribeTaskDelete(
        handler: IRealTimeEventHandler<DeleteEntityTasksParameter>,
    ) {
        return this.subscribeEvent(this.taskDeleted, handler);
    }
    public notifyTaskDelete(
        deleteParam: DeleteEntityTasksParameter,
        external = false,
    ) {
        this.taskDeleted.next(new RealTimeEventArg(deleteParam, external));
    }

    //#endregion ObjectTask

    //#region Commentary

    public subscribeCommentaryCreation(
        handler: IRealTimeEventHandler<EntityCommentaryDTO>,
    ) {
        return this.subscribeEvent(this.commentCreated, handler);
    }
    public notifyCommentaryCreation(
        comment: EntityCommentaryDTO,
        external = false,
    ) {
        this.commentCreated.next(new RealTimeEventArg(comment, external));
    }

    //#endregion Commentary

    //#region EntityField

    public subscribeEntityFieldsUpdate(
        serverType: ServerType,
        handler: IEntityItemEventHandler,
    ) {
        return this.subscribeEventFiltered(
            this.entityFieldsUpdated,
            serverType,
            handler,
        );
    }

    public notifyEntityFieldsUpdate(
        updatedEntity: EntityItem,
        external = false,
    ) {
        this.entityFieldsUpdated.next(
            new EntityItemEventArg(updatedEntity, external),
        );
    }

    //#endregion EntityField

    private subscribeEventFiltered<T>(
        observable: Observable<IServerTypeEventArg<T>>,
        serverType: ServerType,
        handler: (data: T, external?: boolean) => void,
    ) {
        const filtered = observable.pipe(
            filter((eventArg) => eventArg.serverType === serverType),
        );
        return this.subscribeEvent(filtered, handler);
    }

    private subscribeEvent<T>(
        observable: Observable<IRealtimeEventArg<T>>,
        handler: (data: T, external?: boolean) => void,
    ) {
        return observable.subscribe({
            next: (eventArg) => handler(eventArg.data, eventArg.external),
        });
    }

    private subscribeExternalEntityEvents() {
        this.realTimeCommService.subscribeDeleteEntityEvent(
            (
                _userData: unknown,
                deleteEntityParameter: DeleteEntityParameter,
            ) =>
                this.notifyEntityDelete(
                    ServerType[deleteEntityParameter.DataTypeName],
                    new EntityDeleteEventData(
                        deleteEntityParameter.DataReferenceIdList,
                    ),
                    true,
                ),
        );

        this.realTimeCommService.subscribeCreateEntityEvent(
            (_userData: unknown, createdEntity: EntityItem) =>
                this.notifyEntityCreation(
                    new EntityCreatedEvent(createdEntity),
                    true,
                ),
        );

        this.realTimeCommService.subscribeUpdateEntity(
            (_userData: unknown, updatedEntity: EntityItem) =>
                this.notifyEntityUpdate(updatedEntity, true),
        );

        this.realTimeCommService.subscribeBulkUpdateEntity(
            (_userData: unknown, updatedEntities: EntityItem[]) =>
                this.notifyEntityBulkUpdateEvent(updatedEntities, true),
        );

        this.realTimeCommService.subscribeUpdateEntityParentEvent(
            (
                _userData: unknown,
                setEntitiesParentResult: SetEntitiesParentResult,
            ) => this.notifyEntityParentUpdate(setEntitiesParentResult, true),
        );
    }

    private subscribeExternalTaskEvents() {
        this.realTimeCommService.subscribeUpdateTask(
            (_userData: unknown, task: EntityTaskDTO) =>
                this.notifyTaskUpdate(task, true),
        );

        this.realTimeCommService.subscribeDeleteTask(
            (_userData: unknown, deleteParameter: DeleteEntityTasksParameter) =>
                this.notifyTaskDelete(deleteParameter, true),
        );
    }

    private subscribeToTranslationEvents() {
        this.attributeValueTranslationService.attributeTranslatedToSourceLanguage.subscribe(
            (e) => {
                e.entity.setAttributeValue(e.attributePath, e.updatedValue);
                this.notifyEntityUpdate(e.entity);
            },
        );
    }

    private subscribeToEntityEventDispatcher() {
        this.entityEventDispatcher.subscribeToEventsByName('updated', (e) => {
            this.notifyEntityUpdate(e.entity);
        });
    }

    private subscribeToEntityCreatorEvents() {
        this.entityCreator.entityCreatedEvent.subscribe((e) => {
            this.notifyEntityCreation(e, false);
        });
    }
}

export class EntityDeleteEventData {
    constructor(
        public deletedIds: string[],
        public deletedTotalCount?: number,
    ) {}
}

export type IRealTimeEventHandler<T> = (data: T, external?: boolean) => void;
export type IDataIdentifierEventHandler<T extends IDataIdentifier> =
    IRealTimeEventHandler<T>;
export type IEntityItemEventHandler = IDataIdentifierEventHandler<EntityItem>;
export type IEntityCreationEventHandler<T extends IDataIdentifier> = (
    data: T,
    external?: boolean,
    context?: IEntityCreationContext,
) => void;

export interface IRealtimeEventArg<T> {
    data: T;
    /** true means the event has not been emitted locally (ie comes from the bask-office) */
    external?: boolean;
}
export interface IServerTypeEventArg<TData> extends IRealtimeEventArg<TData> {
    serverType: ServerType;
}

export class RealTimeEventArg<T> implements IRealtimeEventArg<T> {
    constructor(
        public data: T,
        public external?: boolean,
    ) {}
}
class ServerTypeFilteredEventArg<T> extends RealTimeEventArg<T> {
    constructor(
        public serverType: ServerType,
        data: T,
        external?: boolean,
    ) {
        super(data, external);
    }
}
class EntityItemEventArg extends ServerTypeFilteredEventArg<EntityItem> {
    constructor(
        public data: EntityItem,
        external?: boolean,
    ) {
        super(data.ServerType, data, external);
    }
}
