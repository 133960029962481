import { CoreUtil } from '@datagalaxy/core-util';
import {
    deserializeTypedEvent,
    ITypedEvent,
    ServerType,
} from '@datagalaxy/dg-object-model';
import * as moment from 'moment';
import { deserialize, deserializeAs, inheritSerialization } from 'cerialize';
import { BaseMessage } from '@datagalaxy/data-access';

export class UserNotification implements IUserNotification {
    public Guid = '';
    public Event?: ITypedEvent;
    public AcknowledgementTime?: string;
    public ReadTime?: string;
    public CreationTime?: string;
    public IsUserMention?: boolean;
    public MentionedTeamGuid?: string;

    public get CreationTimeFromNow() {
        return moment(this.CreationTime).fromNow();
    }
    public get IsAcknowledged() {
        return !!this.AcknowledgementTime;
    }
    public get IsMention() {
        return this.IsUserMention || !!this.MentionedTeamGuid;
    }

    constructor(un: IUserNotification | undefined = undefined) {
        if (!un) {
            return;
        }
        CoreUtil.assign(this, un);
        if (!this.Event) {
            return;
        }
        this.Event = deserializeTypedEvent(this.Event) as ITypedEvent;
    }
}

export interface IUserNotification {
    Guid: string;
    Event?: ITypedEvent;
    AcknowledgementTime?: string;
    ReadTime?: string;
    CreationTime?: string;
    IsUserMention?: boolean;
    MentionedTeamGuid?: string;
}

export enum NotificationMessageCode {
    Dynamic,
    WelcomeUser,
    UserLoggedIn,
    UserLoggedOut,
    ImportStarted,
    ImportEnded,
    CopyStarted,
    CopyEnded,
    GenerateGlossaryDataEnded,
    UserNotification,
    UserListChange,
    SecurityRightsChange,
}

@inheritSerialization(BaseMessage)
export class NotificationMessage extends BaseMessage {
    @deserialize public SourceConnectionId: string;
    @deserialize public SourceSystemId: string;
    @deserialize public SourceUserId: string;
    @deserialize public SourceUserFullName: string;
    @deserialize public IsSuccess: boolean;
    @deserializeAs(UserNotification)
    public NotificationObject: UserNotification;

    public get ServerType() {
        return ServerType.NotificationMessage;
    }

    constructor() {
        super();
    }
}
