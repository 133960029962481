import { IDgEvent, TEventTypeName } from './UserEventBase';

export const dgEventTypeMappings = new Map<string, new () => IDgEvent>();

/**
 * Register an event implementing IDgEvent to the event registry
 * @param type Type constructor
 * @param typeName event type name.
 * Needed because in production classes are minified, so we can't use "type.name"
 * @returns
 */
export function registerDgEventType(
    type: new () => IDgEvent,
    typeName: TEventTypeName,
) {
    if (!type || !typeName || dgEventTypeMappings.has(typeName)) {
        return;
    }
    dgEventTypeMappings.set(typeName, type);
}
