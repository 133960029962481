import {
    deserialize,
    deserializeAs,
    inheritSerialization,
    serialize,
} from 'cerialize';
import { BaseMasterData, ITypeMetadata } from '../master-data';
import { IHasTechnicalName } from '@datagalaxy/data-access';
import {
    EntityType,
    HierarchicalData,
    ServerType,
} from '@datagalaxy/dg-object-model';
import { TableRef } from '../table';
import { ColumnRef } from '../column';
import { PrimaryKeyRef } from '../primary-key';

@inheritSerialization(BaseMasterData)
export class ForeignKey extends BaseMasterData implements IHasTechnicalName {
    static readonly tmd: ITypeMetadata = {
        parentPropertyName: 'ForeignKeys',
        childPropertyName: 'PrimaryKeyRef',
    };

    @deserialize public DisplayName!: string;
    @deserialize public TechnicalName!: string;
    @deserialize public IsMandatory!: boolean;
    @deserialize public IsFunctionalOnly!: boolean;
    @deserialize public Description!: string;
    @deserializeAs(HierarchicalData) public HddData!: HierarchicalData;
    @deserializeAs(PrimaryKeyRef.fromId, 'PrimaryKeyId') public PrimaryKeyRef =
        new PrimaryKeyRef();
    @deserializeAs(TableRef.fromId, 'ChildTableId') public ChildTableRef =
        new TableRef();
    @deserializeAs(ColumnRef.fromIds, 'OrderedChildColumnIds')
    public OrderedChildColumns: ColumnRef[] = []; //#abasedata-metadata

    public readonly ServerType = ServerType.ForeignKey;

    public get EntityType() {
        return EntityType.ForeignKey;
    }

    public get entityType() {
        return EntityType.ForeignKey;
    }

    constructor() {
        super();
    }
}

export class PrimaryKeyForeignKeyLink {
    @serialize public ParentColumnId: string;
    @serialize public IsNewColumn: boolean;
    @serialize public ChildColumnId?: string;
    @serialize public ChildColumnName?: string;

    constructor(
        parentColumnId: string,
        isNewColumn: boolean,
        childColumnId?: string,
        childColumnName?: string,
    ) {
        this.ParentColumnId = parentColumnId;
        this.IsNewColumn = isNewColumn;
        this.ChildColumnId = childColumnId;
        this.ChildColumnName = childColumnName;
    }
}
