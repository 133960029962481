import { Provider } from '@angular/core';
import { UnauthorizedInterceptor } from './unauthorized.interceptor';
import { CurrentSpaceVersionIdInterceptor } from './current-space-version-id.interceptor';
import { BACKEND_HTTP_INTERCEPTORS } from '@datagalaxy/data-access';
import { UserLanguageHeaderInterceptor } from '@datagalaxy/webclient/user/data-access';

export const backendHttpInterceptors: Provider[] = [
    {
        provide: BACKEND_HTTP_INTERCEPTORS,
        useClass: UnauthorizedInterceptor,
        multi: true,
    },
    {
        provide: BACKEND_HTTP_INTERCEPTORS,
        useClass: CurrentSpaceVersionIdInterceptor,
        multi: true,
    },
    {
        provide: BACKEND_HTTP_INTERCEPTORS,
        useClass: UserLanguageHeaderInterceptor,
        multi: true,
    },
];
