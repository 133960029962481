import { Injectable } from '@angular/core';
import {
    BaseUiService,
    IActionOption,
    ListOptionBurgerMenuActionProvider,
} from '@datagalaxy/core-ui';
import { IDiagramCreationModalInput } from './diagram-configurator/diagram-configurator.types';
import { DiagramCreationModalComponent } from './diagram-creation-modal/diagram-creation-modal.component';
import { DiagramEditModalComponent } from './diagram-edit-modal/diagram-edit-modal.component';
import {
    IDiagramEditModalInputs,
    IDiagramEditModalOptions,
} from './diagram-edit-modal/diagram-edit-modal.types';
import {
    ICurrentDiagramActionOptions,
    IDiagramActionOptions,
} from './diagram-list.types';
import { DiagramDataService } from './diagram/diagram-data.service';
import { DiagramSecurityService } from './diagram/diagram-security.service';
import { DxyModalService } from '../shared/dialogs/DxyModalService';
import { EntityPreviewPanelService } from '../shared/entity/services/entity-preview-panel.service';
import { IEntityIdentifier, ServerType } from '@datagalaxy/dg-object-model';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';
import { DiagramService } from './diagram/diagram.service';

@Injectable({ providedIn: 'root' })
export class DiagramListUiService extends BaseUiService {
    constructor(
        private diagramService: DiagramService,
        private dxyModalService: DxyModalService,
        private diagramDataService: DiagramDataService,
        private entityPreviewPanelService: EntityPreviewPanelService,
        private diagramSecurityService: DiagramSecurityService,
    ) {
        super();
    }

    public async navigateToDiagram(diagram: EntityItem) {
        await this.diagramService.goToDiagram(diagram);
    }

    public getAvailableActions(
        opt?: IDiagramActionOptions,
    ): IActionOption<EntityItem>[] {
        return [
            {
                glyphClass: 'glyph-object-preview',
                tooltipTranslateKey: 'UI.Global.openPreview',
                callback: (diagram) => this.openPreview(diagram),
            },
            {
                tooltipTranslateKey: 'UI.OmniGrid.burgerButtonTooltip',
                burgerMenuProvider: this.getBurgerMenuProvider(opt),
            },
        ];
    }

    public getCurrentDiagramsActions(
        opt?: ICurrentDiagramActionOptions,
    ): IActionOption<EntityItem>[] {
        const buildCallback = (
            action: (diagram: EntityItem) => Promise<void>,
        ) => {
            return async (diagram: EntityItem) => {
                await action(diagram);
                opt?.afterAction?.(diagram);
            };
        };
        return [
            {
                glyphClass: 'glyph-object-preview',
                tooltipTranslateKey: 'UI.Global.openPreview',
                callback: buildCallback((diagram) => this.openPreview(diagram)),
                alwaysVisible: true,
            },
            {
                tooltipTranslateKey: 'UI.OmniGrid.burgerButtonTooltip',
                alwaysVisible: true,
                burgerMenuProvider: this.getBurgerMenuProvider(opt),
            },
        ];
    }

    public getAvailableEntityActions(
        opt?: IDiagramActionOptions,
    ): IActionOption<EntityItem>[] {
        return [
            {
                glyphClass: 'glyph-object-preview',
                tooltipTranslateKey: 'UI.Global.openPreview',
                callback: (diagram) => this.openPreview(diagram),
            },
            {
                tooltipTranslateKey: 'UI.OmniGrid.burgerButtonTooltip',
                burgerMenuProvider: this.getBurgerMenuProvider(opt),
            },
        ];
    }

    public async createDiagram(sourceIdr?: IEntityIdentifier) {
        await this.dxyModalService.open<
            DiagramCreationModalComponent,
            IDiagramCreationModalInput,
            EntityItem
        >({
            componentType: DiagramCreationModalComponent,
            data: {
                sourceIdr,
                isSourceReadonly: !!sourceIdr,
            },
        });
    }

    private getBurgerMenuProvider(opt?: IDiagramActionOptions) {
        return new ListOptionBurgerMenuActionProvider<EntityItem>([
            {
                // publish
                glyphClass: 'glyph-tools-publish',
                labelKey: 'UI.Diagrams.btnPublishDiagram',
                callback: (diagram) =>
                    this.diagramDataService.openPublishModal(diagram),
                hidden: (diagram) =>
                    !this.diagramSecurityService.canPublishDiagram(diagram),
            },
            {
                // clone
                glyphClass: 'glyph-file-copy',
                labelKey: 'UI.Diagrams.btnCloneDiagram',
                callback: (diagram) =>
                    this.openModalForClone(diagram, opt?.navigateOnClone),
                hidden: (diagram) =>
                    !this.diagramSecurityService.canCloneDiagram(diagram),
            },
            {
                // rename
                glyphClass: 'glyph-edit',
                labelKey: 'UI.Diagrams.btnEditDiagram',
                callback: (diagram) => this.openModalForRename(diagram),
                hidden: (diagram) =>
                    !this.diagramSecurityService.canRenameDiagram(diagram),
            },
            {
                // delete
                glyphClass: 'glyph-delete',
                labelKey: 'UI.Diagrams.btnDeleteDiagram',
                callback: (diagram) => this.confirmAndDeleteDiagram(diagram),
                hidden: (diagram) =>
                    !this.diagramSecurityService.canDeleteDiagram(diagram),
            },
        ]);
    }

    private async confirmAndDeleteDiagram(diagram: EntityItem): Promise<void> {
        const confirmed = await this.dxyModalService.confirmDeleteObject(
            ServerType[diagram.ServerType],
            { featureCode: 'DIAGRAM,D' },
        );
        if (
            !confirmed ||
            !(await this.diagramDataService.deleteDiagram(diagram))
        ) {
            return;
        }
    }

    private async openModalForClone(
        diagram: EntityItem,
        navigateOnClone: boolean,
    ): Promise<void> {
        const newDiagram = await this.openEditModal(diagram, { isCopy: true });
        if (!newDiagram || !navigateOnClone) {
            return;
        }
        await this.diagramService.goToDiagram(newDiagram);
    }

    private async openModalForRename(diagram: EntityItem): Promise<void> {
        const updatedEntity = await this.openEditModal(diagram, {
            isUpdate: true,
        });
        if (updatedEntity) {
            diagram.TechnicalName = updatedEntity.TechnicalName;
            diagram.DisplayName = updatedEntity.DisplayName;
        }
    }

    private async openEditModal(
        diagram: EntityItem,
        options: IDiagramEditModalOptions,
    ) {
        return await this.dxyModalService.open<
            DiagramEditModalComponent,
            IDiagramEditModalInputs,
            EntityItem
        >({
            componentType: DiagramEditModalComponent,
            data: {
                diagram,
                isCopy: options.isCopy,
                isUpdate: options.isUpdate,
            },
        });
    }

    private async openPreview(diagram: EntityItem) {
        await this.entityPreviewPanelService.setupPanel({ entityIdr: diagram });
    }
}
