import {
    ChangeDetectionStrategy,
    Component,
    computed,
    inject,
    input,
    signal,
    TemplateRef,
    viewChild,
    ViewContainerRef,
} from '@angular/core';
import {
    FlexibleConnectedPositionStrategy,
    Overlay,
    OverlayRef,
} from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { ElementRef } from '@angular/core';
import { MenuListDirective } from '../menu-list/menu-list.directive';
import { getMenuPosition, MenuAlignment } from './menu-alignment';

const menuViewportMarginInPx = 16;
const menuMinHeightInPx = 20;

@Component({
    selector: 'dxy-menu',
    standalone: true,
    imports: [MenuListDirective],
    templateUrl: './menu.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuComponent {
    menuTemplate = viewChild.required<TemplateRef<unknown>>('menuTemplate');
    alignment = input<MenuAlignment>();

    private overlayRef?: OverlayRef;
    private overlay = inject(Overlay);
    private viewContainerRef = inject(ViewContainerRef);

    private isOpenSignal = signal(false);
    public readonly isOpen = computed(() => this.isOpenSignal());

    public open(trigger: ElementRef) {
        if (this.overlayRef) {
            this.close();
            return;
        }

        const positionStrategy = this.getPositionStrategy(trigger);

        this.overlayRef = this.overlay.create({
            positionStrategy,
            hasBackdrop: true,
            backdropClass: 'cdk-overlay-transparent-backdrop',
            scrollStrategy: this.overlay.scrollStrategies.reposition(),
        });

        this.adjustMenuPosition(positionStrategy, trigger);

        const portal = new TemplatePortal(
            this.menuTemplate(),
            this.viewContainerRef,
        );
        this.overlayRef.attach(portal);

        this.overlayRef.backdropClick().subscribe(() => this.close());
        this.isOpenSignal.set(true);
    }

    public close() {
        if (this.overlayRef) {
            this.overlayRef.dispose();
            this.overlayRef = undefined;
        }
        this.isOpenSignal.set(false);
    }

    private adjustMenuPosition(
        positionStrategy: FlexibleConnectedPositionStrategy,
        trigger: ElementRef<any>,
    ) {
        let isRepositioning = false;
        positionStrategy.positionChanges.subscribe((change) => {
            if (isRepositioning || !this.overlayRef) {
                return;
            }
            const overlayPane = this.overlayRef.overlayElement;
            const triggerRect = trigger.nativeElement.getBoundingClientRect();

            const viewportHeight = document.documentElement.clientHeight;

            if (change.connectionPair.overlayY === 'top') {
                const availableSpace =
                    viewportHeight -
                    Math.max(triggerRect.bottom, 0) -
                    menuViewportMarginInPx;
                const minHeight = Math.max(availableSpace, menuMinHeightInPx);
                overlayPane.style.maxHeight = `${minHeight}px`;
            } else {
                const availableSpace = triggerRect.top - menuViewportMarginInPx;
                const minHeight = Math.max(availableSpace, menuMinHeightInPx);
                overlayPane.style.maxHeight = `${minHeight}px`;
            }
            isRepositioning = true;
            positionStrategy.apply();
            isRepositioning = false;
        });
    }

    private getPositionStrategy(trigger: ElementRef) {
        return this.overlay
            .position()
            .flexibleConnectedTo(trigger)
            .withFlexibleDimensions(false)
            .withPush(true)
            .withPositions(getMenuPosition(this.alignment()));
    }
}
