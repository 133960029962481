<div class="modal-header">
    <h3 mat-dialog-title class="modal-title" translate>
        UI.Glossary.fusion.title
    </h3>
    <button dxyIconButton color="transparent" (click)="onCloseCancel()">
        <i class="glyph-cancelsearch"></i>
    </button>
</div>
<div mat-dialog-content>
    <div *ngIf="isFusionFormEnabled" class="form-horizontal">
        <dxy-entity-selector-field
            labelKey="UI.Glossary.fusion.lblSources"
            [ngModel]="sourceProperties"
            [readonly]="true"
            [isMultiValue]="true"
        ></dxy-entity-selector-field>
        <div class="target-entity-wrapper">
            <dxy-entity-selector-field
                labelKey="UI.Glossary.fusion.lblTarget"
                [ngModel]="targetProperty"
                (ngModelChange)="onSelectTargetProperty($event)"
                [options]="entitySelectorData"
                [takeFocus]="true"
                [openMenuOnFocus]="true"
                [errorMessageKey]="targetErrorMessageKey"
                (openClose)="onPanelOpenClose($event)"
            ></dxy-entity-selector-field>
        </div>
        <div class="checkboxes-wrapper">
            <mat-label translate
                >UI.Glossary.fusion.lblFusionElements</mat-label
            >
            <mat-checkbox [(ngModel)]="includeDetails">
                <span translate
                    >UI.Glossary.fusion.lblFusionElementDetails</span
                >
            </mat-checkbox>
            <mat-checkbox [(ngModel)]="includeRelations">
                <span translate
                    >UI.Glossary.fusion.lblFusionElementRelations</span
                >
            </mat-checkbox>
            <mat-checkbox [(ngModel)]="includeImplementations">
                <span translate
                    >UI.Glossary.fusion.lblFusionElementImplementations</span
                >
            </mat-checkbox>
        </div>
    </div>
    <dxy-spinner *ngIf="isLoading"></dxy-spinner>
    <div
        *ngIf="hasIncompatibilityError"
        class="alert alert-warning"
        role="alert"
    >
        <span class="glyph glyph-warning"></span>
        <span translate="UI.Glossary.fusion.lblIncompatibilityError"></span>
    </div>
    <div *ngIf="errorMessage" class="alert alert-warning" role="alert">
        <span class="glyph glyph-warning"></span>
        <span>{{ errorMessage }}</span>
    </div>
</div>

<div class="modal-footer dxy-actions">
    <button
        *ngIf="!isLoading"
        dxyButton
        variant="secondary"
        type="button"
        (click)="onCloseCancel()"
    >
        <span translate="UI.Dialog.btnCancel"></span>
    </button>
    <button
        *ngIf="!isLoading"
        dxyButton
        variant="primary"
        type="button"
        (click)="onSubmit()"
        dxyLogFunctional="MERGE,C"
        [disabled]="!isSubmitEnabled()"
    >
        <span translate="UI.Global.btnFusion"></span>
    </button>
</div>
