import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { BaseService } from '@datagalaxy/core-ui';
import { ViewType } from '../shared/util/app-types/ViewType';

@Injectable({ providedIn: 'root' })
export class AppEventsService extends BaseService {
    //#region ViewType

    /** fired on technical/functional view change */
    public get viewTypeChange$() {
        return this.viewTypeChange.asObservable();
    }
    private viewTypeChange = new Subject<ViewType>();

    /** to be called on technical/functional view change */
    public emitViewTypeChange(viewType: ViewType) {
        this.log('emitViewTypeChange', viewType);
        this.viewTypeChange.next(viewType);
    }

    //#endregion

    //#region Import

    /** fired on import successfully completed from home shortcuts */
    public get importDone$() {
        return this.importDone.asObservable();
    }
    private importDone = new Subject<void>();

    /** to be called on import from home shortcuts */
    public notifyImportDone() {
        this.importDone.next();
    }

    //#endregion
}
