import { IFilterFormAttributes, IFilterFormModel } from './IFilterFormModel';
import { AttributeFilterModel } from '../attribute-filter/attributeFilterModel';
import { BehaviorSubject, Subject } from 'rxjs';
import { AttributeTextFilterModel } from '../attribute-text-filter/AttributeTextFilterModel';
import { AttributeFilterService } from '../attribute-filter.service';
import { CollectionsHelper, LogFn } from '@datagalaxy/core-util';
import { FilterUtil } from '../../../util/FilterUtil';
import { IWorkspaceIdentifier } from '@datagalaxy/webclient/workspace/domain';
import { Filter, FilterOperator } from '@datagalaxy/webclient/filter/domain';
import {
    AttributeMetaInfo,
    AttributeMetaType,
    AttributeMetaValue,
} from '@datagalaxy/webclient/attribute/domain';
import { DgZone } from '@datagalaxy/webclient/domain';
import { ServerConstants } from '@datagalaxy/shared/server/domain';

export abstract class BaseAttributeFilterFormModel
    implements IFilterFormModel, IFilterFormAttributes
{
    //#region IFilterFormModel
    public readonly filterItems: AttributeFilterModel[] = [];

    public get displayedFilters$() {
        return this.displayedFiltersChanged.asObservable();
    }
    protected notifyDisplayedFiltersChanged(filters: AttributeFilterModel[]) {
        this.displayedFiltersChanged.next(filters);
    }
    private displayedFiltersChanged = new BehaviorSubject<
        AttributeFilterModel[]
    >([]);

    public get filterItemsChanged$() {
        return this.filterItemsChanged.asObservable();
    }
    protected notifyFilterItemsChanged() {
        return this.filterItemsChanged.next();
    }
    private filterItemsChanged = new Subject<void>();
    //#endregion

    //#region IFilterFormAttributes
    public get sourceAttributes() {
        return this.formAttributes.sourceAttributes;
    }
    public set sourceAttributes(attributes: AttributeMetaInfo[]) {
        this.formAttributes.sourceAttributes = attributes;
    }
    public get allAttributes() {
        return this.formAttributes.allAttributes;
    }
    public get searchTermAttributeKey() {
        return this.formAttributes.searchTermAttributeKey;
    }
    public get entityTypeAttribute() {
        return this.formAttributes.entityTypeAttribute;
    }
    public availableAttributes: AttributeMetaInfo[];
    //#endregion

    public get searchTerm() {
        const searchTermFilter = this.searchTermFilter;
        return searchTermFilter instanceof AttributeTextFilterModel
            ? searchTermFilter.value
            : null;
    }
    public set searchTerm(value: string) {
        const searchTermFilter = this.searchTermFilter;
        if (searchTermFilter instanceof AttributeTextFilterModel) {
            searchTermFilter.value = value;
        }
    }
    private get searchTermFilter() {
        return this.filterItems.find(
            (filter) => filter.attributeKey === this.searchTermAttributeKey,
        );
    }

    constructor(
        public readonly dgZone: DgZone,
        public readonly spaceIdr: IWorkspaceIdentifier,
        private readonly formAttributes: IFilterFormAttributes,
        public log?: LogFn,
    ) {}

    public addFilterItem(afm: AttributeFilterModel) {
        this.replace(this.filterItems, (fi) => fi.id === afm.id, afm);
        this.onFilterItemsChanged();
    }

    public removeFilterItem(afm: AttributeFilterModel) {
        this.replace(this.filterItems, (fi) => fi.id === afm.id);
        this.onFilterItemsChanged();
    }

    public removeFilterItems(
        afms: AttributeFilterModel[],
        predicate: (afm: AttributeFilterModel) => boolean,
    ) {
        afms?.forEach((afm) => {
            if (predicate(afm)) {
                this.removeFilterItem(afm);
            }
        });
    }

    public addFilterFromAttribute(attribute: AttributeMetaInfo) {
        const afm = AttributeFilterService.newFilterItemFromAttribute(
            this,
            attribute,
        );
        this.addFilterItem(afm);
        return afm;
    }

    /**
     * Compute filters (Filter[]) from filter items (FilterItemModel[])
     */
    public computeFilters(allowEmptySearchTerm?: boolean) {
        this.log?.('computeFilters', this);
        const notEmptyFilterPredicate = (afm) =>
            afm.isNotEmpty() ||
            FilterUtil.isValuelessOperator(afm.operator) ||
            (allowEmptySearchTerm &&
                afm.attributeKey == ServerConstants.Search.SearchTermFilterKey);
        const notEmptyFilters = AttributeFilterService.getFilterItems(
            this,
            notEmptyFilterPredicate,
        );
        if (!notEmptyFilters.length) {
            return [];
        }

        const exportedFilters =
            AttributeFilterService.exportFilters(notEmptyFilters);
        return exportedFilters.filter((f) =>
            AttributeFilterService.isFilterComplete(f, allowEmptySearchTerm),
        );
    }

    public setupFilterItems(filters: Filter[]) {
        this.filterItems.length = 0;
        if (filters?.length) {
            const filterItems =
                AttributeFilterService.getFilterItemsFromFilters(
                    this,
                    filters,
                    true,
                );
            this.filterItems.push(...filterItems);
        }
        this.setupAdditionalFilters();
        this.setupSearchTermFilter();
        this.onFilterItemsChanged();
    }

    protected abstract setupAdditionalFilters(): void;

    protected onFilterItemsChanged() {
        this.updateAvailableAttributes();
        this.setDisplayedFilters();
        this.notifyFilterItemsChanged();
    }

    protected getSearchTermFilterItem(ffm: IFilterFormModel) {
        const ak = this.formAttributes.searchTermAttributeKey;
        const searchTermAttribute = new AttributeMetaInfo({
            attributeKey: ak,
            attributeType: AttributeMetaType.Text,
        });
        searchTermAttribute.AttributePath = ak;
        searchTermAttribute.ListValues = [
            new AttributeMetaValue(searchTermAttribute, 'search', ''),
        ];
        const filterItem = new AttributeTextFilterModel(
            ffm,
            searchTermAttribute,
            [
                FilterOperator.TextContains,
                FilterOperator.TextEquals,
                FilterOperator.TextStartsWith,
                FilterOperator.TextEndsWith,
            ],
        );
        filterItem.isFormOpen = false;
        filterItem.value = '';
        return filterItem;
    }

    private replace(
        afms: AttributeFilterModel[],
        predicate: (f: AttributeFilterModel) => boolean,
        replaceBy?: AttributeFilterModel,
    ) {
        if (!afms) {
            return;
        }
        const i = afms.findIndex(predicate);
        if (i !== -1) {
            if (replaceBy) {
                afms[i] = replaceBy;
            } else {
                afms.splice(i, 1);
            }
        } else if (replaceBy) {
            afms.push(replaceBy);
        }
    }

    private updateAvailableAttributes() {
        const aps = this.filterItems.map((afm) => afm.attributePath);
        this.availableAttributes = this.sourceAttributes.filter(
            (ami) => !aps.includes(ami?.AttributePath),
        );
    }

    private setDisplayedFilters() {
        const displayedFilters = this.filterItems.filter(
            (filter) => filter.attributeKey !== this.searchTermAttributeKey,
        );
        const orderedDisplayedFilters = CollectionsHelper.orderBy(
            displayedFilters,
            (afm) =>
                afm.attributeKey === this.entityTypeAttribute.AttributeKey
                    ? -1
                    : 1,
        );
        this.notifyDisplayedFiltersChanged(orderedDisplayedFilters);
    }

    private setupSearchTermFilter() {
        const searchTermFilter = this.filterItems.find(
            (afm) => afm.attributeKey === this.searchTermAttributeKey,
        );
        if (searchTermFilter) {
            return;
        }
        const afm = this.getSearchTermFilterItem(this);
        this.addFilterItem(afm);
    }
}
