import { AccountModelService } from './services/account-model.service';
import { AccountSetPasswordMode } from './models/account-set-password.model';
import { ActivatedRouteSnapshot, Route, Routes } from '@angular/router';
import { LegacyAuthGuardMatch } from '../auth/legacy-auth/legacy-auth.guard';
import { inject } from '@angular/core';
import {
    LegacySsoAuthGuardMatch,
    LegacySsoLoginGuard,
} from '../auth/legacy-sso/legacy-sso-auth.guard';
import { DummySsoComponent } from '../auth/legacy-sso/dummy-sso.component';

/**
 * @deprecated Related to auth v1
 */
const login: Route = {
    path: 'login',
    loadComponent: () =>
        import('../account/account-login/account-login.component').then(
            (m) => m.AccountLoginComponent,
        ),
    canMatch: [LegacyAuthGuardMatch],
};

/**
 * @deprecated Related to auth v1
 */
const resetPassword: Route = {
    path: 'reset',
    loadComponent: () =>
        import(
            '../account/account-set-password/account-set-password.component'
        ).then((m) => m.AccountSetPasswordComponent),
    canMatch: [LegacyAuthGuardMatch],
    resolve: {
        accountSetPasswordData: (route: ActivatedRouteSnapshot) => {
            return inject(AccountModelService).createAccountSetPasswordModel(
                route.queryParams.t,
                AccountSetPasswordMode.ResetPassword,
            );
        },
    },
};

/**
 * @deprecated Related to auth v1
 */
const externalLogin: Route = {
    path: 'extlogin',
    component: DummySsoComponent,
    canMatch: [LegacySsoAuthGuardMatch],
    canActivate: [LegacySsoLoginGuard],
};

/**
 * @deprecated Related to auth v1
 */
const setPassword: Route = {
    path: 'init',
    loadComponent: () =>
        import(
            '../account/account-set-password/account-set-password.component'
        ).then((m) => m.AccountSetPasswordComponent),
    canMatch: [LegacyAuthGuardMatch],
    resolve: {
        accountSetPasswordData: (route: ActivatedRouteSnapshot) => {
            return inject(AccountModelService).createAccountSetPasswordModel(
                route.queryParams.t,
                AccountSetPasswordMode.ResetPassword,
            );
        },
    },
};

/**
 * @deprecated Related to auth v1
 */
export const legacyAuthRoutes: Routes = [
    login,
    resetPassword,
    setPassword,
    externalLogin,
];
