import {
    BaseServiceParameter,
    BaseServiceResult,
} from '@datagalaxy/data-access';
import {
    deserialize,
    deserializeAs,
    inheritSerialization,
    SerializableEnumeration,
    serialize,
} from 'cerialize';
import {
    DataTypeSettings,
    MasterDataSerializer,
} from '@datagalaxy/webclient/modeler/data-access';

export enum NotificationRealTimeBehavior {
    All = 0,
    None = 1,
    ActionOnly = 2,
}
SerializableEnumeration(NotificationRealTimeBehavior);

@inheritSerialization(BaseServiceParameter)
export class ClientLoginParameter extends BaseServiceParameter {
    @serialize public LoginToken?: string;
    @serialize public ClientId?: string;
}

export class LoginSecurityData {
    @deserialize public $id!: string; //#archi-abasedata-$id: may be not used
    @deserialize public UserRoleLocalIds: string[] = [];
    @deserialize public LicenseLevel!: string;
    @deserialize public CanCreateEntities!: boolean;
    @deserialize public CanImportEntities!: boolean;
}

@inheritSerialization(BaseServiceResult)
export class ClientLoginResult extends BaseServiceResult {
    //static OnDeserialized(instance: any, json: any) { CoreUtil.log('OnDeserialized', instance, json) }

    @deserialize public UserSessionId!: string;

    /** Encapsulates the UserSessionId, ClientId, UserId, Email in a secure multi-purpose JWT */
    @deserialize public AccessToken!: string;

    @deserializeAs(
        MasterDataSerializer.forLegacyDataTypeSettingsFormat(
            'ClientTypeSettings',
        ),
    )
    public ClientTypeSettings!: DataTypeSettings;

    @deserializeAs(LoginSecurityData) public SecurityData!: LoginSecurityData;
    @deserializeAs(Number)
    public NotificationRealTimeBehavior!: NotificationRealTimeBehavior;

    @deserialize public ClientAccessFlags!: string[];
    @deserialize public ClientName!: string;
    @deserialize public ClientOfferPlan!: string;
    @deserialize public ClientOfferName!: string;

    @deserialize public FirstConnectionTime!: string;
    @deserialize public LastConnectionTime!: string;
    @deserialize public IsExternal!: boolean;
    @deserialize public Category!: string;
    @deserialize public Status!: string;
    @deserialize public ClientEngagementLifeCycle!: string;
    @deserialize public POVStartTime!: string;
    @deserialize public POVEndTime!: string;
    @deserialize public TacitAgreementDate!: string;
    @deserialize public EndOfContractNotificationDate!: string;
    @deserialize public DefaultSpaceUid!: string;
    @deserialize public ClientDescription!: string;
    @deserialize public ClientImageHash!: string;
    @deserialize public SearchImageHash!: string;
    @deserialize public UserSessionTimeoutMinutes!: number;
    @deserialize public ClientUISettingsJSON!: string;

    /**   This flag marks that the current login is an External Login (SAML, etc.) */
    @deserialize public IsExternalLogin!: boolean;
}
