import { CoreUtil } from './core-util';

export class EmailUtil {
    private static readonly emailValidationRegExp = new RegExp(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );

    public static validateEmailFormat(email: string) {
        return EmailUtil.emailValidationRegExp.test(email);
    }

    /**
     * Open the default user's mail client with the target email address filled
     * Note: opt.rawBody option is not secured, the text/html will not
     * be sanitized against any kind of security attacks like XSS
     */
    public static mailTo(emailAddress: string, opt?: IMailToOptions): boolean {
        if (!emailAddress) {
            return;
        }
        if (!EmailUtil.validateEmailFormat(emailAddress)) {
            CoreUtil.warn(`${emailAddress} is not a valid email address`);
            return;
        }
        const cc = EmailUtil.filterValidEmails(opt?.cc);
        const bcc = EmailUtil.filterValidEmails(opt?.bcc);

        const searchParams = new URLSearchParams(
            CoreUtil.omitBy(
                {
                    subject: opt?.subject,
                    cc: cc?.join(','),
                    bcc: bcc?.join(','),
                    body: opt?.rawBody,
                },
                (o) => !o,
            ),
        ).toString();
        window.location.assign(
            `mailto:${emailAddress}${searchParams ? `?${searchParams}` : ''}`,
        );
        return true;
    }

    private static filterValidEmails(emails: string[]) {
        return emails?.filter((email) => {
            const isValidEmail = EmailUtil.validateEmailFormat(email);
            if (!isValidEmail) {
                CoreUtil.warn(
                    `Skipping ${email} since it is not a valid email`,
                );
            }
            return isValidEmail;
        });
    }
}

export interface IMailToOptions {
    subject?: string;
    cc?: string[];
    bcc?: string[];
    rawBody?: string;
}
