import {
    ActivatedRouteSnapshot,
    CanActivateFn,
    CanMatchFn,
    Route,
    Router,
    RouterStateSnapshot,
    UrlSegment,
} from '@angular/router';
import { inject } from '@angular/core';
import { SlackRedirectionQueryParams } from '../notification-channels/slack-redirection.types';
import { SlackRedirectionHandler } from '../notification-channels/slack-redirection-handler';
import { QueryParamRemover } from '../notification-channels/query-param-remover';
import { LegacyAuthenticationService } from '../auth/legacy-auth/legacy-authentication.service';
import { ClientService } from './client.service';
import { filter, take } from 'rxjs/operators';
import { combineLatest, switchMap } from 'rxjs';
import { ClientServiceManagerService } from './client-service-manager.service';
import { CurrentUserService } from '@datagalaxy/webclient/user/data-access';
import { AuthenticationService } from '@datagalaxy/webclient/auth/feature';
import { LegacySsoAuthService } from '../auth/legacy-sso/legacy-sso-auth.service';

/**
 * Remove the hash /client/:id from the URL
 * This guard ensure retro-compatibility with old URLs (Auth v1)
 */
export const ClientBackwardCompatGuard: CanMatchFn = (
    _route: Route,
    segments: UrlSegment[],
) => {
    const router = inject(Router);

    const regex = /^client\/[^/]+/; // Matches "/client/:id"
    const path = segments.join('/');
    const match = regex.test(path);

    if (match) {
        return router.parseUrl(path.replace(regex, ''));
    }

    return true;
};

export const ClientQueryParamsGuard: CanActivateFn = (
    route: ActivatedRouteSnapshot,
) => {
    const slackRedirectionHandler = inject(SlackRedirectionHandler);
    const queryParamRemover = inject(QueryParamRemover);
    const params = route.queryParams;

    const callbackIdentifier =
        params[SlackRedirectionQueryParams.callbackIdentifier];
    const code = params[SlackRedirectionQueryParams.code];
    const from = params.from;

    if (callbackIdentifier && code) {
        setTimeout(
            () =>
                slackRedirectionHandler.handleRedirection(
                    callbackIdentifier,
                    code,
                ),
            10,
        );
    }

    if (from) {
        setTimeout(() => queryParamRemover.remove(['from']), 10);
    }

    return true;
};

export const LegacyClientGuard: CanActivateFn = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
) => {
    const authenticationService = inject(LegacyAuthenticationService);
    const ssoAuthService = inject(LegacySsoAuthService);
    const clientService = inject(ClientService);
    const clientServiceManager = inject(ClientServiceManagerService);
    const router = inject(Router);
    const clientId = route.params.id;

    return combineLatest([
        authenticationService.authenticated$,
        ssoAuthService.authenticated$,
    ]).pipe(
        filter(
            ([authenticated, ssoAuthenticated]) =>
                authenticated || ssoAuthenticated,
        ),
        take(1),
        switchMap(async () => {
            try {
                const res = await clientService.clientLogin(clientId);
                await clientServiceManager.initializeServicesLegacy(
                    res,
                    clientId,
                );

                // Could be move in angular 18 to children path '' with redirectTo
                // function
                const isFullMatch = new RegExp('^\\/client\\/[^\\/]+$').test(
                    state.url,
                );

                if (isFullMatch || state.url === '/') {
                    return router.createUrlTree(
                        await clientService.getLandingPageRouteCommands(
                            clientId,
                        ),
                    );
                }

                return true;
            } catch (error) {
                return false;
            }
        }),
    );
};

export const ClientGuard: CanActivateFn = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
) => {
    const currentUserService = inject(CurrentUserService);
    const clientServiceManager = inject(ClientServiceManagerService);
    const authenticationService = inject(AuthenticationService);
    const clientService = inject(ClientService);
    const router = inject(Router);

    return authenticationService.authenticated$.pipe(
        filter((authenticated) => authenticated),
        take(1),
        switchMap(() =>
            currentUserService.selectUserInfo().pipe(
                filter((userInfo) => !!userInfo),
                take(1),
                switchMap(async (userInfo) => {
                    try {
                        await clientServiceManager.initializeServices(
                            userInfo,
                            currentUserService.clientInfo,
                        );

                        if (state.url === '/') {
                            return router.createUrlTree(
                                await clientService.getLandingPageRouteCommands(
                                    currentUserService.clientId,
                                ),
                            );
                        }

                        return true;
                    } catch (error) {
                        return router.createUrlTree(['/error']);
                    }
                }),
            ),
        ),
    );
};
