import {
    ActivatedRouteSnapshot,
    CanActivateFn,
    CanMatchFn,
    Router,
} from '@angular/router';
import { inject } from '@angular/core';
import { LegacySsoAuthService } from './legacy-sso-auth.service';
import { filter, take } from 'rxjs/operators';
import { map } from 'rxjs';
import { AppConfigService } from '@datagalaxy/webclient/config';

export const LegacySsoAuthGuard: CanActivateFn = () => {
    const ssoAuthService = inject(LegacySsoAuthService);
    const window = inject(Window);

    return ssoAuthService.loaded$.pipe(
        filter(Boolean),
        take(1),
        map(() => {
            if (ssoAuthService.authenticated || !ssoAuthService.enabled) {
                return true;
            }

            window.location.assign(ssoAuthService.authUrl);

            return false;
        }),
    );
};

/**
 * Handle the SSO login callback.
 */
export const LegacySsoLoginGuard: CanActivateFn = async (
    route: ActivatedRouteSnapshot,
) => {
    const ssoAuthService = inject(LegacySsoAuthService);
    const router = inject(Router);
    const status = route.queryParams.status;
    const loginToken = route.queryParams.logintoken;
    const url = route.queryParams.path;

    if (status == '1') {
        const res = await ssoAuthService.postLogin(loginToken);

        if (url) {
            const Url = new URL(url);
            const pathname = `${Url.hash.replace('#', '')}` || Url.pathname;

            if (pathname !== '/') {
                return router.navigateByUrl(pathname);
            }
        }

        return router.createUrlTree([
            '/client',
            res.CurrentClientId || res.AuthorizedClients?.[0].ClientId,
        ]);
    }

    const routeParams = ssoAuthService.handlePostLoginError(status, loginToken);
    return inject(Router).navigate(routeParams);
};

/**
 * Enable the route only for the SSO legacy authentication
 * (not classic login nor Auth v2).
 */
export const LegacySsoAuthGuardMatch: CanMatchFn = () => {
    const ssoAuthService = inject(LegacySsoAuthService);
    const appConfig = inject(AppConfigService);

    if (appConfig.ENABLE_AUTH_V2) {
        return true;
    }

    return ssoAuthService.loaded$.pipe(
        filter(Boolean),
        take(1),
        map(() => ssoAuthService.enabled),
    );
};
