import { Inject, Injectable } from '@angular/core';
import {
    getBaseObjectRouteUrl,
    RestApiService,
    V3_REST_API_SERVICE_TOKEN,
} from '@datagalaxy/data-access';
import { IEntityIdentifier } from '@datagalaxy/dg-object-model';
import {
    AttributeValueTranslation,
    ObjectTranslation,
} from '@datagalaxy/webclient/multilingual/domain';
import { ObjectTranslationDto } from './object-translation-dto';
import { getContextId, getLocalId } from '@datagalaxy/utils';

@Injectable({ providedIn: 'root' })
export class TranslationApiService {
    constructor(
        @Inject(V3_REST_API_SERVICE_TOKEN)
        private restApiService: RestApiService,
    ) {}

    public async getObjectTranslationForLanguage(
        entityIdentifier: IEntityIdentifier,
        languageCode: string,
    ): Promise<ObjectTranslation> {
        const result =
            await this.restApiService.getPromise<ObjectTranslationDto>(
                `${this.getBaseTranslationsRouteUrl(
                    entityIdentifier,
                )}/${languageCode}`,
            );
        return this.adaptObjectTranslation(result, entityIdentifier);
    }

    public async getObjectsTranslationsForLanguage(
        entityIdentifiers: IEntityIdentifier[],
        languageCode: string,
    ): Promise<ObjectTranslation[]> {
        const idr = entityIdentifiers[0];
        const spaceId = getContextId(idr.ReferenceId);
        const versionId = idr.VersionId;
        const result = await this.restApiService.getPromise<
            ObjectTranslationDto[]
        >(
            `workspaces/${spaceId}/versions/${versionId}/objects/translations/${languageCode}`,
            {
                objectId: entityIdentifiers.map(
                    (e) => getLocalId(e.ReferenceId) ?? '',
                ),
            },
        );
        return result.map((o) =>
            this.adaptObjectTranslation(
                o,
                entityIdentifiers.find(
                    (e) => getLocalId(e.ReferenceId) === o.objectId,
                ) as IEntityIdentifier,
            ),
        );
    }

    public async getObjectTranslations(
        entityIdentifier: IEntityIdentifier,
    ): Promise<ObjectTranslation[]> {
        const result = await this.restApiService.getPromise<
            ObjectTranslationDto[]
        >(`${getBaseObjectRouteUrl(entityIdentifier)}/translations-collection`);
        return result.map((o) =>
            this.adaptObjectTranslation(o, entityIdentifier),
        );
    }

    public async updateObjectAttributeTranslation(
        entityIdentifier: IEntityIdentifier,
        languageCode: string,
        value: AttributeValueTranslation,
    ) {
        return this.restApiService.putPromise<AttributeValueTranslation>(
            `${this.getBaseTranslationsRouteUrl(
                entityIdentifier,
            )}/${languageCode}/attributes/${value.attributePath}`,
            value,
        );
    }

    public async regenerateAttributeTranslations(params: {
        from: string;
        entityIdentifier: IEntityIdentifier;
        attributePath: string;
    }) {
        await this.restApiService.postPromise<ObjectTranslationDto>(
            `${getBaseObjectRouteUrl(
                params.entityIdentifier,
            )}/attributes/${params.attributePath}/regenerate-translations`,
            {
                from: params.from,
            },
        );
    }

    public async resetAttributeTranslations(params: {
        entityIdentifier: IEntityIdentifier;
        attributePath: string;
    }) {
        await this.restApiService.deletePromise(
            `${getBaseObjectRouteUrl(
                params.entityIdentifier,
            )}/attributes/${params.attributePath}/translations`,
        );
    }

    public async getAttributeTranslation(params: {
        entityIdentifier: IEntityIdentifier;
        attributePath: string;
        languageCode: string;
    }) {
        const objectTranslation = await this.getObjectTranslationForLanguage(
            params.entityIdentifier,
            params.languageCode,
        );
        return objectTranslation?.translatedAttributeValues?.find(
            (v) => v.attributePath == params.attributePath,
        );
    }

    private getBaseTranslationsRouteUrl(entityIdentifier: IEntityIdentifier) {
        return `${getBaseObjectRouteUrl(entityIdentifier)}/translations`;
    }

    private adaptObjectTranslation(
        translation: ObjectTranslationDto,
        entityIdentifier: IEntityIdentifier,
    ): ObjectTranslation {
        return {
            languageCode: translation.languageCode,
            entityIdentifier,
            translatedAttributeValues: translation.translatedAttributeValues,
        };
    }
}
