//#region base error
export interface IBaseErrorData {
    ErrorDetails: string;
    errorDetailsKey: string;
}
export class BaseError {
    constructor(
        public data: IBaseErrorData = null,
        public message?: string,
    ) {}
}
//#endregion

//#region login
/**
 * @deprecated No login error is possible since Auth is now implemented outside the app (KeyCloak)
 */

export enum ErrorLoginType {
    notAuthenticated = 0,
    loginFailed = 1,
    trialLicenseExpired = 2,
}
/**
 * @deprecated No login error is possible since Auth is now implemented outside the app (KeyCloak)
 */
export class ErrorLogin extends BaseError {
    public type: ErrorLoginType;
    public loginErrorMessage: string;

    constructor(
        data: IBaseErrorData = null,
        type: ErrorLoginType = null,
        errorMessageKey: string = null,
    ) {
        super(data);
        this.loginErrorMessage = errorMessageKey;
        if (type != undefined) {
            this.type = type;
        }
    }
}
//#endregion
