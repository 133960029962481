// keep in mind that string values are used for translation
// and also for goto unfortunately
import { DgModuleDefinition } from '@datagalaxy/shared/dg-module/domain';

export enum ViewIdentifier {
    ClientSpacesList = 'clientSpacesList',
    ClientSearchResults = 'clientSearchResults',
    ClientTasks = 'clientTasks',
    ClientConnectors = 'clientConnectors',
    ClientWidgetsDashboard = 'clientWidgetsDashboard',
    SpaceDashboard = 'spaceDashboard',
    SpaceHome = 'spaceHome',
    SpaceSecurity = 'security',
    SpaceSettings = 'spaceSettings',
    SpaceScreen = 'screen',
    SpaceParameters = 'parameters',
    SpaceVersion = 'version',
    SpaceDetails = 'details',
    SpaceRoles = 'roles',
    SpaceConnector = 'connector',
    SpaceAnalytics = 'analytics',

    UserProfile = 'userProfile',

    DataProcessing = 'dataProcessing',
    Glossary = 'glossary',
    Software = 'software',

    Modeler = 'modeler',
    ModelerDiagrams = 'modelerDiagrams',

    Create = 'create',
    ImpactAnalysis = 'impactAnalysis',
    Diagrams = 'diagrams',
    Diagram = 'diagram',
    Campaigns = 'campaigns',
    CampaignDetails = 'campaignDetails',
    ImpactAnalysisExploratory = 'exploratory',
    ImpactAnalysisLineage = 'lineage',

    Project = 'project',

    ClientAdmin = 'clientAdmin',
    ClientAdminIntegrationCollaboration = 'collaboration',
    ClientAdminIntegrationTokens = 'tokens',
    ClientDashboard = 'dashboard',

    CsvImportPreview = 'Preview',
    CsvImportMapping = 'Mapping',
    CsvImportCheck = 'Check',

    VersioningComparatorCreated = 'versioningComparatorCreated',
    VersioningComparatorModified = 'versioningComparatorModified',
    VersioningComparatorDeleted = 'versioningComparatorDeleted',
    VersioningComparatorUnmodified = 'versioningComparatorUnmodified',

    GUIMenuCollapse = 'collapse',
    GUIMenuExpand = 'expand',

    AccessControlManagementObjects = 'accessControlManagementObjects',
}

export function getViewIdentifierFromModuleDefinition(
    module: DgModuleDefinition,
) {
    switch (module.name) {
        case 'Glossary':
            return ViewIdentifier.Glossary;
        case 'Catalog':
            return ViewIdentifier.Modeler;
        case 'Processing':
            return ViewIdentifier.DataProcessing;
        case 'Usage':
            return ViewIdentifier.Software;
    }
}
