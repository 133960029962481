import { BaseFieldSelectAdapter } from '@datagalaxy/core-ui';
import { AttributeDataService } from '../../attribute/attribute-data.service';
import { ServerType } from '@datagalaxy/dg-object-model';
import { AttributeMetaInfo } from '@datagalaxy/webclient/attribute/domain';

export class AMIFieldSelectAdapter extends BaseFieldSelectAdapter<AttributeMetaInfo> {
    public serverType: ServerType;

    constructor(
        private attributeDataService: AttributeDataService,
        onSelectionChange?: (a: AttributeMetaInfo) => void,
    ) {
        super(onSelectionChange);
    }

    public init(attributes: AttributeMetaInfo[], serverType: ServerType) {
        this.serverType = serverType;
        this.options = this.attributeDataService.sortAlphaAttributes(
            attributes ?? [],
            serverType,
        );
    }

    public getText(a: AttributeMetaInfo) {
        return this.attributeDataService.getAttributeDisplayName(
            a,
            this.serverType,
        );
    }
}
