<div class="modal-header">
    <h3 mat-dialog-title class="modal-title" translate>
        UI.Teams.Members.AddMembersModal.title
    </h3>
    <button dxyIconButton color="transparent" (click)="onCloseCancel()">
        <i class="glyph-cancelsearch"></i>
    </button>
</div>
<form (ngSubmit)="onCloseSubmit()">
    <div mat-dialog-content>
        <dxy-field-multi-select
            labelKey="UI.Teams.Members.AddMembersModal.membersLabel"
            [data]="multiSelectData"
            [(ngModel)]="selectedMembers"
            name="members"
        ></dxy-field-multi-select>
    </div>
    <dxy-modal-footer
        [actionEnabled]="canAdd"
        [actionBtnLbl]="'UI.Dialog.btnAdd' | translate"
        [actionLoading]="isLoading"
        (onCloseCancel)="onCloseCancel()"
        featureCode="TEAM_MEMBER,C"
    ></dxy-modal-footer>
</form>
