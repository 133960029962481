import { inheritSerialization, serialize } from 'cerialize';
import { BaseServiceParameter } from '@datagalaxy/data-access';

export class ClientContext {
    /** not used anymore */
    public readonly isCollapsedNavBar = true;

    constructor(
        public currentState: string,
        public currentViewType: string,
        public IsFullPageMode: boolean,
        public listViewType: string,
        public isDockingPaneVisible: boolean,
        public activeFilterId: string,
        public userAgent: string,
        public graphicResolution: string,
        public hasSavedFilter: boolean,
        public hasTypeFilter: boolean,
        public hasGlobalTextFilter: boolean,
        public hasOtherFilter: boolean,
        public isDockingPaneCollapsed: boolean,
        public dockingPaneActiveTool: string,
        public licenseLevel: string,
        public isMonoWorkspace: boolean,
        public isClientAdmin: boolean,
        public userService: string,
        public userRole: string,
        public userTitle: string,
        public dgAppVersion: string,
    ) {}
}

@inheritSerialization(BaseServiceParameter)
export class ClientErrorParameter extends BaseServiceParameter {
    @serialize public ErrorData: any;
    @serialize public ClientContext?: ClientContext;
}
