import { deserializeAs, inheritSerialization, serialize } from 'cerialize';
import {
    BaseServiceParameter,
    BaseServiceResult,
} from '@datagalaxy/data-access';
import { ScreenDTO } from './screen';

@inheritSerialization(BaseServiceParameter)
export class GetClientScreensParameter extends BaseServiceParameter {
    @serialize ModuleNameFilter?: string;

    constructor(moduleName?: string) {
        super();
        this.ModuleNameFilter = moduleName;
    }
}

@inheritSerialization(GetClientScreensParameter)
export class GetSpaceScreensParameter extends GetClientScreensParameter {
    @serialize SpaceId: string;

    constructor(spaceId: string, moduleName: string, versionId: string) {
        super(moduleName);
        this.SpaceId = spaceId;
        this.VersionId = versionId;
    }
}

@inheritSerialization(BaseServiceParameter)
export class CreateSpaceScreenParameter extends BaseServiceParameter {
    @serialize SpaceId: string;
    @serialize ModuleName: string;
    @serialize SubTypeName: string;
    @serialize Layout: string;

    constructor(
        spaceId: string,
        moduleName: string,
        subTypeName: string,
        layout: string,
    ) {
        super();
        this.SpaceId = spaceId;
        this.ModuleName = moduleName;
        this.SubTypeName = subTypeName;
        this.Layout = layout;
    }
}

@inheritSerialization(BaseServiceParameter)
export class ResetClientScreenParameter extends BaseServiceParameter {
    @serialize ModuleName: string;
    @serialize SubTypeName: string;

    constructor(moduleName: string, subTypeName: string) {
        super();
        this.ModuleName = moduleName;
        this.SubTypeName = subTypeName;
    }
}

@inheritSerialization(ResetClientScreenParameter)
export class ResetSpaceScreenParameter extends ResetClientScreenParameter {
    @serialize SpaceId: string;

    constructor(spaceId: string, moduleName: string, subTypeName: string) {
        super(moduleName, subTypeName);
        this.SpaceId = spaceId;
    }
}

@inheritSerialization(BaseServiceParameter)
export class UpdateClientScreenParameter extends BaseServiceParameter {
    @serialize ModuleName: string;
    @serialize SubTypeName: string;
    @serialize Layout: string;

    constructor(moduleName: string, subTypeName: string, layout: string) {
        super();
        this.ModuleName = moduleName;
        this.SubTypeName = subTypeName;
        this.Layout = layout;
    }
}

@inheritSerialization(UpdateClientScreenParameter)
export class UpdateSpaceScreenParameter extends UpdateClientScreenParameter {
    @serialize SpaceId: string;

    constructor(
        spaceId: string,
        moduleName: string,
        subTypeName: string,
        layout: string,
    ) {
        super(moduleName, subTypeName, layout);
        this.SpaceId = spaceId;
    }
}

@inheritSerialization(BaseServiceResult)
export class GenericScreenResult extends BaseServiceResult {
    @deserializeAs(ScreenDTO) public Screen!: ScreenDTO;
}

@inheritSerialization(BaseServiceResult)
export class GetScreensResult extends BaseServiceResult {
    @deserializeAs(ScreenDTO) public Screens!: ScreenDTO[];
}

@inheritSerialization(BaseServiceResult)
export class BulkUpdateScreenResult extends BaseServiceResult {
    @deserializeAs(ScreenDTO) public UpdatedScreensList!: ScreenDTO[];
}
