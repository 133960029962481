import { BaseComponent } from '@datagalaxy/utils';

/**
 * decorator to manage loading state when calling an async method.
 * This decorator works for methods inside classes extended from BaseComponent
 * @example
 * -@withLoading()
    protected async asyncMethod() {
        await this.apiCall()
    }
 */
export function withLoading() {
    return function (
        _target: BaseComponent,
        _key: string,
        descriptor: PropertyDescriptor,
    ) {
        const originalMethod = descriptor.value;

        descriptor.value = async function (...args: any[]) {
            const componentInstance = this as BaseComponent;
            componentInstance.startLoading();
            try {
                return await originalMethod.apply(this, args);
            } finally {
                componentInstance.endLoading();
            }
        };

        return descriptor;
    };
}
