import {
    Directive,
    ElementRef,
    HostListener,
    Input,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { MatLegacyTooltip as MatTooltip } from '@angular/material/legacy-tooltip';
import { TooltipPosition } from '@angular/material/tooltip';

/**
 * ## Role
 * Add a tooltip text when text is ellipsed
 * ## Features
 * - By default display the original content of HTMLElement
 * - Setting lineClamp input will truncate the text vertically
 * on the x specified line
 * - Tooltip text can be set by input
 * @example <div dxyEllipsisTooltip> content </div>
 * // tooltip contains 'content'
 * @example <div [dxyEllipsisTooltip]="'otherContent'"> content </div>
 * // tooltip contains 'otherContent'
 * @example <div [dxyEllipsisTooltip]="'otherContent'" dxyEllipsisMode="alwaysVisible"> content <div>
 * // tooltip contains 'otherContent' is showed even if the text is not ellipsed
 * // tooltip contains 'otherContent\ncontent' when the text is ellipsed
 */
@Directive({
    standalone: true,
    selector: '[dxyEllipsisTooltip]',
    providers: [MatTooltip],
})
export class EllipsisTooltipDirective implements OnDestroy, OnInit {
    @Input('dxyEllipsisTooltip') customText?: string;
    @Input('dxyEllipsisTooltipPosition') position?: TooltipPosition;
    @Input('dxyEllipsisTooltipDisabled') disabled?: boolean;
    @Input('dxyEllipsisTooltipClass') class?: string;
    @Input('dxyEllipsisTooltipLineClamp') lineClamp?: number;
    // eslint-disable-next-line @angular-eslint/no-input-rename
    @Input('dxyEllipsisMode') mode?: EllipsisTooltipMode = 'ellipsed';

    private get el(): HTMLElement {
        return this.elementRef.nativeElement;
    }

    private get alwaysVisible() {
        return this.mode === 'alwaysVisible';
    }

    private get ellipsed() {
        return this.isEllipsed(this.el, !!this.lineClamp);
    }

    private get tooltipActive() {
        return (!this.disabled && this.ellipsed) || this.alwaysVisible;
    }

    constructor(
        private elementRef: ElementRef,
        private tooltip: MatTooltip,
    ) {}

    ngOnInit() {
        this.el.classList.add(
            this.lineClamp ? 'multiline-ellipsis-content' : 'ellipsis-content',
        );
        if (this.lineClamp) {
            this.el.style.webkitLineClamp = this.lineClamp.toString();
        }
    }

    ngOnDestroy() {
        this.tooltip.hide();
    }

    @HostListener('mouseover') mouseover() {
        if (!this.tooltipActive) {
            return;
        }
        this.tooltip.message = this.getMessage() || '';
        this.tooltip.tooltipClass = this.class || '';
        this.tooltip.position = this.position || 'above';
        this.tooltip.show();
    }

    @HostListener('mouseleave') mouseleave() {
        this.tooltip.hide();
    }

    private getMessage() {
        if (!this.alwaysVisible) {
            return this.customText || this.el.textContent;
        }
        if (!this.ellipsed) {
            return this.customText;
        }

        return this.customText
            ? `${this.el.textContent}\n${this.customText}`
            : this.el.textContent;
    }

    /**
     * Returns true if the given element is bigger than its container on one dimension:
     * taller if *isVertical* is true, else wider.
     * It is usefull when paired with line clamp css property
     */
    public isEllipsed(el: HTMLElement, isVertical?: boolean) {
        if (!el) {
            return;
        }
        return (
            (!isVertical && el.scrollWidth > el.offsetWidth) ||
            (isVertical && el.scrollHeight > el.offsetHeight)
        );
    }
}

type EllipsisTooltipMode = 'ellipsed' | 'alwaysVisible';
