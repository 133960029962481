import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DxyAttributeBaseInput } from '../DxyAttributeBaseInput';
import { TranslateService } from '@ngx-translate/core';
import {
    DxyFieldDataTypeSizeComponent,
    IDataTypeDisplayAdapter,
    IDataTypePrecisionSize,
} from '@datagalaxy/core-ui/fields';
import { ModelerService } from '../../../../modeler/services/modeler.service';
import {
    DataTypeDTO,
    DataTypePrecisionSize,
} from '@datagalaxy/webclient/modeler/data-access';
import { KeyboardUtil } from '@datagalaxy/utils';
import { DxyUnitaryFieldActionsComponent } from '../../../fields/unitary/dxy-unitary-field-actions/dxy-unitary-field-actions.component';
import { NgIf } from '@angular/common';
import { FormsModule } from '@angular/forms';

/** Displays a data type selector, a size input and a precision (number) input,
 *  Used for db column DataTypePrecisionSize attribute for type, size, precision, all at once */
@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'dxy-attribute-data-type-size-input',
    templateUrl: './dxy-attribute-data-type-size-input.component.html',
    standalone: true,
    imports: [
        DxyFieldDataTypeSizeComponent,
        FormsModule,
        NgIf,
        DxyUnitaryFieldActionsComponent,
    ],
})
export class DxyAttributeDataTypeSizeInputComponent
    extends DxyAttributeBaseInput<DataTypePrecisionSize>
    implements OnInit
{
    @ViewChild('field') field: DxyFieldDataTypeSizeComponent<DataTypeDTO>;

    public readonly adapter: IDataTypeDisplayAdapter<DataTypeDTO> = {
        getText: (o) => o.DisplayName,
        hasSize: (o) => o.IsSizeRequired,
        hasPrecision: (o) => o.IsPrecisionRequired,
    };
    public availableDataTypes: DataTypeDTO[];
    public fieldValue: IDataTypePrecisionSize<DataTypeDTO> = {};
    public get hasLabel() {
        return this.isLabelDisplayed || this.isBulkForm;
    }

    private initialValue: IDataTypePrecisionSize<DataTypeDTO> = {};
    private initialValueSet = false;

    constructor(
        private modelerService: ModelerService,
        elementref: ElementRef<HTMLElement>,
        translate: TranslateService,
    ) {
        super(elementref, translate);
    }

    ngOnInit() {
        super.ngOnInit();
        this.init();
    }

    public onKeyPress(event: KeyboardEvent) {
        if (KeyboardUtil.preventEnterKey(event, true)) {
            this.onEnterKeyDown().then();
        }
    }

    //#region IAttributeBaseInputOverride

    public isDirty() {
        return (
            this.fieldValue.dataType != this.initialValue.dataType ||
            this.fieldValue.size != this.initialValue.size ||
            this.fieldValue.precision != this.initialValue.precision
        );
    }

    public async onBeforeValidate() {
        if (this.hasInternalError) {
            return false;
        }

        if (!this.isDirty()) {
            this.initInitialValue();
            return false;
        }
        const dataType = this.fieldValue.dataType;
        const value = new DataTypePrecisionSize(
            dataType.ReferenceId,
            dataType.IsSizeRequired ? this.fieldValue.size : undefined,
            dataType.IsPrecisionRequired
                ? this.fieldValue.precision
                : undefined,
        );
        await this.setAttributeValue(value, 'DataTypePrecisionSize');

        return true;
    }
    public onAfterValidate(validated: boolean) {
        if (!validated || this.isValidated) {
            this.initInitialValue();
        }
    }

    public onAfterUndo() {
        this.fieldValue = { ...this.initialValue };
    }

    public blurField() {
        this.field.doBlur();
    }
    public focusField() {
        this.field.doFocus();
    }

    //#endregion

    private async init() {
        const entity = this.getEntityData();
        this.availableDataTypes =
            await this.modelerService.getModelDataTypesFromEntity(entity);
        const dataTypeId = this.getAttributeValue<string>('DataTypeRef');
        const dataType =
            dataTypeId &&
            this.availableDataTypes?.find(
                (dto) => dto.ReferenceId == dataTypeId,
            );
        if (this.isBulkForm) {
            this.setCurrentAttributeKey('DataTypePrecisionSize');
        }
        this.fieldValue = {
            dataType,
            size: this.getAttributeValue<number>('Size'),
            precision: this.getAttributeValue<number>('Precision'),
        };
        if (!this.initialValueSet) {
            this.initInitialValue(true);
        }
    }

    private initInitialValue(isInit = false) {
        this.initialValueSet = isInit;
        this.initialValue = { ...this.fieldValue };
    }
}
