<!-- root case -->
<ng-template
    *ngIf="!_childOption && options?.length"
    [ngTemplateOutlet]="list"
></ng-template>

<!-- child case: when sub-options -->
<ng-container *ngIf="_childOption">
    <!-- sub-menu trigger: glyph-button -->
    <button
        #menuTrigger="matMenuTrigger"
        dxyIconButton
        variant="tertiary"
        type="button"
        [class.in-burger-menu]="isInBurgerMenu"
        [disabled]="isDisabled(_childOption) || isButtonDisabled(_childOption)"
        [matMenuTriggerFor]="menu"
        (menuOpened)="onMenuOpenedClosed(true)"
        (menuClosed)="onMenuOpenedClosed(false)"
        (click)="onMenuTriggerClick($event)"
        (keydown.escape)="onEscapeKey($event)"
        [ngClass]="menuSideClass"
        [class.deployed]="isMenuOpen"
    >
        <i *ngIf="isBurgerMenu" class="glyph glyph-splitter"></i>
        <ng-template
            *ngTemplateOutlet="optionContent; context: { option: _childOption }"
        ></ng-template>
    </button>

    <!-- sub-menu overlay -->
    <mat-menu
        #menu="matMenu"
        [class]="matMenuClass"
        [xPosition]="xPosition"
        [yPosition]="yPosition"
        [overlapTrigger]="true"
    >
        <ng-container *ngIf="isMenuOpen && !isHidden(_childOption)">
            <ng-template
                *ngIf="!isMultiSelect(_childOption)"
                [ngTemplateOutlet]="list"
            ></ng-template>
            <dxy-multiselect-list
                *ngIf="isMultiSelect(_childOption)"
                [data]="getMultiSelectData(_childOption)"
            ></dxy-multiselect-list>
        </ng-container>
    </mat-menu>
</ng-container>

<!-- the options list -->
<ng-template #list>
    <ul
        class="toolbar-list"
        [class.vertical]="isVertical"
        [class.horizontal]="isHorizontal"
        [class.burger-menu]="isBurgerMenu"
        [class.in-burger-menu]="isInBurgerMenu"
        [class.options-stride]="!!_childOption?.stride"
        [style.--options-stride]="_childOption?.stride"
    >
        <ng-container
            *ngFor="
                let option of options;
                let index = index;
                trackBy: getOptionId
            "
        >
            <!-- option -->
            <li
                *ngIf="!isHidden(option)"
                [class.separator]="isSeparator(option)"
                [class.current-active]="isCurrentActive(option)"
                [class.burger-menu-option]="isBurgerMenu"
                [class.in-burger-menu]="isBurgerMenu || isInBurgerMenu"
                [dxyDataTestId]="getDataTestId(option)"
                [attr.data-tracker-id]="getDataTrackerId(option)"
            >
                <ng-container *ngIf="!isSeparator(option)">
                    <!-- case 1: drop-down option: glyph-button and sub-options -->
                    <dxy-graphical-toolbar
                        *ngIf="isSubMenu(option)"
                        [_childOption]="option"
                        [_parent]="this"
                        [class]="class"
                        [options]="option.options"
                        [placement]="placement"
                        [menuSide]="menuSide"
                        [orientation]="orientation"
                        [isInToolbarRoot]="true"
                        (functional)="onFunctionalInternal($event)"
                        [logId]="getNestedLogId(index)"
                        [closeHierarchyOnMenuClose]="!isBurgerMenu"
                    ></dxy-graphical-toolbar>

                    <!-- case 2: glyph-button, or glyph/text -->
                    <ng-container *ngIf="!isSubMenu(option)">
                        @if (isBurgerMenu && getText(option)) {
                            <button
                                type="button"
                                class="burger-menu-text-option"
                                [disabled]="isButtonDisabled(option)"
                                (click)="onOptionClicked(option, $event)"
                                [ngClass]="getOptionClass(option)"
                                [matTooltip]="getTooltipText(option, true)"
                                [matTooltipPosition]="matTooltipPosition"
                                matTooltipClass="multiline-tooltip"
                            >
                                <ng-template
                                    *ngTemplateOutlet="
                                        optionContent;
                                        context: { option }
                                    "
                                ></ng-template>
                            </button>
                        } @else if (option.callback) {
                            <button
                                dxyIconButton
                                [variant]="option.buttonVariant ?? 'tertiary'"
                                type="button"
                                [class.in-burger-menu]="
                                    isBurgerMenu || isInBurgerMenu
                                "
                                [disabled]="isButtonDisabled(option)"
                                (click)="onOptionClicked(option, $event)"
                                [ngClass]="getOptionClass(option)"
                                [matTooltip]="getTooltipText(option, true)"
                                [matTooltipPosition]="matTooltipPosition"
                                matTooltipClass="multiline-tooltip"
                            >
                                <ng-template
                                    *ngTemplateOutlet="
                                        optionContent;
                                        context: { option }
                                    "
                                ></ng-template>
                            </button>
                        } @else {
                            <span [ngClass]="getOptionClass(option)">
                                <ng-template
                                    [ngTemplateOutlet]="optionContent"
                                    [ngTemplateOutletContext]="{ option }"
                                ></ng-template>
                            </span>
                        }
                    </ng-container>
                </ng-container>
            </li>
        </ng-container>
    </ul>
</ng-template>

<!-- an option's content (every case) -->
<ng-template #optionContent let-option="option">
    <dxy-renderer
        *ngIf="option.renderData"
        [renderer]="option.renderData.renderer"
        [param]="option.renderData.param"
        [isMini]="option.renderData.isMini"
    ></dxy-renderer>
    <ng-container *ngIf="!option.renderData">
        <i *ngIf="hasGlyphClass(option)" [ngClass]="getGlyphClass(option)"></i>
        <span *ngIf="hasText(option)" class="text">{{ getText(option) }}</span>
    </ng-container>
</ng-template>
