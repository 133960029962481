import { IDragDropConfig } from '@datagalaxy/core-ui';
import { IGridCellParams } from '@datagalaxy/core-ui/cell-components';
import {
    HierarchicalData,
    IEntityIdentifier,
} from '@datagalaxy/dg-object-model';

export interface IEntityCardCellParams<TEntity = any>
    extends IGridCellParams<
        TEntity,
        HierarchicalData,
        IEntityCardCellInputs<TEntity>
    > {}

export interface IEntityCardCellInputs<TEntity = any> {
    hideBreadcrumb?: boolean | ((params: IEntityCardCellParams) => boolean);
    noLabelNavLink?: boolean;
    noBreadcrumbNavLink?: boolean;
    breadcrumbOpenPreview?: boolean;
    forceIncludeSpace?: boolean;
    forceExcludeSpace?: boolean;
    actionsCaptureMouseDown?: boolean;
    noName?: boolean;
    aliasName?: string | ((params: IEntityCardCellParams) => string);
    nameSuffix?: string | ((params: IEntityCardCellParams) => string);
    withExtraData?: boolean;
    dtContext?: string;
    hasGoldenLinkIndicator?: boolean;
    customClass?: string;
    isFromHierarchical?: boolean;
    placeholderText?: string;
    isMiniCard?: boolean;
    dragDrop?: IDragDropConfig<IEntityIdentifier>;
    getAttributes?: (data: TEntity) => object;
    isRestrictedItem?: boolean | ((params: IEntityCardCellParams) => boolean);
    showRestrictedActions?: boolean;
}

export const multiSelectEntityCardParams: IEntityCardCellParams = {
    isMini: true,
    inputs: {
        noLabelNavLink: true,
        noBreadcrumbNavLink: true,
    },
};
