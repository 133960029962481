import { AccountSecurityTokenModel } from './account-security-token.model';

export enum AccountSetPasswordMode {
    InitialPassword,
    ResetPassword,
}

export class AccountSetPasswordModel {
    public mode: AccountSetPasswordMode;
    public token: string = null;
    public mail = '';
    public newPassword = '';
    public newPasswordConfirm = '';
    public isValidToken: boolean;
    public isExpiredToken: boolean;

    constructor(mode: AccountSetPasswordMode) {
        this.mode = mode;
    }

    private isModeEqualTo(targetMode: AccountSetPasswordMode) {
        return this.mode === targetMode;
    }

    public isInitialPasswordMode() {
        return this.isModeEqualTo(AccountSetPasswordMode.InitialPassword);
    }

    public isResetPasswordMode() {
        return this.isModeEqualTo(AccountSetPasswordMode.ResetPassword);
    }

    public hasToken() {
        return !!this.token;
    }

    public setInformationsWithAccountSecurityToken(
        securityToken: AccountSecurityTokenModel,
    ) {
        this.mail = securityToken.userMail;
    }
}
