import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import {
    DXY_POPOVER_DATA,
    DxyBasePopoverComponent,
    IActionOption,
} from '@datagalaxy/core-ui';
import { CommentaryService } from '../../../commentary/commentary.service';
import { TaskService } from '../../../tasks/task.service';
import { EmailUtil, StringUtil } from '@datagalaxy/core-util';
import { OverlayRef } from '@angular/cdk/overlay';
import { UserPublicData } from '@datagalaxy/webclient/user/domain';
import { IMiniEntityContent } from '@datagalaxy/webclient/entity/domain';
import { UserCellComponent } from '@datagalaxy/webclient/user/ui';
import { TranslateModule } from '@ngx-translate/core';
import { SearchInputComponent } from '@datagalaxy/ui/search';
import { NgIf, NgFor } from '@angular/common';

/**
 * ## Role
 * Display a user list with a search bar if there is too many user.
 * Each row allow to add comment/task/email for the concerned user and
 * entity
 */
@Component({
    selector: 'dxy-entity-user-list-popover-tooltip',
    templateUrl: './dxy-entity-user-list-popover-tooltip.component.html',
    styleUrls: ['./dxy-entity-user-list-popover-tooltip.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgIf,
        SearchInputComponent,
        TranslateModule,
        NgFor,
        UserCellComponent,
    ],
})
export class DxyEntityUserListPopoverTooltipComponent extends DxyBasePopoverComponent<IUsersPopoverTooltipResolve> {
    /**
     * Tooltips are disabled if not single user to not add too much
     * visual clutter
     */
    public actions: IActionOption<UserPublicData>[] = [
        {
            glyphClass: 'glyph-comment',
            buttonColor: 'transparent',
            buttonVariant: 'primary',
            tooltipTranslateKey: this.hasSingleUser
                ? 'UI.CommentaryContainer.lblAdd'
                : '',
            tooltipPlacement: 'bottom',
            callback: (data) => this.openCommentaryUserModal(data.UserId),
            hidden: (data) =>
                data.LicenseLevel == null || !this.data.entityData,
            alwaysVisible: this.hasSingleUser,
        },
        {
            glyphClass: 'glyph-file-tasks-check',
            buttonColor: 'transparent',
            buttonVariant: 'primary',
            tooltipTranslateKey: this.hasSingleUser
                ? 'UI.TaskContainer.lblAdd'
                : '',
            tooltipPlacement: 'bottom',
            callback: (data) => this.openTaskUserModal(data.UserId),
            hidden: (data) =>
                data.LicenseLevel == null || !this.data.entityData,
            alwaysVisible: this.hasSingleUser,
        },
        {
            glyphClass: 'glyph-email',
            buttonColor: 'transparent',
            buttonVariant: 'primary',
            tooltipTranslateKey: this.hasSingleUser ? 'UI.User.SendEmail' : '',
            tooltipPlacement: 'bottom',
            callback: (data) => this.openEmail(data.Email),
            alwaysVisible: this.hasSingleUser,
        },
    ];
    public filteredUsers: UserPublicData[];

    public get title() {
        return this.data.title;
    }
    public get users() {
        return this.filteredUsers || this.data.users;
    }
    public get userCount() {
        return this.users?.length || 0;
    }
    public get showSearchInput() {
        return this.data.users?.length > 7;
    }
    /**
     * Fixed height to prevent mouseleave if the panel is shorter after
     * search is made
     */
    public get bodyHeight() {
        return this.data.users?.length * 40 + 10;
    }
    public get hasSingleUser() {
        return this.data.users?.length === 1;
    }

    constructor(
        @Inject(DXY_POPOVER_DATA) data: IUsersPopoverTooltipResolve,
        overlayRef: OverlayRef,
        private commentaryService: CommentaryService,
        private taskService: TaskService,
    ) {
        super(overlayRef, data);
    }

    public onSearchTerm(searchTerm: string) {
        this.filteredUsers = StringUtil.filterSearched(
            searchTerm,
            this.data.users,
            (d) => d.FullName,
        );
    }

    public async openCommentaryUserModal(userId: string) {
        this.log('openCommentaryUserModal');
        this.onClose();
        await this.commentaryService.openCommentaryNewModal({
            entityData: this.data.entityData,
            targetUserIds: [userId],
        });
    }
    public async openTaskUserModal(userId: string) {
        this.log('openTaskUserModal');
        this.onClose();
        await this.taskService.openTaskFormModal(this.data.entityData, userId);
    }
    public openEmail(email: string) {
        EmailUtil.mailTo(email);
    }
}

export interface IUsersPopoverTooltipResolve {
    title: string;
    entityData: IMiniEntityContent;
    users: UserPublicData[];
}
