import {
    Component,
    Input,
    OnInit,
    SimpleChanges,
    OnChanges,
} from '@angular/core';
import {
    MatLegacyTableDataSource as MatTableDataSource,
    MatLegacyTableModule,
} from '@angular/material/legacy-table';
import {
    CdkDragDrop,
    moveItemInArray,
    CdkDropList,
    CdkDrag,
} from '@angular/cdk/drag-drop';
import { IOptionAdapter } from '@datagalaxy/core-ui';
import { ControlContainer, NgForm, FormsModule } from '@angular/forms';
import { ForeignKeyColumnMappingContainer } from '../ForeignKeyColumnMappingContainer';
import { ForeignKeyColumnMapping } from '../ForeignKeyColumnMapping';
import { ModelerService } from '../../services/modeler.service';
import { Column, Table } from '@datagalaxy/webclient/modeler/data-access';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { DxyFieldSelectComponent } from '@datagalaxy/core-ui/fields';
import { DxyFieldTextComponent } from '@datagalaxy/ui/fields';
import { NgIf } from '@angular/common';
import { MatLegacyCheckboxModule } from '@angular/material/legacy-checkbox';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'dxy-foreign-key-columns-table',
    templateUrl: 'dxy-foreign-key-columns-table.component.html',
    styleUrls: ['dxy-foreign-key-columns-table.component.scss'],
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
    standalone: true,
    imports: [
        MatLegacyTableModule,
        CdkDropList,
        FormsModule,
        CdkDrag,
        TranslateModule,
        MatLegacyCheckboxModule,
        NgIf,
        DxyFieldTextComponent,
        DxyFieldSelectComponent,
    ],
})
export class DxyForeignKeyColumnsTableComponent
    extends DxyBaseComponent
    implements OnInit, OnChanges
{
    @Input() hasWriteAccess: boolean;
    @Input() childTable: Table;
    @Input() isFkMandatory: boolean;
    @Input() fkColumnMappingContainer: ForeignKeyColumnMappingContainer;

    public readonly datasource =
        new MatTableDataSource<ForeignKeyColumnMapping>();
    public readonly availableChildColumnsByMapping = new Map<
        ForeignKeyColumnMapping,
        Column[]
    >();
    public readonly displayedColumns = [
        'primaryKey',
        'columnType',
        'newColumn',
        'childTable',
        'status',
    ];
    public readonly adapter: IOptionAdapter<Column> = {
        getText: (c) => c.TechnicalName,
    };

    constructor(private modelerService: ModelerService) {
        super();
    }

    ngOnChanges(changes: SimpleChanges) {
        super.onChanges(
            changes,
            ['fkColumnMappingContainer', 'childTable'],
            () => this.init(),
        );
    }

    ngOnInit() {
        this.log(
            'ngOnInit',
            this.childTable,
            this.fkColumnMappingContainer,
            this.hasWriteAccess,
        );
        this.init();
    }

    //#region event handlers
    public onDrop(event: CdkDragDrop<string[]>) {
        this.log('onDrop', event);
        this.init(event);
    }
    public onChildColumnSelected(columnMapping: ForeignKeyColumnMapping) {
        this.log(
            'onChildColumnSelected',
            columnMapping.childColumn?.TechnicalName,
        );
        this.updateMapping(columnMapping);
    }
    public onCheckNewColumn(columnMapping: ForeignKeyColumnMapping) {
        this.log('onCheckNewColumn', columnMapping.isNewColumn);
        this.updateMapping(columnMapping);
    }
    //#endregion

    public getPrimaryKeyColumnName(columnMapping: ForeignKeyColumnMapping) {
        return columnMapping.primaryKeyColumnName;
    }

    public getPrimaryKeyColumnTypeName(columnMapping: ForeignKeyColumnMapping) {
        return columnMapping.primaryKeyColumnTypeName;
    }

    public isMappingSuccess(columnMapping: ForeignKeyColumnMapping) {
        return columnMapping.isMappingSuccess;
    }
    private init(event?: CdkDragDrop<any>) {
        this.log('init', !!event);
        const mappings = this.fkColumnMappingContainer.mappings;
        if (event) {
            moveItemInArray(mappings, event.previousIndex, event.currentIndex);
        }
        this.datasource.data = mappings.slice();
        this.availableChildColumnsByMapping.clear();
        mappings.forEach((mapping) => {
            //console.log(mapping.newColumnName, mapping.childColumn)
            const childColumns =
                this.modelerService.getColumnsForFkColumnMapping(
                    this.childTable,
                    mapping,
                );
            if (childColumns.length) {
                this.availableChildColumnsByMapping.set(mapping, childColumns);
            } else {
                mapping.isNewColumn = true;
            }
        });
    }

    private updateMapping(columnMapping: ForeignKeyColumnMapping) {
        columnMapping.updateMappingValidity();
        this.fkColumnMappingContainer.updateMappingUnicity();
    }
}
