import { Dom2dUtil } from '@datagalaxy/core-2d-util';
import { ArrowKind, IArrowParams, IDiagramMarkerSpec } from './marker.types';
import {
    IDiagramShapeSpec,
    ShapeId,
    ShapeOrient,
    ShapeType,
} from './shape.types';

/** Produce data to generate arrow-shaped path and marker elements */
export class ArrowBuilder {
    /** Note: Arrows origin point (0,0) is the point where the connector's path connects to the arrow */

    private static readonly arrowParamTemplates = new Map<
        ShapeId,
        IArrowParams
    >([
        //#region used in diagrams
        [
            ShapeId.arrowPlain90,
            {
                kind: ArrowKind.plain,
                breadth: 10,
                length: 10,
            },
        ],
        [
            ShapeId.arrowPlain45fb,
            {
                kind: ArrowKind.plain,
                foldback: 0.9,
                breadth: 6,
                length: 10,
            },
        ],
        [
            ShapeId.arrowLine90,
            {
                kind: ArrowKind.line,
                breadth: 12,
                length: 6,
            },
        ],
        //#endregion

        // used in dp-mapping
        [
            ShapeId.arrowLine6x6,
            {
                kind: ArrowKind.line,
                breadth: 6,
                length: 6,
            },
        ],
    ]);

    public static makeArrowSpec(p: IArrowParams, forMarker?: boolean) {
        switch (p.kind) {
            case ArrowKind.line:
                return ArrowBuilder.lineArrowSpec(p);
            case ArrowKind.plain:
                return p.foldback >= 0 && p.foldback < 1
                    ? ArrowBuilder.foldbackArrowSpec(p)
                    : ArrowBuilder.triangleArrowSpec(p, forMarker);
        }
    }
    private static triangleArrowSpec(
        p: IArrowParams,
        forMarker?: boolean,
    ): IDiagramShapeSpec {
        const { length: w, breadth: h, thickness = 0 } = p;
        const pTip = { x: w, y: 0 };
        const p1 = { x: 0, y: -h / 2 };
        const p2 = { x: 0, y: h / 2 };
        const d = thickness + 1,
            vw = w + d;
        return {
            type: ShapeType.arrow,
            points: [p1, pTip, p2],
            recess: w + (forMarker ? thickness : 0),
            close: true,
            thickness,
            vw,
            vh: h + d,
            vx: 0,
        };
    }
    private static foldbackArrowSpec(p: IArrowParams): IDiagramShapeSpec {
        const { length: w, breadth: h, thickness = 0, foldback: fb } = p;
        const xt = w * fb;
        const xb = -w * (1 - fb);
        const pfb = { x: 0, y: 0 };
        const pTip = { x: xt, y: 0 };
        const p1 = { x: xb, y: -h / 2 };
        const p2 = { x: xb, y: h / 2 };
        const vw = w + 3 * thickness,
            d = thickness + 1;
        return {
            type: ShapeType.arrow,
            points: [pfb, p1, pTip, p2],
            recess: xt + d,
            close: true,
            vw,
            vh: h + 2 * thickness,
            vx: -d,
        };
    }
    private static lineArrowSpec(p: IArrowParams): IDiagramShapeSpec {
        const { length: w, breadth: h, thickness = 0 } = p;
        const p1 = { x: -w, y: -h / 2 };
        const pTip = { x: 0, y: 0 };
        const p2 = { x: -w, y: h / 2 };
        const vw = w + thickness + 1;
        return {
            type: ShapeType.arrow,
            points: [p1, pTip, p2],
            recess: thickness / 2,
            vw,
            vh: h + thickness,
            vx: -vw / 2 - 2.5,
        };
    }

    public static generateArrowMarkerSpecs(
        thicknesses: number[],
        orient = ShapeOrient.end,
        addDummies?: boolean,
    ) {
        const arrowMarkerSpecs = new Map<string, IDiagramMarkerSpec>();
        ArrowBuilder.arrowParamTemplates.forEach((ap, at) =>
            thicknesses.forEach((thickness) => {
                arrowMarkerSpecs.set(
                    ArrowBuilder.getArrowShapeId(at, thickness),
                    ArrowBuilder.makeArrowMarkerSpec(
                        ap,
                        thickness,
                        orient,
                        addDummies,
                    ),
                );
            }),
        );
        return arrowMarkerSpecs;
    }

    public static generateArrowShapeSpecs(thicknesses: number[]) {
        const arrowMarkerSpecs = new Map<string, IDiagramShapeSpec>();
        ArrowBuilder.arrowParamTemplates.forEach((ap, at) =>
            thicknesses.forEach((thickness) => {
                arrowMarkerSpecs.set(
                    ArrowBuilder.getArrowShapeId(at, thickness),
                    ArrowBuilder.makeArrowShapeSpec(ap, thickness),
                );
            }),
        );
        return arrowMarkerSpecs;
    }

    private static getArrowShapeId(type: ShapeId, thickness: number) {
        return type ? `${ShapeId[type]}-${thickness || 0}` : null;
    }
    private static makeArrowMarkerSpec(
        p: IArrowParams,
        thickness: number,
        orient?: ShapeOrient,
        addDummies?: boolean,
    ): IDiagramMarkerSpec {
        const { vh, vw, vx, points, close, recess } =
            ArrowBuilder.makeArrowSpec({ ...p, thickness }, true);
        return {
            type: ShapeType.arrow,
            thickness,
            pathD: Dom2dUtil.pointsPathD(points, {
                close,
                roundDecimals: p.roundDecimals,
            }),
            recess,
            shapeClass: `arrow ${ArrowKind[p.kind]} thick-${thickness}`,
            addDummies,
            refX: 0,
            refY: 0,
            viewBox: [vx ?? -vw / 2, -vh / 2, vw, vh],
            markerWidth: vw,
            markerHeight: vh,
            orient:
                orient == ShapeOrient.start
                    ? 'auto-start-reverse'
                    : orient == ShapeOrient.end
                      ? 'auto'
                      : undefined,
            class: p.class,
            markerUnits: 'userSpaceOnUse',
        };
    }
    private static makeArrowShapeSpec(
        p: IArrowParams,
        thickness: number,
    ): IDiagramShapeSpec {
        const { points, close, recess } = ArrowBuilder.makeArrowSpec({
            ...p,
            thickness,
        });
        return {
            type: ShapeType.arrow,
            thickness,
            recess,
            shapeClass: `arrow ${ArrowKind[p.kind]} ${
                p.foldback ? 'fb' : ''
            } thick-${thickness}`,
            points,
            close,
        };
    }
}
