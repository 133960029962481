import {
    Component,
    ElementRef,
    HostListener,
    OnInit,
    ViewChild,
} from '@angular/core';
import { IOptionAdapter } from '@datagalaxy/core-ui';
import { DxyFieldSelectComponent } from '@datagalaxy/core-ui/fields';
import { TranslateService } from '@ngx-translate/core';
import { IEntityIdentifier } from '@datagalaxy/dg-object-model';
import { DxyAttributeBaseInput } from '../../DxyAttributeBaseInput';
import { AttributeDataService } from '../../../attribute-data.service';
import { EntityEventService } from '../../../../entity/services/entity-event.service';
import { AttributeMetaValue } from '@datagalaxy/webclient/attribute/domain';
import { AttributeSuggestionListComponent } from '../../../attribute-suggestion-list/attribute-suggestion-list.component';
import { DxyUnitaryFieldActionsComponent } from '../../../../fields/unitary/dxy-unitary-field-actions/dxy-unitary-field-actions.component';
import { NgIf, AsyncPipe } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'dxy-attribute-value-list-input',
    templateUrl: './dxy-attribute-value-list-input.component.html',
    styleUrls: ['./dxy-attribute-value-list-input.component.scss'],
    standalone: true,
    imports: [
        DxyFieldSelectComponent,
        FormsModule,
        NgIf,
        DxyUnitaryFieldActionsComponent,
        AttributeSuggestionListComponent,
        AsyncPipe,
    ],
})
export class DxyAttributeValueListInputComponent
    extends DxyAttributeBaseInput<AttributeMetaValue>
    implements OnInit
{
    @ViewChild('field') field: DxyFieldSelectComponent<AttributeMetaValue>;

    public adapter: IOptionAdapter<AttributeMetaValue> = {
        getText: (amv) => this.getOptionText(amv),
        getGlyphClass: (amv) => amv?.glyphClass,
    };

    public get attributeInfo() {
        return this.attributeMeta;
    }
    public get options() {
        return this.attributeListValues;
    }
    public get currentOption() {
        return this._currentOption;
    }
    public set currentOption(value: AttributeMetaValue) {
        this.setData(value.Value).then();
        this._currentOption = value;
    }
    private _currentOption: AttributeMetaValue;

    constructor(
        elementRef: ElementRef<HTMLElement>,
        translate: TranslateService,
        private attributeDataService: AttributeDataService,
        private entityEventService: EntityEventService,
    ) {
        super(elementRef, translate);
    }

    ngOnInit() {
        super.ngOnInit();
        const entityData = this.getEntityData();
        if (entityData) {
            super.registerSubscription(
                this.entityEventService.subscribeEntityUpdate(
                    entityData.ServerType,
                    (entity) => this.onEntityUpdate(entity),
                ),
            );
        }
        this.attributeMeta.ListValues = this.attributeMeta.ListValues.filter(
            (value) => !value.DeactivationTime,
        );
        this.setCurrentOption();
    }

    //#region IAttributeBaseInputOverride
    public onAfterUndo() {
        this.setCurrentOption();
    }
    public focusField() {
        this.field.doFocus();
    }
    public blurField() {
        this.field.doBlur();
    }
    //#endregion

    @HostListener('keydown.enter', ['$event'])
    private keydownEnter(event: KeyboardEvent) {
        if (!this.getOptionText(this.getData())) {
            return;
        }
        event.preventDefault();
        event.stopPropagation();
        this.onEnterKeyDown().then();
    }

    private onEntityUpdate(entity: IEntityIdentifier) {
        if (
            this.isActive ||
            this.isValidating ||
            !entity?.ReferenceId ||
            this.getEntityData()?.ReferenceId != entity.ReferenceId
        ) {
            return;
        }

        this.setCurrentOption();
    }

    private getOptionText(option: AttributeMetaValue) {
        if (!option) {
            return;
        }
        return (
            option.translatedDisplayName ??
            this.attributeDataService.getAttributeOptionDisplayName(
                this.attributeMeta,
                option,
            )
        );
    }

    private setCurrentOption() {
        const amv = this.getData();
        this._currentOption = this.options?.find(
            (option) => option.Value === amv,
        );
    }
}
