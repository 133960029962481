import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Directive, HostListener, Inject } from '@angular/core';
import { IModalInstanceOptions } from './core-modal.types';
import { DxyBaseComponent } from '@datagalaxy/ui/core';

/**
 * Base class for every modal.
 * [Angular doc](https://material.angular.io/components/dialog/overview)
 */
@Directive()
export abstract class DxyBaseModalComponent<
    TData,
    TResult,
> extends DxyBaseComponent {
    protected result?: TResult;

    private modalInstanceOptions?: IModalInstanceOptions;
    private _hasOpenPanel?: boolean;

    constructor(
        public dialogRef: MatDialogRef<
            DxyBaseModalComponent<TData, TResult>,
            TResult
        >,
        @Inject(MAT_DIALOG_DATA) protected data?: TData,
    ) {
        super();
    }

    @HostListener('window:keyup.esc')
    public onKeyUpEsc() {
        if (!this.dialogRef) {
            //we consider this as not a modal (case of a component used both as a modal and not)
            return;
        }
        if (this.modalInstanceOptions?.disableCloseOnEscapeKey) {
            return;
        }
        if (this.hasOpenPanel()) {
            this.log('hasOpenPanel - not closing');
            return;
        }
        this.onCloseCancel();
    }

    public onCloseCancel() {
        this.log('(super)onCloseCancel');
        this.dialogRef.close();
    }

    public onCloseSubmit() {
        this.log('(super)onCloseSubmit', this.result);
        this.dialogRef.close(this.result);
    }

    // called by the modal-manager service - before ngOnInit()
    public setModalInstanceOptions(options: IModalInstanceOptions) {
        this.log('setModalInstanceOptions', options);
        this.modalInstanceOptions = options;
        if (!options?.disableCloseOnBackdropClick) {
            super.subscribe(this.dialogRef.backdropClick(), () => {
                this.log('setModalInstanceOptions-backdropClick()');
                this.onCloseCancel();
            });
        }
    }

    /**
     * To be overriden when not using *onPanelOpenClose* and contained components have a menu panel,
     * to prevent the modal to close when the escape key is pressed by the user to close a menu. */
    protected hasOpenPanel(): boolean {
        return !!this._hasOpenPanel;
    }

    /** To be called from *openClose* event of a field that implements it,
     * to prevent the modal to close when the escape key is pressed by the user to close a menu. */
    public onPanelOpenClose(isOpen: boolean) {
        this.log('onPanelOpenClose', isOpen);
        this._hasOpenPanel = isOpen;
    }
}
