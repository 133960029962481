import { Directive, HostBinding, Input } from '@angular/core';
import { FreshDeskService } from './fresh-desk.service';

/** ## Role
 * Base class for every diagram node */
@Directive({
    selector: '[dxyFreshdeskHelpLink]',
    standalone: true,
})
export class DxyFreshdeskHelpLinkDirective {
    @Input() freshDeskArticle: string;

    constructor(protected freshDeskService: FreshDeskService) {}

    @HostBinding('attr.href') get getHref() {
        return this.freshDeskService.getFreshdeskUrlFromArticle(
            this.freshDeskArticle,
        );
    }

    @HostBinding('attr.target') get getTarget() {
        return '_blank';
    }
}
