import {
    autoserializeAs,
    deserializeAs,
    inheritSerialization,
    serialize,
} from 'cerialize';
import { EntityCommentaryDTO, ServerType } from '@datagalaxy/dg-object-model';
import {
    BaseServiceParameter,
    BaseServiceResult,
} from '@datagalaxy/data-access';

/**
 * As a matter of module separation, BaseEntitySocialParameter exists in both
 * "Task" and "Comment" modules. The implementation is supposed to be the same
 */
@inheritSerialization(BaseServiceParameter)
export class BaseEntitySocialParameter extends BaseServiceParameter {
    @autoserializeAs(String) public EntityDataReferenceId: string;
    @autoserializeAs(String) public EntityDataTypeName: string;

    constructor(entityId: string, serverType: ServerType, versionId?: string) {
        super();
        this.EntityDataReferenceId = entityId;
        this.EntityDataTypeName = ServerType[serverType];
        if (versionId) {
            this.VersionId = versionId;
        }
    }
}

@inheritSerialization(BaseEntitySocialParameter)
export class DeleteEntityCommentsParameter extends BaseEntitySocialParameter {
    @autoserializeAs(String) public CommentaryIds: string[];

    constructor(
        entityId: string,
        serverType: ServerType,
        versionId: string,
        commentId: string,
    ) {
        super(entityId, serverType);
        this.VersionId = versionId;
        this.CommentaryIds = [commentId];
    }
}

@inheritSerialization(BaseEntitySocialParameter)
export class CreateEntityCommentParameter extends BaseEntitySocialParameter {
    @serialize public Text: string;

    constructor(
        entityId: string,
        serverType: ServerType,
        versionId: string,
        text: string,
    ) {
        super(entityId, serverType);
        this.VersionId = versionId;
        this.Text = text;
    }
}

@inheritSerialization(BaseServiceResult)
export class CreateEntityCommentResult extends BaseServiceResult {
    @deserializeAs(EntityCommentaryDTO)
    public CreatedCommentary!: EntityCommentaryDTO;

    constructor() {
        super();
    }
}

@inheritSerialization(BaseEntitySocialParameter)
export class UpdateEntityCommentParameter extends BaseEntitySocialParameter {
    @serialize public Text: string;
    @serialize public CommentaryId: string;

    constructor(
        entityId: string,
        serverType: ServerType,
        versionId: string,
        commentId: string,
        text: string,
    ) {
        super(entityId, serverType);
        this.VersionId = versionId;
        this.CommentaryId = commentId;
        this.Text = text;
    }
}

@inheritSerialization(BaseServiceResult)
export class UpdateEntityCommentResult extends BaseServiceResult {
    @deserializeAs(EntityCommentaryDTO)
    public UpdatedCommentary!: EntityCommentaryDTO;

    constructor() {
        super();
    }
}

@inheritSerialization(BaseEntitySocialParameter)
export class GetEntityCommentsParameter extends BaseEntitySocialParameter {
    @serialize public WithChildren: boolean;

    constructor(
        entityId: string,
        serverType: ServerType,
        versionId: string,
        withChildren: boolean,
    ) {
        super(entityId, serverType);
        this.VersionId = versionId;
        this.WithChildren = withChildren;
    }
}

@inheritSerialization(BaseServiceResult)
export class GetEntityCommentsResult extends BaseServiceResult {
    @deserializeAs(EntityCommentaryDTO)
    public Commentaries!: EntityCommentaryDTO[];

    constructor() {
        super();
    }
}
