import {
    ActivatedRouteSnapshot,
    CanActivateFn,
    CanMatchFn,
    Router,
    RouterStateSnapshot,
} from '@angular/router';
import { LegacyAuthenticationService } from './legacy-authentication.service';
import { inject } from '@angular/core';
import { map, take } from 'rxjs';
import { filter } from 'rxjs/operators';
import { AppConfigService } from '@datagalaxy/webclient/config';

export const LegacyAuthGuard: CanActivateFn = (
    _route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
) => {
    const legacyAuthService = inject(LegacyAuthenticationService);
    const router = inject(Router);

    return legacyAuthService.loaded$.pipe(
        filter(Boolean),
        take(1),
        map(() => {
            if (!legacyAuthService.enabled) {
                return true;
            }

            if (!legacyAuthService.authenticated) {
                legacyAuthService.saveUrl(state.url);
                return router.createUrlTree(['/login']);
            }

            if (
                legacyAuthService.savedUrl &&
                legacyAuthService.savedUrl !== '/client/default'
            ) {
                const savedUrl = legacyAuthService.savedUrl;
                legacyAuthService.clearUrl();
                return router.parseUrl(savedUrl);
            }

            return true;
        }),
    );
};

/**
 * Enable the route only for the classic login legacy authentication
 * (not SSO nor Auth v2).
 */
export const LegacyAuthGuardMatch: CanMatchFn = () => {
    const authenticationService = inject(LegacyAuthenticationService);
    const appConfig = inject(AppConfigService);

    if (appConfig.ENABLE_AUTH_V2) {
        return false;
    }

    return authenticationService.loaded$.pipe(
        filter(Boolean),
        take(1),
        map(() => authenticationService.enabled),
    );
};
