import {
    ChangeDetectionStrategy,
    Component,
    forwardRef,
    Input,
    OnChanges,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import {
    DxyActionListComponent,
    IActionOption,
    IDragDropConfig,
    IFunctionalEvent,
} from '@datagalaxy/core-ui';
import { BaseCellComponent } from '@datagalaxy/core-ui/cell-components';
import {
    IEntityCardCellInputs,
    IEntityCardCellParams,
} from './entity-card-cell.types';
import { CoreUtil } from '@datagalaxy/core-util';
import { DxyNavBreadcrumbComponent } from '../../../navigation/dxy-nav-breadcrumb/dxy-nav-breadcrumb.component';
import {
    HierarchicalData,
    IEntityIdentifier,
    IHasHddData,
} from '@datagalaxy/dg-object-model';
import { EntityUiService } from '../../entity/services/entity-ui.service';
import { FilteredEntityItem } from '@datagalaxy/webclient/search/data-access';
import { EntityCardSettings } from './EntityCardSettings';
import { Observable } from 'rxjs';
import { EntityDisplayNameService } from '../../entity/services/entity-display-name.service';
import { FunctionalLogService } from '@datagalaxy/shared/monitoring/data-access';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';
import { ServerConstants } from '@datagalaxy/shared/server/domain';
import { EntityLifecycleStatus } from '@datagalaxy/webclient/attribute/domain';
import { ILayoutRefreshableGridCellComponent } from '@datagalaxy/ui/grid';
import { TranslateModule } from '@ngx-translate/core';
import { CdkDrag } from '@angular/cdk/drag-drop';
import { EllipsisTooltipDirective } from '@datagalaxy/ui/tooltip';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';
import {
    EntityTypeCardComponent,
    EntityTypeIconComponent,
} from '@datagalaxy/webclient/entity/ui';
import { AsyncPipe, NgClass, NgIf, NgTemplateOutlet } from '@angular/common';
import { RouterLink } from '@angular/router';
import { EntityUrlPipe } from '../../../entity-dashboard/entity-dashboard-url.pipe';
import PropertyName = ServerConstants.PropertyName;

@Component({
    selector: 'app-entity-card-cell',
    templateUrl: 'entity-card-cell.component.html',
    styleUrls: ['entity-card-cell.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgIf,
        DxyActionListComponent,
        EntityTypeIconComponent,
        EntityTypeCardComponent,
        forwardRef(() => DxyNavBreadcrumbComponent),
        MatLegacyTooltipModule,
        EllipsisTooltipDirective,
        NgClass,
        NgTemplateOutlet,
        CdkDrag,
        AsyncPipe,
        TranslateModule,
        RouterLink,
        forwardRef(() => EntityUrlPipe),
    ],
})
export class EntityCardCellComponent<TEntity extends IHasHddData = IHasHddData>
    extends BaseCellComponent<TEntity, HierarchicalData, IEntityCardCellParams>
    implements
        IEntityCardCellInputs,
        OnChanges,
        ILayoutRefreshableGridCellComponent
{
    @Input() hierarchicalData: HierarchicalData;
    @Input() attributes?: object;
    @Input() hideBreadcrumb?:
        | boolean
        | ((params: IEntityCardCellParams) => boolean);
    @Input() noLabelNavLink?: boolean;
    @Input() breadcrumbOpenPreview?: boolean;
    @Input() noBreadcrumbNavLink?: boolean;
    /* if true, display only entity icon and DisplayName on one small line */
    @Input() isMiniCard?: boolean;
    /* if true, space is included in the breadcrumbs; otherwise space is included only if no global current space */
    @Input() forceIncludeSpace: boolean;
    /* if true, space is excluded of the breadcrumbs; otherwise space is included only if no global current space */
    @Input() forceExcludeSpace: boolean;
    @Input() actions?: IActionOption<IHasHddData>[];
    @Input() actionsCaptureMouseDown?: boolean;
    /** when true, displayName and aliasName are not displayed */
    @Input() noName?: boolean;
    /** displayed before the displayName */
    @Input() aliasName?: string | ((params: IEntityCardCellParams) => string);
    /** appended to the displayName */
    @Input() nameSuffix?: string | ((params: IEntityCardCellParams) => string);
    @Input() withExtraData?: boolean;
    @Input() customClass?: string;
    @Input() hasGoldenLinkIndicator = false;
    /* concerns dxyNavDataLink */
    @Input() isFromHierarchical?: boolean;
    @Input() dtContext?: string;
    @Input() placeholderText = '';
    @Input() dragDrop?: IDragDropConfig<IEntityIdentifier>;
    @Input() showActions?: boolean;
    @Input() isRestrictedItem?:
        | boolean
        | ((params: IEntityCardCellParams) => boolean);
    @Input() showRestrictedActions?: boolean;

    @ViewChild(DxyNavBreadcrumbComponent)
    navBreadcrumb: DxyNavBreadcrumbComponent;
    @Input() entity?: TEntity;

    protected technoTooltip: string;
    protected technoImgUrl: string;
    protected entityCardSettings: EntityCardSettings;
    protected displayName$: Observable<string>;

    protected get hasData() {
        return !!this.hierarchicalData?.DataReferenceId;
    }
    protected get showBreadcrumb() {
        const hideBreadcrumbValue = CoreUtil.fromFnOrValue(
            this.hideBreadcrumb,
            this.params,
        );
        return !hideBreadcrumbValue && !this.isMiniCard;
    }
    protected get actionsData(): IHasHddData {
        return this.entity || this.data || { HddData: this.hierarchicalData };
    }
    protected get aliasNameValue() {
        return CoreUtil.fromFnOrValue(this.aliasName, this.params);
    }
    protected get nameSuffixValue() {
        return CoreUtil.fromFnOrValue(this.nameSuffix, this.params);
    }

    protected get isRestrictedItemValue() {
        return CoreUtil.fromFnOrValue(this.isRestrictedItem, this.params);
    }

    protected get isDragDisabled() {
        return (
            !this.dragDrop ||
            this.dragDrop.disabled ||
            !this.hierarchicalData ||
            this.dragDrop.isDisabled?.(this.hierarchicalData.Data)
        );
    }

    protected get isObsolete() {
        const statusStr = this.attributes?.[
            PropertyName.EntityStatus
        ] as string;
        return (
            EntityLifecycleStatus[statusStr] === EntityLifecycleStatus.Obsolete
        );
    }
    protected get obsoleteTooltipKey() {
        return this.isObsolete ? 'DgServerTypes.EntityStatus.Obsolete' : '';
    }
    protected get typeIconVisible() {
        return this.isMiniCard && !!this.hierarchicalData.entityType;
    }

    protected get hideActions() {
        return (
            (this.isMiniCard && !this.showActions) ||
            (this.isRestrictedItemValue && !this.showRestrictedActions)
        );
    }

    protected get cardCssClasses() {
        return `${this.customClass} ${this.dragDrop?.itemClass}`;
    }

    constructor(
        private entityUiService: EntityUiService,
        private functionalLogService: FunctionalLogService,
        private displayNameService: EntityDisplayNameService,
    ) {
        super();
    }

    ngOnChanges(changes: SimpleChanges): void {
        super.ngOnChanges(changes);
        this.onChange(changes, 'hierarchicalData', () => this.init(), true);
    }

    protected onLogFunctional(event: IFunctionalEvent) {
        this.functionalLogService.parseAndLog(event.text, event.origin);
    }

    public override updateLayout() {
        this.navBreadcrumb?.refresh();
    }

    public refreshLayout() {
        this.updateLayout();
    }

    protected override updateBindings() {
        if (!this.params) {
            return;
        }
        this.hierarchicalData = this.getHierachicalData();
        if (!this.hierarchicalData) {
            return;
        }
        if (
            this.data instanceof EntityItem ||
            this.data instanceof FilteredEntityItem
        ) {
            this.attributes = this.data.Attributes;
        } else if (this.params?.inputs?.getAttributes) {
            this.attributes = this.params.inputs?.getAttributes(this.data);
        }
        this.isMiniCard = this.params.isMini;
        this.actions = this.params.actions;
        CoreUtil.assign(this, this.params.inputs);
        this.init();
    }

    private getHierachicalData() {
        const value = this.value;
        const hddData = this.data?.HddData;
        if (value instanceof HierarchicalData) {
            return value;
        }
        if (hddData instanceof HierarchicalData) {
            return hddData;
        }
        if (hddData) {
            CoreUtil.warn(
                'HierarchicalData has to be build by the entity card, please provide a HierarchicalData as cell data',
                { hddData },
            );
            return new HierarchicalData(
                this.data?.HddData?.Data,
                this.data?.HddData?.Parents,
            );
        }
        CoreUtil.warn('no HierarchicalData found for this entity card', {
            value,
            hddData,
        });
    }

    private init() {
        const hd = this.hierarchicalData;
        this.log('init', hd);
        if (!hd) {
            return;
        }

        this.displayName$ = this.displayNameService.getDisplayName$(hd);

        const displaySettings = (this.entityCardSettings =
            this.entityUiService.getUserEntityCardDisplaySettings(
                hd.TechnologyCode,
            ));
        this.technoImgUrl = displaySettings.technoImgUrl;
        this.technoTooltip = displaySettings.technoTooltip;
        this.cd.detectChanges();
    }
}
