import { BehaviorSubject, Subject } from 'rxjs';
import { BaseService } from '@datagalaxy/core-ui';
import { AttributeFilterService } from '../../attribute/attribute-filter/attribute-filter.service';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EntityType } from '@datagalaxy/dg-object-model';
import { CollectionsHelper, CoreUtil } from '@datagalaxy/core-util';
import { DataUtil } from '../../util/DataUtil';
import { DxyModalService } from '../../dialogs/DxyModalService';
import { DxyPublicEditModalComponent } from '../../public-edit-modal/dxy-public-edit-modal/dxy-public-edit-modal.component';
import { CurrentSearch } from '../../../search/models/CurrentSearch';
import { IPublicEditModalResolve } from '../../public-edit-modal/public-edit-modal.types';
import {
    FilteredViewApiService,
    FilteredViewsUtil,
} from '@datagalaxy/webclient/filter/data-access';
import { getContextId, getLocalId } from '@datagalaxy/webclient/utils';
import { IWorkspaceIdentifier } from '@datagalaxy/webclient/workspace/domain';
import {
    Filter,
    FilteredViewDto,
    FilterModuleName,
    FilterOperator,
} from '@datagalaxy/webclient/filter/domain';
import { DgZone } from '@datagalaxy/webclient/domain';
import { DgModule, DgModuleName } from '@datagalaxy/shared/dg-module/domain';
import { ServerConstants } from '@datagalaxy/shared/server/domain';
import { Router } from '@angular/router';
import { WorkspaceIdentifier } from '@datagalaxy/webclient/workspace/utils';
import { ModuleService } from '../../../module/module.service';

@Injectable({ providedIn: 'root' })
export class FilteredViewService extends BaseService {
    //#region static

    public static hasCompletedFilters(filteredView: FilteredViewDto): boolean {
        return filteredView?.ListFilter?.some((filter) =>
            AttributeFilterService.isFilterComplete(filter),
        );
    }

    /**
     * A filteredView is considered changed if its filters has changed or its space has changed
     */
    public static hasChanged(filteredView: FilteredViewDto): boolean {
        return (
            filteredView &&
            (FilteredViewService.hasFiltersChanged(filteredView) ||
                FilteredViewService.hasSpaceChanged(filteredView))
        );
    }

    private static hasFiltersChanged(filteredView: FilteredViewDto): boolean {
        const currentFilters = filteredView.ListFilter;
        const savedFilters = filteredView.savedListFilter;

        if (currentFilters.length != savedFilters.length) {
            return true;
        }

        const orderedCurrent = CollectionsHelper.orderBy(
            currentFilters,
            (f) => f.AttributePath,
        );
        const orderedSaved = CollectionsHelper.orderBy(
            savedFilters,
            (f) => f.AttributePath,
        );
        return orderedCurrent.some(
            (cf, i) =>
                !cf.equals(orderedSaved[i], false) &&
                AttributeFilterService.isFilterComplete(cf, true),
        );
    }

    private static hasSpaceChanged(filteredView: FilteredViewDto): boolean {
        return (
            (filteredView.savedSpaceUid ?? '') != (filteredView.SpaceUid ?? '')
        );
    }

    //#endregion - static

    /** emitted whenever a change occurred on a current filteredView (filter changed/added/removed, view cleared/replaced) */
    public get currentViewChanged$() {
        return this.currentViewChanged.asObservable();
    }
    public notifyCurrentViewChange(event: ICurrentFilteredViewChangeEvent) {
        this.currentViewChanged.next(event);
    }

    private currentViewChanged =
        new BehaviorSubject<ICurrentFilteredViewChangeEvent>(null);

    public get viewCRUD$() {
        return this.viewCRUD.asObservable();
    }
    private viewCRUD = new Subject<{
        dgZone: DgZone;
        action: FilteredViewCRUD;
        filteredView: FilteredViewDto;
    }>();
    private notifyCRUD(
        action: FilteredViewCRUD,
        filteredView: FilteredViewDto,
    ) {
        if (!filteredView) {
            return;
        }
        setTimeout(() =>
            this.viewCRUD.next({
                dgZone: filteredView.DgZone,
                action,
                filteredView,
            }),
        );
    }

    /** Holds, for each view type:
     * - the current filteredView,
     * - a cache of the last available views requested for a space+dgModule */
    private readonly store = new Map<DgZone, ICurrentViewData>();

    constructor(
        private dxyModalService: DxyModalService,
        private translate: TranslateService,
        private filteredViewApiService: FilteredViewApiService,
        private router: Router,
        private moduleService: ModuleService,
    ) {
        super();
        [DgZone.Module, DgZone.Search].forEach((type) => this.getData(type));
    }

    public async openSaveModal(filteredView: FilteredViewDto, edit: boolean) {
        await this.dxyModalService.open<
            DxyPublicEditModalComponent,
            IPublicEditModalResolve,
            void
        >({
            componentType: DxyPublicEditModalComponent,
            data: {
                dto: filteredView,
                edit,
                titleKey: edit
                    ? 'UI.Global.filter.updateModalTitle'
                    : 'UI.Global.filter.createModalTitle',
                featureCode: edit ? 'SAVED_FILTER,U' : 'SAVED_FILTER,C',
                featureCodePublish: 'PUBLISH_SAVED_FILTER,U',
                canChangePrivate: false,
            },
        });
    }

    public async saveFilteredViewAs(
        dgModule: DgModule,
        filteredView: FilteredViewDto,
    ) {
        this.log('saveFilteredViewAs', filteredView);
        const dgZone = filteredView.DgZone;
        const tfv = this.getNewFilteredView(dgZone);
        filteredView.copyTo(tfv, { includeListFilter: true });
        await this.openSaveModal(tfv, false);
        const spaceId = filteredView.SpaceUid;
        const newFilteredView = await this.saveNewFilterView(
            spaceId,
            tfv.DisplayName,
            tfv.Description,
            tfv.IsPrivate,
            tfv.filters,
            dgZone,
            dgModule,
        );
        newFilteredView.ListFilter = filteredView.ListFilter;
        this.setCurrentFilteredView(newFilteredView.init());
    }

    public async getCurrentViewOrFavoriteOrNewOne(
        dgZone: DgZone,
        dgModule?: DgModule,
        spaceIdr?: IWorkspaceIdentifier,
    ) {
        const spaceId = spaceIdr?.spaceId;
        const moduleName: FilterModuleName =
            dgZone === DgZone.Module
                ? FilterModuleName[DgModule[dgModule]]
                : FilterModuleName.MainSearch;

        let fv = this.getCurrentView(dgZone);

        if (
            fv &&
            fv.ModuleName === moduleName &&
            (fv.FilteredViewId !== -1 || fv.filters?.length)
        ) {
            return fv;
        }

        if (dgZone === DgZone.Module) {
            fv = await this.filteredViewApiService.getUserFavoriteFilteredView(
                DgModule[dgModule],
                spaceIdr?.spaceId,
            );
        }
        if (!fv) {
            fv = this.getNewFilteredView(dgZone, {
                dgModule,
                spaceId,
                defaultVersion: spaceIdr?.versionId,
            });
        }
        this.setCurrentFilteredView(fv);
        return fv;
    }

    public async goToFilteredView(
        fv: FilteredViewDto,
        workspace: WorkspaceIdentifier,
    ) {
        switch (fv.DgZone) {
            case DgZone.Module: {
                this.setCurrentFilteredView(fv);
                return this.moduleService.goToModule(
                    FilterModuleName[fv.ModuleName] as DgModuleName,
                    workspace,
                );
            }
        }
    }

    public async goToEntityTypeFilteredView(
        entityType: EntityType,
        spaceIdr: IWorkspaceIdentifier,
    ) {
        const filteredView = this.getNewFilteredView(DgZone.Module, {
            autoFilterEntityType: entityType,
        });

        return this.goToFilteredView(filteredView, spaceIdr);
    }

    public getNewFilteredView(
        dgZone: DgZone,
        opt?: {
            autoFilterEntityType?: EntityType;
            dgModule?: DgModule;
            spaceId?: string;
            defaultVersion?: string;
        },
    ) {
        this.log('getNewFilteredView', DgZone[dgZone], opt);
        const fv = new FilteredViewDto(
            dgZone,
            this.translate.instant('UI.Global.filter.newFilterName'),
        );
        const { autoFilterEntityType, spaceId, defaultVersion } = opt ?? {};
        let dgModule = opt?.dgModule;
        if (autoFilterEntityType) {
            const filter = new Filter(
                ServerConstants.Search.EntityTypeFilterKey,
                FilterOperator.Equals,
                [EntityType[autoFilterEntityType]],
            );
            fv.ListFilter = [filter];
            dgModule = DataUtil.getModuleFromEntityType(autoFilterEntityType);
            fv.autoEntityTypeFilter = autoFilterEntityType;
        }
        fv.SpaceUid = spaceId;
        fv.DefaultVersionId = defaultVersion;
        fv.ModuleName = FilteredViewsUtil.moduleNameFromZoneAndModule(
            dgZone,
            dgModule,
        );
        return fv.init();
    }

    /** Return a new FilteredViewDto filled with currentSearch properties */
    public getFilteredViewFromCurrentSearch(currentSearch: CurrentSearch) {
        const fv = this.getNewFilteredView(DgZone.Search);
        return this.updateFilteredViewFromCurrentSearch(fv, currentSearch);
    }

    /** Return a new updated FilteredViewDto filled with currentSearch properties */
    public updateFilteredViewFromCurrentSearch(
        fv: FilteredViewDto,
        currentSearch: CurrentSearch,
    ) {
        const spaceIdr = currentSearch.spaceIdr;
        const filteredView = CoreUtil.cloneDeep(fv);

        filteredView.SpaceUid = getLocalId(spaceIdr.spaceId);
        filteredView.DefaultVersionId = spaceIdr.versionId;
        filteredView.ListFilter = currentSearch.computeFilters(true);
        return filteredView;
    }

    public async loadView(
        filteredViewGuid: string,
        dgZone: DgZone,
    ): Promise<FilteredViewDto> {
        const fv =
            await this.filteredViewApiService.getFilteredView(filteredViewGuid);
        if (fv && (fv.DgZone || 0) != (dgZone || 0)) {
            this.warn('DgZone type mismatch');
            return;
        }
        return fv;
    }

    //#region current view
    public clearCurrentFilteredView(
        dgZone: DgZone,
        context?: ICurrentFilteredViewChangeContext,
    ) {
        this.log('clearCurrentFilteredView', DgZone[dgZone], context);
        const data = this.getData(dgZone);
        if (data.currentView == undefined) {
            return;
        }
        data.currentView = undefined;
        this.notifyCurrentViewChange({
            filteredView: undefined,
            dgZone,
            context,
        });
    }
    public resetCurrentFilteredView(
        dgZone: DgZone,
        opt?: { dgModule?: DgModule },
        context?: ICurrentFilteredViewChangeContext,
    ) {
        this.log('resetCurrentFilteredView', DgZone[dgZone], opt, context);
        this.setCurrentFilteredView(
            this.getNewFilteredView(dgZone, opt),
            context,
        );
    }
    /** updates the current filteredView and emit the currentViewChanged event */
    public setCurrentFilteredView(
        filteredView: FilteredViewDto,
        context?: ICurrentFilteredViewChangeContext,
    ) {
        const dgZone = filteredView?.DgZone;
        this.log(
            'setCurrentFilteredView',
            DgZone[dgZone],
            filteredView,
            context,
        );
        if (!dgZone) {
            return;
        }
        if (dgZone == DgZone.Module && !filteredView.ModuleName) {
            this.warn('FilteredView has no ModuleName', filteredView);
        }
        const data = this.getData(dgZone);
        if (FilteredViewDto.equals(data.currentView, filteredView)) {
            this.log('same filteredView', filteredView);
            return;
        }
        data.currentView = filteredView;
        this.notifyCurrentViewChange({ filteredView, dgZone, context });
    }
    public getCurrentView(dgZone: DgZone) {
        return this.getData(dgZone)?.currentView;
    }
    public getCurrentViewId(dgZone: DgZone) {
        return this.getCurrentView(dgZone)?.FilteredViewId;
    }
    //#region current view filters
    public getCurrentFilters(dgZone: DgZone) {
        if (!dgZone) {
            return [];
        }
        return this.getFilters(dgZone)?.slice() ?? [];
    }
    public getCurrentFiltersCount(filters: Filter[]) {
        const activefilters = filters?.filter((filter) => {
            const isSearchTermFilter =
                filter.AttributeKey ==
                ServerConstants.Search.SearchTermFilterKey;
            const isNotEmptySearchFilter =
                filter.SearchValues.length && filter.SearchValues[0] != '';

            return !isSearchTermFilter || isNotEmptySearchFilter;
        });

        return activefilters?.length ?? 0;
    }
    public hasCurrentFilters(dgZone: DgZone) {
        return this.getFilters(dgZone)?.length > 0;
    }
    public hasCurrentGlobalTextFilter(dgZone: DgZone) {
        return (
            this.hasCurrentFilters(dgZone) &&
            this.getFilters(dgZone).some(
                (item) =>
                    item &&
                    (item.AttributeKey == 'DisplayName' ||
                        item.AttributeKey == 'TechnicalName'),
            )
        );
    }
    public hasCurrentTypeFilter(dgZone: DgZone) {
        return (
            this.hasCurrentFilters(dgZone) &&
            this.getFilters(dgZone).some((item) => item?.AttributeKey == 'Type')
        );
    }
    public hasCurrentOtherFilter(dgZone: DgZone) {
        const typeAndName = ['Type', 'DisplayName', 'TechnicalName'];
        return (
            this.hasCurrentFilters(dgZone) &&
            this.getFilters(dgZone).some(
                (item) => item && !typeAndName.includes(item.AttributeKey),
            )
        );
    }
    private getFilters(dgZone: DgZone) {
        return this.getCurrentView(dgZone)?.ListFilter;
    }
    //#endregion
    //#endregion - current view

    //#region CRUD
    /* notes:
       - versionId is not used: one FilteredView matches all versions of a Space (Project or Orga)
       - on write operations, the cached list of filtered views (for the matching DgZone, space and module) is updated
    */

    public async getSpaceFilteredViews(
        spaceIdr: IWorkspaceIdentifier,
    ): Promise<FilteredViewDto[]> {
        const moduleNames =
            CollectionsHelper.getEnumValues<FilterModuleName>(FilterModuleName);
        const filteredViews =
            await this.filteredViewApiService.getFilteredViews(
                moduleNames,
                spaceIdr,
            );
        return filteredViews ?? [];
    }

    public async getFilteredViews(
        dgZone: DgZone,
        spaceIdr?: IWorkspaceIdentifier,
        dgModule?: DgModule,
        noCache = false,
    ): Promise<FilteredViewDto[]> {
        if (!dgZone) {
            return [];
        }

        const spaceId = spaceIdr?.spaceId;
        const current = this.getData(dgZone);
        if (
            !noCache &&
            current &&
            dgZone != DgZone.Search &&
            (current.listSpaceId ?? '') == (spaceId ?? '') &&
            (current.dgModule || 0) == (dgModule || 0)
        ) {
            return current.list ?? [];
        }

        const updateCache = (res: FilteredViewDto[]) => {
            current.listSpaceId = spaceId;
            current.dgModule = dgModule;
            return (current.list = res ?? []);
        };
        let res: FilteredViewDto[];
        switch (dgZone) {
            case DgZone.Module: {
                const moduleName =
                    FilteredViewsUtil.moduleNameFromZoneAndModule(
                        dgZone,
                        dgModule,
                    );
                res = await this.filteredViewApiService.getFilteredViews(
                    [moduleName],
                    spaceIdr,
                );
                break;
            }
            case DgZone.Search:
                res =
                    await this.filteredViewApiService.getSearchFilteredViews(
                        spaceIdr,
                    );
                break;

            default:
                this.warn('not implemented', DgZone[dgZone]);
                return [];
        }

        return updateCache(res);
    }
    public async setFilteredViewFavorite(
        isFavorite: boolean,
        filteredViewGuid: string,
    ) {
        await this.filteredViewApiService.setFilteredViewFavorite(
            isFavorite,
            filteredViewGuid,
        );
    }
    public async updateFilteredView(filteredView: FilteredViewDto) {
        await this.filteredViewApiService.updateFilteredView(filteredView);
        // update cache
        const found = this.getMatchingInCurrentList(filteredView);
        if (found) {
            filteredView.copyTo(found, { includeListFilter: true });
            found.init();
        }
        this.notifyCRUD(FilteredViewCRUD.updated, filteredView);
    }
    public async saveNewFilterView(
        spaceId: string,
        name: string,
        description: string,
        Isprivate: boolean,
        filters: Filter[],
        dgZone: DgZone,
        dgModule?: DgModule,
    ) {
        const filteredView =
            await this.filteredViewApiService.createFilteredView(
                spaceId,
                name,
                description,
                filters,
                dgZone,
                dgModule,
            );
        // update cache
        const list = this.getCurrentListIfMatching(filteredView);
        if (list) {
            if (
                !list.some(
                    (fv) => fv.FilteredViewId == filteredView.FilteredViewId,
                )
            ) {
                list.push(filteredView);
            }
        } else {
            const current = this.getData(filteredView.DgZone);
            if (current && !current.list) {
                current.list = [filteredView];
                current.listSpaceId = spaceId;
                current.dgModule = dgModule;
            }
        }
        this.notifyCRUD(FilteredViewCRUD.created, filteredView);
        return filteredView;
    }

    public async updateFilteredViewProperties(
        spaceIdr: IWorkspaceIdentifier,
        filteredView: FilteredViewDto,
    ) {
        const spaceId = spaceIdr?.spaceId;
        await this.filteredViewApiService.updateFilteredViewProperties(
            spaceId,
            filteredView,
        );
        // update cache
        const found = this.getMatchingInCurrentList(filteredView);
        if (found) {
            filteredView.copyTo(found);
        }
        this.notifyCRUD(FilteredViewCRUD.propertiesUpdated, filteredView);
    }
    public async deleteFilteredView(
        filteredView: FilteredViewDto,
    ): Promise<void> {
        if (!filteredView) {
            return;
        }
        await this.filteredViewApiService.deleteFilteredView(
            filteredView.FilteredViewUid,
        );
        // update cache
        const list = this.getCurrentListIfMatching(filteredView);
        CollectionsHelper.remove(
            list,
            (fv) => fv.FilteredViewId == filteredView.FilteredViewId,
        );
        this.notifyCRUD(FilteredViewCRUD.deleted, filteredView);
    }
    //#endregion -- CRUD

    private getData(dgZone: DgZone) {
        if (!dgZone) {
            return;
        }
        let data = this.store.get(dgZone);
        if (!data) {
            this.store.set(dgZone, (data = {}));
        }
        return data;
    }
    private getMatchingInCurrentList(filteredView: FilteredViewDto) {
        const list = this.getCurrentListIfMatching(filteredView);
        return list?.find(
            (fv) =>
                fv.FilteredViewId == filteredView.FilteredViewId &&
                fv !== filteredView,
        );
    }
    private getCurrentListIfMatching(filteredView: FilteredViewDto) {
        const current = this.getData(filteredView?.DgZone);
        return current &&
            getContextId(current.listSpaceId) == filteredView.SpaceUid &&
            (current.dgModule || 0) == (DgModule[filteredView.ModuleName] || 0)
            ? current.list
            : undefined;
    }
}

interface ICurrentViewData {
    currentView?: FilteredViewDto;
    list?: FilteredViewDto[];
    listSpaceId?: string;
    dgModule?: DgModule;
}

export enum FilteredViewCRUD {
    unknown = 0,
    propertiesUpdated,
    updated,
    deleted,
    created,
}

export interface ICurrentFilteredViewChangeEvent {
    dgZone: DgZone;
    context?: ICurrentFilteredViewChangeContext;
    filteredView: FilteredViewDto;
}

export interface ICurrentFilteredViewChangeContext {
    isReloading?: boolean;
}
