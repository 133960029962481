import { Observable } from 'rxjs';
import { IRectNode, IRectSidePoint } from '@datagalaxy/core-2d-util';
import {
    EndpointHandle,
    IBaseConnectorEditOptions,
    IOrthoEditorOptions,
} from '../tools';
import {
    Connector,
    IBaseConnectorBaseParams,
    IBaseConnectorDrawingOptions,
    IConnector,
    IConnectorEndBaseParams,
    IConnectorSpec,
    IEdgeOrthoConnectorOptions,
    LineStyle,
    TConnectorGeometry,
} from '../connector';
import { IGraphItem } from '../graph-surface.types';
import { IDiagNode, ShapeId } from '../../diagramming';
import { GraphicalColor } from '@datagalaxy/shared/graphical/domain';

export type TDiagEdge<N, E = unknown> = IDiagEdge<IDiagNode<N>, E>;

/** compatible with IDiagramEdgeSpec */
export interface EdgeSpec<N = unknown, D = unknown>
    extends Omit<IConnectorSpec<N, D>, 'source' | 'target' | 'geometry'> {
    type?: string;
    /** source node or id of the source node */
    source: string;
    /** target node or id of the target node */
    target: string;
    geometry?: TConnectorGeometry;
}

/** compatible with IDiagramEdge */
export interface IDiagEdge<
    TNode extends IDiagNode = IDiagNode,
    TEdgeData = unknown,
> {
    id: string;
    source: TNode;
    target: TNode;
    geometry: TConnectorGeometry;
    data?: TEdgeData;
    connector?: Connector;
}

export interface IEdgeManagerOptions<NodeData = unknown, EdgeData = unknown>
    extends IBaseConnectorDrawingOptions,
        IDisconnectOptions {
    editor?: false | IOrthoEditorOptions;
    /** true *not* to toggle the editor when a connector is clicked */
    noEditOnClick?: boolean;
    /** type and parameters of edge connectors */
    connectors?: IEdgeOrthoConnectorOptions & {
        /** defaults to Black */
        color?: GraphicalColor;
    };
    /** Function to persist the edited geometry of an edge - called when the edition mode ends */
    saveGeometry?: (
        edge: TDiagEdge<NodeData, EdgeData>,
        geometry: TConnectorGeometry,
    ) => Promise<void>;
    /** Function to clear the persisted geometry of an edge - called when the edition mode ends */
    clearSavedGeometry?: (
        ...edges: TDiagEdge<NodeData, EdgeData>[]
    ) => Promise<void>;
}

export interface IDisconnectOptions {
    /** Set to true to disallow disconnecting any edge by dragging a connected endpoint. */
    noDisconnect?: boolean;
    /** Distance in pixels, at the beggining of a connected endpoint dragging gesture, to consider the edge as being disconnected. Defaults to 0. */
    disconnectDistance?: number;
}

export interface IConnectorEndpointSpec {
    /** identifier of the registered marker to draw at this end of the connector */
    markerId?: string;
    /** identifier of the registered shape to draw at this end of the connector */
    shapeId?: string;
}

export interface IEndpointEditOptions {
    /** css class to be applied to each endpoint */
    class?: string;
}

//#region internal

/** IRectSidePoint with an optional handle object */
export interface IEndpoint<T> extends IRectSidePoint<T> {
    node: IRectNode<T> & Partial<IGraphItem<T>>;
    handle?: EndpointHandle<T>;
}
export interface IConnectedEndpoints<T> {
    readonly src: IEndpoint<T>;
    readonly tgt: IEndpoint<T>;
}

//#endregion

/** Side of an edge: the *source* or the *target*. Defaults to *none* */
export enum EdgeSide {
    none = 0,
    source,
    target,
}

export interface IConnectorEditor<N, D> extends IConnectorBaseEdit<N> {
    init(options: IBaseConnectorEditOptions): void;
    /** the connector being edited */
    editing: Connector<N, D>;
    /** emitted on edit mode enter/exit */
    readonly editingChanged$: Observable<IConnectorEditorEvent<N, D>>;
    /** emitted when connector needs to be redrawn */
    readonly connectorChanged$: Observable<void>;
    /** to rebuild the handles after modifying the edited connector */
    onConnectorUpdated(): void;
}
export interface IConnectorBaseEdit<N> {
    /** the connector being edited */
    editing: IConnectedEndpoints<N>;
    startEdit(c: IConnectedEndpoints<N>): void;
    toggleEdit(c: IConnectedEndpoints<N>): void;
    endEdit(): void;
}
export interface IConnectorEditorEvent<N = unknown, D = unknown> {
    connector: IConnector<N, D>;
    editing: boolean;
}

export interface IConnectingSpec extends IBaseConnectorBaseParams {
    /** defaults to *Black* */
    color?: GraphicalColor;
    class?: string;
    lineStyle?: LineStyle;
    shapeId?: ShapeId;
    /** parameters for the source end */
    srcEp?: IConnectorEndBaseParams;
    /** parameters for the target end */
    tgtEp?: IConnectorEndBaseParams;
}
