import { Component, Input } from '@angular/core';
import {
    BaseCollectionCellComponent,
    baseMiniUserCollectionCellInputs,
    baseUserCollectionCellInputs,
    DxyUserBadgeCellComponent,
    ICollectionCellInputs,
    ICollectionCellOption,
    ICollectionCellParams,
} from '@datagalaxy/core-ui/cell-components';
import { UserService } from '../../../../services/user.service';
import { EntityUiService } from '../../../entity/services/entity-ui.service';
import { getLocalId } from '@datagalaxy/utils';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';
import { DxyCollectionCellComponent } from '@datagalaxy/core-ui/cell-components';
import { NgIf } from '@angular/common';

/**
 * ## Role
 * Display a collection of users with ellipse if not enough space
 */
@Component({
    selector: 'app-user-collection-cell',
    templateUrl: 'user-collection-cell.component.html',
    standalone: true,
    imports: [NgIf, DxyCollectionCellComponent],
})
export class UserCollectionCellComponent extends BaseCollectionCellComponent<
    unknown,
    string[]
> {
    @Input() userIds?: string[];
    @Input() hideUserName = false;
    @Input() disableMoreCountTooltip = false;
    @Input() maxItems?: number;
    @Input() alignRight?: boolean;
    @Input() enablePopover?: boolean;
    @Input() popoverTitle?: string;
    @Input() entityData?: EntityItem;

    protected collectionCellParams?: ICollectionCellParams;
    protected openPopoverFn = (el: HTMLElement) => this.onUserPopover(el);

    constructor(
        private userService: UserService,
        private entityUiService: EntityUiService,
    ) {
        super();
    }

    protected override updateBindings() {
        super.updateBindings();
        this.userIds = this.value;
    }

    public override updateLayout() {
        super.updateLayout();
        const baseCellData = this.mini
            ? baseMiniUserCollectionCellInputs
            : baseUserCollectionCellInputs;
        const collectionInputs: ICollectionCellInputs = {
            ...baseCellData,
            containerWidth: this.containerWidth,
            options: this.userIds?.map((id) => this.buildOption(id)),
            hideLabel: this.hideUserName,
            fitContentWidth: this.fitContentWidth,
            maxItems: this.maxItems,
            alignRight: this.alignRight,
            disableTooltip: this.enablePopover,
        };
        this.collectionCellParams = {
            inputs: collectionInputs,
            isMini: this.mini,
        };
    }

    private onUserPopover(el: HTMLElement) {
        const users = this.userIds
            .map((userId) => this.userService.getPublicUserData(userId))
            .filter((user) => !!user);
        this.entityUiService.openEntityUserListPopover(
            el,
            this.entityData,
            users,
            this.popoverTitle,
        );
    }

    private buildOption(userId: string): ICollectionCellOption {
        const userData = this.userService.getUserBadgeDataFromId(userId);
        const userLocalId = getLocalId(userId);
        const username = this.userService.getUserName(userLocalId);
        return {
            labelText: username,
            renderData: {
                renderer: DxyUserBadgeCellComponent,
                param: {
                    value: userData,
                },
            },
        };
    }
}
