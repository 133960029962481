export class MapUtils {
    // Move to mapUtils
    static toRecord<T extends string | number | symbol, U>(map: Map<T, U>) {
        return Array.from(map.entries()).reduce(
            (acc, [key, value]) => {
                acc[key] = value;
                return acc;
            },
            {} as Record<T, U>,
        );
    }

    // Move to MathUtils
    static sum<T>(data: T[], getValue: (o: T) => number | undefined): number {
        return (
            (data?.length &&
                data.reduce((p, c) => p + (getValue(c) || 0), 0)) ||
            0
        );
    }
}
