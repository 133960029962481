import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { DateFormatPipe, withLoading } from '@datagalaxy/core-ui';
import { Toaster } from '@datagalaxy/ui/toaster';
import { BaseComponent } from '@datagalaxy/utils';
import {
    MultilingualApiService,
    MultilingualLexiconStatistics,
} from '@datagalaxy/webclient/multilingual/data-access';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, catchError, filter, of, switchMap } from 'rxjs';
import { lexiconFileName, teamplateFileName } from './lexicon.constants';
import { saveAs } from 'file-saver';
import { SpinnerComponent } from '@datagalaxy/ui/spinner';
import { FileInputComponent } from '@datagalaxy/ui/forms';
import { AsyncPipe, NgIf } from '@angular/common';

const csvSeparator = ',';

@Component({
    selector: 'dxy-lexicon-upload-form',
    templateUrl: './lexicon-upload-form.component.html',
    styleUrls: ['./lexicon-upload-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgIf,
        TranslateModule,
        FileInputComponent,
        SpinnerComponent,
        AsyncPipe,
        DateFormatPipe,
    ],
})
export class LexiconUploadFormComponent extends BaseComponent {
    @Input() languageCodes: string[] = [];
    @Output() lexiconUploaded = new EventEmitter<void>();
    protected succeeded?: boolean;
    protected loadStatisticsSubject = new BehaviorSubject(null);
    protected statistics$ = this.loadStatisticsSubject.pipe(
        switchMap(() =>
            this.multilingualApiService.getLexiconStatistics().pipe(
                catchError((error) => {
                    console.warn(error);
                    this.toaster.errorToast({
                        title: this.translate.instant(
                            'Multilingual.Administration.LexiconForm.statisticsLoadingErrorTitle',
                        ),
                        message: this.translate.instant(
                            'Multilingual.Administration.LexiconForm.statisticsLoadingErrorMessage',
                        ),
                    });
                    return of({
                        entryCount: 0,
                        languages: [],
                    } as MultilingualLexiconStatistics);
                }),
            ),
        ),
    );
    protected teamplateFileName = teamplateFileName;
    protected lexiconFileName = lexiconFileName;

    constructor(
        private multilingualApiService: MultilingualApiService,
        private toaster: Toaster,
        private translate: TranslateService,
    ) {
        super();
        super.subscribe(
            this.statistics$.pipe(filter((s) => s.entryCount > 0)),
            () => {
                this.lexiconUploaded.emit();
            },
        );
    }

    @withLoading()
    protected async uploadFile(file: File) {
        if (!file) {
            return;
        }
        this.succeeded = undefined;
        try {
            await this.multilingualApiService.uploadLexicon(file);
            this.toaster.successToast({
                title: this.translate.instant('UI.Global.success'),
                message: this.translate.instant(
                    'Multilingual.Administration.LexiconStep.uploadSuccessMessage',
                ),
            });
        } catch (error) {
            this.handleError(error);
        }
        this.succeeded = true;
        await this.loadStatisticsSubject.next(null);
    }

    protected downloadCsvTemplate() {
        const csvFile = this.buildCsvTemplate();
        const blob = new Blob([csvFile], { type: 'text/csv' });
        saveAs(blob, this.teamplateFileName);
    }

    protected async downloadLexicon() {
        const buffer = await this.multilingualApiService.downloadLexicon();
        if (!buffer) {
            return;
        }
        return saveAs(
            new Blob([buffer], {
                type: 'text/csv',
            }),
            lexiconFileName,
        );
    }

    private buildCsvTemplate() {
        const csvTemplate = `${this.languageCodes.join(
            csvSeparator,
        )}\n${this.languageCodes
            .map((code) => `Your lexicon entry in ${code} version`)
            .join(csvSeparator)}\n`;

        return csvTemplate;
    }

    private handleError(error: unknown) {
        this.succeeded = false;
        throw error;
    }
}
