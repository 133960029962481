<div *ngIf="showDropDown">
    <div
        class="menu-selected"
        [matMenuTriggerFor]="menu"
        (menuOpened)="menuOpenClose(true)"
        (menuClosed)="menuOpenClose(false)"
    >
        <span
            class="displayname"
            (click)="onDisplayNameClickInternal($event)"
            [attr.data-tracker-id]="selectedTextTrackerId"
            >{{ selectedVersionName }}</span
        >
        <button
            *ngIf="isVersionSelectable"
            dxyIconButton
            variant="tertiary"
            type="button"
            size="small"
            [attr.data-tracker-id]="selectedCaretTrackerId"
        >
            <i class="glyph-arrow-drop-down"></i>
        </button>
    </div>

    <mat-menu #menu="matMenu" class="dg5-mat-menu">
        <ul class="versions-dropdown" [ngClass]="menuClass">
            <!-- version officielle -->

            <li *ngIf="!hideOfficialVersion" role="header" translate>
                UI.Versioning.navBarSelector.officialHeader
            </li>

            <li
                *ngIf="!hideOfficialVersion"
                class="dg_versioning-dropdown-items"
            >
                <a
                    *ngIf="hasOfficialVersion"
                    (click)="onVersionClick(officialVersion)"
                    class="versioning-dropdown-item"
                    dxyLogFunctional="VERSION,R"
                >
                    <span
                        class="text"
                        [matTooltip]="officialVersion.VersionDescription"
                        matTooltipPosition="above"
                    >
                        {{ officialVersion.VersionName }}
                    </span>
                    <span
                        *ngIf="useMoreText"
                        class="more-text dg5-badge"
                        [ngClass]="getMoreTextClass(officialVersion)"
                    >
                        {{ getMoreText(officialVersion) }}
                    </span>
                </a>
                <button
                    *ngIf="
                        hasOfficialVersion &&
                        isFavoriteButtonVisible(officialVersion)
                    "
                    dxyIconButton
                    variant="tertiary"
                    type="button"
                    (click)="onStarClick($event, officialVersion)"
                    class="favorite-icon"
                    role="button"
                    tabindex="0"
                    [matTooltip]="defaultVersionTooltip"
                    matTooltipPosition="above"
                >
                    <i
                        [class.glyph-star-fav]="
                            officialVersion.IsUserDefaultVersion
                        "
                        [class.glyph-star]="
                            !officialVersion.IsUserDefaultVersion
                        "
                    ></i>
                </button>

                <a *ngIf="!hasOfficialVersion" class="empty-message">
                    <span translate
                        >UI.Versioning.navBarSelector.emptyOfficial</span
                    >
                </a>
            </li>

            <li *ngIf="!hideOfficialVersion" role="separator"></li>

            <!-- versions candidates -->

            <li role="header" translate>
                UI.Versioning.navBarSelector.releaseCandidateHeader
            </li>

            <li
                *ngFor="let version of releaseCandidateVersions"
                class="dg_versioning-dropdown-items"
            >
                <a
                    (click)="onVersionClick(version)"
                    class="versioning-dropdown-item"
                    dxyLogFunctional="VERSION,R"
                >
                    <span
                        class="text"
                        [matTooltip]="version.VersionDescription"
                        matTooltipPosition="above"
                    >
                        {{ version.VersionName }}
                    </span>
                    <span
                        *ngIf="useMoreText"
                        class="more-text dg5-badge"
                        [ngClass]="getMoreTextClass(version)"
                    >
                        {{ getMoreText(version) }}
                    </span>
                </a>
                <button
                    *ngIf="isFavoriteButtonVisible(version)"
                    dxyIconButton
                    variant="tertiary"
                    type="button"
                    (click)="onStarClick($event, version)"
                    class="favorite-icon"
                    role="button"
                    tabindex="0"
                    [dxyLogFunctional]="getFeatureCode(version)"
                    [matTooltip]="defaultVersionTooltip"
                    matTooltipPosition="above"
                >
                    <i
                        [class.glyph-star-fav]="version.IsUserDefaultVersion"
                        [class.glyph-star]="!version.IsUserDefaultVersion"
                    ></i>
                </button>
            </li>

            <li *ngIf="!hasReleaseCandidateVersions">
                <a class="empty-message">
                    <span translate
                        >UI.Versioning.navBarSelector.emptyReleaseCandidate</span
                    >
                </a>
            </li>

            <li role="separator"></li>

            <!-- versions actives -->

            <li role="header" translate>
                UI.Versioning.navBarSelector.activeVersions
            </li>

            <li
                *ngFor="let version of activeVersions"
                class="dg_versioning-dropdown-items"
            >
                <a
                    (click)="onVersionClick(version)"
                    class="versioning-dropdown-item"
                    dxyLogFunctional="VERSION,R"
                >
                    <span
                        class="text"
                        [matTooltip]="version.VersionDescription"
                        matTooltipPosition="above"
                    >
                        {{ version.VersionName }}
                    </span>
                    <span
                        *ngIf="useMoreText"
                        class="more-text dg5-badge"
                        [ngClass]="getMoreTextClass(version)"
                    >
                        {{ getMoreText(version) }}
                    </span>
                </a>
                <button
                    *ngIf="isFavoriteButtonVisible(version)"
                    dxyIconButton
                    variant="tertiary"
                    type="button"
                    (click)="onStarClick($event, version)"
                    class="favorite-icon"
                    role="button"
                    tabindex="0"
                    [dxyLogFunctional]="getFeatureCode(version)"
                    [matTooltip]="defaultVersionTooltip"
                    matTooltipPosition="above"
                >
                    <i
                        [class.glyph-star-fav]="version.IsUserDefaultVersion"
                        [class.glyph-star]="!version.IsUserDefaultVersion"
                    ></i>
                </button>
            </li>

            <li *ngIf="!hasActiveVersions">
                <a class="empty-message">
                    <span translate
                        >UI.Versioning.navBarSelector.emptyActive</span
                    >
                </a>
            </li>

            <!-- versions archivées -->
            <ng-container *ngIf="includeArchived">
                <li role="header" translate>
                    UI.Versioning.navBarSelector.archivedVersions
                </li>

                <li
                    *ngFor="let version of archivedVersions"
                    class="dg_versioning-dropdown-items"
                >
                    <a
                        (click)="onVersionClick(version)"
                        class="versioning-dropdown-item"
                        dxyLogFunctional="VERSION,R"
                    >
                        <span
                            class="text"
                            [matTooltip]="version.VersionDescription"
                            matTooltipPosition="above"
                        >
                            {{ version.VersionName }}
                        </span>
                        <span
                            *ngIf="useMoreText"
                            class="more-text dg5-badge"
                            [ngClass]="getMoreTextClass(version)"
                        >
                            {{ getMoreText(version) }}
                        </span>
                    </a>
                </li>

                <li *ngIf="!hasArchivedVersions">
                    <a class="empty-message">
                        <span translate
                            >UI.Versioning.navBarSelector.emptyArchivedVersions</span
                        >
                    </a>
                </li>
            </ng-container>
        </ul>
        <ul>
            <!-- versioningNavigator link -->

            <li *ngIf="showNavigatorLink" role="separator"></li>
            <li *ngIf="showNavigatorLink" (click)="onNavigatorLinkClick()">
                <span translate
                    >UI.Versioning.navBarSelector.versioningNavigator</span
                >
            </li>
        </ul>
    </mat-menu>
</div>
