<dxy-spinner *ngIf="loading" class="absolute-centered"></dxy-spinner>
<div class="entity-informations">
    <div class="main-part">
        <button
            dxyButton
            variant="tertiary"
            size="medium"
            (click)="goToDetail()"
            dxyLogFunctional="POPOVER_DETAIL,R"
        >
            <i class="glyph" [ngClass]="typeClyphClass"></i>
            <div class="entity-title" dxyEllipsisTooltip>{{ entityName }}</div>
        </button>
        <dxy-nav-breadcrumb
            [hierarchicalData]="hierarchicalData"
            [includeSelf]="true"
            [readOnly]="true"
        ></dxy-nav-breadcrumb>
        <div class="description">{{ entityDescription }}</div>
    </div>
    <div class="social-part">
        <div class="owners-list">
            <span translate> DgServerTypes.BaseData.fields.DataOwners </span>
            <div *ngIf="hasOwners" class="pictures-list">
                <div *ngFor="let owner of owners">
                    <span [matTooltip]="getFullName(owner)">
                        <dxy-profile-avatar
                            [userId]="owner.PersonUid"
                        ></dxy-profile-avatar>
                    </span>
                </div>
                <div
                    *ngIf="moreOwnersThan3 > 0"
                    class="user-more"
                    [matTooltip]="allOwnersTooltip"
                    matTooltipPosition="below"
                >
                    +{{ moreOwnersThan3 }}
                </div>
            </div>
            <div *ngIf="!hasOwners" class="pictures-list">-</div>
        </div>
        <div class="stewards-list">
            <span translate> DgServerTypes.BaseData.fields.DataStewards </span>
            <div *ngIf="hasStewards" class="pictures-list">
                <div *ngFor="let steward of stewards">
                    <span [matTooltip]="getFullName(steward)">
                        <dxy-profile-avatar
                            [userId]="steward.PersonUid"
                        ></dxy-profile-avatar>
                    </span>
                </div>
                <div
                    *ngIf="moreStewardsThan3 > 0"
                    class="user-more"
                    [matTooltip]="allStewardsTooltip"
                    matTooltipPosition="below"
                >
                    +{{ moreStewardsThan3 }}
                </div>
            </div>
            <div *ngIf="!hasStewards" class="pictures-list">-</div>
        </div>
    </div>
</div>
<div class="entity-actions">
    <div *ngIf="isGdpr" class="master-tag-element">
        <div
            class="tag-element"
            [style.background]="gdprTagColor"
            [class.white]="gdprTagColor == 'white'"
        >
            <span>{{ gdprTagText }}</span>
        </div>
    </div>
    <button
        dxyIconButton
        variant="tertiary"
        (click)="openCommentaryNewModal()"
        [matTooltip]="'UI.CommentaryContainer.lblAdd' | translate"
        matTooltipPosition="left"
    >
        <i class="glyph-comment"></i>
    </button>
    <button
        dxyIconButton
        variant="tertiary"
        (click)="openTaskNewModal()"
        [matTooltip]="'UI.TaskContainer.lblAdd' | translate"
        matTooltipPosition="left"
    >
        <i class="glyph-file-tasks-check"></i>
    </button>
    <button
        dxyIconButton
        variant="tertiary"
        (click)="openDetailPane()"
        [matTooltip]="'UI.Spotlight.tooltip.openPane' | translate"
        matTooltipPosition="left"
        dxyLogFunctional="POPOVER_PANE,R"
    >
        <i class="glyph-object-preview"></i>
    </button>
</div>
<button
    (click)="close()"
    dxyIconButton
    variant="tertiary"
    class="close-popover"
>
    <i class="glyph-close-circle"></i>
</button>
