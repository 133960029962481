import { CollectionsHelper } from '@datagalaxy/core-util';
import {
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import { EntityTypeUtil } from '@datagalaxy/dg-object-model';
import {
    CompareOperation,
    ComparisonItem,
    ProjectVersion,
} from '@datagalaxy/webclient/versioning/data-access';
import { EntityTypeUtils } from '@datagalaxy/webclient/entity/utils';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { DxyVersioningComparatorItemComponent } from '../dxy-versioning-comparator-item/dxy-versioning-comparator-item.component';
import { TranslateModule } from '@ngx-translate/core';
import { NgClass, NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'dxy-versioning-comparator-section',
    templateUrl: 'dxy-versioning-comparator-section.component.html',
    styleUrls: ['dxy-versioning-comparator-section.component.scss'],
    standalone: true,
    imports: [
        NgClass,
        TranslateModule,
        NgIf,
        NgFor,
        DxyVersioningComparatorItemComponent,
    ],
})
export class DxyVersioningComparatorSectionComponent
    extends DxyBaseComponent
    implements OnInit, OnChanges
{
    @Input() moduleName: string;
    @Input() comparisonItems: ComparisonItem[];
    @Input() currentOperation?: CompareOperation;
    @Input() selectedVersion: ProjectVersion;

    public displayedItems: ComparisonItem[];
    public get moduleGlyph() {
        const entityType = EntityTypeUtil.getEntityType(this.moduleName);
        return EntityTypeUtils.getColoredGlyphClass(entityType);
    }
    public get moduleTranslateKey() {
        return `UI.Versioning.Comparator.${this.moduleName}`;
    }
    public get summaryTranslateKey() {
        switch (this.currentOperation) {
            case CompareOperation.Added:
                return 'UI.Versioning.Comparator.sectionCreation';
            case CompareOperation.Updated:
                return 'UI.Versioning.Comparator.sectionUpdate';
            case CompareOperation.Deleted:
                return 'UI.Versioning.Comparator.sectionDelete';
            case CompareOperation.NotUpdated:
                return 'UI.Versioning.Comparator.sectionNoChange';
            default:
                return 'UI.Versioning.Comparator.resultsSummary';
        }
    }
    public get summaryTranslateParams() {
        switch (this.currentOperation) {
            case CompareOperation.Added:
                return { count: this.addedItems.length };
            case CompareOperation.Updated:
                return { count: this.updatedItems.length };
            case CompareOperation.Deleted:
                return { count: this.deletedItems.length };
            case CompareOperation.NotUpdated:
                return { count: this.unmodifiedItems.length };
            default:
                return {
                    addedItemsCount: this.addedItems.length,
                    updatedItemsCount: this.updatedItems.length,
                    deletedItemsCount: this.deletedItems.length,
                    notUpdatedItemsCount: this.unmodifiedItems.length,
                };
        }
    }
    public get hasItems() {
        return !!this.comparisonItems?.length;
    }
    public get isSectionCollapsed() {
        return this.isCollapsed;
    }
    public get isContentExpanded() {
        return this.hasItems && !this.isCollapsed;
    }
    public get isFooterAvailable() {
        return this.isDisplayMoreAvailable || this.isDisplayLessAvailable;
    }
    public get isDisplayMoreAvailable() {
        const itemsCount = this.allItems.length;
        return (
            itemsCount > 0 && this.usedLimit < itemsCount && !this.isCollapsed
        );
    }
    public get isDisplayLessAvailable() {
        const itemsCount = this.allItems.length;
        return (
            itemsCount > 0 &&
            this.usedLimit >= itemsCount &&
            this.usedLimit > this.baseLimit &&
            !this.isCollapsed
        );
    }
    public get badgeCountString() {
        return `${this.usedLimit}/${this.allItems.length}`;
    }
    public get usedOffset() {
        // Compute diff between total nb of items vs current displayed items
        const diffItemsCount = this.allItems.length - this.usedLimit;
        return Math.max(diffItemsCount, this.itemsOffset) ?? 0;
    }

    private readonly itemsOffset = 50;
    private readonly baseLimit = 10;
    private usedLimit = this.baseLimit;
    private isCollapsed = false;
    private allItems: ComparisonItem[] = [];
    private addedItems: ComparisonItem[] = [];
    private updatedItems: ComparisonItem[] = [];
    private deletedItems: ComparisonItem[] = [];
    private unmodifiedItems: ComparisonItem[] = [];

    constructor() {
        super();
    }

    ngOnChanges(changes: SimpleChanges) {
        super.onChange(changes, 'comparisonItems', () =>
            this.initOperationItems(),
        );
    }

    ngOnInit() {
        this.initOperationItems();
    }

    public getItemId(index: number, item: ComparisonItem) {
        return item.Item.Data.DataReferenceId;
    }

    private getComparisonItemsByAction(operation: CompareOperation) {
        return (
            this.comparisonItems?.filter(
                (item) =>
                    item.Module === this.moduleName &&
                    item.Operation === operation,
            ) ?? []
        );
    }

    public onDisplayMore() {
        this.usedLimit += this.itemsOffset;
        this.setDisplayedItems();
    }

    public onDisplayLess() {
        this.usedLimit = this.baseLimit;
        this.setDisplayedItems();
    }

    public onToggleHeaderCollapse() {
        if (this.hasItems) {
            this.isCollapsed = !this.isCollapsed;
        }
    }

    private initOperationItems() {
        this.addedItems = this.getComparisonItemsByAction(
            CompareOperation.Added,
        );
        this.updatedItems = this.getComparisonItemsByAction(
            CompareOperation.Updated,
        );
        this.deletedItems = this.getComparisonItemsByAction(
            CompareOperation.Deleted,
        );
        this.unmodifiedItems = this.getComparisonItemsByAction(
            CompareOperation.NotUpdated,
        );
        this.allItems = this.addedItems
            .concat(this.updatedItems)
            .concat(this.deletedItems);
        this.setDisplayedItems();
    }

    private setDisplayedItems() {
        const items = this.allItems.slice(
            0,
            Math.min(this.usedLimit, this.allItems.length),
        );
        this.displayedItems = CollectionsHelper.orderBy(
            items,
            (item) => item.Operation,
        );
    }
}
