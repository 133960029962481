import { Component, forwardRef, Input } from '@angular/core';
import { BaseCellComponent } from '@datagalaxy/core-ui/cell-components';
import { IEntitySimpleLinkCell } from './entity-simple-link-cell.types';
import { ViewTypeService } from '../../../../services/viewType.service';
import {
    HierarchyDataDescriptor,
    IHasHddData,
    IHierarchicalData,
} from '@datagalaxy/dg-object-model';
import { RouterLink } from '@angular/router';
import { EntityUrlPipe } from '../../../../entity-dashboard/entity-dashboard-url.pipe';

@Component({
    selector: 'app-entity-simple-link-cell',
    templateUrl: './entity-simple-link-cell.component.html',
    standalone: true,
    imports: [RouterLink, forwardRef(() => EntityUrlPipe)],
})
export class EntitySimpleLinkCellComponent extends BaseCellComponent<
    IHasHddData,
    unknown,
    IEntitySimpleLinkCell
> {
    @Input() text: string;
    public get hierarchicalData(): IHierarchicalData {
        return this.data.HddData;
    }

    constructor(private viewTypeService: ViewTypeService) {
        super();
    }

    protected override updateBindings() {
        this.initData();
    }

    private initData() {
        const propertyKey = this.params.propertyKey;
        const hdData = this.hierarchicalData.Data;
        this.text =
            propertyKey && hdData
                ? hdData[propertyKey]
                : this.getTechnicalOrDisplayName(hdData);
    }

    private getTechnicalOrDisplayName(hdData: HierarchyDataDescriptor) {
        return this.viewTypeService.getTechnicalOrDisplayName(hdData);
    }
}
