<dxy-rich-text-field
    #field
    [ngModel]="store.value()"
    (ngModelChange)="onValueChange($event)"
    [readonly]="readonly()"
    [labelText]="'DgServerTypes.BaseData.fields.LongDescription' | translate"
    [labelTooltipText]="
        'DgServerTypes.BaseData.description.LongDescription' | translate
    "
    (focus)="onFieldFocus()"
    (blur)="onFieldBlur($event)"
    toolbarDisplayMode="focus"
    [noTabCapture]="true"
>
    <div class="label-actions" label-actions>
        <dxy-translation-input-actions
            *dxyIfFeatureFlag="'multilingual'"
            [attributeName]="
                'DgServerTypes.BaseData.fields.LongDescription' | translate
            "
            [entityIdentifier]="entity()"
            [metalModelValue]="store.metaModelValue()"
            [translationResult]="store.translationResult()"
        />
        <dxy-text-attribute-ai-generation-button
            *dxyIfFeatureFlag="'autodescription'"
            [readonly]="readonly()"
            [generationStatus]="store.generationStatus()"
            [generateButtonTooltip]="
                'Suggestions.AutoDescription.generateDescriptionTooltip'
                    | translate
            "
            [menuTitle]="'Suggestions.AiGeneration.generated' | translate"
            [menuSubtitle]="
                'Suggestions.AutoDescription.suggestionPrecision' | translate
            "
            (generate)="store.generateDescription()"
        />
    </div>
</dxy-rich-text-field>

@if (store.edited()) {
    <button
        type="button"
        class="validate-button"
        dxyIconButton
        size="small"
        [disabled]="store.isPending()"
    >
        <i class="glyph-check"></i>
    </button>
}

@if (store.aiGenerationResultVisible()) {
    <dxy-text-attribute-ai-generation-result
        (cancel)="store.rejectSuggestion()"
        [generating]="store.isPending()"
        [error]="store.generationError()"
    >
        <ng-container title>
            {{ 'Suggestions.AutoDescription.suggestedDescription' | translate }}
        </ng-container>
        <ng-container suggestion>
            {{ store.pendingSuggestion()?.text }}
        </ng-container>
    </dxy-text-attribute-ai-generation-result>
}
