<div class="modal-header">
    <h3
        mat-dialog-title
        class="modal-title"
        translate="UI.DataQualityRules.creationModal.title"
    ></h3>
    <button dxyIconButton color="transparent" (click)="onCloseCancel()">
        <i class="glyph-cancelsearch"></i>
    </button>
</div>
<form [formGroup]="formGroup" (ngSubmit)="onCloseSubmit()">
    <div mat-dialog-content>
        <dxy-field-text
            name="statement"
            labelKey="UI.DataQualityRules.creationModal.statement"
            formControlName="statement"
            [mandatory]="true"
            [hintBeforeControl]="true"
        ></dxy-field-text>

        <dxy-field-text
            name="code"
            labelKey="UI.DataQualityRules.creationModal.code"
            formControlName="code"
            [mandatory]="false"
            [hintBeforeControl]="true"
            [errorMessageText]="codeErrorMessage"
        ></dxy-field-text>

        <dxy-field-select
            name="type"
            labelKey="UI.DataQualityRules.creationModal.type"
            [adapter]="ruleTypesAdapter"
            formControlName="type"
            [mandatory]="true"
            [hintBeforeControl]="true"
        ></dxy-field-select>

        <dxy-entity-selector-field
            formControlName="fieldIds"
            labelKey="UI.DataQualityRules.creationModal.fields"
            [options]="entitySelectorOptions"
            [isMultiValue]="true"
        ></dxy-entity-selector-field>
    </div>
    <dxy-modal-footer
        [actionEnabled]="formGroup.valid"
        [actionBtnLbl]="'UI.Dialog.NewItem.lblCreate' | translate"
        [actionLoading]="loading$ | async"
        (onCloseCancel)="onCloseCancel()"
        featureCode="DQ_RULE,C,ADD"
    ></dxy-modal-footer>
</form>
