import { CollectionsHelper } from '@datagalaxy/core-util';
import { IActionOption, withLoading } from '@datagalaxy/core-ui';
import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SynonymCellComponent } from '../cells/synonym-cell/synonym-cell.component';
import { EntityPreviewPanelService } from '../services/entity-preview-panel.service';
import {
    IScreenData,
    IScreenLayoutComponent,
} from '../../screens-layout/screens-layout.types';
import {
    EntityType,
    ObjectLinkType,
    ServerType,
} from '@datagalaxy/dg-object-model';
import { EntityService } from '../services/entity.service';
import { IDropdownButtonOption } from '../../shared-ui/dropdownButton.types';
import { EntityUiService } from '../services/entity-ui.service';
import { AppEventsService } from '../../../services/AppEvents.service';
import { ScreenService } from '../../../screens/screen.service';
import { EntityEventService } from '../services/entity-event.service';
import {
    SynonymDto,
    SynonymType,
} from '@datagalaxy/webclient/entity/data-access';
import { ScreenCategory } from '@datagalaxy/webclient/screen/data-access';
import {
    CrudOperation,
    FunctionalLogService,
} from '@datagalaxy/shared/monitoring/data-access';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { EntityIdentifier } from '@datagalaxy/webclient/entity/utils';
import { GridCellType, GridConfig, TColDef } from '@datagalaxy/ui/grid';
import { GridComponent } from '@datagalaxy/ui/grid';
import { DxyDropdownButtonComponent } from '../../shared-ui/dxy-dropdown-button/dxy-dropdown-button.component';
import { NgIf } from '@angular/common';
import { DxyCollapsibleComponent } from '@datagalaxy/core-ui';

/**
 * ## Role
 * OmniGrid containing all synonyms of entity
 */
@Component({
    selector: 'dxy-entity-synonyms',
    templateUrl: 'dxy-entity-synonyms.component.html',
    styleUrls: ['dxy-entity-synonyms.component.scss'],
    standalone: true,
    imports: [
        DxyCollapsibleComponent,
        NgIf,
        DxyDropdownButtonComponent,
        GridComponent,
    ],
})
export class DxyEntitySynonymsComponent
    extends DxyBaseComponent
    implements IScreenLayoutComponent, OnInit
{
    @Input() screenData: IScreenData;
    @Input() screenCategory: ScreenCategory;

    public readonly addButtonActions: IDropdownButtonOption[] = [
        {
            titleKey: 'UI.Synonyms.Create.CreateLocalSynonymBtn',
            subTitleKey: 'UI.Synonyms.Create.CreateLocalSynonymDescription',
            callback: () => this.onCreateLocalSynonym(),
        },
        {
            titleKey: 'UI.Synonyms.Create.CreateSynonymLinkBtn',
            subTitleKey: 'UI.Synonyms.Create.CreateSynonymLinkDescription',
            callback: () => this.onCreateSynonymLinkedObject(),
        },
    ];
    protected synonyms: SynonymDto[];
    protected gridConfig: GridConfig<SynonymDto> = {
        getItemId: (row) => row.ReferenceId,
        autoHeight: true,
    };
    protected cols: TColDef<SynonymDto>[] = [];

    protected get entityData() {
        return this.screenData.entityData;
    }
    protected get hasWriteAccess() {
        return (
            this.entityData.SecurityData.HasWriteAccess &&
            !this.screenData?.readOnly
        );
    }
    protected get titleKey() {
        return this.screenService.getScreenCategoryNameKey(this.screenCategory);
    }
    protected get hasSynonyms() {
        return !!this.synonyms?.length;
    }
    protected get synonymsCountMsg() {
        return this.translate.instant('UI.Synonyms.SynonymsCount', {
            count: this.synonyms?.length,
        });
    }

    constructor(
        private translate: TranslateService,
        private functionalLogService: FunctionalLogService,
        private entityUiService: EntityUiService,
        private entityPreviewPanelService: EntityPreviewPanelService,
        private entityEventService: EntityEventService,
        private screenService: ScreenService,
        private entityService: EntityService,
        private appEventsService: AppEventsService,
    ) {
        super();
    }

    ngOnInit() {
        this.registerSubscriptions(
            this.entityEventService.subscribeEntityLinkIdentifierDelete(
                ServerType.Property,
                (data) => {
                    const index = this.synonyms.findIndex(
                        (s) => s.ReferenceId == data.DataReferenceId,
                    );
                    if (
                        this.entityData.DataReferenceId !=
                            data.DataReferenceId &&
                        index == -1
                    ) {
                        return;
                    }
                    this.loadSynonyms();
                },
            ),

            this.entityEventService.subscribeEntityLinkAdd(
                ServerType.Property,
                (data) => {
                    if (
                        this.entityData.DataReferenceId != data.DataReferenceId
                    ) {
                        return;
                    }
                    this.loadSynonyms();
                },
            ),

            this.entityEventService.subscribeEntityUpdate(
                ServerType.Property,
                (data) => {
                    const description =
                        data.getAttributeValue<string>('Description');
                    const index = this.synonyms.findIndex(
                        (s) => s.ReferenceId == data.DataReferenceId,
                    );
                    if (
                        this.synonyms?.length > 0 &&
                        description != null &&
                        index >= 0
                    ) {
                        const synonym = this.synonyms[index];
                        synonym.Description = description;
                        this.refreshSynonyms(this.synonyms);
                    }
                },
            ),
        );

        this.initColumns();
        this.loadSynonyms();
    }

    //#region Event Handler : Local Synonyms Actions
    public async onCreateLocalSynonym() {
        const modalResult = await this.entityUiService.openLocalSynonymModal();
        if (!modalResult) {
            return;
        }
        const synonymDto = await this.entityService.createLocalSynonym(
            this.entityData.ReferenceId,
            modalResult.displayName,
            modalResult.description,
            this.entityData.VersionId,
        );
        this.synonyms.push(synonymDto);
        this.logCrudOperation(CrudOperation.C);
        this.refreshSynonyms(this.synonyms);
    }
    public async onUpdateLocalSynonym(data: SynonymDto) {
        const modalResult = await this.entityUiService.openLocalSynonymModal(
            false,
            data.DisplayName,
            data.Description,
        );
        if (!modalResult) {
            return;
        }
        const synonymDto = await this.entityService.updateLocalSynonym(
            data.ReferenceId,
            modalResult.displayName,
            modalResult.description,
            this.entityData.VersionId,
        );
        CollectionsHelper.replaceOne(
            this.synonyms,
            (a) => a.ReferenceId == synonymDto.ReferenceId,
            synonymDto,
        );
        this.logCrudOperation(CrudOperation.U);
        this.refreshSynonyms(this.synonyms);
    }
    public async onDeleteLocalSynonym(data: SynonymDto) {
        const synonymDto = await this.entityService.deleteLocalSynonym(
            data.ReferenceId,
            this.entityData.VersionId,
        );
        CollectionsHelper.remove(
            this.synonyms,
            (a) => a.ReferenceId == synonymDto.ReferenceId,
        );
        this.logCrudOperation(CrudOperation.D);
        this.refreshSynonyms(this.synonyms);
    }
    //#endregion

    //#region Event Handler : Synonyms Linked Object Actions
    public onCreateSynonymLinkedObject() {
        this.entityUiService.openSynonymLinkObjectModal(this.entityData);
        this.logCrudOperation(CrudOperation.C);
    }

    public onDeleteSynonymLinkedObject(data: SynonymDto) {
        this.entityService.deleteEntityLink(
            this.entityData.ReferenceId,
            data.ReferenceId,
            this.entityData.VersionId,
            ObjectLinkType.IsSynonymOf,
        );
        this.logCrudOperation(CrudOperation.D);
    }

    public onOpenPane(data: SynonymDto) {
        this.functionalLogService.logFunctionalAction(
            'PROPERTY_SYNONYMS',
            CrudOperation.R,
        );
        this.entityPreviewPanelService.setupPanel({
            entityIdr: EntityIdentifier.fromIHasHddData(data),
        });
    }
    //#endregion

    private refreshSynonyms(synonyms: SynonymDto[]) {
        this.synonyms = [...synonyms];
    }

    @withLoading()
    private async loadSynonyms() {
        const result = await this.entityService.getPropertySynonyms(
            this.screenData.entityData.ReferenceId,
            this.entityData.VersionId,
        );
        this.refreshSynonyms(result);
    }
    private initColumns() {
        this.cols = [
            {
                id: 'DisplayName',
                headerLabel: this.translate.instant('UI.Synonyms.DisplayName'),
                type: GridCellType.custom,
                customCellComponent: SynonymCellComponent,
                getInputs: (row) =>
                    ({
                        readOnly: this.screenData.readOnly,
                        actions: this.getAvailableActions(),
                        synonym: row,
                        localSynonym: row.DisplayName,
                    }) as Partial<SynonymCellComponent>,
                minWidth: 100,
                resizable: false,
                fixed: true,
                sortable: false,
            },
            {
                id: 'Description',
                headerLabel: this.translate.instant('UI.Synonyms.Summary'),
                type: GridCellType.text,
                minWidth: 100,
                resizable: false,
                fixed: true,
                sortable: false,
            },
        ];
    }

    private getAvailableActions() {
        if (this.screenData.readOnly) {
            return [];
        }
        const options = new Array<IActionOption<SynonymDto>>();

        options.push({
            glyphClass: (data) =>
                this.isLocalSynonymType(data)
                    ? 'glyph-edit'
                    : 'glyph-object-preview',
            callback: (data) =>
                this.isLocalSynonymType(data)
                    ? this.onUpdateLocalSynonym(data)
                    : this.onOpenPane(data),
            tooltipTranslateKey: (data: SynonymDto) =>
                this.isLocalSynonymType(data)
                    ? this.translate.instant(
                          'UI.Synonyms.UpdateSynonymToolTipMsg',
                      )
                    : this.translate.instant(
                          'UI.Search.resultActionTooltip.openPane',
                      ),
            hidden: (data: SynonymDto) =>
                this.isLocalSynonymType(data) ? !this.hasWriteAccess : false,
        });

        options.push({
            buttonColor: 'destructive',
            glyphClass: (data) =>
                this.isLocalSynonymType(data)
                    ? 'glyph-delete'
                    : 'glyph-link-broken',
            callback: (data) =>
                this.isLocalSynonymType(data)
                    ? this.onDeleteLocalSynonym(data)
                    : this.onDeleteSynonymLinkedObject(data),
            tooltipTranslateKey: (data: SynonymDto) =>
                this.isLocalSynonymType(data)
                    ? this.translate.instant(
                          'UI.Synonyms.DeleteLocalSynonymToolTipMsg',
                      )
                    : this.translate.instant(
                          'UI.Synonyms.DeleteLinkedObjectToolTipMsg',
                      ),
            hidden: () => !this.hasWriteAccess,
        });

        return options;
    }

    private isLocalSynonymType(data: SynonymDto) {
        return data.Type == SynonymType.LocalSynonym;
    }

    private logCrudOperation(crudOperation: CrudOperation) {
        const entityType =
            EntityType[this.entityData?.EntityType]?.toUpperCase();
        this.functionalLogService.logFunctionalAction(
            `LOCAL_SYNONYM_${entityType}`,
            crudOperation,
        );
    }
}
